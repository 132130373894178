<label class="container">
  <span
    class="checkbox-label" [ngClass]="{'checked': val}">{{label}}</span>
  <input #checkboxInput type="checkbox" [(ngModel)]="val" (ngModelChange)="onChange($event)" [disabled]="disabled">
  <span *ngIf="tooltip" [nbTooltip]="tooltip"
        class="checkmark" [ngClass]="{'blue': color === CheckboxColor.BLUE,
                                      'red': color === CheckboxColor.RED,
                                      'green': color === CheckboxColor.GREEN}">
  </span>
  <span *ngIf="!tooltip"
        class="checkmark" [ngClass]="{'blue': color === CheckboxColor.BLUE,
                                      'red': color === CheckboxColor.RED,
                                      'green': color === CheckboxColor.GREEN}">
  </span>
</label>
