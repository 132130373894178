import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { getValidationStatus, handleValidationErrorMessage, isFormValid, getErrorMessage } from '../../../utilities/utils';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { phoneNumber, alpha, invalidDate, dateGreaterOrEqualThan } from '../../../utilities/validators';
import { NbToastrService } from '@nebular/theme';
import { TableService } from '../../shared/table/table.service';
import { AuthUserService } from '../../../@core/data/auth-user.service';
import { Subscription } from 'rxjs';
import { errorMessages } from '../residents-errors';
import { ResidentsService } from '../../../shared/residents/residents.service';
import * as moment from 'moment';
import { FinanceTaskService } from '../../task-management/tasks/edit-task/finance-task.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ngx-resident-left',
  templateUrl: './resident-left.component.html',
  styleUrls: ['./resident-left.component.scss']
})
export class ResidentLeftComponent implements OnInit, OnDestroy {
  @Input() financeTaskMode = false;
  private subscription: Subscription = new Subscription();
  public errorMessages = errorMessages;
  public form: UntypedFormGroup;
  public utils = { getValidationStatus, handleValidationErrorMessage, isFormValid };
  public residentId = 0;
  public financeTaskId = 0;
  public leftDate = null;
  public leftMaxDate = moment(new Date()).add(7, 'd');
  public nokFields = ['firstName', 'surname', 'street', 'city', 'county', 'houseNumber', 'zipCode', 'email', 'country', 'phoneNumber'];
  public noks: any = [
    {
      id: -1,
      name: 'to come'
    },
    {
      id: 0,
      name: 'Manual',
      data: {
        firstName: null,
        surname: null,
        phoneNumber: null,
        email: null,
        houseNumber: null,
        street: null,
        city: null,
        county: null,
        zipCode: null,
        country: null,
      }
    }
  ];

  constructor(private tableService: TableService,
              private toastrService: NbToastrService,
              private authUserService: AuthUserService,
              private residentsService: ResidentsService,
              @Inject(DOCUMENT) private document: Document,
              private financeTaskService: FinanceTaskService) {
    this.subscription.add(this.financeTaskService.saveData$.subscribe((save: boolean) => {
      if (save) {
        this.save();
      }
    }));
    this.subscription.add(this.financeTaskService.financeData$.subscribe((financeData: any) => {
      if (financeData) {
        this.loadData(financeData);
      }
    }));
  }

  ngOnInit() {
    this.createForm();
    this.form.markAllAsTouched();
    if (!this.financeTaskMode) {
      this.financeTaskId = this.tableService.getValue().financeTaskId;
      this.residentId = this.tableService.getValue().elementId;
      this.leftDate = this.tableService.getValue().leftDate;
      this.loadResidentAddresses();
    }
    this.form.patchValue({
      dateLeft: this.leftDate
    });
    this.form.get('dateLeft').disable();
  }

  loadData(financeData: any) {
    this.financeTaskId = financeData.financeTaskId;
    this.residentId = financeData.residentId;
    this.loadResidentAddresses();
    this.updateValues(financeData);
  }

  loadResidentAddresses() {
    this.subscription.add(this.residentsService.getResident(this.residentId)
      .subscribe((response: any) => {
        this.noks.push({
          id: 1,
          name: 'copy from Primary Contact Person',
          data: response.residentInfo.contactAddress1
        });
        this.noks.push({
          id: 2,
          name: 'copy from Primary Contact Person 2',
          data: response.residentInfo.contactAddress2
        });
        this.noks.push({
          id: 3,
          name: 'copy from Finances Manager Details',
          data: response.residentInfo.financeManagerAddress
        });
    }));
  }

  createForm() {
    this.form = new UntypedFormGroup({
      reasonForLeaving: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
      dateLeft: new UntypedFormControl(null, [Validators.required, invalidDate]),
      dateRoomCleared: new UntypedFormControl(null, [Validators.required, invalidDate]),
      sundriesToCharge: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
      residentCashToReimburse: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
      nokToReceiveRefund: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
      firstName: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
      surname: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
      phoneNumber: new UntypedFormControl('', [Validators.required, phoneNumber]),
      email: new UntypedFormControl(null, [Validators.required, Validators.email, Validators.maxLength(50)]),
      houseNumber: new UntypedFormControl(null, [Validators.required, Validators.maxLength(20)]),
      street: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
      city: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
      county: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
      zipCode: new UntypedFormControl(null, [Validators.required, Validators.maxLength(25)]),
      country: new UntypedFormControl(null, [Validators.required, Validators.maxLength(2), alpha]),
      sortCode: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
      accountNumber: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
      comments: new UntypedFormControl(null, [Validators.maxLength(5000)]),
      sundriesToChargeToCome: new UntypedFormControl(false),
      residentCashToReimburseToCome: new UntypedFormControl(false),
      contactPersonToCome: new UntypedFormControl(false),
      sortCodeToCome: new UntypedFormControl(false),
      accountNumberToCome: new UntypedFormControl(false),
      dateRoomClearedToCome: new UntypedFormControl(false)
    });

    this.subscription.add(this.form.get('dateLeft').valueChanges.subscribe(change => {
      this.form.get('dateRoomCleared').setValidators([Validators.required, invalidDate, dateGreaterOrEqualThan(this.form.get('dateLeft').value)]);
      this.form.get('dateRoomCleared').updateValueAndValidity();
    }));

    this.form.get('sundriesToChargeToCome').valueChanges.subscribe((value: any) => {
      this.changeByCheckbox(value, 'sundriesToCharge');
    });
    this.form.get('sundriesToCharge').valueChanges.subscribe((value: any) => {
      this.changeByInput(value, 'sundriesToCharge', 'sundriesToChargeToCome');
    });

    this.form.get('residentCashToReimburseToCome').valueChanges.subscribe((value: any) => {
      this.changeByCheckbox(value, 'residentCashToReimburse');
    });
    this.form.get('residentCashToReimburse').valueChanges.subscribe((value: any) => {
      this.changeByInput(value, 'residentCashToReimburse', 'residentCashToReimburseToCome');
    });

    this.form.get('nokToReceiveRefund').valueChanges.subscribe((value: any) => {
      for (let i = 0; i < this.noks.length; i++) {
        if (this.noks[i].id == value && value != -1) {
          this.form.patchValue(this.noks[i].data);
          this.enableNokFields();
          break;
        } else {
          this.disableNokFields();
        }
      }
    });

    this.form.get('contactPersonToCome').valueChanges.subscribe((value: any) => {
      if (value) {
        this.form.patchValue({ nokToReceiveRefund: -1 }, { emitEvent: false });
        this.form.get('nokToReceiveRefund').disable();
        this.disableNokFields();
      } else {
        this.form.patchValue({ nokToReceiveRefund: 0 }, { emitEvent: false });
        this.form.get('nokToReceiveRefund').enable();
        this.enableNokFields();
      }
      this.form.get('nokToReceiveRefund').updateValueAndValidity();
    });
    this.form.get('nokToReceiveRefund').valueChanges.subscribe((value: any) => {
      if (this.form.get('nokToReceiveRefund').enabled && value === -1) {
        this.form.patchValue({ contactPersonToCome: true }, { emitEvent: false });
        this.form.get('contactPersonToCome').updateValueAndValidity();
      }
    });

    this.form.get('sortCodeToCome').valueChanges.subscribe((value: any) => {
      this.changeByCheckbox(value, 'sortCode');
    });
    this.form.get('sortCode').valueChanges.subscribe((value: any) => {
      this.changeByInput(value, 'sortCode', 'sortCodeToCome');
    });

    this.form.get('accountNumberToCome').valueChanges.subscribe((value: any) => {
      this.changeByCheckbox(value, 'accountNumber');
    });
    this.form.get('accountNumber').valueChanges.subscribe((value: any) => {
      this.changeByInput(value, 'accountNumber', 'accountNumberToCome');
    });

    this.form.get('dateRoomClearedToCome').valueChanges.subscribe((value: any) => {
      this.changeByCheckbox(value, 'dateRoomCleared');
    });
  }

  enableNokFields() {
    this.nokFields.forEach((control) => {
      this.form.get(control).enable();
    });
  }

  disableNokFields() {
    this.nokFields.forEach((control) => {
      this.form.get(control).disable();
    });
  }

  changeByCheckbox(value: any, controlNameToChange: string) {
    if (value) {
      const obj = {};
      obj[controlNameToChange] = 'to come';
      this.form.patchValue(obj, { emitEvent: false });
      this.form.get(controlNameToChange).disable();
    } else {
      const obj = {};
      obj[controlNameToChange] = null;
      this.form.patchValue(obj, { emitEvent: false });
      this.form.get(controlNameToChange).enable();
    }
    this.form.get(controlNameToChange).updateValueAndValidity();
  }

  changeByInput(value: any, controlName: string, controlNameToChange: string) {
    if (this.form.get(controlName).enabled && value === 'to come') {
      const obj = {};
      obj[controlNameToChange] = true;
      this.form.patchValue(obj, { emitEvent: false });
      this.form.get(controlNameToChange).updateValueAndValidity();
    }
  }

  getNokToReceiveRefund(id: any) {
    let value = 'to come';
    this.noks.map((nok: any) => {
      if (nok.id === id) {
        value = nok.name;
      }
    });
    return value;
  }

  prepareData() {
    const data = {
      careHomeId: this.authUserService.getCareHomeId(),
      reasonForLeaving: this.form.get('reasonForLeaving').value,
      dateLeft: this.form.get('dateLeft').value,
      dateRoomCleared: this.form.get('dateRoomCleared').value,
      sundriesToCharge: this.form.get('sundriesToCharge').value,
      residentCashToReimburse: this.form.get('residentCashToReimburse').value,
      nokToReceiveRefund: this.getNokToReceiveRefund(this.form.get('nokToReceiveRefund').value),
      contactPerson: {
        firstName: this.form.get('firstName').value,
        surname: this.form.get('surname').value,
        phoneNumber: this.form.get('phoneNumber').value,
        email: this.form.get('email').value,
        houseNumber: this.form.get('houseNumber').value,
        street: this.form.get('street').value,
        city: this.form.get('city').value,
        county: this.form.get('county').value,
        zipCode: this.form.get('zipCode').value,
        country: this.form.get('country').value
      },
      sortCode: this.form.get('sortCode').value,
      accountNumber: this.form.get('accountNumber').value,
      comments: this.form.get('comments').value,
      sundriesToChargeToCome: this.form.get('sundriesToChargeToCome').value,
      residentCashToReimburseToCome: this.form.get('residentCashToReimburseToCome').value,
      contactPersonToCome: this.form.get('contactPersonToCome').value,
      sortCodeToCome: this.form.get('sortCodeToCome').value,
      accountNumberToCome: this.form.get('accountNumberToCome').value,
      dateRoomClearedToCome: this.form.get('dateRoomClearedToCome').value,
      originUrl: this.document.location.origin,
    };

    if (this.form.get('dateRoomClearedToCome').value) {
      data.dateRoomCleared = null;
    }
    return data;
  }

  updateValues(data: any) {
    this.form.patchValue({
      reasonForLeaving: data.reasonForLeaving,
      dateLeft: data.dateLeft,
      dateRoomCleared: data.dateRoomCleared,
      sundriesToCharge: data.sundriesToCharge,
      residentCashToReimburse: data.residentCashToReimburse,
      nokToReceiveRefund: this.getNokToReceiveRefundId(data.nokToReceiveRefund),
      sortCode: data.sortCode,
      accountNumber: data.accountNumber,
      comments: data.comments,
      dateRoomClearedToCome: data.dateRoomClearedToCome,
      firstName: data.contactPerson && data.contactPerson.firstName,
      surname: data.contactPerson && data.contactPerson.surname,
      phoneNumber: data.contactPerson && data.contactPerson.phoneNumber,
      email: data.contactPerson && data.contactPerson.email,
      houseNumber: data.contactPerson && data.contactPerson.houseNumber,
      street: data.contactPerson && data.contactPerson.street,
      city: data.contactPerson && data.contactPerson.city,
      county: data.contactPerson && data.contactPerson.county,
      zipCode: data.contactPerson && data.contactPerson.zipCode,
      country: data.contactPerson && data.contactPerson.country,
    });
    this.form.get('dateRoomCleared').setValue(data.dateRoomCleared);

    this.form.valueChanges
      .subscribe(() => {
        this.financeTaskService.isValid$.next(this.utils.isFormValid(this.form));
    });
  }

  getNokToReceiveRefundId(name: string) {
    let value = 0;
    this.noks.map((nok: any) => {
      if (nok.name === name) {
        value = nok.id;
      }
    });
    return value;
  }

  save() {
    const data = this.prepareData();
      this.subscription.add(this.residentsService.updateFinanceTask(this.residentId, this.financeTaskId, data)
      .subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        this.tableService.closeWindow(true);
      },
      (err) => {
        this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
      }
    ));
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.financeTaskService.reset();
  }

}
