import { ReadMessageComponent } from './read-message/read-message.component';
import { InboxListComponent } from './inbox-list/inbox-list.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NoteboardComponent } from './noteboard/noteboard.component';

const routes: Routes = [
  {
    path: 'categories/:id/:mailboxName/:mainCategoryId',
    component: InboxListComponent
  },
  {
    path: 'categories/:id/:mailboxName',
    component: InboxListComponent
  },
  {
    path: 'message/:uid',
    component: ReadMessageComponent
  },
  {
    path: 'noteboard',
    component: NoteboardComponent
  },
  {
    path: 'inbox',
    redirectTo: 'inbox/categories/toRead/toRead'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InboxRoutingModule {}
