<ng-container *ngIf="!editMode; else editModeContainer">
  <div
    class="cell-container"
    [style.padding.rem]="0.5"
    [class.migrate]="dayData.requireMigration"
    [class.confirmed]="dayData.confirmedFrom"
    [class.not-scheduled]="!form.get('from').value"
  >
    <ng-container *ngIf="dayData.statusId != noStart && dayData.statusId != noEnd; else beforeContractStartDate">
      <ng-container *ngIf="form.get('from').value; else notScheduled">
        <div>
          <div>{{dataSaved ? (form.get('careHomeId').value | homeCare: homes) : contractType}}</div>
          <div>{{dataSaved ? form.get('from').value : dayData.scheduleFrom}}
            - {{dataSaved ? form.get('to').value : dayData.scheduleTo}}</div>
        </div>
        <div>{{dataSaved ? (form.get('positionId').value | roleType: roles) : dayData.careHomeName}}</div>
      </ng-container>
      <ng-template #notScheduled>
        <div>
          <div>{{dataSaved ? (form.get('careHomeId').value | homeCare: homes) : contractType}}</div>
          <div></div>
        </div>
        <div>{{dataSaved ? (form.get('positionId').value | roleType: roles) : dayData.careHomeName}}</div>
      </ng-template>
    </ng-container>
    <ng-template #beforeContractStartDate></ng-template>
  </div>
</ng-container>
<ng-template #editModeContainer>
  <ng-container *ngIf="dayData.statusId != noStart && dayData.statusId != noEnd; else beforeContractStartDateEdit">
    <div
      [formGroup]="form"
      class="cell-container cell-form"
      [class.migrate-edit]="dayData.requireMigration"
      [class.confirmed-edit]="dayData.confirmedFrom"
    >
      <ng-select
        formControlName="positionId"
        dropdownPosition="auto"
        appendTo=".full-screen"
        class="rota-migration-additional__select"
        [virtualScroll]="true"
        [clearable]="false"
        [items]="roles"
        [bindValue]="'id'"
        [bindLabel]="'roleName'"
      ></ng-select>
      <div class="form-hours">
        <ngx-hours-cell-selector
          formControlName="from"
          id="fromControl"
          [values]="hoursTemplate.hoursTemplateFrom"
          (valueSelected)="checkHours($event, false)"
          (onChangeEvent)="patchFromValue($event)"
          #fromHoursSelectorComponent
        ></ngx-hours-cell-selector>
        <ngx-hours-cell-selector
          formControlName="to"
          id="toControl"
          (valueSelected)="!['00:00', '0:00', '0'].includes($event.target.value) && checkHours($event)"
          (focusout)="!['00:00', '0:00', '0'].includes($event.target.value) && checkHours($event)"
          [values]="hoursTemplate.hoursTemplateTo"
          (onChangeEvent)="patchToValue($event)"
        ></ngx-hours-cell-selector>
      </div>
      <div class="form-hours clearHours">
        <ng-select
          formControlName="careHomeId"
          dropdownPosition="auto"
          appendTo=".full-screen"
          class="rota-migration-additional__select"
          [virtualScroll]="true"
          [clearable]="false"
          [items]="homes"
          [bindValue]="'id'"
          [bindLabel]="'name'"
        ></ng-select>
        <button class="delete-file" (click)="clearHours()" [disabled]="dayData.confirmedFrom"
                [style.cursor]="dayData.confirmedFrom ? 'auto' : 'none'"><i class="nb-trash" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #beforeContractStartDateEdit>
    <div class="cell-container" [style.padding.rem]="0.5" [class.not-scheduled]="!form.get('from').value"></div>
  </ng-template>
</ng-template>
