import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { TaskBoardsService } from '../../../shared/tasks/task-boards/task-boards.service';
import { LoadingStatus } from '../loading-status.enum';
import { TasksService } from '../../../shared/tasks/tasks.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'ngx-add-watchers-dialog',
  templateUrl: './add-watchers-dialog.component.html',
  styleUrls: ['./add-watchers-dialog.component.scss']
})
export class AddWatchersDialogComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() taskId: number;
  @Input() taskboardId: number | number[];
  @Input() isMultiple: boolean = false;
  public form: UntypedFormGroup;
  public choosendWatchers = [];
  public watchersToAdd = [];
  public LoadingStatus: typeof LoadingStatus = LoadingStatus;
  public loadingStatus: LoadingStatus = LoadingStatus.NOT_LOADED;

  constructor(protected ref: NbDialogRef<AddWatchersDialogComponent>,
              private taskBoardsService: TaskBoardsService,
              private taskService: TasksService) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      selectedWatchers: new UntypedFormControl({value: null, disabled: true}, []),
      watchers: new UntypedFormControl([])
    });
    let boardSubsription = this.taskBoardsService.getTaskBoard(this.taskboardId);
    let watchersSubscription = this.taskService.getTaskWatchers(this.taskId);

    if (this.isMultiple) {
      boardSubsription = this.taskBoardsService.getTaskBoardMultipleWatchers(this.taskboardId);
      watchersSubscription = this.taskService.getTaskMultipleWatchers(this.taskId);
    }

    this.subscription.add(boardSubsription
      .subscribe((response: any) => {
        this.loadingStatus = LoadingStatus.LOADED;
        const allWatchers = response.result.allowToClose;
        this.subscription.add(watchersSubscription
          .subscribe((innerResponse: any) => {
            this.choosendWatchers = innerResponse.result.watchersList;
            this.form.patchValue({
              selectedWatchers: innerResponse.result.watchersList
            });
            this.watchersToAdd = this.filterWatchersToAdd(allWatchers);
        }));
      }
    ));
  }

  filterWatchersToAdd(allWatchers: any) {
    for (let i = allWatchers.length - 1; i >= 0; i--) {
      for (let j = 0; j < this.choosendWatchers.length; j++) {
        if (allWatchers[i] && (allWatchers[i].id === this.choosendWatchers[j].id)) {
          allWatchers.splice(i, 1);
        }
      }
    }
    return allWatchers;
  }

  cancel() {
    this.ref.close(null);
  }

  ok() {
    const values = {} as any;
    values.watchersList = this.form.get('watchers').value;
    this.ref.close(values);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
