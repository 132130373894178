<div class="form-view">
  <main>
    <div class="table-container">
      <ngx-horizontal-sticky-only-table
        *ngIf="loaded === LoadingStatus.LOADED && !isBoards"
        [data]="data"
        [highHeader]="false"
        [thinner]="true"
        [columns]="columns">
      </ngx-horizontal-sticky-only-table>

      <ngx-only-table
      *ngIf="loaded === LoadingStatus.LOADED && isBoards"
        [data]="data"
        [thinner]="true"
        [columns]="columns">

      </ngx-only-table>

      <ng-container *ngIf="isStaffHours" class="task-table-container">
        <p class="subtitle">Actual task on Staff taskboards</p>
        <ngx-taskboard-only-table-with-stats
          class="taskboard-container"
          id="taskboard-container"
          [dataStream]="dataStream"
          [openInBackstack]="true"
          [backstackViewParams]="viewParams">
        </ngx-taskboard-only-table-with-stats>
      </ng-container>
    </div>
  </main>
  <footer class="space-between">
    <button (click)="closeWindow()"
            class="btn btn-danger">
      <i class="nb-close" aria-hidden="true"></i> Close
    </button>
    <div class="flex">
      <div *ngIf="!isBoards" class="flex">
        <button class="btn btn-success standard" (click)="subtractWeek()">-</button>
        <span class="week-counter">{{actualWeek}}</span>
        <button class="btn btn-success mr-2 standard" (click)="addWeek()">+</button>
      </div>
      <div class="flex">
          <button *ngIf="isXLSEnabled" class="btn btn-info report-icon button-xls" (click)="downloadXls()"><img src="assets/images/nb-xls.svg" alt=""/></button>
          <button class="btn btn-info standard" (click)="load()"><i class="nb-loop" aria-hidden="true"></i></button>
      </div>
    </div>
  </footer>
</div>
