import { AfterViewInit, Directive, ElementRef, HostListener, Input, NgZone, OnInit } from "@angular/core";
import { KeyboardScrollService } from "../../shared/scroll.service";

@Directive({
  selector: '[keyboardScroll]'
})
export class KeyboardScrollDirective implements AfterViewInit {
  private allowScroll = false
  private clickTarget = ''

  @Input() keyboardScrollNameTrigger: string = ''
  @Input() keyboardScrollHrefTrigger: string = ''

  @HostListener('document:click', [ '$event' ] ) focusOn(event: any) {
    if (event.target.innerText != '' || event.target.innerHTML != '') {
      if (this.keyboardScrollNameTrigger != '' && event.target.innerText == this.keyboardScrollNameTrigger) {
        this.allowScroll = true
      } else if (this.keyboardScrollNameTrigger != '' && event.target.innerHTML.toLowerCase().includes(this.keyboardScrollNameTrigger.toLowerCase())) {
        this.allowScroll = true
      } else if (this.keyboardScrollHrefTrigger != '' && window.location.href.toLowerCase().includes(this.keyboardScrollHrefTrigger.toLowerCase())) {
        this.allowScroll = true
      }else {
        this.allowScroll = !!this.elementRef.nativeElement.contains(event.target);
      }
    }
  }

  @HostListener('window:keydown', [ '$event' ] ) onArrowDown(event: KeyboardEvent) {
    if (this.allowScroll) {
      if (event.code == 'ArrowUp') this.moveUp()
      if (event.code == 'ArrowDown') this.moveDown()
      if (event.code == 'ArrowLeft') this.moveLeft()
      if (event.code == 'ArrowRight') this.moveRight()
      if (event.code == 'PageUp') this.movePageUp()
      if (event.code == 'PageDown') this.movePageDown()
    }
  }

  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private keyboardScrollService: KeyboardScrollService
    ) {
  }

  ngAfterViewInit (): void {
    this.keyboardScrollService.getClickTarget().subscribe((target) => {
      this.allowScroll = target == this.keyboardScrollNameTrigger;
      this.clickTarget = target
    })
    this.keyboardScrollService.getClickTagsBoardNameTarget().subscribe((tagsBoardName) => {
      if (tagsBoardName != '' && this.clickTarget != '' && this.clickTarget == tagsBoardName) {
        this.allowScroll = true
      }
    })

  }

  moveUp() {
    this.ngZone.runOutsideAngular(() => {
      this.elementRef.nativeElement.scrollBy({top: -150, behavior: 'smooth'});
    });
  }

  moveDown() {
    this.ngZone.runOutsideAngular(() => {
      this.elementRef.nativeElement.scrollBy({top: 150, behavior: 'smooth'});
    });
  }

  movePageUp() {
    this.ngZone.runOutsideAngular(() => {
      this.elementRef.nativeElement.scrollBy({top: -450, behavior: 'smooth'});
    });
  }

  movePageDown() {
    this.ngZone.runOutsideAngular(() => {
      this.elementRef.nativeElement.scrollBy({top: 450, behavior: 'smooth'});
    });
  }

  moveLeft() {
    this.ngZone.runOutsideAngular(() => {
      this.elementRef.nativeElement.scrollBy({left: -150, behavior: 'smooth'});
    });
  }

  moveRight() {
    this.ngZone.runOutsideAngular(() => {
      this.elementRef.nativeElement.scrollBy({left: 150, behavior: 'smooth'});
    });
  }
}
