import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
} from "@angular/forms";
import {
  getValidationStatus,
  handleValidationErrorMessage,
  isFormValid,
  validateFormControlsByName,
  getErrorMessage,
  getFullName,
  validateAtLeastOneInput,
  onHoursInput,
  onHoursInputWithExcluding,
  getMinutes,
  getTime,
} from "../../../../utilities/utils";
import { TableService } from "../../../shared/table/table.service";
import { EmployessService } from "../../../../shared/employess/employess.service";
import { DictionariesService } from "../../../../shared/dictionaries/dictionaries.service";
import {
  invalidDate,
  invalidHrs,
  invalidHrsMore,
} from "../../../../utilities/validators";
import { AnnualLeavingService } from "../../../../shared/employess/annual-leaving/annual-leaving.service";
import { NbToastrService, NbDialogService } from "@nebular/theme";
import { AuthUserService } from "../../../../@core/data/auth-user.service";
import { Subject, Subscription, debounceTime, finalize } from "rxjs";
import { QuestionDialogComponent } from "../../../shared/question-dialog/question-dialog.component";
import * as moment from "moment";
import { eventsErrors } from "../../events-errors";
import { OverviewService } from "../../../../shared/employess/overview/overview.service";
import { LoadingStatus } from "../../../shared/loading-status.enum";
import {
  EmployeeScheduleCalendar,
  EmployeeScheduleSummary,
} from "../../overview/overview-calendar/overview-calendar.component";
import { ConfirmRequestHoursComponent } from "../confirm-request-hours/confirm-request-hours.component";
import { ExplainAnnualLeaveComponent } from "../explain-annual-leave/explain-annual-leave.component";
import { ExplanationAnnualLeave } from "../model/explanation-annual-leave.model";
import { CurrentCareHomeService } from "../../../../@core/data/current-care-home.service";

enum ContractStatus {
  CANCELLED = "canceled",
  ACTIVE = "runing",
}
@Component({
  selector: "ngx-new-annual-leaving",
  templateUrl: "./new-annual-leaving.component.html",
  styleUrls: ["./new-annual-leaving.component.scss"],
})
export class NewAnnualLeavingComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public errorMessages = eventsErrors;
  public employees: any = [];
  public eventTypes: any = [];
  public employeeContracts: any = [];
  public explanationAnnualLeave: ExplanationAnnualLeave = null;
  public form: UntypedFormGroup;
  public controlNames = [
    "employeeId",
    "eventType",
    "leavingStart",
    "leavingEnd",
  ];
  public contractControlNames = [];
  public utils = {
    getValidationStatus,
    handleValidationErrorMessage,
    isFormValid,
    validateFormControlsByName,
    getFullName,
    validateAtLeastOneInput,
    onHoursInput,
    onHoursInputWithExcluding,
  };
  public showAnnualLeavingTable = false;
  public showASInfo: boolean = false;
  public givenHrsSum = "00:00";
  public givenHrsSumMinutes = 0;
  public recommendedHrsSum = "00:00";
  public recommendedHrsSumMinutes = 0;
  public maxHrsCanGive = "00:00";
  public maxHrsCanGiveMinutes = 0;
  public isGivenMoreThanRecommended = false;
  public isGivenMoreThanMax = false;
  public buttonDetailsText = "Hide full year AL";
  public showDetails = true;
  public upToDateText = "";
  public careHomeId = this.authUserService.getCareHomeId();
  public careHome: any;
  private employeeId = 0;
  public canSave = true;

  schedule: EmployeeScheduleCalendar[] = [];
  scheduleSummary: EmployeeScheduleSummary[] = [];

  public calendarData = {
    startDate: moment(),
    endDate: moment(),
    months: [],
  };
  public dateRange = {
    from: moment().subtract(3, "days"),
    to: moment().add(3, "days"),
  };
  public calendarMessageLeave = "";
  public calendarMessageShifts = "";
  public leaveDays = 0;
  public LoadingStatus: typeof LoadingStatus = LoadingStatus;
  public dataLoaded: LoadingStatus = LoadingStatus.NOT_LOADED;
  public tableIdentifier?: string;

  private previousReqRange: IPreviousReq = { from: undefined, to: undefined };
  private previousReqRangeContracts: IPreviousReq = {
    from: undefined,
    to: undefined,
  };

  public hasNoActiveContract: boolean = true;
  public noActiveContractMsg: string =
    "This employee has no active contract, it is not possible to add AL, please contact the HR department";
  employeeName: any;
  employeeFullName: string;

  contractsLoading = false;

  @ViewChild("leaveItem") leaveItem;

  alText = "";

  parentalLeaveId: number = null;
  requestOffId: number = null;

  private contractsSubject = new Subject();
  constructor(
    private tableService: TableService,
    private authUserService: AuthUserService,
    private employeeService: EmployessService,
    private dictionariesService: DictionariesService,
    private annualLeavingService: AnnualLeavingService,
    private toastrService: NbToastrService,
    private formBuilder: UntypedFormBuilder,
    private dialogService: NbDialogService,
    private overviewService: OverviewService,
    private careHomeService: CurrentCareHomeService,
  ) {
    this.employeeId =
      this.tableService && this.tableService.getValue()
        ? this.tableService.getValue().employeeId
        : 0;

    this.careHomeId =
      this.tableService &&
      this.tableService.getValue() &&
      this.tableService.getValue().careHomeId
        ? this.tableService.getValue().careHomeId
        : this.authUserService.getCareHomeId();
    this.alText =
      this.tableService && this.tableService.getValue()
        ? this.tableService.getValue().alText
        : "";
    this.tableIdentifier =
      this.tableService && this.tableService.getValue()
        ? this.tableService.getValue().tableIdentifier
        : undefined;
  }

  ngOnInit() {
    this.givenHrsSum = "00:00";
    this.givenHrsSumMinutes = 0;
    this.createForm();
    this.subscription.add(
      this.dictionariesService.getEventTypes().subscribe((response: any) => {
        this.eventTypes = response.result.wordsFromDictionary.filter(
          (word) => !word.wordFullName.includes("Sickness"),
        );

        this.parentalLeaveId =
          response.result.wordsFromDictionary.find(
            (i) => i.wordShortName == "PL",
          ).id || null;
        this.requestOffId =
          response.result.wordsFromDictionary.find(
            (i) => i.wordShortName == "RO",
          ).id || null;
        this.form
          .get("eventType")
          .patchValue(
            this.eventTypes.find((word) => word.wordFullName === "Annual leave")
              .id,
          );
      }),
    );
    this.subscription.add(
      this.employeeService
        .getEmployeesByCareHome(this.careHomeId, this.employeeId)
        .subscribe((response: any) => {
          response.result.employeesLines.map(
            (i) =>
              (i.fullName = getFullName(
                i.employeeFirstName,
                i.employeeSurname,
              )),
          );

          const employee = response.result.employeesLines.find(
            (i) => i.employeeId === this.employeeId,
          );

          if (employee) this.employeeFullName = employee.fullName;

          return (this.employees = response.result.employeesLines);
        }),
    );
    if (this.tableService.getValue()) {
      this.patchValues();
      this.careHomeId = this.tableService.getValue().careHomeId;
    }

    this.subscription.add(
      this.contractsSubject.pipe(debounceTime(300)).subscribe(() => {
        this.searchforContracts();
      }),
    );
  }

  createForm() {
    this.form = new UntypedFormGroup({
      employeeId: new UntypedFormControl(null, [Validators.required]),
      eventType: new UntypedFormControl(null, [Validators.required]),
      leavingStart: new UntypedFormControl(null, [
        Validators.required,
        invalidDate,
      ]),
      leavingEnd: new UntypedFormControl(null, [
        Validators.required,
        invalidDate,
      ]),
      firstDayAvailable: new UntypedFormControl(null),
      given: new UntypedFormControl(null),
      description: new UntypedFormControl(null, [Validators.required]),
      requiresAcceptance: new UntypedFormControl(false),
    });

    this.subscription.add(
      this.form.get("leavingEnd").valueChanges.subscribe((value: any) => {
        this.dateRange.to = moment(value).add(2, "days");
        this.form.patchValue({
          firstDayAvailable: moment(value).add(1, "d"),
        });
        if (this.form.get("leavingEnd").value) {
          if (this.checkIfPreviousIsSame(this.previousReqRangeContracts)) {
            return;
          }
          this.contractsSubject.next(true);
        }
      }),
    );

    this.subscription.add(
      this.form.get("leavingStart").valueChanges.subscribe((value: any) => {
        this.dateRange.from = moment(value).subtract(2, "days");
        this.upToDateText = moment(value).format("DD/MM/YYYY");
        if (this.form.get("leavingStart").value) {
          if (this.checkIfPreviousIsSame(this.previousReqRangeContracts)) {
            return;
          }
          this.contractsSubject.next(true);
        }
      }),
    );

    this.subscription.add(
      this.form.get("employeeId").valueChanges.subscribe((value: any) => {
        if (value) {
          this.contractsSubject.next(true);
        }
      }),
    );

    this.subscription.add(
      this.form
        .get("firstDayAvailable")
        .valueChanges.subscribe((value: any) => {
          if (
            moment(this.form.get("leavingEnd").value).isSameOrAfter(
              moment(value),
              "date",
            )
          ) {
            this.form
              .get("firstDayAvailable")
              .setErrors({ dateGreaterOrEqualThan: true });
          } else if (!this.isFDAEmptyOrNextAfterEnd()) {
            this.form
              .get("firstDayAvailable")
              .setErrors({ dateFDAEmptyOrNextAfterEnd: true });
          } else {
            this.form.get("firstDayAvailable").setErrors(null);
          }
        }),
    );
  }

  checkIfPreviousIsSame(previous: IPreviousReq): boolean {
    return (
      moment(previous.from).isSame(moment(this.dateRange.from, "day")) &&
      moment(previous.to).isSame(moment(this.dateRange.to, "day"))
    );
  }

  onStartDateInput(event: any) {
    const date = event.value;
    this.dateRange.from = moment(date).subtract(2, "days");
    if (this.form.get("leavingEnd").value === null) {
      this.form.get("leavingEnd").setValue(date);
      this.dateRange.to = moment(date).add(7, "days");
    } else {
      this.dateRange.to = moment(this.form.get("leavingEnd").value).add(
        2,
        "days",
      );
    }
    if (this.checkIfPreviousIsSame(this.previousReqRange)) {
      return;
    }

    this.getCalendarData();
  }

  onEndDateInput(event: any) {
    this.form.get("leavingEnd").setValue(event.value);
    this.dateRange.to = moment(event.value).add(2, "days");
    if (this.form.get("leavingStart").value) {
      if (this.checkIfPreviousIsSame(this.previousReqRange)) {
        return;
      }
    }
    this.getCalendarData();
  }

  clearFirstDayAvailable() {
    this.form.patchValue({ firstDayAvailable: null });
  }

  patchValues() {
    this.form
      .get("employeeId")
      .patchValue(this.tableService.getValue().employeeId);
    if (this.tableService.getValue().leavingStart) {
      this.form
        .get("leavingStart")
        .patchValue(this.tableService.getValue().leavingStart);
    }
    if (this.tableService.getValue().eventType === "Annual leave") {
      // 103 seems to be the eventType code for 'Annual leave'
      this.form.get("eventType").patchValue(103);
    }
  }

  updateValues() {
    this.employeeContracts.forEach((leaving) =>
      this.form.patchValue({
        [`leaving-${leaving.contractId}`]: this.form.get(
          `leaving-${leaving.contractId}`,
        ).value,
      }),
    );

    Object.keys(this.form.controls).forEach((key) => {
      if (key.includes("leaving-")) {
        this.form.get(key).valueChanges.subscribe((value: any) => {
          this.handleCalculateGivenHrs();
        });
      }
    });
    this.handleCalculateGivenHrs();
  }

  searchforContracts() {
    this.contractsLoading = true;
    this.previousReqRangeContracts["from"] = this.dateRange.from;
    this.previousReqRangeContracts["to"] = this.dateRange.to;
    this.isGivenMoreThanRecommended = false;
    if (
      this.form.get("leavingStart").value == null ||
      this.form.get("leavingEnd").value == null ||
      this.form.get("employeeId").value == null
    ) {
      this.contractsLoading = false;
      return;
    }
    this.subscription.add(
      this.employeeService
        .getContractsByEmployeeIdAndDateForAL(
          this.form.get("employeeId").value,
          this.form.get("leavingStart").value,
          this.form.get("leavingEnd").value,
          true,
        )
        .pipe(
          finalize(() => {
            this.contractsLoading = false;
          }),
        )
        .subscribe((response: any) => {
          this.employeeContracts = response.result.contractsLines;
          this.explanationAnnualLeave = response.result.alDetailedInfo;
          this.alText = response.result.alCalculationInfo;
          this.getSum("recommendedHrs");
          this.getSum("maxHrs");
          if (
            this.employeeContracts &&
            this.employeeContracts[0] &&
            this.employeeContracts[0].employeeStatus === "Inactive"
          ) {
            this.toastrService.danger(
              "Employee is inactive, you cannot add AL or sickness",
              "Error",
              { duration: 60000, destroyByClick: true },
            );
          } else {
            this.employeeContracts.forEach((leaving) => {
              this.form.addControl(
                `leaving-${leaving.contractId}`,
                this.formBuilder.control("0:00", [Validators.required]),
              );
              if (leaving.contracStatus == "canceled") {
                this.form.get(`leaving-${leaving.contractId}`).disable();
              }
            });
            this.showAnnualLeavingTable = true;
            this.form.get("firstDayAvailable").markAsTouched();
            this.form.get("description").markAsTouched();
            this.contractControlNames = [];
            Object.keys(this.form.controls).forEach((key) => {
              if (key.includes("leaving-")) {
                this.contractControlNames.push(key);
              }
            });
            this.updateValues();
            const start = this.form.get("leavingStart").value;
            const end = this.form.get("leavingEnd").value;
            this.employeeContracts.forEach((leaving) => {
              if (
                leaving.contracStatus === "waiting for start" ||
                leaving.contracStatus === "runing"
              ) {
                this.form.controls[
                  `leaving-${leaving.contractId}`
                ].clearValidators();
                // this.form.controls[`leaving-${leaving.contractId}`].setValidators([invalidHrsMore(leaving.contractHoursPerWeek, this.setHours(leaving), start, end), invalidHrs(leaving.contractHoursPerWeek, this.setHours(leaving), start, end)])
                this.form.controls[
                  `leaving-${leaving.contractId}`
                ].setValidators([
                  invalidHrs(
                    leaving.contractHoursPerWeek,
                    this.setHours(leaving),
                    start,
                    end,
                  ),
                ]);
                this.form
                  .get(`leaving-${leaving.contractId}`)
                  .setValue(this.setHours(leaving));
                this.form.controls[
                  `leaving-${leaving.contractId}`
                ].valueChanges.subscribe(() => {
                  this.form.updateValueAndValidity();
                });
                this.form.updateValueAndValidity();
              }
            });
          }
          if (this.employeeContracts && this.employeeContracts.length > 0) {
            for (const contract of this.employeeContracts)
              if (
                contract.contractRole !== "in Training" &&
                contract.contracStatus === "runing"
              ) {
                this.hasNoActiveContract = false;
              }
          }
        }),
    );
  }

  isAnyLeftHoursLessThanGiven() {
    let isAnyLeftHoursLessThanGiven = false;
    this.employeeContracts.forEach((leaving: any) => {
      const leftHours = this.parseHoursToFloat(
        leaving.leaveSettlements.leftHrs,
      );
      const given = this.form.get(`leaving-${leaving.contractId}`).value;
      if (leftHours < this.parseHoursToFloat(given))
        isAnyLeftHoursLessThanGiven = true;
    });
    return isAnyLeftHoursLessThanGiven;
  }

  parseHoursToFloat(hoursString: string): number {
    const [hours, minutes] = hoursString.split(":");
    const floatHours = parseInt(hours, 10) + parseInt(minutes, 10) / 60;
    return Math.round(floatHours * 100) / 100;
  }

  isFDAEmptyOrNextAfterEnd() {
    let isFDAEmptyOrNextAfterEnd = false;
    if (
      this.form.get("firstDayAvailable").value === null ||
      moment(this.form.get("leavingEnd").value)
        .add(1, "day")
        .isSame(this.form.get("firstDayAvailable").value)
    ) {
      isFDAEmptyOrNextAfterEnd = true;
    }
    return isFDAEmptyOrNextAfterEnd;
  }

  isHoursGivenInInactiveContractAndAnyAS() {
    let isHoursGivenInInactiveContract = false;
    this.employeeContracts.forEach((leaving: any) => {
      const given = this.form.get(`leaving-${leaving.contractId}`).value;
      if (leaving.contracStatus === ContractStatus.CANCELLED && given > 0) {
        isHoursGivenInInactiveContract = true;
      }
    });
    let showPopup = false;
    if (isHoursGivenInInactiveContract) {
      let isAnyAs = false;
      this.employeeContracts.forEach((leaving: any) => {
        const given = this.form.get(`leaving-${leaving.contractId}`).value;
        if (String(given).toLowerCase() === "as") {
          isAnyAs = true;
        }
      });
      showPopup = isAnyAs ? false : true;
    }
    return showPopup;
  }

  showNDAWarning() {
    this.dialogService.open(QuestionDialogComponent, {
      closeOnBackdropClick: false,
      context: {
        title: "Warning",
        message: `First day avaiable, must by set to next day after end day or empty for TBC.`,
        cancelLabel: "OK",
        hideOkButton: true,
      },
    });
  }

  showASWarning() {
    this.dialogService.open(QuestionDialogComponent, {
      closeOnBackdropClick: false,
      context: {
        title: "Warning",
        message: `You are assigning AL for an inactive contract. This AL will not appear on Rota or the Staff overview.<br>
                  Type AS into the relevant active contracts if you want this AL event to appear on Rota and staff overview.`,
        cancelLabel: "OK",
        hideOkButton: true,
      },
    });
  }

  saveData() {
    if (!this.isFDAEmptyOrNextAfterEnd()) {
      this.showNDAWarning();
    }

    if (this.isHoursGivenInInactiveContractAndAnyAS()) {
      this.showASWarning();
    }

    if (!this.isAnyLeftHoursLessThanGiven()) {
      this.sendData();
      return;
    }

    this.subscription.add(
      this.dialogService
        .open(QuestionDialogComponent, {
          closeOnBackdropClick: false,
          context: {
            title: "Confirmation",
            message: `You will be giving more AL than the employee has accrued. Check policy before confirming.`,
            okLabel: "Confirm",
            cancelLabel: "Cancel",
          },
        })
        .onClose.subscribe((decision: boolean) => {
          if (decision) {
            this.sendData();
          }
        }),
    );
  }

  prepareData() {
    let events = [];
    const eventId = this.form.get("eventType").value;
    const eventTypeName = this.eventTypes.find(
      (x) => x.id === eventId,
    ).wordFullName;
    this.employeeContracts.forEach((leaving: any) => {
      events.push({
        startDate: this.form.get("leavingStart").value,
        endDate: this.form.get("leavingEnd").value,
        firstDayAvailable: this.form.get("firstDayAvailable").value,
        contractId: leaving.contractId,
        typeId: eventId,
        givenHrs: this.form.get(`leaving-${leaving.contractId}`).value,
        description: this.form.get("description").value,
        requiresAcceptance: this.form.get("requiresAcceptance").value,
        addedById: this.authUserService.getOriginalUser().id,
        acceptedById: this.authUserService.getOriginalUser().id,
      });
      events = events.filter((item) => item.givenHrs !== null);
    });
    return {
      employeeId: this.form.get("employeeId").value,
      description: this.form.get("description").value,
      isGivenMoreThanRecommended: this.isGivenMoreThanRecommended,
      eventTypeId: eventId,
      eventStartDate: this.form.get("leavingStart").value,
      eventEndDate: this.form.get("leavingEnd").value,
      firstDayAvailable: this.form.get("firstDayAvailable").value,
      eventType: eventTypeName,
      eventList: events,
    };
  }

  sendData() {
    this.canSave = false;
    const data = this.prepareData();
    let minutes = 0;
    const diffDays = data.eventEndDate.diff(data.eventStartDate, "days") + 1;
    data.eventList.forEach((e, i) => {
      const splittedTime = e.givenHrs.split(":");
      minutes +=
        parseInt(splittedTime[0], 10) * 60 + parseInt(splittedTime[1], 10);
    });
    const dialogRef = this.dialogService
      .open(ConfirmRequestHoursComponent, {
        hasBackdrop: true,
        context: {
          data: {
            hours: `${Math.floor(minutes / 60)}`,
            minutes: `${minutes % 60}`.padStart(2, "0"),
            days:
              diffDays >= 14
                ? `\nThis AL will be ${diffDays} days long.`
                : null,
          },
        },
      })
      .onClose.subscribe((result: "confirm" | null) => {
        if (result && result === "confirm") {
          const eventId = this.form.get("eventType").value;
          const eventTypeName = this.eventTypes.find(
            (x) => x.id === eventId,
          ).wordFullName;
          this.subscription.add(
            this.annualLeavingService
              .addNewLeaving(
                this.form.get("employeeId").value,
                data,
                eventTypeName,
              )
              .subscribe(
                (response: any) => {
                  this.toastrService.success(response.message, "Success");
                  this.tableService.closeWindow(true, 0, this.tableIdentifier);
                },
                (err) => {
                  this.canSave = true;
                  this.toastrService.danger(getErrorMessage(err), "Error", {
                    duration: 60000,
                    destroyByClick: true,
                  });
                },
              ),
          );
        } else {
          this.canSave = true;
        }
      });
  }

  getSum(fieldName: any) {
    let minutesSum = 0;
    this.employeeContracts.forEach((element: any) => {
      minutesSum += getMinutes(element.leaveSettlements[fieldName]);
    });

    if (fieldName === "recommendedHrs") {
      this.recommendedHrsSum = getTime(minutesSum);
      this.recommendedHrsSumMinutes = minutesSum;
    } else if (fieldName === "maxHrs") {
      this.maxHrsCanGive = getTime(minutesSum);
      this.maxHrsCanGiveMinutes = minutesSum;
    }

    return getTime(minutesSum);
  }

  handleCalculateGivenHrs() {
    let minutesSum = 0;
    Object.keys(this.form.controls).forEach((key) => {
      if (key.includes("leaving-")) {
        minutesSum += getMinutes(this.form.get(key).value);
      }
    });

    this.givenHrsSum = getTime(minutesSum);
    this.givenHrsSumMinutes = minutesSum;
    this.checkIfHoursCorrect();
  }

  checkIfHoursCorrect() {
    this.isGivenMoreThanRecommended =
      this.givenHrsSumMinutes > this.recommendedHrsSumMinutes;
    this.isGivenMoreThanMax =
      this.givenHrsSumMinutes > this.maxHrsCanGiveMinutes;
  }

  hideDetails() {
    this.showDetails = !this.showDetails;
    this.buttonDetailsText = this.showDetails
      ? "Hide full year AL"
      : "See full year AL";
  }

  isOnlyASOrNumberInserted(form: UntypedFormGroup) {
    const results = this.contractControlNames.map((name) => {
      const value = form.get(name).value;
      return (
        String(value).toLowerCase() === "as" ||
        XRegExp(
          "^([0-9]|[0-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|250)([:]{1})([0-5]{1})([0123456789]{1})$",
        ).test(value)
      );
    });
    return results.every((value) => value === true);
  }

  isOnly0OrAs(form: UntypedFormGroup) {
    if (this.isOnlyASOrNumberInserted(form)) {
      const results = this.contractControlNames.map((name) => {
        return false;
      });
      return results.every((value) => value === true);
    } else {
      return true;
    }
  }

  showASInfoIfCancelled(status: any) {
    this.showASInfo = status == "canceled";
  }

  closeWindow() {
    this.tableService.closeWindow(false);
    if (
      this.tableService &&
      this.tableService.getValue() &&
      this.tableService.getValue().closeButtonAdditional
    ) {
      this.tableService.getValue().closeButtonAdditional();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  calculateALHours(contract: any) {
    const SLEEPING_OPTIONS = 40;
    const sleepingInterval = contract.careHomeSleepingInterval;
    const sleepingIntervalHours = moment.duration(sleepingInterval).asHours();
    const hoursTable = [];
    hoursTable.push("0:00");
    for (let i = 1; i < SLEEPING_OPTIONS; i++) {
      hoursTable.push(this.getHoursAndMinutes(sleepingIntervalHours * i));
    }

    return hoursTable;
  }

  onRefreshData() {
    this.getCalendarData(true);
  }

  private getHoursAndMinutes(hours: number) {
    const MINUTES_IN_HOUR = 60;
    const minutes = hours * MINUTES_IN_HOUR;
    const hoursFromMinutes = Math.floor(hours);
    const minutesInHour = minutes % 60;
    const hoursString = this.getTimeStringFromNumber(hoursFromMinutes);
    const minutesString = this.getTimeStringFromNumber(minutesInHour);

    return `${hoursString}:${minutesString}`;
  }

  private getTimeStringFromNumber(time: number) {
    let minutesString = "";
    if (time === 0) {
      minutesString = "00";
    } else if (time < 10) {
      minutesString = `0${time}`;
    } else {
      minutesString = time.toString();
    }

    return minutesString;
  }

  private getCalendarData(openAfterLoad = false) {
    this.calendarMessageLeave = "";
    this.calendarMessageShifts = "";
    this.previousReqRange["from"] = this.dateRange.from;
    this.previousReqRange["to"] = this.dateRange.to;

    this.leaveDays =
      moment(this.form.get("leavingEnd").value).diff(
        moment(this.form.get("leavingStart").value),
        "days",
      ) + 1;

    this.dataLoaded = LoadingStatus.NOT_LOADED;
    let loadCount = 2;
    if (this.form.get("employeeId").value === null) {
      return;
    }
    if (this.employeeId) {
      this.subscription.add(
        this.overviewService
          .getDataForOtherEventsForEmployeeWithSorceEmployee(
            this.employeeId,
            this.dateRange.from,
            this.dateRange.to,
            true,
          )
          .subscribe((response: any) => {
            if (!response.result.calendar) {
              this.calendarMessageLeave =
                "There are no other Annual Leave nor Sickness in this period.";
            } else {
              this.calendarData.months = this.transformCallendarData(
                response.result.list,
              );
            }
            loadCount--;
            if (loadCount == 0) {
              this.dataLoaded = LoadingStatus.LOADED;
            }
          }),
      );
      this.subscription.add(
        this.overviewService
          .getDataOfEmployeeSchedule(
            this.employeeId,
            this.dateRange.from,
            this.dateRange.to,
          )
          .subscribe((responseSchedule: any) => {
            if (responseSchedule.result.list.length > 0) {
              this.schedule = responseSchedule.result.list;
              this.scheduleSummary = responseSchedule.result.summaryByRoleList;
              if (this.calendarData.months.length == 0) {
                this.calendarData.months = this.transformScheduleData(
                  responseSchedule.result.list,
                );
              }
            } else {
              this.calendarMessageShifts =
                "There are no shifts planned in this period.";
            }
            loadCount--;
            if (loadCount == 0) {
              this.dataLoaded = LoadingStatus.LOADED;
            }
            if (openAfterLoad) {
              setTimeout(() => {
                this.leaveItem.open();
              }, 200);
            }
          }),
      );
    }
  }

  private transformScheduleData(scheduleList: any[]) {
    const monthsData: { month: string; date: number }[] = [];
    const monthsArray = [];
    const monthsArrayTimestamps = [];
    scheduleList.forEach((schedule) => {
      if (!monthsArray.includes(moment(schedule.date).month())) {
        monthsArray.push(moment(schedule.date).month());
        monthsData.push({
          month: moment(schedule.date).format("MMMM"),
          date: moment(schedule.date).valueOf(),
        });
        monthsArrayTimestamps.push(moment(schedule.date).valueOf());
      }
    });
    return monthsData;
  }

  private transformCallendarData(callendarMonths: any[]) {
    const firstMonth = callendarMonths[0];
    let fmContracts = firstMonth.contracts;
    if (callendarMonths.length > 1) {
      fmContracts = fmContracts.concat(callendarMonths[1].contracts);
      fmContracts = fmContracts.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
      );
    }

    firstMonth.contracts = fmContracts;

    return [firstMonth];
  }

  checkHowManyDaysIncluded(contract): number {
    let start = moment(this.form.get("leavingStart").value);
    let end = moment(this.form.get("leavingEnd").value);
    if (
      this.form.get("leavingStart").value ==
        this.form.get("leavingEnd").value &&
      moment(contract.contractStartDate).isSameOrBefore(start)
    ) {
      return 1;
    }

    if (
      moment(contract.contractStartDate).isSameOrBefore(start) &&
      moment(contract.contractEndDate).isSameOrAfter(end)
    ) {
      return end.diff(start, "days") + 1;
    }
    if (
      moment(contract.contractStartDate).isAfter(start) &&
      moment(contract.contractEndDate).isSameOrAfter(end)
    ) {
      return end.diff(moment(contract.contractStartDate), "days") + 1;
    }
    if (
      moment(contract.contractStartDate).isSameOrBefore(start) &&
      moment(contract.contractEndDate).isBefore(end)
    ) {
      return moment(contract.contractEndDate).diff(start, "days") + 1;
    }
    return 0;
  }

  setHours(contract: any) {
    const workingDaysAWeek = this.careHomeService.workingDays;

    let daysIncluded = this.checkHowManyDaysIncluded(contract);
    if (daysIncluded == 0) {
      return "0:00";
    }

    const contractMinutesPerWeek = contract.contractMinutesPerWeek as number;
    //int division
    const weeks = (daysIncluded / 7) | 0;
    const remainingDays = daysIncluded % 7;
    const remainingPartOfWeek = Math.min(remainingDays / workingDaysAWeek, 1);

    const weeksTotal = weeks + remainingPartOfWeek;

    const totalTime = contractMinutesPerWeek * weeksTotal;
    const hours = Math.floor(totalTime / 60);
    const minutes = totalTime % 60;

    return `${hours}:${this.roundToQuarterHour(minutes)}`;
  }

  explainAnnual() {
    this.dialogService.open(ExplainAnnualLeaveComponent, {
      hasBackdrop: true,
      context: {
        data: {
          employeeName: this.employeeFullName,
          employeeId: this.employeeId,
          explanation: this.explanationAnnualLeave,
          alText: this.alText,
        },
      },
    });
  }

  get parentalLeaveSelected(): boolean {
    return this.parentalLeaveId === this.form.controls.eventType.value;
  }

  get hideColumns(): boolean {
    return (
      this.parentalLeaveId === this.form.controls.eventType.value ||
      this.requestOffId === this.form.controls.eventType.value
    );
  }

  public roundToQuarterHour(minutes: number): string {
    const roundedMinutes = Math.round(minutes / 15) * 15;
    return roundedMinutes.toString().padStart(2, "0");
  }
}

export interface IPreviousReq {
  from: moment.Moment | undefined;
  to: moment.Moment | undefined;
}
