import { Directive, Output, EventEmitter, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[dragDropNoBgFiles]'
})
export class DragDropNoBgFilesDirective {
  @Output() onFileDropped = new EventEmitter<any>();
  @Input() disabled = false;

  @HostBinding('style.background-color') private background = '';
  @HostBinding('style.opacity') private opacity = '1';

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.disabled) {
      this.background = '#ff0000';
      this.opacity = '0.8';
    } else {
      this.background = '#c6e1f5';
      this.opacity = '0.8';
    }
  }
  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '';
    this.opacity = '1';
  }
  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '';
    this.opacity = '1';
    if (!this.disabled) {
      const files = evt.dataTransfer.files;
      if (files.length > 0) {
        this.onFileDropped.emit(files);
      }
    }
  }

}
