import {Component, OnDestroy, OnInit} from "@angular/core";
import {
  getErrorMessage,
  getValidationStatus,
  handleValidationErrorMessage,
  isFormValid,
  validateFormControlsByName,
} from "../../../../utilities/utils";
import {Subscription} from "rxjs";
import {UntypedFormControl, UntypedFormGroup, Validators,} from "@angular/forms";
import {TableService} from "../../../shared/table/table.service";
import {AnnualLeavingService} from "../../../../shared/employess/annual-leaving/annual-leaving.service";
import {NbDialogService, NbToastrService} from "@nebular/theme";
import {invalidDate, numbers} from "../../../../utilities/validators";
import {NewReplacementComponent} from "../../replacement/new-replacement/new-replacement.component";
import {CancelEventDialogComponent} from "../../../shared/cancel-event-dialog/cancel-event-dialog.component";
import {EmployeeElementType} from "../../employee-element-type.enum";
import {QuestionDialogComponent} from "../../../shared/question-dialog/question-dialog.component";
import {eventsErrors} from "../../events-errors";
import * as moment from "moment";
import {FormMode} from "../../../../utilities/form-mode.enum";
import {EmployessService} from "../../../../shared/employess/employess.service";
import {PermissionsService} from "../../../../@core/data/permissions.service";
import {LoadingStatus} from "../../../shared/loading-status.enum";
import {OverviewService} from "../../../../shared/employess/overview/overview.service";

@Component({
  selector: "ngx-edit-annual-leaving",
  templateUrl: "./edit-annual-leaving.component.html",
  styleUrls: ["./edit-annual-leaving.component.scss"],
})
export class EditAnnualLeavingComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public FormMode: typeof FormMode = FormMode;
  public mode: FormMode = FormMode.EDIT;
  public LoadingStatus: typeof LoadingStatus = LoadingStatus;
  public dataLoaded: LoadingStatus = LoadingStatus.NOT_LOADED;
  public errorMessages = eventsErrors;
  public leavingData: any;
  public employeeAnnualLeavings: any = [];
  public employeeId: number;
  public form: UntypedFormGroup;
  public controls = ["employeeId", "eventType", "leavingStart", "leavingEnd"];
  public utils = {
    getValidationStatus,
    handleValidationErrorMessage,
    isFormValid,
    validateFormControlsByName,
  };
  public replacements: any = [];
  public cancelled: boolean;
  public hideShortenLeave: boolean = false;
  public elementId: number;
  private reloadAfterClose: boolean = true;
  public tableIdentifier: string = "";
  public permissions = <any>{};
  public calendarData = {
    startDate: moment(),
    endDate: moment(),
    months: [],
  };
  public dateRange = {
    from: moment().subtract(3, "days"),
    to: moment().add(3, "days"),
  };
  public calendarMessage = "";
  public events = [];
  public roles = [];
  public searchValue: string;
  public additionalInformation: string;
  public isGivenMoreThanRecommended = false;

  public replacementSettingsNotCancelled = {
    actions: {
      add: false,
      edit: false,
      delete: true,
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash" aria-hidden="true"></i>',
    },
    columns: {
      replacementEmployeeNameSurname: {
        title: "Full Name",
        filter: false,
        sort: true,
      },
      replacementFrom: {
        title: "Start",
        filter: false,
        sort: true,
      },
      replacementTo: {
        title: "End",
        filter: false,
        sort: true,
      },
      replacementDailyHrs: {
        title: "Replacement Daily Hrs",
        filter: false,
        sort: true,
      },
      replacementComments: {
        title: "Comments",
        filter: false,
        sort: true,
      },
    },
  };
  public replacementSettingsCancelled = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    columns: {
      replacementEmployeeNameSurname: {
        title: "Full Name",
        filter: false,
        sort: true,
      },
      replacementFrom: {
        title: "Start",
        filter: false,
        sort: true,
      },
      replacementTo: {
        title: "End",
        filter: false,
        sort: true,
      },
      replacementDailyHrs: {
        title: "Replacement Daily Hrs",
        filter: false,
        sort: true,
      },
      replacementComments: {
        title: "Comments",
        filter: false,
        sort: true,
      },
    },
  };

  private daysDuration = 0;

  loadReplacements = () => this.replacements;

  addReplacement = () => {
    this.tableService.openWindowWithBackStack(
      NewReplacementComponent,
      "Add new replacement",
      {
        component: EditAnnualLeavingComponent,
        title: `Event information - ${this.leavingData.eventNo}`,
        elementId: this.elementId,
      },
      {
        source: EmployeeElementType.AL,
        employeeId: this.employeeId,
        eventNo: this.leavingData.eventNo,
        eventId: this.elementId,
        contractId: this.leavingData.contractId,
        contractRoleId: this.leavingData.contractRoleId,
      },
    );
  };

  deleteReplacement = (data: any) => {
    this.subscription.add(
      this.annualLeavingService
        .deleteReplacement(data.data.replacementId)
        .subscribe(
          (response: any) => {
            data.confirm.resolve();
            this.toastrService.success(response.message, "Success");
          },
          (err) =>
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            }),
        ),
    );
  };

  constructor(
    private tableService: TableService,
    private annualLeavingService: AnnualLeavingService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private employeesService: EmployessService,
    private permissionsService: PermissionsService,
    private overviewService: OverviewService,
  ) {
    this.permissions = this.permissionsService.getPermissions();
  }

  ngOnInit() {
    this.elementId = this.tableService.getValue().elementId;
    this.mode = this.tableService.getValue().mode;
    this.reloadAfterClose =
      this.tableService.getValue().reloadAfterClose != null
        ? this.tableService.getValue().reloadAfterClose
        : true;

    this.tableIdentifier = this.tableService.getValue().tableIdentifier || '';

    this.createForm();

    this.loadData();

    this.subscription.add(
      this.tableService.refresh$.subscribe(() => {
        this.loadData();
      }),
    );
    if (this.mode === FormMode.DECIDE) {
      this.form.get("requiresAcceptance").enable();
    }
  }

  createForm() {
    this.form = new UntypedFormGroup({
      employeeName: new UntypedFormControl({ value: "", disabled: true }),
      eventType: new UntypedFormControl({ value: "", disabled: true }),
      leavingStart: new UntypedFormControl({ value: "", disabled: true }, [
        Validators.required,
        invalidDate,
      ]),
      leavingEnd: new UntypedFormControl({ value: "", disabled: true }, [
        Validators.required,
        invalidDate,
      ]),
      firstDayAvailable: new UntypedFormControl({
        value: null,
        disabled: this.cancelled,
      }),
      given: new UntypedFormControl(null, [numbers]),
      description: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(5000),
      ]),
      requiresAcceptance: new UntypedFormControl({
        value: null,
        disabled: true,
      }),
      cancellationDate: new UntypedFormControl({ value: "", disabled: true }),
      cancellationDescription: new UntypedFormControl({
        value: "",
        disabled: true,
      }),
    });
  }

  public loadData() {
    this.subscription.add(
      this.annualLeavingService
        .getAnnualLeavingById(this.elementId)
        .subscribe((response: any) => {
          this.leavingData = response.result;
          this.employeeId = response.result.employeeId;
          if (this.leavingData.cancellationDate) {
            this.cancelled = true;
          }

          if (
            this.leavingData.statusName === "canceled" ||
            this.leavingData.statusName === "rejected"
          ) {
            this.hideShortenLeave = true;
          }

          this.daysDuration =
            moment(this.leavingData.eventEndDate).diff(
              moment(this.leavingData.eventStartDate),
              "days",
            ) + 1;
          this.patchValues(response.result, true);
        }),
    );
  }

  patchValues(data: any, onInit: boolean = false) {
    this.dateRange.from = moment(data.eventStartDate).subtract(3, "days");
    this.dateRange.to = moment(data.eventEndDate).add(3, "days");
    this.getCalendarData();
    this.additionalInformation = "";
    this.isGivenMoreThanRecommended = data.isGivenMoreThanRecommended;
    this.form.patchValue({
      employeeId: this.employeeId,
      employeeName: `${data.employeeFirstName} ${data.employeeSurname}`,
      leavingStart: data.eventStartDate,
      leavingEnd: data.eventEndDate,
      firstDayAvailable: data.firstDayAvailable,
      eventType: data.eventTypeName,
      description: data.description,
      requiresAcceptance: data.requiresAcceptance,
      cancellationDate: data.cancellationDate,
      cancellationDescription: data.cancellationDescription,
    });

    if (data.firstDayAvailable) {
      this.form.get("firstDayAvailable").disable();
    }

    if (!onInit) {
      this.subscription.add(
        this.annualLeavingService
          .getReplacements(this.elementId)
          .subscribe((response: any) => {
            this.replacements = response.result.replacementList;
          }),
      );
    }

    this.form.get("firstDayAvailable").valueChanges.subscribe((value: any) => {
      if (
        moment(this.form.get("leavingEnd").value).isSameOrAfter(
          moment(value),
          "date",
        )
      ) {
        this.form
          .get("firstDayAvailable")
          .setErrors({ dateGreaterOrEqualThan: true });
      } else if (!this.isFDAEmptyOrNextAfterEnd()) {
        this.form
          .get("firstDayAvailable")
          .setErrors({ dateFDAEmptyOrNextAfterEnd: true });
      } else {
        this.form.get("firstDayAvailable").setErrors(null);
        if (value && !this.form.get("firstDayAvailable").disabled) {
          this.form.get("firstDayAvailable").disable();
        }
      }
    });
  }

  clearFirstDayAvailable() {
    this.form.patchValue({ firstDayAvailable: null });
    this.form.get("firstDayAvailable").enable();
  }

  isFDAEmptyOrNextAfterEnd() {
    let isFDAEmptyOrNextAfterEnd = false;
    if (
      this.form.get("firstDayAvailable").value === null ||
      moment(this.form.get("leavingEnd").value)
        .add(1, "day")
        .isSame(this.form.get("firstDayAvailable").value)
    ) {
      isFDAEmptyOrNextAfterEnd = true;
    }
    return isFDAEmptyOrNextAfterEnd;
  }

  saveData() {
    const data = {
      acceptanceDate: new Date().toISOString(),
      firstDayAvailable: this.form.get("firstDayAvailable").value,
      acceptedById: this.employeeId,
      requiresAcceptance: this.form.get("requiresAcceptance").value,
      description: this.form.get("description").value,
      isGivenMoreThanRecommended: this.isGivenMoreThanRecommended,
    };
    this.subscription.add(
      this.annualLeavingService.updateLeaving(this.elementId, data).subscribe(
        (response: any) => {
          this.toastrService.success(response.message, "Success");
          this.tableService.closeWindow(this.reloadAfterClose, 0, this.tableIdentifier);
        },
        (err) =>
          this.toastrService.danger(getErrorMessage(err), "Error", {
            duration: 60000,
            destroyByClick: true,
          }),
      ),
    );
  }

  cancelEvent() {
    this.tableService.openWindowWithBackStack(
      CancelEventDialogComponent,
      "Shorten leave",
      {
        component: EditAnnualLeavingComponent,
        title: `Event information - ${this.tableService.getValue().eventNo}`,
        elementId: this.elementId,
      },
      {
        type: EmployeeElementType.AL,
        elementId: this.elementId,
        startDate: this.form.get("leavingStart").value,
        endDate: this.form.get("leavingEnd").value,
      },
    );
  }

  deleteEvent() {
    this.subscription.add(
      this.dialogService
        .open(QuestionDialogComponent, {
          closeOnBackdropClick: false,
          context: {
            title: "Delete confirmation",
            message: "Are you sure you want to delete? It cannot be undone.",
            deleteInput: true,
          },
        })
        .onClose.subscribe((decision: boolean) => {
          if (decision) {
            this.subscription.add(
              this.annualLeavingService.deleteLeaving(this.elementId).subscribe(
                (response: any) => {
                  this.toastrService.success(response.message, "Success");
                  this.tableService.closeWindow(true, 0, this.tableIdentifier);
                },
                (err) => {
                  this.toastrService.danger(getErrorMessage(err), "Error", {
                    duration: 60000,
                    destroyByClick: true,
                  });
                },
              ),
            );
          }
        }),
    );
  }

  acceptEvent() {
    if (this.daysDuration > 14) {
      this.subscription.add(
        this.dialogService
          .open(QuestionDialogComponent, {
            context: {
              title: "Accept AL",
              message: `Are you sure to accept AL for ${this.daysDuration} days?`,
              okLabel: "Yes",
              cancelLabel: "No",
            },
          })
          .onClose.subscribe((decision: boolean) => {
            if (decision) {
              this.accept();
            }
          }),
      );
    } else {
      this.accept();
    }
  }

  accept() {
    const data = {
      acceptanceDate: new Date().toISOString(),
      firstDayAvailable: this.form.get("firstDayAvailable").value,
      acceptedById: this.employeeId,
      requiresAcceptance: false,
      description: this.form.get("description").value,
    };
    this.subscription.add(
      this.annualLeavingService.acceptEvent(this.elementId, data).subscribe(
        (response: any) => {
          this.toastrService.success(response.message, "Success");
          this.tableService.closeWindow(true, 0, this.tableIdentifier);
        },
        (err) =>
          this.toastrService.danger(getErrorMessage(err), "Error", {
            duration: 60000,
            destroyByClick: true,
          }),
      ),
    );
  }

  rejectEvent() {
    this.subscription.add(
      this.dialogService
        .open(QuestionDialogComponent, {
          closeOnBackdropClick: false,
          context: {
            title: "Explain your rejection reason",
            message:
              "Explain why you are rejecting this AL. This will be Echo messaged to the employee too",
            messageInput: true,
            messagePlaceholder: "Explain why you are rejecting this AL.",
            panelClass: "reject-dialog",
          },
        })
        .onClose.subscribe((decision: any) => {
          if (decision && decision.isConfirm) {
            const data = {
              cancellationDate: new Date().toISOString(),
              cancellationDescription: decision.input,
            };
            this.subscription.add(
              this.employeesService.cancelEvent(this.elementId, data).subscribe(
                (response: any) => {
                  this.toastrService.success(response.message, "Success");
                  this.tableService.closeWindow(true, 0, this.tableIdentifier);
                },
                (err) => {
                  this.toastrService.danger(getErrorMessage(err), "Error", {
                    duration: 60000,
                    destroyByClick: true,
                  });
                },
              ),
            );
          }
        }),
    );
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private getCalendarData() {
    this.calendarMessage = "";
    this.dataLoaded = LoadingStatus.IN_PROGRESS;
    this.subscription.add(
      this.overviewService
        .getDataForOtherEvents(this.dateRange.from, this.dateRange.to, true)
        .subscribe((response: any) => {
          if (!response.result.calendar) {
            this.calendarMessage =
              "There are no others Annual Leave nor Sickness in this period.";
          } else {
            this.calendarData.months = this.transformCallendarData(
              response.result.list,
            );
          }
          this.dataLoaded = LoadingStatus.LOADED;
        }),
    );
  }

  private transformCallendarData(callendarMonths: any[]) {
    const firstMonth = callendarMonths[0];
    let fmContracts = firstMonth.contracts;
    if (callendarMonths.length > 1) {
      fmContracts = fmContracts.concat(callendarMonths[1].contracts);
      fmContracts = fmContracts.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
      );
    }

    firstMonth.contracts = fmContracts;

    return [firstMonth];
  }
}
