import { Component, Input, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { isFormValid } from "../../../utilities/utils";

export enum QuestionDecisionTypeEnum {
  FIRST = "first",
  SECOND = "second",
}

@Component({
  selector: "ngx-question-dialog",
  templateUrl: "./question-dialog.component.html",
  styleUrls: ["./question-dialog.component.scss"],
})
export class QuestionDialogComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() okLabel: string = "OK";
  @Input() secondOkLabel: string = "";
  @Input() cancelLabel: string = "Cancel";
  @Input() deleteInput: boolean = false;
  @Input() deleteLabel: string = "Type DELETE to delete this record:";
  @Input() deleteCheckValue: string = "DELETE";
  @Input() parameterInput: boolean = false;
  @Input() messageInput: boolean = false;
  @Input() messagePlaceholder: string = "description";
  @Input() hideOkButton: boolean = false;
  @Input() hideSecondOkButton: boolean = true;
  @Input() panelClass: string = "";
  @Input() autoAddEmailRecipients: any = "";
  @Input() passwordInput: boolean = false;
  @Input() hideCancelButton: boolean = false;
  @Input() hasRecurringTasks: boolean = false;
  @Input() recurringTasks: { name: string, checked: boolean, taskName: string}[] = [];
  public form: UntypedFormGroup;
  public radioGroupValue = 10;

  public utils = { isFormValid };

  options = [
    { value: "10", label: "Small", checked: true },
    { value: "12", label: "Medium" },
    { value: "14", label: "Big" },
  ];

  constructor(protected ref: NbDialogRef<QuestionDialogComponent>) {}

  ngOnInit() {
    if (this.messageInput) {
      this.form = new UntypedFormGroup({
        messageInputValue: new UntypedFormControl(null, [Validators.required]),
      });
    } else if (this.passwordInput) {
      this.form = new UntypedFormGroup({
        password: new UntypedFormControl('', [Validators.required]),
      });
    } else {
      this.form = new UntypedFormGroup({
        confirmation: new UntypedFormControl(null, [Validators.required]),
        parameter: new UntypedFormControl(10),
      });
    }

    this.radioGroupValue = 10;
  }

  cancel() {
    this.ref.close(false);
  }

  secondOk() {
    if (!this.hideSecondOkButton) {
      this.ref.close({
        isConfirm: true,
        type: QuestionDecisionTypeEnum.SECOND,
      });
    } else {
      this.ref.close(false);
    }
  }

  ok() {
    if (!this.hideSecondOkButton) {
      this.ref.close({
        isConfirm: true,
        type: QuestionDecisionTypeEnum.FIRST,
      });
    } else {
      if (this.parameterInput) {
        this.ref.close({
          isConfirm: true,
          input: this.radioGroupValue,
        });
      } else if (this.messageInput) {
        this.ref.close({
          isConfirm: true,
          input: this.form.get("messageInputValue").value,
        });
      } else if (this.passwordInput) {
        this.ref.close({
          isConfirm: true,
          input: this.form.get('password').value,
        });
      } else {
        this.ref.close(true);
      }
    }
  }

  isDeleteEntered(form: UntypedFormGroup) {
    return form.get("confirmation").value?.trim().toUpperCase() === this.deleteCheckValue;
  }

  allRecurringTasksAreChecked(): boolean {
    return this.recurringTasks.every(task => task.checked);
  }
}
