import { ApplicationPermissions } from "../@core/data/application-permissions.enum";
import { TaskboardPermissions } from "./pages.component";

export const keyReportsMenu = [
  {
    title: "Apex",
    link: "/dashboard/admin-analyzer-dashboard",
    permission: ApplicationPermissions.MENU_APEX,
  },
  {
    title: "Group HR Calendar",
    link: "/dashboard/manager-overview",
    permission: ApplicationPermissions.MENU_MANAGER_HR_CALENDAR,
  },
  {
    title: "Weekly updates",
    link: "/task-management/manager-boards-summary-dashboard",
    permission: ApplicationPermissions.WEEKLY_REPORT_GROUP_MANAGER,
  },
  {
    title: "Task Archive",
    link: "/dashboard/all-tasks-dashboard",
    permission: ApplicationPermissions.MENU_WU_PAST_TASKS,
  },
  {
    title: "Other Staff Reports",
    children: [
      {
        title: "Annual Leave Report",
        link: "/employess/annual-leaving",
        permission: ApplicationPermissions.MENU_ANNUAL_LEAVES,
      },
      {
        title: "Agency report",
        link: "/dashboard/agency-report",
        permission: ApplicationPermissions.MENU_ROTA,
      },
      {
        title: "Leavers Report",
        link: "/employess/leavers-report",
        permission: ApplicationPermissions.MENU_LEAVERS_REPORT,
      },
      {
        title: "Sickness List Report",
        link: "/employess/sickness",
        permission: ApplicationPermissions.MENU_SICKNESS,
      },
      {
        title: "Sickness analysis",
        link: "/employess/sickness-analysis",
        permission: ApplicationPermissions.MENU_SICKNESS,
      },
      {
        title: "Sick / AL to accept",
        link: "/dashboard/events-to-accept-dashboard",
        permission: ApplicationPermissions.MENU_AL_ACCEPTANCE,
      },
      {
        title: "Completed courses",
        link: "/dashboard/completed-courses-dashboard",
        permission: ApplicationPermissions.MENU_COURSES,
      },
    ],
  },

  {
    title: "Other Administrative Reports",
    children: [
      {
        title: "Downloadable only",
        link: "/dashboard/report-desktop",
        permission: ApplicationPermissions.MENU_ROTA,
      },
      {
        title: "Finance report",
        link: "/dashboard/finance-report-dashboard",
        permission: ApplicationPermissions.MENU_RESIDENT_FINANCE_REPORT,
      },
    ],
  },
];

export const employeesMenu = [
  {
    title: "Employee List",
    link: "/employess/list",
    permission: ApplicationPermissions.MENU_EMPLOYEES,
  },
  {
    title: "Rota Master Table",
    link: "/employess/rota",
    permission: ApplicationPermissions.MENU_ROTA,
  },
  {
    title: "Payroll Adjustments",
    link: "/employess/payroll",
    permission: ApplicationPermissions.MENU_PAYROLL_ADJUSTMENTS,
  },
  {
    title: "Timesheets",
    link: "/employess/day-shifts",
    permission: ApplicationPermissions.SHIFT_CONFIRMATION_CONFIRMATION_ABILITY,
  },
  {
    title: "Awards",
    link: "/employess/awards",
    permission: ApplicationPermissions.MENU_AWARDS,
    // permission: ApplicationPermissions.MENU_LEAVERS_REPORT,
  },
  {
    title: "HR Calendar",
    link: "/employess/overview",
    permission: ApplicationPermissions.MENU_HR_CALENDAR,
  },
  {
    title: "Staff Hours Report",
    link: "/employess/staff-hours",
    permission: ApplicationPermissions.MENU_STAFF_HOURS,
  },
  {
    title: "Training matrix",
    link: "/employess/courses-overview",
    permission: ApplicationPermissions.MENU_COURSES,
  },
];

export const residentsMenu = [
  {
    title: "List",
    link: "/residents/list",
    permission: ApplicationPermissions.MENU_RESIDENTS,
  },
  {
    title: "Medication orders",
    link: "/residents/medical-orders",
    permission: ApplicationPermissions.MENU_MEDICAL_ORDERS,
    children: [
      {
        title: "List",
        link: "/residents/medical-orders",
        permission: ApplicationPermissions.MENU_MEDICAL_ORDERS,
      },
      {
        title: "Pharmacies",
        link: "/residents/pharmacies",
        permission: ApplicationPermissions.MENU_PHARMACIES,
      },
      {
        title: "General Practices",
        link: "/residents/general-practice",
        permission: ApplicationPermissions.MENU_GENERAL_PRACITCES,
      },
    ],
  },
  {
    title: "Evacuation info",
    link: "/residents/evacuation-list",
    permission: ApplicationPermissions.MENU_RESIDENTS,
  },
  {
    title: "Diet and allergies",
    link: "/residents/food-preferences",
    permission: ApplicationPermissions.MENU_RESIDENTS,
  },
  {
    title: "Menu choices",
    link: "/residents/menu-choices",
    permission: ApplicationPermissions.MENU_MENU_CHOICES,
  },
  {
    title: "Activity sheet",
    link: "/residents/activity-sheet",
    permission: ApplicationPermissions.MENU_ACTIVITY_SHEET,
  },
];

export const taskManagementMenu = [
  {
    title: "Report Summary",
    link: "/task-management/boards-summary",
    permission: ApplicationPermissions.WEEKLY_REPORT_MANAGER,
  },
  {
    title: "Resident Taskboard",
    link: "/task-management/custom-task-boards/resident-taskboard",
    taskboardPermission: TaskboardPermissions.RESIDENT_TASKBOARD,
  },
  {
    title: "Community Tracker",
    link: "/task-management/custom-task-boards/community-tracker",
    taskboardPermission: TaskboardPermissions.COMMUNITY_TRACKER,
  },
  {
    title: "Menu Changes",
    link: "/task-management/custom-task-boards/menu-changes",
    taskboardPermission: TaskboardPermissions.MENU_CHANGES,
  },
  {
    title: "Room Enquiries",
    link: "/task-management/custom-task-boards/room-enquiries",
    taskboardPermission: TaskboardPermissions.ROOM_ENQUIRES,
  },
  {
    title: "Finance Taskboard",
    link: "/task-management/custom-task-boards/finance-taskboard",
    taskboardPermission: TaskboardPermissions.FINANCE_TASKBOARD,
  },
  {
    title: "Assigned tasks",
    link: "/task-management/assigned-tasks",
    permission: ApplicationPermissions.TASK_COLLABORATION_ASSIGNMENT,
  },
  {
    title: "Automatic Taskboard",
    link: "/task-management/custom-task-boards/auto-tasks-taskboard",
    taskboardPermission: TaskboardPermissions.AUTOMATIC_TASKBOARD,
  },
];

export const administrationMenu = [
  {
    title: "Matrix Control Table",
    link: "/administration/courses-matrix",
    permission: ApplicationPermissions.MENU_MATRIX_CONTROL_TABLE,
  },
  {
    title: "Rota Hours Schema",
    link: "/administration/courses-hours",
    permission: ApplicationPermissions.MENU_ROTA_HOURS_SCHEMA,
  },
  {
    title: "Rota Hours Schema New",
    link: "/administration/rota-hours",
    permission: ApplicationPermissions.MENU_ROTA_HOURS_SCHEMA_NEW,
  },
  {
    title: "Items Config",
    link: "/stuff-config",
    permission: ApplicationPermissions.MENU_ROTA_HOURS_SCHEMA,
  },
];

export const rightMenu = [
  {
    title: "Manage care homes",
    icon: "home-outline",
    link: "/care-homes",
  },
  {
    title: "Rota conflicts",
    icon: "alert-triangle-outline",
    link: "/administration/rota-conflicts",
    permission: ApplicationPermissions.ROTA_CONFLICT_SOLVING,
  },
  {
    title: "Tracked Events",
    icon: "grid-outline",
    link: "/task-management/tracked-events",
  },
  {
    title: "Holidays",
    icon: "sun-outline",
    link: "/administration/holidays",
  },
  {
    title: "Remote help",
    icon: "question-mark-circle-outline",
    link: "/remote-help",
  },
  {
    title: "Users",
    icon: "person-outline",
    children: [
      {
        title: "Manage users",
        icon: "person-done-outline",
        link: "/users",
      },
      {
        title: "Users report",
        icon: "printer-outline",
        link: "/users-report",
      },
    ],
  },
  {
    title: "Tasks",
    icon: "layers-outline",
    children: [
      {
        title: "Taskboards",
        icon: "list-outline",
        link: "/task-management/task-boards",
      },
      {
        title: "Automatic tasks",
        icon: "grid-outline",
        link: "/task-management/automatic-tasks-managment",
      },
      {
        title: "Groups",
        icon: "people-outline",
        link: "/administration/groups",
      },
      {
        title: "RAGG",
        icon: "log-in-outline",
        link: "/administration/ragg",
      },
      {
        title: "Task categories",
        icon: "book-open-outline",
        link: "/administration/task-categories",
      },
      {
        title: "Task's tags",
        icon: "hash-outline",
        link: "/administration/task-tags",
      },
      {
        title: "Task's templates",
        icon: "file-text-outline",
        link: "/task-management/task-templates",
      },
    ],
  },
  {
    title: "Messages",
    icon: "email-outline",
    children: [
      {
        title: "Message groups",
        icon: "email-outline",
        link: "/administration/message-groups",
      },
      {
        title: "Message templates",
        icon: "email-outline",
        link: "/administration/message-templates",
      },
    ],
  },
  {
    title: "System log",
    icon: "lock-outline",
    children: [
      {
        title: "Events",
        icon: "lock-outline",
        link: "/administration/events",
      },
      {
        title: "Email test",
        icon: "email-outline",
        link: "/administration/email-test",
      },
      {
        title: "Deadline emails report",
        icon: "email-outline",
        link: "/administration/deadline-emails",
      },
    ],
  },
  {
    title: "Configuration",
    icon: "settings-outline",
    children: [
      {
        title: "Taskboard groups",
        icon: "settings-outline",
        link: "/administration/configuration",
      },
      {
        title: "Care Homes order",
        icon: "home-outline",
        link: "/administration/care-homes-order-configuration",
      },
    ],
  },
  {
    title: "Permissions",
    icon: "options-outline",
    children: [
      {
        title: "Profiles manager",
        icon: "options-outline",
        link: "/administration/profiles-manager",
      },
      {
        title: "CB Permissions",
        icon: "options-outline",
        link: "/administration/contact-book-permissions",
      },
    ],
  },
];
