import { Injectable, OnDestroy, TemplateRef } from "@angular/core";
import { Subject, BehaviorSubject, Observable, fromEvent } from "rxjs";
import { ScheduleDayStatus } from "./schedule-day-status.enum";
import {
  NbWindowRef,
  NbComponentType,
  NbWindowService,
  NbToastrService,
} from "@nebular/theme";
import { CellErrorType } from "./cell-error-type.enum";
import { IRotaSelectedAdditionalInfo } from "./rota-table-week/interfaces/rota-selected-additional-info.interfaces";
import { DictionariesAgenciesModel } from "../../../shared/models/dictionaries/agencies/dictionaries-agencies-model";
import { DictionariesService } from "../../../shared/dictionaries/dictionaries.service";
import { catchError, takeUntil, tap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { getErrorMessage } from "../../../utilities/utils";
import { ICareHome } from "../../shared/interfaces/care-home.interfaces";

@Injectable({
  providedIn: "root",
})
export class RotaTableService implements OnDestroy {
  public editableComponents: any = {};
  public selectableComponents: any = {};
  public roles: any = [];
  public hoursTemplate: any;
  public actualEditableRow = "";
  public errorType: CellErrorType = CellErrorType.NONE;

  private editModeSource = new Subject<{
    editMode: boolean;
    rowIdentifier: string;
    save?: boolean;
  }>();
  public editMode$ = this.editModeSource.asObservable();

  public confirmMode$ = new BehaviorSubject<boolean | "blend">("blend");
  public switcherReset$ = new Subject();
  public reload$ = new Subject<string>();
  public dates$ = new Subject();
  public getConfirmationDate$ = new Subject<boolean>();
  public isConfirm$ = new BehaviorSubject<boolean | "blend">("blend");
  public discard$ = new Subject();
  public rotaStatOpened$ = new BehaviorSubject<any>({});
  private windowRef: NbWindowRef;
  public rotaHaveChanges: boolean = false;
  public forceScheduleMode: boolean = false;
  // not used beacuse new popover in rota implemented
  public isChangeStatRowOpenField$ = new BehaviorSubject<string>("");
  public isAdditionalInfoWasChanged: boolean;
  public isSelectedDayMode: boolean;
  public selectedAdditionalData: [IRotaSelectedAdditionalInfo[]] = [[]];
  public isWindowOpen: boolean;
  public openRotaHoursDropdown = new Subject<string>();

  public openDuplicateRota = new Subject<boolean>();

  public confirmSelectedCells = new Subject<string[]>();
  public clearSelectedCells = new Subject<string[]>();
  public clearSelectedScheduledCells = new Subject<string[]>();

  public cellOptionsSubject = new Subject<{
    weekIdentifier?: string;
    fieldName?: string;
    rowIdentifier?: string;
    paste?: boolean;
    deselectCells?: boolean;
  }>();

  public looseFocus = fromEvent(document, "click");

  public copiedHours = new BehaviorSubject<{ from: string; to: string } | null>(
    null,
  );

  public selectedList = [];

  private _agencies$: BehaviorSubject<DictionariesAgenciesModel> =
    new BehaviorSubject<DictionariesAgenciesModel>(null);

  constructor(
    private windowService: NbWindowService,
    private _dictionariesApiService: DictionariesService,
    private _toastrService: NbToastrService,
  ) {
    this.looseFocus.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      this.cellOptionsSubject.next({ deselectCells: true });
      this.selectedList = [];
    });
  }

  private destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get agencies$(): Observable<DictionariesAgenciesModel> {
    return this._agencies$.asObservable();
  }

  public setAgencies(value: DictionariesAgenciesModel): void {
    this._agencies$.next(value);
  }

  resetService() {
    this.rotaHaveChanges = false;
    this.forceScheduleMode = false;
    this.selectableComponents = {};
    this.editableComponents = {};
  }

  setStatCellOpened(
    careHomeId: number,
    roleId: any,
    dayDate: any,
    open: boolean,
  ) {
    this.rotaStatOpened$.next({
      careHomeId,
      roleId,
      dayDate,
      open,
    });
  }

  changeToConfirmMode(confirmMode: boolean | "blend") {
    this.confirmMode$.next(confirmMode);
  }

  discardChanges(editMode: boolean, rowIdentifier: string) {
    this.editModeSource.next({
      editMode: editMode,
      rowIdentifier: rowIdentifier,
      save: false,
    });
  }

  getSwitcherReset() {
    return this.switcherReset$.asObservable();
  }

  setSwitcherReset() {
    this.switcherReset$.next("");
  }

  changeEditMode(editMode: boolean, rowIdentifier: string) {
    this.editModeSource.next({
      editMode: editMode,
      rowIdentifier: rowIdentifier,
    });
  }

  saveData(rowIdentifier: string) {
    this.editModeSource.next({
      editMode: false,
      rowIdentifier: rowIdentifier,
      save: true,
    });
  }

  // ------------ EDITING ------------------------------
  intitRowComponents(rowIdentifier: string) {
    this.editableComponents[rowIdentifier] = new Set();
  }

  addToRowComponents(rowIdentifier: string, cellIdentifier: string) {
    this.editableComponents[rowIdentifier].add(cellIdentifier);
  }

  removeFromRowComponents(rowIdentifier: string, cellIdentifier: string) {
    this.editableComponents[rowIdentifier].delete(cellIdentifier);
  }

  getRowComponentsSize(rowIdentifier: string) {
    return this.editableComponents[rowIdentifier].size;
  }
  // ------------ EDITING ------------------------------

  // ------------ SELECTING ------------------------------
  initSelectableWeek(weekIdentifier: string) {
    this.selectableComponents[weekIdentifier] = {};
  }

  initSelectableRow(
    weekIdentifier: string,
    rowIdentifier: string,
    contract: any,
  ) {
    this.selectableComponents[weekIdentifier][rowIdentifier] = contract;
  }

  changeCellSelection(
    weekIdentifier: string,
    rowIdentifier: string,
    fieldName: string,
    day: any,
  ) {
    this.selectableComponents[weekIdentifier][rowIdentifier][fieldName] = day;
  }

  getSelected() {
    const days = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];
    const selected = {};
    const tableIdentifiers = [];
    // tslint:disable-next-line:forin
    for (const weekIdentifier in this.selectableComponents) {
      // tslint:disable-next-line:forin
      for (const rowIdentifier in this.selectableComponents[weekIdentifier]) {
        days.forEach((day: any) => {
          if (
            this.selectableComponents[weekIdentifier][rowIdentifier][day]
              .selected
          ) {
            this.selectedAdditionalData.forEach((item) => {
              const changedCareHome = item.find((x) => x.dayName === day);
              if (changedCareHome) {
                const selectedCareHome = changedCareHome.value as ICareHome;
                this.selectableComponents[weekIdentifier][rowIdentifier][
                  day
                ].careHomeId = selectedCareHome.careHomeId;
                this.selectableComponents[weekIdentifier][rowIdentifier][
                  day
                ].careHomeName = selectedCareHome.careHomeFullName;
              }
            });
            selected[rowIdentifier] =
              this.selectableComponents[weekIdentifier][rowIdentifier];

            const tableIdentifier =
              this.selectableComponents[weekIdentifier][rowIdentifier]
                .tableIdentifier;

            if (tableIdentifiers.indexOf(tableIdentifier) === -1) {
              tableIdentifiers.push(tableIdentifier);
            }
          }
        });
      }
    }

    return {
      values: selected,
      tableIdentifiers: tableIdentifiers,
    };
  }

  // do after confirm selected cells to avoid repeat same element in selectableComponents
  resetSelectableWeeks() {
    for (const weekIdentifier in this.selectableComponents) {
      this.selectableComponents[weekIdentifier] = {};
    }
  }

  selectByRole(
    weekIdentifier: string,
    dayName: string,
    selectedRoles: any = [],
  ) {
    for (const rowIdentifier in this.selectableComponents[weekIdentifier]) {
      for (const role of selectedRoles) {
        const row = this.selectableComponents[weekIdentifier][rowIdentifier];
        if (
          row.positionId === role.id &&
          row[dayName].statusId === ScheduleDayStatus.NOT_CONFIRMED &&
          row[dayName].allowEditing
        ) {
          row[dayName].selected = true;
        }
      }
    }
  }

  removeSelectedByRole(weekIdentifier: string, dayName: string) {
    for (const rowIdentifier in this.selectableComponents[weekIdentifier]) {
      this.selectableComponents[weekIdentifier][rowIdentifier][
        dayName
      ].selected = false;
    }
  }

  // ------------ SELECTING ------------------------------

  openWindow(
    windowContent: TemplateRef<any> | NbComponentType,
    title: string,
    parameter: any = {},
    windowClass?: string,
    backdrop: boolean = true,
  ) {
    if (this.isWindowOpen && this.windowRef) this.windowRef.close();

    const config = {
      closeOnBackdropClick: false,
      closeOnEsc: false,
      hasBackdrop: backdrop,
      title: title,
      context: parameter,
      windowClass: windowClass ? windowClass : "",
    };
    this.isWindowOpen = true;
    this.windowRef = this.windowService.open(windowContent, config);
  }

  closeWindow(tableIdentifier?: string) {
    this.isWindowOpen = false;
    this.windowRef.close();
    if (tableIdentifier) {
      this.reload$.next(tableIdentifier);
    }
  }

  public loadAgencies(careHomeId: number): void {
    this._dictionariesApiService
      .getAgenciesDictionary(careHomeId)
      .pipe(
        tap((response) => this.setAgencies(response)),
        catchError((err: HttpErrorResponse) => {
          this._toastrService.danger(getErrorMessage(err), "Error", {
            duration: 60000,
            destroyByClick: true,
          });
          throw err;
        }),
      )
      .subscribe();
  }

  generateId() {
    return (
      Math.random().toString(36).substring(2) +
      new Date().getTime().toString(36)
    );
  }

  confirmSelected() {
    this.confirmSelectedCells.next(this.selectedList);
  }

  clearSelected() {
    this.clearSelectedCells.next(this.selectedList);
  }

  pasteSelected() {
    this.selectedList.forEach((item) => {
      const keys = item.split("-");

      this.cellOptionsSubject.next({
        weekIdentifier: keys[0],
        rowIdentifier: keys[1],
        fieldName: keys[2],
        paste: true,
      });
    });

    this.selectedList = [];
  }

  clearSelectedScheduled() {
    this.clearSelectedScheduledCells.next(this.selectedList);
  }
}
