import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { AuthUserService } from '../../@core/data/auth-user.service';

@Injectable({
  providedIn: 'root'
})
export class InboxService {
  constructor(private httpClient: HttpClient) {
  }

  getMessageTemplates(careHomeIdId: number) {
    return this.httpClient.get(`${environment.apiUrl}/messages/messages-templates?careHomeId=${careHomeIdId}`);
  }

  getCategories(userId: number, careHomeId: any) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userId}/mailboxes?careHomeId=${careHomeId}`);
  }

  getFile(blobId: any) {
    return this.httpClient.get(`${environment.apiUrl}/general/blobs/${blobId}`);
  }

  getMessagesByCategories(userId: number, categoryId: string, mailboxName: string, careHomeId: any, from?: number, to?: number, orderBy?: string, orderDirection?: string, filter?: any, is360 = false) {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${environment.apiUrl}/users/${userId}/messages-headers?categoryId=${categoryId}&mailboxName=${mailboxName}&careHomeId=${careHomeId}&rowsFrom=${from}&rowsTo=${to}&orderBy=${orderBy}&orderDirection=${orderDirection}&filter=${filter}${is360 ? '&isEmployee360=true' : ''}`);
  }

  getMessage(userId: number, uid: string, categoryId: any, careHomeId: any, fromId: any, toId: any) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userId}/messages/${uid}?categoryId=${categoryId}&careHomeId=${careHomeId}&fromId=${fromId}&toId=${toId}`);
  }

  downloadMessageAsPdf(userId: number, uid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userId}/messages/${uid}/pdf`);
  }

  getMessagesReport(userId: number, careHomeId: number, dateFrom: any, dateTo: any, groupesId: any, from?: number, to?: number, orderBy?: string, orderDirection?: string, filter?: any, pinned = false, is360 = false) {
    if (is360) {
      return this.httpClient.get(`${environment.apiUrl}/users/${userId}/messages/care-home-report?careHomeId=${careHomeId}&groupesId=${groupesId}&dateFromFormated=${moment(dateFrom).format('YYYY/MM/DD')}&dateToFormated=${moment(dateTo).format('YYYY/MM/DD')}&rowsFrom=${from}&rowsTo=${to}&orderBy=${orderBy}&orderDirection=${orderDirection}&filter=${filter}&isPinned=${pinned}&employee360Id=${userId}`);
    }
    return this.httpClient.get(`${environment.apiUrl}/users/${userId}/messages/care-home-report?careHomeId=${careHomeId}&groupesId=${groupesId}&dateFromFormated=${moment(dateFrom).format('YYYY/MM/DD')}&dateToFormated=${moment(dateTo).format('YYYY/MM/DD')}&rowsFrom=${from}&rowsTo=${to}&orderBy=${orderBy}&orderDirection=${orderDirection}&filter=${filter}&isPinned=${pinned}`);
  }

  sendEasyMessage(userId: number, message: any) {
    return this.httpClient.post(`${environment.apiUrl}/users/${userId}/messages`, message);
  }

  markReadMessage(userId: number, uid: string, data: any) {
    return this.httpClient.patch(`${environment.apiUrl}/users/${userId}/messages/${uid}`, data);
  }

  markReadSelectedMessages(userId: number, messageRequestPutStatus: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/${userId}/messages/read`, messageRequestPutStatus);
  }

  changeMessageCategory(userId: number, messageRequestPutStatus: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/${userId}/messages/status`, messageRequestPutStatus);
  }

  pinMessage(userId: number, uid: string, data: any) {
    return this.httpClient.patch(`${environment.apiUrl}/users/${userId}/messages/${uid}/noticeboard`, data);
  }

  getNoteboard(userId: number, careHomeId: number) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userId}/noticeboard?careHomeId=${careHomeId}`);
  }

  updateNote(userId: number, noteUid: string, data: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/${userId}/noticeboard/notice/${noteUid}`, data);
  }

  // path na ordderIndex do zmiany kolejnosci

}
