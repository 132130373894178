<select
  #agencySelect
  *ngIf="agencies?.length > 0; else agenciesEmpty"
  class="ngx-drop-down ngx-drop-down--in-table-full"
  (change)="agencyChanged(agencySelect.value)"
  [(ngModel)]="agencyId"
>
  <option [value]="null"> --- </option>
  <option *ngFor="let agency of agencies" [value]="agency.id">{{ agency.wordShortName }}</option>
</select>

<ng-template #agenciesEmpty>
  <span>No agencies in template</span>
</ng-template>
