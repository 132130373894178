<nb-card>
  <nb-card-header>{{dialogTitle}}</nb-card-header>
  <nb-card-body>
    <form [formGroup]="form" class="form-container" [style.min-height]="newSubtask ? 'unset' : '320px'">
      <label class="label" for="title-input">{{addTodos ? 'Row' : newSubtask ? 'Subtask' : 'Row'}} title</label>
      <textarea
        nbInput
        fullWidth
        formControlName="title"
        id="title-input"
        name="title-input"
        minlength="3"
        maxlength="500"
        autofocus="false"
        cdkTextareaAutosize
        class="thin-echo-scrollbar-5"
        [ngStyle]="{'max-height': '160px', 'min-height': '40px'}"
      ></textarea>
      <ng-container *ngIf="!newSubtask">
        <mat-divider></mat-divider>
        <label *ngIf="subtasksNames.length > 1">
          <input type="checkbox" [checked]="isAllSelected" (change)="selectUnSelectAll()"/>
          {{isAllSelected ? 'Unselect' : 'Select'}} All
        </label>
        <p class="subtitle">{{dialogSubtitle}}</p>
        <div *ngFor="let data of subtasksNames; let i=index">
          <label>
            <input type="checkbox" [value]="data.name" [checked]="data.selected" (change)="onCheckboxChange($event)"/>
            {{data.name}}
          </label>
        </div>
        <p class="error" *ngIf="this.form.controls['todos'].errors?.['required']">
          At least one {{addTodos ? 'column' : 'row'}} must be checked/added.
        </p>
      </ng-container>
    </form>
  </nb-card-body>
  <nb-card-footer class="flex-space">
    <button [disabled]="form.invalid" (click)="!addTodos && save(); addTodos && edit()" class="btn btn-success full-width">{{okLabel}}</button>
    <button (click)="cancel()" class="btn btn-danger full-width">{{cancelLabel}}</button>
  </nb-card-footer>
</nb-card>
