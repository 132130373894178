<div class="buttons-on-top">
  <div
    *ngIf="taskboardDetails.groupingDictionaryName"
    class="grouping-dictionary-select"
  >
    <form [formGroup]="form">
      <label class="label"
        >{{ taskboardDetails.groupingDictionaryName }}:</label
      >

      <ng-select
        class="edit-task-wrapper-select"
        [style.width.px]="150"
        [items]="grouping"
        formControlName="taskGoupingId"
        [ngStyle]="{ 'background-color': raggsColors.groupingColor }"
        [disabled]="isTaskClosed"
        bindLabel="wordFullName"
        bindValue="id"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
          let-search="searchTerm"
        >
          <div [ngStyle]="{ 'background-color': item.colorHash }">
            {{ item.wordFullName }}
          </div>
        </ng-template>
      </ng-select>
    </form>

    <ngx-switcher
      *ngIf="!showGridView"
      [isToggle]="false"
      [isTriple]="false"
      [isTask]="true"
      [firstValue]="true"
      [secondValue]="'default'"
      [thirdValue]="false"
      [firstValueLabel]="'Expand All'"
      [secondValueLabel]="'Default'"
      [thirdValueLabel]="'Collapse All'"
      [value]="'default'"
      (valueChange)="toggleExpandCollapse($event)"
      [style.transform]="'scale(0.8)'"
    >
    </ngx-switcher>
  </div>
</div>
<div class="form-view-grid">
  <ngx-tabset (changeTab)="onTabChange($event)">
    <ngx-tab
      id="edit-task-container-info-tab"
      class="edit-task__tab-container thin-echo-scrollbar"
      [tabTitle]="tabLabels[0]"
      [active]="activeTab === TaskTab.INFO"
      cdkScrollable
    >
      <main
        style="margin-right: 0.5rem"
        class="edit-task__main"
        tabindex="-1"
        #formBlock
      >
        <form [formGroup]="form" class="mb-4 edit-task__form">
          <div *ngIf="!isTaskLoaded" class="table-container">
            <div class="table-spinner-container">
              <div id="nb-global-spinner" class="spinner">
                <img src="./assets/images/echo_sygnet.svg" alt="" />
              </div>
            </div>
          </div>
          <ng-container *ngIf="isTaskLoaded">
            <div class="flex edit-taks-form-flex">
              <div class="edit-taks-form-flex__inputs">
                <div class="edit-taks-form-flex__inputs-item">
                  <div>
                    <nb-icon
                      icon="email-outline"
                      style="margin-right: 0.5rem; cursor: pointer"
                      (click)="openEmailLogs()"
                    ></nb-icon>
                    <label class="label" for="input-title">Title:</label>
                  </div>
                  <input
                    nbInput
                    fullWidth
                    formControlName="title"
                    name="title"
                    id="input-title"
                    maxlength="100"
                    placeholder="Title"
                    [readOnly]="isTaskClosed"
                    [disabled]="isTaskClosed"
                  />
                  <p class="error-message">
                    {{
                      utils.handleValidationErrorMessage(
                        form,
                        "title",
                        errorMessages
                      )
                    }}
                  </p>
                </div>
                <div class="edit-taks-form-flex__inputs-item">
                  <label class="label" for="comments">Description:</label>
                  <pre
                    id="description-pre"
                    (click)="onClickPre($event)"
                    [innerHTML]="descriptionWithActiveLinks"
                  ></pre>
                  <textarea
                    nbInput
                    style="height: 126px; display: none"
                    fullWidth
                    formControlName="comments"
                    name="comments"
                    (focusout)="onFocusLostOfDesc()"
                    id="comments"
                    placeholder="Add notes about this task"
                    [maxlength]="descriptionMaxLength"
                    autofocus
                    [readOnly]="isTaskClosed"
                    [disabled]="isTaskClosed"
                  >
                  </textarea>
                </div>
                <div class="edit-taks-form-flex__inputs-item">
                  <div class="task_category" *ngIf="showAdvancedFeatures">
                    <nb-icon
                      *ngIf="checkedCategories.length > 0"
                      icon="info-outline"
                      nbPopoverTrigger="hover"
                      [nbPopover]="categoryInfoPopover"
                    ></nb-icon>
                    <ng-template #categoryInfoPopover>
                      <div class="task-tooltip">
                        <div class="category-info-title">
                          Following users will be informed about changes in this
                          task:
                        </div>
                        <div class="category-info-subtitle">
                          <ul>
                            <li *ngFor="let name of getCategoriesNames()">
                              {{ name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </ng-template>
                    <ngx-multiple-autocomplete-selector-with-checkbox
                      [values]="categories"
                      class="categories-selector"
                      bindLabel="wordFullName"
                      placeholder="select max 3 categories"
                      label="Category:"
                      valuesLimit="3"
                      [noSelectAll]="true"
                      [useShortForm]="true"
                      formControlName="categoryId"
                      [disabled]="isTaskClosed"
                    >
                    </ngx-multiple-autocomplete-selector-with-checkbox>
                    <p class="error-message">
                      {{
                        utils.handleValidationErrorMessage(
                          form,
                          "categoryId",
                          errorMessages
                        )
                      }}
                    </p>
                  </div>
                </div>
                <div
                  class="edit-taks-form-flex__inputs-item"
                  *ngIf="taskSelectorVisible && showAdvancedFeatures"
                >
                  <ngx-multiple-autocomplete-selector-with-checkbox-and-add
                    [values]="tags"
                    bindLabel="wordFullName"
                    placeholder="select tags"
                    label="Linking tags:"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="selectedTags"
                    noSelectAll="true"
                    (newValueAdded)="newTagAdded($event)"
                    [disabled]="isTaskClosed"
                  >
                  </ngx-multiple-autocomplete-selector-with-checkbox-and-add>
                </div>
                <div
                  class="edit-taks-form-flex__inputs-item"
                  *ngIf="showAdvancedFeatures"
                >
                  <div
                    *ngIf="taskboardDetails.groupingDictionaryName"
                    class="grouping-dictionary-select in-advanced"
                  >
                    <form [formGroup]="form">
                      <label class="label"
                        >{{ taskboardDetails.groupingDictionaryName }}:</label
                      >

                      <ng-select
                        style="width: 100%"
                        class="edit-task-wrapper-select in-advanced"
                        [items]="grouping"
                        formControlName="taskGoupingId"
                        [ngStyle]="{
                          'background-color': raggsColors.groupingColor
                        }"
                        [disabled]="isTaskClosed"
                        bindLabel="wordFullName"
                        bindValue="id"
                      >
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-index="index"
                          let-search="searchTerm"
                        >
                          <div
                            [ngStyle]="{ 'background-color': item.colorHash }"
                          >
                            {{ item.wordFullName }}
                          </div>
                        </ng-template>
                      </ng-select>
                    </form>
                  </div>
                </div>
              </div>
              <div class="edit-taks-form-flex__settings">
                <div class="edit-taks-form-flex__settings-item">
                  <nb-icon
                    icon="info-outline"
                    style="margin-right: 0.5rem"
                    nbPopoverTrigger="hover"
                    [nbPopover]="taskNo"
                  ></nb-icon>
                  <label class="label" for="input-taskboard">Taskboard:</label>
                  <input
                    nbInput
                    fullWidth
                    [value]="taskDetails.taskBoardName || ''"
                    disabled
                  />
                </div>
                <div class="edit-taks-form-flex__settings-item">
                  <div>
                    <nb-icon
                      icon="clock-outline"
                      style="margin-right: 0.5rem"
                      [nbPopover]="timestampInfoPopover"
                      nbPopoverTrigger="hover"
                    ></nb-icon>
                    <span>Task Timestamps information</span>
                  </div>
                  <ng-template #timestampInfoPopover>
                    <div class="task-tooltip">
                      <p>Task created:</p>
                      <span>{{ taskDetails.createdAt }}</span>
                      <p>Task modified:</p>
                      <span>{{ taskDetails.modifiedAt }}</span>
                      <p class="mt-2">Last care home report:</p>
                      <span>{{ taskDetails.lastCareHomeReport }}</span>
                      <p class="mt-2">Last area manager report:</p>
                      <span>{{ taskDetails.lastManagerReport }}</span>
                    </div>
                  </ng-template>
                </div>
                <div class="edit-taks-form-flex__settings-item">
                  <label class="label" for="input-status">Status:</label>
                  <select formControlName="statusId">
                    <option
                      *ngFor="let status of statuses"
                      [ngValue]="status.id"
                      [disabled]="status.disabled"
                    >
                      {{ status.wordFullName }}
                    </option>
                  </select>
                  <p class="error-message">
                    {{
                      utils.handleValidationErrorMessage(
                        form,
                        "statusId",
                        errorMessages
                      )
                    }}
                  </p>
                </div>
                <div class="edit-taks-form-flex__settings-item">
                  <ngx-switcher
                    [firstValue]="false"
                    [secondValue]="true"
                    firstValueLabel="Advanced features"
                    [value]="!showAdvancedFeatures"
                    (valueChange)="toggleAdvFeatures()"
                    [ngStyle]="
                      showAdvancedFeatures
                        ? {
                            display: 'flex',
                            'margin-bottom': '20px',
                            'margin-top': '20px'
                          }
                        : {
                            display: 'flex',
                            'margin-bottom': '20px',
                            'margin-top': '15px'
                          }
                    "
                  >
                  </ngx-switcher>
                  <div *ngIf="showAdvancedFeatures"></div>
                  <ngx-switcher
                    *ngIf="showAdvancedFeatures"
                    [firstValue]="true"
                    [secondValue]="false"
                    firstValueLabel="List view"
                    secondValueLabel="Grid view"
                    [value]="!showGridView"
                    (valueChange)="toggleListGridView()"
                    [ngStyle]="
                      showAdvancedFeatures
                        ? {
                            display: 'flex',
                            'margin-bottom': '20px',
                            'margin-top': '20px'
                          }
                        : { display: 'flex', 'margin-bottom': '20px' }
                    "
                  >
                  </ngx-switcher>
                </div>
                <div
                  *ngIf="showAdvancedFeatures"
                  class="edit-taks-form-flex__settings-item"
                >
                  <nb-icon
                    *ngIf="form.get('dueTo').value"
                    icon="info-outline"
                    style="margin-right: 0.5rem"
                    [style.color]="
                      deadlineEmailsCollected ? '#40dc7e' : '#4b4b4b'
                    "
                    (mouseenter)="!deadlineEmailsCollected && getDataToSend()"
                    [nbPopover]="deadlineInfoPopover"
                    nbPopoverTrigger="hover"
                  ></nb-icon>
                  <ng-template #deadlineInfoPopover>
                    <div class="task-tooltip">
                      <div class="category-info-title">Following users will get deadline mail:</div>
                      <div class="category-info-subtitle">
                        <ul>
                          <li *ngFor="let user of deadlineEmails">{{user.userFullName}} - {{user.email}}</li>
                        </ul>
                      </div>
                    </div>
                  </ng-template>
                  <label class="label" for="input-due-to">Deadline:</label>
                  <input
                    nbInput
                    fullWidth
                    formControlName="dueTo"
                    name="dueTo"
                    id="input-due-to"
                    placeholder="DD/MM/YYYY"
                    [readOnly]="isTaskClosed"
                    [disabled]="isTaskClosed"
                    [matDatepicker]="picker"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker startView="month"></mat-datepicker>
                </div>
                <div
                  *ngIf="showAdvancedFeatures"
                  class="edit-taks-form-flex__settings-item"
                >
                  <label class="label" for="input-priority-id">Priority:</label>
                  <select
                    formControlName="priorityId"
                    [disabled]="isTaskClosed"
                  >
                    <option
                      *ngFor="let priority of priorities"
                      [ngValue]="priority.id"
                    >
                      {{ priority.wordFullName }}
                    </option>
                  </select>
                  <p class="error-message">
                    {{
                      utils.handleValidationErrorMessage(
                        form,
                        "priorityId",
                        errorMessages
                      )
                    }}
                  </p>
                </div>
                <div
                  *ngIf="showAdvancedFeatures"
                  class="edit-taks-form-flex__settings-item"
                >
                  <label class="label" for="input-pinnedAreaId"
                    >Pinned Area:</label
                  >
                  <select
                    formControlName="pinnedAreaId"
                    id="input-pinnedAreaId"
                  >
                    <option [ngValue]="null" [disabled]="true">
                      {{
                        pinned.length > 0 ? "Select pinned area" : "Loading..."
                      }}
                    </option>
                    <option [ngValue]="''"></option>
                    <option
                      *ngFor="let pinnedArea of pinned"
                      [ngValue]="pinnedArea.id"
                      [disabled]="pinnedArea.disabled"
                    >
                      {{ pinnedArea.wordFullName }}
                    </option>
                  </select>
                  <p class="error-message">
                    {{
                      utils.handleValidationErrorMessage(
                        form,
                        "pinnedAreaId",
                        errorMessages
                      )
                    }}
                  </p>
                </div>
                <div
                  *ngIf="showAdvancedFeatures"
                  class="edit-taks-form-flex__settings-item"
                ></div>
                <div
                  *ngIf="showAdvancedFeatures"
                  class="edit-taks-form-flex__settings-item"
                ></div>
              </div>
            </div>
            <!-- <div class="grid-wrapper mt-2">
              <div class="form-control-group box start-1 end-13">
                <div>
                  <nb-icon
                    icon="email-outline"
                    style="margin-right: 0.5rem; cursor: pointer"
                    (click)="openEmailLogs()"
                  ></nb-icon>
                  <label class="label" for="input-title">Title:</label></div>
                <input
                  nbInput
                  fullWidth
                  formControlName="title"
                  name="title"
                  id="input-title"
                  maxlength="100"
                  placeholder="Title"
                  [readOnly]="isTaskClosed"
                  [disabled]="isTaskClosed"
                />
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "title",
                      errorMessages
                    )
                  }}
                </p>
              </div>
              <div class="form-control-group box start-13 end-17">
                <nb-icon
                  icon="info-outline"
                  style="margin-right: 0.5rem"
                  nbPopoverTrigger="hover"
                  [nbPopover]="taskDebugInfgo"
                ></nb-icon>
                <label class="label" for="input-taskboard">Taskboard:</label>
                <input
                  nbInput
                  fullWidth
                  [value]="taskDetails.taskBoardName || ''"
                  disabled
                />
              </div>
            </div> -->

            <!-- <div class="grid-wrapper lines-3 mt-2 mb-2">
              <div class="form-control-group box start-1 end-13 row-1">
                <label class="label" for="comments">Description:</label>
                <pre
                  id="description-pre"
                  (click)="onClickPre($event)"
                  [innerHTML]="descriptionWithActiveLinks"
                ></pre>
                <textarea
                  nbInput
                  style="height: 126px; display: none"
                  fullWidth
                  formControlName="comments"
                  name="comments"
                  (focusout)="onFocusLostOfDesc()"
                  id="comments"
                  placeholder="Add notes about this task"
                  [maxlength]="descriptionMaxLength"
                  autofocus
                  [readOnly]="isTaskClosed"
                  [disabled]="isTaskClosed"
                >
                </textarea>
              </div>
              <div
                class="form-control-group box start-13 end-17 row-1"
                style="margin-top: -0.6rem; margin-bottom: 1rem"
              >
                <div>
                  <nb-icon
                    icon="clock-outline"
                    style="margin-right: 0.5rem"
                    [nbPopover]="timestampInfoPopover"
                    nbPopoverTrigger="hover"
                  ></nb-icon>
                  <span>Task Timestamps information</span>
                </div>
                <ng-template #timestampInfoPopover>
                  <div class="task-tooltip">
                    <p>Task created:</p>
                    <span>{{ taskDetails.createdAt }}</span>
                    <p>Task modified:</p>
                    <span>{{ taskDetails.modifiedAt }}</span>
                    <p class="mt-2">Last care home report:</p>
                    <span>{{ taskDetails.lastCareHomeReport }}</span>
                    <p class="mt-2">Last area manager report:</p>
                    <span>{{ taskDetails.lastManagerReport }}</span>
                  </div>
                </ng-template>
              </div>
              <div
                *ngIf="!isHideStatus"
                class="form-control-group box start-13 end-17 row-1"
                style="margin-top: 1.2rem"
              >
                <div>
                  <label class="label" for="input-status">Status:</label>
                  <select formControlName="statusId">
                    <option
                      *ngFor="let status of statuses"
                      [ngValue]="status.id"
                      [disabled]="status.disabled"
                    >
                      {{ status.wordFullName }}
                    </option>
                  </select>
                  <p class="error-message">
                    {{
                      utils.handleValidationErrorMessage(
                        form,
                        "statusId",
                        errorMessages
                      )
                    }}
                  </p>
                </div>

                <div>
                  <ngx-switcher
                    [firstValue]="false"
                    [secondValue]="true"
                    firstValueLabel="Advanced features"
                    [value]="!showAdvancedFeatures"
                    (valueChange)="toggleAdvFeatures()"
                    [ngStyle]="showAdvancedFeatures ? {display: 'flex', 'margin-bottom': '20px', 'margin-top': '20px'} : {display: 'flex', 'margin-bottom': '20px', 'margin-top': '15px'}"
                  >
                  </ngx-switcher>
                </div>
              <div *ngIf="showAdvancedFeatures"></div>
              <ngx-switcher
                *ngIf="showAdvancedFeatures"
                [firstValue]="true"
                [secondValue]="false"
                firstValueLabel="List view"
                secondValueLabel="Grid view"
                [value]="!showGridView"
                (valueChange)="toggleListGridView()"
                [ngStyle]="showAdvancedFeatures ? {display: 'flex', 'margin-bottom': '20px', 'margin-top': '20px'} : {display: 'flex', 'margin-bottom': '20px'}"
              >
              </ngx-switcher>
              </div>
              <div
                class="form-control-group box start-13 end-17 row-2"
                style="margin-top: 0.61rem"
              >
              <div *ngIf="showAdvancedFeatures">
                <nb-icon
                  *ngIf="form.get('dueTo').value"
                  icon="info-outline"
                  style="margin-right: 0.5rem"
                  [style.color]="deadlineEmailsCollected ? '#40dc7e' : '#4b4b4b'"
                  (mouseenter)="!deadlineEmailsCollected && getDataToSend()"
                  [nbPopover]="deadlineInfoPopover"
                  nbPopoverTrigger="hover"
                ></nb-icon>
                <label class="label" for="input-due-to">Deadline:</label>
                <input
                  nbInput
                  fullWidth
                  formControlName="dueTo"
                  name="dueTo"
                  id="input-due-to"
                  placeholder="DD/MM/YYYY"
                  [readOnly]="isTaskClosed"
                  [disabled]="isTaskClosed"
                  [matDatepicker]="picker"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
              </div>
                <mat-datepicker #picker startView="month"></mat-datepicker>

              </div>
              <div class="form-control-group box start-1 end-13 row-3 task_category"
              *ngIf="showAdvancedFeatures"
              >
                <nb-icon
                  *ngIf="checkedCategories.length > 0"
                  icon="info-outline"
                  nbPopoverTrigger="hover"
                  [nbPopover]="categoryInfoPopover"
                ></nb-icon>
                <ng-template #categoryInfoPopover>
                  <div class="task-tooltip">
                    <div class="category-info-title">Following users will be informed about changes in this task:</div>
                    <div class="category-info-subtitle">
                      <ul>
                        <li *ngFor="let name of getCategoriesNames()">{{name}}</li>
                      </ul>
                    </div>
                  </div>
                </ng-template>
                <ngx-multiple-autocomplete-selector-with-checkbox
                  [values]="categories"
                  class="categories-selector"
                  bindLabel="wordFullName"
                  placeholder="select max 3 categories"
                  label="Category:"
                  valuesLimit="3"
                  [noSelectAll]="true"
                  [useShortForm]="true"
                  formControlName="categoryId"
                  [disabled]="isTaskClosed"
                >
                </ngx-multiple-autocomplete-selector-with-checkbox>
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "categoryId",
                      errorMessages
                    )
                  }}
                </p>
              </div>
              <div class="form-control-group box start-13 end-17 row-3"
              *ngIf="showAdvancedFeatures"
              >
                <label class="label" for="input-priority-id">Priority:</label>
                <select
                  formControlName="priorityId"
                  [disabled]="isTaskClosed"
                >
                  <option
                    *ngFor="let priority of priorities"
                    [ngValue]="priority.id"
                  >
                    {{ priority.wordFullName }}
                  </option>
                </select>
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "priorityId",
                      errorMessages
                    )
                  }}
                </p>
              </div>
            </div> -->
            <!-- <div class="grid-wrapper" [ngStyle]="showAdvancedFeatures ? {'margin-top': 'unset'} : {'margin-top': '-60px'}">
              <div
                *ngIf="taskboardDetails.groupingDictionaryName"
                class="form-control-group box start-1"
                [class.end-13]="showAdvancedFeatures"
                [class.end-17]="!showAdvancedFeatures"
              >
                <label class="label"
                >{{ taskboardDetails.groupingDictionaryName }}:</label
                >
                <select
                  formControlName="taskGoupingId"
                  [ngStyle]="{ 'background-color': raggsColors.groupingColor }"
                  [disabled]="isTaskClosed"
                >
                  <option
                    *ngFor="let group of grouping"
                    [ngValue]="group.id"
                    [ngStyle]="{ 'background-color': group.colorHash }"
                  >
                    {{ group.wordFullName }}
                  </option>
                </select>
              </div>
              <div class="form-control-group box start-13 end-17" *ngIf="showAdvancedFeatures">
                <label class="label" for="input-pinnedAreaId">Pinned Area:</label>
                <select formControlName="pinnedAreaId" id="input-pinnedAreaId">
                  <option [ngValue]="null" [disabled]="true" >{{pinned.length > 0 ? 'Select pinned area' : 'Loading...'}}</option>
                  <option [ngValue]="''"></option>
                  <option
                    *ngFor="let pinnedArea of pinned"
                    [ngValue]="pinnedArea.id"
                    [disabled]="pinnedArea.disabled"
                  >
                    {{ pinnedArea.wordFullName }}
                  </option>
                </select>
                <p class="error-message">
                  {{
                  utils.handleValidationErrorMessage(
                    form,
                    "pinnedAreaId",
                    errorMessages
                  )
                  }}
                </p>
              </div>
            </div> -->
            <div class="advanced-switchers-container">
              <!-- <ngx-switcher
                *ngIf="!showGridView"
                [isToggle]="false"
                [isTriple]="false"
                [isTask]="true"
                [firstValue]="true"
                [secondValue]="'default'"
                [thirdValue]="false"
                [firstValueLabel]="'Expand All'"
                [secondValueLabel]="'Default'"
                [thirdValueLabel]="'Collapse All'"
                [value]="'default'"
                (valueChange)="toggleExpandCollapse($event)"
                [style.transform]="'scale(0.8)'"
                [ngStyle]="showAdvancedFeatures ? {display: 'flex', 'margin-bottom': '20px', 'margin-top': '20px'} : {display: 'flex', 'margin-bottom': '20px', 'margin-top': '15px'}"
              >
              </ngx-switcher> -->
              <!-- <ngx-switcher
                [firstValue]="false"
                [secondValue]="true"
                firstValueLabel="Advanced features"
                [value]="!showAdvancedFeatures"
                (valueChange)="toggleAdvFeatures()"
                [ngStyle]="showAdvancedFeatures ? {display: 'flex', 'margin-bottom': '20px', 'margin-top': '20px'} : {display: 'flex', 'margin-bottom': '20px', 'margin-top': '15px'}"
              >
              </ngx-switcher>
              <div *ngIf="showAdvancedFeatures"></div>
              <ngx-switcher
                *ngIf="showAdvancedFeatures"
                [firstValue]="true"
                [secondValue]="false"
                firstValueLabel="List view"
                secondValueLabel="Grid view"
                [value]="!showGridView"
                (valueChange)="toggleListGridView()"
                [ngStyle]="showAdvancedFeatures ? {display: 'flex', 'margin-bottom': '20px', 'margin-top': '20px'} : {display: 'flex', 'margin-bottom': '20px'}"
              >
              </ngx-switcher> -->
            </div>
            <!-- <div class="grid-wrapper" *ngIf="showAdvancedFeatures">
              <div
                *ngIf="taskSelectorVisible"
                class="form-control-group box start-1 end-17"
              >
                <ngx-multiple-autocomplete-selector-with-checkbox-and-add
                  [values]="tags"
                  bindLabel="wordFullName"
                  placeholder="select tags"
                  label="Linking tags:"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="selectedTags"
                  noSelectAll="true"
                  (newValueAdded)="newTagAdded($event)"
                  [disabled]="isTaskClosed"
                >
                </ngx-multiple-autocomplete-selector-with-checkbox-and-add>
              </div>
            </div> -->
            <div
              class="grid-wrapper"
              *ngIf="
                showAdvancedFeatures &&
                (taskboardDetails.ragg1DictionaryName ||
                  taskboardDetails.ragg2DictionaryName ||
                  taskDetails.setToClosedAtMessage)
              "
            >
              <div
                *ngIf="taskboardDetails.ragg1DictionaryName"
                class="form-control-group box start-1 end-7"
              >
                <label class="label"
                  >{{ taskboardDetails.ragg1DictionaryName }}:</label
                >
                <select
                  formControlName="taskRagg1Id"
                  [ngStyle]="{ 'background-color': raggsColors.ragg1Color }"
                  [disabled]="isTaskClosed"
                >
                  <option
                    *ngFor="let rag of raggs1"
                    [ngValue]="rag.id"
                    [ngStyle]="{ 'background-color': rag.colorHash }"
                  >
                    {{ rag.wordFullName }}
                  </option>
                </select>
              </div>

              <div
                *ngIf="taskboardDetails.ragg2DictionaryName"
                class="form-control-group box start-7 end-13"
              >
                <label class="label"
                  >{{ taskboardDetails.ragg2DictionaryName }}:</label
                >
                <select
                  formControlName="taskRagg2Id"
                  [ngStyle]="{ 'background-color': raggsColors.ragg2Color }"
                  [disabled]="isTaskClosed"
                >
                  <option
                    *ngFor="let rag of raggs2"
                    [ngValue]="rag.id"
                    [ngStyle]="{ 'background-color': rag.colorHash }"
                  >
                    {{ rag.wordFullName }}
                  </option>
                </select>
              </div>
              <div
                class="form-control-group box start-13 end-17"
                *ngIf="taskDetails.setToClosedAtMessage"
              >
                <p>{{ taskDetails.setToClosedAtMessage }}</p>
              </div>
            </div>
          </ng-container>

          <ngx-subtask-editor
            #subtaskEditor
            *ngIf="isTaskLoaded && !showGridView"
            [taskId]="id"
            [task]="taskDetails"
            [taskBoardId]="taskBoardId"
            [canAdd]="!isTaskClosed"
            [refreshComponent]="refreshSubTasks"
            [forceCheckIsAllTodosR]="forceCheckIsAllTodosR"
            (reloadParent)="refreshForSubtasks($event)"
            (localDataChanged)="refreshLocalData($event)"
            (subTasksChanged)="refreshSubtasksData($event)"
            (dataLoaded)="subtasksLoaded($event)"
            (allTodosCompleted)="assignTodosStatus($event)"
            (continueEvent)="presaveContinue($event)"
            (sendRecurringTodos)="getRecurringTodos($event)"
            (saveStatusChanged)="changeSaveStatus($event)"
          >
          </ngx-subtask-editor>
          <ngx-subtask-editor
            #subtaskEditor
            *ngIf="isTaskLoaded && showGridView"
            [advancedView]="true"
            [taskId]="id"
            [task]="taskDetails"
            [taskBoardId]="taskBoardId"
            [canAdd]="!isTaskClosed"
            [refreshComponent]="refreshSubTasks"
            [forceCheckIsAllTodosR]="forceCheckIsAllTodosR"
            (reloadParent)="refreshForSubtasks($event)"
            (localDataChanged)="refreshLocalData($event)"
            (subTasksChanged)="refreshSubtasksData($event)"
            (dataLoaded)="subtasksLoaded($event)"
            (allTodosCompleted)="assignTodosStatus($event)"
            (continueEvent)="presaveContinue($event)"
            (sendRecurringTodos)="getRecurringTodos($event)"
            (saveStatusChanged)="changeSaveStatus($event)"
          >
          </ngx-subtask-editor>
        </form>
      </main>
    </ngx-tab>
    <ngx-tab
      id="finance-tab"
      class="edit-task__tab-container thin-echo-scrollbar"
      *ngIf="isFinanceTask"
      [tabTitle]="tabLabels[1]"
      [active]="activeTab === TaskTab.FINANCE"
    >
      <ngx-resident-left
        class="mt-1 edit-task"
        [financeTaskMode]="isFinanceTask"
      >
      </ngx-resident-left>
    </ngx-tab>
    <ngx-tab
      id="edit-task-container-files-tab"
      class="edit-task__tab-container thin-echo-scrollbar"
      [tabTitle]="tabLabels[3]"
      [active]="activeTab === TaskTab.FILES"
    >
      <div class="mt-2">
        <ngx-file-upload
          [files]="files"
          [isFileSendEnabled]="isFileSendEnabled"
          isAddNotesEnabled="true"
          (onUpload)="uploadFile($event)"
          (onDelete)="deleteFile($event)"
          (onDownload)="downloadFile($event)"
          (descriptionUpdate)="uploadDocumentDescription($event)"
          [customButtonCheckFunction]="checkFileIsContractDocument"
          [customButtonDisabled]="isContractUploaded"
          [customButtonLabel]="getContractLabel"
          [customButtonAction]="connectWithContract"
        >
        </ngx-file-upload>
      </div>
    </ngx-tab>
    <ngx-tab
      id="edit-task-container-history-tab"
      class="edit-task__tab-container thin-echo-scrollbar"
      [tabTitle]="tabLabels[2]"
      [active]="activeTab === TaskTab.HISTORY"
      keyboardScroll
      [keyboardScrollNameTrigger]="'Task history'"
    >
      <main class="history mt-2">
        <form [formGroup]="formHistory">
          <div class="grid-wrapper">
            <div class="form-control-group box start-1 end-17">
              <label class="label" for="input-title">Title:</label>
              <input
                nbInput
                fullWidth
                formControlName="titleDisabled"
                name="title-disabled"
                id="input-title-disabled"
                disabled="true"
                [readOnly]="isTaskClosed"
                [disabled]="isTaskClosed"
              />
              <p class="error-message">
                {{
                  utils.handleValidationErrorMessage(
                    form,
                    "title",
                    errorMessages
                  )
                }}
              </p>
            </div>
          </div>

          <div id="description-container" class="grid-wrapper">
            <div class="form-control-group textarea box start-1 end-17">
              <label class="label" for="comments">Description:</label>
              <textarea
                nbInput
                fullWidth
                formControlName="commentsDisabled"
                name="comments-disabled"
                id="comments-dsiabled"
                placeholder="Add notes about this task"
                maxlength="2000"
                [readOnly]="isTaskClosed"
                [disabled]="isTaskClosed"
              >
              </textarea>
            </div>
          </div>
          <ngx-switcher
            class="history-table-switcher"
            [firstValue]="false"
            [secondValue]="true"
            [firstValueLabel]="'Task History'"
            [secondValueLabel]="'Event History'"
            [value]="isNewHistoryTable"
            (valueChange)="toogleIsNewHistoryTable($event)"
          >
          </ngx-switcher>
          <div class="grid-wrapper mt-1">
            <div
              *ngIf="historyTabIsActive"
              class="history-table-container box start-1 end-17"
              style="overflow-x: hidden"
            >
              <ngx-history-table-with-pagination
                *ngIf="isNewHistoryTable"
                [data]="historyData"
                [disabled]="isTaskClosed"
                [load]="loadHistoryOld"
                [edit]="addTaskTab"
                [columns]="historyColumnsOld"
                [thinner]="true"
                [sortFromBackend]="true"
                [usePagination]="true"
                [filterEnterEnabled]="true"
                [totalRows]="historyTotalRows"
                [paginationSizeOptions]="[10, 25, 50]"
                [isDataLoaded]="historyTableLoaded"
              >
              </ngx-history-table-with-pagination>
              <ngx-history-table
                *ngIf="!isNewHistoryTable"
                [data]="historyData"
                [load]="loadHistory"
                [edit]="addTaskTab"
                [columns]="historyColumns"
                [thinner]="true"
                [sortFromBackend]="true"
                [usePagination]="true"
                [filterEnterEnabled]="true"
                [totalRows]="historyTotalRows"
                [paginationSizeOptions]="[10, 25, 50]"
                [isDataLoaded]="historyTableLoaded"
              >
              </ngx-history-table>
            </div>
          </div>
        </form>
      </main>
    </ngx-tab>
    <ngx-tab
      id="edit-task-container-linked-tab"
      class="edit-task__tab-container thin-echo-scrollbar"
      [tabTitle]="tabLabels[4]"
      [active]="activeTab === TaskTab.LINKED_TASKS"
    >
      <form
        class="mt-2"
        style="
          margin-right: 0.5rem;
          /* height: 730px;
          max-height: 730px;
          min-height: 730px; */
        "
      >
        <div class="switcher-width">
          <div class="mr-1">
            <nb-checkbox
              (change)="toggleTakeAll($event)"
              [checked]="this.form.get('isShowPaused').value"
              class="line-checkbox"
              [disabled]="isTaskClosed"
              >show Paused and D&R</nb-checkbox
            >
          </div>
          <div>
            <nb-checkbox
              (change)="toggleTakeClosed($event)"
              [checked]="this.form.get('isShowClosed').value"
              class="line-checkbox"
              [disabled]="isTaskClosed"
              >show closed</nb-checkbox
            >
          </div>
        </div>
        <div class="grid-wrapper">
          <div
            class="form-control-group box start-1 end-17"
            *ngIf="taskSelectorVisible"
          >
            <ngx-multiple-autocomplete-selector-with-checkbox-and-add
              [values]="tags"
              bindLabel="wordFullName"
              placeholder="select tags"
              label="Tags:"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="selectedTags"
              noSelectAll="true"
              [disabled]="isTaskClosed"
              (newValueAdded)="newTagAdded($event)"
            >
            </ngx-multiple-autocomplete-selector-with-checkbox-and-add>
          </div>
        </div>

        <div>
          <div *ngFor="let table of linkedTasksData">
            <p class="tag-paragraph">
              Unique tag: <span>{{ table.name }}</span>
            </p>
            <div id="table-container" class="mb-3" style="height: 100%">
              <ngx-taskboard-only-table
                [data]="table.taskList"
                [columns]="tagsColumns"
                [edit]="addTab"
              >
              </ngx-taskboard-only-table>
            </div>
          </div>
        </div>
      </form>
    </ngx-tab>
  </ngx-tabset>
  <footer class="space-between footer-fixed-height">
    <div
      id="footer-task-progress"
      [ngStyle]="{ 'background-color': taskProgressBackground }"
    >
      <div class="progress-info">
        <nb-icon icon="info-outline" style="margin-right: 0.5rem"></nb-icon>
        <p [innerHTML]="taskProgress"></p>
      </div>
    </div>
    <div class="flex">
      <button
        *ngIf="selectedTab == TaskTab.FINANCE"
        class="btn btn-success"
        [disabled]="!financeTabIsValid"
        (click)="presave()"
      >
        <i class="nb-checkmark" aria-hidden="true"></i> Save
      </button>
      <button
        *ngIf="selectedTab != TaskTab.FINANCE"
        class="btn btn-success"
        [disabled]="!utils.isFormValid(form) || !isSaveEnabled"
        (click)="presave()"
      >
        <i class="nb-checkmark" aria-hidden="true"></i> Save
      </button>
      <button class="btn btn-danger ml-2" (click)="closeWindow()">
        <i class="nb-close" aria-hidden="true"></i> Close
      </button>
      <div class="flex middle ml-2" [ngStyle]="{ cursor: 'pointer' }">
        <!-- <nb-checkbox
          (change)="changeEmailList($event)"
          [checked]="this.form.get('isEmailList').value"
          [disabled]="isTaskClosed"
          class="task-checkbox"
          >email with WU</nb-checkbox
        > -->
        <nb-checkbox
          (change)="changeEmailOnSave($event)"
          [checked]="this.form.get('isEmailOnSave').value"
          [disabled]="isTaskClosed"
          class="task-checkbox longer"
          >email this task on save</nb-checkbox
        >
      </div>
    </div>

    <div class="flex middle">
      <!-- <button
        *ngIf="!disableTaskCopy"
        class="btn btn-success ml-2"
        (click)="copyTask()"
        [disabled]="isTaskClosed"
      >
        <i class="nb-compose" aria-hidden="true"></i> Copy
      </button> -->
      <!-- <button
        *ngIf="!disableTaskCopy"
        class="btn btn-success ml-2"
        [disabled]="isTaskClosed"
        (click)="moveTask()"
      >
        <i class="nb-arrow-retweet" aria-hidden="true"></i> Move
      </button> -->
      <!-- <button
        id="watchers"
        class="btn btn-info ml-2"
        [disabled]="!taskBoardId && isTaskClosed"
        (click)="addWatchers()"
      >
        Add Watchers
      </button> -->
      <!-- <nb-checkbox
        (change)="changeFavoriteState($event)"
        [checked]="this.form.get('isFavorite').value"
        [disabled]="isTaskClosed"
        class="task-checkbox"
        >on my watchlist</nb-checkbox
      > -->
      <!-- <button class="btn btn-info" *ngIf="showAdvancedFeatures" (click)="saveAsNewTask()" style="width: 165px;">
        <nb-icon icon="clipboard-outline" class="mr-2"></nb-icon> Save new copy
      </button> -->

      <button
        class="btn btn-info ml-2"
        (click)="showOptionsTooltip = !showOptionsTooltip"
      >
        <nb-icon icon="more-vertical-outline" class="mr-2"></nb-icon> Options
      </button>

      <div *ngIf="showOptionsTooltip" class="popup-for-options">
        <ul class="options-tooltip">
          <li class="subheader"><strong>Actions</strong></li>
          <li
            class="with-icon"
            *ngIf="!disableTaskCopy"
            (click)="saveAsNewTask()"
          >
            <i><nb-icon icon="clipboard-outline"></nb-icon></i> Save new copy
          </li>
          <li class="with-icon" *ngIf="!disableTaskCopy" (click)="copyTask()">
            <i class="nb-compose" aria-hidden="true"></i> Copy
          </li>
          <li
            class="with-icon"
            [ngClass]="{ disabled: isTaskClosed }"
            *ngIf="!disableTaskCopy"
            (click)="!isTaskClosed && moveTask()"
          >
            <i class="nb-arrow-retweet" aria-hidden="true"></i> Move
          </li>
          <li
            class="with-icon"
            [ngClass]="{ disabled: isTaskClosed }"
            (click)="!(!taskBoardId && isTaskClosed) && addWatchers()"
          >
            <i class="nb-arrow-retweet" aria-hidden="true"></i> Add watchers
          </li>
          <li>
            <nb-checkbox
              (change)="changeEmailList($event)"
              [checked]="this.form.get('isEmailList').value"
              [disabled]="isTaskClosed"
              >email with WU</nb-checkbox
            >
          </li>
          <li>
            <nb-checkbox
              (change)="changeFavoriteState($event)"
              [checked]="this.form.get('isFavorite').value"
              [disabled]="isTaskClosed"
              >on my watchlist</nb-checkbox
            >
          </li>
        </ul>
      </div>
    </div>
  </footer>
</div>
