import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { UsersService } from '../../../shared/users/users.service';
import { CareHomesService } from '../../../shared/care-homes/care-homes.service';
import { Validators, UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { handleValidationErrorMessage, getValidationStatus, getErrorMessage, isFormValid, isFieldInvalid } from '../../../utilities/utils';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { errorMessages } from '../user-errors';
import { TableService } from '../../shared/table/table.service';
import { DictionariesService } from '../../../shared/dictionaries/dictionaries.service';
import { ApplicationPermissions } from '../../../@core/data/application-permissions.enum';
import { Subscription } from 'rxjs';
import { TaskBoardsService } from '../../../shared/tasks/task-boards/task-boards.service';
import { QuestionDialogComponent } from '../../shared/question-dialog/question-dialog.component';
import { AvatarsService } from '../../../shared/avatars.service';
import { LocalDataSource } from 'angular2-smart-table';
import { ProfilesService } from '../../../shared/profiles/profiles.service';
import { PermissionsService } from '../../../@core/data/permissions.service';
import { AuthUserService } from '../../../@core/data/auth-user.service';
import { EmployessService } from '../../../shared/employess/employess.service';
import { DOCUMENT } from '@angular/common';
import { distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: 'ngx-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public ApplicationPermissions: typeof ApplicationPermissions = ApplicationPermissions;
  public errorMessages = errorMessages;
  public careHomes: any = [];
  public statuses: any = [];
  public userProfiles: any = [];
  public employees: any = [];
  public form: UntypedFormGroup;
  public utils = { getValidationStatus, handleValidationErrorMessage, isFormValid, isFieldInvalid };
  public userId = 0;
  public showChangePasswordInputs = false;
  public profileAvatar = null;
  public taskboardPermissions: LocalDataSource;
  public permissions = <any>{};
  private filter;
  public isMyProfile = false;
  public isEditEmployee = false;
  public isEmployeeEmailChanged = false;
  public taskboardPermissionsSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      careHomeName: {
        title: 'Care Home Name',
        filter: true,
        sort: false
      },
      boardName: {
        title: 'Taskboard name',
        filter: true,
        sort: false,
      },
      canIAdd: {
        title: 'Add',
        filter: false,
        sort: false,
        class: 'table-checkbox',
        type: 'html',
        valuePrepareFunction(cell: any, row: any) {
          return row.canIAdd ? `<div class="primary-green">${row.canIAdd}</div>` : row.canIAdd;
        }
      },
      canIWrite: {
        title: 'Write',
        filter: false,
        sort: false,
        class: 'table-checkbox',
        type: 'html',
        valuePrepareFunction(cell: any, row: any) {
          return row.canIWrite ? `<div class="primary-green">${row.canIWrite}</div>` : row.canIWrite;
        }
      },
      canIEdit: {
        title: 'Edit',
        filter: false,
        sort: false,
        class: 'table-checkbox',
        type: 'html',
        valuePrepareFunction(cell: any, row: any) {
          return row.canIEdit ? `<div class="primary-green">${row.canIEdit}</div>` : row.canIEdit;
        }
      },
      canIChange: {
        title: 'Change',
        filter: false,
        sort: false,
        class: 'table-checkbox',
        type: 'html',
        valuePrepareFunction(cell: any, row: any) {
          return row.canIChange ? `<div class="primary-green">${row.canIChange}</div>` : row.canIChange;
        }
      },
      canIDelete: {
        title: 'Delete',
        filter: false,
        sort: false,
        class: 'table-checkbox',
        type: 'html',
        valuePrepareFunction(cell: any, row: any) {
          return row.canIDelete ? `<div class="primary-green">${row.canIDelete}</div>` : row.canIDelete;
        }
      },
      canIClose: {
        title: 'Group manager',
        filter: false,
        sort: false,
        class: 'table-checkbox',
        type: 'html',
        valuePrepareFunction(cell: any, row: any) {
          return row.canIClose ? `<div class="primary-green">${row.canIClose}</div>` : row.canIClose;
        }
      },
    },
    pager: {
      display: false
    }
  };

  constructor(private tableService: TableService,
              private usersService: UsersService,
              private careHomesService: CareHomesService,
              private dictionariesService: DictionariesService,
              private toastrService: NbToastrService,
              private taskboardsService: TaskBoardsService,
              private formBuilder: UntypedFormBuilder,
              private dialogService: NbDialogService,
              private avatarsService: AvatarsService,
              private profilesService: ProfilesService,
              private permissionsService: PermissionsService,
              private authUserService: AuthUserService,
              private employeesService: EmployessService,
              @Inject(DOCUMENT) private document: Document) {
    this.permissions = this.permissionsService.getPermissions();
  }

  ngOnInit() {
    const userId = this.tableService.getValue().elementId ? this.tableService.getValue().elementId : this.tableService.getValue();
    this.isEditEmployee = this.tableService.getValue().isEditEmployee ? this.tableService.getValue().isEditEmployee : false;
    this.createForm();
    this.subscription.add(this.careHomesService.getCareHomesWithIdName()
      .subscribe((response: any) => {
        this.careHomes = response.result.careHomesList;
        response.result.careHomesList.map(i => i.fullName = i.careHomeFullName);
        response.result.careHomesList.map(i => i.id = i.careHomeId);
        this.rolesCareHomes = response.result.careHomesList;
      }
      ));

    this.subscription.add(this.usersService.getUser(userId)
      .subscribe((response: any) => {
        this.updateValues(response.result);
        this.loadEmployees(response.result.defaultCareHomeId);
        this.loadTaskboardPermissions();
      }
      ));
    this.subscription.add(this.dictionariesService.getUserStatuses()
      .subscribe((response: any) => {
        this.statuses = response.result.wordsFromDictionary;
      }
      ));
    this.subscription.add(this.dictionariesService.getUserProfiles()
      .subscribe((response: any) => {
        this.userProfiles = response.result.list;
      }
      ));
    this.subscription.add(this.profilesService.getUserProfiles(userId)
      .subscribe((response: any) => {
        this.roles = response.result.rolesList;
        for (const role of this.roles) {
          this.rolesForm.addControl(`role${role.id}`, this.formBuilder.control(role.careHomes));
        }
      }
      ));
  }

  loadEmployees(userDefaultCareHomeId: number) {
    this.subscription.add(this.employeesService.getActiveEmployeesByCareHome(userDefaultCareHomeId)
      .subscribe((response: any) => {
        response.result.employeesLines.map((i: any) => i.fullName = i.employeeFullName);
        this.employees = response.result.employeesLines;
      })
    );
  }

  createForm() {
    this.form = new UntypedFormGroup({
      avatar: new UntypedFormControl(null),
      id: new UntypedFormControl({ value: null, disabled: true }),
      login: new UntypedFormControl({ value: null, disabled: !this.permissions.USER_MANAGER }, [Validators.required, Validators.minLength(5), Validators.maxLength(25)]),
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      employeeEmail: new UntypedFormControl(null),
      status: new UntypedFormControl({ value: null, disabled: !this.permissions.USER_MANAGER }, [Validators.required]),
      firstName: new UntypedFormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      phoneNumber: new UntypedFormControl(null, [Validators.required, Validators.maxLength(25)]),
      accountType: new UntypedFormControl({ value: null, disabled: !this.permissions.USER_MANAGER }, [Validators.required]),
      surname: new UntypedFormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      defaultCareHome: new UntypedFormControl({ value: null, disabled: !this.permissions.USER_MANAGER }, [Validators.required]),
      employeeId: new UntypedFormControl({ value: null, disabled: !this.permissions.USER_MANAGER }),
      isTechnicalAccount: new UntypedFormControl({ value: false, disabled: this.isEditEmployee }),
      isValidCompanyEmail: new UntypedFormControl({ value: false, disabled: this.isEditEmployee })
    });

    this.form.get('employeeEmail').valueChanges.pipe(distinctUntilChanged()).subscribe((value: any) => {
      this.isEmployeeEmailChanged = true;
    });

    this.rolesForm = new UntypedFormGroup({});
  }

  loadAvatar(avatarId: any) {
    this.avatarsService.getBlobAvatar(avatarId)
      .subscribe((response: any) => {
        this.profileAvatar = response;
      });
  }

  updateValues(user: any) {
    this.userId = user.id;
    this.isMyProfile = this.userId === this.authUserService.getUser().id;
    this.form.patchValue({
      id: user.id,
      login: user.login,
      email: user.email,
      status: user.statusId,
      firstName: user.firstName,
      phoneNumber: user.phoneNumber,
      accountType: user.accountType,
      surname: user.surname,
      defaultCareHome: user.defaultCareHome ? user.defaultCareHomeId : null,
      employeeId: user.employeeId,
      isTechnicalAccount: user.isTechnicalAccount,
      isValidCompanyEmail: user.isValidCompanyEmail
    });
    this.loadAvatar(user.avatarId);
  }

  saveData() {
    const fd = new FormData();
    fd.append('avatarImage', this.form.get('avatar').value);
    fd.append('id', this.form.get('id').value);
    fd.append('originUrl', this.document.location.origin);
    fd.append('login', this.form.get('login').value);
    fd.append('email', this.form.get('email').value);
    fd.append('status', this.form.get('status').value);
    fd.append('firstName', this.form.get('firstName').value);
    fd.append('phoneNumber', this.form.get('phoneNumber').value);
    fd.append('accountType', this.form.get('accountType').value);
    fd.append('surname', this.form.get('surname').value);
    fd.append('defaultCareHome', this.form.get('defaultCareHome').value);
    fd.append('employeeId', this.form.get('employeeId').value);
    fd.append('isTechnicalAccount', this.form.get('isTechnicalAccount').value);
    fd.append('isValidCompanyEmail', this.form.get('isValidCompanyEmail').value);

    this.subscription.add(this.usersService.updateUser(this.userId, fd)
      .subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        this.tableService.closeWindow(true);
      },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }
      ));

  }

  get isAdmin() {
    return this.authUserService.isAdmin();
  }

  changeState(event: any, formField: string) {
    this.form.get(formField).setValue(event.target.checked);
  }

  deleteUser() {
    this.subscription.add(this.usersService.deleteUser(this.userId)
      .subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        this.tableService.closeWindow(true);
      },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }
      ));
  }

  toggleChangePasswordInputs() {
    this.showChangePasswordInputs = !this.showChangePasswordInputs;
  }

  onPasswordChangeClose() {
    this.showChangePasswordInputs = false;
  }

  loadTaskboardPermissions() {
    this.filter = this.taskboardPermissions ? this.taskboardPermissions.getFilter().filters : undefined;
    this.taskboardPermissions = new LocalDataSource();
    this.subscription.add(this.taskboardsService.getUserTaskboardsPermissions(this.userId)
      .subscribe((response: any) => {
        this.taskboardPermissions.load(response.result.taskBoard.taskBoardList);
        this.taskboardPermissions.setFilter(this.filter);
      }
      ));
  }

  editTaskboardPermissionsLine = (data: any) => {
    data.confirm.resolve();
    const putData = {
      userId: this.userId,
      careHomeName: data.newData.careHomeName,
      boardName: data.newData.boardName,
      canIAdd: data.newData.canIAdd,
      canIChange: data.newData.canIChange,
      canIClose: data.newData.canIClose,
      canIDelete: data.newData.canIDelete,
      canIEdit: data.newData.canIEdit,
      canIWrite: data.newData.canIWrite
    };
    this.taskboardsService.updateUserTaskboardPermissions(data.newData.id, putData)
      .subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
      },
        (err) => this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true })
      );
  }



  deleteTaskboardPermissionsLine = (data: any) => {
    const taskboardId = data.data.id;
    const userId = this.userId;
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Delete confirmation',
          message: 'Are you sure you want to delete? It cannot be undone.',
          okLabel: 'Yes',
          cancelLabel: 'No'
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          this.subscription.add(this.taskboardsService.deleteUserTaskboardPermissions(taskboardId, userId)
            .subscribe((response: any) => {
              data.confirm.resolve();
              this.toastrService.success(response.message, 'Success');
              this.loadTaskboardPermissions();
            },
              (err) => {
                this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
                data.confirm.reject();
              }
            ));
        }
      }
      );
  }

  public roleId;
  public add = false;
  @ViewChild('select', { static: false }) select: any;
  public roles = [];
  public rolesCareHomes = [];
  public rolesForm: UntypedFormGroup;
  public selectAll: boolean = false;

  setFocus = () => {
    this.add = true;
    this.select.open();
  }

  isInRoles(idToFind: number) {
    return this.roles.find((element: any) => element.id == idToFind);
  }

  onChangeRole(role: any) {
    if (!this.isInRoles(role.id)) {
      role['careHomes'] = [];
      this.roles.push(role);
      this.rolesForm.addControl(`role${role.id}`, this.formBuilder.control(null));
    }
    console.log(this.rolesForm)
  }

  addRole() {
    this.setFocus();
  }

  toggleSelectAll(value: boolean, fieldName: string) {
    this.selectAll = value;
    if (value) {
      this.rolesForm.get(fieldName).setValue(this.rolesCareHomes);
    } else {
      this.rolesForm.get(fieldName).setValue([]);
    }
  }

  saveRoles() {
    this.roles.forEach((role: any) => {
      role.careHomes = this.rolesForm.get(`role${role.id}`).value;
    });
    console.log(this.roles);
    this.subscription.add(this.profilesService.updateUserProfiles(this.userId, this.roles)
      .subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        this.loadTaskboardPermissions();
      },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }
      ));
  }

  deleteRole = (roleId: number) => {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Delete confirmation',
          message: 'Are you sure you want to delete this role?',
          okLabel: 'Yes',
          cancelLabel: 'No'
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          this.roles = this.roles.filter((element: any) => element.id !== roleId);
          this.saveRoles();
        }
      }
      );
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
