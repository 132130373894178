import { Subscription } from "rxjs";
import { AvatarsService } from "./../../../../shared/avatars.service";
import { AuthUserService } from "./../../../../@core/data/auth-user.service";
import { InboxService } from "./../../../../shared/inbox/inbox.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { NbToastrService } from "@nebular/theme";
import { getServerLink } from "../../../../utilities/utils";

@Component({
  selector: "ngx-message-report",
  templateUrl: "./message-report.component.html",
  styleUrls: ["./message-report.component.scss"],
})
export class MessageReportComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public message: any = {};
  private userId = 0;
  private uid: string;
  public categoryId = "-1";
  public avatarSender = null;
  public avatarTo = null;
  public fromId = "";
  public toId = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MessageReportComponent>,
    private inboxService: InboxService,
    private toastService: NbToastrService,
    private authUserService: AuthUserService,
    private avatarsService: AvatarsService,
  ) {}

  ngOnInit() {
    this.userId = this.authUserService.getUser().id;
    this.uid = this.data.message.messageUid;
    this.fromId = this.data.message.fromId;
    this.toId = this.data.message.toId;
    this.getMessage();
  }

  getMessage() {
    this.inboxService
      .getMessage(
        this.userId,
        this.uid,
        this.categoryId,
        this.authUserService.getCareHomeId(),
        this.fromId,
        this.toId,
      )
      .subscribe((inboxResponse: any) => {
        this.message = inboxResponse.result;
        this.message.message = inboxResponse.result.message.replace(
          /\n/g,
          "<br/>",
        );
        this.checkIfImage(this.message.attachmentsList);
        this.loadAvatar(this.message.fromAvatarId, true);
        this.loadAvatar(this.message.toAvatarId, false);
      });
  }

  loadAvatar(avatarId: any, isSender: boolean) {
    this.avatarsService.getBlobAvatar(avatarId).subscribe((response: any) => {
      isSender ? (this.avatarSender = response) : (this.avatarTo = response);
    });
  }

  checkIfImage(files: any[]) {
    for (const file of files) {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        file.url = null;
        this.loadAttachmentsImage(file.uid);
      }
    }
  }

  loadAttachmentsImage(imageId: any) {
    this.avatarsService.getBlobAvatar(imageId).subscribe((response: any) => {
      this.message.attachmentsList.find((file: any) => {
        if (file.uid === imageId) {
          file.url = response;
        }
      });
    });
  }

  downloadDocument(fileUid: any) {
    this.subscription.add(
      this.inboxService.getFile(fileUid).subscribe(
        (response: any) => {
          window.open(getServerLink(response.link), "_blank");
        },
        (err) => {
          this.toastService.danger("Error");
        },
      ),
    );
  }

  setUpText(s: string) {
    console.log(s);
    return s.replace(/&lt;br\/&gt;/g, "<br/> ");
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
