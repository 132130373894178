import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UsersApiUrls } from "./users.enum";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { TasksApiUrls } from "./../tasks/tasks.enum";

@Injectable()
export class UsersService {
  constructor(private httpClient: HttpClient) {}

  getUsers(
    isActiveOnly: boolean = false,
    rowsFrom?: number,
    rowsTo?: number,
    orderBy?: string,
    orderDirection?: string,
    searchFilter?: string,
  ) {
    return this.httpClient.get(
      `${environment.apiUrl}${UsersApiUrls.users}?activeOnly=${isActiveOnly}&rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&orderBy=${orderBy}&orderDirection=${orderDirection}&searchFilter=${searchFilter}`,
    );
  }

  getUsersForTasksCollaboration(taskId: number, careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${TasksApiUrls.tasks}/${taskId}/users-collaboration?careHomeId=${careHomeId}`,
    );
  }

  getUsersForTasksCollaborationWithAssignedTasks(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${TasksApiUrls.tasks}/0/users-collaboration?careHomeId=${careHomeId}&onlyWithAssignedTasks=true`,
    );
  }

  getUsersReport() {
    return this.httpClient.get(
      `${environment.apiUrl}${UsersApiUrls.users}/active-users-report`,
    );
  }

  getUser(id: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${UsersApiUrls.users}/${id}`,
    );
  }

  addNewUser(data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${UsersApiUrls.users}`,
      data,
    );
  }

    registerNewAccount(data: any) {
    return this.httpClient.post(`${environment.apiUrl}/quickBMS/companies`, data);
  }

  updateUser(id: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${UsersApiUrls.users}/${id}`,
      data,
    );
  }

  deleteUser(id: number) {
    return this.httpClient.delete(
      `${environment.apiUrl}${UsersApiUrls.users}/${id}`,
    );
  }

  deleteUserSession(sessionId: number) {
    return this.httpClient.delete(
      `${environment.apiUrl}${UsersApiUrls.users}/active-users/${sessionId}`,
    );
  }

  getUserPermissions(id: number, careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${UsersApiUrls.users}/${id}${UsersApiUrls.permissions}?careHomeId=${careHomeId}`,
    );
  }

  getAvatar(id: any): Observable<Blob> {
    return this.httpClient.get(
      `${environment.apiUrl}${UsersApiUrls.users}/${id}/avatars`,
      { responseType: "blob" },
    );
  }

  getMessagesAddresBook(userId: number, careHomeId: number, type?: string) {
    return this.httpClient.get(
      `${environment.apiUrl}/users/${userId}/messages/address-book?careHomeId=${careHomeId}&type=${type}`,
    );
  }

  getMessagesAddresBookForHomes(
    userId: number,
    careHomeIds: number[],
    type?: string,
  ): Observable<any> {
    return this.httpClient.get(
      `${environment.apiUrl}/users/${userId}/messages/address-book?careHomeId=${careHomeIds}&type=${type}`,
    );
  }

  updateFilterSettings(id: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${UsersApiUrls.users}/${id}/filter-settings`,
      data,
    );
  }

  getFilterSettings(id: number, page: string) {
    return this.httpClient.get(
      `${environment.apiUrl}${UsersApiUrls.users}/${id}/filter-settings/${page}`,
    );
  }

  deleteFilterSettings(id: number, userId: number) {
    return this.httpClient.delete(
      `${environment.apiUrl}${UsersApiUrls.users}/${userId}/filter-settings/${id}`,
    );
  }
}
