import { Component, OnDestroy, Inject } from '@angular/core';
import { AuthService } from '../../../@core/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { AuthUserService, USER_KEY } from '../../../@core/data/auth-user.service';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { DOCUMENT } from '@angular/common';

const REDIRECT_DELAY = 500;
enum FormTypes { login, changePassword }

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();
  public FormTypes: typeof FormTypes = FormTypes;
  public formType: FormTypes = FormTypes.login;
  public error: string = '';
  public additionalErrorMessage: string = '';
  public additionalMessage: string = '';
  public firsTimeLogin: boolean = false;
  public user: any = {login: '', password: '', rememberMe: false};
  public submitted: boolean = false;
  public isBetaVersion = false;
  private userLogin: string;

  constructor(protected router: Router,
              protected toastrService: NbToastrService,
              protected authService: AuthService,
              protected authUserService: AuthUserService,
              @Inject(DOCUMENT) private document: Document,
              protected httpClient: HttpClient,
              protected route: ActivatedRoute) {
    this.isBetaVersion = environment.beta;
    this.route.queryParams.subscribe(params => {
      this.userLogin = params.userLogin;
      if (this.userLogin) this.user.login= this.userLogin;
    });
    if (localStorage.getItem(USER_KEY)) {
      this.handleSuccess()
    }
  }

  loginToSystem(): void {
    this.error = '';
    this.submitted = true;

    this.subscription.add(this.authUserService.login(this.user)
      .subscribe((result: any) => {
        if (result.firstTimeLogin) {
          this.submitted = false;
          this.formType = FormTypes.changePassword;
          this.firsTimeLogin = true;
          this.toastrService.info('Please change your password', 'Info', { duration: 0 });
          this.toastrService.success('Successfully logged in to the system', 'Success');
        } else {
          this.handleSuccess();
        }
      },
      (err) => {
        this.handleError(err);
      }
    ));
  }

  resetPass(): void {
    this.error = '';
    this.submitted = true;

    const modifiedUser = {
      login: this.user.login,
      originUrl: this.document.location.origin,
      passwordOld: this.user.passwordOld,
      passwordNew: this.user.passwordNew
    };

    this.subscription.add(this.authService.resetPassword(modifiedUser)
      .subscribe(() => {
        this.handleSuccess();
      },
      (err) => {
        this.handleError(err);
      }
    ));
  }

  handleSuccess() {
    this.submitted = false;
    setTimeout(() => {
      let url = '/dashboard';
      const redirectUrl = this.authUserService.getRedirectUrl();
      if (redirectUrl && redirectUrl.length > 0) {
        url = redirectUrl;
        this.authUserService.deleteRedirectUrl();
      }
      return this.router.navigateByUrl(url);
    }, REDIRECT_DELAY);
  }

  handleError(err: any): void {
    this.submitted = false;
    const errorToShow = err.error.errors ? err.error.errors[0].message : 'There is no internet connection';
    this.toastrService.danger(errorToShow, 'Error', { duration: 60000, destroyByClick: true });
    this.error = err.error.errors ? err.error.errors[0].message : errorToShow;
    this.additionalErrorMessage = err.error.errors ? err.error.errors[0].extendedMessage : '';
    this.additionalMessage  = err.error.errors ? err.error.errors[0].additionalMessage : '';
  }

  // getHelp() {
  //   this.subscription.add(this.httpClient.get(`${environment.apiUrl}/help-files-public?area=login-to-echo-system`)
  //     .subscribe((response: any) => {
  //       window.open(getServerLink(response.link), '_blank');
  //     })
  //   );
  // }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
