import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ICareHome } from '../../../shared/interfaces/care-home.interfaces';
import * as moment from 'moment';
import { IWeekChanges, RotaMigrationService } from '../rota-migration.service';
import { Subscription } from 'rxjs';
import { CareHomesService } from "../../../../shared/care-homes/care-homes.service";
import { AuthUserService } from "../../../../@core/data/auth-user.service";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { getErrorMessage } from "../../../../utilities/utils";
import { QuestionDialogComponent } from "../../../shared/question-dialog/question-dialog.component";
import { EmployessService } from "../../../../shared/employess/employess.service";
import {
  IDataToSave,
  IScheduleWeekInfoDepList,
  IShiftMigrationApiResponse
} from "../../../shared/interfaces/shifts.interfaces";

@Component({
  selector: 'ngx-rota-migration-table-week',
  templateUrl: './rota-migration-table-week.component.html',
  styleUrls: ['./rota-migration-table-week.component.scss']
})
export class RotaMigrationTableWeekComponent implements OnInit, OnDestroy {

  @Output() sendUpdatedData = new EventEmitter<IScheduleWeekInfoDepList[]>();
  @Input() employeeId: number;
  @Input() homeId: number;
  @Input() homeName: string;
  @Input() weekDate: string;
  @Input() weekDateEnd: string;
  @Input() year: string;
  @Input() data: IScheduleWeekInfoDepList[];
  @Input() weekNo: string;
  private _careHomes: ICareHome[];
  @Input()
  get careHomes(): ICareHome[] {
    return this._careHomes;
  }
  set careHomes(value) {
    this._careHomes = value.filter((x) => x.careHomeFullName !== 'ALL CARE HOMES');
    this._careHomes = [{ careHomeId: 0, careHomeFullName: '-' }, ...this._careHomes] as ICareHome[];
  }
  public days: any = [];
  public isEditMode: boolean = false;
  public isDataLoaded = true;

  private _saveObject: any = [];
  private _changedData: IWeekChanges[] = [];

  public subscription: Subscription = new Subscription();

  constructor(
    private rotaMigrationService: RotaMigrationService,
    private employeeService: EmployessService,
    private careHomesSevice: CareHomesService,
    private authUserService: AuthUserService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService
  ) {
    this.subscription.add(
      this.rotaMigrationService.weekChanges$.subscribe(res => {
        this._changedData = res;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {

    const date = moment(this.weekDate).startOf('week');
    for (let i = 1; i <= 7; i++) {
      const newDate = moment(date).add(i, 'day');
      this.days.push({
        dayDate: newDate,
      });
    }
  }

  isToday(date: any) {
    return date && moment().isSame(date, 'd');
  }

  editToggle() {
    this.isEditMode = !this.isEditMode;
  }

  saveData() {
    this.isEditMode = false;
    this.rotaMigrationService.saveData(this.weekNo);
  }

  objectToPush(contract: IDataToSave) {
    return {
      contractId: contract.contractId,
      count: 1,
      ready: false,
      [contract.fieldName]: {
        careHomeId: contract.careHomeId,
        positionId: contract.positionId,
        scheduleFrom: contract.from,
        scheduleTo: contract.to,
        scheduleDate: contract.scheduleDate,
        confirmedByManger: true,
        mode: "shift-migration"
      },
      contractCareHomeId: this.homeId,
      contractCareHomeName: this.homeName,
      dayToSave: 'FULL_WEEK',
      employeeId: this.employeeId,
      weekNo: this.weekNo,
      weekStartDate: this.weekDate,
      weekEndDate: this.weekDateEnd,
      yearNo: this.year,
      isAgency: false,
      isSleeper: false,
      isExtrasToBeTaken: false
    };
  }

  onSaved($event: IDataToSave) {
    let canBeSaved = false;
    const weeks = [];
    let contracts = [];
    this._changedData.forEach(week => {
      weeks.push(week.weekNo);
      contracts = [...contracts, ...week.contractIds];
    });
    if (weeks.includes($event.weekNo) && contracts.includes($event.contractId)) {
      canBeSaved = true;
    }

    if (!canBeSaved) {return; }
    if (this._saveObject.length == 0) {
      this._saveObject.push(this.objectToPush($event));
    } else {
      if (!this._saveObject.some(contract => contract.contractId == $event.contractId)) {
          this._saveObject.push(this.objectToPush($event));
      }
    }

    this._saveObject.forEach(contract => {
      if (contract.contractId == $event.contractId) {
        if (contract.count == 7) {
          contract.ready = true;
        }
        if (!contract.hasOwnProperty($event.fieldName)) {
          contract.count = contract.count + 1;
          contract[$event.fieldName] = {
            careHomeId: $event.careHomeId,
            positionId: $event.positionId,
            scheduleFrom: $event.from,
            scheduleTo: $event.to,
            scheduleDate: $event.scheduleDate,
            confirmedByManger: true,
            mode: "shift-migration"
          };
          if (contract.count == 7) {
            contract.ready = true;
          }
        }
      }
    });

    if (this._saveObject.every(contract => contract.ready)) {
      this._saveObject.forEach(contract => {
        setTimeout(() => {
          delete contract.count;
          delete contract.ready;
          const index = this._saveObject.findIndex(item => {
            return item.contractId == contract.contractId;
          });
          this._saveObject.splice(index, 1);
          this.sendDataToSave(contract);
        }, 250);
      });
      this.rotaMigrationService.clearSavedWeek($event.weekNo);
    }
  }

  sendDataToSave(data: any) {
    this.subscription.add(
      this.careHomesSevice.updateContractSchedule(
        this.authUserService.getCareHomeId(),
        data
      ).subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        this.rotaMigrationService.saveSuccessData({
          weekNo: data.weekNo,
          contractId: data.contractId,
          saved: true
        });
        this.reloadData();
      }, (error) => {
        const errorsArray = error.error.errors || [];
        let errorCode = -1;
        if (errorsArray.length) {
          errorCode = errorsArray[0].extendandErrorCodeId || -1;
        } else {
          this.toastrService.danger(getErrorMessage(error), 'Error', {
            duration: 60000,
            destroyByClick: true,
          });
        }
        if (errorCode === 101) {
          this.subscription.add(
            this.dialogService
              .open(QuestionDialogComponent, {
                closeOnBackdropClick: false,
                context: {
                  title: 'Overwrite schedule',
                  message: getErrorMessage(error),
                  okLabel: 'Overwrite',
                  cancelLabel: 'Cancel',
                },
              })
              .onClose.subscribe((decision: any) => {
              if (decision) {
                data['forceExtra'] = true;
                this.subscription.add(
                  this.careHomesSevice
                    .updateContractSchedule(this.authUserService.getCareHomeId(), data)
                    .subscribe((response: any) => {
                        this.toastrService.success(response.message, 'Success');
                        this.rotaMigrationService.saveSuccessData({
                          weekNo: data.weekNo,
                          contractId: data.contractId,
                          saved: true
                        });
                        this.reloadData();
                      },
                      (innerErr: any) => {
                        this.toastrService.danger(getErrorMessage(innerErr), 'Error', { duration: 60000, destroyByClick: true }
                        );
                      }
                    )
                );
              }
            })
          );
        } else {
          this.toastrService.danger(getErrorMessage(error), 'Error', {
            duration: 60000,
            destroyByClick: true,
          });
        }
      })
    );
  }

  reloadData() {
    this.isDataLoaded = false;
    this.employeeService.getMigrationShiftsData(this.employeeId).subscribe((response: IShiftMigrationApiResponse) => {
      const updatedData = response.result.scheduleInfoList.find(week => week.weekNo == this.weekNo);
      this.data = updatedData.scheduleWeekInfoDepList;
      this.isDataLoaded = true;
      this.sendUpdatedData.emit(this.data);
    });
  }
}
