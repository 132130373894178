<div class="resolve-conflict" (click)="selectedChange.emit()">
  <div
    class="select-option change-to"
    [ngClass]="{ selected: control.value === difference.changeTo }"
    (click)="select('changeTo')"
  >
    <div class="title">change to:</div>
    <div class="option">{{ getDisplayValue(difference.changeTo) }}</div>
  </div>
  <div
    class="select-option original"
    [ngClass]="{ selected: control.value === difference.original }"
    (click)="select('original')"
  >
    <div class="title">original:</div>
    <div class="option">{{ getDisplayValue(difference.original) }}</div>
  </div>
</div>
