<input
  name="from"
  placeholder="00:00"
  class="form-control"
  [disabled]="disabled"
  [(ngModel)]="value"
  (ngModelChange)="onChange(value)"
  hourChars="true"
  (keyup.backspace)="setBackspaceActive()"
  (keyup)="filterValues(value); autoFill && value != null && autofillValue(value)"
  (keydown)="makeListNotVisible()"
  (keydown.Tab)="value != null && autofillValue(value)"
  (focusin)="makeListVisible()"
  minlength="1"
  maxlength="5"
  autocomplete="off"
  cdkOverlayOrigin
  [ngClass]="{
    'field-error': fieldError
  }"
  #trigger="cdkOverlayOrigin"
  #agCellInput
/>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
>
  <div class="rota-table-selector-list thin-echo-scrollbar">
    <div
      *ngFor="let item of filteredValues"
      (click)="setValue(item, $event)"
      class="rota-table-selector-item"
      [class.rota-table-selector-item-extra]="['AM', 'PM', 'LONG', 'NIGHT'].includes(item)"
    >
      {{ item }}
    </div>
  </div>
</ng-template>
