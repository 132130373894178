import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  constructor(private httpClient: HttpClient) {}

  saveMessageTemplate(message: any) {
    return this.httpClient.post(`${environment.apiUrl}/messages/messages-templates`, message);
  }

  getMessageTemplates(careHomeId?: number) {
    const additional = careHomeId ? `?careHomeId=${careHomeId}` : '';
    return this.httpClient.get(`${environment.apiUrl}/messages/messages-templates${additional}`);
  }

  getMessageTemplateDetails(templateId: string) {
    return this.httpClient.get(`${environment.apiUrl}/messages/messages-templates/${templateId}`);
  }

  updateMessageTemplate(message: any, templateId: number) {
    return this.httpClient.put(`${environment.apiUrl}/messages/messages-templates/${templateId}`, message);
  }

  deleteTemplate(templateId: string) {
    return this.httpClient.delete(`${environment.apiUrl}/messages/messages-templates/${templateId}`);
  }

  sendSupportMessage(message: FormData) {
    return this.httpClient.post(`${environment.apiUrl}/messages/support`, message);
  }

  getMessageTemplateByType(careHomeId: number, supportArea: string) {
    const additional = `?careHomeId=${careHomeId}&supportArea=${supportArea}`;
    return this.httpClient.get(`${environment.apiUrl}/messages/messages-templates${additional}`);
  }
}
