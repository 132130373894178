import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PayrollApiUrls } from './payroll.enum';
import { AuthUserService } from '../../@core/data/auth-user.service';
import * as moment from 'moment';

@Injectable()
export class PayrollService {

  constructor(private httpClient: HttpClient,
              private authUserService: AuthUserService) {}

  getPayrolls(startDate: any, endDate: any) {
    return this.httpClient.get(`${environment.apiUrl}${PayrollApiUrls.careHomePayrolls.replace('$chId', <any>this.authUserService.getCareHomeId())}?startDateFormated=${startDate}&endDateFormated=${endDate}`);
  }

  getPayroll(id: number) {
    return this.httpClient.get(`${environment.apiUrl}${PayrollApiUrls.payrolls}/${id}`);
  }

  updatePayroll(id: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${PayrollApiUrls.payrolls}/${id}`, data);
  }

  deletePayroll(id: number) {
    return this.httpClient.delete(`${environment.apiUrl}${PayrollApiUrls.payrolls}/${id}`);
  }

  addPayroll(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${PayrollApiUrls.payrolls}`, data);
  }

  updatePayrollStatus(id: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${PayrollApiUrls.payrolls}/${id}/status`, data);
  }

  sendEmails(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${PayrollApiUrls.payrolls}/send-emails`, data);
  }

}
