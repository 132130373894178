<div class="logo">
  <img src="assets/images/logo_echo.svg" alt=""/>
</div>
<h1 id="title" class="title">Forgot Password</h1>
<p class="sub-title">Enter your email address and we'll send a link to reset your password</p>

<nb-alert *ngIf="error && !submitted" outline="danger" role="alert">
  <span class="alert-text">{{ error }}</span>
</nb-alert>

<form (ngSubmit)="requestPass()" #requestPassForm="ngForm" aria-labelledby="title">
  <div class="form-control-group">
    <label class="label" for="input-email">Enter your email address:</label>
    <input nbInput
            [(ngModel)]="user.email"
            #email="ngModel"
            id="input-email"
            name="email"
            pattern=".+@.+\..+"
            placeholder="Email address"
            autofocus
            fullWidth
            [status]="email.dirty ? (email.invalid  ? '' : 'success') : ''"
            required
            [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="error-message" *ngIf="email.errors?.required">
        Email is required!
      </p>
      <p class="error-message" *ngIf="email.errors?.pattern">
        Email should be the real one!
      </p>
    </ng-container>
  </div>

  <button nbButton
          fullWidth
          [status]="'warning'"
          class="btn btn-success"
          [disabled]="submitted || !requestPassForm.valid">
    REQUEST PASSWORD
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login"><i class="nb-arrow-left" aria-hidden="true"></i> Back to Log In</a></p>
</section>
