<div class="header">
  <p *ngIf="label" class="label">{{ label }}</p>
  <div class="flex">
    <ngx-thinner-switcher *ngIf="!disabled && showSecondLabelToggle" class="ml-1" [firstValue]="false"
      [secondValue]="true" [firstValueLabel]="'Show Emails'" [secondValueLabel]="''" [value]="showAdditionalLabel"
      (valueChange)="toggleAdditionalLabel($event)"></ngx-thinner-switcher>
    <ngx-thinner-switcher *ngIf="!disabled && !noSelectAll" class="ml-1" [firstValue]="false" [secondValue]="true"
      [firstValueLabel]="'Select all'" [secondValueLabel]="''" [value]="selectAll"
      (valueChange)="toggleSelectAll($event)"></ngx-thinner-switcher>
  </div>
</div>
<ng-select #selector [items]="pickValues" class="select-selector" [multiple]="true" [bindLabel]="bindLabel"
  [closeOnSelect]="false" [placeholder]="placeholder" [virtualScroll]="true" (ngModelChange)="onChange($event)"
  (change)="checkModel()" (clear)="clear($event)" (remove)="remove($event)" [disabled]="disabled"
  [ngClass]="{ 'one-row': useShortForm }" [(ngModel)]="val" [groupBy]="groupBy" [selectableGroup]="true"
  [selectableGroupAsModel]="false" [appendTo]="appendTo">
  <ng-template ng-optgroup-tmp let-item="item">
    <span [ngStyle]="{ color: item.color ? item.color : '' }">{{
      item[groupBy] || "Unnamed group"
      }}</span>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
    <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone: true }" />
    <span [ngOptionHighlight]="search" [ngStyle]="{ color: item.color ? item.color : '' }">{{ item[bindLabel] }}
      <span *ngIf="secondBindLabel">, {{ item[secondBindLabel] }}</span></span>

  </ng-template>
  <ng-template *ngIf="acceptColorsFromElements" ng-label-tmp let-item="item" let-clear="clear">
    <span [ngStyle]="{
        background: item.background ? item.background : '',
        color: item.color ? item.color : ''
      }" [ngOptionHighlight]="search">{{ item[bindLabel] }}
      <span *ngIf="showAdditionalLabel && secondBindLabel" style="color: #fff">&nbsp; {{ item[secondBindLabel]
        }}</span><span *ngIf="showClear" class="ng-value-icon right" (click)="clear(item)"
        aria-hidden="true">×</span></span>
  </ng-template>
  <ng-template *ngIf="!acceptColorsFromElements" ng-label-tmp let-item="item" let-clear="clear">
    <span [ngOptionHighlight]="search">{{ item[bindLabel] }}
      <span *ngIf="showAdditionalLabel && secondBindLabel" style="color: #fff">&nbsp; {{ item[secondBindLabel]
        }}</span><span *ngIf="showClear" class="ng-value-icon right" (click)="clear(item)"
        aria-hidden="true">×</span></span>

  </ng-template>
  <ng-template *ngIf="useShortForm" ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngFor="let item of items">
      <span class="ng-value-label">{{ item[bindLabel] }}</span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </div>
  </ng-template>
</ng-select>