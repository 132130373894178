import { Pipe, PipeTransform } from '@angular/core';
import { CareHomeModel } from "../../../shared/models/care-homes/care-home/care-home-model";

@Pipe({name: 'homeCare'})
export class HomeCareNamePipe implements PipeTransform {
  transform(value: number | string, dict: any[]): string {
    if (value == 0 || value == null) { return ''; }

    if (typeof value == "string") {
      value = parseInt(value, 10);
    }

    if (dict[0].careHomeFullName) {
      return dict.filter((role) => role.careHomeId == value )[0].careHomeFullName;
    } else {
      return dict.filter((role) => role.id == value )[0].name;
    }
  }
}
