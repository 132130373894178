import {
  Component,
  Input,
  ViewChild,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output,
} from "@angular/core";
import * as moment from "moment";
import { EventType } from "../event-type.enum";
import { EditAnnualLeavingComponent } from "../../annual-leaving/edit-annual-leaving/edit-annual-leaving.component";
import { EditSicknessComponent } from "../../sickness/edit-sickness/edit-sickness.component";
import { TableService } from "../../../shared/table/table.service";
import {
  NbDialogService,
  NbPopoverDirective,
  NbToastrService,
} from "@nebular/theme";
import { PermissionsService } from "../../../../@core/data/permissions.service";
import { FormMode } from "../../../../utilities/form-mode.enum";
import { Subscription } from "rxjs";
import { AnnualLeavingService } from "../../../../shared/employess/annual-leaving/annual-leaving.service";
import { getErrorMessage } from "../../../../utilities/utils";
import { QuestionDialogComponent } from "../../../shared/question-dialog/question-dialog.component";
import { EmployessService } from "../../../../shared/employess/employess.service";
import { NewSicknessComponent } from "../../sickness/new-sickness/new-sickness.component";
import { NewAnnualLeavingComponent } from "../../annual-leaving/new-annual-leaving/new-annual-leaving.component";
import { BackPage } from '../../../shared/table/types/back-page.interface';
import { SharedAnnualLeavingService } from '../../../shared/services/shared-annual-leaving.service';

@Component({
  selector: "ngx-overview-cell",
  templateUrl: "./overview-cell.component.html",
  styleUrls: ["./overview-cell.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewCellComponent implements OnDestroy, OnInit {
  private subscription: Subscription = new Subscription();
  public EventType: typeof EventType = EventType;

  @Input() date: any;
  @Input() isHeaderCell: boolean = false;
  @Input() hideAlButton = false;
  @Input() event: any = {};
  @Input() isStartCell: boolean = false;
  @Input() isEndCell: boolean = false;
  @Input() employeeId: number;
  @Input() careHomeId: number;
  @Output() refreshData = new EventEmitter();
  @ViewChild(NbPopoverDirective, { static: false }) popover;
  public permissions = <any>{};
  public isEmpty: boolean = false;
  public isCellDropdownVisible: boolean = false;
  public isWindowOpen: boolean = false;
  constructor(
    private tableService: TableService,
    private permissionsService: PermissionsService,
    private toastrService: NbToastrService,
    private annualLeavingService: AnnualLeavingService,
    private dialogService: NbDialogService,
    private employeesService: EmployessService,
    private _sharedAnnualLeavingService: SharedAnnualLeavingService
  ) {
    this.permissions = this.permissionsService.getPermissions();
  }

  getDate(date: any) {
    return date && moment(date).format("DD/MM/YYYY");
  }

  isToday(date: any) {
    return date && moment().isSame(date, "d");
  }

  isLastFive(date: any) {
    const dayOfMonth = +moment(date).format('DD')
    const daysOfMonth = moment(date).daysInMonth()
    let isInLastFive = dayOfMonth + 4 > daysOfMonth;
    return isInLastFive
  }

  ngOnInit(): void {
    this.isEmpty = !this.event;
  }
  showEvent() {
    this.popover.hide();
    const type = this.event.eventType.wordFullName;
    const isAL =
      type == EventType.ANNUAL_LEAVE ||
      type == EventType.MATERNITY_LEAVE ||
      type == EventType.REQUEST_OFF;
    const component = isAL ? EditAnnualLeavingComponent : EditSicknessComponent;
    const title = `${isAL ? `Leave` : `Sickness`} Information - ${
      this.event.eventNo
    }`;
    const formMode = this.event.requiresAcceptance
      ? FormMode.DECIDE
      : FormMode.EDIT;

    const backPage: BackPage = {
      component: this._sharedAnnualLeavingService.isAl ? EditAnnualLeavingComponent : EditSicknessComponent,
      title: `${this._sharedAnnualLeavingService.isAl ? `Leave` : `Sickness`} Information - ${this._sharedAnnualLeavingService.eventNo}`,
      elementId: this._sharedAnnualLeavingService.elementId,
      eventNo: this._sharedAnnualLeavingService.eventNo,
      isAl: this._sharedAnnualLeavingService.isAl
    }

    this.tableService.openWindowWithBackStack(component, title, backPage, {
      mode: formMode,
      elementId: this.event.id
    });
    this._sharedAnnualLeavingService.eventNo = this.event.eventNo;
    this._sharedAnnualLeavingService.elementId = this.event.id;
    this._sharedAnnualLeavingService.isAl = isAL;
  }

  acceptEvent() {
    const data = {
      acceptanceDate: new Date().toISOString(),
      firstDayAvailable: this.event.firstDayAvailable,
      acceptedById: 1,
      requiresAcceptance: false,
      description: this.event.description,
    };
    this.subscription.add(
      this.annualLeavingService.acceptEvent(this.event.id, data).subscribe(
        (response: any) => {
          this.toastrService.success(response.message, "Success");
          this.tableService.justRefreshData();
          this.refreshData.emit();
        },
        (err) =>
          this.toastrService.danger(getErrorMessage(err), "Error", {
            duration: 60000,
            destroyByClick: true,
          })
      )
    );
  }

  rejectEvent() {
    this.subscription.add(
      this.dialogService
        .open(QuestionDialogComponent, {
          closeOnBackdropClick: false,
          context: {
            title: "Explain your rejection reason",
            message:
              "Explain why you are rejecting this AL. This will be Echo messaged to the employee too",
            messageInput: true,
            messagePlaceholder: "Explain why you are rejecting this AL.",
            panelClass: "reject-dialog",
          },
        })
        .onClose.subscribe((decision: any) => {
          if (decision && decision.isConfirm) {
            const data = {
              cancellationDate: new Date().toISOString(),
              cancellationDescription: decision.input,
            };
            this.subscription.add(
              this.employeesService.cancelEvent(this.event.id, data).subscribe(
                (response: any) => {
                  this.toastrService.success(response.message, "Success");
                  this.tableService.justRefreshData();
                  this.refreshData.emit();
                },
                (err) => {
                  this.toastrService.danger(getErrorMessage(err), "Error", {
                    duration: 60000,
                    destroyByClick: true,
                  });
                }
              )
            );
          }
        })
    );
  }

  addSickness() {
    this.isWindowOpen = true;
    this.tableService.openWindow(NewSicknessComponent, "Add new sickness", {
      employeeId: this.employeeId,
      careHomeId: this.careHomeId,
      sicknessStart: this.date,
      closeButtonAdditional: () => this.closeCellDropdown(),
    });
  }

  addAnnualLeaving() {
    this.isWindowOpen = true;
    this.tableService.openWindow(
      NewAnnualLeavingComponent,
      "Add new annual leaving",
      {
        employeeId: this.employeeId,
        careHomeId: this.careHomeId,
        leavingStart: this.date,
        eventType: "Annual leave",
        closeButtonAdditional: () => this.closeCellDropdown(),
      }
    );
  }
  closeCellDropdown() {
    this.isWindowOpen = false;
    this.isCellDropdownVisible = false;
  }
  openCellOptions(event: Event) {
    this.isCellDropdownVisible = false;
    if (this.isWindowOpen) {
      this.isWindowOpen = false;
      return;
    }
    this.isCellDropdownVisible = true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
