import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from "@angular/core";
import { generateDays, generateDaysWithEvents, generateDaysWithSchedule } from "../overview-utils";
import * as moment from "moment";
import { AvatarResource } from "../../../../utilities/avatar-resource.enum";
import { NewSicknessComponent } from "../../sickness/new-sickness/new-sickness.component";
import { EditEmployeeComponent } from "../../edit-employee/edit-employee.component";
import { NewAnnualLeavingComponent } from "../../annual-leaving/new-annual-leaving/new-annual-leaving.component";
import { NbPopoverDirective } from "@nebular/theme";
import { TableService } from "../../../shared/table/table.service";
import { getFullName } from "../../../../utilities/utils";
import { PermissionsService } from "../../../../@core/data/permissions.service";
import { getTruncateName } from '../../../../utilities/utils'
import { EmployeeScheduleCalendar, EmployeeScheduleSummary } from "../overview-calendar/overview-calendar.component";

@Component({
  selector: "ngx-overview-row",
  templateUrl: "./overview-row.component.html",
  styleUrls: ["./overview-row.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewRowComponent implements OnInit {
  public AvatarResource: typeof AvatarResource = AvatarResource;
  @Input() date: any;
  @Input() employeeName: string;
  @Input() hideAlButton = false;
  @Input() contract: any = {};
  @Input() events: any = [];
  @Input() dateRange: any;
  @Input() isSchedule: boolean = false;
  @Input() schedule: EmployeeScheduleCalendar[] = [];
  @Input() scheduleSummary: EmployeeScheduleSummary[] = [];
  @Output() refreshData = new EventEmitter();
  @ViewChild(NbPopoverDirective, { static: false }) popover: any;
  public isCellDropdownVisible: boolean = false;
  public isWindowOpen: boolean = false;
  public days: any = [];
  public daysScheduled: any = [];
  public permissions = <any>{};
  private careHomeId = -1;

  constructor(
    private tableService: TableService,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.permissions = this.permissionsService.getPermissions();
    this.days = generateDaysWithEvents(
      moment(this.date, 'x'),
      this.events,
      this.dateRange
    );
    this.daysScheduled = generateDaysWithSchedule(
      moment(this.date, 'x'),
      this.schedule,
      this.dateRange
    );
    this.careHomeId = this.contract.careHomeId;
  }

  trackByFn(index, item) {
    return index;
  }

  goToEditEmployee() {
    this.isWindowOpen = true;
    this.tableService.openWindowWithoutCareHomeVerification(
      EditEmployeeComponent,
      `Employee Profile Information - ${getFullName(
        this.contract.employee.firstName,
        this.contract.employee.surname
      )}`,
      {
        elementId: this.contract.employeeId,
        closeButtonAdditional: () => this.closeCellDropdown(),
      }
    );
  }

  addSickness() {
    this.isWindowOpen = true;
    this.tableService.openWindow(NewSicknessComponent, "Add new sickness", {
      employeeId: this.contract.employeeId,
      careHomeId: this.careHomeId,
      closeButtonAdditional: () => this.closeCellDropdown(),
    });
  }

  addAnnualLeaving() {
    this.isWindowOpen = true;
    this.tableService.openWindow(
      NewAnnualLeavingComponent,
      "Add new annual leaving",
      {
        employeeId: this.contract.employeeId,
        careHomeId: this.careHomeId,
        eventType: "Annual leave",
        closeButtonAdditional: () => this.closeCellDropdown(),
      }
    );
  }
  closeCellDropdown() {
    this.isWindowOpen = false;
    this.isCellDropdownVisible = false;
  }
  openCellOptions(event: Event) {
    this.isCellDropdownVisible = false;
    if (this.isWindowOpen) {
      this.isWindowOpen = false;
      return;
    }
    this.isCellDropdownVisible = true;
  }

  public getCombinedName = (contract: any): string => (`${contract && contract.employee.firstName} ${contract.employee.surname}`);
  public getCombinedRoleName = (contract: any): string => (`${contract && contract.position.roleName} (${contract.employee.careHome})`);
  public getCombinedLeftHrs = (contract: any): string => (`AL left: ${ contract && contract.leaveSettlements && contract.leaveSettlements.leftHrs}`);
  public getName = (name: string, range: number): string => getTruncateName(name, range);

  isToday(date: any) {
    return date && moment().isSame(date, "d");
  }

  onRefreshData() {
    this.refreshData.emit()
  }
}
