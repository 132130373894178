<table #table mat-table matSort [dataSource]="dataSource">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef style="padding: 10px;" [ngClass]="{'hide': !isSelectableVisible}">
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row" style="padding: 10px;" [ngClass]="{'hide': !isSelectableVisible}">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? toggleRow(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
  </ng-container>
  <ng-container matColumnDef="index">
    <th mat-header-cell *matHeaderCellDef class="header" mat-sort-header><span>No.</span></th>
    <td mat-cell *matCellDef="let index = index"><span>{{index + 1}}</span></td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef class="header" mat-sort-header><span>Actions</span></th>
    <td class="payroll-actions" mat-cell *matCellDef="let element; let index = index">
      <nb-icon title="Show shift on Rota Master" (click)="openRotaWithSpecyficWeek(element.eventDate)" class="actions-icon" icon="eye-outline"></nb-icon>
      <nb-icon title="Show this shift" (click)="openPopupForRole(element)" class="actions-icon" icon="file-text-outline"></nb-icon>
    </td>
  </ng-container>
  <ng-container *ngFor="let column of columns; trackBy: trackByFn" [matColumnDef]="column.fieldName">
    <!-- header -->
    <th mat-header-cell mat-sort-header *matHeaderCellDef
        class="header">
      <span>{{column.title}}</span>
    </th>
    <!-- header -->
    <!-- content -->
    <td mat-cell *matCellDef="let element" (click)="onRowClick && column.fieldType !='select' ? onRowClick(element) : ''">
      <ng-container *ngIf="column.fieldName != 'avatar'">
        <ng-container *ngIf="!column.valuePrepareFunction">
          <span *ngIf="column.fieldType !='select'" (click)="column.action ? column.action($event, element) : null">{{element ? element[column.fieldName] : ''}}</span>
          <span *ngIf="column.fieldType =='select'" class="status-changer">
            <ng-container *ngIf="permissions.PAYROLL_ADJUSTEMENTS_ADD_AND_ACCEPTANCE">
              <div *ngIf="element[column.fieldName] != column.readonlyStatus || (element[column.fieldName] == column.readonlyStatus && permissions.UNLOCK_PAYROLL_ADJUSTEMENTS)" class="form-control-group">
                <select [ngModel]="element[column.fieldName]" (ngModelChange)="column.action($event, element)">
                  <option *ngFor="let status of statuses" [ngValue]="status.wordFullName">{{status.wordFullName}}</option>
                </select>
              </div>
            </ng-container>
            <ng-container *ngIf="!permissions.PAYROLL_ADJUSTEMENTS_ADD_AND_ACCEPTANCE || (element[column.fieldName] == column.readonlyStatus && !permissions.UNLOCK_PAYROLL_ADJUSTEMENTS)">{{element[column.fieldName]}}</ng-container>
          </span>
        </ng-container>
        <span *ngIf="column.valuePrepareFunction" (click)="column.action ? column.action($event, element) : null" [innerHTML]="element ? column.valuePrepareFunction(element) : ''"></span>
      </ng-container>
      <ng-container *ngIf="column.fieldName == 'avatar'">
        <span [ngClass]="{'center-avatar': thinner}" style="cursor: pointer;"
              (click)="showEmployee360($event, element[column.id], element[column.firstNameColumn], element[column.surnameColumn], element[column.fullNameColumn])">
          <img [src]="element[column.avatarId] | avatar | async" class="avatar" [ngClass]="{'thinner-avatar': thinner}" alt="">
        </span>
      </ng-container>
    </td>
    <!-- content -->
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="thinner"></tr>
</table>


