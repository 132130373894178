import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthApiUrls } from './auth.enum';

@Injectable()
export class AuthService {
  constructor(private httpClient: HttpClient) {}

  authenticate(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${AuthApiUrls.login}`, data);
  }

  resetPassword(data: any) {
    return this.httpClient.put(`${environment.apiUrl}${AuthApiUrls.resetPassword}`, data);
  }

  checkEmailToken(token: string) {
    return this.httpClient.get(`${environment.apiUrl}${AuthApiUrls.emailToken}?token=${token}`);
  }

  resetUserPassword(data: any, userId: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/${userId}/password`, data);
  }

  requestPassword(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${AuthApiUrls.requestPassword}`, data);
  }
}
