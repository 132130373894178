<div class="subtasks-container">
  <p [ngStyle]="{ color: '#40dc7e', 'font-weight': '600' }">Subtasks</p>
  <div *ngIf="!isEditorLoaded" class="flash-loader">
    <div class="dot-flashing"></div>
  </div>
  <ng-container>
    <div class="progress-keep">
      <ngx-progress-bar
        [progressPercent]="subtasksProgress"
        [ngStyle]="{ flex: 1 }"
      ></ngx-progress-bar>
      <div
        *ngIf="!isGroupManager && subtasks.length > 0 && canAdd"
        style="display: flex; justify-content: flex-end; margin-right: 0.05rem"
      >
        <button class="btn btn-danger" (click)="keepAllRemainingTodos()">
          K all remaining todos
        </button>
      </div>
    </div>
    <div class="todos" *ngIf="!advancedView">
      <form *ngIf="canAdd" (submit)="addSubtask($event)" class="flex">
        <input
          [(ngModel)]="newSubtaskTitle"
          (keyup.enter)="newSubtaskTitle = ''"
          class="textfield full"
          placeholder="Type SUBTASK title"
          name="newSubtaskTitle"
          autocomplete="off"
        />
        <button
          type="submit"
          [disabled]="newSubtaskTitle === ''"
          class="btn btn-success ml-3"
        >
          Add Subtask
        </button>
        <input hidden id="todo-care-home-id" [value]="careHomeId"/>
      </form>
      <section *ngIf="subtasks.length > 0" class="subtasks">
        <div class="flex" [style.display]="'none'">
          <span class="cell title header">Title</span>
          <ng-container *ngIf="!isGroupManager">
            <span
              class="cell description header"
              [ngClass]="{ bold: updateMode }"
              >Latest update</span
            >
            <span
              class="cell description header right-border"
              [ngStyle]="{ width: updateMode ? '1547px' : '515px' }"
              >Submitted update</span
            >
          </ng-container>
          <ng-container *ngIf="isGroupManager">
            <span class="cell description header right-border"
              >Submitted update</span
            >
          </ng-container>
        </div>

        <div class="subtasks-header-container">
          <span>Title</span>
          <span>Progress</span>
          <span>Actions</span>
        </div>
        <div cdkDropListGroup>
          <nb-accordion [multi]="true">
            <nb-accordion-item
              *ngFor="
                let subtask of subtasks;
                let index = index;
                trackBy: trackByFn
              "
              [collapsed]="!subtask.isExpanded"
              #item
              class="subtask"
            >
              <nb-accordion-item-header
                #accordionItemHeaderComponent
                [ngStyle]="{
                  width: '100%',
                  border: '1px solid #edf1f7',
                  padding: '0.75rem 1.25rem'
                }"
                (click)="resetCollapse()"
              >
                <ngx-subtask-editable-title
                  [style.width.%]="100"
                  [disabled]="!canAdd"
                  [title]="subtask.title"
                  [index]="index"
                  [isFirst]="index == 0"
                  [isLast]="index == subtasks.length - 1"
                  [isDeletedReadonly]="subtask.isDeletedReadonly || false"
                  [subtaskPercent]="subtask.finishedString || '0%'"
                  (click)="$event.preventDefault(); $event.stopPropagation()"
                  (deleteSubtask)="deleteSubtask($event)"
                  (saveTitle)="saveSubtaskTitle($event)"
                  (moveSubtask)="moveSubtask($event)"
                  (recurringTask)="
                    makeRecurringTasks(index, !isRecurring(index))
                  "
                  (toggleAccordion)="accordionItemHeaderComponent.toggle()"
                >
                </ngx-subtask-editable-title>
              </nb-accordion-item-header>
              <nb-accordion-item-body class="main-body">
                <div class="subtask">
                  <div [id]="'index-' + index">
                    <div
                      class="cell description"
                      [id]="index"
                      cdkDropList
                      [cdkDropListData]="subtask.todos"
                      (cdkDropListDropped)="canAdd && onDrop($event)"
                    >
                      <form
                        *ngIf="canAdd"
                        (submit)="addTodo($event, index)"
                        class="flex"
                        id="addTodoBtn"
                      >
                        <input
                          [(ngModel)]="subtasksInputs[index]"
                          (keyup.enter)="subtasksInputs[index] = ''"
                          placeholder="Type TODO name"
                          [id]="'subtask-editor-add-todo-input-' + index"
                          class="textfield"
                          name="newTodo"
                          autocomplete="off"
                        />
                        <button
                          type="submit"
                          [disabled]="subtasksInputs[index] === ''"
                          class="btn btn-success ml-3 todo-add-button"
                        >
                          <nb-icon icon="plus-outline"></nb-icon> Add Todo
                        </button>
                      </form>
                      <ul class="mt-2">
                        <div
                          *ngIf="subtask.todos.length == 0"
                          class="no-subtask-info"
                        >
                          This subtask does yet not have any Todos. Add a Todo
                          otherwise it will be deleted when you click save
                        </div>
                        <li
                          *ngFor="let todo of subtask.todos; let i = index"
                          [id]="i"
                          cdkDrag
                          [cdkDragData]="todo"
                          [cdkDragDisabled]="!editEnabled || editedId == i"
                          (cdkDragStarted)="
                            canAdd && dragStarted($event, subtask.todos, index, i)
                          "
                        >
                          <ngx-subtask-editable-todo
                            [id]="'todo-' + todo.id"
                            [todo]="todo"
                            [task]="task"
                            [subtaskIndex]="index"
                            [disabled]="!canAdd"
                            [toDoindex]="i"
                            [allCollapsed]="allCollapsed"
                            [isGroupManager]="isGroupManager"
                            [updateMode]="updateMode"
                            (onEdit)="editedId = $event ? i : null"
                            (delete)="deleteTodo($event)"
                            (saveComments)="saveTodoComments($event)"
                            (changeStatuses)="saveTodoStatuses($event)"
                            (saveLatestUpdate)="saveTodoLatestUpdate($event)"
                            (addTaskResponse)="addTaskResponse($event)"
                            (changeAssignedUsers)="changeAssignedUsers($event)"
                            (setInfinity)="setTodoInfinity($event)"
                            (resetCollapse)="resetCollapse()"
                            (dblclick)="doubleClick()"
                          >
                          </ngx-subtask-editable-todo>
                          <ng-container *cdkDragPlaceholder>
                            <ngx-subtask-editable-todo
                              [id]="'todo-' + todo.id"
                              [todo]="todo"
                              [task]="task"
                              [subtaskIndex]="index"
                              [disabled]="!canAdd"
                              [toDoindex]="i"
                              [allCollapsed]="true"
                              [isGroupManager]="isGroupManager"
                              [updateMode]="updateMode"
                              [isPlaceholder]="true"
                            ></ngx-subtask-editable-todo>
                          </ng-container>
                          <ng-container *cdkDragPreview>
                            <ngx-subtask-editable-todo
                              class="todo-preview"
                              [id]="'todo-' + todo.id"
                              [todo]="todo"
                              [task]="task"
                              [subtaskIndex]="index"
                              [disabled]="!canAdd"
                              [toDoindex]="i"
                              [allCollapsed]="true"
                              [isGroupManager]="isGroupManager"
                              [updateMode]="updateMode"
                            ></ngx-subtask-editable-todo>
                          </ng-container>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>
        </div>
      </section>
    </div>
    <div class="advanced-todos" *ngIf="advancedView">
      <div class="flex">
        <button
          *ngIf="canAdd"
          (click)="addSubtaskWindow()"
          class="btn btn-info todo-add-button"
          [disabled]="!finishLoading || !canAdd"
        >
          <nb-icon icon="plus-outline"></nb-icon>
          Add column
        </button>
        <button
          *ngIf="canAdd"
          (click)="addTodoWindow()"
          class="btn btn-info todo-add-button"
          [disabled]="!finishLoading || !canAdd || subtasks.length === 0"
          [title]="subtasks.length === 0 ? 'Please add column first' : ''"
        >
          <nb-icon icon="plus-outline"></nb-icon>
          Add row
        </button>
      </div>
      <section class="thin-echo-scrollbar flipped">
        <div class="subtasks-header flipped">
          <div class="grid-header">Action / Item ({{ subtasks.length }})</div>
          <div
            class="subtask-title"
            *ngFor="let title of subtasksTitles; let i = index"
          >
            <div>
              <!--              <nb-icon icon="grid-outline"></nb-icon>-->
              <span
                *ngIf="editId != 'todo_' + i"
                [nbPopover]="subtaskTitlePopover"
                nbPopoverTrigger="hover"
                >{{ title }}</span
              >
              <ng-template #subtaskTitlePopover>
                <div class="subtask-title-popover">{{ title }}</div>
              </ng-template>
              <input
                nbInput
                [id]="'subtask_' + i"
                #todoTitleInput
                *ngIf="todoEditMode && editId == 'todo_' + i"
                [value]="title"
                (focusout)="
                  title != todoTitleInput.value &&
                    editTodoTitle(todoTitleInput.value, title);
                  title == todoTitleInput.value && endEdit()
                "
                (keydown.enter)="
                  title != todoTitleInput.value &&
                    editTodoTitle(todoTitleInput.value, title);
                  title == todoTitleInput.value && endEdit()
                "
                [disabled]="!canAdd"
                class="edit-title-mode"
              />
            </div>
            <div *ngIf="canAdd" style="display: flex; flex-direction: column">
              <nb-icon
                (click)="finishLoading && setEditMode(i, false)"
                icon="edit-outline"
              ></nb-icon>
              <nb-icon
                [ngClass]="{ active: isRecurringTitle(title) }"
                (click)="finishLoading && makeRecurringTasksGrid(title)"
                class="recurring-icon"
                icon="flip-2-outline"
              ></nb-icon>
              <nb-icon
                (click)="finishLoading && deleteTodos(title)"
                icon="trash-2-outline"
              ></nb-icon>
            </div>
          </div>
        </div>
        <div [ngStyle]="{ display: 'flex' }">
          <ng-container *ngFor="let subtask of subtasks; let s = index">
            <div class="subtask-column flipped" cdkDropListGroup>
              <div class="title-header">
                <div class="subtask-input">
                  <!--                <nb-icon icon="grid-outline"></nb-icon>-->
                  <span class="move-icons" *ngIf="canAdd">
                    <span
                      *ngIf="!isFirst(s)"
                      (click)="goUp(s, subtask.title)"
                      class="icon"
                    >
                      <nb-icon icon="arrow-ios-back-outline"></nb-icon>
                    </span>
                    <span
                      *ngIf="!isLast(s)"
                      (click)="goDown(s, subtask.title)"
                      class="icon"
                    >
                      <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
                    </span>
                  </span>
                  <span
                    *ngIf="editId != 'subtask_' + s"
                    [nbPopover]="subtaskColumnTitlePopover"
                    nbPopoverTrigger="hover"
                    >{{ subtask.title }}</span
                  >
                  <ng-template #subtaskColumnTitlePopover>
                    <div class="subtask-title-popover">{{ subtask.title }}</div>
                  </ng-template>
                  <textarea
                    [disabled]="!canAdd"
                    [readOnly]="!canAdd"
                    nbInput
                    [id]="'subtask_' + s"
                    #subtaskTitleInput
                    *ngIf="subtaskEditMode && editId == 'subtask_' + s"
                    [innerHTML]="subtask.title"
                    [(ngModel)]="subtask.title"
                    (focusout)="saveNewTitle(subtaskTitleInput.value, s)"
                    (keydown.space)="$event.stopPropagation()"
                    (keydown.enter)="$event.stopPropagation()"
                    class="edit-title-mode thin-echo-scrollbar-5"
                  ></textarea>
                </div>
                <div class="action-icons" *ngIf="canAdd">
                  <nb-icon
                    *ngIf="subtaskEditMode"
                    icon="edit-outline"
                    (click)="finishLoading && saveNewTitle(subtask.title, s)"
                  ></nb-icon>
                  <nb-icon
                    *ngIf="!subtaskEditMode"
                    icon="edit-outline"
                    (click)="finishLoading && setEditMode(s)"
                  ></nb-icon>
                  <nb-icon
                    [ngClass]="{ active: isRecurring(s) }"
                    (click)="
                      finishLoading && makeRecurringTasks(s, !isRecurring(s))
                    "
                    class="recurring-icon"
                    icon="flip-2-outline"
                  ></nb-icon>
                  <nb-icon
                    icon="trash-2-outline"
                    (click)="finishLoading && deleteSubtask(s, true)"
                  ></nb-icon>
                </div>
              </div>
              <div
                cdkDropList
                [id]="s"
                [cdkDropListData]="subtask.todos"
                (cdkDropListDropped)="onDrop($event)"
              >
                <ng-container
                  *ngFor="let title of subtasksTitles; let i = index"
                >
                  <div
                    class="subtask-todo-container"
                    [id]="i"
                    cdkDrag
                    [cdkDragData]="getTODO(subtask.todos, i)"
                    (cdkDragStarted)="dragStarted($event)"
                  >
                    <div
                      class="subtask-todo thin-echo-scrollbar"
                      *ngIf="getTodo(subtask.todos, i)"
                      [id]="'todoBox-' + getTODO(subtask.todos, i).id"
                      [class.completed]="checkIfCompleted(subtask.todos, i)"
                      [class.notCompleted]="
                        checkIfNotCompleted(subtask.todos, i) ||
                        getLatestUpdate(subtask.todos, i)
                      "
                      (click)="
                        openPopover(
                          getTODO(subtask.todos, i).id,
                          subtask,
                          i,
                          task
                        )
                      "
                    >
                      <ng-container *ngIf="getLatestUpdate(subtask.todos, i)">
                        {{ getLatestUpdate(subtask.todos, i) }}
                      </ng-container>
                      <ng-container *ngIf="!getLatestUpdate(subtask.todos, i)">
                        {{ getShortHistory(subtask.todos, i) }}
                        <div
                          *ngIf="
                            checkIfCompleted(subtask.todos, i) &&
                            getTODO(subtask.todos, i)?.updateHistory?.length
                          "
                          class="short-history update-history-container"
                          [innerHTML]="getComments(getTODO(subtask.todos, i))"
                        ></div>
                      </ng-container>
                    </div>
                    <div
                      class="subtask-todo empty"
                      *ngIf="!getTodo(subtask.todos, i)"
                      (click)="
                        finishLoading &&
                          !createInProgress &&
                          canAdd &&
                          addAdvancedTodo(subtask.titleIndex, title)
                      "
                    >
                      <nb-icon
                        *ngIf="!createInProgress"
                        icon="plus-outline"
                      ></nb-icon>
                    </div>
                    <ng-template #subtaskInfo let-data>
                      <nb-card>
                        <nb-card-header [style.padding-inline.px]="10">
                          Subtask: {{ data.todo.title }}
                        </nb-card-header>
                        <nb-card-body
                          [ngStyle]="{ padding: '0', 'margin-bottom': '-5px' }"
                        >
                          <ngx-subtask-editable-todo
                            *ngIf="popoverId == data.popId"
                            class="subtask-todo-info"
                            [id]="data.popId"
                            [disabled]="!canAdd"
                            [todo]="data.todo"
                            [task]="data.task"
                            [highlight]="data.highlight"
                            [isAdvanced]="true"
                            [subtaskIndex]="data.subtask.titleIndex"
                            [toDoindex]="data.todo.index"
                            [allCollapsed]="allCollapsed"
                            [isGroupManager]="isGroupManager"
                            [updateMode]="updateMode"
                            (delete)="deleteTodo($event, true)"
                            (saveComments)="saveTodoComments($event, true)"
                            (changeStatuses)="saveTodoStatuses($event, true)"
                            (saveLatestUpdate)="
                              saveTodoLatestUpdate($event, true)
                            "
                            (addTaskResponse)="addTaskResponse($event)"
                            (changeAssignedUsers)="
                              changeAssignedUsers($event, true)
                            "
                            (dblclick)="doubleClick()"
                            (setInfinity)="setTodoInfinity($event, true)"
                          >
                          </ngx-subtask-editable-todo>
                        </nb-card-body>
                      </nb-card>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </section>
    </div>
  </ng-container>
</div>
