<main>
    <section id="image-placeholder" *ngIf="!url">
      <div class="circle circle--outer">
        <div class="circle circle--inner">
          <nb-icon *ngIf="!image" icon="person"></nb-icon>
          <img *ngIf="image" [src]="image" alt=""/>
        </div>
      </div>
    </section>
    <section *ngIf="url">
      <ngx-super-croppie
        #ngxSuperCroppie
        [croppieOptions]="croppieOptions"
        [url]="url.toString()"
        [points]="points"
        [orientation]="orientation"
        [zoom]="0"
        [resultOptions]="resultOptions"
        (result)="updateCroppedImage($event)"
      ></ngx-super-croppie>
    </section>

  </main>
