<div class="form-view">
  <main>
    <form [formGroup]="form" autocomplete="off">
      <div class="form-wrapper">
        <div class="form-column">
          <div class="flex-container-horizontal switcher-container">
            <div class="switch-control flex former" [ngClass]="{'hidden': id}">
              <h2>Show former employees</h2>
              <ngx-switcher
                [firstValue]="true"
                [secondValue]="false"
                firstValueLabel="Yes"
                secondValueLabel="No"
                [value]="showFormerEmployees"
                (valueChange)="toggleShowFormerEmployees($event)">
              </ngx-switcher>
            </div>
          </div>
          <div class="flex-container-horizontal middle">
            <div class="form-control-group search-dropdown">
              <label>Employee name:</label>
              <ng-select [items]="employees"
                          #select
                          bindLabel="fullName"
                          dropdownPosition="auto"
                          bindValue="employeeId"
                          labelForId="employeeId"
                          placeholder="Search employee"
                          [virtualScroll]="true"
                          name="input-employee-id"
                          id="input-employee-id"
                          formControlName="employeeId">
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                  <div>
                    <span><b>{{ item.employeeFirstName }}</b> {{ item.employeeSurname }}</span>
                  </div>
                </ng-template>
              </ng-select>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'employeeId', errorMessages) }}
              </p>
            </div>
            <div class="form-control-group">
              <label class="label" for="input-type">Adjustment Type:</label>
              <select formControlName="typeId" (change)="onAdjustmentPick()">
                <option *ngFor="let type of filterTypes; trackBy: trackByFn" [ngValue]="type.id">{{type.wordFullName}}</option>
              </select>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'typeId', errorMessages) }}
              </p>
            </div>

          </div>
          <div class="flex-container-horizontal middle">
            <div class="form-control-group">
              <label class="label" for="input-role">Role:</label>
              <select formControlName="roleId">
                <option *ngFor="let role of roles; trackBy: trackByFn" [ngValue]="role.id">{{role.roleName}}</option>
              </select>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'roleId', errorMessages) }}
              </p>
            </div>
            <div class="form-control-group-double" *ngIf="permissions.PAYROLL_ADJUSTMENT_VALUE; else noPermission">
              <div class="form-control-group">
                <label class="label" for="input-time">Amount of hours:</label>
                <input nbInput
                       fullWidth
                       formControlName="timeAmount"
                       (focusout)="utils.onHoursInput($event, form, 'timeAmount')"
                       name="timeAmount"
                       id="input-time"
                       placeholder="HH:MM">
                <p class="error-message">
                  {{ utils.handleValidationErrorMessage(form, 'timeAmount', errorMessages) }}
                </p>
              </div>
              <div class="form-control-group">
                <label class="label" for="input-time-value">Amount (£) if known:</label>
                <input nbInput
                       fullWidth
                       type="number"
                       formControlName="value"
                       name="value"
                       id="input-time-value"
                       placeholder="">
                <p class="error-message">
                  {{ utils.handleValidationErrorMessage(form, 'value', errorMessages) }}
                </p>
              </div>
            </div>
            <ng-template #noPermission>
              <div class="form-control-group">
                <label class="label" for="input-time">Amount of hours:</label>
                <input nbInput
                       fullWidth
                       formControlName="timeAmount"
                       (focusout)="utils.onHoursInput($event, form, 'timeAmount')"
                       name="timeAmount"
                       id="input-time"
                       placeholder="HH:MM">
                <p class="error-message">
                  {{ utils.handleValidationErrorMessage(form, 'timeAmount', errorMessages) }}
                </p>
              </div>
            </ng-template>
          </div>
          <div class="flex-container-horizontal middle">
            <div class="form-control-group">
              <label class="label" for="input-event">Event date:</label>
              <input nbInput
                      fullWidth
                      formControlName="eventDate"
                      name="eventDate"
                      id="input-event"
                      placeholder="DD/MM/YYYY"
                      [matDatepicker]="eventpicker"
                      minlength="3"
                      maxlength="50">
                    <mat-datepicker-toggle matSuffix [for]="eventpicker"></mat-datepicker-toggle>
                    <mat-datepicker #eventpicker startView="month"></mat-datepicker>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'eventDate', errorMessages) }}
                <ng-container *ngIf="isDateInTheFuture()">Selected date is in the future</ng-container>
              </p>
            </div>
            <p id="note" *ngIf="isAdjustmentAdvancePicked">For advances, you must explain what the advance is for and the number of hours due</p>
            <br *ngIf="isAdjustmentAdvancePicked"/>
            <div class="form-control-group">
              <label class="label" for="input-payroll">To be paid with monthly salary for:</label>
              <select formControlName="payrollMonth">
                <option *ngFor="let month of monthList; trackBy: trackByFn" [ngValue]="month.dateSend">{{month.dateShow}}</option>
              </select>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'payrollMonth', errorMessages) }}
              </p>
            </div>

            <div class="form-control-group">
              <label class="label" for="pay-how">Pay how:</label>
              <select formControlName="isAdvance">
                <option [value]="true">As an advance (see policy first)</option>
                <option [value]="false">At the pay date</option>
              </select>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'payrollMonth', errorMessages) }}
              </p>
            </div>
          </div>
        </div>
        <div class="form-column">
          <div class="form-control-group textarea">
            <label class="label" for="comments">Description:</label>
            <textarea nbInput
                      fullWidth
                      formControlName="comments"
                      name="comments"
                      id="comments"
                      placeholder="Add notes about this payroll"
                      minlength="5"
                      maxlength="1000"
                      class="textarea-with-counter"
                      autofocus></textarea>
            <span class="remaning-counter">{{ remainingCounter }}</span>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'comments', errorMessages) }}
            </p>
          </div>
          <p id="note">AL should not be inputted here. <br>Add AL instead from the 'Annual Leave' section.</p>
        </div>
      </div>
    </form>
  </main>
  <footer class="space-between">
    <div class="flex">
      <button class="btn btn-success"
              *ngIf="!readOnly"
              [disabled]="!utils.isFormValid(form) || !canSave"
              (click)="save()">
        <i class="nb-checkmark" aria-hidden="true"></i> Save
      </button>
      <button class="btn btn-success"
              *ngIf="lockedEmailSave"
              (click)="save()">
        <i class="nb-checkmark" aria-hidden="true"></i> Save
      </button>
      <button class="btn btn-danger"
              [ngClass]="{'ml-4': !readOnly || lockedEmailSave}"
              (click)="closeWindow()">
        <i class="nb-close" aria-hidden="true"></i> Close
      </button>
      <p class="acceptance-message">{{acceptanceMessage}}</p>
      <nb-checkbox (change)="changeEmailOnSave($event)" [checked]="this.form.get('isEmailOnSave').value">email this on save</nb-checkbox>
    </div>

    <div class="flex" *ngIf="!readOnly">
      <button *ngIf="id && status != 'Accepted'"
              class="btn btn-success accept mr-4"
              (click)="acceptPayroll()"
              [disabled]="!permissions.PAYROLL_ADJUSTEMENTS_ADD_AND_ACCEPTANCE">
        <i class="nb-checkmark-circle" aria-hidden="true"></i> Accept payroll
      </button>
      <button class="btn btn-danger"
              (click)="deletePayroll()"
              [disabled]="!permissions.PAYROLL_ADJUSTEMENTS_ADD_AND_ACCEPTANCE">
        <i class="nb-trash" aria-hidden="true"></i> Delete
      </button>
    </div>
  </footer>
</div>
