import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { AuthUserService } from '../../../@core/data/auth-user.service';
import { AvatarsService } from '../../../shared/avatars.service';
import { InboxService } from '../../../shared/inbox/inbox.service';
import { getErrorMessage, getServerLink } from '../../../utilities/utils';
import { NewMessageDialogComponent } from '../new-message-dialog/new-message-dialog.component';
import { PermissionsService } from '../../../@core/data/permissions.service';
import { TableService } from '../../shared/table/table.service';

@Component({
  selector: 'ngx-message-preview',
  templateUrl: './message-preview.component.html',
  styleUrls: ['./message-preview.component.scss']
})
export class MessagePreviewComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  private uid: string;
  private userId = 0;
  public message: any = {};
  public avatarSender = null;
  public sendMessage = false;
  public categoryId = 'all';
  public mailboxName = '';
  public mainCategoryId = '';
  public showUnpin = false;
  public title = '';
  public permissions = <any>{};
  public fromId = '';
  public toId = '';

  constructor(private inboxService: InboxService,
              private dialog: MatDialog,
              private toastService: NbToastrService,
              private authUserService: AuthUserService,
              private avatarsService: AvatarsService,
              public dialogRef: MatDialogRef<NewMessageDialogComponent>,
              public toastrService: NbToastrService,
              @Inject (MAT_DIALOG_DATA) public data: any,
              private tableService: TableService,
              private permissionsService: PermissionsService) {
    this.permissions = this.permissionsService.getPermissions();
  }

  ngOnInit() {
    this.userId = this.authUserService.getUser().id;
    this.uid = this.data.elementId;
    this.title = this.data.title;
    this.getMessage();
  }

  getMessage() {
    this.inboxService.getMessage(this.userId, this.uid, this.categoryId, this.authUserService.getCareHomeId(), this.fromId, this.toId)
    .subscribe((inboxResponse: any) => {
      this.message = inboxResponse.result;
      this.message.message = inboxResponse.result.message.replace(/\n/g, '<br/>');
      this.checkIfImage(this.message.attachmentsList);

      this.sendMessage = (this.mainCategoryId === 'sent');
      if (!this.sendMessage && !this.message.readed) {
        this.markReadStatus(true);
      }
      const avatarToLoad = this.message.ownMail ? this.message.toAvatarId : this.message.fromAvatarId;
      this.loadAvatar(avatarToLoad);
    });
  }

  loadAvatar(avatarId: any) {
    if (avatarId) {
      this.avatarsService.getBlobAvatar(avatarId)
      .subscribe((response: any) => {
        this.avatarSender = response;
      });
    }
  }

  checkIfImage(files: any[]) {
    for (const file of files) {
      if (file.type === 'image/jpeg' || file.type  === 'image/png') {
        file.url = null;
        this.loadAttachmentsImage(file.uid);
      }
    }
  }

  loadAttachmentsImage(imageId: any) {
    this.avatarsService.getBlobAvatar(imageId)
    .subscribe((response: any) => {
      this.message.attachmentsList.find(file => {
        if (file.uid === imageId) {
          file.url = response;
        }
      });
    });
  }

  downloadDocument(fileUid: any) {
    this.subscription.add(this.inboxService.getFile(fileUid)
      .subscribe((response: any) => {
        window.open(getServerLink(response.link), '_blank');
      },
      () => {
        this.toastService.danger('Error');
      })
    );
  }

  markReadStatus(markAsReadOnly?: boolean) {
    let changedStatus: boolean;
    markAsReadOnly ? changedStatus = true : changedStatus = !this.message.readed;
    this.inboxService.markReadMessage(this.userId, this.message.uid, {readed: changedStatus})
    .subscribe(() => {
      this.message.readedAt = 'n/a';
      this.message.readed = !this.message.readed;
    }, () => this.toastService.danger('Error'));
  }

  createReplyForward(type) {
    this.dialog.open(NewMessageDialogComponent, {
      disableClose: true,
      data: {
       type,
       sender: this.message.fromId,
       subject: this.message.subject,
       fromCareHomeId: this.message.fromCareHomeId,
       message: this.message.message.replace(/<br\s*\/?>/gi, '\n'),
       fromName: this.message.fromName,
       fromEmail: this.message.fromEmail,
       toName: this.message.toName,
       toEmail: this.message.toEmail,
       date: this.message.careatedAt,
       replyToUid: this.message.uid,
       scheduleRelated: this.message.scheduleRelated,
       attachmentsList: this.message.attachmentsList
      } });
  }

  changeMessageCategory(newStatusId: number, newStatus: string) {
    const messageRequestPutStatus = {
      newStatusId,
      newStatus,
      messagesIdList: [this.message.uid]
    };
    this.subscription.add(this.inboxService.changeMessageCategory(this.userId, messageRequestPutStatus)
      .subscribe((response: any) => {
        this.toastService.success(response.message);
      },
      () => {
        this.toastService.danger('Error');
      })
    );
  }

  pinMessage(markPined: boolean) {
    this.inboxService.pinMessage(this.userId, this.message.uid, {
      messageHeadUid: this.message.messageHeadUid,
      isPined: markPined,
      careHomeId: this.authUserService.getCareHomeId()
    }).subscribe(() => {
        this.message.isPined = markPined;
        this.tableService.reloadTable();
    }, () => this.toastService.danger('Error'));
  }

  downloadPdf() {
    this.inboxService.downloadMessageAsPdf(this.userId, this.uid)
      .subscribe((response: any) => {
        window.open(getServerLink(response.link), '_blank');
      }, (err) => {
        this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
      });
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
