import { Component, Input, ViewChild } from '@angular/core';

// Third party
import { NbPopoverDirective } from '@nebular/theme';

@Component({
  selector: 'ngx-ui-table-photo-cell',
  templateUrl: './ui-table-photo-cell.component.html',
  styleUrls: ['./ui-table-photo-cell.component.scss'],
})
export class UiTablePhotoCellComponent {
  @Input() photoData: any;
  @Input() employeeFullName: string;
  @Input() employeePositionName: string;

  @ViewChild(NbPopoverDirective, { static: false }) private _popover: NbPopoverDirective;

  closePopover() {
    this._popover.hide();
  }
}
