import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ApplicationPermissions } from "./application-permissions.enum";

const PERMISSIONS_KEY = "echo_permissions";

@Injectable()
export class PermissionsService {
  private userPermissions = this.getPermissionsFromCache();
  private permissions = {
    userPermissions: [],
    taskboardPermissions: <any>{},
  };
  private userLevel: any;
  // set mode when click on the fast-editor for first time
  private isGroupManager: boolean = false;
  public isGroupManager$ = new Subject<boolean>();
  // set mode when change mode in fast-editor window

  haveTaskboardPermissionTo(permissionName: string) {
    return this.permissions.taskboardPermissions[permissionName];
  }

  haveUserPermissionTo(permissionName: string) {
    return this.permissions.userPermissions.find((permission: any) => {
      return permission.permissionName == permissionName;
    });
  }

  setPermissions(permissionsResponse: any) {
    this.permissions = {
      userPermissions: permissionsResponse[0].result.list,
      taskboardPermissions: permissionsResponse[1].result.taskBoard,
    };
    this.userLevel = permissionsResponse[0].result.userLevel;
    for (const [key, value] of Object.entries(ApplicationPermissions)) {
      this.userPermissions[key] = this.haveUserPermissionTo(value);
    }

    this.cachePermissions(this.userPermissions);
  }

  getPermissions() {
    return this.userPermissions;
  }

  getAvailableTaskboards() {
    return this.permissions.taskboardPermissions.taskBoardList;
  }

  getUserLevel() {
    return this.userLevel;
  }

  setIsGroupManager(value: boolean) {
    this.isGroupManager = value;
    this.isGroupManager$.next(value);
  }

  getIsGroupManager() {
    return this.isGroupManager;
  }

  private cachePermissions(permissions: any) {
    localStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions));
  }

  private getPermissionsFromCache() {
    const permissions = JSON.parse(localStorage.getItem(PERMISSIONS_KEY));
    return permissions ? permissions : {};
  }
}
