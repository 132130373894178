import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  OnChanges,
  ChangeDetectionStrategy,
  AfterViewInit,
  NgZone,
  Output, ViewChild, ElementRef,
} from "@angular/core";
import { TableService } from "../table.service";
import { TableFilter } from "../table-filter.enum";
import { Subscription, BehaviorSubject, Subject } from "rxjs";
import * as moment from "moment";
import { LoadingStatus } from "../../loading-status.enum";
import { IconType } from "../../icon-type.enum";
import { Router } from "@angular/router";
import { CareHomesService } from "../../../../shared/care-homes/care-homes.service";
import { TableType, FilterType } from "../table-type.enum";
import { AuthUserService } from "../../../../@core/data/auth-user.service";
import { EmployeeTab } from "../../../employess/employee-tab.enum";
import { debounceTime } from "rxjs/operators";
import { FormControl, UntypedFormGroup } from "@angular/forms";
import { Check } from "../types/check.interface";
import { EventEmitter } from "@angular/core";
import { AwardsSummaryModel } from '../../../../shared/employess/awards/models/awards-summary.interfaces';
import { DictionariesAgencyWordsModel } from '../../../../shared/models/dictionaries/agencies/dictionaries-agencies-model';
import { IAgencyDetailedReportResponseLine } from '../../../../shared/models/agency-report/agency-report.models';
import { TaskContextModelResponse } from "../../../../shared/models/care-homes/task-board/task-context.model";
import { MatPaginator } from "@angular/material/paginator";

export interface DownloadSettings {
  title: "";
  icon: IconType;
  action: (datesRange?: any) => any;
}

const DEBOUNCE_TIME = 500;

@Component({
  selector: "ngx-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @ViewChild('paginator') paginator: MatPaginator;

  private actualFilterValue: string = "";
  public isPinned: boolean = false;
  @Input() cardBodyMaxHeight = 'calc(100vh - 84px)'
  @Input() showPinned = false
  @Input() multiselectorVisible = true
  @Input() title: string = "";
  @Input() data: any = [];
  @Input() filterBy: any = [];
  @Input() columns: any = {};
  @Input() add: () => any;
  @Input() addTraining: () => any;
  @Input() addDisabled: boolean;
  @Input() customAdd: () => any;
  @Input() helpTooltip: () => any;
  @Input() sendEmails: () => any;
  @Input() datesRange: any = {};
  @Input() customAddLabel: string;
  @Input() employeeDetailsTab: EmployeeTab = EmployeeTab.INFO;
  @Input() edit: (event: any) => any;
  @Input() download: DownloadSettings;
  @Input() download2: DownloadSettings;
  @Input() nearFilterAction: () => any;
  @Input() customTotal: { value: string, name: string } = { value: '', name: ''};
  @Input() displayedColumnsFinal: string[] = [];
  @Input() hideAllFilters: boolean = false;
  @Input() hideBottomButtonContainer: boolean = false;
  @Input() isBottomPadding: boolean = false;
  @Input() isZoomCalcDisabled: boolean = false;
  @Input() autoOverflowX: boolean = false;
  @Input() isCustomBottomPadding: boolean = false;
  @Input() customBottomPadding: number = 0;
  @Input() isMainTable: boolean = false;
  @Input() load: (
    activeFilter?: any,
    datesRange?: any,
    actualSelectorValue?: any,
    searchFilter?: any,
    paginationData?: any,
    extendedFilters?: any,
    extendedSort?: any,
    categoryIdFilter?: any,
    statusIdFilter?: any,
    startDate?: any,
    endDate?: any,
    isPinned?: any
  ) => any;
  @Input() careHomeChange: (careHomeId: any) => any;
  @Input() showTableFilter: boolean = false;
  @Input() weekDateFilter: boolean = false;
  @Input() monthDateFilter: boolean = false;
  @Input() weekInput: boolean = false;
  @Input() careHomeFilter: boolean = false;
  @Input() customFilter: boolean = false;
  @Input() clearTop: boolean = false;
  @Input() descriptionTop: boolean = false;
  @Input() descriptionText: string = "";
  @Input() fullHeight: boolean = false;
  @Input() noBorder: boolean = false;
  @Input() datesDropdownVisible: boolean = false;
  @Input() activeOnlyLabel: string = "Active Only";
  @Input() isDisableDateIfActiveOnly: boolean = false;
  @Input() activeFilter: any = TableFilter.ALL;
  @Input() thinner: boolean = false;
  @Input() changeView: () => any;
  @Input() changeViewTitle: string = "";
  @Input() comments: string = "";
  @Input() messageText: string = "";
  @Input() tableType: any = TableType.FULL;
  @Input() filterType: any = FilterType.SIMPLE;
  @Input() autocompleteSelectorValues: any;
  @Input() autocompleteSelectorPlaceholder: any = "";
  @Input() autocompleteSelectorLabel: any = "";
  @Input() actualSelectorValue: any;
  @Input() selectAllSwitch = false;
  @Input() actualFilterTypeValue: any;
  @Input() usePagination: boolean;
  @Input() totalRows = 0;
  @Input() totalRow: boolean = false;
  @Input() paginationSizeOptions;
  @Input() isXTableScroll = false;
  @Input() sortFromBackend: boolean = false;
  @Input() filterEnterEnabled: boolean = false;
  @Input() isDataLoaded: boolean = true;
  @Input() noLoadOnStart: boolean = false;
  @Input() additionalButtons: any = [];
  @Input() checkActualSelector: Check;
  @Input() disableSorting = false;
  @Input() doubleHeader: boolean = false;
  @Input() showIndexColumn: boolean = true;
  @Input() doubleHeaderColumns: string;
  @Input() awardsDataSummary: AwardsSummaryModel[];
  @Input() isLeaversReport: boolean;
  @Input() isAgencyReport: boolean;
  @Input() hideFilter: boolean = false;
  @Input() agencies: DictionariesAgencyWordsModel[];
  @Input() showWagesInMonths = false;
  @Output() textfieldChanged = new EventEmitter();
  @Output() setDatesRange = new EventEmitter();
  @Output() onTextAreaChange: EventEmitter<{element: any, value: string, index: number}> = new EventEmitter<{element: any, value: string, index: number}>();
  @Output() onAgencyChanged: EventEmitter<IAgencyDetailedReportResponseLine> = new EventEmitter<IAgencyDetailedReportResponseLine>();
  public TableType: typeof TableType = TableType;
  public FilterType: typeof FilterType = FilterType;
  public IconType: typeof IconType = IconType;
  public onFilterChange$ = new BehaviorSubject<string>("");
  public searchChanged: Subject<string> = new Subject<string>();
  public tableFilter = TableFilter;
  public refreshSubscription: Subscription = null;
  public LoadingStatus: typeof LoadingStatus = LoadingStatus;
  public dataLoaded: LoadingStatus = LoadingStatus.NOT_LOADED;
  public careHomes: any = [];
  public filterConfigurations: any = [];
  public pickedFilterConfiguration = null;
  public pickedSortConfiguration = null;
  public dateSelected: any;
  public form: UntypedFormGroup;
  public monthList = [];
  public weekList = [];
  public statusesFilterString = "";
  public statuses = [];
  public categoriesFilterString = "";
  public categories: any = [];
  public isShowMore = false;
  public isUsingExtendedFilters = false;
  public isDefaultSortingLoaded = false;
  public currentFilter = "";
  public extendedFilterConfig = null;
  public showMoreText = "Show advanced";
  public defaultCareHomeId = 0;
  public onInit = true
  public paginationData = {
    pageIndex: 0,
    pageSize: 0,
    orderBy: "",
    orderDirection: "",
  };
  private subscription: Subscription = new Subscription();
  public isCareHomeFromFilter = false;
  public selectedItems = [];
  public clearSelection$ = new BehaviorSubject<boolean>(false);
  public selectableVisibility$ = new BehaviorSubject<boolean>(false);
  public isSelectableVisible = false;

  public date: any = {
    start: moment().startOf('isoWeek').add(5, 'hour').toDate(),
    end: moment()
      .startOf('isoWeek')
      .add(1, 'week')
      .subtract(5, 'hour')
      .toDate(),
  };

  @ViewChild('filterInput', {static: false}) filterInput: ElementRef;

  @Input() hasContextBar: boolean = false;
  @Input() cardHeader: string = ''
  @Input() rightMargin: number = 20;
  @Input() bottom: string = '6px';

  private payrollDates = false;
  private firstDateChanged = false;

  constructor(
    protected tableService: TableService,
    protected router: Router,
    private careHomesService: CareHomesService,
    private authUserService: AuthUserService,
    private ngZone: NgZone
  ) {

    this.refreshSubscription = this.tableService.refresh$.subscribe(() => {
      this.load(
        this.activeFilter,
        this.datesRange,
        this.actualSelectorValue,
        this.actualFilterValue,
        this.paginationData,
        this.filterBy,
        this.pickedSortConfiguration,
        this.categoriesFilterString,
        this.statusesFilterString
      )
      if (this.sendEmails) {
        this.selectedItems = [];
        this.clearSelection$.next(true);
      }
    });

    this.searchChanged.pipe(debounceTime(DEBOUNCE_TIME)).subscribe((res) => {
      this.actualFilterValue = res
      this.paginationData.pageIndex = 0;
      if (this.paginator) {
        this.paginator?.firstPage();
      }
      this.load(
        this.activeFilter,
        this.datesRange,
        this.actualSelectorValue,
        this.actualFilterValue,
        this.paginationData,
        this.filterBy,
        this.pickedSortConfiguration,
        this.categoriesFilterString,
        this.statusesFilterString
      );
    });
  }

  myControl = new FormControl<string>('')

  ngOnInit() {
    this.setWeeks();

    if (!this.datesRange.start) {
      this.datesRange = {
        start: this.monthDateFilter
          ? moment().startOf("month").add(5, "hour").toDate()
          : moment().startOf("isoWeek").add(5, "hour").toDate(),
        end: this.monthDateFilter
          ? moment().endOf("month").subtract(5, "hour").toDate()
          : moment()
              .startOf("isoWeek")
              .add(1, "week")
              .subtract(5, "hour")
              .toDate(),
      };
    }

    if (this.usePagination) {
      this.paginationData.pageSize = this.paginationSizeOptions[0];
    }

    if (!this.noLoadOnStart) {
      this.load(
        this.activeFilter,
        this.datesRange,
        this.actualSelectorValue,
        this.actualFilterValue,
        this.paginationData,
        this.filterBy,
        this.pickedSortConfiguration,
        this.categoriesFilterString,
        this.statusesFilterString
      );
    }

    if (this.datesDropdownVisible) {
      this.careHomesService
        .getCareHome(this.authUserService.getCareHomeId())
        .subscribe((response: any) => {
          const payrollDay = moment(response.result.wagesDate).format("DD");
          this.prepareMonthList(payrollDay);
        });
    }

    if (this.careHomeFilter) {
      this.careHomesService.getCareHomes().subscribe((response: any) => {
        const _careHomes = response.result.careHomesList;
        _careHomes.unshift({
          careHomeId: 0,
          careHomeFullName: "ALL CARE HOMES",
        });
        this.careHomes = _careHomes;
      });
    }

    if (this.isDisableDateIfActiveOnly) {
      this.changeCallendarFilterVisibility(false);
    }
  }

  ngAfterViewInit(): void {
    this.myControl.valueChanges.pipe(
      debounceTime(DEBOUNCE_TIME)
    ).subscribe((searchValue) => {
      this.applyFilter(searchValue)
    })

    if (this.isZoomCalcDisabled) return;
    this.subscription.add(
      this.tableService.zoom$.subscribe((zoomValue: number) => {
        this.ngZone.runOutsideAngular(() => {
          const minusValue = this.tableService.isMessageReport ? 320 - 16 * ((1 - zoomValue) * 10) : 330 - 16 * ((1 - zoomValue) * 10);
          (<any>(
            document.getElementById("table-container-to-focus")
          )).style.height = `calc(calc(100vh - ${minusValue}px) / ${zoomValue})`;
        });
      })
    );
  }

  toggleTaskList(isListShown: boolean) {
    let btnLeft = document.getElementById('over-task-btn-container-left')
    let btnRight = document.getElementById('over-task-btn-container-right')

    if (isListShown) {
      btnLeft.classList.remove('over-task-list')
      btnRight.classList.remove('over-task-list')
    } else {
      setTimeout(() => {
        btnLeft.classList.add('over-task-list')
        btnRight.classList.add('over-task-list')
        }, 1250)
    }
  }

  focusOnFilter() {
    if (this.filterInput && this.title !== 'Employees List') {
      this.filterInput.nativeElement.focus()
    }
  }

  onTextfieldChange(event) {
    this.textfieldChanged.emit(event);
  }
  updatePagination(event, isSort?) {
    if (
      this.pickedSortConfiguration &&
      this.pickedSortConfiguration.length > 0
    ) {
      this.pickedSortConfiguration = null;
    }

    if (isSort) {
      this.paginationData.orderBy = event.active;
      this.paginationData.orderDirection = event.direction;
    } else {
      this.paginationData.pageIndex = event.pageIndex;
      this.paginationData.pageSize = event.pageSize;
    }
    this.load(
      this.activeFilter,
      this.datesRange,
      this.actualSelectorValue,
      this.actualFilterValue,
      this.paginationData,
      this.filterBy,
      this.pickedSortConfiguration,
      this.categoriesFilterString,
      this.statusesFilterString
    );
  }



  ngOnChanges() {
    if (!this.customFilter) {
      (async () => {
        await delay(1);
        this.onFilterChange$.next("aaa");
        this.applyFilter(this.actualFilterValue);
      })();
    }
    this.dataLoaded = LoadingStatus.LOADED;
    setTimeout(() => {
      document.getElementById("table-container-to-focus").focus();
      this.focusOnFilter()
    }, 100);

  }

  toogleTableFilter(value: any) {
    this.activeFilter = value;
    this.actualFilterValue = value;
    if (this.isDisableDateIfActiveOnly) {
      this.changeCallendarFilterVisibility(!value);
    }
    this.tableService.reloadTable();
  }

  applyFilter(filterValue: string, checkIfEmpty = false) {
    this.actualFilterValue = filterValue;
    if (checkIfEmpty && this.filterEnterEnabled) {
      if (filterValue.trim().length === 0) {
        if (this.customFilter) {
          this.searchChanged.next(this.actualFilterValue);
        } else {
          this.onFilterChange$.next(this.actualFilterValue);
        }
      }
    } else {
      if (this.customFilter) {
        this.searchChanged.next(this.actualFilterValue);
      } else {
        this.onFilterChange$.next(this.actualFilterValue);
      }
    }
  }

  applyExtendedFilter() {
    this.searchChanged.next("aaa");
  }

  onDateFilterChange(date: any) {
    if (this.monthList.length > 0) {
      this.dateSelected = this.monthList[0];
    }
    if (this.weekList.length > 0) {
      this.dateSelected = '-';
    }
    this.datesRange.start = date.start;
    this.datesRange.end = date.end;
    this.setDatesRange.emit(this.datesRange);
    if (this.tableType === TableType.PAYROLL && this.payrollDates && !this.firstDateChanged) {
      this.firstDateChanged = true;
      return;
    }
    this.load(
      this.activeFilter,
      this.datesRange,
      this.actualSelectorValue,
      this.actualFilterValue,
      this.paginationData,
      this.filterBy,
      this.pickedSortConfiguration,
      this.categoriesFilterString,
      this.statusesFilterString
    );
  }

  onChangeCareHome(event: any, careHomeFromFilter: boolean) {
    this.careHomeChange(event.careHomeId);
    this.isCareHomeFromFilter = careHomeFromFilter;
  }

  reload() {
    if (this.checkActualSelector) {
      if (this.checkActualSelector.doCheck(this.actualSelectorValue)) {
        this.doReload();
      } else {
        this.checkActualSelector.onError();
      }
    } else {
      this.doReload();
    }
  }

  doReload() {
    if (this.dataLoaded !== LoadingStatus.IN_PROGRESS) {
      this.dataLoaded = LoadingStatus.IN_PROGRESS;
      this.tableService.reloadTable(true);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.data = null;
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  dateChanged(event: any) {
    const index = event.target.selectedIndex;
    this.datesRange.start = this.monthList[index].startDateMoment.toDate();
    this.datesRange.end = this.monthList[index].endDateMoment.toDate();
    this.datesRange = Object.assign({}, this.datesRange);
    this.load(
      this.activeFilter,
      this.datesRange,
      this.actualSelectorValue,
      this.actualFilterValue,
      this.paginationData,
      this.filterBy,
      this.pickedSortConfiguration,
      this.categoriesFilterString,
      this.statusesFilterString
    );
  }

  onChangeSortConfiguration(configuration: any, isLoaded = false) {
    this.isDefaultSortingLoaded = isLoaded;

    if (configuration) {
      this.paginationData.orderBy = configuration.value;
    } else {
      this.paginationData.orderBy = "";
    }
    this.searchChanged.next(configuration);
  }

  onChangeConfiguration(configuration: any) {
    this.extendedFilterConfig = configuration;
    if (configuration) {
      const careHomeConfig = configuration.fields.find(
        (x) => x.fieldName === "careHomeId"
      );
      const categoryIdConfig = configuration.fields.find(
        (x) => x.fieldName === "categoryIdFilter"
      );
      this.setConfigurationToDropdown(categoryIdConfig, "categoryId");
      const statusIdConfig = configuration.fields.find(
        (x) => x.fieldName === "statusIdFilter"
      );
      this.setConfigurationToDropdown(statusIdConfig, "statusId");
      const defaultSortConfig = configuration.fields.find(
        (x) => x.fieldName === "defaultSortConfiguration"
      );
      this.setConfigurationToSorting(defaultSortConfig);
      const defaultStartDateConfig = configuration.fields.find(
        (x) => x.fieldName === "dateStart"
      );
      this.setDatesConfiguration(defaultStartDateConfig);
      if (careHomeConfig) {
        const filterCareHomeId = parseInt(careHomeConfig.value, 10);
        this.defaultCareHomeId = filterCareHomeId;
        this.onChangeCareHome({ careHomeId: filterCareHomeId }, true);
      } else {
        this.defaultCareHomeId = 0;
        this.onChangeCareHome({ careHomeId: 0 }, true);
      }
      for (const field of this.filterBy) {
        this.form.get("filter-extended-" + field.fieldName).value
          ? this.form.get("filter-extended-" + field.fieldName).setValue(null)
          : this.form.get("filter-extended-" + field.fieldName).setValue("");
        const matchField = configuration.fields.find(
          (x) => x.fieldName === field.fieldName
        );
        if (matchField) {
          this.form
            .get("filter-extended-" + field.fieldName)
            .setValue(matchField.value);
        }
      }
    } else {
      this.isUsingExtendedFilters = false;
      if (this.isCareHomeFromFilter) {
        this.defaultCareHomeId = 0;
        this.onChangeCareHome({ careHomeId: 0 }, true);
      }
      if (this.isDefaultSortingLoaded) {
        this.pickedSortConfiguration = null;
        this.onChangeSortConfiguration(null, false);
      }
      for (const field of this.filterBy) {
        this.form.get("filter-extended-" + field.fieldName).setValue(null);
      }
      this.form.get("categoryId").setValue(null);
      this.form.get("statusId").setValue(null);
      this.categoriesFilterString = "";
      this.statusesFilterString = "";
    }
  }

  public textAreaChange(event: { element: any, value: string, index: number }): void {
    this.onTextAreaChange.emit(event);
  }

  public agencyChanged(value: IAgencyDetailedReportResponseLine): void {
    this.onAgencyChanged.emit(value);
  }

  private setDatesConfiguration(dateStart: any) {
    this.datesRange = {
      start: dateStart
        ? moment(dateStart.value, "DD/MM/YYYY").toDate()
        : this.datesRange.start,
      end: this.datesRange.end,
    };
  }

  private setConfigurationToSorting(configuration: any) {
    if (configuration) {
      this.pickedSortConfiguration = configuration.value;
      this.onChangeSortConfiguration(configuration, true);
    }
  }

  private setConfigurationToDropdown(config: any, formId: string) {
    if (config) {
      const values = JSON.parse(config.value);
      this.form.get(formId).setValue(values);
    }
  }

  private changeCallendarFilterVisibility(isVisible: boolean) {
    this.weekDateFilter = isVisible;
    this.monthDateFilter = isVisible;
  }

  private setWeeks() {
    const weeks = [];

    const today = moment().format();
    weeks.push({
      weekStart: moment(today).startOf('isoWeek').format(),
      weekEnd: moment(today).endOf('isoWeek').format(),
      weekNo: moment(today).isoWeek(),
      weekString: `Week ${moment(today).isoWeek()}, ${moment(today).startOf('isoWeek').format('YYYY/MM/DD')} - ${moment(today).endOf('isoWeek').format('YYYY/MM/DD')}`
    });
    for (let i = 1; i < 7; i++) {
      const date = moment(today).subtract(i * 7, 'days');
      weeks.push({
        weekStart: moment(date).startOf('isoWeek').format(),
        weekEnd: moment(date).endOf('isoWeek').format(),
        weekNo: moment(date).isoWeek(),
        weekString: `Week ${moment(date).isoWeek()}, ${moment(date).startOf('isoWeek').format('YYYY/MM/DD')} - ${moment(date).endOf('isoWeek').format('YYYY/MM/DD')}`
      });
    }

    this.weekList = weeks;
  }

  private prepareMonthList(payrollDay: any) {
    this.monthList = [];
    const date = moment();
    let addMonthsStart = 3;
    const payrollDate =
      moment(date, "YYYY/MM/DD").format("YYYY/MM") + "/" + payrollDay;
    const payrollMonth = moment(date, "YYYY/MM/DD").format("DD");
    const payrollDayNumber = parseInt(payrollDay, 10);
    const dayNumber = parseInt(payrollMonth, 10);

    const isDateAfterPayroll = dayNumber >= payrollDayNumber;

    if (isDateAfterPayroll) {
      addMonthsStart = 2;
    }

    const startDate = moment(payrollDate, "YYYY/MM/DD").subtract(
      addMonthsStart,
      "months"
    );
    const endDate = moment(payrollDate, "YYYY/MM/DD")
      .subtract(addMonthsStart - 1, "months")
      .subtract(1, "days");

    this.monthList.push({
      dateShow: "-",
      startDateMoment: this.monthDateFilter
        ? moment().startOf("month").add(5, "hour")
        : moment().startOf("isoWeek").add(5, "hour"),
      endDateMoment: this.monthDateFilter
        ? moment().endOf("month").subtract(5, "hour")
        : moment().startOf("isoWeek").add(1, "week").subtract(5, "hour"),
    });

    for (let i = 0; i < 7; i++) {
      const startMonthToInsert = startDate.add(1, "months");
      const endMonthToInsert = endDate.add(1, "months");
      const monthFormatted = `${startMonthToInsert.format(
        "YYYY/MM/DD"
      )} - ${endMonthToInsert.format("YYYY/MM/DD")}${this.showWagesInMonths && i === 0 ? ' (previous wages)' : this.showWagesInMonths && i === 1 ? ' (active wages)' : ''}`;
      this.monthList.push({
        dateShow: monthFormatted,
        startDateMoment: moment(startMonthToInsert),
        endDateMoment: moment(endMonthToInsert),
      });
    }

    this.setCurrentPayrollDate();
  }

  private setCurrentPayrollDate() {
    this.dateSelected = this.monthList[2].dateShow;
    this.datesRange.start = this.monthList[2].startDateMoment.toDate();
    this.datesRange.end = this.monthList[2].endDateMoment.toDate();
    this.datesRange = Object.assign({}, this.datesRange);
    this.load(
      this.activeFilter,
      this.datesRange,
      this.actualSelectorValue,
      this.actualFilterValue,
      this.paginationData,
      this.filterBy,
      this.pickedSortConfiguration,
      this.categoriesFilterString,
      this.statusesFilterString
    );

    this.payrollDates = true;

    // for (const month of this.monthList) {
    //   if (
    //     dateNow.isSameOrAfter(month.startDateMoment) &&
    //     dateNow.isSameOrBefore(month.endDateMoment) &&
    //     month.dateShow !== "-"
    //   ) {
    //     this.dateSelected = month.dateShow;
    //     dateNotSelected = false;
    //     this.datesRange.start = month.startDateMoment.toDate();
    //     this.datesRange.end = month.endDateMoment.toDate();
    //     this.datesRange = Object.assign({}, this.datesRange);
    //     this.load(
    //       this.activeFilter,
    //       this.datesRange,
    //       this.actualSelectorValue,
    //       this.actualFilterValue,
    //       this.paginationData,
    //       this.filterBy,
    //       this.pickedSortConfiguration,
    //       this.categoriesFilterString,
    //       this.statusesFilterString
    //     );
    //   }
    // }
  }

  selectedRows = (items: any) => {
    this.selectedItems = items;
  }

  toggleSelectableVisibility(event: any) {
    this.selectableVisibility$.next(event);
  }

  weekChanged(event: any) {
    const index = event.target.selectedIndex - 1;
    const start = this.weekList[index].weekStart;
    const end = this.weekList[index].weekEnd;
    this.datesRange.start = moment(this.weekList[index].weekStart, moment.defaultFormat).toDate();
    this.datesRange.end = moment(moment(this.weekList[index].weekEnd).subtract(5, 'h'), moment.defaultFormat).toDate();
    this.datesRange = Object.assign({}, this.datesRange);
    this.load(start, end);
  }

getButtonTitle(): string {
    switch (this.title) {
      case 'Employees List':            return 'Add new employee';
      case 'Payroll Adjustments':       return 'Add payroll adjustment';
      case 'Automatic Tasks':           return 'Add automatic task';
      case 'Manage Task Boards':        return 'Add task board';
      case 'Task\'s templates':         return 'Add task template';
      case 'Tracked Events':            return 'Add tracked event';
      case 'Message templates':         return 'Add message template';
      case 'Manage users':              return 'Add user';
      case 'Sickness list':             return 'Add event';
      case 'Contracts list':            return 'Add new contract';
      case 'Annual Leaving List':       return 'Add Annual Leave';
      case 'Menu choices':              return 'Add menu choice';

      default:
        return 'Add Care Home';
    }
  }

  loadOn() {
    this.load(
      this.activeFilter,
      this.datesRange,
      this.actualSelectorValue,
      this.actualFilterValue,
      this.paginationData,
      this.filterBy,
      this.pickedSortConfiguration,
      this.categoriesFilterString,
      this.statusesFilterString,
      undefined, undefined,
      this.isPinned
    );
  }
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
