import { Component, OnDestroy, forwardRef, ViewChild, TemplateRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { TasksService } from '../../../shared/tasks/tasks.service';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { DictionariesService } from '../../../shared/dictionaries/dictionaries.service';
import { TableService } from '../table/table.service';
import { QuestionDialogComponent } from '../question-dialog/question-dialog.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Input } from '@angular/core';
import { getErrorMessage } from '../../../utilities/utils';
import {
  AddSubtaskAdvancedViewDialogComponent
} from "../add-subtask-advanced-view-dialog/add-subtask-advanced-view-dialog.component";

@Component({
  selector: 'ngx-subtask-editor-form',
  templateUrl: './subtask-editor-form.component.html',
  styleUrls: ['./subtask-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SubtaskEditorFormComponent)
    }
  ]
})
export class SubtaskEditorFormComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() addingTaskInNewTicketMode: boolean = false;
  @Input() isRecurringVisible = false;
  @Input() showGridView = false
  public newSubtaskTitle: string = '';
  public subtasksInputs = [];
  public subtasks = [];
  public subtasksProgress = '0%';
  public disabled = false;

  public subtasksTitles: string[] = [];
  todoEditMode: boolean = false;
  subtaskEditMode: boolean = false;
  editId: string = '';

  createInProgress: boolean = false;
  dataIsLoaded: boolean = true;

  public onChange(newVal: any) {}
  public onTouched(_?: any) {}
  writeValue(value: any): void {
    this.subtasks = value || [];
  }
  public registerOnChange(fn: any): void { this.onChange = fn; }
  public registerOnTouched(fn: any): void { this.onTouched = fn; }
  public setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled; }

  public popoverId: string;
  @ViewChild('subtaskInfo') subtaskData: TemplateRef<any>;

  editedId: number = null;
  constructor(protected tasksService: TasksService,
              protected toastrService: NbToastrService,
              protected dictionariesService: DictionariesService,
              protected tableService: TableService,
              protected dialogService: NbDialogService) {
  }

  openPopover(id: number, subtask: any, title: string, i: number, index: number) {
    this.popoverId = 'todoBox-' + id
    this.dialogService.open(
      this.subtaskData, {context: {
          id,
          popId: 'todoBox-' + id,
          todo: this.getTodoData(subtask.todos, title),
          index,
          highlight: true,
          subtask
        }, hasBackdrop: true, closeOnEsc: false, closeOnBackdropClick: true}
    ).onClose.subscribe(() => {
      this.popoverId = ''
    })
  }

  isSubtaskTitleExist(title: string) {
    const subtask = this.subtasks.find((item: any) => {
      return item.title == title;
    });
    return subtask;
  }

  public reloadOnChanges() {
    const mySubs = Object.assign([], this.subtasks);
    if (mySubs.length > 0) {
      this.generateTodoNamesList(mySubs)
    }
  }

  generateTodoNamesList(subtasks: any[]) {
    let namesArray = []
    subtasks.forEach((sub) => {
      sub.todos.forEach((todo) => {
        if (!namesArray.includes(todo.comments)) {
          namesArray.push(todo.comments)
        }
      })
    })

    const sort = (array, sortArray) => {
      return [...array].sort(
        (a, b) => sortArray.indexOf(a) - sortArray.indexOf(b)
      )
    }

    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday','Thursday', 'Friday', 'Saturday', 'Sunday']
    const sortedNames = sort(namesArray, daysOfWeek)

    this.subtasksTitles = sortedNames
  }

  addSubtask(event?: any) {
    if (this.isSubtaskTitleExist(this.newSubtaskTitle)) {
      this.toastrService.danger('Subtask title exist. Can not add new one with the same title.', 'Error', { duration: 60000, destroyByClick: true });
    } else {
      const subtaskObject = {
        title: this.newSubtaskTitle,
        todos: [],
        addingTaskInNewTicketMode: this.addingTaskInNewTicketMode
      };
      this.subtasksInputs.push('');
      this.subtasks.push(subtaskObject);
      this.onChange(this.subtasks);
      this.reloadOnChanges()
      this.newSubtaskTitle = '';
      if (event) {
        event.preventDefault();
        setTimeout(() => {
          let newSubTitle = document.getElementById(`subtask-editable-title-${subtaskObject.title.split(' ').join('')}`)
          newSubTitle.scrollIntoView();
          newSubTitle.click()
          setTimeout(() => {
            document
              .getElementById(
                "subtask-editor-add-todo-input-" + (this.subtasks.length - 1)
              )
              .scrollIntoView();
            document
              .getElementById(
                "subtask-editor-add-todo-input-" + (this.subtasks.length - 1)
              )
              .focus()
          }, 100)
        }, 100);
      }
    }
  }

  addTodo(event: any, index: number) {
    if (this.subtasksInputs[index] && this.subtasksInputs[index].trim().length != 0) {
      const todoObj = {
        title: this.subtasks[index].title,
        comments: this.subtasksInputs[index],
        addingTaskInNewTicketMode: this.addingTaskInNewTicketMode,
        isCompleted: null,
        open: true,
        isNotCompleted: null
      };
      if (this.isRecurringVisible) {
        todoObj['isRecurring'] = false;
      }
      this.subtasks[index].todos.push(todoObj);
      this.onChange(this.subtasks);
      this.reloadOnChanges()
      if (event) {
        event.preventDefault();
      }
      this.subtasksInputs[index] = '';
    } else {
      this.toastrService.warning('Todo title cannot be blank', 'Info', { duration: 60000, destroyByClick: true });
    }
  }

  addGridTodo(index: number, title: string, multi = false) {
    const todoObj = {
      title: this.subtasks[index].title,
      comments: title,
      addingTaskInNewTicketMode: this.addingTaskInNewTicketMode,
      isCompleted: null,
      open: true,
      isNotCompleted: null
    };
    if (this.isRecurringVisible) {
      todoObj['isRecurring'] = false;
    }
    this.subtasks[index].todos.push(todoObj);
    this.onChange(this.subtasks);
    this.reloadOnChanges()
    this.subtasksInputs[index] = '';
    if (!multi) {
      setTimeout(() => {
        const newTodoId = document.getElementById(`todoBox-${todoObj.title}_${todoObj.comments}`)
        newTodoId.click()
      }, 500)
    }
  }

  deleteSubtask(index: number) {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Are you sure?',
          message: 'You want to delete this subtask?',
          okLabel: 'Yes',
          cancelLabel: 'No'
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          this.subtasks.splice(index, 1);
          this.subtasksInputs.splice(index, 1);
          this.onChange(this.subtasks);
          this.reloadOnChanges()
        }
      }
    );
  }

  saveSubtaskTitle(data: { title: string, index: number }) {
    this.subtasks[data.index].title = data.title;
    this.checkTodosTitle();
    this.onChange(this.subtasks);
    this.reloadOnChanges()
  }

  moveSubtask(data: { title: string, oldIndex: number, newIndex: number }) {
    const toRemember = this.subtasks[data.newIndex];
    this.subtasks[data.newIndex] = this.subtasks[data.oldIndex];
    this.subtasks[data.oldIndex] = toRemember;
    this.onChange(this.subtasks);

  }

  saveTodoComments(data: { comments: string, subtaskIndex: number, todoIndex: number, isRecurring: boolean }, todoData?: any) {
    todoData ?
      this.subtasks[data.subtaskIndex].todos.find((todo) => todo.comments == todoData.comments).comments = data.comments :
      this.subtasks[data.subtaskIndex].todos[data.todoIndex].comments = data.comments;
    todoData ?
      this.subtasks[data.subtaskIndex].todos.find((todo) => todo.comments == todoData.comments).isRecurring = data.isRecurring :
      this.subtasks[data.subtaskIndex].todos[data.todoIndex].isRecurring = data.isRecurring;
    this.onChange(this.subtasks);
    this.reloadOnChanges()
  }

  saveTodoStatus(data: { isNotCompleted: boolean, isCompleted: boolean, subtaskIndex: number, todoIndex: number }, todoData?: any) {
    todoData ? this.subtasks[data.subtaskIndex].todos.find((todo) => todo.comments == todoData.comments).isNotCompleted = data.isNotCompleted :
      this.subtasks[data.subtaskIndex].todos[data.todoIndex].isNotCompleted = data.isNotCompleted;
    todoData ? this.subtasks[data.subtaskIndex].todos.find((todo) => todo.comments == todoData.comments).isCompleted = data.isCompleted :
      this.subtasks[data.subtaskIndex].todos[data.todoIndex].isCompleted = data.isCompleted;
    this.onChange(this.subtasks);
    this.reloadOnChanges()
  }

  deleteTodo(data: { subtaskIndex: number, todoIndex: number }) {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Are you sure?',
          message: 'You want to delete this todo?',
          okLabel: 'Yes',
          cancelLabel: 'No'
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          this.subtasks[data.subtaskIndex].todos.splice(data.todoIndex, 1);
          this.onChange(this.subtasks);
          this.reloadOnChanges()
        }
      }
    );
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.container.id != event.previousContainer.id) {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex, event.currentIndex);
        this.checkTodosTitle();
    } else {
      moveItemInArray(event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.onChange(this.subtasks);

  }

  private checkTodosTitle() {
    for (const subTask of this.subtasks) {
      for (const todo of subTask.todos) {
        todo.title = subTask.title;
      }
    }
  }

  trackByFn(index, item) {
    return index;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addSubtaskWindow() {
    this.dialogService
      .open(AddSubtaskAdvancedViewDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          addTodos: false,
          subtasksNames: this.subtasksTitles,
          okLabel: "Save",
          cancelLabel: "Cancel",
        },
      }).onClose.subscribe((res) => {
      if (res.save) {
        if (res.newSubtask) {
          this.newSubtaskTitle = res.title
          this.addSubtask()
        } else {
          this.dataIsLoaded = false
          this.newSubtaskTitle = res.title
          if (this.isSubtaskTitleExist(this.newSubtaskTitle)) {
            this.toastrService.danger(
              "Subtask title exist. Can not add new one with the same title.",
              "Error",
              { duration: 60000, destroyByClick: true }
            );
            this.newSubtaskTitle = "";
          } else {
            const subtaskObject = {
              title: this.newSubtaskTitle,
              todos: [],
              addingTaskInNewTicketMode: this.addingTaskInNewTicketMode
            };
            this.subtasksInputs.push("");
            this.subtasks.push(subtaskObject);
            const newSubIndex = this.subtasks.findIndex((sub) => sub.title === res.title)
            res.todos.forEach((todo) => {
              this.addGridTodo(newSubIndex, todo, true)
            })

            this.onChange(this.subtasks);
            this.reloadOnChanges()
            this.newSubtaskTitle = '';
          }
          setTimeout(() => {
            this.dataIsLoaded = true
          }, 1000)
        }
      }
    })
  }

  addTodoWindow() {
    let subtasksNames: string[] = []
    this.subtasks.forEach((sub) => {
      subtasksNames.push(sub.title)
    })
    this.dialogService
      .open(AddSubtaskAdvancedViewDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          addTodos: true,
          subtasksNames: subtasksNames,
          okLabel: "Create",
          cancelLabel: "Cancel",
        },
      })
      .onClose.subscribe((res) => {
      if (res.create) {
        this.dataIsLoaded = false
        this.subtasks.forEach((sub, index) => {
          if (res.subtasks.includes(sub.title)) {
            this.addGridTodo(index, res.title, true)
          }
        })
        this.onChange(this.subtasks);
        this.reloadOnChanges()
        setTimeout(() => {
          this.dataIsLoaded = true
        }, 1000)
      }
    });
  }

  editTodoTitle(title: string, oldTitle: string) {
    this.subtasks.forEach((sub, subIndex ) => {
      sub.todos.forEach((todo, todoIndex) => {
        if (todo.comments == oldTitle) {
          this.saveTodoComments({comments: title, subtaskIndex: subIndex, todoIndex: todoIndex, isRecurring: todo.isRecurring})
        }
      })
    })
    this.endEdit()
    this.reloadOnChanges()

  }

  endEdit() {
    this.todoEditMode = false
    this.editId = ''
  }

  setEditMode(i: number, subtask = true) {
    if (subtask) {
      this.subtaskEditMode = true
      this.editId = 'subtask_' + i
    } else {
      this.todoEditMode = true
      this.editId = 'todo_' + i
    }

  }

  deleteTodos(title: string) {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: "Are you sure?",
          message: `You want to delete ${title} todos from all subtasks?`,
          okLabel: "Yes, delete all",
          cancelLabel: "No",
        },
      })
      .onClose.subscribe((decision: boolean) => {
      if (decision) {
        this.subtasks.forEach((sub, subIndex) => {
          sub.todos.forEach((todo, todoIndex) => {
            if (todo.comments == title) {
              this.subtasks[subIndex].todos.splice(todoIndex, 1);
              this.onChange(this.subtasks);
              this.reloadOnChanges()
            }
          })
        })
      }
    });
  }

  isFirst(i: number): boolean {
    return i == 0;
  }

  isLast(i: number): boolean {
    return i == (this.subtasks.length - 1);
  }

  goUp(i: number, title: string) {
    const newIndex = i - 1
    const subTaskMoveData = {
      title: title,
      newIndex,
      oldIndex: i
    }
    this.moveSubtask(subTaskMoveData)
  }

  goDown(i: number, title) {
    const newIndex = i + 1
    const subTaskMoveData = {
      title: title,
      newIndex,
      oldIndex: i
    }
    this.moveSubtask(subTaskMoveData)
  }

  saveNewTitle(title: string, index: number) {
    this.saveSubtaskTitle({title, index})
    this.subtaskEditMode = false
    this.editId = ''
  }

  getTodo(todos: any[], comments: string):boolean {
    let found = false
    todos.forEach((todo) => {
      if (todo.comments == comments) {
        found = true
      }
    })
    return found
  }

  getTODO(todos: any[], comments: string): any {
    return todos.find((todo) => todo.comments == comments).title + '_' + todos.find((todo) => todo.comments == comments).comments
  }

  getTodoData(todos: any[], comments: string) {
    return todos.find((todo) => todo.comments == comments)
  }

  checkIfCompleted(todos: any[], comments: string):boolean {
    let completed = false
    todos.forEach((todo) => {
      if (todo.comments == comments && todo.isCompleted) {
        completed = true
      }
    })
    return completed
  }

  checkIfNotCompleted(todos: any[], comments: string):boolean {
    let notCompleted = false
    todos.forEach((todo) => {
      if (todo.comments == comments && todo.isNotCompleted) {
        notCompleted = true
      }
    })
    return notCompleted
  }

  getLatestUpdate(todos: any[], comments: string):string {
    let data
    todos.forEach((todo) => {
      if (todo.comments == comments) {
        data = todo.groupResponse
      }
    })
    return data
  }
}
