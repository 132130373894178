<div class="strike">
  <h4>Change user password</h4>
</div>
<div class="flex-container">
  <form [formGroup]="formPass" autocomplete="off">
    <div class="flex-container-horizontal">
      <ng-template [ngIf]="!permissions.USER_MANAGER_CHANGE_PASSWORD">
        <div class="form-control-group">
          <label class="label" for="input-old-password">Old Password</label>
          <input nbInput fullWidth formControlName="oldPassword" name="input-old-password" type="password"
            id="input-old-password" placeholder="Old Password" minlength="5" maxlength="25" autocomplete="new-password"
            onblur="this.setAttribute('readonly', 'readonly')" onfocus="this.removeAttribute('readonly')" readonly
            autofocus>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(formPass, 'oldPassword', errorMessages) }}
          </p>
        </div>
      </ng-template>
      <div class="form-control-group">
        <label class="label" for="input-change-password">New Password</label>
        <input nbInput fullWidth formControlName="changePassword" name="input-change-password" type="password"
          id="input-change-password" placeholder="New Password" autocomplete="new-password"
          onblur="this.setAttribute('readonly', 'readonly')" onfocus="this.removeAttribute('readonly')" minlength="5"
          maxlength="25" autofocus>
        <p class="error-message">
          {{ utils.handleValidationErrorMessage(formPass, 'changePassword', errorMessages) }}
        </p>
      </div>
      <div class="form-control-group">
        <label class="label" for="input-confirm-password">Confirm Password</label>
        <input nbInput fullWidth formControlName="confirmPassword" name="input-confirm-password" type="password"
          id="input-confirm-password" placeholder="Confirm Password" minlength="5" maxlength="25"
          autocomplete="new-password" onblur="this.setAttribute('readonly', 'readonly')"
          onfocus="this.removeAttribute('readonly')" autofocus>
        <p class="error-message">
          {{ utils.handleValidationErrorMessage(formPass, 'confirmPassword', errorMessages) }}
        </p>
      </div>
      <button class="btn btn-success" (click)="changePassword()" [disabled]="!utils.isFormValid(formPass)">
        <i class="nb-checkmark" aria-hidden="true"></i>
      </button>
      <button class="btn btn-danger ml-05" (click)="close()">
        <i class="nb-close" aria-hidden="true"></i>
      </button>
    </div>
  </form>
</div>