<div class="subtasks-container">
  <p *ngIf="!showGridView">Subtasks:</p>
<!--  <ngx-progress-bar [progressPercent]="subtasksProgress"></ngx-progress-bar>-->
  <div class="todos" *ngIf="!showGridView; else gridView">
    <form (submit)="addSubtask($event)" class="flex">
      <input [(ngModel)]="newSubtaskTitle" (keyup.enter)="newSubtaskTitle=''" class="textfield full" placeholder="Type SUBTASK title" name="newSubtaskTitle" autocomplete="off"/>
      <button type="submit" [disabled]="newSubtaskTitle===''" class="btn btn-success ml-3">Add Subtask</button>
    </form>
    <section *ngIf="subtasks.length > 0" class="subtasks">
      <div class="flex">
        <span class="cell title header" [ngStyle]="{'min-width': '900px', 'font-weight': '300', 'border-inline': '1px solid #EDF1F7'}" [style.min-width.px]="900">Title</span>
        <span class="cell description header" [ngStyle]="{'font-weight': '300', 'border-inline': '1px solid #EDF1F7'}">Actions</span>
      </div>

      <div cdkDropListGroup>
        <nb-accordion [multi]="true">
          <nb-accordion-item *ngFor="let subtask of subtasks; let index=index; trackBy: trackByFn" class="subtask" [expanded]="subtask.isExpanded">
            <nb-accordion-item-header
              #accordionItemHeaderComponent
              [ngStyle]="{width: '100%', border: '1px solid #edf1f7', padding: '0.75rem 1.25rem'}"
            >
              <ngx-subtask-editable-title
                [style.width.%]="100"
                [title]="subtask.title"
                [isMultiple]="true"
                [index]="index"
                [isFirst]="index == 0"
                [isLast]="index == (subtasks.length-1)"
                [subtaskPercent]="subtask.finishedString || '0%'"
                (click)="$event.preventDefault(); $event.stopPropagation()"
                (deleteSubtask)="deleteSubtask($event)"
                (saveTitle)="saveSubtaskTitle($event)"
                (moveSubtask)="moveSubtask($event)"
                (toggleAccordion)="accordionItemHeaderComponent.toggle()">
              </ngx-subtask-editable-title>
            </nb-accordion-item-header>
            <nb-accordion-item-body class="main-body">
              <div class="cell description" [id]="index" cdkDropList [cdkDropListData]="subtask.todos" (cdkDropListDropped)="onDrop($event)">
                <form (submit)="addTodo($event, index)" class="flex">
                  <input [(ngModel)]="subtasksInputs[index]" (keyup.enter)="subtasksInputs[index]=''" placeholder="Type TODO name"
                         [id]="'subtask-editor-add-todo-input-'+index" class="textfield" name="newTodo" autocomplete="off"/>
                  <button type="submit" [disabled]="subtasksInputs[index]===''" class="btn btn-success ml-3 todo-add-button">
                    <nb-icon icon="plus-outline"></nb-icon> Add Todo
                  </button>
                </form>
                <ul class="mt-2">
                  <div *ngIf="subtask.todos.length == 0" class="no-subtask-info">This subtask does yet not have any Todos. Add a Todo otherwise it will be deleted when you click save</div>
                  <li *ngFor="let todo of subtask.todos; let i=index" [id]="i" cdkDrag [cdkDragData]="todo" [cdkDragDisabled]="editedId == i">
                    <ngx-subtask-editable-todo-form
                      [id]="'todo-' + todo.id"
                      [todo]="todo"
                      [isRecurringVisible]="isRecurringVisible"
                      [subtaskIndex]="index"
                      [toDoindex]="i"
                      (onEdit)="editedId = $event ? i : null"
                      (saveStatus)="saveTodoStatus($event)"
                      (delete)="deleteTodo($event)"
                      (saveComments)="saveTodoComments($event)"
                    >
                    </ngx-subtask-editable-todo-form>
                  </li>
                </ul>
              </div>
            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>

      </div>

    </section>
  </div>
  <ng-template #gridView>
    <div class="advanced-todos" *ngIf="dataIsLoaded; else dataLoading">
      <div class="flex">
        <button (click)="addSubtaskWindow()" class="btn btn-info todo-add-button">
          <nb-icon icon="plus-outline"></nb-icon>
          {{subtasks.length == 0 ? 'Add first row' : 'Add column'}}
        </button>
        <button *ngIf="subtasks.length > 0" (click)="addTodoWindow()" class="btn btn-info todo-add-button">
          <nb-icon icon="plus-outline"></nb-icon>
          Add row
        </button>
      </div>
      <section class="thin-echo-scrollbar">
        <div class="subtasks-header">
          <div class="grid-header">
            Action / Item ({{subtasks.length}})
          </div>
          <div class="subtask-title" *ngFor="let title of subtasksTitles; let i=index">
            <div>
              <!--              <nb-icon icon="grid-outline"></nb-icon>-->
              <span *ngIf="editId != 'todo_' + i" [nbPopover]="subtaskTitlePopover" nbPopoverTrigger="hover">{{title}}</span>
              <ng-template #subtaskTitlePopover>
                <div class="subtask-title-popover">{{title}}</div>
              </ng-template>
              <input
                nbInput
                [id]="'subtask_' + i"
                #todoTitleInput
                *ngIf="todoEditMode && editId == 'todo_' + i"
                [value]="title"
                (focusout)="title != todoTitleInput.value && editTodoTitle(todoTitleInput.value, title); title == todoTitleInput.value && endEdit()"
                (keydown.enter)="title != todoTitleInput.value && editTodoTitle(todoTitleInput.value, title); title == todoTitleInput.value && endEdit()"
                class="edit-title-mode">
            </div>
            <div>
              <nb-icon (click)="setEditMode(i, false)" icon="edit-outline"></nb-icon>
              <nb-icon (click)="deleteTodos(title)" icon="trash-2-outline"></nb-icon>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let subtask of subtasks; let index=index">
          <div class="subtask-column">
            <div class="title-header">
              <div class="subtask-input">
                <!--                <nb-icon icon="grid-outline"></nb-icon>-->
                <span class="move-icons">
                  <span *ngIf="!isFirst(index)" (click)="goUp(index, subtask.title)" class="icon">
                    <nb-icon icon="arrow-ios-back-outline"></nb-icon>
                  </span>
                  <span *ngIf="!isLast(index)" (click)="goDown(index, subtask.title)" class="icon">
                    <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
                  </span>
                </span>
                <span *ngIf="editId != 'subtask_' + index" [nbPopover]="subtaskColumnTitlePopover" nbPopoverTrigger="hover">{{subtask.title}}</span>
                <ng-template #subtaskColumnTitlePopover>
                  <div class="subtask-title-popover">{{subtask.title}}</div>
                </ng-template>
                <textarea
                  nbInput
                  [id]="'subtask_' + index"
                  #subtaskTitleInput
                  *ngIf="subtaskEditMode && editId == 'subtask_' + index"
                  [innerHTML]="subtask.title"
                  [(ngModel)]="subtask.title"
                  (focusout)="saveNewTitle(subtaskTitleInput.value, index)"
                  (keydown.space)="$event.stopPropagation()"
                  (keydown.enter)="$event.stopPropagation()"
                  class="edit-title-mode thin-echo-scrollbar-5"></textarea>
              </div>
              <div class="action-icons">
                <nb-icon *ngIf="subtaskEditMode" icon="edit-outline" (click)="saveNewTitle(subtask.title, index)"></nb-icon>
                <nb-icon *ngIf="!subtaskEditMode" icon="edit-outline" (click)="setEditMode(index)"></nb-icon>
                <nb-icon icon="trash-2-outline" (click)="deleteSubtask(index)"></nb-icon>
              </div>
            </div>
            <ng-container *ngFor="let title of subtasksTitles; let i=index">
              <div class="subtask-todo-container">
                <div class="subtask-todo thin-echo-scrollbar" *ngIf="getTodo(subtask.todos, title)"
                     [id]="'todoBox-' + getTODO(subtask.todos, title)"
                     [class.completed]="checkIfCompleted(subtask.todos, title)"
                     [class.notCompleted]="checkIfNotCompleted(subtask.todos, title) || getLatestUpdate(subtask.todos, title)"
                     (click)="openPopover(getTodoData(subtask.todos, title).id, subtask, title, i, index)"
                >
                  {{getLatestUpdate(subtask.todos, title)}}
                </div>
                <div class="subtask-todo empty" *ngIf="!getTodo(subtask.todos, title)" (click)="!createInProgress && addGridTodo(index, title)">
                  <nb-icon *ngIf="!createInProgress" icon="plus-outline"></nb-icon>
                </div>
                <ng-template #subtaskInfo let-data>
                  <nb-card>
                    <nb-card-header [style.padding-inline.px]="10">
                     Subtask: {{data.todo.title}}
                    </nb-card-header>
                    <nb-card-body [ngStyle]="{padding: '0', 'margin-bottom': '-5px'}">
                      <ngx-subtask-editable-todo-form
                        *ngIf="popoverId == data.popId"
                        [ngStyle]="{display: 'block', 'min-width': '800px', 'max-width': '800px'}"
                        [id]="data.popId"
                        [todo]="data.todo"
                        [highlight]="data.highlight"
                        (onEdit)="editedId = $event ? i : null"
                        [isRecurringVisible]="isRecurringVisible"
                        [subtaskIndex]="data.index"
                        [toDoindex]="data.i"
                        [gridView]="true"
                        (saveStatus)="saveTodoStatus($event, data.todo)"
                        (delete)="deleteTodo($event)"
                        (saveComments)="saveTodoComments($event, data.todo)">
                      </ngx-subtask-editable-todo-form>
                    </nb-card-body>
                  </nb-card>
                </ng-template>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </section>
    </div >
    <ng-template #dataLoading>
      <div class="table-container">
        <div class="table-spinner-container">
          <div id="nb-global-spinner" class="spinner">
            <img src="./assets/images/echo_sygnet.svg" alt=""/>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>
