import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";


@Injectable({
  providedIn: 'root',
})
export class RotaMigrationService {
  public roles: any = [];
  public homes: any = [];

  private editModeSource = new Subject<{
    editMode: boolean;
    weekNo: string;
    save?: boolean;
  }>();

  public editMode$ = this.editModeSource.asObservable();

  saveData(weekNo: string) {
    this.editModeSource.next({
      editMode: false,
      weekNo: weekNo,
      save: true
    });
  }

  private saveSuccessSource = new Subject<ISaveSuccessData>();

  public saveSuccess$ = this.saveSuccessSource.asObservable();

  saveSuccessData(data: ISaveSuccessData) {
    this.saveSuccessSource.next(data);
  }

  private weekChanges = new BehaviorSubject<IWeekChanges[]>([]);
  public weekChanges$ = this.weekChanges.asObservable();

  addContractToChanges(data: IWeekContractChange) {
    const changes = this.weekChanges.value;
    const weeks = [];
    changes.forEach(week => weeks.push(week.weekNo));
    if (changes.length == 0) {
      changes.push({weekNo: data.weekNo, contractIds: [data.contractId]});
    } else {
      changes.forEach(week => {
        if (data.weekNo == week.weekNo && !week.contractIds.includes(data.contractId)) {
          week.contractIds.push(data.contractId);
        }
        if (data.weekNo != week.weekNo && !weeks.includes(data.weekNo)) {
          weeks.push(data.weekNo);
          changes.push({weekNo: data.weekNo, contractIds: [data.contractId]});
        }
      });
    }
    this.weekChanges.next(changes);
  }

  clearChanges() {
    this.weekChanges.next([]);
  }

  clearSavedWeek(weekNo: string) {
    const changes = this.weekChanges.value.filter(week => week.weekNo != weekNo);
    this.weekChanges.next(changes);
  }
}

export interface ISaveSuccessData {
  weekNo: string;
  contractId: number;
  saved: boolean;
}

export interface IWeekChanges {
  weekNo: string;
  contractIds: number[];
}

export interface IWeekContractChange {
  weekNo: string;
  contractId: number;
}
