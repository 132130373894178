import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { of as observableOf, Observable, Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { getAvatarImage } from '../../utilities/utils';
import { ActivatedRoute, Params } from '@angular/router';
import {CareHomesService} from "../../shared/care-homes/care-homes.service";

export const USER_KEY = 'echo_auth_app_user';
const PERMISSIONS_KEY = 'echo_permissions';
const OCCUPY_WEEKS_KEY = 'echo_occupancy_weeks';
const PICKED_CARE_HOME_KEY = 'echo_picked_care_home';
const REDIRECT_URL_KEY = 'echo_redirect_url';

@Injectable()
export class AuthUserService implements OnDestroy {
  private subscrtiption: Subscription = new Subscription();
  authenticated = false;
  originalUser: any = {};
  token = '';
  private careHomeId: number = 0;
  user = {
    id: -1,
    name: '',
    picture: 'assets/images/person.png'
  };
  public userCareHomeChange$ = new Subject();
  public messageConfirmed$ = new Subject();

  constructor(private authService: AuthService,
              private careHomesService: CareHomesService,
              private route: ActivatedRoute) {
    try {
      const _user = JSON.parse(localStorage.getItem(USER_KEY));
      this.setUser(_user);
    }
    catch (error) {}
    this.route.queryParams.subscribe((params: Params) => {
      if (params.careHomeId) {
        this.setCareHomeId(params.careHomeId);
      }

      if (this.careHomeId !== 0) {
        this.subscrtiption.add(this.userCareHomeChange$
          .subscribe((value: { careHomeId: number, pageBefore: string }) => {
            this.setCareHomeId(value.careHomeId);
          }));
      }
    });
    if (this.careHomeId === 0 )
    this.subscrtiption.add(this.userCareHomeChange$
      .subscribe((value: { careHomeId: number, pageBefore: string }) => {
        this.setCareHomeId(value.careHomeId);
    }));
  }

  public login(data: any): Observable<Object> {
    return this.authService.authenticate(data)
      .pipe(
        switchMap((result: any) => {
          const user = result.userProfileInformation;
          user.token = result.token;
          user.firstTimeLogin = result.firstTimeLogin;
          return this.setUser(user);
        })
      );
  }

  private setUser(user: any) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    this.token = user.token;
    this.originalUser = user;
    this.careHomeId = user.defaultCareHomeId;
    this.careHomesService.currentCareHome = user.defaultCareHome;
    this.user.name = user.login;
    this.user.id = user.id;
    this.user.picture = getAvatarImage(this.originalUser.avatar);
    this.authenticated = true;
    return observableOf(user);
  }

  public getUser() {
    return this.user;
  }

  public getUserId() {
    return this.user.id
  }

  public setRedirectUrl(url: string) {
    sessionStorage.setItem(REDIRECT_URL_KEY, url);
  }

  public getRedirectUrl(): string {
    return sessionStorage.getItem(REDIRECT_URL_KEY);
  }

  public deleteRedirectUrl() {
    sessionStorage.removeItem(REDIRECT_URL_KEY);
  }

  public setWeeksForOccupancy(weekCounter: any) {
    sessionStorage.setItem(OCCUPY_WEEKS_KEY, weekCounter);
  }

  public getWeeksForOccupancy(): string {
    return sessionStorage.getItem(OCCUPY_WEEKS_KEY);
  }

  public logout() {
    this.deletePermissionsOnLogout();
    sessionStorage.removeItem(PICKED_CARE_HOME_KEY);
    localStorage.removeItem(USER_KEY);
    localStorage.clear();
    this.authenticated = false;
    this.token = '';
    this.originalUser = {};
    this.careHomeId = 0;
  }

  public getToken() {
    return this.token;
  }

  public getOriginalUser() {
    return this.originalUser;
  }

  public isAdmin() {
    return this.originalUser.accountType === 'Admin';
  }

  public isAuthenticated() {
    return this.authenticated;
  }

  public setCareHomeId(id: number): any {
    this.careHomeId = id;
    sessionStorage.setItem(PICKED_CARE_HOME_KEY, id.toString());
  }

  public getStoredCareHome(): string {
    return sessionStorage.getItem(PICKED_CARE_HOME_KEY);
  }

  public getCareHomeId() {
    if (this.checkIfCareHomeNotStored()) {
      return this.careHomeId;
    } else {
      return parseInt(this.getStoredCareHome(), 10);
    }
  }

  private checkIfCareHomeNotStored(): boolean {
    return this.getStoredCareHome() == null || this.getStoredCareHome() == 'NaN';
  }

  private deletePermissionsOnLogout() {
    localStorage.removeItem(PERMISSIONS_KEY);
  }

  ngOnDestroy() {
    this.subscrtiption.unsubscribe();
  }
}
