<main>
    <div mat-dialog-title>
        <span *ngIf="!data.subject">New Message</span>
        <span *ngIf="data.type==='reply'">Reply Message</span>
        <span *ngIf="data.type==='forward'">Forward Message</span>
        <div class="right-menu">
            <ngx-switcher class="switcher-container" [firstValue]="true" [secondValue]="false"
                firstValueLabel="Show advanced features" [secondValueLabel]="''" [value]="showAdvanced"
                (valueChange)="showAdvanced = !showAdvanced"></ngx-switcher>
            <button class="btn-icon" (click)=close()>
                <nb-icon icon="close"></nb-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="form" autocomplete="off">
        <div mat-dialog-content>
            <section>
                <div class="flex-between">
                    <!-- <div>
                        <div *ngIf="!data.subject" id="choose-template" (click)="chooseTemplate()">Choose message template</div>
                    </div> -->
                </div>

                <div class="form-split">
                    <div class="input-container" [ngClass]="{
                        'input-container--smaller': showAdvanced
                    }">
                        <div class="field-wrapper">
                            <ngx-multiple-autocomplete-selector-with-checkbox [values]="sendToValues" [noSelectAll]=true
                                bindLabel="name" secondBindLabel="secondName" placeholder="Search..." label="Send to"
                                acceptColorsFromElements="true" [filteredEmailsSentTo]="defaultRecipients"
                                formControlName="sendTo">
                            </ngx-multiple-autocomplete-selector-with-checkbox>
                        </div>
                        <p class="mt-3">Topic</p>
                        <mat-form-field floatLabel="never" appearance="outline">
                            <mat-label></mat-label>
                            <input matInput placeholder="Topic *" [errorStateMatcher]="matcher" formControlName="topic"
                                name="topic">
                            <mat-error>
                                <span>Topic is required</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="advanced-container" *ngIf="showAdvanced">
                        <div class="advanced-features-container">
                            <div class="form-control-group box start-1 end-9">
                                <ngx-multiple-autocomplete-selector-with-checkbox [values]="careHomes"
                                    placeholder="select care homes" label="Care homes:" formControlName="careHomeList">
                                </ngx-multiple-autocomplete-selector-with-checkbox>
                            </div>
                            <div>
                                <div class="toggle toggle--small" *ngIf="!isPined">
                                    <ngx-switcher class="switcher-container" [firstValue]="true" [secondValue]="false"
                                        [firstValueLabel]="label360" [secondValueLabel]="''" [value]="show360List"
                                        (valueChange)="change360List()"></ngx-switcher>
                                </div>
                            </div>
                            <div>
                                <div *ngIf="!isPined && !show360List" class="pin" (click)="isPined = true">
                                    <img src="/assets/images/pin.svg" /><span>pin to public noticeboard</span>
                                </div>
                                <ng-container *ngIf="isPined">
                                    <div *ngIf="!showUnpin" class="pin" (click)="isPined = false"
                                        (mouseenter)="showUnpin = true" (mouseleave)="showUnpin = false">
                                        <img src="/assets/images/pin_green.svg" /><span>message pinned</span>
                                    </div>
                                    <div *ngIf="showUnpin" class="pin" (click)="isPined = false"
                                        (mouseenter)="showUnpin = true" (mouseleave)="showUnpin = false">
                                        <img src="/assets/images/pin_red.svg" /><span style="margin-left: 0.2rem;">unpin
                                            message</span>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="field-wrapper" style="margin-block: 1rem;">
                                <ngx-multiple-autocomplete-selector-with-checkbox *ngIf="show360List"
                                    [values]="e360recipients" [noSelectAll]=true bindLabel="name"
                                    label="Employees 360 list" placeholder="Search..."
                                    formControlName="e360recipients">
                                </ngx-multiple-autocomplete-selector-with-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pin-info" *ngIf="isPined">
                    This message will be pinned for all to see. Make sure you aren't sending anything personal
                  </div>
                <div class="full-flex">
                    <p>Message</p>
                    <p class="extra-info">*Messages sent through Echo may be monitored</p>
                </div>
                <mat-form-field floatLabel="never" appearance="outline">
                    <mat-label></mat-label>
                    <textarea matInput cdkTextareaAutosize placeholder="Here you can type your message"
                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="9" cdkAutosizeMaxRows="11"
                        formControlName="message" name="message">
                    </textarea>
                    <mat-error>
                        <span>Message is required</span>
                    </mat-error>
                </mat-form-field>
            </section>
        </div>
        <div mat-dialog-actions>
            <div class="buttons flex">
                <button *ngIf="!isPined" [disabled]="form.invalid || !canSave" (click)="sendMessage()"
                    class="btn btn-success mr-3">
                    <nb-icon icon="paper-plane-outline" class="mr-1"></nb-icon>
                    Send
                </button>
                <button *ngIf="isPined" [disabled]="form.invalid || !canSave" (click)="showPinnedWarningMessage()"
                    class="btn btn-success mr-3">
                    <nb-icon icon="paper-plane-outline" class="mr-1"></nb-icon>
                    Send
                </button>
                <button (click)=close() class="btn-danger">
                    <nb-icon icon="close" class="mr-1"></nb-icon>
                    Cancel
                </button>
            </div>
            <div class="right-side">
                <div class="mr-4" *ngIf="data?.screenshot">
                    <mat-checkbox class="screenshot-checkbox" (change)="changeAttachFile($event)">Attach a
                        screenshot</mat-checkbox>
                </div>
                <div class="add-btn" (click)='clickInput()'>
                    <input hidden type="file" #FileInput (change)="fileSelected($event.target.files)" name="files"
                        multiple>
                    <button class="btn-icon" [style.color]="'unset'">
                        <nb-icon icon="attach-2-outline"></nb-icon>
                        <span>add attachment</span>
                    </button>
                </div>
            </div>
            <p *ngIf="files && files.length > 0" class="attachments-header">Attachments</p>
            <div class="files-list flex">
                <ng-container *ngFor="let file of files; index as i">
                    <div [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" [matMenuTriggerData]="{pickedFile:i}"
                        class="file mb-2">
                        <ng-container *ngIf="file.size || file.size == 0">
                            <nb-icon icon="file-text-outline"></nb-icon>
                            <span class="file-name">{{file.name}} ({{file.size*0.001 | number : '1.0-0'}} kb)</span>
                        </ng-container>
                        <ng-container *ngIf="!file.size && file.size != 0">
                            <nb-icon icon="file-text-outline"></nb-icon>
                            <span class="file-name">{{file.fileName}}</span>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</main>

<mat-menu #menu="matMenu" yPosition="above">
    <ng-template matMenuContent let-indexOfFile="pickedFile">
        <div class="popover-container flex">
            <button class="btn-icon" (click)="deleteFile(indexOfFile)">
                <nb-icon icon="close"></nb-icon>
                <span>delete</span>
            </button>

            <button class="btn-icon" (click)="openInNewTab(indexOfFile)" *ngIf="isScreenshot(indexOfFile)">
                <nb-icon icon="eye-outline"></nb-icon>
                <span>open in new tab</span>
            </button>
        </div>
    </ng-template>

</mat-menu>
