import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { WebSocketSubject } from "rxjs/internal/observable/dom/WebSocketSubject";
import { AuthUserService } from "../@core/data/auth-user.service";
import { NotificationsService } from "./notifications.service";
import { environment } from "../../environments/environment";
import { SignInOutReminderService } from "./sign-in-out-reminder.service";

export class Message {
  constructor(
    public userId: number,
    public careHomeId: number,
    public content: string | any,
    public isBroadcast = false,
    public isConnected = false,
    public messageType = 0,
  ) {}
}

@Injectable({
  providedIn: "root",
})
export class WebsocketService {
  private subscription: Subscription = new Subscription();
  private socket$: WebSocketSubject<Message>;

  constructor(
    private authUserService: AuthUserService,
    private notificationsService: NotificationsService,
    private signInOutService: SignInOutReminderService,
  ) {}

  public connect() {
    this.socket$ = new WebSocketSubject(environment.wsUrl);
    this.subscribeToSocket();
  }

  private subscribeToSocket() {
    this.socket$.subscribe(
      (message: Message) => {
        switch (message.messageType) {
          case 1:
            this.notificationsService.sendNotification(message);
            break;
          case 2:
            this.signInOutService.openSignInOutReminder(
              "sign-out",
              message.content.shiftDate,
            );
            break;
          case 3:
            this.signInOutService.openSignInOutReminder(
              "sign-in",
              message.content.shiftDate,
            );
            break;
        }
      },
      (err) => {
        this.reconnect();
      },
      () => console.warn("Completed!"),
    );
    this.sendInitMessage(
      this.authUserService.getUserId(),
      this.authUserService.getCareHomeId(),
    );
  }

  private sendInitMessage(userId: number, careHomeId: number) {
    this.socket$.next({
      userId,
      careHomeId,
      content: "Hello",
      isBroadcast: false,
      isConnected: true,
      messageType: 0,
    });
  }

  private reconnect() {
    this.connect();
    this.subscription.add(
      this.socket$.subscribe((message: Message) => {
        if (message.isConnected) {
          this.subscription.unsubscribe();
        }
      }),
    );
  }
}
