<div class="filters bottom-margin">
  <div class="flex">
    <table id="summary-table">
      <tr>
        <th>Commented<br>tasks</th>
        <th>Uncommented<br>tasks</th>
        <th>Active<br>tasks</th>
        <th>Paused<br>tasks</th>
        <th>Done<br>tasks</th>
      </tr>
      <tr>
        <td>{{taskboardSummary.commentedTasksCount || 0}}</td>
        <td>{{taskboardSummary.uncommentedTasksCount || 0}}</td>
        <td>{{taskboardSummary.activeTasksCount || 0}}</td>
        <td>{{taskboardSummary.pausedTasksCount || 0}}</td>
        <td>{{taskboardSummary.doneTasksCount || 0}}</td>
      </tr>
    </table>
  </div>
  <div class="flex filter-with-reload">
    <div style="margin-right: 10px">
      <button class="btn btn-info ml-3" (click)="loadOnlyMine()">Only my tasks</button>
    </div>
    <div style="margin-right: 10px">
      <ng-select [items]="employees" #select bindLabel="login" dropdownPosition="auto" bindValue="id"
        [loading]="employeeLoader" placeholder="Search employee" [virtualScroll]="true" name="input-employee-id"
        id="input-employee-id" [(ngModel)]="employeeId" appendTo="body" [style.width.px]="300" (ngModelChange)="showEmployeeInfo = false; loadEmployeeId.next(employeeId)">
      </ng-select>

    </div>
    <div class="form-control-group filter">
      <label class="label" for="input-filter">&nbsp;</label>
      <input nbInput
              placeholder="Filter..."
              name="filter"
              (keyup)="applyFilter($event.target.value)">
    </div>
    <button *ngIf="taskboardPermissions.canIAdd && add" class="btn btn-success ml-3" (click)="add(taskboardPermissions)"><i class="nb-plus" aria-hidden="true"></i></button>
    <button *ngIf="showReload" class="btn btn-info ml-3" (click)="load()"><i class="nb-loop" aria-hidden="true"></i></button>
  </div>
</div>
<div *ngIf="showEmployeeInfo && employeeId" class="show-assigned-task-to">
  Showing assigned tasks for: {{ getEmployeeName(employeeId) }}
</div>
<div id="table-container" class="adjustable-max-table-container" [ngClass]="{'no-scroll': !showTableScroll}">
  <table #table mat-table matSort [dataSource]="data">
    <ng-container *ngFor="let column of columns; trackBy: trackByFn" [matColumnDef]="column.fieldName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="header"
          [ngClass]="{'title': column.fieldName === 'title', 'status': column.fieldName === 'status'}">
        <span>{{column.title}}</span>
      </th>
      <ng-container *ngIf="column.fieldName === 'index'">
        <td
          mat-cell
          *matCellDef="let element"
          [ngStyle]="{'background-color': element['groupingColor']}"
          [attr.colspan]="element.isEmptySpace ? columns.length : 0"
          (click)="toogleSubtaskList(element.taskId)"
          class="small-no-column align-content-center">
          <span *ngIf="!element.isEmptySpace">
            <span *ngIf="!element.isEmptySpace">{{element[column.fieldName]}}</span>
            <span *ngIf="element.totalSubtasks > 0" style="padding-left: 0;">
              <nb-icon *ngIf="showSubtasksForTaskId != element.taskId" icon="plus-square-outline" class="subtask-expandable-icon"></nb-icon>
              <nb-icon *ngIf="showSubtasksForTaskId == element.taskId" icon="minus-square-outline" class="subtask-expandable-icon"></nb-icon>
            </span>
          </span>
          <div *ngIf="element.isEmptySpace" class="display-flex">
            <span>{{element.taskGoupingName}}</span>
            <div class="display-flex display-flex--evenly" style="flex: 1;">
              <span [nbPopover]="ragg1GroupAvgHtml" nbPopoverTrigger="hover" [ngStyle]="{'background-color': element['ragg1GroupScoreColor'], 'visibility': !taskBoardDetails.ragg1DictionaryName ? 'hidden' : 'visible'}">{{taskBoardDetails.ragg1DictionaryName}}: {{element.ragg1GroupScore}}</span>
              <span [nbPopover]="ragg2GroupAvgHtml" nbPopoverTrigger="hover" [ngStyle]="{'background-color': element['ragg2GroupScoreColor'], 'visibility': !taskBoardDetails.ragg2DictionaryName ? 'hidden' : 'visible'}">{{taskBoardDetails.ragg2DictionaryName}}: {{element.ragg2GroupScore}}</span>
              <ng-template #ragg1GroupAvgHtml>
                <div class="tool-tip" [innerHTML]="element.ragg1GroupAvg"></div>
              </ng-template>
              <ng-template #ragg2GroupAvgHtml>
                <div class="tool-tip" [innerHTML]="element.ragg2GroupAvg"></div>
              </ng-template>
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container *ngIf="column.fieldName === 'info'">
        <td
          mat-cell
          *matCellDef="let element"
          (click)="edit(element)"
          [ngStyle]="{
                  display: element.isEmptySpace ? 'none' : 'table-cell'
                }"
          class="info-column"
        >
                <span>
                  <ng-container *ngIf="element['isSubTask']">
                    <nb-icon
                      *ngIf="
                        element.isTaskCompleted && !element.isTaskNotCompleted
                      "
                      icon="checkmark-square-2-outline"
                      class="taskboard-icon green"
                    ></nb-icon>
                    <nb-icon
                      *ngIf="
                        !element.isTaskCompleted && element.isTaskNotCompleted
                      "
                      icon="close-square-outline"
                      class="taskboard-icon red"
                    ></nb-icon>
                    <nb-icon
                      *ngIf="
                        !element.isTaskCompleted && !element.isTaskNotCompleted
                      "
                      icon="square-outline"
                      class="taskboard-icon red"
                    ></nb-icon>
                  </ng-container>
                  <img
                    *ngIf="element['isAfgBell']"
                    src="./assets/images/danger.png"
                    alt=""
                  />
                  <nb-icon
                    *ngIf="!element['isSubTask'] && element['commented']"
                    icon="checkmark-circle-outline"
                    class="taskboard-icon green"
                  ></nb-icon>
                  <nb-icon
                    *ngIf="element['isFavorite']"
                    icon="star-outline"
                    class="taskboard-icon"
                  ></nb-icon>
                  <nb-icon
                    *ngIf="element['isEmailList']"
                    icon="email-outline"
                    class="taskboard-icon"
                  ></nb-icon>
                  <nb-icon
                    *ngIf="element['status'] == 'Paused'"
                    icon="pause-circle-outline"
                    class="taskboard-icon red"
                  ></nb-icon>
                  <nb-icon
                    *ngIf="element['numberOfAttachments']"
                    icon="attach-2-outline"
                    class="taskboard-icon"
                  ></nb-icon>
                  <span
                    *ngIf="
                      element.subTaskColaboration &&
                      element.subTaskColaborationTaskForMe
                    "
                  ><img
                    src="assets/images/group_my.png"
                    style="width: 20px"
                    nbPopoverTrigger="hover"
                    [nbPopover]="colaborationInfoPopover"
                  /></span>
                  <span
                    *ngIf="
                      element.subTaskColaboration &&
                      !element.subTaskColaborationTaskForMe
                    "
                  ><img
                    src="assets/images/group.png"
                    style="width: 20px"
                    nbPopoverTrigger="hover"
                    [nbPopover]="colaborationInfoPopover"
                  /></span>

                  <ng-template #colaborationInfoPopover>
                    <div class="task-tooltip" style="padding: 5px">
                      <p>Assigned to:</p>
                      <div [innerHTML]="element.assignedTo"></div>
                    </div>
                  </ng-template>
                </span>
        </td>
      </ng-container>
      <ng-container *ngIf="column.fieldName === 'title' || column.fieldName === 'taskBoardName'">
        <td mat-cell
            *matCellDef="let element"
            (click)="edit(element)"
            [ngClass]="{'high': element['priority'] == 'high', 'low': element['priority'] == 'low'}">
              <span class="title" [ngClass]="{'completed':  element.status === 'updated' && element.isTaskCompleted,
                                            'notCompleted':  element.status === 'updated' && (element.isTaskNotCompleted || (element.isSubTask && !element.isTaskCompleted))}">
                <ng-container *ngIf="element['isSubTask']">
                  <nb-icon *ngIf="element.isTaskCompleted && !element.isTaskNotCompleted" icon="checkmark-square-2-outline" class="taskboard-icon green"></nb-icon>
                  <nb-icon *ngIf="!element.isTaskCompleted && element.isTaskNotCompleted" icon="close-square-outline" class="taskboard-icon red"></nb-icon>
                  <nb-icon *ngIf="!element.isTaskCompleted && !element.isTaskNotCompleted" icon="square-outline" class="taskboard-icon red"></nb-icon>
                </ng-container>
                <img *ngIf="element['isAfgBell']" src="./assets/images/danger.png" alt=""/>
                <nb-icon *ngIf="element['isFavorite']" icon="star-outline" class="taskboard-icon"></nb-icon>
                <nb-icon *ngIf="element['isEmailList']" icon="email-outline" class="taskboard-icon"></nb-icon>
                <nb-icon *ngIf="element['numberOfAttachments']" icon="attach-2-outline" class="taskboard-icon"></nb-icon>
                {{element[column.fieldName] || element['title']}}
              </span>
        </td>
      </ng-container>
      <ng-container *ngIf="column.fieldName === 'statusToDisplay'">
          <td mat-cell
              *matCellDef="let element"
              (click)="edit(element)"
              [ngStyle]="{'display': element.isEmptySpace ? 'none': 'table-cell'}"
              [ngClass]="{'grey': element['status'] == 'Paused', 'green': element['status'] == 'Done' || element['status'] == 'D&V', 'hard-grey': element['status'] == 'D&R' || element['status'] == 'Closed'}">
            <span class="status">{{element[column.fieldName]}}</span>
            <span class="recommended-status" [innerHTML]="element['recommendedStatus']"></span>
          </td>
      </ng-container>
      <ng-container *ngIf="column.fieldName === 'status'">
        <ng-container *ngIf="!taskboardPermissions.canIChange && !taskboardPermissions.canIClose">
          <td mat-cell
              [ngStyle]="{'display': element.isEmptySpace ? 'none': 'table-cell'}"
              *matCellDef="let element"
              (click)="edit(element)"
              [ngClass]="{'grey': element['status'] == 'Paused', 'green': element['status'] == 'Done' || element['status'] == 'D&V', 'hard-grey': element['status'] == 'D&R' || element['status'] == 'Closed'}">
            <span [innerHTML]="element['statusToDisplay']"></span>
          </td>
        </ng-container>
        <ng-container *ngIf="taskboardPermissions.canIChange || taskboardPermissions.canIClose">
          <td mat-cell
          [ngStyle]="{'display': element.isEmptySpace ? 'none': 'table-cell'}"
          *matCellDef="let element"
          (click)="openStatusSelect()"
          [ngClass]="{'grey': element['status'] == 'Paused', 'green': element['status'] == 'Done' || element['status'] == 'D&V', 'hard-grey': element['status'] == 'D&R' || element['status'] == 'Closed'}">
          <span *ngIf="!element['isSubTask']" class="status-changer">
            <div class="form-control-group">
              <ng-select
              #statusSelect
              [style.width.px]="90"
              [virtualScroll]="true"
              [clearable]="false"
              [items]="statuses"
              [bindLabel]="'wordShortName'"
              [bindValue]="'wordShortName'"
              [(ngModel)]="element[column.fieldName]"
              (ngModelChange)="onChangeStatus($event, element)"
              dropdownPosition="auto"
            ></ng-select>
            </div>
            <span class="recommended-status" [innerHTML]="element['recommendedStatus']"></span>
          </span>
          <span *ngIf="element['isSubTask']" class="status">{{element[column.fieldName]}}</span>
        </td>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="column.fieldName !== 'status' && column.fieldName !== 'title'
                        && column.fieldType !== 'ragg' && column.fieldType !== 'avatar' && column.fieldType !== 'residentFullName'">
        <ng-container *ngIf="column.fieldType === 'textarea-view'">
          <td mat-cell
              class="large-column-width"
              *matCellDef="let element"
              [ngClass]="{
                    'large-column-width': column.fieldName != 'description',
                    'description-column-width':
                      column.fieldName == 'description'
                  }"
              (click)="column.click ? column.click($event, element) : edit(element)"
              [ngStyle]="{'display': element.isEmptySpace ? 'none': 'table-cell'}">
            <ngx-progress-bar *ngIf="element.totalSubtasks > 0 && column.addSubtasksProgress" [progressPercent]="element.subTasksFinishedString"></ngx-progress-bar>
            <span *ngIf="element.totalSubtasks > 0 && column.addSubtasksChangeProgress" class="right">{{element.subTasksFinishedChangePercent}}</span>
            <br *ngIf="!column.addSubtasksProgress && !column.addSubtasksChangeProgress"/>
            <textarea *ngIf="!column.formatHtml" autosize [maxRows]="7" class="textarea-view" #content
              [ngClass]="{'completed': element.status === 'updated' && element.isTaskCompleted && column.fieldName=='description',
                        'notCompleted':  element.status === 'updated' && (element.isTaskNotCompleted  || (element.isSubTask && !element.isTaskCompleted)) && element.title && column.fieldName=='description'}"
              [innerHTML]="element[column.fieldName]" readonly></textarea>
            <div *ngIf="column.formatHtml" class="textarea-view format-html" #content
              [ngClass]="{'completed': element.status === 'updated' && element.isTaskCompleted && column.fieldName=='description',
              'notCompleted': element.status === 'updated' && (element.isTaskNotCompleted  || (element.isSubTask && !element.isTaskCompleted)) && element.title && column.fieldName=='description'}"
              [innerHTML]="element[column.fieldName]" readonly></div>
          </td>
        </ng-container>
        <ng-container *ngIf="column.fieldType !== 'textarea-view'">
          <td mat-cell
              *matCellDef="let element"
              (click)="column.click ? column.click($event, element) : edit(element)"
              [ngStyle]="{'display': element.isEmptySpace ? 'none': 'table-cell'}">
            <span #content [class]="column.class">{{element[column.fieldName]}}</span>
            <p *ngIf="isOverflow(content)" class="blue" (click)="column.click ? column.click($event, element) : '' ">... see more {{countLines(content)}}</p>
          </td>
        </ng-container>
      </ng-container>

    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="thinner"
        [ngClass]="{'green': !row.isSubTask && row.commented,
                    'grey': row.status == 'Paused' || row.status == 'D&V',
                    'hard-grey': row.status == 'D&R' || row.status == 'Closed',
                    'thicker-line': row.isSubTask && row.isDrawLineAfter,
                    'hidden': row.isSubTask,
                    'visible': showSubtasksForTaskId == row.subTaskForTaskId}"></tr>
  </table>
</div>
