import { AfterViewInit, Component } from "@angular/core";
import { AuthUserService } from "./@core/data/auth-user.service";
const moment = require("moment-timezone");

@Component({
  selector: "ngx-app",
  template: ` <router-outlet></router-outlet
    ><ng-container *ngIf="isAuthenticated"
      ><ngx-task-tabs></ngx-task-tabs
    ></ng-container>`,
})
export class AppComponent implements AfterViewInit {
  constructor(public authUserService: AuthUserService) {
    moment.tz.setDefault("UTC");
  }

  get isAuthenticated(): boolean {
    return this.authUserService.isAuthenticated();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let spinnerContainer = document.getElementById("spinner-container");
      if (spinnerContainer && spinnerContainer.parentNode) {
        spinnerContainer.parentNode.removeChild(spinnerContainer);
      }
    }, 300);
  }
}
