<div class="new-doc-container">
  <mat-horizontal-stepper linear [@.disabled]="true" #stepper>
    <!--    TODO: Usunąć do produkcji - notRota-->
    <mat-step [completed]="files.length > 0" *ngIf="notRota">
      <ngx-file-upload
        [files]="files"
        [displayedColumns]="displayedColumns"
        [isFileSendEnabled]="true"
        [isDownloadEnabled]="false"
        [isAddNewContractDoc]="true"
        (onUpload)="uploadFile($event)"
        (onDelete)="deleteFile($event)"
        (onDeleteMulti)="deleteMultiFile($event)">
      </ngx-file-upload>

      <div class="btn-container">
        <button class="btn btn-danger" (click)="closeWindow()">
          <i class="nb-close" aria-hidden="true"></i> Cancel
        </button>
        <button [disabled]="files.length == 0" mat-button matStepperNext class="btn btn-success" (click)="addNewContract = true">
          Next
          <img src="assets/images/arrowhead-right-white.svg" alt="next">
        </button>
      </div>
    </mat-step>
    <!--    TODO: Usunąć do produkcji - notRota-->
     <mat-step [completed]="newContractAdded" *ngIf="notRota">
      <ngx-new-contract
        [activeOnly]="activeOnly"
        [decisionType]="decisionType"
        [allEmployeeContracts]="contracts"
        (save)="saveContractAndGoNext($event)"
        (uploadToContract)="uploadFilesToContract($event)"
        (finish)="closeWindow()"
        (decisionTypeChange)="decisionTypeChange($event)"
      ></ngx-new-contract>
      <div class="btn-container">
        <button class="btn btn-danger" (click)="closeWindow()">
          <i class="nb-close" aria-hidden="true"></i> Cancel
        </button>
      </div>
    </mat-step>
    <mat-step *ngIf="decisionType == 'first'">
      <div [class.ngx-rota-migration-container]="isAtEnd">
        <ngx-rota-migration
          *ngIf="newContractAdded && isAtEnd"
          [employeeId]="employeeId"
          (closeWindow)="finished()"
        ></ngx-rota-migration>
      </div>
      <div class="btn-container">
        <button class="btn btn-danger" (click)="closeWindow()">
          <i class="nb-close" aria-hidden="true"></i> Cancel
        </button>
        <button class="btn btn-success" (click)="finished()">
          <i class="nb-checkmark" aria-hidden="true"></i> Finished
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
