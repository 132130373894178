import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { NbMenuItem, NbSidebarService, NbSidebarState } from "@nebular/theme";
import { Subscription, switchMap, take } from "rxjs";
import { AuthUserService } from "../../../@core/data/auth-user.service";
import { MatAccordion } from "@angular/material/expansion";
import { CareHomesService } from "../../../shared/care-homes/care-homes.service";
import { CareHomesInfoService } from "../../../pages/shared/services/care-homes-info.service";
import { CurrentCareHomeService } from "../../../@core/data/current-care-home.service";
import { ScreenshotService } from "../../../shared/screenshot.service";
import { EditUserComponent } from "../../../pages/users/edit-user/edit-user.component";
import { TableService } from "../../../pages/shared/table/table.service";
import { SideMenuAccordionService } from "./side-menu-accordion/side-menu-accordion.service";
import { SideMenuOptionsService } from "./side-menu-options-accordion/service/side-menu-options.service";
import {MessageNotification, NotificationsService} from "../../../shared/notifications.service";
import {WebsocketService} from "../../../shared/websocket.service";

@Component({
  selector: "ngx-app-menu",
  templateUrl: "./app-menu.component.html",
  styleUrls: ["./app-menu.component.scss"],
})
export class AppMenuComponent implements OnInit, OnDestroy {
  public userMenu = [
    { title: "My Profile", action: "profile" },
    { title: "Logout", action: "logout" },
  ];
  public updateMessage: string = "";
  @Input() set items(val: NbMenuItem[]) {
    if (val) {
      this.menuItems = [...val];
      this.originalItems = [...val];
    }
  }

  @Input() set settingsMenu(val: NbMenuItem[]) {
    if (val) {
      this.settingsMenuItems = [...val];
      this.settingsOriginalItems = [...val];
    }
  }

  @Output() composeMessage = new EventEmitter<void>();
  @Output() search = new EventEmitter<string>();

  public user: any;
  public isOpen = false;
  public isUserAdmin: boolean = false;
  public inputValue: string = "";
  originalItems: NbMenuItem[] = [];
  menuItems: NbMenuItem[] = [];
  settingsMenuItems: NbMenuItem[] = [];
  settingsOriginalItems: NbMenuItem[] = [];
  openedIndex = -1;
  openedSettingsIndex = -1;
  openedSubmenuIndex = -1;
  openedSettingsSubmenuIndex = -1;
  isCompacted = false;
  openedUrl = localStorage.getItem("echo_active_link")
    ? localStorage.getItem("echo_active_link")
    : "";
  private subscription = new Subscription();
  public defaultCareHomeId = 0;
  public careHomes: any = [];
  public eMarUrl = "";
  public eMarName = "";
  public carePlansUrl = "";
  public carePlansName = "";
  public areOptionsOpen = false;
  public get settingsOpened() {
    return this.sideMenuService.settingsOpened.value;
  }

  public notifications = {
    messages: 0,
    notifications: 0
  };

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    private router: Router,
    private tableService: TableService,
    private authUserService: AuthUserService,
    private sidebarService: NbSidebarService,
    private careHomesService: CareHomesService,
    private _careHomesInfoService: CareHomesInfoService,
    private currentCareHomeService: CurrentCareHomeService,
    private screenShotService: ScreenshotService,
    private sideMenuService: SideMenuAccordionService,
    private notificationsService: NotificationsService,
    private websocketService: WebsocketService,
  ) {}

  ngOnInit(): void {
    this.user = this.authUserService.getUser();
    this.isUserAdmin = this.authUserService.isAdmin();

    this.openLink();
    this.subscription.add(
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.openedUrl = val.url;
          this.findSelectedPositionName(this.openedUrl);
        }
      }),
    );

    this.subscription.add(
      this.sideMenuService.onOpen$.subscribe(() => {
        this.areOptionsOpen = false;
      }),
    );

    this.careHomesService.getCareHomes().subscribe((response: any) => {
      const _careHomes = response.result.careHomesList;
      _careHomes.unshift({
        careHomeId: 0,
        careHomeFullName: "ALL CARE HOMES",
      });
      this.careHomes = _careHomes;

      this._careHomesInfoService.setCareHomes(_careHomes);

      this.setCareHomeName();
      this.careHomesService.setCareHome(
        this.careHomes,
        this.authUserService.getCareHomeId(),
      );
    });

    this.defaultCareHomeId = this.authUserService.getCareHomeId();

    this.subscription.add(this.updateSidebar());

    this.subscription.add(
      this.sidebarService
        .onToggle()
        .pipe(
          switchMap(() => {
            return this.sidebarService.getSidebarState("menu-sidebar");
          }),
        )
        .subscribe((state: NbSidebarState) => {
          if (state === "compacted") {
            this.openedIndex = -1;
            this.openedSubmenuIndex = -1;
            this.isCompacted = true;
            this.isOpen = false;
            return;
          }

          this.areOptionsOpen = false;
          this.isOpen = false;
          this.isCompacted = false;
        }),
    );

    this.websocketService.connect();
    this.notificationsService.messagesToRead$.subscribe((messageNotification: MessageNotification) => {
      this.notifications.messages = messageNotification.unreadMessages;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private updateSidebar() {
    return this.sidebarService
      .getSidebarState("menu-sidebar")
      .pipe(take(1))
      .subscribe((state: NbSidebarState) => {
        if (state === "compacted") {
          this.openedIndex = -1;
          this.openedSubmenuIndex = -1;
          this.isCompacted = true;
          this.isOpen = false;
          return;
        }

        this.areOptionsOpen = false;
        this.isOpen = false;
        this.isCompacted = false;
      });
  }

  private filterMenu(textToSearch: string) {
    const _menu = [];
    this.originalItems.forEach((item: any) => {
      const itemToCopy = Object.assign({}, item);
      const childrenArray = [];

      item.children.forEach((child: any) => {
        if (child.children) {
          const children = child.children.filter((ch) => {
            return ch.title.toLowerCase().includes(textToSearch?.toLowerCase());
          });
          if (children.length > 0) {
            childrenArray.push({ ...child, children });
            return;
          }
        }

        if (child.title.toLowerCase().includes(textToSearch?.toLowerCase())) {
          childrenArray.push(child);
        }
      });

      if (childrenArray.length > 0) {
        itemToCopy.children = childrenArray;
        _menu.push(itemToCopy);
      }
    });

    if (_menu.length > 0) {
      this.sideMenuService.filteredIndex.next(0);
    }

    this.menuItems = _menu;
  }

  private openLink() {
    const lastLink = localStorage.getItem("echo_active_link");
    const lastTitle = localStorage.getItem("echo_active_title");
    if (lastLink && !lastLink.includes("/task-management/task-boards/")) {
      let hasTitle = false;
      this.menuItems.forEach((e, i) => {
        if (lastLink.includes(e.link)) {
          this.openedIndex = i;
        } else {
          e?.children?.forEach((ch) => {
            if (lastLink.includes(ch.link)) {
              this.openedIndex = i;
            }
          });
        }
        if (lastTitle == e.title) {
          hasTitle = true;
        }
      });
    } else if (lastLink) {
      const splittedTitle = lastTitle.split(" - ")[1];
      this.openedIndex = this.menuItems.findIndex(
        (e) => e.link === "/task-management",
      );
      let findTaskBoard = false;
      this.menuItems[this.openedIndex]?.children?.forEach((e) => {
        if (e.title.includes(splittedTitle)) {
          findTaskBoard = true;
          this.router.navigate([e.link]);
        }
        if (!findTaskBoard) {
          this.router.navigate(["/dashboard/dashboard"]);
        }
      });
    }
  }

  private findSelectedPositionName(url: string) {
    this.menuItems.forEach((e) => {
      if (e.link === url) {
        localStorage.setItem("echo_active_link", e.link);
        localStorage.setItem("echo_active_title", e.title);
      } else if (e.children && e.children.length > 0) {
        e.children.forEach((child) => {
          if (child.link === url) {
            localStorage.setItem("echo_active_link", child.link);
            localStorage.setItem("echo_active_title", child.title);
          } else if (child.children && child.children.length > 0) {
            child.children.forEach((ch) => {
              if (ch.link === url) {
                localStorage.setItem("echo_active_link", ch.link);
                localStorage.setItem("echo_active_title", ch.title);
              }
            });
          }
        });
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.subscription.add(this.updateSidebar());
  }

  onChangeCareHome(event: any) {
    const pageBefore = this.router.url.split("?")[0];
    if (this.authUserService.getCareHomeId() !== event.careHomeId) {
      this.authUserService.setCareHomeId(event.careHomeId);
      this.authUserService.userCareHomeChange$.next({
        careHomeId: event.careHomeId,
        pageBefore: pageBefore,
      });
      this.careHomesService.setCareHome(
        this.careHomes,
        this.authUserService.getCareHomeId(),
      );
    }
  }

  setCareHomeName() {
    const actualCareHome = this.careHomes.find((item: any) => {
      if (item.careHomeId == this.defaultCareHomeId) {
        if (item.urlEmar) {
          this.eMarUrl = item.urlEmar;
          if (item.urlEmarName) {
            this.eMarName = item.urlEmarName;
          }
        } else {
          this.eMarUrl = "";
          this.eMarName = "";
        }
        if (item.urlCarePlans) {
          this.carePlansUrl = item.urlCarePlans;
          if (item.urlCarePlansName) {
            this.carePlansName = item.urlCarePlansName;
          }
        } else {
          this.carePlansUrl = "";
          this.carePlansName = "";
        }
        return item;
      }
    });

    if (actualCareHome) {
      this.currentCareHomeService.careHomeName$.next(
        actualCareHome.careHomeFullName,
      );
    }
  }

  public onComposeMessage() {
    this.composeMessage.emit();
  }

  toggleSidebar(isCompacted: boolean): boolean {
    if (isCompacted) {
      this.sidebarService.toggle(true, "menu-sidebar");
      this.sideMenuService.close();
      setTimeout(() => {
        document.getElementById("search-in-menu").focus();
      });
    }
    return false;
  }

  onInput(val: string) {
    this.inputValue = val;
    this.filterMenu(val);
  }

  sendMessageToSupport() {
    // this.captureScreen();
    this.screenShotService.captureScreen();
  }

  onMenuItemClick(action: "logout" | "profile") {
    this.isOpen = false;
    switch (action) {
      case "logout": {
        this.authUserService.logout();
        this.router.navigateByUrl("/");
        location.reload();
        break;
      }
      case "profile": {
        this.tableService.openWindowWithoutCareHomeVerification(
          EditUserComponent,
          "User Profile Information",
          this.authUserService.getOriginalUser().id,
        );
        break;
      }
    }
  }

  public toggleOptionsSidebarIfCollapsed() {
    if (this.isCompacted) {
      this.sideMenuService.close();
      this.areOptionsOpen = !this.areOptionsOpen;
    }
  }

  hideOptionsSidebar() {
    this.areOptionsOpen = false;
  }

  public openSettingsMenu() {
    if (this.isCompacted) return;

    this.sideMenuService.settingsOpened.next(!this.settingsOpened);
  }
}
