<main>
    <div mat-dialog-title>
        <span>New message to Support</span>
        <span class="right-menu">
            <button class="btn-icon" (click)=close()>
                <nb-icon icon="close"></nb-icon>
            </button>
        </span>
    </div>
    <form [formGroup]="form" autocomplete="off">
        <div mat-dialog-content>
            <section>
                <div *ngIf="data.type!=='reply'" class="field-wrapper">
                    <ngx-multiple-autocomplete-selector-with-checkbox [values]="sendToValues" [noSelectAll]=true
                        bindLabel="name" secondBindLabel="secondName" placeholder="Search..." label="Send to"
                        acceptColorsFromElements="true" [filteredEmailsSentTo]="defaultRecipients"
                        formControlName="sendTo">
                    </ngx-multiple-autocomplete-selector-with-checkbox>
                </div>
                <p class="mt-3">Topic</p>
                <mat-form-field floatLabel="never" appearance="outline">
                    <input matInput placeholder="Topic" [errorStateMatcher]="matcher" formControlName="topic"
                        name="topic">
                    <mat-error>
                        <span>Topic is required</span>
                    </mat-error>
                </mat-form-field>
                <div class="full-flex">
                    <p>Message</p>
                    <p class="extra-info">*Messages sent through Echo may be monitored</p>
                </div>
                <mat-form-field floatLabel="never" appearance="outline">
                    <textarea matInput cdkTextareaAutosize placeholder="Here you can type your message"
                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="9" cdkAutosizeMaxRows="11"
                        formControlName="message" name="message">
                    </textarea>
                    <mat-error>
                        <span>Message is required</span>
                    </mat-error>
                </mat-form-field>
            </section>
        </div>
        <div mat-dialog-actions>
            <div class="buttons flex">
                <button [disabled]="form.invalid || !canSave || disableExternal" (click)="sendMessage()"
                    class="btn btn-success mr-3">
                    <nb-icon icon="paper-plane-outline" class="mr-1"></nb-icon>
                    Send via external email app
                </button>
                <button [disabled]="form.invalid || !canSave" (click)="sendMessage()" class="btn btn-success mr-3">
                    <nb-icon icon="paper-plane-outline" class="mr-1"></nb-icon>
                    Send
                </button>
                <button (click)=close() class="btn-danger">
                    <nb-icon icon="close" class="mr-1"></nb-icon>
                    Cancel
                </button>
            </div>
            <div class="right-side">
                <div style="margin-top: 6px;">
                    <mat-checkbox [checked]="attachScreenshot" class="screenshot-checkbox"
                        (change)="changeAttachFile($event)">Attach
                        screenshot</mat-checkbox>
                </div>
                <div class="add-btn" (click)='clickInput()'>
                    <input hidden type="file" #FileInput (change)="fileSelected($event.target.files)" name="files"
                        multiple>
                    <button class="btn-icon" [style.color]="'unset'">
                        <nb-icon icon="attach-2-outline"></nb-icon>
                        <span>Add attachment</span>
                    </button>
                </div>
            </div>
            <p *ngIf="files && files.length > 0" class="attachments-header">Attachments</p>
            <div class="files-list flex">
                <div class="files-list flex">
                    <ng-container *ngFor="let file of files; index as i">
                        <div [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
                            [matMenuTriggerData]="{pickedFile:i}" class="file mb-2">
                            <ng-container *ngIf="file.size || file.size == 0">
                                <nb-icon icon="file-text-outline"></nb-icon>
                                <span class="file-name">{{file.name}} ({{file.size*0.001 | number : '1.0-0'}} kb)</span>
                            </ng-container>
                            <ng-container *ngIf="!file.size && file.size != 0">
                                <nb-icon icon="file-text-outline"></nb-icon>
                                <span class="file-name">{{file.fileName}}</span>
                            </ng-container>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
    </form>
</main>

<mat-menu #menu="matMenu" yPosition="above">
    <ng-template matMenuContent let-indexOfFile="pickedFile">
        <div class="popover-container flex">
            <button class="btn-icon" (click)="deleteFile(indexOfFile)">
                <nb-icon icon="close"></nb-icon>
                <span>delete</span>
            </button>
            <button class="btn-icon" (click)="openInNewTab(indexOfFile)" *ngIf="isScreenshot(indexOfFile)">
                <nb-icon icon="eye-outline"></nb-icon>
                <span>open in new tab</span>
            </button>
        </div>
    </ng-template>

</mat-menu>
