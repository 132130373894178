<div class="head mt-3 mb-4" *ngIf="!isLoading">
  <div class="flex">
    <button class="btn-icon" (click)="back()">
      <nb-icon class="color-success" icon="arrow-back-outline"></nb-icon>
    </button>
    <button
      class="btn-icon"
      (click)="refreshMenu()"
      matTooltip="Refresh"
      matTooltipPosition="above"
    >
      <nb-icon icon="refresh-outline"></nb-icon>
    </button>
    <button
      class="btn-icon"
      *ngIf="!sendMessage"
      (click)="changeMessageCategory(486, 'Archived')"
      matTooltip="Move to archive"
      matTooltipPosition="above"
    >
      <nb-icon icon="archive-outline"></nb-icon>
    </button>
    <div class="read-status">
      <button
        *ngIf="!sendMessage"
        mat-button
        class="btn-info mr-2"
        (click)="markReadStatus()"
      >
        <span class="wrapp">
          <nb-icon icon="email"></nb-icon>
          <span *ngIf="message.readed">mark as unread </span>
          <span *ngIf="!message.readed"> mark as read </span>
        </span>
      </button>
      <span *ngIf="message.readed">
        <span *ngIf="message.readedAt != 'n/a'"
          >readed at: {{ message.readedAt }}</span
        >
        <span *ngIf="message.readedAt == 'n/a'">readed: now</span>
      </span>
      <span *ngIf="!message.readed">unreaded</span>
    </div>
  </div>
  <div class="flex">
    <ng-container *ngIf="permissions.NOTICEBOARD_MANAGEMENT">
      <div *ngIf="!message.isPined" class="pin" (click)="pinMessage(true)">
        <img src="/assets/images/pin.svg" /><span>pin to public noticeboard</span>
      </div>
      <ng-container *ngIf="message.isPined">
        <div
          *ngIf="!showUnpin"
          class="pin"
          (click)="pinMessage(false)"
          (mouseenter)="showUnpin = true"
          (mouseleave)="showUnpin = false"
        >
          <img src="/assets/images/pin_green.svg" /><span>message pinned</span>
        </div>
        <div
          *ngIf="showUnpin"
          class="pin"
          (click)="pinMessage(false)"
          (mouseenter)="showUnpin = true"
          (mouseleave)="showUnpin = false"
        >
          <img src="/assets/images/pin_red.svg" /><span
            style="margin-left: 0.2rem"
            >unpin message</span
          >
        </div>
      </ng-container>
    </ng-container>
    <span (click)="downloadPdf()" class="pdf-button"
      ><nb-icon icon="download-outline"></nb-icon> PDF</span
    >
  </div>
</div>
<div class="pin-info" *ngIf="message?.isPined">
  This message will be pinned for all to see. Make sure you aren't sending anything personal
</div>
<div *ngIf="!isLoading" class="shadow-wrapper">
  <div class="message-wrapp">
    <h2><span>Subject:</span> {{ message.subject }}</h2>
    <div class="message-header mt-2 mb-1">
      <div class="sender-wrapper flex mb-3">
        <ngx-image-viewer [imageData]="avatarSender"> </ngx-image-viewer>
        <div class="sender-data ml-2">
          <span *ngIf="!sendMessage"
            >{{ message.fromName }} {{ message.fromEmail ? '(' + message.fromEmail + ') ' : '' }}[{{message.fromCareHomeShortName}}]</span
          >
          <span *ngIf="sendMessage"
            >{{ message.toName }} {{ message.toEmail ? '(' + message.toEmail + ')' : '' }}[{{message.fromCareHomeShortName}}]</span
          >
          <div class="job-title">Employee</div>
        </div>
      </div>
      <div class="message-menu flex">
        <span>sent at: {{ message.careatedAt }}</span>
        <button
          class="btn-icon"
          (click)="createReplyForward('reply')"
          matTooltip="Reply"
          matTooltipPosition="above"
        >
          <nb-icon icon="undo"></nb-icon>
        </button>
        <button
          class="btn-icon"
          [disabled]="message.previousMessage == ''"
          (click)="shiftMessage(message.previousMessage)"
          matTooltip="Previous unreaded message"
          matTooltipPosition="above"
        >
          <nb-icon icon="rewind-left"></nb-icon>
        </button>
        <button
          class="btn-icon"
          [disabled]="message.nextMessage == ''"
          (click)="shiftMessage(message.nextMessage)"
          matTooltip="Next readed message"
          matTooltipPosition="above"
        >
          <nb-icon icon="rewind-right"></nb-icon>
        </button>
        <button
          [matMenuTriggerFor]="menu"
          #menuTrigger="matMenuTrigger"
          class="btn-icon"
        >
          <nb-icon icon="more-vertical"></nb-icon>
        </button>
      </div>
    </div>
    <ng-container
      *ngIf="
        message && message.recipientsList && message.recipientsList.length > 0
      "
    >
      <h2
        *ngIf="showRecipients"
        class="recipients"
        (click)="showRecipients = !showRecipients"
      >
        <div>
          <span>Recipients:</span>
          <p
            class="recipient-item"
            *ngFor="let recipient of message.recipientsList"
          >
            {{ recipient.fullName }},
          </p>
        </div>
        <div style="margin-left: 5px">
          <nb-icon icon="chevron-up-outline"></nb-icon>
        </div>
      </h2>
      <h2 *ngIf="!showRecipients" class="recipients">
        <div>
          <span
            >Recipients: [{{
              message &&
                message.recipientsList &&
                message.recipientsList.length
            }}]</span
          >
        </div>
        <div
          style="margin-left: 5px"
          (click)="showRecipients = !showRecipients"
        >
          <nb-icon icon="chevron-down-outline"></nb-icon>
        </div>
      </h2>
    </ng-container>
    <div
      class="message-text mt-1"
      [innerHTML]="setUpText(message.message | linkify)"
      readonly
    ></div>
    <div class="attachments">
      Attachments
      <div class="files flex mt-3">
        <div
          (click)="downloadDocument(file.uid)"
          class="file-wrapper flex mr-5"
          *ngFor="let file of message.attachmentsList"
        >
          <div class="file">
            <ngx-image-viewer
              *ngIf="file.type == 'image/jpeg' || file.type == 'image/png'"
              [imageData]="file.url"
            >
            </ngx-image-viewer>
            <img
              *ngIf="file.type == 'application/pdf'"
              src="../../../../assets/images/pdf.png"
              alt=""
            />
            <img
              *ngIf="
                file.type != 'image/jpeg' &&
                file.type != 'image/png' &&
                file.type != 'application/pdf'
              "
              src="../../../../assets/images/document.png"
              alt=""
            />
            <span>{{ file.fileName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isLoading" class="table-container">
  <div class="table-spinner-container">
    <div id="nb-global-spinner" class="spinner">
      <img src="./assets/images/echo_sygnet.svg" alt=""/>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu" class="more-list" xPosition="before">
  <ul>
    <li (click)="createReplyForward('forward')">
      <nb-icon icon="arrow-right"></nb-icon>
      <span>Forward</span>
    </li>
  </ul>
</mat-menu>
