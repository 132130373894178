export const DictionariesApiUrls = {
  contractTypes: '/dictionaries?dictionaryFullName=Contract%20types',
  professionalRoles: '/dictionaries/professionalRoles',
  genders: '/dictionaries?dictionaryFullName=Gender',
  titles: '/dictionaries?dictionaryFullName=Title',
  maritalStatus: '/dictionaries?dictionaryFullName=Marital%20staus',
  statuses: '/dictionaries?dictionaryFullName=Common%20names',
  userStatuses: '/dictionaries?dictionaryFullName=Users%20statuses',
  employeeStatuses: '/dictionaries?dictionaryFullName=Employee%20status',
  userPermissions: '/dictionaries?dictionaryFullName=User%20permissions%20-%20CH',
  eventTypes: '/dictionaries?dictionaryFullName=Contract%20events',
  courseStatuses: '/dictionaries?dictionaryFullName=Training%20statuses',
  mealSizes: '/dictionaries?dictionaryFullName=Meal%20Size',
  breads: '/dictionaries?dictionaryFullName=Bread',
  taskPriorities: '/dictionaries?dictionaryFullName=Tasks%20priority',
  taskCategories: '/dictionaries?dictionaryFullName=Tasks%20category',
  pinnedTasks: '/dictionaries?dictionaryFullName=Pinned%20tasks',
  messageGroups: '/dictionaries?dictionaryFullName=Message%20groups',
  taskStatuses: '/dictionaries?dictionaryFullName=Tasks%20status',
  taskStatusesAll: '/dictionaries?dictionaryFullName=Tasks%20status&takeAll=true',
  payrollTypes: '/dictionaries?dictionaryFullName=Payroll%20Adjustment%20Types',
  residencyStatuses: '/dictionaries?dictionaryFullName=Residency%20status',
  p45Values: '/dictionaries?dictionaryFullName=P45',
  studentLoanTypes: '/dictionaries?dictionaryFullName=Student%20loan%20type',
  employeeStatements: '/dictionaries?dictionaryFullName=Employee%20statement',
  userProfiles: '/dictionaries/profileRoles',
  permissions: '/dictionaries?dictionaryFullName=User%20permissions%20-%20NEW',
  payrollStatuses: '/dictionaries?dictionaryFullName=Payroll%20adjustment%20stauses',
  securityLevels: '/dictionaries?dictionaryFullName=Security%20levels',
  medicalUrgencies: '/dictionaries?dictionaryFullName=Medical%20urgency',
  trainingCategories: '/dictionaries?dictionaryFullName=Training%20category',
  trainingCategoriesFilters: '/dictionaries?dictionaryFullName=Training%20category%20Filter',
  taskTags: '/dictionaries?dictionaryFullName=Task%20tag',
  typeOfFinancing: '/dictionaries?dictionaryFullName=Type%20of%20financing',
  agency: '/dictionaries/agencies',
  stuff: '/dictionaries/stuff',
  stuffGroups: '/dictionaries?dictionaryFullName=stuffCategories',
};
