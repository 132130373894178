import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Message } from './websocket.service';

export enum NotificationSource {
  INBOX, OTHERS
}

export interface MessageNotification {
  source: NotificationSource;
  unreadMessages: number;
  unreadNotifications: number;
}

enum MessageType {
  MESSAGE_TO_READ = 1
}
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public notifications = {
    messages: 0,
    notifications: 0
  };
  public messagesToRead$ = new Subject<MessageNotification>();

  notifyAboutMessagesToRead(messageNotification: MessageNotification) {
    this.messagesToRead$.next(messageNotification);
    this.notifications.messages = messageNotification.unreadMessages;
    this.notifications.notifications = messageNotification.unreadNotifications;
  }

  sendNotification(message: Message) {
    if (message.messageType === MessageType.MESSAGE_TO_READ) {
      this.notifications.messages = message.content.unreadedTotalCount;
      this.notifications.notifications = message.content.unreadedNoticeboardCount;
      this.notifyAboutMessagesToRead({
        source: NotificationSource.OTHERS,
        unreadMessages: message.content.unreadedTotalCount,
        unreadNotifications: message.content.unreadedNoticeboardCount
      });
    }
  }

  getNotifications() {
    return this.notifications;
  }
}
