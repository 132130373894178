<div class="form-view-grid">
  <main id="fast-editor-container-main">
    <form [formGroup]="form" style="margin-right: 0.5rem;">
      <div class="grid-wrapper">
        <div class="form-control-group box start-1 end-12">
          <label class="label" for="input-taskboard">Title:</label>
          <input nbInput
                fullWidth
                class="disabled-field"
                [value]="task.title"
                disabled>
        </div>
        <div class="form-control-group box start-1 end-12" style="height: initial; margin-top: -2rem;">
          <label class="label" for="comments">Description:</label>
          <textarea nbInput
                    class="readonly-textarea disabled-field"
                    style="height: 137px;"
                    fullWidth
                    formControlName="comments"
                    name="comments"
                    placeholder="Add notes about this task"
                    readonly
                    autofocus>
          </textarea>
        </div>
      </div>

      <ngx-subtask-editor
        [task]="task"
        [taskId]="task.taskId"
        [taskBoardId]="task.taskBoardId"
        [canAdd]="false"
        [updateMode]="true"
        (subTasksChanged)="subtasksChanged($event)"
        (reloadParent)="refreshForSubtasks()">
      </ngx-subtask-editor>

      <div class="grid-wrapper mb-3">
        <div class="box start-1 end-3">
          <label class="label" for="input-due-to">Deadline:</label>
          <input nbInput
                  fullWidth
                  formControlName="dueTo"
                  name="dueTo"
                  id="input-due-to"
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker startView="month"></mat-datepicker>
        </div>
        <div class="box start-3 end-6 wu-email">
          <nb-checkbox (change)="changeEmailList($event)" [checked]="this.form.get('isEmailList').value">email with WU</nb-checkbox>
        </div>
      </div>

      <div class="grid-wrapper">
        <div class="box start-1 end-3">
          <div *ngIf="!isHideStatus && !isClosed">
            <label class="label" for="input-status">Status:</label>
            <select formControlName="statusId">
              <option *ngFor="let status of statuses" [ngValue]="status.id">{{status.wordFullName}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="task.groupingDictionaryName" class="box start-3 end-5">
          <label class="label">{{task.groupingDictionaryName}}:</label>
          <select formControlName="taskGoupingId" [ngStyle]="{'background-color': raggsColors.groupingColor}">
            <option *ngFor="let group of grouping" [ngValue]="group.id"
              [ngStyle]="{'background-color': group.colorHash}">{{group.wordFullName}}</option>
          </select>
        </div>
        <div *ngIf="task.ragg1DictionaryName" class="box start-5 end-7">
          <label class="label">{{task.ragg1DictionaryName}}:</label>
          <select formControlName="taskRagg1Id" [ngStyle]="{'background-color': raggsColors.ragg1Color}">
            <option *ngFor="let rag of raggs1" [ngValue]="rag.id" [ngStyle]="{'background-color': rag.colorHash}">
              {{rag.wordFullName}}</option>
          </select>
        </div>
        <div *ngIf="task.ragg2DictionaryName" class="box start-7 end-9">
          <label class="label">{{task.ragg2DictionaryName}}:</label>
          <select formControlName="taskRagg2Id" [ngStyle]="{'background-color': raggsColors.ragg2Color}">
            <option *ngFor="let rag of raggs2" [ngValue]="rag.id" [ngStyle]="{'background-color': rag.colorHash}">
              {{rag.wordFullName}}</option>
          </select>
        </div>
      </div>

      <div class="grid-wrapper" *ngIf="isClosed">
        <div class="box start-1 end-9">
          <span [innerHTML]="task.statusToDisplayLine"></span>
        </div>
      </div>

    </form>
  </main>
</div>
<footer style="padding: 1rem 0 0; border-top: 1px solid #ebeef2;">
  <div id="footer-task-progress" [ngStyle]="{'background-color': taskProgressBackground}">
    <div class="progress-info">
      <nb-icon icon="info-outline" style="margin-right: 0.5rem;"></nb-icon>
      <p [innerHTML]="taskProgress"></p>
    </div>
  </div>
  <div class="flex space">
    <div class="flex">
      <button class="btn btn-success" (click)="save()">
        <i class="nb-checkmark" aria-hidden="true"></i> Save
      </button>
      <button class="btn btn-danger ml-4" (click)="closeWindow()">
        <i class="nb-close" aria-hidden="true"></i> Close
      </button>
    </div>
  </div>
</footer>
