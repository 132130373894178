<div class="pinned-tasks" [style.bottom]="bottom" [ngStyle]="{'z-index': currentOpen ? 9999 : 98}">
  <div class="pinned-tasks-header" [ngClass]="{ 'visible': showPinnedTasksFlag && currentOpen }" (click)="showPinnedTasks()" [style.margin-right.px]="!onlyGlobal ? rightMargin : (cardHeader === 'Global' ? rightMargin : (rightMargin + 340))">
    <div class="pinned-tasks-header-icon">
      <i
        [ngClass]="showPinnedTasksFlag ?  'nb-arrow-down': 'nb-arrow-up'"
      >
      </i>
    </div>
    <div class="pinned-tasks-header-title">
      <div><span>{{taskContextInfo.totalTasks == 1 ? taskContextInfo.totalTasks + ' task ' : taskContextInfo.totalTasks + ' tasks '}}</span><span *ngIf="cardHeader !== 'Global'">
        related to {{cardHeader}}
      </span>
      <span *ngIf="cardHeader === 'Global'">on my watchlist</span>
      </div>
      <div><span [style.color]="'#ffc107'">{{taskContextInfo.totalToComment == 1 ? taskContextInfo.totalToComment + ' todo ' : taskContextInfo.totalToComment + ' todos '}}</span>to comment</div>
    </div>
  </div>
  <div
    class="pinned-tasks-content resize-touchpoint"
    (click)="showPinnedTasksFlag && resize($event, pinnedTasksContent)"
    [ngClass]="showPinnedTasksFlag ? 'pinned-tasks-content visible' : 'pinned-tasks-content'">
    <div
      class="thin-echo-scrollbar"
      #pinnedTasksContent
      [ngClass]="showPinnedTasksFlag ? 'pinned-tasks-content-container visible' : 'pinned-tasks-content-container'"
    >
    <div class="search-input">
      <input
          placeholder="Filter..."
          nbInput
          [(ngModel)]="inputValue"
          class="input-full-width"
          #input
          (input)="onInput($event.target.value)"
        />
    </div>
      <table *ngIf="dataLoaded"
        #table
        mat-table
        cdkDropList
        [dataSource]="data"
        [cdkDropListData]="data"
        (cdkDropListDropped)="dropTable($event)"
      >
        <ng-container
          *ngFor="let column of columns; trackBy: trackByFn"
          [matColumnDef]="column.fieldName"
        >
          <ng-container>
            <th
              mat-header-cell
              *matHeaderCellDef
              class="header"
              [ngClass]="{
                  title: column.fieldName === 'title',
                  status: column.fieldName === 'status',
                  hide: column.fieldName === 'isChecked' && !isCopyVisible
                }"
            >
              <span class="bordered">{{ column.title }}</span>
            </th>
          </ng-container>
          <ng-container *ngIf="column.fieldName === 'isChecked'">
            <td
              mat-cell
              *matCellDef="let element"
              class="checkbox-width"
              [ngStyle]="{
                  'background-color': element.isEmptySpace
                    ? element['groupingColor']
                    : 'transparent'
                }"
              [ngClass]="{
                  hide: column.fieldName === 'isChecked' && !isCopyVisible
                }"
            >
              <nb-checkbox
                *ngIf="!(element.isEmptySpace || element.isSubTask)"
                (change)="onTaskSelectionChange($event, element)"
              >
              </nb-checkbox>
            </td>
          </ng-container>
          <ng-container *ngIf="column.fieldName === 'index'">
            <td
              mat-cell
              *matCellDef="let element"
              [ngStyle]="{
                  'background-color':
                    element.isEmptySpace && element['groupingColor']
                }"
              [attr.colspan]="element.isEmptySpace ? columns.length : 0"
              class="small-no-column"
            >
              <div class="flex">
                <div
                  class="grouping-bar"
                  style="width: 5px"
                  [ngStyle]="{ 'background-color': element['groupingColor'] }"
                >
                  &nbsp;
                </div>
                <div>
                    <span *ngIf="!element.isEmptySpace">
                      <span *ngIf="!element.isEmptySpace" class="text-index">{{
                        element[column.fieldName]
                        }}</span>
                      <span
                        *ngIf="element.totalSubtasks > 0"
                        (click)="toogleSubtaskList(element.taskId)"
                        style="padding-left: 0"
                      >
                        <nb-icon
                          *ngIf="showSubtasksForTaskId != element.taskId"
                          icon="plus-square-outline"
                          class="subtask-expandable-icon"
                          [ngClass]="{
                            'move-left': element[column.fieldName] > 9
                          }"
                        ></nb-icon>
                        <nb-icon
                          *ngIf="showSubtasksForTaskId == element.taskId"
                          icon="minus-square-outline"
                          class="subtask-expandable-icon"
                          [ngClass]="{
                            'move-left': element[column.fieldName] > 9
                          }"
                        ></nb-icon>
                      </span>
                    </span>
                  <div *ngIf="element.isEmptySpace" class="display-flex">
                    <span>{{ element.taskGoupingName }}</span>
                    <div
                      class="display-flex display-flex--evenly"
                      style="flex: 1"
                    >
                        <span
                          [nbPopover]="ragg1GroupAvgHtml"
                          nbPopoverTrigger="hover"
                          [ngStyle]="{
                            'background-color': element['ragg1GroupScoreColor'],
                            visibility: !taskBoardDetails.ragg1DictionaryName
                              ? 'hidden'
                              : 'visible'
                          }"
                        >{{ taskBoardDetails.ragg1DictionaryName }}:
                          {{ element.ragg1GroupScore }}</span
                        >
                      <span
                        [nbPopover]="ragg2GroupAvgHtml"
                        nbPopoverTrigger="hover"
                        [ngStyle]="{
                            'background-color': element['ragg2GroupScoreColor'],
                            visibility: !taskBoardDetails.ragg2DictionaryName
                              ? 'hidden'
                              : 'visible'
                          }"
                      >{{ taskBoardDetails.ragg2DictionaryName }}:
                        {{ element.ragg2GroupScore }}</span
                      >
                      <ng-template #ragg1GroupAvgHtml>
                        <div
                          class="tool-tip"
                          [innerHTML]="element.ragg1GroupAvg"
                        ></div>
                      </ng-template>
                      <ng-template #ragg2GroupAvgHtml>
                        <div
                          class="tool-tip"
                          [innerHTML]="element.ragg2GroupAvg"
                        ></div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container *ngIf="column.fieldName === 'info'">
            <td
              mat-cell
              *matCellDef="let element"
              [ngStyle]="{
                  display: element.isEmptySpace ? 'none' : 'table-cell'
                }"
              class="info-column"
            >
                <span>
                  <ng-container *ngIf="element['isSubTask']">
                    <nb-icon
                      *ngIf="
                        element.isTaskCompleted && !element.isTaskNotCompleted
                      "
                      icon="checkmark-square-2-outline"
                      class="taskboard-icon green"
                    ></nb-icon>
                    <nb-icon
                      *ngIf="
                        !element.isTaskCompleted && element.isTaskNotCompleted
                      "
                      icon="close-square-outline"
                      class="taskboard-icon red"
                    ></nb-icon>
                    <nb-icon
                      *ngIf="
                        !element.isTaskCompleted && !element.isTaskNotCompleted
                      "
                      icon="square-outline"
                      class="taskboard-icon red"
                    ></nb-icon>
                  </ng-container>
                  <img
                    *ngIf="element['isAfgBell']"
                    src="./assets/images/danger.png"
                    alt=""
                  />
                  <nb-icon
                    *ngIf="!element['isSubTask'] && element['commented']"
                    icon="checkmark-circle-outline"
                    class="taskboard-icon green"
                  ></nb-icon>
                  <nb-icon
                    *ngIf="element['isFavorite']"
                    icon="star-outline"
                    class="taskboard-icon"
                  ></nb-icon>
                  <nb-icon
                    *ngIf="element['isEmailList']"
                    icon="email-outline"
                    class="taskboard-icon"
                  ></nb-icon>
                  <nb-icon
                    *ngIf="element['status'] == 'Paused'"
                    icon="pause-circle-outline"
                    class="taskboard-icon red"
                  ></nb-icon>
                  <nb-icon
                    *ngIf="element['numberOfAttachments']"
                    icon="attach-2-outline"
                    class="taskboard-icon"
                  ></nb-icon>
                  <span
                    *ngIf="
                      element.subTaskColaboration &&
                      element.subTaskColaborationTaskForMe
                    "
                  ><img
                    src="assets/images/group_my.png"
                    style="width: 20px"
                    nbPopoverTrigger="hover"
                    [nbPopover]="colaborationInfoPopover"
                  /></span>
                  <span
                    *ngIf="
                      element.subTaskColaboration &&
                      !element.subTaskColaborationTaskForMe
                    "
                  ><img
                    src="assets/images/group.png"
                    style="width: 20px"
                    nbPopoverTrigger="hover"
                    [nbPopover]="colaborationInfoPopover"
                  /></span>

                  <ng-template #colaborationInfoPopover>
                    <div class="task-tooltip" style="padding: 5px">
                      <p>Assigned to:</p>
                      <div [innerHTML]="element.assignedTo"></div>
                    </div>
                  </ng-template>
                </span>
            </td>
          </ng-container>
          <ng-container *ngIf="column.fieldName === 'priority'">
            <td
              mat-cell
              [ngStyle]="{
                  display: element.isEmptySpace ? 'none' : 'table-cell',
                  width: '50px',
                  textAlign: 'center'
                }"
              *matCellDef="let element"
              [ngClass]="{
                  high: element['priority'] == 'high',
                  low: element['priority'] == 'low'
                }"
            >
              <img
                *ngIf="element['priority'] == 'high'"
                src="assets/images/high.svg"
                style="width: 45px; height: 30px"
              >
              <img
                *ngIf="element['priority'] == 'low'"
                src="assets/images/low.svg"
                style="width: 45px; height: 30px"
              >
            </td>
          </ng-container>
          <ng-container *ngIf="column.fieldName === 'title'">
            <td
              mat-cell
              [ngStyle]="{
                  display: element.isEmptySpace ? 'none' : 'table-cell'
                }"
              *matCellDef="let element"
              (click)="edit(element)"
            >
                <span
                  [innerHTML]="element[column.fieldName]"
                  class="title"
                  [ngClass]="{
                    completed:
                      element.status === 'updated' && element.isTaskCompleted,
                    notCompleted:
                      element.status === 'updated' &&
                      (element.isTaskNotCompleted ||
                        (element.isSubTask && !element.isTaskCompleted))
                  }"
                >
                </span>
            </td>
          </ng-container>
          <ng-container *ngIf="column.fieldName === 'status'">
            <ng-container
              *ngIf="
                  !taskboardPermissions.canIChange &&
                  !taskboardPermissions.canIClose
                "
            >
              <td
                mat-cell
                [ngStyle]="{
                    display: element.isEmptySpace ? 'none' : 'table-cell'
                  }"
                *matCellDef="let element"
                (click)="edit(element)"
                [ngClass]="{
                    grey: element['status'] == 'Paused',
                    green:
                      element['status'] == 'Done' || element['status'] == 'D&V',
                    'hard-grey':
                      element['status'] == 'D&R' ||
                      element['status'] == 'Closed'
                  }"
              >
                <span class="status">{{ element[column.fieldName] }}</span>
                <span
                  class="recommended-status"
                  [innerHTML]="element['recommendedStatus']"
                ></span>
              </td>
            </ng-container>
            <ng-container
              *ngIf="
                  taskboardPermissions.canIChange ||
                  taskboardPermissions.canIClose
                "
            >
              <td
                mat-cell
                [ngStyle]="{
                    display: element.isEmptySpace ? 'none' : 'table-cell'
                  }"
                *matCellDef="let element"
                [ngClass]="{
                    grey: element['status'] == 'Paused',
                    green:
                      element['status'] == 'Done' || element['status'] == 'D&V',
                    'hard-grey':
                      element['status'] == 'D&R' ||
                      element['status'] == 'Closed'
                  }"
              >
                  <span *ngIf="!element['isSubTask']" class="status-changer">
                    <div *ngIf="showChanger" class="form-control-group">
                      <select
                        [ngModel]="element[column.fieldName]"
                        (ngModelChange)="onChangeStatus($event, element)"
                      >
                        <option
                          *ngFor="let status of statuses"
                          [ngValue]="status.wordShortName"
                        >
                          {{ status.wordShortName }}
                        </option>
                      </select>
                    </div>
                    <span
                      class="recommended-status"
                      [innerHTML]="element['recommendedStatus']"
                    ></span>
                  </span>
                <ng-container *ngIf="element['isSubTask']">
                  <span class="status-time">{{ element["updateTime"] }}</span>
                  <span class="status">{{ element[column.fieldName] }}</span>
                </ng-container>
              </td>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="column.fieldName === 'avatar'">
            <td
              mat-cell
              *matCellDef="let element"
              (click)="
                  showEmployee360(
                    $event,
                    element.employeeId,
                    element.employeeFullName
                  )
                "
              [ngStyle]="{
                  display: element.isEmptySpace ? 'none' : 'table-cell'
                }"
            >
                <span class="center-avatar">
                  <img
                    [src]="element[column.avatarId] | avatar | async"
                    class="avatar"
                    alt=""
                  />
                </span>
            </td>
          </ng-container>
          <ng-container *ngIf="column.fieldType === 'dictionary-ragg'">
            <ng-container *ngIf="!column.canChange">
              <td
                mat-cell
                *matCellDef="let element"
                (click)="edit(element)"
                class="short-column-width"
                [ngStyle]="{
                    'background-color': getRaggColorFromId(
                      element[column.fieldName],
                      column['dictionary']
                    ),
                    display: element.isEmptySpace ? 'none' : 'table-cell'
                  }"
              >
                  <span *ngIf="!element['isSubTask']">{{
                    getRaggNameFromId(
                      element[column.fieldName],
                      column["dictionary"]
                    )
                    }}</span>
              </td>
            </ng-container>
            <ng-container *ngIf="column.canChange">
              <td
                mat-cell
                *matCellDef="let element"
                class="short-column-width"
                [ngStyle]="{
                    'background-color':
                      element[column.fieldName]
                      | raggColumnBackground: column['dictionary'],
                    display: element.isEmptySpace ? 'none' : 'table-cell'
                  }"
              >
                  <span
                    *ngIf="!element['isSubTask']"
                    class="status-changer ragg"
                  >
                    <div class="form-control-group">
                      <select
                        [(ngModel)]="element[column.fieldName]"
                        (ngModelChange)="
                          onChangeRagg(
                            element[column.fieldName],
                            element['taskId'],
                            column.fieldName
                          )
                        "
                      >
                        <option
                          *ngFor="let rag of column['dictionary']"
                          [ngValue]="rag.id"
                          [ngStyle]="{ 'background-color': rag.colorHash }"
                        >
                          {{ rag.wordShortName }}
                        </option>
                      </select>
                    </div>
                  </span>
              </td>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="
                column.fieldName !== 'status' &&
                column.fieldName !== 'title' &&
                column.fieldType !== 'ragg'
              "
          >
            <ng-container *ngIf="column.fieldType === 'textarea-view'">
              <td
                class="large-column-width"
                mat-cell
                *matCellDef="let element"
                [ngClass]="{
                    'large-column-width': column.fieldName != 'description',
                    'description-column-width':
                      column.fieldName == 'description'
                  }"
                (click)="
                    column.click ? column.click($event, element) : edit(element)
                  "
                [ngStyle]="{
                    display: element.isEmptySpace ? 'none' : 'table-cell'
                  }"
              >
                <!-- <nb-icon icon="clipboard-outline" (click)="copyText($event, 'textarea-view'+column.fieldName+element.taskId+element.index)"></nb-icon> -->
                <div *ngIf="column.addSubtasksProgress" class="task-age">
                  {{ element.taskAge }}
                </div>
                <ngx-progress-bar
                  *ngIf="
                      element.totalSubtasks > 0 && column.addSubtasksProgress
                    "
                  [progressPercent]="element.subTasksFinishedString"
                ></ngx-progress-bar>
                <span
                  *ngIf="
                      element.totalSubtasks > 0 &&
                      column.addSubtasksChangeProgress
                    "
                  class="right"
                >{{ element.subTasksFinishedChangePercent }}</span
                >
                <br
                  *ngIf="
                      !column.addSubtasksProgress &&
                      !column.addSubtasksChangeProgress
                    "
                />
                <div
                  [id]="
                      'textarea-view' +
                      column.fieldName +
                      element.taskId +
                      element.index
                    "
                  *ngIf="
                      (!column.formatHtml && element.isSubTask) ||
                      (!column.formatHtml &&
                        !element.isSubTask &&
                        showSubtasksForTaskId == element.taskId)
                    "
                  class="textarea-view"
                  #content
                  [ngClass]="{
                      completed:
                        element.status === 'updated' &&
                        element.isTaskCompleted &&
                        column.fieldName == 'description',
                      notCompleted:
                        element.status === 'updated' &&
                        (element.isTaskNotCompleted ||
                          (element.isSubTask && !element.isTaskCompleted)) &&
                        element.title &&
                        column.fieldName == 'description'
                    }"
                >
                  <pre [innerHTML]="element[column.fieldName]"></pre>
                </div>
                <div
                  [id]="
                      'textarea-view' +
                      column.fieldName +
                      element.taskId +
                      element.index
                    "
                  *ngIf="
                      (column.formatHtml && element.isSubTask) ||
                      (column.formatHtml &&
                        !element.isSubTask &&
                        showSubtasksForTaskId == element.taskId)
                    "
                  class="textarea-view format-html"
                  #content
                  [ngClass]="{
                      completed:
                        element.status === 'updated' &&
                        element.isTaskCompleted &&
                        column.fieldName == 'description',
                      notCompleted:
                        element.status === 'updated' &&
                        (element.isTaskNotCompleted ||
                          (element.isSubTask && !element.isTaskCompleted)) &&
                        element.title &&
                        column.fieldName == 'description'
                    }"
                  [innerHTML]="column.fieldName === 'latestUpdate' ? formatLastUpdateField(element[column.fieldName]) : element[column.fieldName]"
                  readonly
                ></div>
              </td>
            </ng-container>
            <ng-container *ngIf="column.fieldType !== 'textarea-view'">
              <td
                mat-cell
                *matCellDef="let element"
                (click)="
                    column.click ? column.click($event, element) : edit(element)
                  "
                [ngStyle]="{
                    display: element.isEmptySpace ? 'none' : 'table-cell'
                  }"
              >
                  <span
                    #content
                    [class]="column.class"
                    [ngClass]="{
                      completed:
                        element.status === 'updated' &&
                        element.isTaskCompleted &&
                        column.fieldName == 'description',
                      notCompleted:
                        element.status === 'updated' &&
                        (element.isTaskNotCompleted ||
                          (element.isSubTask && !element.isTaskCompleted)) &&
                        element.title &&
                        column.fieldName == 'description'
                    }"
                  >
                    {{ element[column.fieldName] }}
                  </span>
                <p
                  *ngIf="isOverflow(content)"
                  class="blue"
                  (click)="column.click ? column.click($event, element) : ''"
                >
                  ... see more {{ countLines(content) }}
                </p>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [cdkDragDisabled]="row.isSubTask"
          cdkDrag
          [cdkDragData]="row"
          class="thinner"
          (cdkDragStarted)="dragStarted($event)"
          [ngClass]="{
              green: !row.isSubTask && row.commented,
              'green-bg':
                !isGroupManager &&
                (row.status == 'Done' ||
                  (row.isSubTask &&
                    row.status != 'update needed' &&
                    row.status != 'Paused')),
              grey: row.status == 'Paused' || row.status == 'D&V',
              'hard-grey': row.status == 'D&R' || row.status == 'Closed',
              'todo-green':
                !isGroupManager && row.isSubTask && row.status == 'Closed',
              'p-green':
                isGroupManager &&
                row.isSubTask &&
                row.managerModeColor == 'green',
              'p-white':
                isGroupManager &&
                row.isSubTask &&
                row.managerModeColor == 'white',
              'p-grey':
                isGroupManager &&
                row.isSubTask &&
                row.managerModeColor == 'grey',
              'p-yellow':
                isGroupManager &&
                row.isSubTask &&
                row.managerModeColor == 'yelow',
              'thicker-line': row.isSubTask && row.isDrawLineAfter,
              'hidden': row.isSubTask,
              'visible': showSubtasksForTaskId == row.subTaskForTaskId
            }"
        ></tr>
      </table>
    </div>
  </div>
</div>
