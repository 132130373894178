import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { AuthRoutingModule } from "./auth-routing.module";
import { NbAuthModule } from "@nebular/auth";
import {
  NbAlertModule,
  NbButtonModule,
  NbCheckboxModule,
  NbInputModule,
} from "@nebular/theme";
import { LoginComponent } from "./login/login.component";
import { ThemeModule } from "../../@theme/theme.module";
import { AuthComponent } from "./auth.component";
import { RequestPassComponent } from "./request-pass/request-pass.component";
import { NewPassComponent } from "./new-pass/new-pass.component";
import { RegisterComponent } from "./register/register.component";
import { WizardComponent } from './wizard/wizard.component';

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    RequestPassComponent,
    NewPassComponent,
    WizardComponent,
  ],
  imports: [
    ThemeModule,
    CommonModule,
    FormsModule,
    RouterModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    AuthRoutingModule,
    NbAuthModule,
  ],
})
export class AuthModule {}
