import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthUserService } from "../@core/data/auth-user.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private authUserService: AuthUserService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authUserService.isAuthenticated()) {
      return true;
    } else {
      if (state.url && state.url.length > 0 && state.url.includes("taskId")) {
        this.authUserService.setRedirectUrl(state.url);
      }



      if (state.url && state.url.includes("register")) {
        this.router.navigateByUrl("auth/register");
        return;
      }

      this.router.navigate(["login"]);
    }
  }
}
