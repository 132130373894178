import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TableService } from '../../../shared/table/table.service';
import { EmployessService } from '../../../../shared/employess/employess.service';
import { AnnualLeavingService } from '../../../../shared/employess/annual-leaving/annual-leaving.service';
import { ContractsService } from '../../../../shared/employess/contracts/contracts.service';
import { SicknessService } from '../../../../shared/employess/sickness/sickness.service';
import { AuthUserService } from '../../../../@core/data/auth-user.service';
import { getFullName, getErrorMessage, isFormValid, handleValidationErrorMessage, validateFormControlsByName, onHoursInput } from '../../../../utilities/utils';
import { invalidDate, hours } from '../../../../utilities/validators';
import { NbToastrService } from '@nebular/theme';
import { errorMessages } from '../replacement-errors';
import { DictionariesService } from '../../../../shared/dictionaries/dictionaries.service';
import { RotaTableService } from '../../rota/rota-table.service';
import * as moment from 'moment';

@Component({
  selector: 'ngx-new-replacement',
  templateUrl: './new-replacement.component.html',
  styleUrls: ['./new-replacement.component.scss']
})
export class NewReplacementComponent implements OnInit, OnDestroy {
  @Input() initDateFrom: any = null;
  @Input() initDateTo: any = null;
  @Input() freezeDate: boolean = false;
  @Input() roleId: any;
  private subscription: Subscription = new Subscription();
  public employees: any = [];
  public roles: any = [];
  public employeeData: any;
  public eventData: any;
  public form: UntypedFormGroup;
  public utils = { isFormValid, handleValidationErrorMessage, validateFormControlsByName, onHoursInput };
  public errorMessages = errorMessages;
  public controlNames = ['replacementFrom', 'replacementTo'];
  public source: string = '';
  public canSave: boolean = true;
  public isCorrectDate: boolean = true;
  public toBeTakenByEmployee = false;
  private statCellData: {careHomeId: number, roleId: any, dayDate: any, open: boolean};
  private staffTraining = false;

  constructor(private tableService: TableService,
              private authUserService: AuthUserService,
              private dictionariesService: DictionariesService,
              private employeeService: EmployessService,
              private annualLeavingService: AnnualLeavingService,
              private contractsService: ContractsService,
              private sicknessService: SicknessService,
              private rotaTableService: RotaTableService,
              private toastrService: NbToastrService) {}

  ngOnInit() {
    this.source = this.tableService.getValue().source;
    this.fetchEvent(this.tableService.getValue().source);

    if (this.tableService.getValue()?.staffTraining) {
      this.staffTraining = this.tableService.getValue().staffTraining;
    }

    this.statCellData = Object.assign({}, this.rotaTableService.rotaStatOpened$.value);

    this.subscription.add(
      this.dictionariesService.getProfessionalRoles().subscribe((response: any) => {
        this.roles = response.result;
        if (this.staffTraining) {
          const index = this.roles.findIndex((e) => e.roleName === 'Staff Training');
          if (index > -1) {
            this.form.patchValue({
              replacementRole: this.roles[index].id
            });
          }
        }
      })
    );
    this.subscription.add(
      this.employeeService.getActiveEmployess().subscribe((response: any) => {
        this.employees = response.result.employeesLines
          .filter(employee => employee.employeeId !== this.tableService.getValue().employeeId)
          .map(i => {
            return { ...i, fullName: getFullName(i.employeeFirstName, i.employeeSurname) };
          });
      })
    );
    if (this.tableService.getValue().employeeId) {
      this.subscription.add(
        this.employeeService.getEmployee(this.tableService.getValue().employeeId)
          .subscribe((response: any) => {
            this.employeeData = response.result;
            this.patchValues(this.employeeData);
          })
      );
    }

    this.createForm();
  }

  createForm() {
    this.form = new UntypedFormGroup({
      employeeName: new UntypedFormControl({ value: '', disabled: true }),
      replacementId: new UntypedFormControl(null, [Validators.required]),
      replacementFrom: new UntypedFormControl(this.initDateFrom, [Validators.required, invalidDate]),
      replacementTo: new UntypedFormControl(this.initDateTo, [Validators.required, invalidDate]),
      replacementRole: new UntypedFormControl({value: null, disabled: this.tableService.getValue().source !== 'Extras'}, [Validators.required]),
      comments: new UntypedFormControl(null, [Validators.maxLength(5000)]),
      toBeTakenByEmployee: new UntypedFormControl(false),
      scheduleDate: new UntypedFormControl(null),
      scheduleFrom: new UntypedFormControl(null),
      scheduleTo: new UntypedFormControl(null),
    });

    this.form.get('replacementFrom').valueChanges.subscribe((value: any) => {
      this.checkDates();
    });

    this.form.get('replacementTo').valueChanges.subscribe((value: any) => {
      this.checkDates();
    });

    this.form.get('toBeTakenByEmployee').valueChanges.subscribe((value: any) => {
      this.toggleToBeTaken(value);
    });

    if (this.freezeDate) {
      this.form.get('replacementFrom').disable();
      this.form.get('replacementTo').disable();
    }
  }

  toggleToBeTaken(toBeTakenValue: boolean) {
    this.toBeTakenByEmployee = toBeTakenValue;
    const replacementId = this.form.get('replacementId');

    if (toBeTakenValue) {
      replacementId.setValidators(null);
      this.form.get('scheduleDate').setValidators([Validators.required, invalidDate]);
      this.form.get('scheduleFrom').setValidators([Validators.required, hours]);
      this.form.get('scheduleTo').setValidators([Validators.required, hours]);
      this.isCorrectDate = true;
    } else {
      replacementId.setValidators([Validators.required]);
      this.form.get('scheduleDate').setValidators(null);
      this.form.get('scheduleFrom').setValidators(null);
      this.form.get('scheduleTo').setValidators(null);
      this.checkDates();
    }
    this.form.get('scheduleDate').updateValueAndValidity();
    this.form.get('scheduleFrom').updateValueAndValidity();
    this.form.get('scheduleTo').updateValueAndValidity();
    replacementId.updateValueAndValidity();
  }

  fetchEvent(source: string) {
    switch (source) {
      case 'AL':
        return this.subscription.add(
          this.annualLeavingService.getAnnualLeavingById(this.tableService.getValue().eventId)
            .subscribe((response: any) => {
              this.eventData = response.result;
            })
        );
      case 'Sickness':
        return this.subscription.add(
          this.sicknessService.getSicknessById(this.tableService.getValue().eventId)
            .subscribe((response: any) => {
              this.eventData = response.result;
            })
        );
      case 'Contract':
        return this.subscription.add(
          this.contractsService.getContract(this.tableService.getValue().contractId)
            .subscribe((response: any) => {
              this.eventData = response.result;
            })
        );
    }
  }

  patchValues(data: any) {
    const roleSource = this.tableService.getValue().contractRoleId ? this.tableService.getValue().contractRoleId : this.eventData.contractRoleId;
    this.form.patchValue({
      employeeName: `${data.firstName} ${data.surname}`,
      replacementRole: this.roles.find(role => role.id === roleSource).id
    });
  }

  saveData() {
    this.canSave = false;
    const data = {
      addedBy: this.authUserService.getOriginalUser().id,
      employeeId: this.form.get('replacementId').value,
      contractId: this.tableService.getValue().contractId,
      eventId: this.tableService.getValue().eventId,
      replacementsFrom: this.form.get('replacementFrom').value,
      replacementsTo: this.form.get('replacementTo').value,
      comments: this.form.get('comments').value,
      replacementRoleId: this.form.get('replacementRole').value,
      replacementCareHomeId: this.authUserService.getCareHomeId(),
      toBeTakenByEmployee: this.form.get('toBeTakenByEmployee').value,
      scheduleFrom: this.form.get('scheduleFrom').value,
      scheduleTo: this.form.get('scheduleTo').value,
      scheduleDate: this.form.get('scheduleDate').value
    };
    if (this.toBeTakenByEmployee) {
      data.employeeId = null;
    }
    switch (this.tableService.getValue().source) {
      case 'AL':
        return this.subscription.add(
          this.annualLeavingService.createReplacement(data).subscribe((response: any) => {
            this.toastrService.success(response.message, 'Success');
            this.closeWindow(true);
          },
          (err) => {
            this.canSave = true;
            this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
          })
        );
      case 'Sickness':
        return this.subscription.add(
          this.sicknessService.createReplacement(data).subscribe((response: any) => {
            this.toastrService.success(response.message, 'Success');
            this.closeWindow(true);
          },
          (err) => {
            this.canSave = true;
            this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
          })
        );
      case 'Contract':
        return this.subscription.add(
          this.contractsService.createReplacement(data).subscribe((response: any) => {
            this.toastrService.success(response.message, 'Success');
            this.closeWindow(true);
          },
          (err) => {
            this.canSave = true;
            this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
          })
        );
      case 'Extras':
        return this.subscription.add(
          this.employeeService.createReplacement(data).subscribe((response: any) => {
            this.toastrService.success(response.message, 'Success');
            this.rotaTableService.reload$.next(this.tableService.getValue().tableIdentifier);
            this.closeWindow(false);
          },
          (err) => {
            this.canSave = true;
            this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
          })
        );
    }
  }

  toogleSwitcher(event: any) {
    this.form.get('toBeTakenByEmployee').setValue(event);
  }

  closeWindow(refresh: boolean) {
    this.tableService.closeWindow(refresh);
    this.rotaTableService.setStatCellOpened(
      this.statCellData.careHomeId,
      this.statCellData.roleId,
      this.statCellData.dayDate,
      true
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private checkDates() {
    const pickedFrom = moment(this.form.get('replacementFrom').value, 'DD/MM/YYYY');
    const initFromDate = moment(this.initDateFrom);
    const pickedTo = moment(this.form.get('replacementTo').value);
    const initDateTo = moment(this.initDateTo);

    if (pickedFrom.isBefore(initFromDate) || pickedTo.isAfter(initDateTo) || pickedFrom.isAfter(pickedTo)) {
      this.isCorrectDate = false;
    } else {
      this.isCorrectDate = true;
    }
  }

}
