<div class="form-view">
  <nb-tabset [nbSpinner]="dataLoaded !== LoadingStatus.LOADED">
    <nb-tab tabTitle="Leave Overview">
        <main>
            <form [formGroup]="form" autocomplete="off">
              <div class="flex-container-horizontal middle">
                <div class="form-control-group">
                  <label class="label" for="input-employeeName">Employee:</label>
                  <input nbInput
                          fullWidth
                          formControlName="employeeName"
                          id="input-employeeName"
                          placeholder="Employee">
                </div>
                <div id="acceptance" class="form-control-group">
                  <nb-checkbox formControlName="requiresAcceptance">Requires acceptance</nb-checkbox>
                </div>
                <div class="event-status">
                  <span><strong>Event status:</strong> {{ leavingData?.statusName }}</span>
                </div>
              </div>
              <div class="flex-container-horizontal middle">
                <div class="form-control-group">
                  <label class="label" for="input-event-type">Type of Event:</label>
                  <input nbInput
                          fullWidth
                          formControlName="eventType"
                          name="input-event-type"
                          id="input-event-type">
                </div>
                <div class="form-control-group form-control-group--small">
                  <label class="label" for="input-event-type">Event hrs:</label>
                  <input nbInput
                          fullWidth
                          disabled
                          [value]="leavingData?.givenHrs"
                          name="input-event-type"
                          id="input-event-type">
                </div>
                <div class="form-control-group">
                  <label class="label" for="input-leaving-start">Start:</label>
                  <input nbInput
                          fullWidth
                          formControlName="leavingStart"
                          placeholder="DD/MM/YYYY"
                          [matDatepicker]="leavingStartDatepicker"
                          autofocus>
                        <mat-datepicker-toggle matSuffix [for]="leavingStartDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #leavingStartDatepicker startView="month"></mat-datepicker>
                  <p class="error-message">
                    {{ utils.handleValidationErrorMessage(form, 'leavingStart', errorMessages) }}
                  </p>
                </div>
                <div class="form-control-group">
                  <label class="label" for="input-leaving-end">End:</label>
                  <input nbInput
                          fullWidth
                          formControlName="leavingEnd"
                          id="input-leaving-end"
                          placeholder="DD/MM/YYYY"
                          [matDatepicker]="leavingEndDatepicker"
                          autofocus>
                        <mat-datepicker-toggle matSuffix [for]="leavingEndDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #leavingEndDatepicker startView="month"></mat-datepicker>
                  <p class="error-message">
                    {{ utils.handleValidationErrorMessage(form, 'leavingEnd', errorMessages) }}
                  </p>
                </div>
                <div class="form-control-group">
                  <label class="label" for="input-first-day-available">First Day Available (leave blank if unknown):</label>
                  <input nbInput
                          fullWidth
                          formControlName="firstDayAvailable"
                          id="input-first-day-available"
                          placeholder="DD/MM/YYYY"
                          [matDatepicker]="firstDayAvailableDatepicker"
                          autofocus>
                        <mat-datepicker-toggle matSuffix [for]="firstDayAvailableDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #firstDayAvailableDatepicker startView="month"></mat-datepicker>
                        <div *ngIf="form.get('firstDayAvailable').value" id="clear-date" (click)="clearFirstDayAvailable()">x</div>
                  <p class="error-message">
                    {{ utils.handleValidationErrorMessage(form, 'firstDayAvailable', errorMessages) }}
                  </p>
                </div>
              </div>
              <p *ngIf="additionalInformation" [innerHTML]="additionalInformation" class="ml-4"></p>
              <div class="form-control-group table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Role</th>
                      <th>Contract</th>
                      <th>Contract hours</th>
                      <th>Week AL Base</th>
                      <th>Accrued</th>
                      <th>Remaining</th>
                      <th>Used</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="leavingData">
                      <td>{{ leavingData.contractRole }}</td>
                      <td>{{ leavingData.contractType }}</td>
                      <td>{{ leavingData.contractHrs }}</td>
                      <td>{{ leavingData?.leaveSettlements?.weekAlBase }}</td>
                      <td>{{ leavingData.accrued }}</td>
                      <td>{{ leavingData.remaining }}</td>
                      <td>{{ leavingData?.leaveSettlements?.usedHrs }}</td>
                      <td class="flex-end">
                        <button [nbPopover]="templateRef" class="btn btn-info">
                          <i class="nb-help" aria-hidden="true"></i>
                        </button>
                        <ng-template #templateRef>
                          <div class="info-box">
                            <div>{{ leavingData.leaveSettlements && leavingData.leaveSettlements.comment1 }}</div>
                            <div>{{ leavingData.leaveSettlements && leavingData.leaveSettlements.comment2 }}</div>
                            <div>{{ leavingData.leaveSettlements && leavingData.leaveSettlements.comment3 }}</div>
                            <div>{{ leavingData.leaveSettlements && leavingData.leaveSettlements.comment4 }}</div>
                            <div>{{ leavingData.leaveSettlements && leavingData.leaveSettlements.comment5 }}</div>
                          </div>
                        </ng-template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="form-control-group textarea-container note-height">
                <p *ngIf="isGivenMoreThanRecommended" id="note">This person will not have accrued more
                  than {{recommendedHrsSum}} hrs up to this point.
                  You are giving more hours than the person will have accrued. If the person leaves after this AL,
                  the company may incur losses. Only give this AL if you are certain they will work past this point.</p>
              </div>
              <div class="form-control-group textarea-container">
                <label class="label" for="input-description">Description (required):</label>
                <textarea nbInput
                          fullWidth
                          formControlName="description"
                          placeholder="Description"
                          minlength="3"
                          maxlength="5000"
                          autofocus>
                </textarea>
                <p class="error-message">
                  {{ utils.handleValidationErrorMessage(form, 'description', errorMessages) }}
                </p>
              </div>
              <ng-template [ngIf]="cancelled">
                <div class="form-control-group">
                  <label for="input-cancellation-date">Cancellation/rejection date:</label>
                  <input nbInput
                          fullWidth
                          formControlName="cancellationDate"
                          id="input-cancellation-date"
                          placeholder="DD/MM/YYYY"
                          [matDatepicker]="cancellationDatepicker"
                          autofocus>
                        <mat-datepicker-toggle matSuffix [for]="cancellationDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #cancellationDatepicker startView="month"></mat-datepicker>
                </div>
                <div class="form-control-group cancellation-container">
                  <label for="input-cancellation-reason">Rejection reason:</label>
                  <textarea nbInput
                            fullWidth
                            formControlName="cancellationDescription"
                            id="input-cancellation-reason"
                            placeholder="Rejection reason"
                            minlength="3"
                            maxlength="5000"
                            autofocus>
                  </textarea>
                  <p class="error-message">
                    {{ utils.handleValidationErrorMessage(form, 'cancellationDescription', errorMessages) }}
                  </p>
                </div>
              </ng-template>
            </form>
            <ngx-overview-calendar
              *ngIf="dataLoaded === LoadingStatus.LOADED"
              [months]="calendarData.months"
              [hideAlButton]="true"
              [dateRange]="dateRange">
            </ngx-overview-calendar>
            <p class="ml-3 mb-4">{{calendarMessage}}</p>
          </main>
    </nb-tab>
    <nb-tab tabTitle="Replacement" [disabled]="!permissions.NEW_CONTRACTS">
      <main>
        <angular2-smart-table
          [settings]="cancelled ? replacementSettingsCancelled : replacementSettingsNotCancelled"
          [source]="replacements"
          (deleteConfirm)="deleteReplacement($event)"
        ></angular2-smart-table>
        <div class="flex-end mt-4">
          <button class="btn btn-success"
                  (click)="addReplacement()"
                  [disabled]="!permissions.MENU_ANNUAL_LEAVES">
            <i class="nb-plus" aria-hidden="true"></i>
          </button>
        </div>
      </main>
    </nb-tab>
  </nb-tabset>
  <footer class="space-between">
    <ng-container *ngIf="mode !== FormMode.DECIDE">
      <div class="flex">
        <button class="btn btn-success"
                (click)="saveData()"
                [disabled]="!utils.isFormValid(form) || !permissions.MENU_ANNUAL_LEAVES">
          <i class="nb-checkmark" aria-hidden="true"></i> Save
        </button>
        <button class="btn btn-danger ml-4"
                (click)="closeWindow()">
          <i class="nb-close" aria-hidden="true"></i> Close
        </button>
      </div>
      <div class="flex">
        <button class="btn btn-danger button-wide ml-4 justify-center"
                (click)="deleteEvent()"
                [disabled]="!permissions.MENU_ANNUAL_LEAVES">
          <i class="nb-e-commerce mr-2" aria-hidden="true"></i> Delete whole event
        </button>
        <button *ngIf="!hideShortenLeave"
                class="btn btn-danger longer ml-4 justify-center"
                (click)="cancelEvent()"
                [disabled]="!permissions.MENU_ANNUAL_LEAVES">
          <i class="nb-trash" aria-hidden="true"></i> Shorten leave
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="mode === FormMode.DECIDE">
      <div class="flex">
        <button class="btn btn-success longer padding-btn"
                (click)="acceptEvent()"
                [disabled]="!permissions.MENU_ANNUAL_LEAVES">
          <i class="nb-checkmark" aria-hidden="true"></i> Accept leave
        </button>
        <button class="btn btn-danger ml-4 padding-btn"
                (click)="closeWindow()">
          <i class="nb-close" aria-hidden="true"></i> Close
        </button>
      </div>
      <div class="flex">
        <button class="btn btn-danger ml-4 padding-btn"
                (click)="rejectEvent()"
                [disabled]="!permissions.MENU_ANNUAL_LEAVES">
          <i class="nb-e-commerce mr-2" aria-hidden="true"></i> Reject
        </button>
      </div>
    </ng-container>
  </footer>
</div>
