import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { CareHomesService } from "../../../shared/care-homes/care-homes.service";
import { Subscription } from "rxjs";
import { isFormValid } from "../../../utilities/utils";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "ngx-tasks-email-dialog",
  templateUrl: "./tasks-email-dialog.component.html",
  styleUrls: ["./tasks-email-dialog.component.scss"],
})
export class TasksEmailDialogComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() reportType: string = "Home";
  @Input() needLoadTable: boolean = false;
  @Input() showClearEmails: boolean = true;
  @Input() careHomeId: number = 0;
  @Input() taskboards: any = [];
  @Input() emailsSentTo: string[] = [];
  @Input() filteredEmailsSentTo: {
    email: string;
    id: number;
    login: string;
    fullName: string;
  }[] = [];
  @Input() autoAddEmailRecipients: boolean = false;
  @ViewChild("dropdownUsers", { static: false }) dropdown: ElementRef;
  private subscription: Subscription = new Subscription();
  public utils = { isFormValid };
  public form: UntypedFormGroup;
  public userList: any = [];
  public mailingListText: string = "";
  public isSelectAll = false;
  public data: any;
  public displayedColumns: string[] = [];
  public isGroup = false;

  public columns = [
    {
      fieldName: "taskBoardName",
      title: "Name of Taskboard",
      class: "text-left",
    },
    {
      fieldName: "title",
      title: "Title",
    },
    {
      fieldName: "description",
      title: "Description",
    },
    {
      fieldName: "latestUpdate",
      title: "Latest update",
    },
    {
      fieldName: "submittedUpdate",
      title: "Submitted update",
    },
    {
      fieldName: "status",
      title: "Status",
    },
    {
      fieldName: "isSend",
      title: "Send",
    },
  ];
  isLoading: boolean = true;

  constructor(
    protected ref: NbDialogRef<TasksEmailDialogComponent>,
    protected careHomeService: CareHomesService
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      emailSubject: new UntypedFormControl(null, [Validators.required]),
      emailTo: new UntypedFormControl(null, [Validators.required]),
      emailList: new UntypedFormControl({ value: null, disabled: true }),
      emailBody: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(3),
      ]),
      clearEmailListAfterSend: new UntypedFormControl(false),
    });

    this.isGroup = this.reportType === "Group";
    let taskboardsList = [];
    if (this.taskboards) {
      taskboardsList = this.taskboards.map((item: any) => {
        return item.id;
      });
    }

    this.displayedColumns = this.columns.map((column: any) => column.fieldName);
    if (this.needLoadTable) {
      this.subscription.add(
        this.careHomeService
          .getTasksToEmail(this.careHomeId)
          .subscribe((result: any) => {
            const list = result.result.taskList;
            list.forEach((task: any) => {
              task.isSend = true;
            });
            this.data = new MatTableDataSource(list);
          })
      );
    }

    this.subscription.add(
      this.careHomeService
        .getGroupEmailRecipients(
          this.careHomeId,
          this.reportType.toLowerCase(),
          taskboardsList
        )
        .subscribe((response: any) => {
          this.userList = response.result.list;
          this.filteredEmailsSentTo = this.userList.filter((user) => {
            return this.emailsSentTo.includes(user.email);
          });
          this.isLoading = false
          if (this.isGroup) {
            this.isSelectAll = true;
          }
        })
    );

    this.form.get("emailTo").valueChanges.subscribe((value: any) => {
      this.form.get("emailList").setValue(this.buildMailingList(value));
    });
  }

  changeEmailClearState(event: any) {
    this.form.patchValue({
      clearEmailListAfterSend: event.target.checked ? true : false,
    });
  }

  cancel() {
    this.ref.close({
      isConfirm: false,
    });
  }

  ok() {
    this.ref.close({
      isConfirm: true,
      subject: this.form.get("emailSubject").value,
      emailList: this.form.get("emailList").value,
      emailBody: this.form.get("emailBody").value,
      emailTo: this.getIdList(this.form.get("emailTo").value),
      clearEmailListAfterSend: this.form.get("clearEmailListAfterSend").value,
      taskList: this.getTasksIdToSend(),
    });
  }

  changeSendState(task: any) {
    task.isSend = !task.isSend;
    this.getTasksIdToSend();
  }

  getTasksIdToSend() {
    const taskIdList = [];
    if (this.data && this.data.data.length > 0) {
      this.data.data.forEach((task: any) => {
        if (task.isSend) {
          taskIdList.push(task.taskId);
        }
      });
    }

    return taskIdList;
  }

  private buildMailingList(usersList: any[]) {
    let emailList = "";

    if (usersList) {
      for (const user of usersList) {
        emailList += `${user.email}; `;
      }
    }

    return emailList;
  }

  private getIdList(userList: any[]) {
    const list = [];
    for (const user of userList) {
      list.push(user.id);
    }

    return list;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
