<label class="switch-label">
  <span class="first" [class.active]="isFirstValue()">
    {{firstValueLabel}}
  </span>

  <div class="switch">
    <input type="checkbox" [checked]="isSecondValue()" (change)="changeValue()" [disabled]="isDisabled">
    <span class="slider" [ngClass]="{'disabled': isDisabled}"></span>
  </div>

  <span
    class="second"
    [class.active]="isSecondValue()">
      {{secondValueLabel}}
  </span>
</label>