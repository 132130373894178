import { Component, OnInit, Input, OnDestroy, OnChanges, ViewChild, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { TableService } from '../../table.service';
import { debounceTime } from 'rxjs/operators';

const DEBOUNCE_TIME = 500;
@Component({
  selector: 'ngx-simple-only-table',
  templateUrl: './simple-only-table.component.html',
  styleUrls: ['../only-table.component.scss']
})
export class SimpleOnlyTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('table', { static: false }) table: MatTable<any>;
  @Input() data: any = [];
  @Input() columns: any = {};
  @Input() onRowClick: (event: any) => any;
  @Input() filter: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Input() thinner: boolean = false;
  @Input() showIndexColumn: boolean = true;
  @Input() sortFromBackend: boolean = false;
  @Output() matSortChange = new EventEmitter();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public displayedColumns: string[] = [];
  public dataSource: any;

  constructor(protected tableService: TableService) {}

  ngOnInit() {
    this.filter
      // .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((value: string) => {
      this.dataSource.filter = (value.trim) ? value.trim().toLowerCase() : '';
    });
    this.dataSource.sort = this.sort;
    if (this.sortFromBackend) {
      this.dataSource.sortData = ((data, sort) => {});
    }
  }

  ngOnChanges() {
    const columns = [];
    if (this.showIndexColumn) {
      columns.push('index');
    }

    for (const column of this.columns) {
      columns.push(column.fieldName);
    }
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.filterPredicate = (order: any, filter: string) => {
      const transformedFilter = filter.trim().toLowerCase();
      const listAsFlatString = (obj): string => {
        let returnVal = '';

        Object.values(obj).forEach((val) => {
          if (typeof val !== 'object') {
            returnVal = returnVal + ' ' + val;
          } else if (val !== null) {
            returnVal = returnVal + ' ' + listAsFlatString(val);
          }
        });

        return returnVal.trim().toLowerCase().replace(/ +(?= )/g, '');
      };
      return listAsFlatString(order).includes(transformedFilter);
    };
    this.displayedColumns = columns;
    // this.dataSource.sort = this.sort;
    if (this.sortFromBackend) {
      this.dataSource.sortData = ((data, sort) => {});
    }
  }

  trackByFn(index, item) {
    return index;
  }

  ngOnDestroy(): void {
    if (this.filter) {
      this.filter.unsubscribe();
    }
  }
}
