import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CoursesOverviewService } from '../courses-overview.service';
import * as moment from 'moment';
import { CoursesService } from '../../../../shared/courses/courses.service';
import { NbToastrService } from '@nebular/theme';
import { getErrorMessage } from '../../../../utilities/utils';
import { Subscription } from 'rxjs';
import { TableService } from '../../../shared/table/table.service';

@Component({
  selector: 'ngx-courses-change-status',
  templateUrl: './courses-change-status.component.html',
  styleUrls: ['./courses-change-status.component.scss']
})
export class CoursesChangeStatusComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public fromDocumentUploadMode = false;
  public startDateChangesSubscribe: Subscription = new Subscription();
  public expiryDateChangesSubscribe: Subscription = new Subscription();
  public form: UntypedFormGroup;
  public data: any = [];
  public dateScheduled = false;
  public settings = {
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      employee: {
        title: 'Employee',
        filter: false,
        type: 'html',
        valuePrepareFunction: (cell: any, row: any) => {
          return `${row.employee.firstName} ${row.employee.surname}`;
        }
      },
      course: {
        title: 'Course',
        valuePrepareFunction: (cell: any, row: any) => {
          return row.course.name;
        }
      },
      from: {
        title: 'Start date',
        valuePrepareFunction: (cell: any, row: any) => {
          return row.course.from ? moment(row.course.from).format('DD/MM/YYYY') : 'N/A';
        }
      },
      to: {
        title: 'Expiry date',
        valuePrepareFunction: (cell: any, row: any) => {
          return row.course.to ? moment(row.course.to).format('DD/MM/YYYY') : 'N/A';
        }
      }
    }
  };

  constructor(private coursesOverviewService: CoursesOverviewService,
              private courseService: CoursesService,
              private toastrService: NbToastrService,
              private tableSevice: TableService) {
    this.createForm();
  }

  ngOnInit() {
    if (this.tableSevice.getValue() && this.tableSevice.getValue().fromDocumentUploadMode) {
      this.fromDocumentUploadMode = this.tableSevice.getValue().fromDocumentUploadMode;
      this.data = this.tableSevice.getValue().data;
    } else {
      this.coursesOverviewService.saveOffset();
      if (this.coursesOverviewService.choosenCourse) {
        const choosenCourse = this.coursesOverviewService.getChoosenCourseData();
        this.data = JSON.parse(JSON.stringify([choosenCourse]));
        this.form.patchValue({
          description: choosenCourse.course.description,
          scheduleDate: choosenCourse.course.scheduleDate
        });
      } else {
        this.data = JSON.parse(JSON.stringify(this.coursesOverviewService.getSelectedCourses()));
      }
    }
  }

  readonly isScheduleOrCourseDateEntered = () => {
    return this.form.get('scheduleDate').value || this.form.get('completedAt').value;
  }

  createForm() {
    this.form = new UntypedFormGroup({
      scheduleDate: new UntypedFormControl(null),
      completedAt: new UntypedFormControl(null),
      description: new UntypedFormControl(null),
      manualReview: new UntypedFormControl(null),
      expirationDate: new UntypedFormControl(moment().add(5, 'years')),
    });

    this.startDateChangesSubscribe = this.form.get('completedAt').valueChanges.subscribe(val => {
      this.updateDates(val);
    });

    this.form.get('manualReview').valueChanges.subscribe(val => {
      if (val) {
        this.form.get('description').setValidators([Validators.required]);
        this.form.get('description').updateValueAndValidity();
        this.form.get('expirationDate').setValidators([Validators.required]);
        this.form.get('expirationDate').updateValueAndValidity();
        this.updateExpiryDate(this.form.get('expirationDate').value);
        this.startDateChangesSubscribe = this.form.get('completedAt').valueChanges.subscribe(v => {
          this.updateStartDate(v);
        });
        this.expiryDateChangesSubscribe = this.form.get('expirationDate').valueChanges.subscribe(v => {
          this.updateExpiryDate(v);
        });
      } else {
        this.form.get('description').clearValidators();
        this.form.get('description').updateValueAndValidity();
        this.form.get('expirationDate').clearValidators();
        this.form.get('expirationDate').updateValueAndValidity();
        this.updateDates(this.form.get('completedAt').value);
        this.startDateChangesSubscribe = this.form.get('completedAt').valueChanges.subscribe(v => {
          this.updateDates(v);
        });
        this.expiryDateChangesSubscribe.unsubscribe();
      }
    });
  }

  saveData() {
    const toSend = this.prepareData();
    this.subscription.add(this.courseService.changeCoursesStatus(toSend)
      .subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        this.closeWindow(true);
      },
      (err: any) => {
        this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
      }
    ));
  }

  prepareData() {
    const list = [];
    const courseData = this.form.get('completedAt').value;
    this.data.forEach((row: any) => {
      list.push({
        completedAt: this.form.get('manualReview').value ? null : courseData,
        employeeId: row.employee.id,
        trainingId: row.course.id,
        scheduleDate: this.form.get('manualReview').value ? null : courseData ? null : this.form.get('scheduleDate').value,
        description: this.form.get('description').value,
        isManualReview: this.form.get('manualReview').value,
        expiryDate: this.form.get('manualReview').value ? this.form.get('expirationDate').value : null
      });
    });
    return {
      trainingsCompletedList: list
    };
  }

  updateExpiryDate(date: any) {
    if (moment(date).isValid()) {
      const expiryAt = moment(date);
      const _data = this.data;
      _data.forEach((row: any) => {
        row.course.to = expiryAt;
      });
      this.data = Object.assign([], _data);
    }
  }

  updateStartDate(date: any) {
    if (moment(date).isValid()) {
      const completedAt = moment(date);
      const _data = this.data;
      _data.forEach((row: any) => {
        row.course.from = completedAt;
      });
      this.data = Object.assign([], _data);
    }
  }

  updateDates(date: any) {
    if (moment(date).isValid()) {
      const completedAt = moment(date);
      const _data = this.data;
      _data.forEach((row: any) => {
        row.course.from = completedAt;
        row.course.to = completedAt.clone().add(row.course.durationTime, 'months');
      });
      this.data = Object.assign([], _data);
    }
  }

  clearSchedule() {
    this.form.patchValue({
      scheduleDate: null
    });
  }

  isFormValid(form: UntypedFormGroup) {
    return form.valid;
  }

  closeWindow(refresh: boolean) {
    if (this.fromDocumentUploadMode) {
      this.tableSevice.closeWindow(refresh);
    } else {
      this.coursesOverviewService.closeWindow(refresh);
    }
  }

  ngOnDestroy() {
    this.form = null;
    this.data = null;
    this.subscription.unsubscribe();
    this.expiryDateChangesSubscribe.unsubscribe();
    this.startDateChangesSubscribe.unsubscribe();
  }

  toggleScheduled($event) {
    this.dateScheduled = $event.checked;
  }
}
