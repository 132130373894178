import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  constructor(private httpClient: HttpClient) {}

  getProfiles() {
    return this.httpClient.get(`${environment.apiUrl}/general/profiles`);
  }

  addProfile(data: any) {
    return this.httpClient.post(`${environment.apiUrl}/dictionaries/0/words`, data);
  }

  updateProfile(id: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}/dictionaries/0/words/${id}`, data);
  }

  deleteProfile(id: number) {
    return this.httpClient.delete(`${environment.apiUrl}/dictionaries/0/words/${id}`);
  }

  getProfilesPermissions() {
    return this.httpClient.get(`${environment.apiUrl}/general/permission-roles`);
  }

  updateProfilesPermission(roleId: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}/general/permission-roles/${roleId}`, data);
  }

  updateUserProfiles(id: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/${id}/profiles`, { rolesList: data });
  }

  getUserProfiles(id: number) {
    return this.httpClient.get(`${environment.apiUrl}/users/${id}/profiles`);
  }

  updateProfilePermissions(id: number, data: any) {
    return this.httpClient.post(`${environment.apiUrl}/general/contact-book-permissions/${id}`, data );
  }

  getProfilePermissions(id: number) {
    return this.httpClient.get(`${environment.apiUrl}/general/contact-book-permissions/${id}`);
  }

  getEmailTest(option: string) {
    return this.httpClient.get(`${environment.apiUrl}/general/email-test/${option}`);
  }

  getEmailLog(startDate: any, endDate: any, filter = '', rowsFrom?: number, rowsTo?: number, orderBy?: string, orderDirection?: string) {
    return this.httpClient.get(`${environment.apiUrl}/general/logs/email-log?startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}&filter=${filter}&rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&orderBy=${orderBy}&orderDirection=${orderDirection}`);
  }

  getEmailLogForTask(taskId: number) {
    return this.httpClient.get(`${environment.apiUrl}/general/logs/email-log?taskId=${taskId}`);
  }

  getEmailMessage(emailId: string) {
    return this.httpClient.get(`${environment.apiUrl}/emails/${emailId}`);
  }
}
