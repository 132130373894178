<div class="row pages-main-row" [ngClass]="{ 'pages-main-row': !fullHeight, 'flex-column': isLeaversReport }">
  <nb-card [ngStyle]="{ border: noBorder ? 0 : '1px solid #e4e9f2;'}" [ngClass]="{ 'table__leavers-report': isLeaversReport }">
    <nb-card-header *ngIf="!clearTop">
      <div class="flex-help-tooltip">
        <span
          >{{ title }} <span class="green-header">{{ comments }}</span></span
        >
        <p
          *ngIf="helpTooltip"
          class="help"
          nbTooltip="Need help? Click here for instructions"
          (click)="helpTooltip()"
        >
          ?
        </p>
        <ng-container *ngIf="sendEmails">
          <button
            class="btn btn-success ml-3"
            style="min-height: 35px"
            (click)="sendEmails(selectedItems)"
            [disabled]="selectedItems.length === 0"
          >
            Send emails
          </button>
          <p class="ml-3">Multiple select</p>
          <div class="ml-2">
            <ngx-switcher
              [firstValue]="false"
              [secondValue]="true"
              [firstValueLabel]="'off'"
              [secondValueLabel]="'on'"
              [changeDefaultValue]="false"
              [value]="isSelectableVisible"
              (valueChange)="toggleSelectableVisibility($event)"
            ></ngx-switcher>
          </div>
        </ng-container>
      </div>
      <ngx-switcher
        *ngIf="showTableFilter"
        [firstValue]="tableFilter.ALL"
        [secondValue]="tableFilter.ACTIVE_ONLY"
        [firstValueLabel]="'All'"
        [secondValueLabel]="activeOnlyLabel"
        [value]="activeFilter"
        (valueChange)="toogleTableFilter($event)"
      ></ngx-switcher>
      <div *ngIf="changeView" id="change-view">
        <a (click)="changeView()" routerLinkActive="active"
          >{{ changeViewTitle }}<i class="nb-arrow-right" aria-hidden="true"></i
        ></a>
      </div>
    </nb-card-header>
    <nb-card-header *ngIf="descriptionTop && multiselectorVisible">
      <p>{{ descriptionText }}</p>
    </nb-card-header>
    <nb-card-body class="pages-main-content" [style.max-height]="cardBodyMaxHeight">
      <div *ngIf="messageText.length > 0">
        <textarea disabled autosize class="textarea-view">{{
          messageText
        }}</textarea>
      </div>
      <div class="filters" *ngIf="!hideAllFilters">
        <ng-container *ngIf="showPinned">
          <nb-checkbox [(ngModel)]="isPinned" (change)="loadOn()" [style.margin-top.rem]=".25"
          >Pinned only</nb-checkbox
          >
        </ng-container>
        <ngx-date-filer
          *ngIf="weekDateFilter || monthDateFilter"
          class="mr-1"
          [ngClass]="{ 'mt-25': filterType === FilterType.FULL }"
          [monthMode]="monthDateFilter"
          [startDate]="datesRange"
          [canSelectRange]="true"
          (onChange)="onDateFilterChange($event)"
        ></ngx-date-filer>
        <div class="mr-1" *ngIf="datesDropdownVisible">
          <select
            [(ngModel)]="dateSelected"
            (change)="dateChanged($event)"
            class="dropdown"
          >
            <option class="text-left" *ngFor="let month of monthList">
              {{ month.dateShow }}
            </option>
          </select>
        </div>
        <div *ngIf="weekInput" class="form-control-group mr-1">
          <select
            [(ngModel)]="dateSelected"
            (change)="weekChanged($event)"
            class="dropdown"
          >
            <option value="-" disabled selected hidden>Select Week</option>
            <option class="text-left week-option" *ngFor="let week of weekList" [value]="week">
              {{ week.weekString }}
            </option>
          </select>
        </div>
        <div
          *ngIf="autocompleteSelectorValues && multiselectorVisible"
          class="form-control-group filter selector mr-1"
        >
          <ngx-multiple-autocomplete-selector-with-checkbox
            class="multiple-selector"
            [values]="autocompleteSelectorValues"
            [noSelectAll]="false"
            [useShortForm]="true"
            [(ngModel)]="actualSelectorValue"
            [bindLabel]="autocompleteSelectorLabel"
            [placeholder]="autocompleteSelectorPlaceholder"
            [selectAllToggle]="selectAllSwitch"
          >
          </ngx-multiple-autocomplete-selector-with-checkbox>
        </div>
        <div *ngIf="!hideFilter" class="form-control-group filter">
          <div [ngClass]="{ 'flex-filters': filterType === FilterType.FULL }">
            <input
              #filterInput
              nbInput
              autofocus
              fullWidth
              placeholder="Filter..."
              name="filter"
              class="mr-10"
              autocomplete="off"
              [formControl]='myControl'
              [ngClass]="{ 'mt-25': filterType === FilterType.FULL }"
              (keyup.enter)="applyFilter($event.target.value); $event.preventDefault(); $event.stopPropagation()"
              (keydown.enter)="applyFilter($event.target.value); $event.preventDefault(); $event.stopPropagation()"
            />
            <ngx-multiple-autocomplete-selector-with-checkbox
              *ngIf="filterType === FilterType.FULL"
              [values]="filterBy"
              bindLabel="title"
              placeholder="Search..."
              label="Search by:"
              [(ngModel)]="actualFilterTypeValue"
            >
            </ngx-multiple-autocomplete-selector-with-checkbox>
          </div>
        </div>
        <div class="form-control-group" *ngIf="nearFilterAction">
          <button

            class="btn btn-info ml-4"
            (click)="nearFilterAction()"
          >
            <i class="nb-compose" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div *ngIf="!isDataLoaded" class="table-container">
        <div class="table-spinner-container">
          <div id="nb-global-spinner" class="spinner">
            <img src="./assets/images/echo_sygnet.svg" alt=""/>
          </div>
        </div>
      </div>
      <div
        tabindex="-1"
        id="table-container-to-focus"
        class="table-container thin-echo-scrollbar"
        [ngClass]="{
          small: messageText.length > 0,
          'description-top': descriptionTop,
          'scroll-x': isXTableScroll,
          'overflow-x-auto': autoOverflowX,
          'main-table': isMainTable
        }"
        [ngStyle]="{
          'padding-bottom': isCustomBottomPadding
            ? customBottomPadding + 'px'
            : ''
        }"
        [style.display]="isDataLoaded ? 'block' : 'none'"
      >
        <ngx-only-table
          (matSortChange)="
            usePagination ? updatePagination($event, true) : null
          "
          *ngIf="tableType === TableType.FULL"
          [data]="data"
          [columns]="columns"
          [totalRow]="totalRow"
          [onRowClick]="edit"
          [thinner]="thinner"
          [customTotal]="customTotal"
          [displayedColumnsFinal]="displayedColumnsFinal"
          [employeeDetailsTab]="employeeDetailsTab"
          [disableSorting]="disableSorting"
          [sortFromBackend]="sortFromBackend"
          [filter]="onFilterChange$"
          [doubleHeader]="doubleHeader"
          [doubleHeaderColumns]="doubleHeaderColumns"
          (textfieldChange)="onTextfieldChange($event)"
          [showIndexColumn]="showIndexColumn"
          [isAgencyReport]="isAgencyReport"
          [agencies]="agencies"
          (onTextAreaChange)="textAreaChange($event)"
          (onAgencyChanged)="agencyChanged($event)"
        >
        </ngx-only-table>
        <ngx-simple-only-table
          (matSortChange)="
            usePagination ? updatePagination($event, true) : null
          "
          *ngIf="tableType === TableType.SIMPLE"
          [data]="data"
          [columns]="columns"
          [onRowClick]="edit"
          [thinner]="thinner"
          [sortFromBackend]="sortFromBackend"
          [filter]="onFilterChange$"
        >
        </ngx-simple-only-table>
        <ngx-payroll-only-table
          *ngIf="tableType === TableType.PAYROLL"
          [data]="data"
          [columns]="columns"
          [onRowClick]="edit"
          [thinner]="thinner"
          [selectedRows]="selectedRows"
          [filter]="onFilterChange$"
          [clearSelection]="clearSelection$"
          [selectableVisibility]="selectableVisibility$"
        >
        </ngx-payroll-only-table>
        <ngx-awards-only-table
          (matSortChange)="
            usePagination ? updatePagination($event, true) : null
          "
          *ngIf="tableType === TableType.AWARDS"
          [data]="data"
          [columns]="columns"
          [onRowClick]="edit"
          [thinner]="thinner"
          [employeeDetailsTab]="employeeDetailsTab"
          [disableSorting]="disableSorting"
          [sortFromBackend]="sortFromBackend"
          [filter]="onFilterChange$"
          [doubleHeader]="doubleHeader"
          [doubleHeaderColumns]="doubleHeaderColumns"
          (textfieldChange)="onTextfieldChange($event)"
          [showIndexColumn]="showIndexColumn"
          [tableDataSummary]="awardsDataSummary"
        >
        </ngx-awards-only-table>
        <ngx-agency-report-only-table
          *ngIf="tableType === TableType.AGENCY"
          [data]="data"
          [columns]="columns"
          [onRowClick]="edit"
          [thinner]="thinner"
          [employeeDetailsTab]="employeeDetailsTab"
          [disableSorting]="disableSorting"
          [sortFromBackend]="sortFromBackend"
          [filter]="onFilterChange$"
          [doubleHeader]="doubleHeader"
          [doubleHeaderColumns]="doubleHeaderColumns"
          (textfieldChange)="onTextfieldChange($event)"
          [showIndexColumn]="showIndexColumn"
          [isAgencyReport]="isAgencyReport"
          [agencies]="agencies"
          (onTextAreaChange)="textAreaChange($event)"
          (onAgencyChanged)="agencyChanged($event)"
        ></ngx-agency-report-only-table>
      </div>
      <div class="button-container"  *ngIf="!hideBottomButtonContainer">
        <div id="over-task-btn-container-left" style="height: 40px;" [ngClass]="{'over-task-list': hasContextBar}" class="flex">
          <button
            *ngIf="add"
            [disabled]="addDisabled"
            class="btn btn-success mr-2"
            (click)="add()"
          >
            {{getButtonTitle()}}
          </button>
          <button
            *ngIf="addTraining"
            [disabled]="addDisabled"
            class="btn btn-info mr-2"
            (click)="addTraining()"
          >
            Add Training Home
          </button>
          <button
            *ngFor="let actionButton of additionalButtons"
            class="btn btn-success mr-2"
            (click)="actionButton.action()"
          >
            {{ actionButton.title }}
          </button>
          <button
            *ngIf="customAdd"
            [disabled]="addDisabled"
            class="btn btn-success"
            (click)="customAdd()"
          >
            <i
              *ngIf="!customAddLabel"
              class="nb-plus-circled"
              aria-hidden="true"
            ></i>
            <span *ngIf="customAddLabel">{{ customAddLabel }}</span>
          </button>
        </div>
        <div id="over-task-btn-container-right" class="flex"  [ngClass]="{ middle: usePagination, 'over-task-list': hasContextBar}">
          <mat-paginator
            #paginator
            *ngIf="usePagination"
            (page)="updatePagination($event)"
            class="pagination"
            [length]="totalRows"
            [pageSizeOptions]="paginationSizeOptions"
          >
          </mat-paginator>
          <button
            *ngIf="download"
            [title]="download.title"
            class="btn btn-info in-header mr-2 btn-small-text"
            (click)="
              download.action(
                datesRange,
                activeFilter,
                actualSelectorValue,
                actualFilterValue,
                paginationData,
                filterBy,
                pickedSortConfiguration,
                categoriesFilterString,
                statusesFilterString
              )
            "
          >
            <img
              *ngIf="
                download.icon === IconType.XLS ||
                download.icon === IconType.XLS_TEXT
              "
              src="assets/images/nb-xls.svg"
              [ngClass]="{ 'btn-image': download.icon === IconType.XLS_TEXT }"
              alt=""
            />
            <img
              *ngIf="download.icon === IconType.PDF"
              src="assets/images/nb-pdf.svg"
              alt=""
            />
            <p class="button-text" *ngIf="download.icon === IconType.TEXT">
              {{ download.title }}
            </p>
            <img
              *ngIf="
                download.icon === IconType.XLS_TEXT ||
                download.icon === IconType.XLS_TEXT
              "
              class="btn-image"
              src="assets/images/nb-xls.svg"
              alt=""
            />
          </button>
          <button
            *ngIf="download2"
            [title]="download2.title"
            class="btn btn-info in-header mr-2 btn-small-text"
            (click)="
              download2.action(
                datesRange,
                activeFilter,
                actualSelectorValue,
                actualFilterValue,
                paginationData,
                filterBy,
                pickedSortConfiguration,
                categoriesFilterString,
                statusesFilterString
              )
            "
          >
            <img
              *ngIf="
                download2.icon === IconType.XLS ||
                download2.icon === IconType.XLS_TEXT
              "
              src="assets/images/nb-xls.svg"
              [ngClass]="{ 'btn-image': download2.icon === IconType.XLS_TEXT }"
              alt=""
            />
            <img
              *ngIf="download2.icon === IconType.PDF"
              src="assets/images/nb-pdf.svg"
              alt=""
            />
            <p
              class="button-text"
              *ngIf="
                download2.icon === IconType.TEXT ||
                download2.icon === IconType.XLS_TEXT
              "
            >
              {{ download2.title }}
            </p>
          </button>
          <button class="btn btn-info in-header" (click)="reload()" *ngIf="!showPinned">
            <i class="nb-loop" aria-hidden="true"></i>
          </button>
          <button class="btn btn-info in-header" (click)="loadOn()" *ngIf="showPinned">
            <i class="nb-loop" aria-hidden="true"></i>
          </button>
        </div  >
      </div>
    </nb-card-body>
  </nb-card>
</div>
