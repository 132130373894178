<div class="flex-horizontal">
  <ng-container *ngIf="!isSchedule; else scheduleRow">
    <div class="employee">
      <div (click)="openCellOptions($event)" class="profile-img-container">
        <img
          [src]="contract.employeeAvatarId | avatar | async"
          class="avatar pointer"
          alt=""
        />
        <nav
          class="employee-popup-menu employee-popup-menu--right"
          *ngIf="isCellDropdownVisible"
        >
          <div
            *ngIf="permissions.MENU_SICKNESS"
            (click)="addSickness()"
            class="cell-option"
          >
            Add Sickness
          </div>
          <div *ngIf="!permissions.MENU_SICKNESS" class="disabled cell-option">
            Add Sickness
          </div>
          <div
            *ngIf="permissions.MENU_ANNUAL_LEAVES"
            (click)="addAnnualLeaving()"
            class="cell-option"
          >
            Add Annual Leaving
          </div>
          <div
            *ngIf="!permissions.MENU_ANNUAL_LEAVES"
            class="disabled cell-option"
          >
            Add Annual Leaving
          </div>
          <div (click)="goToEditEmployee()" class="cell-option">
            Show Employee 360
          </div>
        </nav>
      </div>
      <div>
        <ng-container *ngIf="getCombinedName(contract).length > 25; else fullRole">
          <div [nbTooltip]="getCombinedName(contract)">{{ getName(getCombinedName(contract), 24) }}</div>
        </ng-container>
        <ng-template #fullRole>
          <div>{{ getCombinedName(contract) }}</div>
        </ng-template>

        <ng-container *ngIf="getCombinedRoleName(contract).length > 24; else fullName">
          <span [nbTooltip]="getCombinedRoleName(contract)">{{ getName(getCombinedRoleName(contract), 23) }}</span>
        </ng-container>
        <ng-template #fullName>
          <span>{{ getCombinedRoleName(contract)}}</span>
        </ng-template>

        <div>
          <ng-container *ngIf="getCombinedLeftHrs(contract).length > 24; else fullLeft">
            <span [nbTooltip]="getCombinedLeftHrs(contract)">{{ getName(getCombinedLeftHrs(contract), 23) }}</span>
          </ng-container>
          <ng-template #fullLeft>
            <span>{{ getCombinedLeftHrs(contract) }}</span>
          </ng-template>
        </div>
      </div>
    </div>
    <ngx-overview-cell
      *ngFor="let day of days; trackBy: trackByFn"
      [date]="day.date"
      [event]="day.event"
      [hideAlButton]="hideAlButton"
      [isStartCell]="day.isStartCell"
      [isEndCell]="day.isEndCell"
      (refreshData)="onRefreshData()"
      [employeeId]="contract.employeeId"
      [careHomeId]="contract.careHomeId"
    ></ngx-overview-cell>
  </ng-container>
  <ng-template #scheduleRow>
    <div class="employee scheduled">
      <div>{{employeeName}} scheduled</div>
      <span
        [nbPopover]="summaryPopover"
        nbPopoverTrigger="hover"
      >
        Show summary
      </span>
      <ng-template #summaryPopover>
        <div class="task-tooltip">
          <div *ngFor="let sum of scheduleSummary">
            {{sum.roleName}} (<span>{{sum.scheduled}}</span>)
          </div>
        </div>
      </ng-template>
    </div>
    <div
      *ngFor="let day of daysScheduled"
      class="scheduled-container"
      [ngClass]="{today: isToday(day.date)}"
    >
      <div
        *ngIf="day.roleSchedule"
        class="bar"
        [ngClass]="{
          'first-and-last': day.roleSchedule,
          schedule: day.roleSchedule,
          'confirmed': day['roleSchedule'][0].confirmed,
          'not-confirmed': !day['roleSchedule'][0].confirmed
        }"
      >
<!--        <img-->
<!--          src="assets/images/calendar-white.svg"-->
<!--          alt="show scheduled hours"-->
<!--          [nbPopover]="scheduledPopover"-->
<!--          nbPopoverTrigger="hover"-->
<!--        >-->
        <div *ngFor="let role of day.roleSchedule" [style.font-size.px]="10">
          {{role.role}}<br>(<span>{{role.scheduled}}</span>)
        </div>
<!--        <ng-template #scheduledPopover>-->
<!--          <div class="task-tooltip">-->
<!--            <div *ngFor="let role of day.roleSchedule">-->
<!--              {{role.role}} (<span>{{role.scheduled}}</span>)-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-template>-->
      </div>
    </div>
  </ng-template>
</div>
<div
  class="popup-fallback-bg"
  (click)="closeCellDropdown()"
  *ngIf="isCellDropdownVisible"
></div>
