import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { employessErrorMessages } from "../employess-errors";
import {
  Validators,
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import {
  handleValidationErrorMessage,
  getValidationStatus,
  getErrorMessage,
  isFormValid,
  isFieldInvalid,
  getServerLink,
} from "../../../utilities/utils";
import { TableService } from "../../shared/table/table.service";
import { EmployessService } from "../../../shared/employess/employess.service";
import {
  NbToastrService,
  NbDialogService,
  NbTabsetComponent,
  NbTabComponent,
} from "@nebular/theme";
import {
  invalidDate,
  numbers,
  phoneNumber,
  handleAdultWarningMessage,
  dateGreaterThan,
  alpha,
  alphaNumbers,
  emailPleaseFillInFullCorrect,
  customPhoneNumber,
} from "../../../utilities/validators";
import { DictionariesService } from "../../../shared/dictionaries/dictionaries.service";
import * as moment from "moment";
import { AuthUserService } from "../../../@core/data/auth-user.service";
import { NewSicknessComponent } from "../sickness/new-sickness/new-sickness.component";
import { EditContractComponent } from "../contracts/edit-contract/edit-contract.component";
import { NewAnnualLeavingComponent } from "../annual-leaving/new-annual-leaving/new-annual-leaving.component";
import { Subscription, BehaviorSubject, Subject } from "rxjs";
import { EditAnnualLeavingComponent } from "../annual-leaving/edit-annual-leaving/edit-annual-leaving.component";
import { EditSicknessComponent } from "../sickness/edit-sickness/edit-sickness.component";
import { QuestionDialogComponent } from "../../shared/question-dialog/question-dialog.component";
import { TableFilter } from "../../shared/table/table-filter.enum";
import { EmployeeLeftService } from "../employee-left/employee-left.service";
import { CancelEventDialogComponent } from "../../shared/cancel-event-dialog/cancel-event-dialog.component";
import { EmployeeElementType } from "../employee-element-type.enum";
import { PermissionsService } from "../../../@core/data/permissions.service";
import { AvatarsService } from "../../../shared/avatars.service";
import {
  distinctUntilChanged,
  debounceTime,
  shareReplay,
} from "rxjs/operators";
import { EmployeeTab } from "../employee-tab.enum";
import { CareHomesService } from "../../../shared/care-homes/care-homes.service";
import { CurrentCareHomeService } from "../../../@core/data/current-care-home.service";
import { EmployeeActivationChecklistComponent } from "../employee-activation-checklist/employee-activation-checklist.component";
import { EmployeeInTrainingComponent } from "../employee-in-training/employee-in-training.component";
import { NewUserComponent } from "../../users/new-user/new-user.component";
import { EditUserComponent } from "../../users/edit-user/edit-user.component";
import { EmployeeLeftComponent } from "../employee-left/employee-left.component";
import { CoursesChangeStatusComponent } from "../courses-overview/courses-change-status/courses-change-status.component";
import { ChooseContractComponent } from "../contracts/choose-contract/choose-contract.component";
import { Router } from "@angular/router";
import { QuestionDialogCheckboxComponent } from "../../shared/question-dialog/question-dialog-checkbox/question-dialog-checkbox.component";
import { EmployeeLeaveStatusComponent } from "../employee-leave-status/employee-leave-status.component";
import { NewPayrollComponent } from "../payroll/new-payroll/new-payroll.component";
import { FormMode } from "../../../utilities/form-mode.enum";
import { EmployeeLeavingLeftDialogComponent } from "../employee-leavingleft-dialog/employee-leavingleft-dialog.component";
import { CareHomesInfoService } from "../../shared/services/care-homes-info.service";
import { Observable } from "rxjs";
import { ICareHome } from "../../shared/interfaces/care-home.interfaces";
import { ProfilesService } from "../../../shared/profiles/profiles.service";
import { UserProfileModel } from "../../../shared/models/users/profiles.model";
import { EmployeeStatusType } from "./enums/employee.enums";
import { NewDocumentsComponent } from "../contracts/new-documents/new-documents.component";
import { EmployeeMigrationToolComponent } from "../employee-migration-tool/employee-migration-tool.component";
import { EmployeesCardHistoryLine } from "./models/history.model";

const DEBOUNCE_TIME = 800;

@Component({
  selector: "ngx-edit-employee",
  templateUrl: "./edit-employee.component.html",
  styleUrls: ["./edit-employee.component.scss"],
})
export class EditEmployeeComponent implements OnInit, OnDestroy {
  public EmployeeTab: typeof EmployeeTab = EmployeeTab;
  public TableFilter: typeof TableFilter = TableFilter;
  private subscription: Subscription = new Subscription();
  public activeTab: EmployeeTab;
  public errorMessages = employessErrorMessages;
  public titles: any = [];
  public genders: any = [];
  public maritalStatuses: any = [];
  public statuses: any = [];
  public statusesToChange: any = [];
  public securityLevels: any = [];
  public p45Values: any = [];
  public studentLoanTypes: any = [];
  public employeeStatements: any = [];
  public contracts: any = [];
  public events: any = [];
  public eventsText = "";
  public courses: any = [];
  public schedule: any = [];
  public finance: any = [];
  public payroll: any = [];
  public checkboxStatuses = [
    {
      value: true,
      label: "YES",
    },
    {
      value: false,
      label: "NO",
    },
  ];
  public histories: EmployeesCardHistoryLine[] = [];
  public employee: any;
  public form: UntypedFormGroup;
  public utils = {
    getValidationStatus,
    handleValidationErrorMessage,
    isFormValid,
    isFieldInvalid,
    handleAdultWarningMessage,
  };
  public employeeId = 0;
  public employeeUserId: any;
  public careHomeId;
  public careHomeName;
  public profileAvatar = null;
  public activeContractsTableFilter = TableFilter.ACTIVE_ONLY;
  public activeEventsTableFilter = TableFilter.ACTIVE_ONLY;
  public studentLoan: boolean = false;
  public hideTelephoneNumber = false;
  public hideMobileTelephoneNumber = false;
  public hideEmail = false;
  public formChanged: boolean = false;
  public minDate = new Date(1900, 0, 1);
  public maxDate = new Date();
  public showLeavingTab = true;
  public lastTab = "information";
  public eventTypeSort = "";
  private employeeStatusId = -1;
  public categoriesWithFiles: any = [];
  public migratedCategoriesWithFiles: any = [];
  public employeeActivations: any = [];
  public fileCategories = [];
  public noReload = false;
  public isMissingRequiredDocuments = false;
  public isDocumentsAvailable = false;
  public isLeftDisable = true;
  public isDisableDFJ = false;
  public isSicknessEventOnly = false;
  public isAlEventOnly = false;
  public blockChange = false;
  public DFJplaceholder = "DD/MM/YYYY";
  public rotaFreezeDate: any;
  public originalUserDate = moment("2020/01/10", "YYYY/MM/DD");
  public forcePasswordChange = false;
  public noAccessToDocuments = false;
  public leavingCardDisableForm = false;
  public totalRows = 0;
  public searchChanged: Subject<any> = new Subject<any>();
  public rolesMultiSiteForm: UntypedFormGroup;
  public employeeLevelId: any;
  public paginationData = {
    pageIndex: 0,
    pageSize: 50,
    orderBy: "",
    orderDirection: "",
    filterValue: "",
  };

  public historyColumns = [
    {
      fieldName: "changeDate",
      title: "Change Date",
    },
    {
      fieldName: "changeBy",
      title: "Changed By",
    },
    {
      fieldName: "fieldName",
      title: "Field name",
    },
    {
      fieldName: "oldValue",
      title: "Old value",
    },
    {
      fieldName: "newValue",
      title: "New value",
    },
  ];

  public contractColumns = [
    {
      fieldName: "contractCreatedAt",
      title: "Created At",
    },
    {
      fieldName: "careHomeName",
      title: "Care Home",
    },
    {
      fieldName: "contractRole",
      title: "Role",
    },
    {
      fieldName: "contractTypeAndHrs",
      title: "Type",
    },
    {
      fieldName: "contractStart",
      title: "Start Date",
    },
    {
      fieldName: "contractEnd",
      title: "End Date",
    },
    {
      fieldName: "contracStatus",
      title: "Contract Status",
    },
    {
      fieldName: "alDaysMonth",
      title: "AL Days",
      tooltip: "click on cell, to see details",
      staticWidth: "105px",
    },
    {
      fieldName: "contractComments",
      title: "Comments",
    },
  ];

  public eventColumns = [
    {
      fieldName: "careHomeName",
      title: "Care Home",
    },
    {
      fieldName: "eventHY",
      title: "Al year",
    },
    {
      fieldName: "contractRole",
      title: "Role",
    },
    {
      fieldName: "eventTypeFull",
      title: "Type of Event",
    },
    {
      fieldName: "eventStartDate",
      title: "Start Date",
    },
    {
      fieldName: "eventEndDate",
      title: "End Date",
    },
    {
      fieldName: "eventGivenHrs",
      title: "hrs",
    },
    {
      fieldName: "eventFirstDayAvailable",
      title: "First Day Available For Work",
    },
    {
      fieldName: "eventStatus",
      title: "Status",
    },
    {
      fieldName: "eventDescription",
      title: "Description",
    },
  ];

  public coursesColumns = [
    {
      fieldName: "trainingRole",
      title: "Training Role",
    },
    {
      fieldName: "trainingName",
      title: "Training Name",
    },
    {
      fieldName: "status",
      title: "Status",
    },
    {
      fieldName: "completedAt",
      title: "Completed At",
    },
    {
      fieldName: "vaidateToDate",
      title: "Vaidate To",
    },
    {
      fieldName: "description",
      title: "Description",
    },
  ];

  public payrollColumns = [
    {
      fieldName: "adjNo",
      title: "Payroll Adjustment No.",
    },
    {
      fieldName: "addedBy",
      title: "Added By",
    },
    {
      fieldName: "createdAt",
      title: "Created At",
    },
    {
      fieldName: "employee",
      title: "Employee",
    },
    {
      fieldName: "roleName",
      title: "Role",
    },
    {
      fieldName: "payrollMonth",
      title: "Payroll Date",
    },
    {
      fieldName: "timeAmount",
      title: "Time Amount",
    },
    {
      fieldName: "type",
      title: "Adjustment Type",
    },
    {
      fieldName: "payHow",
      title: "Pay How",
    },
    {
      fieldName: "acceptanceStatus",
      title: "Status",
    },
  ];

  public scheduleColumns = [
    {
      fieldName: "date",
      title: "Date",
    },
    {
      fieldName: "weekDate",
      title: "Week NO",
    },
    {
      fieldName: "positionName",
      title: "Position",
    },
    {
      fieldName: "careHomeName",
      title: "Care Home info",
    },
    {
      fieldName: "dayOfWeek",
      title: "Day of week",
    },
    {
      fieldName: "schedule",
      title: "schedule",
    },
    {
      fieldName: "scheduleHrs",
      title: "schedule hrs",
    },
    {
      fieldName: "confirmed",
      title: "confirmed",
    },
    {
      fieldName: "confirmedHrs",
      title: "confirmed hrs",
      hideColumnInSummary: true,
    },
  ];

  public financeColumns = [
    {
      fieldName: "contractNo",
      title: "Contract No",
    },
    {
      fieldName: "careHomeName",
      title: "Care Home",
    },
    {
      fieldName: "contractRole",
      title: "Role",
    },
    {
      fieldName: "contractType",
      title: "Type",
    },
    {
      fieldName: "contractStart",
      title: "Start Date",
    },
    {
      fieldName: "contracStatus",
      title: "Contract Status",
    },
    {
      fieldName: "validFrom",
      title: "Valid From",
    },
    {
      fieldName: "baseRateOfPay",
      title: "Base Rate",
    },
    {
      fieldName: "createdBy",
      title: "By",
    },
  ];

  public activationColumns = [
    {
      fieldName: "name",
      title: "Name",
    },
    {
      fieldName: "description",
      title: "Description",
    },
    {
      fieldName: "comments",
      title: "Comments",
    },
    {
      fieldName: "confirmed",
      title: "Confirmed",
    },
  ];

  public onFilterChange$ = new BehaviorSubject<string>("");
  public permissions = <any>{};
  public doNotShowLeavingLeftModal = false;
  public dataLoaded = false;
  public careHomes$: Observable<ICareHome[]>;
  public userProfileRoles: UserProfileModel[];
  public isMultiSiteRolesTab: boolean;
  public isDataLoading: boolean;
  public isAgency = false;

  public testButtonActive: boolean = false;

  private colorsMap = [
    "#ffb4b4",
    "#ffdbb4",
    "#fffeb4",
    "#e0ffb4",
    "#b4fbff",
    "#b4ccff",
    "#c5b4ff",
    "#f0b4ff",
    "#ffb4ce",
    "#bfbfbf",
  ];
  idMaps: any = {};
  private refreshEvents$ = new Subject();

  @ViewChild("tabset") tabset: NbTabsetComponent;
  @ViewChild("informationTab") informationTab: NbTabComponent;
  @ViewChild("documentsTab") documentsTab: NbTabComponent;

  minHeightTab = 0;
  private refreshGeneral$ = new Subject();

  constructor(
    private tableService: TableService,
    private employeeService: EmployessService,
    private dictionaryService: DictionariesService,
    private authUserService: AuthUserService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private employeeLeftService: EmployeeLeftService,
    private permissionsService: PermissionsService,
    private avatarsService: AvatarsService,
    private careHomeService: CareHomesService,
    private currentCareHomeService: CurrentCareHomeService,
    private router: Router,
    private careHomesInfoService: CareHomesInfoService,
    private careHomesService: CareHomesService,
    private profilesService: ProfilesService,
  ) {
    this.rolesMultiSiteForm = new UntypedFormGroup({
      multiSiteWorker: new UntypedFormControl(null),
      multiSiteScheduler: new UntypedFormControl(null),
    });
    this.careHomes$ = this.careHomesInfoService.careHomes$;
    this.permissions = this.permissionsService.getPermissions();
    this.subscription.add(
      this.tableService.refresh$.subscribe(() => {
        if (this.activeTab !== EmployeeTab.MESSAGES) {
          this.refresh("contracts");
          this.refresh("events");
          this.refresh("courses");
          this.refresh("documents");
          this.refresh("Payroll adjustments");
          if (this.permissions.FINANCE_MANAGER) {
            this.refresh("finance");
          }
        }
      }),
    );
    this.searchChanged
      .pipe(debounceTime(DEBOUNCE_TIME), distinctUntilChanged())
      .subscribe((model: any) => {
        this.refresh(model.tableName);
      });

    this.subscription.add(
      this.refreshEvents$.pipe(debounceTime(200)).subscribe(() => {
        this.refresh("events");
      }),
    );
  }

  get multiSiteWorkerControl(): AbstractControl {
    return this.rolesMultiSiteForm.get("multiSiteWorker");
  }
  get multiSiteSchedulerControl(): AbstractControl {
    return this.rolesMultiSiteForm.get("multiSiteScheduler");
  }

  get statusIdControl(): AbstractControl {
    return this.form.get("statusId");
  }

  get userId(): number {
    return this.employeeUserId || null;
  }

  get isUserHaveMultiSiteAdminPermission(): boolean {
    return this.permissionsService.haveUserPermissionTo("Multi site admin");
  }

  get employeeStatusType(): typeof EmployeeStatusType {
    return EmployeeStatusType;
  }

  showOptionsTooltip = false;

  toogleContractsTableFilter(value: any) {
    this.activeContractsTableFilter = value;
    this.refreshGeneral$.next(true);
  }

  toogleEventsTableFilter(value: any) {
    this.activeEventsTableFilter = value;
    this.refreshEvents$.next(true);
  }

  addContract = () => {
    this.toastrService.info(
      "To create a new contract, open their 360 Profile, go to Documents and upload the paper contract first",
      "Info",
      { duration: 0 },
    );
  };

  migrateUser = () => {
    this.tableService.openWindowWithBackStack(
      EmployeeMigrationToolComponent,
      "Migrate Tool",
      {
        component: EditEmployeeComponent,
        title: "Employee Profile Information",
        elementId: this.employeeId,
        activeTab: EmployeeTab.MULTI_SITE_ROLES,
      },
      {
        employeeUid: this.employee.uid,
        careHomeId: this.careHomeId,
      },
    );
  };

  addSickness = (forceChangeToLTS = false) => {
    const params = { employeeId: this.employeeId, needAcceptance: false };
    if (forceChangeToLTS) {
      params["forceChangeToLTS"] = true;
    }
    this.tableService.openWindowWithBackStack(
      NewSicknessComponent,
      "Add new sickness",
      {
        component: EditEmployeeComponent,
        title: "Employee Profile Information",
        elementId: this.employeeId,
        activeTab: EmployeeTab.EVENTS,
      },
      params,
    );
  };

  addAnnualLeaving = () => {
    this.tableService.openWindowWithBackStack(
      NewAnnualLeavingComponent,
      "Add new annual leaving",
      {
        component: EditEmployeeComponent,
        title: "Employee Profile Information",
        elementId: this.employeeId,
        activeTab: EmployeeTab.EVENTS,
      },
      { employeeId: this.employeeId, alText: this.eventsText },
    );
  };

  editContract = (event: any) => {
    if (this.permissions.NEW_CONTRACTS) {
      this.tableService.openWindowWithBackStack(
        EditContractComponent,
        `Contract information - ${event.contractNo}`,
        {
          component: EditEmployeeComponent,
          title: "Employee Profile Information",
          elementId: this.employeeId,
          activeTab: EmployeeTab.CONTRACTS,
        },
        { elementId: event.contractId },
      );
    }
  };

  editEvent = (event: any) => {
    if (event.eventTypeFull.includes("Sickness")) {
      this.tableService.openWindowWithBackStack(
        EditSicknessComponent,
        `Event Information - ${event.eventNo}`,
        {
          component: EditEmployeeComponent,
          title: "Employee Profile Information",
          elementId: this.employeeId,
          activeTab: EmployeeTab.EVENTS,
        },
        {
          elementId: event.eventId,
          employeeId: this.employeeId,
          eventType: event.eventTypeFull,
          eventNo: event.eventNo,
        },
      );
    } else if (event.eventTypeFull.includes("leave")) {
      this.tableService.openWindowWithBackStack(
        EditAnnualLeavingComponent,
        `Event Information - ${event.eventNo}`,
        {
          component: EditEmployeeComponent,
          title: "Employee Profile Information",
          elementId: this.employeeId,
          activeTab: EmployeeTab.EVENTS,
        },
        {
          elementId: event.eventId,
          employeeId: this.employeeId,
          eventType: event.eventTypeFull,
          eventNo: event.eventNo,
        },
      );
    }
  };

  editPayroll = (event: any) => {
    this.tableService.openWindowWithBackStack(
      NewPayrollComponent,
      `Payroll Adjustment - ${event.adjNo}`,
      {
        component: EditEmployeeComponent,
        title: "Employee Profile Information",
        elementId: this.employeeId,
        activeTab: EmployeeTab.PAYROLL,
      },
      { mode: FormMode.EDIT, elementId: event.adjId },
    );
  };

  ngOnInit() {
    this.isDataLoading = true;
    this.activeTab = this.tableService.getValue().activeTab || EmployeeTab.INFO;
    if (
      !this.permissions.EMPLOYEE_DOCUMENTS &&
      this.activeTab == EmployeeTab.DOCUMENTS
    ) {
      this.activeTab = EmployeeTab.INFO;
    }
    this.createForm();
    this.form
      .get("dateFirstJoined")
      .setValidators([
        Validators.required,
        invalidDate,
        dateGreaterThan(this.form.get("dateOfBirth").value),
      ]);
    this.noReload = this.tableService.getValue().noReload
      ? this.tableService.getValue().noReload
      : false;
    this.employeeId = this.tableService.getValue().elementId
      ? this.tableService.getValue().elementId
      : this.tableService.getValue().employeeId;
    this.careHomeId = this.authUserService.getCareHomeId();
    this.isDocumentsAvailable =
      !this.currentCareHomeService.employeeDocumentsNotConfigured;

    this.subscription.add(
      this.dictionaryService
        .getEmployeeStatuses()
        .subscribe((response: any) => {
          this.statuses = response.result.wordsFromDictionary;
          this.loadStatusesWithoutLeft(response.result.wordsFromDictionary);
        }),
    );
    this.subscription.add(
      this.dictionaryService
        .getTitles()
        .subscribe(
          (response: any) =>
            (this.titles = response.result.wordsFromDictionary),
        ),
    );
    this.subscription.add(
      this.dictionaryService
        .getGenders()
        .subscribe(
          (response: any) =>
            (this.genders = response.result.wordsFromDictionary),
        ),
    );
    this.subscription.add(
      this.dictionaryService
        .getMaritalStatuses()
        .subscribe(
          (response: any) =>
            (this.maritalStatuses = response.result.wordsFromDictionary),
        ),
    );
    this.subscription.add(
      this.dictionaryService
        .getP45Values()
        .subscribe(
          (response: any) =>
            (this.p45Values = response.result.wordsFromDictionary),
        ),
    );
    this.subscription.add(
      this.dictionaryService
        .getStudentLoanTypes()
        .subscribe(
          (response: any) =>
            (this.studentLoanTypes = response.result.wordsFromDictionary),
        ),
    );
    this.subscription.add(
      this.dictionaryService
        .getEmployeeStatements()
        .subscribe(
          (response: any) =>
            (this.employeeStatements = response.result.wordsFromDictionary),
        ),
    );
    this.subscription.add(
      this.dictionaryService.getSecurityLevels().subscribe((response: any) => {
        this.securityLevels = response.result.wordsFromDictionary;
      }),
    );
    this.subscription.add(
      this.careHomeService
        .getDocumentCategories(this.careHomeId)
        .subscribe((response: any) => {
          this.fileCategories = response.result.list;
        }),
    );

    this.getEmployeeData();

    this.subscription.add(
      this.employeeService
        .getEmployeeActivations(this.employeeId)
        .subscribe((response: any) => {
          this.employeeActivations = response.result.list;
        }),
    );

    this.subscription.add(
      this.careHomeService
        .getFreezeDateById(this.careHomeId)
        .subscribe(
          (response: any) =>
            (this.rotaFreezeDate = moment(response.freezeDate, "YYYY/MM/DD")),
        ),
    );
    // this.refresh("contracts");
    // must be here for status changing logic
    Object.keys(this.form.controls).forEach((key) =>
      this.form.get(key).markAsTouched(),
    );

    this.subscription.add(
      this.refreshGeneral$.pipe(debounceTime(200)).subscribe(() => {
        this.refresh("general");
      }),
    );

    this.minHeightTab =
      document.getElementById("employee-tabset").offsetHeight - 100;
  }

  loadStatusesWithoutLeft(allStatuses = []) {
    this.statusesToChange = Object.assign([], allStatuses);
    const leftIndex = this.statuses.findIndex(
      (element: any) => element.id == this.getWord("Left"),
    );
    this.statusesToChange.splice(leftIndex, 1);
  }

  updatePagination(event, tableName, isSort?) {
    if (isSort) {
      this.paginationData.orderBy = event.active;
      this.paginationData.orderDirection = event.direction;
    } else {
      this.paginationData.pageIndex = event.pageIndex;
      this.paginationData.pageSize = event.pageSize;
    }
    this.refresh(tableName);
  }

  refresh(tableName: string) {
    const activeOnly =
      this.activeContractsTableFilter === TableFilter.ACTIVE_ONLY;
    const from =
      this.paginationData.pageSize * (this.paginationData.pageIndex + 1) -
      this.paginationData.pageSize +
      1;
    const to =
      this.paginationData.pageSize * (this.paginationData.pageIndex + 1);
    switch (tableName) {
      case "general":
        return this.subscription.add(
          this.employeeService
            .getUnaggregatedContractsWithoutCareHome(
              this.employeeId,
              activeOnly,
            )
            .subscribe((response: any) => {
              this.contracts = response.result.contractsLines;
              this.eventsText = response.result.alCalculationInfo;
            }),
        );
      case "events":
        return this.subscription.add(
          this.employeeService
            .getUnaggregatedEventsWithoutCareHome(
              this.employeeId,
              this.activeEventsTableFilter === TableFilter.ACTIVE_ONLY,
              this.eventTypeSort,
            )
            .subscribe((response: any) => {
              this.events = response.result.contractsLines;
              let index = 0;
              this.events.forEach((e) => {
                if (!(e.eventSeriesId in this.idMaps)) {
                  this.idMaps[e.eventSeriesId] = this.colorsMap[index];
                  index++;
                  if (index === this.colorsMap.length - 1) {
                    index = 0;
                  }
                }
              });
            }),
        );
      case "courses":
        return this.subscription.add(
          this.employeeService
            .getUnaggregatedCoursesWithoutCareHome(this.employeeId)
            .subscribe(
              (response: any) =>
                (this.courses = response.result.employeeTrainingsList),
            ),
        );
      case "schedule":
      case "rota":
      case "day schedule":
        return this.subscription.add(
          this.employeeService
            .getEmployeeSchedule(
              this.employeeId,
              from,
              to,
              this.paginationData.orderBy,
              this.paginationData.orderDirection,
              this.paginationData.filterValue,
            )
            .subscribe((response: any) => {
              this.totalRows = response.result.totalRows;
              let lastItemName =
                response.result.list &&
                response.result.list[0] &&
                response.result.list[0].weekDate;

              let confirmedHrs: number = 0;
              let confirmedMin: number = 0;
              let schedule: any[] = [];
              response.result.list.forEach((item, index: number) => {
                if (item.weekDate === lastItemName || !lastItemName) {
                  const splitHours = item.confirmedHrs.split(":");
                  confirmedHrs += parseInt(splitHours[0], 10);
                  confirmedMin += parseInt(splitHours[1], 10);
                } else if (lastItemName != item.weekDate) {
                  schedule = [
                    ...schedule,
                    {
                      confirmed: `${this._calculateRotaConfirmedHrs(
                        confirmedHrs,
                        confirmedMin,
                      )} hrs confirmed`,
                      date: lastItemName,
                      isEmployeeRotaSummaryRow: true,
                    },
                  ];
                  lastItemName = item.weekDate;
                  const splitHours = item.confirmedHrs.split(":");
                  confirmedHrs = parseInt(splitHours[0], 10);
                  confirmedMin = parseInt(splitHours[1], 10);
                }

                schedule = [...schedule, item];

                if (index === response.result.list.length - 1) {
                  schedule = [
                    ...schedule,
                    {
                      confirmed: `${this._calculateRotaConfirmedHrs(
                        confirmedHrs,
                        confirmedMin,
                      )} hrs confirmed`,
                      date: lastItemName,
                      isEmployeeRotaSummaryRow: true,
                    },
                  ];
                }
              });
              this.schedule = schedule;
            }),
        );
      case "activation checklist":
        return this.subscription.add(
          this.employeeService
            .getEmployeeActivations(this.employeeId)
            .subscribe((response: any) => {
              this.employeeActivations = response.result.list;
            }),
        );
      case "payroll adjustments":
        return this.subscription.add(
          this.employeeService
            .getEmployeePayroll(this.employeeId)
            .subscribe((response: any) => {
              this.payroll = response.result.payrollAdjustmentList;
            }),
        );
      case "finance":
        return this.subscription.add(
          this.employeeService
            .getEmployeeFinance(this.employeeId)
            .subscribe(
              (response: any) => (this.finance = response.result.list),
            ),
        );
      case "documents":
        return this.subscription.add(
          this.employeeService
            .getEmployeeDocuments(this.employeeId)
            .subscribe((response: any) => {
              this.categoriesWithFiles = this.linkDocumentsWithCategories(
                response.result.list,
                this.fileCategories,
              );
              this.prepareDocumentsWithoutCategories(
                response.result.list,
                this.fileCategories,
              );
              this.categoriesWithFiles = [
                ...this.categoriesWithFiles,
                ...this.migratedCategoriesWithFiles,
              ];
            }),
        );
      case "leaving":
        return this.employeeLeftService.refreshLeavingTab$.next(true);
      case "history":
        return this.subscription.add(
          this.employeeService
            .getEmployeeHistory(this.employeeId)
            .subscribe((res) => {
              this.histories = res.result.list;
            }),
        );
    }
  }

  prepareDocumentsWithoutCategories(files: any = [], categories: any = []) {
    const categoriesIds: number[] = [];
    categories.forEach((cat) => {
      categoriesIds.push(cat.id);
    });
    const tmp = [];

    files.forEach((file: any) => {
      if (!categoriesIds.includes(file.categoryId)) {
        // now check if migratedCategoriesWithFiles has this category applied
        if (tmp.some((category) => category["id"] === file.categoryId)) {
          const categoryIndex = tmp.findIndex(
            (category) => category["id"] === file.categoryId,
          );
          tmp[categoryIndex]["files"].push(file);
        } else {
          tmp.push({
            description: file.category,
            files: [file],
            id: file.categoryId,
            name: file.category,
            obligatory: false,
            courseId: null,
            courseName: "",
            goLiveDate: null,
            isMigrated: true,
          });
        }
      }
    });
    this.migratedCategoriesWithFiles = tmp.reverse();
  }

  linkDocumentsWithCategories(files: any = [], categories: any = []) {
    categories.forEach((category: any) => {
      category["files"] = files.filter((file: any) => {
        return file.categoryId == category.id;
      });
    });

    // check for missing categories
    const dateUserJoined = moment(this.employee.dateFirstJoined);
    const isOriginalUser = dateUserJoined.isBefore(this.originalUserDate);
    if (this.isDocumentsAvailable && !isOriginalUser) {
      for (let i = 0; i < categories.length; i++) {
        const goLiveDate = moment(categories[i].goLiveDate, "YYYY-MM-DD");
        const isUserJoinedAfterGoLive = !categories[i].goLiveDate
          ? true
          : dateUserJoined.isAfter(goLiveDate);
        if (
          categories[i].obligatory &&
          categories[i].files.length === 0 &&
          isUserJoinedAfterGoLive
        ) {
          this.isMissingRequiredDocuments = true;
          break;
        } else {
          this.isMissingRequiredDocuments = false;
        }
      }
    }

    return categories;
  }

  loadAvatar(avatarId: any) {
    if (avatarId) {
      this.avatarsService.getBlobAvatar(avatarId).subscribe((response: any) => {
        this.profileAvatar = response;
      });
    }
  }

  applyFilter(filterValue: string, tableName?: string) {
    if (tableName) {
      this.paginationData.filterValue = filterValue;
      this.searchChanged.next({ tableName, filterValue });
    } else {
      this.onFilterChange$.next(filterValue);
    }
  }

  createForm() {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      levelId: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [numbers],
      ),
      statusId: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.required],
      ),
      avatar: new UntypedFormControl(null),
      firstName: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.required, Validators.maxLength(50)],
      ),
      surname: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.required, Validators.maxLength(50)],
      ),
      dateOfBirth: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.required, invalidDate],
      ),
      dateFirstJoined: new UntypedFormControl({ value: null, disabled: true }, [
        invalidDate,
      ]),
      emergencyNameFirst: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.required],
      ),
      emergencyRelationFirst: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.required],
      ),
      emergencyPhoneFirst: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
          customPhoneNumber,
        ],
      ),
      emergencyNameSecond: new UntypedFormControl({
        value: null,
        disabled: !this.permissions.MENU_EMPLOYEES,
      }),
      emergencyRelationSecond: new UntypedFormControl({
        value: null,
        disabled: !this.permissions.MENU_EMPLOYEES,
      }),
      emergencyPhoneSecond: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.minLength(6), Validators.maxLength(20), customPhoneNumber],
      ),
      nationalInsuranceNumber: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.required, Validators.minLength(3), Validators.maxLength(9)],
      ),
      passportNumber: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.maxLength(25), alphaNumbers],
      ),
      title: new UntypedFormControl({
        value: null,
        disabled: !this.permissions.MENU_EMPLOYEES,
      }),
      gender: new UntypedFormControl({
        value: null,
        disabled: !this.permissions.MENU_EMPLOYEES,
      }),
      maritalStatus: new UntypedFormControl({
        value: null,
        disabled: !this.permissions.MENU_EMPLOYEES,
      }),
      phoneNumber: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.maxLength(25), phoneNumber],
      ),
      secondPhoneNumber: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.maxLength(25), phoneNumber],
      ),
      email: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.maxLength(50), emailPleaseFillInFullCorrect],
      ),
      receiveEmail: new UntypedFormControl({
        value: null,
        disabled: !this.permissions.MENU_EMPLOYEES,
      }),
      hideTelephoneNumber: new UntypedFormControl({ value: null }),
      hideMobileTelephoneNumber: new UntypedFormControl({ value: null }),
      hideEmail: new UntypedFormControl({ value: null }),
      street: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.maxLength(50)],
      ),
      city: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.maxLength(50), alpha],
      ),
      state: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.maxLength(50), alpha],
      ),
      zipCode: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.maxLength(50)],
      ),
      houseNumber: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.minLength(1), Validators.maxLength(20)],
      ),
      bankAccountNumber: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.maxLength(8), numbers],
      ),
      bankSortCode: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.minLength(6), Validators.maxLength(6)],
      ),
      buildingSocietyRoutingNumber: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.maxLength(25)],
      ),
      description: new UntypedFormControl(
        { value: null, disabled: !this.permissions.MENU_EMPLOYEES },
        [Validators.maxLength(5000)],
      ),
      studentLoan: new UntypedFormControl({
        value: null,
        disabled: !this.permissions.MENU_EMPLOYEES,
      }),
      studentLoanTypeId: new UntypedFormControl({
        value: null,
        disabled: !this.permissions.MENU_EMPLOYEES,
      }),
      employeeStatementId: new UntypedFormControl({
        value: null,
        disabled: !this.permissions.MENU_EMPLOYEES,
      }),
      p45Id: new UntypedFormControl({
        value: null,
        disabled: !this.permissions.MENU_EMPLOYEES,
      }),
      employeeUserLogin: new UntypedFormControl({
        value: null,
        disabled: true,
      }),
      isSicknessEventOnly: new UntypedFormControl(false),
      isAlEventOnly: new UntypedFormControl(false),
      isAgency: new UntypedFormControl(false),
    });

    this.form.valueChanges.subscribe(() => (this.formChanged = true));
    this.form.get("isSicknessEventOnly").valueChanges.subscribe((value) => {
      if (!this.blockChange) {
        this.changeEventToggle("Sick");
      } else {
        this.blockChange = false;
      }
    });

    this.form.get("isAlEventOnly").valueChanges.subscribe((value) => {
      if (!this.blockChange) {
        this.changeEventToggle("AL");
      } else {
        this.blockChange = false;
      }
    });
  }

  updateValues(employee: any) {
    this.employeeId = employee.id;
    this.employeeStatusId = employee.statusId;
    this.employeeUserId = employee.employeeUserId;
    this.noAccessToDocuments = !employee.accessToDocuments;
    this.employeeLevelId = employee.levelId;
    this.form.patchValue({
      id: employee.id,
      levelId: employee.levelId,
      statusId: employee.statusId,
      firstName: employee.firstName,
      surname: employee.surname,
      dateOfBirth: new Date(employee.dateOfBirth),
      nationalInsuranceNumber: employee.nationalInsuranceNumber
        ? employee.nationalInsuranceNumber
        : null,
      passportNumber: employee.passportNumber ? employee.passportNumber : null,
      title: employee.title ? employee.titleId : null,
      gender: employee.gender ? employee.genderId : null,
      maritalStatus: employee.maritalStatus ? employee.maritalStatusId : null,
      phoneNumber: employee.phoneNumber ? employee.phoneNumber : null,
      secondPhoneNumber: employee.secondPhoneNumber
        ? employee.secondPhoneNumber
        : null,
      email: employee.email ? employee.email : null,
      receiveEmail: employee.receiveEmail ? employee.receiveEmail : null,
      hideTelephoneNumber: employee.hideTelephoneNumber
        ? employee.hideTelephoneNumber
        : false,
      hideMobileTelephoneNumber: employee.hideMobileTelephoneNumber
        ? employee.hideMobileTelephoneNumber
        : false,
      hideEmail: employee.hideEmail ? employee.hideEmail : false,
      street: employee.street ? employee.street : null,
      city: employee.city ? employee.city : null,
      country: employee.country ? employee.country : null,
      state: employee.state ? employee.state : null,
      zipCode: employee.zipCode ? employee.zipCode : null,
      houseNumber: employee.houseNumber ? employee.houseNumber : null,
      bankAccountNumber: employee.bankAccountNumber
        ? employee.bankAccountNumber
        : null,
      bankSortCode: employee.bankSortCode ? employee.bankSortCode : null,
      buildingSocietyRoutingNumber: employee.buildingSocietyRoutingNumber
        ? employee.buildingSocietyRoutingNumber
        : null,
      description: employee.description ? employee.description : null,
      studentLoan: employee.studentLoanId ? true : false,
      studentLoanTypeId: employee.studentLoanTypeId
        ? employee.studentLoanTypeId
        : null,
      employeeStatementId: employee.employeeStatementId
        ? employee.employeeStatementId
        : null,
      p45Id: employee.p45Id ? employee.p45Id : null,
      employeeUserLogin: employee.employeeUserLogin
        ? employee.employeeUserLogin
        : null,
      isAgency: employee.isAgency || false,
      emergencyNameFirst: employee.emergencyNameFirst || null,
      emergencyNameSecond: employee.emergencyNameSecond || null,
      emergencyPhoneFirst: employee.emergencyPhoneFirst || null,
      emergencyPhoneSecond: employee.emergencyPhoneSecond || null,
      emergencyRelationFirst: employee.emergencyRelationFirst || null,
      emergencyRelationSecond: employee.emergencyRelationSecond || null,
    });
    this.isAgency = employee.isAgency || false;
    if (employee.employeeUserLogin && employee.employeeUserLogin != null) {
      this.form.get("receiveEmail").enable();
    } else {
      this.form.get("receiveEmail").disable();
    }
    this.subscription.add(
      this.form.get("employeeUserLogin").valueChanges.subscribe((val) => {
        if (val && val != null) {
          this.form.get("receiveEmail").enable();
        } else {
          this.form.get("receiveEmail").disable();
        }
      }),
    );

    this.form
      .get("dateFirstJoined")
      .setValue(
        employee.dateFirstJoined ? new Date(employee.dateFirstJoined) : null,
      );

    if (this.isDisableDFJ) {
      this.form.get("dateFirstJoined").disable();
    } else {
      this.form.get("dateFirstJoined").enable();
    }

    this.dataLoaded = true;
    this.toggleStudentLoan(employee.studentLoanId == 1 ? true : false);
    this.togglehideMobileTelephoneNumber(
      employee.hideMobileTelephoneNumber == true ? true : false,
    );
    this.togglehideTelephoneNumber(
      employee.hideTelephoneNumber == true ? true : false,
    );
    this.togglehideEmail(employee.hideEmail == true ? true : false);
    Object.keys(this.form.controls).forEach((key) =>
      this.form.get(key).markAsTouched(),
    );
    this.form
      .get("dateFirstJoined")
      .setValidators([
        Validators.required,
        invalidDate,
        dateGreaterThan(this.form.get("dateOfBirth").value),
      ]);
    if (
      this.form.get("statusId").value !== this.getWord("Leaving") &&
      this.form.get("statusId").value !== this.getWord("Left")
    ) {
      if (this.activeTab === EmployeeTab.LEAVING) {
        this.activeTab = EmployeeTab.INFO;
      }
      this.showLeavingTab = false;
    } else {
      if (this.form.get("statusId").value === this.getWord("Left")) {
        this.leavingCardDisableForm = true;
        this.statusesToChange = [];
        this.statusesToChange.push(
          this.statuses.find(
            (element: any) => element.id == this.getWord("Active"),
          ),
        );
        this.statusesToChange.push(
          this.statuses.find(
            (element: any) => element.id == this.getWord("Left"),
          ),
        );
      }
      this.showLeavingTab = true;
    }
    if (
      this.form.get("statusId").value === this.getWord("In Training") &&
      !this.permissions.IN_TRAININIG_TO_ACTIVE
    ) {
      this.statusesToChange = [];
      this.statusesToChange.push(
        this.statuses.find(
          (element: any) => element.id == this.getWord("In Training"),
        ),
      );
      // this.statusesToChange.push(
      //   this.statuses.find((element: any) => element.id == this.getWord("Left"))
      // );
      this.statusesToChange.push(
        this.statuses.find(
          (element: any) => element.id == this.getWord("Leaving"),
        ),
      );
    }
    if (this.form.get("statusId").value === this.getWord("Leaving")) {
      this.statusesToChange = [];
      this.statusesToChange.push(
        this.statuses.find(
          (element: any) => element.id == this.getWord("Active"),
        ),
      );
      this.statusesToChange.push(
        this.statuses.find(
          (element: any) => element.id == this.getWord("Leaving"),
        ),
      );
      this.statusesToChange.push(
        this.statuses.find(
          (element: any) => element.id == this.getWord("Left"),
        ),
      );
    }
    if (this.form.get("statusId").value === this.getWord("Active")) {
      this.loadStatusesWithoutLeft(this.statuses);
    }
    this.loadAvatar(employee.avatarId);
    this.form
      .get("statusId")
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((value: string) => {
        if (this.isChangeToActiveStatus()) {
          if (this.isMissingRequiredDocuments) {
            this.doNotShowLeavingLeftModal = true;
            this.setCurrentStatusForUser();
            this.toastrService.danger(
              `Unable to set active status for this user. Please fill missing documents.`,
              "Error",
              { duration: 60000, destroyByClick: true },
            );
          } else if (this.isReturnFromTraining()) {
            this.openTrainingEditComponentWithSingleBackstack(true, true);
          } else if (this.isReturnFromLeft()) {
            this.showReturnFromLeftToActive();
          }
        } else if (this.needShowComponentForStatusWord("In Training")) {
          this.openTrainingEditComponentWithSingleBackstack(
            !this.isChangeFromCreatedToInTraining(),
          );
        } else if (this.isPickedLeftStatus(value)) {
          if (this.doNotShowLeavingLeftModal) {
            this.doNotShowLeavingLeftModal = false;
          } else {
            this.openLeftWindow();
          }
        } else if (this.isPickedLeavingStatus(value)) {
          // if click CLOSE on Left Modal do not show leaving modal next
          if (this.doNotShowLeavingLeftModal) {
            this.doNotShowLeavingLeftModal = false;
          } else {
            this.showLeavingModal();
          }
        } else if (this.isPickedLTSickStatus(value)) {
          this.addSickness(true);
        } else if (this.isChangeToActiveWithoutTrainingStatus()) {
          if (this.isMissingRequiredDocuments) {
            this.setCurrentStatusForUser();
            this.toastrService.danger(
              `Unable to set active status for this user. Please fill missing documents.`,
              "Error",
              { duration: 60000, destroyByClick: true },
            );
          }
        }
      });

    this.isDataLoading = false;
  }

  showReturnFromLeftToActive() {
    this.subscription.add(
      this.dialogService
        .open(QuestionDialogComponent, {
          closeOnBackdropClick: false,
          context: {
            title: "Activation confirmation",
            message: `In order to avoid problems related to the settlement of the employee, the reactivation of the employee from the left status<br/>requires confirmation with the financial department.
        Please contact: <b>finance@rosecaregroup.co.uk</b>.<br/><br/>Are you sure to active employee ?`,
            okLabel: "Yes",
            cancelLabel: "No",
          },
        })
        .onClose.subscribe((decision: boolean) => {
          if (decision) {
            const fd = new FormData();
            this.saveData(false, null, false, fd, true);
          } else {
            this.doNotShowLeavingLeftModal = true;
            this.form.get("statusId").setValue(this.employeeStatusId);
          }
        }),
    );
  }

  showLeavingModal() {
    this.dialogService
      .open(EmployeeLeavingLeftDialogComponent, { closeOnBackdropClick: false })
      .onClose.subscribe((response: any) => {
        if (response) {
          const fd = new FormData();
          fd.append("reasonForLeaving", response.reasonForLeaving);
          fd.append("uniformReturned", response.uniformReturned);
          fd.append("paymentInLieu", response.paymentInLieu);
          fd.append("otherNotes", response.otherNotes);
          this.saveData(false, null, true, fd, true, true);
        } else {
          this.form.get("statusId").setValue(this.employeeStatusId);
        }
      });
  }

  private openLeftWindow() {
    this.subscription.add(
      this.dialogService
        .open(EmployeeLeavingLeftDialogComponent, {
          closeOnBackdropClick: false,
          context: {
            isLeft: true,
            employeeId: this.employeeId,
          },
        })
        .onClose.subscribe((response: any) => {
          if (response) {
            const data = {
              dateLeft: response.dateLeft,
              isLeft: response.isLeft,
              proceedTogether: true,
              noticeDate: response.noticeDate,
              otherNotes: response.otherNotes,
              paymentInLieu: response.paymentInLieu,
              reasonForLeaving: response.reasonForLeaving,
              uniformReturned: response.uniformReturned,
              careHomeId: this.authUserService.getCareHomeId(),
              employeeId: this.employeeId,
            };

            this.subscription.add(
              this.employeeService
                .updateDismissalProcess(this.employeeId, data)
                .subscribe(
                  (res: any) => {
                    this.toastrService.success(res.message, "Success");
                    this.ngOnInit();
                    this.refresh("leaving");
                  },
                  (err) => {
                    this.toastrService.danger(getErrorMessage(err), "Error", {
                      duration: 60000,
                      destroyByClick: true,
                    });
                  },
                ),
            );
          } else {
            this.doNotShowLeavingLeftModal = true;
            this.form.get("statusId").setValue(this.employeeStatusId);
          }
        }),
    );
  }

  toggleStudentLoan(value) {
    this.studentLoan = this.form.get("studentLoan").value;
    // this.form.get('studentLoan').setValue(value);
    if (!value) {
      this.form.patchValue({ studentLoanTypeId: null });
      this.form.get("studentLoanTypeId").disable();
    } else {
      this.form.get("studentLoanTypeId").enable();
    }
  }

  togglehideMobileTelephoneNumber(value: boolean) {
    this.hideMobileTelephoneNumber = value;
    this.form.get("hideMobileTelephoneNumber").setValue(value);
  }

  togglehideTelephoneNumber(value: boolean) {
    this.hideTelephoneNumber = value;
    this.form.get("hideTelephoneNumber").setValue(value);
  }

  togglehideEmail(value: boolean) {
    this.hideEmail = value;
    this.form.get("hideEmail").setValue(value);
  }

  toggleAgency(value: boolean) {
    this.isAgency = value;
    this.form.get("isAgency").setValue(value);
  }

  getWord(wordName: string) {
    const leftWord = this.statuses.find(
      (element: any) => element.wordFullName == wordName,
    );
    return leftWord ? leftWord.id : null;
  }

  saveEmployee(needCloseWindow = true) {
    this.saveData(needCloseWindow, null, true);
  }

  saveData(
    closeWindow: boolean = true,
    dateToCantractCancelationDueToLeft: any = null,
    saveFromButton: any = false,
    customFormData = null,
    returnToPreviousStatus = false,
    isLeavingError = false,
    lastTab = "",
  ) {
    if (this.userProfileRoles) {
      this.saveMultiSiteRoles();
    }

    let fd = new FormData();
    if (customFormData) {
      fd = customFormData;
    }
    fd.append("avatarImage", this.form.get("avatar").value);
    fd.append("levelId", this.form.get("levelId").value);
    fd.append("firstName", this.form.get("firstName").value);
    fd.append("surname", this.form.get("surname").value);
    fd.append("statusId", this.form.get("statusId").value);
    fd.append(
      "dateOfBirth",
      moment(this.form.get("dateOfBirth").value).toISOString(),
    );
    fd.append(
      "nationalInsuranceNumber",
      this.form.get("nationalInsuranceNumber").value,
    );
    fd.append("passportNumber", this.form.get("passportNumber").value);
    fd.append("title", this.form.get("title").value);
    fd.append("gender", this.form.get("gender").value);
    fd.append("maritalStatus", this.form.get("maritalStatus").value);
    fd.append("phoneNumber", this.form.get("phoneNumber").value);
    fd.append("secondPhoneNumber", this.form.get("secondPhoneNumber").value);
    fd.append("email", this.form.get("email").value);
    fd.append("receiveEmail", this.form.get("receiveEmail").value);
    fd.append(
      "hideTelephoneNumber",
      this.form.get("hideTelephoneNumber").value,
    );
    fd.append(
      "hideMobileTelephoneNumber",
      this.form.get("hideMobileTelephoneNumber").value,
    );
    fd.append("hideEmail", this.form.get("hideEmail").value);
    fd.append("street", this.form.get("street").value);
    fd.append("city", this.form.get("city").value);
    fd.append("state", this.form.get("state").value);
    fd.append("zipCode", this.form.get("zipCode").value);
    fd.append("houseNumber", this.form.get("houseNumber").value);
    fd.append("emergencyNameFirst", this.form.get("emergencyNameFirst").value);
    fd.append(
      "emergencyRelationFirst",
      this.form.get("emergencyRelationFirst").value,
    );
    fd.append(
      "emergencyPhoneFirst",
      this.form.get("emergencyPhoneFirst").value,
    );
    fd.append(
      "emergencyNameSecond",
      this.form.get("emergencyNameSecond").value,
    );
    fd.append(
      "emergencyRelationSecond",
      this.form.get("emergencyRelationSecond").value,
    );
    fd.append(
      "emergencyPhoneSecond",
      this.form.get("emergencyPhoneSecond").value,
    );
    fd.append("bankAccountNumber", this.form.get("bankAccountNumber").value);
    fd.append("bankSortCode", this.form.get("bankSortCode").value);
    fd.append(
      "buildingSocietyRoutingNumber",
      this.form.get("buildingSocietyRoutingNumber").value,
    );
    fd.append("description", this.form.get("description").value);
    fd.append("studentLoan", this.form.get("studentLoan").value);
    fd.append("studentLoanTypeId", this.form.get("studentLoanTypeId").value);
    fd.append(
      "employeeStatementId",
      this.form.get("employeeStatementId").value,
    );
    fd.append("p45Id", this.form.get("p45Id").value);
    fd.append("careHomeIdForTask", this.authUserService.getCareHomeId() + "");
    fd.append("isAgency", this.form.get("isAgency").value + "");

    this.subscription.add(
      this.employeeService.updateEmployee(this.employeeId, fd).subscribe(
        (response: any) => {
          this.toastrService.success(response.message, "Success");
          if (saveFromButton) {
            if (
              this.needShowComponentForStatusWord("Leaving") ||
              this.needShowComponentForStatusWord("Left")
            ) {
              this.tableService.closeWindow(!this.noReload);
              this.tableService.openWindowWithBackStack(
                EmployeeLeftComponent,
                `Employee left request - ${this.form.get("firstName").value} ${
                  this.form.get("surname").value
                }`,
                {
                  component: EditEmployeeComponent,
                  title: "Employee Profile Information",
                  elementId: this.employeeId,
                  activeTab: EmployeeTab.INFO,
                },
                {
                  elementId: this.employeeId,
                  employeeName: `${this.form.get("firstName").value} ${
                    this.form.get("surname").value
                  }`,
                },
              );
            } else if (
              this.lastTab === "leaving" &&
              (this.form.get("statusId").value === this.getWord("Leaving") ||
                this.form.get("statusId").value === this.getWord("Left"))
            ) {
              this.employeeLeftService.closeWindow = closeWindow;
              this.employeeLeftService.saveData$.next(true);
            } else {
              if (closeWindow) {
                this.tableService.closeWindow(!this.noReload);
              }
              this.formChanged = false;
            }
          } else {
            if (closeWindow) {
              this.tableService.closeWindow(!this.noReload);
            }
            this.formChanged = false;
            this.getEmployeeData();
          }
          this.lastTab = lastTab;
        },
        (err) => {
          if (!isLeavingError) {
            this.doNotShowLeavingLeftModal = true;
          }
          if (returnToPreviousStatus) {
            this.form.get("statusId").setValue(this.employeeStatusId);
          }
          this.toastrService.danger(getErrorMessage(err), "Error", {
            duration: 60000,
            destroyByClick: true,
          });
        },
      ),
    );
  }

  downloadNspf01 = () => {
    this.subscription.add(
      this.employeeService
        .downloadNspf01(this.employeeId)
        .subscribe((response: any) => {
          window.open(getServerLink(response.link), "_blank");
        }),
    );
  };

  deleteEmployee() {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: "Delete confirmation",
          message: "Are you sure you want to delete? It cannot be undone.",
          deleteInput: true,
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          this.subscription.add(
            this.employeeService.deleteEmployee(this.employeeId).subscribe(
              (response: any) => {
                this.toastrService.success(response.message, "Success");
                this.tableService.closeWindow(!this.noReload);
              },
              (err) => {
                this.toastrService.danger(getErrorMessage(err), "Error", {
                  duration: 60000,
                  destroyByClick: true,
                });
              },
            ),
          );
        }
      });
  }

  cancelLeavingEvent(event: any) {
    this.tableService.openWindowWithBackStack(
      CancelEventDialogComponent,
      `Cancel event - ${event.eventNo}`,
      {
        component: EditEmployeeComponent,
        title: "Employee Profile Information",
        elementId: this.employeeId,
        activeTab: EmployeeTab.INFO,
      },
      {
        type:
          event.eventTypeShort === "AL"
            ? EmployeeElementType.AL
            : EmployeeElementType.SICK,
        elementId: event.eventId,
        goBackAfterSave: true,
      },
    );
  }

  onTabChange(event: any) {
    this.isMultiSiteRolesTab = event.tabTitle.toUpperCase() === "MULTI SITE";

    this.testButtonActive = event.tabTitle.toUpperCase() == "ROTA";

    if (event.tabTitle == "Messages") {
      this.activeTab = EmployeeTab.MESSAGES;
    }

    if (this.lastTab === "leaving") {
      this.employeeLeftService.saveData$.next(true);
    }
    const tabTitle: string = event.tabTitle + "";
    this.refresh(tabTitle.toLowerCase());
    if (this.formChanged) {
      this.saveData(
        false,
        null,
        true,
        null,
        false,
        false,
        tabTitle.toLowerCase(),
      );
    } else {
      this.lastTab = tabTitle.toLowerCase();
    }
  }

  uploadDocumentDescription(event: any) {
    this.subscription.add(
      this.employeeService.addDocumentDescription(event.uid, event).subscribe(
        (response: any) => {
          this.toastrService.success(response.message, "Success");
        },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), "Error", {
            duration: 60000,
            destroyByClick: true,
          });
        },
      ),
    );
  }

  editUserAccount() {
    this.tableService.openWindowWithBackStack(
      EditUserComponent,
      `User Profile Information`,
      {
        component: EditEmployeeComponent,
        title: "Employee Profile Information",
        elementId: this.employeeId,
        activeTab: EmployeeTab.INFO,
      },
      {
        elementId: this.employeeUserId,
        isEditEmployee: true,
      },
    );
  }

  changeEventToggle(eventType: string) {
    const sicknessEvent = this.form.get("isSicknessEventOnly");
    const alEvent = this.form.get("isAlEventOnly");
    let finalEventType = eventType;

    if (eventType === "Sick") {
      if (alEvent.value) {
        this.blockChange = true;
        alEvent.setValue(false);
      }
    } else {
      if (sicknessEvent.value) {
        this.blockChange = true;
        sicknessEvent.setValue(false);
      }
    }

    if (!sicknessEvent.value && !alEvent.value) {
      finalEventType = "";
    }

    this.eventTypeSort = finalEventType;

    this.refresh("events");
  }

  resetPassword(isPortableUser: boolean) {
    const platform = isPortableUser ? "Portable" : "Desktop";

    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: "Are you sure?",
          message:
            "This action will generate new password and send invitation for user to Echo " +
            platform,
        },
      })
      .onClose.subscribe((decision: any) => {
        if (decision) {
          const data = {
            userId: this.employeeUserId,
            isPortableUser: isPortableUser,
            forceChangePassword: true,
          };
          this.subscription.add(
            this.employeeService
              .resetPasswordAndSendInvitation(this.employeeId, data)
              .subscribe(
                (response: any) => {
                  this.toastrService.success(response.message, "Success");
                },
                (err) => {
                  this.toastrService.danger(getErrorMessage(err), "Error", {
                    duration: 60000,
                    destroyByClick: true,
                  });
                },
              ),
          );
        }
      });
  }

  createUserAccount() {
    if (this.form.get("statusId").value !== this.getWord("Left")) {
      if (
        this.form.get("email").value &&
        this.form.get("email").value.length > 0 &&
        this.form.get("email").valid
      ) {
        this.saveEmployee(false);
        const name = this.form.get("firstName").value;
        const surname = this.form.get("surname").value;
        this.tableService.openWindowWithBackStack(
          NewUserComponent,
          `Connect with user account - ${this.form.get("firstName").value} ${
            this.form.get("surname").value
          }`,
          {
            component: EditEmployeeComponent,
            title: "Employee Profile Information",
            elementId: this.employeeId,
            activeTab: EmployeeTab.INFO,
          },
          {
            elementId: this.employeeId,
            login: `${name}.${surname}`.toLowerCase(),
            email: this.form.get("email").value,
            phoneNumber: this.form.get("phoneNumber").value,
            accountType: "user",
            firstName: name,
            surname: surname,
            defaultCareHome: this.careHomeId,
            defaultPassword: `${moment(
              this.form.get("dateOfBirth").value,
            ).format("YYYYMMDD")}`,
            isPortableUser: true,
          },
        );
      } else {
        this.toastrService.warning(
          "To set up a user account, enter the valid email address of the employee",
          "Warning",
          { duration: 60000, destroyByClick: true },
        );
      }
    } else {
      this.toastrService.warning(
        "To do this action you must be in active status",
        "Error",
        { duration: 60000, destroyByClick: true },
      );
    }
  }

  uploadDocument(eventFile: any) {
    if (
      eventFile.category &&
      eventFile.category.toLowerCase().includes("contract")
    ) {
      this.dialogService
        .open(QuestionDialogComponent, {
          closeOnBackdropClick: false,
          context: {
            title: "New contract document",
            message: "",
            okLabel:
              "This is a new contract or you are replacing an existing contract",
            secondOkLabel: "I am assigning a document to an existing contract",
            hideSecondOkButton: false,
          },
        })
        .onClose.subscribe((decision: any) => {
          if (decision.isConfirm) {
            this.tableService.openWindowWithBackStack(
              NewDocumentsComponent,
              decision.type == "first"
                ? "New Contract"
                : "Assign document to an existing contract",
              {
                component: EditEmployeeComponent,
                title: "Employee Profile Information",
                elementId: this.employeeId,
                activeTab: EmployeeTab.DOCUMENTS,
              },
              {
                categoryToAssign: eventFile.category,
                employeeId: this.employeeId,
                securityLevelId: this.employeeLevelId,
                notRota: true,
                decisionType: decision.type,
                activeOnly:
                  this.activeContractsTableFilter == TableFilter.ACTIVE_ONLY,
                documentToAssign: eventFile,
                tests: true,
              },
            );
          }
        });
    } else {
      const fd = new FormData();
      fd.append("file", eventFile);
      fd.append("categoryId", eventFile.categoryId);
      this.subscription.add(
        this.employeeService.addEmployeeDocument(this.employeeId, fd).subscribe(
          (response: any) => {
            this.toastrService.success(response.message, "Success");
            this.refresh("documents");
            if (
              eventFile.category &&
              eventFile.category.courseName == "MULTIPLE TRAININGS"
            ) {
              this.goToCoursesOverview();
            } else if (eventFile.category && eventFile.category.courseId) {
              this.tableService.openWindowWithBackStack(
                CoursesChangeStatusComponent,
                "Courses instance",
                {
                  component: EditEmployeeComponent,
                  title: "Employee Profile Information",
                  elementId: this.employeeId,
                  activeTab: EmployeeTab.DOCUMENTS,
                },
                {
                  fromDocumentUploadMode: true,
                  data: [
                    {
                      employee: {
                        id: this.employeeId,
                        firstName: this.form.get("firstName").value,
                        surname: this.form.get("surname").value,
                      },
                      course: {
                        id: eventFile.category.courseId,
                        name: eventFile.category.courseName,
                      },
                    },
                  ],
                },
              );
            }
          },
          (err) => {
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
          },
        ),
      );
    }
  }

  goToCoursesOverview() {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: "Open next tab confirmation",
          message:
            "If you will click the OK button you will be redirected to the new tab with courses overview",
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          const newRelativeUrl = this.router.createUrlTree([
            "/employess/courses-overview",
          ]);
          const baseUrl = window.location.href.replace(this.router.url, "");
          window.open(baseUrl + newRelativeUrl, "_blank");
        }
      });
  }

  deleteDocument(fileUid: string) {
    this.subscription.add(
      this.employeeService
        .deleteEmployeeDocument(this.employeeId, fileUid)
        .subscribe(
          (response: any) => {
            this.toastrService.success(response.message, "Success");
            this.refresh("documents");
          },
          (err) => {
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
          },
        ),
    );
  }

  downloadDocument(fileUid: string) {
    this.subscription.add(
      this.employeeService
        .downloadEmployeeDocument(this.employeeId, fileUid)
        .subscribe(
          (response: any) => {
            window.open(getServerLink(response.link), "_blank");
          },
          (err) => {
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
          },
        ),
    );
  }

  closeWindow() {
    if (this.form.dirty) {
      this.subscription.add(
        this.dialogService
          .open(QuestionDialogComponent, {
            context: {
              title: "Edit employee",
              message: `It looks like you have been editing something. \nChanges you made may not be saved. \nDo you want to close dialog without saving?`,
              okLabel: "Yes",
              cancelLabel: "No",
            },
          })
          .onClose.subscribe((decision: boolean) => {
            if (decision) {
              if (this.tableService.getValue().closeButtonAdditional) {
                this.tableService.getValue().closeButtonAdditional();
              }
              this.subscription.unsubscribe();
              this.tableService.closeWindow(!this.noReload);
            }
          }),
      );
    } else {
      if (this.tableService.getValue().closeButtonAdditional) {
        this.tableService.getValue().closeButtonAdditional();
      }
      this.subscription.unsubscribe();
      this.tableService.closeWindow(!this.noReload);
    }
  }

  public setUserProfileRoles(e: UserProfileModel[]): void {
    this.userProfileRoles = e;
  }

  public openTrainingEditComponentWithSingleBackstack(
    checkDocuments: boolean,
    isEditMode: boolean = false,
    isTrainingBudgetInEditMode: boolean = false,
  ) {
    const employeeName: string = `${this.form.get("firstName").value} ${
      this.form.get("surname").value
    }`;
    const trainingTitle = isEditMode
      ? `Confirm ${employeeName} training dates & budget`
      : `Enter ${employeeName} training dates & budget`;
    if (!this.isMissingRequiredDocuments || !checkDocuments) {
      if (!isEditMode) {
        this.tableService.openWindowWithBackStack(
          EmployeeInTrainingComponent,
          trainingTitle,
          {
            component: EditEmployeeComponent,
            title: "Employee Profile Information",
            elementId: this.employeeId,
            activeTab: EmployeeTab.INFO,
          },
          {
            elementId: this.employeeId,
            statusId: this.form.get("statusId").value,
            employeeName: `${this.form.get("firstName").value} ${
              this.form.get("surname").value
            }`,
            isMultipleStackRequired: false,
            isEditMode: isEditMode,
            rotaFreezeDate: this.rotaFreezeDate,
            careHomeId: this.careHomeId,
            isTrainingBudgetInEditMode,
          },
        );
      } else {
        this.tableService.openWindowWithBackStack(
          EmployeeActivationChecklistComponent,
          "Activation Checklist",
          {
            component: EditEmployeeComponent,
            title: "Employee Profile Information",
            elementId: this.employeeId,
            activeTab: EmployeeTab.INFO,
          },
          {
            fromStatus: this.employeeStatusId,
            toStatus: this.form.get("statusId").value,
            elementId: this.employeeId,
            rotaFreezeDate: this.rotaFreezeDate,
            careHomeId: this.careHomeId,
          },
        );
      }
    } else {
      this.toastrService.danger(
        "Required documents are missing. Please upload all mandatory files and try again.",
        "Error",
        { duration: 60000, destroyByClick: true },
      );
      this.form.get("statusId").setValue(this.employeeStatusId);
    }
  }

  public isTrainingBudgetNegative(): boolean {
    return this.employee && this.employee.trainingBudget.includes("-");
  }

  public isUsedTrainingBudget(): boolean {
    return this.employee && this.employee.usedTrainingBudget.includes("-");
  }

  public isRemainingTrainingBudget(): boolean {
    return this.employee && this.employee.remainingTrainingBudget.includes("-");
  }

  ngOnDestroy() {
    this.employeeLeftService.reset();
    this.subscription.unsubscribe();
  }

  private getEmployeeData() {
    this.dataLoaded = false;
    this.subscription.add(
      this.employeeService.getEmployee(this.employeeId).subscribe({
        next: (response: any) => {
          this.employee = response.result;
          this.careHomeName = response.result.careHomeName;
          this.isDisableDFJ = response.result.disableDFJ;
          this.setDFJPlaceholder();
          this.updateValues(response.result);
          this.allowSecurityLevelEditing(response.result.levelId);
          this.employeeLevelId = response.result.levelId;
          this.refresh("documents");
          if (response.result.isInLeaversReport) {
            this.showReactivateInfo();
          }
        },
        error: (err) => {
          this.toastrService.danger(getErrorMessage(err), "Error", {
            duration: 60000,
            destroyByClick: true,
          });

          this.tableService.closeWindow(false);
        },
      }),
    );
  }

  showReactivateInfo() {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          hideOkButton: true,
          cancelLabel: "Close",
          title: "Please contact Finance and Tech to reactivate this person",
          message:
            "This has to be discussed with finance to check if end-of-employment deductions were made. Please contact Finance and Tech to reactivate this person.",
        },
      })
      .onClose.subscribe((decision: boolean) => {});
  }

  private allowSecurityLevelEditing(employeeLevel: any) {
    const userLevel = parseInt(this.permissionsService.getUserLevel(), 10);
    const foundEmployeeLevel = this.securityLevels.find((level: any) => {
      return level.id === employeeLevel;
    });
    // employee have set security level
    if (foundEmployeeLevel) {
      const employeeLevelToCompare = parseInt(foundEmployeeLevel.value, 10);
      if (userLevel === 1000) {
        return;
      } else if (userLevel > employeeLevelToCompare) {
        this.securityLevels = this.securityLevels.filter((level: any) => {
          return userLevel > parseInt(level.value, 10);
        });
      } else {
        this.form.get("levelId").disable();
      }
    } else {
      // employee do not have set security level so you can set level that is one level down than your level
      if (userLevel === 1000) {
        return;
      } else {
        this.securityLevels = this.securityLevels.filter((level: any) => {
          return userLevel > parseInt(level.value, 10);
        });
      }
    }
  }

  private setCurrentStatusForUser() {
    this.form.get("statusId").setValue(this.employeeStatusId);
  }

  private isChangeToActiveStatus() {
    return (
      this.employeeStatusId != this.getWord("Active") &&
      this.compareStatusWithValue("Active")
    );
  }

  private isChangeToActiveWithoutTrainingStatus() {
    return (
      this.employeeStatusId != this.getWord("Other � NT") &&
      this.compareStatusWithValue("Other � NT")
    );
  }

  private isChangeFromCreatedToInTraining() {
    return (
      this.employeeStatusId == this.getWord("Created") &&
      this.compareStatusWithValue("In Training")
    );
  }

  private isReturnFromTraining() {
    return (
      this.getWord("In Training") === this.employeeStatusId &&
      this.employeeStatusId != this.form.get("statusId").value
    );
  }

  private isReturnFromLeft() {
    return (
      this.getWord("Left") === this.employeeStatusId &&
      this.employeeStatusId != this.form.get("statusId").value
    );
  }

  private isPickedLeavingStatus(value: any) {
    return this.compareStatusWithValue("Leaving");
  }

  private isPickedLeftStatus(value: any) {
    return value === this.getWord("Left");
  }

  private isPickedLTSickStatus(value: any) {
    return this.compareStatusWithValue("LT Sick");
  }

  private needShowComponentForStatusWord(statusWord: string) {
    return (
      this.employeeStatusId != this.form.get("statusId").value &&
      this.form.get("statusId").value === this.getWord(statusWord)
    );
  }

  private compareStatusWithValue(statusWord: string) {
    return this.form.get("statusId").value === this.getWord(statusWord);
  }

  private setDFJPlaceholder() {
    this.DFJplaceholder = this.isDisableDFJ
      ? "Add contract to see DFJ"
      : "DD/MM/YYYY";
  }

  private saveMultiSiteRoles(): void {
    this.userProfileRoles = this.userProfileRoles.map((item) => {
      if (item.wordFullName === "Multi site worker") {
        item.careHomes = this.multiSiteWorkerControl.value;
        return item;
      } else if (item.wordFullName === "Multi site scheduler") {
        item.careHomes = this.multiSiteSchedulerControl.value;
        return item;
      }
      return item;
    });

    this.profilesService
      .updateUserProfiles(this.userId, this.userProfileRoles)
      .subscribe(
        (response: any) => {
          this.toastrService.success(response.message, "Success");
        },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), "Error", {
            duration: 60000,
            destroyByClick: true,
          });
        },
      );
  }

  private _calculateRotaConfirmedHrs(
    confirmedHrs: number,
    confirmedMin: number,
  ): string {
    const sumConfirmedTime = (confirmedHrs + confirmedMin / 60)
      .toFixed(2)
      .split(".");
    const sumHrs = parseInt(sumConfirmedTime[0], 10);
    const sumMin = parseInt(sumConfirmedTime[1], 10);

    return `${sumHrs}${sumMin ? `:${(sumMin * 0.6).toFixed(0)}` : ""}`;
  }

  openTestShiftMigration() {
    this.tableService.openWindowWithBackStack(
      NewDocumentsComponent,
      "Migration system for Shifts",
      {
        component: EditEmployeeComponent,
        title: "Employee Profile Information",
        elementId: this.employeeId,
        activeTab: EmployeeTab.SCHEDULE,
      },
      {
        employeeId: this.employeeId,
        notRota: false,
      },
    );
  }

  openErrorTab(formValid: boolean) {
    if (formValid) {
      this.tabset.selectTab(this.documentsTab);
    } else {
      this.tabset.selectTab(this.informationTab);
    }
  }
}
