<div class="form-view">
  <main>
    <form [formGroup]="form" autocomplete="off">
      <div class="switch-style">
        <ngx-switcher
          [firstValue]="false"
          [secondValue]="true"
          firstValueLabel="This is a shift I want to assign to a specific staff member"
          secondValueLabel="This is an unfilled shift I want to advertise on Echo"
          [value]="false"
          (valueChange)="toogleSwitcher($event)">
        </ngx-switcher>
      </div>
      <div class="form-control-group search-dropdown" *ngIf="source !== 'Extras'">
        <label class="label" for="input-employeeName">Absent Employee:</label>
        <input nbInput
                fullWidth
                formControlName="employeeName"
                id="input-employeeName"
                placeholder="Employee">
      </div>
      <div class="flex-container-horizontal">
        <div class="form-control-group search-dropdown" *ngIf="!toBeTakenByEmployee">
          <label class="label">{{ source !== 'Extras' ? 'Replacement' : null }} Employee</label>
          <ng-select [items]="employees"
                    #select
                    bindLabel="fullName"
                    dropdownPosition="auto"
                    bindValue="employeeId"
                    labelForId="replacementId"
                    placeholder="Search replacement employee"
                    [virtualScroll]="true"
                    formControlName="replacementId">
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
              <span>{{ item.employeeFirstName }} {{ item.employeeSurname }} ({{ item.employeeId }})</span>
            </ng-template>
          </ng-select>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'replacementId', errorMessages) }}
          </p>
        </div>
        <div class="form-control-group search-dropdown">
          <label class="label" for="input-role">{{ source !== 'Extras' ? 'Replacement' : null }} Role:</label>
          <ng-select [items]="roles"
                    #select
                    [clearable]="false"
                    bindLabel="roleName"
                    dropdownPosition="auto"
                    bindValue="id"
                    labelForId="replacementRole"
                    placeholder="Role"
                    [virtualScroll]="true"
                    [(ngModel)]="roleId"
                    formControlName="replacementRole">
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
              <span>{{ item.roleName }}</span>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div *ngIf="!toBeTakenByEmployee" class="flex-container-horizontal">
        <div class="form-control-group">
          <label class="label" for="input-replacement-from">{{ source !== 'Extras' ? 'Replacement' : null }} From:</label>
          <input nbInput
                  fullWidth
                  formControlName="replacementFrom"
                  id="input-replacement-from"
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="replacementFromDatepicker"
                  autofocus>
                <mat-datepicker-toggle matSuffix [for]="replacementFromDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #replacementFromDatepicker startView="month"></mat-datepicker>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'replacementFrom', errorMessages) }}
          </p>
        </div>
        <div class="form-control-group">
          <label class="label" for="input-replacement-to">{{ source !== 'Extras' ? 'Replacement' : null }} To:</label>
          <input nbInput
                  fullWidth
                  formControlName="replacementTo"
                  id="input-replacement-to"
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="replacementToDatepicker"
                  autofocus>
                <mat-datepicker-toggle matSuffix [for]="replacementToDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #replacementToDatepicker startView="month"></mat-datepicker>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'replacementTo', errorMessages) }}
          </p>
        </div>
      </div>
      <div *ngIf="toBeTakenByEmployee" class="flex-container-horizontal">
        <div class="form-control-group" style="min-width: initial;">
          <label class="label">Date:</label>
          <input nbInput
                  fullWidth
                  formControlName="scheduleDate"
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="scheduleFromDatepicker"
                  autofocus>
                <mat-datepicker-toggle matSuffix [for]="scheduleFromDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #scheduleFromDatepicker startView="month"></mat-datepicker>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'scheduleDate', errorMessages) }}
          </p>
        </div>
        <div class="flex-container-horizontal">
          <div class="form-control-group" style="min-width: initial;">
            <label class="label">Scheduled from:</label>
            <input nbInput
                    formControlName="scheduleFrom"
                    placeholder="00:00"
                    minlength="1"
                    maxlength="50"
                    (focusout)="utils.onHoursInput($event, form, 'scheduleFrom')"
                    autofocus>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'scheduleFrom', errorMessages) }}
            </p>
          </div>
          <div class="form-control-group" style="min-width: initial;">
            <label class="label">Scheduled to:</label>
            <input nbInput
                    formControlName="scheduleTo"
                    placeholder="00:00"
                    minlength="1"
                    maxlength="50"
                    (focusout)="utils.onHoursInput($event, form, 'scheduleTo')"
                    autofocus>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'scheduleTo', errorMessages) }}
            </p>
          </div>
        </div>
      </div>
      <div class="form-control-group textarea-container">
        <label class="label" for="input-comments">Comments:</label>
        <textarea nbInput
                  fullWidth
                  formControlName="comments"
                  maxlength="5000"
                  placeholder="Comments"
                  autofocus>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'comments', errorMessages) }}
          </p>
        </textarea>
      </div>
    </form>
  </main>
  <footer>
    <button class="btn btn-success"
            [disabled]="!utils.isFormValid(form) || !isCorrectDate || !canSave"
            (click)="saveData()">
      <i class="nb-checkmark" aria-hidden="true"></i> Save
    </button>
    <button class="btn btn-danger"
            (click)="closeWindow(false)">
      <i class="nb-close" aria-hidden="true"></i> Close
    </button>
  </footer>
</div>
