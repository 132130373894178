<div class="form-view">
  <main>
    <form [formGroup]="form">
      <div class="flex-container">
        <div class="form-control-group">
          <label class="label" for="careHomeId">Set Care Home:</label>
          <select
            nbInput
            formControlName="careHomeId"
            name="careHomeId"
            id="careHomeId"
          >
            <option
              *ngFor="let careHome of careHomes"
              [ngValue]="careHome.careHomeId"
            >
              {{ careHome.careHomeFullName }}
            </option>
          </select>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'careHomeId', errorMessages) }}
          </p>
        </div>
        <div class="form-control-group">
          <label class="label" for="input-changeDate">Set effective migration date (only Mondays)</label>
          <input nbInput
                 id="input-changeDate"
                 fullWidth
                 formControlName="changeDate"
                 placeholder="DD/MM/YY"
                 [matDatepickerFilter]="myFilter"
                 [matDatepicker]="dateMigrationStartPicker"
                 minlength="3"
                 maxlength="50"
                 autofocus>
          <mat-datepicker-toggle matSuffix [for]="dateMigrationStartPicker"></mat-datepicker-toggle>
          <mat-datepicker #dateMigrationStartPicker startView="month"></mat-datepicker>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'changeDate', errorMessages) }}
          </p>
        </div>
      </div>
    </form>
  </main>
  <footer>
    <button class="btn btn-success"
            (click)="migrateEmployee()"
            [disabled]="!canSave || form.invalid">
      <i class="nb-checkmark" aria-hidden="true"></i> Save
    </button>
    <button class="btn btn-danger ml-4"
            (click)="closeWindow()">
      <i class="nb-close" aria-hidden="true"></i> Close
    </button>
  </footer>
</div>
