<div class="form-view">
  <nb-tabset (changeTab)="onTabChange($event)">
    <nb-tab tabTitle="Contract Overview">
     <main>
        <form [formGroup]="form" autocomplete="off">
          <div class="form-control-group flex">
            <div class="form-control-group">
              <label class="label" for="input-employeeName">Employee:</label>
              <input nbInput
                    fullWidth
                    formControlName="employeeName"
                    name="employeeName"
                    id="input-employeeName"
                    placeholder="Employee">
            </div>
            <div class="form-control-group">
              <label class="label" for="input-yal-days">AL days per year:</label>
              <input nbInput
                    fullWidth
                    formControlName="yearAlBase"
                    name="yearAlBase"
                    id="input-yal-days"
                    placeholder="year Al days">
            </div>
          </div>
          <div class="flex-container-horizontal">
            <div class="flex-container middle">
              <div class="form-control-group">
                <label class="label" for="input-positionId">Role Name:</label>
                <input nbInput
                        fullWidth
                        formControlName="positionId"
                        placeholder="Role Name">
              </div>
              <div class="form-control-group">
                <label class="label" for="input-contractStart">Contract Start Date:</label>
                <input nbInput
                        fullWidth
                        formControlName="contractStart"
                        name="contractStart"
                        id="input-contractStart"
                        placeholder="DD/MM/YYYY"
                        [matDatepicker]="contractStartDatepicker"
                        minlength="3"
                        maxlength="50">
                      <mat-datepicker-toggle matSuffix [for]="contractStartDatepicker"></mat-datepicker-toggle>
                      <mat-datepicker #contractStartDatepicker startView="month"></mat-datepicker>
              </div>
            </div>
            <div class="flex-container middle">
              <div class="form-control-group">
                <label class="label" for="input-hoursPerWeek">Hrs p/w:</label>
                <input nbInput
                        fullWidth
                        formControlName="hoursPerWeek"
                        name="hoursPerWeek"
                        id="input-hoursPerWeek"
                        placeholder="Hrs p/w"
                        minlength="1"
                        maxlength="50"
                        autofocus>
              </div>
              <div class="form-control-group">
                <ng-template [ngIf]="form.get('contractTypeId').value !== 'Bank' && form.get('contractTypeId').value !== 'Permanent'">
                  <label class="label" for="input-contractEnd">Contract End Date:</label>
                  <input nbInput
                          fullWidth
                          formControlName="contractEnd"
                          name="contractEnd"
                          id="input-contractEnd"
                          placeholder="DD/MM/YYYY"
                          [matDatepicker]="contractEndDatepicker"
                          minlength="3"
                          maxlength="50">
                        <mat-datepicker-toggle matSuffix [for]="contractEndDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #contractEndDatepicker startView="month"></mat-datepicker>
                </ng-template>
              </div>
            </div>
            <div class="flex-container middle">
              <div class="form-control-group">
                <label class="label" for="input-contractTypeId">Contract Type:</label>
                <input nbInput
                        fullWidth
                        formControlName="contractTypeId">
              </div>
              <div class="form-control-group">
                <ng-template [ngIf]="cancelled">
                  <label for="input-cancellation-date">Cancellation/rejection date:</label>
                  <input nbInput
                          fullWidth
                          formControlName="cancellationDate"
                          id="input-cancellation-date"
                          placeholder="DD/MM/YYYY"
                          [matDatepicker]="cancellationDatepicker"
                          autofocus>
                        <mat-datepicker-toggle matSuffix [for]="cancellationDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #cancellationDatepicker startView="month"></mat-datepicker>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="form-control-group textarea">
            <label class="label" for="comments">Description:</label>
            <textarea nbInput
                      fullWidth
                      formControlName="comments"
                      name="comments"
                      id="comments"
                      placeholder="Add notes about this contract"
                      maxlength="1000"
                      autofocus></textarea>
          </div>
          <ng-template [ngIf]="cancelled">
              <div class="form-control-group cancellation-container">
                <label for="input-cancellation-reason">Rejection reason:</label>
                <textarea nbInput
                          fullWidth
                          formControlName="cancellationReasons"
                          id="input-cancellation-reason"
                          placeholder="Rejection reason"
                          minlength="3"
                          maxlength="5000"
                          autofocus>
                </textarea>
                <p class="error-message">
                  {{ utils.handleValidationErrorMessage(form, 'cancellationReasons', errorMessages) }}
                </p>
              </div>
            </ng-template>
        </form>
      </main>
    </nb-tab>
    <nb-tab *ngIf="this.permissions.FINANCE_MANAGER" tabTitle="Finance">
      <main>
        <form [formGroup]="financeForm" autocomplete="off">
          <p id="actual-values">Add values:</p>
          <div class="flex-container-horizontal">
            <div class="flex-container middle">
              <div class="form-control-group">
                <label class="label">Valid from:</label>
                <input nbInput
                        fullWidth
                        formControlName="validFrom"
                        placeholder="DD/MM/YYYY"
                        [matDatepicker]="validFromDatepicker"
                        minlength="3"
                        maxlength="50">
                      <mat-datepicker-toggle matSuffix [for]="validFromDatepicker"></mat-datepicker-toggle>
                      <mat-datepicker #validFromDatepicker startView="month"></mat-datepicker>
                <p class="error-message">
                  {{ utils.handleValidationErrorMessage(financeForm, 'validFrom', errorMessages) }}
                </p>
              </div>
            </div>
            <div class="flex-container middle">
              <div class="form-control-group">
                <label class="label">Base rate of pay (£ph):</label>
                <input nbInput
                        fullWidth
                        formControlName="baseRateOfPay"
                        placeholder="base rate"
                        type="text"
                        onlyIntegerNumber = "true"
                        minlength="1"
                        maxlength="50"
                        autofocus>
                <p class="error-message">
                  {{ utils.handleValidationErrorMessage(financeForm, 'baseRateOfPay', errorMessages) }}
                </p>
              </div>
            </div>
          </div>
          <div class="form-control-group textarea">
            <label class="label" for="comments">Description:</label>
            <textarea nbInput
                      fullWidth
                      formControlName="comments"
                      placeholder="Add notes about this contract"
                      maxlength="1000"
                      autofocus></textarea>
          </div>
          <p id="actual-values" class="no-margin mt-4">Contract finance:</p>
          <div class="table-container">
            <angular2-smart-table
              [settings]="financeSettings"
              [source]="financeData"
              (deleteConfirm)="deleteFinanceItem($event)"
              (editConfirm)="editFinanceItem($event)"
            ></angular2-smart-table>
          </div>
        </form>
      </main>
    </nb-tab>
    <nb-tab tabTitle="Replacement" *ngIf="contractType !== 'Replacement'">
      <main>
        <angular2-smart-table
          [settings]="cancelled ? replacementSettingsCancelled : replacementSettingsNotCancelled"
          [source]="replacements"
          (deleteConfirm)="deleteReplacement($event)"
        ></angular2-smart-table>
        <div class="display-flex display-flex--end mt-4">
            <button class="btn btn-success"
                    (click)="addReplacement()"
                    *ngIf="!cancelled">
              <i class="nb-plus" aria-hidden="true"></i>
            </button>
          </div>
      </main>
    </nb-tab>
    <nb-tab tabTitle="Documents">
      <ngx-file-upload
      [files]="files"
      [isFileSendEnabled]="false"
      (onDelete)="deleteFile($event)"
      (onDownload)="downloadFile($event)">
    </ngx-file-upload>
    </nb-tab>
  </nb-tabset>
  <footer class="space-between">
    <div class="flex">
      <button class="btn btn-success"
              (click)="save()"
              [disabled]="!isValid()">
        <i class="nb-checkmark" aria-hidden="true"></i> Save
      </button>
      <button class="btn btn-danger ml-4"
              (click)="closeWindow()">
        <i class="nb-close" aria-hidden="true"></i> Close
      </button>
    </div>
    <div class="flex">
      <button class="btn btn-danger longer ml-4 display-flex--justify-center"
              (click)="deleteContract()">
        <i class="nb-e-commerce mr-2" aria-hidden="true"></i> Delete contract
      </button>
      <button *ngIf="!cancelled"
              class="btn btn-danger ml-4 display-flex--justify-center longer"
              (click)="cancelContract()">
        <i class="nb-trash" aria-hidden="true"></i> Cancel contract
      </button>
    </div>
  </footer>
</div>
