import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'charsLimit'})
export class CharsLimitPipe implements PipeTransform {
  transform(value: string, charsLimit: number) {
    let returnValue = value;
    if (value && value.length > charsLimit) {
      returnValue = returnValue.substring(0, charsLimit) + '...';
    }
    return returnValue;
  }
}
