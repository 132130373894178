<nb-card [ngStyle]="{'height': isLeft ? '729px' : '467px'}">
  <nb-card-header><span style="color: red;">WARNING&nbsp;</span> - switching employee to {{isLeft ? 'Left' : 'Leaving'}}</nb-card-header>
  <nb-card-body>
    <div [formGroup]="form" class="flex">
      <img src="./assets/images/danger-shield.png" alt="" style="margin-right: 1.25rem; margin-top: -0.5rem;"/>
      <div>
        <div class="form-control-group" style="width: 100%">
          <label class="label">Reason for leaving:</label><label class="label obligatory" title="This field is obligatory">*</label>
          <textarea nbInput
              fullWidth
              formControlName="reasonForLeaving"
              placeholder="reason for leaving"
              minlength="1"
              maxlength="500"
              rows="2"
              style="resize: none;"
              autofocus>
            </textarea>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'reasonForLeaving', errorMessages) }}
          </p>
        </div>
        <div *ngIf="isLeft" class="flex" style="width: 100%; justify-content: space-between;">
          <div style="width: 100%; height: 69px; margin-right: 0.5rem;">
            <p class="margin-al-date">Enter notice date:</p>
            <input nbInput
                  fullWidth
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="picker"
                  formControlName="noticeDate"
                  minlength="3"
                  maxlength="50"
                  autofocus>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker startView="month"></mat-datepicker>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'noticeDate', errorMessages) }}
            </p>
          </div>
          <div style="width: 100%; height: 69px; margin-left: 0.5rem">
            <p class="margin-al-date">Enter left date:</p>
            <input nbInput
                  fullWidth
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="leftPicker"
                  formControlName="dateLeft"
                  [min]="pickedNoticeDate"
                  minlength="3"
                  maxlength="50"
                  autofocus>
                <mat-datepicker-toggle matSuffix [for]="leftPicker"></mat-datepicker-toggle>
                <mat-datepicker #leftPicker startView="month"></mat-datepicker>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'dateLeft', errorMessages) }}
            </p>
          </div>
          
        </div>
        <p *ngIf="isLeft" [innerHTML]="dateLeftText" class="info"></p>
        <div class="flex">
          <div class="form-control-group" [ngStyle]="{'width': isLeft ? '100%' : '340px'}">
            <label class="label">Uniform returned:</label><label class="label obligatory" title="This field is obligatory">*</label>
            <input nbInput
                    fullWidth
                    formControlName="uniformReturned"
                    placeholder="Uniform returned"
                    minlength="1"
                    maxlength="50"
                    autofocus>
          </div>
          <nb-checkbox *ngIf="!isLeft" formControlName="uniformReturnedToCome" (change)="setToComeStatus($event.target.checked, 'uniformReturned')" class="to-come">to come</nb-checkbox>
        </div>
        <div class="flex">
          <div class="form-control-group" [ngStyle]="{'width': isLeft ? '100%' : '340px'}">
            <label class="label">Has a payment in lieu been agreed:</label><label class="label obligatory" title="This field is obligatory">*</label>
            <input nbInput
                    fullWidth
                    formControlName="paymentInLieu"
                    placeholder="Has a payment in lieu been agreed"
                    minlength="1"
                    maxlength="50"
                    autofocus>
          </div>
          <nb-checkbox *ngIf="!isLeft" formControlName="paymentInLieuToCome" (change)="setToComeStatus($event.target.checked, 'paymentInLieu')" class="to-come">to come</nb-checkbox>
        </div>
        <div class="flex">
          <div class="form-control-group" [ngStyle]="{'width': isLeft ? '100%' : '340px'}">
            <label class="label">Any other notes on their exit for Finance:</label><label class="label obligatory" title="This field is obligatory">*</label>
            <input nbInput
                  fullWidth
                  formControlName="otherNotes"
                  placeholder="Any other notes on their exit for Finance"
                  minlength="1"
                  maxlength="500"
                  autofocus>
          </div>
          <!-- <p class="error-message">
              {{ utils.handleValidationErrorMessageForGeneratedFields(form, 'otherNotes', 'otherNotes', errorMessages) }}
          </p> -->
          <nb-checkbox *ngIf="!isLeft" formControlName="otherNotes" (change)="setToComeStatus($event.target.checked, 'otherNotes')" class="to-come">to come</nb-checkbox>
        </div>
        <div *ngIf="isLeft">
          <p style="color: red; max-width: 450px; margin-top: 0.75rem; margin-bottom: 0.75rem; font-size: 15px;">Setting them to Left will cancel all AL and shifts and other entries after this date.  
            It cannot be undone. Only set this status if you are sure.</p>
          <p class="mt-2">Type <b>CONFIRM</b> to change status.</p>
          <div class="form-control-group" style="width: 100%;">
            <input nbInput
                    fullWidth
                    formControlName="confirmation"
                    minlength="3"
                    maxlength="20"
                    autofocus>
          </div>
          <p style="color: red; font-weight: 600;">{{rotaFreezeMessage}}</p>
          <p *ngIf="!isLeft" class="mt-3">You will have <b>2 weeks</b> to complete the leavers report for this employee. Otherwise the weekly report will be blocked.</p>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button [disabled]="!utils.isFormValid(form) || !isDeleteEntered(form)" class="btn btn-success mr-2" (click)="ok()">Confirm</button>
    <button class="btn btn-danger" (click)="cancel()">Close</button>
  </nb-card-footer>
</nb-card>