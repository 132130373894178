<div class="form-view">
  <main>
    <form [formGroup]="form">
      <div class="form-control-group">
        <label class="label">Target care home:</label>
        <select formControlName="targetCareHomeId">
          <option *ngFor="let careHome of careHomes" [ngValue]="careHome.careHomeId">
            {{ careHome.careHomeFullName }}
          </option>
        </select>
        <p class="error-message">
          {{ utils.handleValidationErrorMessage(form, 'targetCareHomeId', errorMessages) }}
        </p>
      </div>
      <div class="form-control-group">
        <label class="label">Target taskboard:</label>
        <select formControlName="targetTaskBoardId">
          <option *ngFor="let taskboard of taskboards" [ngValue]="taskboard.id">
            {{ taskboard.boardName }}
          </option>
        </select>
        <p class="error-message">
          {{ utils.handleValidationErrorMessage(form, 'targetTaskBoardId', errorMessages) }}
        </p>
      </div>
      <div style="padding: 0 10px;">
        <nb-checkbox formControlName="withHistory">With history</nb-checkbox>
      </div>
    </form>
    <div class="loading-container" [nbSpinner]="showSpinner" nbSpinnerStatus="success"></div>
  </main>
  <footer>
    <button class="btn btn-success"
            [disabled]="!isFormValid(form) || saveClicked"
            (click)="saveData()">
      <i class="nb-checkmark" aria-hidden="true"></i> {{mode == CopyMode.COPY ? 'Copy' : 'Move'}}
    </button>
    <button (click)="closeWindow()"
            class="btn btn-danger ml-4">
      <i class="nb-close" aria-hidden="true"></i> Close
    </button>
  </footer>
</div>
