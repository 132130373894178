import { NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbDatepickerModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbIconModule,
  NbInputModule,
  NbAccordionModule,
  NbDialogModule,
  NbWindowModule,
  NbToastrModule,
  NbAlertModule,
  NbTooltipModule,
  NbListModule,
  NbRadioModule,
  NbSpinnerModule,
} from "@nebular/theme";

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
} from "./components";
import {
  AvatarPipe,
  DateFormatPipe,
  CharsLimitPipe,
  DateAfterOrEqualTo,
  RaggColumnBackground,
  IsTodayPipe,
  ScheduleConfirmationPhoto,
} from "./pipes";
import { MainLayoutComponent } from "./main-layout/main.layout";
import { NgSelectModule } from "@ng-select/ng-select";
import { OnlyIntegerNumberDirective } from "./directives/only-number.directive";
import { HourCharsDirective } from "./directives/hour-chars.directive";
import { AlFilterDirective } from "./directives/al-filter.directive";
import { DateTimeFormatPipe } from "./pipes";
import { DragDropFilesDirective } from "./directives/drag-drop.directive";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { DragDropNoBgFilesDirective } from "./directives/drag-drop-nobg.directive";
import { SliderSwitcherComponent } from "./components/slider-switcher/slider-switcher.component";
import { RouterModule } from "@angular/router";
import { MaxInputLengthDirective } from "./directives/max-input-length.directive";
import { MatMenuModule } from "@angular/material/menu";
import { CheckLeavePipe } from "./pipes/checkLeave.pipe";
import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { KeyboardScrollDirective } from "./directives/keyboardScroll.directive";
import { MessageToSupportComponent } from "./components/message-to-support/message-to-support.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { EditStuffComponent } from "../pages/stuff-config/edit-stuff/edit-stuff.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { MultipleAutocompleteSelectorWithCheckboxComponent } from "./components/multiple-autocomplete-selector-with-checkbox/multiple-autocomplete-selector-with-checkbox.component";
import { PagesSharedModule } from "../pages/shared/pages-shared.module";
import { AppMenuComponent } from "./components/app-menu/app-menu.component";
import { MatListModule } from "@angular/material/list";
import { MatExpansionModule } from "@angular/material/expansion";
import { SideMenuAccordionComponent } from "./components/app-menu/side-menu-accordion/side-menu-accordion.component";
import { SideMenuCollapsedPopupComponent } from "./components/app-menu/side-menu-collapsed-popup/side-menu-collapsed-popup.component";
import {
  SideMenuOptionsAccordionComponent
} from "./components/app-menu/side-menu-options-accordion/side-menu-options-accordion.component";
import {SignInOutReminderComponent} from "./components/sign-in-out-reminder/sign-in-out-reminder.component";

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NgSelectModule,
  RouterModule,
];

const NB_MODULES = [
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbDatepickerModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbInputModule,
  NbAccordionModule,
  NbDialogModule,
  NbWindowModule,
  NbToastrModule,
  NbAlertModule,
  NbTooltipModule,
  NbListModule,
  NbEvaIconsModule,
  NbIconModule,
  NbRadioModule,
  MatMenuModule,
  NbSpinnerModule,
];

const MATERIAL_MODULES = [
  MatCheckboxModule,
  MatIconModule,
  MatInputModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  OverlayModule,
  MatListModule,
  MatExpansionModule,
];

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  SliderSwitcherComponent,
  MainLayoutComponent,
  MessageToSupportComponent,
  EditStuffComponent,
  SideMenuAccordionComponent,
  SideMenuCollapsedPopupComponent,
  SignInOutReminderComponent
];

const PIPES = [
  AvatarPipe,
  DateFormatPipe,
  DateTimeFormatPipe,
  CharsLimitPipe,
  DateAfterOrEqualTo,
  RaggColumnBackground,
  IsTodayPipe,
  ScheduleConfirmationPhoto,
  CheckLeavePipe,
];

const DIRECTIVES = [
  OnlyIntegerNumberDirective,
  HourCharsDirective,
  AlFilterDirective,
  DragDropFilesDirective,
  DragDropNoBgFilesDirective,
  MaxInputLengthDirective,
  ClickOutsideDirective,
  KeyboardScrollDirective,
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot({
    name: "corporate",
  }).providers,
  { provide: LOCALE_ID, useValue: "en-Gb" },
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  ...NbDialogModule.forRoot().providers,
  ...NbWindowModule.forRoot().providers,
  ...NbToastrModule.forRoot().providers,
  ...NbDatepickerModule.forRoot().providers,
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES, ...MATERIAL_MODULES, SideMenuOptionsAccordionComponent],
  exports: [
    ...BASE_MODULES,
    ...NB_MODULES,
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES,
  ],
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES,
    MultipleAutocompleteSelectorWithCheckboxComponent,
    AppMenuComponent,
  ],
})
export class ThemeModule {
  static forRoot() {
    return {
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS],
    };
  }
}
