import {
  Component,
  Input,
  forwardRef,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";

@Component({
  selector: "ngx-multiple-autocomplete-selector-with-checkbox",
  templateUrl: "./multiple-autocomplete-selector-with-checkbox.component.html",
  styleUrls: ["./multiple-autocomplete-selector-with-checkbox.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(
        () => MultipleAutocompleteSelectorWithCheckboxComponent
      ),
    },
  ],
})
export class MultipleAutocompleteSelectorWithCheckboxComponent
  implements ControlValueAccessor, AfterViewInit
{
  @Input() disabled = false;
  public onChange(newVal: any) {}
  public onTouched(_?: any) {}
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  @Input() noSelectAll: boolean;
  @Input() label: string = "";
  @Input() placeholder: string = "";
  @Input() bindLabel: string = "fullName";
  @Input() dropdownPosition: string = "auto";
  @Input() secondBindLabel: string;
  @Input() useShortForm: boolean;
  @Input() hideSelectAll = false;
  @Input() valuesLimit: number;
  @Input() emitEmptyRemove = false;
  @Input() showSecondLabelToggle = false;
  @Input() showClear = true;
  @Input() autofocus = false;
  @Input() acceptColorsFromElements = false;
  @Input() groupBy = "";
  @Input() appendTo: string = "body";
  @Input() hideInnerX: boolean = false;
  @Input() filteredEmailsSentTo: {
    email: string;
    id: number;
    login: string;
    fullName: string;
  }[] = [];
  @Input() autoAddEmailRecipients: boolean = false;
  public selectAll: boolean = false;
  @Input()
  set selectAllToggle(value: boolean) {
    this.selectAll = value;
    this.toggleSelectAll(value);
  }
  @Input()
  public selectSome: boolean = false;
  @Input()
  set selectSomeToggle(value: boolean) {
    this.selectSome = this.autoAddEmailRecipients;
    this.toggleSelectSome(value);
  }
  public pickValues = [];
  @Input()
  set values(values: any[]) {
    this.pickValues = values;
    if (this.selectAll) {
      this.toggleSelectAll(this.selectAll);
    }
    if (this.selectSome) {
      this.toggleSelectSome(this.selectSome);
    }
  }
  public val = [];
  public showAdditionalLabel = false;

  @ViewChild("selector", { static: true }) ngSelect: NgSelectComponent;
  ngAfterViewInit() {
    if (this.filteredEmailsSentTo.length) {
      this.toggleSelectSome(true);
    }
    if (this.autofocus) {
      setTimeout(() => {
        this.ngSelect.open();
        this.ngSelect.close();
      }, 1000);
    }
  }

  ngOnChanges() {
    if (this.filteredEmailsSentTo.length) {
      console.log("this.filteredEmailsSentTo", this.filteredEmailsSentTo);
      this.toggleSelectSome(true);
    }
  }

  writeValue(value: any): void {
    this.val = value;
  }

  remove(event: any) {
    this.selectAll = false;
    if (this.emitEmptyRemove) {
      this.onChange(this.val);
    }
  }

  clear(event: any) {
    this.selectAll = false;
    this.onChange(event);
  }

  toggleSelectAll(value: boolean) {
    if (!this.disabled && this.pickValues && this.pickValues.length > 0) {
      this.selectAll = value;
      if (value) {
        if (this.valuesLimit) {
          this.val = this.pickValues.slice(0, this.valuesLimit);
        } else {
          this.val = this.pickValues;
        }
      } else {
        this.val = [];
      }
      this.onChange(this.val);
    }
  }
  toggleSelectSome(value: boolean) {
    if (!this.disabled && this.pickValues && this.pickValues.length > 0) {
      this.selectSome = value;
      if (value) {
        if (this.valuesLimit) {
          this.val = this.filteredEmailsSentTo.slice(0, this.valuesLimit);
        } else {
          this.val = this.filteredEmailsSentTo;
        }
      } else {
        this.val = [];
      }
      this.onChange(this.val);
    }
  }
  toggleAdditionalLabel(value: boolean) {
    this.showAdditionalLabel = value;
  }

  checkModel() {
    if (this.valuesLimit) {
      if (this.val.length > this.valuesLimit) {
        this.val = this.val.slice(0, this.valuesLimit);
      }
    }
  }

  getValueToInput(main, secondBindLabel, second) {
    return decodeURIComponent(main + (secondBindLabel ? `, ${second}` : ''));
  }
}
