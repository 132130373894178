import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  OnChanges,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource, MatTable } from "@angular/material/table";
import { NbPopoverDirective } from "@nebular/theme";
import { AvatarResource } from "../../../../utilities/avatar-resource.enum";
import { TableService } from "../table.service";
import { EditEmployeeComponent } from "../../../employess/edit-employee/edit-employee.component";
import { getFullName, getMinutes, getTime } from "../../../../utilities/utils";
import { EmployeeTab } from "../../../employess/employee-tab.enum";
import { debounceTime, takeUntil } from "rxjs/operators";
import { DictionariesAgencyWordsModel } from "../../../../shared/models/dictionaries/agencies/dictionaries-agencies-model";
import { IAgencyDetailedReportResponseLine } from "../../../../shared/models/agency-report/agency-report.models";

export enum TableFieldType {
  HOURS,
}

const DEBOUNCE_TIME = 500;

@Component({
  selector: "ngx-only-table",
  templateUrl: "./only-table.component.html",
  styleUrls: ["./only-table.component.scss"],
})
export class OnlyTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("table", { static: false }) table: MatTable<any>;

  @Input() data: any = [];
  @Input() columns: any = {};
  @Input() onRowClick: (event: any) => any;
  @Input() employeeDetailsTab: EmployeeTab = EmployeeTab.INFO;
  @Input() filter: BehaviorSubject<string> = new BehaviorSubject<string>("");
  @Input() thinner: boolean = false;
  @Input() totalRow: boolean = false;
  @Input() customTotal: { value: string; name: string } = {
    value: "",
    name: "",
  };
  @Input() displayedColumnsFinal: string[] = [];
  @Input() showIndexColumn: boolean = true;
  @Input() sortFromBackend: boolean = false;
  @Input() disableSorting: boolean = false;
  @Input() doubleHeader: boolean = false;
  @Input() doubleHeaderColumns: string;
  @Input() isAgencyReport: boolean;
  @Input() agencies: DictionariesAgencyWordsModel[];
  @Input() specifyRowColors = false;
  @Input() idMaps: any;
  @Output() textfieldChange = new EventEmitter();
  @Output() matSortChange = new EventEmitter();
  @Output() onTextAreaChange: EventEmitter<{
    element: any;
    value: string;
    index: number;
  }> = new EventEmitter();
  @Output() onAgencyChanged: EventEmitter<IAgencyDetailedReportResponseLine> =
    new EventEmitter<IAgencyDetailedReportResponseLine>();
  @ViewChild(NbPopoverDirective, { static: false }) popover;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public AvatarResource: typeof AvatarResource = AvatarResource;
  public displayedColumns: string[] = [];
  public dataSource: any;
  public id: number = 0;
  public tempTextfieldValue: string;
  public isTextFieldToBeRestored: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(protected tableService: TableService) {}

  ngOnInit() {
    this.filter
      .pipe(debounceTime(DEBOUNCE_TIME), takeUntil(this.destroy$))
      .subscribe((value: string) => {
        this.dataSource.filter = value.trim ? value.trim().toLowerCase() : "";
      });
    this.dataSource.sort = this.sort;
    if (this.sortFromBackend) {
      this.dataSource.sortData = (data, sort) => {};
    }

    this.tableService.resetSort$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.clearSort();
      });
  }

  ngOnChanges() {
    const columns = [];
    if (this.showIndexColumn) {
      columns.push("index");
    }

    for (const column of this.columns) {
      columns.push(column.fieldName);
    }
    // console.log('data', this.data)
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.filterPredicate = (order: any, filter: string) => {
      const transformedFilter = filter.trim().toLowerCase();

      const listAsFlatString = (obj): string => {
        let returnVal = "";

        Object.values(obj).forEach((val) => {
          if (typeof val !== "object") {
            returnVal = returnVal + " " + val;
          } else if (val !== null) {
            returnVal = returnVal + " " + listAsFlatString(val);
          }
        });

        return returnVal
          .trim()
          .toLowerCase()
          .replace(/ +(?= )/g, "");
      };

      return listAsFlatString(order).includes(transformedFilter);
    };

    this.displayedColumns = columns;
    // console.log(this.columns)
    // console.log(this.dataSource)

    if (this.sortFromBackend) {
      this.dataSource.sortData = (data, sort) => {};
    } else {
      this.dataSource.sort = this.sort;
    }
  }

  showEmployee360(
    event: any,
    employeeId: number,
    firstName: string,
    surname: string,
    fullName: string,
  ) {
    event.preventDefault();
    event.stopPropagation();
    this.tableService.openWindowWithoutCareHomeVerification(
      EditEmployeeComponent,
      `Employee Profile Information - ${
        fullName ? fullName : getFullName(firstName, surname)
      }`,
      { elementId: employeeId, activeTab: this.employeeDetailsTab },
    );
  }

  getSum(fieldName: any, fieldType: TableFieldType) {
    if (fieldType === TableFieldType.HOURS) {
      let minutesSum = 0;
      this.dataSource.filteredData.map((element: any) => {
        minutesSum += getMinutes(this.getTimeFromString(element[fieldName]));
      });
      return getTime(minutesSum);
    } else {
      return 0;
    }
  }

  trackByFn(index, item) {
    return index;
  }

  changeId(id: number) {
    this.id = id;
  }
  onTextFieldChange(event) {
    this.textfieldChange.emit(event);
    this.isTextFieldToBeRestored = false;
  }
  saveTextFieldContent(event) {
    this.tempTextfieldValue = event.target.value;
    this.isTextFieldToBeRestored = true;
  }
  restoreTextFieldContent(event) {
    if (!this.isTextFieldToBeRestored) return;
    event.target.value = this.tempTextfieldValue;
    this.isTextFieldToBeRestored = true;
  }

  public textAreaChange(element: any, value: string, index: number): void {
    this.onTextAreaChange.emit({ element, value, index });
  }

  public agencyChanged(
    agencyId: number,
    employee: IAgencyDetailedReportResponseLine,
    index: number,
  ): void {
    employee.agencyId = agencyId;
    employee.index = index;
    this.onAgencyChanged.emit(employee);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getTimeFromString(element: string) {
    const time = element.split("(");

    if (time.length > 0) {
      const timePart = time[0].trim();
      return timePart;
    }

    return "";
  }

  clearSort() {
    this.sort.sort({ id: "", start: "asc", disableClear: false });
  }
}
