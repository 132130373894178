import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { EmployeeTab } from '../../../../employess/employee-tab.enum';
import { BehaviorSubject } from 'rxjs';
import { DictionariesAgencyWordsModel } from '../../../../../shared/models/dictionaries/agencies/dictionaries-agencies-model';
import { IAgencyDetailedReportResponseLine } from '../../../../../shared/models/agency-report/agency-report.models';
import { NbPopoverDirective, NbToastrService } from '@nebular/theme';
import { MatSort } from '@angular/material/sort';
import { TableService } from '../../table.service';
import { debounceTime } from 'rxjs/operators';
import { EditEmployeeComponent } from '../../../../employess/edit-employee/edit-employee.component';
import { getFullName } from '../../../../../utilities/utils';
import { AvatarResource } from '../../../../../utilities/avatar-resource.enum';
import { ShiftStatus } from '../../../../employess/day-shifts/shifts-table-row/shift-status.enum';

const DEBOUNCE_TIME = 500;

@Component({
  selector: 'ngx-agency-report-only-table',
  templateUrl: './agency-report-only-table.component.html',
  styleUrls: ['../only-table.component.scss'],
})
export class AgencyReportOnlyTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('table', { static: false }) table: MatTable<any>;

  @Input() data: IAgencyDetailedReportResponseLine[] = [];
  @Input() columns: any = {};
  @Input() onRowClick: (event: any) => any;
  @Input() employeeDetailsTab: EmployeeTab = EmployeeTab.INFO;
  @Input() filter: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Input() thinner: boolean = false;
  @Input() totalRow: boolean = false;
  @Input() showIndexColumn: boolean = true;
  @Input() sortFromBackend: boolean = false;
  @Input() disableSorting: boolean = false;
  @Input() doubleHeader: boolean = false;
  @Input() doubleHeaderColumns: string;
  @Input() isAgencyReport: boolean;
  @Input() agencies: DictionariesAgencyWordsModel[];
  @Output() textfieldChange = new EventEmitter();
  @Output() matSortChange = new EventEmitter();
  @Output() onTextAreaChange: EventEmitter<{ element: any; value: string; index: number }> = new EventEmitter();
  @Output() onAgencyChanged: EventEmitter<IAgencyDetailedReportResponseLine> = new EventEmitter<IAgencyDetailedReportResponseLine>();

  @ViewChild(NbPopoverDirective, { static: false }) popover;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('otherNotesTextarea', { static: false }) otherNotesTextareaElementRef: ElementRef;
  public AvatarResource: typeof AvatarResource = AvatarResource;
  public ShiftStatus: typeof ShiftStatus = ShiftStatus;
  public displayedColumns: string[] = [];
  public dataSource: any;
  public id: number = 0;
  public isTextFieldToBeRestored: boolean = false;

  constructor(protected tableService: TableService, private toastrService: NbToastrService) {}

  ngOnInit() {
    this.filter.pipe(debounceTime(DEBOUNCE_TIME)).subscribe((value: string) => {
      this.dataSource.filter = value.trim ? value.trim().toLowerCase() : '';
    });
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    const columns = [];
    if (this.showIndexColumn) {
      columns.push('index');
    }

    for (const column of this.columns) {
      columns.push(column.fieldName);
    }
    // console.log('data', this.data)
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.filterPredicate = (order: any, filter: string) => {
      const transformedFilter = filter.trim().toLowerCase();

      const listAsFlatString = (obj): string => {
        let returnVal = '';

        Object.values(obj).forEach((val) => {
          if (typeof val !== 'object') {
            returnVal = returnVal + ' ' + val;
          } else if (val !== null) {
            returnVal = returnVal + ' ' + listAsFlatString(val);
          }
        });

        return returnVal
          .trim()
          .toLowerCase()
          .replace(/ +(?= )/g, '');
      };

      return listAsFlatString(order).includes(transformedFilter);
    };

    this.displayedColumns = columns;

    this.dataSource.sort = this.sort;
  }

  showEmployee360(event: any, employeeId: number, firstName: string, surname: string, fullName: string) {
    event.preventDefault();
    event.stopPropagation();
    this.tableService.openWindowWithoutCareHomeVerification(EditEmployeeComponent, `Employee Profile Information - ${fullName ? fullName : getFullName(firstName, surname)}`, {
      elementId: employeeId,
      activeTab: this.employeeDetailsTab,
    });
  }

  trackByFn(index) {
    return index;
  }

  onTextFieldChange(event) {
    this.textfieldChange.emit(event);
    this.isTextFieldToBeRestored = false;
  }

  public textAreaChange(element: any, value: string, index: number): void {
    this.onTextAreaChange.emit({ element, value, index });
  }

  public agencyChanged(agencyId: number, employee: IAgencyDetailedReportResponseLine, index: number): void {
    employee.agencyId = agencyId;
    employee.index = index;
    this.onAgencyChanged.emit(employee);
  }

  public setTextareaInEditMode(employee: IAgencyDetailedReportResponseLine) {
    if (!employee.isTextareaInEditMode) {
      this.data.forEach((item) => {
        item.isTextareaInEditMode = false;
      });
      employee.isTextareaInEditMode = true;
      setTimeout(() => {
        if (this.otherNotesTextareaElementRef) {
          this.otherNotesTextareaElementRef.nativeElement.focus();
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.filter) {
      this.filter.unsubscribe();
    }
  }
}
