<div class="table-fill">
  <div *ngIf="!isMessagesReport" class="head mt-3 mb-3">
    <button
      class="btn-icon"
      [style.margin]="'unset'"
      (click)="refreshPage()"
      matTooltip="Refresh"
      matTooltipPosition="above"
    >
      <nb-icon icon="refresh-outline"></nb-icon>
    </button>
    <!-- [disabled]='selectedMessages.length==0' -->
    <button
      class="btn-icon"
      [style.margin]="'unset'"
      *ngIf="!isSentMailbox"
      (click)="changeMessageCategory(486, 'Archived')"
    >
      <nb-icon
        icon="archive-outline"
        matTooltip="Move to archive"
        matTooltipPosition="above"
      ></nb-icon>
    </button>
    <button
      class="btn-info read-status mr-6"
      *ngIf="!isSentMailbox"
      mat-button
      (click)="markReadStatus()"
      [disabled]="!enableReadBtn"
    >
      <span class="wrapp">
        <nb-icon icon="email"></nb-icon>
        <span *ngIf="!readed"> mark as read </span>
        <span *ngIf="readed">mark as unread </span>
      </span>
    </button>
    <div class="search-bar-container">
      <input
        [style.width]="'calc(100% - 45px)'"
        [style.max-width]="'unset!important'"
        nbInput
        matInput
        (keyup)="applyFilter($event.target.value)"
        placeholder="Search..."
        name="filter"
      />
      <nb-icon class="search" icon="search-outline"></nb-icon>
    </div>
  </div>
  <div *ngIf="!isDataLoaded" class="table-container loader">
    <div class="table-spinner-container">
      <div id="nb-global-spinner" class="spinner">
        <img src="./assets/images/echo_sygnet.svg" alt=""/>
      </div>
    </div>
  </div>
  <div
    class="table-container container-extended-filters thin-echo-scrollbar"
    id="email-container-zoom-helper"
    [style.display]="isDataLoaded ? 'block' : 'none'"
    [ngStyle]="{
      'height': heightPx - 120 + 'px',
      'min-height': heightPx - 120 + 'px'
    }"
    data-focus-pointer-helper="true"
    tabindex="-1"
  >
    <ngx-selectable-only-table
      (matSortChange)="usePagination ? updatePagination($event, true) : null"
      [onRowClick]="edit"
      [data]="data"
      [columns]="columns"
      [sortFromBackend]="sortFromBackend"
      [selectedRows]="selectedRows"
      [clearSelected]="clearSelected"
      [filter]="onFilterChange$"
    >
    </ngx-selectable-only-table>
  </div>
  <div class="button-container">
    <div class="flex"></div>
    <div class="flex middle">
      <mat-paginator
        (page)="updatePagination($event)"
        class="pagination"
        [length]="totalRows"
        [pageSizeOptions]="paginationSizeOptions"
      >
      </mat-paginator>
      <button class="btn btn-info" (click)="reload()">
        <i class="nb-loop" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</div>
