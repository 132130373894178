export enum TableType {
  FULL = 0,
  SIMPLE = 1,
  PAYROLL = 2,
  TASKBOARD = 3,
  AWARDS = 4,
  AGENCY = 5
}

export enum FilterType {
  SIMPLE = 0,
  FULL = 1,
  CUSTOM_INPUT = 2,
}
