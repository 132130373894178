<div class="flex" [formGroup]="form">
  <div class="week-filter">
    <div class="filter-buttons">
      <button
        (click)="subtractWeekFrom()"
        class="button-date-filter"
        [ngClass]="{ 'button-small': hideWeekNames }"
      >
        -
      </button>
      <button
        (click)="addWeekFrom()"
        class="button-date-filter"
        [ngClass]="{ 'button-small': hideWeekNames }"
      >
        +
      </button>
    </div>
    <div *ngIf="!hideWeekNames" id="left-week">{{ startWeek }}</div>
    <ng-container *ngIf="withAutofocus">
      <input
        nbInput
        placeholder="range from"
        [ngClass]="{ 'input-radius-left': hideWeekNames }"
        [nbDatepicker]="dateFrom"
        minlength="3"
        maxlength="50"
        formControlName="dateFrom"
        autofocus
      />
    </ng-container>
    <ng-container *ngIf="!withAutofocus">
      <input
        nbInput
        placeholder="range from"
        [ngClass]="{ 'input-radius-left': hideWeekNames }"
        [nbDatepicker]="dateFrom"
        minlength="3"
        maxlength="50"
        formControlName="dateFrom"
      />
    </ng-container>
    <nb-datepicker (dateChange)="dateFromChange($event)" #dateFrom>
    </nb-datepicker>
    <ng-container *ngIf="withAutofocus">
      <input
        nbInput
        placeholder="range to"
        [ngClass]="{ 'input-radius-right': hideWeekNames }"
        [nbDatepicker]="dateTo"
        minlength="3"
        maxlength="50"
        formControlName="dateTo"
        autofocus
        style="padding-right: 0"
      />
    </ng-container>
    <ng-container *ngIf="!withAutofocus">
      <input
        nbInput
        placeholder="range to"
        [ngClass]="{ 'input-radius-right': hideWeekNames }"
        [nbDatepicker]="dateTo"
        minlength="3"
        maxlength="50"
        formControlName="dateTo"
        style="padding-right: 0"
      />
    </ng-container>

    <nb-datepicker (dateChange)="dateToChange($event)" #dateTo> </nb-datepicker>
    <div *ngIf="!hideWeekNames" id="right-week">{{ endWeek }}</div>
    <div class="filter-buttons">
      <button
        (click)="subtractWeekTo()"
        class="button-date-filter"
        [ngClass]="{ 'button-small': hideWeekNames }"
      >
        -
      </button>
      <button
        (click)="addWeekTo()"
        class="button-date-filter"
        [ngClass]="{ 'button-small': hideWeekNames }"
      >
        +
      </button>
    </div>
  </div>
</div>
