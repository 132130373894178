import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class CurrentCareHomeService {
  public employeeDocumentsNotConfigured = false;
  public sleepersStart = '22:30';
  public sleepersEnd = '07:00';
  public workingDays = 7;
  public anyCareHomeUpdated$ = new Subject();
  public menuSearch$ = new Subject();
  public careHomeName$ = new BehaviorSubject<string>('');

  setCurrentCareHome(careHome: any) {
    this.employeeDocumentsNotConfigured = careHome ? careHome.employeeDocumentsNotConfigured : false;
    this.sleepersStart = careHome ? careHome.careHomeSleepersStart : '';
    this.sleepersEnd = careHome ? careHome.careHomeSleepersEnd : '';
    this.workingDays = careHome?.workingDays || 7;
  }
}
