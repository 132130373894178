import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NbMenuItem, NbSidebarService } from "@nebular/theme";
import { SideMenuAccordionService } from "./side-menu-accordion.service";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "side-menu-accordion",
  templateUrl: "./side-menu-accordion.component.html",
  styleUrls: ["./side-menu-accordion.component.scss"],
})
export class SideMenuAccordionComponent implements OnInit, OnDestroy {
  private _isCompacted: boolean;
  private _openedIndex: number;

  @Input() menuItems: NbMenuItem[];

  @Input() toggleSidebar: (boolean) => boolean;
  @Output() menuClick = new EventEmitter<void>();

  @Input() set openedIndex(value: number) {
    this._openedIndex = value;
    if (value === -1) {
      this.openedSubmenuIndex = -1;
    }
  }
  get openedIndex(): number {
    return this._openedIndex;
  }

  @Input() set isCompacted(value) {
    this._isCompacted = value;
    if (value) {
      this._openedIndex = -1;
      this.openedSubmenuIndex = -1;
    }
  }
  get isCompacted(): boolean {
    return this._isCompacted;
  }

  @Input() openedUrl: string;

  @Input() openedSubmenuIndex: number;

  @Input() panel_type: "menu" | "settings" = "menu";

  private destroy$ = new EventEmitter<void>();
  constructor(
    private sidebarService: NbSidebarService,
    private sideMenuService: SideMenuAccordionService,
  ) {}

  ngOnInit() {
    if (this.panel_type === "settings") {
      this.sideMenuService.settingsOpened
        .pipe(takeUntil(this.destroy$))
        .subscribe((value) => {
          if (!value) {
            this._openedIndex = -1;
            this.openedSubmenuIndex = -1;
          }
        });
    }

    if (this.panel_type === "menu") {
      this.sideMenuService.filteredIndex
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this._openedIndex = 0;
          this.openedSubmenuIndex = 0;
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openIfExpanded(index: number, submenu?: boolean) {
    if (this.isCompacted) {
      return;
    }

    this.negateScrollJumping();

    if (!submenu) {
      if (this._openedIndex === index) {
        this._openedIndex = -1;
        this.openedSubmenuIndex = -1;
        return;
      }

      this._openedIndex = index;
      this.openedSubmenuIndex = 0;
    } else {
      if (this.openedSubmenuIndex === index) {
        this.openedSubmenuIndex = -1;
        return;
      }

      this.openedSubmenuIndex = index;
    }
  }

  openCompactedSideMenu(items: NbMenuItem) {
    if (!this.isCompacted) {
      return;
    }

    if (
      this.sideMenuService.isShown.value &&
      this.sideMenuService.menuIdentifier.value === items.title
    ) {
      this.sideMenuService.close();
      return;
    }

    const element = document.getElementById(items.title);

    this.sideMenuService.open(element, items, items.title);
  }

  public negateScrollJumping() {
    const el = document.getElementById("app-menu-scroll-container");
    if (el === null) return;
    if (el.scrollHeight > el.clientHeight) return;

    el.classList.add("thin-echo-scrollbar-hidden");
    el.classList.remove("thin-echo-scrollbar-2");
    setTimeout(() => {
      el.classList.add("thin-echo-scrollbar-2");
      el.classList.remove("thin-echo-scrollbar-hidden");
    }, 250);
  }
}
