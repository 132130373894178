import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { TableService } from '../../../shared/table/table.service';
import { Subscription } from 'rxjs';
import { TasksService } from '../../../../shared/tasks/tasks.service';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { getErrorMessage, isFormValid, handleValidationErrorMessage } from '../../../../utilities/utils';
import { DictionariesService } from '../../../../shared/dictionaries/dictionaries.service';
import { QuestionDialogComponent, QuestionDecisionTypeEnum } from '../../../shared/question-dialog/question-dialog.component';
import { PermissionsService } from '../../../../@core/data/permissions.service';
import { SubtasksService } from '../../../shared/subtask-editor/subtask.service';

@Component({
  selector: 'ngx-fast-editor',
  templateUrl: './fast-editor.component.html',
  styleUrls: ['./fast-editor.component.scss']
})
export class FastEditorComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public form: UntypedFormGroup;
  public utils = { handleValidationErrorMessage, isFormValid };
  @Input() task: any = {};
  @Input() taskPermissions: any = {};
  @Input() isHideStatus: boolean = false;
  public statuses: any = [];
  public grouping: any = [];
  public raggs1: any = [];
  public raggs2: any = [];
  public raggsColors: any = {};
  public statusToDisplay;
  public isClosed = false;
  public isGroupManager = false;
  public taskProgress: any;
  public taskProgressBackground = '#fff';
  public subtasks = [];

  constructor(private tableService: TableService,
              private tasksService: TasksService,
              private toastrService: NbToastrService,
              private dictionariesService: DictionariesService,
              private dialogService: NbDialogService,
              protected permissionsService: PermissionsService,
              private subtasksService: SubtasksService) {
    this.subscription.add(this.tableService.refresh$.subscribe(() => {
      this.reload();
    }));
    this.isGroupManager = this.permissionsService.getIsGroupManager();
  }

  ngOnInit() {
    this.subtasksService.waitForTaskResponse = false;
    const value = this.tableService.getValue() || {};
    value['subtaskId'] = this.tableService.getValue() && this.tableService.getValue().subtaskId;
    const subtaskId = this.tableService.getValue().subtaskId;
    this.tableService.setValue(value);
    this.createForm();
    this.subscription.add(this.tasksService.getTask(this.task.taskId).subscribe((response: any) => {
      this.taskProgress = response.result.taskProgress;
      this.patchValues(response.result);
      this.task = response.result;
      this.loadGrouppingAndRaggs();
      if (subtaskId !== undefined && subtaskId === 0) {
        this.goToAFGTextArea();
      }
      this.updateTaskStatus(this.subtasks);
    }));
  }

  reload() {
    this.subscription.add(this.tasksService.getTask(this.task.taskId).subscribe((response: any) => {
      this.patchValues(response.result);
      this.task = response.result;
    }));
  }

  createForm() {
    this.form = new UntypedFormGroup({
      latestUpdate: new UntypedFormControl(this.task.latestUpdate),
      shortHistory: new UntypedFormControl(null),
      comments: new UntypedFormControl(this.task.comments),
      statusId: new UntypedFormControl(this.task.statusId),
      taskGoupingId: new UntypedFormControl(this.task.taskGoupingId),
      taskRagg1Id: new UntypedFormControl(this.task.taskRagg1Id),
      taskRagg2Id: new UntypedFormControl(this.task.taskRagg2Id),
      isEmailList: new UntypedFormControl(this.task.isEmailList),
      dueTo: new UntypedFormControl(this.task.dueTo),
    });

    this.taskPermissions.canIChangeStateRagg1 ? this.form.get('taskRagg1Id').enable() : this.form.get('taskRagg1Id').disable();
    this.taskPermissions.canIChangeStateRagg2 ? this.form.get('taskRagg2Id').enable() : this.form.get('taskRagg2Id').disable();

    this.form.get('taskRagg1Id').valueChanges.subscribe((value: any) => {
      this.setRaggColor(this.raggs1, value, 'ragg1Color');
    });

    this.form.get('taskRagg2Id').valueChanges.subscribe((value: any) => {
      this.setRaggColor(this.raggs2, value, 'ragg2Color');
    });

    this.form.get('taskGoupingId').valueChanges.subscribe((value: any) => {
      this.setRaggColor(this.grouping, value, 'groupingColor');
    });
    if (this.task.statusFullName === 'Closed') {
      this.isClosed = true;
      this.statusToDisplay = this.task.statusToDisplay;
    }
    this.subscription.add(this.dictionariesService.getTaskStatuses()
      .subscribe((response: any) => {
        this.statuses = response.result.wordsFromDictionary;
      }));
  }

  patchValues(data: any) {
    this.form.patchValue({
      latestUpdate: data.latestUpdate,
      comments: data.comments,
      statusId: data.statusId,
      taskGoupingId: data.taskGoupingId,
      shortHistory: data.shortHistory,
      taskRagg1Id: data.taskRagg1Id,
      taskRagg2Id: data.taskRagg2Id,
      isEmailList: data.isEmailList,
      dueTo: data.dueTo
    });
  }

  getWord(arrray: any, wordId: number) {
    const word = arrray.find((element: any) => element.id == wordId);
    return word;
  }

  setRaggColor(array: any, value: number, colorFieldName: string) {
    const word = this.getWord(array, value);
    this.raggsColors[colorFieldName] = word ? word.colorHash : '';
  }

  loadGrouppingAndRaggs() {
    if (this.task.ragg1DictionaryId) {
      this.dictionariesService.getDictionaryByName(this.task.ragg1DictionaryName)
        .subscribe((response: any) => {
          this.raggs1 = response.result.wordsFromDictionary;
          this.setRaggColor(this.raggs1, this.form.get('taskRagg1Id').value, 'ragg1Color');
        });
    }

    if (this.task.ragg2DictionaryId) {
      this.dictionariesService.getDictionaryByName(this.task.ragg2DictionaryName)
        .subscribe((response: any) => {
          this.raggs2 = response.result.wordsFromDictionary;
          this.setRaggColor(this.raggs2, this.form.get('taskRagg2Id').value, 'ragg2Color');
        });
    }

    if (this.task.groupingDictionaryId) {
      this.dictionariesService.getDictionaryByName(this.task.groupingDictionaryName)
        .subscribe((response: any) => {
          this.grouping = response.result.wordsFromDictionary;
          this.setRaggColor(this.grouping, this.form.get('taskGoupingId').value, 'groupingColor');
        });
    }
  }

  refreshForSubtasks() {
    this.subscription.add(this.tasksService.getTask(this.task.taskId)
      .subscribe((response: any) => {
        this.task.openTime = response.result.openTime;
        this.form.patchValue({
          latestUpdate: response.result.latestUpdate,
          // groupResponse: response.result.groupResponse,
        });
        this.subtasksService.waitForTaskResponse = false;
      }));
  }

  save(forceUpdate = false) {
    setTimeout(() => {
      if (forceUpdate || !this.subtasksService.waitForTaskResponse) {
        const data = {};
        data['latestUpdate'] = this.form.get('latestUpdate').value;
        data['isEmailList'] = this.form.get('isEmailList').value;
        data['dueTo'] = this.form.get('dueTo').value && this.form.get('dueTo').value.length === 0 ? null : this.form.get('dueTo').value;
        data['statusId'] = this.form.get('statusId') && this.form.get('statusId').value || this.task.statusId;
        data['taskGoupingId'] = this.form.get('taskGoupingId').value;
        data['taskRagg1Id'] = this.form.get('taskRagg1Id').value;
        data['taskRagg2Id'] = this.form.get('taskRagg2Id').value;
        data['forceUpdate'] = forceUpdate;
        data['openTime'] = this.task.openTime;
        this.subscription.add(this.tasksService.patchTask(this.task.taskId, data)
          .subscribe((response: any) => {
            if (response.taskModifiedByOther) {
              this.showConfirmModifiedByOther(response.message);
            } else {
              this.toastrService.success(response.message, 'Success');
              this.tableService.closeWindow(true);
            }
          },
            (err) => {
              this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
            }
          ));
      }
    }, 1000);
  }

  goToAFGTextArea() {
    let fieldToCheck = 'afg-latest-update';
    let fieldToFocus = 'afg-latest-update-field';
    if (this.isGroupManager) {
      fieldToCheck = 'group-response';
      fieldToFocus = 'group-response-field';
    }
    let element = document.getElementById(fieldToCheck);
    if (element) {
      element.scrollIntoView({ block: 'center' });
      let elementToFocus = document.getElementById(fieldToFocus);
      if (elementToFocus) {
        elementToFocus.focus();
        elementToFocus = undefined;
      }
      element = undefined;
    }
  }

  subtasksChanged(event) {
    this.subtasks = event && event.subtasks;
    this.updateTaskStatus(this.subtasks);
  }

  calculateHowManySubtaskToUpdate(subtasks: any = []) {
    let toUpdate = 0;
    for (const subTask of subtasks) {
      for (const todo of subTask.todos) {
        if (!todo.isCompleted && !todo.isNotCompleted && !todo.latestUpdate) {
          toUpdate++;
        }
      }
    }
    return toUpdate;
  }

  updateTaskStatus(subtasks: any = []) {
    const noOfSubtasksToUpdate = this.calculateHowManySubtaskToUpdate(subtasks);

    if (this.task.status == 'Paused') {
      this.taskProgress = "This task is paused";
      this.taskProgressBackground = "#5DE191";
    } else if (noOfSubtasksToUpdate < 1 ) {
      this.taskProgress = "Task ready to submit for your report.";
      this.taskProgressBackground = "#5DE191";
    } else {
      this.taskProgress = "There are " + noOfSubtasksToUpdate + " todos to be updated";
      this.taskProgressBackground = "#6B6B6B";
    }
  }

  showConfirmModifiedByOther(message: string) {
    this.subscription.add(this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Modify confirmation',
          message: message,
          okLabel: 'Modify task',
          secondOkLabel: 'Reload task',
          cancelLabel: 'Cancel',
          hideSecondOkButton: false
        },
      })
      .onClose.subscribe((decision: any) => {
        if (decision.isConfirm && decision.type === QuestionDecisionTypeEnum.FIRST) {
          this.save(true);
        } else if (decision.isConfirm && decision.type === QuestionDecisionTypeEnum.SECOND) {
          this.ngOnInit();
        }
      }));
  }

  changeEmailList(event: any) {
    this.form.patchValue({
      isEmailList: event.target.checked ? true : false
    });
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
