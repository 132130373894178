import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EmployessApiUrls } from "./employess.enum";
import { environment } from "../../../environments/environment";
import { Moment } from "moment";
import * as moment from "moment";
import { Observable } from "rxjs";
import {
  IAwardsLineUpdate,
  IAwardsResponse,
} from "../../pages/employess/awards/awards-response.model";
import { IAwardEmployeeInfo } from "../../pages/employess/awards/models/award-employee-info.interfaces";
import { EmployeeTrainingBudgetModel } from "./models/employee-training-budget-model";
import { IHttpSuccessResponse } from "../models/http-success-reposonse.interfaces";
import { map } from "rxjs/operators";
import { EmployeeTrainingBudgetCommand } from "./models/employee-training-budget-command";
import {
  EmployeesCardHistoryLine,
  EmployeesCardHistoryResponse,
} from "../../pages/employess/edit-employee/models/history.model";

@Injectable()
export class EmployessService {
  constructor(private httpClient: HttpClient) {}

  getEmployess() {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}`,
    );
  }

  downloadEmployees(
    isActiveOnly: boolean,
    fileType: any,
    isPrivate: boolean,
    careHomesQuery?: string,
  ) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/download?isActiveOnly=${isActiveOnly}&fileType=${fileType}&isPrivate=${isPrivate}&${careHomesQuery}`,
    );
  }

  getMigrationShiftsData(id: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${id}/shift-migration`,
    );
  }

  saveMigrationShiftsData(id: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${id}/shift-migration`,
      data,
    );
  }

  getEmployeeNextSchedule(id: number, date: Moment) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        EmployessApiUrls.nextScheduleString
      }${id}/nextSchedule?date=${moment(date).format("YYYY/MM/DD")}`,
    );
  }

  getActiveEmployess() {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.activeEmployees}`,
    );
  }

  getActiveEmployessShort() {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.activeEmployees}&format=short`,
    );
  }

  getEmployeesAwards(careHomeId: number, startDate: string, endDate: string) {
    return this.httpClient.get<{ message: string; result: IAwardsResponse }>(
      `${environment.apiUrl}${EmployessApiUrls.awards}${careHomeId}/awards?startDateFormated=${startDate}&endDateFormated=${endDate}`,
    );
  }

  // downloadAwardXLS(careHomeId: number, scheduleDate: Moment) {
  downloadAwardXLS(careHomeId: number, startDate: string, endDate: string) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.awards}${careHomeId}/awards/xls?startDateFormated=${startDate}&endDateFormated=${endDate}`,
      // }${careHomeId}/awards/xls?&monthDate=${moment(scheduleDate).format(
      //   "YYYY/MM/DD"
      // )}`
    );
  }
  getEmployeesByCareHome(
    careHomeId: number,
    employeeId = 0,
    activeOnly = true,
  ) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employeesSkip}?careHomeId=${careHomeId}&activeOnly=${activeOnly}&employeeId=${employeeId}`,
    );
  }

  getActiveEmployeesByCareHome(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.activeEmployeesSkip}&careHomeId=${careHomeId}&format=short`,
    );
  }

  getEmployeesWithoutCareHome() {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employeesSkip}`,
    );
  }

  getEmployee(id: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${id}`,
    );
  }

  getEmployeeTraining(id: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${id}/training-details`,
    );
  }

  getEmployeeActivations(id: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${id}/activate`,
    );
  }

  addEmployee(data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${EmployessApiUrls.employess}`,
      data,
    );
  }

  updateEmployee(id: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${id}`,
      data,
    );
  }

  patchEmployee(id: number, data: any) {
    return this.httpClient.patch(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${id}`,
      data,
    );
  }

  updateAwards(careHomeId: number, data: IAwardEmployeeInfo) {
    return this.httpClient.put(
      `${environment.apiUrl}${EmployessApiUrls.awards}${careHomeId}/awards`,
      data,
    );
  }
  updateAwardsLine(careHomeId: number, data: IAwardsLineUpdate) {
    return this.httpClient.put(
      `${environment.apiUrl}${EmployessApiUrls.awards}${careHomeId}/awards/${data.awardId}`,
      data,
    );
  }
  resetPasswordAndSendInvitation(id: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${id}/send-invitation`,
      data,
    );
  }

  deleteEmployee(employeeId: number) {
    return this.httpClient.delete(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}`,
    );
  }

  getContractsByEmployeeId(employeeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}${EmployessApiUrls.contracts}`,
    );
  }

  getContractsByEmployeeIdInAddContractMode(employeeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}${EmployessApiUrls.contracts}?addContractMode=true`,
    );
  }

  getContractsByEmployeeIdAndCalculateEvents(employeeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}${EmployessApiUrls.contracts}?calculateEvents=true&dismissalProcess=true`,
    );
  }

  getContractsByEmployeeIdAndDate(
    employeeId: number,
    startDate: Moment,
    endDate: Moment,
  ) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}${
        EmployessApiUrls.contracts
      }?startDateFormated=${moment(startDate).format(
        "YYYY/MM/DD",
      )}&endDateFormated=${moment(endDate).format("YYYY/MM/DD")}`,
    );
  }

  getContractsByEmployeeIdAndDateForAL(
    employeeId: number,
    startDate: Moment,
    endDate: Moment,
    alCalculation = false,
  ) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}${
        EmployessApiUrls.contracts
      }?calculateEvents=true&startDateFormated=${moment(startDate).format(
        "YYYY/MM/DD",
      )}&endDateFormated=${moment(endDate).format(
        "YYYY/MM/DD",
      )}&alCalculation=${alCalculation}`,
    );
  }

  getUnaggregatedContractsWithoutCareHome(
    employeeId: number,
    activeOnly: boolean = true,
  ) {
    const url = activeOnly
      ? EmployessApiUrls.activeContractsListSkip + "&calculateEvents=true"
      : EmployessApiUrls.contractsListSkip + "?calculateEvents=true";
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}${url}`,
    );
  }

  getEmployeePayroll(employeeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}${EmployessApiUrls.payroll}`,
    );
  }

  getEventsByEmployeeId(employeeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}${EmployessApiUrls.events}`,
    );
  }

  getEventsToAccept() {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}${EmployessApiUrls.eventsList}?waitingForAcceptance=true&dashboardVersion=true`,
    );
  }

  getEventsWithoutFirstDateAvailable() {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}${EmployessApiUrls.eventsList}?firstDayAvailable=null&dashboardVersion=true`,
    );
  }

  getUnaggregatedEventsByEmployeeId(employeeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}${EmployessApiUrls.eventsList}`,
    );
  }

  getUnaggregatedEventsWithoutCareHome(
    employeeId: number,
    activeOnly: boolean = true,
    eventType: string = "",
  ) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}${EmployessApiUrls.eventsListSkip}?activeOnly=${activeOnly}&type=${eventType}`,
    );
  }

  getUnaggregatedActiveEventsByContractId(
    employeeId: number,
    contractId: number,
  ) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}${EmployessApiUrls.eventsListSkip}?activeOnly=true&contractId=${contractId}`,
    );
  }

  getUnaggregatedCoursesWithoutCareHome(employeeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}${EmployessApiUrls.coursesListSkip}`,
    );
  }

  getEmployeeSchedule(
    employeeId: number,
    from?: number,
    to?: number,
    orderBy?: string,
    orderDirection?: string,
    filter?: any,
  ) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}${EmployessApiUrls.scheduleList}?rowsFrom=${from}&rowsTo=${to}&orderBy=${orderBy}&orderDirection=${orderDirection}&filter=${filter}`,
    );
  }

  getAnnualLeavingsByEmployeeId(employeeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}${EmployessApiUrls.annualLeavings}`,
    );
  }

  cancelEvent(eventId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${EmployessApiUrls.employess}/events/${eventId}`,
      data,
    );
  }

  shortenEvent(eventId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${EmployessApiUrls.employess}/events/${eventId}/shorten`,
      data,
    );
  }

  getAvatar(id: any): Observable<Blob> {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${id}/avatars`,
      { responseType: "blob" },
    );
  }

  downloadNspf01(id: number): any {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${id}/${EmployessApiUrls.nspf01}`,
    );
  }

  addToDismissalProcess(employeeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}/dismissal-process`,
      data,
    );
  }

  updateDismissalProcess(employeeId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}/dismissal-process`,
      data,
    );
  }

  uploadTrainingDetails(employeeId: number, careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}/training-details?careHomeId=${careHomeId}`,
      data,
    );
  }

  uploadEmployeeActivation(employeeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}/activate`,
      data,
    );
  }

  migrateEmployee(
    employeeUid: string,
    data: { careHomeId: number; changeDate: string },
  ) {
    return this.httpClient.put(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeUid}/care-home`,
      data,
    );
  }

  updateTrainingDetails(
    employeeId: number,
    trainingId: number,
    careHomeId: number,
    data: any,
  ) {
    return this.httpClient.put(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}/training-details/${trainingId}?careHomeId=${careHomeId}`,
      data,
    );
  }

  getDismissalProcessValues(employeeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}/dismissal-process`,
    );
  }

  getEmployeeFinance(employeeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}/finance-report`,
    );
  }

  getEmployeeDocuments(employeeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}/documents`,
    );
  }

  addEmployeeDocument(employeeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}/documents`,
      data,
    );
  }

  addTaskWithEmployeeDocument(employeeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}/documents-add-task`,
      data,
    );
  }

  addDocumentDescription(uid: any, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${uid}/documents-description`,
      data,
    );
  }

  deleteEmployeeDocument(employeeId: number, fileUId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}/documents/${fileUId}`,
    );
  }

  downloadEmployeeDocument(employeeId: number, fileUId: string) {
    return this.httpClient.get(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}/documents/${fileUId}`,
    );
  }

  createReplacement(data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${EmployessApiUrls.rotaReplacement}`,
      data,
    );
  }

  public getEmployeeTrainingBudget(
    employeeId: number,
  ): Observable<EmployeeTrainingBudgetModel> {
    return this.httpClient
      .get<IHttpSuccessResponse<EmployeeTrainingBudgetModel>>(
        `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}/training-budget`,
      )
      .pipe(map((item) => item.result));
  }

  public updateEmployeeTrainingBudget(
    employeeId: number,
    body: EmployeeTrainingBudgetCommand,
  ): Observable<any> {
    return this.httpClient.put<Observable<any>>(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}/training-budget`,
      body,
    );
  }

  getEmployeeHistory(
    employeeId: number,
  ): Observable<EmployeesCardHistoryResponse> {
    return this.httpClient.get<EmployeesCardHistoryResponse>(
      `${environment.apiUrl}${EmployessApiUrls.employess}/${employeeId}/card-history`,
    );
  }
}
