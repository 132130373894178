import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  NbSortDirection,
  NbSortRequest,
  NbTreeGridDataSource,
  NbTreeGridDataSourceBuilder,
} from "@nebular/theme";
import { BehaviorSubject, debounceTime } from "rxjs";

@Component({
  selector: "ngx-events-table",
  templateUrl: "./events-table.component.html",
  styleUrls: ["./events-table.component.scss"],
})
export class EventsTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() onRowClick: (event: any) => any;
  @Input() filter: BehaviorSubject<string> = new BehaviorSubject<string>("");
  @Input() data: any[] = [];

  public groupedData: { hy: string; data: any[] }[] = [];

  customColumn = "no";
  defaultColumns = [
    "careHomeName",
    "contractRole",
    "eventTypeFull",
    "eventStartDate",
    "eventEndDate",
    "eventGivenHrs",
    "eventFirstDayAvailable",
    "eventStatus",
    "eventDescription",
  ];

  columnMap: Map<string, string> = new Map([
    ["careHomeName", "Care Home"],
    ["contractRole", "Role"],
    ["eventTypeFull", "Type of event"],
    ["eventStartDate", "Start date"],
    ["eventEndDate", "End date"],
    ["eventGivenHrs", "Hrs"],
    ["eventFirstDayAvailable", "First day available for work"],
    ["eventStatus", "Status"],
    ["eventDescription", "Description"],
  ]);

  allColumns = [this.customColumn, ...this.defaultColumns];

  dataSource: NbTreeGridDataSource<any>;
  dataSources: { hy: string; dataSource: NbTreeGridDataSource<any> }[] = [];

  sortColumn: string;
  sortDirection = NbSortDirection.NONE;

  constructor(private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>) {}

  ngOnInit(): void {
    this.filter.pipe(debounceTime(300)).subscribe((value: string) => {
      this.dataSources.forEach((source) => {
        source.dataSource.filter(value);
      });
    });
  }

  ngOnDestroy(): void {
    if (this.filter) {
      this.filter.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data) {
      if (this.data.length === 0) {
        this.groupedData = [];
        this.dataSources = [];
        return;
      }

      this.groupedData = this.groupByEventHY();
      this.dataSources = this.createDataSources();
    }
  }

  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + nextColumnStep * index;
  }

  findUniqueEventHYs() {
    return Array.from(new Set(this.data.map((item) => item.eventHY)));
  }

  groupByEventHY() {
    const uniqueHY = this.findUniqueEventHYs();
    return uniqueHY.map((hy: string) => {
      return {
        hy,
        data: this.data.filter((item) => item.eventHY === hy),
      };
    });
  }

  createDataSources() {
    return this.groupedData.map((group) => {
      return {
        hy: group.hy,
        dataSource: this.dataSourceBuilder.create(
          this.mapEventsToTable(group.data),
        ),
      };
    });
  }

  private mapEventsToTable(data: any[]) {
    const indexes = Array.from(new Set(data.map((item) => item.eventSeriesId)));
    return indexes.map((eventSeriesId) => {
      const item = data.find((i) => i.eventSeriesId === eventSeriesId);
      return {
        data: {
          no: "",
          eventSeriesId,
          eventHY: item.eventHY || "",
          careHomeName: item.careHomeName || "",
          contractRole: item.contractRole || "",
          eventTypeFull: item.eventTypeFull || "",
          eventStartDate: item.eventStartDate || "",
          eventEndDate: item.eventEndDate || "",
          eventGivenHrs: item.eventGivenHrs || "",
          eventFirstDayAvailable: item.eventFirstDayAvailable || "",
          eventStatus: item.eventStatus || "",
          eventDescription: item.eventDescription || "",
          eventSeriesHrsSum: item.eventSeriesHrsSum || "",
          isChildren: false,
        },
        children: item.careHomeName
          ? data
              .filter((i) => i.eventSeriesId === eventSeriesId)
              .map((i) => ({ data: { ...i, no: "", isChildren: true } }))
          : [],
      };
    });
  }

  getRolesText(roles): string {
    return !roles
      ? "-"
      : roles.length === 1
        ? "1 role"
        : roles.length > 1
          ? `${roles.length} roles`
          : "-";
  }
}

@Component({
  selector: "nb-fs-icon",
  template: `
    <nb-tree-grid-row-toggle
      [hidden]="!children || children.length === 0"
      [expanded]="expanded"
    >
    </nb-tree-grid-row-toggle>
  `,
})
export class FsIconComponent {
  @Input() expanded: boolean;
  @Input() children: any[] = [];
}
