import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TasksApiUrls } from './tasks.enum';
import * as moment from 'moment';
import { map } from "rxjs/operators";
import { ITasksDeadlineEmailsInfoResponse } from "../../pages/shared/interfaces/tasks.interfaces";
import { Observable } from "rxjs";

@Injectable()
export class TasksService {
  constructor(private httpClient: HttpClient) {}

  getTasks(careHomeId: number, taskBoardId: any, dates: { dateFrom: moment.Moment; dateTo: moment.Moment; }, categoriesFilter = '', statusesFilter = '', employeeId = null, paginationObject: { rowsFrom: number; rowsTo: number;}) {
    const employeeIdParam = employeeId ? `&employeeId=${employeeId}` : ``;
    if (dates.dateFrom && dates.dateTo) {
      return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.taskBoards}/${taskBoardId}/care-home/${careHomeId}/tasks?startDateFormated=${dates.dateFrom.format('YYYY-MM-DD')}&endDateFormated=${dates.dateTo.format('YYYY-MM-DD')}&categoriesFilter=${categoriesFilter}&statusesFilter=${statusesFilter}${employeeIdParam}&rowsFrom=${paginationObject.rowsFrom}&rowsTo=${paginationObject.rowsTo}`);
    } else {
      return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.taskBoards}/${taskBoardId}/care-home/${careHomeId}/tasks?weekStartAtFormated=${moment().startOf('isoWeek').format('YYYY/MM/DD')}&categoriesFilter=${categoriesFilter}&statusesFilter=${statusesFilter}${employeeIdParam}`);
    }
  }

  getDeadlineTasks(careHomeId: number, dates: { dateFrom: moment.Moment; dateTo: moment.Moment; }) {
    return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.taskBoards}/care-home/${careHomeId}/deadline?weekStartAtFormated=${moment().startOf('isoWeek').format('YYYY/MM/DD')}`);
  }

  getTasksAnalyzer(date: any, filteredBy: string) {
    return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.tasks}/analyzer?dateFormated=${moment(date).format('YYYY/MM/DD')}&filterBy=${filteredBy}`);
  }

  updateTasksAnalyzerFeedback(data: any) {
    return this.httpClient.put(`${environment.apiUrl}${TasksApiUrls.tasks}/analyzer/notes`, data);
  }

  getTask(id: number) {
    return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.tasks}/${id}`);
  }

  getRelatedTasks(id: number) {
    return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.tasks}/${id}/related`);
  }

  getTaskHistory(id: number, rowsFrom?: number, rowsTo?: number, orderBy?: string, orderDirection?: string, type= 'old') {
    return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.tasks}/${id}/history?rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&orderBy=${orderBy}&orderDirection=${orderDirection}&type=${type}`);
  }

  getTaskLinkedTasks(id: number, takeAll = false, excludeClosed = false) {
    return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.tasks}/${id}/linked-tasks?showPaused=${takeAll}&showClosed=${excludeClosed}`);
  }

  getTemplateTasks(filters: string = '', rowsFrom?: number, rowsTo?: number, orderBy?: string, orderDirection?: string) {
    return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.templateTasks}?rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&orderBy=${orderBy}&orderDirection=${orderDirection}&filters=${filters}`);
  }

  getTemplateTaskDetails(taskId: number) {
    return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.templateTasks}/${taskId}`);
  }

  getDynamicTagsList() {
    return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.tasks}/dynamic-tags`);
  }

  addTodo(id: number, data: any) {
    return this.httpClient.post(`${environment.apiUrl}${TasksApiUrls.tasks}/${id}/todo`, data);
  }

  addDynamicTag(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${TasksApiUrls.tasks}/dynamic-tag`, data);
  }

  addMultipleTask(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${TasksApiUrls.tasks}/multiple`, data);
  }

  addTemplateTask(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${TasksApiUrls.tasks}/template`, data);
  }

  copyMultipleTask(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${TasksApiUrls.tasks}/copy-multiple`, data);
  }

  updateMultipleTasks(id: number[], data: any) {
    return this.httpClient.put(`${environment.apiUrl}${TasksApiUrls.tasks}/multiple/${id}`, data);
  }

  updateTask(id: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${TasksApiUrls.tasks}/${id}`, data);
  }

  updateTemplate(id: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${TasksApiUrls.tasks}/template/${id}`, data);
  }

  updateTaskCategoryRoles(id: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${TasksApiUrls.tasks}/categories/${id}`, data);
  }

  getTaskCategoryRoles(id: any) {
    return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.tasks}/categories/${id}`);
  }

  patchTask(id: number, data: any) {
    return this.httpClient.patch(`${environment.apiUrl}${TasksApiUrls.tasks}/${id}`, data);
  }

  patchTaskPausing(id: number, data: any) {
    return this.httpClient.patch(`${environment.apiUrl}${TasksApiUrls.tasks}/${id}/pausing`, data);
  }

  updateAssignedUsers(id: number, subtaskId: number, data: any) {
    return this.httpClient.patch(`${environment.apiUrl}${TasksApiUrls.tasks}/${id}/subtasks/${subtaskId}/users-collaboration`, data);
  }

  addRoomEnquiriesTask(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${TasksApiUrls.tasks}/room-enquiries`, data);
  }

  copyTask(taskId: number, data: { targetCareHomeId: any; targetTaskBoardId: any; }) {
    return this.httpClient.post(`${environment.apiUrl}${TasksApiUrls.tasks}/${taskId}`, data);
  }

  moveTask(taskId: number, data: { targetCareHomeId: any; targetTaskBoardId: any; }) {
    return this.httpClient.put(`${environment.apiUrl}${TasksApiUrls.tasks}/${taskId}/move`, data);
  }

  moveMultipleTasks(data: any) {
    return this.httpClient.put(`${environment.apiUrl}${TasksApiUrls.tasks}/move-multiple`, data);
  }

  changeTaskOrder(taskId: number, data: any) {
    return this.httpClient.patch(`${environment.apiUrl}${TasksApiUrls.tasks}/${taskId}/order`, data);
  }

  changeSubtaskTitleOrder(mainTaskId: number, data: any) {
    return this.httpClient.patch(`${environment.apiUrl}${TasksApiUrls.tasks}/${mainTaskId}/subtasks/order`, data);
  }

  getTaskWatchers(id: any) {
    return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.tasks}/${id}/watchers`);
  }

  getTaskMultipleWatchers(id: any) {
    return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.tasks}/${id}/multiple-watchers`);
  }

  updateTaskWatchers(id: any, watchers: any) {
    return this.httpClient.put(`${environment.apiUrl}${TasksApiUrls.tasks}/${id}/watchers`, watchers);
  }

  updateTaskStatus(id: any, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${TasksApiUrls.tasks}/${id}/status`, data);
  }

  updateTaskRagg(id: any, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${TasksApiUrls.tasks}/${id}/ragg`, data);
  }

  addTaskFile(taskId: any, data: any) {
    return this.httpClient.post(`${environment.apiUrl}${TasksApiUrls.tasks}/${taskId}/files`, data);
  }

  addTaskFileArray(taskId: any, data: any) {
    return this.httpClient.post(`${environment.apiUrl}${TasksApiUrls.tasks}/${taskId}/files-array`, data);
  }

  addTaskListFileArray(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${TasksApiUrls.tasks}/files-array`, data);
  }

  deleteTaskFile(taskId: any, fileUId: string) {
    return this.httpClient.delete(`${environment.apiUrl}${TasksApiUrls.tasks}/${taskId}/files/${fileUId}`);
  }

  downloadTaskFile(taskId: any, fileUId: string) {
    return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.tasks}/${taskId}/files/${fileUId}`);
  }

  getTaskFiles(taskId: any) {
    return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.tasks}/${taskId}/files`);
  }

  getDeadlineEmails(taskId: number): Observable<ITasksDeadlineEmailsInfoResponse> {
    return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.tasks}/${taskId}/dead-line-emails-info`)
      .pipe(
        map((response: ApiResponse) => response.result)
      );
  }

  addDocumentDescription(taskId: any, uid: any, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${TasksApiUrls.tasks}/${taskId}/files/${uid}/documents-description`, data);
  }

  getTaskSubtasks(taskId: number) {
    return this.httpClient.get(`${environment.apiUrl}${TasksApiUrls.tasks}/${taskId}/subtasks`);
  }

  deleteSubtasks(taskId: number, subtaskIds: [any], addingTaskInNewTicketMode: boolean) {
    return this.httpClient.delete(`${environment.apiUrl}${TasksApiUrls.tasks}/${taskId}/subtasks?ids=${subtaskIds}&addingTaskInNewTicketMode=${addingTaskInNewTicketMode}`);
  }

  deleteTemplate(taskId: number) {
    return this.httpClient.delete(`${environment.apiUrl}${TasksApiUrls.tasks}/template/${taskId}`);
  }

  updateSubtasksTitle(taskId: any, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${TasksApiUrls.tasks}/${taskId}/subtasks-title`, data);
  }

  testTaskRedo(taskId: any) {
    return this.httpClient.patch(`${environment.apiUrl}${TasksApiUrls.tasks}/${taskId}/handleTaskReDo`, {});
  }


}
export interface ApiResponse {
  message: string;
  result: any;
}
