import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";
import { NbMenuItem } from "@nebular/theme";

@Injectable({
  providedIn: "root",
})
export class SideMenuAccordionService implements OnDestroy {
  public settingsOpened = new BehaviorSubject<boolean>(false);
  public filteredIndex = new Subject<number>();

  public isShown = new BehaviorSubject<boolean>(false);
  public position = new BehaviorSubject<{ left: number; top: number }>({
    left: 0,
    top: 0,
  });
  public menuItems = new BehaviorSubject<NbMenuItem | null>(null);
  public menuIdentifier = new BehaviorSubject<string>("");

  public isSubShown = new BehaviorSubject<boolean>(false);
  public subPosition = new BehaviorSubject<{ left: number; top: number }>({
    left: 0,
    top: 0,
  });
  public subMenuItems = new BehaviorSubject<NbMenuItem | null>(null);
  public subMenuIdentifier = new BehaviorSubject<string>("");

  private open$ = new Subject<{
    element: HTMLElement;
    menuItems: NbMenuItem;
    menuIdentifier: string;
  }>();

  public onOpen$ = this.open$.asObservable();

  private close$ = new Subject<void>();

  private openSubMenu$ = new Subject<{
    element: HTMLElement;
    menuItems: NbMenuItem;
    menuIdentifier: string;
  }>();
  private closeSubMenu$ = new Subject<void>();

  private destroy$ = new Subject<void>();
  constructor() {
    this.open$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ element, menuItems, menuIdentifier }) => {
        this.isShown.next(true);
        this.position.next({
          left: 80,
          top: element.getBoundingClientRect().top,
        });
        this.menuItems.next(menuItems);
        this.menuIdentifier.next(menuIdentifier);
        this.closeSubMenu();
      });

    this.close$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isShown.next(false);
      this.position.next({ left: 0, top: 0 });
      this.menuItems.next(null);
      this.menuIdentifier.next("");
      this.closeSubMenu();
    });

    this.openSubMenu$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ element, menuItems, menuIdentifier }) => {
        this.isSubShown.next(true);
        this.subPosition.next({
          left: 365,
          top: element.getBoundingClientRect().top,
        });
        this.subMenuItems.next(menuItems);
        this.subMenuIdentifier.next(menuIdentifier);
      });

    this.closeSubMenu$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isSubShown.next(false);
      this.subPosition.next({ left: 0, top: 0 });
      this.subMenuItems.next(null);
      this.subMenuIdentifier.next("");
    });
  }

  public open(
    element: HTMLElement,
    menuItems: NbMenuItem,
    menuIdentifier: string,
  ) {
    this.open$.next({ element, menuItems, menuIdentifier });
  }

  public close() {
    this.close$.next();
  }

  public openSubMenu(
    element: HTMLElement,
    menuItems: NbMenuItem,
    menuIdentifier: string,
  ) {
    this.openSubMenu$.next({ element, menuItems, menuIdentifier });
  }

  public closeSubMenu() {
    this.closeSubMenu$.next();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
