<div>
  <main>
    <div class="form-view">
      <form [formGroup]="form" autocomplete="off">
        <p class="freeze-date">Rota freeze date: {{rotaFreezeDate.format('DD/MM/YYYY')}}</p>
        <div class="flex"> 
          <div class="form-control-group">
            <label class="label" for="input-training-start">Training Start:</label>
            <input nbInput
                    fullWidth
                    formControlName="trainingStart"
                    id="input-sickness-start"
                    placeholder="DD/MM/YYYY"
                    [matDatepicker]="trainingStartDatepicker"
                    minlength="3"
                    maxlength="50"
                    autofocus>
                  <mat-datepicker-toggle matSuffix [for]="trainingStartDatepicker"></mat-datepicker-toggle>
                  <mat-datepicker #trainingStartDatepicker startView="month"></mat-datepicker>
            <p class="error-message" *ngIf="isDatesWarning">
              Start date cannot be greater than end date
            </p>
          </div>
          <div class="form-control-group">
            <label class="label" for="input-training-end">Training End: </label><label class="label obligatory" title="This field is obligatory">*</label>
            <input nbInput
                    fullWidth
                    formControlName="trainingEnd"
                    id="input-training-end"
                    placeholder="DD/MM/YYYY"
                    [matDatepicker]="trainingEndDatepicker"
                    minlength="3"
                    maxlength="50"
                    autofocus>
                  <mat-datepicker-toggle matSuffix [for]="trainingEndDatepicker"></mat-datepicker-toggle>
                  <mat-datepicker #trainingEndDatepicker startView="month"></mat-datepicker>
            <p class="error-message" *ngIf="isRotaFreeze">
              End date must be greater than Rota freeze date
            </p>
            <p class="error-message" *ngIf="isEndDateWarning">
              Please provide proper end date
            </p>
          </div>
        </div> 
        <div class="flex-container">
          <div class="form-control-group">
            <label class="label" for="input-description">Description:</label><label class="label obligatory" title="This field is obligatory">*</label>
            <textarea nbInput
                      fullWidth
                      formControlName="description"
                      maxlength="255"
                      placeholder="Description">
            </textarea>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'description', errorMessages) }}
            </p>
          </div>
        </div>
      </form>
    </div>
    <table #table mat-table cdkDropList [dataSource]="data" *ngIf="isCheckList">
      <ng-container *ngFor="let column of columns;" [matColumnDef]="column.fieldName">
        <ng-container>
          <th mat-header-cell *matHeaderCellDef class="header"
            [ngClass]="{'title': column.fieldName === 'title', 'status': column.fieldName === 'status', 'hide': column.fieldName === 'isChecked' && !isCopyVisible}">
            <span class="bordered">{{column.title}}</span>
          </th>
        </ng-container>
        <ng-container *ngIf="column.fieldName === 'isChecked'">
          <td mat-cell *matCellDef="let element" class="checkbox-width">
            <nb-checkbox [(ngModel)]="element[column.fieldName]" *ngIf="!element.isEmptySpace" (change)="onTaskSelectionChange($event, element)">
            </nb-checkbox>
          </td>
        </ng-container>
        <ng-container *ngIf="column.fieldName === 'index'">
          <td mat-cell *matCellDef="let element" class="small-no-column">
            <span>{{element[column.fieldName]}}</span>
          </td>
        </ng-container>
        <ng-container>
          <ng-container *ngIf="column.fieldType === 'textarea-view'">
            <td mat-cell *matCellDef="let element"
              [ngStyle]="{'display': element.isEmptySpace ? 'none': 'table-cell'}">
              <textarea [(ngModel)]="element[column.fieldName]" autosize [maxRows]="7" class="textarea-view" #content [innerHTML]="element[column.fieldName]"></textarea>
            </td>
          </ng-container>
          <ng-container *ngIf="column.fieldType !== 'textarea-view'">
            <td mat-cell *matCellDef="let element"
              [ngStyle]="{'display': element.isEmptySpace ? 'none': 'table-cell'}">
              <span #content [class]="column.class">{{element[column.fieldName]}}</span>
            </td>
          </ng-container>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="thinner"></tr>
    </table>
  </main>
  <footer class="flex">
    <button class="btn btn-success" (click)="save()" [disabled]="!allChecked || !utils.isFormValid(form) || isEndDateWarning || isDatesWarning">
      <i class="nb-checkmark" aria-hidden="true"></i> Save
    </button>
    <button class="btn btn-danger ml-4" (click)="closeWindow()">
      <i class="nb-close" aria-hidden="true"></i> Close
    </button>
  </footer>
</div>