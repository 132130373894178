import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, take, tap, timeout } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthApiUrls } from '../@core/auth/auth.enum';
import { AuthUserService } from '../@core/data/auth-user.service';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { VersionService } from './version.service';
import { QuestionDialogComponent } from '../pages/shared/question-dialog/question-dialog.component';
import { FunctionsStorageService } from './functions-storage.service';
import { filter } from "rxjs/operators";
import { RotaTableService } from "../pages/employess/rota/rota-table.service";
import {CareHomesService} from "./care-homes/care-homes.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  isLogoutMessageShown = false;

  constructor(private authUserService: AuthUserService,
              private router: Router,
              private toastrService: NbToastrService,
              private versionService: VersionService,
              private dialogService: NbDialogService,
              private rotaTableService: RotaTableService,
              private careHomesService: CareHomesService,
              private _functionsStorageService: FunctionsStorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const requestedURL = req.url.replace(environment.apiUrl, '');
    let copiedReq = req;
    if (requestedURL !== AuthApiUrls.login) {
      copiedReq = req.clone({ headers: req.headers.set('x-access-token', this.authUserService.getToken()).set('frontend-build-version', environment.frontEndBuild + '') });
    }
    if (req.method === 'GET' && requestedURL.includes('/employees') || req.method === 'GET' && requestedURL.includes('/residents')) {
      const symbol = req.url.includes('?') ? '&' : '?';
      if (!requestedURL.includes('skip-care-home')) {
        copiedReq = req.clone({
          url: `${req.url}${symbol}careHomeId=${this.authUserService.getCareHomeId()}`,
          headers: req.headers.set('x-access-token', this.authUserService.getToken()).set('frontend-build-version', environment.frontEndBuild + '')
        });
      }
    }
    copiedReq = copiedReq.clone({
      headers: copiedReq.headers.set('x-access-token', this.authUserService.getToken()).set('frontend-build-version', environment.frontEndBuild + ''),
      url: copiedReq.url.replace('?skip-care-home', '')
    });
    copiedReq = copiedReq.clone({
      headers: copiedReq.headers.set('x-access-token', this.authUserService.getToken()).set('care-home-id', this.authUserService.getCareHomeId() +''),
      url: copiedReq.url.replace('?skip-care-home', '')
    });
    copiedReq = copiedReq.clone({
      headers: copiedReq.headers.set('x-access-token', this.authUserService.getToken()).set('care-home-name', this.careHomesService.currentCareHome?.name || this.careHomesService.currentCareHome?.careHomeFullName +''),
      url: copiedReq.url.replace('?skip-care-home', '')
    });
    return next.handle(copiedReq)
      .pipe(
        timeout(180000),
        tap((res) => {
          if (res instanceof HttpResponse) {
            const apiVersion = JSON.parse(res.headers.get('api-version'));
            this.versionService.version$.next(apiVersion);
            this.showMessageToConfirmIfExist(res && res.body.messageToBeConfirmedByUser);
          }
        }, (error) => {
          const extendandErrorCodeId = (error.error.errors) ? error.error.errors[0].extendandErrorCodeId : null;
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              if (!this.isLogoutMessageShown) {
                this.toastrService.danger('Your session has expired. You will be redirect to login page.', 'Error', { duration: 60000, destroyByClick: true });
                setTimeout(() => {
                  this.authUserService.logout();
                  this.router.navigateByUrl('/');
                  location.reload();
                  this.isLogoutMessageShown = false;
                }, 1500);
                this.isLogoutMessageShown = true;
                this.authUserService.setRedirectUrl(this.router.url);
              }
            } else if (error.status >= 500) {
              this.toastrService.danger('Connection with server timeout. Please try again.', 'Error', { duration: 60000, destroyByClick: true });
            } else if (error.status === 400 && extendandErrorCodeId === 102) {
              return next.handle(copiedReq)
                .pipe(
                  filter((x) => (x as HttpResponse<any>).status === 200),
                  tap((response) => this._functionsStorageService.responseSuccessFn(response))
                ).subscribe(
                  {
                    next: () => {},
                    error: (error) => {
                      return this._showDialogWithPasswordConfirmation(error.error.errors[0].message)
                        .onClose.pipe(
                          take(1),
                          filter((x: { isConfirm: boolean; input: string }) => x.isConfirm),
                          tap((dialogResponse: { isConfirm: boolean; input: string }) => {
                            copiedReq.body.password = dialogResponse.input;
                            return next.handle(copiedReq).subscribe();
                          })
                        ).subscribe()
                    }
                  }
                )
            } else if (error.status >= 400 && error.status < 500) {

            } else {
              //this._showDialogNoInternetConnection('We are working to improve ECHO. Please try in a few minutes.')
              this.toastrService.danger('There is no internet connection. Please try again.', 'Error', { duration: 60000, destroyByClick: true });
            }
          }
        })
      )
  }


  showMessageToConfirmIfExist(messageToBeConfirmedByUser: string) {
    if (messageToBeConfirmedByUser) {
      this.dialogService
        .open(QuestionDialogComponent, {
          closeOnBackdropClick: false,
          context: {
            title: 'Information',
            message: messageToBeConfirmedByUser,
            hideOkButton: true,
            cancelLabel: 'Ok, understand.'
          },
        }).onClose.subscribe((response: any) => {
          if (messageToBeConfirmedByUser.includes('Due to changes to the submitted rota, the submission date has been changed to')) {
            this.rotaTableService.getConfirmationDate$.next(true)
          }
          this.authUserService.messageConfirmed$.next(response);
        });
    }
  }

  private _showDialogWithPasswordConfirmation(message: string): NbDialogRef<QuestionDialogComponent> {
    return this.dialogService.open(QuestionDialogComponent, {
      closeOnBackdropClick: false,
      context: {
        title: 'Please confirm scheduled hours',
        message,
        okLabel: 'Confirm',
        passwordInput: true
      }
    })
  }

  private _showDialogNoInternetConnection(message: string): NbDialogRef<QuestionDialogComponent> {
    return this.dialogService.open(QuestionDialogComponent, {
      closeOnBackdropClick: false,
      closeOnEsc: true,
      context: {
        panelClass: 'question-dialog--warning',
        title: 'Server site maintenance',
        message,
        okLabel: 'Ok',
        hideCancelButton: true
      }
    })
  }
}
