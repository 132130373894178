import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-task-tabs',
  templateUrl: './task-tabs.component.html',
  styleUrls: ['./task-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskTabsComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  tasksMap: Map<string, string> = new Map([
    ['/employess/awards', 'Awards'],
    ['/employess/courses-overview', 'Training matrix'],
    ['/employess/day-shifts', 'Shift confirmation'],
    ['/employess/list', 'Employees List'],
    ['/employess/leavers-report', 'Leavers report'],
    ['/employess/payroll', 'Payroll adjustment'],
    ['/employess/rota', 'Rota master'],
    ['/employess/staff-hours', 'Staff hours'],
    ['/residents/activity-sheet', 'Activity sheet'],
    ['/residents/evacuation-list', 'Evac info'],
    ['/residents/food-preferences', 'Diets and allergies'],
    ['/residents/list', 'Residents list'],
  ]);

  public url = '';
  constructor(private router: Router, private cdr:ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscription.add(
      this.router.events.subscribe((v) => {
        if (v instanceof NavigationEnd) {
          this.url = v.url;
          this.cdr.detectChanges();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
