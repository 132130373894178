import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InboxService } from './inbox/inbox.service';
import { NgModule } from '@angular/core';
import { UsersService } from './users/users.service';
import { CareHomesService } from './care-homes/care-homes.service';
import { ContractsService } from './employess/contracts/contracts.service';
import { EmployessService } from './employess/employess.service';
import { CoursesService } from './courses/courses.service';
import { SicknessService } from './employess/sickness/sickness.service';
import { OverviewService } from './employess/overview/overview.service';
import { AnnualLeavingService } from './employess/annual-leaving/annual-leaving.service';
import { DictionariesService } from './dictionaries/dictionaries.service';
import { CoreModule } from '../@core/core.module';
import { ResidentsService } from './residents/residents.service';
import { MedicalOrdersService } from './residents/medical-orders/medical-orders.service';
import { PharmaciesService } from './residents/pharmacies/pharmacies.service';
import { TasksService } from './tasks/tasks.service';
import { PayrollService } from './payroll/payroll.service';
import { GeneralPracticesService } from './general-practice/general-practice.service';
import { TaskBoardsService } from './tasks/task-boards/task-boards.service';
import { HolidaysService } from './holidays/holidays.service';
import { LogsService } from './logs/logs.service';
import { ActivitySheetsService } from './activity-sheets/activity-sheets.service';
import { HelpFilesService } from './help-files.service';
import { DashboardService } from './dashboard/dashboard.service';
import { AvatarsService } from './avatars.service';
import { AnalyzerService } from './dashboard/analyzer.service';
import { GroupsService } from './groups/groups.service';
import { AuthInterceptor } from './auth.interceptor';
import { ThemeModule } from '../@theme/theme.module';
import { KeyboardScrollService } from "./scroll.service";

@NgModule({
  imports: [CoreModule, ThemeModule],
  providers: [
    UsersService,
    CareHomesService,
    EmployessService,
    ContractsService,
    DictionariesService,
    SicknessService,
    OverviewService,
    CoursesService,
    AnnualLeavingService,
    ResidentsService,
    MedicalOrdersService,
    PharmaciesService,
    TasksService,
    PayrollService,
    GeneralPracticesService,
    TaskBoardsService,
    HolidaysService,
    InboxService,
    LogsService,
    ActivitySheetsService,
    HelpFilesService,
    DashboardService,
    AvatarsService,
    AnalyzerService,
    GroupsService,
    KeyboardScrollService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class SharedModule {}
