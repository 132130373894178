import { Component, Output, EventEmitter, Input } from '@angular/core';
import { AuthUserService } from '../../../@core/data/auth-user.service';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { QuestionDialogComponent } from '../question-dialog/question-dialog.component';

const MAX_SIZE = 25 * 1024 * 1024; // 25MB


@Component({
  selector: 'ngx-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  @Input() displayedColumns: string[] = [];
  @Input() isFileSendEnabled = true;
  @Input() isDownloadEnabled = true;
  @Input() isAddNotesEnabled = false;
  @Input() isAddNewContractDoc = false;

  @Input() customButtonCheckFunction: any;
  @Input() customButtonLabel: any;
  @Input() customButtonAction: any;
  @Input() customButtonDisabled: any;

  @Output() onUpload: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() onDelete: EventEmitter<string> = new EventEmitter<string>();
  @Output() onDeleteMulti: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() onDownload: EventEmitter<string> = new EventEmitter<string>();
  @Output() descriptionUpdate = new EventEmitter();

  private _files: any[] = [];

  @Input() set files(value: any) {
    this._files = value;
  }
  get files() {
    return this._files;
  }

  constructor(public authUserService: AuthUserService,
              public toastrService: NbToastrService,
              public dialogService: NbDialogService) {}

  uploadFile(event: any, categoryId) {
    for (const file of event) {
      if (file.size <= MAX_SIZE) {
        file.categoryId = categoryId;
        this.onUpload.next(file);
      } else {
        this.toastrService.danger('Maximum file size exceed. Max file size is 25 MB.', 'Error');
      }
    }
  }

  getElementSize(size: any) {
    const fSExt = ['Bytes', 'KB', 'MB', 'GB'];
    let i = 0;
    while (size > 900) {
      size /= 1024;
      i++;
    }
    return (Math.round(size * 100) / 100) + ' ' + fSExt[i];
  }

  deletePopup(uid: string, multi: boolean = false) {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Are you sure?',
          message: 'You want to delete this file?',
          okLabel: 'Yes',
          cancelLabel: 'No'
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          if (!multi) {
            this.delete(uid);
          } else {
            this.deleteMulti(uid);
          }
        }
      }
    );
  }

  delete(uid: string) {
    this.onDelete.emit(uid);
  }

  deleteMulti(uid: string) {
    this.onDeleteMulti.emit(uid);
  }

  download(uid: string) {
    this.onDownload.emit(uid);
  }


  saveDesc(event: any, file: any) {
    this.descriptionUpdate.emit({
      text: event.target.value,
      uid: file.uid
    });
  }

  getIndex(element) {
    const index = this.files.findIndex(file => file.name == element.name);
    return index + 1;
  }
}
