<div
  class="menu-position"
  id="actions-source"
  cdkOverlayOrigin
  #openSource
  #triggerOptions="cdkOverlayOrigin"
  (mouseenter)="open()"
  (mouseleave)="shouldCloseFromSource($event)"
>
  <div class="left-group">
    <div class="icon-circle">
      <nb-icon [icon]="'more-horizontal-outline'"></nb-icon>
    </div>
    <p *ngIf="!isCompacted">Actions</p>
  </div>
  <div class="icon-container">
    <div class="icon"></div>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="triggerOptions"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPositions]="positions"
>
  <div
    class="options-card"
    id="actions-card"
    (mouseleave)="shouldCloseFromOverlay($event)"
  >
    <div class="content">
      <div
        class="content-item"
        *ngIf="
          permissionsService.haveUserPermissionTo(
            ApplicationPermissions.SEND_USER_MESSAGES
          )
        "
        (click)="composeMessage()"
      >
        <div class="left-group">
          <nb-icon [icon]="'email-outline'"></nb-icon>
          <p>New message</p>
        </div>
      </div>
      <div
        class="content-item"
        *ngIf="
          permissionsService.haveUserPermissionTo(
            ApplicationPermissions.ADD_MULTIPLE_TASKS
          )
        "
        (click)="addNewTask()"
      >
        <div class="left-group">
          <nb-icon [icon]="'plus-circle-outline'"></nb-icon>
          <p>Add Task</p>
        </div>
      </div>
      <div class="content-item" (click)="goToPortable()">
        <div class="left-group">
          <nb-icon [icon]="'smartphone-outline'"></nb-icon>
          <p>Switch to Echo portable</p>
        </div>
      </div>
      <div *ngIf="isBeta" class="content-item" (click)="goToProuction()">
        <div class="left-group">
          <nb-icon [icon]="'compass-outline'"></nb-icon>
          <p>Go to production</p>
        </div>
      </div>
      <div class="content-item" (click)="addNewInstance()">
        <div class="left-group">
          <nb-icon [icon]="'folder-outline'"></nb-icon>
          <p>Open in new tab</p>
        </div>
      </div>
      <div class="content-item" (click)="sendMessageToSupport()">
        <div class="left-group">
          <div class="icon-container">
            <div class="icon"></div>
          </div>
          <p>Help</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
