import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'dateAfterOrEqualTo'})
export class DateAfterOrEqualTo implements PipeTransform {
  transform(date: string, dateToCompare: string) {
    let returnValue = false;
    const dateBefore = moment(date, 'YYYY/MM/DD');
    const dateAfter = moment(dateToCompare, 'YYYY-MM-DD');
    if (dateBefore.isValid() && dateAfter.isValid()) {
      returnValue = dateBefore.valueOf() >= dateAfter.valueOf();
    }
    return returnValue;
  }
}
