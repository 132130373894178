export const SicknessApiUrls = {
  sickness: '/employees/events/aggregate',
  sicknessList: '/employees/events/list',
  activeSickness: '/employees/events/list',
  sicknessUnaggregated: '/employees/events',
  sicknessSkip: '/employees/events?skip-care-home',
  sicknessReplacement: '/employees/contracts-replacements',
  sicknessListNew: '/employees/events/list?',
  activeSicknessNew: '/employees/events/list?activeOnly=true&'
};
