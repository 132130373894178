import { Component, ElementRef, ViewChild, Input, forwardRef, Output } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { EventEmitter } from 'events';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'ngx-multiple-autocomplete-selector',
  templateUrl: './multiple-autocomplete-selector.component.html',
  styleUrls: ['./multiple-autocomplete-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultipleAutocompleteSelectorComponent)
    }
  ]
})
export class MultipleAutocompleteSelectorComponent implements ControlValueAccessor {
  @Input() disabled = false;
  public onChange(newVal: any) {}
  public onTouched(_?: any) {}
  public writeValue(obj: any): void {}
  public registerOnChange(fn: any): void { this.onChange = fn; }
  public registerOnTouched(fn: any): void { this.onTouched = fn; }
  public setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled; }
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() values: any = [];
  @Input() values1: any = [];
  @Input() values2: any = [];
  @Input() values3: any = [];
  @Input() values1Label: string = '';
  @Input() values2Label: string = '';
  @Input() values3Label: string = '';
  @Input() selectedValues: any[] = [];
  @Input() noSelectAll = false;
  public inputControl = new UntypedFormControl();
  public filteredValues: Observable<any[]>;
  public selectAll: boolean = false;
  public values1Selected: boolean = false;
  public values2Selected: boolean = false;
  public values3Selected: boolean = false;
  @ViewChild('textInput', { static: false }) textInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  constructor() {
    this.filteredValues = this.inputControl.valueChanges.pipe(
        startWith(null),
        map((value: string | null) => value ? this._filter(value) : this.values.slice()));
  }

  remove(value: string): void {
    if (!this.disabled) {
      const index = this.selectedValues.indexOf(value);
      if (index >= 0) {
        this.selectedValues.splice(index, 1);
      }
      this.onChange(this.selectedValues);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.disabled) {
      const index = this.selectedValues.indexOf(event.option.value);
      if (index < 0) {
        this.selectedValues.push(event.option.value);
        this.onChange(this.selectedValues);
      }
      this.textInput.nativeElement.value = '';
      this.inputControl.setValue(null);
    }
  }

  private _filter(value: any) {
    if (typeof value === 'string' || value instanceof String) {
      const filterValue = value.toLowerCase();
      return this.values.filter((item: any) => item.fullName.toLowerCase().replace(/ +(?= )/g, '').includes(filterValue) === true);
    }
  }

  toggleSelectAll(value: boolean) {
    if (!this.disabled) {
      this.selectAll = value;
      if (value) {
        this.selectedValues = this.values;
      } else {
        this.selectedValues = [];
      }
      this.onChange(this.selectedValues);
    }
  }

  toggleSelectValues(value: boolean, valuesArray: any, selectVariable: string) {
    if (!this.disabled) {
      this[selectVariable] = value;
      if (value) {
        this.selectedValues = this.addValuesToArray(this.selectedValues, valuesArray);
      } else {
        this.selectedValues = this.removeValuesFromArray(this.selectedValues, valuesArray);
      }
      this.onChange(this.selectedValues);
    }
  }

  removeValuesFromArray(base: any, toDelete: any) {
    return base.filter(({ id: id1 }) => !toDelete.some(({ id: id2 }) => id2 === id1));
  }

  addValuesToArray(base: any, newValues: any) {
    newValues.forEach((newElement: any) => {
      if (base.filter((item: any) => item.id == newElement.id).length == 0) {
        base.push(newElement);
      }
    });
    return base;
  }
}
