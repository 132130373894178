import { NgModule } from "@angular/core";
import { HomeCareNamePipe } from "./homeCareName.pipe";
import { RoleTypeNamePipe } from "./roleTypeName.pipe";


@NgModule({
  declarations: [HomeCareNamePipe, RoleTypeNamePipe],
  exports: [HomeCareNamePipe, RoleTypeNamePipe]
})
export class RotaPipesModule {}
