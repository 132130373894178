export const employessErrorMessages = [
  {
    field: 'id',
    errors: [
      {
        error: 'required',
        message: 'Employee ID is required'
      }
    ]
  },
  {
    field: 'levelId',
    errors: [
      {
        error: 'numbers',
        message: 'Must be a number'
      }
    ]
  },
  {
    field: 'status',
    errors: [
      {
        error: 'required',
        message: 'Employee Status is required'
      }
    ]
  },
  {
    field: 'firstName',
    errors: [
      {
        error: 'required',
        message: 'Employee First Name is required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Employee First Name cannot be longer than 50 characters'
      }
    ]
  },
  {
    field: 'surname',
    errors: [
      {
        error: 'required',
        message: 'Employee Last Name is required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Employee Last Name cannot be longer than 50 characters'
      }
    ]
  },
  {
    field: 'dateOfBirth',
    errors: [
      {
        error: 'required',
        message: 'Employee Date of Birth is required'
      },
      {
        error: 'invalidDate',
        message: 'Invalid date'
      },
      {
        error: 'adult',
        message: 'This person is below 18 years of age'
      }
    ]
  },
  {
    field: 'dateFirstJoined',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'invalidDate',
        message: 'Invalid date'
      },
      {
        error: 'dateGreaterThan',
        message: 'Must be greater than date of birth'
      }
    ]
  },
  {
    field: 'nationalInsuranceNumber',
    errors: [
      {
        error: 'required',
        message: 'National insurance number is required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'National Insurance Number cannot be longer than 9 characters'
      },
      {
        error: 'nationalInsuranceNumber',
        message: 'Must be a valid NINO'
      }
    ]
  },
  {
    field: 'passportNumber',
    errors: [
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Passport number cannot be longer than 25 characters'
      },
      {
        error: 'numbers',
        message: 'Must be a number'
      }
    ]
  },
  {
    field: 'phoneNumber',
    errors: [
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'numbers',
        message: 'Must be a number'
      },
      {
        error: 'phoneNumber',
        message: 'Only numbers and dashes allowed'
      }
    ]
  },
  {
    field: 'secondPhoneNumber',
    errors: [
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'numbers',
        message: 'Must be a number'
      },
      {
        error: 'phoneNumber',
        message: 'Only numbers and dashes allowed'
      }
    ]
  },
  {
    field: 'email',
    errors: [
      {
        error: 'email',
        message: 'Must be a valid email'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Email cannot be longer than 50 characters'
      }
    ]
  },
  {
    field: 'receiveEmail',
    errors: [
      {
        error: 'email',
        message: 'Must be a valid email'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Email cannot be longer than 50 characters'
      }
    ]
  },

  {
    field: 'houseNumber',
    errors: [
      {
        error: 'minlength',
        message: 'Must be at least 1 characters long'
      },
      {
        error: 'maxlength',
        message: 'House number cannot be longer than 20 characters'
      }
    ]
  },
  {
    field: 'zipCode',
    errors: [
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Must be at most 50 characters long'
      }
    ]
  },
  {
    field: 'city',
    errors: [
      {
        error: 'alpha',
        message: 'Must be letters only'
      }
    ]
  },
  {
    field: 'state',
    errors: [
      {
        error: 'alpha',
        message: 'Must be letters only'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'country',
    errors: [
      {
        error: 'alpha',
        message: 'Must be letters only'
      },
      {
        error: 'maxlength',
        message: 'Maximum 2 characters'
      }
    ]
  },
  {
    field: 'bankAccountNumber',
    errors: [
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 8 characters'
      },
      {
        error: 'numbers',
        message: 'Must be a number'
      }
    ]
  },
  {
    field: 'bankSortCode',
    errors: [
      {
        error: 'numbers',
        message: 'Must be a number'
      },
      {
        error: 'minlength',
        message: 'Sort code is 6 numbers long'
      },
      {
        error: 'maxlength',
        message: 'Sort code is 6 numbers long'
      }
    ]
  },
  {
    field: 'buildingSocietyRoutingNumber',
    errors: [
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'numbers',
        message: 'Must be a number'
      }
    ]
  },
  {
    field: 'description',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Description cannot be longer than 5000 characters'
      }
    ]
  },
  {
    field: 'given',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'hours',
        message: 'Must be HH:mm or al'
      }
    ]
  },
  {
    field: 'reasonForLeaving',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'comments',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'maxlength',
        message: 'Maximum 5000 characters'
      }
    ]
  },
  {
    field: 'noticeDate',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'invalidDate',
        message: 'Invalid date'
      },
      {
        error: 'dateGreaterThan',
        message: 'Must be greater than contract end date'
      }
    ]
  },
  {
    field: 'leftDate',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'invalidDate',
        message: 'Invalid date'
      },
      {
        error: 'dateGreaterThan',
        message: 'Must be greater than rota freeze date'
      }
    ]
  },
  {
    field: 'paymentInLieu',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'uniformReturned',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'procesStatus',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'procesDaysToEndContract',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'emergencyNameFirst',
    errors: [
      {
        error: 'required',
        message: 'One Emergency contact is required'
      }
    ]
  },
  {
    field: 'emergencyPhoneFirst',
    errors: [
      {
        error: 'required',
        message: 'Contact phone is required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 6 characters long'
      },
      {
        error: 'invalidPhone',
        message: 'Digits and "-" only allowed, min 6, max 20 characters'
      }
    ]
  },
  {
    field: 'emergencyPhoneSecond',
    errors: [
      {
        error: 'invalidPhone',
        message: 'Digits and "-" only allowed, min 6, max 20 characters'
      },
      {
        error: 'minlength',
        message: 'Must be at least 6 characters long'
      },
    ]
  },
  {
    field: 'emergencyRelationFirst',
    errors: [
      {
        error: 'required',
        message: 'Relation type is required'
      }
    ]
  }
];
