import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { generateDays } from '../overview-utils';
import * as moment from 'moment';

@Component({
  selector: 'ngx-overview-header',
  templateUrl: './overview-header.component.html',
  styleUrls: ['./overview-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewHeaderComponent implements OnInit {
  @Input() date: any;
  @Input() dateRange: any;
  @Input() multipleSchedules: boolean = false;
  public days: any = [];

  constructor() { }

  ngOnInit() {
    this.days = generateDays(moment(this.date, 'x'), this.multipleSchedules ? {} : this.dateRange);
  }

  getDate(date: any) {
    if (this.multipleSchedules) {
      return moment(parseInt(date), 'x').format('MMMM YYYY');
    }
    let from = this.dateRange.from as moment.Moment;
    let to = this.dateRange.to  as moment.Moment
    let diffDays = to.diff(from, 'days');
    let middle
    if (diffDays > 1) {
       middle = moment(from).add(diffDays / 2, 'days');
    } else {
      middle = to;
    }

    return moment(middle, 'x').format('MMMM YYYY');
  }

  trackByFn(index, item) {
    return index;
  }

}
