<label class="switch-label" *ngIf="isToggle; else tripleSwitch">
  <span class="first" [class.active]="isFirstValue()" (click)="changeValue()">
    {{firstValueLabel}}
  </span>

  <div class="switch" (click)="changeValue()">
    <input type="checkbox" [checked]="isSecondValue()" (change)="changeValue()" [disabled]="isDisabled">
    <span class="slider" [ngClass]="{'disabled': isDisabled, 'blue': asBlue}"></span>
  </div>

  <span
    class="second"
    (click)="changeValue()"
    [class.active]="isSecondValue()">
      {{secondValueLabel}}
  </span>
</label>

<ng-template #tripleSwitch>
  <label class="switch-label flex--column-gap-2" *ngIf="isTriple; else doubleSwitch">
    <button
      [class.activated]="isFirstValue()"
      class="btn btn-info info-button rota-top-button triple-switch-btn btn-1"
      (click)="changeTripleValue(firstValue)"
      matTooltipPosition="above"
      matTooltip="{{firstValueLabel}}{{!isTask ? ' Mode' : ''}}"
      [matTooltipClass]="'switcher-tooltip'"
    >
      <i [class]="isTask ? 'nb-maximize' : 'nb-checkmark'" aria-hidden="true"></i>
    </button>
    <button
      [class.activated]="isSecondValue()"
      class="btn btn-info info-button rota-top-button triple-switch-btn btn-2"
      (click)="changeTripleValue(secondValue)"
      matTooltipPosition="above"
      matTooltip="{{secondValueLabel}}{{!isTask ? ' Mode' : ''}}"
      [matTooltipClass]="'switcher-tooltip'"
    >
      <i [class]="isTask ? 'nb-loop' : 'nb-shuffle'" aria-hidden="true"></i>
    </button>
    <button
      [class.activated]="isThirdValue()"
      class="btn btn-info info-button rota-top-button triple-switch-btn btn-3"
      (click)="changeTripleValue(thirdValue)"
      matTooltipPosition="above"
      matTooltip="{{thirdValueLabel}}{{!isTask ? ' Mode' : ''}}"
      [matTooltipClass]="'switcher-tooltip'"
    >
      <i [class]="isTask ? 'nb-minimize' : 'nb-list'" aria-hidden="true"></i>
    </button>
  </label>
  <ng-template #doubleSwitch>
    <label class="switch-label flex--column-gap-2" id="double-switch">
      <button
        [class.activated]="isFirstValue() || isThirdValue()"
        class="btn btn-info info-button rota-top-button triple-switch-btn btn-1"
        (click)="changeTripleValue(isFirstValue() ? thirdValue : firstValue)"
        matTooltipPosition="above"
        matTooltip="{{isFirstValue() ? thirdValueLabel : firstValueLabel}}"
        [matTooltipClass]="'switcher-tooltip'"
      >
        <i [class]="isFirstValue() ? 'nb-minimize' : 'nb-maximize'" aria-hidden="true"></i>
      </button>
      <button *ngIf="!hideSecondValue"
        class="btn btn-info info-button rota-top-button triple-switch-btn btn-2"
        (click)="changeTripleValue(secondValue)"
        matTooltipPosition="above"
        matTooltip="{{secondValueLabel}}"
        [matTooltipClass]="'switcher-tooltip'"
      >
        <i class="nb-loop" aria-hidden="true"></i>
      </button>
    </label>
  </ng-template>
</ng-template>

