<table #table mat-table [dataSource]="dataSource">
  <ng-container [matColumnDef]="firstColumn.fieldName" sticky>
    <th mat-header-cell *matHeaderCellDef class="header bordered static-header">
      <span>{{firstColumn.title}}</span>
    </th>
    <td mat-cell *matCellDef="let element" (click)="onRowClick ? onRowClick(element) : ''">
      <span class="static-width" *ngIf="!firstColumn.valuePrepareFunction" [nbTooltip]="element.tooltip ? element.tooltip : element ? element[firstColumn.fieldName] : ''">{{element ? element[firstColumn.fieldName] : ''}}</span>
      <span class="static-width" *ngIf="firstColumn.valuePrepareFunction"  [nbTooltip]="element.tooltip ? element.tooltip : element ? element[firstColumn.fieldName] : ''" [innerHTML]="element ? firstColumn.valuePrepareFunction(element) : ''"></span>
    </td>
  </ng-container>
  <ng-container *ngFor="let column of cutedColumns; trackBy: trackByFn; let columnIndex = index" [matColumnDef]="column.fieldName">
    <ng-container *ngIf="actions">
      <th mat-header-cell
          *matHeaderCellDef
          class="header bordered static-header"
          [nbPopover]="popoverTemplate"
          nbPopoverPlacement="right"
          (click)="setElementToPass({
            careHomeId: column.careHomeId,
            careHomeName: column.careHomeName
          })">
        <span>{{column.title}}</span>
      </th>
    </ng-container>
    <ng-container *ngIf="!actions">
        <th mat-header-cell
            *matHeaderCellDef
            class="header bordered static-header"
            (click)="onHeaderClick ? onHeaderClick(column.careHomeId, column.careHomeName): ''">
          <span>{{column.title}}</span>
        </th>
    </ng-container>
    <td mat-cell *matCellDef="let element" (click)="onRowClick ? onRowClick(element) : ''">
      <ng-container *ngIf="!column.fieldType">
        <span class="right flex" *ngIf="!column.valuePrepareFunction">{{element ? element[column.fieldName] : ''}}</span>
        <span class="right flex" *ngIf="column.valuePrepareFunction" [innerHTML]="element ? column.valuePrepareFunction(element[column.fieldName], element.fieldName) : ''"></span>
      </ng-container>
      <ng-container *ngIf="column.fieldType == 'textarea'">
        <textarea
          fullWidth
          maxlength="100"
          rows="5"
          [(ngModel)]="element[column.fieldName]"
          (focusout)="onTextareaFocusOut({ column: column, value: element[column.fieldName] })"
          class="form-control textarea-small-input"></textarea>
      </ng-container>
      <ng-container *ngIf="column.fieldType == 'clickable'">
        <div (click)="column.action(element)">
          <span class="right flex" *ngIf="!column.valuePrepareFunction">{{element ? element[column.fieldName] : ''}}</span>
          <span class="right flex" *ngIf="column.valuePrepareFunction" [innerHTML]="element ? column.valuePrepareFunction(element[column.fieldName], element.fieldName) : ''"></span>
        </div>
      </ng-container>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <ng-container *ngIf="thinner">
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="thinner"></tr>
  </ng-container>
  <ng-container *ngIf="!thinner">
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </ng-container>
</table>

<button #hiddenButton style="visibility: hidden;"></button>

<ng-template #popoverTemplate>
  <nav class="employee-popup-menu">
    <div *ngFor="let action of actions" (click)="hidePopoverAndExecuteAction(action.click)">{{action.name}}</div>
  </nav>
</ng-template>