import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { MedicalOrdersApiUrls } from './medical-orders.enum';
import * as moment from 'moment';

@Injectable()
export class MedicalOrdersService {
  constructor(private httpClient: HttpClient) {}

  getOrders(careHomeId: number, startDate: any, endDate: any) {
    return this.httpClient.get(`${environment.apiUrl}${MedicalOrdersApiUrls.medicalOrders}?careHomeId=${careHomeId}&startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}`);
  }

  getOrder(id: number) {
    return this.httpClient.get(`${environment.apiUrl}${MedicalOrdersApiUrls.medicalOrders}/${id}`);
  }

  addOrderHeader(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${MedicalOrdersApiUrls.medicalOrders}`, data);
  }

  updateOrderHeader(orderId: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${MedicalOrdersApiUrls.medicalOrders}/${orderId}`, data);
  }

  addOrderLine(orderId: number, data: any) {
    return this.httpClient.post(`${environment.apiUrl}${MedicalOrdersApiUrls.medicalOrders}/${orderId}/order-lines`, data);
  }

  updateOrderLine(orderId: number, lineId: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${MedicalOrdersApiUrls.medicalOrders}/${orderId}/order-lines/${lineId}`, data);
  }

  removeOrderLine(orderId: number, lineId: number) {
    return this.httpClient.delete(`${environment.apiUrl}${MedicalOrdersApiUrls.medicalOrders}/${orderId}/order-lines/${lineId}`);
  }

  getOrderPdf(orderId: number) {
    return this.httpClient.get(`${environment.apiUrl}${MedicalOrdersApiUrls.medicalOrders}/${orderId}/pdf-orders`);
  }

  deleteOrder(orderId: number) {
    return this.httpClient.delete(`${environment.apiUrl}${MedicalOrdersApiUrls.medicalOrders}/${orderId}`);
  }

}
