import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GeneralPracticesApiUrls } from './general-practices.enum';

@Injectable()
export class GeneralPracticesService {
  constructor(private httpClient: HttpClient) {}

  getGeneralPractices(careHomeId: number) {
    return this.httpClient.get(`${environment.apiUrl}${GeneralPracticesApiUrls.practices}?careHomeId=${careHomeId}`);
  }

  getGeneralPractice(id: number) {
    return this.httpClient.get(`${environment.apiUrl}${GeneralPracticesApiUrls.practices}/${id}`);
  }

  addGeneralPractice(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${GeneralPracticesApiUrls.practices}`, data);
  }

  updateGeneralPractice(id: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${GeneralPracticesApiUrls.practices}/${id}`, data);
  }

}
