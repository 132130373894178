import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedAnnualLeavingService {
  elementId: number | undefined;
  eventNo: string | undefined;
  isAl: boolean;
}
