import { DictionariesService } from './../../../shared/dictionaries/dictionaries.service';
import { PermissionsService } from './../../../@core/data/permissions.service';
import { MessageReportComponent } from './../_components/message-report/message-report.component';
import { MatDialog } from '@angular/material/dialog';
import { TableService } from './../../shared/table/table.service';
import { AuthUserService } from './../../../@core/data/auth-user.service';
import { InboxService } from './../../../shared/inbox/inbox.service';
import { InboxEventService } from '../_services/inbox-event.service';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as moment from 'moment';
import { NbToastrService } from '@nebular/theme';
import { getErrorMessage } from '../../../utilities/utils';

@Component({
  selector: 'ngx-inbox-list',
  templateUrl: './inbox-list.component.html',
  styleUrls: ['./inbox-list.component.scss']
})
export class InboxListComponent implements OnInit, OnChanges {
  private subscription: Subscription = new Subscription();
  public categoryId = '-1';
  public mailboxName = 'toRead';
  public mainCategoryId = '';
  @Input() userId = -1;
  @Input() showPinned = false;
  @Input() is360 = false;
  public data = [];
  public groupes = [];
  @Input() multiselectorVisible = true
  @Input() noLoadOnStart = false;
  @Input() heightPx = 0;
  public selectedMessages = [];
  public selectedUid = [];
  public onFilterChange$ = new BehaviorSubject<string>('');
  public clearSelected$ = new BehaviorSubject<boolean>(false);
  private actualFilterValue = '';
  public readed = true;
  public enableReadBtn = false;
  @Input() isMessagesReport = false;
  public permissions = <any>{};
  public reportData = [];
  public actualGroupesValue = [{description: 'Direct messages', id: -1, isStandardDepartment: false, orderById: null, roleName: 'Direct messages'}];
  public totalRows = 0;
  public incomingColumns = [
    {
      fieldName: 'fromName',
      title: 'From',
      sort: true
    },
    {
      fieldName: 'toName',
      title: 'To',
      sort: true
    },
    {
      fieldName: 'subject',
      title: 'Title',
      sort: true
    },
    {
      fieldName: 'date',
      title: 'Date',
      sort: true
    }
  ];
  public outgoingColumns = [
    {
      fieldName: 'toName',
      title: 'To',
      sort: true
    },
    {
      fieldName: 'subject',
      title: 'Title',
      sort: true
    },
    {
      fieldName: 'date',
      title: 'Sent at',
      sort: true
    },
    {
      fieldName: 'readedAt',
      title: 'Readed at',
      sort: true
    },
    {
      fieldName: 'e360',
      title: 'e360',
      sort: true
    }
  ];

  public reportColumns = [
    {
      fieldName: 'messageFrom',
      title: 'From',
      sort: true
    },
    {
      fieldName: 'messageTo',
      title: 'To',
      sort: true
    },
    {
      fieldName: 'sentDate',
      title: 'Sent',
      sort: true
    },
    {
      fieldName: 'readDate',
      title: 'Read',
      sort: true
    },
    {
      fieldName: 'subject',
      title: 'Subject',
      sort: true
    },
    {
      fieldName: 'e360',
      title: 'e360',
      sort: true
    }
    /*{
      fieldName: 'attachments',
      title: 'Attachments',
      sort: true
    }*/
  ];

  public columns = [];
  public isDataLoaded = false;

  constructor(private inboxService: InboxService,
              private toastrService: NbToastrService,
              private permissionsService: PermissionsService,
              private authUserService: AuthUserService,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private toastService: NbToastrService,
              private dictionariesService: DictionariesService,
              private eventService: InboxEventService,
              private tableService: TableService) {
    this.permissions = this.permissionsService.getPermissions();
  }

  ngOnInit() {
    if (!this.isMessagesReport) {
      this.userId = this.authUserService.getUser().id;
    }
    this.route.paramMap
      .subscribe((params: ParamMap) => {
        this.categoryId = params.get('id');
        this.mailboxName = params.get('mailboxName');
        this.mainCategoryId = params.get('mainCategoryId');
        this.mainCategoryId === 'sent' ? this.columns = this.outgoingColumns : this.columns = this.incomingColumns;
        this.isMessagesReport = this.categoryId === 'messagesReport';
        if (this.isMessagesReport) {
          this.mainCategoryId = 'messagesReport'
        }
        this.cdr.detectChanges();
        this.eventService.actualMailbox$.next(this.mainCategoryId);
        this.eventService.updateLeftMenu(this.categoryId);
        this.tableService.isMessageReport = this.isMessagesReport;
        if (!this.isMessagesReport) {
          this.tableService.reloadTable();
        }
      });
    this.eventService.refreshList$.subscribe(() => {
      this.refreshPage();
    });
    if (this.multiselectorVisible) {
      this.subscription.add(this.dictionariesService.getProfessionalRolesActiveOnlyForMessages( this.authUserService.getCareHomeId(), '', '')
        .subscribe((response: any) => {
          this.groupes = response.result;
          this.groupes.unshift(this.actualGroupesValue[0]);
          this.actualGroupesValue = Object.assign([], response.result);
          // for loading all categories at start
          if (this.isMessagesReport) {
            setTimeout(() => { this.tableService.reloadTable(); }, 1000);
          }
        }));
    }
  }

  showMails = (activeFilter?: any, datesRange?: any, actualSelectorValue?: any, searchFilter?: any, paginationData?: any, extendedFilters?: any, extendedSort?: any, extendedOrder?: any, categoryIdFilter?: any, statusIdFilter?: any) => {
    this.isDataLoaded = false;
    let from = 1;
    let to = 50;
    let orderBy = '';
    let orderDirection = '';
    if (paginationData) {
      from = paginationData.pageSize * (paginationData.pageIndex + 1) - paginationData.pageSize + 1;
      to = paginationData.pageSize * (paginationData.pageIndex + 1);
      orderBy = paginationData.orderBy;
      orderDirection = paginationData.orderDirection;
    }

    this.inboxService.getMessagesByCategories(this.userId, this.categoryId, this.mailboxName, this.authUserService.getCareHomeId(), from, to, orderBy, orderDirection, searchFilter, this.is360)
      .subscribe((inboxResponse: any) => {
        this.data = inboxResponse.result.list;
        this.totalRows = inboxResponse.result.totalRows;
        this.isDataLoaded = true;
      },
      (err) => {
        this.toastrService.danger(getErrorMessage(err), 'Server message:', { duration: 60000, destroyByClick: true });
        this.data = null;
      });
  }

  load = (activeFilter: any, datesRange: any, actualGroupesValue: any, actualFilterValue: any, paginationData: any, extendedFilters: any,extendedSort: any, categoryIdFilter: any, statusIdFilter: any, startDate: any, endDate: any, isPinned) => {
    this.isDataLoaded = false;
    const groupesIdList = [];
    if (actualGroupesValue && this.multiselectorVisible) {
      actualGroupesValue.forEach(group => {
        groupesIdList.push(group.id);
      });
    }

    const from = paginationData.pageSize * (paginationData.pageIndex + 1) - paginationData.pageSize + 1;
    const to = paginationData.pageSize * (paginationData.pageIndex + 1);
    this.subscription.add(this.inboxService.getMessagesReport(this.userId, this.authUserService.getCareHomeId(), moment(datesRange.start), moment(datesRange.end), groupesIdList, from, to, paginationData.orderBy, paginationData.orderDirection, actualFilterValue, isPinned, this.is360).subscribe((response: any) => {
      this.reportData = response.result.list;
      this.totalRows = response.result.totalRows;
      this.isDataLoaded = true;
    },
    (err) => {
      this.toastrService.danger(getErrorMessage(err), 'Server message:', { duration: 60000, destroyByClick: true });
      this.reportData = null;
    }));
  }

  openMessage = (message: any) => {
    this.router.navigate(['inbox/message', message.uid], { queryParams: {
      categoryId: this.categoryId,
      mainCategoryId: this.mainCategoryId,
      fromId: message.fromId,
      toId: message.toId
    } });
  }

  openMessageReport = (message: any) => {
    this.dialog.open(MessageReportComponent, {
      data: { message },
      width: '1100px',
      hasBackdrop: true,
    });
  }

  selectMessages = (messages: any) => {
    const selectedUid = [];
    messages.forEach((mes => {
      selectedUid.push(mes.bodyUid);
    }));
    this.selectedMessages = messages;
    this.selectedUid = selectedUid;
    this.checkStatus();
  }

  checkStatus() {
    const readList = [];
    const unReadList = [];
    this.selectedMessages.forEach(message => {
      message.readed ? readList.push(message) : unReadList.push(message);
    });
    if (readList.length > 0 && unReadList.length === 0) {
      this.enableReadBtn = true;
      this.readed = true;
    } else if (unReadList.length > 0 && readList.length === 0) {
      this.enableReadBtn = true;
      this.readed = false;
    } else {
      this.enableReadBtn = false;
      this.readed = true;
    }
  }

  markReadStatus =() => {
    this.clearSelected$.next(false);
    this.inboxService.markReadSelectedMessages(this.userId, { readed: !this.readed, messagesIdList: this.selectedUid })
      .subscribe(() => {
        this.refreshPage();
      }, () => this.toastService.danger('Error'));
  }

  changeMessageCategory = (newStatusId: number, newStatus: string) => {
    const messageRequestPutStatus = {
      newStatusId,
      newStatus,
      messagesIdList: this.selectedUid
    };
    this.subscription.add(this.inboxService.changeMessageCategory(this.userId, messageRequestPutStatus)
      .subscribe((response: any) => {
        this.toastService.success(response.message);
        this.refreshPage();
      },
        () => {
          this.toastService.danger('Error');
        })
    );
  }

  applyFilter(event: any) {
    if (event.target) {
      this.actualFilterValue = event.target.value;
      this.onFilterChange$.next(this.actualFilterValue);
    }
  }

  refreshPage = () => {
    this.clearSelected$.next(false);
    this.eventService.updateLeftMenu(this.categoryId);
    this.tableService.reloadTable();
  }

  ngOnChanges() {
    (async () => {
      await delay(1);
      this.onFilterChange$.next('aaa');
      this.applyFilter(this.actualFilterValue);
    })();
  }

}
