import { UntypedFormControl, UntypedFormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

export const atLeastOne = (validator: ValidatorFn, controls: string[] = null) => (group: UntypedFormGroup): ValidationErrors | null => {
  if (!controls) {
    controls = Object.keys(group.controls);
  }
  const hasAtLeastOne = group && group.controls && controls
    .some(k => !validator(group.controls[k]));
  return hasAtLeastOne ? null : {
    atLeastOne: true,
  };
};

export function alpha(control: UntypedFormControl): { [ key: string ]: boolean } {
  return !XRegExp('^[\\p{L}\\s]*$').test(control.value) ? { 'alpha': true } : null;
}

export function numbers(control: UntypedFormControl): { [ key: string ]: boolean } {
  return control.value != null && !/^[0-9]*$/.test(control.value) ? { 'numbers': true } : null;
}

export function numbersDecimal(control: UntypedFormControl): { [ key: string ]: boolean } {
  return control.value != null && !/^(\d*\.)?\d+$/.test(control.value) ? { 'numbersDecimal': true } : null;
}

export function numbersALHrs(control: UntypedFormControl): { [ key: string ]: boolean } {
    return !XRegExp('^([0-9]|[1-9][0-9]|[1-9][1-9][0-9])([:]{1})([012345]{1})([0123456789]{1})$').test(control.value) ? { 'hours': true } : null;
}

export function alphaNumbers(control: UntypedFormControl): { [ key: string ]: boolean } {
  return !XRegExp('^[\\p{L}\\s0-9]*$').test(control.value) ? { 'alphaNumbers': true } : null;
}

export function hours(control: UntypedFormControl): { [ key: string ]: boolean } {
  if (control.value === null || control.value === '') {
    return null;
  }
  return !XRegExp('^([0-9]|[0-4][0-9]|50)([:]{1})([012345]{1})([0123456789]{1})$').test(control.value) ? { 'hours': true } : null;
}

export function hours250(control: UntypedFormControl): { [ key: string ]: boolean } {
  return !XRegExp('^([0-9]|[0-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|250)([:]{1})([0-5]{1})([0123456789]{1})$').test(control.value) ? { 'hours': true } : null;
}

export function hours250AndNegative(control: UntypedFormControl): { [ key: string ]: boolean } {
  return !XRegExp('^([-]{0,1})([0-9]|[0-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|250)([:]{1})([0-5]{1})([0123456789]{1})$').test(control.value) ? { 'hours': true } : null;
}


export function hoursAS(control: UntypedFormControl): { [ key: string ]: boolean } {
  return !XRegExp('^(([Aa]{1})(Ss){1})|(([0-9]|[1-4][0-9]|50)([:]{1})([012345]{1})([0123456789]{1}))$').test(control.value) ? { 'hours': true } : null;
}

export function phoneNumber(control: UntypedFormControl): { [ key: string ]: boolean } {
  return control.value != null && !/^[\d -]*$/.test(control.value) ? { 'phoneNumber': true } : null;
}

export function customPhoneNumber(control: UntypedFormControl): { [ key: string ]: boolean } {
  return control.value != null && !/^[0-9\-\s]{6,20}$/.test(control.value) ? { 'invalidPhone': true } : null;
}

export function phoneNumberPleaseFillIn(control: UntypedFormControl): { [ key: string ]: boolean } {
  return control.value != null && control.value != 'please fill in' && !/^[0-9\d -]*$/.test(control.value) ? { 'phoneNumber': true } : null;
}
export function phoneNumberPleaseFillIn2PhoneNumber(control: UntypedFormControl): { [ key: string ]: boolean } {
  return control.value != null && control.value != 'please fill in' && !/^[\d -\/]*$/.test(control.value) ? { 'phoneNumber': true } : null;
}
export function emailPleaseFillIn(control: UntypedFormControl): { [ key: string ]: boolean } {
  return control.value != null && control.value != 'please fill in' && !/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i.test(control.value) ? { 'email': true } : null;
}
export function invalidDate(control: UntypedFormControl): { [ key: string ]: boolean } {
  return control.value && isNaN(Date.parse(control.value)) ? { 'invalidDate': true } : null;
}

export function adult(control: UntypedFormControl): { [ key: string ]: boolean } {
  const now = new Date();
  const value = new Date(control.value);
  const adultAge = now.getFullYear() - 18;
  return !value || adultAge <= value.getFullYear() ? { 'adult': true } : null;
}
export function adultWarn(control: UntypedFormControl): { [ key: string ]: boolean } {
  const now = new Date();
  const value = new Date(control.value);
  const adultAge = now.getFullYear() - 18;
  return adultAge <= value.getFullYear() ? { 'adult': true } : null;
}
export function equalToFieldValue(fieldValue: string) {
  // tslint:disable-next-line
  return (control: UntypedFormControl): { [ key: string ]: boolean } => fieldValue != control.value ? { 'equalToFieldValue': true } : null;
}
export function password(control: UntypedFormControl) {
  return !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,}$/.test(control.value) ? { 'password': true } : null;
}
export function passwordEsy(control: UntypedFormControl) {
  return !/^(?=.*[0-9]).{8,}$/.test(control.value) ? { 'password': true } : null;
}
export function equalTo(value: any) {
  // tslint:disable-next-line
  return (control: UntypedFormControl): { [ key: string ]: boolean } =>  value != control.value ? { 'equalTo': true } : null;
}
export function greaterThan(value: any) {
  return (control: UntypedFormControl): { [ key: string ]: boolean } =>
    parseInt(control.value, 10) <= parseInt(value, 10) ? { 'greaterThan': true } : null;
}

export function dateGreaterThan(value: Date) {
  return (control: UntypedFormControl): { [ key: string ]: boolean } => {
      return moment(control.value, 'DD/MM/YYYY').isBefore(moment(value, 'DD/MM/YYYY')) ? { 'dateGreaterThan': true } : null;
  };
}

function getMinutesFromStringHour(givenTime: string): number {
  let timeSplit = givenTime.split(':')
  let minHrs = parseInt(timeSplit[0]) * 60
  let min = parseInt(timeSplit[1])
  return minHrs + min
}

export function invalidHrsMore(contractHrs: string, leavingHours: string,  start: any, end: any) {
  const leaveDays = moment(end).diff(moment(start), 'days') + 1

  return (control: UntypedFormControl): { [ key: string ]: boolean } => {
    if (leaveDays < 8) {return null}
    let ctrValue = getMinutesFromStringHour(control.value)
    let hrsValue = getMinutesFromStringHour(contractHrs)
    let levValue = getMinutesFromStringHour(leavingHours)
    if (levValue >= hrsValue) {
      if (ctrValue < levValue || (ctrValue > levValue + 12*60)) {
        return { 'invalidHrsMore': true }
      } else  {
        return null
      }
    } else {
      return null
    }
  }
}

export function invalidHrs(contractHrs: string, leavingHours: string, start: any, end: any) {
  //const leaveDays = moment(end).diff(moment(start), 'days') + 1
  return (control: UntypedFormControl): { [ key: string ]: boolean } => {
    //if (leaveDays > 7) {return null}
    let ctrValue = getMinutesFromStringHour(control.value)
    //let hrsValue = getMinutesFromStringHour(contractHrs)
    let levValue = getMinutesFromStringHour(leavingHours)
    if (ctrValue < levValue - 12*60 || ctrValue > levValue + 12*60) {
      return { 'invalidHrs': true }
    } else {
      return null
    }
    // if (levValue < hrsValue) {
    //   if (ctrValue > hrsValue) {
    //     return { 'invalidHrs': true }
    //   } else {
    //     if (ctrValue < levValue - 12*60 || ctrValue > levValue + 12*60) {
    //       return { 'invalidHrs': true }
    //     } else {
    //       return null
    //     }
    //   }
    // } else {
    //   return null
    // }
  }
}

export function dateGreaterThanInMiliseconds(value: any) {
  return (control: UntypedFormControl): { [ key: string ]: boolean } => {
      return moment(control.value, 'YYYY-MM-DD').valueOf() < moment(new Date(value), 'YYYY-MM-DD').valueOf() ? { 'dateGreaterThan': true } : null;
  };
}

export function dateGreaterOrEqualThan(value: Date) {
  return (control: UntypedFormControl): { [ key: string ]: boolean } => {
    return moment(control.value, 'DD/MM/YYYY').isSameOrBefore(moment(value, 'DD/MM/YYYY')) ? { 'dateGreaterThan': true } : null;
  };
}

export function dateSmallerThan(value: Date) {
  return (control: UntypedFormControl): { [ key: string ]: boolean } => {
    return new Date(control.value).getTime() >= new Date(value).getTime() ? { 'dateSmallerThan': true } : null;
  };
}

export function emptyOrDateGreaterThan(value: Date) {
  return (control: UntypedFormControl): { [ key: string ]: boolean } => {
    if (control.value != null) {
      return new Date(control.value).getTime() <= new Date(value).getTime() ? { 'emptyOrDateGreaterThan': true } : null;
    }
    return null;
  };
}

export function emptyOrDateSmallerThan(value: Date) {
  return (control: UntypedFormControl): { [ key: string ]: boolean } => {
    if (control.value != null) {
      return new Date(control.value).getTime() < new Date(value).getTime() ? { 'emptyOrDateSmallerThan': true } : null;
    }
    return null;
  };
}

export function nationalInsuranceNumber(control: UntypedFormControl): { [ key: string ]: boolean } {
  return control.value != null && !/^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/i.test(control.value) ? { 'nationalInsuranceNumber': true } : null;
}

export function dailyHrs(control: UntypedFormControl): { [ key: string ]: boolean } {
  return control.value != null && !/^(?:(0[0-9]|1[0-9]|2[0-3]|[0-9])(:[0-5][0-9])?)?$/.test(control.value) ? { 'dailyHrs': true } : null;
}

export function fileSize(file: File, max: number) {
  return (control: UntypedFormControl): { [ key: string ]: boolean } => {
    return !file || file.size > (1024 * 1024 * max) ? { 'fileSize': true } : null;
  };
}

export function minFileSize(file: File, min: number) {
  return (control: UntypedFormControl): { [ key: string ]: boolean } => {
    return !file || file.size < min ? { 'minFileSize': true } : null;
  };
}

export function fileType(file: File, mimeTypes: Array<string>) {
  // tslint:disable-next-line
  return (control: UntypedFormControl): { [ key: string ]: boolean } =>  !file || mimeTypes.indexOf(file.type) == -1 ? { 'fileType': true } : null;
}

export function handleAdultWarningMessage(form: UntypedFormGroup, field: string): boolean {
  return (moment().subtract(18, 'years').isBefore(new Date(form.get(field).value), 'days'));
}

export function emailPleaseFillInFullCorrect(control: UntypedFormControl): { [key: string]: boolean } {
  return  control.value !== null &&
  control.value !== 'please fill in' &&
  !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(control.value)
    ? { email: true }
    : null;
}
