import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Cacheable } from 'ngx-cacheable';
import { getServerLink } from '../../utilities/utils';
import { CareHomesService } from '../../shared/care-homes/care-homes.service';
import { AuthUserService } from '../../@core/data/auth-user.service';

@Pipe({name: 'scheduleConfirmationPhoto'})
export class ScheduleConfirmationPhoto implements PipeTransform {

  constructor(private careHomesService: CareHomesService,
              private authUserService: AuthUserService) {}

  @Cacheable()
  transform(id: string, type: number) {
    return new Observable<string|ArrayBuffer>((observer) => {
      observer.next('assets/images/person.png');
      if (id) {
        this.careHomesService.getSchedulePositionPhoto(this.authUserService.getCareHomeId(), id, type)
          .subscribe((response: any) => {
            if (response) {
              observer.next(getServerLink(response.link));
            }
          },
          (error: any) => {
            observer.next('assets/images/person.png');
          }
        );
      }
      return { unsubscribe() {} };
    });
  }
}
