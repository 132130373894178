<div class="form-view">
  <nb-tabset>
    <nb-tab tabTitle="Sickness Overview">
        <main>
            <form [formGroup]="form" autocomplete="off">
              <div class="form-control-group">
                <label class="label" for="input-employeeName">Employee:</label>
                <input nbInput
                        fullWidth
                        formControlName="employeeName"
                        id="input-employeeName"
                        placeholder="Employee">
              </div>
              <div class="flex-container-horizontal middle">
                <div class="form-control-group">
                  <label class="label" for="input-event-type">Type of Event:</label>
                  <input nbInput
                          fullWidth
                          formControlName="eventType"
                          name="input-event-type"
                          id="input-event-type">
                </div>
                <div class="form-control-group">
                  <label class="label" for="input-sickness-start">Start:</label>
                  <input nbInput
                          fullWidth
                          formControlName="sicknessStart"
                          placeholder="DD/MM/YYYY"
                          [matDatepicker]="sicknessStartDatepicker"
                          autofocus>
                        <mat-datepicker-toggle matSuffix [for]="sicknessStartDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #sicknessStartDatepicker startView="month"></mat-datepicker>
                  <p class="error-message">
                      {{ utils.handleValidationErrorMessage(form, 'sicknessStart', errorMessages) }}
                  </p>
                </div>
                <div class="form-control-group">
                  <label class="label" for="input-sickness-end">End:</label>
                  <input nbInput
                          fullWidth
                          formControlName="sicknessEnd"
                          placeholder="DD/MM/YYYY"
                          [matDatepicker]="sicknessEndDatepicker"
                          autofocus>
                        <mat-datepicker-toggle matSuffix [for]="sicknessEndDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #sicknessEndDatepicker startView="month"></mat-datepicker>
                  <p class="error-message">
                    {{ utils.handleValidationErrorMessage(form, 'sicknessEnd', errorMessages) }}
                  </p>
                </div>
                <div class="form-control-group">
                  <label class="label" for="input-first-day-available">First Day Available (leave blank if return date unknown):</label>
                  <input nbInput
                          fullWidth
                          formControlName="firstDayAvailable"
                          id="input-first-day-available"
                          placeholder="DD/MM/YYYY"
                          [matDatepicker]="firstDayAvailableDatepicker"
                          autofocus>
                        <mat-datepicker-toggle matSuffix [for]="firstDayAvailableDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #firstDayAvailableDatepicker startView="month"></mat-datepicker>
                        <div *ngIf="form.get('firstDayAvailable').value" id="clear-date" (click)="clearFirstDayAvailable()">x</div>
                  <p class="error-message">
                    {{ utils.handleValidationErrorMessage(form, 'firstDayAvailable', errorMessages) }}
                  </p>
                </div>
              </div>
              <div class="form-control-group table-container" *ngIf="sicknessData">
                <table>
                  <thead>
                    <tr>
                      <th *ngFor="let column of columns">{{ column }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ sicknessData.contractNo }}</td>
                      <td>{{ sicknessData.contractRole }}</td>
                      <td>{{ sicknessData.contractType }}</td>
                      <td>{{ sicknessData.givenHrs }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="form-control-group textarea-container">
                <label class="label" for="input-description">Description (required):</label>
                <textarea nbInput
                          fullWidth
                          formControlName="description"
                          placeholder="Provide a proper sickness reason - do not just write 'sickness'"
                          minlength="3"
                          maxlength="5000"
                          autofocus>
                </textarea>
                <p class="error-message">
                  {{ utils.handleValidationErrorMessage(form, 'description', errorMessages) }}
                </p>
              </div>
              <ng-template [ngIf]="cancelled">
                  <div class="form-control-group">
                    <label for="input-cancellation-date">Cancellation/rejection date:</label>
                    <input nbInput
                            fullWidth
                            formControlName="cancellationDate"
                            id="input-cancellation-date"
                            placeholder="DD/MM/YYYY"
                            [matDatepicker]="cancellationDatepicker"
                            autofocus>
                          <mat-datepicker-toggle matSuffix [for]="cancellationDatepicker"></mat-datepicker-toggle>
                          <mat-datepicker #cancellationDatepicker startView="month"></mat-datepicker>
                  </div>
                  <div class="form-control-group cancellation-container">
                    <label for="input-cancellation-reason">Rejection reason:</label>
                    <textarea nbInput
                              fullWidth
                              formControlName="cancellationDescription"
                              id="input-cancellation-reason"
                              placeholder="Rejection reason"
                              minlength="3"
                              maxlength="5000"
                              autofocus>
                    </textarea>
                    <p class="error-message">
                      {{ utils.handleValidationErrorMessage(form, 'cancellationDescription', errorMessages) }}
                    </p>
                  </div>
                </ng-template>
            </form>
          </main>
    </nb-tab>
    <nb-tab tabTitle="Replacement" [disabled]="!permissions.NEW_CONTRACTS">
        <main>
            <angular2-smart-table
              [settings]="cancelled ? replacementSettingsCancelled : replacementSettingsNotCancelled"
              [source]="replacements"
              (deleteConfirm)="deleteReplacement($event)"
            ></angular2-smart-table>
            <div class="flex-end mt-4">
              <button class="btn btn-success"
                      (click)="addReplacement()"
                      [disabled]="!permissions.MENU_SICKNESS || !permissions.ADD_EXTRAS">
                <i class="nb-plus" aria-hidden="true"></i>
              </button>
            </div>
          </main>
    </nb-tab>
  </nb-tabset>
  <footer class="space-between">
    <ng-container *ngIf="mode !== FormMode.DECIDE">
      <div class="flex">
        <button class="btn btn-success"
                (click)="saveData()"
                [disabled]="!utils.isFormValid(form) || !permissions.MENU_SICKNESS">
          <i class="nb-checkmark" aria-hidden="true"></i> Save
        </button>
        <button class="btn btn-danger ml-4"
                (click)="closeWindow()">
          <i class="nb-close" aria-hidden="true"></i> Close
        </button>
      </div>
      <div class="flex">
        <button class="btn btn-danger button-wide ml-4 justify-center"
                (click)="deleteEvent()"
                [disabled]="!permissions.MENU_SICKNESS">
          <i class="nb-e-commerce mr-2" aria-hidden="true"></i> Delete whole event
        </button>
        <button *ngIf="!cancelled"
                class="btn btn-danger longer ml-4 justify-center"
                (click)="cancelEvent()"
                [disabled]="!permissions.MENU_SICKNESS">
          <i class="nb-trash" aria-hidden="true"></i> Shorten sickness
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="mode === FormMode.DECIDE">
      <div class="flex">
        <button class="btn btn-success longer-sick padding-btn"
                (click)="acceptEvent()"
                [disabled]="!permissions.MENU_SICKNESS">
          <i class="nb-checkmark" aria-hidden="true"></i> Accept sickness
        </button>
        <button class="btn btn-danger ml-4 padding-btn"
                (click)="closeWindow()">
          <i class="nb-close" aria-hidden="true"></i> Close
        </button>
      </div>
      <div class="flex">
        <button class="btn btn-danger longer-sick ml-4 padding-btn"
                (click)="rejectEvent()"
                [disabled]="!permissions.MENU_SICKNESS">
          <i class="nb-e-commerce mr-2" aria-hidden="true"></i> Reject sickness
        </button>
      </div>
    </ng-container>
  </footer>
</div>
