import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { EmployessService } from '../../../shared/employess/employess.service';
import { getErrorMessage } from '../../../utilities/utils';

@Injectable({
  providedIn: 'root'
})
export class UploadDocumentToCategoryService {
  constructor(private toastrService: NbToastrService,
              private employeeService: EmployessService) {}

  addTaskForDocumentToCategory(file, categoryId, employeeId, careHomeId, callback) {
    const fd = new FormData();
    fd.append('file', file);
    fd.append('documentCategoryId', categoryId.toString());
    fd.append('careHomeId', careHomeId.toString());
    fd.append('createForContract', true.toString());
    this.employeeService.addTaskWithEmployeeDocument(employeeId, fd)
      .subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        callback();
      },
      (err) => {
        this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
      }
    );
  }

}
