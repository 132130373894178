import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import {
  getValidationStatus,
  handleValidationErrorMessage,
  handleValidationErrorMessageForGeneratedFields,
  isFormValid,
  getErrorMessage,
  correctTimeValueIfNecessary
} from '../../../utilities/utils';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { TableService } from '../../shared/table/table.service';
import { AuthUserService } from '../../../@core/data/auth-user.service';
import { Observable, Subscription } from 'rxjs';
import { EmployessService } from '../../../shared/employess/employess.service';
import { employessErrorMessages } from '../employess-errors';
import { invalidDate } from '../../../utilities/validators';
import * as moment from 'moment';
import { ITableColumn } from '../../shared/table/interfaces/table.interfaces';
import { EmployeeTrainingBudgetModel } from '../../../shared/employess/models/employee-training-budget-model';
import { EmployeeTrainingBudgetService } from './services/employee-training-budget.service';
import { filter, tap } from 'rxjs/operators';
import {PermissionsService} from "../../../@core/data/permissions.service";

@Component({
  selector: 'ngx-employee-in-training',
  templateUrl: './employee-in-training.component.html',
  styleUrls: ['./employee-in-training.component.scss'],
})
export class EmployeeInTrainingComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() cardMode = false;
  @Output() cancelEvent = new EventEmitter();
  private subscription: Subscription = new Subscription();
  public errorMessages = employessErrorMessages;
  public form: UntypedFormGroup;
  public utils = { getValidationStatus, handleValidationErrorMessage, handleValidationErrorMessageForGeneratedFields, isFormValid };
  public employeeId = 0;
  public trainingId = -1;
  public statusId = -1;
  public careHomeId = -1;
  public isEditMode = false;
  public isRotaFreeze = false;
  public isMultipleStack = false;
  public isRotaFreezeWarning = false;
  public isStartDateDisabled = false;
  public isEndDateDisabled = false;
  public isDatesWarning = false;
  public rotaFreezeDate: any;
  public isTrainingBudgetInEditMode: boolean = false;

  public columns: ITableColumn[];
  public employeeTrainingBudget: EmployeeTrainingBudgetModel;
  public isDataLoading$: Observable<boolean>;

  @ViewChild('inputTrainingBudget', { static: false }) private trainingBudgetElementRef: ElementRef;

  constructor(
    private tableService: TableService,
    private authUserService: AuthUserService,
    private toastrService: NbToastrService,
    private employeeService: EmployessService,
    private _trainingBudgetService: EmployeeTrainingBudgetService,
    private _permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.createForm();
    this.employeeId = this.tableService.getValue().elementId;
    this.isEditMode = this.tableService.getValue().isEditMode;
    this.isMultipleStack = this.tableService.getValue().isMultipleStackRequired;
    this.statusId = this.tableService.getValue().statusId;
    this.rotaFreezeDate = this.tableService.getValue().rotaFreezeDate;
    this.careHomeId = this.tableService.getValue().careHomeId;
    this.columns = this._trainingBudgetService.createTableColumns();
    this.isDataLoading$ = this._trainingBudgetService.isDataLoading$;

    if (!this.isEditMode) {
      this.subscription.add(
        this.employeeService.getEmployeeTraining(this.employeeId).subscribe((data: any) => {
          this.patchValues(data.result);
          this.trainingId = data.result.trainingId;
          this.checkRotaFreeze();
        }),
      );
    }

    this.subscription.add(
      this._trainingBudgetService.trainingBudget$
        .pipe(
          filter((x: EmployeeTrainingBudgetModel) => !!x),
          tap((budget: EmployeeTrainingBudgetModel) => {
            this.employeeTrainingBudget = budget;
            this.trainingBudgetControl.patchValue(this.employeeTrainingBudget.totalBudgetHrs);
          }),
        )
        .subscribe(),
    );

    this.form.get('trainingEnd').valueChanges.subscribe(() => {
      this.checkDates();
      this.checkRotaFreeze();
    });

    this.form.get('trainingStart').valueChanges.subscribe(() => {
      this.checkDates();
      this.checkRotaFreeze();
    });

    this._trainingBudgetService.loadData(this.employeeId);
  }

  ngAfterViewInit(): void {
    this.trainingBudgetElementRef.nativeElement.focus();
  }

  public get trainingBudgetControl(): AbstractControl {
    return this.form.get('trainingBudget');
  }

  public get descriptionControl(): AbstractControl {
    return this.form.get('description');
  }

  public get isUserHavePermissionsToEditBudget(): boolean {
    return this._permissionsService.getPermissions().EDIT_TRAINING_BUDGET;
  }

  createForm() {
    this.form = new UntypedFormGroup({
      description: new UntypedFormControl(null, [Validators.required]),
      trainingStart: new UntypedFormControl({ disabled: this.isTrainingBudgetInEditMode, value: null }, [Validators.required, invalidDate]),
      trainingEnd: new UntypedFormControl({ disabled: true, value: null }, [Validators.required, invalidDate]),
      trainingBudget: new UntypedFormControl(
        { disabled: !this.isUserHavePermissionsToEditBudget, value: ''},
        [Validators.required, Validators.pattern('^[0-9:]*')]
      ),
    });

    this.form.get('trainingStart').valueChanges.subscribe((value: any) => {
      if (this.form.get('trainingEnd').value === null) {
        this.form.get('trainingEnd').setValue(moment(value).add(2, 'month'));
      }
    });
  }

  patchValues(data: any) {
    const trainingStart = moment(data.trainingStart, 'YYYY-MM-DD');
    const trainingEnd = moment(data.trainingEnd, 'YYYY-MM-DD');

    this.form.patchValue({
      description: data.description,
      trainingStart: trainingStart,
      trainingEnd: trainingEnd
    });

    if (trainingStart.isBefore(this.rotaFreezeDate)) {
      this.form.get('trainingStart').disable();
      this.isStartDateDisabled = true;
    }

    if (trainingEnd.isBefore(this.rotaFreezeDate)) {
      this.form.get('trainingEnd').disable();
      this.isEndDateDisabled = true;
    }
  }

  prepareData() {
    const data = {
      employeeId: this.employeeId,
      description: this.form.get('description').value,
      totalTrainingBudget: this.trainingBudgetControl.value,
      trainingStart: this.form.get('trainingStart').value,
      trainingEnd: this.form.get('trainingEnd').value,
      careHomeId: this.authUserService.getCareHomeId(),
      statusId: this.statusId
    };
    return data;
  }

  save() {
    this._trainingBudgetService.setIsDataLoading(true);
    const data = this.prepareData();

    if (this.isEditMode) {
      this.saveTrainingEnd(data);
    } else {
      this.saveTraingStart(data);
    }
  }

  saveTraingStart(data: any) {
    this.subscription.add(
      this.employeeService.uploadTrainingDetails(this.employeeId, this.careHomeId, data).subscribe(
        (response: any) => {
          this.toastrService.success(response.message, 'Success');
          this.closeWindow();
        },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
          this._trainingBudgetService.setIsDataLoading(false)
        },
        () => this._trainingBudgetService.setIsDataLoading(false),
      ),
    );
  }

  saveTrainingEnd(data: any) {
    this.subscription.add(
      this.employeeService.updateTrainingDetails(this.employeeId, this.trainingId, this.careHomeId, data).subscribe(
        (response: any) => {
          this.toastrService.success(response.message, 'Success');
          this.closeWindow();
        },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        },
        () => this._trainingBudgetService.setIsDataLoading(false)
      ),
    );
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  public correctTrainingBudgetIfNecessary(): void {
    const value: string = this.trainingBudgetControl.value;
    this.trainingBudgetControl.patchValue(correctTimeValueIfNecessary(value));
  }

  public updateTrainingBudget(): void {
    this._trainingBudgetService.updateTrainingBudget(this.employeeId, this.trainingBudgetControl.value, this.descriptionControl.value)
    this.closeWindow();

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private checkRotaFreeze() {
    if (this.rotaFreezeDate) {
      const trainingStartDate = moment(this.form.get('trainingStart').value, 'DD/MM/YYYY');
      const trainingEndDate = moment(this.form.get('trainingEnd').value, 'DD/MM/YYYY');

      this.isRotaFreeze = (this.rotaFreezeDate.isSameOrAfter(trainingEndDate)  && !this.isEndDateDisabled) || (this.rotaFreezeDate.isSameOrAfter(trainingStartDate) && !this.isStartDateDisabled);

      this.setRotaFreezeWarningVisibility(this.isRotaFreeze);
    }
  }

  private setRotaFreezeWarningVisibility(isVisible: boolean) {
    this.isRotaFreezeWarning = isVisible;
  }

  private checkDates() {
    const trainingStartDate = moment(this.form.get('trainingStart').value, 'DD/MM/YYYY');
    const trainingEndDate = moment(this.form.get('trainingEnd').value, 'DD/MM/YYYY');

    this.isDatesWarning = trainingStartDate.isAfter(trainingEndDate);
  }
}
