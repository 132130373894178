import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { TableService } from "../../table/table.service";
import { NbToastrService } from "@nebular/theme";
import { SubtasksService } from "../subtask.service";
import { Subscription } from "rxjs";
import { CheckboxColor } from "../../checkbox/checkbox.component";
import { AuthUserService } from "../../../../@core/data/auth-user.service";
import { storageKeyTODOS } from "../subtask-editor.component";

enum TodoEditMode {
  TODO_LATEST_UPDATE,
  GROUP_RESPONSE,
  LATEST_UPDATE,
  NONE,
}

@Component({
  selector: "ngx-subtask-editable-todo",
  templateUrl: "./subtask-editable-todo.component.html",
  styleUrls: ["./subtask-editable-todo.component.scss"],
})
export class SubtaskEditableTodoComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild("todoInput", { read: ElementRef })
  todoInput: ElementRef;

  private subscription: Subscription = new Subscription();
  public TodoEditMode: typeof TodoEditMode = TodoEditMode;
  public CheckboxColor: typeof CheckboxColor = CheckboxColor;
  public tableEditMode: TodoEditMode = TodoEditMode.NONE;
  public userTODOS: {
    todos: { id: number; isCollapsed: boolean }[];
    todosIds: number[];
  };
  @Input() disabled: boolean = false;
  @Input() subtaskIndex: number;
  @Input() toDoindex: number;
  @Input() isAdvanced: boolean = false;
  @Input() todo: any;
  @Input() task: any;
  @Input() highlight: boolean = false;
  @Input() isGroupManager: boolean = true;
  @Input() updateMode: boolean = false;
  @Input() allCollapsed: boolean = false;
  @Input() isPlaceholder: boolean = false;

  @Output() resetCollapse = new EventEmitter();
  @Output() delete = new EventEmitter<{
    subtaskIndex: number;
    todoIndex: number;
  }>();
  @Output() saveComments = new EventEmitter<{
    comments: string;
    subtaskIndex: number;
    todoIndex: number;
  }>();
  @Output() changeStatuses = new EventEmitter<{
    isNotCompleted: boolean;
    isCompleted: boolean;
    subtaskIndex: number;
    todoIndex: number;
    focusToNext: boolean;
    kForAll: boolean;
  }>();
  @Output() saveLatestUpdate = new EventEmitter<{
    latestUpdate: string;
    subtaskIndex: number;
    todoIndex: number;
  }>();
  @Output() addTaskResponse = new EventEmitter<{
    groupResponse: string;
    latestUpdate: string;
  }>();
  @Output() setInfinity = new EventEmitter<{
    subtaskIndex: number;
    todoIndex: number;
    isRecurring: boolean;
  }>();
  @Output() changeAssignedUsers = new EventEmitter<{
    subtaskIndex: number;
    todoIndex: number;
    assignedUsers: [];
  }>();

  @Output() onEdit = new EventEmitter<boolean>();
  public form: UntypedFormGroup;
  public editMode: boolean = false;
  public expandTodoId = null;
  public users = [];

  @ViewChild("titleTodo") titleTodo: ElementRef;
  showMoreInfo = false;
  expandText = false;

  constructor(
    protected tableService: TableService,
    protected toastrService: NbToastrService,
    protected subtasksService: SubtasksService,
    protected authUserServce: AuthUserService,
    private cdr: ChangeDetectorRef,
  ) {
    this.subscription.add(
      this.subtasksService.expandTodo$.subscribe((todoId: number) => {
        this.expandTodoId = todoId;
      }),
    );
    this.users = this.subtasksService.getUsers();
    this.subscription.add(
      this.subtasksService.keepAllRemaining$.subscribe(() => {
        if (
          !this.isAdvanced &&
          !this.todo.isNotCompleted &&
          !this.todo.isCompleted
        ) {
          const isAssignedToSomeone: boolean = !!this.todo.assignedUsers[0];
          let isAssignedToMe: boolean = false;
          const currentUser = this.authUserServce.getUser();

          this.todo.assignedUsers.forEach((user) => {
            if (user.id === currentUser.id) {
              isAssignedToMe = true;
            }
          });

          const shouldIgnore: boolean = isAssignedToSomeone && !isAssignedToMe;
          if (shouldIgnore) return;
          if (!this.todo.latestUpdate && !shouldIgnore) {
            this.todo.latestUpdate = "K'd using the 'K all' button";
          }
          this.setStatus(false, false, true);
        }
      }),
    );
  }

  @ViewChild("latestUpdateInput") latestUpdateInput: ElementRef;

  ngOnInit() {
    this.cdr.detectChanges();
    this.userTODOS = JSON.parse(localStorage.getItem(storageKeyTODOS));
    this.createForm();
    this.todo.isCollapsed = this.userTODOS.todos.find(
      (todo) => todo.id == this.todo.id,
    )
      ? this.userTODOS.todos.find((todo) => todo.id == this.todo.id).isCollapsed
      : false;
    this.todo.assignedUsers = this.todo.assignedUsers || [];
    document.querySelectorAll(".expansion-indicator").forEach((el) => {
      el.addEventListener("click", ($event) => {
        this.resetCollapse.emit();
      });
    });
  }

  ngAfterViewInit() {
    if (this.highlight) {
      this.latestUpdateInput.nativeElement.focus();
      this.latestUpdateInput.nativeElement.click();
    }
    if (this.titleTodo.nativeElement.offsetHeight > 95) {
      this.showMoreInfo = true;
    }
  }

  createForm() {
    this.form = new UntypedFormGroup({
      comments: new UntypedFormControl(this.todo.comments),
    });
  }

  edit(event) {
    event.stopPropagation();
    this.editMode = true;
    this.onEdit.emit(true);
    setTimeout(() => {
      this.todoInput.nativeElement.focus();
    }, 100);
  }

  deleteItem(event) {
    event.stopPropagation();
    this.delete.emit({
      subtaskIndex: this.subtaskIndex,
      todoIndex: this.toDoindex,
    });
  }

  onAssingedUserSelected(event: any) {
    const data = {
      subtaskIndex: this.subtaskIndex,
      todoIndex: this.toDoindex,
      assignedUsers: event,
    };
    this.changeAssignedUsers.next(data);
  }

  save() {
    if (this.form.get("comments").value.trim().length == 0) {
      this.toastrService.danger("Subtask title cannot be blank", "Error", {
        duration: 60000,
        destroyByClick: true,
      });
    } else {
      const data = {
        comments: this.form.get("comments").value,
        subtaskIndex: this.subtaskIndex,
        todoIndex: this.toDoindex,
      };
      this.editMode = false;
      this.onEdit.emit(false);
      this.saveComments.emit(data);
    }
  }

  onModelChange() {
    if (this.todo.latestUpdate.trim().length == 0) {
      const data = {
        isNotCompleted: this.todo.isNotCompleted,
        isCompleted: false,
        subtaskIndex: this.subtaskIndex,
        todoIndex: this.toDoindex,
        focusToNext: false,
        kForAll: this.todo.kForAll,
      };
      this.changeStatuses.emit(data);
    }
  }

  completedChanged(event) {
    if (this.todo.latestUpdate && this.todo.latestUpdate.trim()) {
      const targretStatus = !this.todo.isCompleted;
      this.todo.isNotCompleted = false;
      const data = {
        isNotCompleted: this.todo.isNotCompleted,
        isCompleted: targretStatus,
        subtaskIndex: this.subtaskIndex,
        todoIndex: this.toDoindex,
        focusToNext: false,
        kForAll: this.todo.kForAll,
      };
      this.todo.isDisabled = true;
      this.changeStatuses.emit(data);
    } else {
      this.toastrService.danger(
        "To R a todo, you have to have written something in the text box, confirming why it should be R",
        "Error",
        { duration: 60000, destroyByClick: true },
      );
      this.todo.isCompleted = false;
    }
    event.stopPropagation();
    event.preventDefault();
  }

  notCompletedChanged(event) {
    event.stopPropagation();
    if (this.todo.isCollapsed && !this.isGroupManager) return;
    const targretStatus = !this.todo.isNotCompleted;
    this.todo.isCompleted = false;
    const data = {
      isNotCompleted: targretStatus,
      isCompleted: this.todo.isCompleted,
      subtaskIndex: this.subtaskIndex,
      todoIndex: this.toDoindex,
      focusToNext: false,
      kForAll: this.todo.kForAll,
    };
    this.todo.isDisabled = true;
    this.changeStatuses.emit(data);
  }

  setStatus(isDone: boolean, focusToNext = true, kForAll = false) {
    const data = {
      isNotCompleted: !isDone,
      isCompleted: isDone,
      subtaskIndex: this.subtaskIndex,
      todoIndex: this.toDoindex,
      focusToNext: focusToNext,
      kForAll: kForAll,
    };
    if (isDone) {
      if (this.todo.latestUpdate && this.todo.latestUpdate.trim()) {
        this.changeStatuses.emit(data);
      } else {
        this.toastrService.danger(
          "To R a todo, you have to have written something in the text box, confirming why it should be R",
          "Error",
          { duration: 60000, destroyByClick: true },
        );
      }
    } else {
      this.changeStatuses.emit(data);
    }
  }

  enableTableEdit(todoEditMode: TodoEditMode) {
    this.tableEditMode = todoEditMode;
  }

  saveTableLatestUpdate() {
    this.tableEditMode = TodoEditMode.NONE;
    const nn = this.todo.latestUpdate;
    // this.todo.latestUpdate.replace(/(\r\n|\n|\r)/gm, "");
    this.todo.latestUpdate = nn;
    const data = {
      latestUpdate: nn,
      subtaskIndex: this.subtaskIndex,
      todoIndex: this.toDoindex,
    };
    this.editMode = false;
    this.onEdit.emit(false);
    this.saveLatestUpdate.emit(data);
    // if (needFocus) {
    //   document.getElementById(`subtask-editable-todo-done-button-${this.subtaskIndex}-${this.toDoindex}`).focus();
    // }
  }

  focusOut() {
    document
      .getElementById(
        `subtask-editable-todo-done-button-${this.subtaskIndex}-${this.toDoindex}`,
      )
      .focus();
  }

  saveTaskResponse() {
    this.addTaskResponse.emit({
      groupResponse: this.task.groupResponse,
      latestUpdate: this.task.latestUpdate,
    });
  }

  toogleDetails() {
    this.subtasksService.toogleTodoDetails(this.todo.id);
  }

  toggleInfinity(event) {
    event.stopPropagation();
    this.setInfinity.emit({
      isRecurring: !this.todo.isRecurring,
      subtaskIndex: this.subtaskIndex,
      todoIndex: this.toDoindex,
    });
  }

  doNothing(event: any) {
    event.preventDefault();
  }

  public stopPropagation(event): void {
    event.stopPropagation();
  }

  readonly isConfirmable = () => {
    return (
      this.todo.latestUpdate &&
      this.todo.latestUpdate.trim() &&
      !this.todo.isCompleted
    );
  };

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  collapsedChange($event: boolean) {
    if (this.allCollapsed) return;

    this.userTODOS = JSON.parse(localStorage.getItem(storageKeyTODOS));
    this.todo.isCollapsed = $event;
    const todoIndex = this.userTODOS.todos.findIndex(
      (todos) => this.todo.id == todos.id,
    );

    this.userTODOS.todos[todoIndex].isCollapsed = $event;
    localStorage.setItem(
      storageKeyTODOS,
      JSON.stringify({
        todos: this.userTODOS.todos,
        todosIds: this.userTODOS.todosIds,
      }),
    );
  }

  getComments(comments: { date: string; author: string; comment: string }[]) {
    let result =
      "---------------------------------------<br>HISTORY<br>---------------------------------------<br>";
    comments.forEach((e) => {
      result += `${e.date} ${e.author}<br>${e.comment}<br>--------------------<br>`;
    });
    return result;
  }

  toggleExpand() {
    this.expandText = !this.expandText;
  }

  public getSubtaskLatestUpdate(latestUpdate: string | null) {
    if (!latestUpdate) {
      return "";
    }

    return latestUpdate.replace(/[\s\r\n\t]+/g, "").substring(0, 50);
  }
}
