import { Pipe, PipeTransform } from '@angular/core';
import {
  ProfessionalRolesModel
} from "../../../shared/models/dictionaries/professional-roles/professional-roles-model";

@Pipe({name: 'roleType'})
export class RoleTypeNamePipe implements PipeTransform {
  transform(value: number | string, dict: ProfessionalRolesModel[]): string {
    if (value == 0 || value == null) { return ''; }
    if (value == 32) { return 'In training'; }

    if (typeof value == "string") {
      value = parseInt(value, 10);
    }

    return dict.filter((role) => role.id == value )[0].roleName;
  }
}
