import { APP_BASE_HREF } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CoreModule } from "./@core/core.module";

import { registerLocaleData } from "@angular/common";
import localeEnGb from "@angular/common/locales/en-GB";

registerLocaleData(localeEnGb);

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { ThemeModule } from "./@theme/theme.module";
import { AuthInterceptor } from "./shared/auth.interceptor";
import { AuthModule } from "./pages/auth/auth.module";
import { SharedModule } from "./shared/shared.module";
import { NewContractComponent } from "./pages/employess/contracts/new-contract/new-contract.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NewEmployeeComponent } from "./pages/employess/new-employee/new-employee.component";
import { EditEmployeeComponent } from "./pages/employess/edit-employee/edit-employee.component";
import { TableService } from "./pages/shared/table/table.service";
import { NewUserComponent } from "./pages/users/new-user/new-user.component";
import { EditUserComponent } from "./pages/users/edit-user/edit-user.component";
import { PagesSharedModule } from "./pages/shared/pages-shared.module";
import { NewCareHomeComponent } from "./pages/care-homes/new-care-home/new-care-home.component";
import { EditCareHomeComponent } from "./pages/care-homes/edit-care-home/edit-care-home.component";
import { NewSicknessComponent } from "./pages/employess/sickness/new-sickness/new-sickness.component";
import { QuestionDialogComponent } from "./pages/shared/question-dialog/question-dialog.component";
import { NewAnnualLeavingComponent } from "./pages/employess/annual-leaving/new-annual-leaving/new-annual-leaving.component";
import { NewResidentComponent } from "./pages/residents/new-resident/new-resident.component";
import { EditContractComponent } from "./pages/employess/contracts/edit-contract/edit-contract.component";
import { EditRoomsComponent } from "./pages/care-homes/edit-care-home/edit-rooms/edit-rooms.component";
import { ImageEditorComponent } from "./pages/shared/image-editor/image-editor.component";
import { NewResidentStayComponent } from "./pages/residents/new-resident-stay/new-resident-stay.component";
import { EditResidentStayComponent } from "./pages/residents/edit-resident-stay/edit-resident-stay.component";
import { EditAnnualLeavingComponent } from "./pages/employess/annual-leaving/edit-annual-leaving/edit-annual-leaving.component";
import { EditSicknessComponent } from "./pages/employess/sickness/edit-sickness/edit-sickness.component";
import {
  NbTooltipModule,
  NbWindowModule,
  NbPopoverModule,
  NbThemeModule,
  NbLayoutModule,
  NbAccordionModule,
  NbCheckboxModule,
  NbFormFieldModule,
} from "@nebular/theme";
import { NewPharmacyComponent } from "./pages/residents/pharmacies/new-pharmacy/new-pharmacy.component";
import { NewReplacementComponent } from "./pages/employess/replacement/new-replacement/new-replacement.component";
import { NewMedicalOrderComponent } from "./pages/residents/medical-orders/new-medical-order/new-medical-order.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from "@angular/material/core";
import { MomentUtcDateAdapter } from "./utilities/moment-utc.adapter";
import { EditCareHomeManagerComponent } from "./pages/care-homes/edit-care-home-manager/edit-care-home-manager.component";
import { EditResidentComponent } from "./pages/residents/edit-resident/edit-resident.component";
import { NewTaskComponent } from "./pages/task-management/tasks/new-task/new-task.component";
import { NewTaskBoardComponent } from "./pages/task-management/task-boards/new-task-board/new-task-board.component";
import { EditTaskComponent } from "./pages/task-management/tasks/edit-task/edit-task.component";
import { FastEditorComponent } from "./pages/task-management/tasks/fast-editor/fast-editor.component";
import { ResidentLeftComponent } from "./pages/residents/resident-left/resident-left.component";
import { CopyTaskComponent } from "./pages/task-management/tasks/copy-task/copy-task.component";
import { NewAutomaticTaskComponent } from "./pages/task-management/automatic-tasks/new-automatic-task/new-automatic-task.component";
import { NewMultipleTaskComponent } from "./pages/task-management/tasks/new-multiple-task/new-multiple-task.component";
import { NewTrackedEventComponent } from "./pages/task-management/automatic-tasks/new-tracked-event/new-tracked-event.component";
import { TaskBoardsCommentComponent } from "./pages/task-management/task-boards/task-boards-comment/task-boards-comment.component";
import { EmployeeLeftComponent } from "./pages/employess/employee-left/employee-left.component";
import { AdminAnalyzerDetailsComponent } from "./pages/dashboard/admin-analyzer/admin-analyzer-details/admin-analyzer-details.component";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { EditDocumentCategoriesComponent } from "./pages/care-homes/edit-care-home/edit-document-categories/edit-document-categories.component";
import { EmployeeInTrainingComponent } from "./pages/employess/employee-in-training/employee-in-training.component";
import { RotaTableDuplicateComponent } from "./pages/employess/rota/rota-table-duplicate/rota-table-duplicate.component";
import { RotaTableAcceptationComponent } from "./pages/employess/rota/rota-table-acceptation/rota-table-acceptation.component";
import { RotaTableCommentComponent } from "./pages/employess/rota/rota-table-comment/rota-table-comment.component";
import { RotaTableConfirmationComponent } from "./pages/employess/rota/rota-table-confirmation/rota-table-confirmation.component";
import { RotaTableHourModalComponent } from "./pages/employess/rota/rota-table-hour-modal/rota-table-hour-modal.component";
import { NewPayrollComponent } from "./pages/employess/payroll/new-payroll/new-payroll.component";
import { CoursesChangeStatusComponent } from "./pages/employess/courses-overview/courses-change-status/courses-change-status.component";
import { AlExtrasReportComponent } from "./pages/care-homes/edit-care-home/al-extras-report/al-extras-report.component";
import { AutosizeModule } from "ngx-autosize";
import { TasksDetailsComponent } from "./pages/dashboard/admin-analyzer/tasks-details/tasks-details.component";
import { NewGeneralPracticeComponent } from "./pages/residents/general-practice/new-general-practice/new-general-practice.component";
import { MenuChoicesFormComponent } from "./pages/residents/menu-choices/menu-choices-form/menu-choices-form.component";
import { ActivitySheetCopyComponent } from "./pages/residents/activity-sheet/activity-sheet-copy/activity-sheet-copy.component";
import { ActivitySheetRepeatsComponent } from "./pages/residents/activity-sheet/activity-sheet-repeats/activity-sheet-repeats.component";
import { MenuChoicesImportComponent } from "./pages/residents/menu-choices/menu-choices-import/menu-choices-import.component";
import { QuestionDialogParameterComponent } from "./pages/shared/question-dialog/question-dialog-parameters/question-dialog-parameter.component";
import { GroupsManagerDetailsComponent } from "./pages/administration/groups-manager/groups-manager-details/groups-manager-details.component";
import { RaggDetailsComponent } from "./pages/administration/ragg/ragg-details/ragg-details.component";
import { TimeChartComponent } from "./pages/task-management/tasks/time-chart/time-chart.component";
import { OverviewModule } from "./pages/employess/overview/overview.module";
import { EmployeeActivationChecklistComponent } from "./pages/employess/employee-activation-checklist/employee-activation-checklist.component";
import { CareHomeActivationChecklistComponent } from "./pages/care-homes/edit-care-home/carehome-activation-checklist/carehome-activation-checklist.component";
import { MatTableModule } from "@angular/material/table";
import { NewShiftTableExtrasComponent } from "./pages/employess/day-shifts/new-shift-table-extras/new-shift-table-extras.component";
import { ShiftTableCommentComponent } from "./pages/employess/day-shifts/shift-table-comment/shift-table-comment.component";
import { TaskCategoriesDetailsComponent } from "./pages/administration/task-categories/task-categories-details/task-categories-details.component";
import { ChooseContractComponent } from "./pages/employess/contracts/choose-contract/choose-contract.component";
import { TaskboardConfigurationDetailsComponent } from "./pages/administration/configuration/details/taskboard-configuration-details.component";
import { MessageGroupsDetailsComponent } from "./pages/administration/message-groups/message-groups-details/message-groups-details.component";
import { ContactBookPermissionsDetailsComponent } from "./pages/administration/contact-book-permissions/message-groups-details/contact-book-permissions-details.component";
import { ProfilesManagerDetailsComponent } from "./pages/administration/profiles-manager/profiles-manager-details/profiles-manager-details.component";
import { EmployeeLeaveStatusComponent } from "./pages/employess/employee-leave-status/employee-leave-status.component";
import { MatTabsModule } from "@angular/material/tabs";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CoursesGenerateComponent } from "./pages/employess/courses-summary/courses-generate/courses-generate.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { NewTaskTemplateComponent } from "./pages/task-management/task-templates/new-task-template/new-task-template.component";
import { ChooseTemplateDialogComponent } from "./pages/shared/choose-template/choose-template.component";
import { ResidentLeftDateDialogComponent } from "./pages/shared/resident-left-date-dialog/resident-left-date-dialog.component";
import { AngularResizedEventModule } from "angular-resize-event";
import { DialogModule } from "./shared/dialog/public-api";
import { NewMessageTemplateComponent } from "./pages/administration/message-templates/new-message-template/new-message-template.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { EmployeeLeavingLeftDialogComponent } from "./pages/employess/employee-leavingleft-dialog/employee-leavingleft-dialog.component";
import { RolesMultiSiteComponent } from "./pages/employess/edit-employee/roles-multi-site/roles-multi-site.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NewDocumentsComponent } from "./pages/employess/contracts/new-documents/new-documents.component";
import { MatStepperModule } from "@angular/material/stepper";
import { MatButtonModule } from "@angular/material/button";
import { RotaMigrationComponent } from "./pages/employess/contracts/rota-migration/rota-migration.component";
import { RotaMigrationTableComponent } from "./pages/employess/contracts/rota-migration-table/rota-migration-table.component";
import { RotaMigrationTableWeekComponent } from "./pages/employess/contracts/rota-migration-table-week/rota-migration-table-week.component";
import { RotaMigrationTableWeekCellComponent } from "./pages/employess/contracts/rota-migration-table-week-cell/rota-migration-table-week-cell.component";
import { RotaPipesModule } from "./@theme/pipes/rota-data-pipes/rotaPipes.module";
import { NewTrainingCareHomeComponent } from "./pages/care-homes/new-training-care-home/new-training-care-home.component";
import { EmployeeMigrationToolComponent } from "./pages/employess/employee-migration-tool/employee-migration-tool.component";
import { AgmCoreModule } from "@agm/core";
import { Angular2SmartTableModule } from "angular2-smart-table";
import { InboxModule } from "./pages/inbox/inbox.module";
import { NgxSuperCroppieModule } from "ngx-super-croppie";
import { TaskTabsComponent } from "./task-tabs/task-tabs.component";
import { NgDragDropModule } from "ng-drag-drop";
import { NgxLinkifyjsModule } from "ngx-linkifyjs";

export const DATE_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "DD/MM/YYYY",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    PagesSharedModule,
    NgSelectModule,
    Angular2SmartTableModule,
    OverviewModule,
    ThemeModule.forRoot(),
    CoreModule.forRoot(),
    NbWindowModule.forRoot(),
    NbPopoverModule,
    NbTooltipModule,
    MatTableModule,
    MatDatepickerModule,
    NbLayoutModule,
    NbEvaIconsModule,
    NbCheckboxModule,
    NbThemeModule.forRoot({ name: "corporate" }),
    AutosizeModule,
    MatTabsModule,
    DragDropModule,
    ScrollingModule,
    AngularResizedEventModule,
    DialogModule.forRoot(),
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatButtonModule,
    NbAccordionModule,
    RotaPipesModule,
    ThemeModule,
    AgmCoreModule,
    FormsModule,
    InboxModule,
    NgxSuperCroppieModule,
    NgxLinkifyjsModule.forRoot(),
    NgDragDropModule.forRoot(),
    NbFormFieldModule,
  ],
  declarations: [
    AppComponent,
    NewContractComponent,
    EditContractComponent,
    NewEmployeeComponent,
    NewTrainingCareHomeComponent,
    EmployeeMigrationToolComponent,
    EditEmployeeComponent,
    NewUserComponent,
    EditUserComponent,
    NewCareHomeComponent,
    EditCareHomeComponent,
    NewSicknessComponent,
    NewAnnualLeavingComponent,
    NewResidentComponent,
    EditResidentComponent,
    CoursesChangeStatusComponent,
    CoursesGenerateComponent,
    NewAnnualLeavingComponent,
    EditRoomsComponent,
    ImageEditorComponent,
    NewResidentStayComponent,
    EditResidentStayComponent,
    EditAnnualLeavingComponent,
    EditSicknessComponent,
    RotaTableDuplicateComponent,
    NewPharmacyComponent,
    NewReplacementComponent,
    NewMedicalOrderComponent,
    NewTaskComponent,
    NewPayrollComponent,
    NewGeneralPracticeComponent,
    RotaTableAcceptationComponent,
    RotaTableHourModalComponent,
    EditCareHomeManagerComponent,
    NewTaskBoardComponent,
    RotaTableCommentComponent,
    EditTaskComponent,
    FastEditorComponent,
    TimeChartComponent,
    MenuChoicesFormComponent,
    RotaTableConfirmationComponent,
    ResidentLeftDateDialogComponent,
    ActivitySheetCopyComponent,
    ActivitySheetRepeatsComponent,
    ResidentLeftComponent,
    CopyTaskComponent,
    NewAutomaticTaskComponent,
    NewMultipleTaskComponent,
    NewTrackedEventComponent,
    TaskBoardsCommentComponent,
    EmployeeLeftComponent,
    EmployeeInTrainingComponent,
    EmployeeLeaveStatusComponent,
    CareHomeActivationChecklistComponent,
    MenuChoicesImportComponent,
    AdminAnalyzerDetailsComponent,
    TasksDetailsComponent,
    EditDocumentCategoriesComponent,
    EmployeeActivationChecklistComponent,
    AlExtrasReportComponent,
    GroupsManagerDetailsComponent,
    RaggDetailsComponent,
    NewShiftTableExtrasComponent,
    ShiftTableCommentComponent,
    TaskCategoriesDetailsComponent,
    ChooseContractComponent,
    TaskboardConfigurationDetailsComponent,
    MessageGroupsDetailsComponent,
    ContactBookPermissionsDetailsComponent,
    ProfilesManagerDetailsComponent,
    NewTaskTemplateComponent,
    ChooseTemplateDialogComponent,
    NewMessageTemplateComponent,
    EmployeeLeavingLeftDialogComponent,
    RolesMultiSiteComponent,
    NewDocumentsComponent,
    RotaMigrationComponent,
    RotaMigrationTableComponent,
    RotaMigrationTableWeekComponent,
    RotaMigrationTableWeekCellComponent,
    TaskTabsComponent,
  ],
  exports: [ImageEditorComponent],
  bootstrap: [AppComponent],
  providers: [
    { provide: APP_BASE_HREF, useValue: "/" },
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    NgxSuperCroppieModule,
  ],
})
export class AppModule {}
