export const ContractsApiUrls = {
  contracts: '/employees/contracts',
  allContracts: '/employees/contracts?activeOnly=false',
  allContractsXLS: '/employees/contracts-xls?activeOnly=false',
  employeeContractsList: '/contracts/list',
  activeContracts: '/employees/contracts?activeOnly=true',
  activeXLSContracts: '/employees/contracts-xls?activeOnly=true',
  contractsAggregate: '/employees/events-aggregate',
  contractsUnaggregated: '/employees/events',
  contractsSkip: '/employees/events?skip-care-home',
  contractsReplacement: '/employees/contracts-replacements',
  activeContractsAggregate: '/employees/events-aggregate?contractEnd<' + Date.now()
};
