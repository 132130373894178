<h1 *ngIf="isBetaVersion" id="beta">BETA</h1>
<div class="logo">
  <img src="assets/images/logo_echo.svg" alt=""/>
</div>
<h1 id="title" class="title">Login to Echo</h1>
<p class="sub-title">Hello! Log in with your login and password.</p>

<nb-alert *ngIf="error && !submitted" outline="danger" role="alert">
  <span class="alert-text">{{ error }}</span>
  <span class="alert-text">{{ additionalErrorMessage }}</span>
</nb-alert>

<nb-alert outline="info" role="info">
  <span class="info-text">
    Hello {{userName}}<br>
    Please set up a new password.
  </span>
</nb-alert>

<form (ngSubmit)="resetPass()" #resetPassForm="ngForm" aria-labelledby="title">
  <div class="form-control-group">
    <label class="label" for="input-passwordNew">New password:</label>
    <input nbInput
           [(ngModel)]="user.passwordNew"
           #passwordNew="ngModel"
           type="password"
           id="input-passwordNew"
           name="passwordNew"
           class="first"
           placeholder="New password"
           autofocus
           fullWidth
           [status]="passwordNew.dirty ? (passwordNew.invalid  ? 'danger' : 'success') : ''"
           required
           [minlength]="8"
           [maxlength]="255"
           [attr.aria-invalid]="passwordNew.invalid && passwordNew.touched ? true : null">
    <ng-container *ngIf="passwordNew.invalid && passwordNew.touched">
      <p class="error-message" *ngIf="passwordNew.errors?.required">
        New password is required!
      </p>
      <p class="error-message" *ngIf="passwordNew.errors?.minlength || passwordNew.errors?.maxlength">
        New password should contains from 8 to 255 characters
      </p>
    </ng-container>
  </div>

  <div class="form-group">
    <label class="label" for="input-re-password">Confirm Password:</label>
    <input nbInput
           [(ngModel)]="user.confirmPassword"
           #rePass="ngModel"
           id="input-re-password"
           name="rePass"
           type="password"
           class="last"
           placeholder="Confirm Password"
           fullWidth
           [status]="rePass.touched
               ? (rePass.invalid || passwordNew.value != rePass.value ? 'danger' : 'success')
               : ''"
           required
           [attr.aria-invalid]="rePass.invalid && rePass.touched ? true : null">
    <ng-container *ngIf="rePass.touched">
      <p class="error-message" *ngIf="rePass.invalid && rePass.errors?.required">
        Password confirmation is required!
      </p>
      <p class="error-message" *ngIf="passwordNew.value != rePass.value && !rePass.errors?.required">
        Password does not match the confirm password.
      </p>
    </ng-container>
  </div>

  <div>
    <div class="password-validate-item" [ngClass]="{'validated-right': validatedItems.upperLetter}">
      <nb-icon *ngIf="validatedItems.upperLetter" icon="checkmark-outline"></nb-icon> <nb-icon *ngIf="!validatedItems.upperLetter" icon="close-outline"></nb-icon> <span>one upper letter</span>
    </div>
    <div class="password-validate-item" [ngClass]="{'validated-right': validatedItems.lowerLetter}">
      <nb-icon *ngIf="validatedItems.lowerLetter" icon="checkmark-outline"></nb-icon> <nb-icon *ngIf="!validatedItems.lowerLetter" icon="close-outline"></nb-icon> <span>one lower letter</span>
    </div>
    <div class="password-validate-item" [ngClass]="{'validated-right': validatedItems.oneNumber}">
      <nb-icon *ngIf="validatedItems.oneNumber" icon="checkmark-outline"></nb-icon> <nb-icon *ngIf="!validatedItems.oneNumber" icon="close-outline"></nb-icon> <span>one number</span>
    </div>
    <div class="password-validate-item" [ngClass]="{'validated-right': validatedItems.oneSpecialChar}">
      <nb-icon *ngIf="validatedItems.oneSpecialChar" icon="checkmark-outline"></nb-icon> <nb-icon *ngIf="!validatedItems.oneSpecialChar" icon="close-outline"></nb-icon> <span>one special char</span>
    </div>
    <div class="password-validate-item" [ngClass]="{'validated-right': validatedItems.minimumLength}">
      <nb-icon *ngIf="validatedItems.minimumLength" icon="checkmark-outline"></nb-icon> <nb-icon *ngIf="!validatedItems.minimumLength" icon="close-outline"></nb-icon> <span>8 chars or more</span>
    </div>
  </div>

  <button nbButton
          fullWidth
          class="btn btn-success mt-2"
          [disabled]="submitted || !isFormValid()">
    CHANGE PASSWORD
  </button>
</form>
