<div class="wizard-page">
  <div class="top-controls">
    <div class="back-group" [routerLink]="'/login'">
      <nb-icon icon="arrow-back-outline"></nb-icon>
      <div>Back</div>
    </div>
  </div>
  <div class="wizard-page--main-content">
    <div class="echo-icon"></div>
    <div class="main-text">
      <div class="main-header">Welcome to the Echo Wizard</div>

      <div class="main-text--message">
        This wizard will guide you through the initial configuration of the
        settings in 4 simple steps. First, you need to create an administrator
        account to have full access to system functions and settings. Let's get
        started
      </div>

      <div class="start-button" [routerLink]="'/auth/register'">
        <div class="stars-icon"></div>
        Start
      </div>
    </div>
  </div>
</div>
