import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { errorMessages } from '../stuff-errors';
import { getValidationStatus, handleValidationErrorMessage, isFormValid } from '../../../utilities/utils';
import { DictionariesService } from '../../../shared/dictionaries/dictionaries.service';

@Component({
  selector: 'new-stuff-group',
  templateUrl: './new-stuff-group.component.html',
  styleUrls: ['./new-stuff-group.component.scss']
})
export class NewStuffGroupComponent implements OnInit {

  @Input() title: string;
  @Input() groupId: number = null;
  @Input() type: 'add' | 'edit' = 'add';
  @Input() group = null;
  @Input() groupDictionaryId: number;
  isIEOrEdge = false;
  form = this.fb.group({
    groupName: [null, Validators.required],
    colorHash: [null, Validators.required]
  });
  public utils = { getValidationStatus, handleValidationErrorMessage, isFormValid };
  public errorMessages = errorMessages;

  constructor(protected ref: NbDialogRef<NewStuffGroupComponent>, private fb: FormBuilder, private dictionariesService: DictionariesService) { }

  ngOnInit(): void {
    this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (this.type === 'edit') {
      this.loadGroup()
    }
  }

  dismiss() {
    this.ref.close();
  }

  save() {
    const formValue = this.form.getRawValue();
    switch (this.type) {
      case 'add': {
        this.dictionariesService.addStuffGroup({
          name: formValue.groupName,
          description: formValue.groupName,
          colorHash: formValue.colorHash
        }, this.groupDictionaryId).subscribe((res) => {
          this.ref.close({
            groupId: res.result
          });
        });
        break;
      }
      case 'edit': {
        this.dictionariesService.updateStuffGroup({
          name: formValue.groupName,
          description: formValue.groupName,
          colorHash: formValue.colorHash
        }, this.groupDictionaryId, this.group.id).subscribe(() => {
          this.ref.close()
        });
        break;
      }
    }
  }

  formValid(): boolean {
    return this.form.valid;
  }

  private loadGroup() {
    this.form.patchValue({
      groupName: this.group.wordFullName,
      colorHash: this.group.colorHash
    });
  }
}
