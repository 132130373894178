<div
  class="floating-card"
  *ngIf="
    ((!isSubMenu ? sideMenuService.isShown : sideMenuService.isSubShown)
      | async) &&
    ((!isSubMenu ? sideMenuService.position : sideMenuService.subPosition)
      | async) as position
  "
  [style.top.px]="calculatePosition(position.top)"
  [style.left.px]="position.left"
>
  <div class="header">
    {{
      (!isSubMenu
        ? sideMenuService.menuIdentifier
        : sideMenuService.subMenuIdentifier
      ) | async
    }}
  </div>
  <div class="divider"></div>
  <div class="content">
    <ng-container
      *ngFor="
        let child of (
          (!isSubMenu
            ? sideMenuService.menuItems
            : sideMenuService.subMenuItems
          ) | async
        ).children
      "
    >
      <div
        class="content-item"
        [ngClass]="{'selected' : (sideMenuService.subMenuIdentifier | async) === child.title}"
        (click)="openOrHide(child)"
        [routerLink]="
          child.children && child.children.length > 0 ? undefined : child.link
        "
        [id]="child.title"
      >
        <div class="left-group"><nb-icon *ngIf="child.icon" [icon]="child.icon"></nb-icon>{{ child.title }}</div>
        <nb-icon *ngIf="child.children" icon="chevron-right-outline"></nb-icon>
      </div>
    </ng-container>
  </div>
</div>
