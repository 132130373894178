<table #table mat-table matSort [dataSource]="dataSource" class="only-table">
  <ng-container matColumnDef="shiftDate">
    <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [ngStyle]="{ width: '90px', 'min-width': '90px', 'max-width': '90px' }" disabled>
      <span>Date</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <span [ngClass]="{ 'color--width': element.isEmployeeRotaSummaryRow }">{{ element.shiftDate }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="avatar">
    <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [ngStyle]="{ width: '50px', 'min-width': '50px', 'max-width': '50px' }" disabled>
      <span>Photo</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <span
        *ngIf="!element.isEmployeeRotaSummaryRow"
        [ngClass]="{ 'center-avatar': thinner }"
        style="cursor: pointer"
        (click)="showEmployee360($event, element.agencyEmployeeId, element.agencyEmployeeFirstName, element.agencyEmployeeSurname, null)"
      >
        <img [src]="element.employeeAvatarId | avatar | async" class="avatar" [ngClass]="{ 'thinner-avatar': thinner }" alt="" />
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="agencyEmployeeFullName">
    <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [ngStyle]="{ width: '170px', 'min-width': '170px', 'max-width': '170px' }" disabled>
      <span>Agency Echo name </span>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="agency-report-table__name-container">
        <span class="agency-report-table__name">{{ element.agencyEmployeeFullName }}</span>
        <span class="agency-report-table__role">{{ element.agencyEmployeeRoleName }}</span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="scheduled">
    <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [ngStyle]="{ width: '160px', 'min-width': '160px', 'max-width': '160px' }" disabled>
      <span>Scheduled</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="!element.isEditMode">
        <span>
          {{ element.scheduled }}
        </span>
      </ng-container>
      <ng-container *ngIf="element.isEditMode">
        <ngx-ui-table-editable-hours-cell [cellForm]="element.scheduledForm"></ngx-ui-table-editable-hours-cell>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="confirmed">
    <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [ngStyle]="{ width: '160px', 'min-width': '160px', 'max-width': '160px' }" disabled>
      <span>Confirmed</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="!element.isEditMode">
        <span [ngClass]="{ 'only-table__hours--warning': element.isConfirmedDifferentThenScheduled }">
          {{ element.confirmed }}
        </span>
      </ng-container>
      <ng-container *ngIf="element.isEditMode">
        <ngx-ui-table-editable-hours-cell [cellForm]="element.confirmedForm"></ngx-ui-table-editable-hours-cell>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="confirmedHrs">
    <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [ngStyle]="{ width: '115px', 'min-width': '115px', 'max-width': '115px' }" disabled>
      <span>Hours confirmed</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <span [ngClass]="{ 'color--width': element.isEmployeeRotaSummaryRow }">{{ element.confirmedHrs }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="gpsPosition">
    <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [ngStyle]="{ width: '70px', 'min-width': '70px', 'max-width': '70px' }" disabled>
      <span>Location</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf="!element.isEmployeeRotaSummaryRow" class="display-flex display-flex--justify-center">
        <ng-container *ngIf="element.gpsPosition?.length > 0; else noShiftInfo">
          <ngx-ui-table-gps-cell
            *ngFor="let position of element.gpsPosition"
            [employeePositionName]="element.agencyEmployeeRoleName"
            [employeeFullName]="element.agencyEmployeeFullName"
            [gpsData]="position"
          ></ngx-ui-table-gps-cell>
        </ng-container>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="confirmedPhoto">
    <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [ngStyle]="{ width: '70px', 'min-width': '70px', 'max-width': '70px' }" disabled>
      <span>Photo</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf="!element.isEmployeeRotaSummaryRow" class="display-flex display-flex--justify-center">
        <ng-container *ngIf="element.gpsPosition?.length > 0; else noShiftInfo">
          <ngx-ui-table-photo-cell
            *ngFor="let position of element.confirmedPhoto"
            [employeePositionName]="element.agencyEmployeeRoleName"
            [employeeFullName]="element.agencyEmployeeFullName"
            [photoData]="position"
          ></ngx-ui-table-photo-cell>
        </ng-container>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="acceptedConfirmed">
    <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [ngStyle]="{ width: '125px', 'min-width': '125px', 'max-width': '125px' }" disabled>
      <span>Accepted / confirmed?</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="agency-report-table__status-wrapper">
        <span
          class="agency-report-table__status"
          [nbTooltip]="element.statusHover"
          [ngClass]="{
            'agency-report-table__status--to-verified': element.statusId === ShiftStatus.TO_VERIFIED,
            'agency-report-table__status--denied': element.statusId === ShiftStatus.DENY,
            'agency-report-table__status--accepted': element.statusId === ShiftStatus.ACCEPTED,
            'agency-report-table__status--waiting': element.statusId === ShiftStatus.WAITING_FOR_EXPLANATION
          }"
        >
          {{ element.status }}
        </span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="editAgency">
    <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [ngStyle]="{ width: '65px', 'min-width': '65px', 'max-width': '65px' }" disabled>
      <span>Edit</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf="element.isEditMode" (click)="columns[9].action($event, element)" class="agency-report-table__edit-actions cursor--pointer">
        <i class="nb-checkmark agency-report-table__check-icon" aria-hidden="true"></i>
        <i class="nb-close agency-report-table__close-icon" aria-hidden="true"></i>
      </div>
      <div *ngIf="!element.isEditMode" (click)="columns[9].action($event, element)" class="agency-report-table__edit-actions cursor--pointer">
        <i class="nb-edit agency-report-table__edit-icon" aria-hidden="true"></i>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="agencyName">
    <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [ngStyle]="{ width: '160px', 'min-width': '160px', 'max-width': '160px' }" disabled>
      <span>Name of agency that worked</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="!element.isEmployeeRotaSummaryRow">
        <ngx-ui-agencies-list [agencies]="agencies" [agencyId]="element.agencyId" (onAgencyChanged)="agencyChanged($event, element, 10)"></ngx-ui-agencies-list>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="otherNotes">
    <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [ngStyle]="{ width: '200px', 'min-width': '200px', 'max-width': '200px' }" disabled>
      <span>Other notes</span>
    </th>
    <td mat-cell *matCellDef="let element; index as i" (click)="setTextareaInEditMode(element)">
      <ng-container *ngIf="!element.isEmployeeRotaSummaryRow">
        <ng-container *ngIf="element.isTextareaInEditMode; else textareaNotEditMode">
          <textarea
            #otherNotesTextarea
            autosize
            [maxRows]="2"
            class="textarea-editable cursor--text"
            [disabled]="!element.agencyId"
            [title]="!element.agencyId ? 'Please select agency first' : ''"
            (blur)="onTextFieldChange($event); textAreaChange(element, $event.target.value, 11)"
            [innerHTML]="element.otherNotes"
          ></textarea>
        </ng-container>
        <ng-template #textareaNotEditMode>
          <span class="agency-report-table__other-notes-text cursor--text">{{ element.otherNotes }}</span>
        </ng-template>
      </ng-container>
    </td>
  </ng-container>


  <ng-container matColumnDef="agencyEmployeeFullNameEnteredOnSignIn">
    <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [ngStyle]="{ width: '140px', 'min-width': '140px', 'max-width': '140px' }" disabled>
      <span>Agency Employee Full Name</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <span>{{ element.agencyEmployeeFullNameEnteredOnSignIn }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="overHours">
    <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [ngStyle]="{ width: '200px', 'min-width': '200px', 'max-width': '200px' }" disabled>
      <span>Over Hours</span>
    </th>
    <td mat-cell *matCellDef="let element" (click)="!element.isEmployeeRotaSummaryRow ? columns[columns.length - 1].action($event, element) : ''">
      <ng-container *ngIf="!element.isEmployeeRotaSummaryRow">
        {{ element.confirmedHrs }}
      </ng-container>
    </td>
  </ng-container>

  <ng-template #noShiftInfo>
    <i class="nb-close only-table__no-info-icon" aria-hidden="true"></i>
  </ng-template>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    class="thinner NEW ROW"
    [ngClass]="{
      'row-disabled': row.isLTSickOrMaternity,
      'row-not-started-yet': row.isNotStartedYet,
      'thicker-line': row.isThickerLineNeeded,
      'graded-row': row.isEmployeeRotaSummaryRow && !row.isSummaryBlackRow,
      'black-row': row.isEmployeeRotaSummaryRow && row.isSummaryBlackRow
    }"
  ></tr>
</table>
