export const environment = {
  production: true,
  portableUrl: "https://echo-portable.rosecaregroup.co.uk",
  desktopUrl: "https://echo.rosecaregroup.co.uk",
  apiUrl: 'https://api.echo.rosecaregroup.co.uk/api',
  wsUrl: 'wss://ws.rosecaregroup.co.uk',
  apiVersion: 6.02,
  frontEndBuild: 20240408.12,
  beta: false
};
