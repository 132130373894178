import { Injectable } from '@angular/core';

// Third party
import { BehaviorSubject, Observable } from 'rxjs';

// Models
import { CareHomeModel } from '../../models/care-homes/care-home/care-home-model';

@Injectable({
  providedIn: 'root',
})
export class SelectedCareHomeStateService {
  private _selectedCareHome$: BehaviorSubject<CareHomeModel> = new BehaviorSubject<CareHomeModel>(null);

  get selectedCareHome$(): Observable<CareHomeModel> {
    return this._selectedCareHome$.asObservable();
  }

  setSelectedCareHome(value: CareHomeModel): void {
    this._selectedCareHome$.next(value);
  }
}
