<label class="switch-label">
  <span class="first" [class.active]="!value">
    {{firstValueLabel}}
  </span>

  <div class="switch">
    <input type="checkbox" [checked]="value" (change)="changeValue()" [disabled]="isDisabled">
    <span class="slider" [ngClass]="{'disabled': isDisabled}"></span>
  </div>

  <span class="second" [class.active]="value">
    {{secondValueLabel}}
  </span>
</label>