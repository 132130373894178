import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { errorMessages } from '../../users/user-errors';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { handleValidationErrorMessage, getValidationStatus, isFormValid, isFieldInvalid } from '../../../utilities/utils';
import { equalToFieldValue, password } from '../../../utilities/validators';
import { NbToastrService } from '@nebular/theme';
import { AuthUserService } from '../../../@core/data/auth-user.service';
import { AuthService } from '../../../@core/auth/auth.service';
import { PermissionsService } from '../../../@core/data/permissions.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ngx-confirm-password-input',
  templateUrl: './confirm-password-input.component.html',
  styleUrls: ['./confirm-password-input.component.scss']
})
export class ConfirmPasswordInputComponent implements OnInit {
  @Input() userId: any;
  @Output() onClose = new EventEmitter<any>();
  public errorMessages = errorMessages;
  public error: string = '';
  public formPass: UntypedFormGroup;
  public utils = { getValidationStatus, handleValidationErrorMessage, isFormValid, isFieldInvalid };
  public permissions = <any>{};

  constructor(protected authUserService: AuthUserService,
              protected authService: AuthService,
              protected toastrService: NbToastrService,
              protected permissionsService: PermissionsService,
              @Inject(DOCUMENT) private document: Document) {
    this.permissions = this.permissionsService.getPermissions();
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.formPass = new UntypedFormGroup({
      changePassword: new UntypedFormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(25), password]),
      confirmPassword: new UntypedFormControl(null, [Validators.required])
    });

    if (!this.permissions.USER_MANAGER_CHANGE_PASSWORD) {
      this.formPass.addControl('oldPassword', new UntypedFormControl(null, [Validators.required]));
    }

    this.formPass.get('changePassword')
      .valueChanges
      .subscribe(() => {
        const control = this.formPass.get('confirmPassword');
        control.setValidators([Validators.required, equalToFieldValue(this.formPass.get('changePassword').value)]);
        control.updateValueAndValidity();
      }
    );

    this.formPass.get('confirmPassword')
      .valueChanges
      .subscribe(() => {
        this.formPass
              .get('confirmPassword')
              .setValidators([Validators.required, equalToFieldValue(this.formPass.get('changePassword').value)]);
      }
    );
  }

  changePassword() {
    const data = {
      originUrl: this.document.location.origin,
      passwordOld: this.permissions.USER_MANAGER_CHANGE_PASSWORD ? 'emptyPassword' : this.formPass.get('oldPassword').value,
      passwordNew: this.formPass.get('confirmPassword').value
    };
    return this.authService.resetUserPassword(data, this.userId).subscribe((response: any) => {
      this.toastrService.success(response.message, 'Success');
      this.onClose.emit();
    },
    (err) => this.handleError(err));
  }

  handleError(err: any): void {
    this.error = err.error.errors[0].message;
    this.toastrService.danger(this.error, 'Error', { duration: 60000, destroyByClick: true });
  }

  close() {
    this.onClose.emit();
  }
}
