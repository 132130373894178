import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  getValidationStatus,
  handleValidationErrorMessage,
  isFormValid,
  validateFormControlsByName,
  getErrorMessage,
} from "../../../../utilities/utils";
import { Subscription } from "rxjs";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { TableService } from "../../../shared/table/table.service";
import { SicknessService } from "../../../../shared/employess/sickness/sickness.service";
import { NbToastrService, NbDialogService } from "@nebular/theme";
import { invalidDate } from "../../../../utilities/validators";
import { NewReplacementComponent } from "../../replacement/new-replacement/new-replacement.component";
import { CancelEventDialogComponent } from "../../../shared/cancel-event-dialog/cancel-event-dialog.component";
import { EmployeeElementType } from "../../employee-element-type.enum";
import { QuestionDialogComponent } from "../../../shared/question-dialog/question-dialog.component";
import * as moment from "moment";
import { eventsErrors } from "../../events-errors";
import { PermissionsService } from "../../../../@core/data/permissions.service";
import { FormMode } from "../../../../utilities/form-mode.enum";
import { EmployessService } from "../../../../shared/employess/employess.service";
import { AnnualLeavingService } from "../../../../shared/employess/annual-leaving/annual-leaving.service";

@Component({
  selector: "ngx-edit-sickness",
  templateUrl: "./edit-sickness.component.html",
  styleUrls: [
    "../../annual-leaving/edit-annual-leaving/edit-annual-leaving.component.scss",
  ],
})
export class EditSicknessComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public errorMessages = eventsErrors;
  public FormMode: typeof FormMode = FormMode;
  public mode: FormMode = FormMode.EDIT;
  public sicknessData: any;
  public employeeId: number;
  public form: UntypedFormGroup;
  public controls = ["employeeId", "eventType", "sicknessStart", "sicknessEnd"];
  public utils = {
    getValidationStatus,
    handleValidationErrorMessage,
    isFormValid,
    validateFormControlsByName,
  };
  public columns = ["Contract ID", "Role", "Contract", "Given"];
  public replacements: any = [];
  public cancelled: boolean;
  public elementId: number;
  public tableIdentifier: string = "";
  public permissions = <any>{};
  public replacementSettingsNotCancelled = {
    actions: {
      add: false,
      edit: false,
      delete: true,
      class: "action-width",
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash" aria-hidden="true"></i>',
    },
    columns: {
      replacementEmployeeNameSurname: {
        title: "First Name",
        filter: false,
        sort: true,
      },
      replacementFrom: {
        title: "Start",
        filter: false,
        sort: true,
      },
      replacementTo: {
        title: "End",
        filter: false,
        sort: true,
      },
      replacementDailyHrs: {
        title: "Replacement Daily Hrs",
        filter: false,
        sort: true,
      },
      replacementComments: {
        title: "Comments",
        filter: false,
        sort: true,
      },
    },
  };
  public replacementSettingsCancelled = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    columns: {
      replacementEmployeeNameSurname: {
        title: "First Name",
        filter: false,
        sort: true,
      },
      replacementFrom: {
        title: "Start",
        filter: false,
        sort: true,
      },
      replacementTo: {
        title: "End",
        filter: false,
        sort: true,
      },
      replacementDailyHrs: {
        title: "Replacement Daily Hrs",
        filter: false,
        sort: true,
      },
      replacementComments: {
        title: "Comments",
        filter: false,
        sort: true,
      },
    },
  };

  loadReplacements = () => this.replacements;

  addReplacement = () => {
    this.tableService.openWindowWithBackStack(
      NewReplacementComponent,
      "Add new replacement",
      {
        component: EditSicknessComponent,
        title: `Event information - ${this.sicknessData.eventNo}`,
        elementId: this.elementId,
      },
      {
        source: EmployeeElementType.SICK,
        employeeId: this.employeeId,
        eventNo: this.sicknessData.eventNo,
        eventId: this.elementId,
        contractId: this.sicknessData.contractId,
        contractRoleId: this.sicknessData.contractRoleId,
      },
    );
  };

  deleteReplacement = (data: any) => {
    this.subscription.add(
      this.sicknessService.deleteReplacement(data.data.replacementId).subscribe(
        (response: any) => {
          data.confirm.resolve();
          this.toastrService.success(response.message, "Success");
        },
        (err) =>
          this.toastrService.danger(getErrorMessage(err), "Error", {
            duration: 60000,
            destroyByClick: true,
          }),
      ),
    );
  };

  constructor(
    private tableService: TableService,
    private sicknessService: SicknessService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private permissionsService: PermissionsService,
    private employeesService: EmployessService,
    private annualLeavingService: AnnualLeavingService,
  ) {
    this.permissions = this.permissionsService.getPermissions();
  }

  ngOnInit() {
    this.elementId = this.tableService.getValue().elementId;
    this.mode = this.tableService.getValue().mode;

    this.tableIdentifier = this.tableService.getValue().tableIdentifier || "";

    this.subscription.add(
      this.sicknessService
        .getSicknessById(this.elementId)
        .subscribe((response: any) => {
          this.sicknessData = response.result;
          this.employeeId = response.result.employeeId;
          if (this.sicknessData.cancellationDate) {
            this.cancelled = true;
          }
          this.updateValues(response.result);
        }),
    );
    this.createForm();
  }

  createForm() {
    this.form = new UntypedFormGroup({
      employeeName: new UntypedFormControl({ value: "", disabled: true }),
      eventType: new UntypedFormControl({ value: "", disabled: true }),
      contractId: new UntypedFormControl({ value: "", disabled: true }),
      sicknessStart: new UntypedFormControl({ value: "", disabled: true }, [
        Validators.required,
        invalidDate,
      ]),
      sicknessEnd: new UntypedFormControl({ value: "", disabled: true }, [
        Validators.required,
        invalidDate,
      ]),
      firstDayAvailable: new UntypedFormControl({
        value: null,
        disabled: !this.cancelled,
      }),
      description: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(5000),
      ]),
      cancellationDescription: new UntypedFormControl(null, [
        Validators.maxLength(5000),
      ]),
      cancellationDate: new UntypedFormControl({ value: "", disabled: true }),
    });
  }

  updateValues(data: any) {
    this.form.patchValue({
      employeeId: this.tableService.getValue().employeeId,
      employeeName: `${data.employeeFirstName} ${data.employeeSurname}`,
      sicknessStart: data.eventStartDate,
      sicknessEnd: data.eventEndDate,
      firstDayAvailable: data.firstDayAvailable,
      eventType: data.eventTypeName,
      contractId: data.contractId,
      description: data.description,
      cancellationDate: data.cancellationDate,
      cancellationDescription: data.cancellationDescription,
    });

    if (data.firstDayAvailable) {
      this.form.get("firstDayAvailable").disable();
    }

    this.subscription.add(
      this.sicknessService
        .getReplacements(this.elementId)
        .subscribe(
          (response: any) =>
            (this.replacements = response.result.replacementList),
        ),
    );

    this.form.get("firstDayAvailable").valueChanges.subscribe((value: any) => {
      if (
        moment(this.form.get("sicknessEnd").value).isSameOrAfter(
          moment(value),
          "date",
        )
      ) {
        this.form
          .get("firstDayAvailable")
          .setErrors({ dateGreaterOrEqualThan: true });
      } else if (!this.isFDAEmptyOrNextAfterEnd()) {
        this.form
          .get("firstDayAvailable")
          .setErrors({ dateFDAEmptyOrNextAfterEnd: true });
      } else {
        this.form.get("firstDayAvailable").setErrors(null);
      }
    });
  }

  isFDAEmptyOrNextAfterEnd() {
    let isFDAEmptyOrNextAfterEnd = false;
    if (
      this.form.get("firstDayAvailable").value === null ||
      moment(this.form.get("sicknessEnd").value)
        .add(1, "day")
        .isSame(this.form.get("firstDayAvailable").value)
    ) {
      isFDAEmptyOrNextAfterEnd = true;
    }
    return isFDAEmptyOrNextAfterEnd;
  }

  clearFirstDayAvailable() {
    this.form.patchValue({ firstDayAvailable: null });
    this.form.get("firstDayAvailable").enable();
  }

  saveData() {
    const data = {
      acceptanceDate: new Date().toISOString(),
      firstDayAvailable: this.form.get("firstDayAvailable").value,
      acceptedById: this.tableService.getValue().employeeId,
      description: this.form.get("description").value,
    };
    this.subscription.add(
      this.sicknessService.updateSickness(this.elementId, data).subscribe(
        (response: any) => {
          this.toastrService.success(response.message, "Success");
          this.tableService.closeWindow(true, 0, this.tableIdentifier);
        },
        (err) =>
          this.toastrService.danger(getErrorMessage(err), "Error", {
            duration: 60000,
            destroyByClick: true,
          }),
      ),
    );
  }

  cancelEvent() {
    this.tableService.openWindowWithBackStack(
      CancelEventDialogComponent,
      "Shorten sickness",
      {
        component: EditSicknessComponent,
        title: `Event information - ${this.tableService.getValue().eventNo}`,
        elementId: this.elementId,
      },
      {
        type: EmployeeElementType.SICK,
        elementId: this.elementId,
        startDate: this.form.get("sicknessStart").value,
        endDate: this.form.get("sicknessEnd").value,
      },
    );
  }

  deleteEvent() {
    this.subscription.add(
      this.dialogService
        .open(QuestionDialogComponent, {
          closeOnBackdropClick: false,
          context: {
            title: "Delete confirmation",
            message: "Are you sure you want to delete? It cannot be undone.",
            deleteInput: true,
          },
        })
        .onClose.subscribe((decision: boolean) => {
          if (decision) {
            this.subscription.add(
              this.sicknessService.deleteSickness(this.elementId).subscribe(
                (response: any) => {
                  this.toastrService.success(response.message, "Success");
                  this.tableService.closeWindow(true, 0, this.tableIdentifier);
                },
                (err) => {
                  this.toastrService.danger(getErrorMessage(err), "Error", {
                    duration: 60000,
                    destroyByClick: true,
                  });
                },
              ),
            );
          }
        }),
    );
  }

  acceptEvent() {
    const data = {
      acceptanceDate: new Date().toISOString(),
      firstDayAvailable: this.form.get("firstDayAvailable").value,
      acceptedById: this.employeeId,
      requiresAcceptance: false,
      description: this.form.get("description").value,
    };
    this.subscription.add(
      this.annualLeavingService.acceptEvent(this.elementId, data).subscribe(
        (response: any) => {
          this.toastrService.success(response.message, "Success");
          this.tableService.closeWindow(true, 0, this.tableIdentifier);
        },
        (err) =>
          this.toastrService.danger(getErrorMessage(err), "Error", {
            duration: 60000,
            destroyByClick: true,
          }),
      ),
    );
  }

  rejectEvent() {
    this.subscription.add(
      this.dialogService
        .open(QuestionDialogComponent, {
          closeOnBackdropClick: false,
          context: {
            title: "Reject confirmation",
            message: "Please add rejection comments in the field below",
            messageInput: true,
            messagePlaceholder: "Rejection comments here",
            panelClass: "reject-dialog",
          },
        })
        .onClose.subscribe((decision: any) => {
          if (decision && decision.isConfirm) {
            const data = {
              cancellationDate: new Date().toISOString(),
              cancellationDescription: decision.input,
            };
            this.subscription.add(
              this.employeesService.cancelEvent(this.elementId, data).subscribe(
                (response: any) => {
                  this.toastrService.success(response.message, "Success");
                  this.tableService.closeWindow(true, 0, this.tableIdentifier);
                },
                (err) => {
                  this.toastrService.danger(getErrorMessage(err), "Error", {
                    duration: 60000,
                    destroyByClick: true,
                  });
                },
              ),
            );
          }
        }),
    );
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
