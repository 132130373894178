<header>
  <div class="header-cell">
    <span>Actions</span>
  </div>
  <div *ngFor="let day of days" class="header-cell" [ngClass]="{'today': isToday(day.dayDate)}">
    <div>{{day.dayDate.format('dddd')}}</div>
    <div>{{day.dayDate.format('DD.MM')}}</div>
  </div>
</header>
<main>
  <ng-container *ngIf="!isEditMode; else editMode">
    <div class="main-cell view-mode" (click)="editToggle()">
      <button class="edit-row-btn toggle-edit" ><i class="nb-edit" aria-hidden="true"></i></button>
    </div>
  </ng-container>
  <ng-template #editMode>
    <div class="main-cell edit-mode">
      <button class="edit-row-btn save" (click)="saveData()"><i class="nb-checkmark" aria-hidden="true"></i></button>
      <button class="edit-row-btn toggle" (click)="editToggle()"><i class="nb-close" aria-hidden="true"></i></button>
    </div>
  </ng-template>
  <div class="main-cell">
    <ng-container *ngFor="let contract of data">
      <ngx-rota-migration-table-week-cell
        (onSaved)="onSaved($event)"
        fieldName="monday"
        [weekNo]="contract.weekNo"
        [homes]="contract.multiSiteCareHomeList"
        [editMode]="isEditMode"
        [employeePosition]="contract.positionName"
        [isSleeper]="contract.isSleeper"
        [contractNumber]="contract.contractId"
        [dayData]="contract?.monday"
        [positionId]="contract.positionId"
        [contractType]="contract.positionName"
      ></ngx-rota-migration-table-week-cell>
    </ng-container>
  </div>
  <div class="main-cell">
    <ng-container *ngFor="let contract of data">
      <ngx-rota-migration-table-week-cell
        (onSaved)="onSaved($event)"
        fieldName="tuesday"
        [weekNo]="contract.weekNo"
        [homes]="contract.multiSiteCareHomeList"
        [editMode]="isEditMode"
        [employeePosition]="contract.positionName"
        [isSleeper]="contract.isSleeper"
        [contractNumber]="contract.contractId"
        [dayData]="contract?.tuesday"
        [positionId]="contract.positionId"
        [contractType]="contract.positionName"
      ></ngx-rota-migration-table-week-cell>
    </ng-container>
  </div>
  <div class="main-cell">
    <ng-container *ngFor="let contract of data">
      <ngx-rota-migration-table-week-cell
        (onSaved)="onSaved($event)"
        fieldName="wednesday"
        [weekNo]="contract.weekNo"
        [homes]="contract.multiSiteCareHomeList"
        [editMode]="isEditMode"
        [employeePosition]="contract.positionName"
        [isSleeper]="contract.isSleeper"
        [contractNumber]="contract.contractId"
        [dayData]="contract?.wednesday"
        [positionId]="contract.positionId"
        [contractType]="contract.positionName"
      ></ngx-rota-migration-table-week-cell>
    </ng-container>
  </div>
  <div class="main-cell">
    <ng-container *ngFor="let contract of data">
      <ngx-rota-migration-table-week-cell
        (onSaved)="onSaved($event)"
        fieldName="thursday"
        [weekNo]="contract.weekNo"
        [homes]="contract.multiSiteCareHomeList"
        [editMode]="isEditMode"
        [employeePosition]="contract.positionName"
        [isSleeper]="contract.isSleeper"
        [dayData]="contract?.thursday"
        [contractNumber]="contract.contractId"
        [positionId]="contract.positionId"
        [contractType]="contract.positionName"
      ></ngx-rota-migration-table-week-cell>
    </ng-container>
  </div>
  <div class="main-cell">
    <ng-container *ngFor="let contract of data">
      <ngx-rota-migration-table-week-cell
        (onSaved)="onSaved($event)"
        fieldName="friday"
        [weekNo]="contract.weekNo"
        [homes]="contract.multiSiteCareHomeList"
        [editMode]="isEditMode"
        [employeePosition]="contract.positionName"
        [isSleeper]="contract.isSleeper"
        [contractNumber]="contract.contractId"
        [dayData]="contract?.friday"
        [positionId]="contract.positionId"
        [contractType]="contract.positionName"
      ></ngx-rota-migration-table-week-cell>
    </ng-container>
  </div>
  <div class="main-cell">
    <ng-container *ngFor="let contract of data">
      <ngx-rota-migration-table-week-cell
        (onSaved)="onSaved($event)"
        fieldName="saturday"
        [weekNo]="contract.weekNo"
        [homes]="contract.multiSiteCareHomeList"
        [editMode]="isEditMode"
        [employeePosition]="contract.positionName"
        [isSleeper]="contract.isSleeper"
        [contractNumber]="contract.contractId"
        [dayData]="contract?.saturday"
        [positionId]="contract.positionId"
        [contractType]="contract.positionName"
      ></ngx-rota-migration-table-week-cell>
    </ng-container>
  </div>
  <div class="main-cell">
    <ng-container *ngFor="let contract of data">
      <ngx-rota-migration-table-week-cell
        (onSaved)="onSaved($event)"
        fieldName="sunday"
        [weekNo]="contract.weekNo"
        [homes]="contract.multiSiteCareHomeList"
        [editMode]="isEditMode"
        [employeePosition]="contract.positionName"
        [isSleeper]="contract.isSleeper"
        [contractNumber]="contract.contractId"
        [dayData]="contract?.sunday"
        [positionId]="contract.positionId"
        [contractType]="contract.positionName"
      ></ngx-rota-migration-table-week-cell>
    </ng-container>
  </div>
</main>
