<nb-card>
    <nb-card-header>Annual Leave Explanation for {{ data?.employeeName }} in the hy {{ data?.explanation?.employeeYear }}</nb-card-header>
    <nb-card-body>
        <div class="explain-al-table">
            <table class="explain-al-table__table">
                <thead>
                    <tr>
                        <th class="role to-left">Role</th>
                        <th class="column">MAX (hrs)</th>
                        <th class="column">Used (hrs)</th>
                        <th class="column">Left (hrs)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let role of data.explanation.annualLeaveExplanation">
                        <td class="to-left">{{ role.roleName }}</td>
                        <td>{{ role.max }}</td>
                        <td>{{ role.used }}</td>
                        <td>{{ role.left }}</td>
                    </tr>
                    <tr class="sum">
                        <td>TOTAL</td>
                        <td>{{ data.explanation.totalMax }}</td>
                        <td>{{ data.explanation.totalUsed }}</td>
                        <td>{{ data.explanation.totalLeft }}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colspan="3">
                            <ngx-progress-bar [progressPercent]="getPercentage()"></ngx-progress-bar>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="explain-al-text" [innerHTML]="data?.alText">

        </div>
    </nb-card-body>
    <nb-card-footer>
        <div class="buttons-container">
            <button class="btn btn-success" (click)="close()">Close</button>
        </div>
    </nb-card-footer>
  </nb-card>