<div
  [@moveLeftAnimation]="animationState"
  class="reminder-container"
  [ngClass]="type"
>
  <div class="reminder-header" (click)="close()">
    <div class="title">Reminder</div>
    <div class="hide-title">
      <nb-icon icon="eye-off-outline" class="hide-icon"></nb-icon>
      Hide
    </div>
  </div>
  <div class="reminder-content">
    <div class="action-button" (click)="navigateToSignIn()">
      <div class="button-icon"></div>
    </div>
    <div class="information-rows">
      <div class="action-info">
        Remember to
        <span (click)="navigateToSignIn()">{{ type === "sign-in" ? "SIGN IN" : "SIGN OUT" }}</span>
      </div>
      <div class="action-date">
        {{ type === "sign-in" ? "for" : "from" }} your shift at
        <span>{{ date }}</span>
      </div>
    </div>
  </div>
</div>
