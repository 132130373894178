<div class="form-view">
  <nb-tabset>
    <nb-tab tabTitle="Edit User">
      <main>
        <form [formGroup]="form">
          <div class="flex-container-horizontal">
            <div class="flex-container image-chooser">
              <ngx-image-editor
                formControlName="avatar"
                [imageData]="profileAvatar"
              >
              </ngx-image-editor>
            </div>
            <div class="flex-container">
              <div class="flex-container-horizontal">
                <div class="flex-container">
                  <div class="form-control-group">
                    <label class="label" for="input-id">System ID:</label>
                    <input
                      nbInput
                      fullWidth
                      id="input-id"
                      formControlName="id"
                      name="id"
                    />
                  </div>
                  <div class="form-control-group">
                    <label class="label" for="input-login">Login:</label>
                    <input
                      nbInput
                      formControlName="login"
                      fullWidth
                      name="login"
                      id="input-login"
                      placeholder="Login"
                      minlength="5"
                      maxlength="25"
                      autofocus
                    />
                    <p class="error-message">
                      {{
                        utils.handleValidationErrorMessage(
                          form,
                          "login",
                          errorMessages
                        )
                      }}
                    </p>
                  </div>
                  <div class="form-control-group">
                    <label class="label" for="input-email"
                      >Profile email:</label
                    >
                    <input
                      nbInput
                      formControlName="email"
                      fullWidth
                      name="email"
                      id="input-email"
                      placeholder="Email"
                      maxlength="50"
                      autofocus
                    />
                    <p class="error-message">
                      {{
                        utils.handleValidationErrorMessage(
                          form,
                          "email",
                          errorMessages
                        )
                      }}
                    </p>
                  </div>
                  <div class="form-control-group">
                    <label class="label" for="input-type">Employee:</label>
                    <ng-select
                      [items]="employees"
                      [disabled]="!permissions.USER_MANAGER"
                      #select
                      bindLabel="fullName"
                      dropdownPosition="auto"
                      bindValue="employeeId"
                      labelForId="employeeId"
                      placeholder="Search employee"
                      [virtualScroll]="true"
                      dropdownPosition="top"
                      name="input-employee-id"
                      id="input-employee-id"
                      formControlName="employeeId"
                    >
                      <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-search="searchTerm"
                      >
                        <div>
                          <span
                            ><strong>{{ item.employeeFirstName }}</strong>
                            {{ item.employeeSurname }}</span
                          >
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>

                <div class="flex-container">
                  <div class="form-control-group">
                    <label class="label" for="input-status">Status:</label>
                    <select
                      formControlName="status"
                      name="status"
                      [ngClass]="utils.getValidationStatus(form, 'status')"
                    >
                      <option
                        *ngFor="let status of statuses"
                        [ngValue]="status.id"
                      >
                        {{ status.wordFullName }}
                      </option>
                    </select>
                    <p class="error-message">
                      {{
                        utils.handleValidationErrorMessage(
                          form,
                          "status",
                          errorMessages
                        )
                      }}
                    </p>
                  </div>

                  <div class="form-control-group">
                    <label class="label" for="input-firstName"
                      >First Name:</label
                    >
                    <input
                      nbInput
                      fullWidth
                      formControlName="firstName"
                      name="firstName"
                      id="input-firstName"
                      placeholder="First Name"
                      minlength="1"
                      maxlength="50"
                      autofocus
                    />
                    <p class="error-message">
                      {{
                        utils.handleValidationErrorMessage(
                          form,
                          "firstName",
                          errorMessages
                        )
                      }}
                    </p>
                  </div>
                  <div class="form-control-group">
                    <label class="label" for="input-phoneNumber"
                      >Phone number:</label
                    >
                    <input
                      nbInput
                      fullWidth
                      formControlName="phoneNumber"
                      name="phoneNumber"
                      id="input-phoneNumber"
                      placeholder="Phone number"
                      maxlength="25"
                      autofocus
                    />
                    <p class="error-message">
                      {{
                        utils.handleValidationErrorMessage(
                          form,
                          "phoneNumber",
                          errorMessages
                        )
                      }}
                    </p>
                  </div>
                  <div class="form-control-group">
                    <label class="label">&nbsp;</label>
                    <nb-checkbox
                      [disabled]="isEditEmployee"
                      (change)="changeState($event, 'isValidCompanyEmail')"
                      [checked]="form.get('isValidCompanyEmail').value"
                      >Valid company email</nb-checkbox
                    >
                  </div>
                  <div
                    *ngIf="form.get('employeeId').value"
                    class="form-control-group"
                  ></div>
                </div>

                <div class="flex-container">
                  <div class="form-control-group">
                    <label class="label" for="input-accountType"
                      >Account type:</label
                    >
                    <select
                      formControlName="accountType"
                      name="accountType"
                      [disabled]="!isAdmin"
                      [ngClass]="utils.getValidationStatus(form, 'accountType')"
                    >
                      <option value="User">User</option>
                      <option value="Admin">Admin</option>
                    </select>
                    <p class="error-message">
                      {{
                        utils.handleValidationErrorMessage(
                          form,
                          "accountType",
                          errorMessages
                        )
                      }}
                    </p>
                  </div>

                  <div class="form-control-group">
                    <label class="label" for="input-surname">Last Name:</label>
                    <input
                      nbInput
                      fullWidth
                      formControlName="surname"
                      name="surname"
                      id="input-surname"
                      placeholder="Last Name"
                      minlength="1"
                      maxlength="50"
                      autofocus
                    />
                    <p class="error-message">
                      {{
                        utils.handleValidationErrorMessage(
                          form,
                          "surname",
                          errorMessages
                        )
                      }}
                    </p>
                  </div>
                  <div class="form-control-group">
                    <label class="label" for="input-type">
                      Default Care Home:
                    </label>
                    <select
                      formControlName="defaultCareHome"
                      name="defaultCareHome"
                      [disabled]="!permissions.USER_MANAGER"
                      [ngClass]="
                        utils.getValidationStatus(form, 'defaultCareHome')
                      "
                    >
                      <option
                        *ngFor="let careHome of careHomes"
                        [ngValue]="careHome.careHomeId"
                      >
                        {{ careHome?.careHomeFullName }}
                      </option>
                    </select>
                    <p class="error-message">
                      {{
                        utils.handleValidationErrorMessage(
                          form,
                          "defaultCareHome",
                          errorMessages
                        )
                      }}
                    </p>
                  </div>
                  <div class="form-control-group">
                    <label class="label">&nbsp;</label>
                    <nb-checkbox
                      [disabled]="isEditEmployee && !permissions.USER_MANAGER"
                      (change)="changeState($event, 'isTechnicalAccount')"
                      [checked]="form.get('isTechnicalAccount').value"
                      >Technical account</nb-checkbox
                    >
                  </div>
                  <div
                    *ngIf="form.get('employeeId').value"
                    class="form-control-group"
                  ></div>
                </div>
              </div>
              <ng-template [ngIf]="showChangePasswordInputs">
                <ngx-confirm-password-input
                  [userId]="userId"
                  (onClose)="onPasswordChangeClose()"
                ></ngx-confirm-password-input>
              </ng-template>
            </div>
          </div>
        </form>
      </main>
      <footer id="main-footer" class="space-between">
        <div class="flex">
          <button
            class="btn btn-success"
            (click)="saveData()"
            [disabled]="!utils.isFormValid(form)"
          >
            <i class="nb-checkmark" aria-hidden="true"></i> Save
          </button>
          <button class="btn btn-danger ml-4" (click)="closeWindow()">
            <i class="nb-close" aria-hidden="true"></i> Close
          </button>
        </div>
        <div class="flex">
          <button
            id="change-password"
            class="btn btn-success"
            [disabled]="
              !permissions.USER_MANAGER_CHANGE_PASSWORD && !isMyProfile
            "
            (click)="toggleChangePasswordInputs()"
          >
            <i class="nb-edit" aria-hidden="true"></i> Change password
          </button>
          <button class="btn btn-danger ml-4 longer" (click)="deleteUser()">
            <i class="nb-trash" aria-hidden="true"></i> Delete user
          </button>
        </div>
      </footer>
    </nb-tab>

    <nb-tab tabTitle="Taskboards Permissions" *ngIf="permissions.USER_MANAGER">
      <main class="permission-scroll">
        <form>
          <angular2-smart-table
            [settings]="taskboardPermissionsSettings"
            [source]="taskboardPermissions"
            (editConfirm)="editTaskboardPermissionsLine($event)"
            (deleteConfirm)="deleteTaskboardPermissionsLine($event)"
          >
          </angular2-smart-table>
        </form>
      </main>
      <footer id="permission-footer">
        <button class="btn btn-danger" (click)="closeWindow()">
          <i class="nb-close" aria-hidden="true"></i> Close
        </button>
      </footer>
    </nb-tab>
    <nb-tab tabTitle="User roles" *ngIf="permissions.USER_MANAGER">
      <main class="flex">
        <button id="role-button" class="btn btn-success" (click)="addRole()">
          <i class="nb-plus" aria-hidden="true"></i> Add role
        </button>
        <ng-select
          [items]="userProfiles"
          #select
          [clearable]="false"
          dropdownPosition="auto"
          bindValue="id"
          bindLabel="wordShortName"
          [(ngModel)]="roleId"
          (change)="onChangeRole($event)"
          placeholder="Roles"
          [virtualScroll]="true"
        >
          <ng-template ng-option-tmp let-item="item">
            <div>
              <span>{{ item.wordShortName }}</span>
            </div>
          </ng-template>
        </ng-select>
      </main>
      <main class="roles-scroll">
        <form id="roles" [formGroup]="rolesForm">
          <div
            class="category-container"
            *ngFor="let role of roles; let index = index"
          >
            <div class="category">
              <div>
                <span>{{ role.wordShortName }}</span>
                {{ role.wordFullName ? "(" + role.wordFullName + ")" : "" }}
              </div>
              <nb-icon
                (click)="deleteRole(role.id)"
                icon="trash-outline"
              ></nb-icon>
            </div>
            <div
              class="details"
              [ngClass]="{ bordered: index + 1 === roles.length }"
            >
              <ngx-multiple-autocomplete-selector-with-checkbox
                [values]="rolesCareHomes"
                placeholder="Search..."
                [label]="'Choose the Care Home you want to add to this role:'"
                [formControlName]="'role' + role.id"
              >
              </ngx-multiple-autocomplete-selector-with-checkbox>
            </div>
          </div>
        </form>
      </main>
      <footer id="permission-footer">
        <button class="btn btn-success" (click)="saveRoles()">
          <i class="nb-checkmark" aria-hidden="true"></i> Save
        </button>
        <button class="btn btn-danger ml-4" (click)="closeWindow()">
          <i class="nb-close" aria-hidden="true"></i> Close
        </button>
      </footer>
    </nb-tab>
  </nb-tabset>
</div>
