import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SwitcherComponent } from '../switcher.component';

@Component({
  selector: 'ngx-thinner-switcher',
  styleUrls: ['./thinner-switcher.component.scss'],
  templateUrl: './thinner-switcher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThinnerSwitcherComponent extends SwitcherComponent {
  changeValue() {
    this.value = !this.value;
    this.valueChange.emit(this.value);
  }
}
