import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { AvatarResource } from '../../utilities/avatar-resource.enum';
import { Cacheable } from 'ngx-cacheable';
import { AvatarsService } from '../../shared/avatars.service';
import { getServerLink } from '../../utilities/utils';
import { share, shareReplay, startWith } from "rxjs/operators";

@Pipe({name: 'avatar'})
export class AvatarPipe implements PipeTransform {

  constructor(private avatarsService: AvatarsService) {}

  @Cacheable()
  transform(id: string, resource?: AvatarResource) {
    return new Observable<string|ArrayBuffer>((observer) => {
      observer.next('assets/images/person.png');
        if (id) {
        this.avatarsService.getAvatar(id).subscribe({
          next(response: any) {
            if (response.link) {
              observer.next(getServerLink(response.link));
            } else {
              observer.next('assets/images/person.png');
            }
          },
          error() {observer.next('assets/images/person.png');}
        })
      }
      return { unsubscribe() {} };
    });
  }
}
