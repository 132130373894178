import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import {
  getValidationStatus,
  handleValidationErrorMessage,
  isFormValid,
  getErrorMessage,
  getFullName,
  validateFormControlsByName,
  onHoursInput,
} from '../../../../utilities/utils';
import { contractsErrorMessages } from '../contracts-errors';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { TableService } from '../../../shared/table/table.service';
import { invalidDate, hours } from '../../../../utilities/validators';
import { EmployessService } from '../../../../shared/employess/employess.service';
import { DictionariesService } from '../../../../shared/dictionaries/dictionaries.service';
import { ContractsService } from '../../../../shared/employess/contracts/contracts.service';
import { AuthUserService } from '../../../../@core/data/auth-user.service';
import { Subscription, forkJoin } from 'rxjs';
import * as moment from 'moment';
import { PermissionsService } from '../../../../@core/data/permissions.service';
import { QuestionDialogComponent } from '../../../shared/question-dialog/question-dialog.component';
import { ProfessionalRolesModel } from '../../../../shared/models/dictionaries/professional-roles/professional-roles-model';

enum ContractType {
  TEMP_R = 6,
  TEMP_T = 7
}

@Component({
  selector: 'ngx-new-contract',
  templateUrl: './new-contract.component.html',
  styleUrls: ['./new-contract.component.scss']
})
export class NewContractComponent implements OnInit, OnDestroy {

  @Input() decisionType: 'first' | 'second' = 'second';
  @Input() activeOnly: boolean = true;
  @Input() allEmployeeContracts: any = [];
  @Output() uploadToContract: EventEmitter<number> = new EventEmitter<number>();
  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() finish: EventEmitter<any> = new EventEmitter();
  @Output() decisionTypeChange: EventEmitter<'first' | 'second'> = new EventEmitter<'first' | 'second'>();

  private subscription: Subscription = new Subscription();
  public ContractType: typeof ContractType = ContractType;
  public errorMessages = contractsErrorMessages;
  public employess: any = [];
  public employee: any;
  public roles: ProfessionalRolesModel[] = [];
  public contractTypes: any = [];
  public form: UntypedFormGroup;
  public tableForm: UntypedFormGroup;
  public utils = { getValidationStatus, handleValidationErrorMessage, isFormValid, getFullName, onHoursInput };
  public inProgress: boolean = false;

  public careHomeId = -1;
  public rotaFreezeDate: any;

  public columns = ['No.', this.decisionType == 'first' ? 'Contract ID' : 'Contract Number', 'Role', 'Contract Type', 'Contract Start', 'Contract End', 'Gross Hrs', 'Status', this.decisionType == 'first' ? 'To cancel' : 'Choose'];
  public employeeContracts: any = [];
  public contractControlNames = [];
  public securityLevels = [];
  public permissions = <any>{};

  private documentToAssign: any;
  private documentUid: any;
  private employeeId: any;
  private documentCategoryId: any;
  private securityLevelId: any;
  private lastRoleId: any;
  private contractRoles = [];
  private employeeLevelId: number;


  constructor(private tableService: TableService,
              private employessService: EmployessService,
              private dictionariesService: DictionariesService,
              private contractsService: ContractsService,
              private authUserService: AuthUserService,
              private formBuilder: UntypedFormBuilder,
              private toastrService: NbToastrService,
              private permissionsService: PermissionsService,
              private dictionaryService: DictionariesService,
              private dialogService: NbDialogService) {
    this.permissions = this.permissionsService.getPermissions();
  }

  ngOnInit() {
    this.createForm();
    this.rotaFreezeDate = this.tableService.getValue().rotaFreezeDate;
    this.careHomeId = this.authUserService.getCareHomeId();

    this.subscription.add(this.employessService.getEmployess()
      .subscribe((response: any) => {
        response.result.employeesLines.map(i => i.fullName = getFullName(i.employeeFirstName, i.employeeSurname));
        this.employess = response.result.employeesLines;
        this.form.get('employeeId').valueChanges.subscribe(change => {
          const selectedEmployee = this.employess.find(employee => employee.employeeId === change);
          this.employee = selectedEmployee;
          if (selectedEmployee && selectedEmployee.employeeDateFirstJoinedDMY) {
            this.form.get('contractStart').setValidators([Validators.required, invalidDate]);
          }
        });
        if (this.tableService.getValue()) {
          this.employeeId = this.tableService.getValue().employeeId;
          this.documentToAssign = this.tableService.getValue().documentToAssign;
          this.documentCategoryId = this.tableService.getValue().documentCategoryId;
          this.documentUid = this.tableService.getValue().documentUid;
          this.securityLevelId = this.tableService.getValue().securityLevelId;
          this.contractRoles = this.tableService.getValue().contractRoles;
          this.form.get('employeeId').patchValue(this.employeeId);
        }
    }));
    this.subscription.add(this.dictionariesService.getContractTypes()
      .subscribe((response: any) => {
        this.contractTypes = response.result.wordsFromDictionary;
    }));

    const professionalRoles = this.dictionariesService.getProfessionalRolesForCare(this.authUserService.getCareHomeId());
    const securityLevels = this.dictionaryService.getSecurityLevels();
    const employee = this.employessService.getEmployee(this.tableService.getValue().employeeId);

    this.subscription.add(forkJoin([professionalRoles, securityLevels, employee]).subscribe((response: any) => {
      this.roles = response[0].result;
      this.securityLevels = response[1].result.wordsFromDictionary.map((e) => ({...e, value: parseInt(e.value, 10)}));
      this.employeeLevelId = response[2].result.levelId;
      this.form.get('levelId').setValue(this.employeeLevelId);
    }));
  }

  createForm() {
    this.tableForm = new UntypedFormGroup({});
    this.form = new UntypedFormGroup({
      employeeId: new UntypedFormControl(null, [Validators.required]),
      positionId: new UntypedFormControl(null, [Validators.required]),
      contractStart: new UntypedFormControl(null, [Validators.required, invalidDate]),
      contractEnd: new UntypedFormControl(null, [invalidDate]),
      hoursPerWeek: new UntypedFormControl(null, [Validators.required, hours]),
      contractTypeId: new UntypedFormControl(null, [Validators.required]),
      levelId: new UntypedFormControl(null, [Validators.required]),
      baseRateOfPay: new UntypedFormControl('0'),
      numberOfAlDays: new UntypedFormControl('28'),
    });

    this.form.get('levelId').disable();
    this.form.get('employeeId').disable();

    this.form.get('contractStart').valueChanges.subscribe((value: any) => {
      const isContractTemp = this.form.get('contractTypeId').value === ContractType.TEMP_R || this.form.get('contractTypeId').value === ContractType.TEMP_T;
      if (this.form.get('contractEnd').value === null && isContractTemp) {
        this.form.get('contractEnd').setValue(value);
      }
    });

    this.form.get('contractTypeId').valueChanges.subscribe((value: any) => {
      const contractStartDate = this.form.get('contractStart').value;
      const contractEndDate = this.form.get('contractEnd').value;
      if ((value === ContractType.TEMP_R || value === ContractType.TEMP_T) && contractStartDate !== null && contractEndDate === null) {
        this.form.get('contractEnd').setValue(contractStartDate);
      }
    });

    this.form.get('positionId').valueChanges.subscribe((value: any) => {
      this.assignSecurityLevelToRole(value);
    });

    this.form.get('contractStart').valueChanges.subscribe(() => this.checkContract());
  }

  assignSecurityLevelToRole(roleId: any) {
    let role = this.roles.find((item: any) => {
      return item.id == roleId;
    });

    const levelValue = this.securityLevels.find((i) => i.value === role.securityLevel)?.id;

    if (!levelValue) {
      return;
    }

    const activeSecurityLevel = this.securityLevels.find((e) => e.id == this.employeeLevelId);
    if (activeSecurityLevel) {
      if (role.securityLevel <= activeSecurityLevel.value) {
        return;
      }
    }
    

    this.subscription.add(this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Change security level confirmation',
          message: `Adding a new contract as ${role.roleName} will change the permission level to ${role.securityLevel}.<br>Please confirm`,
          okLabel: 'Yes',
          cancelLabel: 'No'
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          this.lastRoleId = roleId;
          this.securityLevelId = role.securityLevel;
          this.form.patchValue({
            levelId: levelValue
          }, { emitEvent: false });
        } else {
          this.form.patchValue({
            positionId: this.lastRoleId
          }, { emitEvent: false });
        }
      }
    ));

  }

  isContractEndValid() {
    if (this.form.get('contractTypeId').value === ContractType.TEMP_R
      || this.form.get('contractTypeId').value === ContractType.TEMP_T) {
        return !this.isContractEndBeforeStart();
    } else {
      return true;
    }
  }

  isContractEndBeforeStart() {
    return moment(this.form.get('contractEnd').value, 'DD/MM/YYYY').isBefore(moment(this.form.get('contractStart').value, 'DD/MM/YYYY'));
  }

  getSelectedContracts() {
    let list = [];
    this.employeeContracts.forEach((contract: any) => {
      list.push({
        contractId: contract.contractId,
        given: this.tableForm.get(`contract-${contract.contractId}`).value
      });
      list = list.filter(item => item.given === 'true');
    });
    return list;
  }

  prepareData() {
    let contractsToCancel = null;
    if (this.employeeContracts.length > 0) {
      contractsToCancel = this.getSelectedContracts();
    }
    let contractEndDate = null;
    const contractType = this.form.get('contractTypeId').value;
    if (contractType === ContractType.TEMP_R || contractType === ContractType.TEMP_T) {
      contractEndDate = this.form.get('contractEnd').value;
    }
    const data = {
      careHomeId: this.authUserService.getCareHomeId(),
      employeeId: this.form.get('employeeId').value,
      positionId: this.form.get('positionId').value,
      contractStart: this.form.get('contractStart').value,
      contractEnd: contractEndDate,
      hoursPerWeek: this.form.get('hoursPerWeek').value,
      baseRateOfPay: this.form.get('baseRateOfPay').value,
      alDays: this.form.get('numberOfAlDays').value,
      levelId: this.form.get('levelId').value,
      contractTypeId: contractType,
      contractsToCancel: contractsToCancel,
    };

    return data;
  }

  checkContract() {
    if (this.form.get('employeeId').value && validateFormControlsByName(this.form, ['contractStart'])) {
      const data = this.prepareData();
      this.subscription.add(this.contractsService.checkEmployeeContract(data.employeeId, data)
        .subscribe((response: any) => {
          if (response.result.contractsLines.length === 0) {
            this.employeeContracts = [];
            this.tableForm = new UntypedFormGroup({});
          } else {
            this.createTable(response.result.contractsLines);
          }
      }));
    }
  }

  createTable(contracts: any) {
    this.employeeContracts = contracts;
    this.employeeContracts.forEach(contract => {
      this.tableForm.removeControl(`contract-${contract.contractId}`);
    });
    this.employeeContracts.forEach(contract => {
      this.tableForm.addControl(`contract-${contract.contractId}`, this.formBuilder.control(null, [Validators.required]));
    });
    this.contractControlNames = [];
    Object.keys(this.tableForm.controls).forEach(key => {
      if (key.includes('contract-')) {
        this.contractControlNames.push(key);
      }
    });
    this.tableForm.valueChanges.subscribe((v) => {
      const res = JSON.parse(JSON.stringify(v));
      let contractLevel = 0;
      Object.keys(res).forEach((e) => {
        if (!res[e] || res[e] === 'false') {
          if (contractLevel < this.employeeContracts.find((i) => i.contractId.toString() === e.split('contract-')[1]).securityLevel) {
            contractLevel = this.employeeContracts.find((i) => i.contractId.toString() === e.split('contract-')[1]).securityLevel;
          }
        }
      });
      const selectedRole = this.form.get('positionId')?.value;
      
      if (selectedRole) {
        const selectedRoleObj = this.roles.find((e) => e.id === selectedRole);
        if (selectedRoleObj && selectedRoleObj.securityLevel > contractLevel) {
          contractLevel = selectedRoleObj.securityLevel;
        }
      }

      let role = this.securityLevels.find((e) => e?.value === contractLevel)
      
      if (role) {
        this.form.get('levelId').setValue(role.id);
      }
    })
  }

  saveData() {
    if (!this.inProgress) {
      this.inProgress = true;
      const data = this.prepareData();
      this.subscription.add(this.contractsService.addNewContract(data)
        .subscribe((response: any) => {
          this.toastrService.success(response.message, 'Success');
          this.save.emit({id: response.id, typeId: this.form.get('contractTypeId').value, roleId: this.form.get('positionId').value});
          // if (this.documentToAssign || this.documentUid) {
          //   this.assignDocument(response.id);
          // } else {
          //   this.tableService.closeWindow(true);
          // }
          this.inProgress = false;
        },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
          this.inProgress = false;
        }
      ));
    }
  }

  assignDocument(contractId: any) {
    const fd = new FormData();
    if (this.documentUid) {
      fd.append('documentUid', this.documentUid);
    }
    fd.append('document', this.documentToAssign);
    fd.append('employeeId', this.employeeId.toString());
    fd.append('careHomeId', this.authUserService.getCareHomeId().toString());
    const contractTypeId = this.form.get('contractTypeId').value;
    const positionId = this.form.get('positionId').value;
    const contractType = this.contractTypes.find((item) => item.id == contractTypeId);
    const position = this.roles.find((item) => item.id == positionId);
    fd.append('documentCategoryId', this.documentCategoryId.toString());
    fd.append('notes', `${position && position.roleName} - ${contractType && contractType.wordFullName}`);
    this.subscription.add(this.contractsService.addContractDocuments(contractId, fd)
      .subscribe((response: any) => {
        this.tableService.closeWindow(false, 1);
        this.toastrService.success(response.message, 'Success');
      },
      (err) => {
        this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
      }
    ));
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  changeToNewContract() {
    this.decisionTypeChange.emit('first');
  }
}
