import { Component, OnChanges, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'ngx-progress-bar',
  template: `<div id="bar" [ngClass]="{'zeroPercent': progressPercent === '0%'}">
              <span *ngIf="progressPercent === '0%'">{{progressPercent}}</span>
              <div id="progress" #progress>
                <span>{{progressPercent}}</span>
              </div>
            </div>`,
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnChanges {
  @ViewChild('progress', { static: true }) progress: ElementRef;
  @Input() progressPercent = '0%';

  ngOnChanges() {
    if (this.progressPercent) {
      const onlyPercent = this.progressPercent.replace('%', '');
      if (parseInt(onlyPercent) > 100) {
        this.progressPercent = '100%' + '';
      }
    }
    this.progress.nativeElement.style.width = `${this.progressPercent}`;
  }
}
