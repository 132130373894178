import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { RotaTableService } from '../../employess/rota/rota-table.service';

@Component({
  selector: 'ngx-hours-cell-selector',
  templateUrl: './hours-cell-selector.component.html',
  styleUrls: ['./hours-cell-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => HoursCellSelectorComponent),
    },
  ],
})
export class HoursCellSelectorComponent
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  private subscription = new Subscription();
  public value;
  public filteredValues = [];
  @Input() from = true;
  @Input() disabled = false;
  @Input() values = [];
  @Input() lastValue = '';
  @Input() formType: 'from' | 'to';
  @Input() autoFill = false;
  @Output() valueSelected = new EventEmitter();
  @Output() onChangeEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() onLastValueChange = new EventEmitter<string>();
  @ViewChild('agCellInput', { static: false }) cellInput: ElementRef;
  public isOpen = false;

  @Output() onFieldError = new EventEmitter<boolean>();

  private isBackspaceActive: boolean;
  private uniqueId = '';
  public fieldError = false;

  @Input() paramTemplate: {
    AM: {from: string, to: string},
    PM: {from: string, to: string},
    LONG: {from: string, to: string},
    NIGHT: {from: string, to: string}
  } = {
    AM: {from: '6:00', to: '14:00'},
    PM: {from: '14:00', to: '22:00'},
    LONG: {from: '6:00', to: '22:00'},
    NIGHT: {from: '22:00', to: '6:00'}
  };


  constructor(private changeDetector: ChangeDetectorRef, private rotaTableService: RotaTableService) {
    this.uniqueId = this.rotaTableService.generateId();
  }

  ngOnInit() {
    this.filteredValues = Object.assign([], this.values);
    this.subscription.add(this.rotaTableService.openRotaHoursDropdown.subscribe((id) => {
      if (id !== this.uniqueId) {
        this.isOpen = false;
      }
    }))
  }

  ngAfterViewInit(): void {
    const content = document.querySelector('.pages-main-content');
    const scroll$ = fromEvent(content, 'scroll').pipe(map(() => content));

    this.subscription.add(
      scroll$.subscribe(() => {
        this.isOpen = false;
        this.changeDetector.detectChanges();
      })
    );
  }

  public onChange(newVal: any) {
    console.log('on change');
  }
  public onTouched(_?: any) {}
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public autofillValue(val: string): void {
    val = val.replace('.', ':');
    if (val.length === 2 && !this.isBackspaceActive && parseInt(val, 10) <= 24) {
      this.value = (val.includes(':')) ? `${val}00` : `${val}:00`;
    }
    if (val.length === 3 && !val.includes(':')) {
      const tempVal = val.slice(0, 2) + ':' + val.slice(2).padEnd(2, '0');
      const test = tempVal.split(':').map((_) => parseInt(_));
      if (test[0] < 24 && test[1] < 60) {
        this.value = tempVal;
        this.fieldError = false;
        this.onFieldError.emit(false);
      } else {
        this.value = '';
        this.fieldError = true;
        this.onFieldError.emit(true);
      }
      
    }
    if (val.length === 4 && !val.includes(':')) {
      if (val.slice(0, 2) < '23' && val.slice(2) < '60') {
        this.value = val.slice(0, 2) + ':' + val.slice(2);
        this.onFieldError.emit(false);

      } else {
        this.value = '';
        this.fieldError = true;
        this.onFieldError.emit(true);
      }
    }
    this.isBackspaceActive = false;
    this.onChangeEvent.emit(this.value);
  }

  public autofillValueFull(val: string): void {
    val = val.replace('.', ':');
    if (val.length === 2 && !this.isBackspaceActive && parseInt(val, 10) <= 24) {
      this.value = (val.includes(':')) ? `${val}00` : `${val}:00`;
    }   else if (val.length === 1 && !this.isBackspaceActive && parseInt(val, 10) <= 10) {
      this.value = `${val}:00`;
    }
    this.isBackspaceActive = false;
    this.onChangeEvent.emit(this.value);
  }

  public setBackspaceActive(): void {
    this.isBackspaceActive = true;
  }

  writeValue(val: any): void {
    this.value = val;
    this.changeDetector.detectChanges();
  }

  setValue(val: string, event?: Event) {
    this.lastValue = val;
    this.onLastValueChange.emit(val);
    if (val == 'AM' && (this.value == 'AM')) {this.isOpen = false; return}
    if (val == 'PM' && (this.value == 'PM')) {this.isOpen = false; return}
    if (val == 'LONG' && (this.value == 'LONG')) {this.isOpen = false; return}
    if (val == 'NIGHT' && (this.value == 'NIGHT')) {this.isOpen = false; return}
    if (this.value == val) {this.isOpen = false; return}
    this.value = val;
    this.onChange(this.value);
    this.valueSelected.emit({
      emitterEvent: this.cellInput.nativeElement,
      emitterVal: val,
    });
    this.isOpen = false;
  }

  filterValues(val: string) {
    const filtered =
      this.values && this.values.filter((value: string) => value.includes(val));
    this.filteredValues = Object.assign([], filtered);
    if (!this.filteredValues.length) {
      this.filteredValues = this.values;
    }
  }

  makeListVisible() {
    this.isOpen = true;
    this.rotaTableService.openRotaHoursDropdown.next(this.uniqueId);
  }

  makeListNotVisible() {
    this.isOpen = false;
  }

  public autoFillValueOnTabPress(e: Event): void {
    return;
    if (this.filteredValues.length > 0) {
      this.setValue(this.filteredValues[0], e);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
