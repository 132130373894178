<table mat-table [dataSource]="matTableDataSource" matSort (matSortChange)="matSortChange.emit($event)" #focusBlock>
  <ng-container *ngFor="let column of columns; trackBy: trackByFn" [matColumnDef]="column.fieldName">
    <th mat-header-cell mat-sort-header *matHeaderCellDef class="header"
      [ngClass]="{'title': column.fieldName === 'title', 'status': column.fieldName === 'status'}">
      <span>{{column.title}}</span>
    </th>
    <ng-container *ngIf="column.fieldName === 'index'">
      <td mat-cell *matCellDef="let element"
        [ngStyle]="{'background-color': element['groupingColor'], 'background': element ? element.indexColor : 'transparent'}">
        <span>{{element[column.fieldName]}}</span>
        <span *ngIf="element.totalSubtasks > 0" (click)="toogleSubtaskList(element.taskId)" style="padding-left: 0;">
          <nb-icon *ngIf="showSubtasksForTaskId != element.taskId" icon="plus-square-outline"
            class="subtask-expandable-icon"></nb-icon>
          <nb-icon *ngIf="showSubtasksForTaskId == element.taskId" icon="minus-square-outline"
            class="subtask-expandable-icon"></nb-icon>
        </span>
      </td>
    </ng-container>
    <ng-container *ngIf="column.fieldName === 'title'">
      <td mat-cell *matCellDef="let element" (click)="edit(element)"
        [ngClass]="{'high': element['priority'] == 'high', 'low': element['priority'] == 'low', 'grey': element['status'] == 'Paused', 'hard-grey': element['status'] == 'Done(remove)' , 'green': element['status'] == 'Done' || element['status'] == 'D&V'}">
        <ngx-progress-bar *ngIf="element.totalSubtasks > 0 && column.addSubtasksProgress"
          [progressPercent]="element.subTasksFinishedString"></ngx-progress-bar>
        <span *ngIf="!column.valuePrepareFunction" class="title" [ngClass]="{'completed': element.status === 'updated' && element.isTaskCompleted,
                                                                          'notCompleted': element.status === 'updated' && (element.isTaskNotCompleted || (element.isSubTask && !element.isTaskCompleted))}">
          <ng-container *ngIf="element['isSubTask']">
            <nb-icon *ngIf="element.isTaskCompleted && !element.isTaskNotCompleted" icon="checkmark-square-2-outline" class="taskboard-icon green"></nb-icon>
            <nb-icon *ngIf="!element.isTaskCompleted && element.isTaskNotCompleted" icon="close-square-outline" class="taskboard-icon red"></nb-icon>
            <nb-icon *ngIf="!element.isTaskCompleted && !element.isTaskNotCompleted" icon="square-outline" class="taskboard-icon"></nb-icon>
          </ng-container>
          <img *ngIf="element['isAfgBell']" src="./assets/images/danger.png" alt=""/>
          <nb-icon *ngIf="element['isFavorite']" icon="star-outline" class="taskboard-icon"></nb-icon>
          <nb-icon *ngIf="element['isEmailList']" icon="email-outline" class="taskboard-icon"></nb-icon>
          <nb-icon *ngIf="element['numberOfAttachments']" icon="attach-2-outline" class="taskboard-icon"></nb-icon>
          {{element[column.fieldName]}}
        </span>
        <span *ngIf="column.valuePrepareFunction" class="title" [ngClass]="{'completed': element.status === 'updated' && element.isTaskCompleted,
                                                                            'notCompleted': element.status === 'updated' && element.isTaskNotCompleted}">
          <ng-container *ngIf="element['isSubTask']">
            <nb-icon *ngIf="element.isTaskCompleted && !element.isTaskNotCompleted" icon="checkmark-square-2-outline" class="taskboard-icon green"></nb-icon>
            <nb-icon *ngIf="!element.isTaskCompleted && element.isTaskNotCompleted" icon="close-square-outline" class="taskboard-icon red"></nb-icon>
            <nb-icon *ngIf="!element.isTaskCompleted && !element.isTaskNotCompleted" icon="square-outline" class="taskboard-icon red"></nb-icon>
          </ng-container>
          <img *ngIf="element['isAfgBell']" src="./assets/images/danger.png" alt=""/>
          <nb-icon *ngIf="element['isFavorite']" icon="star-outline" class="taskboard-icon"></nb-icon>
          <nb-icon *ngIf="element['isEmailList']" icon="email-outline" class="taskboard-icon"></nb-icon>
          <nb-icon *ngIf="element['numberOfAttachments']" icon="attach-2-outline" class="taskboard-icon"></nb-icon>
          <div [innerHTML]="element ? column.valuePrepareFunction(element) : ''"></div>
        </span>
      </td>
    </ng-container>
    <ng-container *ngIf="column.fieldName === 'statusName' || column.fieldName === 'status'">
      <td mat-cell *matCellDef=" let element"
      class="column-padding"
      (click)="column.click ? column.click($event, element) : edit(element)"
      [ngClass]="{'grey': element['status'] == 'Paused', 'hard-grey': element['status'] == 'Done(remove)' , 'green': element['status'] == 'Done' || element['status'] == 'D&V'}">
        <div>{{element[column.fieldName]}}</div>
      </td>
    </ng-container>
    <ng-container *ngIf="column.fieldName === 'statusToDisplay'">
      <td mat-cell *matCellDef="let element" (click)="edit(element)"
        [ngClass]="{'grey': element['status'] == 'Paused', 'hard-grey': element['status'] == 'Done(remove)' , 'green': element['status'] == 'Done' || element['status'] == 'D&V'}">
        <span [innerHTML]="element[column.fieldName]"></span>
        <span class="recommended-status" [innerHTML]="element['recommendedStatus']"></span>
      </td>
    </ng-container>
    <ng-container *ngIf="column.fieldType === 'textarea-view'">
      <td mat-cell *matCellDef=" let element"
          [ngClass]="{'large-column-in-window': column.isInWindowSize, 'grey': element['status'] == 'Paused', 'hard-grey': element['status'] == 'Done(remove)' , 'green': element['status'] == 'Done' || element['status'] == 'D&V'}"
        (click)="column.click ? column.click($event, element) : edit(element)">
        <div class="textarea-view" #content
                    [ngClass]="{'completed': element.status === 'updated' && element.isTaskCompleted && column.fieldName=='description',
                                'notCompleted': element.status === 'updated' && (element.isTaskNotCompleted  || (element.isSubTask && !element.isTaskCompleted)) && element.title && column.fieldName=='description'}">
            <pre [innerHTML]="element[column.fieldName]"></pre>
          </div>
      </td>
    </ng-container>
    <ng-container *ngIf="column.fieldName !== 'status' || column.fieldName !== 'statusName' ">
          <td mat-cell *matCellDef=" let element"
          [ngClass]="{'large-column-in-window': column.isInWindowSize, 'large-column-width': column.isStaticLongWidth, 'grey': element['status'] == 'Paused', 'hard-grey': element['status'] == 'Done(remove)', 'green': element['status'] == 'Done' || element['status'] == 'D&V' }"
      (click)="column.click ? column.click($event, element) : edit(element)">
      <ngx-progress-bar *ngIf="element.totalSubtasks > 0 && column.addSubtasksProgress"
        [progressPercent]="element.subTasksFinishedString"></ngx-progress-bar>
      <span *ngIf="element.totalSubtasks > 0 && column.addSubtasksChangeProgress"
        class="right">{{element.subTasksFinishedChangePercent}}</span>
      <br *ngIf="!column.addSubtasksProgress && !column.addSubtasksChangeProgress"/>
      <div *ngIf="!column.formatHtml && !column.valuePrepareFunction" class="textarea-view" #content
        [ngClass]="{'completed': element.status === 'updated' && element.isTaskCompleted && column.fieldName=='description',
                    'notCompleted': element.status === 'updated' && (element.isTaskNotCompleted  || (element.isSubTask && !element.isTaskCompleted)) && element.title && column.fieldName=='description'}">
        <pre [innerHTML]="element[column.fieldName]"></pre>
      </div>
      <div *ngIf="column.formatHtml || column.valuePrepareFunction" class="textarea-view format-html thin-echo-scrollbar-5" #content
           [ngClass]="{'completed': element.status === 'updated' && element.isTaskCompleted && column.fieldName=='description',
                    'notCompleted': element.status === 'updated' && (element.isTaskNotCompleted  || (element.isSubTask && !element.isTaskCompleted)) && element.title && column.fieldName=='description'}"
           [innerHTML]="column.valuePrepareFunction && element ? column.valuePrepareFunction(element) : column.formatHtml ? element[column.fieldName] : ''" readonly></div>
      </td>
    </ng-container>
    <ng-container *ngIf="column.fieldType !== 'textarea-view'">
      <td mat-cell *matCellDef="let element" (click)="column.click ? column.click($event, element) : edit(element)">
        <span *ngIf="!column.valuePrepareFunction" #content [class]="column.class">{{element[column.fieldName]}}</span>
        <span *ngIf="column.valuePrepareFunction" #content [class]="column.class"
          [innerHTML]="element ? column.valuePrepareFunction(element) : ''"></span>
      </td>
    </ng-container>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="thinner"
      [ngClass]="{'green': !row.isSubTask && row.commented,
                  'grey': row.status == 'Paused' || row.status == 'D&V',
                  'hard-grey': row.status == 'D&R' || row.status == 'Closed',
                  'hidden': row.isSubTask,
                  'visible': showSubtasksForTaskId == row.subTaskForTaskId}"></tr>
</table>
