import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { NbToastrService } from "@nebular/theme";
import { SubtasksService } from "../../subtask.service";

@Component({
  selector: "ngx-subtask-editable-todo-form",
  templateUrl: "./subtask-editable-todo-form.component.html",
  styleUrls: ["../subtask-editable-todo.component.scss"],
})
export class SubtaskEditableTodoFormComponent implements OnInit, AfterViewInit {
  @Input() subtaskIndex: number;
  @Input() highlight: boolean = false;
  @Input() toDoindex: number;
  @Input() todo: any;
  @Input() task: any;
  @Input() isRecurringVisible = false;
  @Input() gridView = false;
  @Output() saveStatus = new EventEmitter<{
    isNotCompleted: boolean;
    isCompleted: boolean;
    subtaskIndex: number;
    todoIndex: number;
  }>();
  @Output() delete = new EventEmitter<{
    subtaskIndex: number;
    todoIndex: number;
  }>();
  @Output() saveComments = new EventEmitter<{
    comments: string;
    subtaskIndex: number;
    todoIndex: number;
    isRecurring: boolean;
  }>();
  @Output() changeAssignedUsers = new EventEmitter<{
    subtaskIndex: number;
    todoIndex: number;
    assignedUsers: [];
  }>();
  public form: UntypedFormGroup;
  public editMode: boolean = false;
  public isRecurring = false;
  public users = [];
  public isCompleted = false;

  constructor(
    protected toastrService: NbToastrService,
    protected subtasksService: SubtasksService,
  ) {
    this.subtasksService.users$.subscribe((response: any) => {
      this.users = response;
    });
  }

  @ViewChild("latestUpdateInput") latestUpdateInput: ElementRef;

  ngOnInit() {
    this.createForm();
    this.todo.assignedUsers = this.todo.assignedUsers || [];
    this.setupRecurring();
  }

  ngAfterViewInit() {
    if (this.highlight) {
      this.latestUpdateInput.nativeElement.focus();
      this.latestUpdateInput.nativeElement.click();
    }
  }

  createForm() {
    this.form = new UntypedFormGroup({
      comments: new UntypedFormControl(this.todo.comments),
    });
  }

  setupRecurring() {
    if (this.todo.isRecurring) {
      this.isRecurring = this.todo.isRecurring;
    }
  }

  edit(event) {
    event.stopPropagation();
    event.preventDefault();
    this.editMode = true;
    setTimeout(() => {
      document.getElementById("subtask-editable-todo-edit-input").focus();
    }, 100);
  }

  deleteItem(event) {
    event.stopPropagation();
    this.delete.emit({
      subtaskIndex: this.subtaskIndex,
      todoIndex: this.toDoindex,
    });
  }

  toggleInfinity() {
    this.isRecurring = !this.isRecurring;
    const data = {
      comments: this.form.get("comments").value,
      subtaskIndex: this.subtaskIndex,
      todoIndex: this.toDoindex,
      isRecurring: this.isRecurring,
    };
    this.saveComments.emit(data);
  }

  setStatus(isDone: boolean) {
    const data = {
      isNotCompleted: !isDone,
      isCompleted: isDone,
      subtaskIndex: this.subtaskIndex,
      todoIndex: this.toDoindex,
    };
    if (isDone) {
      if (this.todo.groupResponse && this.todo.groupResponse.trim()) {
        this.saveStatus.emit(data);
      } else {
        this.toastrService.danger(
          "To R a todo, you have to have written something in the text box, confirming why it should be R",
          "Error",
          { duration: 60000, destroyByClick: true },
        );
      }
    } else {
      this.saveStatus.emit(data);
    }
  }

  doNothing(event: any) {
    event.preventDefault();
  }

  save() {
    if (this.form.get("comments").value.trim().length == 0) {
      this.toastrService.danger("Subtask title cannot be blank", "Error", {
        duration: 60000,
        destroyByClick: true,
      });
    } else {
      const data = {
        comments: this.form.get("comments").value,
        subtaskIndex: this.subtaskIndex,
        todoIndex: this.toDoindex,
        isRecurring: this.isRecurring,
      };
      this.editMode = false;
      this.saveComments.emit(data);
    }
  }

  onModelChange() {
    if (this.todo.groupResponse.trim().length == 0) {
      const data = {
        isNotCompleted: this.todo.isNotCompleted,
        isCompleted: false,
        subtaskIndex: this.subtaskIndex,
        todoIndex: this.toDoindex,
      };
      this.saveStatus.emit(data);
    }
  }

  clearWhitespaces(text: string) {
    return text.replace(/[\s\r\n\t]+/g, "").substring(0, 50);
  }

  readonly isConfirmable = () => {
    return (
      this.todo.groupResponse &&
      this.todo.groupResponse.trim() &&
      !this.todo.isCompleted
    );
  };
}
