export const contractsErrorMessages = [
  {
    field: 'employeeId',
    errors: [
      {
        error: 'required',
        message: 'Employee Name is required'
      }
    ]
  },
  {
    field: 'positionId',
    errors: [
      {
        error: 'required',
        message: 'Role Name is required'
      }
    ]
  },
  {
    field: 'contractStart',
    errors: [
      {
        error: 'required',
        message: 'Role Start Date is required'
      },
      {
        error: 'invalidDate',
        message: 'Invalid date'
      },
      {
        error: 'dateGreaterThan',
        message: 'Cannot be earlier than date first joined'
      }
    ]
  },
  {
    field: 'contractEnd',
    errors: [
      {
        error: 'required',
        message: 'Contract End Date is required'
      },
      {
        error: 'invalidDate',
        message: 'Invalid date'
      },
      {
        error: 'dateGreaterThan',
        message: 'Cannot be earlier than contract start date'
      }
    ]
  },
  {
    field: 'hoursPerWeek',
    errors: [
      {
        error: 'required',
        message: 'Hrs p/w is required'
      },
      {
        error: 'hours',
        message: 'Hours p/w in format hh:mm'
      }
    ]
  },
  {
    field: 'contractTypeId',
    errors: [
      {
        error: 'required',
        message: 'Contract Type is required'
      },
    ]
  },
  {
    field: 'cancellationDate',
    errors: [
      {
        error: 'required',
        message: 'Cancellation Date is required'
      },
    ]
  },
  {
    field: 'validFrom',
    errors: [
      {
        error: 'required',
        message: 'Valid from date is required'
      },
      {
        error: 'invalidDate',
        message: 'Invalid date'
      },
      {
        error: 'dateGreaterThan',
        message: 'Cannot be earlier than date first joined'
      }
    ]
  },
  {
    field: 'baseRateOfPay',
    errors: [
      {
        error: 'required',
        message: 'Base rate of pay is required'
      },
    ]
  }
];
