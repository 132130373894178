import { Component, OnInit } from '@angular/core';
import { EmployessService } from '../../../shared/employess/employess.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TableService } from '../table/table.service';
import { Subscription } from 'rxjs';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { getErrorMessage, isFormValid, handleValidationErrorMessage } from '../../../utilities/utils';
import { invalidDate } from '../../../utilities/validators';
import { ContractsService } from '../../../shared/employess/contracts/contracts.service';
import { eventsErrors } from '../../employess/events-errors';
import { EmployeeElementType } from '../../employess/employee-element-type.enum';
import * as moment from 'moment';
import { QuestionDialogComponent } from '../question-dialog/question-dialog.component';

@Component({
  selector: 'ngx-cancel-event-dialog',
  templateUrl: './cancel-event-dialog.component.html',
  styleUrls: ['./cancel-event-dialog.component.scss']
})
export class CancelEventDialogComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  form: UntypedFormGroup;
  utils = { handleValidationErrorMessage, isFormValid };
  errorMessages = eventsErrors;
  type: EmployeeElementType;
  cancelLabel: string;
  elementId: number;
  private goBackAfterSave = false;
  public isDateStartError = false;
  public isDateEndError = false;
  public originalEndDate = null;

  constructor(private tableService: TableService,
              private dialogService: NbDialogService,
              private employeesService: EmployessService,
              private contractsService: ContractsService,
              private toastrService: NbToastrService) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = new UntypedFormGroup({
      cancellationDate: new UntypedFormControl(null, [Validators.required, invalidDate]),
      cancellationDescription: new UntypedFormControl(null, [Validators.required, Validators.maxLength(5000)]),
      startDate: new UntypedFormControl({ value: this.tableService.getValue().startDate, disabled: true }),
      firstDayAvailable: new UntypedFormControl(null),
    });

    this.form.get('cancellationDate').valueChanges.subscribe((value: any) => {
      this.isDateStartError = moment(value).isBefore(moment(this.form.get('startDate').value));
      this.form.get('firstDayAvailable').setValue(moment(value).add(1, 'days'));
      if (this.originalEndDate) {
        if (moment(value).isAfter(moment(this.originalEndDate))) {
          this.isDateEndError = true;
          this.showExtendEventDialog();
        } else {
          this.isDateEndError = false;
        }
      }
    });

    this.form.get('firstDayAvailable').valueChanges.subscribe((value: any) => {
      if (moment(this.form.get('cancellationDate').value).isSameOrAfter(moment(value), 'date')) {
        this.form.get('firstDayAvailable').setErrors({'dateGreaterOrEqualThan': true});
      } else if (!this.isFDAEmptyOrNextAfterEnd()) {
        this.form.get('firstDayAvailable').setErrors({'dateFDAEmptyOrNextAfterEnd': true});
      } else  {
        this.form.get('firstDayAvailable').setErrors(null);
        if (value && !this.form.get('firstDayAvailable').disabled) {
          this.form.get('firstDayAvailable').disable();
        }
      }
    });

    this.originalEndDate = this.tableService.getValue().endDate;
    this.type = this.tableService.getValue().type;
    this.elementId = this.tableService.getValue().elementId;
    this.goBackAfterSave = this.tableService.getValue().goBackAfterSave;
    switch (this.type) {
      case 'AL':
        return this.cancelLabel = 'Shorten leave';
      case 'Sickness':
        return this.cancelLabel = 'Shorten sickness';
      case 'Contract':
        return this.cancelLabel = 'Cancel contract';
    }
  }

  isFDAEmptyOrNextAfterEnd() {
    let isFDAEmptyOrNextAfterEnd = false;
    if ((this.form.get('firstDayAvailable').value) === null || moment(this.form.get('cancellationDate').value).add(1, 'day').isSame(this.form.get('firstDayAvailable').value)) {
      isFDAEmptyOrNextAfterEnd = true;
    }
    return isFDAEmptyOrNextAfterEnd;
  }

  clearFirstDayAvailable() {
    this.form.patchValue({ firstDayAvailable: null });
    this.form.get('firstDayAvailable').enable();
  }

  saveData() {
    const data = {
      cancellationDate: this.form.get('cancellationDate').value,
      cancellationDescription: this.form.get('cancellationDescription').value
    };

    switch (this.type) {
      case 'AL':
        const dataLeave = {
          shortenDate: this.form.get('cancellationDate').value,
          shortenDescription: this.form.get('cancellationDescription').value,
          firstDayAvailable: this.form.get('firstDayAvailable').value,
        };
        return this.subscription.add(this.employeesService.shortenEvent(this.elementId, dataLeave).subscribe((response: any) => {
          this.tableService.closeWindow(true, this.goBackAfterSave ? 0 : 1);
          this.toastrService.success(response.message, 'Success');
        },
        (err) => this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true })));
      case 'Sickness':
        const dataSickness = {
          shortenDate: this.form.get('cancellationDate').value,
          shortenDescription: this.form.get('cancellationDescription').value,
          firstDayAvailable: this.form.get('firstDayAvailable').value,
        };
        return this.subscription.add(this.employeesService.shortenEvent(this.elementId, dataSickness).subscribe((response: any) => {
          this.tableService.closeWindow(true, this.goBackAfterSave ? 0 : 1);
          this.toastrService.success(response.message, 'Success');
        },
        (err) => this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true })));
      case 'Contract':
        return this.subscription.add(this.contractsService.updateContract(this.elementId, data).subscribe((response: any) => {
          this.tableService.closeWindow(true, this.goBackAfterSave ? 0 : 1);
          this.toastrService.success(response.message, 'Success');
        },
        (err) => this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true })));
    }
  }

  showExtendEventDialog() {
    const message = `To extend ${this.type} period please add new one`;
    this.subscription.add(this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Extend',
          message: message,
          hideOkButton: true,
          cancelLabel: 'Close'
        },
      })
      .onClose.subscribe(() => {}));
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

}
