import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CroppieOptions, ResultOptions } from 'croppie';
import { Component, forwardRef, OnChanges, ViewChild, Input } from '@angular/core';
import { NgxSuperCroppieComponent } from "ngx-super-croppie";

@Component({
  selector: 'ngx-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageViewerComponent),
      multi: true
    }
  ]
})
export class ImageViewerComponent implements OnChanges {
  @Input() public imgCropToHeight = '160';
  @Input() public imgCropToWidth = '160';
  @Input() public imageData: any;
  public image: any;

  /* Our cropped image and the value of our image controller */
  public croppieImage;

  /* Options for the cropped image type and size */
  public outputoption = { type: 'blob', size: 'viewport' };

  /* Element to paint our form control */

  @ViewChild('ngxSuperCroppie')
  ngxSuperCroppie: NgxSuperCroppieComponent;

  public resultOptions: ResultOptions = {
    type: 'blob',
    size: 'viewport',
    format: 'png',
    quality: 1,
    circle: true,
  };

  public url: string | ArrayBuffer;
  public points: number[] = [0, 0, 0, 0];
  public orientation = 1;
  public zoom = 0;
  public croppedImage: string | HTMLElement | Blob | HTMLCanvasElement;

  constructor() { }

  ngOnChanges(changes: any) {
    this.loadImage();
    if (this.croppieImage) {
      return;
    }

    if (!changes.imageUrl) {
      return;
    }
    if (!changes.imageUrl.previousValue && changes.imageUrl.currentValue) {
      this.croppieImage = changes.imageUrl.currentValue;
      this.propagateChange(this.croppieImage);
    }
  }

  private loadImage() {
    if (this.imageData) {
      const reader = new FileReader();
      reader.readAsDataURL(this.imageData);
      reader.onloadend = () => {
        this.image = reader.result as string;
      };
    }
  }

  public updateCroppedImage(
    crop: string | HTMLElement | Blob | HTMLCanvasElement,
  ): void {
    this.croppedImage = crop;
    this.imageData = crop
    this.ngxSuperCroppie.setZoom(0)
    this.propagateChange(this.croppedImage);
  }

  /* Options for croppie, you can learn more about this here -> https://foliotek.github.io/Croppie/ */
  public get croppieOptions(): CroppieOptions {
    const opts: CroppieOptions = {};
    opts.viewport = {
      width: parseInt(this.imgCropToWidth, 10),
      height: parseInt(this.imgCropToHeight, 10),
      type: 'circle'
    };
    opts.boundary = {
      width: 160,
      height: 160
    };
    opts.enforceBoundary = true;
    opts.showZoomer = true;

    return opts;
  }


  /* Takes the value  */
  writeValue(value: any) {
    if (value !== undefined) {
      this.croppieImage = value;
      this.propagateChange(this.croppieImage);
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}


}
