import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CareHomesApiUrls } from "./care-homes.enum";
import { environment } from "../../../environments/environment";
import * as moment from "moment";
import { Observable } from "rxjs";
import { IAgencyReportResponse } from "../models/agency-report/agency-report.models";
import { map } from "rxjs/operators";
import { ApplicationPermissions } from "../../@core/data/application-permissions.enum";
import { EmployeeAgencyInfoCommand } from "../models/care-homes/agency-report/employee-agency-info-command";
import { TrainingReportModel } from "../models/care-homes/training-report/training-report-model";
import { IHttpSuccessResponse } from "../models/http-success-reposonse.interfaces";
import { CommunityTrackerLine } from "../../pages/task-management/task-boards/community-tracker/community-tracker-line.model";
import {
  IResourcesNeedsResponse,
  IResourcesNeedsResponseGET,
} from "./care-home-models";
import {
  TaskBoardSummaryModel,
  TaskContextModelInfoResponseGET,
} from "../models/care-homes/task-board/task-context.model";

@Injectable()
export class CareHomesService {
  currentCareHome = null;
  constructor(private httpClient: HttpClient) {}

  setCareHome(careHomes, id) {
    this.currentCareHome = careHomes.find((e) => e.careHomeId === id);

    localStorage.setItem("eai-chat_care_home_id", id);
    sessionStorage.setItem("eai-chat_care_home_id", id);
    localStorage.setItem(
      "eai-chat_care_home_name",
      this.currentCareHome.careHomeFullName,
    );
    sessionStorage.setItem(
      "eai-chat_care_home_name",
      this.currentCareHome.careHomeFullName,
    );
  }

  getCareHomes() {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}`,
    );
  }

  getCareHomesWithIdName() {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomesIdName}`,
    );
  }

  getCareHomesWithIdNameForMultiSiteAdmin() {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomesIdName}&permission=${ApplicationPermissions.MULTI_SITE_ADMIN}`,
    );
  }

  getCareHomesShortForm() {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}?shortForm=true`,
    );
  }

  getCareHome(id: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${id}`,
    );
  }

  getSicknessControls(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/sickness-analysis-control`,
    );
  }

  addNewCareHome(data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}`,
      data,
    );
  }

  addNewTrainingHome(data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.trainingHomes}`,
      data,
    );
  }

  unfreezeRota(id: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${id}/rota-unfreeze-10m`,
      data,
    );
  }

  updateCareHome(id: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${id}`,
      data,
    );
  }

  updateCareHomeIndex(id: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${id}/index`,
      data,
    );
  }
  updateCareHomeManager(id: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${id}/evac`,
      data,
    );
  }

  deleteCareHome(careHomeId: number) {
    return this.httpClient.delete(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}`,
    );
  }

  getRooms(careHomeId: number): Observable<any> {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rooms`,
    );
  }

  createRoom(careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rooms`,
      data,
    );
  }

  updateRooms(careHomeId: number, roomId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rooms/${roomId}`,
      data,
    );
  }

  deleteRooms(careHomeId: number, roomId: number) {
    return this.httpClient.delete(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rooms/${roomId}`,
    );
  }

  bookRoom(careHomeId: number, payload): Observable<any> {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/book-room`,
      payload,
    );
  }

  updateBookRoom(careHomeId: number, bookId: number, payload): Observable<any> {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/book-room/${bookId}`,
      payload,
    );
  }

  getDocumentCategories(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/documents-categories`,
    );
  }

  getActivationChecklist(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/activation-checklist`,
    );
  }

  createDocumentCategory(careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/documents-categories`,
      data,
    );
  }

  createActivationChecklist(careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/activation-checklist`,
      data,
    );
  }

  updateDocumentCategory(careHomeId: number, id: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/documents-categories/${id}`,
      data,
    );
  }

  updateActivationChecklist(careHomeId: number, id: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/activation-checklist/${id}`,
      data,
    );
  }

  deleteDocumentCategory(careHomeId: number, id: number) {
    return this.httpClient.delete(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/documents-categories/${id}`,
    );
  }

  deleteActivationChecklist(careHomeId: number, id: number) {
    return this.httpClient.delete(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/activation-checklist/${id}`,
    );
  }

  getResources(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-needs`,
    );
  }

  getNewResources(careHomeId: number): Observable<IResourcesNeedsResponse> {
    return this.httpClient
      .get<IResourcesNeedsResponseGET>(
        `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-needs-config`,
      )
      .pipe(
        map((response) => {
          return response.result;
        }),
      );
  }

  getResourcesXls(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-needs-xls`,
    );
  }

  getNewResourcesXls(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-needs-xls-config`,
    );
  }

  saveRotaSchemaAsBudget(careHomeId: number) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-needs-config-budget`,
      {},
    );
  }

  getLeaversReport(careHomeId: number, startDate: any, endDate: any) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/leavers-report?raportStartDate=${moment(startDate).format(
        "YYYY/MM/DD",
      )}&raportEndDate=${moment(endDate).format("YYYY/MM/DD")}`,
    );
  }

  getLeaversReportActiveOnly(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/leavers-report?activeOnly=true`,
    );
  }

  getLeaversReportXLS(careHomeId: number, startDate: any, endDate: any) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/leavers-report-xls?raportStartDate=${moment(
        startDate,
      ).format("YYYY/MM/DD")}&raportEndDate=${moment(endDate).format(
        "YYYY/MM/DD",
      )}`,
    );
  }

  getLeaversReportActiveOnlyXLS(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/leavers-report-xls?activeOnly=true`,
    );
  }

  updateResourceStartHours(careHomeId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-needs/hours`,
      data,
    );
  }

  createResources(careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-needs`,
      data,
    );
  }

  updateResources(careHomeId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-needs`,
      data,
    );
  }

  createResourcesNew(careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-needs-config`,
      data,
    );
  }

  updateResourcesNew(careHomeId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-needs-config`,
      data,
    );
  }

  deleteResourcesNew(careHomeId: number, needId: any) {
    return this.httpClient.delete(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-needs-config/${needId}`,
    );
  }

  getSchedule(
    careHomeId: number,
    startDate: any,
    endDate: any,
  ): Observable<any> {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/resources-schedules?startDateFormated=${moment(
        startDate,
      ).format("YYYY/MM/DD")}&endDateFormated=${moment(endDate).format(
        "YYYY/MM/DD",
      )}`,
    );
  }

  updateContractSchedule(careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-schedules`,
      data,
    );
  }

  createSicknessControl(careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/sickness-analysis-control`,
      data,
    );
  }

  updateSicknessControl(careHomeId: number, controlId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/sickness-analysis-control/${controlId}`,
      data,
    );
  }

  updateContractScheduleSingleDay(careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-schedule-single-day`,
      data,
    );
  }

  updateSchedulesEnhanced(careHomeId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota-enhanced-schedules`,
      data,
    );
  }
  updateMultisiteCareHomeEnhanced(
    careHomeId: number,
    data: IUpdateCareHomeBody,
  ) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota-enhanced-care-homes`,
      data,
    );
  }

  updateConfirmedEnhanced(careHomeId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota-enhanced-confirmed`,
      data,
    );
  }

  confirmSchedulesEnhanced(careHomeId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota-enhanced-confirm`,
      data,
    );
  }

  deleteScheduleEnhanced(careHomeId: number, rotaUid, date) {
    return this.httpClient.delete(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota/${rotaUid}?date=${date}`,
    );
  }

  updateSchedulesEnhancedNote(careHomeId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota-enhanced-notes`,
      data,
    );
  }

  confirmContractSchedule(careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-confirmations`,
      data,
    );
  }

  updateContractScheduleComment(
    careHomeId: number,
    scheduleId: number,
    data: any,
  ) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-schedules/${scheduleId}`,
      data,
    );
  }

  checkDuplicateScheduleWeek(
    careHomeId: number,
    sourceDate: any,
    targetDate: any,
  ) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/resources-schedules-copy?sourceDateFormated=${moment(
        sourceDate,
      ).format("YYYY/MM/DD")}&targetDateFormated=${moment(targetDate).format(
        "YYYY/MM/DD",
      )}`,
    );
  }

  duplicateScheduleWeek(careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-schedules-copy`,
      data,
    );
  }

  downloadScheduleReportXls(
    careHomeId: number,
    startDate: any,
    endDate: any,
    weekly: boolean,
  ) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/resources-schedule-weeek-xls?startDateFormated=${moment(
        startDate,
      ).format("YYYY/MM/DD")}&endDateFormated=${moment(endDate).format(
        "YYYY/MM/DD",
      )}&weekly=${weekly}`,
    );
  }

  downloadScheduleReportPdf(careHomeId: number, startDate: any, endDate: any) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/resources-schedule-weeek-pdf?startDateFormated=${moment(
        startDate,
      ).format("YYYY/MM/DD")}&endDateFormated=${moment(endDate).format(
        "YYYY/MM/DD",
      )}`,
    );
  }

  downloadConfirmedReportPdf(careHomeId: number, startDate: any, endDate: any) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/resources-confirmed-weeek-pdf?startDateFormated=${moment(
        startDate,
      ).format("YYYY/MM/DD")}&endDateFormated=${moment(endDate).format(
        "YYYY/MM/DD",
      )}`,
    );
  }

  getEvacuationInfo(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/report-evac-info`,
    );
  }

  downloadEvacuationReport(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/report-evac-info-pdf`,
    );
  }

  getFoodPreferences(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/report-food-pref`,
    );
  }

  downloadFoodPreferencesReport(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/report-food-pref-pdf`,
    );
  }

  downloadDrinkPreferencesReport(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/report-am-pm-drinks-pref-pdf`,
    );
  }

  downloadDateOfBirthdayReport(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/report-residents-dob-pdf`,
    );
  }

  downloadContactReport(
    careHomeId: number,
    fileType: any,
    isPresentOnly: boolean,
    data: any,
  ) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/report-contact-info-pdf?careHomeId=${careHomeId}&fileType=${fileType}&isPresentOnly=${isPresentOnly}&secondContact=${data.isSecondaryContact}&financeContact=${data.isFinanceContact}`,
    );
  }

  downloadPayrollReportPdf(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/report-payroll-pdf`,
    );
  }

  downloadPayrollReportXls(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/report-payroll-xls`,
    );
  }

  updateFreezeDate(careHomeId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota-freeze-date`,
      data,
    );
  }

  getFreezeDate(careHomeId: number, startDate: any, endDate: any) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/rota-freeze-date?startDateFormated=${moment(
        startDate,
      ).format("YYYY/MM/DD")}&endDateFormated=${moment(endDate).format(
        "YYYY/MM/DD",
      )}`,
    );
  }

  getFreezeDateById(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota-freeze-date`,
    );
  }

  checkRotaConfirmationPeriod(
    careHomeId: number,
    startDate: any,
    endDate: any,
  ) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/rota-verification-status?fromDateFormated=${moment(
        startDate,
      ).format("YYYY/MM/DD")}&toDateFormated=${moment(endDate).format(
        "YYYY/MM/DD",
      )}`,
    );
  }

  updateRotaConfirmationDate(careHomeId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota-confirmation-date`,
      data,
    );
  }

  getRotaConfirmationDate(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota-confirmation-date`,
    );
  }

  getRotaHoursTemplate(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota-hours-template`,
    );
  }

  updateRotaHoursTemplate(careHomeId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota-hours-template`,
      data,
    );
  }

  getRotaEnhanced(careHomeId: number, roleId: number, date: any) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota-enhanced/${roleId}?dateFormated=${date}`,
    );
  }

  getRotaWeekNoteByDate(careHomeId: number, weekStartDate: any) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/rota-notes?weekDateFormated=${moment(
        weekStartDate,
      ).format("YYYY/MM/DD")}`,
    );
  }

  getRotaWeekNote(careHomeId: number, id: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota-notes/${id}`,
    );
  }

  addRotaWeekNote(careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota-notes`,
      data,
    );
  }

  updateRotaWeekNote(careHomeId: number, id: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota-notes/${id}`,
      data,
    );
  }

  deleteRotaWeekNote(careHomeId: number, id: number) {
    return this.httpClient.delete(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/rota-notes/${id}`,
    );
  }

  getCommunityTracker(careHomeId: number, startDate: any, endDate: any) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/community-trackers?startDateFormated=${moment(
        startDate,
      ).format("YYYY/MM/DD")}&endDateFormated=${moment(endDate).format(
        "YYYY/MM/DD",
      )}`,
    );
  }

  updateCommunityTracker(careHomeId: number, data: CommunityTrackerLine) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/community-trackers/${data.id}`,
      data,
    );
  }

  getCommunityTrackerTargets(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/community-trackers-targets`,
    );
  }

  updateCommunityTrackerTargets(careHomeId: number, data: any, id: number) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/community-trackers-targets/${id}`,
      data,
    );
  }

  addCommunityTrackerTargets(careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/community-trackers-targets`,
      data,
    );
  }

  getFavoriteTasksTaskboard(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/task-board-favorite-tasks?weekStartAtFormated=${moment()
        .startOf("isoWeek")
        .format("YYYY/MM/DD")}`,
    );
  }

  getCollaborationTasksTaskboard(careHomeId: any, employeeId: number = null) {
    const employeeParam = employeeId ? `&employeeId=${employeeId}` : "";
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/task-board-my-collaboration?weekStartAtFormated=${moment()
        .startOf("isoWeek")
        .format("YYYY/MM/DD")}${employeeParam}`,
    );
  }

  getAutotasksTaskboard(careHomeId: number, employeeId = null, dates) {
    const employeeParam = employeeId ? `&employeeId=${employeeId}` : "";
    if (dates.dateFrom && dates.dateTo) {
      return this.httpClient.get(
        `${environment.apiUrl}${
          CareHomesApiUrls.careHomes
        }/${careHomeId}/task-board-annual-tasks?weekStartAtFormated=${moment()
          .startOf("isoWeek")
          .format(
            "YYYY/MM/DD",
          )}${employeeParam}&startDateFormated=${dates.dateFrom.format(
          "YYYY-MM-DD",
        )}&endDateFormated=${dates.dateTo.format("YYYY-MM-DD")}`,
      );
    } else {
      return this.httpClient.get(
        `${environment.apiUrl}${
          CareHomesApiUrls.careHomes
        }/${careHomeId}/task-board-annual-tasks?weekStartAtFormated=${moment()
          .startOf("isoWeek")
          .format("YYYY/MM/DD")}${employeeParam}`,
      );
    }
  }

  getMenuChanges(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/task-board-menu-changes?weekStartAtFormated=${moment()
        .startOf("isoWeek")
        .format("YYYY/MM/DD")}`,
    );
  }

  getResidentsTaskboard(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/task-board-residents?weekStartAtFormated=${moment()
        .startOf("isoWeek")
        .format("YYYY/MM/DD")}`,
    );
  }

  getResidentsTaskboardWithFuturePresences(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/task-board-residents?weekStartAtFormated=${moment()
        .startOf("isoWeek")
        .format("YYYY/MM/DD")}&futurePresences=true`,
    );
  }

  getRoomEnquiresTaskboard(careHomeId: number, employeeId: number = null) {
    const employeeParam = employeeId ? `&employeeId=${employeeId}` : "";
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/task-board-room-enquiries?weekStartAtFormated=${moment()
        .startOf("isoWeek")
        .format("YYYY/MM/DD")}${employeeParam}`,
    );
  }

  getFinanceTaskboard(
    careHomeId: number,
    categoriesFilter = "",
    statusesFilter = "",
    employeeId: number = null,
  ) {
    const employeeParam = employeeId ? `&employeeId=${employeeId}` : "";
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/task-board-finance?weekStartAtFormated=${moment()
        .startOf("isoWeek")
        .format(
          "YYYY/MM/DD",
        )}&categoriesFilter=${categoriesFilter}&statusesFilter=${statusesFilter}${employeeParam}`,
    );
  }

  getTaskBoardsSummary(careHomeId: number, reportType: string) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/task-board-summary?weekStartAtFormated=${moment()
        .startOf("isoWeek")
        .format("YYYY/MM/DD")}&reportType=${reportType}`,
    );
  }

  getManagerTaskBoardsSummary(
    careHomeId: number,
    reportType: string,
    details: 0 | 1,
  ) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/task-board-summary?weekStartAtFormated=${moment()
        .startOf("isoWeek")
        .format(
          "YYYY/MM/DD",
        )}&managerId=1&reportType=${reportType}&details=${details}`,
    );
  }

  getTaskBoardContextInfo(
    careHomeId: number,
    context: string,
  ): Observable<TaskBoardSummaryModel> {
    return this.httpClient
      .get<TaskContextModelInfoResponseGET>(
        `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/task-board-context-info?context=${context}`,
      )
      .pipe(
        map((response) => {
          return response.result.taskBoardSummaryList[0];
        }),
      );
  }

  getTaskBoardContext(
    careHomeId: number,
    context: string,
  ): Observable<{ message: string; result: any }> {
    return this.httpClient.get<{ message: string; result: any }>(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/task-board-context?context=${context}`,
    );
  }

  submitWeeklyReport(careHomeId: number, reportType: string, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/weekly-report?reportType=${reportType}`,
      data,
    );
  }

  getMenuChoices(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/menu-choices`,
    );
  }

  addMenuChoice(careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/menu-choices`,
      data,
    );
  }

  updateMenuChoice(careHomeId: number, menuId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/menu-choices/${menuId}`,
      data,
    );
  }

  getMenuChoice(careHomeId: number, menuId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/menu-choices/${menuId}`,
    );
  }

  deleteMenuChoice(careHomeId: number, menuId: number) {
    return this.httpClient.delete(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/menu-choices/${menuId}`,
    );
  }

  downloadMenuChoicePdf(careHomeId: number, menuId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/menu-choices/${menuId}/pdf`,
    );
  }

  getStaffHours(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/staff-hours-report`,
    );
  }

  getStaffGapHours(careHomeId: number, startDate?, endDate?) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/staff-hours-report-details?startDateFormated=${moment(
        startDate,
      ).format("YYYY/MM/DD")}&endDateFormated=${moment(endDate).format(
        "YYYY/MM/DD",
      )}`,
    );
  }

  updateStaffHours(careHomeId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/staff-hours-report`,
      data,
    );
  }

  getEmployeeDocumentsReport(careHomeId: number, activeOnly: boolean = true) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/employees-documents-report?activeOnly=${activeOnly}`,
    );
  }

  getAlExtrasReport(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/al-extras-report`,
    );
  }

  sendTasksInEmailTasks(careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/group-email-tasks`,
      data,
    );
  }

  sendTasksInEmail(careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/group-email`,
      data,
    );
  }

  getTasksToEmail(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/group-email`,
    );
  }

  getGroupEmailRecipients(
    careHomeId: number,
    reportType: string,
    taskboards?: any,
  ) {
    if (!taskboards) taskboards = [];
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/group-email/recipients?reportType=${reportType}&taskboards=${taskboards}`,
    );
  }

  acceptSchedulePositions(careHomeId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/shedule-portable/accept`,
      data,
    );
  }

  clearSchedulePositions(careHomeId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/shedule-portable/clear`,
      data,
    );
  }

  denySchedulePositions(careHomeId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/shedule-portable/deny`,
      data,
    );
  }

  explainSchedulePositions(careHomeId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/shedule-portable/manager-replay`,
      data,
    );
  }

  getEmployeesScheduleCalendar(careHomeId: number, date: any) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/schedule-dates-info?scheduleMonth=${moment(date)
        .startOf("month")
        .format("YYYY/MM/DD")}`,
    );
  }

  getEmployeesSchedule(careHomeId: number, date: any, notVerifiedOnly = false) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        CareHomesApiUrls.careHomes
      }/${careHomeId}/schedule-acceptance-form?scheduleDay=${moment(
        date,
      ).format("YYYY/MM/DD")}&notVerifiedOnly=${notVerifiedOnly}`,
    );
  }

  updateSchedulePositionReply(
    careHomeId: number,
    scheduleLineId: number,
    data: any,
  ) {
    return this.httpClient.patch(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/shedule-portable/${scheduleLineId}/manager-reply`,
      data,
    );
  }

  getSchedulePositionPhoto(careHomeId: number, photoUid: any, type: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/schedule-confirmation-photo/${photoUid}?typeId=${type}`,
    );
  }

  addScheduleDayNote(careHomeId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/signoff-notes`,
      data,
    );
  }

  updateScheduleDayNote(careHomeId: number, id: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/signoff-notes/${id}`,
      data,
    );
  }

  getMessageGroupsRoles(id: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/message-groups/${id}`,
    );
  }

  updateMessageGroupsRoles(id: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/message-groups/${id}`,
      data,
    );
  }

  deleteSicknessControls(careHomeId: number, controlId: number) {
    return this.httpClient.delete(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/sickness-analysis-control/${controlId}`,
    );
  }

  public getAgencyReport(
    careHomeId: number,
    startDateFormatted: string,
    endDateFormatted: string,
  ): Observable<IAgencyReportResponse> {
    const query = `startDateFormated=${startDateFormatted}&endDateFormated=${endDateFormatted}`;
    return this.httpClient
      .get<IHttpSuccessResponse<IAgencyReportResponse>>(
        `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/report-agency?${query}`,
      )
      .pipe(map((item) => item.result));
  }

  public downloadAgencyReportXls(
    careHomeId: number,
    startDateFormatted: string,
    endDateFormatted: string,
  ): Observable<{ message: string; link: string }> {
    const query = `startDateFormated=${startDateFormatted}&endDateFormated=${endDateFormatted}`;
    return this.httpClient.get<{ message: string; link: string }>(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/report-agency-xls?${query}`,
    );
  }

  public updateEmployeeAgencyInfo(
    careHomeId: number,
    agencyEmployeeId: number,
    body: EmployeeAgencyInfoCommand,
  ): Observable<any> {
    return this.httpClient.put(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/resources-schedule-single-day/agency/${agencyEmployeeId}`,
      body,
    );
  }

  public loadTrainingReport(
    careHomeId: number,
  ): Observable<TrainingReportModel[]> {
    return this.httpClient
      .get<IHttpSuccessResponse<TrainingReportModel[]>>(
        `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/training-report`,
      )
      .pipe(
        map((item: IHttpSuccessResponse<TrainingReportModel[]>) => item.result),
      );
  }

  public addNewTeam(
    careHomeId: number,
    payload: { teamName: string; description: string; roleUid: string },
  ) {
    return this.httpClient.post(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/teams`,
      payload,
    );
  }

  public updateTeam(
    careHomeId: number,
    teamUid: string,
    payload: { teamName: string; description: string; isShowTotal: boolean },
  ) {
    return this.httpClient.patch(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/teams/${teamUid}`,
      payload,
    );
  }

  public deleteTeam(careHomeId: number, teamUid: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/teams/${teamUid}`,
    );
  }

  public changeTeamForRole(
    careHomeId: number,
    teamUid: string,
    payload: {
      roleUid: string;
      roleIndex: number;
      roles: string[];
      prevRoleIndex?: number;
    },
  ) {
    return this.httpClient.patch(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/teams/${teamUid}/roles-list`,
      payload,
    );
  }
  public changeTeamOrder(
    careHomeId: number,
    teamUid: string,
    payload: { teamIndex: number },
  ) {
    return this.httpClient.patch(
      `${environment.apiUrl}${CareHomesApiUrls.careHomes}/${careHomeId}/teams/${teamUid}`,
      payload,
    );
  }
}

export interface IUpdateCareHomeBody {
  roleId: number;
  employeeId: number;
  rotaDate: string;
  careHomeId: number;
  rotaUid: string;
  dayName: string;
}
