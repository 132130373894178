<nb-card>
  <nb-card-header>Add watchers to task</nb-card-header>
  <nb-card-body>
    <form [formGroup]="form">
      <ngx-multiple-autocomplete-selector
        *ngIf="loadingStatus === LoadingStatus.LOADED"
        [values]="watchersToAdd"
        [selectedValues]="choosendWatchers"
        formControlName="selectedWatchers"
        [placeholder]="'select watchers'"
        [label]="'Watchers:'"
      ></ngx-multiple-autocomplete-selector>
      <ngx-multiple-autocomplete-selector
        *ngIf="loadingStatus === LoadingStatus.LOADED"
        [values]="watchersToAdd"
        formControlName="watchers"
        [placeholder]="'select watchers'"
        [label]="'New watchers:'"
      ></ngx-multiple-autocomplete-selector>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-success mr-2" (click)="ok()">Ok</button>
    <button class="btn btn-danger" (click)="cancel()">Cancel</button>
  </nb-card-footer>
</nb-card>
