<nb-card [nbSpinner]="isLoading" nbSpinnerSize="large" nbSpinnerStatus="success">
  <div  class="roles-multi-site">
    <form *ngIf="!errorMsg" [formGroup]="rolesMultiSiteForm" class="roles-multi-site__form">
      <div class="roles-multi-site__selector-container">
        <p class="roles-multi-site__label">Multi site worker</p>
        <ngx-multiple-autocomplete-selector-with-checkbox [values]="careHomes" placeholder="Search..."
                                                          [label]="'Choose the care homes where this person can pick up scheduled shifts:'" [formControlName]="'multiSiteWorker'">
        </ngx-multiple-autocomplete-selector-with-checkbox>
      </div>
      <div class="roles-multi-site__selector-container">
        <p class="roles-multi-site__label">Multi site scheduler</p>
        <ngx-multiple-autocomplete-selector-with-checkbox [values]="careHomes" placeholder="Search..."
                                                          [label]="'Choose the care homes where this person can schedule their own shifts:'" [formControlName]="'multiSiteScheduler'">
        </ngx-multiple-autocomplete-selector-with-checkbox>
      </div>
    </form>
    <div *ngIf="errorMsg" class="roles-multi-site__error">
      <span>{{errorMsg}}</span>
    </div>
  </div>
</nb-card>

