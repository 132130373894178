<table #table mat-table matSort  (matSortChange)="matSortChange.emit($event)"
  [dataSource]="dataSource">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
        </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? toggleRow(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
  </ng-container>
  <ng-container *ngFor="let column of columns" [matColumnDef]="column.fieldName">
    <!-- header -->
    <th mat-header-cell mat-sort-header *matHeaderCellDef 
        class="header">
      <span>{{column.title}}</span> 
    </th>
    <!-- header -->
    <!-- content -->
    <td [ngClass]="{bold: !element.readed}" mat-cell *matCellDef="let element" (click)="onRowClick ? onRowClick(element) : ''">
      <span *ngIf="!column.valuePrepareFunction" (click)="column.action ? column.action($event, element) : null">{{element ? element[column.fieldName] : ''}}</span>
      <span *ngIf="column.valuePrepareFunction" (click)="column.action ? column.action($event, element) : null" [innerHTML]="element ? column.valuePrepareFunction(element) : ''"></span>
    </td>
    <!-- content -->
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="thinner"></tr>
</table>
