import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-subtask-editable-title',
  templateUrl: './subtask-editable-title.component.html',
  styleUrls: ['./subtask-editable-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubtaskEditableTitleComponent {

  @Input() editDisabled: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isMultiple: boolean = false;
  @Input() subtaskPercent: string;
  @Input() index: number;
  @Input() title: string;
  @Input() isLast: boolean = false;
  @Input() isFirst: boolean = false;
  @Input() isDeletedReadonly: boolean = false;
  @Output() deleteSubtask = new EventEmitter<number>();
  @Output() saveTitle = new EventEmitter<{ title: string, index: number }>();
  @Output() moveSubtask = new EventEmitter<{ title: string, oldIndex: number, newIndex: number }>();
  @Output() toggleAccordion = new EventEmitter();
  @Output() recurringTask = new EventEmitter();
  public editMode: boolean = false;

  constructor(protected toastrService: NbToastrService) {}

  edit() {
    this.editMode = true;
    setTimeout(() => {
      document.getElementById('subtask-editable-title-edit-textarea').focus();
    }, 100);
  }

  deleteItem() {
    this.deleteSubtask.emit(this.index);
  }

  save() {
    if (this.title.trim().length == 0) {
      this.toastrService.danger('Subtask title cannot be blank', 'Error', { duration: 60000, destroyByClick: true });
    } else {
      const data = {
        title: this.title,
        index: this.index
      };
      this.editMode = false;
      this.saveTitle.emit(data);
    }
  }

  goUp() {
    const newIndex = this.index - 1;
    this.moveSubtask.emit({
      title: this.title,
      newIndex: newIndex,
      oldIndex: this.index
    });
  }

  goDown() {
    const newIndex = this.index + 1;
    this.moveSubtask.emit({
      title: this.title,
      newIndex: newIndex,
      oldIndex: this.index
    });
  }

  makeRecurringTasks() {
    this.recurringTask.emit();
  }
}
