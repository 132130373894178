import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubtasksService {
  public expandTodo$ = new Subject<number>();
  private expandTodoId = null;
  public waitForTaskResponse = false;
  public keepAllRemaining$ = new Subject<boolean>();
  public users$ = new BehaviorSubject<any>([]);
  private users = [];

  toogleTodoDetails(todoId: number) {
    if (this.expandTodoId != todoId) {
      this.expandTodoId = todoId;
    } else {
      this.expandTodoId = null;
    }
    this.expandTodo$.next(this.expandTodoId);
  }

  public setUsers(users = []) {
    this.users = users;
    this.users$.next(this.users);
  }

  public getUsers() {
    return this.users;
  }
}
