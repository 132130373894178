import { Component, Input, OnInit } from '@angular/core';
import { TableService } from '../../../shared/table/table.service';
import { NbDialogRef } from '@nebular/theme';
import { ExplanationAnnualLeave } from '../model/explanation-annual-leave.model';

@Component({
  selector: 'explain-annual-leave',
  templateUrl: './explain-annual-leave.component.html',
  styleUrls: ['./explain-annual-leave.component.scss']
})
export class ExplainAnnualLeaveComponent implements OnInit {
  @Input() data: {
    employeeName: string;
    employeeId: number;
    explanation: ExplanationAnnualLeave;
    alText: string
  };
  constructor(protected ref: NbDialogRef<ExplainAnnualLeaveComponent>) { }

  ngOnInit(): void {
  }

  close() {
    this.ref.close();
  }



  getPercentage(): string {
    return Math.floor( (this.data.explanation.totalUsedOfMax) * 100) + '%'
  }
}
