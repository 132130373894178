import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ICareHome } from '../../../shared/interfaces/care-home.interfaces';
import { CareHomesInfoService } from '../../../shared/services/care-homes-info.service';
import { IScheduleInfoList, IScheduleWeekInfoDepList } from "../../../shared/interfaces/shifts.interfaces";

@Component({
  selector: 'ngx-rota-migration-table',
  templateUrl: './rota-migration-table.component.html',
  styleUrls: ['./rota-migration-table.component.scss']
})
export class RotaMigrationTableComponent implements OnInit {

  @Input() week: IScheduleInfoList;
  @Input() homeId: number;
  @Input() homeName: string;
  @Input() employeeId: any;
  public careHomes$: Observable<ICareHome[]>;
  public amountToMigrateValue: number;

  constructor(
    private _careHomesInfoService: CareHomesInfoService
  ) {
    this.careHomes$ = this._careHomesInfoService.careHomes$;
  }

  ngOnInit() {
    this.amountToMigrate(this.week.scheduleWeekInfoDepList);
  }

  amountToMigrate(weekList: IScheduleWeekInfoDepList[]) {
    const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    let amount = 0;
    weekList.forEach(contract => {
      weekdays.forEach(day => {
        if (contract[day].requireMigration) {
          amount++;
        }
      });
    });

    this.amountToMigrateValue = amount;
  }

  updateWeekScheduleData($event: IScheduleWeekInfoDepList[]) {
    this.amountToMigrate($event);
  }
}
