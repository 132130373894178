import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { AuthComponent } from "./auth.component";
import { RequestPassComponent } from "./request-pass/request-pass.component";
import { NewPassComponent } from "./new-pass/new-pass.component";
import { WizardComponent } from "./wizard/wizard.component";

type PathMatch = "full" | "prefix" | undefined;

const routes: Routes = [
  {
    path: "",
    component: AuthComponent,
    children: [
      {
        path: "login",
        component: LoginComponent,
      },
      {
        path: "request-pass",
        component: RequestPassComponent,
      },
      {
        path: "new-pass",
        component: NewPassComponent,
      },
      {
        path: "wizard",
        component: WizardComponent,
      },
      {
        path: "register",
        loadChildren: () =>
          import("./register/register.module").then((m) => m.RegisterModule),
      },
      {
        path: "",
        redirectTo: "login",
        pathMatch: "full" as PathMatch,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
