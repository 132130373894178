import { Injectable, NgZone, TemplateRef } from "@angular/core";
import {
  NbWindowService,
  NbComponentType,
  NbWindowRef,
  NbToastrService,
} from "@nebular/theme";
import { BehaviorSubject, Subject } from "rxjs";
import { AuthUserService } from "../../../@core/data/auth-user.service";
import { BackPage } from "./types/back-page.interface";
import { SharedAnnualLeavingService } from "../services/shared-annual-leaving.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class TableService {
  public zoom$ = new BehaviorSubject<number>(1.0);
  private toSaveCounter: number = 0;
  private value: any;
  private windowRef: NbWindowRef;
  private refreshSource = new Subject<any>();
  private refreshItemOnTable = new Subject<any>();
  private resetSort = new Subject<void>();
  public refresh$ = this.refreshSource.asObservable();
  public refreshLocal$ = this.refreshItemOnTable.asObservable();
  public resetSort$ = this.resetSort.asObservable();
  public offsetTop = 0;
  private backStack: Array<BackPage> = [];
  private windowBeforeParams;
  public isWindowCurrentlyOpen: boolean;

  public isMessageReport: boolean;

  // additional for open window from other component and cannot use backstact because don't know params
  private goToWindowBefore = false;

  public setMainFocus = new BehaviorSubject<void>(null);

  constructor(
    private windowService: NbWindowService,
    private toastrService: NbToastrService,
    private authUserService: AuthUserService,
    private router: Router,
    private ngZone: NgZone,
    private _sharedAnnualLeavingService: SharedAnnualLeavingService,
  ) {}

  toSaveCounterAdd() {
    this.toSaveCounter++;
  }

  toSaveCounterDesc() {
    if (this.toSaveCounter > 0) {
      this.toSaveCounter--;
    }
  }

  getToSaveCounter() {
    return this.toSaveCounter;
  }

  setValue(value: any) {
    this.value = value;
  }

  getValue() {
    return this.value;
  }

  reloadTable(parameter: any = true) {
    this.refreshSource.next(parameter);
  }

  resetSortTable() {
    this.resetSort.next();
  }

  openWindowWithoutCareHomeVerification(
    windowContent: TemplateRef<any> | NbComponentType,
    title: string,
    parameter?: any,
    windowClass?: string,
  ) {
    this.openNewWindow(windowContent, title, false, parameter, windowClass);
  }

  openWindowAndRememberWindowBefore(
    windowContent: TemplateRef<any> | NbComponentType,
    title: string,
    parameter?: any,
  ) {
    this.windowBeforeParams = {
      windowContent,
      title,
      verifyCareHomeSelected: true,
      parameter,
    };
    this.goToWindowBefore = true;
    this.openNewWindow(windowContent, title, true, parameter);
  }

  openNewWindowAndCloseThis(
    windowContent: TemplateRef<any> | NbComponentType,
    title: string,
    parameters?: any,
  ) {
    if (this.windowRef) {
      this.windowRef.close();
    }
    this.openNewWindow(windowContent, title, true, parameters);
  }

  openWindowWithBackStack(
    windowContent: TemplateRef<any> | NbComponentType,
    title: string,
    backPage: BackPage,
    parameters?: any,
    windowClass?: string,
  ) {
    if (this.windowRef) {
      this.windowRef.close();
    }
    this.openNewWindow(windowContent, title, true, parameters, windowClass);
    this.backStack.push(backPage);
  }

  openWindow(
    windowContent: TemplateRef<any> | NbComponentType,
    title: string,
    parameter?: any,
    windowClass?: string,
  ) {
    this.openNewWindow(windowContent, title, true, parameter, windowClass);
  }

  updateLocalObjectAndCloseWindow(objectToUpdate: any, setMainFocus = false) {
    this.refreshItemOnTable.next(objectToUpdate);
    this.closeWindow(false);
    if (setMainFocus) {
      this.setMainFocus.next();
    }
  }

  closeWindowClearAndRedirectTo(url: string, newTab: boolean = false) {
    this.goToWindowBefore = false;
    this.windowBeforeParams = {};
    this.windowRef.close();
    const urlPath = this.router.serializeUrl(this.router.createUrlTree([url]));
    if (newTab) {
      window.open("#" + urlPath);
    } else {
      this.router.navigate([urlPath]);
    }
  }

  justRefreshData(parameter: any = {}) {
    this.refreshSource.next(parameter);
  }

  closeWindow(
    parentPageNeedRefresh: boolean,
    skipPage: number = 0,
    parameter: any = {},
  ) {
    if (parentPageNeedRefresh) {
      this.refreshSource.next(parameter);
    }
    this.windowRef.close();
    this.isWindowCurrentlyOpen = false;
    if (this.goToWindowBefore && this.windowBeforeParams) {
      this.openNewWindow(
        this.windowBeforeParams.windowContent,
        this.windowBeforeParams.title,
        this.windowBeforeParams.verifyCareHomeSelected,
        this.windowBeforeParams.parameter,
      );
      this.goToWindowBefore = false;
    }
    if (this.backStack.length > 0) {
      let backPage = this.backStack.pop();
      for (let i = 0; i < skipPage; i++) {
        backPage = this.backStack.pop();
      }
      if (backPage && backPage.elementId !== undefined) {
        this._sharedAnnualLeavingService.elementId = backPage.elementId;
        this._sharedAnnualLeavingService.eventNo = backPage.eventNo;
        this._sharedAnnualLeavingService.isAl = backPage.isAl;
        this.openNewWindow(backPage.component, backPage.title, true, backPage);
      }

      if (this.backStack.length === 0) {
        this._sharedAnnualLeavingService.elementId = undefined;
        this._sharedAnnualLeavingService.eventNo = undefined;
      }
    }
  }

  private openNewWindow(
    windowContent: TemplateRef<any> | NbComponentType,
    title: string,
    verifyCareHomeSelected: boolean,
    parameter?: any,
    windowClass?: string,
  ) {
    if (verifyCareHomeSelected && this.authUserService.getCareHomeId() == 0) {
      this.toastrService.warning(
        "To do this operation you must choose specific Care Home in header of the page",
        "Info",
      );
    } else {
      this.setValue(parameter);
      const config = {
        closeOnBackdropClick: windowClass === "max-100-h" ? true : false,
        closeOnEsc: false,
        title: title,
        context: parameter,
        windowClass: windowClass ? windowClass : "",
      };

      this.windowRef = this.windowService.open(windowContent, config);
      this.isWindowCurrentlyOpen = true;
      this.ngZone.runOutsideAngular(() => {
        (<any>document.getElementsByTagName("nb-window")[0]).style.zoom =
          this.zoom$.getValue();
      });

      this.ngZone.runOutsideAngular(() => {
        (<any>document.getElementsByTagName("nb-window")[0]).setAttributeNS(
          null,
          "cdkDrag",
          "",
        );
      });
    }
  }

  public changeWindowName(title: string) {
    this.windowRef.config.title = title;
  }
}
