import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CellErrorType } from '../rota/cell-error-type.enum';
import { DictionariesAgenciesModel } from '../../../shared/models/dictionaries/agencies/dictionaries-agencies-model';

@Injectable({
  providedIn: 'root'
})
export class DayShiftsTableService {
  private isRowInEdit = false;
  public showDetails = true;
  public errorType: CellErrorType = CellErrorType.NONE;
  public showDetailsSubject$ = new BehaviorSubject<boolean>(true);
  public edit$ = new BehaviorSubject<{rowName: string, discardData: boolean, edit: boolean}>({
    rowName: null,
    discardData: false,
    edit: false
  });
  public rememberScrollPosition$ = new Subject();
  public hoursTemplate: any;
  public isFreezed = false;

  private _agenciesDictionary$: BehaviorSubject<DictionariesAgenciesModel> = new BehaviorSubject<DictionariesAgenciesModel>(null);

  get agenciesDictionary$(): Observable<DictionariesAgenciesModel> {
    return this._agenciesDictionary$.asObservable();
  }

  setAgenciesDictionary(value: DictionariesAgenciesModel): void {
    this._agenciesDictionary$.next(value);
  }

  startEdit(rowName: string) {
    if (!this.isRowInEdit) {
      this.edit$.next({
        rowName: rowName,
        discardData: false,
        edit: true
      });
      this.isRowInEdit = true;
    }
  }

  closeEdit(rowName: string, discardData: boolean) {
    this.edit$.next({
      rowName: rowName,
      discardData: discardData,
      edit: false
    });
    this.isRowInEdit = false;
  }

  toggleShowDetails() {
    this.showDetails = !this.showDetails;
    this.showDetailsSubject$.next(this.showDetails);
  }

  resetService() {
    this.isRowInEdit = false;
  }

}
