<div
  class="todo-container"
  [ngClass]="{
    green: todo.isCompleted,
    red: todo.isNotCompleted || todo.latestUpdate,
    placeholder: isPlaceholder
  }"
>
  <div class="left-bar">&nbsp;</div>
  <div class="subtask-editable-todo-content">
    <nb-accordion>
      <nb-accordion-item
        (collapsedChange)="collapsedChange($event)"
        [collapsed]="todo.isCollapsed || allCollapsed"
        class="subtask-editable-todo-content__accordion-item"
      >
        <nb-accordion-item-header>
          <div
            *ngIf="editMode"
            [formGroup]="form"
            class="subtask-editable__header-input-wrapper"
          >
            <textarea
              (keydown.space)="$event.stopPropagation()"
              (keydown.enter)="$event.stopPropagation()"
              id="subtask-editable-todo-edit-input"
              #todoInput
              formControlName="comments"
              name="comments"
              placeholder="Type title here"
              class="form-control thin-echo-scrollbar-5"
              style="height: 7.5em; max-height: 7.5em"
              (focusout)="save()"
              minlength="1"
              [disabled]="disabled"
            ></textarea>
          </div>
          <div *ngIf="!editMode" class="flex-between w-100">
            <div class="flex-center subtask-editable__title-section w-100">
              <ngx-checkbox
                *ngIf="!todo.isDisabled && !disabled"
                [(ngModel)]="todo.isCompleted"
                (click)="completedChanged($event)"
                [color]="CheckboxColor.GREEN"
                tooltip="mark as done"
                class="checkbox-space title-checkbox subtask-editable__checkbox"
              >
              </ngx-checkbox>
              <div class="todo-title-rotate-box thin-echo-scrollbar">
                <div [style.transform]="'rotateY(180deg)'">
                  <span
                    class="text-content thin-echo-scrollbar"
                    [ngClass]="{ expand: expandText, truncated: showMoreInfo }"
                    #titleTodo
                    [style.margin]="'unset'"
                  >
                    <span
                      style="
                        direction: ltr;
                        display: inline-block;
                        padding-left: 10px;
                      "
                      [ngClass]="{ 'all-collapsed': allCollapsed }"
                      >{{ todo.comments }}</span
                    >
                  </span>
                  <span
                    class="toggle-button"
                    (click)="
                      $event.stopPropagation();
                      $event.preventDefault();
                      toggleExpand()
                    "
                    *ngIf="showMoreInfo"
                    >{{ expandText ? "Show less" : "Show more" }}</span
                  >
                </div>
              </div>
            </div>
            <div class="flex-center">
              <ngx-checkbox
                *ngIf="!todo.isDisabled && !disabled"
                [(ngModel)]="todo.isNotCompleted"
                (mouseup)="notCompletedChanged($event)"
                [color]="CheckboxColor.RED"
                label="NOT Done"
                class="checkbox-space"
                [disabled]="!isGroupManager && disabled"
                tooltip="keep"
              >
              </ngx-checkbox>
              <span
                class="icon"
                [ngClass]="{ disabled: todo.isRecurringReadonly || disabled }"
                nbTooltip="recurring"
              >
                <nb-icon
                  (click)="!disabled && toggleInfinity($event)"
                  icon="flip-2-outline"
                  class="infinity-icon"
                  [ngClass]="{ active: todo.isRecurring, disabled: disabled }"
                ></nb-icon>
              </span>
              <span
                (click)="edit($event)"
                nbTooltip="edit"
                class="icon"
                [ngClass]="{ disabled: todo.isEditReadonly || disabled }"
                ><i class="nb-edit" aria-hidden="true"></i
              ></span>
              <span
                (click)="deleteItem($event)"
                class="icon red"
                nbTooltip="remove"
                [ngClass]="{ disabled: todo.isDeleteReadonly || disabled }"
                ><i class="nb-trash" aria-hidden="true"></i
              ></span>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <table style="position: relative">
            <tr>
              <td class="left-column">
                <textarea
                  *ngIf="!isGroupManager"
                  (mousedown)="$event.stopPropagation()"
                  [id]="
                    'subtask-editable-todo-edit-latest-update-input-' +
                    subtaskIndex +
                    '-' +
                    toDoindex +
                    '~~~' +
                    getSubtaskLatestUpdate(todo.comments)
                  "
                  name="latestUpdate"
                  placeholder="please fill Latest Update here"
                  nbInput
                  [(ngModel)]="todo.latestUpdate"
                  (ngModelChange)="onModelChange()"
                  class="textarea-view can-be-edited thin-echo-scrollbar"
                  [ngClass]="{
                    highlight: tableEditMode === TodoEditMode.TODO_LATEST_UPDATE
                  }"
                  (focusout)="saveTableLatestUpdate()"
                  (click)="enableTableEdit(TodoEditMode.TODO_LATEST_UPDATE)"
                  [autofocus]="highlight"
                  minlength="1"
                  [disabled]="disabled"
                  #latestUpdateInput
                >
                </textarea>
                <div *ngIf="isGroupManager" style="min-height: 74px">
                  <!-- <textarea
              (mousedown)="$event.stopPropagation()"
              [id]="
                'subtask-editable-todo-edit-latest-update-input-' +
                subtaskIndex +
                '-' +
                toDoindex
              "
              name="latestUpdate"
              placeholder="Submitted Update"
              [(ngModel)]="todo.shortHistory"
              class="textarea-view disabled-field full-width-group-manager thin-echo-scrollbar"
              [ngClass]="{highlight: tableEditMode === TodoEditMode.TODO_LATEST_UPDATE}"
              (focusout)="saveTableLatestUpdate()"
              (click)="enableTableEdit(TodoEditMode.TODO_LATEST_UPDATE)"
              minlength="1"
              maxlength="100"
              readonly
              [disabled]="disabled"
            >
            </textarea>
            <p *ngIf="isGroupManager" class="todo-update-time group-manager-update-time" [ngClass]="{ green: todo.isAnsweredInPrevWU }" > {{ todo.updateTime }} </p> -->

                  <div
                    tabindex="1"
                    class="textarea-view disabled-field thin-echo-scrollbar history-with-comments with-group-manager"
                  >
                    <div
                      class="history-container"
                      [innerHTML]="todo.shortHistory"
                    ></div>
                    <div
                      *ngIf="todo?.updateHistory?.length"
                      class="short-history update-history-container"
                      [innerHTML]="getComments(todo.updateHistory)"
                    ></div>
                  </div>
                  <p
                    *ngIf="isGroupManager"
                    class="todo-update-time group-manager-update-time"
                    [ngClass]="{ green: todo.isAnsweredInPrevWU }"
                  >
                    {{ todo.updateTime }}
                  </p>
                </div>
                <p
                  *ngIf="!isGroupManager"
                  class="todo-update-time"
                  [ngClass]="{ green: todo.isAnsweredInPrevWU }"
                >
                  {{ todo.updateTime }}
                </p>
              </td>
              <td *ngIf="!isGroupManager" class="middle-column to-top">
                <div
                  tabindex="1"
                  class="textarea-view disabled-field thin-echo-scrollbar history-with-comments"
                >
                  <div
                    class="history-container"
                    [innerHTML]="todo.shortHistory"
                  ></div>
                  <pre
                    *ngIf="todo?.updateHistory?.length"
                    class="short-history update-history-container"
                    [innerHTML]="getComments(todo.updateHistory)"
                  ></pre>
                </div>
              </td>
              <td class="right-column flex">
                <button
                  (keyup.r)="setStatus(true)"
                  (keyup.k)="setStatus(false)"
                  (keyup.enter)="doNothing($event)"
                  (mouseup)="setStatus(true, false)"
                  class="decision-button disabled"
                  [id]="
                    'subtask-editable-todo-done-button-' +
                    subtaskIndex +
                    '-' +
                    toDoindex
                  "
                  [ngClass]="{
                    confirmable: isConfirmable(),
                    disabled: disabled
                  }"
                  [disabled]="disabled"
                >
                  <div>
                    <nb-icon
                      class="mb-1 bigger-icon"
                      icon="checkmark-outline"
                    ></nb-icon>
                    <span>"R"</span>
                  </div>
                </button>
                <button
                  (keyup.r)="setStatus(true)"
                  (keyup.k)="setStatus(false)"
                  (keyup.enter)="doNothing($event)"
                  [disabled]="disabled"
                  (mouseup)="setStatus(false, false)"
                  class="decision-button red"
                  [ngClass]="{ disabled: todo.isNotCompleted || disabled }"
                  [id]="
                    'subtask-editable-todo-not-done-button-' +
                    subtaskIndex +
                    '-' +
                    toDoindex
                  "
                >
                  <div>
                    <nb-icon
                      class="mb-1 bigger-icon"
                      icon="close-outline"
                    ></nb-icon>
                    <span>"K"</span>
                  </div>
                </button>
                <button (click)="toogleDetails()" class="decision-button blue">
                  <nb-icon
                    icon="arrow-ios-downward-outline"
                    [ngClass]="{ expanded: expandTodoId == todo.id }"
                  ></nb-icon>
                </button>
              </td>
            </tr>
          </table>

          <div
            *ngIf="expandTodoId && expandTodoId == todo.id"
            class="additional-actions-container"
          ></div>
          <div
            *ngIf="
              (todo.assignedUsers && todo.assignedUsers.length > 0) ||
              (expandTodoId && expandTodoId == todo.id)
            "
          >
            <ngx-multiple-autocomplete-selector
              [values]="users"
              [disabled]="disabled"
              [selectedValues]="todo.assignedUsers"
              [(ngModel)]="todo.assignedUsers"
              (ngModelChange)="onAssingedUserSelected($event)"
              noSelectAll="true"
              [label]="'Assigned to'"
            ></ngx-multiple-autocomplete-selector>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </div>
</div>

<ng-template #legendPopover>
  <div *ngIf="task.shortHistory" style="width: 300px; height: 100%">
    <p><strong>Submitted home AFG reply:</strong></p>
    <div [innerHTML]="task.shortHistory"></div>
  </div>
  <div
    *ngIf="!task.shortHistory"
    class="disabled-field"
    style="width: 250px; height: 50px"
  >
    <p><strong>Submitted home AFG reply:</strong></p>
    <p>There is no information here.</p>
  </div>
</ng-template>
