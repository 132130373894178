import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardService {
  constructor(private httpClient: HttpClient) { }

  getGlobalFavoriteTasksTaskboard() {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/global-favorite-tasks?careHomeId=general`);
  }

  getCompletedCourses(startDate: any, endDate: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/completed-courses?startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}`);
  }

  getCompletedCoursesXls(startDate: any, endDate: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/completed-courses-xls?startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}`);
  }

  getSicknessReport(startDate: any, endDate: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/absences-list?dashboardVersion=true&startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}`);
  }

  getSicknessReportXls(startDate: any, endDate: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/absences-list-xls?dashboardVersion=true&startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}`);
  }

  getAllTasks(startDate: any, endDate: any, careHomeId: any = 'general', searchFilter: string = '', rowsFrom?: number, rowsTo?: number, orderBy?: string, orderDirection?: string, extendedFilter?: string, categoryIdFilter?: string, statusIdFilter?: string, sortOrder?: string, filterByDateCreated?: boolean) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/all-tasks?careHomeId=${careHomeId}&startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}&filter=${searchFilter}&rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&orderBy=${orderBy}&orderDirection=${orderDirection}&categoryId=${categoryIdFilter}&statusId=${statusIdFilter}${extendedFilter}&defaultOrder=${sortOrder}&filterByDateCreated=${filterByDateCreated}`);
  }

  getContractReport(careHomeId: any, startDate: any, endDate: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/contract-report?careHomeId=${careHomeId}&startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}`);
  }

  getContractReportXls(careHomeId: any, startDate: any, endDate: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/contract-report-xls?careHomeId=${careHomeId}&startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}`);
  }

  getAllTasksXls(startDate: any, endDate: any, careHomeId: any = 'general', searchFilter: string = '', rowsFrom?: number, rowsTo?: number, orderBy?: string, orderDirection?: string, extendedFilter?: string, categoryIdFilter?: string, statusIdFilter?: string) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/all-tasks-xls?careHomeId=${careHomeId}&startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}&filter=${searchFilter}&rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&orderBy=${orderBy}&orderDirection=${orderDirection}&categoryId=${categoryIdFilter}&statusId=${statusIdFilter}${extendedFilter}`);
  }

  getDeadlinesReport(careHomeId: any = 'general', date: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/all-deadline-tasks?careHomeId=${careHomeId}&startDateFormated=${moment(date).startOf('month').format('YYYY/MM/DD')}&endDateFormated=${moment(date).endOf('month').format('YYYY/MM/DD')}`);
  }

  getDeadlinesReportFromDay(careHomeId: any = 'general', date: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/all-deadline-tasks?careHomeId=${careHomeId}&startDateFormated=${moment(date).format('YYYY/MM/DD')}&endDateFormated=${moment(date).format('YYYY/MM/DD')}`);
  }
  
  getDeadlinesReportDueIn2Days(careHomeId: any = 'general', date: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/all-deadline-tasks?careHomeId=${careHomeId}&dueIn2Days=true&startDateFormated=${moment(date).format('YYYY/MM/DD')}&endDateFormated=${moment(date).format('YYYY/MM/DD')}`);
  }

  getAssignedTasksInfo(careHomeId: number) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/assigned-tasks-info?careHomeId=${careHomeId}`);
  }

  getGlobalTasks(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/global-favorite-tasks?careHomeId=general`);
  }

}
