import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { CoursesApiUrls } from "./courses.enum";
import { AuthUserService } from "../../@core/data/auth-user.service";
import { of } from "rxjs";
import {
  IConfirmCourseCopyRequest,
} from "./courses.models";

@Injectable()
export class CoursesService {
  constructor(
    private httpClient: HttpClient,
    private authUserService: AuthUserService,
  ) {}

  getCourses(search = "") {
    return this.httpClient.get(
      `${environment.apiUrl}${CoursesApiUrls.courses.replace(
        "$chId",
        <any>this.authUserService.getCareHomeId(),
      )}?filter=${search}`,
    );
  }

  getCoursesForCareHome(careHomeId: number, search = "") {
    return this.httpClient.get(
      `${environment.apiUrl}${CoursesApiUrls.courses.replace(
        "$chId",
        careHomeId.toString(),
      )}?filter=${search}`,
    );
  }

  getCoursesXls() {
    return this.httpClient.get(
      `${environment.apiUrl}${CoursesApiUrls.coursesXls.replace(
        "$chId",
        <any>this.authUserService.getCareHomeId(),
      )}`,
    );
  }

  getCoursesReport(
    categoryFilter: string = "",
    trainingFilter: string = "",
    employeeFilter: string = "",
  ) {
    return this.httpClient.get(
      `${environment.apiUrl}${CoursesApiUrls.coursesReport.replace(
        "$chId",
        <any>this.authUserService.getCareHomeId(),
      )}?categoryName=${categoryFilter}&training=${trainingFilter}&employee=${employeeFilter}`,
    );
  }

  getCoursesReportPdf(
    generateByEmployee: boolean = false,
    categoryFilter: string = "",
    trainingFilter: string = "",
    employeeFilter: string = "",
  ) {
    return this.httpClient.get(
      `${environment.apiUrl}${CoursesApiUrls.coursesReportPdf.replace(
        "$chId",
        <any>this.authUserService.getCareHomeId(),
      )}?generateByEmployee=${generateByEmployee}&category=${categoryFilter}&training=${trainingFilter}&employee=${employeeFilter}`,
    );
  }

  addCourse(data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${CoursesApiUrls.courses.replace(
        "$chId",
        <any>this.authUserService.getCareHomeId(),
      )}`,
      data,
    );
  }

  copyCourses(data: IConfirmCourseCopyRequest) {
    return this.httpClient.post(
      `${environment.apiUrl}/care-homes/trainings-copy`,
      data,
    );
  }

  confirmCourseCopy(data: IConfirmCourseCopyRequest) {
    return this.httpClient.post(
      `${environment.apiUrl}/care-homes/trainings-copy-confirm`,
      data,
    );
  }

  editCourse(id: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${CoursesApiUrls.courses.replace(
        "$chId",
        <any>this.authUserService.getCareHomeId(),
      )}/${id}`,
      data,
    );
  }

  deleteCourse(id: number) {
    return this.httpClient.delete(
      `${environment.apiUrl}${CoursesApiUrls.courses.replace(
        "$chId",
        <any>this.authUserService.getCareHomeId(),
      )}/${id}`,
    );
  }

  getCoursesOverview(search = "") {
    return this.httpClient.get(
      `${environment.apiUrl}${CoursesApiUrls.coursesOverview.replace(
        "$chId",
        <any>this.authUserService.getCareHomeId(),
      )}?filter=${search}`,
    );
  }

  changeCoursesStatus(data: any): any {
    return this.httpClient.post(
      `${environment.apiUrl}${CoursesApiUrls.coursesCompleted.replace(
        "$chId",
        <any>this.authUserService.getCareHomeId(),
      )}`,
      data,
    );
  }

  clearAllScheduledCourses() {
    return this.httpClient.delete(
      `${environment.apiUrl}${CoursesApiUrls.coursesSchedulesOverview.replace(
        "$chId",
        <any>this.authUserService.getCareHomeId(),
      )}`,
    );
  }

  getCoursesSummary(): any {
    return this.httpClient.get(
      `${environment.apiUrl}${CoursesApiUrls.coursesSummary.replace(
        "$chId",
        <any>this.authUserService.getCareHomeId(),
      )}`,
    );
  }

  getCoursesSummary2(): any {
    return this.httpClient.get(
      `${environment.apiUrl}${CoursesApiUrls.coursesSummary2.replace(
        "$chId",
        <any>this.authUserService.getCareHomeId(),
      )}`,
    );
  }

  downloadSummary(): any {
    return this.httpClient.get(
      `${environment.apiUrl}${CoursesApiUrls.coursesPdfReport.replace(
        "$chId",
        <any>this.authUserService.getCareHomeId(),
      )}`,
    );
  }

  downloadSummaryXls(): any {
    return this.httpClient.get(
      `${environment.apiUrl}${CoursesApiUrls.coursesXlsReport.replace(
        "$chId",
        <any>this.authUserService.getCareHomeId(),
      )}`,
    );
  }
}
