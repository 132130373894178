import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeLeftService {
  public saveData$ = new Subject();
  public refreshLeavingTab$ = new Subject<boolean>();
  public closeWindow: boolean = false;

  public reset() {
    this.saveData$ = new Subject();
    this.refreshLeavingTab$ = new Subject();
  }
}
