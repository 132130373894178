import { Injectable } from "@angular/core";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { SignInOutReminderComponent } from "../@theme/components/sign-in-out-reminder/sign-in-out-reminder.component";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SignInOutReminderService {
  private overlayRef: OverlayRef;

  public closeSignInOutReminder() {
    this.overlayRef.detach();
  }

  public openSignInOutReminder(
    type: "sign-in" | "sign-out" = "sign-in",
    shiftDate?: string,
  ) {
    this.closeSignInOutReminder();

    const overlayPortal = new ComponentPortal(SignInOutReminderComponent);
    const componentRef = this.overlayRef.attach(overlayPortal);

    componentRef.instance.type = type;
    if (!!shiftDate) componentRef.instance.shiftDate = shiftDate;
  }

  constructor(
    private overlay: Overlay,
    private router: Router,
  ) {
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .global()
        .top("10px")
        .right("10px"),
      hasBackdrop: false,
    });
  }

  public navigateToSignIn(date: string) {
    window.open(this.getLink(date), "_blank");
  }

  public getLink(date: string) {
    return `${environment.portableUrl}/#/schedule?scheduleDate=${date}`;
  }
}
