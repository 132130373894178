import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthUserService } from '../../../@core/data/auth-user.service';
import { DictionariesService } from '../../../shared/dictionaries/dictionaries.service';
import { getValidationStatus, handleValidationErrorMessage, isFormValid } from '../../../utilities/utils';
import { errorMessages } from '../stuff-errors';
import { DictionaryStuffModel } from '../../../shared/models/dictionaries/stuff/dictionaries-stuff.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DictionariesAgencyWordsModel } from '../../../shared/models/dictionaries/agencies/dictionaries-agencies-model';
import { NbDialogService } from '@nebular/theme';
import { NewStuffGroupComponent } from '../new-stuff-group/new-stuff-group.component';

@Component({
  selector: 'edit-stuff',
  templateUrl: './edit-stuff.component.html',
  styleUrls: ['./edit-stuff.component.scss']
})
export class EditStuffComponent implements OnInit, OnDestroy {
  form = this.fb.group({
    name: ['', Validators.required],
    // description: ['', Validators.required],
    groupId: [0, [Validators.required]],
  });
  public utils = { getValidationStatus, handleValidationErrorMessage, isFormValid };
  public errorMessages = errorMessages;
  public groups: DictionariesAgencyWordsModel[] = [];

  private subscription = new Subscription();
  constructor(private fb: FormBuilder, private dictionariesService: DictionariesService, private authUserService: AuthUserService, private dialogService: NbDialogService, public dialogRef: MatDialogRef<EditStuffComponent>,
              @Inject (MAT_DIALOG_DATA) public data: {
                stuff: DictionaryStuffModel,
                groups: DictionariesAgencyWordsModel[],
                groupDictionaryId: number
              }) { }


  ngOnInit(): void {
    this.loadGroups();
    this.updateForm();
  }

  updateForm() {
    if (!this.data.stuff.id) {
      this.closeWindow();
    }
    this.form.get('name').setValue(this.data.stuff.wordShortName);
    // this.form.get('description').setValue(this.data.stuff.wordFullName);
    this.form.get('groupId').setValue(this.data.stuff.groupId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  closeWindow() {
    this.dialogRef.close();
  }

  private loadGroups(groupId?: number) {
    this.subscription.add(this.dictionariesService.getStuffGroups().subscribe((res) => {
      this.groups = res.wordsFromDictionary;
      if (groupId) {
        this.form.get('groupId').setValue(groupId);
      }
    }))
  }

  editStuff() {
    this.subscription.add(this.dictionariesService.updateStuff(
      this.data.stuff.id, {
        ...this.form.getRawValue(),
        careHomeId: this.authUserService.getCareHomeId(),
      }).subscribe(() => {
      this.closeWindow();
    }))
  }

  addGroup() {
    this.dialogService.open(NewStuffGroupComponent, {
      hasBackdrop: true,
      context: {
        title: 'Add new stuff group',
        groupDictionaryId: this.data.groupDictionaryId
      }
    }).onClose.subscribe((res) => {
      this.loadGroups(res.groupId);
    });
  }
}
