// angular
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// third party
import { NbToastrService } from '@nebular/theme';

// services
import { CareHomesService } from '../../../../shared/care-homes/care-homes.service';
import { DictionariesService } from '../../../../shared/dictionaries/dictionaries.service';
import { ProfilesService } from '../../../../shared/profiles/profiles.service';

// models
import { ICareHome } from '../../../shared/interfaces/care-home.interfaces';
import { UserProfileModel } from '../../../../shared/models/users/profiles.model';
import { DictionariesProfilesModel } from '../../../../shared/models/dictionaries/dictionaries-profiles-model';

@Component({
  selector: 'ngx-roles-multi-site',
  templateUrl: './roles-multi-site.component.html',
  styleUrls: ['./roles-multi-site.component.scss'],
})
export class RolesMultiSiteComponent implements OnInit {
  @Input() rolesMultiSiteForm: UntypedFormGroup;
  @Input() employeeUserId: number;

  @Output() onUserProfileRoles: EventEmitter<UserProfileModel[]> = new EventEmitter<UserProfileModel[]>();

  public careHomes: ICareHome[];
  public userProfileRoles: UserProfileModel[];
  public userAllRoles: DictionariesProfilesModel[];
  public isLoading: boolean;
  public errorMsg: string;

  constructor(
    private careHomesService: CareHomesService,
    private profilesService: ProfilesService,
    private dictionariesService: DictionariesService,
    private toastrService: NbToastrService,
  ) {
    this.careHomesService.getCareHomesWithIdNameForMultiSiteAdmin().subscribe((response: any) => {
      response.result.careHomesList.map((i) => (i.fullName = i.careHomeFullName));
      response.result.careHomesList.map((i) => (i.id = i.careHomeId));
      this.careHomes = response.result.careHomesList;
    });
  }

  ngOnInit(): void {
    if (this.employeeUserId) {
      this.isLoading = true;
      this.dictionariesService.getUserProfiles().subscribe((allRoles: any) => {
        this.userAllRoles = allRoles.result.list as DictionariesProfilesModel[];

        this.profilesService.getUserProfiles(this.employeeUserId).subscribe((res: any) => {
          this.userProfileRoles = res.result.rolesList as UserProfileModel[];

          const multiSiteWorkerRole: UserProfileModel = this._findMultiSiteRole('Multi site worker');
          const multiSiteSchedulerRole: UserProfileModel = this._findMultiSiteRole('Multi site scheduler');

          if (multiSiteWorkerRole && multiSiteSchedulerRole) {
            this.rolesMultiSiteForm.patchValue({
              multiSiteWorker: multiSiteWorkerRole && multiSiteWorkerRole.careHomes,
              multiSiteScheduler: multiSiteSchedulerRole && multiSiteSchedulerRole.careHomes,
            });
            this._userProfileRolesEmit();
            this.errorMsg = null;
          } else {
            this.toastrService.danger('Multi site roles not found. Please contact with administrator');
            this.errorMsg = 'Multi site roles not found. Please contact with administrator';
          }
          this.isLoading = false;
        });
      });
    }
  }

  private _userProfileRolesEmit(): void {
    this.onUserProfileRoles.emit(this.userProfileRoles);
  }

  private _findMultiSiteRole(role: string): UserProfileModel | null {
    let multiSiteRole = this.userProfileRoles.find((x) => x.wordFullName === role);

    if (!multiSiteRole) {
      const roleFromDictionaries = this.userAllRoles.find((x) => x.wordShortName === role);
      multiSiteRole = roleFromDictionaries ? this._createNewRoleItem(roleFromDictionaries.id, role) : null;
    }

    return multiSiteRole;
  }

  private _createNewRoleItem(id: number, name: string): UserProfileModel {
    const newRoleItem: UserProfileModel = { id, wordShortName: name, wordFullName: name, careHomes: null };
    this.userProfileRoles = [...this.userProfileRoles, newRoleItem];
    return newRoleItem;
  }
}
