import { EventEmitter, Output } from '@angular/core';
import { Component, ViewChild, OnDestroy, Input, OnChanges, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Subscription, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ngx-task-history-only-table',
  templateUrl: './task-history-only-table.component.html',
  styleUrls: ['../only-table.component.scss']
})
export class TaskHistoryOnlyTableComponent implements OnInit, OnChanges, OnDestroy {
  private subscription: Subscription = new Subscription();
  @ViewChild('table', { static: false }) table: MatTable<any>;
  @Input() columns = [];
  @Input() data: any;
  @Input() filter: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Input() edit: (event: any) => any;
  @Input() sortFromBackend: boolean = false;
  @Output() matSortChange = new EventEmitter();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public displayedColumns: string[] = [];
  public showSubtasksForTaskId;

  matTableDataSource = new MatTableDataSource<any>();

  ngOnInit() {
    this.filter.subscribe((value: string) => {
      this.matTableDataSource.filter = (value.trim) ? value.trim().toLowerCase() : '';
    });
    this.matTableDataSource.sort = this.sort;
    if (this.sortFromBackend) {
      this.matTableDataSource.sortData = ((data, sort) => []);
    }
  }

  ngOnChanges() {
    const columns = [];
    columns.push('index');
    for (const column of this.columns) {
      columns.push(column.fieldName);
    }
    this.matTableDataSource = new MatTableDataSource(this.data);
    this.matTableDataSource.filterPredicate = (order: any, filter: string) => {
      const transformedFilter = filter.trim().toLowerCase();

      const listAsFlatString = (obj): string => {
        let returnVal = '';

        Object.values(obj).forEach((val) => {
          if (typeof val !== 'object') {
            returnVal = returnVal + ' ' + val;
          } else if (val !== null) {
            returnVal = returnVal + ' ' + listAsFlatString(val);
          }
        });

        return returnVal.trim().toLowerCase().replace(/ +(?= )/g, '');
      };

      return listAsFlatString(order).includes(transformedFilter);
    };
    this.displayedColumns = columns;
    if (this.sortFromBackend) {
      this.matTableDataSource.sortData = ((data, sort) => []);
    }
  }

  trackByFn(index, item) {
    return index;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
