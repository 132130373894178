import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Injectable()
export class AnalyzerService {
  constructor(private httpClient: HttpClient) {}

  getOccupancies(date: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-residents?reportDateFormated=${moment(date).format('YYYY/MM/DD')}`);
  }

  getStaffHours(date: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-staff?reportDateFormated=${moment(date).format('YYYY/MM/DD')}`);
  }

  getNumberOfStaffByStatus(date: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-employees-statuses?reportDateFormated=${moment(date).format('YYYY/MM/DD')}`);
  }

  getTrainings(date: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-trainings?reportDateFormated=${moment(date).format('YYYY/MM/DD')}`);
  }

  getOtherChecks(date: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-other-checks?reportDateFormated=${moment(date).format('YYYY/MM/DD')}`);
  }

  getTaskTracking(date: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-tasks?reportDateFormated=${moment(date).format('YYYY/MM/DD')}`);
  }

  getTaskDetails(date: any, careHomeId: any, filters: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-tasks-details?reportDateFormated=${moment(date).format('YYYY/MM/DD')}&careHomeId=${careHomeId}&filters=${filters}`);
  }

  getBoards(date: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-special-boards?reportDateFormated=${moment(date).format('YYYY/MM/DD')}`);
  }

  getAveragePercentCompleteToday(date: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-average-complete-today?reportDateFormated=${moment(date).format('YYYY/MM/DD')}`);
  }

  getAveragePercentChange1weekAgo(date: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-average-change-weekago?reportDateFormated=${moment(date).format('YYYY/MM/DD')}`);
  }

  updateNote(data: any) {
    return this.httpClient.put(`${environment.apiUrl}/dashboard/analitics-notes`, data);
  }

  getNotes(date: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-notes?reportDateFormated=${moment(date).format('YYYY/MM/DD')}`);
  }

  getOccupanciesDetails(careHomeId: any, date: any, weekEarlier: number) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-residents-details?reportDateFormated=${moment(date).format('YYYY/MM/DD')}&careHomeId=${careHomeId}&weekCount=${weekEarlier}`);
  }

  getStaffHoursDetails(careHomeId: any, date: any, weekEarlier: number) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-staff-details?reportDateFormated=${moment(date).format('YYYY/MM/DD')}&careHomeId=${careHomeId}&weekCount=${weekEarlier}`);
  }

  getTrainingDetails(careHomeId: any, date: any, weekEarlier: number) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-trainings-details?reportDateFormated=${moment(date).format('YYYY/MM/DD')}&careHomeId=${careHomeId}&weekCount=${weekEarlier}`);
  }

  getBoardsDetails(careHomeId: any, date: any, weekEarlier: number) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-boards-details?reportDateFormated=${moment(date).format('YYYY/MM/DD')}&careHomeId=${careHomeId}&weekCount=${weekEarlier}`);
  }

  downloadReport(date: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-xls?reportDateFormated=${moment(date).format('YYYY/MM/DD')}`);
  }

  downloadDetailsReport(date: any, weekCounter: any, careHomeId: any) {
    return this.httpClient.get(`${environment.apiUrl}/dashboard/analitics-residents-details-xls?reportDateFormated=${moment(date).format('YYYY/MM/DD')}&weekCounter=${weekCounter}&careHomeId=${careHomeId}`);
  }
}
