import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IDiff } from "../../../../../shared/courses/courses.models";
import { FormControl } from "@angular/forms";

@Component({
  selector: "resolve-clash",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./resolve-clash.component.html",
  styleUrls: ["./resolve-clash.component.scss"],
})
export class ResolveClashComponent implements OnInit {
  //this is not the normal way of making a component accept a formControl
  @Input() difference: IDiff;
  @Input() control: FormControl;

  @Output() selectedChange = new EventEmitter<void>();

  public getDisplayValue(diff: string | undefined): string {
    switch (diff) {
      case "-1":
        return "NEV";
      case "999":
        return "OPL";
      case "":
        return "-";
      default:
        return diff || "";
    }
  }

  constructor() {}

  ngOnInit(): void {}

  public select(value: "original" | "changeTo") {
    if (!this.control) return;

    this.control.patchValue(
      value === "original"
        ? this.difference.original
        : this.difference.changeTo,
    );
  }
}
