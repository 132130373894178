<div class="table-gps-cell" [nbTooltip]="gpsData.hover">
  <img class="table-gps-cell__img" *ngIf="gpsData" [nbPopover]="popoverGps" src="/assets/images/locator.png" alt="">
</div>

<ng-template #popoverGps>
  <div class="table-gps-photo-popover">
    <header class="table-gps-photo-popover__header">
      <p class="table-gps-photo-popover__title">{{gpsData.type}} gps - {{employeeFullName}} {{employeePositionName}} {{gpsData.date}}</p>
      <div class="table-gps-photo-popover__close" (click)="closePopover()"><i class="nb-close" aria-hidden="true"></i></div>
    </header>
    <div class="table-gps-photo-popover__content">
      <agm-map
        class="table-gps-photo-popover__agm-map"
        [latitude]="gpsData.latitude"
        [longitude]="gpsData.longitude"
        [scrollwheel]="false"
        [zoom]="zoom">
        <agm-marker [latitude]="gpsData.latitude" [longitude]="gpsData.longitude"></agm-marker>
      </agm-map>
      <div class="table-gps-photo-popover__map-content" [innerHTML]="gpsData.address"></div>
    </div>
  </div>
</ng-template>
