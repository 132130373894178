import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContractsService } from '../../../../shared/employess/contracts/contracts.service';
import { TableService } from '../../../shared/table/table.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { getValidationStatus, handleValidationErrorMessage, isFormValid, getErrorMessage, getServerLink } from '../../../../utilities/utils';
import { contractsErrorMessages } from '../contracts-errors';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { NewReplacementComponent } from '../../replacement/new-replacement/new-replacement.component';
import { CancelEventDialogComponent } from '../../../shared/cancel-event-dialog/cancel-event-dialog.component';
import { invalidDate, dateGreaterThanInMiliseconds } from '../../../../utilities/validators';
import { EmployeeElementType } from '../../employee-element-type.enum';
import { QuestionDialogComponent } from '../../../shared/question-dialog/question-dialog.component';
import { PermissionsService } from '../../../../@core/data/permissions.service';

@Component({
  selector: 'ngx-edit-contract',
  templateUrl: './edit-contract.component.html',
  styleUrls: ['./edit-contract.component.scss']
})
export class EditContractComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public errorMessages = contractsErrorMessages;
  public form: UntypedFormGroup;
  public utils = { getValidationStatus, handleValidationErrorMessage, isFormValid };
  public contractType: string = 'Replacement';
  public contractId: number = 0;
  public employeeId: number = 0;
  public contractNo: string = '';
  public contractRoleId: number;
  public replacements: any = [];
  public files: any = [];
  public cancelled: boolean;
  public contractData: any;
  public financeForm: UntypedFormGroup;
  public actualTab = 'Contract Overview';
  private employeeDateFirstJoined;
  public isValid = () => {
    return true;
  }

  public replacementSettingsNotCancelled = {
    actions: {
      add: false,
      edit: false,
      delete: true
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash" aria-hidden="true"></i>'
    },
    columns: {
      replacementEmployeeNameSurname: {
        title: 'Full Name',
        filter: false,
        sort: true
      },
      replacementFrom: {
        title: 'Start',
        filter: false,
        sort: true
      },
      replacementTo: {
        title: 'End',
        filter: false,
        sort: true
      },
      replacementDailyHrs: {
        title: 'Replacement Daily Hrs',
        filter: false,
        sort: true
      },
      replacementComments: {
        title: 'Comments',
        filter: false,
        sort: true
      }
    }
  };

  public replacementSettingsCancelled = {
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      replacementEmployeeNameSurname: {
        title: 'Full Name',
        filter: false,
        sort: true
      },
      replacementFrom: {
        title: 'Start',
        filter: false,
        sort: true
      },
      replacementTo: {
        title: 'End',
        filter: false,
        sort: true
      },
      replacementDailyHrs: {
        title: 'Replacement Daily Hrs',
        filter: false,
        sort: true
      },
      replacementComments: {
        title: 'Comments',
        filter: false,
        sort: true
      }
    }
  };

  public financeData = [];
  public financeSettings = {
    actions: {
      add: false,
      edit: false,
      delete: true
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash" aria-hidden="true"></i>'
    },
    columns: {
      validFrom: {
        title: 'Valid From',
        filter: false,
        sort: true
      },
      comments: {
        title: 'Comments',
        filter: false,
        sort: true
      },
      baseRateOfPay: {
        title: 'Base Rate Of Pay',
        filter: false,
        sort: true
      },
      createdAt: {
        title: 'Created At',
        filter: false,
        sort: true
      },
      createdBy: {
        title: 'Created By',
        filter: false,
        sort: true
      }
    }
  };

  addReplacement = () => {
    this.tableService.openWindowWithBackStack(NewReplacementComponent, 'Add new replacement',
      { component: EditContractComponent, title: `Contract information - ${this.contractNo}`, elementId: this.contractId },
      {
        source: 'Contract', employeeId: this.employeeId, contractNo: this.contractNo, eventId: this.contractId,
        contractId: this.contractId, contractRoleId: this.contractRoleId
      }
    );
  }

  deleteReplacement = (data: any) => {
    this.subscription.add(
      this.contractsService.deleteReplacement(data.data.replacementId)
        .subscribe((response: any) => {
          data.confirm.resolve();
          this.toastrService.success(response.message, 'Success');
        },
          (err) => this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true }))
    );
  }

  deleteFinanceItem = (data: any) => {
    this.subscription.add(
      this.contractsService.deleteContractFinanceItem(this.contractId, data.data.id)
        .subscribe((response: any) => {
          data.confirm.resolve();
          this.toastrService.success(response.message, 'Success');
          this.loadFinanceData();
        },
          (err) => this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true }))
    );
  }

  public permissions = <any>{};

  constructor(private contractsService: ContractsService,
              private tableService: TableService,
              private toastrService: NbToastrService,
              private dialogService: NbDialogService,
              private permissionsService: PermissionsService) {
    this.permissions = this.permissionsService.getPermissions();
  }

  ngOnInit() {
    this.contractId = this.tableService.getValue().elementId;
    this.createForms();
    this.subscription.add(this.contractsService.getContract(this.contractId)
      .subscribe((contractResponse: any) => {
        if (contractResponse.result.cancellationDate && contractResponse.result.cancellationDate !== 'n/a') {
          this.cancelled = true;
        }
        if (contractResponse.result.cancellationDate === 'n/a') {
          contractResponse.result.cancellationDate = null;
        }
        if (contractResponse.result.contractStart === 'n/a') {
          contractResponse.result.contractStart = null;
        }
        if (contractResponse.result.contractEnd === 'n/a') {
          contractResponse.result.contractEnd = null;
        }
        this.employeeId = contractResponse.result.employeeId;
        this.contractNo = contractResponse.result.contractNo;
        this.employeeDateFirstJoined = contractResponse.result.employeeDateFirstJoined;
        this.financeForm.get('validFrom').setValidators([Validators.required, invalidDate, dateGreaterThanInMiliseconds(this.employeeDateFirstJoined)]);
        this.updateValues(contractResponse.result);
      }
      ));
    this.loadFinanceData();
    this.loadFiles();
  }

  loadFinanceData() {
    if (this.permissions.FINANCE_MANAGER) {
      this.subscription.add(this.contractsService.getContractFinance(this.contractId)
        .subscribe((financeResponse: any) => {
          this.updateFinanceValues(financeResponse.result);
        }));
      this.subscription.add(this.contractsService.getContractItems(this.contractId)
        .subscribe((response: any) => {
          this.financeData = response.result.list;
        }));
    }
  }

  loadFiles() {
    this.subscription.add(this.contractsService.getContractDocuments(this.contractId)
      .subscribe((response: any) => {
        this.files = response.result.list;
      }));
  }

  createForms() {
    this.form = new UntypedFormGroup({
      employeeName: new UntypedFormControl({ value: '', disabled: true }),
      positionId: new UntypedFormControl({ value: '', disabled: true }),
      contractStart: new UntypedFormControl({ value: '', disabled: true }),
      hoursPerWeek: new UntypedFormControl({ value: '', disabled: true }),
      contractTypeId: new UntypedFormControl({ value: '', disabled: true }),
      contractEnd: new UntypedFormControl(null, [invalidDate]),
      cancellationDate: new UntypedFormControl({ value: '', disabled: true }),
      cancellationReasons: new UntypedFormControl({ value: '', disabled: true }),
      weekAlBase: new UntypedFormControl({value: '', disabled: true}),
      yearAlBase: new UntypedFormControl({value: '', disabled: true}),
      comments: new UntypedFormControl(null)
    });

    this.financeForm = new UntypedFormGroup({
      baseRateOfPay: new UntypedFormControl(null, [Validators.required]),
      validFrom: new UntypedFormControl(null, [Validators.required, invalidDate]),
      comments: new UntypedFormControl(null)
    });
  }

  updateValues(contract: any) {
    this.form.patchValue({
      employeeName: `${contract.employeeFirstName} ${contract.employeeSurname}`,
      positionId: contract.contractRole,
      contractStart: contract.contractStart === null ? null : new Date(contract.contractStart),
      hoursPerWeek: contract.contractHoursPerWeek,
      contractTypeId: contract.contractType,
      contractEnd: contract.contractEnd === null ? null : new Date(contract.contractEnd),
      cancellationDate: contract.cancellationDate === null ? null : new Date(contract.cancellationDate),
      cancellationReasons: contract.cancellationDescription,
      weekAlBase: contract.weekAlBase,
      yearAlBase: contract.yearAlBase,
      comments: contract.contractComments
    });
    this.contractType = contract.contractType;
    this.replacements = contract.replacementList;
    this.contractRoleId = contract.contractRoleId;
  }

  updateFinanceValues(finance: any) {
    this.form.patchValue({
      baseRateOfPay: finance.baseRateOfPay,
      validFrom: finance.validFrom,
      comments: finance.comments
    });
  }

  deleteFile(fileUid: string) {
    this.subscription.add(this.contractsService.deleteContractDocument(this.contractId, fileUid)
      .subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        this.loadFiles();
      },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }
      ));
  }

  downloadFile(fileUId: string) {
    this.subscription.add(this.contractsService.downloadDocument(this.contractId, fileUId)
      .subscribe((response: any) => {
        window.open(getServerLink(response.link), '_blank');
      },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }
      ));
  }


  onTabChange($event) {
    this.actualTab = $event.tabTitle;
    if (this.actualTab === 'Finance') {
      this.isValid = () => {
        return this.utils.isFormValid(this.financeForm);
      };
    } else {
      this.isValid = () => {
        return true;
      };
    }
  }

  save() {
    if (this.actualTab === 'Finance') {
      this.saveFinanceData();
    } else {
      this.saveContractData();
    }
  }

  private saveFinanceData() {
    const data = {
      baseRateOfPay: this.financeForm.get('baseRateOfPay').value,
      validFrom: this.financeForm.get('validFrom').value,
      comments: this.financeForm.get('comments').value,
    };

    if (this.isBaseRateOfPayValid(data.baseRateOfPay)) {
      this.subscription.add(this.contractsService.updateContractFinance(this.contractId, data)
        .subscribe((response: any) => {
          this.toastrService.success(response.message, 'Success');
          this.loadFinanceData();
          this.clearFinanceForm();
        },
          (err) => {
            this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
          }
        ));
    } else {
      this.toastrService.warning('Please input correct value for base rate of pay', 'Warning', { duration: 60000, destroyByClick: true });
    }
  }

  private saveContractData() {
    const data = {
      contractEnd: this.form.get('contractEnd').value,
      cancellationDate: this.form.get('cancellationDate').value,
      comments: this.form.get('comments').value,
    };

    this.subscription.add(this.contractsService.updateContract(this.contractId, data)
      .subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        this.tableService.closeWindow(true);
      },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }
      ));
  }

  deleteContract() {
    this.subscription.add(this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Delete confirmation',
          message: 'WARNING! Deleting a contract should only be done if you added a contract in error.<br>Deleting may mess up AL and rostering. If this contract has ended and is being<br>replaced by another, pick ‘Cancel Contract’ instead',
          deleteLabel: 'Type CONFIRM to confirm you want to delete this contract',
          deleteCheckValue: 'CONFIRM',
          okLabel: 'delete contract',
          deleteInput: true
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          this.subscription.add(this.contractsService.deleteContract(this.contractId)
            .subscribe((response: any) => {
              this.toastrService.success(response.message, 'Success');
              this.tableService.closeWindow(true);
            },
              (err) => {
                this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
              }
            ));
        }
      }
      ));
  }

  cancelContract() {
    this.tableService.openWindowWithBackStack(CancelEventDialogComponent, 'Cancel contract',
      { component: EditContractComponent, title: `Contract information - ${this.contractNo}`, elementId: this.contractId },
      { type: EmployeeElementType.CONTRACT, elementId: this.contractId, startDate: this.form.get('contractStart').value }
    );
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private isBaseRateOfPayValid(baseRate: any) {
    return !isNaN(baseRate);
  }

  private clearFinanceForm() {
    this.financeForm.patchValue({
      baseRateOfPay: null,
      validFrom: null,
      comments: null
    });

    this.financeForm.get('baseRateOfPay').markAsUntouched();
    this.financeForm.get('validFrom').markAsUntouched();
  }
}
