import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { handleValidationErrorMessage, isFormValid } from '../../../utilities/utils';
import { Subscription } from 'rxjs';
import { CareHomesService } from '../../../shared/care-homes/care-homes.service';
import { AuthUserService } from '../../../@core/data/auth-user.service';
import { invalidDate } from '../../../utilities/validators';
import { employessErrorMessages } from '../employess-errors';
import { EmployessService } from '../../../shared/employess/employess.service';

@Component({
  selector: 'ngx-employee-leavingleft-dialog',
  templateUrl: './employee-leavingleft-dialog.component.html',
  styleUrls: ['./employee-leavingleft-dialog.component.scss']
})

export class EmployeeLeavingLeftDialogComponent implements OnInit, OnDestroy {
  @Input() isLeft: boolean = false;
  @Input() employeeId;
  public errorMessages = employessErrorMessages;
  public utils = { handleValidationErrorMessage, isFormValid };
  private subscription = new Subscription();
  public form: UntypedFormGroup;
  public rotaFreezeDate;
  public rotaUnFreezeDate;
  public dateLeftText = 'This person is noted as having resigned with immediate effect';
  public pickedNoticeDate = new Date();
  public rotaFreezeMessage = '';

  constructor(protected ref: NbDialogRef<EmployeeLeavingLeftDialogComponent>,
              protected careHomeService: CareHomesService,
              protected authUserService: AuthUserService,
              protected employeeService: EmployessService) {
    this.subscription.add(
      this.careHomeService.getFreezeDateById(this.authUserService.getCareHomeId())
        .subscribe((response: any) => { this.rotaFreezeDate = response.freezeDate;
                                        this.rotaUnFreezeDate  = response.unFreezeDate;
                                        }
        )
    );
  }

  loadDismissalProcessData() {
    this.subscription.add(this.employeeService.getDismissalProcessValues(this.employeeId)
      .subscribe((response: any) => {
          console.log('dismiss = ', response);
          this.updateValues(response.result);
    }));
  }

  updateValues(dismissalObject: any = {}) {
    this.form.patchValue({
      reasonForLeaving: dismissalObject.reasonForLeaving,
      uniformReturned: dismissalObject.uniformReturned,
      paymentInLieu: dismissalObject.paymentInLieu,
      otherNotes: dismissalObject.otherNotes,
      noticeDate: dismissalObject.noticeDate,
      dateLeft: dismissalObject.dateLeft,
    });
  }

  ngOnInit() {
    if (this.isLeft) {
      this.form = new UntypedFormGroup({
        reasonForLeaving: new UntypedFormControl(null, [Validators.required]),
        uniformReturned: new UntypedFormControl(null, [Validators.required]),
        uniformReturnedToCome: new UntypedFormControl(false, [Validators.required]),
        paymentInLieu: new UntypedFormControl(null, [Validators.required]),
        paymentInLieuToCome: new UntypedFormControl(false, [Validators.required]),
        otherNotes: new UntypedFormControl(null, [Validators.required]),
        otherNotesToCome: new UntypedFormControl(false, [Validators.required]),
        noticeDate: new UntypedFormControl(moment().add(5, 'hours').toDate(), [Validators.required, invalidDate]),
        confirmation: new UntypedFormControl(null, [Validators.required]),
        dateLeft: new UntypedFormControl(moment().add(5, 'hours').toDate(), [Validators.required, invalidDate]),
      });

      this.subscription.add(this.form.valueChanges.subscribe((changes: any) => {
        const isFrozen = moment(this.form.get('dateLeft').value).isSameOrBefore(moment(this.rotaFreezeDate), 'date');
        const isUnfozen =  this.rotaUnFreezeDate ? moment().isSameOrBefore(moment(this.rotaUnFreezeDate)) : false;
        
        if (isFrozen && !isUnfozen) {
          this.form.get('dateLeft').setErrors({'dateGreaterThan': true});
          this.rotaFreezeMessage = 'ROTA is frozen';
        } else {
          this.rotaFreezeMessage = '';
        }
        this.pickedNoticeDate = this.form.get('noticeDate').value;
      }));
      this.loadDismissalProcessData();
    } else {
      this.form = new UntypedFormGroup({
        reasonForLeaving: new UntypedFormControl(null, [Validators.required]),
        uniformReturned: new UntypedFormControl(null, [Validators.required]),
        uniformReturnedToCome: new UntypedFormControl(false, [Validators.required]),
        paymentInLieu: new UntypedFormControl(null, [Validators.required]),
        paymentInLieuToCome: new UntypedFormControl(false, [Validators.required]),
        otherNotes: new UntypedFormControl(null, [Validators.required]),
        otherNotesToCome: new UntypedFormControl(false, [Validators.required]),
      });
    }

    this.form.valueChanges
      .subscribe((changedObject: any) => {
        const date = moment(changedObject.dateLeft);
        const daysBetween = date.diff(moment(changedObject.noticeDate), 'days');
        if (daysBetween == 0) {
          this.dateLeftText = 'This person is noted as having resigned with immediate effect';
        } else if (daysBetween < 28) {
          this.dateLeftText = 'This person is leaving without having served 28 days of notice only ' + daysBetween + ' days, please check your input';
        } else {
          this.dateLeftText = '';
        }
    });
  }

  cancel() {
    this.ref.close(false);
  }

  ok() {
    const data = {
      isLeft: this.isLeft,
      reasonForLeaving: this.form.get('reasonForLeaving') && this.form.get('reasonForLeaving').value,
      uniformReturned: this.form.get('uniformReturned') && this.form.get('uniformReturned').value,
      paymentInLieu: this.form.get('paymentInLieu') && this.form.get('paymentInLieu').value,
      otherNotes: this.form.get('otherNotes') && this.form.get('otherNotes').value,
    };
    if (this.isLeft) {
      data['noticeDate'] = this.form.get('noticeDate') && this.form.get('noticeDate').value;
      data['dateLeft'] = this.form.get('dateLeft') && this.form.get('dateLeft').value;
    }
    this.ref.close(data);
  }

  setToComeStatus(isChecked: any, formControlId: any, isSetValue = true) {
    if (isSetValue) {
      this.form.get(formControlId).setValue(isChecked ? 'to come' : '');
    }
  }

  public readonly isDeleteEntered = (form: UntypedFormGroup) => {
    return !this.isLeft || (this.isLeft && form.get('confirmation').value?.trim().toUpperCase() === 'CONFIRM');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
