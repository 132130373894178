import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, Injectable, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../@core/auth/auth.service';
import { AuthUserService } from '../../../@core/data/auth-user.service';

const REDIRECT_DELAY = 500;

@Component({
  selector: 'ngx-new-pass',
  templateUrl: './new-pass.component.html',
  styleUrls: ['../login/login.component.scss']
})

@Injectable()
export class NewPassComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private formChangesSubscription: Subscription = new Subscription();
  @ViewChild('resetPassForm', { static: true }) ngForm: NgForm;
  private token = '';
  public userName = '';
  public userLogin = '';
  public error: string = '';
  public additionalErrorMessage: string = '';
  public additionalMessage: string = '';
  public user: any = {};
  public submitted: boolean = false;
  public isBetaVersion = false;
  private isPortable = false;
  public validatedItems = {
    upperLetter: false,
    lowerLetter: false,
    oneNumber: false,
    oneSpecialChar: false,
    minimumLength: false
  };

  constructor(protected router: Router,
              protected toastrService: NbToastrService,
              protected authService: AuthService,
              protected authUserService: AuthUserService,
              @Inject(DOCUMENT) private document: Document,
              protected httpClient: HttpClient,
              protected route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
      this.userName = params.username;
      this.userLogin = params.userLogin;
      this.isPortable = params.isPortable === "true";
    });

    this.isBetaVersion = environment.beta;
  }

  ngOnInit() {
    this.formChangesSubscription = this.ngForm.form.valueChanges.subscribe(form => {
      if (form.passwordNew) {
        const specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        this.validatedItems.oneSpecialChar = specialChars.test(form.passwordNew);
        this.validatedItems.lowerLetter = /[a-z]/.test(form.passwordNew);
        this.validatedItems.upperLetter = /[A-Z]/.test(form.passwordNew);
        this.validatedItems.oneNumber = /[0-9]/.test(form.passwordNew);
        this.validatedItems.minimumLength = form.passwordNew.length >= 8;
      }
    });

  }

  resetPass(): void {
    this.error = '';
    this.submitted = true;

    const modifiedUser = {
      token: this.token,
      originUrl: this.document.location.origin,
      passwordNew: this.user.passwordNew
    };

    this.subscription.add(this.authService.resetPassword(modifiedUser)
      .subscribe(() => {
        this.handleSuccess();
      },
      (err) => {
        this.handleError(err);
      }
    ));
  }

  handleSuccess() {
    this.submitted = false;
    setTimeout(() => {
     const target =  ((this.isPortable) ?  ( environment.portableUrl ) :  ( environment.desktopUrl )) + "/#/auth/login?userLogin=" + this.userLogin;
    // window.open(link, "_blank");
        window.location.href = target;
        // const link    =   document.createElement('a');
        // link.setAttribute('rel', 'noopener noreferrer');
        // link.setAttribute('target', 'about:blank');
        // link.setAttribute('href', target);
        // link.click();
    }, REDIRECT_DELAY);
  }

  handleError(err: any): void {
    this.submitted = false;
    const errorToShow = err.error.errors ? err.error.errors[0].message : 'There is no internet connection';
    this.toastrService.danger(errorToShow, 'Error', { duration: 60000, destroyByClick: true });
    this.error = err.error.errors ? err.error.errors[0].message : errorToShow;
    this.additionalErrorMessage = err.error.errors ? err.error.errors[0].extendedMessage : '';
    this.additionalMessage  = err.error.errors ? err.error.errors[0].additionalMessage : '';
  }

  public readonly isFormValid = () => {
    return this.ngForm.form.valid
        && this.validatedItems.oneSpecialChar
        && this.validatedItems.lowerLetter
        && this.validatedItems.upperLetter
        && this.validatedItems.oneNumber
        && this.validatedItems.minimumLength
        && this.ngForm.form.controls.passwordNew.value == this.ngForm.form.controls.rePass.value;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formChangesSubscription.unsubscribe();
  }
}
