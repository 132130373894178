
<div
  class="category-container"
  *ngFor="let category of categoriesWithFiles"
  [ngClass]="{'bottom-border': category.files.length > 0}"
  (onFileDropped)="uploadFileWithCategory($event, category)"
  dragDropNoBgFiles
>
  <div class="category"
      [ngClass]="{'red': (category.obligatory && category.files.length === 0 && category.goLiveDate !== null && !(category.goLiveDate | dateAfterOrEqualTo: employeeDateFirstJoined)) || (category.obligatory && category.files.length === 0 && category.goLiveDate === null)}"
      (click)="category.name.toLowerCase().includes('contract') ? addNewDoc(category) : individualFileInput.click()">
    <input hidden type="file" #individualFileInput onclick="this.value=null;" (change)="uploadFile($event.target.files, category.id)" multiple>
      <div>
        <span>{{category.name}}</span> {{category.obligatory ? (category.goLiveDate === null ? '- this is mandatory' : (' this is mandatory for DFJ after: ' + category.goLiveDate))  : (category.goLiveDate  !== null ? (' this is mandatory for DFJ after: ' + category.goLiveDate) : '')}}
        <span *ngIf="category.isMigrated" [style.color]="'#ffa100'"> - Migrated</span>
      </div>
    <nb-icon *ngIf="isFileSendEnabled" icon="plus-circle-outline"></nb-icon>
  </div>

  <div class="files-list" *ngFor="let file of category.files">
    <p class="name">{{ file.name }} ({{file.size}})</p>
    <div class="flex">
      <p class="mr-1">note: </p>
        <input class="mr-1"
              nbInput
              fullWidth
              name="input-text"
              id="input-text"
              [value]="file.fileDescription"
              (focusout)="saveDesc($event, file)"
              maxlength="255"
              autofocus>
      <p class="mr-1">{{ file.uploadedBy }}, at: {{file.uploadedAt}}</p>
      <button class="btn btn-success" (click)="download(file.uid)">download</button>
      <button class="delete-file" *ngIf="isFileSendEnabled" (click)="deletePopup(file.uid)"><i class="nb-trash" aria-hidden="true"></i></button>
    </div>
  </div>
</div>
