import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { handleValidationErrorMessage, isFormValid, getErrorMessage, urlify } from '../../../../utilities/utils';
import { TableService } from '../../../shared/table/table.service';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { TasksService } from '../../../../shared/tasks/tasks.service';
import { DictionariesService } from '../../../../shared/dictionaries/dictionaries.service';
import { taskErrorMessages } from '../task-errors';
import { TaskBoardsService } from '../../../../shared/tasks/task-boards/task-boards.service';
import { AuthUserService } from '../../../../@core/data/auth-user.service';
import { AddWatchersDialogComponent } from '../../../shared/add-watchers-dialog/add-watchers-dialog.component';
import { TaskTab } from '../task-tab.enum';
import * as moment from 'moment';
import { TasksEmailDialogComponent } from '../../../shared/tasks-email-dialog/tasks-email-dialog.component';
import { DOCUMENT } from '@angular/common';
import { CareHomesService } from '../../../../shared/care-homes/care-homes.service';
import { QuestionDecisionTypeEnum, QuestionDialogComponent } from '../../../shared/question-dialog/question-dialog.component';
import { ChooseTemplateDialogComponent } from '../../../shared/choose-template/choose-template.component';
import { PermissionsService } from '../../../../@core/data/permissions.service';
import { UsersService } from '../../../../shared/users/users.service';
import { SubtasksService } from '../../../shared/subtask-editor/subtask.service';

@Component({
  selector: 'ngx-new-task',
  templateUrl: './new-task.component.html',
  styleUrls: ['./new-task.component.scss']
})
export class NewTaskComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private taskboardPermissions: any = {};
  public errorMessages = taskErrorMessages;
  public TaskTab: typeof TaskTab = TaskTab;
  public activeTab = TaskTab.INFO;
  public utils = { handleValidationErrorMessage, isFormValid };
  public form: UntypedFormGroup;
  public careHomes: any = [];
  public selectedCareHomes: any = [];
  public statuses: any = [];
  public categories: any = [];
  public priorities: any = [];
  public taskboards: any = [];
  public taskboardsBackup: any = [];
  public groupTaskboards: any = [];
  public isGroupPick = false;
  public grouping: any = [];
  public raggs1: any = [];
  public raggs2: any = [];
  public taskboardDetails: any = {};
  public raggsColors: any = {};
  public isRoomEnquiries: boolean = false;
  private taskId = 0;
  private taskBoardId = 0;
  public files: any = [];
  public checkedTasks: any[] = [];
  public pickedBoardName = '';
  public isCloseWindow = false;
  private tagsDictionaryId: number;
  public tags: any = [];
  public permissions = <any>{};
  public canSave = true;
  public canChangeTaskboard = false;
  public sourceTemplateId = null;
  public checkedCategories: any[] = [];

  constructor(private dictionariesService: DictionariesService,
              private tableService: TableService,
              private tasksService: TasksService,
              private toastrService: NbToastrService,
              private taskBoardsService: TaskBoardsService,
              private authUserService: AuthUserService,
              private dialogService: NbDialogService,
              private careHomesService: CareHomesService,
              @Inject(DOCUMENT) private document: Document,
              private permissionsService: PermissionsService,
              private usersService: UsersService,
              private subtasksService: SubtasksService) {
    this.permissions = this.permissionsService.getPermissions();
  }

  ngOnInit() {
    this.createForm();
    this.canChangeTaskboard = this.tableService.getValue() ? this.tableService.getValue().canChangeTaskboard : false;
    this.isRoomEnquiries = this.tableService.getValue() ? this.tableService.getValue().isRoomEnquiries : false;
    if (this.isRoomEnquiries) {
      this.form.patchValue({
        comments: `PERSON Making enquiry:
DATE of enquiry:
ENQUIRY FOR (e.g. mum):
TEL:
EMAIL:
SOURCE (how did they hear about us):
Perm or Respite and how long:
Needs level?:
Other notes:`
      });
      this.form.removeControl('taskBoardId');
      this.form.removeControl('careHomes');
    }

    this.taskboardPermissions = this.tableService.getValue() ? this.tableService.getValue().taskboardPermissions : null;
    if (this.taskboardPermissions) {
      this.disableByPermissions();
    }

    this.sourceTemplateId = this.tableService.getValue() && this.tableService.getValue().taskTemplateId;
    if (this.sourceTemplateId) {
      this.applyTemplate(this.sourceTemplateId, true);
    }

    this.subscription.add(this.taskBoardsService.getOnlyMineTaskBoards(this.authUserService.getCareHomeId(), true)
      .subscribe((response: any) => {
        this.taskboards = response.result.taskBoard.taskBoardList;
        this.taskboardsBackup = this.taskboards;
        if (this.tableService.getValue()) {
          const taskboardId = Number.parseInt(this.tableService.getValue().taskBoardId);
          this.taskBoardId = taskboardId;
          if (taskboardId) {
            const foundTaskBoard = this.taskboards.find((x => x.id === taskboardId));
            const taskboardList = [];
            taskboardList.push(foundTaskBoard);
            this.form.get('taskboards').setValue(taskboardList);
          }
        }
      })
    );

    this.subscription.add(this.dictionariesService.getTaskCategories()
      .subscribe((response: any) => {
        this.categories = response.result.wordsFromDictionary;
      }));

    this.subscription.add(this.dictionariesService.getTaskPriorities()
      .subscribe((response: any) => {
        this.priorities = response.result.wordsFromDictionary;
        this.setDefaultPriorityValue();
      }));

    this.subscription.add(this.dictionariesService.getTaskStatuses()
      .subscribe((response: any) => {
        this.statuses = response.result.wordsFromDictionary;
        this.setDefaultStatusValue();
      }));

    this.loadTags(null);
    this.loadCollaboartionUsers();
  }

  disableByPermissions() {
    if (!this.taskboardPermissions.canIWrite) {
      this.form.get('latestUpdate').disable();
    }
    if (!this.taskboardPermissions.canIEdit) {
      this.form.get('comments').disable();
    }
    if (!this.taskboardPermissions.canIClose) {
      this.form.get('groupResponse').disable();
    }
  }

  setDefaultPriorityValue() {
    const mediumWord = this.priorities.find((element: any) => {
      return element.wordFullName == 'medium';
    });
    this.form.patchValue({ priorityId: mediumWord.id });
  }

  setDefaultStatusValue() {
    const newWord = this.statuses.find((element: any) => {
      return element.wordFullName == 'Active';
    });
    this.form.patchValue({ statusId: newWord.id });
  }

  getMineCareHome() {
    const careHome = this.careHomes.find((element: any) => {
      return element.id == this.authUserService.getCareHomeId();
    });
    return careHome;
  }

  loadTags(callback: any = null) {
    this.subscription.add(this.dictionariesService.getTaskTags()
      .subscribe((response: any) => {
        this.tags = response.result.wordsFromDictionary;
        this.tagsDictionaryId = response.result.dictionaryId;
        if (callback) { callback(); }
      }));
  }

  loadCollaboartionUsers(taskId = 0, careHomeId = 0) {
    this.subscription.add(this.usersService.getUsersForTasksCollaboration(taskId, careHomeId)
      .subscribe((data: any) => {
        const d = data.result.list
        .map(i => {
          return { id: i.id, fullName: i.login };
        });
        this.subtasksService.setUsers(d);
      })
    );
  }

  newTagAdded(value: string) {
    const data = {
      description: value,
      name: value
    };
    this.subscription.add(this.dictionariesService.addDictionaryWord(this.tagsDictionaryId, data)
      .subscribe((response: any) => {
        this.loadTags(() => {
          const addedWordId = response.result;
          const actualTagsValue = this.form.get('tags').value || [];
          const addedValue = this.tags.find((item: any) => {
            return item.id == addedWordId;
          });
          actualTagsValue.push(addedValue);
          this.form.patchValue({ tags: actualTagsValue });
          this.form.updateValueAndValidity();
        });
      },
      (err: any) => {
        this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
      }));
  }

  createForm() {
    this.form = new UntypedFormGroup({
      taskboards: new UntypedFormControl(null, [Validators.required]),
      careHomeGroupId: new UntypedFormControl(null),
      title: new UntypedFormControl(null, [Validators.required]),
      careHomes: new UntypedFormControl(null, [Validators.required]),
      categoryId: new UntypedFormControl(null, [Validators.required]),
      statusId: new UntypedFormControl(null, [Validators.required]),
      priorityId: new UntypedFormControl(null, [Validators.required]),
      dueTo: new UntypedFormControl(null),
      comments: new UntypedFormControl(null),
      latestUpdate: new UntypedFormControl(null),
      groupResponse: new UntypedFormControl(null),
      isFavorite: new UntypedFormControl(false),
      taskGoupingId: new UntypedFormControl(null),
      taskRagg1Id: new UntypedFormControl(null),
      taskRagg2Id: new UntypedFormControl(null),
      isEmailList: new UntypedFormControl(false),
      isEmailOnSave: new UntypedFormControl(false),
      tags: new UntypedFormControl(null),
      subTasksList: new UntypedFormControl(null)
    });

    if (!this.permissions.CHANGE_NEW_TASK_STATUS) {
      this.form.get('statusId').disable();
    }

    this.form.get('title').markAsTouched();
    this.form.get('categoryId').markAsTouched();

    this.form.get('taskRagg1Id').valueChanges.subscribe((value: any) => {
      this.setRaggColor(this.raggs1, value, 'ragg1Color');
    });

    this.form.get('taskRagg2Id').valueChanges.subscribe((value: any) => {
      this.setRaggColor(this.raggs2, value, 'ragg2Color');
    });

    this.form.get('taskGoupingId').valueChanges.subscribe((value: any) => {
      this.setRaggColor(this.grouping, value, 'groupingColor');
    });

    this.form.get('categoryId').valueChanges.subscribe((value: any) => {
      this.checkedCategories = value;
      console.log(this.checkedCategories)
    });

    // TODO manage colors in room enquires
    // this not running in room enquires
    this.subscription.add(this.form.get('taskboards').valueChanges
      .subscribe((value: any) => {
        if (value) {
          const taskBoard = value[0];
          if (taskBoard) {
            this.subscription.add(this.taskBoardsService.getTaskBoard(taskBoard.id)
              .subscribe((response: any) => {
                this.taskBoardId = taskBoard.id;
                this.careHomes = response.result.careHomes;
                const careHome = this.getMineCareHome();
                this.selectedCareHomes = [careHome];
                this.form.patchValue({
                  careHomes: [careHome]
                });
                this.taskboardPermissions = response.result;
                this.disableByPermissions();
                this.loadGrouppingAndRaggs(response.result);
              },
                (err: any) => {
                  this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
                })
            );
          }
        }
      }
    ));
  }

  getCategoriesNames(): string[] {
    const namesToReturn = [];

    this.checkedCategories.forEach(category => {
      category.customListOfInfo.forEach(item => {
        if (!namesToReturn.includes(item.name)) {
          namesToReturn.push(item.name);
        }
      });
    });

    return namesToReturn;
  }

  loadGrouppingAndRaggs(taskboardDetails: any) {
    this.taskboardDetails = taskboardDetails;
    if (taskboardDetails.ragg1DictionaryId) {
      this.dictionariesService.getDictionaryByName(taskboardDetails.ragg1DictionaryName)
        .subscribe((response: any) => {
          this.raggs1 = response.result.wordsFromDictionary;
          this.setRaggColor(this.raggs1, this.form.get('taskRagg1Id').value, 'ragg1Color');
        });
    }

    if (taskboardDetails.ragg2DictionaryId) {
      this.dictionariesService.getDictionaryByName(taskboardDetails.ragg2DictionaryName)
        .subscribe((response: any) => {
          this.raggs2 = response.result.wordsFromDictionary;
          this.setRaggColor(this.raggs2, this.form.get('taskRagg2Id').value, 'ragg2Color');
        });
    }

    if (taskboardDetails.groupingDictionaryId) {
      this.dictionariesService.getDictionaryByName(taskboardDetails.groupingDictionaryName)
        .subscribe((response: any) => {
          this.grouping = response.result.wordsFromDictionary;
          this.setRaggColor(this.grouping, this.form.get('taskGoupingId').value, 'groupingColor');
        });
    }
  }

  getWord(arrray: any, wordId: number) {
    const word = arrray.find((element: any) => element.id == wordId);
    return word;
  }

  setRaggColor(array: any, value: number, colorFieldName: string) {
    const word = this.getWord(array, value);
    this.raggsColors[colorFieldName] = word ? word.colorHash : '';
  }

  prepareData(encodeDescription = true) {
    const data = {
      taskBoardId: null,
      careHomes: [],
      taskboards: [],
      tags: this.form.get('tags').value,
      title: this.form.get('title').value,
      categoryId: this.form.get('categoryId').value,
      statusId: this.form.get('statusId').value,
      priorityId: this.form.get('priorityId').value,
      dueTo: this.form.get('dueTo').value,
      comments: encodeDescription ? urlify(this.form.get('comments').value) : this.form.get('comments').value,
      latestUpdate: this.form.get('latestUpdate').value,
      groupResponse: this.form.get('groupResponse').value,
      isFavorite: this.form.get('isFavorite').value,
      taskGoupingId: this.form.get('taskGoupingId').value,
      taskRagg1Id: this.form.get('taskRagg1Id').value,
      taskRagg2Id: this.form.get('taskRagg2Id').value,
      isEmailList: this.form.get('isEmailList').value,
      openTime: new Date(),
      subTasksList: this.form.get('subTasksList').value || [],
      sourceTemplateId: this.sourceTemplateId
    };

    if (this.isRoomEnquiries) {
      data.careHomes.push({ id: this.authUserService.getCareHomeId() });
    } else {
      data.taskboards = this.form.get('taskboards').value;
      data.careHomes = this.form.get('careHomes').value;
    }

    return data;
  }

  save() {
    this.canSave = false;
    this.saveWithObservable(true).subscribe(() => {
      if (this.form.get('isEmailOnSave').value) {
        this.openEmailWindow();
      }
    });
  }

  uploadFile(file: any) {
    file.uid = this.files.length;
    file.createdAt = moment().format('DD/MM/YYYY HH:mm');
    file.createdBy = this.authUserService.getUser().name;
    file.isSent = false;
    this.files.push(file);
  }

  deleteFile(fileUId: string) {
    this.files.splice(fileUId, 1);
  }

  saveWithObservable(closeWindow = true) {
    this.isCloseWindow = closeWindow;
    const data = this.prepareData();
    let dataStream = this.tasksService.addMultipleTask(data);
    let isUpdate = false;
    if (this.isRoomEnquiries) {
      dataStream = this.tasksService.addRoomEnquiriesTask(data);
    }
    if (this.taskId > 0) {
      dataStream = this.tasksService.updateTask(this.taskId, data);
      isUpdate = true;
    } else if (Array.isArray(this.taskId)) {
      dataStream = this.tasksService.updateMultipleTasks(this.taskId, data);
    }
    return Observable.create(observer => {
      this.subscription.add(dataStream
        .subscribe((response: any) => {
          if (!isUpdate) {
            this.taskId = response.addedTasks;
          }
          if (this.files.length > 0) {
            this.sendFiles(closeWindow);
          } else {
            this.toastrService.success(response.message, 'Success');
            if (closeWindow) {
              if (!this.form.get('isEmailOnSave').value) {
                this.tableService.closeWindow(true);
              }
            }
          }

          observer.next(response);
          observer.complete();
        },
        (err) => {
          this.canSave = true;
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
          observer.complete();
        }
      ));
    });
  }

  openEmailWindow() {
    this.checkedTasks = [];
    this.checkedTasks.push(this.taskId);
    this.dialogService
      .open(TasksEmailDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Please enter subject and emails',
          needLoadTable: false,
          showClearEmails: false,
          careHomeId: this.authUserService.getCareHomeId(),
          taskboards: this.form.get('taskboards').value
        }
      })
      .onClose.subscribe((inputData: any) => {
        if (inputData && inputData.isConfirm) {
          const data = {
            frontURL: this.document.location.origin,
            emailToIds: inputData.emailTo,
            emailTo: inputData.emailList,
            emailBody: inputData.emailBody,
            emailSubject: inputData.subject,
            taskList: Array.isArray(this.taskId) ? this.taskId : this.checkedTasks,
            clearEmailListAfterSend: inputData.clearEmailListAfterSend
          };

          this.subscription.add(this.careHomesService.sendTasksInEmailTasks(this.authUserService.getCareHomeId(), data)
          .subscribe(() => {
            this.subscription.add(this.tasksService.updateTaskWatchers(this.taskId, { watchersList: inputData.emailTo })
              .subscribe((response: any) => {
                this.toastrService.success(response.message, 'Success');
                this.tableService.closeWindow(this.isCloseWindow);
              },
                (err: any) => {
                  this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
                }));
          },
            (err) => {
              this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
            }));
        } else {
          this.tableService.closeWindow(true);
        }
      }
      );
  }

  sendFiles(closeWindow = true) {
    const fd = new FormData();
    this.files.forEach((file: any) => {
      fd.append('files', file);
    });

    this.subscription.add(this.tasksService.addTaskFileArray(this.taskId, fd)
      .subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        if (!this.form.get('isEmailOnSave').value) {
          this.tableService.closeWindow(closeWindow);
        }
      },
      (err) => {
        this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
      }
    ));
  }

  readonly isAnySubtaskAdded = () => {
    let subtaskCounter = 0;
    const subtasks = this.form.get('subTasksList') && this.form.get('subTasksList').value || [];
    for (let i = 0; i < subtasks.length; i++) {
      if (subtasks[i].todos.length > 0) {
        subtaskCounter++;
        break;
      }
    }
    return subtaskCounter > 0;
  }

  addWatchers() {
    if (this.taskId > 0) {
      this.showWatchersForm();
    } else {
      this.saveWithObservable(false).subscribe(() => {
        this.showWatchersForm();
      });
    }
  }

  showWatchersForm() {
    this.dialogService
      .open(AddWatchersDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          taskboardId: this.taskBoardId,
          taskId: this.taskId
        },
      })
      .onClose.subscribe((selectedWatchers: any) => {
        if (selectedWatchers) {
          this.subscription.add(this.tasksService.updateTaskWatchers(this.taskId, selectedWatchers)
            .subscribe((response: any) => {
              this.toastrService.success(response.message, 'Success');
            },
              (err: any) => {
                this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
              }));
        }
      }
      );
  }

  chooseTemplate() {
    this.subscription.add(this.dialogService
      .open(ChooseTemplateDialogComponent, {closeOnBackdropClick: false})
      .onClose.subscribe((templateId: any) => {
        if (templateId) {
          const taskChanged = this.checkTaskChanged();
          if (taskChanged) {
            this.subscription.add(this.dialogService
              .open(QuestionDialogComponent, {
                closeOnBackdropClick: false,
                context: {
                  title: 'Confirmation',
                  message: `We have detected information already within the task.<br>Should we ADD the template to what you’ve already got, wherever possible,<br>OR shall we OVERWRITE what’s already there`,
                  okLabel: 'Add',
                  cancelLabel: 'Cancel',
                  hideSecondOkButton: false,
                  secondOkLabel: 'Overwrite'
                },
              })
              .onClose.subscribe((decision: any) => {
                if (decision && decision.type && decision.isConfirm) {
                  if (decision.type === QuestionDecisionTypeEnum.FIRST) {
                    this.applyTemplate(templateId, false);
                  } else {
                    this.applyTemplate(templateId, true);
                  }
                }
              }));
          } else {
            this.applyTemplate(templateId, true);
          }
        }
      }));
  }

  applyTemplate(detailsId: any, overwirte = false) {
    this.tasksService.getTemplateTaskDetails(detailsId).subscribe((response: any) => {
      const templateDetails = response.result;
      const originData = this.prepareData(false);
      const dueToDate = templateDetails.deadlineDays ? moment().add(templateDetails.deadlineDays, 'd') : originData.dueTo;
      this.sourceTemplateId = templateDetails.taskId;
      // this.dynamicTags = templateDetails.dynamicTags;
      // this.saveFromTemplate = true;
      if (overwirte) {
        this.form.patchValue({
          title: templateDetails.title || originData.title,
          comments: templateDetails.comments || originData.comments,
          categoryId: templateDetails.categoryId,
          dynamicTags: templateDetails.dynamicTags,
          dueTo: dueToDate,
          subTasksList: templateDetails.subTasks,
          statusId: templateDetails.statusId,
          priorityId: templateDetails.priorityId
        });

        this.toastrService.info('Template has been aplied as an overwrite', 'Info', { duration: 60000, destroyByClick: true });
      } else {
        const categories = (originData.categoryId || []).concat(templateDetails.categoryId);
        const subtasks = (originData.subTasksList || []).concat(templateDetails.subTasks);
        this.form.patchValue({
          title: (originData.title || '') + templateDetails.title,
          comments: (originData.comments || '') + '\n' + templateDetails.comments,
          categoryId: categories,
          dynamicTags: templateDetails.dynamicTags,
          subTasksList: subtasks,
          statusId: templateDetails.statusId,
          priorityId: templateDetails.priorityId
        });

        this.toastrService.info('Template has been aplied', 'Info', { duration: 60000, destroyByClick: true });
      }
    });
  }

  private checkTaskChanged(): boolean {
    if (this.form.get('title').value !== null
      || this.form.get('dueTo').value !== null
      || this.form.get('comments').value !== null) {
      return true;
    }

    if (this.form.get('categoryId').value && this.form.get('categoryId').value.length !== 0) {
      return true;
    }

    if (this.form.get('tags').value && this.form.get('tags').value.length !== 0) {
      return true;
    }

    return false;
  }

  clear(fieldName: string) {
    this.form.get(fieldName).setValue(null);
  }

  changeFavoriteState(event: any) {
    this.form.patchValue({
      isFavorite: event.target.checked ? true : false
    });
  }

  changeEmailList(event: any) {
    this.form.patchValue({
      isEmailList: event.target.checked ? true : false
    });
  }

  changeEmailOnSave(event: any) {
    this.form.patchValue({
      isEmailOnSave: event.target.checked ? true : false
    });
  }

  closeWindow() {
    this.tableService.closeWindow(true);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  checkAllFilesSent() {
    this.files.forEach((file: any) => {
      if (!file.isSent) {
        return false;
      }
    });

    return true;
  }
}

export interface CategoriesData {
  name: string;
  infoList: string[];
}
