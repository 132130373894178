<div *ngFor="let month of months; trackBy: trackByFn" class="overview-calendar-wrapper thin-echo-scrollbar" [ngClass]="{'without-height': withoutHeight}">
  <ngx-overview-header [date]="month.date" [dateRange]="dateRange" [multipleSchedules]="months.length > 1">
  </ngx-overview-header>
  <ngx-overview-row *ngIf="schedule.length > 0"
    [isSchedule]='true'
    [hideAlButton]="hideAlButton"
    [date]="month.date"
    [employeeName]="employeeName"
    [schedule]="schedule"
    [scheduleSummary]="scheduleSummary"
    (refreshData)="onRefreshData()"
    [dateRange]="months.length > 1 ? undefined : dateRange"
  >
  </ngx-overview-row>
  <ngx-overview-row
    *ngFor="let contract of month.contracts; trackBy: trackByFn"
    [date]="month.date"
    [hideAlButton]="hideAlButton"
    [contract]="contract"
    [events]="contract.contractEvents"
    (refreshData)="onRefreshData()"
    [dateRange]="months.length > 1 ? undefined : dateRange"
  >
  </ngx-overview-row>
</div>
