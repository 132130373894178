<nb-card [class]="panelClass">
  <nb-card-header>{{ title }} {{ autoAddEmailRecipients }}</nb-card-header>

  <nb-card-body *ngIf="message != ''">
    <p [innerHTML]="message"></p>
    <form [formGroup]="form">
      <div
        class="form-control-group"
        style="margin-top: 2rem"
        *ngIf="deleteInput"
      >
        <label class="label" for="input-confirmation">{{ deleteLabel }}</label>
        <input
          nbInput
          fullWidth
          formControlName="confirmation"
          name="confirmation"
          minlength="3"
          maxlength="20"
          autofocus
        />
      </div>
      <div *ngIf="messageInput">
        <textarea
          nbInput
          fullWidth
          formControlName="messageInputValue"
          [placeholder]="messagePlaceholder"
          minlength="3"
          maxlength="5000"
          autofocus
        >
        </textarea>
      </div>
      <div class="question-dialog__password" *ngIf="passwordInput">
        <input nbInput fullWidth type="password" formControlName="password" autofocus placeholder="Fill out password">
      </div>
    </form>
    <div *ngIf="hasRecurringTasks" class="recurring-container">
      <ng-container *ngFor="let task of recurringTasks; let i = index; let first = first">
        <div class="subTask-name" *ngIf="first">Subtask: {{task.taskName}}</div>
        <div class="subTask-name" *ngIf="!first && task.taskName != recurringTasks[i - 1].taskName">Subtask: {{task.taskName}}</div>
        <div class="form-control-group">
          <nb-checkbox
            id="input-confirmation"
            [(ngModel)]="task.checked"
            [checked]="task.checked"
          >
          </nb-checkbox>
          <label class="label" for="input-confirmation">{{ task.name }}</label>
        </div>
      </ng-container>
    </div>
    <div *ngIf="parameterInput">
      <nb-radio-group [(value)]="radioGroupValue">
        <nb-radio [value]="10">Small</nb-radio>
        <nb-radio [value]="12">Medium</nb-radio>
        <nb-radio [value]="14">Big</nb-radio>
      </nb-radio-group>
    </div>
  </nb-card-body>
  <nb-card-footer [ngClass]="{ 'flex-space': !hideSecondOkButton }" *ngIf="title != 'New contract document'; else contractDocumentFooter">
    <div [ngClass]="{ flex: !hideSecondOkButton }" *ngIf="!hideOkButton">
      <button
        *ngIf="hasRecurringTasks"
        class="btn btn-success mr-2"
        [disabled]="!allRecurringTasksAreChecked()"
        (click)="ok()"
      >
        {{ okLabel }}
      </button>
      <button
        *ngIf="messageInput || passwordInput"
        class="btn btn-success mr-2"
        [disabled]="!utils.isFormValid(form)"
        (click)="ok()"
      >
        {{ okLabel }}
      </button>
      <button
        *ngIf="deleteInput"
        class="btn btn-success mr-2"
        [disabled]="!isDeleteEntered(form)"
        (click)="ok()"
      >
        {{ okLabel }}
      </button>
      <button
        *ngIf="!deleteInput && !messageInput && !passwordInput && !hasRecurringTasks"
        class="btn btn-success mr-2"
        (click)="ok()"
      >
        {{ okLabel }}
      </button>
      <button
        *ngIf="!hideSecondOkButton"
        class="btn btn-success mr-2"
        (click)="secondOk()"
      >
        {{ secondOkLabel }}
      </button>
    </div>
    <button *ngIf="!hideCancelButton" class="btn btn-danger" (click)="cancel()">{{ cancelLabel }}</button>
  </nb-card-footer>
  <ng-template #contractDocumentFooter>
    <nb-card-footer>
      <div class="contract-footer">
        <div>
          <button
            class="btn btn-success mr-2"
            (click)="ok()"
          >
            {{ okLabel }}
          </button>
          <button
            *ngIf="!hideSecondOkButton"
            class="btn btn-success mr-2"
            (click)="secondOk()"
          >
            {{ secondOkLabel }}
          </button>
        </div>
        <button *ngIf="!hideCancelButton" class="btn btn-danger" (click)="cancel()">{{ cancelLabel }}</button>
      </div>
    </nb-card-footer>
  </ng-template>
</nb-card>
