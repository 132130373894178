<div class="header">
  <nb-icon icon="close-outline" (click)="close()"></nb-icon>
</div>
<div class="body show-overflow">
  <form [formGroup]="form" class="form">
    <div class="role-select" *ngIf="roles.length > 0">
      <nb-select-label>Role</nb-select-label>
      <nb-select
        formControlName="role"
      >
        <nb-option *ngFor="let role of roles" [value]="role.id">{{
          role.roleName
        }}</nb-option>
      </nb-select>
    </div>
    <div class="shifts">
      <nb-select-label>Shift hours</nb-select-label>
      <div class="shift-inputs">
        <input
          type="text"
          nbInput
          formControlName="from"
          (keydown)="makeListNotVisible()"
          (keydown.tab)="fromValue != null && autofillValue('from')"
          (focusin)="makeListVisible('from')"
          min="1"
          max="5"
          autocomplete="off"
          id="fromControl"
          #fromControl
        />
        <input
          type="text"
          nbInput
          formControlName="to"
          (keydown)="makeListNotVisible()"
          (keydown.tab)="toValue != null && autofillValue('to')"
          (focusin)="makeListVisible('to')"
          (focusout)="
            !['00:00', '0:00', '0'].includes($event.target.value) &&
              $event.target.value != form.get('from').value &&
              checkHours($event);
          "
          min="1"
          max="5"
          autocomplete="off"
          id="toControl"
        />
      </div>
    </div>
    <div class="employee-select">
      <ng-container
        *ngIf="!form.get('isExtrasToBeTaken').value; else published"
      >
        <nb-select-label>Assign employee (optional)</nb-select-label>
        <nb-select formControlName="employeeId">
          <nb-option
            *ngFor="let employee of dayData?.availableStuff"
            [value]="employee.employeeId"
          >
            {{ employee.employeeFullName }} ( {{ employee.employeePosition }})
          </nb-option>
          <nb-option
            *ngFor="let employee of dayData?.otherStuff"
            [value]="employee.employeeId"
          >
            {{ employee.employeeFullName }} ( {{ employee.employeePosition }})
          </nb-option>
        </nb-select>
      </ng-container>
      <ng-template #published>
        <nb-select-label>Status</nb-select-label>
        <nb-select placeholder="Published" formControlName="extrasPublished">
          <nb-option [value]="true"> Published </nb-option>
          <nb-option [value]="false"> Unpublished </nb-option>
        </nb-select>
      </ng-template>
    </div>

    <div *ngIf="canHaveExtras" class="is-extras">
      <nb-checkbox formControlName="isExtrasToBeTaken">Unassigned</nb-checkbox>
    </div>
  </form>
  <div
    class="menu-list"
    *ngIf="isOpen"
    [ngClass]="{
      'for-to': isOpen && menuPosition === 'to',
      'role-enabled': roleEnabled
    }"
  >
    <div class="menu-item" *ngFor="let item of templateHRS" (click)="fillFromMenu(item)">
      {{ item }}
    </div>
  </div>
</div>
<div class="footer">
  <button class="btn btn-success" [disabled]="!form.valid" (click)="save()">
    <nb-icon icon="checkmark-outline" class="mr-2"></nb-icon> Save
  </button>
  <button class="btn btn-cancel" (click)="close()">
    <i class="nb-close mr-2" aria-hidden="true"></i> Close
  </button>
</div>
