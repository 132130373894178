<div class="form-view edit-stuff">
    <h1>Edit stuff</h1>
    <main>
      <form [formGroup]="form">
        <div class="flex-container-horizontal">
          <div class="flex-container">
            <div class="form-control-group">
              <label class="label" for="name">Name:</label>
              <input nbInput fullWidth formControlName="name" name="name" id="name" placeholder="Stuff name">
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'name', errorMessages) }}
              </p>
            </div>
            <!-- <div class="form-control-group">
              <label class="label" for="description">Long name:</label>
              <input nbInput fullWidth formControlName="description" name="description" id="description" placeholder="Description">
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'description', errorMessages) }}
              </p>
            </div> -->
            <div class="form-control-group">
              <label class="label" for="description">Group:</label>
              <div class="grid-wrapper">
                <ng-select [items]="groups"
                bindLabel="wordFullName"
                [multiple]="false"
                [clearable]="false"
                dropdownPosition="auto"
                bindValue="id"
                placeholder="Select group"
                class="stuff-select box start-1 end-11"
                formControlName="groupId"
                >

                </ng-select>
                <button class="btn btn-success box start-11" (click)="addGroup()">NEW<br>GROUP</button>
              </div>

                <p class="error-message">
                    {{ utils.handleValidationErrorMessage(form, 'groupId', errorMessages) }}
                  </p>
            </div>
          </div>
        </div>
      </form>
    </main>
    <footer>
      <div class="flex space">
        <div class="flex">
          <button class="btn btn-success" (click)="editStuff()" [disabled]="!utils.isFormValid(form)">
            <i class="nb-checkmark" aria-hidden="true"></i> Save
          </button>
          <button class="btn btn-danger ml-4" (click)="closeWindow()">
            <i class="nb-close" aria-hidden="true"></i> Close
          </button>
        </div>
      </div>
    </footer>
  </div>
  