<nb-layout>
  <nb-layout-column>
    <nb-card>
      <nb-card-body>
        <nb-auth-block>
          <router-outlet></router-outlet>
        </nb-auth-block>
      </nb-card-body>
    </nb-card>
    <div class="auth-footer">
      Created by <a class="text-link" href="https://corvus-it.com/">corvus-it.com</a><br>
      <small>{{version}}</small>
    </div>
  </nb-layout-column>
</nb-layout>
