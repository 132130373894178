import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { SicknessApiUrls } from './sickness.enum';
import * as moment from 'moment';

@Injectable()
export class SicknessService {
  constructor(private httpClient: HttpClient) {}

  getSicknessById(eventId: number) {
    return this.httpClient.get(`${environment.apiUrl}${SicknessApiUrls.sicknessUnaggregated}/${eventId}`);
  }

  getSickness(startDate: any, endDate: any, onlyActive: boolean) {
    const url = onlyActive ? SicknessApiUrls.activeSicknessNew : SicknessApiUrls.sicknessListNew;
    return this.httpClient.get(`${environment.apiUrl}${url}type=Sick&startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}`);
  }

  getSicknessTopAnalysis() {
    return this.httpClient.get(`${environment.apiUrl}/employees/events/sickness-analysis-top`);
  }

  getSicknessAnalysis() {
    return this.httpClient.get(`${environment.apiUrl}/employees/events/sickness-analysis`);
  }

  addNewSickness(employeeId: number, data: any) {
    return this.httpClient.post(`${environment.apiUrl}/employees/${employeeId}/events?eventType=Sickness`, data);
  }

  updateSickness(eventId: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${SicknessApiUrls.sicknessUnaggregated}/${eventId}`, data);
  }

  getReplacements(eventId: number) {
    return this.httpClient.get(`${environment.apiUrl}/employees/events/${eventId}?skip-care-home`);
  }

  createReplacement(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${SicknessApiUrls.sicknessReplacement}`, data);
  }

  deleteReplacement(eventId: number) {
    return this.httpClient.delete(`${environment.apiUrl}${SicknessApiUrls.sicknessReplacement}/${eventId}`);
  }

  deleteSickness(eventId: number) {
    return this.httpClient.delete(`${environment.apiUrl}${SicknessApiUrls.sicknessUnaggregated}/${eventId}`);
  }
}
