import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject } from 'rxjs';
import { Component, OnInit, OnChanges, OnDestroy, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';

// only for inbox
@Component({
  selector: 'ngx-selectable-only-table',
  templateUrl: './selectable-only-table.component.html',
  styleUrls: ['./selectable-only-table.component.scss']
})
export class SelectableOnlyTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: any = [];
  @Input() columns: any = {};
  @Input() onRowClick: (event: any) => any;
  @Input() selectedRows: (event: any) => any;
  @Input() filter: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Input() clearSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  @Input() totalRows = 0;
  @Input() paginationSizeOptions;
  @Input() load: (paginationData?: any, searchFilter?: any) => any;
  @Output() matSortChange = new EventEmitter();
  public dataSource: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public displayedColumns: string[] = [];
  public selection = new SelectionModel(true, []);
  public paginationData = {
    pageIndex: 0,
    pageSize: 0,
    orderBy: '',
    orderDirection: ''
  };
  public pickedSortConfiguration = null;
  @Input() sortFromBackend: boolean = false;

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let returnValue = false;
    if (this.dataSource && this.dataSource.data) {
      const numRows = this.dataSource.data.length;
      returnValue = numSelected === numRows;
    }
    
    return returnValue;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    this.selectedRows(this.selection.selected);
  }

  toggleRow(row) {
    this.selection.toggle(row);
    this.selectedRows(this.selection.selected);
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  ngOnInit() {
    this.filter
      // .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((value: string) => {
      this.dataSource.filter = (value.trim) ? value.trim().toLowerCase() : '';
    });
    this.dataSource.sort = this.sort;
    if (this.sortFromBackend) {
      this.dataSource.sortData = ((data, sort) => {});
    }
  }

  // ngOnChanges() {
  //   const columns = [];

  //   for (const column of this.columns) {
  //     columns.push(column.fieldName);
  //   }
  //   this.dataSource = new MatTableDataSource(this.data);
  //   this.dataSource.filterPredicate = (order: any, filter: string) => {
  //     const transformedFilter = filter.trim().toLowerCase();
  //     const listAsFlatString = (obj): string => {
  //       let returnVal = '';

  //       Object.values(obj).forEach((val) => {
  //         if (typeof val !== 'object') {
  //           returnVal = returnVal + ' ' + val;
  //         } else if (val !== null) {
  //           returnVal = returnVal + ' ' + listAsFlatString(val);
  //         }
  //       });

  //       return returnVal.trim().toLowerCase().replace(/ +(?= )/g, '');
  //     };
  //     return listAsFlatString(order).includes(transformedFilter);
  //   };
  //   this.displayedColumns = columns;
  //   // this.dataSource.sort = this.sort;
  //   if (this.sortFromBackend) {
  //     this.dataSource.sortData = ((data, sort) => {});
  //   }
  // }

  ngOnChanges() {
    this.clearSelected.subscribe((event: any) => {
      this.selection.clear();
    });

    const columns = [];
    columns.push('select');

    for (const column of this.columns) {
      columns.push(column.fieldName);
    }
    this.dataSource = new MatTableDataSource(this.data);
    
    this.dataSource.filterPredicate = (order: any, filter: string) => {
      const transformedFilter = filter.trim().toLowerCase();
      const listAsFlatString = (obj): string => {
        let returnVal = '';

        Object.values(obj).forEach((val) => {
          if (typeof val !== 'object') {
            returnVal = returnVal + ' ' + val;
          } else if (val !== null) {
            returnVal = returnVal + ' ' + listAsFlatString(val);
          }
        });

        return returnVal.trim().toLowerCase().replace(/ +(?= )/g, '');
      };
      return listAsFlatString(order).includes(transformedFilter);
    };
    this.displayedColumns = columns;
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = ((data, sort) => {});
    if (this.sortFromBackend) {
      this.dataSource.sortData = ((data, sort) => {});
    }
  }

  updatePagination(event, isSort?) {
    if (this.pickedSortConfiguration && this.pickedSortConfiguration.length > 0) {
      this.pickedSortConfiguration = null;
    }

    if (isSort) {
      this.paginationData.orderBy = event.active;
      this.paginationData.orderDirection = event.direction;
    } else {
      this.paginationData.pageIndex = event.pageIndex;
      this.paginationData.pageSize = event.pageSize;
    }
    this.load(this.paginationData);
  }

  trackByFn(index, item) {
    return index;
  }

  ngOnDestroy(): void {
    if (this.filter) {
      this.filter.unsubscribe();
    }
  }

}
