<div class="header">
  <p class="label">{{label}}</p>
  <ngx-thinner-switcher
    *ngIf="!disabled && !noSelectAll"
    [firstValue]="false"
    [secondValue]="true"
    [firstValueLabel]="'Select all'"
    [secondValueLabel]="''"
    [value]="selectAll"
    (valueChange)="toggleSelectAll($event)"
  ></ngx-thinner-switcher>
</div>
<ng-select *ngIf="!hideField"
            [items]="pickValues"
            [dropdownPosition]="dropdownPosition"
            [multiple]="true"
            [bindLabel]="bindLabel"
            [closeOnSelect]="false"
            [placeholder]="placeholder"
            [virtualScroll]="true"
            (ngModelChange)="onChange($event)"
            (keyup.enter)="addNewValue($event)"
            [loading]="loading"
            (change)="checkModel()"
            (remove)="valueRemovedEmit($event)"
            appendTo="body"
            [disabled]="disabled"
            [ngClass]="{'one-row': useShortForm}"
            [(ngModel)]="val">
    <ng-template class="select-item-dropdown" ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">

      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" [disabled]="disableAllCheckboxes"> <span [ngOptionHighlight]="search">{{item[bindLabel]}} <span *ngIf="secondBindLabel">, {{item[secondBindLabel]}}</span>
      </span>
      <div class="order-buttons-wrapper" *ngIf="showOrder">
        <button [ngStyle]="{'visibility': index === (val.length - 1) ? 'hidden' : 'visible'}"class="order-button" (click)="changeOrder($event, 'down', item)">
          <nb-icon icon="arrow-ios-downward-outline"></nb-icon>
        </button>
        <button [ngStyle]="{'visibility': index === 0 ? 'hidden' : 'visible'}" class="order-button" (click)="changeOrder($event, 'up', item)">
          <nb-icon icon="arrow-ios-upward-outline"></nb-icon>
        </button>
      </div>
  </ng-template>
  <ng-template *ngIf="useShortForm" ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngFor="let item of (items ? items.slice(0,2): [])">
        <span class="ng-value-label"> {{item[bindLabel]}}</span>
        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </div>
    <div class="ng-value" *ngIf="items.length > 2" >
        <span class="ng-value-label">...</span>
    </div>
  </ng-template>
</ng-select>
