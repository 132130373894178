import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class HelpFilesService {

  constructor(private httpClient: HttpClient) {}

  getHelpFile(area: string) {
    return this.httpClient.get(`${environment.apiUrl}/help-files?area=${area}`);
  }

}
