<div *ngIf="isFileSendEnabled" class="upload-file-container" (click)="fileInput.click()" dragDropFiles
     (onFileDropped)="uploadFile($event)">
  <p>Drag & drop files or click here</p>
  <input hidden type="file" onclick="this.value=null;" multiple #fileInput (change)="uploadFile($event.target.files)">
</div>
<section class="files">
  <ng-container *ngIf="!isAddNewContractDoc; else newContractDocFiles">
    <div class="files-list" *ngFor="let file of files">
      <p class="name">{{ file.name }} ({{file.size}})</p>
      <div class="flex middle">
        <p *ngIf="isAddNotesEnabled" class="mr-1">note: </p>
        <input *ngIf="isAddNotesEnabled"
               class="mr-1 note-input"
               nbInput
               fullWidth
               name="input-text"
               id="input-text"
               [value]="file.fileDescription"
               (focusout)="saveDesc($event, file)"
               (keyup.enter)="saveDesc($event, file)"
               maxlength="255"
               autofocus>
        <p class="mr-1">{{ file.createdBy }}, at: {{file.createdAt}}</p>
        <button *ngIf="isDownloadEnabled" class="btn btn-success" (click)="download(file.uid)">download</button>
        <button *ngIf="customButtonCheckFunction && customButtonCheckFunction(file)" class="btn btn-success ml-2"
                (click)="customButtonAction(file)"
                [disabled]="customButtonDisabled(file)">{{customButtonLabel(file)}}</button>
        <button class="delete-file" *ngIf="isFileSendEnabled" (click)="deletePopup(file.uid)"><i class="nb-trash"
                                                                                                 aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #newContractDocFiles>
    <table *ngIf="files.length > 0" mat-table multiTemplateDataRows [dataSource]="files" class="new-contract-table">
        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns; let i=index">
          <th mat-header-cell *matHeaderCellDef>{{column}}</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <button *ngIf="column == ' '" class="delete-file" (click)="deletePopup(element.uid, true)"><i class="nb-trash" aria-hidden="true"></i></button>
            <ng-container *ngIf="column == 'No.'">{{getIndex(element)}}</ng-container>
            <ng-container *ngIf="column == 'Name'">{{element.name.split('.')[0]}}</ng-container>
            <ng-container *ngIf="column == 'File extension'">{{element.name.split('.')[1]}}</ng-container>
            <ng-container *ngIf="column == 'File size'">{{(element.size / 1024 / 1024).toFixed(2)}} MB</ng-container>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </ng-template>
</section>
