<nb-card>
    <nb-card-header>Confirm Annual Leave</nb-card-header>
    <nb-card-body>
        <p class="confirm-text">You are requesting for {{data?.hours}} hours and {{data?.minutes}} minutes of annual leave. {{ data?.days }}<br>Please confirm</p>
    </nb-card-body>
    <nb-card-footer>
        <div class="buttons-container">
            <button class="btn btn-success" (click)="close('confirm')">Confirm</button>
            <button class="btn btn-danger" (click)="close(null)">Cancel</button>
        </div>
    </nb-card-footer>
  </nb-card>