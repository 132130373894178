import { OnInit, Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import * as moment from 'moment';

@Component({
  selector: 'ngx-question-dialog-task-pause',
  templateUrl: './question-dialog-task-pause.component.html',
  styleUrls: ['./question-dialog-task-pause.component.scss']
})
export class QuestionDialogTaskPauseDialogComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  public form: UntypedFormGroup;

  constructor(protected ref: NbDialogRef<QuestionDialogTaskPauseDialogComponent>) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      date: new UntypedFormControl(moment(), [Validators.required]),
      text: new UntypedFormControl(null, [Validators.required])
    });
  }

  cancel() {
    this.ref.close(false);
  }

  ok() {
    const textToAppend = (this.form.get('text').value.toString().toLowerCase().includes("reason")) ?
        this.form.get('text').value
        : ("Reason for pausing the task: ").toUpperCase() + this.form.get('text').value + "\n------------------------------";
    this.ref.close({
      date: this.form.get('date').value,
      text: textToAppend
    });
  }

  isFormValid(form: UntypedFormGroup) {
    return form.valid;
  }

}
