import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NbDialogRef } from "@nebular/theme";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";

export enum DialogTypeData {
  ADD = 'Add new column',
  ADD_SUBTASK = 'Add new subtask',
  ADD_SUBTITLE = 'Select which rows should be included',
  CREATE = 'Create new row',
  CREATE_SUBTITLE = 'Select which columns should include this row',
}

@Component({
  selector: 'add-subtask-advanced-view-dialog',
  templateUrl: './add-subtask-advanced-view-dialog.component.html',
  styleUrls: ['./add-subtask-advanced-view-dialog.component.scss']
})
export class AddSubtaskAdvancedViewDialogComponent implements OnInit {

  @Input() addTodos: boolean = false
  @Input() subtasksNames: any[] = [];
  @Input() okLabel: string = 'Yes'
  @Input() cancelLabel: string = 'No'
  public newSubtask: boolean = false;
  public dialogTitle: DialogTypeData = DialogTypeData.ADD;
  public dialogSubtitle: DialogTypeData = DialogTypeData.ADD_SUBTITLE;
  public form: FormGroup

  public isAllSelected: boolean = false

  constructor(protected ref: NbDialogRef<AddSubtaskAdvancedViewDialogComponent>) { }


  ngOnInit(): void {
    if (this.addTodos) {
      this.dialogTitle = DialogTypeData.CREATE
      this.dialogSubtitle = DialogTypeData.CREATE_SUBTITLE
    }
    if (this.subtasksNames.length == 0) {
      this.dialogTitle = DialogTypeData.ADD_SUBTASK
      this.newSubtask = true
      this.form = new FormGroup({
        title: new FormControl<string>('', [Validators.required, Validators.minLength(3)])
      })
    } else {
      this.subtasksNames = this.subtasksNames.map((name) => {return {name: name, selected: false}})
      this.form = new FormGroup({
        title: new FormControl<string>('', [Validators.required, Validators.minLength(3)]),
        todos: new FormArray([], [Validators.required])
      })
    }

  }

  selectUnSelectAll() {
    this.isAllSelected = !this.isAllSelected
    this.subtasksNames.forEach((item) => {
      item.selected = this.isAllSelected
    })
    const checkArray: FormArray = this.form.get('todos') as FormArray;
    checkArray.controls = []
    if (this.isAllSelected) {
      this.subtasksNames.forEach(item => checkArray.push(new FormControl(item.name)))
    } else {
      checkArray.controls = []
      this.form.get('todos').setValue([])
    }
  }

  onCheckboxChange(e: any) {
    const checkArray: FormArray = this.form.get('todos') as FormArray;
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      checkArray.controls.forEach((item: any, i) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    if (this.isAllSelected && this.form.get('todos').value.length == 0) {
      this.isAllSelected = false
    }
  }

  cancel() {
    this.ref.close({save: false});
  }

  save() {
    if (this.subtasksNames.length == 0) {
      this.ref.close({save: true, newSubtask: true, title: this.form.get('title').value})
    } else {
      this.ref.close({save: true, newSubtask: false, title: this.form.get('title').value, todos: this.form.get('todos').value})
    }
  }

  edit() {
    this.ref.close({create: true, title: this.form.get('title').value, subtasks: this.subtasksNames.length == 0 ? [] : this.form.get('todos').value })
  }
}
