import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { getValidationStatus, handleValidationErrorMessage, handleValidationErrorMessageForGeneratedFields, isFormValid, getErrorMessage } from '../../../utilities/utils';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { TableService } from '../../shared/table/table.service';
import { AuthUserService } from '../../../@core/data/auth-user.service';
import { Subscription } from 'rxjs';
import { EmployessService } from '../../../shared/employess/employess.service';
import { employessErrorMessages } from '../employess-errors';
import { invalidDate } from '../../../utilities/validators';
import * as moment from 'moment';
import { CareHomesService } from '../../../shared/care-homes/care-homes.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'ngx-employee-activation-checklist',
  templateUrl: './employee-activation-checklist.component.html',
  styleUrls: ['./employee-activation-checklist.component.scss']
})
export class EmployeeActivationChecklistComponent implements OnInit, OnDestroy {
  @Input() cardMode = false;
  @Output() cancelEvent = new EventEmitter();
  private subscription: Subscription = new Subscription();
  public utils = { getValidationStatus, handleValidationErrorMessage, handleValidationErrorMessageForGeneratedFields, isFormValid };
  public data: any;
  public allChecked = false;
  public columns = [
    {
      fieldName: 'index',
      title: 'No.'
    },
    {
      fieldName: 'name',
      title: 'Task',
      class: 'text-left'
    },
    {
      fieldName: 'description',
      title: 'Description',
      class: 'text-left'
    },
    {
      fieldName: 'comments',
      title: 'Comments',
      fieldType: 'textarea-view'
    },
    {
      title: 'Done?',
      fieldName: 'isChecked',
      class: 'checkbox'
    }
  ];

  public displayedColumns = this.columns;
  public form: UntypedFormGroup;
  public employeeId = 0;
  public fromStatus = -1;
  public toStatus = -1;
  public rotaFreezeDate: any;
  public careHomeId = -1;
  public isStartDateDisabled = false;
  public isEndDateDisabled = false;
  public isDatesWarning = false;
  public isEndDateWarning = false;
  public isRotaFreeze = false;
  public isRotaFreezeWarning = false;
  public isCheckList = false;
  public trainingId = -1;
  public errorMessages = employessErrorMessages;

  constructor(private tableService: TableService,
    private careHomesService: CareHomesService,
    private authUserService: AuthUserService,
    private toastrService: NbToastrService,
    private employeeService: EmployessService) {
  }

  ngOnInit() {
    this.loadColumns();
    this.createForm();
    this.employeeId = this.tableService.getValue().elementId;
    this.fromStatus = this.tableService.getValue().fromStatus;
    this.toStatus = this.tableService.getValue().toStatus;
    this.rotaFreezeDate = this.tableService.getValue().rotaFreezeDate;
    this.careHomeId = this.tableService.getValue().careHomeId;

    this.subscription.add(this.employeeService.getEmployeeTraining(this.employeeId).subscribe((data: any) => {
      this.patchValues(data.result);
      this.checkRotaFreeze();
      this.trainingId = data.result.trainingId;
    }));

    this.subscription.add(this.careHomesService.getActivationChecklist(this.authUserService.getCareHomeId()).subscribe((response: any) => {
      let index = 1;
      this.isCheckList = response.result.list.length > 0;

      if (!this.isCheckList) {
        this.allChecked = true;
      }

      response.result.list.map(item => {
        item.isChecked = false;
        item.comments = '';
        item.index = index;
        index++;
      });
      this.data = new MatTableDataSource(response.result.list);
    }));
  }

  createForm() {
    this.form = new UntypedFormGroup({
      description: new UntypedFormControl(null, [Validators.required]),
      trainingStart: new UntypedFormControl({ disabled: true, value: null }, [Validators.required, invalidDate]),
      trainingEnd: new UntypedFormControl(null, [Validators.required, invalidDate]),
    });

    this.form.get('trainingStart').valueChanges.subscribe((value: any) => {
      this.checkDates();
    });

    this.form.get('trainingEnd').valueChanges.subscribe((value: any) => {
      this.checkEndDate();
    });
  }

  patchValues(data: any) {
    const trainingStart = moment(data.trainingStart, 'YYYY-MM-DD');

    this.form.patchValue({
      description: data.description,
      trainingStart: trainingStart,
      trainingEnd: null
    });

    if (trainingStart.isBefore(this.rotaFreezeDate)) {
      this.form.get('trainingStart').disable();
      this.isStartDateDisabled = true;
    }
  }


  save() {
    const data = this.prepareData();
    const trainingData = this.prepareTrainingData();
    this.subscription.add(this.employeeService.uploadEmployeeActivation(this.employeeId, data).subscribe((response: any) => {
      this.toastrService.success(response.message, 'Success');
      this.saveTrainingEnd(trainingData);
    },
      (err) => {
        this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
      }));
  }

  saveTrainingEnd(data: any) {
    this.subscription.add(this.employeeService.updateTrainingDetails(this.employeeId, this.trainingId, this.careHomeId, data)
      .subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        this.closeWindow();
      },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }
      ));
  }

  isAllChecked() {
    for (const element of this.data.data) {
      if (!element.isChecked) {
        return false;
      }
    }

    return true;
  }

  onTaskSelectionChange(event: any, element: any) {
    this.allChecked = this.isAllChecked();
  }

  loadColumns() {
    const columns = [];
    for (const column of this.columns) {
      columns.push(column.fieldName);
    }
    this.displayedColumns = columns;
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private prepareData() {
    const checkList = this.getChecklistFormatted();

    const data = {
      fromEmployeeStatus: this.fromStatus,
      toEmployeeStatus: this.toStatus,
      checkList: checkList
    };

    return data;
  }

  private prepareTrainingData() {
    const data = {
      employeeId: this.employeeId,
      description: this.form.get('description').value,
      trainingStart: this.form.get('trainingStart').value,
      trainingEnd: this.form.get('trainingEnd').value,
      careHomeId: this.authUserService.getCareHomeId(),
      statusId: this.toStatus
    };
    return data;
  }

  private getChecklistFormatted() {
    const checkList = [];
    for (const element of this.data.data) {
      const checkPosition = {
        name: element.name,
        description: element.description,
        comments: element.comments,
        confirmed: element.isChecked
      };
      checkList.push(checkPosition);
    }

    return checkList;
  }

  private checkRotaFreeze() {
    const trainingStartDate = moment(this.form.get('trainingStart').value, 'DD/MM/YYYY');
    const trainingEndDate = moment(this.form.get('trainingEnd').value, 'DD/MM/YYYY');

    this.isRotaFreeze = (this.rotaFreezeDate.isSameOrAfter(trainingEndDate) && !this.isEndDateDisabled) || (this.rotaFreezeDate.isSameOrAfter(trainingStartDate) && !this.isStartDateDisabled);

    this.setRotaFreezeWarningVisibility(this.isRotaFreeze);
  }

  private setRotaFreezeWarningVisibility(isVisible: boolean) {
    this.isRotaFreezeWarning = isVisible;
  }

  private checkDates() {
    const trainingStartDate = moment(this.form.get('trainingStart').value, 'DD/MM/YYYY');
    const trainingEndDate = moment(this.form.get('trainingEnd').value, 'DD/MM/YYYY');

    this.isDatesWarning = trainingStartDate.isAfter(trainingEndDate);
  }

  private checkEndDate() {
    const startDate = moment(this.form.get('trainingStart').value, 'DD/MM/YYYY');
    const dateEnd = moment(this.form.get('trainingEnd').value, 'DD/MM/YYYY');

    this.isEndDateWarning = dateEnd.isBefore(startDate) || dateEnd.isAfter(moment());
  }
}
