import {
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { CdkDragDrop, CdkDragStart } from "@angular/cdk/drag-drop";
import { QuestionDialogComponent } from "../../shared/question-dialog/question-dialog.component";
import { debounceTime, distinctUntilChanged, forkJoin, Subject, Subscription } from "rxjs";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { TaskTab } from "../tasks/task-tab.enum";
import { FastEditorComponent } from "../tasks/fast-editor/fast-editor.component";
import { EditTaskComponent } from "../tasks/edit-task/edit-task.component";
import { FormMode } from "../../../utilities/form-mode.enum";
import { TableService } from "../../shared/table/table.service";
import { TasksService } from "../../../shared/tasks/tasks.service";
import { TaskBoardsService } from "../../../shared/tasks/task-boards/task-boards.service";
import { DictionariesService } from "../../../shared/dictionaries/dictionaries.service";
import { TaskContextModelResponse } from "../../../shared/models/care-homes/task-board/task-context.model";
import { getErrorMessage } from "../../../utilities/utils";
import { TaskPausingService } from "../../shared/table/task-pausing.service";
import { EditEmployeeComponent } from "../../employess/edit-employee/edit-employee.component";
import { CareHomesService } from "../../../shared/care-homes/care-homes.service";
import { AuthUserService } from "../../../@core/data/auth-user.service";
import { DashboardService } from '../../../shared/dashboard/dashboard.service';
import { DashboardStateService } from '../../../shared/dashboard/dashboard-state.service';
import { TaskBoardsStateService } from '../../../shared/tasks/task-boards/task-boards-state.service';

@Component({
  selector: 'task-list-simple-table',
  templateUrl: './task-list-simple-table.component.html',
  styleUrls: ['./task-list-simple-table.component.scss','../../shared/taskboard-table/taskboard-table.component.scss', '../tasks/task-list/task-list.component.scss']
})
export class TaskListSimpleTableComponent implements OnInit, OnChanges {

  showPinnedTasksFlag: boolean = false;
  taskContextInfo: { totalTasks: number, totalToComment: number } = {totalTasks: 0, totalToComment: 0};
  taskContext!: TaskContextModelResponse
  @Input() cardHeader = ''
  @Input() rightMargin = 20;
  @Input() bottom: string = '0';
  @Input() onlyGlobal = true;
  @Output() toggleList = new EventEmitter<boolean>();

  @ViewChild('pinnedTasksContent') pinnedTasksContent!: ElementRef;

  private offsetTop: number = 0;

  private subscription: Subscription = new Subscription();
  private _loadTaskBoardSubscription: Subscription;
  private taskBoardId: string = '';
  public taskBoardName: string = '';
  public data: any;

  private tasksWithoutSubtasks = [];
  public taskboardPermissions: any = {};
  public taskboardPermissionsFromTask: any = {};

  public isShowCommentedTasks = true;
  public showSubtasksForTaskId;
  private subtasksCount = 0;
  inputValue = '';
  private inputSubject = new Subject<string>();
  @ViewChild('table', { static: false }) table: MatTable<any>;
  dropTable(event: CdkDragDrop<any>) {
    if (this.showSubtasksForTaskId) return;
    // if (!this.isZoomEqualOne) return;
    event.currentIndex = event.currentIndex - this.subtasksCount;
    event.previousIndex = event.previousIndex - this.subtasksCount;
    if (this.isShowCommentedTasks && (this.tasksWithoutSubtasks[event.currentIndex].taskGoupingName != this.data.data[event.previousIndex].taskGoupingName || event.currentIndex == 0)) {
      const newCategoryName = event.currentIndex != 0 && this.tasksWithoutSubtasks[event.currentIndex].taskGoupingName ? this.tasksWithoutSubtasks[event.currentIndex].taskGoupingName : 'ungrouped';
      const oldCategoryName = event.previousIndex != 0 && this.tasksWithoutSubtasks[event.previousIndex].taskGoupingName ? this.tasksWithoutSubtasks[event.previousIndex].taskGoupingName : 'ungrouped';

      if (newCategoryName == oldCategoryName) {
        this.changeIndex(event);
      } else {
        this.subscription.add(this.dialogService
          .open(QuestionDialogComponent, {
            context: {
              title: 'Change category confirmation',
              message: `You are about to change task category from <b>${oldCategoryName}</b> to <b>${newCategoryName}</b>. Are you sure ?`,
              okLabel: 'Yes',
              cancelLabel: 'No'
            },
          })
          .onClose.subscribe((decision: boolean) => {
            if (decision) {
              this.changeIndex(event);
            }
          }));
      }
    } else if (this.isShowCommentedTasks) {
      this.changeIndex(event);
    } else {
      this.toastrService.warning(`To do this operation you must switch toggle to 'All'`, 'Warning');
    }
  }

  public changeIndex(event: any) {
    this.subscription.add(this.tasksService.changeTaskOrder(event.item.data.taskId, {
      rowBefore: (event.currentIndex > event.previousIndex) ? this.tasksWithoutSubtasks[event.currentIndex] : this.tasksWithoutSubtasks[event.currentIndex - 1],
      rowAfter: (event.currentIndex > event.previousIndex) ? this.tasksWithoutSubtasks[event.currentIndex + 1] : this.tasksWithoutSubtasks[event.currentIndex],
      newIndex: event.currentIndex,
      oldIndex: event.previousIndex,
      taskBoardId: event.item.data.taskBoardId
    }).subscribe(() => {
      this.getTopOffset();
      this.loadData();
    }));
  }

  public originalColumns = [
    {
      fieldName: 'isChecked',
      class: 'checkbox'
    },
    {
      fieldName: 'index',
      title: 'No.'
    },
    {
      fieldName: 'info',
      title: 'Info'
    },
    {
      fieldName: 'priority',
      title: 'Pri',
      fieldType: 'textarea-view',
    },
    {
      fieldName: 'title',
      title: 'Title',
      class: 'text-left'
    },
    {
      fieldName: 'description',
      title: 'Description',
      fieldType: 'textarea-view',
      addSubtasksProgress: true,
      click: (event: any, task: any) => {
        this.getTopOffset();
        if (task.canEditLatestUpdate) {
          event.preventDefault();
          event.stopPropagation();
          this.edit(task, TaskTab.INFO);
        } else {
          this.toastrService.warning('You cannot edit this field.', 'Warning', { duration: 60000, destroyByClick: true });
        }
      }
    },
    {
      fieldName: 'latestUpdate',
      title: 'Latest update',
      fieldType: 'textarea-view',
      formatHtml: true,
      click: (event: any, task: any) => {
        this.getTopOffset();
        if (task.canEditLatestUpdate) {
          event.preventDefault();
          event.stopPropagation();
          let taskNo = task.taskNo;
          let taskId = task.taskId;
          let subtaskId = 0;
          if (task.isSubTask) {
            taskNo = task.subTaskForTaskNo;
            taskId = task.subTaskForTaskId;
            subtaskId = task.taskId;
          }
          // if subtask change task id to parent for load this task in details
          const taskCopy = Object.assign({}, task);
          taskCopy.taskId = taskId;
          if (task.isSubTask) {
            this.tableService.openWindow(FastEditorComponent, `Edit Latest update of ${taskNo}`, {
              task: taskCopy,
              elementId: taskId,
              taskPermissions: this.taskboardPermissions,
              subtaskId: subtaskId
            });
          } else {
            this.edit(task, TaskTab.INFO, true);
          }
        } else {
          this.toastrService.warning('You cannot edit this field.', 'Warning', { duration: 60000, destroyByClick: true });
        }
      }
    },
    {
      fieldName: 'status',
      title: 'State',
    }
  ];
  public columns = [];
  public statuses = [];
  public checkedTasks = [];
  public displayedColumns: string[] = [];
  public dataLoaded = false;
  public isDataLoaded = false;
  public isGroupManager = false;
  public originalData: any;
  public taskboardSummary: any = {};
  public raggStatistics: any = {};
  public taskBoardDetails: any = {};
  public suggestedTaskTemplates = [];
  public isCopyVisible = false;
  public isDetailsVisible = false;
  public showChanger = true;

  private id: string;
  currentOpen = false;

  constructor(
    private careHomesService: CareHomesService,
    private authUserService: AuthUserService,
    private tasksService: TasksService,
    private taskboardService: TaskBoardsService,
    private dictionariesService: DictionariesService,
    private tableService: TableService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private taskPausingService: TaskPausingService,
    private taskBoardsStateService: TaskBoardsStateService,
    private dashboardService: DashboardService,
    private dashboardStateService: DashboardStateService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.id = `${this.cardHeader}_${new Date().getTime()}`
    if (this.cardHeader == '') {return}
    this.subscription.add(this.dashboardStateService.lastOpened$.subscribe((lastId) => {
      if (this.id === lastId) {
        this.currentOpen = true;
      } else {
        this.currentOpen = false;
        this.showPinnedTasksFlag = false;
      }
    }))
    if (this.cardHeader === 'Global') {
      this.subscription.add(this.taskBoardsStateService.updateGlobalList$.subscribe(() => {
        this.loadGlobal();
      }));
      this.loadGlobal();
    } else {
      this.load();
    }

    this.subscription.add(
      this.inputSubject.pipe(debounceTime(200), distinctUntilChanged()).subscribe((val) => {
        this.loadData(val)
      })
    )
  }

  private load() {
    this.subscription.add(
      forkJoin({
        taskContextInfo: this.careHomesService.getTaskBoardContextInfo(this.authUserService.getCareHomeId(), this.cardHeader),
        taskContext: this.careHomesService.getTaskBoardContext(this.authUserService.getCareHomeId(), this.cardHeader)
      }).subscribe(({taskContextInfo, taskContext}) => {
        if (taskContextInfo && taskContext) {
          this.taskContextInfo['totalTasks'] = taskContextInfo.totalTasks;
          this.taskContextInfo['totalToComment'] = taskContextInfo.totalToComment;
          this.taskContext = Object.assign({}, taskContext.result);

          this.loadData();
        }
      })
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.cardHeader) {
      this.load();
    }
    this.cdr.detectChanges()
  }

  private loadGlobal() {
    this.subscription.add(this.dashboardService.getGlobalTasks().subscribe(({ result }) => {
      this.data = result.tasksListGeneral.sort((a, b) => a.index > b.index ? 1 : -1);
      let tasks = [];
      this.data.forEach((e) => {
        tasks.push({
          groupingColor: '',
          isEmptySpace: true,
          taskGoupingName: e.careHomeName
        });
        e.tasksList.forEach((task) => {
          tasks.push(task);
        });

        this.taskContext = {
          deadlineList: [],
          isDisplayUserIcon: false,
          orderList: [],
          raggInfo: {},
          raggStatistics: {},
          requiresUpdate: false,
          statistics: {},
          taskBoardDescription: 'Desc',
          taskBoardId: 0,
          taskBoardName: 'Name',
          taskCount: 0,
          taskboardPermissions: {},
          tasksList: tasks,
          top3Templates: { teampleteList: [] }
        }
      });
      this.loadData();
    }));
  }
  loadData(filterText = '', refreshDescription = false, taskIdToReload = -1) {
    this.getTopOffset();
    this.isDataLoaded = false;
    this.checkedTasks = [];

    this.columns = Object.assign([], this.originalColumns);

    // this._loadTaskBoardSubscription = (this.taskboardService.getTaskBoard(this.taskContext.taskBoardId)
    //   .subscribe((response: any) => {
    //     this.taskBoardName = response.result.boardName;
    //     this.taskboardPermissions = response.result;
    //   }));

    this.tasksWithoutSubtasks = this.taskContext?.tasksList.filter((item: any) => !item.isSubTask) || [];
    this.subtasksCount = (this.taskContext?.tasksList?.length - this.tasksWithoutSubtasks.length) || 0;
    this.originalData = this.taskContext?.tasksList || [];
    this.taskboardSummary = this.taskContext?.statistics || {};
    this.raggStatistics = this.taskContext?.raggStatistics || {};
    this.taskBoardDetails = this.taskContext?.raggInfo || {};
    this.taskboardPermissionsFromTask = this.taskContext?.taskboardPermissions || {};
    this.suggestedTaskTemplates = this.taskContext?.top3Templates.teampleteList || [];
    this.loadTaskStatuses();
    if (filterText) {
      this.data = new MatTableDataSource(this.originalData.filter((e) => e.isEmptySpace || !e.isEmptySpace && e.title.toLowerCase().includes(filterText.toLocaleLowerCase())));
    } else {
      this.data = new MatTableDataSource(this.originalData);
    }
    
    if (this.cardHeader === 'Global') {
      this.taskContextInfo = {
        totalTasks: this.originalData.filter((i) => i.taskId && !i.isSubTask).length,
        totalToComment: this.originalData.filter((i) => i.isSubTask && !i.commented).length
      }
    }
    if (!this.dataLoaded) {
      //this.addRaggColumnsIfConfigured();
      this.manageAvatarColumn(this.taskContext?.isDisplayUserIcon);
      this.loadColumns();
      this.dataLoaded = true;
    }
    this.isDataLoaded = true;
    this.cdr.detectChanges();
  }

  loadColumns() {
    const columns = [];
    for (const column of this.columns) {
      columns.push(column.fieldName);
    }
    this.displayedColumns = columns;
  }

  addRaggColumnsIfConfigured() {
    const ragg1ColumnIndex = this.columns.findIndex((element: any) => element.fieldName === 'taskRagg1Id');
    const ragg2ColumnIndex = this.columns.findIndex((element: any) => element.fieldName === 'taskRagg2Id');

    if (ragg1ColumnIndex === -1) {
      if (this.taskBoardDetails.ragg1DictionaryId && this.taskBoardDetails.ragg1DictionaryId > 0) {
        const field = {
          fieldName: 'taskRagg1Id',
          fieldType: 'dictionary-ragg',
          canChange: this.taskboardPermissionsFromTask.canIChangeStateRagg1,
          title: this.taskBoardDetails.ragg1DictionaryNameShort,
          dictionary: this.taskBoardDetails.ragg1DictionaryWordList && this.taskBoardDetails.ragg1DictionaryWordList.wordsFromDictionary
        };
        this.columns.push(field);
      }
    } else {
      this.columns[ragg1ColumnIndex].canChange = this.taskboardPermissionsFromTask.canIChangeStateRagg1;
    }

    if (ragg2ColumnIndex === -1) {
      if (this.taskBoardDetails.ragg2DictionaryId && this.taskBoardDetails.ragg2DictionaryId > 0) {
        const field = {
          fieldName: 'taskRagg2Id',
          fieldType: 'dictionary-ragg',
          canChange: this.taskboardPermissionsFromTask.canIChangeStateRagg2,
          title: this.taskBoardDetails.ragg2DictionaryNameShort,
          dictionary: this.taskBoardDetails.ragg2DictionaryWordList && this.taskBoardDetails.ragg2DictionaryWordList.wordsFromDictionary
        };
        this.columns.push(field);
      }
    } else {
      this.columns[ragg2ColumnIndex].canChange = this.taskboardPermissionsFromTask.canIChangeStateRagg2;
    }
  }

  onTaskSelectionChange(event: any, element: any) {
    const checked = event.target.checked;
    if (checked) {
      this.checkedTasks.push(element.taskId);
    } else {
      const checkedTaskIndex = this.checkedTasks.findIndex((value: any) => value === element.taskId);
      if (checkedTaskIndex > -1) {
        this.checkedTasks.splice(checkedTaskIndex, 1);
      }
    }
  }

  onChangeStatus(newStatus: any, task: any) {
    let data;
    data = {
      status: newStatus
    };
    if (task.isSubTask) {
      data = {
        status: newStatus,
        isSubTask: true
      };
    }

    if (task.isRecurring && (newStatus === 'Paused' || newStatus === 'D&R')) {
      this.showChangeStatusPopup(task, newStatus, data);
    } else if (newStatus === 'Paused') {
      this.taskPausingService.showPausingPopup(task, () => {
        this.updateTaskStatus(newStatus, task, data);
      }, () => {
        this.updateTaskStatusCancel(newStatus, task, data);
      });
    } else {
      this.updateTaskStatus(newStatus, task, data);
    }
  }

  updateTaskStatus(newStatus: any, task: any, data) {
    this.getTopOffset();
    this.subscription.add(this.tasksService.updateTaskStatus(task.taskId, data)
      .subscribe((response: any) => {
          this.toastrService.success(response.message, 'Success');
          this.loadData('', false, task.taskId);
        },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
          this.updateTaskStatusCancel(newStatus, task, data);
        }));
  }

  showEmployee360(event: any, employeeId: number, fullName: string) {
    if (employeeId && employeeId > -1) {
      event.preventDefault();
      event.stopPropagation();
      this.tableService.openWindowWithoutCareHomeVerification(EditEmployeeComponent,
        `Employee Profile Information - ${fullName}`, { elementId: employeeId });
    } else {
      this.toastrService.warning('Please assign employee to this task with employee dropdown', 'Warning');
    }
  }

  getRaggNameFromId(id: any, raggWords: any[]): string {
    const raggFound = raggWords.find(x => x.id === id);
    if (raggFound) {
      return raggFound.wordShortName;
    }
    return '';
  }

  getRaggColorFromId(id: any, raggWords: any[]): string {
    const raggFound = raggWords.find(x => x.id === id);
    if (raggFound) {
      return raggFound.colorHash;
    }
    return '';
  }

  onChangeRagg(value: number, taskId: any, fieldName: string) {
    this.getTopOffset();
    const data = {};
    data[fieldName] = value;
    this.subscription.add(this.tasksService.updateTaskRagg(taskId, data)
      .subscribe((response: any) => {
          this.toastrService.success(response.message, 'Success');
          this.loadData();
        },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }));
  }

  public formatLastUpdateField = (latestUpdate: string): string => (latestUpdate ? latestUpdate.split('\n').join('<br>') : latestUpdate);

  isOverflow(element: any) {
    return (element.offsetHeight < element.scrollHeight
      || element.offsetWidth < element.scrollWidth);
  }

  countLines(element: any) {
    return Math.floor(100 - (element.offsetHeight * 100) / element.scrollHeight) + '% info';
  }

  public dragStarted(event: CdkDragStart) {
    if (this.showSubtasksForTaskId) {
      event.source._dragRef.reset();
      this.toastrService.warning('To do this operation you must hide all subtasks', 'Warning');
    }
  }

  private showChangeStatusPopup(task: any, newStatus: string, data?: any) {
    let message = 'There are tasks in here that will refresh after each WU submission. Are you sure you want to change the status and remove this whole task?';

    if (newStatus === 'Paused') {
      message = 'There are tasks in here that will refresh after each WU submission. Are you sure you want to change the status and pause this whole task?';
    }
    this.subscription.add(this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Modify confirmation',
          message: message,
          okLabel: 'Yes',
          cancelLabel: 'No',
          hideSecondOkButton: true
        },
      })
      .onClose.subscribe((decision: any) => {
        if (decision) {
          const data = {
            status: newStatus
          };

          this.getTopOffset();
          if (newStatus == 'Paused') {
            this.taskPausingService.showPausingPopup(task, () => {
              this.updateTaskStatus(newStatus, task, data);
            }, () => {
              this.updateTaskStatusCancel(newStatus, task, data);
            });
          } else {
            // status D & R
            this.subscription.add(this.tasksService.updateTaskStatus(task.taskId, data)
              .subscribe((response: any) => {
                  this.toastrService.success(response.message, 'Success');
                  this.loadData('', false, task.taskId);
                },
                (err) => {
                  this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
                }));
          }
        } else {
          this.loadData('', false, task.taskId);
        }
      }));
  }

  updateTaskStatusCancel(newStatus: any, task: any, data) {
    this.getTopOffset();
    this.loadData('', false, task.taskId);
  }

  toogleSubtaskList(taskId: number) {
    if (this.showSubtasksForTaskId != taskId) {
      this.showSubtasksForTaskId = taskId;
    } else {
      this.showSubtasksForTaskId = null;
    }
  }

  manageAvatarColumn(displayUserIcon: boolean) {
    const avatarColumnIndex = this.columns.findIndex(column => column.title === 'Photo');

    if (displayUserIcon) {
      if (avatarColumnIndex === -1) {
        const avatarColumn = {
          fieldName: 'avatar',
          title: 'Photo',
          id: 'employeeId',
          avatarId: 'employeeAvatarId',
        };
        this.columns.splice(2, 0, avatarColumn);
      }
    } else {
      if (avatarColumnIndex !== -1) {
        this.columns.splice(avatarColumnIndex, 0);
      }
    }
  }

  setIsGroupManagerMode(isGroupManager: boolean) {
    this.isGroupManager = isGroupManager;
    if (isGroupManager) {
      const indexInDisplayedColumns = this.displayedColumns.findIndex((item: any) => {
        return item === 'latestUpdate'
      });
      this.displayedColumns[indexInDisplayedColumns] = 'shortHistory';
      const index = this.columns.findIndex((item: any) => {
        return item.fieldName === 'latestUpdate'
      });
      this.columns[index] = {
        fieldName: 'shortHistory',
        title: 'Submitted update',
        fieldType: 'textarea-view',
        formatHtml: true,
        addSubtasksChangeProgress: true,
        click: (event: any, task: any) => {
          this.edit(task, TaskTab.INFO, true);
        }
      };
    } else {
      const indexInDisplayedColumns = this.displayedColumns.findIndex((item: any) => {
        return item === 'shortHistory';
      });
      this.displayedColumns[indexInDisplayedColumns] = 'latestUpdate';
      const index = this.columns.findIndex((item: any) => {
        return item.fieldName === 'shortHistory'
      });
      this.columns[index] = {
        fieldName: 'latestUpdate',
        title: 'Latest update',
        fieldType: 'textarea-view',
        formatHtml: true,
        click: (event: any, task: any) => {
          this.getTopOffset();
          if (task.canEditLatestUpdate) {
            event.preventDefault();
            event.stopPropagation();
            let taskNo = task.taskNo;
            let taskId = task.taskId;
            let subtaskId = 0;
            if (task.isSubTask) {
              taskNo = task.subTaskForTaskNo;
              taskId = task.subTaskForTaskId;
              subtaskId = task.taskId;
            }
            // if subtask change task id to parent for load this task in details
            const taskCopy = Object.assign({}, task);
            taskCopy.taskId = taskId;
            if (task.isSubTask) {
              this.tableService.openWindow(FastEditorComponent, `Edit Latest update of ${taskNo}`, {
                task: taskCopy,
                elementId: taskId,
                taskPermissions: this.taskboardPermissions,
                subtaskId: subtaskId
              });
            } else {
              this.edit(task, TaskTab.INFO, true);
            }
          } else {
            this.toastrService.warning('You cannot edit this field.', 'Warning', { duration: 60000, destroyByClick: true });
          }
        }
      };
    }
  }

  loadTaskStatuses() {
    this.subscription.add(this.dictionariesService.getTaskStatuses()
      .subscribe((response: any) => {
        this.statuses = response.result.wordsFromDictionary.filter(item => item.wordFullName != 'Closed');
      }));
  }

  edit = (row: any, tab: TaskTab = TaskTab.INFO, scrollToUpdate = true) => {
    this.showPinnedTasks();
    let subTitle = row.title;
    let taskToEdit = row.taskId;
    let taskHeader = row.taskBoardName || ''  + ' "' + row.title + '"';
    let subtaskId = 0;
    if (row.isSubTask) {
      taskToEdit = row.subTaskForTaskId;
      subtaskId = row.taskId;
    }
    // this.getTopOffset();
    this.tableService.openWindow(EditTaskComponent, `Edit Task - ${taskHeader}`,
      {
        mode: FormMode.EDIT,
        elementId: taskToEdit,
        activeTab: tab,
        subtaskId: subtaskId,
        scrollToUpdate: scrollToUpdate,
        subtitle: subTitle
      }, 'edit-task-window');
  }

  toggleCopyVisibility(event: any) {
    this.isCopyVisible = event;
  }

  trackByFn(index) {
    return index;
  }

  getTopOffset() {
    // this.offsetTop = document.getElementsByClassName('scrollable-container')[0].scrollTop;
  }

  showPinnedTasks() {
    if (this.showPinnedTasksFlag && !this.currentOpen) {
      this.dashboardStateService.lastOpened$.next(this.id)
    } else {
      this.showPinnedTasksFlag = !this.showPinnedTasksFlag;
      if (this.showPinnedTasksFlag) {
        this.dashboardStateService.lastOpened$.next(this.id)
      }
      this.toggleList.emit(this.showPinnedTasksFlag);
    }
  }

  resize(event: any, pinnedTasksContent: HTMLDivElement) {
    if (!event.target.classList.contains('resize-touchpoint')) {return }
    let height = pinnedTasksContent.offsetHeight;
    if (height == 300) {
      this.pinnedTasksContent.nativeElement.classList.add('resized')
    } else {
      this.pinnedTasksContent.nativeElement.classList.remove('resized')
    }
  }
  onInput(val: string) {
    this.inputSubject.next(val);
  }
}
