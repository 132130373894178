
<div class="table-margin height">
    <ngx-inbox-table
        *ngIf="!isMessagesReport"
        [columns]="columns"
        [data]="data"
        [edit]="openMessage"
        [load]="showMails"
        [refreshPage]="refreshPage"
        [changeMessageCategory]="changeMessageCategory"
        [markReadStatus]="markReadStatus"
        [thinner]="true"
        [clearTop]="true"
        [usePagination]="true"
        [paginationSizeOptions] = "[50, 100, 250]"
        [sortFromBackend]='true'
        [customFilter]="true"
        [totalRows]='totalRows'
        [noBorder]="true"
        [is360]="is360"
        [heightPx]="heightPx"
        [selectedRows]="selectMessages"
        [clearSelected]="clearSelected$"
        [enableReadBtn]="enableReadBtn"
        [isDataLoaded]="isDataLoaded">
    </ngx-inbox-table>
    <div *ngIf="isMessagesReport" class="table-margin height">
        <ngx-table
            class="message-report__container"
            [edit]="permissions.VIEW_ALL_MESSAGES? openMessageReport : null"
            [columns]="reportColumns"
            [data]="reportData"
            [load]="load"
            [showTableFilter]="false"
            [monthDateFilter]="true"
            [actualSelectorValue]='actualGroupesValue'
            [multiselectorVisible]="multiselectorVisible"
            [autocompleteSelectorValues]="groupes"
            autocompleteSelectorLabel="roleName"
            autocompleteSelectorPlaceholder="Groupes"
            [thinner]="true"
            [clearTop]="true"
            [usePagination]="true"
            [paginationSizeOptions] = "[10, 20, 50, 100]"
            [sortFromBackend]='true'
            [customFilter]="true"
            [totalRows]='totalRows'
            [noLoadOnStart]="noLoadOnStart"
            [noBorder]="true"
            [descriptionTop]="true"
            [showPinned]="showPinned"
            [selectAllSwitch]="true"
            [isDataLoaded]="isDataLoaded"
            [descriptionText]="'You cannot see messages sent to or from people who are higher ranked than you'">
        </ngx-table>
    </div>
</div>

