import { Injectable } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { TasksService } from '../../../shared/tasks/tasks.service';
import { getErrorMessage, urlify } from '../../../utilities/utils';
import { QuestionDialogTaskPauseDialogComponent } from '../question-dialog/question-dialog-task-pause/question-dialog-task-pause.component';

@Injectable({
  providedIn: 'root'
})
export class TaskPausingService {
  private task: any;
  private callback = null;
  private subscription = new Subscription();

  constructor(private dialogService: NbDialogService,
              private taskService: TasksService,
              private toastrService: NbToastrService) {}

  showPausingPopup(task: any, callback = null, cancelCallback = null) {
    this.callback = callback;
    this.task = task;
    this.subscription = this.dialogService
      .open(QuestionDialogTaskPauseDialogComponent, {
        context: {
          title: 'Pausing confirmation'
        },
      })
      .onClose.subscribe((decisionResponse: any) => {
        if (decisionResponse) {
          this.setDeadlineAndText(decisionResponse, cancelCallback);
        } else {
          if (cancelCallback) {
            cancelCallback();
          }
        }
      });
  }

  setDeadlineAndText(dateAndText: { date: string, text: string}, cancelCallback = null) {
        const textToAppend = (dateAndText.text.toString().toLowerCase().includes("reason")) ?
        dateAndText.text
      : ("Reason for pausing the task:").toUpperCase() + dateAndText.text +"\n------------------------------";    
    const data = {
      comments: textToAppend + '\n' + urlify(this.task.description),
      dueTo: dateAndText.date,
      openTime: new Date()
    };
    this.subscription = this.taskService.patchTaskPausing(this.task.taskId, data)
      .subscribe((response: any) => {
          if (this.callback) {
            this.callback();
          }
        },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
          if (cancelCallback) {
            cancelCallback();
          }
        }
      );
  }

}
