<img class="table-photo-cell__img" [nbPopover]="popoverPhoto" src="/assets/images/photo_aparat.png" alt="">

<ng-template #popoverPhoto>
  <div class="table-gps-photo-popover">
    <header class="table-gps-photo-popover__header">
      <p class="table-gps-photo-popover__title">{{photoData.type}} photo - {{employeeFullName}} {{employeePositionName}} {{photoData.date}}</p>
      <div class="table-gps-photo-popover__close" (click)="closePopover()"><i class="nb-close" aria-hidden="true"></i></div>
    </header>
    <div class="table-gps-photo-popover__content">
      <img class="table-gps-photo-popover__img" [src]="photoData.photoUid | scheduleConfirmationPhoto: photoData.typeId | async" alt="">
    </div>
  </div>
</ng-template>
