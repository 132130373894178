<div class="form-view-grid">
  <ngx-tabset>
    <ngx-tab id="new-multiple-task-container-info-tab" class="thin-echo-scrollbar" tabTitle="Task Information" [active]="activeTab === TaskTab.INFO">
      <main>
        <form [formGroup]="form" autocomplete="off" style="margin-right: 0.5rem;">
          <div class="grid-wrapper mt-2">
            <div class="box start-1 end-12 mt-1rem mb-2">
              <ngx-multiple-autocomplete-selector-with-checkbox
                [values]="taskboards"
                placeholder="select taskboards"
                bindLabel="boardName"
                label="Taskboards:"
                formControlName="taskboards"
                emitEmptyRemove="true">
              </ngx-multiple-autocomplete-selector-with-checkbox>
            </div>
            <div class="form-control-group box start-12 end-17">
              <label class="label" for="input-home-group-id" style="margin-top: 0.3rem; margin-bottom: 0.3rem;">Select a Preset Group of boards:</label>
              <select formControlName="careHomeGroupId">
                <option *ngFor="let group of groupList" [ngValue]="group.id">{{group.wordShortName}}</option>
              </select>
              <div *ngIf="form.get('careHomeGroupId').value" class="clear multiple" (click)="clear('careHomeGroupId')">
                <nb-icon icon="close-outline"></nb-icon>
              </div>
            </div>
          </div>

          <div class="grid-wrapper">
            <div class="form-control-group box start-12 end-17">
              <label class="label" for="input-status">Status:</label>
              <select formControlName="statusId">
                <option *ngFor="let status of statuses" [ngValue]="status.id">{{status.wordFullName}}</option>
              </select>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'statusId', errorMessages) }}
              </p>
            </div>
          </div>

          <div class="grid-wrapper">
            <div class="form-control-group box start-1 end-12 height-auto">
              <ngx-multiple-autocomplete-selector-with-checkbox
                [values]="categories"
                [noSelectAll]="true"
                bindLabel="wordFullName"
                placeholder="select max 3 categories"
                class="categories-selector"
                label="Category:"
                valuesLimit="3"
                formControlName="categoryId">
              </ngx-multiple-autocomplete-selector-with-checkbox>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'categoryId', errorMessages) }}
              </p>
            </div>
            <div class="form-control-group box start-12 end-17">
              <label class="label" for="input-due-to">Deadline:</label>
              <input nbInput
                      fullWidth
                      formControlName="dueTo"
                      name="dueTo"
                      id="input-due-to"
                      placeholder="DD/MM/YYYY"
                      [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker startView="month"></mat-datepicker>
            </div>
          </div>

          <div class="grid-wrapper mt-2">
            <div
              class="form-control-group box start-1"
              [class.end-12]="showAdvancedFeatures"
              [class.end-17]="!showAdvancedFeatures">
              <label class="label" for="input-title">Title:</label>
              <input nbInput
                    fullWidth
                    formControlName="title"
                    name="title"
                    id="input-title"
                    maxlength="50"
                    placeholder="Title">
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'title', errorMessages) }}
              </p>
            </div>
            <div class="form-control-group box start-12 end-17" *ngIf="showAdvancedFeatures">
              <label class="label" for="input-priority-id">Priority:</label>
              <select formControlName="priorityId">
                <option *ngFor="let priority of priorities" [ngValue]="priority.id">{{priority.wordFullName}}</option>
              </select>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'priorityId', errorMessages) }}
              </p>
            </div>
          </div>

          <div class="grid-wrapper mt-3">
            <div class="box start-1 end-17">
              <label class="label" for="comments">Description:</label>
              <textarea nbInput
                        fullWidth
                        formControlName="comments"
                        name="comments"
                        style="height: 114px;"
                        id="comments"
                        placeholder="Add notes about this task"
                        autofocus>
              </textarea>
            </div>
          </div>

          <div class="grid-wrapper mt-2" *ngIf="showAdvancedFeatures">
            <div class="form-control-group box start-1 end-12">
              <ngx-multiple-autocomplete-selector-with-checkbox-and-add
                [values]="tags"
                bindLabel="wordFullName"
                placeholder="select tags"
                label="Linking tags:"
                [(ngModel)]="selectedTags"
                formControlName="tags"
                noSelectAll="true"
                (newValueAdded)="newTagAdded($event)">
              </ngx-multiple-autocomplete-selector-with-checkbox-and-add>
            </div>
            <div class="form-control-group box start-12 end-17">
              <label class="label" for="input-pinnedAreaId">Pinned Area:</label>
              <select formControlName="pinnedAreaId" id="input-pinnedAreaId">
                <option [ngValue]="null" [disabled]="true" >{{pinned.length > 0 ? 'Select pinned area' : 'Loading...'}}</option>
                <option [ngValue]="''"></option>
                <option
                  *ngFor="let pinnedArea of pinned"
                  [ngValue]="pinnedArea.id"
                  [disabled]="pinnedArea.disabled"
                >
                  {{ pinnedArea.wordFullName }}
                </option>
              </select>
              <p class="error-message">
                {{
                utils.handleValidationErrorMessage(
                  form,
                  "pinnedAreaId",
                  errorMessages
                )
                }}
              </p>
            </div>
          </div>

          <div class="grid-wrapper mt-2">
            <div class="box start-1 end-17">
              <div class="grouping-multiple">
                <div class="grouping-dropdown-auto" *ngFor="let groupDetails of groupingList; let index=index">
                  <label class="label">{{groupDetails.name}}:</label>
                  <select [(ngModel)]="pickedGrouping[index]" [ngModelOptions]="{standalone: true}"
                          (change)="groupingChanged($event, groupDetails)">
                    <option *ngFor="let group of groupDetails.groupItemList" [value]="group.id"
                            [ngStyle]="{'background-color': group.color}">{{group.wordFullName}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="advanced-switchers-container" [ngStyle]="{display: 'flex', 'margin-bottom': '-45px'}">
            <ngx-switcher
              *ngIf="!showGridView && subtaskEditorFormComponent.subtasks.length > 0"
              [isToggle]="false"
              [isTriple]="false"
              [isTask]="true"
              [firstValue]="true"
              [secondValue]="'default'"
              [thirdValue]="false"
              [firstValueLabel]="'Expand All'"
              [secondValueLabel]="'Default'"
              [thirdValueLabel]="'Collapse All'"
              [hideSecondValue]="true"
              [value]="'default'"
              (valueChange)="toggleExpandCollapse($event)"
              [style.transform]="'scale(0.8)'"
              [ngStyle]="showAdvancedFeatures ? {display: 'flex', 'margin-bottom': '20px', 'margin-top': '20px'} : {display: 'flex', 'margin-bottom': '20px', 'margin-top': '15px'}"
            >
            </ngx-switcher>
            <ngx-switcher
              [firstValue]="false"
              [secondValue]="true"
              firstValueLabel="Advanced features"
              [value]="!showAdvancedFeatures"
              (valueChange)="toggleAdvFeatures()"
              [ngStyle]="showAdvancedFeatures ? {display: 'flex', 'margin-bottom': '20px', 'margin-top': '20px'} : {display: 'flex', 'margin-bottom': '20px', 'margin-top': '15px'}"
            >
            </ngx-switcher>
            <div *ngIf="showAdvancedFeatures"></div>
            <ngx-switcher
              *ngIf="showAdvancedFeatures"
              [firstValue]="true"
              [secondValue]="false"
              firstValueLabel="List view"
              secondValueLabel="Grid view"
              [value]="!showGridView"
              (valueChange)="toggleListGridView()"
            >
            </ngx-switcher>
          </div>
          <div style="margin-top: 4.3rem;">
            <ngx-subtask-editor-form
              #subtaskEditorFormComponent
              formControlName="subTasksList"
              [showGridView]="showGridView"
              [isRecurringVisible]="true"
              [addingTaskInNewTicketMode]="true"></ngx-subtask-editor-form>
          </div>
        </form>
      </main>
    </ngx-tab>
    <ngx-tab tabTitle="Files" [active]="activeTab === TaskTab.FILES">
      <div class="mt-2">
        <ngx-file-upload
          [files]="files"
          [isDownloadEnabled]="false"
          [isAddNotesEnabled]="true"
          (descriptionUpdate)="uploadDocumentDescription($event)"
          (onUpload)="uploadFile($event)"
          (onDelete)="deleteFile($event)">
        </ngx-file-upload>
      </div>
    </ngx-tab>
  </ngx-tabset>
  <footer class="space-between">
    <div class="flex">
      <button class="btn btn-success"
              [disabled]="!utils.isFormValid(form) || !isAnySubtaskAdded() || !canSave"
              (click)="save()">
        <i class="nb-checkmark" aria-hidden="true"></i> Save
      </button>
      <button class="btn btn-danger ml-4"
              (click)="closeWindow()">
        <i class="nb-close" aria-hidden="true"></i> Close
      </button>
      <div class="flex middle ml-2" [ngStyle]="{cursor: 'pointer'}">
        <nb-checkbox (change)="changeEmailList($event)" [checked]="this.form.get('isEmailList').value" class="task-checkbox">email with WU</nb-checkbox>
        <nb-checkbox (change)="changeEmailOnSave($event)" [checked]="this.form.get('isEmailOnSave').value" class="task-checkbox longer">email this task on save</nb-checkbox>
      </div>
    </div>
    <div class="flex middle">
      <button id="watchers" class="btn btn-info" [disabled]="!utils.isFormValid(form) || !isAnySubtaskAdded()" (click)="addWatchers()">Add Watchers</button>
      <nb-checkbox (change)="changeFavoriteState($event)" [checked]="this.form.get('isFavorite').value" class="task-checkbox">on my watchlist</nb-checkbox>
    </div>
  </footer>
</div>
