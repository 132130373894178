
<nb-card class="new-stuff-group">
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
        <form [formGroup]="form">
            <div class="flex-container-horizontal">
              <div class="flex-container">
                <div class="form-control-group">
                  <label class="label" for="name">Name:</label>
                  <input nbInput fullWidth formControlName="groupName" name="groupName" id="groupName" placeholder="Group name">
                  <p class="error-message">
                    {{ utils.handleValidationErrorMessage(form, 'groupName', errorMessages) }}
                  </p>
                </div>
                <div class="form-control-group">
                  <label class="label" for="colorHash">Group color</label>
                  <input
                  class="form-control"
                  [colorPicker]="form.get('colorHash').value"
                  [value]="form.get('colorHash').value"
                  (colorPickerChange)="form.get('colorHash').setValue($event)"
                  formControlName="colorHash"
                  [style.background]="form.get('colorHash').value"
                  />

                  <p class="error-message">
                    {{ utils.handleValidationErrorMessage(form, 'colorHash', errorMessages) }}
                  </p>
                </div>
              </div>
            </div>
          </form>
    </nb-card-body>
    <nb-card-footer>
      <div class="new-stuff-group__footer">
        <button class="btn btn-success"(click)="save()" [disabled]="!formValid()">Save</button>
        <button class="btn btn-danger" (click)="dismiss()">Cancel</button>
      </div>
    </nb-card-footer>
  </nb-card>