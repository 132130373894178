import { Component, OnInit, Input, OnDestroy, OnChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { TableService } from '../../table.service';
import { EditEmployeeComponent } from '../../../../employess/edit-employee/edit-employee.component';
import { getFullName } from '../../../../../utilities/utils';
import { EmployeeTab } from '../../../../employess/employee-tab.enum';
import { PermissionsService } from '../../../../../@core/data/permissions.service';
import { debounceTime } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { DictionariesService } from "../../../../../shared/dictionaries/dictionaries.service";
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { PayrollAllRolesPopupComponent } from '../../../../employess/payroll/payroll-all-roles-popup/payroll-all-roles-popup.component';
import {RotaSummaryTableComponent} from "../../../../employess/rota/rota-summary-table/rota-summary-table.component";

const DEBOUNCE_TIME = 500;
// payroll table
@Component({
  selector: 'ngx-payroll-only-table',
  templateUrl: './payroll-only-table.component.html',
  styleUrls: ['../only-table.component.scss']
})
export class PayrollOnlyTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('table', { static: false }) table: MatTable<any>;

  @Input() data: any = [];
  @Input() columns: any = {};
  @Input() onRowClick: (event: any) => any;
  @Input() filter: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Input() clearSelection: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() selectableVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() thinner: boolean = false;
  @Input() showIndexColumn: boolean = true;
  @Input() selectedRows: (event: any) => any;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public displayedColumns: string[] = [];
  public dataSource: any;
  public permissions = <any>{};
  public isSelectableVisible = false;
  public selection = new SelectionModel(true, []);
  statuses: any = []

  private subscription: Subscription = new Subscription();

  public readonly isAllSelected = () => {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    this.selectedRows(this.selection.selected);
  }

  toggleRow(row) {
    this.selection.toggle(row);
    this.selectedRows(this.selection.selected);
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  constructor(protected tableService: TableService,
              protected permissionsService: PermissionsService,
              private router: Router,
              private dialogService: NbDialogService,
              private dictionariesService: DictionariesService,) {
    this.permissions = this.permissionsService.getPermissions();
  }

  ngOnInit() {
    this.subscription.add(
      this.dictionariesService.getPayrollStatuses().subscribe((response: any) => {
        this.statuses = response.result.wordsFromDictionary
      })
    )
    this.dataSource.sort = this.sort;
    this.filter
      .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((value: string) => {
      this.dataSource.filter = (value.trim) ? value.trim().toLowerCase() : '';
    });
    this.clearSelection.subscribe(() => {
      this.selection.clear();
    });

    this.selectableVisibility.subscribe((value: boolean) => {
      this.isSelectableVisible = value;
    });
  }

  ngOnChanges() {
    const columns = [];
    columns.push('select');
    if (this.showIndexColumn) {
      columns.push('index');
    }

    columns.push('actions');
    for (const column of this.columns) {
      columns.push(column.fieldName);
    }
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.filterPredicate = (order: any, filter: string) => {
      const transformedFilter = filter.trim().toLowerCase();
      const listAsFlatString = (obj): string => {
        let returnVal = '';

        Object.values(obj).forEach((val) => {
          if (typeof val !== 'object') {
            returnVal = returnVal + ' ' + val;
          } else if (val !== null) {
            returnVal = returnVal + ' ' + listAsFlatString(val);
          }
        });

        return returnVal.trim().toLowerCase().replace(/ +(?= )/g, '');
      };
      return listAsFlatString(order).includes(transformedFilter);
    };
    this.displayedColumns = columns;
    this.dataSource.sort = this.sort;
  }

  showEmployee360(event: any, employeeId: number, firstName: string, surname: string, fullName: string) {
    event.preventDefault();
    event.stopPropagation();
    this.tableService.openWindowWithoutCareHomeVerification(EditEmployeeComponent,
      `Employee Profile Information - ${fullName ? fullName : getFullName(firstName, surname)}`, { elementId: employeeId, activeTab: EmployeeTab.INFO });
  }

  openRotaWithSpecyficWeek(eventDate: string) {
    window.open(`${window.location.origin}/#/employess/rota?loadDate=${eventDate.replace(/\//g, '-')}`, '_blank')
  }

  trackByFn(index, item) {
    return index;
  }

  ngOnDestroy(): void {
    if (this.filter) {
      this.filter.unsubscribe();
    }
  }

  openPopupForRole(element) {
    this.tableService.openWindow(
      RotaSummaryTableComponent,
      "Rota Summary Table",
      {
        roleId: element.roleId,
        dayDate: element.eventDate,
        tableIdentifier: "",
        teamUid: element.teamUid,
      },
      "expanded-body",
    );
  }


}
