import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'confirm-request-hours',
  templateUrl: './confirm-request-hours.component.html',
  styleUrls: ['./confirm-request-hours.component.scss']
})
export class ConfirmRequestHoursComponent {

  @Input() data: {
    hours: string;
    minutes: string;
    days?: string;
  };
  constructor(
    protected ref: NbDialogRef<ConfirmRequestHoursComponent>
  ) { }

  close(value) {
    this.ref.close(value);
  }
}
