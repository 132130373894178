<div class="noteboard">
  <section *ngFor="let category of dataToShow; let index=index" class="noteboard-category" [style.marginTop.px]="index > 0 ? '30px' : '0px'">
    <header class="noteboard-category-header"><h3>{{category.name}}</h3><span>{{category && category.messageList && category.messageList.length}} messages pinned</span></header>
    <content class="noteboard-category-content">
      <div *ngFor="let chunk of category.list;" class="noteboard-category-content-row">
        <article *ngFor="let note of chunk;" class="noteboard-note"
        [ngClass]="{'white': note.color == NoteColor.WHITE || !note.color,
                    'green': note.color == NoteColor.GREEN,
                    'blue': note.color == NoteColor.BLUE,
                    'red': note.color == NoteColor.RED,
                    'violet': note.color == NoteColor.VIOLET,
                    'orange': note.color == NoteColor.ORANGE,
                    'black': note.color == NoteColor.BLACK,
                    'pink': note.color == NoteColor.PINK
                  }">
          <header class="noteboard-note-sender">
            <div class="noteboard-note-sender-left">
              <div class="noteboard-note-sender-left-wrapper flex">
                <img [src]="note.author.avatarUid | avatar | async" class="avatar pointer" alt=""/>
                <div class="noteboard-note-sender-left-data ml-2">
                  <span>{{note.author && note.author.employeeFullName}}</span>
                  <div class="noteboard-note-sender-left-position">{{note.author && note.author.positionName}}</div>
                </div>
              </div>
            </div>
            <div class="noteboard-note-sender-right">
              <nb-icon *ngIf="note.haveAttachments" icon="attach-outline" style="margin-right: 5px;"></nb-icon>
              <nb-icon *ngIf="permissions.NOTICEBOARD_MANAGEMENT" icon="more-vertical-outline" [mdePopoverTriggerFor]="noteboardPopover" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger" style="cursor: pointer;"></nb-icon>
              <mde-popover #noteboardPopover="mdePopover" [mdePopoverOverlapTrigger]="false" mdePopoverCloseOnClick="false" mdePopoverArrowColor="#fff">
                <div class="noteboard-note-popover">
                  <div class="noteboard-note-popover-header-container">
                    <span>Options</span><hr>
                  </div>
                  <div class="noteboard-note-popover-unpin" (click)="unpin(note)">
                    <img src="/assets/images/unpin.svg"/><span>Unpin</span>
                  </div>
                  <div class="noteboard-note-popover-header-container">
                    <span>Colors</span><hr>
                  </div>
                  <div class="noteboard-note-popover-colors-container">
                    <div class="noteboard-note-popover-colors-container-row">
                      <div class="noteboard-note-popover-colors-container-color white" (click)="changeNoteColor(note, NoteColor.WHITE)">
                        <nb-icon *ngIf="!note.color && note.color == null || note.color == NoteColor.WHITE" icon="checkmark-outline"></nb-icon>
                      </div>
                      <div class="noteboard-note-popover-colors-container-color" style="background-color:#88B767;" (click)="changeNoteColor(note, NoteColor.GREEN)">
                        <nb-icon *ngIf="note.color == NoteColor.GREEN" icon="checkmark-outline"></nb-icon>
                      </div>
                      <div class="noteboard-note-popover-colors-container-color" style="background-color: #67b3ff;" (click)="changeNoteColor(note, NoteColor.BLUE)">
                        <nb-icon *ngIf="note.color == NoteColor.BLUE" icon="checkmark-outline"></nb-icon>
                      </div>
                      <div class="noteboard-note-popover-colors-container-color" style="background-color: #ff6780;" (click)="changeNoteColor(note, NoteColor.RED)">
                        <nb-icon *ngIf="note.color == NoteColor.RED" icon="checkmark-outline"></nb-icon>
                      </div>
                    </div>
                    <div class="noteboard-note-popover-colors-container-row">
                      <div class="noteboard-note-popover-colors-container-color" style="background-color: #9c92ff;" (click)="changeNoteColor(note, NoteColor.VIOLET)">
                        <nb-icon *ngIf="note.color == NoteColor.VIOLET" icon="checkmark-outline"></nb-icon>
                      </div>
                      <div class="noteboard-note-popover-colors-container-color" style="background-color: #ffaf26;" (click)="changeNoteColor(note, NoteColor.ORANGE)">
                        <nb-icon *ngIf="note.color == NoteColor.ORANGE" icon="checkmark-outline"></nb-icon>
                      </div>
                      <div class="noteboard-note-popover-colors-container-color" style="background-color: #4b4b4b;" (click)="changeNoteColor(note, NoteColor.BLACK)">
                        <nb-icon *ngIf="note.color == NoteColor.BLACK" icon="checkmark-outline"></nb-icon>
                      </div>
                      <div class="noteboard-note-popover-colors-container-color" style="background-color: #ec86c3;" (click)="changeNoteColor(note, NoteColor.PINK)">
                        <nb-icon *ngIf="note.color == NoteColor.PINK" icon="checkmark-outline"></nb-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </mde-popover>
            </div>
          </header>
          <content (click)="showMessage(note)">
            <div class="noteboard-note-title">
              <p>{{note.title}}</p>
              <span>{{note.date}}</span>
            </div>
            <p class="noteboard-note-message">{{note.message}}</p>
          </content>
          <div class="note-triangle">
            <svg id="corner" xmlns="http://www.w3.org/2000/svg" width="28" height="28.277" viewBox="0 0 28 28.277">
              <g id="corner2" data-name="corner" transform="translate(8060 9734.277)">
                <rect id="Rectangle_1717" data-name="Rectangle 1717" width="28" height="28" transform="translate(-8060 -9734)" fill="#F5F7FC"/>
                <path id="Path_673" data-name="Path 673" d="M0,0H28V28Z" transform="translate(-8060 -9734.277)" [attr.fill]="getTriangleColor(note.color)"/>
              </g>
            </svg>
          </div>
        </article>
      </div>
    </content>
  </section>
</div>
