<nb-card>
  <nb-card-header>{{ title }}</nb-card-header>
  <nb-card-body
    (keydown.arrowdown)="move(true)"
    (keydown.arrowup)="move(false)"
    (keydown.enter)="close($event)"
  >
    <nb-form-field>
      <nb-icon nbPrefix icon="search-outline"></nb-icon>
      <input
        id="filter-input"
        nbInput
        fullWidth
        placeholder="search for template"
        [(ngModel)]="filterValue"
        (keyup)="applyExtendedFilter()"
      />
    </nb-form-field>
    <div class="selector-container">
      <section>
        <h6>Template title</h6>
      </section>
      <section>
        <h6>Template description</h6>
      </section>
    </div>
    <div class="selector-container templates">
      <section
        style="direction: rtl"
        class="thin-echo-scrollbar-5 templates-list"
      >
        <ng-container
          *ngIf="suggestedTemplates && suggestedTemplates.length > 0"
        >
          <div class="divider-title">Recent templates</div>
          <div
            *ngFor="let item of suggestedTemplates"
            class="template"
            (click)="chooseTemplate(item)"
            [class.active]="selected === getTempateId(item)"
          >
            {{ item.templateNameFull }}
          </div>
        </ng-container>
        <ng-container>
          <div
            class="divider-title"
            *ngIf="suggestedTemplates && suggestedTemplates.length > 0"
          >
            All
          </div>
          <div
            *ngFor="let item of filteredTemplates"
            class="template"
            (click)="chooseTemplate(item)"
            [class.active]="selected === getTempateId(item)"
          >
            {{ item.title }}
          </div>
        </ng-container>
      </section>
      <section>
        <textarea
          class="description"
          [class.active]="selected"
          [innerHTML]="selectedItemDescription"
          readonly
        ></textarea>
      </section>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="footer-buttons">
      <div>
        <button class="btn btn-success mr-2" (click)="ok()">Ok</button>
        <button class="btn btn-danger" (click)="cancel()">Cancel</button>
      </div>
      <button class="btn btn-success ml-5" (click)="cancel()">
        Blank template
      </button>
    </div>
  </nb-card-footer>
</nb-card>
