import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Injectable()
export class LogsService {

  constructor(private httpClient: HttpClient) {}

  getLogs(careHomeId: number, startDate: any, endDate: any, rowsFrom?: number, rowsTo?: number, orderBy?: string, orderDirection?: string, searchFilter?: string) {
    return this.httpClient.get(`${environment.apiUrl}/general/logs/system-log?careHomeId=${careHomeId}&startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}&rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&orderBy=${orderBy}&orderDirection=${orderDirection}&searchFilter=${searchFilter}`);
  }

  getDeadlineEmailLogs(careHomeId: number, startDate: any, endDate: any, rowsFrom?: number, rowsTo?: number, orderBy?: string, orderDirection?: string, searchFilter?: string) {
    return this.httpClient.get(`${environment.apiUrl}/general/logs/dadline-email-log?careHomeId=${careHomeId}&startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}&rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&orderBy=${orderBy}&orderDirection=${orderDirection}&searchFilter=${searchFilter}`);
  }

  sendOpenTime(data: any) {
    return this.httpClient.post(`${environment.apiUrl}/general/frontend-logs`, data);
  }

  getRotaConflicts() {
    return this.httpClient.get(`${environment.apiUrl}/general/rota-conflicts`);
  }

  deleteRotaConflict(id: number) {
    return this.httpClient.delete(`${environment.apiUrl}/general/rota-conflicts/${id}`);
  }
}
