export const payrollErrorMessages = [
  {
    field: 'employeeId',
    errors: [
      {
        error: 'required',
        message: 'Employee is required'
      }
    ]
  },
  {
    field: 'typeId',
    errors: [
      {
        error: 'required',
        message: 'Adjustment Type is required'
      }
    ]
  },
  {
    field: 'categoryId',
    errors: [
      {
        error: 'required',
        message: 'Category is required'
      }
    ]
  },
  {
    field: 'roleId',
    errors: [
      {
        error: 'required',
        message: 'Role is required'
      }
    ]
  },
  {
    field: 'timeAmount',
    errors: [
      {
        error: 'required',
        message: 'Amount of hours is required'
      },
      {
        error: 'hours',
        message: 'Amount of hours in format hh:mm'
      }
    ]
  },
  {
    field: 'value',
    errors: [
      {
        error: 'required',
        message: 'Value is required'
      }
    ]
  },
  {
    field: 'payrollMonth',
    errors: [
      {
        error: 'required',
        message: 'Payroll month is required'
      }
    ]
  },
  {
    field: 'eventDate',
    errors: [
      {
        error: 'required',
        message: 'Event date is required'
      }
    ]
  },
  {
    field: 'comments',
    errors: [
      {
        error: 'required',
        message: 'Description is required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 5 characters long'
      },
      {
        error: 'maxlength',
        message: 'Description cannot be longer than 1000 characters'
      }
    ]
  },
  {
    field: 'payrollMonth',
    errors: [
      {
        error: 'required',
        message: 'Date is required'
      },
      {
        error: 'invalidDate',
        message: 'Invalid date'
      }
    ]
  }
];
