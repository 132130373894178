import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { UsersService } from '../../../shared/users/users.service';
import { CareHomesService } from '../../../shared/care-homes/care-homes.service';
import { NbToastrService } from '@nebular/theme';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { getValidationStatus, handleValidationErrorMessage, isFormValid, getErrorMessage } from '../../../utilities/utils';
import { errorMessages } from '../user-errors';
import { password, equalToFieldValue } from '../../../utilities/validators';
import { TableService } from '../../shared/table/table.service';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { EmployessService } from '../../../shared/employess/employess.service';

@Component({
  selector: 'ngx-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public careHomes: any = [];
  public errorMessages = errorMessages;
  public form: UntypedFormGroup;
  public utils = { getValidationStatus, handleValidationErrorMessage, isFormValid };
  public inProgress: boolean = false;
  public isPortableUser: any;
  public defaultPassword: string = '';
  public employees: any = [];
  public canSave = true;

  constructor(private usersService: UsersService,
              private careHomesService: CareHomesService,
              private tableService: TableService,
              private employeesService: EmployessService,
              @Inject(DOCUMENT) private document: Document,
              private toastrService: NbToastrService) {}

  ngOnInit() {
    this.createForm();
    this.subscription.add(this.careHomesService.getCareHomesWithIdName()
      .subscribe((response: any) => {
        this.careHomes = response.result.careHomesList;
      }
    ));
    this.loadEmployeeIfNeed();
  }

  loadEmployeeIfNeed() {
    const data = this.tableService.getValue();
    if (data) {
      this.isPortableUser = true;
      data.employeeId = data.elementId;
      this.defaultPassword = data.defaultPassword;
      this.form.get('password').setValue(this.defaultPassword);
      this.form.get('confirmPassword').setValue(this.defaultPassword);


      this.form.patchValue(data);
      if (this.defaultPassword.length > 0) {
        this.form.get('password').clearValidators();
        this.form.get('confirmPassword').clearValidators();

        this.form.get('password').valueChanges.subscribe(() =>{});
        this.form.get('confirmPassword').valueChanges.subscribe(() =>{});
        this.form.get('password').updateValueAndValidity();
        this.form.get('confirmPassword').updateValueAndValidity();
      }
      this.form.get('accountType').disable();
      this.form.get('defaultCareHome').disable();
      this.getEmployeesByCareHome(data.defaultCareHome);
    }
  }

  createForm() {
    if (this.isPortableUser) {
      this.form = new UntypedFormGroup({
        login: new UntypedFormControl(null, [Validators.required, Validators.minLength(5), Validators.maxLength(25)]),
        email: new UntypedFormControl(null, [Validators.required, Validators.email]),
        phoneNumber: new UntypedFormControl(null, [Validators.maxLength(25)]),
        accountType: new UntypedFormControl(null, [Validators.required]),
        firstName: new UntypedFormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
        surname: new UntypedFormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
        password: new UntypedFormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(25)]),
        defaultCareHome: new UntypedFormControl(null, [Validators.required]),
        confirmPassword: new UntypedFormControl(null, [Validators.required]),
        employeeId: new UntypedFormControl(null),
        isTechnicalAccount: new UntypedFormControl(false),
        isValidCompanyEmail: new UntypedFormControl(false),
        isPortableUser: new UntypedFormControl(false)
      });
      } else {
      this.form = new UntypedFormGroup({
        avatar: new UntypedFormControl(null),
        login: new UntypedFormControl(null, [Validators.required, Validators.minLength(5), Validators.maxLength(25)]),
        email: new UntypedFormControl(null, [Validators.required, Validators.email]),
        phoneNumber: new UntypedFormControl(null, [Validators.maxLength(25)]),
        accountType: new UntypedFormControl(null, [Validators.required]),
        firstName: new UntypedFormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
        surname: new UntypedFormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
        password: new UntypedFormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(25),  password]),
        defaultCareHome: new UntypedFormControl(null, [Validators.required]),
        confirmPassword: new UntypedFormControl(null, [Validators.required]),
        employeeId: new UntypedFormControl(null),
        isTechnicalAccount: new UntypedFormControl(false),
        isValidCompanyEmail: new UntypedFormControl(false),
        isPortableUser: new UntypedFormControl(false)
      });

    }

    this.subscription.add(this.form.get('defaultCareHome')
      .valueChanges
      .subscribe((value: any) => {
        this.getEmployeesByCareHome(value);
      }
    ));

    // password
    this.subscription.add(this.form.get('password')
      .valueChanges
      .subscribe(() => {
        const control = this.form.get('confirmPassword');
        control.setValidators([Validators.required, equalToFieldValue(this.form.get('password').value)]);
        control.updateValueAndValidity();
      }
    ));

    this.subscription.add(this.form.get('confirmPassword')
      .valueChanges
      .subscribe(() => {
        this.form.get('confirmPassword').setValidators([Validators.required, equalToFieldValue(this.form.get('password').value)]);
      }
    ));
  }

  getEmployeesByCareHome(careHomeId: number) {
    this.subscription.add(this.employeesService.getActiveEmployeesByCareHome(careHomeId)
    .subscribe((response: any) => {
      response.result.employeesLines.map((i: any) => i.fullName = i.employeeFullName);
      this.employees = response.result.employeesLines;
    }));
  }

  addNewUser() {
    this.canSave = false;
    if (!this.inProgress) {
      this.inProgress = true;
      const fd = new FormData();
      if (!this.isPortableUser) { fd.append('avatarImage', this.form.get('avatar').value); }
      fd.append('originUrl', this.document.location.origin);
      fd.append('login', this.form.get('login').value);
      fd.append('email', this.form.get('email').value);
      fd.append('firstName', this.form.get('firstName').value);
      fd.append('phoneNumber', this.form.get('phoneNumber').value);
      fd.append('accountType', this.form.get('accountType').value);
      fd.append('surname', this.form.get('surname').value);
      fd.append('defaultCareHome', this.form.get('defaultCareHome').value);
      fd.append('password', this.form.get('password').value);
      fd.append('isTechnicalAccount', this.form.get('isTechnicalAccount').value);
      fd.append('isValidCompanyEmail', this.form.get('isValidCompanyEmail').value);
      fd.append('isPortableUser', this.form.get('isPortableUser').value);
      if (this.form.get('isPortableUser').value) {
        fd.append('employeeId', this.form.get('employeeId').value);
      }

      this.subscription.add(this.usersService.addNewUser(fd)
        .subscribe((response: any) => {
          this.toastrService.success(response.message, 'Success');
          this.tableService.closeWindow(true);
        },
        (err) => {
          this.canSave = true;
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
          this.inProgress = false;
        }
      ));
    }
  }

  changeState(event: any, formField: string) {
    this.form.get(formField).setValue(event.target.checked);
  }

  changeIsPortableUser(event: any) {
    this.form.get('isPortableUser').setValue(event.target.checked);
    this.isPortableUser = event.target.checked;
    this.createForm();
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  ngOnDestroy() {
    this.careHomes = null;
    this.subscription.unsubscribe();
  }

}
