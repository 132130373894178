import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { shareReplay, tap } from "rxjs/operators";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable()
export class AvatarsService {
  constructor(private httpClient: HttpClient) {}

  private avatars: { id: string; link: string, message: string }[] = []

  getAvatar(id: any) {
    let avatar$ = new BehaviorSubject<{ id: string; link: string, message: string }>(null)
    let avatarFound = this.avatars.find(avatar => avatar.id == id)
    if (!avatarFound) {
      return this.httpClient.get<{ link: string, message: string }>(`${environment.apiUrl}/avatars/${id}`)
        .pipe(tap((avatar) => {this.avatars.push({id, link: avatar.link, message: avatar.message})}))
    } else {
      avatar$.next(this.avatars.find(avatar => avatar.id == id))
      return avatar$.asObservable()
    }
  }

  getBlobAvatar(id: any) {
    return this.httpClient.get(`${environment.apiUrl}/avatars/${id}?blob=true`, { responseType: 'blob' });
  }

}
