export enum ApplicationPermissions {
  MENU_APEX = "Menu -> Apex",
  MENU_WU_PAST_TASKS = "Menu -> WU - past tasks",
  MENU_AL_ACCEPTANCE = "Menu -> AL acceptance",
  MENU_SICKNESS_ABSENCE = "Menu -> Sickness / absence",
  MENU_FIRST_DAY_AVAILABLE = "Menu -> First day available",
  MENU_SICKNESS_TO_REVIEW = "Menu -> Sickness to review",
  MENU_EMPLOYEES = "Menu -> Employees",
  MENU_CONTRACTS = "Menu -> Contracts",
  MENU_ANNUAL_LEAVES = "Menu -> Annual leaves",
  MENU_SICKNESS = "Menu -> Sickness",
  MENU_HR_CALENDAR = "Menu -> HR calendar",
  MENU_MANAGER_HR_CALENDAR = "Menu -> Manager HR calendar",
  MENU_RESIDENT_FINANCE_REPORT = "Menu -> Residents finance report",
  MENU_REPORT_DESKTOP = "Menu -> Report desktop",
  MENU_COURSES = "Menu -> Courses",
  MENU_PAYROLL_ADJUSTMENTS = "Menu -> Payroll adjustments",
  MENU_STAFF_HOURS = "Menu -> Staff hours report",
  MENU_LEAVERS_REPORT = "Menu -> Leavers report",
  MENU_AWARDS = "Menu -> Awards",
  MENU_RESIDENTS = "Menu -> Residents",
  MENU_MEDICAL_ORDERS = "Menu -> Medical orders",
  MENU_PHARMACIES = "Menu -> Pharmacies",
  MENU_MENU_CHOICES = "Menu -> Menu choices",
  MENU_ACTIVITY_SHEET = "Menu -> Activity sheet",
  MENU_MATRIX_CONTROL_TABLE = "Menu -> Matrix control table",
  MENU_ROTA_HOURS_SCHEMA = "Menu -> Rota Hours schema",
  MENU_ROTA_HOURS_SCHEMA_NEW = "Rota Manager Schema",
  MENU_SYSTEM_CONFIGURATION = "Menu -> System configuration",
  MENU_ROTA = "Menu -> Rota",
  MENU_GENERAL_PRACITCES = "Menu -> General Practices",
  MENU_LINKED_TASK_DASHBOARD = "Menu -> Linked task dashboard",
  TASK_ANALYZER = "Tasks Analyzer",
  ROTA_SCHEDULE_AND_CONFIRMATION = "Rota schedule & confirmation",
  ROTA_SUBMIT_TO_GROUP = "Rota submit to group",
  ROTA_FREEZE_DATE = "Rota freeze date",
  ROTA_UNFREEZE = "Unfreeze ROTA for 10m",
  NEW_CONTRACTS = "New contracts",
  PAYROLL_ADJUSTEMENTS_ADD_AND_ACCEPTANCE = "Payroll adjustments add and acceptance",
  PAYROLL_ADJUSTMENT_VALUE = "Payroll Adjustment, value",
  PORTABLE_ONLY = "Portable only",
  WEEKLY_REPORT_MANAGER = "Weekly report manager",
  IN_TRAININIG_TO_ACTIVE = "in Training to active",
  FINANCE_MANAGER = "Finance Manager",
  WEEKLY_REPORT_GROUP_MANAGER = "Weekly report group manager",
  EMPLOYEE_DOCUMENTS = "Employee documents",
  EMPLOYEE_MIGRATION = "Employee migration",
  EMPLOYEES = "Employees",
  RESIDENT_MENU_CHOICES_ADDING_EDITING = "Resident menu choices adding editing",
  TUESDAY_MENU_CHOICE_EDITING = "Tuesday menu choices editing",
  ADD_MULTIPLE_TASKS = "Add multiple tasks",
  USER_MANAGER = "User manager",
  USER_MANAGER_CHANGE_PASSWORD = "User manager change password",
  TESTING_FUNCTIONALITY = "Tesing functionality",
  ADD_EXTRAS = "Add extras",
  SHIFT_CONFIRMATION_ADD_COMMENTS = "Add shifts confirmation comments",
  SHIFT_CONFIRMATION_VIEW_SHIFT = "View shifts",
  SHIFT_CONFIRMATION_CONFIRMATION_ABILITY = "Shift confirmation",
  INBOX_MESSAGES_REPORT = "Inbox - Messages report",
  VIEW_ALL_MESSAGES = "View all messages",
  UNLOCK_PAYROLL_ADJUSTEMENTS = "Unlock payroll adjustments",
  ROTA_CONFLICT_SOLVING = "ROTA conflicts solving",
  NOTICEBOARD_ACCESS = "Noticeboard Access",
  NOTICEBOARD_MANAGEMENT = "Noticeboard Management",
  CHANGE_NEW_TASK_STATUS = "Change new task status",
  MULTI_SITE_ADMIN = "Multi site admin",
  EDIT_TRAINING_BUDGET = "Training budget editing",
  TASK_COLLABORATION_ASSIGNMENT = "Task collaboration / assignment",
  SEND_USER_MESSAGES = "Send user messages",
  ROTA_OVERWRITE_EVENT = "ROTA overwrite with event",
  RESIDENTS_FINANCE_MANAGER = "Residents Finance Manager",
}
