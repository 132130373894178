<nb-card [ngClass]="{ group: needLoadTable }">
  <nb-card-header>{{ title }}</nb-card-header>
  <nb-card-body [ngClass]="{ group: needLoadTable }">
    <div class="form-view-grid">
      <form [formGroup]="form">
        <div class="grid-wrapper mt-2">
          <div class="form-control-group box start-1 end-11">
            <ngx-multiple-autocomplete-selector-with-checkbox
              *ngIf="!isLoading"
              #dropdownUsers
              [values]="userList"
              bindLabel="fullName"
              secondBindLabel="email"
              placeholder="Search..."
              label="Email list"
              formControlName="emailTo"
              [selectAllToggle]="isSelectAll"
              [selectSomeToggle]="autoAddEmailRecipients"
              [showSecondLabelToggle]="true"
              [showClear]="true"
              [autoAddEmailRecipients]="autoAddEmailRecipients"
              [filteredEmailsSentTo]="filteredEmailsSentTo"
            >
            </ngx-multiple-autocomplete-selector-with-checkbox>
          </div>
          <div class="form-control-group box start-1 end-11">
            <p class="title-label">Subject</p>
            <input
              nbInput
              fullWidth
              formControlName="emailSubject"
              name="subject-text"
              id="subject-text"
              autofocus
              autocomplete="off"
            />
          </div>
          <div
            class="form-control-group box start-1 end-11"
            style="height: 120px"
          >
            <p class="title-label margin">Message</p>
            <textarea
              class="textarea-height line-height"
              nbInput
              fullWidth
              minlength="3"
              formControlName="emailBody"
              autofocus
            >
            </textarea>
          </div>
        </div>
      </form>
    </div>
    <div class="table-summary-container" *ngIf="needLoadTable">
      <table mat-table [dataSource]="data" matSort style="width: 100%">
        <ng-container
          *ngFor="let column of columns; trackBy: trackByFn"
          [matColumnDef]="column.fieldName"
        >
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="header">
            <span>{{ column.title }}</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span
              *ngIf="
                column.fieldName !== 'isSend' &&
                column.fieldName !== 'description' &&
                column.fieldName !== 'submittedUpdate'
              "
              [class]="column.class"
              >{{ element[column.fieldName] }}</span
            >
            <textarea
              autosize
              *ngIf="
                column.fieldName === 'description' ||
                column.fieldName === 'submittedUpdate'
              "
              class="textarea-view"
              >{{ element[column.fieldName] }}</textarea
            >
            <nb-checkbox
              *ngIf="column.fieldName === 'isSend'"
              (change)="changeSendState(element)"
              [checked]="element.isSend"
            ></nb-checkbox>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="thinner"
          [ngClass]="{ 'green-bg': row.readyToSubmit === 'yes' }"
        ></tr>
      </table>
    </div>
  </nb-card-body>
  <nb-card-footer class="flex-space">
    <div class="flex">
      <button
        class="btn btn-success"
        [disabled]="!utils.isFormValid(form)"
        (click)="ok()"
      >
        <i class="nb-checkmark" aria-hidden="true"></i> Send
      </button>
      <nb-checkbox
        *ngIf="showClearEmails"
        style="margin-top: 0.3rem"
        (change)="changeEmailClearState($event)"
        [checked]="this.form.get('clearEmailListAfterSend').value"
        >clear my email list after sending</nb-checkbox
      >
    </div>
    <button class="btn btn-danger ml-4" (click)="cancel()">
      <i class="nb-close" aria-hidden="true"></i> Close
    </button>
  </nb-card-footer>
</nb-card>
