import { Component, EventEmitter, Input, Output } from '@angular/core';

// Models
import { DictionariesAgencyWordsModel } from '../../../shared/models/dictionaries/agencies/dictionaries-agencies-model';

@Component({
  selector: 'ngx-ui-agencies-list',
  templateUrl: './ui-agencies-list.component.html',
  styleUrls: ['./ui-agencies-list.component.scss'],
})
export class UiAgenciesListComponent {
  @Input() agencies: DictionariesAgencyWordsModel[];
  @Input() agencyId: number;
  @Output() onAgencyChanged: EventEmitter<number> = new EventEmitter<number>();

  public agencyChanged(value: string): void {
    this.onAgencyChanged.emit(+value);
  }
}
