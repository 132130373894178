<div class="todo-container" [ngClass]="{'green': todo.isCompleted, 'red': todo.isNotCompleted || todo.latestUpdate }">

  <div class="full-width">
    <div class="flex-between" [style.padding-bottom.px]="5">
      <div  class="flex-center" [style.width.%]="100">
        <div *ngIf="editMode" class="full-width" [formGroup]="form">
          <textarea
            id="subtask-editable-todo-edit-input"
            formControlName="comments"
            name="comments"
            placeholder="Type title here"
            class="form-control thin-echo-scrollbar-5 full-width"
            (focusout)="save()"
            (keydown.space)="$event.stopPropagation()"
            (keydown.enter)="$event.stopPropagation()"
            minlength="1"></textarea>
        </div>
        <span
          *ngIf="!editMode"
          class="thin-echo-scrollbar-5"
          [ngStyle]="{overflow: 'hidden', 'overflow-y': 'auto', 'word-break': 'break-word', 'max-width': '100%' }"
        >{{ todo.comments }}</span>
      </div>
      <div class="flex-center" *ngIf="!editMode">
        <span *ngIf="!gridView" (click)="edit($event)" class="icon"><i class="nb-edit" aria-hidden="true"></i></span>
        <span (click)="deleteItem($event)" class="icon red"><i class="nb-trash" aria-hidden="true"></i></span>
        <span *ngIf="isRecurringVisible" class="icon"><nb-icon (click)="toggleInfinity()" icon="flip-2-outline" class="infinity-icon" [ngClass]="{'active': isRecurring}"></nb-icon></span>
        <nb-icon icon="chevron-down-outline" (click)="todo.open ? todo.open = false : todo.open = true" class="open-description-icon" [ngClass]="{'open': todo.open}"></nb-icon>
      </div>
    </div>
    <div style="padding: 0 11px 0 6px;">
      <div *ngIf="todo.open" class="flex">
        <textarea
          (mousedown)="$event.stopPropagation()"
          name="groupResponse"
          placeholder="Please provide an update"
          [id]="'~~~' + clearWhitespaces(todo.comments)"
          [(ngModel)]="todo.groupResponse"
          (ngModelChange)="onModelChange()"
          class="textarea-view can-be-edited"
          minlength="1"
          #latestUpdateInput
          style="height: 94px; min-height: 60px;">
        </textarea>
        <div class="right-column add-form flex">
          <button
            (keyup.r)="setStatus(true)"
            (keyup.k)="setStatus(false)"
            (keyup.enter)="doNothing($event)"
            (mouseup)="setStatus(true, false)"
            class="decision-button disabled"
            [id]="'subtask-editable-todo-done-button-' + subtaskIndex + '-' + toDoindex"
            [ngClass]="{'confirmable': isConfirmable()}">
            <div>
              <nb-icon class="mb-1 bigger-icon" icon="checkmark-outline"></nb-icon>
              <span>"R"</span>
            </div>
          </button>
          <button
            (keyup.r)="setStatus(true)"
            (keyup.k)="setStatus(false)"
            (keyup.enter)="doNothing($event)"
            (mouseup)="setStatus(false, false)"
            class="decision-button red"
            [ngClass]="{'disabled': todo.isNotCompleted}"
            [id]="'subtask-editable-todo-not-done-button-' + subtaskIndex + '-' + toDoindex">
            <div>
              <nb-icon class="mb-1 bigger-icon" icon="close-outline"></nb-icon>
              <span>"K"</span>
            </div>
          </button>
        </div>
      </div>
      <div *ngIf="(todo.assignedUsers && todo.assignedUsers.length > 0) || todo.open" style="margin-left: -10px; margin-top: 5px;">
        <ngx-multiple-autocomplete-selector
          [values]="users"
          [selectedValues]="todo.assignedUsers"
          [(ngModel)]="todo.assignedUsers"
          noSelectAll="true"
          [label]="'Assigned to'"
          class="todo-form"
        ></ngx-multiple-autocomplete-selector>
      </div>
    </div>
  </div>
</div>
