import { NgModule } from "@angular/core";
import { ThemeModule } from "../../@theme/theme.module";
import { ConfirmPasswordInputComponent } from "./confirm-password-input/confirm-password-input.component";
import { SwitcherComponent } from "./switcher/switcher.component";
import { QuestionDialogComponent } from "./question-dialog/question-dialog.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DateFilerComponent } from "./date-filer/date-filer.component";
import { CancelEventDialogComponent } from "./cancel-event-dialog/cancel-event-dialog.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { OnlyTableComponent } from "./table/only-table/only-table.component";
import { EditableColumnComponent } from "./editable-column/editable-column.component";
import { MultipleAutocompleteSelectorComponent } from "./multiple-autocomplete-selector/multiple-autocomplete-selector.component";
import { MonthFilterComponent } from "./date-filer/month-filter/month-filter.component";
import { CustomCheckboxInputComponent } from "./custom-checkbox-input/custom-checkbox-input.component";
import { AddWatchersDialogComponent } from "./add-watchers-dialog/add-watchers-dialog.component";
import { TaskboardTableComponent } from "./taskboard-table/taskboard-table.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { HorizontalStickyOnlyTableComponent } from "./table/only-table/horizontal-sticky-only-table/horizontal-sticky-only-table.component";
import { DayFilterComponent } from "./date-filer/day-filter/day-filter.component";
import { TaskboardOnlyTableWithStatsComponent } from "./taskboard-only-table-with-stats/taskboard-only-table-with-stats.component";
import { TaskboardOnlyTableComponent } from "./taskboard-only-table-with-stats/taskboard-only-table/taskboard-only-table.component";
import { HorizontalStickyProfilesPermissionsTableComponent } from "./table/only-table/horizontal-sticky-profiles-permissions-table/horizontal-sticky-profiles-permissions-table.component";
import { ThinnerSwitcherComponent } from "./switcher/thinner-switcher/thinner-switcher.component";
import { EmployeeFileUploadComponent } from "./file-upload/employee-file-upload/employee-file-upload.component";
import { ChooseFileCategoryDialogComponent } from "./choose-file-category-dialog/choose-file-category-dialog.component";
import { MultipleAutocompleteSelectorWithCheckboxComponent } from "./multiple-autocomplete-selector-with-checkbox/multiple-autocomplete-selector-with-checkbox.component";
import { PermissionSwitcherComponent } from "./switcher/permission-switcher/permission-switcher.component";
import { AutosizeModule } from "ngx-autosize";
import { CustomDateInputComponent } from "./custom-date-input/custom-date-input.component";
import { QuestionDialogParameterComponent } from "./question-dialog/question-dialog-parameters/question-dialog-parameter.component";
import { CustomColorPickerComponent } from "./custom-color-picker/custom-color-picker.component";
import { ColorPickerModule } from "ngx-color-picker";
import { CustomColorRenderComponent } from "./custom-color-picker/custom-color-render.component";
import { SimpleOnlyTableComponent } from "./table/only-table/simple-only-table/simple-only-table.component";
import { CustomNumberInputComponent } from "./custom-number-input/custom-number-input.component";
import { CustomNumberRenderComponent } from "./custom-number-input/custom-number-renderer.component";
import { EditableOnlyTableComponent } from "./table/only-table/editable-only-table/editable-only-table.component";
import { EditableCellComponent } from "./table/only-table/editable-only-table/editable-cell/editable-cell.component";
import { EditableColorCellComponent } from "./table/only-table/editable-only-table/editable-color-cell/editable-color-cell.component";
import { TasksEmailDialogComponent } from "./tasks-email-dialog/tasks-email-dialog.component";
import { QuestionDialogToggleComponent } from "./question-dialog/question-dialog-toggle/question-dialog-toggle.component";
import { PayrollOnlyTableComponent } from "./table/only-table/payroll-only-table/payroll-only-table.component";
import { AgmCoreModule } from "@agm/core";
import { QuestionCareHomeComponent } from "./question-dialog/question-care-home/question-care-home.component";
import { SelectableOnlyTableComponent } from "./table/only-table/selectable-only-table/selectable-only-table.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { QuestionContractsComponent } from "./question-dialog/question-contracts/question-contracts.component";
import { CustomSelectCourseInputComponent } from "./custom-select-course-input/custom-select-course-input.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { QuestionDialogCheckboxComponent } from "./question-dialog/question-dialog-checkbox/question-dialog-checkbox.component";
import { FilterSaveDialogComponent } from "./table/filter-save-dialog/filter-save-dialog.component";
import { CustomSelectUrgencyInputComponent } from "./custom-select-urgency-input/custom-select-urgency-input.component";
import { CustomSelectResidentInputComponent } from "./custom-select-resident-input/custom-select-resident-input.component";
import { SubtaskEditorComponent } from "./subtask-editor/subtask-editor.component";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";
import { SubtaskEditableTitleComponent } from "./subtask-editor/subtask-editable-title/subtask-editable-title.component";
import { ProgressBarComponent } from "./progress-bar/progress-bar.component";
import { EditableSelectCellComponent } from "./table/only-table/editable-only-table/editable-select-cell/editable-select-cell.component";
import { SubtasksService } from "./subtask-editor/subtask.service";
import { SubtaskEditorFormComponent } from "./subtask-editor/subtask-editor-form.component";
import { MultipleAutocompleteSelectorWithCheckboxAndAddComponent } from "./multiple-autocomplete-selector-with-checkbox-and-add/multiple-autocomplete-selector-with-checkbox-and-add.component";
import { TabComponent, TabsetComponent } from "./tabset/tabset.component";
import { SubtaskEditableTodoComponent } from "./subtask-editor/subtask-editable-todo/subtask-editable-todo.component";
import { SubtaskEditableTodoFormComponent } from "./subtask-editor/subtask-editable-todo/subtask-editable-todo-form/subtask-editable-todo-form.component";
import { HistoryTableComponent } from "./table/only-table/history-table/history-table.component";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { EditableMultiSelectCellComponent } from "./table/only-table/editable-only-table/editable-multi-select-cell/editable-multi-select-cell.component";
import { TableComponent } from "./table/table/table.component";
import { PastTasksTableComponent } from "./table/past-tasks-table/past-tasks-table.component";
import { EditableBlankTableComponent } from "./table/editable-blank-table/editable-blank-table.component";
import { BlankTableComponent } from "./table/blank-table/blank-table.component";
import { TaskHistoryOnlyTableComponent } from "./table/only-table/task-history-table/task-history-only-table.component";
import { HoursCellSelectorComponent } from "./hours-cell-selector/hours-cell-selector.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { AutocompleteSelectorWithAddComponent } from "./autocomplete-selector-with-add/autocomplete-selector-with-add.component";
import { ImageViewerComponent } from "./image-viewer/image-viewer.component";
import { MdePopoverModule } from "@material-extended/mde";
import { CustomLabelWithInfoComponent } from "./custom-label-with-info/custom-label-with-info.component";
import { ThinnerProgressBarComponent } from "./thinner-progress-bar/thinner-progress-bar.component";
import { HistoryTableWithPaginationComponent } from "./table/only-table/history-table-with-pagination/history-table-with-pagination.component";
import { InboxTableComponent } from "./table/inbox-table/inbox-table.component";
import { QuestionDialogTaskPauseDialogComponent } from "./question-dialog/question-dialog-task-pause/question-dialog-task-pause.component";
import { AwardsOnlyTableComponent } from "./table/only-table/awards-only-table/awards-only-table.component";
import { WagesPeriodComponent } from "./wages-period/components/wages-period.component";
import { FlashLoaderComponent } from "./flash-loader/flash-loader.component";
import { UiTableEditableHoursCellComponent } from "./table/only-table/ui-table-editable-hours-cell/ui-table-editable-hours-cell.component";
import { UiTableGpsCellComponent } from "./table/only-table/ui-table-gps-cell/ui-table-gps-cell.component";
import { UiTablePhotoCellComponent } from "./table/only-table/ui-table-photo-cell/ui-table-photo-cell.component";
import { UiAgenciesListComponent } from "./ui-agencies-list/ui-agencies-list.component";
import { AgencyReportOnlyTableComponent } from "./table/only-table/agency-report-only-table/agency-report-only-table.component";
import { TasksEmailListDialogComponent } from "./tasks-email-list-dialog/tasks-email-list-dialog.component";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { MatSortModule } from "@angular/material/sort";
import { Angular2SmartTableModule } from "angular2-smart-table";
import { AddSubtaskAdvancedViewDialogComponent } from "./add-subtask-advanced-view-dialog/add-subtask-advanced-view-dialog.component";
import { MatDividerModule } from "@angular/material/divider";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TaskListSimpleTableComponent } from "../task-management/task-list-simple-table/task-list-simple-table.component";
import { NgxSuperCroppieModule } from "ngx-super-croppie";
import {
  EventsTableComponent,
  FsIconComponent,
} from "./events-table/events-table.component";
import { NbTableModule, NbTreeGridModule } from "@nebular/theme";
import { QuestionCompareCourses } from "./question-dialog/question-compare-courses/question-compare-courses.component";
import {ResolveClashComponent} from "./question-dialog/question-compare-courses/resolve-clash/resolve-clash.component";

@NgModule({
  imports: [
    ThemeModule,
    Angular2SmartTableModule,
    MatTableModule,
    MatCheckboxModule,
    MatSortModule,
    MatDatepickerModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatInputModule,
    DragDropModule,
    ScrollingModule,
    AutosizeModule,
    ColorPickerModule,
    MatPaginatorModule,
    MatMenuModule,
    MatCardModule,
    OverlayModule,
    AgmCoreModule,
    NgOptionHighlightModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDv10hlYBxNaUhNFr0HaTaCNQo0emvs50g",
      libraries: ["places"],
    }),
    MatDividerModule,
    MatTooltipModule,
    NgxSuperCroppieModule,
    MdePopoverModule,
    NbTableModule,
    NbTreeGridModule,
    ResolveClashComponent,
  ],
  declarations: [
    TableComponent,
    BlankTableComponent,
    EditableBlankTableComponent,
    ConfirmPasswordInputComponent,
    SwitcherComponent,
    QuestionDialogComponent,
    QuestionDialogToggleComponent,
    QuestionCareHomeComponent,
    QuestionContractsComponent,
    TasksEmailDialogComponent,
    FilterSaveDialogComponent,
    QuestionDialogParameterComponent,
    CustomSelectResidentInputComponent,
    CustomSelectUrgencyInputComponent,
    DateFilerComponent,
    CancelEventDialogComponent,
    OnlyTableComponent,
    HistoryTableComponent,
    HistoryTableWithPaginationComponent,
    EditableColumnComponent,
    MultipleAutocompleteSelectorComponent,
    MonthFilterComponent,
    CustomCheckboxInputComponent,
    CustomDateInputComponent,
    CustomColorPickerComponent,
    CustomNumberInputComponent,
    CustomColorRenderComponent,
    CustomNumberRenderComponent,
    AddWatchersDialogComponent,
    TaskboardTableComponent,
    TaskboardOnlyTableWithStatsComponent,
    TaskboardOnlyTableComponent,
    FileUploadComponent,
    HorizontalStickyOnlyTableComponent,
    DayFilterComponent,
    HorizontalStickyProfilesPermissionsTableComponent,
    ThinnerSwitcherComponent,
    PermissionSwitcherComponent,
    EmployeeFileUploadComponent,
    ChooseFileCategoryDialogComponent,
    MultipleAutocompleteSelectorWithCheckboxComponent,
    SimpleOnlyTableComponent,
    EditableOnlyTableComponent,
    EditableCellComponent,
    EditableColorCellComponent,
    PayrollOnlyTableComponent,
    SelectableOnlyTableComponent,
    CustomSelectCourseInputComponent,
    QuestionDialogCheckboxComponent,
    SubtaskEditorComponent,
    SubtaskEditableTitleComponent,
    SubtaskEditableTodoComponent,
    SubtaskEditableTodoFormComponent,
    SubtaskEditorFormComponent,
    ProgressBarComponent,
    ThinnerProgressBarComponent,
    EditableSelectCellComponent,
    EditableMultiSelectCellComponent,
    MultipleAutocompleteSelectorWithCheckboxAndAddComponent,
    TabsetComponent,
    TabComponent,
    CheckboxComponent,
    PastTasksTableComponent,
    TaskHistoryOnlyTableComponent,
    HoursCellSelectorComponent,
    AutocompleteSelectorWithAddComponent,
    ImageViewerComponent,
    CustomLabelWithInfoComponent,
    InboxTableComponent,
    QuestionDialogTaskPauseDialogComponent,
    AwardsOnlyTableComponent,
    WagesPeriodComponent,
    FlashLoaderComponent,
    UiTableEditableHoursCellComponent,
    UiTableGpsCellComponent,
    UiTablePhotoCellComponent,
    UiAgenciesListComponent,
    AgencyReportOnlyTableComponent,
    TasksEmailListDialogComponent,
    AddSubtaskAdvancedViewDialogComponent,
    TaskListSimpleTableComponent,
    EventsTableComponent,
    FsIconComponent,
    QuestionCompareCourses,
  ],
  exports: [
    TableComponent,
    BlankTableComponent,
    EditableBlankTableComponent,
    ConfirmPasswordInputComponent,
    SwitcherComponent,
    QuestionDialogComponent,
    QuestionDialogToggleComponent,
    QuestionCareHomeComponent,
    QuestionContractsComponent,
    TasksEmailDialogComponent,
    FilterSaveDialogComponent,
    QuestionDialogParameterComponent,
    CustomSelectResidentInputComponent,
    CustomSelectUrgencyInputComponent,
    CustomDateInputComponent,
    CustomColorPickerComponent,
    CustomNumberInputComponent,
    DateFilerComponent,
    CancelEventDialogComponent,
    OnlyTableComponent,
    HistoryTableComponent,
    HistoryTableWithPaginationComponent,
    EditableColumnComponent,
    MultipleAutocompleteSelectorComponent,
    MonthFilterComponent,
    CustomCheckboxInputComponent,
    AddWatchersDialogComponent,
    TaskboardTableComponent,
    TaskboardOnlyTableWithStatsComponent,
    TaskboardOnlyTableComponent,
    FileUploadComponent,
    HorizontalStickyOnlyTableComponent,
    DayFilterComponent,
    HorizontalStickyProfilesPermissionsTableComponent,
    ThinnerSwitcherComponent,
    PermissionSwitcherComponent,
    EmployeeFileUploadComponent,
    ChooseFileCategoryDialogComponent,
    MultipleAutocompleteSelectorWithCheckboxComponent,
    SimpleOnlyTableComponent,
    EditableOnlyTableComponent,
    EditableCellComponent,
    EditableColorCellComponent,
    PayrollOnlyTableComponent,
    SelectableOnlyTableComponent,
    // AgmCoreModule,
    CustomSelectCourseInputComponent,
    QuestionDialogCheckboxComponent,
    MatPaginatorModule,
    SubtaskEditorComponent,
    SubtaskEditableTitleComponent,
    SubtaskEditableTodoComponent,
    SubtaskEditableTodoFormComponent,
    SubtaskEditorFormComponent,
    ProgressBarComponent,
    ThinnerProgressBarComponent,
    EditableSelectCellComponent,
    EditableMultiSelectCellComponent,
    MultipleAutocompleteSelectorWithCheckboxAndAddComponent,
    TabsetComponent,
    TabComponent,
    CheckboxComponent,
    PastTasksTableComponent,
    TaskHistoryOnlyTableComponent,
    HoursCellSelectorComponent,
    AutocompleteSelectorWithAddComponent,
    ImageViewerComponent,
    CustomLabelWithInfoComponent,
    InboxTableComponent,
    QuestionDialogTaskPauseDialogComponent,
    AwardsOnlyTableComponent,
    WagesPeriodComponent,
    FlashLoaderComponent,
    UiAgenciesListComponent,
    TasksEmailListDialogComponent,
    AddSubtaskAdvancedViewDialogComponent,
    TaskListSimpleTableComponent,
    EventsTableComponent,
    FsIconComponent,
    QuestionCompareCourses,
  ],
  providers: [SubtasksService],
})
export class PagesSharedModule {}
