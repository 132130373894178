import { Component, OnInit, OnDestroy, Inject, Input } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NbDialogRef } from "@nebular/theme";
import { Subscription } from "rxjs";
import { AuthUserService } from "../../../@core/data/auth-user.service";
import { InboxService } from "../../../shared/inbox/inbox.service";
import { MessagesService } from "../../../shared/messages/messages.service";
import { TasksService } from "../../../shared/tasks/tasks.service";
import { TaskBoardsStateService } from "../../../shared/tasks/task-boards/task-boards-state.service";

export enum TemplateType {
  TASK,
  MESSAGE,
}

@Component({
  selector: "ngx-choose-template",
  templateUrl: "./choose-template.component.html",
  styleUrls: ["./choose-template.component.scss"],
})
export class ChooseTemplateDialogComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() type: TemplateType = TemplateType.TASK;
  public templates = [];
  public suggestedTemplates = [];
  public selected = null;
  public selectedItemDescription = "";
  private selectedItemIndex = -1;
  public filterValue = "";
  public filteredTemplates = [];
  public title = "Choose task template";
  public idProperty = "taskId";

  constructor(
    protected ref: NbDialogRef<ChooseTemplateDialogComponent>,
    protected tasksService: TasksService,
    protected inboxService: InboxService,
    protected authUserService: AuthUserService,
    protected messagesService: MessagesService,
    protected taskboardStateService: TaskBoardsStateService,
  ) {}

  ngOnInit() {
    if (this.type == TemplateType.TASK) {
      this.tasksService.getTemplateTasks().subscribe((response: any) => {
        this.templates = response.result.list;
        this.filteredTemplates = this.templates;
        document.getElementById("filter-input").focus();
        this.suggestedTemplates =
          this.taskboardStateService.suggestedTaskTemplates.value;
      });
    } else {
      this.idProperty = "uid";
      this.title = "Choose message template";
      this.messagesService
        .getMessageTemplates(this.authUserService.getCareHomeId())
        .subscribe((response: any) => {
          this.templates = response.result.list;
          this.filteredTemplates = this.templates;
          document.getElementById("filter-input").focus();
        });
    }
  }

  chooseTemplate(item: any) {
    this.selected = this.getTempateId(item);
    this.selectedItemIndex = this.filteredTemplates.findIndex(
      (element: any) => {
        if (this.getTempateId(item) === this.getTempateId(element)) {
          return item;
        }
      },
    );
    if (this.selectedItemIndex) {
      this.selectedItemDescription =
        this.filteredTemplates[this.selectedItemIndex].description;
    }

    document.getElementById("filter-input").focus();
  }

  filterTable = (order: any) => {
    const transformedFilter = this.filterValue.trim().toLowerCase();

    const listAsFlatString = (obj): string => {
      let returnVal = "";

      Object.values(obj).forEach((val) => {
        if (typeof val !== "object") {
          returnVal = returnVal + " " + val;
        } else if (val !== null) {
          returnVal = returnVal + " " + listAsFlatString(val);
        }
      });
      return returnVal
        .trim()
        .toLowerCase()
        .replace(/ +(?= )/g, "");
    };
    return listAsFlatString(order).includes(transformedFilter);
  };

  applyExtendedFilter() {
    this.filteredTemplates = this.templates.filter(this.filterTable);
  }

  move(goDown: boolean) {
    if (goDown) {
      if (!this.selected) {
        this.selectedItemIndex = 0;
      } else {
        this.selectedItemIndex++;
      }
    } else {
      if (!this.selected) {
        this.selectedItemIndex = this.filteredTemplates.length - 1;
      } else {
        this.selectedItemIndex--;
      }
    }
    this.selected =
      this.filteredTemplates[this.selectedItemIndex] &&
      this.filteredTemplates[this.selectedItemIndex][this.idProperty];
    this.selectedItemDescription =
      this.filteredTemplates[this.selectedItemIndex] &&
      this.filteredTemplates[this.selectedItemIndex].description;
  }

  cancel() {
    this.ref.close(null);
  }

  ok() {
    this.ref.close(this.selected);
  }

  close(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.ref.close(this.selected);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public getTempateId(item: any) {
    return item[this.idProperty] || item.id;
  }
}
