import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectionStrategy,
  OnInit, ChangeDetectorRef
} from '@angular/core';
import { Subscription } from "rxjs";
import { RotaTableService } from "../../employess/rota/rota-table.service";
import * as moment from "moment";

@Component({
  selector: 'ngx-switcher',
  styleUrls: ['./switcher.component.scss'],
  templateUrl: './switcher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitcherComponent implements OnDestroy, OnInit {
  @Input() firstValue: boolean | string;
  @Input() secondValue: boolean | 'blend' | string;
  @Input() thirdValue: boolean;
  @Input() isToggle: boolean = true;
  @Input() isTask: boolean = false;
  @Input() isTriple: boolean = true;
  @Input() asBlue = false;

  @Input() firstValueLabel: string;
  @Input() secondValueLabel: string;
  @Input() thirdValueLabel: string;

  @Input() hideSecondValue: boolean = false;

  @Input() isDisabled: boolean = false;
  @Input() changeDefaultValue: boolean = false;
  @Input() value: boolean | 'blend' | string;
  @Input() cellDayName: string = '';
  @Output() valueChange = new EventEmitter<boolean | 'blend' | string>();

  public todaysDay: string = '';
  private subscription: Subscription = new Subscription();

  constructor(
    private rotaTableService: RotaTableService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.todaysDay = moment().format('dddd');
    if (this.changeDefaultValue) {
      this.changeValue();
    }

    this.rotaTableService.isConfirm$.subscribe(mode => {
      if (this.isToggle) { return; }
      this.value = mode;
      this.cdr.markForCheck();
    });

    this.rotaTableService.getSwitcherReset().subscribe((reset: string) => {
      if (reset == '' && this.isTask) {
        this.value = 'default';
        this.cdr.markForCheck();
      }
    })

    if (this.hideSecondValue) {
      this.value = this.firstValue;
      this.cdr.detectChanges();
    }
  }

  isFirstValue() {
    return this.value === this.firstValue;
  }

  isSecondValue() {
    return this.value === this.secondValue;
  }

  isThirdValue() {
    return this.value === this.thirdValue;
  }

  changeValue() {
    this.value = this.isFirstValue()
      ? this.secondValue
      : this.firstValue;

    this.valueChange.emit(this.value);
  }

  changeTripleValue(value: boolean | 'blend') {
    switch (value) {
      case this.firstValue:
        this.value = this.firstValue;
        this.valueChange.emit(this.value);
        break;
      case this.secondValue:
        this.value = this.secondValue;
        this.valueChange.emit(this.value);
        break;
      case this.thirdValue:
        this.value = this.thirdValue;
        this.valueChange.emit(this.value);
        break;
    }
  }

  ngOnDestroy() {
    this.valueChange.complete();
    this.subscription.unsubscribe();
  }
}
