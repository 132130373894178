<nb-accordion>
  <nb-accordion-item>
    <nb-accordion-item-header>
      <div class="accordion-header">
        <div>Week {{week.weekNo}}</div>
        <div class="weeks-range">{{week.weekStartDate}} - {{week.weekEndDate}}</div>
        <div *ngIf="week.scheduleWeekInfoDepList.length > 0 && amountToMigrateValue > 0" class="migrate-container">{{amountToMigrateValue}} to migrate</div>
      </div>
    </nb-accordion-item-header>
    <nb-accordion-item-body>
      <ngx-rota-migration-table-week
        (sendUpdatedData)="updateWeekScheduleData($event)"
        [employeeId]="employeeId"
        [homeId]="homeId"
        [homeName]="homeName"
        [weekDate]="week.weekStartDate"
        [weekDateEnd]="week.weekEndDate"
        [year]="week.yearNo"
        [data]="week.scheduleWeekInfoDepList"
        [weekNo]="week.weekNo"
        [careHomes]="careHomes$ | async"
      ></ngx-rota-migration-table-week>
    </nb-accordion-item-body>
  </nb-accordion-item>
</nb-accordion>
