import { Component, Input, forwardRef, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export enum CheckboxColor {
  BLUE, RED, GREEN
}

@Component({
  selector: 'ngx-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent)
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor {
  public CheckboxColor: typeof CheckboxColor = CheckboxColor;
  @ViewChild('checkboxInput', { static: false }) checkboxInput: ElementRef;
  disabled = false;
  public onChange(newVal: any) {}
  public onTouched(_?: any) {}
  public registerOnChange(fn: any): void { this.onChange = fn; }
  public registerOnTouched(fn: any): void { this.onTouched = fn; }
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  @Input() label: string = '';
  @Input() color: CheckboxColor = CheckboxColor.BLUE;
  @Input() tooltip: string;
  public val = false;

  writeValue(value: any): void {
    this.val = value;
  }

}
