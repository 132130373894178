import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  getErrorMessage,
  getValidationStatus,
  handleValidationErrorMessage,
  isFormValid
} from "../../../utilities/utils";
import { CareHomesService } from "../../../shared/care-homes/care-homes.service";
import { TableService } from "../../shared/table/table.service";
import { NbToastrService } from "@nebular/theme";
import * as moment from "moment/moment";
import { errorMessages } from '../../care-homes/care-homes-errors';
import { EmployessService } from "../../../shared/employess/employess.service";

@Component({
  selector: 'ngx-employee-migration-tool',
  templateUrl: './employee-migration-tool.component.html',
  styleUrls: ['./employee-migration-tool.component.scss']
})
export class EmployeeMigrationToolComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();
  public errorMessages = errorMessages;
  public form: FormGroup;
  public utils = { getValidationStatus, handleValidationErrorMessage, isFormValid };
  public careHomes: any = [];
  public employeeUid: string;
  public canSave = true;

  constructor(private careHomesService: CareHomesService,
              private tableService: TableService,
              private employeeService: EmployessService,
              private toastrService: NbToastrService) { }

  ngOnInit() {
    this.careHomesService.getCareHomes().subscribe((response: any) => {
      this.careHomes = response.result.careHomesList;
    });
    this.createForm();
    if (this.tableService.getValue()) {
      this.employeeUid = this.tableService.getValue().employeeUid;
      this.form.get('careHomeId').patchValue(this.tableService.getValue().careHomeId);
    }
  }

  createForm() {
    this.form = new FormGroup({
      careHomeId: new FormControl(null, [Validators.required]),
      changeDate: new FormControl(moment(), [Validators.required]),
    });
  }

  myFilter = (d: any | null): boolean => {
    let day
    if (d) {
      day = d.day();
      return day == 1;
    }
    return false
  }

  migrateEmployee() {
    this.canSave = false;
    const data = {
      careHomeId: this.form.get('careHomeId').value,
      changeDate: moment(this.form.get('changeDate').value).format('YYYY/MM/DD')
    };
    this.subscription.add(this.employeeService.migrateEmployee(this.employeeUid, data)
      .subscribe( (response: any) => {
          this.toastrService.success(response.message, 'Success');
          this.closeWindow();
        },
        (err) => {
          this.canSave = true;
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }
      )
    );
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
