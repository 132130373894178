import { Component, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  NbMenuItem,
  NbMenuService,
  NbSidebarResponsiveState,
  NbSidebarService,
  NbSidebarState,
} from "@nebular/theme";
import { Subscription, forkJoin } from "rxjs";
import { AuthUserService } from "../../@core/data/auth-user.service";
import { CurrentCareHomeService } from "../../@core/data/current-care-home.service";
import { NewMessageDialogComponent } from "../../pages/inbox/new-message-dialog/new-message-dialog.component";
import { KeyboardScrollService } from "../../shared/scroll.service";
import { ScreenshotService } from "../../shared/screenshot.service";
import { UsersService } from "../../shared/users/users.service";
import { TaskBoardsService } from "../../shared/tasks/task-boards/task-boards.service";
import { PermissionsService } from "../../@core/data/permissions.service";
import * as MenuItems from "../../pages/menu-items";
import { NavigationEnd, Router } from "@angular/router";
import { SideMenuAccordionService } from "../components/app-menu/side-menu-accordion/side-menu-accordion.service";
import { environment } from "../../../environments/environment";
import { VersionService } from "../../shared/version.service";

@Component({
  selector: "ngx-main-layout",
  styleUrls: ["./main.layout.scss"],
  templateUrl: "./main.layout.html",
})
export class MainLayoutComponent implements OnDestroy {
  private subscrtiption = new Subscription();
  private searchValue = "";

  menu: NbMenuItem[] = [];
  filteredMenu: NbMenuItem[] = [];
  filteredSettingsMenu: NbMenuItem[] = [];
  private cacheTaskBoardsMenu = [];
  private tempTaskBoard = [];

  inputValue = "";
  public isBeta = false;
  public isUpdateNeeded = false;
  public updateMessage: string;

  constructor(
    private keyboardScrollService: KeyboardScrollService,
    public currentCareHomeService: CurrentCareHomeService,
    private authUserService: AuthUserService,
    private usersService: UsersService,
    private menuService: NbMenuService,
    private router: Router,
    private taskBoardsService: TaskBoardsService,
    private permissionsService: PermissionsService,
    public screenshotService: ScreenshotService,
    private sidebarService: NbSidebarService,
    public sideMenuService: SideMenuAccordionService,
    private versionService: VersionService,
  ) {
    this.createMenu();
    this.subscrtiption.add(
      this.authUserService.userCareHomeChange$.subscribe(() => {
        setTimeout(() => {
          this.currentCareHomeService.menuSearch$.next(this.searchValue);
          this.createMenu();
        }, 1000);
      }),
    );
    this.subscrtiption.add(
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.inputValue = "";
          this.currentCareHomeService.menuSearch$.next("");
        }
      }),
    );
    this.subscrtiption.add(
      this.versionService.version$.subscribe((apiVersion: any) => {
        this.isUpdateNeeded =
          apiVersion.apiActualVersion !== environment.apiVersion;
        this.updateMessage = `Frontend: ${environment.apiVersion}, Backend: ${apiVersion.apiActualVersion}`;
      }),
    );

    this.isBeta = environment.beta;
  }

  onInput(val: string) {
    this.searchValue = val;
    this.currentCareHomeService.menuSearch$.next(val);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    setTimeout(() => {
      document.getElementById("search-in-menu").focus();
    });

    this.sideMenuService.close();
    return false;
  }

  ngOnDestroy(): void {
    this.subscrtiption.unsubscribe();
  }

  menuClick($event: any) {
    this.keyboardScrollService.setClickTarget($event.target.title);
  }

  private createMenu(pageBefore?: string, isOpenNewTab = false) {
    const userPermissions = this.usersService.getUserPermissions(
      this.authUserService.getOriginalUser().id,
      this.authUserService.getCareHomeId(),
    );
    const taskboardPermissions =
      this.taskBoardsService.getTaskBoardsWithMyAccess(
        this.authUserService.getCareHomeId(),
      );

    forkJoin([userPermissions, taskboardPermissions]).subscribe(
      (response: any) => {
        this.permissionsService.setPermissions(response);
        const _menu = [];
        const _settings = [];
        _menu.push(
          this.getMenu(
            "Reports",
            "bar-chart-outline",
            "/dashboard",
            MenuItems.keyReportsMenu,
          ),
        );
        this.menuService.addItems(
          [
            this.getMenu(
              "Reports",
              "bar-chart-outline",
              "/dashboard",
              MenuItems.keyReportsMenu,
            ),
          ],
          "leftMenu",
        );
        _menu.push(
          this.getMenu(
            "Staff Management",
            "person-outline",
            "/employess",
            MenuItems.employeesMenu,
          ),
        );
        this.menuService.addItems(
          [
            this.getMenu(
              "Staff Management",
              "person-outline",
              "/employess",
              MenuItems.employeesMenu,
            ),
          ],
          "leftMenu",
        );
        _menu.push(
          this.getMenu(
            "Residents",
            "heart-outline",
            "/residents",
            MenuItems.residentsMenu,
          ),
        );
        this.menuService.addItems(
          [
            this.getMenu(
              "Residents",
              "heart-outline",
              "/residents",
              MenuItems.residentsMenu,
            ),
          ],
          "leftMenu",
        );
        _menu.push(
          this.getTaskboardMenu(
            "Weekly update",
            "list-outline",
            "/task-management",
            MenuItems.taskManagementMenu,
          ),
        );
        this.menuService.addItems(
          [
            this.getTaskboardMenu(
              "Weekly update",
              "list-outline",
              "/task-management",
              MenuItems.taskManagementMenu,
            ),
          ],
          "leftMenu",
        );
        _menu.push(
          this.getMenu(
            "Administration",
            "home-outline",
            "/administration",
            MenuItems.administrationMenu,
          ),
        );
        this.menuService.addItems(
          [
            this.getMenu(
              "Administration",
              "home-outline",
              "/administration",
              MenuItems.administrationMenu,
            ),
          ],
          "leftMenu",
        );

        _settings.push(
          this.getMenu(
            "Settings",
            "settings-2-outline",
            "",
            MenuItems.rightMenu,
          ),
        );
        this.menuService.addItems(
          [
            this.getMenu(
              "Settings",
              "settings-2-outline",
              "",
              MenuItems.rightMenu,
            ),
          ],
          "rightMenu",
        );

        this.filteredMenu = [..._menu];
        this.filteredSettingsMenu = [..._settings];
      },
    );
  }

  getMenu(title: string, icon: string, link: string, elements: any = []) {
    const children = [];
    elements.forEach((element: any) => {
      if (!element.permission) {
        if (element.children) {
          children.push(
            this.getMenu(element.title, null, element.link, element.children),
          );
        } else {
          this.addChildren(element, children);
        }
      }

      // have multiple permissions to check
      if (Array.isArray(element.permission)) {
        let arrayResponse = false;
        for (const permission of element.permission) {
          if (this.permissionsService.haveUserPermissionTo(permission)) {
            arrayResponse = true;
          }
        }
        // have permission to it so add it
        if (element.children) {
          children.push(
            this.getMenu(element.title, null, element.link, element.children),
          );
        } else {
          this.addChildren(element, children);
        }
      } else {
        if (this.permissionsService.haveUserPermissionTo(element.permission)) {
          if (element.children) {
            children.push(
              this.getMenu(element.title, null, element.link, element.children),
            );
          } else {
            this.addChildren(element, children);
          }
        }
      }
    });
    const menu = {
      title: title,
      icon: icon,
      link: link,
      home: true,
      children: children,
    };
    return menu;
  }

  addChildren(element: any, children: any) {
    if (element.additionalCondition && element.additionalCondition == true) {
      children.push(element);
    } else {
      children.push(element);
    }
  }

  getTaskboardMenu(
    title: string,
    icon: string,
    link: string,
    elements: any = [],
  ) {
    const children = [];
    elements.forEach((element: any) => {
      if (element.taskboardPermission) {
        if (
          this.permissionsService.haveTaskboardPermissionTo(
            element.taskboardPermission,
          )
        ) {
          children.push(element);
        }
      } else if (element.permission) {
        if (this.permissionsService.haveUserPermissionTo(element.permission)) {
          children.push(element);
        }
      } else {
        children.push(element);
      }
    });
    for (const board of this.permissionsService.getAvailableTaskboards()) {
      children.push({
        title: board.boardName,
        link: `/task-management/task-boards/${board.id}`,
      });
    }

    this.tempTaskBoard = children;

    if (this.cacheTaskBoardsMenu.length === 0) {
      this.cacheTaskBoardsMenu = children;
    }

    const menu = {
      title: title,
      icon: icon,
      link: link,
      home: true,
      children: children,
    };
    return menu;
  }
}
