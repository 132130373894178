<div class="form-view" [ngClass]="{'card-mode': cardMode}">
  <main>
    <form [formGroup]="form" autocomplete="off">
      <div class="flex">
        <div class="form-control-group">
          <label class="label" for="input-training-start">Training Start:</label>
          <input nbInput
                  fullWidth
                  formControlName="trainingStart"
                  id="input-sickness-start"
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="trainingStartDatepicker"
                  minlength="3"
                  maxlength="50">
                <mat-datepicker-toggle matSuffix [for]="trainingStartDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #trainingStartDatepicker startView="month"></mat-datepicker>
          <p class="error-message" *ngIf="isDatesWarning">
            Start date cannot be greater than end date
          </p>
          <p class="error-message" *ngIf="isRotaFreezeWarning">
            Start date must be greater than rota freeze date
          </p>
        </div>
        <div class="form-control-group" style="visibility: hidden;">
          <label class="label" for="input-training-end">Training End:</label>
          <input nbInput
                  fullWidth
                  formControlName="trainingEnd"
                  id="input-training-end"
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="trainingEndDatepicker"
                  minlength="3"
                  maxlength="50">
                <mat-datepicker-toggle matSuffix [for]="trainingEndDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #trainingEndDatepicker startView="month"></mat-datepicker>
          <p class="error-message" *ngIf="isRotaFreeze && !isEditMode">
            Start and end date must be greater than Rota freeze date
          </p>
          <p class="error-message" *ngIf="isRotaFreeze && isEditMode">
            Edit and Start date must be greater than Rota freeze date
          </p>
        </div>
      </div>
      <div class="employee-training__training-budget-info">
        <ngx-flash-loader [showLoader]="isDataLoading$ | async"></ngx-flash-loader>
        <div class="employee-training__budget-table-wrapper">
          <ngx-only-table *ngIf="employeeTrainingBudget as budget" [columns]="columns" [data]="budget.trainingBudget"></ngx-only-table>
        </div>
        <div class="flex">
          <div class="form-control-group form-control-group--height-unset employee-training__budget-container">
            <label class="label" for="input-training-budget">Total training budget:</label>
            <input
              #inputTrainingBudget
              nbInput
              id="input-training-budget"
              class="employee-training__budget-input"
              placeholder="Total training budget"
              formControlName="trainingBudget"
              autofocus
              (change)="correctTrainingBudgetIfNecessary()"
            />
          </div>
        </div>
      </div>

      <div class="flex-container">
        <div class="form-control-group">
          <label class="label" for="input-description">Description:</label><label class="label obligatory" title="This field is obligatory">*</label>
          <textarea nbInput
                    fullWidth
                    formControlName="description"
                    maxlength="255"
                    placeholder="Description">
          </textarea>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'description', errorMessages) }}
          </p>
        </div>
      </div>
    </form>
  </main>
  <footer *ngIf="!cardMode">
    <button class="btn btn-success"
            [disabled]="!utils.isFormValid(form) || isRotaFreeze || isDatesWarning"
            (click)="isTrainingBudgetInEditMode ? updateTrainingBudget() : save()">
      <i class="nb-checkmark" aria-hidden="true"></i> Save
    </button>
    <button class="btn btn-danger ml-4"
            (click)="closeWindow()">
      <i class="nb-close" aria-hidden="true"></i> Close
    </button>
  </footer>
</div>
