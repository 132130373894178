export const taskErrorMessages = [
  {
    field: 'taskBoardId',
    errors: [
      {
        error: 'required',
        message: 'Taskboard is required'
      }
    ]
  },
  {
    field: 'careHomes',
    errors: [
      {
        error: 'required',
        message: 'Care Homes is required'
      }
    ]
  },
  {
    field: 'title',
    errors: [
      {
        error: 'required',
        message: 'Title is required'
      }
    ]
  },
  {
    field: 'assignedTo',
    errors: [
      {
        error: 'required',
        message: 'Assigned To is required'
      }
    ]
  },
  {
    field: 'categoryId',
    errors: [
      {
        error: 'required',
        message: 'Category is required'
      }
    ]
  },
  {
    field: 'statusId',
    errors: [
      {
        error: 'required',
        message: 'Status is required'
      }
    ]
  },
  {
    field: 'priorityId',
    errors: [
      {
        error: 'required',
        message: 'Priority is required'
      }
    ]
  },
  {
    field: 'targetCareHomeId',
    errors: [
      {
        error: 'required',
        message: 'Target care home is required'
      }
    ]
  },
  {
    field: 'targetTaskBoardId',
    errors: [
      {
        error: 'required',
        message: 'Target task board is required'
      }
    ]
  },
  {
    field: 'annualTaskStartDate',
    errors: [
      {
        error: 'required',
        message: 'Start date is required'
      }
    ]
  },
  {
    field: 'oncePerMonthDate',
    errors: [
      {
        error: 'required',
        message: 'Once per month date is required'
      }
    ]
  },
  {
    field: 'oncePerYeardate',
    errors: [
      {
        error: 'required',
        message: 'Once per year date is required'
      }
    ]
  },
  {
    field: 'dayInterval',
    errors: [
      {
        error: 'required',
        message: 'Day interval is required'
      }
    ]
  },
  {
    field: 'eventId',
    errors: [
      {
        error: 'required',
        message: 'Event is required'
      }
    ]
  },
  {
    field: 'employeeId',
    errors: [
      {
        error: 'required',
        message: 'Employee is required'
      }
    ]
  }
];
