import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ResidentsApiUrls } from './residents.enum';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class  ResidentsService {

  constructor(private httpClient: HttpClient) { }

  getResidents(present?: boolean) {
    return this.httpClient.get(`${environment.apiUrl}${ResidentsApiUrls.residents}${present ? '?present=true' : '?present=false'}`);
  }

  getResidentsWithPresences(present: boolean, blockBooking: boolean) {
    return this.httpClient.get(`${environment.apiUrl}${ResidentsApiUrls.residents}${present ? '?present=true' : '?present=false'}${blockBooking ? '&blockBooking=true' : '&blockBooking=false'}&futurePresences=true`);
  }

  getResidentsShort() {
    return this.httpClient.get(`${environment.apiUrl}${ResidentsApiUrls.residents}?present=true&form=short`);
  }

  getResident(id: number) {
    return this.httpClient.get(`${environment.apiUrl}${ResidentsApiUrls.residents}/${id}?skip-care-home`);
  }

  addLookupResident(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${ResidentsApiUrls.residents}`, data);
  }

  addResident(id: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${ResidentsApiUrls.residents}/${id}?skip-care-home`, data);
  }

  getResidentStays() {
    return this.httpClient.get(`${environment.apiUrl}${ResidentsApiUrls.residents}/stays/`);
  }

  getResidentStaysByResidentId(residentId: number) {
    return this.httpClient.get(`${environment.apiUrl}${ResidentsApiUrls.residents}/${residentId}/stays`);
  }

  getUnaggregatedResidentStays(residentId: number) {
    return this.httpClient.get(`${environment.apiUrl}${ResidentsApiUrls.residents}/${residentId}${ResidentsApiUrls.residentStaysListSkip}`);
  }

  getResidentStay(stayId: number) {
    return this.httpClient.get(`${environment.apiUrl}${ResidentsApiUrls.residents}/stays/${stayId}`);
  }

  addResidentStay(residentId: number, data: any) {
    return this.httpClient.post(`${environment.apiUrl}${ResidentsApiUrls.residents}/${residentId}${ResidentsApiUrls.residentStays}`, data);
  }

  updateResidentStay(stayId: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${ResidentsApiUrls.residents}/stays/${stayId}`, data);
  }

  deleteResident(residentId: number) {
    return this.httpClient.delete(`${environment.apiUrl}${ResidentsApiUrls.residents}/${residentId}`);
  }

  deleteResidentStay(residentId: number, stayId: number) {
    return this.httpClient.delete(`${environment.apiUrl}${ResidentsApiUrls.residents}/${residentId}/stays/${stayId}`);
  }

  createMDAObject(residentId: number, data: any) {
    return this.httpClient.post(`${environment.apiUrl}${ResidentsApiUrls.residents}/${residentId}/mda`, data);
  }

  updateMDAObject(residentId: number, mdaId: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${ResidentsApiUrls.residents}/${residentId}/mda/${mdaId}`, data);
  }

  deleteMDAObject(residentId: number, mdaId: number) {
    return this.httpClient.delete(`${environment.apiUrl}${ResidentsApiUrls.residents}/${residentId}/mda/${mdaId}`);
  }

  getAvatar(id: any): Observable<Blob> {
    return this.httpClient.get(`${environment.apiUrl}${ResidentsApiUrls.residents}/${id}/avatars`, { responseType: 'blob' });
  }

  addAvatar(id: any, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${ResidentsApiUrls.residents}/${id}/avatars`, data);
  }

  updateFinanceTask(residentId: number, taskId: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${ResidentsApiUrls.residents}/${residentId}/financeTasks/${taskId}`, data);
  }

  getFinanceReport(startDate: any, endDate: any, careHomes: any = [], xlsFormat = false, rowsFrom?: number, rowsTo?: number, orderBy?, orderDirection?, searchFilter?) {
    return this.httpClient.get(`${environment.apiUrl}${ResidentsApiUrls.residents}/${xlsFormat ? 'finance-report-xls' : 'finance-report'}?startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}&careHomes=${careHomes}${orderBy ? `&orderBy=${orderBy}` : ''}${orderDirection ? `&orderDirection=${orderDirection}` : ''}${searchFilter ? `&filter=${searchFilter}` : ''}&rowsFrom=${rowsFrom}&rowsTo=${rowsTo}`);
  }

  getResidentFiles(residentId: number) {
    return this.httpClient.get(`${environment.apiUrl}${ResidentsApiUrls.residents}/${residentId}/files`);
  }

  addResidentFile(residentId: number, fileData: FormData) {
    return this.httpClient.post(`${environment.apiUrl}${ResidentsApiUrls.residents}/${residentId}/files`, fileData);
  }

  deleteResidentFile(residentId: number, fileUid: string) {
    return this.httpClient.delete(`${environment.apiUrl}${ResidentsApiUrls.residents}/${residentId}/files/${fileUid}`);
  }

  downloadResidentFile(residentId: number, fileUid: string) {
    return this.httpClient.get(`${environment.apiUrl}${ResidentsApiUrls.residents}/${residentId}/files/${fileUid}`);
  }

  addDocumentDescription(residentId: number, fileUid: string, noteData: string) {
    return this.httpClient.put(`${environment.apiUrl}${ResidentsApiUrls.residents}/${residentId}/files/${fileUid}/documents-description`, noteData);
  }
}
