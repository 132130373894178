<div class="form-view">
  <main>
    <form [formGroup]="form" autocomplete="off" *ngIf="decisionType == 'first'">
      <div class="flex-container">
        <div class="flex-container">
          <div class="flex-container-horizontal">
            <div class="form-control-group">
              <label class="label">Employee name:</label>
              <ng-select [items]="employess" #select bindLabel="fullName" dropdownPosition="auto" bindValue="employeeId"
                         labelForId="employeeId" placeholder="Search employee" [virtualScroll]="true"
                         formControlName="employeeId">
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                  <div>
                    <span>{{item.employeeFirstName}} {{item.employeeSurname}} ({{item.employeeId}})</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div class="form-control-group">
              <label class="label" for="input-employee-id">Security level based on highest position:</label>
              <select id="input-employee-id" nbInput fullWidth formControlName="levelId" name="level-id">
                <option *ngFor="let level of securityLevels" [ngValue]="level.id">{{ level.wordShortName }}</option>
              </select>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'levelId', errorMessages) }}
              </p>
            </div>
          </div>

          <div class="flex-container-horizontal">
            <div class="form-control-group">
              <label class="label" for="input-contractStart">Role Start Date:</label>
              <input nbInput fullWidth formControlName="contractStart" name="contractStart" id="input-contractStart"
                placeholder="DD/MM/YYYY" [matDatepicker]="contractStartDatepicker" minlength="3" maxlength="50">
              <mat-datepicker-toggle matSuffix [for]="contractStartDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #contractStartDatepicker startView="month"></mat-datepicker>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'contractStart', errorMessages) }}
              </p>
            </div>
            <div class="form-control-group">
              <label class="label" for="input-positionId">Role Name:</label>
              <select id="input-positionId" formControlName="positionId">
                <option *ngFor="let role of roles" [ngValue]="role.id">{{role.roleName}}</option>
              </select>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'positionId', errorMessages) }}
              </p>
            </div>
          </div>
        </div>
        <div class="flex-container">
          <div class="flex-container-horizontal container-width">
            <div class="form-control-group" [ngClass]="{'shorter-field': this.permissions.FINANCE_MANAGER}">
              <label class="label" for="input-hoursPerWeek">Hrs p/w:</label>
              <input nbInput fullWidth formControlName="hoursPerWeek" class="long-width" name="hoursPerWeek" id="input-hoursPerWeek"
                placeholder="Hrs p/w" minlength="1" maxlength="50"
                (focusout)="utils.onHoursInput($event, form, 'hoursPerWeek')" autofocus>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'hoursPerWeek', errorMessages) }}
              </p>
            </div>
            <div *ngIf="this.permissions.FINANCE_MANAGER" class="flex-container-horizontal">
              <div class="flex-container">
                <div class="form-control-group shorter-field">
                  <label class="label">Base rate of pay (hpw):</label>
                  <input nbInput fullWidth formControlName="baseRateOfPay" class="short-width" placeholder="base rate" minlength="1"
                    maxlength="50" autofocus>
                  <p class="error-message">
                    {{ utils.handleValidationErrorMessage(form, 'baseRateOfPay', errorMessages) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-container-horizontal container-width">
            <div class="form-control-group shorter-field">
              <label class="label" for="input-contractTypeId">Contract Type:</label>
              <select id="input-contractTypeId" formControlName="contractTypeId">
                <option *ngFor="let contractType of contractTypes" [ngValue]="contractType.id">
                  {{contractType.wordFullName}}</option>
              </select>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'contractTypeId', errorMessages) }}
              </p>
            </div>
            <div class="flex-container-horizontal">
              <div class="flex-container">
                <div class="form-control-group shorter-field al-days-width">
                  <label class="label">AL days per year:</label>
                  <input nbInput fullWidth formControlName="numberOfAlDays" placeholder="al days" minlength="1"
                    maxlength="50" autofocus>
                  <p class="error-message">
                    {{ utils.handleValidationErrorMessage(form, 'baseRateOfPay', errorMessages) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-container"
        *ngIf="form.get('contractTypeId').value === ContractType.TEMP_R || form.get('contractTypeId').value === ContractType.TEMP_T">
        <div class="form-control-group">
          <label class="label" for="input-contract-end">Role End Date:</label>
          <input nbInput fullWidth formControlName="contractEnd" name="input-contract-end" id="input-contract-end"
            placeholder="DD/MM/YYYY" [matDatepicker]="contractEndDatepicker" minlength="3" maxlength="50">
          <mat-datepicker-toggle matSuffix [for]="contractEndDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #contractEndDatepicker startView="month"></mat-datepicker>
          <p class="error-message">
            {{isContractEndBeforeStart() ? 'Cannot be earlier than role start date': ''}}
          </p>
        </div>
      </div>

      <ng-template [ngIf]="employeeContracts.length > 0">
        <form [formGroup]="tableForm">
          <div class="form-control-group table-container">
            <p *ngIf="decisionType == 'first'">Decide which contract will be canceled when you add this new contract:</p>
            <p *ngIf="decisionType == 'second'">Choose to which contract upload a new document</p>
            <table>
              <thead>
                <tr>
                  <th *ngFor="let column of columns">{{ column }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let contract of employeeContracts; let index = index">
                  <td>{{ index+1 }}</td>
                  <td>{{ contract.contractNo }}</td>
                  <td>{{ contract.contractRole }}</td>
                  <td>{{ contract.contractType }}</td>
                  <td>{{ contract.contractStart }}</td>
                  <td>{{ contract.contractEnd }}</td>
                  <td>{{ contract.contractHoursPerWeek }}</td>
                  <td>{{ contract.contracStatus }}</td>
                  <td class="check">
                    <input id="male" type="radio" value="true" [formControlName]="'contract-' + contract.contractId">
                    <label class="custom-control-label mr-2" for="male">YES</label>
                    <input id="female" type="radio" value="false" [formControlName]="'contract-' + contract.contractId">
                    <label class="custom-control-label" for="female">NO</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </ng-template>
    </form>
    <table class="contracts-list" *ngIf="decisionType == 'second'">
      <thead>
      <tr>
        <th *ngFor="let column of columns">{{ column }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let contract of allEmployeeContracts; let index = index">
        <td>{{ index+1 }}</td>
        <td>{{ contract.contractNo }}</td>
        <td>{{ contract.contractRole }}</td>
        <td>{{ contract.contractType }}</td>
        <td>{{ contract.contractStart }}</td>
        <td>{{ contract.contractEnd }}</td>
        <td>{{ contract.contractHoursPerWeek }}</td>
        <td>{{ contract.contracStatus }}</td>
        <td class="upload-btn">
          <button class="btn btn-success" (click)="uploadToContract.emit(contract.contractId)">
            Upload
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </main>
  <footer>
    <button class="btn btn-success" (click)="saveData()" *ngIf="decisionType == 'first'"
      [disabled]="!utils.isFormValid(form) || !utils.isFormValid(tableForm) || !isContractEndValid() || inProgress">
      <i class="nb-checkmark" aria-hidden="true"></i> Next
    </button>
<!--    <button class="btn btn-info" (click)="changeToNewContract()" [style.width]="'max-content'" *ngIf="decisionType == 'second'">-->
<!--      Add New Contract-->
<!--    </button>-->
  </footer>
</div>
