import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { TableService } from '../../../shared/table/table.service';
import * as moment from 'moment';
import { AuthUserService } from '../../../../@core/data/auth-user.service';
import { MatStepper } from '@angular/material/stepper';
import { pluck } from 'rxjs/operators';
import { getErrorMessage } from '../../../../utilities/utils';
import { ContractsService } from '../../../../shared/employess/contracts/contracts.service';
import { Subscription } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { DictionariesService } from '../../../../shared/dictionaries/dictionaries.service';
import { RotaMigrationService } from '../rota-migration.service';
import { CareHomesService } from '../../../../shared/care-homes/care-homes.service';
import { Router } from "@angular/router";
import { EmployessService } from "../../../../shared/employess/employess.service";

@Component({
  selector: 'ngx-new-documents',
  templateUrl: './new-documents.component.html',
  styleUrls: ['./new-documents.component.scss']
})
export class NewDocumentsComponent implements AfterViewInit, OnDestroy {

  @ViewChild('stepper', { static: false }) private stepper: MatStepper;

  isAtStart: boolean;
  isAtEnd: boolean;

  public contractTypes: any;
  public contractRoles: any;

  public files: any = [];
  public addNewContract: boolean = false;
  public newContractAdded: boolean = false;
  public categoryToAssign: any;
  public employeeId: any;
  public securityLevelId: any;
  public displayedColumns: string[] = [' ', 'No.', 'Name', 'File extension', 'File size'];

  private subscription: Subscription = new Subscription();
  notRota: boolean = true;
  decisionType: 'first' | 'second' = 'first';
  activeOnly: boolean = true;
  private contracts: any;

  constructor(
    private router: Router,
    private tableService: TableService,
    private authUserService: AuthUserService,
    private toastrService: NbToastrService,
    private dictionariesService: DictionariesService,
    private contractsService: ContractsService,
    private rotaMigrationService: RotaMigrationService,
    private careHomeService: CareHomesService,
    private employeeService: EmployessService
  ) {
    this.subscription.add(this.dictionariesService.getContractTypes()
      .subscribe((response: any) => {
        this.contractTypes = response.result.wordsFromDictionary;
      }));
    this.subscription.add(this.dictionariesService.getProfessionalRoles()
      .subscribe((response: any) => {
        this.contractRoles = response.result;
        this.rotaMigrationService.roles = response.result;
      }));
    this.subscription.add(this.careHomeService.getCareHomes()
      .subscribe((response: any) => {
        this.rotaMigrationService.homes = response.result.careHomesList;
      }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.notRota = this.tableService.getValue().notRota;
    if (!this.notRota) {
      this.newContractAdded = true;
      this.isAtEnd = true;
    }
    if (this.tableService.getValue() && this.tableService.getValue().documentToAssign) {
      const file = this.tableService.getValue().documentToAssign;
      this.uploadFile(file);
      this.categoryToAssign = Object.assign({});
      this.categoryToAssign['id'] = file.categoryId;
    }


    this.stepper.selectionChange
      .pipe(pluck('selectedIndex'))
      .subscribe((res: number) => {
        this.isAtEnd = res == 2;
        switch (res) {
          case 0:
            this.tableService.changeWindowName('Add Document');
            break;
          case 1:
            let title = this.decisionType == 'first' ? 'New Contract' : 'Assign document to an existing contract'
            this.tableService.changeWindowName(title);
            break;
          case 2:
            this.tableService.changeWindowName('Migration System for Shifts');
            break;
        }
      });

    if (this.decisionType == 'second') {
      this.getContracts();
    }
  }

  uploadFile(file: any) {
    file.createdAt = moment().format('DD/MM/YYYY HH:mm');
    file.createdBy = this.authUserService.getUser().name;
    this.files.push(file);
    this.files = [...this.files];
  }

  deleteFile(fileUId: unknown) {
    this.files.splice(fileUId, 1);
  }

  deleteMultiFile(fileUId: unknown) {
    this.files = this.files.filter(file => file.uid != fileUId);
  }

  getContracts() {
      return this.subscription.add(
        this.employeeService
          .getUnaggregatedContractsWithoutCareHome(
            this.employeeId,
            this.activeOnly
          )
          .subscribe((response: any) => {
            this.contracts = response.result.contractsLines;
          })
      );
  }

  saveContractAndGoNext($event: any) {

    const role = this.contractRoles.filter((item: any) => $event.roleId == item.id)[0].roleName;
    const type = this.contractTypes.filter((item: any) => $event.typeId == item.id)[0].wordShortName;

    let filesCount = this.files.length;

    this.files.forEach(file => {
      const fd = new FormData();
      fd.append('document', file);
      fd.append('employeeId', this.employeeId.toString());
      fd.append('careHomeId', this.authUserService.getCareHomeId().toString());
      fd.append('documentCategoryId', this.categoryToAssign.id);
      fd.append('notes', `${role} - ${type}`);
      this.subscription.add(this.contractsService.addContractDocuments($event.id, fd)
        .subscribe((response: any) => {
            this.toastrService.success(response.message, 'Success');
            filesCount--;
            if (filesCount == 0) {
              this.newContractAdded = true;
              setTimeout(() => {
                this.stepper.next();
              }, 500);
            }
          },
          (err) => {
            this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
          }
        ));
    });
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  finished() {
    this.tableService.closeWindowClearAndRedirectTo(this.router.url);
  }

  uploadFilesToContract(contractId: number) {
    let filesCount = this.files.length;

    this.files.forEach(file => {
      const fd = new FormData();
      fd.append('document', file);
      fd.append('employeeId', this.employeeId.toString());
      fd.append('careHomeId', this.authUserService.getCareHomeId().toString());
      fd.append('documentCategoryId', this.categoryToAssign.id);
      // fd.append('notes', `${role} - ${type}`);
      this.subscription.add(this.contractsService.addContractDocuments(contractId, fd)
        .subscribe((response: any) => {
            this.toastrService.success(`File \"${file.name}\" uploaded`, `Success`);
            filesCount--;
            if (filesCount == 0) {
              if (this.files.length > 1) {
                this.toastrService.success('All files uploaded successfully', 'Success');
              }
              setTimeout(() => {
                this.closeWindow()
              }, 1000);
            }
          },
          (err) => {
            this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
          }
        ));
    });
  }

  decisionTypeChange($event: "first" | "second") {
    this.decisionType = $event;
    let title = this.decisionType == 'first' ? 'New Contract' : 'Assign document to an existing contract'
    this.tableService.changeWindowName(title);
  }
}
