import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PharmaciesApiUrls } from './pharmacies.enum';

@Injectable()
export class PharmaciesService {
  constructor(private httpClient: HttpClient) {}

  getPharmacies(careHomeId: number) {
    return this.httpClient.get(`${environment.apiUrl}${PharmaciesApiUrls.pharmacies}?careHomeId=${careHomeId}`);
  }

  getPharmacy(id: number) {
    return this.httpClient.get(`${environment.apiUrl}${PharmaciesApiUrls.pharmacies}/${id}`);
  }

  addPharmacy(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${PharmaciesApiUrls.pharmacies}`, data);
  }

  updatePharmacy(id: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${PharmaciesApiUrls.pharmacies}/${id}`, data);
  }

}
