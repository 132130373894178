<div class="form-view" [ngClass]="{'card-mode': cardMode}">
  <main>
    <form [formGroup]="form" autocomplete="off">
      <div class="form-control-group">
        <label class="label">Reason for leaving:</label><label class="label obligatory" title="This field is obligatory">*</label>
        <textarea nbInput
            fullWidth
            formControlName="reasonForLeaving"
            placeholder="reason for leaving"
            minlength="1"
            maxlength="500"
            rows="2"
            autofocus
            style="resize: none;">
          </textarea>
        <p class="error-message">
          {{ utils.handleValidationErrorMessage(form, 'reasonForLeaving', errorMessages) }}
        </p>
      </div>
      <nb-accordion>
        <nb-accordion-item>
          <nb-accordion-item-header>
            AL settlement&nbsp;<u>(click to expand)</u>: {{settlementCalculations}}
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <table>
              <thead>
                <th>Contract number</th><th>Role</th><th>Start date</th><th>End date</th><th> AL Remaining<br>(today)</th><th>AL Remaining<br>(on 'date left')</th><th>AL taken</th>
              </thead>
              <tbody>
                <ng-container *ngFor="let settlement of settlementList">
                  <tr>
                    <td>{{settlement.contractNo}}</td>
                    <td>{{settlement.contractRole}}</td>
                    <td>{{settlement.contractStartDate}}</td>
                    <td>{{settlement.contractEndDate}}</td>
                    <td>{{settlement.alCalculation}}</td>
                    <td>{{settlement.alMaxHrs}}</td>
                    <td>{{settlement.usedHrs}}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
      <section *ngIf="formCreated">
        <div class="flex-container-horizontal mt-4">
          <div class="flex-container">
            <div class="flex-container-horizontal">
              <div class="form-control-group">
                <label class="label">Notice given on what date?</label><label class="label obligatory" title="This field is obligatory">*</label>
                <input nbInput
                  fullWidth
                  formControlName="noticeDate"
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="noticeDate"
                  minlength="3"
                  maxlength="50">
                <mat-datepicker-toggle matSuffix [for]="noticeDate"></mat-datepicker-toggle>
                <mat-datepicker #noticeDate startView="month"></mat-datepicker>
                <p class="error-message">
                  {{ utils.handleValidationErrorMessageForGeneratedFields(form, 'noticeDate', 'noticeDate', errorMessages) }}
                </p>
              </div>
              <div class="form-control-group">
                <label class="label">Days to end contract:</label><label class="label obligatory" title="This field is obligatory">*</label>
                <input nbInput
                  fullWidth
                  formControlName="procesDaysToEndContract"
                  placeholder="DD"
                  minlength="1"
                  maxlength="5"
                  type="number">
              </div>
            </div>
          </div>

          <div class="flex-container">
            <div class="flex-container-horizontal">
              <div class="form-control-group">
                <label class="label">Date left:</label><label class="label obligatory" title="This field is obligatory">*</label>
                <input nbInput
                  fullWidth
                  formControlName="dateLeft"
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="dateLeft"
                  minlength="3"
                  maxlength="50">
                <mat-datepicker-toggle matSuffix [for]="dateLeft"></mat-datepicker-toggle>
                <mat-datepicker #dateLeft startView="month"></mat-datepicker>
              </div>
              <div class="form-control-group">
                <label class="label">Has a payment in lieu been agreed:</label><label class="label obligatory" title="This field is obligatory">*</label>
                <input nbInput
                        fullWidth
                        formControlName="paymentInLieu"
                        placeholder="Has a payment in lieu been agreed"
                        minlength="1"
                        maxlength="50"
                        autofocus>
              </div>
            </div>
          </div>
          
        </div>
        <div class="flex-container-horizontal mt-1">
          <div class="flex-container">
            <div class="flex-container-horizontal">
              <div class="form-control-group">
                <label class="label">Uniform returned:</label><label class="label obligatory" title="This field is obligatory">*</label>
                <input nbInput
                        fullWidth
                        formControlName="uniformReturned"
                        placeholder="Uniform returned"
                        minlength="1"
                        maxlength="50"
                        autofocus>
              </div>
              <div class="form-control-group">
                <label class="label">Any other notes on their exit for Finance:</label><label class="label obligatory" title="This field is obligatory">*</label>
                <input nbInput
                      fullWidth
                      formControlName="otherNotes"
                      placeholder="Any other notes on their exit for Finance"
                      minlength="1"
                      maxlength="50"
                      autofocus>
                <p class="error-message">
                    {{ utils.handleValidationErrorMessageForGeneratedFields(form, 'otherNotes', 'otherNotes', errorMessages) }}
                </p>
              </div>
            </div>
          </div>
          
        </div>
      </section>
    </form>
  </main>
  <footer *ngIf="!cardMode">
    <button class="btn btn-success"
            (click)="save()">
      <i class="nb-checkmark" aria-hidden="true"></i> Save
    </button>
    <button class="btn btn-danger ml-4"
            (click)="closeWindow()">
      <i class="nb-close" aria-hidden="true"></i> Close
    </button>
  </footer>
</div>