<nb-layout windowMode>
  <!--  <nb-layout-header class="fullWidth main__header">-->
  <!--    <ngx-header></ngx-header>-->
  <!--  </nb-layout-header>-->

  <nb-sidebar
    class="menu-sidebar my-menu"
    tag="menu-sidebar"
    responsive
    (click)="menuClick($event)"
  >
    <nb-sidebar-header>
      <div class="beta" *ngIf="isBeta">BETA</div>

      <div
        class="menu-header"
        *ngIf="!(this.sideMenuService.settingsOpened | async); else back"
        (click)="toggleSidebar()"
      >
        <nb-icon icon="menu-2-outline"></nb-icon>
        <div class="text">Menu</div>
      </div>
      <ng-template #back>
        <div
          class="menu-header"
          (click)="this.sideMenuService.settingsOpened.next(false)"
        >
          <nb-icon icon="arrow-back-outline"></nb-icon>
          <div class="text">Back</div>
        </div>
      </ng-template>
    </nb-sidebar-header>
    <ngx-app-menu
      *ngIf="filteredMenu.length"
      [items]="filteredMenu"
      [settingsMenu]="filteredSettingsMenu"
    ></ngx-app-menu>
  </nb-sidebar>
  <nb-layout-column
    id="application-main-content"
    #appMainContent
    class="main-content"
  >
    <p class="update-message" [nbTooltip]="updateMessage" [nbTooltipPlacement]="'bottom'" *ngIf="isUpdateNeeded">
      <span class="big-message"
        >The new version of Echo is available. (to upload press CTRL +
        F5).</span
      >
    </p>
    <div class="snapshot-info" *ngIf="screenshotService.showScreenshotInfo">
      Taking snapshot...
    </div>
    <ng-content select="router-outlet"></ng-content>
    <side-menu-collapsed-popup></side-menu-collapsed-popup>
    <side-menu-collapsed-popup [isSubMenu]="true"></side-menu-collapsed-popup>
  </nb-layout-column>

  <nb-layout-footer fixed>
    <ngx-footer></ngx-footer>
  </nb-layout-footer>

  <nb-sidebar
    [style.box-shadow]="'unset'"
    class="settings-sidebar"
    tag="settings-sidebar"
    state="collapsed"
    end
  >
    <ng-content select="[tag=rightMenu]"></ng-content>
  </nb-sidebar>
</nb-layout>
