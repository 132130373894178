import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CareHomesService } from '../../../../shared/care-homes/care-homes.service';
import { NbToastrService } from '@nebular/theme';
import { getErrorMessage, handleValidationErrorMessage } from '../../../../utilities/utils';
import { Subscription } from 'rxjs';
import { TableService } from '../../../shared/table/table.service';
import { TasksService } from '../../../../shared/tasks/tasks.service';
import { TaskBoardsService } from '../../../../shared/tasks/task-boards/task-boards.service';
import { taskErrorMessages } from '../task-errors';

export enum CopyMode {
  COPY, MOVE
}
@Component({
  selector: 'ngx-copy-task',
  templateUrl: './copy-task.component.html',
  styleUrls: ['./copy-task.component.scss']
})
export class CopyTaskComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public CopyMode: typeof CopyMode = CopyMode;
  public form: UntypedFormGroup;
  public errorMessages = taskErrorMessages;
  public utils = { handleValidationErrorMessage };
  public taskId: number = -1;
  public taskList: number[];
  public careHomes = [];
  public taskboards = [];
  public mode: CopyMode = CopyMode.COPY;
  public saveClicked: boolean = false;
  showSpinner = false;

  constructor(private tableService: TableService,
    private tasksService: TasksService,
    private taskBoardsService: TaskBoardsService,
    private careHomesService: CareHomesService,
    private toastrService: NbToastrService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.subscription.add(this.careHomesService.getCareHomes()
      .subscribe((response: any) => {
        this.careHomes = response.result.careHomesList;
      }
      ));
    this.createForm();
    this.taskId = this.tableService.getValue().taskId;
    this.taskList = this.tableService.getValue().taskList;
    this.mode = this.tableService.getValue().mode;
  }

  createForm() {
    this.form = new UntypedFormGroup({
      targetCareHomeId: new UntypedFormControl(null, [Validators.required]),
      targetTaskBoardId: new UntypedFormControl(null, [Validators.required]),
      withHistory: new UntypedFormControl(false)
    });

    this.form.get('targetCareHomeId').valueChanges
      .subscribe((value: any) => {
        this.taskboards = [];
        this.form.get('targetTaskBoardId').setValue(null);
        this.subscription.add(this.taskBoardsService.getTaskBoardsWithMyAccess(value)
          .subscribe((response: any) => {
            this.taskboards = response.result.taskBoard.taskBoardList;
          }));
      });
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  isFormValid(form: UntypedFormGroup) {
    return form.valid;
  }

  saveData() {
    this.saveClicked = true;
    this.showSpinner = true;
    this.cdr.detectChanges();
    const data = {
      targetCareHomeId: this.form.get('targetCareHomeId').value,
      targetTaskBoardId: this.form.get('targetTaskBoardId').value,
      withHistory: this.form.get('withHistory').value,
    };
    if (this.taskId && this.taskId > 0) {
      if (this.mode == CopyMode.COPY) {
        this.subscription.add(
          this.tasksService.copyTask(this.taskId, data)
            .subscribe((response: any) => {
              this.toastrService.success(response.message, 'Success');
              this.tableService.closeWindow(true);
              this.showSpinner = false;
              this.cdr.detectChanges();
            },
              (err) => {
                this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
                this.showSpinner = false;
                this.cdr.detectChanges();
              }
            )
        );
      } else {
        this.subscription.add(
          this.tasksService.moveTask(this.taskId, data)
            .subscribe((response: any) => {
              this.toastrService.success(response.message, 'Success');
              this.tableService.closeWindow(true);
            },
              (err) => {
                this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
              }
            )
        );
      }
    } else {
      this.sendTaskListToCareHome(data);
    }

    setTimeout(() => {
      this.saveClicked = false;
    }, 1500);

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private sendTaskListToCareHome(careHomeData: any) {
    careHomeData.taskList = this.taskList;
    if (this.mode == CopyMode.COPY) {
      this.subscription.add(this.tasksService.copyMultipleTask(careHomeData).subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        this.tableService.closeWindow(true);
      },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }
      ));
    } else {
      this.subscription.add(this.tasksService.moveMultipleTasks(careHomeData).subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        this.tableService.closeWindow(true);
      },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }
      ));
    }
  }
}
