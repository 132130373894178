<div class="form-view">
  <main>
    <form [formGroup]="form" autocomplete="off">
      <div class="flex-container-horizontal">
        <div class="form-control-group search-dropdown">
          <label class="label" for="employeeId">Employee</label>
          <ng-select [items]="employees" #select bindLabel="fullName" dropdownPosition="auto" bindValue="employeeId"
            labelForId="employeeId" placeholder="Search employees" [virtualScroll]="true" name="input-employee-id"
            id="input-employee-id" formControlName="employeeId" appendTo="body">
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
              <div>
                <span>{{ item.employeeFirstName }} {{ item.employeeSurname }} ({{ item.employeeId }})</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div class="form-control-group">
          <label class="label" for="input-event-type">Type of Event:</label>
          <select formControlName="eventType" name="input-event-type" id="input-event-type">
            <option *ngFor="let eventType of eventTypes" [ngValue]="eventType.id">
              {{ eventType.wordFullName }}
            </option>
          </select>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'eventType', errorMessages) }}
          </p>
        </div>
        <div class="form-control-group checkbox">
          <nb-checkbox formControlName="requiresAcceptance">Requires acceptance</nb-checkbox>
        </div>
      </div>
      <div class="flex-container-horizontal">
        <div class="form-control-group">
          <label class="label" for="input-leaving-start">Start:</label>
          <input nbInput fullWidth formControlName="leavingStart" [max]="form.controls['leavingEnd'].value"
            id="input-leaving-start" placeholder="DD/MM/YYYY" (dateChange)="onStartDateInput($event)"
            [matDatepicker]="leavingStartDatepicker" autofocus>
          <mat-datepicker-toggle matSuffix [for]="leavingStartDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #leavingStartDatepicker startView="month"></mat-datepicker>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'leavingStart', errorMessages) }}
          </p>
        </div>
        <div class="form-control-group">
          <label class="label" for="input-leaving-end">End:</label>
          <input nbInput fullWidth formControlName="leavingEnd" id="input-leaving-end" placeholder="DD/MM/YYYY"
            [matDatepicker]="leavingEndDatepicker" autofocus  (dateChange)="onEndDateInput($event)" [min]="form.controls['leavingStart'].value">
          <mat-datepicker-toggle matSuffix [for]="leavingEndDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #leavingEndDatepicker startView="month"></mat-datepicker>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'leavingEnd', errorMessages) }}
          </p>
        </div>
        <div class="form-control-group">
          <label class="label" for="input-first-day-available">First Day Available (blank if date unknown):</label>
          <input nbInput fullWidth formControlName="firstDayAvailable" id="input-first-day-available"
            placeholder="DD/MM/YYYY" [matDatepicker]="firstDayAvailableDatepicker" autofocus>
          <mat-datepicker-toggle matSuffix [for]="firstDayAvailableDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #firstDayAvailableDatepicker startView="month"></mat-datepicker>
          <div *ngIf="form.get('firstDayAvailable').value" id="clear-date" (click)="clearFirstDayAvailable()">x</div>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'firstDayAvailable', errorMessages) }}
          </p>
        </div>
      </div>
      <nb-accordion *ngIf="dataLoaded === LoadingStatus.LOADED && (calendarMessageLeave == '' || calendarMessageShifts == '')" class="leave-table">
        <nb-accordion-item #leaveItem>
          <nb-accordion-item-header>Leave Calendar - click to {{leaveItem.collapsed ? 'expand' : 'hide'}}</nb-accordion-item-header>
          <nb-accordion-item-body>
            <ngx-overview-calendar
              class="mb-4 mt-4"
              [hideAlButton]="true"
              [schedule]="schedule"
              [employeeName]="employeeName"
              [scheduleSummary]="scheduleSummary"
              [months]="calendarData.months"
              (refreshData)="onRefreshData()"
              [dateRange]="dateRange">
            </ngx-overview-calendar>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
      <p class="ml-3 mb-4 mt-4">{{calendarMessageLeave}}<br>{{calendarMessageShifts}}</p>
      <div class="flex-container-horizontal mb-1 flex-space buttons-flex">
        <div>
          <!-- <button class="btn btn-success search-button" (click)="searchforContracts()"
            [disabled]="!utils.validateFormControlsByName(form, controlNames)">
            <i class="nb-search" aria-hidden="true"></i> Search
          </button> -->
          <button *ngIf="showAnnualLeavingTable" class="btn btn-success mr-1" (click)="hideDetails()">
            {{buttonDetailsText}} </button>
        </div>
      </div>
      <ngx-flash-loader *ngIf="contractsLoading"></ngx-flash-loader>
      <ng-template [ngIf]="showAnnualLeavingTable">
        <div class="form-control-group table-container thin-echo-scrollbar">
          <table>
            <thead>
              <tr>
                <th>Role</th>
                <th>Contract</th>
                <th>Contract start</th>
                <th>Contract end</th>
                <th>AL Weeks p/a</th>
                <th>Contract status</th>
                <ng-container *ngIf="!hideColumns">
                  <th class="center" [ngStyle]="{'display': showDetails ? '': 'none'}">A:Full year AL<br>allowance (gross)
                  </th>
                  <th class="center" [ngStyle]="{'display': showDetails ? '': 'none'}">B:Approved <br>AL(this AL year)
                  </th>
                  <th class="center" [ngStyle]="{'display': showDetails ? '': 'none'}">Max Hrs you can<br> give (A -B)
                  </th>
                  <th class="center" [ngStyle]="{'display': showDetails ? 'none': ''}">AL Hrs to spend up to {{upToDateText}}</th>
                  <th class="center">Given Hrs HH:mm (req.)</th>
                  <th></th>
                  <th></th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let contract of employeeContracts">
                <td>{{ contract.contractRole }}</td>
                <td class="contract">{{ contract.contractType }} / ({{ contract.contractHoursPerWeek }} hpw)</td>
                <td>{{ contract.contractStart }}</td>
                <td>{{ contract.contractEnd }}</td>
                <td>{{ contract.alDays }}</td>
                <td>{{ contract.contracStatus }}</td>
                <ng-container *ngIf="!hideColumns">
                  <td class="time-right" [ngStyle]="{'display': showDetails ? '': 'none'}">
                    {{ contract.leaveSettlements.maxHrsAmountWithoutTaken }}</td>
                  <td class="time-right" [ngStyle]="{'display': showDetails ? '': 'none'}">
                    {{ contract.leaveSettlements.usedHrs }}</td>
                  <td class="time-right" [ngStyle]="{'display': showDetails ? '': 'none'}">
                    {{ contract.leaveSettlements.maxHrs }}</td>
                  <td class="time-right" [ngStyle]="{'display': showDetails ? 'none': ''}">{{ contract.leaveSettlements.recommendedHrs }}</td>
                  <td id="given-field">
                    <div *ngIf="contract.isSleeper && contract.contracStatus === 'canceled'" nbTooltip="Can't add AL for cancelled contract">
                      <select [formControlName]="'leaving-' + contract.contractId" class="hrs-select">
                        <option *ngFor="let hours of calculateALHours(contract)" [ngValue]="hours">{{hours}}</option>
                      </select>
                    </div>
                    <div *ngIf="contract.isSleeper && contract.contracStatus !== 'canceled'">
                      <select [formControlName]="'leaving-' + contract.contractId" class="hrs-select">
                        <option *ngFor="let hours of calculateALHours(contract)" [ngValue]="hours">{{hours}}</option>
                      </select>
                    </div>
                    <div *ngIf="!contract.isSleeper && contract.contracStatus === 'canceled'" nbTooltip="Can't add AL for cancelled contract">
                      <input [readOnly]="hasNoActiveContract" [class.no-active-contract]="hasNoActiveContract" nbInput fullWidth [formControlName]="'leaving-' + contract.contractId" class="hrs-input"
                        id="input-given" name="input-given" type="text" placeholder="HH"
                        (focus)="showASInfoIfCancelled(contract.contracStatus)" alFilter="true" minlength="1"
                        maxlength="6"
                        (focusout)="utils.onHoursInputWithExcluding($event, form, 'leaving-' + contract.contractId, 'AS')"
                             [style.border-color]="form.controls['leaving-' + contract.contractId].errors?.invalidHrs || form.controls['leaving-' + contract.contractId].errors?.invalidHrsMore ? 'red' : '#dadfe6'"
                             autofocus>
                    </div>
                    <div *ngIf="!contract.isSleeper && contract.contracStatus !== 'canceled' && contract.contractRole != 'in Training' && contract.contracStatus !== 'waiting for start' && (contract.contractStartDate | checkLeave: contract.contractEndDate: form.get('leavingStart').value)"
                    nbTooltip="AL can be entered only for running contracts on the AL start date"
                    >
                      <input [readOnly]="true" [class.no-active-contract]="true" nbInput fullWidth [formControlName]="'leaving-' + contract.contractId" class="hrs-input"
                        id="input-given" name="input-given" type="text" placeholder="HH"
                        (focus)="showASInfoIfCancelled(contract.contracStatus)" alFilter="true" minlength="1"
                        maxlength="6"
                        (focusout)="utils.onHoursInputWithExcluding($event, form, 'leaving-' + contract.contractId, 'AS')"
                             [style.border-color]="form.controls['leaving-' + contract.contractId].errors?.invalidHrs || form.controls['leaving-' + contract.contractId].errors?.invalidHrsMore ? 'red' : '#dadfe6'"
                             autofocus>
                    </div>
                    <div *ngIf="!contract.isSleeper && contract.contracStatus !== 'canceled' && contract.contractRole != 'in Training' && contract.contracStatus !== 'waiting for start' && !(contract.contractStartDate | checkLeave: contract.contractEndDate: form.get('leavingStart').value)">
                      <input [readOnly]="false" nbInput fullWidth [formControlName]="'leaving-' + contract.contractId" class="hrs-input"
                             id="input-given" name="input-given" type="text" placeholder="HH"
                             (focus)="showASInfoIfCancelled(contract.contracStatus)" alFilter="true" minlength="1"
                             maxlength="6"
                             (focusout)="utils.onHoursInputWithExcluding($event, form, 'leaving-' + contract.contractId, 'AS')"
                             [style.border-color]="form.controls['leaving-' + contract.contractId].errors?.invalidHrs || form.controls['leaving-' + contract.contractId].errors?.invalidHrsMore ? 'red' : '#dadfe6'"
                             autofocus>

                    </div>
                    <div *ngIf="contract.contracStatus === 'waiting for start'">
                      <input [readOnly]="contract.contractStartDate | checkLeave: contract.contractEndDate: form.get('leavingStart').value" [class.no-active-contract]="contract.contractStartDate | checkLeave: contract.contractEndDate: form.get('leavingStart').value" nbInput fullWidth [formControlName]="'leaving-' + contract.contractId" class="hrs-input"
                             id="input-given" name="input-given" type="text" placeholder="HH"
                             (focus)="showASInfoIfCancelled(contract.contracStatus)" alFilter="true" minlength="1"
                             maxlength="6"
                             (focusout)="utils.onHoursInputWithExcluding($event, form, 'leaving-' + contract.contractId, 'AS')"
                             [style.border-color]="form.controls['leaving-' + contract.contractId].errors?.invalidHrs || form.controls['leaving-' + contract.contractId].errors?.invalidHrsMore ? 'red' : '#dadfe6'"
                             autofocus>
                    </div>
                    <div *ngIf="!contract.isSleeper && contract.contracStatus !== 'canceled' && contract.contractRole == 'in Training'">
                      <input [readOnly]="true" [class.no-active-contract]="true" nbInput fullWidth [formControlName]="'leaving-' + contract.contractId" class="hrs-input"
                             id="input-given" name="input-given" type="text" placeholder="HH"
                             (focus)="showASInfoIfCancelled(contract.contracStatus)" alFilter="true" minlength="1"
                             maxlength="6"
                             (focusout)="utils.onHoursInputWithExcluding($event, form, 'leaving-' + contract.contractId, 'AS')"
                             [style.border-color]="form.controls['leaving-' + contract.contractId].errors?.invalidHrs || form.controls['leaving-' + contract.contractId].errors?.invalidHrsMore ? 'red' : '#dadfe6'"
                             autofocus>
                    </div>
                    <div class="error-message" *ngIf="form.controls['leaving-' + contract.contractId].dirty && form.controls['leaving-' + contract.contractId].errors">
                      <div *ngIf="form.controls['leaving-' + contract.contractId].errors?.invalidHrs">
                        {{ 'The system allows ranges to be picked of within 12hrs +/- within given number of hours' }}
  <!--                      {{ 'The system allows ranges to be picked of within 12hrs +/- within given number of hours but not more than contracted week hours' }}-->
                      </div>
                      <div *ngIf="form.controls['leaving-' + contract.contractId].errors?.invalidHrsMore">
                        {{ 'The system allows ranges to be picked of within 12hrs more within given number of hours' }}
                      </div>
                    </div>
                  </td>
                  <td class="flex-end question">
                    <button [nbPopover]="templateRef" class="btn btn-info" *ngIf="contract.contractRole !== 'in Training'">
                      <i class="nb-help" aria-hidden="true"></i>
                    </button>
                    <ng-template #templateRef>
                      <div class="info-box">
                        <div [innerHTML]="contract.leaveSettlements.comment1"></div>
                        <div [innerHTML]="contract.leaveSettlements.comment2"></div>
                        <div [innerHTML]="contract.leaveSettlements.comment3"></div>
                        <div [innerHTML]="contract.leaveSettlements.comment4"></div>
                        <div [innerHTML]="contract.leaveSettlements.comment5"></div>
                      </div>
                    </ng-template>
                  </td>
                </ng-container>
              </tr>
              <tr *ngIf="!hideColumns">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><strong>Total:</strong></td>
                <td class="time-right" [ngStyle]="{'display': showDetails ? '': 'none'}">
                  <strong>{{getSum('maxHrsAmountWithoutTaken')}}</strong></td>
                <td class="time-right" [ngStyle]="{'display': showDetails ? '': 'none'}"><strong>{{getSum('usedHrs')}}</strong>
                </td>
                <td class="time-right" [ngStyle]="{'display': showDetails ? '': 'none'}"><strong>{{getSum('maxHrs')}}</strong>
                </td>
                <td class="time-right" [ngStyle]="{'display': showDetails ? 'none': ''}" ><strong>{{getSum('recommendedHrs')}}</strong></td>
                <td class="time-right question"><strong>{{givenHrsSum}}</strong></td>
                <td class="question"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-control-group textarea-container note-height ">
          <p *ngIf="isGivenMoreThanRecommended && !isGivenMoreThanMax && !hideColumns" id="note">This person will not have accrued more
            than {{recommendedHrsSum}} hrs up to this point.
            You are giving more hours than the person will have accrued. If the person leaves after this AL,
            the company may incur losses. Only give this AL if you are certain they will work past this point.</p>
          <p *ngIf="isGivenMoreThanMax && !hideColumns" id="note">BLOCKED - You are giving more AL than this person can possibly earn in
            the current year.</p>
          <p *ngIf="hasNoActiveContract" id="note">{{noActiveContractMsg}}</p>
        </div>
        <div class="form-control-group textarea-container">
          <label class="label" for="input-description">Description (required):</label>
          <textarea nbInput fullWidth formControlName="description" placeholder="Description" minlength="3"
            maxlength="5000">
          </textarea>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'description', errorMessages) }}
          </p>
        </div>
      </ng-template>
    </form>
  </main>
  <div class="explain-al-text" [innerHTML]="alText"></div>
  <footer class="space-between">
    <div class="flex" >
      <button class="btn btn-success" (click)="saveData()"
        [disabled]="(!utils.isFormValid(form) || isOnly0OrAs(form) || !canSave || hasNoActiveContract || contractsLoading) || (isGivenMoreThanMax && !hideColumns)">
        <i class="nb-checkmark" aria-hidden="true"></i> Save
      </button>
      <button class="btn btn-info ml-4" style="width: 215px;" (click)="explainAnnual()" [disabled]="explanationAnnualLeave === null">
        Explain my Annual Leave
      </button>
      <button class="btn btn-danger ml-4" (click)="closeWindow()">
        <i class="nb-close" aria-hidden="true"></i> Close
      </button>
    </div>
  </footer>
</div>
