import { Component, OnDestroy, OnInit, Inject, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, FormControl } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import {
  handleValidationErrorMessage,
  isFormValid,
  getErrorMessage,
  urlify,
} from "../../../../utilities/utils";
import { TableService } from "../../../shared/table/table.service";
import { NbToastrService, NbDialogService } from "@nebular/theme";
import { TasksService } from "../../../../shared/tasks/tasks.service";
import { DictionariesService } from "../../../../shared/dictionaries/dictionaries.service";
import { taskErrorMessages } from "../task-errors";
import { TaskBoardsService } from "../../../../shared/tasks/task-boards/task-boards.service";
import { TaskTab } from "../task-tab.enum";
import { AuthUserService } from "../../../../@core/data/auth-user.service";
import * as moment from "moment";
import { DOCUMENT } from "@angular/common";
import { CareHomesService } from "../../../../shared/care-homes/care-homes.service";
import { TasksEmailDialogComponent } from "../../../shared/tasks-email-dialog/tasks-email-dialog.component";
import { AddWatchersDialogComponent } from "../../../shared/add-watchers-dialog/add-watchers-dialog.component";
import {
  QuestionDecisionTypeEnum,
  QuestionDialogComponent,
} from "../../../shared/question-dialog/question-dialog.component";
import { ChooseTemplateDialogComponent } from "../../../shared/choose-template/choose-template.component";
import { PermissionsService } from "../../../../@core/data/permissions.service";
import { UsersService } from "../../../../shared/users/users.service";
import { SubtasksService } from "../../../shared/subtask-editor/subtask.service";
import { SubtaskEditorFormComponent } from "../../../shared/subtask-editor/subtask-editor-form.component";

@Component({
  selector: "ngx-new-multiple-task",
  templateUrl: "./new-multiple-task.component.html",
  styleUrls: ["../new-task/new-task.component.scss"],
})
export class NewMultipleTaskComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private taskboardPermissions: any = {};
  public TaskTab: typeof TaskTab = TaskTab;
  public activeTab = TaskTab.INFO;
  public errorMessages = taskErrorMessages;
  public utils = { handleValidationErrorMessage, isFormValid };
  public form: UntypedFormGroup;
  public taskboardValues: any = [];
  public selectedTaskboards: any = [];
  public selectedTags: any = [];
  public statuses: any = [];
  public pinned: any = [];
  public categories: any = [];
  public priorities: any = [];
  public taskboards: any = [];
  public taskboardsBackup: any = [];
  public groupingList: any = [];
  public groupList: any = [];
  public id: number;
  public taskId: any = 0;
  public boardsId: number[] = [];
  public files: any = [];
  public isGroupPick = false;
  public saveFromTemplate = false;
  public taskboardsWithGrouping = [];
  public pickedGrouping = [];
  public taskBoardListSize = 0;
  private tagsDictionaryId: number;
  public tags: any = [];
  public dynamicTags = [];
  public permissions = <any>{};
  public canSave = true;
  public sourceTemplateId = null;

  public showGridView: boolean = false

  public canChangeTaskboard = false;
  public isRoomEnquiries: boolean = false;
  private taskBoardId = 0;
  public careHomes: any = [];
  public selectedCareHomes: any = [];

  public grouping: any = [];
  public raggs1: any = [];
  public raggs2: any = [];
  public taskboardDetails: any = {};
  public raggsColors: any = {};

  private filesToSaveCount = 0
  public showAdvancedFeatures: boolean = false

  @ViewChild('subtaskEditorFormComponent', { static: true }) subtaskEditorFormComponent!: SubtaskEditorFormComponent

  toggleListGridView() {
    this.showGridView = !this.showGridView
  }

  toggleAdvFeatures() {
    this.showAdvancedFeatures = !this.showAdvancedFeatures
  }

  constructor(
    private dictionariesService: DictionariesService,
    private tableService: TableService,
    private tasksService: TasksService,
    private toastrService: NbToastrService,
    private authUserService: AuthUserService,
    @Inject(DOCUMENT) private document: Document,
    private careHomesService: CareHomesService,
    private dialogService: NbDialogService,
    private taskBoardsService: TaskBoardsService,
    private permissionsService: PermissionsService,
    private usersService: UsersService,
    private subtasksService: SubtasksService
  ) {
    this.permissions = this.permissionsService.getPermissions();
  }

  ngOnInit() {
    this.createForm();
    this.canChangeTaskboard = this.tableService.getValue() ? this.tableService.getValue().canChangeTaskboard : false;
    this.isRoomEnquiries = this.tableService.getValue() ? this.tableService.getValue().isRoomEnquiries : false;
    if (this.isRoomEnquiries) {
      this.form.patchValue({
        comments: `PERSON Making enquiry:
DATE of enquiry:
ENQUIRY FOR (e.g. mum):
TEL:
EMAIL:
SOURCE (how did they hear about us):
Perm or Respite and how long:
Needs level?:
Other notes:`
      });
      this.form.removeControl('taskBoardId');
      this.form.removeControl('careHomes');
    }

    this.taskboardPermissions = this.tableService.getValue()
      ? this.tableService.getValue().taskboardPermissions
      : null;
    if (this.taskboardPermissions) {
      this.disableByPermissions();
    }

    this.sourceTemplateId = this.tableService.getValue() && this.tableService.getValue().taskTemplateId;
    if (this.sourceTemplateId) {
      this.applyTemplate(this.sourceTemplateId, true);
    } else {
      this.chooseTemplate();
    }

    if (this.canChangeTaskboard) {
      this.subscription.add(
        this.taskBoardsService
          .getAllICanAddTicketsTaskboards()
          .subscribe((response: any) => {
            response.result.taskBoard.taskBoardList.map(
              (i) => (i.fullName = i.boardName)
            );
            this.taskboards = response.result.taskBoard.taskBoardList;
            this.taskboardsBackup = this.taskboards;
          })
      );
    } else {
      this.subscription.add(this.taskBoardsService.getOnlyMineTaskBoards(this.authUserService.getCareHomeId(), true)
        .subscribe((response: any) => {
          this.taskboards = response.result.taskBoard.taskBoardList;
          this.taskboardsBackup = this.taskboards;
          if (this.tableService.getValue()) {
            const taskboardId = parseInt(this.tableService.getValue().taskBoardId);
            this.taskBoardId = taskboardId;
            if (taskboardId) {
              const foundTaskBoard = this.taskboards.filter(x => x.id === taskboardId);
              const taskboardList = [];
              taskboardList.push(foundTaskBoard[0]);
              this.form.get('taskboards').setValue(taskboardList);
            }
          }
        })
      );
    }

    this.subscription.add(
      this.dictionariesService
        .getDictionaryByName("Home Groups")
        .subscribe((response: any) => {
          this.groupList = response.result.wordsFromDictionary;
          this.groupList.unshift({});
        })
    );

    this.subscription.add(this.dictionariesService.getPinnedTasks()
      .subscribe((response: any) => {
        this.pinned = response.result.wordsFromDictionary;
      }))

    this.subscription.add(
      this.dictionariesService
        .getTaskCategories()
        .subscribe((response: any) => {
          this.categories = response.result.wordsFromDictionary;
        })
    );

    this.subscription.add(
      this.dictionariesService
        .getTaskPriorities()
        .subscribe((response: any) => {
          this.priorities = response.result.wordsFromDictionary;
          this.setDefaultPriorityValue();
        })
    );

    this.subscription.add(
      this.dictionariesService.getTaskStatuses().subscribe((response: any) => {
        this.statuses = response.result.wordsFromDictionary;
        this.setDefaultStatusValue();
      })
    );

    this.loadTags(null);
    this.loadCollaboartionUsers();

    // setInterval(() => {
    //   console.log(this.groupingList);
    //   console.log(this.taskBoardListSize);
    // }, 5000);
  }

  get isEmailOnSaveControl(): AbstractControl {
    return this.form.get('isEmailOnSave');
  }

  disableByPermissions() {
    if (!this.taskboardPermissions.canIWrite) {
      this.form.get("latestUpdate").disable();
    }
    if (!this.taskboardPermissions.canIEdit) {
      this.form.get("comments").disable();
    }
    if (!this.taskboardPermissions.canIClose) {
      this.form.get("groupResponse").disable();
    }
  }

  setDefaultPriorityValue() {
    const mediumWord = this.priorities.find((element: any) => {
      return element.wordFullName == "medium";
    });
    this.form.patchValue({ priorityId: mediumWord.id });
  }

  setDefaultStatusValue() {
    const newWord = this.statuses.find((element: any) => {
      return element.wordFullName == "Active";
    });
    this.form.patchValue({ statusId: newWord.id });
  }

  getMineCareHome() {
    const careHome = this.careHomes.find((element: any) => {
      return element.id == this.authUserService.getCareHomeId();
    });
    return careHome;
  }

  loadTags(callback: any = null) {
    this.subscription.add(
      this.dictionariesService.getTaskTags().subscribe((response: any) => {
        this.tags = response.result.wordsFromDictionary;
        this.tagsDictionaryId = response.result.dictionaryId;
        if (callback) {
          callback();
        }
      })
    );
  }

  loadCollaboartionUsers(taskId = 0, careHomeId = 0) {
    this.subscription.add(
      this.usersService
        .getUsersForTasksCollaboration(taskId, careHomeId)
        .subscribe((data: any) => {
          const d = data.result.list.map((i) => {
            return { id: i.id, fullName: i.login };
          });
          this.subtasksService.setUsers(d);
        })
    );
  }

  newTagAdded(value: string) {
    const data = {
      description: value,
      name: value,
    };
    this.subscription.add(
      this.dictionariesService
        .addDictionaryWord(this.tagsDictionaryId, data)
        .subscribe(
          (response: any) => {
            this.loadTags(() => {
              const addedWordId = response.result;
              const actualTagsValue = this.form.get("tags").value || [];
              const addedValue = this.tags.find((item: any) => {
                return item.id == addedWordId;
              });
              actualTagsValue.push(addedValue);
              this.form.patchValue({ tags: actualTagsValue });
              this.form.updateValueAndValidity();
            });
          },
          (err: any) => {
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
          }
        )
    );
  }

  createForm() {
    this.form = new UntypedFormGroup({
      taskboards: new UntypedFormControl(null, [Validators.required]),
      title: new UntypedFormControl(null, [Validators.required]),
      categoryId: new UntypedFormControl(null, [Validators.required]),
      statusId: new UntypedFormControl(null, [Validators.required]),
      priorityId: new UntypedFormControl(null, [Validators.required]),
      tags: new UntypedFormControl(null),
      dueTo: new UntypedFormControl(null),
      comments: new UntypedFormControl(null),
      latestUpdate: new UntypedFormControl(null),
      groupResponse: new UntypedFormControl(null),
      isEmailList: new FormControl<boolean>(false),
      isFavorite: new FormControl<boolean>(false),
      taskGoupingId: new UntypedFormControl(null),
      taskRagg1Id: new UntypedFormControl(null),
      taskRagg2Id: new UntypedFormControl(null),
      isEmailOnSave: new FormControl<boolean>(false),
      careHomeGroupId: new UntypedFormControl(null),
      subTasksList: new UntypedFormControl(null),
      pinnedAreaId: new FormControl<number>(null)
    });

    if (!this.permissions.CHANGE_NEW_TASK_STATUS) {
      this.form.get("statusId").disable();
    }

    this.form.get("title").markAsTouched();
    this.form.get("categoryId").markAsTouched();

    this.form.get("careHomeGroupId").valueChanges.subscribe((value: any) => {
      this.isGroupPick = value ? true : false;
      if (this.isGroupPick) {
        this.form.get("taskboards").setValidators(null);
        this.form.get("taskboards").setValue(null);
      } else {
        this.form.get("taskboards").setValidators(Validators.required);
      }
      this.manageTaskboardTable(value);
    });

    if (this.canChangeTaskboard) {
      this.subscription.add(
        this.form.get("taskboards").valueChanges.subscribe((value: any) => {
          if (value) {
            const taskBoard = value;
            if (
              taskBoard &&
              taskBoard.length > 0 &&
              this.taskBoardListSize !== taskBoard.length
            ) {
              this.taskBoardListSize = taskBoard.length;

              const taskBoardsId = this.getTaskBoardsId(taskBoard);
              this.subscription.add(
                this.taskBoardsService
                  .getGroupsForTaskboard(taskBoardsId)
                  .subscribe(
                    (response: any) => {
                      this.groupingList = response.result.taskBoard.list;
                      for (const {} of this.groupingList) {
                        // this.pickedGrouping.push({});
                      }
                    },
                    (err) => {
                      console.log(
                        "ERROR during fetch request for taskboards",
                        err
                      );
                    }
                  )
              );
            }
            if (taskBoard.length === 0) {
              this.groupingList = [];
              this.taskBoardListSize = 0;
            }
          } else {
            this.groupingList = [];
            this.taskBoardListSize = 0;
          }
        })
      );
    } else {
      this.subscription.add(this.form.get('taskboards').valueChanges
        .subscribe((value: any) => {
            if (value) {
              const taskBoard = value[0];
              if (taskBoard) {
                this.subscription.add(this.taskBoardsService.getTaskBoard(taskBoard.id)
                  .subscribe((response: any) => {
                      this.taskBoardId = taskBoard.id;
                      this.careHomes = response.result.careHomes;
                      const careHome = this.getMineCareHome();
                      this.selectedCareHomes = [careHome];
                      this.form.patchValue({
                        careHomes: [careHome]
                      });
                      this.taskboardPermissions = response.result;
                      this.disableByPermissions();
                      this.loadGrouppingAndRaggs(response.result);
                    },
                    (err: any) => {
                      this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
                    })
                );

                this.subscription.add(
                  this.taskBoardsService
                    .getGroupsForTaskboard(taskBoard.id)
                    .subscribe(
                      (response: any) => {
                        this.groupingList = response.result.taskBoard.list;
                      },
                      (err) => {
                        console.log(
                          "ERROR during fetch request for taskboards",
                          err
                        );
                      }
                    )
                );
              } else {
                this.groupingList = [];
            this.taskBoardListSize = 0;
              }
            }
          }
        ));
    }
  }

  loadGrouppingAndRaggs(taskboardDetails: any) {
    this.taskboardDetails = taskboardDetails;
    if (taskboardDetails.ragg1DictionaryId) {
      this.dictionariesService.getDictionaryByName(taskboardDetails.ragg1DictionaryName)
        .subscribe((response: any) => {
          this.raggs1 = response.result.wordsFromDictionary;
          this.setRaggColor(this.raggs1, this.form.get('taskRagg1Id').value, 'ragg1Color');
        });
    }

    if (taskboardDetails.ragg2DictionaryId) {
      this.dictionariesService.getDictionaryByName(taskboardDetails.ragg2DictionaryName)
        .subscribe((response: any) => {
          this.raggs2 = response.result.wordsFromDictionary;
          this.setRaggColor(this.raggs2, this.form.get('taskRagg2Id').value, 'ragg2Color');
        });
    }

    if (taskboardDetails.groupingDictionaryId) {
      this.dictionariesService.getDictionaryByName(taskboardDetails.groupingDictionaryName)
        .subscribe((response: any) => {
          this.grouping = response.result.wordsFromDictionary;
          this.setRaggColor(this.grouping, this.form.get('taskGoupingId').value, 'groupingColor');
        });
    }
  }

  setRaggColor(array: any, value: number, colorFieldName: string) {
    const word = this.getWord(array, value);
    this.raggsColors[colorFieldName] = word ? word.colorHash : '';
  }

  getWord(arrray: any, wordId: number) {
    const word = arrray.find((element: any) => element.id == wordId);
    return word;
  }

  groupingChanged(event: any, grouping: any) {
    const groupingId = event.target.value;
    const taskBoards = this.form.get("taskboards").value;
    taskBoards.map(
      (board) =>
        (board.groupingId = this.getGroupingIdForBoard(
          board.id,
          grouping,
          groupingId,
          board.groupingId
        ))
    );
  }

  prepareData(encodeDescription = true) {
    const data = {
      taskboards: this.form.get("taskboards").value,
      title: this.form.get("title").value,
      tags: this.form.get("tags").value,
      categoryId: this.form.get("categoryId").value,
      statusId: this.form.get("statusId").value,
      priorityId: this.form.get("priorityId").value,
      dueTo: this.form.get("dueTo").value,
      comments: encodeDescription
        ? urlify(this.form.get("comments").value)
        : this.form.get("comments").value,
      latestUpdate: this.form.get("latestUpdate").value,
      groupResponse: this.form.get("groupResponse").value,
      isEmailList: this.form.get("isEmailList").value,
      isFavorite: this.form.get("isFavorite").value,
      originUrl: this.document.location.origin,
      careHomeGroupId: this.form.get("careHomeGroupId").value,
      openTime: new Date(),
      subTasksList: this.form.get("subTasksList").value || [],
      saveFromTemplate: this.saveFromTemplate,
      sourceTemplateId: this.sourceTemplateId,
      pinnedAreaId: this.form.get('pinnedAreaId').value
    };
    this.selectedTaskboards = this.form.get("taskboards").value;

    return data;
  }

  save(closeWindow = true) {
    if (this.isEmailOnSaveControl.value) {
      this.openEmailWindow(['withEmail']);
    } else {
      this.saveWithObservable().subscribe(() => {});
    }
  }

  saveWithObservable() {
    this.canSave = false;
    const data = this.prepareData();
    let dataStream = this.tasksService.addMultipleTask({...data, isGridMode: this.showGridView});
    let isUpdate = false;
    if (this.taskId > 0) {
      dataStream = this.tasksService.updateTask(this.taskId, {...data, isGridMode: this.showGridView});
      isUpdate = true;
    } else if (Array.isArray(this.taskId)) {
      dataStream = this.tasksService.updateMultipleTasks(this.taskId, {...data, isGridMode: this.showGridView});
    }
    return Observable.create((observer) => {
      this.subscription.add(
        dataStream.subscribe(
          (response: any) => {
            if (!isUpdate) {
              this.taskId = response.addedTasks;
            }
            this.boardsId = response.taskBoards;
            this.toastrService.success(response.message, "Success");
            if (this.files.length > 0) {
              this.sendFiles();
            } else {
              this.closeWindow()
            }

            observer.next(response);
            observer.complete();
          },
          (err) => {
            this.canSave = true;
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
            observer.complete();
          }
        )
      );
    });
  }

  readonly isAnySubtaskAdded = () => {
    let subtaskCounter = 0;
    const subtasks =
      (this.form.get("subTasksList") && this.form.get("subTasksList").value) ||
      [];
    for (let i = 0; i < subtasks.length; i++) {
      if (subtasks[i].todos.length > 0) {
        subtaskCounter++;
        break;
      }
    }
    return subtaskCounter > 0;
  };

  addWatchers() {
    if (this.taskId > 0 || Array.isArray(this.taskId)) {
      this.showWatchersForm();
    } else {
      this.saveWithObservable().subscribe(() => {
        this.showWatchersForm();
      });
    }
  }

  showWatchersForm() {
    this.dialogService
      .open(AddWatchersDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          taskboardId: this.boardsId,
          taskId: this.taskId,
          isMultiple: true,
        },
      })
      .onClose.subscribe((selectedWatchers: any) => {
        if (selectedWatchers) {
          this.subscription.add(
            this.tasksService
              .updateTaskWatchers(this.taskId, selectedWatchers)
              .subscribe(
                (response: any) => {
                  this.toastrService.success(response.message, "Success");
                },
                (err: any) => {
                  this.toastrService.danger(getErrorMessage(err), "Error", {
                    duration: 60000,
                    destroyByClick: true,
                  });
                }
              )
          );
        }
      });
  }

  openEmailWindow(idList?: any[]) {
    if (!idList && idList[0] !== 'withEmail') {
      this.toastrService.danger("There is no tasks to email", "Error");
    }
    this.dialogService
      .open(TasksEmailDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: "Please enter subject and emails",
          needLoadTable: false,
          showClearEmails: false,
          careHomeId: this.authUserService.getCareHomeId(),
          taskboards: this.form.get("taskboards").value,
        },
      })
      .onClose.subscribe((inputData: any) => {
        if (inputData && inputData.isConfirm) {
          this.saveWithObservable().subscribe(() => {
            const data = {
              frontURL: this.document.location.origin,
              emailToIds: inputData.emailTo,
              emailTo: inputData.emailList,
              emailBody: inputData.emailBody,
              emailSubject: inputData.subject,
              taskList: this.taskId,
              clearEmailListAfterSend: inputData.clearEmailListAfterSend,
            };

            this.careHomesService
              .sendTasksInEmailTasks(this.authUserService.getCareHomeId(), data)
              .subscribe(
                (response: any) => {
                  this.toastrService.success(response.message, "Success");
                  this.tableService.closeWindow(true);
                },
                (err) => {
                  this.toastrService.danger(getErrorMessage(err), "Error", {
                    duration: 60000,
                    destroyByClick: true,
                  });
                }
              );
          });
        }
      });
  }

  sendFiles() {

    if (Array.isArray(this.taskId) && this.taskId.length > 1) {
      this.filesToSaveCount = this.files.length * this.taskId.length
    } else {
      this.filesToSaveCount = this.files.length
    }

    this.taskId.forEach((id) => {
      this.files.forEach((file: any) => {
        this.uploadFileToServer(file, id)
      });
    })

  }

  closeWindow() {
    this.tableService.closeWindow(true);
  }

  uploadFileToServer(file: any, id: number) {
    const fd = new FormData();
    fd.append('file', file);
    this.subscription.add(this.tasksService.addTaskFile(id, fd)
      .subscribe((response: any) => {
          this.uploadDocumentDescriptionToServer({text: file.fileDescription, uid: response.uid || file.uid}, id)
        },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }
      ));
  }

  uploadDocumentDescriptionToServer(event: { text: string, uid: string }, id: number) {
    this.subscription.add(this.tasksService.addDocumentDescription(id, event.uid, event).subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        this.filesToSaveCount--
        if (this.filesToSaveCount == 0) {
          this.closeWindow()
        }
      },
      (err) => {
        this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
      }
    ));
  }

  clear(fieldName: string) {
    this.form.get(fieldName).setValue(null);
  }

  chooseTemplate() {
    this.subscription.add(
      this.dialogService
        .open(ChooseTemplateDialogComponent, { closeOnBackdropClick: false })
        .onClose.subscribe((templateId: any) => {
          if (templateId) {
            const taskChanged = this.checkTaskChanged();
            if (taskChanged) {
              this.subscription.add(
                this.dialogService
                  .open(QuestionDialogComponent, {
                    closeOnBackdropClick: false,
                    context: {
                      title: "Confirmation",
                      message: `We have detected information already within the task.<br>Should we ADD the template to what you’ve already got, wherever possible,<br>OR shall we OVERWRITE what’s already there`,
                      okLabel: "Add",
                      cancelLabel: "Cancel",
                      hideSecondOkButton: false,
                      secondOkLabel: "Overwrite",
                    },
                  })
                  .onClose.subscribe((decision: any) => {
                    if (decision && decision.type && decision.isConfirm) {
                      if (decision.type === QuestionDecisionTypeEnum.FIRST) {
                        this.applyTemplate(templateId, false);
                      } else {
                        this.applyTemplate(templateId, true);
                      }
                    }
                  })
              );
            } else {
              this.applyTemplate(templateId, true);
            }
          }
        })
    );
  }

  applyTemplate(detailsId: any, overwirte = false) {
    this.tasksService
      .getTemplateTaskDetails(detailsId)
      .subscribe((response: any) => {
        const templateDetails = response.result;
        const originData = this.prepareData(false);
        const dueToDate = templateDetails.deadlineDays
          ? moment().add(templateDetails.deadlineDays, "d")
          : originData.dueTo;
        this.dynamicTags = templateDetails.dynamicTags;
        this.saveFromTemplate = true;
        this.sourceTemplateId = templateDetails.taskId;
        if (overwirte) {
          this.form.patchValue({
            title: templateDetails.title || originData.title,
            comments: templateDetails.comments || originData.comments,
            categoryId: templateDetails.categoryId,
            tags: this.dynamicTags,
            dueTo: dueToDate,
            subTasksList: templateDetails.subTasks,
            statusId: templateDetails.statusId,
            priorityId: templateDetails.priorityId,
            pinnedAreaId: templateDetails.pinnedAreaId ? templateDetails.pinnedAreaId : null
          });

          this.toastrService.info(
            "Template has been aplied as an overwrite",
            "Info",
            { duration: 60000, destroyByClick: true }
          );
        } else {
          const categories = templateDetails.categoryId;

          if (
            originData.categoryId.length > 0 &&
            templateDetails.categoryId.length < 3
          ) {
            for (const category of categories) {
              const foundCategory = originData.categoryId.findIndex(
                (element) => element.id === category.id
              );
              if (foundCategory > -1) {
                originData.categoryId.splice(foundCategory, 1);
              }
            }
          }
          if (
            originData.categoryId.length > 0 &&
            templateDetails.categoryId.length < 3
          ) {
            if (templateDetails.categoryId.length === 2) {
              if (originData.categoryId[0]) {
                categories.push(originData.categoryId[0]);
              }
            } else if (templateDetails.categoryId.length === 1) {
              if (originData.categoryId[0]) {
                categories.push(originData.categoryId[0]);
                if (originData.categoryId[1]) {
                  categories.push(originData.categoryId[1]);
                }
              }
            }
          }
          const subtasks = (originData.subTasksList || []).concat(
            templateDetails.subTasks
          );
          const tagList = (originData.tags || []).concat(this.dynamicTags);
          this.form.patchValue({
            title: (originData.title || "") + templateDetails.title,
            comments:
              (originData.comments || "") + "\n" + templateDetails.comments,
            categoryId: categories,
            tags: tagList,
            subTasksList: subtasks,
            statusId: templateDetails.statusId,
            priorityId: templateDetails.priorityId,
          });

          this.toastrService.info("Template has been aplied", "Info", {
            duration: 60000,
            destroyByClick: true,
          });
        }
        this.subtaskEditorFormComponent.reloadOnChanges()
      });
  }

  private checkTaskChanged(): boolean {
    if (
      this.form.get("title").value !== null ||
      this.form.get("dueTo").value !== null ||
      this.form.get("comments").value !== null
    ) {
      return true;
    }

    if (
      this.form.get("categoryId").value &&
      this.form.get("categoryId").value.length !== 0
    ) {
      return true;
    }

    if (
      this.form.get("tags").value &&
      this.form.get("tags").value.length !== 0
    ) {
      return true;
    }

    return false;
  }

  changeFavoriteState(event: any) {
    this.form.patchValue({
      isFavorite: event.target.checked ? true : false,
    });
  }

  changeEmailOnSave(event: any) {
    this.form.patchValue({
      isEmailOnSave: event.target.checked ? true : false,
    });
  }

  changeEmailList(event: any) {
    this.form.patchValue({
      isEmailList: event.target.checked ? true : false,
    });
  }

  uploadFile(file: any) {
    let uid = 0
    for (let i = 0; i < this.files.length; i++) {
      let file = this.files.find(file => file.uid == i)
      uid = i
      if (!file) {break}
      uid++
    }
    file.uid = uid
    file.createdAt = moment().format("DD/MM/YYYY HH:mm");
    file.createdBy = this.authUserService.getUser().name;
    file.fileDescription = ''
    file.isSent = false;
    this.files.push(file);
  }

  deleteFile(fileUId: string) {
    this.files.splice(fileUId, 1);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private getGroupingIdForBoard(
    boardId: number,
    grouping: any,
    groupingId: string,
    actualValue: number
  ) {
    if (grouping.forTaskBoards.includes(boardId)) {
      for (const group of this.groupingList) {
        const inTaskBoardList = group.forTaskBoards.find((x) => x === boardId);

        if (inTaskBoardList) {
          return parseInt(groupingId, 10);
        }
      }
    }

    return actualValue;
  }

  private manageTaskboardTable(groupId: number) {
    if (this.isGroupPick) {
      this.subscription.add(
        this.taskBoardsService
          .getTaskBoardForGroup(groupId)
          .subscribe((response: any) => {
            response.result.taskBoard.taskBoardList.map(
              (i) => (i.fullName = i.boardName)
            );
            this.taskboards = response.result.taskBoard.taskBoardList;
            this.form.get("taskboards").setValue(this.taskboards);
          })
      );
    } else {
      this.taskboards = this.taskboardsBackup;
    }
  }

  private getTaskBoardsId(taskboards: any) {
    const idArr = [];

    for (const taskboard of taskboards) {
      idArr.push(taskboard.id);
    }

    return idArr;
  }

  uploadDocumentDescription(event: any) {
    let fileIndex = this.files.findIndex((file) => file.uid == event.uid)
    this.files[fileIndex].fileDescription = event.text
  }

  toggleExpandCollapse($event: boolean | "default") {
    this.subtaskEditorFormComponent.subtasks.forEach(subTask => {
      if ($event) {
        subTask.isExpanded = $event;
        subTask.todos.forEach(todo => {
          todo.isCollapsed = !$event;
        })
      } else {
        subTask.todos.forEach(todo => {
          todo.isCollapsed = !$event;
        })
        subTask.isExpanded = $event;
      }
    })
  }

}
