import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ISaveSuccessData, RotaMigrationService } from '../rota-migration.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RotaTableService } from '../../rota/rota-table.service';
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { HoursCellSelectorComponent } from "../../../shared/hours-cell-selector/hours-cell-selector.component";
import { CellErrorType } from "../../rota/cell-error-type.enum";
import { RotaTableHourModalComponent } from "../../rota/rota-table-hour-modal/rota-table-hour-modal.component";
import { HourModalService } from "../../rota/rota-table-hour-modal/hour-modal.service";
import { CurrentCareHomeService } from "../../../../@core/data/current-care-home.service";
import { ScheduleDayStatus } from "../../rota/schedule-day-status.enum";
import { IDataToSave, IScheduleWeekDayInfo } from "../../../shared/interfaces/shifts.interfaces";

@Component({
  selector: 'ngx-rota-migration-table-week-cell',
  templateUrl: './rota-migration-table-week-cell.component.html',
  styleUrls: ['./rota-migration-table-week-cell.component.scss']
})
export class RotaMigrationTableWeekCellComponent implements OnInit, OnDestroy {

  @Input() weekNo: string;
  @Input() homes: { id: number; name: string  }[];
  @Input() dayData: IScheduleWeekDayInfo;
  @Input() contractNumber: number;
  @Input() contractDate: {start: string, end: string} = {start: null, end: null};
  @Input() contractType: string;
  @Input() positionId: number;
  @Input() editMode: boolean;
  @Input() fieldName: string;
  @Input() employeePosition: string = '';
  @Input() isSleeper: boolean = false;

  @Output() onSaved = new EventEmitter();

  public roles: any = [];
  private oldValues = {
    from: null,
    to: null,
    positionId: null,
    careHomeId: null
  };
  public hasError = false;
  public dataSaved: boolean = false;

  public hoursTemplate: any;
  public form: UntypedFormGroup;
  public subscription: Subscription = new Subscription();
  public noStart: ScheduleDayStatus = ScheduleDayStatus.NO_CONTRACT_START;
  public noEnd: ScheduleDayStatus = ScheduleDayStatus.NO_CONTRACT_END;

  @ViewChild('fromHoursSelectorComponent', { static: false }) private fromHoursSelectorComponent: HoursCellSelectorComponent;

  constructor(
    private rotaTableService: RotaTableService,
    private hourModalService: HourModalService,
    private currentCareHomeService: CurrentCareHomeService,
    private rotaMigrationService: RotaMigrationService
  ) {
    this.subscription.add(
      this.rotaMigrationService.editMode$.subscribe((value: any) => {
        if (this.weekNo == value.weekNo) {
          if (value.save) {
            this.saveData();
          } else {
            // discard all changes
            this.form.patchValue({
              from: this.oldValues.from,
              to: this.oldValues.to,
              careHomeId: this.oldValues.careHomeId,
              positionId: this.oldValues.positionId
            });
            this.hasError = false;
          }
        }
      })
    );
    this.subscription.add(
      this.rotaMigrationService.saveSuccess$.subscribe((data: ISaveSuccessData) => {
        if (this.weekNo == data.weekNo && this.contractNumber == data.contractId && data.saved) {
          this.dataSaved = true;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.roles = this.rotaMigrationService.roles;
    this.hoursTemplate = this.rotaTableService.hoursTemplate || {
      hoursTemplateFrom: ['7:30', '8:00'],
      hoursTemplateTo: ["14:00", "17:00", "20:00", "20:30"]
    };
    this.createForm();
  }

  createForm() {
    const valueFrom = this.dayData.scheduleFrom;
    const valueTo = this.dayData.scheduleTo;
    const valueHomeId = this.dayData.careHomeId;
    const valuePositionId = this.positionId;

    this.form = new UntypedFormGroup({
      from: new UntypedFormControl({value: valueFrom, disabled: this.dayData.confirmedFrom}),
      to: new UntypedFormControl({value: valueTo, disabled: this.dayData.confirmedFrom}),
      positionId: new UntypedFormControl({value: valuePositionId, disabled: true}),
      careHomeId: new UntypedFormControl({value: valueHomeId, disabled: this.dayData.confirmedFrom})
    });

    this.form.valueChanges.pipe(debounceTime(250), distinctUntilChanged()).subscribe(res => {
      if (res.from != this.oldValues.from || res.to != this.oldValues.to || res.careHomeId != this.oldValues.careHomeId) {
        this.rotaMigrationService.addContractToChanges({weekNo: this.weekNo, contractId: this.contractNumber});
      }
    });

    this.oldValues = {
      from: valueFrom,
      to: valueTo,
      positionId: valuePositionId,
      careHomeId: valueHomeId
    };
  }

  patchToValue(newVal) {
    this.form.controls['to'].patchValue(newVal, {emitSelf: true});
  }

  patchFromValue(newVal: string) {
    this.form.get('from').patchValue(newVal, {emitSelf: true});
  }

  private patchAndRefreshFromValue(value): void {
    this.patchFromValue(value);
    this.fromHoursSelectorComponent.setValue(value);
  }

  saveData() {
    const dataToSave: IDataToSave = {
      contractId: this.contractNumber,
      weekNo: this.weekNo,
      fieldName: this.fieldName,
      from: this.form.get('from').value ? this.form.get('from').value : null,
      to: this.form.get('to').value ? this.form.get('to').value : null,
      positionId: this.form.get('positionId').value,
      careHomeId: this.form.get('careHomeId').value,
      scheduleDate: this.dayData.scheduleDate,
      notScheduled: !this.oldValues.from
    };

    this.onSaved.emit(dataToSave);
  }

  clearHours() {
    this.patchToValue('-');
    this.patchFromValue('-');
  }

  checkHours(event, showHours = true) {
    // if (this.rotaTableService.isWindowOpen) {
    //   this.rotaTableService.closeWindow();
    // }

    this.hasError = false;
    this.rotaTableService.errorType = CellErrorType.NONE;

    const from = this.form.get('from').value;
    const to = this.form.get('to').value;
    // reset hours to 00:00

    if (!from && this.oldValues.from) {
      this.form.patchValue({
        from: '00:00',
      });
    }

    // reset hours to 00:00
    if (!to && this.oldValues.to) {
      this.form.patchValue({
        to: '00:00',
      });
    }
    // Check if entered input was in a 12-hour format and load helping modal.
    if (from && to) {
      const { newFrom, newTo, newTo24Format, newFrom24Format } = this.formatInsertedDate(
        from,
        to
      );
      let target = event.target;
      if (!!event.emitterEvent && !!event.emitterVal) {
        target = event.emitterEvent;
        target.value = event.emitterVal.trim();
      }
      if (!target || !target.value) return;
      if (
        +newTo < 1300 && +newFrom < 1300 &&
        ((+newTo - +newFrom) / 100) < 12 &&
        ((target.value === this.form.get('to').value &&
            target.parentNode.id === 'toControl') ||
          (target.value === this.form.get('from').value) &&
          target.parentNode.id === 'fromControl')
      ) {
        this.hourModalService.setCurrentValues(
          newFrom,
          newTo,
          newTo24Format,
          (arg) => this.patchToValue(arg),
          target,
          newFrom24Format,
          (newFromValue) => this.patchAndRefreshFromValue(newFromValue)
        );
        if (showHours) {
          this.rotaTableService.openWindow(
            RotaTableHourModalComponent,
            'What did you mean?',
            {
              elementId: this.form,
            },
            'hour-modal-window-migration'
          );
        }
        return;
      }
    }
    if (
      (this.employeePosition.includes('night') ||
        this.employeePosition.includes('Night')) &&
      this.checkZeros(from, to) &&
      this.getHour(from) <= this.getHour(to)
    ) {
      this.hasError = true;
      this.rotaTableService.errorType = CellErrorType.NIGHT;
    }

    if (
      this.isSleeper &&
      this.checkZeros(from, to) &&
      (this.getHour(from) >
        this.getHour(this.currentCareHomeService.sleepersStart) ||
        (this.getHour(from) ==
          this.getHour(this.currentCareHomeService.sleepersStart) &&
          this.getMinnutes(from) >
          this.getMinnutes(this.currentCareHomeService.sleepersStart)) ||
        this.getHour(to) <
        this.getHour(this.currentCareHomeService.sleepersEnd) ||
        (this.getHour(to) ==
          this.getHour(this.currentCareHomeService.sleepersEnd) &&
          this.getMinnutes(to) <
          this.getMinnutes(this.currentCareHomeService.sleepersEnd)))
    ) {
      this.hasError = true;
      this.rotaTableService.errorType = CellErrorType.SLEEPER;
    }

    if (
      this.employeePosition.includes('day') &&
      this.checkZeros(from, to) &&
      this.getHour(from) >= this.getHour(to)
    ) {
      this.hasError = true;
      this.rotaTableService.errorType = CellErrorType.DAY;
    }
  }

  formatInsertedDate(
    from: string,
    to: string
  ): { newFrom: string; newTo: string; newTo24Format: string, newFrom24Format: string } {
    // check if hh:mm
    let formattedFrom: string = from;
    let formattedTo: string = to;
    const h_mm = new RegExp('^[0-9]:[0-5][0-9]$');
    const h_m = new RegExp('^[0-9]:[0-9]$');
    const hh_m = new RegExp('^[0-2][0-9]:[0-9]$');
    const h = new RegExp('^[0-9]');
    const hh = new RegExp('^[0-2][0-9]');
    if (from.length !== 5) {
      // regex formats

      h_mm.test(to) ? (formattedTo = `0${to}`) : null;
      hh_m.test(to) ? (formattedTo = `${to}0`) : null;
      h.test(to) ? (formattedTo = `0${to}:00`) : null;
      hh.test(to) ? (formattedTo = `${to}:00`) : null;

      h_mm.test(from) ? (formattedFrom = `0${from}`) : null;
      hh_m.test(from) ? (formattedFrom = `${from}0`) : null;
      h.test(from) ? (formattedFrom = `0${from}:00`) : null;
      hh.test(from) ? (formattedFrom = `${from}:00`) : null;

      formattedFrom = formattedFrom.slice(0, 5);
    }
    if (to.length !== 5) {
      // regex formats

      h_mm.test(to) ? (formattedTo = `0${to}`) : null;
      hh_m.test(to) ? (formattedTo = `${to}0`) : null;

      h.test(to) ? (formattedTo = `0${to}:00`) : null;
      hh.test(to) ? (formattedTo = `${to}:00`) : null;

      formattedTo = formattedTo.slice(0, 5);
    }

    const newFrom = formattedFrom.split(':')[0] + formattedFrom.split(':')[1];
    const newTo = formattedTo.split(':')[0] + formattedTo.split(':')[1];
    const newTo24Format = `${
      +formattedTo.split(':')[0] + 12 < 24
        ? +formattedTo.split(':')[0] + 12
        : '00'
    }:${formattedTo.split(':')[1]}`;
    const newFrom24Format = `${
      +formattedFrom.split(':')[0] + 12 < 24
        ? +formattedFrom.split(':')[0] + 12
        : '00'
    }:${formattedFrom.split(':')[1]}`;
    return { newFrom, newTo, newTo24Format, newFrom24Format };
  }

  checkZeros(from: string, to: string) {
    return from &&
      to &&
      from != '00:00' &&
      to != '00:00' &&
      from != '00:0' &&
      to != '00:0' &&
      from != '0:00' &&
      to != '0:00' &&
      from != '0:0' &&
      to != '0:0' &&
      from != '0' &&
      to != '0';
  }

  getHour(time: string) {
    return parseInt(
      time.indexOf(':') === -1 ? time : time.substr(0, time.indexOf(':')),
      10
    );
  }

  getMinnutes(time: string) {
    return parseInt(
      time.indexOf(':') === -1
        ? '0'
        : time.substr(time.indexOf(':') + 1, time.length),
      10
    );
  }
}
