import * as moment from "moment";
import { EmployeeScheduleCalendar } from "./overview-calendar/overview-calendar.component";
import { Moment } from "moment";

export function generateDays(date: any, dateRange: any = {}) {
  const days: any = [];
  if (!dateRange.from || !dateRange.to) {
    const daysInMonth = moment(date).daysInMonth();
    for (let i = 0; i < daysInMonth; i++) {
      days.push({
        date: moment(date).startOf("month").add(i, "days"),
      });
    }
    return days;
  } else {
    const dateFrom = moment(dateRange.from);
    const dateTo = moment(dateRange.to);
    for (
      const m = dateFrom;
      m.isSameOrBefore(dateTo);
      dateFrom.add(1, "days")
    ) {
      const momentGenerate = moment(dateFrom);
      days.push({ date: momentGenerate });
    }
    return days;
  }
}

export function generateDaysWithEvents(
  date: any,
  events: any,
  dateRange: any = {},
) {
  const days = generateDays(date, dateRange);
  for (let i = 0; i < days.length; i++) {
    events.forEach((event: any) => {
      if (
        moment(days[i].date).isBetween(
          event.startDate,
          event.endDate,
          "days",
          "[]",
        )
      ) {
        days[i] = {
          date: days[i].date,
          day: days[i],
          event: event,
          isStartCell:
            days[i].date.format("D") === moment(event.startDate).format("D"),
          isEndCell:
            days[i].date.format("D") === moment(event.endDate).format("D"),
        };
      }
    });
  }

  return days;
}

export function generateDaysWithSchedule(
  date: any,
  schedule: any,
  dateRange: any = {},
) {
  const days = generateDays(date, dateRange);
  for (let i = 0; i < days.length; i++) {
    schedule.forEach((scheduledDay: EmployeeScheduleCalendar) => {
      if (moment(days[i].date).format("YYYY/MM/DD") == scheduledDay.date) {
        if (days[i].roleSchedule) {
          days[i].roleSchedule.push({
            role: scheduledDay.positionName,
            scheduled: scheduledDay.schedule,
            confirmed: !!scheduledDay?.confirmed,
          });
        } else {
          days[i] = {
            date: days[i].date,
            day: days[i],
            roleSchedule: [
              {
                role: scheduledDay.positionName,
                scheduled: scheduledDay.schedule,
                confirmed: !!scheduledDay?.confirmed,
              },
            ],
          };
        }
      }
    });
  }
  return days;
}

export function generateDaysIncludingFirstDaysOfWeek(date: any) {
  const days: any = [];
  const firstDay = moment(date).startOf("month");
  const firstDayOfWeek = firstDay.clone().startOf("week").add(1, "days");

  const difference = firstDay.diff(firstDayOfWeek, "days");
  const daysInMonth = firstDay.daysInMonth();

  const totalDays = daysInMonth + difference;

  for (let i = 0; i < totalDays; i++) {
    days.push({
      date: firstDayOfWeek.clone().add(i, "days"),
    });
  }

  return days;
}

export function getMonthsBetween(startDate: any, endDate: any) {
  const dates = [];
  const currDate = moment(startDate).startOf("month").subtract(1, "months");
  const lastDate = moment(endDate).endOf("month");

  while (currDate.add(1, "months").diff(lastDate) < 0) {
    dates.push({
      date: currDate.clone(),
    });
  }

  return dates;
}
