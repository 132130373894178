import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-overview-calendar',
  templateUrl: './overview-calendar.component.html',
  styleUrls: ["./overview-calendar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewCalendarComponent {
  @Input() employeeName: string;
  @Input() hideAlButton = false;
  @Input() schedule: EmployeeScheduleCalendar[] = [];
  @Input() scheduleSummary: EmployeeScheduleSummary[] = [];
  @Input() months: any = [];
  @Input() withoutHeight = false;
  @Input() dateRange: any;
  @Output() refreshData = new EventEmitter();

  constructor() {
    // setTimeout(() => console.log("overview calendar", this.months), 100);
  }

  trackByFn(index, item) {
    return index;
  }

  onRefreshData() {
    this.refreshData.emit()
  }
}

export interface EmployeeScheduleCalendar {
  positionName: string; // 'Activities'
  date: string; // '2022/12/19'
  weekDate: string; //  'week: 52'
  dayOfWeek: string; // 'monday'
  scheduleHrs: string; // '1:30'
  schedule: string; // '07:30 - 09:00'
  careHomeName: string; // 'Apps Garden'
  confirmedHrs: string; // '0:00'
  confirmed: null;
}

export interface EmployeeScheduleSummary {
  roleName: string;
  scheduled: string;
  confirmed: string;
}


