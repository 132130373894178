<div class="form-view">
  <main>
    <form [formGroup]="form">
      <div *ngIf="!isPortableUser" class="flex-container image-chooser">
        <ngx-image-editor formControlName="avatar"></ngx-image-editor>
      </div>

      <div class="flex-container-horizontal">
        <div class="flex-container">
          <div class="form-control-group">
            <label class="label" for="input-login">Login:</label>
            <input nbInput fullWidth formControlName="login" name="login" id="input-login" placeholder="Login"
              minlength="5" maxlength="25" autofocus>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'login', errorMessages) }}
            </p>
          </div>
          <div class="form-control-group">
            <label class="label" for="input-email">Email:</label>
            <input nbInput fullWidth formControlName="email" name="email" id="input-email" placeholder="Email"
              maxlength="50" autofocus>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'email', errorMessages) }}
            </p>
          </div>
          <div class="form-control-group">
            <label class="label" for="input-phoneNumber">Phone number:</label>
            <input nbInput fullWidth formControlName="phoneNumber" name="phoneNumber" id="input-phoneNumber"
              placeholder="Phone number" maxlength="25" autofocus>
          </div>
          <div class="form-control-group">
            <nb-checkbox (change)="changeState($event, 'isValidCompanyEmail')"
              [checked]="form.get('isValidCompanyEmail').value">Valid company email</nb-checkbox>
          </div>
          <div *ngIf="isPortableUser" class="form-control-group">
            <label class="label" for="input-type">Employee:</label>
            <ng-select [items]="employees" #select bindLabel="fullName" dropdownPosition="auto" bindValue="employeeId"
              labelForId="employeeId" placeholder="Search employee" [virtualScroll]="true" dropdownPosition="top"
              name="input-employee-id" id="input-employee-id" formControlName="employeeId">
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                <div>
                  <span><strong>{{ item.employeeFirstName }}</strong> {{ item.employeeSurname }}</span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="flex-container">
          <div class="form-control-group">
            <label class="label" for="input-accountType">Account type:</label>
            <select formControlName="accountType">
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'accountType', errorMessages) }}
            </p>
          </div>

          <div class="form-control-group">
            <label class="label" for="input-firstName">First Name:</label>
            <input nbInput fullWidth formControlName="firstName" name="firstName" id="input-firstName"
              placeholder="First Name" minlength="1" maxlength="50" autofocus>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'firstName', errorMessages) }}
            </p>
          </div>
          <div class="form-control-group">
            <label class="label" for="input-password">Password:</label>
            <input nbInput type="password" id="input-password" name="password" class="first" placeholder="Password"
              autofocus fullWidth maxlength="25" formControlName="password">
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'password', errorMessages) }}
            </p>
          </div>
          <div class="form-control-group" [style]="{'visibility': isPortableUser ? 'hidden': 'initial'}">
            <nb-checkbox (change)="changeState($event, 'isTechnicalAccount')"
              [checked]="form.get('isTechnicalAccount').value">Technical account</nb-checkbox>
          </div>
          <div *ngIf="isPortableUser" class="form-control-group"></div>
        </div>

        <div class="flex-container">
          <div class="form-control-group">
            <label class="label" for="input-type">Default Care Home:</label>
            <select formControlName="defaultCareHome">
              <option *ngFor="let careHome of careHomes" [ngValue]="careHome.careHomeId">{{careHome.careHomeFullName}}
              </option>
            </select>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'defaultCareHome', errorMessages) }}
            </p>
          </div>

          <div class="form-control-group">
            <label class="label" for="input-surname">Last Name:</label>
            <input nbInput fullWidth formControlName="surname" name="surname" id="input-surname" placeholder="Last Name"
              minlength="1" maxlength="50" autofocus>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'surname', errorMessages) }}
            </p>
          </div>
          <div class="form-control-group">
            <label class="label" for="input-re-password">Confirm Password:</label>
            <input nbInput formControlName="confirmPassword" id="input-re-password" name="rePass" type="password"
              class="last" maxlength="25" placeholder="Confirm Password" fullWidth>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'confirmPassword', errorMessages) }}
            </p>
          </div>
          <div class="form-control-group">
            <nb-checkbox (change)="changeIsPortableUser($event)" [checked]="form.get('isPortableUser').value">Portable
              user</nb-checkbox>
          </div>
          <div *ngIf="isPortableUser" class="form-control-group"></div>
        </div>
      </div>
      <p class="ml-4">* after adding a user account, it is <strong>necessary</strong> to add permissions to Care Homes.</p>
    </form>
  </main>
  <footer>
    <div class="flex space">
      <div class="flex">
        <button class="btn btn-success" (click)="addNewUser()" [disabled]="!utils.isFormValid(form) || !canSave">
          <i class="nb-checkmark" aria-hidden="true"></i> Save
        </button>
        <button class="btn btn-danger ml-4" (click)="closeWindow()">
          <i class="nb-close" aria-hidden="true"></i> Close
        </button>
      </div>

      <div *ngIf="defaultPassword.length > 0">
        <p>Default password: {{defaultPassword}}</p>
      </div>
    </div>
  </footer>
</div>
