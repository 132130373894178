import * as moment from "moment";
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  HostListener,
  OnDestroy,
  ViewChildren,
  QueryList,
  AfterViewInit,
} from "@angular/core";
import { TableService } from "../../../shared/table/table.service";
import { CareHomesService } from "../../../../shared/care-homes/care-homes.service";
import { AuthUserService } from "../../../../@core/data/auth-user.service";
import {
  Subject,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  from,
} from "rxjs";
import { AddEditShiftComponent } from "./add-edit-shift/add-edit-shift.component";
import { NbToastrService, NbWindowService } from "@nebular/theme";
import { getErrorMessage, getFullName } from "../../../../utilities/utils";
import { DictionariesService } from "../../../../shared/dictionaries/dictionaries.service";
import { ProfessionalRolesModel } from "../../../../shared/models/dictionaries/professional-roles/professional-roles-model";
import { RotaTableService } from "../rota-table.service";
import { concatMap, map, tap } from "rxjs/operators";
import { DropEvent } from "ng-drag-drop";
import { IShift, IUnfilledShift } from "./rota-summary-table.model";
import {
  IConfirmHoursBody,
  IUpdateHoursBody,
  RotaSummaryService,
} from "./services/rota-summary.service";
import { EditEmployeeComponent } from "../../edit-employee/edit-employee.component";
import { CdkOverlayOrigin, ConnectionPositionPair } from "@angular/cdk/overlay";

enum ShiftTypeStatus {
  Confirmed = 1,
  DifferentThanScheduled = 2,
  AwaitingConfirmation = 8,
  Sick = 5,
  AL = 4,
  TRAINING_SICK = 14,
  TRAINING_AL = 15,
}

@Component({
  selector: "rota-summary-table",
  templateUrl: "./rota-summary-table.component.html",
  styleUrls: ["./rota-summary-table.component.scss"],
})
export class RotaSummaryTableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("scrollContainer") private scrollContainer: ElementRef;
  @ViewChild("timelineContainer") private timelineContainer: ElementRef;
  @ViewChild("nextRoleMarker") private nextRoleMarker: ElementRef;
  @ViewChildren("shifts", { read: ElementRef })
  private emptyShifts: QueryList<ElementRef>;

  @ViewChild(CdkOverlayOrigin, { static: false })
  overlayOrigin: CdkOverlayOrigin;

  public positions = [
    new ConnectionPositionPair(
      { originX: "center", originY: "bottom" },
      { overlayX: "center", overlayY: "top" },
    ),
    new ConnectionPositionPair(
      { originX: "center", originY: "top" },
      { overlayX: "center", overlayY: "bottom" },
    ),
  ];

  public overlayEmployeeId: number;
  public overlayEmployeeFullName: string;

  public openEmployeePopover = false;

  scrollCount = 0;
  isLoaded = false;
  protected readonly ShiftTypeStatus = ShiftTypeStatus;
  showOptionsTooltip = false;
  isRoleChanging = false;
  notes = "";

  availableList = [];
  otherList = [];

  otherListFiltered = [];
  availableListFiltered = [];

  selectedShifts = [];

  previousDay = [];
  shifts_list = [];
  empty_shifts_list: IUnfilledShift[] = [];

  next_role_shifts_list = [];
  next_role_empty_shifts_list = [];

  date = "";
  day = "";
  roleName = "";
  fillerHeight = 0;

  type: "Schedule" | "Confirmed" | "Blend" = "Blend";
  result: any = null;

  careHomeId: number = null;
  roleId: number = null;
  dayDate: string = null;
  tableIdentifier: string = null;
  ctrlPressed = false;
  teamUid: string;
  isDialogOpen = false;

  selectedRoleIdIndex: number = null;

  draggableItem: any = null;
  addShiftDragItem: any = null;

  searchValue: string = "";
  private searchTerm = new Subject<string>();

  roles: ProfessionalRolesModel[] = [];
  private subscription = new Subscription();

  resizingEvent = {
    isResizing: false,
    startingCursorX: 0,
    shift: null,
    startingWidth: 0,
    starringOffset: 0,
    direction: null,
  };

  minuteInPx: number = null;
  hourInPx: number = null;
  constructor(
    private tableService: TableService,
    private careHomesService: CareHomesService,
    private dictionariesService: DictionariesService,
    private authUserService: AuthUserService,
    private dialogService: NbWindowService,
    private toastrService: NbToastrService,
    private rotaTableService: RotaTableService,
    private rotaSummaryService: RotaSummaryService,
  ) {}

  ngOnInit(): void {
    if (this.tableService.getValue()) {
      this.careHomeId = this.authUserService.getCareHomeId();
      this.roleId = this.tableService.getValue().roleId;
      this.dayDate = this.tableService.getValue().dayDate;
      this.tableIdentifier = this.tableService.getValue().tableIdentifier;
      this.teamUid = this.tableService.getValue().teamUid;

      this.loadData();
      this.subscription.add(
        this.dictionariesService
          .getProfessionalRoles(true, this.teamUid)
          .subscribe((response: any) => {
            this.roles = response.result;
            this.selectedRoleIdIndex = response.result.findIndex(
              (i) => i.id === this.roleId,
            );
            this.loadNextRoleData();
          }),
      );

      this.subscription.add(
        this.rotaSummaryService.reloadSummaryTable$.subscribe(() => {
          this.loadData();
        }),
      );
    }

    this.subscription.add(
      this.searchTerm
        .pipe(debounceTime(200), distinctUntilChanged())
        .subscribe((val) => {
          if (val.length > 0) {
            this.availableListFiltered = this.availableList.filter((e) =>
              e.employeeFullName.toLowerCase().includes(val.toLowerCase()),
            );
            this.otherListFiltered = this.otherList.filter((e) =>
              e.employeeFullName.toLowerCase().includes(val.toLowerCase()),
            );
          } else {
            this.availableListFiltered = this.availableList;
            this.otherListFiltered = this.otherList;
          }
        }),
    );
  }

  ngAfterViewInit() {
    this.subscription.add(
      this.emptyShifts.changes.subscribe(() => {
        this.addRemovePadding();
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private loadData() {
    this.availableList = [];
    this.otherList = [];
    this.isLoaded = false;
    this.subscription.add(
      this.careHomesService
        .getRotaEnhanced(this.careHomeId, this.roleId, this.dayDate)
        .subscribe((result: any) => {
          this.date = result.result.dayDate;
          this.day = result.result.dayName;
          this.result = result.result;
          this.notes = this.result.notes;
          this.roleName = this.result.roleName;
          this.otherList = this.result.otherStuff;
          this.otherListFiltered = this.result.otherStuff;

          this.previousDay = this.result.previousDay;
          this.shifts_list = this.result.list;
          this.empty_shifts_list = this.result.unfielledList;

          const dayView = document.getElementById("day-view-wrapper");
          const fullDay = parseInt(
            dayView.getBoundingClientRect().width.toString(),
            10,
          );
          this.hourInPx = fullDay / 24;
          this.minuteInPx = fullDay / 24 / 60;

          this.availableList = result.result.availableStuff;
          this.availableListFiltered = result.result.availableStuff;

          this.isLoaded = true;

          const len_empty = this.empty_shifts_list.length;
          const len_displayed = this.howManyDisplayed();

          this.checkMarkerPosition(len_displayed + len_empty);

          setTimeout(() => {
            this.addRemovePadding();
          });
          this.isRoleChanging = false;
        }),
    );

    if (this.roles.length > 1) this.loadNextRoleData();
  }

  public howManyDisplayed(): number {
    return this.shifts_list.reduce((acc, curr) => {
      return acc + (curr.statusId !== ShiftTypeStatus.Sick ? 1 : 0);
    }, 0);
  }

  @HostListener("window:wheel", ["$event"])
  onWheelScroll(event: WheelEvent) {
    if (!this.isWithinBounds(event) || this.isDialogOpen) {
      return;
    }

    if (this.roles.length <= 1) {
      return;
    }

    this.handleScrollEvent(event);
  }

  @HostListener("document:keydown.escape", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    this.rotaSummaryService.buttonsOpened.next("");
  }

  private isWithinBounds(event: WheelEvent): boolean {
    const element = this.scrollContainer.nativeElement;
    const rect = element.getBoundingClientRect();

    return (
      event.clientX >= rect.left &&
      event.clientX <= rect.right &&
      event.clientY >= rect.top &&
      event.clientY <= rect.bottom
    );
  }

  private addRemovePadding() {
    this.emptyShifts.forEach((shift) => {
      if (shift.nativeElement.clientWidth <= 30) {
        shift.nativeElement.classList.add("no-padding-sides");
        return;
      }

      shift.nativeElement.classList.remove("no-padding-sides");
    });
  }

  private handleScrollEvent(event: WheelEvent): void {
    if (event.deltaY > 0) {
      const element = this.scrollContainer.nativeElement;

      if (
        Math.ceil(element.scrollTop + element.clientHeight) >=
        element.scrollHeight
      ) {
        this.scrollCount++;

        if (this.scrollCount === 5) {
          if (!this.isRoleChanging) {
            this.changeRoleById(this.incrementIndex());
          }

          this.scrollCount = 0;
        }
      }
    } else {
      this.scrollCount = 0;
    }
  }

  private changeRoleById(index: number): void {
    this.roleId = this.getRoleIdByIndex(index);
    this.changeRole(true);
  }

  public incrementIndex(): number {
    return this.selectedRoleIdIndex + 1 < this.roles.length
      ? this.selectedRoleIdIndex + 1
      : 0;
  }

  private loadNextRoleData() {
    const nextDayRoleId = this.getRoleIdByIndex(this.incrementIndex());

    if (!nextDayRoleId) {
      return;
    }

    this.subscription.add(
      this.careHomesService
        .getRotaEnhanced(this.careHomeId, nextDayRoleId, this.dayDate)
        .subscribe((result: any) => {
          this.next_role_shifts_list = result.result.list;
          this.next_role_empty_shifts_list = result.result.unfielledList;
        }),
    );
  }

  close() {
    this.tableService.closeWindow(false);
    this.rotaTableService.reload$.next(this.tableIdentifier);
  }

  trackByFn(index, item) {
    return index;
  }

  public deleteShift(shift, dayDate: string) {
    this.rotaSummaryService.deleteShift(shift, dayDate);
  }

  onDragStart(employee) {
    this.draggableItem = employee;
  }

  onDragEnd() {
    this.draggableItem = null;
  }

  onAddShiftDragStart(employee) {
    this.addShiftDragItem = employee;
  }

  getStaffLevelByType(value: "am" | "pm" | "night"): string {
    if (!this.result) {
      return;
    }

    if (this.type === "Confirmed" || this.checkIfPastDate()) {
      const key = `${value}ConfirmedDiff`;
      return this.result[key];
    } else {
      const key = `${value}ScheduleDiff`;
      return this.result[key];
    }
  }

  getHoursForDisplay(item: any): string {
    if (!this.result) {
      return "";
    }
    let result = `${item.scheduleFrom} - ${item.scheduleTo}`;

    if (this.type !== "Schedule") {
      if (
        item.statusId === ShiftTypeStatus.Confirmed ||
        item.statusId === ShiftTypeStatus.DifferentThanScheduled
      ) {
        result = `${item.confirmedFrom} - ${item.confirmedTo}`;
      }
    }
    if (item.statusId === undefined) {
      result = `${
        this.type === "Schedule"
          ? item.scheduleFrom
          : item.confirmedFrom || item.scheduleFrom
      } - ${
        this.type === "Schedule"
          ? item.scheduleTo
          : item.confirmedTo || item.scheduleTo
      }`;
    }
    if (result.includes("null")) {
      result = "";
    }
    return result;
  }

  isConfirmed(item): boolean {
    return item.statusId === ShiftTypeStatus.Confirmed;
  }
  isDifferentThanScheduled(item): boolean {
    return item.statusId === ShiftTypeStatus.DifferentThanScheduled;
  }

  isOutOfWork(item) {
    return (
      item.statusId === ShiftTypeStatus.Sick ||
      item.statusId === ShiftTypeStatus.AL ||
      item.statusId === ShiftTypeStatus.TRAINING_SICK ||
      item.statusId === ShiftTypeStatus.TRAINING_AL
    );
  }

  clickOnShift(shift) {
    if (!shift.rotaId) return;

    if (this.selectedShifts.find((i) => i === shift.rotaId)) {
      this.selectedShifts = this.selectedShifts.filter(
        (i) => i !== shift.rotaId,
      );
    } else {
      this.selectedShifts.push(shift.rotaId);
    }
  }

  checkIfSelectedShift(shift: IShift): boolean {
    return this.selectedShifts.includes(shift.rotaId);
  }

  toggleShiftSelection(checked: boolean, shift) {
    if (checked) {
      if (!this.checkIfSelectedShift(shift)) {
        this.selectedShifts.push(shift.rotaId);
      }
    } else {
      if (this.checkIfSelectedShift(shift)) {
        this.selectedShifts = this.selectedShifts.filter(
          (i) => i !== shift.rotaId,
        );
      }
    }
  }

  acceptSelected(confirmedFrom?: string, confirmedTo?: string) {
    from(this.selectedShifts)
      .pipe(
        concatMap((rotaId) => {
          let shift = this.shifts_list.find((item) => item.rotaId === rotaId);
          if (shift === undefined) {
            shift = this.next_role_shifts_list.find(
              (item) => item.rotaId === rotaId,
            );
          }
          const body = {
            confirmedFrom:
              confirmedFrom || this.hourFromMinutes(shift.scheduleFromMinutes),
            confirmedTo:
              confirmedTo || this.hourFromMinutes(this.capScheduleTo(shift)),
            contractId: shift.contractId,
            dayName: this.day,
            rotaUid: shift.rotaUid,
            employeeId: shift.employeeId,
            isConfirm: true,
            roleId: shift.employeePositionId,
            rotaDate: this.result.rotaDate,
            weekStartAt: shift.weekStartAt,
          };
          return this.careHomesService
            .updateConfirmedEnhanced(this.authUserService.getCareHomeId(), body)
            .pipe(map(() => rotaId));
        }),
      )
      .subscribe({
        next: (rotaId) => {
          this.loadData();
          this.selectedShifts = this.selectedShifts.filter(
            (id) => id !== rotaId,
          );
        },
        error: (err) => {
          this.toastrService.danger(getErrorMessage(err), "Error", {
            duration: 60000,
            destroyByClick: true,
          });
        },
      });
  }

  denySelected() {
    this.acceptSelected("00:00", "00:00");
  }

  clearSelected() {
    this.selectedShifts.forEach((rotaId) => {
      let shift = this.shifts_list.find((item) => item.rotaId === rotaId);
      if (shift === undefined) {
        shift = this.next_role_shifts_list.find(
          (item) => item.rotaId === rotaId,
        );
      }

      this.rotaSummaryService.deleteShift(shift, this.result.rotaDate);

      this.selectedShifts = this.selectedShifts.filter((id) => id !== rotaId);
    });
  }

  onAddShiftDragEnd() {
    this.addShiftDragItem = null;
  }

  addShift(employeeId?: number, time?: "AM" | "PM" | "NIGHT") {
    this.showOptionsTooltip = false;
    if (this.isDialogOpen) return;
    if (!!!this.result) return;

    this.isDialogOpen = true;
    this.dialogService
      .open(AddEditShiftComponent, {
        title: "Add new",
        hasBackdrop: true,
        context: {
          dayData: this.result,
          roles: this.roles,
          selectedRole: this.roleId,
          careHomeId: this.careHomeId,
          employeeId: employeeId,
          shiftType: time,
          canHaveExtras: true,
        },
        windowClass: "expanded-body",
      })
      .onClose.subscribe((res) => {
        this.isDialogOpen = false;
        if (res === null || res === undefined) return;

        if (
          res.from === null ||
          res.from === undefined ||
          res.to === null ||
          res.to === undefined ||
          res.role === null ||
          res.role === undefined
        )
          return;

        const body = {
          roleId: res.role,
          employeeId: res.employeeId ? res.employeeId : undefined,
          rotaDate: this.result.rotaDate,
          scheduleFrom: res.from,
          scheduleTo: res.to,
          isScheduleMode: this.type === "Schedule",
          careHomeId: this.authUserService.getCareHomeId(),
          isExtrasToBeTaken: res.isExtrasToBeTaken ? true : undefined,
          isExtrasPublished: res.isExtrasToBeTaken
            ? res.extrasPublished
            : undefined,
        };

        this.rotaSummaryService.updateHoursSummary(body);
      });
  }

  addNewShift(from: string, to: string, event?: DropEvent) {
    let role = null;
    if (
      this.roles.length > 1 &&
      event.nativeEvent.clientY >
        this.nextRoleMarker.nativeElement.getBoundingClientRect().bottom
    ) {
      role = this.getRoleIdByIndex(this.incrementIndex());
    }

    if (this.addShiftDragItem) {
      let body = {
        roleId: role || this.roleId,
        employeeId: this.addShiftDragItem.employeeId,
        rotaDate: this.result.rotaDate,
        scheduleFrom: from,
        scheduleTo: to,
        careHomeId: this.authUserService.getCareHomeId(),
        isScheduleMode: this.type === "Schedule",
      };
      this.rotaSummaryService.updateHoursSummary(body);
    }
    this.addShiftDragItem = null;
  }

  editShift(shift, roleId?: number, canHaveExtras?: boolean) {
    if (this.isDialogOpen) return;
    if (this.isOutOfWork(shift)) return;
    if (!!!this.result) return;

    this.isDialogOpen = true;

    this.dialogService
      .open(AddEditShiftComponent, {
        title: "Edit shift",
        hasBackdrop: true,
        context: {
          dayData: this.result,
          from:
            this.type === "Schedule"
              ? shift.scheduleFrom
              : this.isConfirmed(shift) || this.isDifferentThanScheduled(shift)
                ? shift.confirmedFrom
                : shift.scheduleFrom,
          to:
            this.type === "Schedule"
              ? shift.scheduleTo
              : this.isConfirmed(shift) || this.isDifferentThanScheduled(shift)
                ? shift.confirmedTo
                : shift.scheduleTo,
          employeeId: shift.employeeId,
          selectedRole: roleId,
          roles: roleId ? this.roles : undefined,
          canHaveExtras: canHaveExtras,
        },
        windowClass: "expanded-body",
      })
      .onClose.subscribe((res) => {
        this.isDialogOpen = false;
        if (res && res.from && res.to) {
          let body: any = {
            roleId: roleId || shift.employeePositionId,
            employeeId: res.employeeId || shift.employeeId,
            rotaDate: this.result.rotaDate,
            rotaUid: shift.rotaUid,
            careHomeId: shift.careHomeId,
            isExtrasToBeTaken: res.isExtrasToBeTaken ? true : undefined,
            isExtrasPublished: res.isExtrasToBeTaken
              ? res.extrasPublished
              : undefined,
          };

          switch (this.type) {
            case "Confirmed": {
              body.confirmedFrom = res.from;
              body.confirmedTo = res.to;
              break;
            }
            case "Schedule": {
              body.scheduleFrom = res.from;
              body.scheduleTo = res.to;
              body.isScheduleMode = true;
              this.rotaSummaryService.updateHoursSummary(body);
              break;
            }
            case "Blend": {
              if (
                this.isConfirmed(shift) ||
                this.isDifferentThanScheduled(shift)
              ) {
                body.confirmedFrom = res.from;
                body.confirmedTo = res.to;
                body.contractId = shift.contractId;
                body.dayName = this.day;
                body.isConfirm = true;
                body.weekStartAt = shift.weekStartAt;

                this.rotaSummaryService.updateConfirmedSummary(body);
              } else {
                body.scheduleFrom = res.from;
                body.scheduleTo = res.to;
                body.isScheduleMode = false;

                this.rotaSummaryService.updateHoursSummary(body);
              }
            }
          }
        }
      });
  }

  getTooltipInfo(type: "am" | "pm" | "night"): string {
    if (!this.result) {
      return null;
    }
    if (this.type === "Confirmed") {
      return this.result[`${type}ConfirmedDiffInfo`];
    } else {
      return this.result[`${type}ScheduleDiffInfo`];
    }
  }

  changeType(type: "Blend" | "Confirmed" | "Schedule") {
    this.type = type;
    this.loadData();
    this.showOptionsTooltip = false;
  }

  checkIfPastDate() {
    return moment(new Date()).diff(moment(this.dayDate), "days") > 0;
  }

  getTypeName(): string {
    return this.type === "Blend" ? "Default" : this.type;
  }

  changeDay(type: "plus" | "minus") {
    let day = this.dayDate;
    if (type === "minus") {
      day = moment(day, "YYYY/MM/DD").subtract(1, "d").format("YYYY/MM/DD");
    } else {
      day = moment(day, "YYYY/MM/DD").add(1, "d").format("YYYY/MM/DD");
    }
    this.dayDate = day;
    this.date = day;
    this.loadData();
  }

  changeRole(event) {
    this.isRoleChanging = true;
    this.selectedRoleIdIndex = this.roles.findIndex(
      (i) => i.id === this.roleId,
    );

    this.tableService.setValue({
      ...this.tableService.getValue(),
      roleId: this.roleId,
    });

    this.loadData();
  }

  search(searchText) {
    this.searchTerm.next(searchText);
  }

  get isLastRoleSelected(): boolean {
    return this.selectedRoleIdIndex === this.roles.length - 1;
  }

  changeRoleFromArrow(type: "up" | "down") {
    if (type === "up") {
      this.selectedRoleIdIndex--;
      this.roleId = this.roles[this.selectedRoleIdIndex].id;
    } else {
      this.selectedRoleIdIndex++;
      if (this.selectedRoleIdIndex >= this.roles.length) {
        this.selectedRoleIdIndex--;
      } else {
        this.roleId = this.roles[this.selectedRoleIdIndex].id;
      }
    }
    this.loadData();
  }

  get getLastRoleName(): string {
    return this.roles[this.incrementIndex()]?.roleName || null;
  }

  get currentRoleName(): string {
    return this.roles[this.selectedRoleIdIndex]?.roleName || null;
  }

  public getRoleIdByIndex(index: number): number {
    return this.roles[index]?.id || null;
  }

  trackByPreviousDay(index, item) {
    return item.rotaId;
  }

  onDropOnEmptyShift(event, shift, roleId?: number) {
    const body = {
      roleId: roleId || this.roleId,
      employeeId: event.dragData.employeeId,
      rotaDate: this.result.rotaDate,
      scheduleFrom: shift.scheduleFrom,
      scheduleTo: shift.scheduleTo,
      isScheduleMode: this.type === "Schedule",
      careHomeId: this.authUserService.getCareHomeId(),
    };
    this.addShiftDragItem = null;
    this.rotaSummaryService.updateHoursSummary(body);
  }

  startResize(event, shift, direction: "left" | "right" | "move") {
    let shiftWidth = shift.shiftScheduleDuration;
    let shiftOffset = shift.scheduleFromMinutes;

    if (
      this.type !== "Schedule" &&
      (this.isConfirmed(shift) || this.isDifferentThanScheduled(shift))
    ) {
      shiftWidth = shift.shiftConfirmedDuration;
      shiftOffset = shift.confirmedFromMinutes;
    }

    this.resizingEvent = {
      isResizing: true,
      startingCursorX: event.clientX,
      shift: shift,
      startingWidth: shiftWidth * this.minuteInPx,
      starringOffset: shiftOffset * this.minuteInPx,
      direction: direction,
    };
  }

  @HostListener("window:mousemove", ["$event"])
  updateShiftDuration(event: MouseEvent) {
    if (!this.resizingEvent.isResizing) {
      return;
    }

    const precision = 5;

    const cursorDeltaX = event.clientX - this.resizingEvent.startingCursorX;

    switch (this.resizingEvent.direction) {
      case "left": {
        const newOffset = this.resizingEvent.starringOffset + cursorDeltaX;
        const newWidth = this.resizingEvent.startingWidth - cursorDeltaX;

        if (newOffset < 0) {
          break;
        }

        if (
          this.type !== "Schedule" &&
          (this.resizingEvent.shift.statusId === ShiftTypeStatus.Confirmed ||
            this.resizingEvent.shift.statusId ===
              ShiftTypeStatus.DifferentThanScheduled)
        ) {
          this.resizingEvent.shift.confirmedFromMinutes =
            Math.round(newOffset / this.minuteInPx / precision) * precision;

          this.resizingEvent.shift.shiftConfirmedDuration =
            Math.round(newWidth / this.minuteInPx / precision) * precision;
        } else {
          this.resizingEvent.shift.scheduleFromMinutes =
            Math.round(newOffset / this.minuteInPx / precision) * precision;

          this.resizingEvent.shift.shiftScheduleDuration =
            Math.round(newWidth / this.minuteInPx / precision) * precision;
        }
        break;
      }
      case "right": {
        const newWidth = this.resizingEvent.startingWidth + cursorDeltaX;

        if (
          this.type !== "Schedule" &&
          (this.resizingEvent.shift.statusId === ShiftTypeStatus.Confirmed ||
            this.resizingEvent.shift.statusId ===
              ShiftTypeStatus.DifferentThanScheduled)
        ) {
          this.resizingEvent.shift.shiftConfirmedDuration =
            Math.round(newWidth / this.minuteInPx / precision) * precision;
          break;
        } else {
          this.resizingEvent.shift.shiftScheduleDuration =
            Math.round(newWidth / this.minuteInPx / precision) * precision;
        }
        break;
      }

      case "move": {
        const newOffset = this.resizingEvent.starringOffset + cursorDeltaX;

        if (
          !(
            // (newOffset + this.resizingEvent.startingWidth) / this.minuteInPx <
            //   1440 &&
            (newOffset >= 0)
          )
        )
          break;

        if (
          this.type !== "Schedule" &&
          (this.resizingEvent.shift.statusId === ShiftTypeStatus.Confirmed ||
            this.resizingEvent.shift.statusId ===
              ShiftTypeStatus.DifferentThanScheduled)
        ) {
          this.resizingEvent.shift.confirmedFromMinutes =
            Math.round(newOffset / this.minuteInPx / precision) * precision;
        } else {
          this.resizingEvent.shift.scheduleFromMinutes =
            Math.round(newOffset / this.minuteInPx / precision) * precision;
        }
      }
    }

    this.updateDisplayHours(this.resizingEvent.shift);
  }

  updateDisplayHours(shift) {
    if (
      this.type !== "Schedule" &&
      (this.resizingEvent.shift.statusId === ShiftTypeStatus.Confirmed ||
        this.resizingEvent.shift.statusId ===
          ShiftTypeStatus.DifferentThanScheduled)
    ) {
      shift.confirmedFrom = this.hourFromMinutes(shift.confirmedFromMinutes);
      shift.confirmedTo = this.hourFromMinutes(this.capConfirmedTo(shift));
    } else {
      shift.scheduleFrom = this.hourFromMinutes(shift.scheduleFromMinutes);
      shift.scheduleTo = this.hourFromMinutes(this.capScheduleTo(shift));
    }
  }

  @HostListener("window:click")
  deselectForResize() {
    if (this.resizingEvent.isResizing) {
      return;
    }

    this.selectedShifts = [];
    this.resizingEvent.shift = null;
    this.rotaSummaryService.buttonsOpened.next("");
  }

  @HostListener("window:mouseup")
  stopResizing() {
    if (
      !this.resizingEvent.shift ||
      !this.resizingEvent.shift.employeeId ||
      !this.resizingEvent.isResizing
    ) {
      return;
    }

    if (
      this.type !== "Schedule" &&
      (this.isConfirmed(this.resizingEvent.shift) ||
        this.isDifferentThanScheduled(this.resizingEvent.shift))
    ) {
      let body: IConfirmHoursBody = {
        confirmedFrom: this.hourFromMinutes(
          this.resizingEvent.shift.confirmedFromMinutes,
        ),
        confirmedTo: this.hourFromMinutes(
          this.capConfirmedTo(this.resizingEvent.shift),
        ),
        contractId: this.resizingEvent.shift.contractId,
        dayName: this.day,
        employeeId: this.resizingEvent.shift.employeeId,
        isConfirm: true,
        rotaUid: this.resizingEvent.shift.rotaUid,
        roleId: this.resizingEvent.shift.employeePositionId,
        rotaDate: this.result.rotaDate,
        weekStartAt: this.resizingEvent.shift.weekStartAt,
        careHomeId: this.resizingEvent.shift.careHomeId,
      };

      this.rotaSummaryService.updateConfirmedSummary(body);
    } else {
      console.log(this.resizingEvent.shift);

      let body: IUpdateHoursBody = {
        roleId: this.resizingEvent.shift.employeePositionId,
        employeeId: this.resizingEvent.shift.employeeId,
        rotaDate: this.result.rotaDate,
        rotaUid: this.resizingEvent.shift.rotaUid,
        scheduleFrom: this.hourFromMinutes(
          this.resizingEvent.shift.scheduleFromMinutes,
        ),
        scheduleTo: this.hourFromMinutes(
          this.capScheduleTo(this.resizingEvent.shift),
        ),
        isScheduleMode: this.type === "Schedule",
        careHomeId: this.resizingEvent.shift.careHomeId,
      };

      this.rotaSummaryService.updateHoursSummary(body);
    }

    this.resizingEvent.isResizing = false;
    this.toggleShiftSelection(false, this.resizingEvent.shift);
  }

  private capScheduleTo(shift) {
    const scheduleToMinutes =
      shift.scheduleFromMinutes + shift.shiftScheduleDuration;

    // if (scheduleToMinutes > 1440) {
    //   return shift.scheduleToMinutes;
    // }

    return scheduleToMinutes;
  }
  private capConfirmedTo(shift) {
    const confirmedToMinutes =
      shift.confirmedFromMinutes + shift.shiftConfirmedDuration;

    if (confirmedToMinutes > 1440) {
      return shift.confirmedToMinutes;
    }

    return confirmedToMinutes;
  }

  public capScheduleWidth(shiftOffset, shiftWidth) {
    const scheduleToMinutes = shiftOffset + shiftWidth;

    if (scheduleToMinutes > 1440) {
      return 1440 - shiftOffset + 20;
    }

    return shiftWidth;
  }

  hourFromMinutes(minutes: number) {
    if (minutes > 1440) minutes = minutes - 1440;

    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;

    return `${hours}:${minutesLeft < 10 ? "0" : ""}${minutesLeft}`;
  }

  shiftSelectForResize(shift) {
    if (this.isOutOfWork(shift)) return;

    if (this.ctrlPressed) return;

    if (this.resizingEvent.shift === shift) {
      this.resizingEvent.shift = null;
      this.toggleShiftSelection(false, shift);
      return;
    }

    if (this.resizingEvent.shift) {
      this.toggleShiftSelection(false, this.resizingEvent.shift);
    }
    this.toggleShiftSelection(true, shift);

    this.resizingEvent.shift = shift;
  }

  getShiftTooltip(shift) {
    let from = shift.scheduleFrom || shift.confirmedFrom;
    let to = shift.scheduleTo || shift.confirmedTo;
    let hrs = shift.scheduledHrs || shift.confirmedHrs;

    if (
      this.type !== "Schedule" &&
      (shift.statusId === ShiftTypeStatus.Confirmed ||
        shift.statusId === ShiftTypeStatus.DifferentThanScheduled)
    ) {
      from = shift.confirmedFrom;
      to = shift.confirmedTo;
      hrs = shift.confirmedHrs;
    }

    return `${shift.employeeFullName} ${from} - ${to} (${hrs}h)`;
  }

  isInResize(shift): boolean {
    return this.resizingEvent.shift === shift;
  }

  @HostListener("window:keydown", ["$event"])
  keyDownEvent(event: KeyboardEvent) {
    if (event.key === "Control") {
      this.ctrlPressed = true;
    }
  }

  @HostListener("window:keyup", ["$event"])
  keyUpEvent(event: KeyboardEvent) {
    if (event.key === "Control") {
      this.ctrlPressed = false;
    }
  }

  checkMarkerPosition(length: number) {
    const nextRoleMarkerPosition =
      this.timelineContainer.nativeElement.getBoundingClientRect().top +
      66 * length;
    const bottomPosition =
      this.timelineContainer.nativeElement.getBoundingClientRect();

    if (
      bottomPosition.bottom - nextRoleMarkerPosition <= 100 ||
      nextRoleMarkerPosition > bottomPosition.bottom
    ) {
      this.fillerHeight = 0;
      return;
    }

    this.fillerHeight = -(nextRoleMarkerPosition - bottomPosition.bottom) - 200;
  }

  saveNotes() {
    const data = {
      notes: this.notes,
      date: this.dayDate,
      roleId: this.roleId,
    };

    this.subscription.add(
      this.careHomesService
        .updateSchedulesEnhancedNote(this.authUserService.getCareHomeId(), data)
        .subscribe(
          (response: any) => {
            this.toastrService.success(response.message, "Success");
            this.rotaTableService.reload$.next(this.tableIdentifier);
          },
          (err: any) => {
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
          },
        ),
    );
  }

  public calculateEmptyShiftWidth(shift: IUnfilledShift): number {
    return shift.scheduleDuration * this.minuteInPx;
  }

  public calculateEmptyShiftOffset(shift: IUnfilledShift): number {
    return shift.scheduleOffsetLeft * this.minuteInPx;
  }

  public calculateShiftWidht(shift: IShift): number {
    return this.type !== "Schedule" &&
      (shift.statusId === ShiftTypeStatus.Confirmed ||
        shift.statusId === ShiftTypeStatus.DifferentThanScheduled)
      ? this.capScheduleWidth(
          shift.confirmedFromMinutes,
          shift.shiftConfirmedDuration,
        ) * this.minuteInPx
      : this.capScheduleWidth(
          shift.scheduleFromMinutes,
          shift.shiftScheduleDuration,
        ) * this.minuteInPx;
  }

  public calculateShiftOffset(shift: IShift): number {
    return this.type !== "Schedule" &&
      (shift.statusId === ShiftTypeStatus.Confirmed ||
        shift.statusId === ShiftTypeStatus.DifferentThanScheduled)
      ? shift.confirmedFromMinutes * this.minuteInPx
      : shift.scheduleFromMinutes * this.minuteInPx;
  }

  getHoursForDisplayEmpty(shift: IUnfilledShift): string {
    return `${shift.scheduleFrom} - ${shift.scheduleTo}`;
  }

  openEmployee360(employeeId: number, employeeFullName: string) {
    this.tableService.openNewWindowAndCloseThis(
      EditEmployeeComponent,
      `Employee Profile Information - ${employeeFullName}`,
      { elementId: employeeId, noReload: true },
    );
  }

  toggleOverlayForEmployee(employeeId: number, employeeFullName: string) {
    this.overlayEmployeeId = employeeId;
    this.overlayEmployeeFullName = employeeFullName;
  }
}
