<div class="content">
    <div class="head mb-1">
        <div class="read-status">
            <img class="message-icon" src="../../../../../assets/images/message.png" alt="">
            <span class="ml-4 mr-1">read at :</span>
            <span *ngIf="!message.readed">unread</span>
            <span *ngIf="message.readed">
                {{message.readedAt}}
            </span>
        </div>
        <div class="read-status">
            <span class="mr-4">sent at : {{message.careatedAt}}</span>
            <button class="btn-icon close" (click)='dialogRef.close()'>
                <nb-icon icon="close"></nb-icon>
            </button>
        </div>
    </div>
    <div class="shadow-wrapper">
        <div class="message-wrapp">
            <h2><span>Subject:</span> {{message.subject}}</h2>
            <div class="message-header mt-3 mb-1">
                <div class="user-data flex mb-3">
                    <h2>From: </h2>
                    <ngx-image-viewer
                        [imageData]="avatarSender">
                    </ngx-image-viewer>
                    <div  class="sender-data ml-2">
                        <span>{{message.fromName}} ({{message.fromEmail}})</span>
                        <div class="job-title">Employee</div>
                    </div>
                </div>
                <div class="user-data flex mb-3">
                    <h2>To: </h2>
                    <ngx-image-viewer
                        [imageData]="avatarTo">
                    </ngx-image-viewer>
                    <div  class="sender-data ml-2">
                        <span>{{message.toName}} ({{message.toEmail}})</span>
                        <div class="job-title">Employee</div>
                    </div>
                </div>
            </div>
            <div class="message-text mt-1" [innerHTML]="setUpText(message.message | linkify)" readonly></div>
            <div class="attachments">
                Attachments
                <div class="files flex mt-3">
                    <div (click)="downloadDocument(file.uid)" class="file-wrapper flex mr-5" *ngFor="let file of message.attachmentsList">
                        <div class="file">
                            <ngx-image-viewer
                                *ngIf='file.type=="image/jpeg" || file.type =="image/png"'
                                [imageData]="file.url">
                            </ngx-image-viewer>
                            <img *ngIf='file.type=="application/pdf"' src="../../../../assets/images/pdf.png" alt="">
                            <img *ngIf='file.type!="image/jpeg"
                                    && file.type !="image/png"
                                    && file.type !="application/pdf"' src="../../../../assets/images/document.png" alt="">
                            <span>{{file.fileName}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
