import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import { MessageToSupportComponent } from '../@theme/components/message-to-support/message-to-support.component';

@Injectable({
  providedIn: 'root'
})
export class ScreenshotService {

  showScreenshotInfo = false;
  constructor(private dialog: MatDialog) { }

  captureScreen() {
    this.getScreen().then((image) => {
      this.dialog.open(MessageToSupportComponent, {
        width: '800px',
        data: {
          image,
          context: 'Support',
          supportArea: 'generalHelp'
        }
      });
    });
  }

  getScreen(): Promise<string> {
    return new Promise(resolve => {
      this.showScreenshotInfo = true;
      html2canvas(document.getElementById('application-main-content'), { scale: 0.5 }).then(canvas => {
        canvas.style.display = 'none';
        document.body.appendChild(canvas);
        return canvas;
      })
      .then(canvas => {
          const image = canvas.toDataURL('image/png');
          canvas.remove();
          this.showScreenshotInfo = false;
          
          resolve(image);
      });
    });
  }
}
