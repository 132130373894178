<div class="form-view">
  <main>
    <form [formGroup]="form">
      <div class="form-control-group flex">
        <div class="mr-1">
          <label class="label" for="input-start-date">Start date:</label>
          <input nbInput
                  fullWidth
                  formControlName="startDate"
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="startDatepicker"
                  autofocus>
                <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatepicker startView="month"></mat-datepicker>
        </div>
        <div>
          <label class="label" for="input-cancellation-date">Shorten to date:</label>
          <input nbInput
                  fullWidth
                  formControlName="cancellationDate"
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="cancellationDatepicker"
                  autofocus>
                <mat-datepicker-toggle matSuffix [for]="cancellationDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #cancellationDatepicker startView="month"></mat-datepicker>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'cancellationDate', errorMessages) }}
          </p>
          <p *ngIf="isDateStartError" class="error-message">
            Shorten to date must be after start date!
          </p>
        </div>
        <div *ngIf="type !== 'Contract'" class="ml-1">
          <label class="label" for="input-first-day-available">First Day Available (leave blank if return date unknown):</label>
          <input nbInput
                  fullWidth
                  formControlName="firstDayAvailable"
                  id="input-first-day-available"
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="firstDayAvailableDatepicker"
                  autofocus>
                <mat-datepicker-toggle matSuffix [for]="firstDayAvailableDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #firstDayAvailableDatepicker startView="month"></mat-datepicker>
                <div *ngIf="form.get('firstDayAvailable').value" id="clear-date" (click)="clearFirstDayAvailable()">x</div>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'firstDayAvailable', errorMessages) }}
          </p>
        </div>
      </div>
      <div class="form-control-group textarea-container">
        <label class="label" for="input-cancellation-description">Shorten reasons:</label>
        <textarea nbInput
                  fullWidth
                  formControlName="cancellationDescription"
                  placeholder="Rejection reasons"
                  minlength="3"
                  maxlength="5000"
                  autofocus>
        </textarea>
        <p class="error-message">
          {{ utils.handleValidationErrorMessage(form, 'cancellationDescription', errorMessages) }}
        </p>
      </div>
    </form>
  </main>
  <footer>
    <button class="btn btn-success longer display-flex display-flex--center-center"
            (click)="saveData()"
            [disabled]="!utils.isFormValid(form) || isDateStartError || isDateEndError">
      <i class="nb-checkmark" aria-hidden="true"></i> {{ cancelLabel }}
    </button>
    <button class="btn btn-danger ml-4"
            (click)="closeWindow()">
      <i class="nb-close" aria-hidden="true"></i> Close
    </button>
  </footer>
</div>