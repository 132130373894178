import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class HolidaysService {

  constructor(private httpClient: HttpClient) {}

  getHolidays() {
    return this.httpClient.get(`${environment.apiUrl}/holidays`);
  }

  addHoliday(data: any) {
    return this.httpClient.post(`${environment.apiUrl}/holidays`, data);
  }

  updateHoliday(id: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}/holidays/${id}`, data);
  }

  deleteHoliday(id: number) {
    return this.httpClient.delete(`${environment.apiUrl}/holidays/${id}`);
  }

}
