import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TaskBoardsApiUrls } from './task-boards.enum';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';

@Injectable()
export class TaskBoardsService {
  constructor(private httpClient: HttpClient) {}

  getCareHomeTaskBoardCareHomes(taskboardId: number) {
    return this.httpClient.get(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}/${taskboardId}/care-homes`);
  }

  getCareHomeTaskBoards(careHomeId: number) {
    return this.httpClient.get(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}?careHomeId=${careHomeId}&shortForm=true`);
  }

  getTaskBoardsWithMyAccess(careHomeId: number) {
    return this.httpClient.get(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}?haveAccess=true&careHomeId=${careHomeId}`);
  }

  getAdminTaskBoards() {
    return this.httpClient.get(`${environment.apiUrl}${TaskBoardsApiUrls.adminTaskBoards}`);
  }

  getAllICanAddTicketsTaskboards() {
    return this.httpClient.get(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}?onlyMineCanAddTasks=true&shortForm=true`);
  }

  getOnlyMineTaskBoards(careHomeId: number, shortForm = false) {
    return this.httpClient.get(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}?onlyMineCanAddTasks=true&careHomeId=${careHomeId}&shortForm=${shortForm}`);
  }

  getTaskBoardForGroup(groupId: number) {
    return this.httpClient.get(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}?groupId=${groupId}`);
  }

  getGroupsForTaskboard(taskboardId: any) {
    return this.httpClient.get(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}/${taskboardId}/grouping`);
  }

  getShortTaskBoardList() {
    return this.httpClient.get(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}?shortForm=true`);
  }

  getTaskBoard(id: any) {
    return this.httpClient.get(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}/${id}`);
  }

  getTaskBoardMultipleWatchers(id: any) {
    return this.httpClient.get(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}/${id}/multiple-watchers`);
  }

  getTaskBoardHistory(id: any, raggNo: any, raggId: any, startDate: any, endDate: any) {
    return this.httpClient.get(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}/${id}/ragg-statistics/${raggNo}?raggId=${raggId}&startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}`);
  }

  addTaskBoard(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}`, data);
  }

  updateTaskBoard(id: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}/${id}`, data);
  }

  updateTaskBoardGroup(id: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}/${id}/groups`, data);
  }

  deleteTaskBoard(id: number) {
    return this.httpClient.delete(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}/${id}`);
  }

  getUserTaskboardsPermissions(userId: number) {
    return this.httpClient.get(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}/permissions-report?userId=${userId}`);
  }

  updateUserTaskboardPermissions(taskboardId: number, permissions: any) {
    return this.httpClient.put(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}/${taskboardId}/permissions`, permissions);
  }

  deleteUserTaskboardPermissions(taskboardId: number, userId: number) {
    return this.httpClient.delete(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}/${taskboardId}/permissions/${userId}`);
  }

  updateTaskboardComment(taskboardId: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}/${taskboardId}/description`, data);
  }

  expungeTaskboard(taskboardId: any) {
    return this.httpClient.post(`${environment.apiUrl}${TaskBoardsApiUrls.taskBoards}/${taskboardId}/expunge`, {});
  }

}
