<table #table mat-table matSort (matSortChange)="matSortChange.emit($event)" [dataSource]="dataSource" class="awards-table">
  <ng-container matColumnDef="index">
    <th mat-header-cell *matHeaderCellDef class="header num" mat-sort-header [disabled]="disableSorting">
      <span>No.</span>
    </th>
    <td mat-cell *matCellDef="let element; let index = index" [ngStyle]="{ background: element ? element.indexColor : 'transparent' }">
      <span class="num">
        {{ index + 1 }}
        <nb-icon *ngIf="element['attachmentCount'] > 0" icon="attach-2-outline" class="taskboard-icon"></nb-icon>
      </span>
    </td>
    <td mat-footer-cell *matFooterCellDef class="header summary">
      <span>Total</span>
    </td>
  </ng-container>
  <ng-container *ngFor="let column of columns; trackBy: trackByFn" [matColumnDef]="column.fieldName">
    <!-- header -->
    <ng-container *ngIf="column.tooltip">
      <ng-container *ngIf="column.staticWidth">
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="header"
          [nbTooltip]="column.tooltip || column.title"
          [ngStyle]="{
            'min-width': column.staticWidth,
            'max-width': column.staticWidth
          }"
          [disabled]="disableSorting"
        >
          <span>{{ column.charsLimit ? (column.title | charsLimit: column.charsLimit) : column.title }}</span>
        </th>
      </ng-container>
      <ng-container *ngIf="!column.staticWidth">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="header" [nbTooltip]="column.tooltip" [disabled]="disableSorting">
          <span>{{ column.title }}</span>
        </th>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!column.tooltip">
      <ng-container *ngIf="column.staticWidth">
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="header"
          [ngStyle]="{
            'min-width': column.staticWidth,
            'max-width': column.staticWidth
          }"
          [disabled]="disableSorting"
        >
          <span>{{ column.charsLimit ? (column.title | charsLimit: column.charsLimit) : column.title }}</span>
        </th>
      </ng-container>
      <ng-container *ngIf="!column.staticWidth">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="header awards-header" [disabled]="disableSorting">
          <span *ngIf="!column.formatHeader" class="awards-colum">
            <div>{{ column.title }}</div>
          </span>
          <span *ngIf="column.formatHeader" class="awards-colum">
            <ng-container *ngIf="getAteAwardInfo(column?.title) as ateInfo">
              <div>{{ ateInfo[0] }}</div>
              <div class="awards-colum__row--bolder-0">{{ ateInfo[1] }}</div>
            </ng-container>
          </span>
        </th>
      </ng-container>
    </ng-container>
    <!-- header -->
    <!-- content -->
    <td
      mat-cell
      *matCellDef="let element"
      (click)="onRowClick ? onRowClick(element) : ''"
      [ngStyle]="{
        background: element[column.backgroundFieldName],
        cursor: onRowClick ? 'pointer' : ''
      }"
    >
      <ng-container *ngIf="column.fieldType && column.fieldType === 'download'">
        <span style="text-align: center; cursor: pointer; padding: 0; padding-top: 0.1rem"
          ><nb-icon (click)="column.action && column.action(element)" icon="download-outline"></nb-icon
        ></span>
      </ng-container>
      <ng-container *ngIf="column.fieldType && column.fieldType === 'textarea-view'">
        <textarea autosize [maxRows]="7" class="textarea-view" #content [innerHTML]="element[column.fieldName]" readonly> </textarea>
      </ng-container>
      <ng-container *ngIf="column.fieldType && column.fieldType === 'textarea-editable'">
        <textarea autosize [maxRows]="3" class="textarea-editable" (blur)="onTextFieldChange($event)" #content [innerHTML]="element[column.fieldName]"> </textarea>
      </ng-container>
      <ng-container *ngIf="!column.fieldType || column.fieldType !== 'textarea-view'">
        <ng-container *ngIf="column.fieldName != 'avatar'">
          <ng-container *ngIf="!column.tooltipFieldName">
            <span *ngIf="!column.valuePrepareFunction" (click)="column.action ? column.action($event, element) : null" [ngStyle]="{ cursor: column.action ? 'pointer' : '' }">{{
              element ? element[column.fieldName] : ''
            }}</span>
            <span
              *ngIf="column.valuePrepareFunction"
              (click)="column.action ? column.action($event, element) : null"
              [ngStyle]="{ cursor: column.action ? 'pointer' : '' }"
              [innerHTML]="element ? column.valuePrepareFunction(element) : ''"
            ></span>
          </ng-container>
          <ng-container *ngIf="column.tooltipFieldName">
            <span
              *ngIf="!column.valuePrepareFunction"
              (click)="column.action ? column.action($event, element) : null"
              [ngStyle]="{ cursor: column.action ? 'pointer' : '' }"
              [nbPopover]="templateRef"
              nbPopoverTrigger="hint"
              >{{ element ? element[column.fieldName] : '' }}</span
            >
            <span
              *ngIf="column.valuePrepareFunction && element[column.tooltipFieldName]"
              (click)="column.action ? column.action($event, element) : null"
              [ngStyle]="{ cursor: column.action ? 'pointer' : '' }"
              [innerHTML]="element ? column.valuePrepareFunction(element) : ''"
              [nbPopover]="templateRef"
              nbPopoverTrigger="hint"
            ></span>
            <span
              *ngIf="column.valuePrepareFunction && !element[column.tooltipFieldName]"
              (click)="column.action ? column.action($event, element) : null"
              [ngStyle]="{ cursor: column.action ? 'pointer' : '' }"
              [innerHTML]="element ? column.valuePrepareFunction(element) : ''"
            ></span>
            <ng-template #templateRef>
              <div class="popover-container" [innerHTML]="element[column.tooltipFieldName]"></div>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="column.fieldName == 'avatar'">
          <span
            *ngIf="column.resource === AvatarResource.EMPLOYEE"
            [ngClass]="{ 'center-avatar': thinner }"
            style="cursor: pointer"
            (click)="showEmployee360($event, element[column.id], element[column.firstNameColumn], element[column.surnameColumn], element[column.fullNameColumn])"
          >
            <img [src]="element[column.avatarId] | avatar | async" class="avatar" [ngClass]="{ 'thinner-avatar': thinner }" alt="" />
          </span>
          <span *ngIf="column.resource !== AvatarResource.EMPLOYEE" [ngClass]="{ 'center-avatar': thinner }">
            <img [src]="element[column.avatarId] | avatar | async" class="avatar" [ngClass]="{ 'thinner-avatar': thinner }" alt="" />
          </span>
        </ng-container>
      </ng-container>
    </td>
    <!-- content -->
    <td mat-footer-cell *matFooterCellDef class="header summary">
      <span *ngIf="column.total">{{ getSum(column.fieldName, column.fieldType) }}</span
      ><span *ngIf="!column.total"></span>
    </td>
  </ng-container>
  <!-- <tr mat-header-row *matHeaderRowDef="doubleHeaderColumns; sticky: true"></tr> -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    class="thinner NEW ROW"
    [ngClass]="{
      'row-disabled': row.isLTSickOrMaternity,
      'row-not-started-yet': row.isNotStartedYet,
      'thicker-line': row.isThickerLineNeeded
    }"
  ></tr>
  <ng-container *ngIf="totalRow">
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
  </ng-container>

  <!--Awards specific double header-->
  <ng-container *ngIf="doubleHeaderColumns === 'awards'">
    <!-- <ng-container matColumnDef="awards-no">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>
    <ng-container matColumnDef="awards-name">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>
    <ng-container matColumnDef="awards-new_contract">
      <th mat-header-cell *matHeaderCellDef class=>Last month: User:</th>
    </ng-container>
    <ng-container matColumnDef="awards-ote">
      <th mat-header-cell *matHeaderCellDef class=>Recommended: User:</th>
    </ng-container>
    <ng-container matColumnDef="awards-SMA">
      <th mat-header-cell *matHeaderCellDef class=>Recommended: User:</th>
    </ng-container>
    <ng-container matColumnDef="awards-care">
      <th mat-header-cell *matHeaderCellDef class=>Last month: User:</th>
    </ng-container>
    <ng-container matColumnDef="awards-explanation">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container> -->
    <ng-container matColumnDef="awards-no">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>
    <ng-container matColumnDef="awards-avatar">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>
    <ng-container matColumnDef="awards-name">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>
    <ng-container matColumnDef="awards-new_contract">
      <th mat-header-cell *matHeaderCellDef class="awards-header-cell">
        <span class="cell">last month</span>
        <span class="cell flex flex-column flex--row-gap-3">
          <div>user</div>
          <div>
            {{ getSummaryDataInfo('isOnNewContract') }}
          </div>
        </span>
      </th>
      <td mat-cell *matCellDef="let element">test</td>
    </ng-container>
    <ng-container matColumnDef="awards-ote">
      <th mat-header-cell *matHeaderCellDef class="awards-header-cell">
        <span class="cell">recommended</span>
        <span class="cell flex flex-column flex--row-gap-3">
          <div>user</div>
          <div>{{ getSummaryDataInfo('isOTEqualification') }}</div>
        </span>
      </th>
    </ng-container>
    <ng-container matColumnDef="awards-food">
      <th mat-header-cell *matHeaderCellDef class="awards-header-cell">
        <span class="cell">recommended</span>
        <span class="cell flex flex-column flex--row-gap-3">
          <div>user</div>
          <div>{{ getSummaryDataInfo('isFoodManagementAward') }}</div>
        </span>
      </th>
    </ng-container>
    <ng-container matColumnDef="awards-cleaning">
      <th mat-header-cell *matHeaderCellDef class="awards-header-cell">
        <span class="cell">recommended</span>
        <span class="cell flex flex-column flex--row-gap-3">
          <div>user</div>
          <div>{{ getSummaryDataInfo('isCleaningManagementAward') }}</div>
        </span>
      </th>
    </ng-container>
    <ng-container matColumnDef="awards-care">
      <th mat-header-cell *matHeaderCellDef class="awards-header-cell">
        <span class="cell">last month</span>
        <span class="cell flex flex-column flex--row-gap-3">
          <div>user</div>
          <div>{{ getSummaryDataInfo('isCareAward') }}</div>
        </span>
      </th>
    </ng-container>
    <ng-container matColumnDef="awards-explanation">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>
    <tr
      class="awards-double-header-row"
      mat-header-row
      *matHeaderRowDef="['awards-no', 'awards-avatar', 'awards-name', 'awards-new_contract', 'awards-ote', 'awards-food', 'awards-cleaning', 'awards-care', 'awards-explanation']"
    ></tr>
  </ng-container>
</table>
