import { AvatarsService } from '../../../shared/avatars.service';
import { AuthUserService } from '../../../@core/data/auth-user.service';
import { InboxEventService } from '../_services/inbox-event.service';
import { InboxService } from '../../../shared/inbox/inbox.service';
import { NewMessageDialogComponent } from '../new-message-dialog/new-message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { getErrorMessage, getServerLink } from '../../../utilities/utils';
import { Location } from '@angular/common';
import { PermissionsService } from '../../../@core/data/permissions.service';
import { QuestionDialogComponent } from '../../shared/question-dialog/question-dialog.component';

@Component({
  selector: 'ngx-read-message',
  templateUrl: './read-message.component.html',
  styleUrls: ['./read-message.component.scss']
})
export class ReadMessageComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  private uid: string;
  private userId = 0;
  public message: IReadMessage;
  public avatarSender = null;
  public sendMessage = false;
  public categoryId = '-1';
  public mailboxName = '';
  public mainCategoryId = '';
  public showUnpin = false;
  public permissions = <any>{};
  public fromId = '';
  public toId = '';
  public showRecipients = false;
  public isLoading = true

  constructor(private inboxService: InboxService,
              private route: ActivatedRoute,
              private router: Router,
              private eventService: InboxEventService,
              private dialog: MatDialog,
              private toastService: NbToastrService,
              private authUserService: AuthUserService,
              private avatarsService: AvatarsService,
              private location: Location,
              private dialogService: NbDialogService,
              private toastrService: NbToastrService,
              private permissionsService: PermissionsService) {
    this.permissions = this.permissionsService.getPermissions();
  }

  ngOnInit() {
    this.userId = this.authUserService.getUser().id;
    this.route.queryParams
      .subscribe((qParams: any) => {
        this.categoryId = qParams.categoryId;
        this.mainCategoryId = qParams.mainCategoryId;
        this.fromId = qParams.fromId;
        this.toId = qParams.toId;
      });
    this.route.paramMap
     .subscribe((params: ParamMap) => {
       this.uid = params.get('uid');
       this.getMessage();
    });
  }

  getMessage() {
    this.isLoading = true
    this.inboxService.getMessage(this.userId, this.uid, this.categoryId, this.authUserService.getCareHomeId(), this.fromId, this.toId)
    .subscribe((inboxResponse: any) => {
      this.message = inboxResponse.result;
      this.message.message = inboxResponse.result.message;
      this.checkIfImage(this.message.attachmentsList);

      this.sendMessage = (this.mainCategoryId === 'sent');
      if (!this.sendMessage && !this.message.readed) {
        this.markReadStatus(true);
      }
      const avatarToLoad = this.message.ownMail ? this.message.toAvatarId : this.message.fromAvatarId;
      this.loadAvatar(avatarToLoad);
      this.isLoading = false
    });
  }

  loadAvatar(avatarId: any) {
    if (avatarId) {
      this.avatarsService.getBlobAvatar(avatarId)
      .subscribe((response: any) => {
        this.avatarSender = response;
      });
    }
  }

  checkIfImage(files: any[]) {
    for (const file of files) {
      if (file.type === 'image/jpeg' || file.type  === 'image/png') {
        file.url = null;
        this.loadAttachmentsImage(file.uid);
      }
    }
  }

  loadAttachmentsImage(imageId: any) {
    this.avatarsService.getBlobAvatar(imageId)
    .subscribe((response: any) => {
      this.message.attachmentsList.find((file) => {
        if (file.uid === imageId) {
          file.url = response;
          return true;
        }
        return false;
      });
    });
  }

  downloadDocument(fileUid: any) {
    this.subscription.add(this.inboxService.getFile(fileUid)
      .subscribe((response: any) => {
        window.open(getServerLink(response.link), '_blank');
      },
      () => {
        this.toastService.danger('Error');
      })
    );
  }

  markReadStatus(markAsReadOnly?: boolean) {
    let changedStatus: boolean;
    markAsReadOnly ? changedStatus = true : changedStatus = !this.message.readed;
    this.inboxService.markReadMessage(this.userId, this.message.uid, {readed: changedStatus})
    .subscribe(() => {
      this.refreshMenu();
      this.message.readedAt = 'n/a';
      this.message.readed = !this.message.readed;
    }, () => this.toastService.danger('Error'));
  }

  createReplyForward(type) {
    this.dialog.open(NewMessageDialogComponent, {
      disableClose: true,
      data: {
       type,
       sender: this.message.fromId,
       subject: this.message.subject,
       fromCareHomeId: this.message.fromCareHomeId,
       message: this.message.message.replace(/<br\s*\/?>/gi, '\n'),
       fromName: this.message.fromName,
       fromEmail: this.message.fromEmail,
       toName: this.message.toName,
       toEmail: this.message.toEmail,
       date: this.message.careatedAt,
       replyToUid: this.message.uid,
       scheduleRelated: this.message.scheduleRelated,
       attachmentsList: this.message.attachmentsList
      }
    });
  }

  changeMessageCategory(newStatusId: number, newStatus: string) {
    const messageRequestPutStatus = {
      newStatusId,
      newStatus,
      messagesIdList: [this.message.uid]
    };
    this.subscription.add(this.inboxService.changeMessageCategory(this.userId, messageRequestPutStatus)
      .subscribe((response: any) => {
        this.toastService.success(response.message);
      },
      () => {
        this.toastService.danger('Error');
      })
    );
  }

  shiftMessage(mailUid: string) {
    this.router.navigate(['inbox/message', mailUid], {queryParams: {categoryId: this.categoryId}});
  }

  pinMessage(markPined: boolean) {
    if (!markPined) {
      this.inboxService.pinMessage(this.userId, this.message.uid, {
        messageHeadUid: this.uid,
        isPined: markPined,
        careHomeId: this.authUserService.getCareHomeId()
      }).subscribe(() => {
          this.message.isPined = markPined;
        }, () => this.toastService.danger('Error'));
    } else {
      this.subscription.add(
        this.dialogService
          .open(QuestionDialogComponent, {
            context: {
              title: "Pin message confirmation",
              message: `This message will be pinned for all to see. Make sure you aren't sending anything personal`,
              okLabel: "Yes",
              cancelLabel: "No",
            },
          })
          .onClose.subscribe((decision: boolean) => {
            if (decision) {
              this.inboxService.pinMessage(this.userId, this.message.uid, {
                messageHeadUid: this.uid,
                isPined: markPined,
                careHomeId: this.authUserService.getCareHomeId()
              }).subscribe(() => {
                  this.message.isPined = markPined;
                }, () => this.toastService.danger('Error'));
            }
          })
      );
    }
  }

  downloadPdf() {
    this.inboxService.downloadMessageAsPdf(this.userId, this.uid)
      .subscribe((response: any) => {
        window.open(getServerLink(response.link), '_blank');
      }, (err) => {
        this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
      });
  }

  refreshMenu() {
    this.eventService.updateLeftMenu(this.categoryId);
  }

  back() {
    this.location.back();
  }

  setUpText(s: string) {
    return s.replace(/\n/g, '<br/> ')
  }
}

export interface IReadMessage {
  attachmentsList: IMessageAttachment[];
  careatedAt: string;
  fromAvatarId: string;
  fromCareHomeId: number;
  fromCareHomeShortName: string;
  fromEmail: string;
  fromId: number;
  fromName: string;
  isPined: boolean;
  message: string;
  messageHeadUid: string;
  nextMessage: string;
  ownMail: boolean;
  previousMessage: string;
  readed: boolean;
  readedAt: string;
  recipientsList: {fullName: string; }[];
  scheduleRelated: boolean;
  subject: string;
  toAvatarId: string;
  toEmail: string;
  toName: string;
  uid: string;
}

interface IMessageAttachment {
  fileName: string;
  type: string;
  uid: string;
  url: string;
}
