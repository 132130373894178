<div class="form-view">
  <main>
    <form [formGroup]="form" autocomplete="off">
      <div class="flex-container-horizontal">
        <div class="form-control-group search-dropdown">
          <label class="label" for="input-employee-id">Employee:</label>
          <ng-select [items]="employees"
                      #select
                      bindLabel="fullName"
                      dropdownPosition="auto"
                      bindValue="employeeId"
                      labelForId="employeeId"
                      placeholder="Search employees"
                      [virtualScroll]="true"
                      name="input-employee-id"
                      id="input-employee-id"
                      appendTo="body"
                      formControlName="employeeId">
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
              <div>
                <span>{{ item.employeeFirstName }} {{ item.employeeSurname }}  ({{ item.employeeId }})</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div class="form-control-group">
          <label class="label" for="input-event-type">Type of Event:</label>
          <select formControlName="eventType"
                  name="input-event-type"
                  id="input-event-type">
            <option *ngFor="let eventType of eventTypes" [ngValue]="eventType.id">
              {{ eventType.wordFullName }}
            </option>
          </select>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'eventType', errorMessages) }}
          </p>
        </div>
      </div>
      <div class="flex-container-horizontal">
        <div class="form-control-group">
          <label class="label" for="input-sickness-start">Start:</label>
          <input nbInput
                  fullWidth
                  formControlName="sicknessStart"
                  (input)="onStartInput($event.target.value)"
                  id="input-sickness-start"
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="sicknessStartDatepicker"
                  (dateChange)="onStartDateInput($event)"
                  minlength="3"
                  maxlength="50"
                  autofocus>
                <mat-datepicker-toggle matSuffix [for]="sicknessStartDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #sicknessStartDatepicker startView="month"></mat-datepicker>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'sicknessStart', errorMessages) }}
          </p>
        </div>
        <div class="form-control-group">
          <label class="label" for="input-sickness-end">End:</label>
          <input nbInput
                  fullWidth
                  formControlName="sicknessEnd"
                  id="input-sickness-end"
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="sicknessEndDatepicker"
                 (dateChange)="onEndDateInput($event)"
                  minlength="3"
                  maxlength="50"
                  autofocus>
                <mat-datepicker-toggle matSuffix [for]="sicknessEndDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #sicknessEndDatepicker startView="month"></mat-datepicker>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'sicknessEnd', errorMessages) }}
          </p>
        </div>
        <div class="form-control-group">
          <label class="label" for="input-first-day-available">First Day Available (leave blank if date unknown):</label>
          <input nbInput
                  fullWidth
                  formControlName="firstDayAvailable"
                  id="input-first-day-available"
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="firstDayAvailableDatepicker"
                  autofocus>
                <mat-datepicker-toggle matSuffix [for]="firstDayAvailableDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #firstDayAvailableDatepicker startView="month"></mat-datepicker>
                <div *ngIf="form.get('firstDayAvailable').value" id="clear-date" (click)="clearFirstDayAvailable()">x</div>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'firstDayAvailable', errorMessages) }}
          </p>
        </div>
      </div>
      <nb-accordion *ngIf="dataLoaded === LoadingStatus.LOADED && (calendarMessageLeave == '' || calendarMessageShifts == '')" class="leave-table">
        <nb-accordion-item #leaveItem>
          <nb-accordion-item-header>Leave Calendar - click to {{leaveItem.collapsed ? 'expand' : 'hide'}}</nb-accordion-item-header>
          <nb-accordion-item-body>
            <ngx-overview-calendar
              class="mb-4 mt-4"
              [schedule]="schedule"
              [scheduleSummary]="scheduleSummary"
              [months]="calendarData.months"
              [dateRange]="dateRange">
            </ngx-overview-calendar>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
      <p class="ml-3 mb-4 mt-4">{{calendarMessageLeave}}<br>{{calendarMessageShifts}}</p>
      <div class="flex-container-horizontal"></div>
      <ng-template [ngIf]="showContractTable">
        <div class="form-control-group textarea-container">
            <label class="label">{{personAvailableText}}</label>
        </div>

        <div class="form-control-group textarea-container">
          <label class="label" for="input-description">Description (required):</label>
          <textarea nbInput
                    fullWidth
                    formControlName="description"
                    placeholder="Provide a proper sickness reason - do not just write 'sickness'"
                    minlength="3"
                    maxlength="5000">
          </textarea>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'description', errorMessages) }}
          </p>
        </div>
      </ng-template>
    </form>
  </main>
  <footer>
    <button class="btn btn-success"
            (click)="saveData()"
            [disabled]="!utils.isFormValid(form) || !utils.validateAtLeastOneCheckbox(contractControlNames, form) || !canSave">
      <i class="nb-checkmark" aria-hidden="true"></i> Save
    </button>
    <button class="btn btn-danger ml-4"
            (click)="closeWindow()">
      <i class="nb-close" aria-hidden="true"></i> Close
    </button>
  </footer>
</div>
