export const errorMessages = [
  {
    field: 'firstName',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'surname',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'dateOfBirth',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'invalidDate',
        message: 'Invalid date format'
      }
    ]
  },
  {
    field: 'description',
    errors: [
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 5000 characters'
      }
    ]
  },
  {
    field: 'defaultCareHome',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'roomNumber',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'numbers',
        message: 'Must be a number'
      }
    ]
  },
  {
    field: 'residencyStatus',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'residencyStart',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'invalidDate',
        message: 'Invalid date format'
      },
      {
        error: 'dateGreaterThan',
        message: 'Cannot be earlier than date of birth'
      }
    ]
  },
  {
    field: 'residencyEnd',
    errors: [
      {
        error: 'invalidDate',
        message: 'Invalid date format'
      },
      {
        error: 'emptyOrDateGreaterThan',
        message: 'Cannot be less than 24 hours'
      },
      {
        error: 'emptyOrDateSmallerThan',
        message: 'Cannot be earlier than residency start date'
      }
    ]
  },
  {
    field: 'band',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'effectiveStart',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'weeklyFee',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'allergy',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'foodAllergy',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'risk',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'oxygen',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'bread',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'residentStayStatus',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'mealSize',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'amDrinks',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'maxlength',
        message: 'Maximum 500 characters'
      }
    ]
  },
  {
    field: 'pmDrinks',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'maxlength',
        message: 'Maximum 500 characters'
      }
    ]
  },
  {
    field: 'foodLikes',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'maxlength',
        message: 'Maximum 500 characters'
      }
    ]
  },
  {
    field: 'foodDislikes',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'maxlength',
        message: 'Maximum 500 characters'
      }
    ]
  },
  {
    field: 'pcpFirstName',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'pcpSurname',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'pcpPhoneNumber',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'phoneNumber',
        message: 'Must be a valid phone number'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      }
    ]
  },
  {
    field: 'pcpEmail',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'email',
        message: 'Must be a valid email'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'pcpHouseNumber',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'maxlength',
        message: 'Maximum 20 characters'
      }
    ]
  },
  {
    field: 'pcpStreet',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'pcpCity',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'pcpState',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'pcpZipCode',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 25 characters'
      }
    ]
  },
  {
    field: 'pcpCountry',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'alpha',
        message: 'Must be letters only'
      },
      {
        error: 'minlength',
        message: 'Must be at least 2 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 2 characters'
      }
    ]
  },
  {
    field: 'country',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'alpha',
        message: 'Must be letters only'
      },
      {
        error: 'minlength',
        message: 'Must be at least 2 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 2 characters'
      }
    ]
  },
  {
    field: 'pcp2FirstName',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'pcp2Surname',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'pcp2PhoneNumber',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'phoneNumber',
        message: 'Must be a valid phone number'
      }
    ]
  },
  {
    field: 'pcp2Email',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'email',
        message: 'Must be a valid email'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'pcp2HouseNumber',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'maxlength',
        message: 'Maximum 20 characters'
      }
    ]
  },
  {
    field: 'pcp2Street',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'pcp2City',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'pcp2State',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'pcp2ZipCode',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 25 characters'
      }
    ]
  },
  {
    field: 'pcp2Country',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'alpha',
        message: 'Must be letters only'
      },
      {
        error: 'minlength',
        message: 'Must be at least 2 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 2 characters'
      }
    ]
  },
  {
    field: 'managerFirstName',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'managerSurname',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'managerPhoneNumber',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'phoneNumber',
        message: 'Must be a valid phone number'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      }
    ]
  },
  {
    field: 'managerEmail',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'email',
        message: 'Must be a valid email'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'email',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'email',
        message: 'Must be a valid email'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Email cannot be longer than 50 characters'
      }
    ]
  },
  {
    field: 'managerHouseNumber',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'maxlength',
        message: 'Maximum 20 characters'
      }
    ]
  },
  {
    field: 'managerStreet',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'managerCity',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'managerState',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'managerZipCode',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 25 characters'
      }
    ]
  },
  {
    field: 'managerCountry',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'alpha',
        message: 'Must be letters only'
      },
      {
        error: 'minlength',
        message: 'Must be at least 2 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 2 characters'
      }
    ]
  },
  {
    field: 'reasonForLeaving',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'houseNumber',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'maxlength',
        message: 'Maximum 20 characters'
      }
    ]
  },
  {
    field: 'city',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'street',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'zipCode',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'maxlength',
        message: 'Maximum 25 characters'
      }
    ]
  },
  {
    field: 'comments',
    errors: [
      {
        error: 'maxlength',
        message: 'Maximum 5000 characters'
      }
    ]
  },
  {
    field: 'county',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'phoneNumber',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'numbers',
        message: 'Must be a number'
      },
      {
        error: 'phoneNumber',
        message: 'Only numbers and dashes allowed'
      }
    ]
  },
  {
    field: 'reasonForLeavingId',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'dateLeft',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'invalidDate',
        message: 'Invalid date format'
      }
    ]
  },
  {
    field: 'dateRoomCleared',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'invalidDate',
        message: 'Invalid date format'
      },
      {
        error: 'dateGreaterThan',
        message: 'Cannot be earlier than date left'
      }
    ]
  },
  {
    field: 'sundriesToCharge',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'residentCashToReimburse',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'nokToReceiveRefund',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'sortCode',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'accountNumber',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'comments',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'maxlength',
        message: 'Maximum 5000 characters'
      }
    ]
  },
  {
    field: 'bandNo',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'bandFee',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'numbersDecimal',
        message: 'Please input numbers only'
      }
    ]
  },
  {
    field: 'foundingTypeId',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
];
