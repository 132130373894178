import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class KeyboardScrollService {

  private clickTarget = new BehaviorSubject('')
  public clickTarget$ = this.clickTarget.asObservable()

  setClickTarget(target: string) {
    this.clickTarget.next(target)
  }

  getClickTarget() {
    return this.clickTarget$
  }

  private clickTagsBoardNameTarget = new BehaviorSubject('')
  public clickTagsBoardNameTarget$ = this.clickTagsBoardNameTarget.asObservable()

  setClickTagsBoardNameTarget(tagsBoardName: string) {
    this.clickTagsBoardNameTarget.next(tagsBoardName)
  }

  getClickTagsBoardNameTarget() {
    return this.clickTagsBoardNameTarget$
  }
}
