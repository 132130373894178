import { Component, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { NbAuthComponent, NbAuthService } from '@nebular/auth';
import { VersionService } from '../../shared/version.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ngx-app-auth',
  styleUrls: ['./auth.component.scss'],
  templateUrl: './auth.component.html'
})
export class AuthComponent extends NbAuthComponent implements OnDestroy {
  private subscriptionObservable: Subscription = new Subscription();
  public version: string;

  constructor(auth: NbAuthService, location: Location, private versionService: VersionService) {
    super(auth, location);
    this.subscription.add(this.versionService.version$.subscribe((apiVersion: any) => {
      this.version = `${apiVersion.realeseDate} f:${environment.apiVersion}, b:${apiVersion.apiActualVersion}`;
    }));
  }

  ngOnDestroy() {
    this.subscriptionObservable.unsubscribe();
  }
}
