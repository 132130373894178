import { Component, Input, forwardRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { INgSelect } from '../interfaces/ng-select.interfaces';

@Component({
  selector: 'ngx-multiple-autocomplete-selector-with-checkbox-and-add',
  templateUrl: './multiple-autocomplete-selector-with-checkbox-and-add.component.html',
  styleUrls: ['./multiple-autocomplete-selector-with-checkbox-and-add.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultipleAutocompleteSelectorWithCheckboxAndAddComponent)
    }
  ]
})
export class MultipleAutocompleteSelectorWithCheckboxAndAddComponent implements ControlValueAccessor {
  public disabled = false;
  public onChange(newVal: any) {console.log('on change', newVal)}
  public onTouched(_?: any) {}
  public registerOnChange(fn: any): void { this.onChange = fn; }
  public registerOnTouched(fn: any): void { this.onTouched = fn; }
  public setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled; }
  @Output() newValueAdded = new EventEmitter<string>();
  @Output() valueRemoved: EventEmitter<INgSelect<any>> = new EventEmitter<INgSelect<any>>();
  @Output() orderChanged = new EventEmitter<{ type: 'up' | 'down', element: any }>();
  @Input() noSelectAll: boolean;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() bindLabel: string = 'fullName';
  @Input() dropdownPosition: string = 'auto';
  @Input() secondBindLabel: string;
  @Input() useShortForm: boolean;
  @Input() valuesLimit: number;
  @Input() showOrder = false;
  @Input() loading = false;
  public selectAll: boolean = false;
  @Input()
  set selectAllToggle(value: boolean) {
    this.selectAll = value;
    this.toggleSelectAll(value);
  }
  public pickValues = [];
  @Input()
  set values(values: any[]) {
    this.pickValues = values;
    if (this.selectAll) {
      this.toggleSelectAll(this.selectAll);
    }
  }
  @Input() disableAllCheckboxes: boolean = false;
  @Input() isResetValueAfterUpdate: boolean = false;

  public val = [];
  public hideField = false;

  constructor() {}

  writeValue(value: any): void {
    this.hideField = true;
    this.val = value;
    setTimeout(() => {
      this.hideField = false;
    }, 1);
  }

  toggleSelectAll(value: boolean) {
    if (!this.disabled && this.pickValues && this.pickValues.length > 0) {
      this.selectAll = value;
      if (value) {
        if (this.valuesLimit) {
          this.val = this.pickValues.slice(0, this.valuesLimit);
        } else {
          this.val = this.pickValues;
        }
      } else {
        this.val = [];
      }
      this.onChange(this.val);
    }
  }

  checkModel() {
    if (this.valuesLimit) {
      if (this.val.length > this.valuesLimit) {
        this.val = this.val.slice(0, this.valuesLimit);
      }
    }
  }

  addNewValue(event: any) {
    const value = event.target.value;
    if (value.length > 2) {
      const foundValue = this.pickValues && this.pickValues.find((item: any) => {
        return item[this.bindLabel] === value;
      });
      if (!foundValue) {
        this.newValueAdded.emit(value);
      }
      if (this.isResetValueAfterUpdate) {
        event.target.value = null;
      }
    }
  }

  public valueRemovedEmit(e: INgSelect<any>): void {
    this.valueRemoved.emit(e);
  }

  changeOrder($event, type: 'up' | 'down', element) {
    $event.stopPropagation();
    this.orderChanged.emit({ type, element });
  }
}
