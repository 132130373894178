import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";

@Pipe({name: 'checkLeave'})
export class CheckLeavePipe implements PipeTransform {
  transform(contractStart: string, contractEnd: string | null, leave: moment.Moment ): boolean {
    const leaveFormated = moment(leave).format('YYYY-MM-DD');
    const startFormated = moment(contractStart);

    if (contractEnd != 'n/a') {
      const endFormated = moment(contractEnd);
      if (moment(leaveFormated).isAfter(endFormated)) {
        return true;
      }
    }

    return !moment(leaveFormated).isSameOrAfter(startFormated);
  }
}
