//Element meant to be focused must have attribute: id="focus-pointer-helper" and tabindex="-1"
export function focusMainElement(
  customParam?: string,
  customParamType?: string,
  isDirectChild?: boolean
): void {
  let elem: HTMLElement;
  (document.activeElement as HTMLElement).blur();
  if (!customParam && !customParamType) {
    elem = document.querySelector("[data-focus-pointer-helper]") as HTMLElement;
    if (isDirectChild && customParam && customParamType === "class") {
      elem = elem.querySelector(`.${customParam}`);
    }
  } else if (!!customParam && customParamType === "class") {
    elem = document.querySelector(`.${customParam}`) as HTMLElement;
    elem.setAttribute("tabindex", "-1");
  } else if (!!customParam && customParamType === "id") {
    elem = document.querySelector(`#${customParam}`) as HTMLElement;
    elem.setAttribute("tabindex", "-1");
  }

  if (!elem) return;
  elem.focus();
}
