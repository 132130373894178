import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export enum GroupsTypes {
  GROUPPING = 1,
  RAGGS = 2
}

@Injectable()
export class GroupsService {
  constructor(private httpClient: HttpClient) { }

  getGroupsDictionaries(dictionaryType: GroupsTypes) {
    return this.httpClient.get(`${environment.apiUrl}/dictionaries/names?dictionaryType=${dictionaryType}`);
  }

  getDictionaryForId(id: number, isBoundary = false) {
    return this.httpClient.get(`${environment.apiUrl}/dictionaries/${id}?isBoundary=${isBoundary}`);
  }

  postWordsForDictionaryId(id: number, data: any) {
    return this.httpClient.post(`${environment.apiUrl}/dictionaries/tasks-managment/${id}/words`, data);
  }

  updateWordForDictionaryId(id: number, wordId: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}/dictionaries/tasks-managment/${id}/words/${wordId}`, data);
  }

  postNameDictionary(data: any) {
    return this.httpClient.post(`${environment.apiUrl}/dictionaries/tasks-managment/names`, data);
  }

  putNameDictionary(nameId: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}/dictionaries/tasks-managment/names?nameId=${nameId}`, data);
  }

  deleteGroup(groupId: number) {
    return this.httpClient.delete(`${environment.apiUrl}/dictionaries/${groupId}`);
  }

  deleteWordFromGroup(groupId: number, wordId: number) {
    return this.httpClient.delete(`${environment.apiUrl}/dictionaries/${groupId}/words/${wordId}`);
  }

  changeGroupElementOrder(groupId: number, wordId: number, data: any) {
    return this.httpClient.patch(`${environment.apiUrl}/dictionaries/groups/${groupId}/words/${wordId}`, data);
  }
}
