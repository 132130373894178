import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { getValidationStatus, handleValidationErrorMessage, handleValidationErrorMessageForGeneratedFields, isFormValid, getErrorMessage } from '../../../utilities/utils';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { TableService } from '../../shared/table/table.service';
import { AuthUserService } from '../../../@core/data/auth-user.service';
import { Subscription } from 'rxjs';
import { EmployessService } from '../../../shared/employess/employess.service';
import { employessErrorMessages } from '../employess-errors';
import { CareHomesService } from '../../../shared/care-homes/care-homes.service';
import * as moment from 'moment';
import { EmployeeLeftService } from './employee-left.service';
import { invalidDate } from '../../../utilities/validators';

@Component({
  selector: 'ngx-employee-left',
  templateUrl: './employee-left.component.html',
  styleUrls: ['./employee-left.component.scss']
})
export class EmployeeLeftComponent implements OnInit, OnDestroy {
  @Input() cardMode = false;
  @Input() reloadData;
  @Input() disableForm = false;
  @Output() cancelEvent = new EventEmitter();
  private subscription: Subscription = new Subscription();
  public errorMessages = employessErrorMessages;
  public form: UntypedFormGroup;
  public utils = { getValidationStatus, handleValidationErrorMessage, handleValidationErrorMessageForGeneratedFields, isFormValid };
  public employeeId = 0;
  public freezeDate = '';
  private editMode = false;
  public formCreated = false;
  public isDataLoaded = false;
  public settlementList: any = [];
  public settlementCalculations = '';

  constructor(private tableService: TableService,
              private toastrService: NbToastrService,
              private authUserService: AuthUserService,
              private employeeService: EmployessService,
              private careHomesService: CareHomesService,
              private employeeLeftService: EmployeeLeftService) {
    this.employeeLeftService.saveData$.subscribe((value: boolean) => {
      if (this.isDataLoaded && value) {
        this.save(null, this.employeeLeftService.closeWindow);
      }
    });
    this.employeeLeftService.refreshLeavingTab$.subscribe((value: boolean) => {
      this.loadDismissalProcessData();
    });
  }

  ngOnInit() {
    this.employeeId = this.tableService.getValue().elementId;
    this.createForm();
    this.subscription.add(this.careHomesService.getFreezeDate(this.authUserService.getCareHomeId(), moment(new Date()).startOf('isoWeek'), moment(new Date()).endOf('isoWeek'))
      .subscribe((response: any) => {
        this.freezeDate = response.freezeDate;
      }));
  }

  createForm() {
    this.form = new UntypedFormGroup({
      reasonForLeaving: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
      id: new UntypedFormControl(null),
      noticeDate: new UntypedFormControl(null, [Validators.required]),
      paymentInLieu: new UntypedFormControl(null, [Validators.required]),
      uniformReturned: new UntypedFormControl(null),
      procesDaysToEndContract: new UntypedFormControl(null),
      dateLeft: new UntypedFormControl(null, [Validators.required]),
      otherNotes: new UntypedFormControl(null),
    });
    if (this.cardMode) {
      this.form.get('noticeDate').setValidators([Validators.required, invalidDate]);
    }
    if (this.disableForm) {
      this.form.disable();
    } else {
      this.form.get('procesDaysToEndContract').valueChanges.subscribe((value: any) => {
        this.makeNewLeftDate(value);
      });

      this.form.get('noticeDate').valueChanges.subscribe((value: any) => {
        this.calculateDateBetween();
      });

      this.form.get('dateLeft').valueChanges.subscribe((value: any) => {
        this.calculateDateBetween();
      });
    }

    this.formCreated = true;
    if (!this.cardMode) {
      this.loadDismissalProcessData();
    }
  }

  makeNewLeftDate(daysBetween: number) {
    const noticeDate = moment(this.form.get('noticeDate').value);
    const newLeftDate = noticeDate.add(daysBetween, 'day');
    this.form.patchValue({ dateLeft: newLeftDate });
  }

  calculateDateBetween() {
    const leftDate = moment(this.form.get('dateLeft').value);
    const noticeDate = moment(this.form.get('noticeDate').value);
    const daysBetween = leftDate.diff(noticeDate, 'days');
    this.form.patchValue({ procesDaysToEndContract: daysBetween }, { emitEvent: false, onlySelf: true });
  }

  loadDismissalProcessData(closeWindowAfterUpdate: boolean = false) {
    this.subscription.add(this.employeeService.getDismissalProcessValues(this.employeeId)
      .subscribe((response: any) => {
        this.editMode = true;
        this.settlementList = response.result.alSettlementsList;
        this.settlementCalculations = response.result.alSummaryCalculation;
        this.updateValues(response.result);
        this.isDataLoaded = true;
      }, () => {}, () => {
        if (closeWindowAfterUpdate) {
          this.tableService.closeWindow(true);
        }
      }));
  }

  updateValues(data: any) {
    this.form.patchValue({
      reasonForLeaving: data.reasonForLeaving,
      id: data.id,
      noticeDate: data.noticeDate,
      paymentInLieu: data.paymentInLieu,
      uniformReturned: data.uniformReturned,
      procesDaysToEndContract: data.procesDaysToEndContract,
      dateLeft: data.dateLeft,
      otherNotes: data.otherNotes,
    });
  }

  prepareData() {
    const data = {
      careHomeId: this.authUserService.getCareHomeId(),
      employeeId: this.employeeId,
      reasonForLeaving: this.form.get('reasonForLeaving').value,
      proceedTogether: true,
      id: this.form.get('id').value,
      noticeDate: this.form.get('noticeDate').value,
      paymentInLieu: this.form.get('paymentInLieu').value,
      uniformReturned: this.form.get('uniformReturned').value,
      daysToEndContract: this.form.get('procesDaysToEndContract').value,
      dateLeft: this.form.get('dateLeft').value,
      otherNotes: this.form.get('otherNotes').value,
    };
    return data;
  }

  save(callback: any = null, closeWindowAfterUpdate: boolean = false) {
    const data = this.prepareData();
    if (this.editMode) {
      this.subscription.add(this.employeeService.updateDismissalProcess(this.employeeId, data)
        .subscribe((response: any) => {
          this.toastrService.success(response.message, 'Success');
          if (callback) {
            callback();
          } else {
            if (!this.cardMode) {
              this.tableService.closeWindow(true);
            } else {
              this.loadDismissalProcessData(closeWindowAfterUpdate);
            }
          }
        },
          (err) => {
            this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
          }
        ));
    } else {
      this.subscription.add(this.employeeService.addToDismissalProcess(this.employeeId, data)
        .subscribe((response: any) => {
          this.toastrService.success(response.message, 'Success');
          if (callback) {
            callback();
          } else {
            if (!this.cardMode) {
              this.tableService.closeWindow(true);
            } else {
              this.ngOnInit();
            }
          }
        },
          (err) => {
            this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
          }
        ));
    }
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
