<nb-card class="task-email-container">
  <nb-card-body class="task-email-container-body">
    <ng-container *ngIf="isDataLoaded; else loadingInfo">
      <ng-container *ngIf="!loadError; else loadErrorInfo">
        <ng-container *ngIf="emailLogList.length > 0; else noDataToShowInfo">
          <div
            class="table-container"
            id="table-container-to-focus"
          >
            <ngx-only-table
              [data]="emailLogList"
              [thinner]="true"
              [columns]="columns"
              [sortFromBackend]="true"
              (matSortChange)="updatePagination($event, true)"
            >
            </ngx-only-table>
          </div>
          <div>
            <mat-paginator
              (page)="updatePagination($event)"
              [pageIndex]="paginationData.pageIndex"
              class="pagination"
              [length]="totalRows"
              [pageSizeOptions]="[25, 50, 100, 200]"
            >
            </mat-paginator>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #loadingInfo>
      <div class="table-container">
        <div class="table-spinner-container">
          <div id="nb-global-spinner" class="spinner">
            <img src="./assets/images/echo_sygnet.svg" alt=""/>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #noDataToShowInfo>
      <div class="noDataToShow">
        <h2>{{noDataMsg}}</h2>
      </div>
    </ng-template>
    <ng-template #loadErrorInfo>
      <div class="noDataToShow error">
        <h2>Server Error: {{errorMsg}}</h2>
      </div>
    </ng-template>
  </nb-card-body>
  <nb-card-footer class="task-email-footer">
    <button class="btn btn-danger" (click)="closeWindow()">
      <i class="nb-close" aria-hidden="true"></i> Close
    </button>
  </nb-card-footer>
</nb-card>
