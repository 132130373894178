import { Component, ElementRef, Input } from "@angular/core";
import { SideMenuAccordionService } from "../side-menu-accordion/side-menu-accordion.service";
import { NbMenuItem } from "@nebular/theme";

@Component({
  selector: "side-menu-collapsed-popup",
  templateUrl: "./side-menu-collapsed-popup.component.html",
  styleUrls: ["./side-menu-collapsed-popup.component.scss"],
})
export class SideMenuCollapsedPopupComponent {
  @Input() isSubMenu: boolean = false;

  constructor(
    public sideMenuService: SideMenuAccordionService,
    private elementRef: ElementRef,
  ) {}

  public openOrHide(child: NbMenuItem) {
    if (child.children) {
      if (
        this.sideMenuService.isSubShown.value &&
        this.sideMenuService.subMenuIdentifier.value === child.title
      ) {
        this.sideMenuService.closeSubMenu();
        return;
      }

      const element = document.getElementById(child.title);
      this.sideMenuService.openSubMenu(element, child, child.title);
      return;
    }

    this.sideMenuService.close();
  }

  public calculatePosition(positionTop: number) {
    const len = !this.isSubMenu
      ? this.sideMenuService.menuItems.value.children.length
      : this.sideMenuService.subMenuItems.value.children.length;
    //sum of paddings, height of header, plus height of each item and gap between them
    const componentHeight = 95 + 17 * len + 15 * (len - 1);
    let newPosition = positionTop;

    this.setPopupPosition(10);

    if (newPosition + componentHeight > window.innerHeight) {
      newPosition = window.innerHeight - componentHeight - 20;

      this.setPopupPosition(positionTop - newPosition + 10);
    }

    return newPosition;
  }

  private setPopupPosition(positionTop: number) {
    this.elementRef.nativeElement.style.setProperty(
      "--before-top",
      `${positionTop}px`,
    );
  }
}
