import * as moment from 'moment';
import { TasksDetailsComponent } from './tasks-details/tasks-details.component';
import { TableService } from '../../shared/table/table.service';
import { AnalyzerService } from '../../../shared/dashboard/analyzer.service';

export function transformViewer(headerFieldName: string, categories: any, dataLines: any) {
  const list = categories;
  dataLines.forEach((line: any) => {
    categories.forEach((category: any, index: number) => {
      list[index][`${headerFieldName}${line.careHomeId}`] = line[category.fieldName];
    });
  });
  return list;
}

export function getHeaders(headerFieldName: string, sourceData: any, tableService: TableService = null, analyzerService: AnalyzerService = null) {
  const columns = [];
  columns.push({
    fieldName: 'category',
    title: ''
  });
  sourceData.sort((a, b) => a.careHomeName.localeCompare(b.careHomeName));
  sourceData.forEach((careHome: any) => {
    columns.push({
      fieldName: `${headerFieldName}${careHome.careHomeId}`,
      title: careHome.careHomeName,
      careHomeId: careHome.careHomeId,
      careHomeName: careHome.careHomeName,
      fieldType: careHome.fieldType
    });
  });

  return columns;
}

export function getStaffHeadersWithTransform(headerFieldName: string, sourceData: any) {
  const columns = [];
  columns.push({
    fieldName: 'category',
    title: ''
  });
  sourceData.sort((a, b) => a.careHomeName.localeCompare(b.careHomeName));
  sourceData.forEach((careHome: any) => {
    columns.push({
      fieldName: `${headerFieldName}${careHome.careHomeId}`,
      title: careHome.careHomeName,
      careHomeId: careHome.careHomeId,
      careHomeName: careHome.careHomeName,
      valuePrepareFunction: (value: string) => {
        let toReturn = '';
        if (value) {
          if (value[0] === '+') {
            toReturn = `<div class="staff-hour-status red">${value}</div>`;
          } else if (value[0] === '-') {
            toReturn = `<div class="staff-hour-status orange">${value}</div>`;
          } else if (value.startsWith('OK')) {
            toReturn = `<div class="staff-hour-status green">${value}</div>`;
          } else {
            toReturn = `<div class="staff-hour-status">${value}</div>`;
          }
        }
        return toReturn;
      }
    });
  });

  return columns;
}

export function getTaskTrackingAndCleranceTransform(headerFieldName: string, sourceData: any, tableService: TableService, analyzerService: AnalyzerService) {
  const columns = [];
  columns.push({
    fieldName: 'category',
    title: ''
  });

  sourceData.sort((a, b) => a.careHomeName.localeCompare(b.careHomeName));
  sourceData.forEach((careHome: any) => {
    columns.push({
      fieldName: `${headerFieldName}${careHome.careHomeId}`,
      title: careHome.careHomeName,
      careHomeId: careHome.careHomeId,
      careHomeName: careHome.careHomeName,
      valuePrepareFunction: (value: any) => {
        let toReturn = '';
        if (value) {
          if (value === 'Group') {
            toReturn = `<div class="task-tracking green">${value}</div>`;
          } else if (value === 'Care Home') {
            toReturn = `<div class="task-tracking red">${value}</div>`;
          } else {
            toReturn = `<div class="task-tracking">${value}</div>`;
          }
        }
        return toReturn;
      },
      fieldType: 'clickable',
      action: (element: any) => {
        if (element.fieldName === 'tasksAddedInLast7Days' || element.fieldName === 'numberOfHighPriorityTasks' || element.fieldName === 'watchedTasks') {
          tableService.openWindow(TasksDetailsComponent, `${careHome.careHomeName} - tasks`,
          {
            isTaskOnlyDetails: true,
            careHomeId: careHome.careHomeId,
            date: careHome.date,
            fieldName: element.fieldName,
            dataStream: analyzerService.getTaskDetails(careHome.date, careHome.careHomeId, element.fieldName)});
        }
      }
    });
  });

  return columns;
}

export function getOtherChecksTransform(headerFieldName: string, sourceData: any) {
  const columns = [];
  columns.push({
    fieldName: 'category',
    title: ''
  });

  sourceData.sort((a, b) => a.careHomeName.localeCompare(b.careHomeName));
  sourceData.forEach((careHome: any) => {
    columns.push({
      fieldName: `${headerFieldName}${careHome.careHomeId}`,
      title: careHome.careHomeName,
      careHomeId: careHome.careHomeId,
      careHomeName: careHome.careHomeName,
      valuePrepareFunction: (value: any, column: any) => {
        let toReturn = value;
        const currentDate = moment();
        const twoDaysFromNow = moment().subtract(2, 'days');
        const thirtyDaysFromNow = moment().subtract(30, 'days');
        const rotaSubmitedDate = moment(careHome.rotaConfirmationDate, 'YYYY/MM/DD');

        switch (column) {
          case 'dateOfLatestActivityInTheActivitySheet': {
            if (value === 'n/a') {
              toReturn = `<div class="staff-hour-status">${value}</div>`;
            } else if (currentDate.isAfter(moment(value, 'YYYY/MM/DD'))) {
              toReturn = `<div class="staff-hour-status red">${value}</div>`;
            } else {
              toReturn = `<div class="staff-hour-status green">${value}</div>`;
            }

            break;
          }
          case 'latestConfirmedDateOfShift': {
            if (value === 'n/a') {
              toReturn = `<div class="staff-hour-status">${value}</div>`;
            } else if (twoDaysFromNow.isAfter(moment(value, 'YYYY/MM/DD'))) {
              toReturn = `<div class="staff-hour-status red">${value}</div>`;
            } else {
              toReturn = `<div class="staff-hour-status green">${value}</div>`;
            }

            break;
          }
          case 'rotaFrozenDate': {
            if (value === 'n/a') {
              toReturn = `<div class="staff-hour-status">${value}</div>`;
            } else if (rotaSubmitedDate.isAfter(moment(value, 'YYYY/MM/DD'))) {
              toReturn = `<div class="staff-hour-status red">${value}</div>`;
            } else {
              toReturn = `<div class="staff-hour-status green">${value}</div>`;
            }

            break;
          }
          case 'dateEvacInfoLastChanged': {
            if (value === 'n/a') {
              toReturn = `<div class="staff-hour-status">${value}</div>`;
            } else if (thirtyDaysFromNow.isAfter(moment(value, 'YYYY/MM/DD'))) {
              toReturn = `<div class="staff-hour-status red">${value}</div>`;
            } else {
              toReturn = `<div class="staff-hour-status green">${value}</div>`;
            }

            break;
          }
        }
        return toReturn;
      }
    });
  });

  return columns;
}

export function transformDataViewer(headerFieldName: string, categories: any, dataLines: any) {
  const list = categories;
  dataLines.forEach((line: any) => {
    categories.forEach((category: any, index: number) => {
      list[index][`${headerFieldName}${line.index}`] = line[category.fieldName];
    });
  });
  return list;
}

export function getDateHeaders(headerFieldName: string, sourceData: any) {
  const columns = [];
  columns.push({
    fieldName: 'category',
    title: ''
  });
  sourceData.forEach((data: any) => {
    columns.push({ fieldName: `${headerFieldName}${data.index}`, title: `${data.reportDate} (${data.reportWeekDay})` });
  });

  return columns;
}

export function getStaffDateHeadersWithTransform(headerFieldName: string, sourceData: any) {
  const columns = [];
  columns.push({
    fieldName: 'category',
    title: ''
  });
  sourceData.forEach((data: any) => {
    columns.push({
      fieldName: `${headerFieldName}${data.index}`,
      title: `${data.reportDate} (${data.reportWeekDay})`,
      valuePrepareFunction: (value: any) => {
        let toReturn = '';
        if (value) {
          if (value[0] === '+') {
            toReturn = `<div class="staff-hour-status red">${value}</div>`;
          } else if (value[0] === '-') {
            toReturn = `<div class="staff-hour-status orange">${value}</div>`;
          } else if (value === 'OK') {
            toReturn = `<div class="staff-hour-status green">${value}</div>`;
          } else {
            toReturn = `<div class="staff-hour-status">${value}</div>`;
          }
        }
        return toReturn;
      }
    });
  });

  return columns;
}

export function transformStaffResponse(data: any) {
  data.forEach((element: any) => {
    element.roleList.forEach((role: any) => {
      element[`role_${role.roleId}`] = role.diffToBudget;
    });
    delete data.roleList;
  });

  return data;
}
