<h1 *ngIf="isBetaVersion" id="beta">BETA</h1>
<div class="logo mb-5">
  <img src="assets/images/logo_echo.svg" alt="" />
</div>
<h1 id="title" class="title">Login to Echo</h1>
<p class="sub-title">Hello! Log in with your login and password.</p>

<nb-alert *ngIf="error && !submitted" outline="danger" role="alert">
  <span class="alert-text">{{ error }}</span>
  <span class="alert-text">{{ additionalErrorMessage }}</span>
  <span class="alert-text"
    >{{
      additionalMessage
        ? additionalMessage.replace(">forgot password<", "")
        : ""
    }}
    <a
      *ngIf="
        additionalMessage
          ? additionalMessage.includes('>forgot password<')
          : false
      "
      class="forgot-password"
      routerLink="../request-pass"
      >Forgot Password</a
    >
  </span>
</nb-alert>

<nb-alert *ngIf="firsTimeLogin && !submitted" outline="info" role="info">
  <span class="info-text">
    You have logged into the system for the first time.<br />
    You need to set up a new password.
  </span>
</nb-alert>

<form
  *ngIf="formType === FormTypes.login"
  (ngSubmit)="loginToSystem()"
  #form="ngForm"
  aria-labelledby="title"
>
  <div class="form-control-group">
    <label class="label" for="input-login">Login:</label>
    <input
      nbInput
      fullWidth
      [(ngModel)]="user.login"
      #login="ngModel"
      name="login"
      id="input-login"
      placeholder="Login"
      autofocus
      [status]="login.dirty ? (login.invalid ? '' : 'success') : ''"
      required
      [attr.aria-invalid]="login.invalid && login.touched ? true : null"
    />
    <ng-container *ngIf="login.invalid && login.touched">
      <p class="error-message" *ngIf="login.errors?.required">
        Login is required!
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <label class="label" for="input-password">Password:</label>
    <input
      nbInput
      fullWidth
      [(ngModel)]="user.password"
      #password="ngModel"
      name="password"
      type="password"
      id="input-password"
      placeholder="Password"
      [status]="password.dirty ? (password.invalid ? '' : 'success') : ''"
      required
      autofocus
      [minlength]="3"
      [attr.aria-invalid]="password.invalid && password.touched ? true : null"
    />
    <ng-container *ngIf="password.invalid && password.touched">
      <p class="error-message" *ngIf="password.errors?.required">
        Password is required!
      </p>
    </ng-container>
  </div>

  <div class="form-control-group accept-group">
    <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe"
      >Remember me</nb-checkbox
    >
    <a class="forgot-password" routerLink="../request-pass">Forgot Password?</a>
  </div>

  <!--  <div id="help-info" (click)="getHelp()">Problems with login? Click here.</div>-->

  <button
    id="login-button"
    nbButton
    fullWidth
    autofocus
    [status]="'warning'"
    class="btn btn-success"
    [disabled]="submitted || !form.valid"
  >
    LOG IN
  </button>

  <div class="create-account">
    No account? <a href="#/auth/wizard">Create one</a>
  </div>
</form>

<form
  *ngIf="formType === FormTypes.changePassword"
  (ngSubmit)="resetPass()"
  #resetPassForm="ngForm"
  aria-labelledby="title"
>
  <div class="form-control-group">
    <label class="label" for="input-passwordOld">Old password:</label>
    <input
      nbInput
      [(ngModel)]="user.passwordOld"
      #passwordOld="ngModel"
      type="password"
      id="input-passwordOld"
      name="passwordOld"
      class="first"
      placeholder="Old password"
      autofocus
      fullWidth
      [status]="
        passwordOld.dirty ? (passwordOld.invalid ? 'danger' : 'success') : ''
      "
      required
      [minlength]="8"
      [maxlength]="255"
      [attr.aria-invalid]="
        passwordOld.invalid && passwordOld.touched ? true : null
      "
    />
    <ng-container *ngIf="passwordOld.invalid && passwordOld.touched">
      <p class="error-message" *ngIf="passwordOld.errors?.required">
        New password is required!
      </p>
      <p
        class="error-message"
        *ngIf="passwordOld.errors?.minlength || passwordOld.errors?.maxlength"
      >
        New password should contains from 8 to 255 characters
      </p>
    </ng-container>
  </div>
  <div class="form-control-group">
    <label class="label" for="input-passwordNew">New password:</label>
    <input
      nbInput
      [(ngModel)]="user.passwordNew"
      #passwordNew="ngModel"
      type="password"
      id="input-passwordNew"
      name="passwordNew"
      class="first"
      placeholder="New password"
      autofocus
      fullWidth
      [status]="
        passwordNew.dirty ? (passwordNew.invalid ? 'danger' : 'success') : ''
      "
      required
      [minlength]="8"
      [maxlength]="255"
      [attr.aria-invalid]="
        passwordNew.invalid && passwordNew.touched ? true : null
      "
    />
    <ng-container *ngIf="passwordNew.invalid && passwordNew.touched">
      <p class="error-message" *ngIf="passwordNew.errors?.required">
        New password is required!
      </p>
      <p
        class="error-message"
        *ngIf="passwordNew.errors?.minlength || passwordNew.errors?.maxlength"
      >
        New password should contains from 8 to 255 characters
      </p>
    </ng-container>
  </div>

  <div class="form-group">
    <label class="label" for="input-re-password">Confirm Password:</label>
    <input
      nbInput
      [(ngModel)]="user.confirmPassword"
      #rePass="ngModel"
      id="input-re-password"
      name="rePass"
      type="password"
      class="last"
      placeholder="Confirm Password"
      fullWidth
      [status]="
        rePass.touched
          ? rePass.invalid || passwordNew.value != rePass.value
            ? 'danger'
            : 'success'
          : ''
      "
      required
      [attr.aria-invalid]="rePass.invalid && rePass.touched ? true : null"
    />
    <ng-container *ngIf="rePass.touched">
      <p
        class="error-message"
        *ngIf="rePass.invalid && rePass.errors?.required"
      >
        Password confirmation is required!
      </p>
      <p
        class="error-message"
        *ngIf="passwordNew.value != rePass.value && !rePass.errors?.required"
      >
        Password does not match the confirm password.
      </p>
    </ng-container>
  </div>

  <button
    nbButton
    fullWidth
    class="btn btn-success"
    [disabled]="submitted || !resetPassForm.valid"
  >
    CHANGE PASSWORD
  </button>
</form>
