import { Component, OnInit, OnDestroy } from '@angular/core';
import { getErrorMessage } from '../../../../utilities/utils';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { TableService } from '../../../shared/table/table.service';
import { EmployessService } from '../../../../shared/employess/employess.service';
import { ContractsService } from '../../../../shared/employess/contracts/contracts.service';
import { AuthUserService } from '../../../../@core/data/auth-user.service';
import { Subscription } from 'rxjs';
import { PermissionsService } from '../../../../@core/data/permissions.service';
import { QuestionDialogComponent } from '../../../shared/question-dialog/question-dialog.component';
import { NewContractComponent } from '../new-contract/new-contract.component';
import { EditEmployeeComponent } from '../../edit-employee/edit-employee.component';
import { EmployeeTab } from '../../employee-tab.enum';
import { UploadDocumentToCategoryService } from '../upload-document-to-category.service';

@Component({
  selector: 'ngx-choose-contract',
  templateUrl: './choose-contract.component.html',
  styleUrls: ['./choose-contract.component.scss']
})
export class ChooseContractComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private employeeId: any;
  private documentToAssign: any;
  private documentCategoryId: any;
  private securityLevelId: any;
  private contractRoles = [];
  public documentUid: any;

  public columns = ['No.', 'Contract ID', 'Role', 'Contract Type', 'Contract Start', 'Contract End', 'Gross Hrs', 'Status', 'Action'];
  public employeeContracts: any = [];
  public contractControlNames = [];
  public permissions = <any>{};

  constructor(private tableService: TableService,
              private employessService: EmployessService,
              private contractsService: ContractsService,
              private authUserService: AuthUserService,
              private dialogService: NbDialogService,
              private toastrService: NbToastrService,
              private permissionsService: PermissionsService,
              private uploadDocumentToCategoryService: UploadDocumentToCategoryService) {
    this.permissions = this.permissionsService.getPermissions();
  }

  ngOnInit() {
    if (this.tableService.getValue()) {
      this.employeeId = this.tableService.getValue().employeeId;
      this.documentToAssign = this.tableService.getValue().documentToAssign;
      this.documentCategoryId = this.tableService.getValue().documentCategoryId;
      this.documentUid = this.tableService.getValue().documentUid;
      this.securityLevelId = this.tableService.getValue().securityLevelId;
      this.subscription.add(this.employessService.getContractsByEmployeeIdInAddContractMode(this.employeeId)
        .subscribe((response: any) => {
          this.employeeContracts = response.result.contractsLines;
          this.contractRoles = [];
          this.employeeContracts.forEach((element: any) => {
            this.contractRoles.push(element.contractRole);
          });
      }));
    }
  }

  chooseContract(contractId: any, role: any, type: any) {
    this.subscription.add(this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Assign confirmation',
          message: `Are you sure you want to assign the contract <b>${role} - ${type}</b> to the employee?`,
          okLabel: 'Yes',
          cancelLabel: 'No'
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          const fd = new FormData();
          if (this.documentUid) {
            fd.append('documentUid', this.documentUid);
          }
          fd.append('document', this.documentToAssign);
          fd.append('employeeId', this.employeeId.toString());
          fd.append('careHomeId', this.authUserService.getCareHomeId().toString());
          fd.append('documentCategoryId', this.documentCategoryId.toString());
          fd.append('notes', `${role} - ${type}`);
          this.subscription.add(this.contractsService.addContractDocuments(contractId, fd)
            .subscribe((response: any) => {
              this.toastrService.success(response.message, 'Success');
              this.tableService.closeWindow(false);
            },
            (err) => {
              this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
            }
          ));
        }
      }
    ));
  }

  addNewContract() {
    this.tableService.openWindowWithBackStack(NewContractComponent, 'Add new contract',
      { component: EditEmployeeComponent, title: 'Employee Profile Information', elementId: this.employeeId, activeTab: EmployeeTab.DOCUMENTS },
      {
        employeeId: this.employeeId,
        documentToAssign: this.documentToAssign,
        documentUid: this.documentUid,
        documentCategoryId: this.documentCategoryId,
        securityLevelId: this.securityLevelId,
        contractRoles: this.contractRoles
      }
    );
  }

  addNewTaskForContract() {
    this.subscription.add(this.uploadDocumentToCategoryService
      .addTaskForDocumentToCategory(this.documentToAssign, this.documentCategoryId, this.employeeId, this.authUserService.getCareHomeId(), () => this.tableService.closeWindow(false)));
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
