import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ActivitySheetsApiUrls } from "./activity-sheets.enum";
import * as moment from "moment";
import { IHttpSuccessResponse } from "../models/http-success-reposonse.interfaces";
import { IActivitySchedule } from "./activity-sheets.models";

@Injectable()
export class ActivitySheetsService {
  constructor(private httpClient: HttpClient) {}

  getSchedule(careHomeId: number, scheduleDate: any, isWeeklyModel = true) {
    const params = new HttpParams()
      .append("careHomeId", careHomeId)
      .append("scheduleDate", moment(scheduleDate).format("YYYY/MM/DD"))
      .append("isWeeklyModel", isWeeklyModel);

    return this.httpClient.get<IHttpSuccessResponse<IActivitySchedule>>(
      `${environment.apiUrl}${ActivitySheetsApiUrls.activities}/schedule`,

      { params },
    );
  }

  addSchedule(data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${ActivitySheetsApiUrls.activities}/schedule`,
      data,
    );
  }

  downloadActivitySheetPdf(
    careHomeId: number,
    scheduleDate: string,
    fontSize: any,
  ) {
    return this.httpClient.get(
      `${environment.apiUrl}${
        ActivitySheetsApiUrls.activities
      }/schedule/pdf?careHomeId=${careHomeId}&scheduleDate=${scheduleDate}&fontSize=${fontSize}`,
    );
  }

  updateNote(noteId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}${ActivitySheetsApiUrls.activities}/note/${noteId}`,
      data,
    );
  }

  copySchedule(data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${ActivitySheetsApiUrls.activities}/schedule/months`,
      data,
    );
  }

  getScheduledMonths(careHomeId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}${ActivitySheetsApiUrls.activities}/schedule/months?careHomeId=${careHomeId}`,
    );
  }
}
