import { Component, NgZone, OnDestroy } from "@angular/core";
import { NbMenuItem, NbMenuService } from "@nebular/theme";
import { PermissionsService } from "../@core/data/permissions.service";
import { Subscription, forkJoin } from "rxjs";
import { ApplicationPermissions } from "../@core/data/application-permissions.enum";
import { AuthUserService } from "../@core/data/auth-user.service";
import { TaskBoardsService } from "../shared/tasks/task-boards/task-boards.service";
import { Router } from "@angular/router";
import { CareHomesService } from "../shared/care-homes/care-homes.service";
import { CurrentCareHomeService } from "../@core/data/current-care-home.service";
import { UsersService } from "../shared/users/users.service";
import { debounceTime } from "rxjs/operators";
import { DetectMobileService } from "../shared/detect-mobile.service";
import { SelectedCareHomeStateService } from '../shared/+state/selected-care-home/selected-care-home-state.service';
import * as MenuItems from './menu-items';

export enum TaskboardPermissions {
  COMMUNITY_TRACKER = "communityTrackerStatus",
  RESIDENT_TASKBOARD = "residentTaskboardStatus",
  MENU_CHANGES = "menuChangesStatus",
  ROOM_ENQUIRES = "roomEnquiriesStatus",
  FINANCE_TASKBOARD = "financeTaskboardStatus",
  AUTOMATIC_TASKBOARD = "annualTaskboardStatus",
}

@Component({
  selector: "ngx-pages",
  templateUrl: "./pages.component.html",
})
export class PagesComponent implements OnDestroy {
  private routes;
  private cacheTaskBoardsMenu = [];
  private tempTaskBoard = [];

  rightMenu = [
    {
      title: "Settings",
      group: true,
    },
    {
      title: "Users",
      icon: "person-outline",
      home: true,
      children: [
        {
          title: "Manage users",
          icon: "person-done-outline",
          link: "/users",
        },
        {
          title: "Users report",
          icon: "printer-outline",
          link: "/users-report",
        },
      ],
    },
    {
      title: "Manage care homes",
      icon: "home-outline",
      link: "/care-homes",
    },
    {
      title: "Tasks",
      icon: "layers-outline",
      home: true,
      children: [
        {
          title: "Taskboards",
          icon: "list-outline",
          link: "/task-management/task-boards",
        },
        {
          title: "Automatic tasks",
          icon: "grid-outline",
          link: "/task-management/automatic-tasks-managment",
        },
        {
          title: "Groups",
          icon: "people-outline",
          link: "/administration/groups",
        },
        {
          title: "RAGG",
          icon: "log-in-outline",
          link: "/administration/ragg",
        },
        {
          title: "Task categories",
          icon: "book-open-outline",
          link: "/administration/task-categories",
        },
        {
          title: "Task's tags",
          icon: "hash-outline",
          link: "/administration/task-tags",
        },
        {
          title: "Task's templates",
          icon: "file-text-outline",
          link: "/task-management/task-templates",
        },
      ],
    },
    {
      title: "Tracked Events",
      icon: "grid-outline",
      link: "/task-management/tracked-events",
    },
    {
      title: "Messages",
      icon: "email-outline",
      home: true,
      children: [
        {
          title: "Message groups",
          icon: "email-outline",
          link: "/administration/message-groups",
        },
        {
          title: "Message templates",
          icon: "email-outline",
          link: "/administration/message-templates",
        },
      ],
    },
    {
      title: "Rota conflicts",
      icon: "alert-triangle-outline",
      link: "/administration/rota-conflicts",
      permission: ApplicationPermissions.ROTA_CONFLICT_SOLVING,
    },
    {
      title: "Holidays",
      icon: "sun-outline",
      link: "/administration/holidays",
    },
    {
      title: "System log",
      icon: "lock-outline",
      children: [
        {
          title: "Events",
          icon: "lock-outline",
          link: "/administration/events",
        },
        {
          title: "Email test",
          icon: "email-outline",
          link: "/administration/email-test",
        },
        {
          title: "Deadline emails report",
          icon: "email-outline",
          link: "/administration/deadline-emails",
        },
      ],
    },
    {
      title: "Configuration",
      icon: "settings-outline",
      home: true,
      children: [
        {
          title: "Taskboard groups",
          icon: "settings-outline",
          link: "/administration/configuration",
        },
        {
          title: "Care Homes order",
          icon: "home-outline",
          link: "/administration/care-homes-order-configuration",
        },
      ],
    },
    {
      title: "Permissions",
      icon: "options-outline",
      home: true,
      children: [
        {
          title: "Profiles manager",
          icon: "options-outline",
          link: "/administration/profiles-manager",
        },
        {
          title: "CB Permissions",
          icon: "options-outline",
          link: "/administration/contact-book-permissions",
        },
      ],
    },
    {
      title: "Remote help",
      icon: "question-mark-circle-outline",
      link: "/remote-help",
    }
  ];

  getRightMenu() {
    const _rightMenu = [];
    this.rightMenu.forEach((element: any) => {
      if (!element.permission) {
        _rightMenu.push(element);
      } else {
        if (this.permissionsService.haveUserPermissionTo(element.permission)) {
          _rightMenu.push(element);
        }
      }
    });

    return _rightMenu;
  }

  getMenu(title: string, icon: string, link: string, elements: any = []) {
    const children = [];
    elements.forEach((element: any) => {
      // have multiple permissions to check
      if (Array.isArray(element.permission)) {
        let arrayResponse = false;
        for (const permission of element.permission) {
          if (this.permissionsService.haveUserPermissionTo(permission)) {
            arrayResponse = true;
          }
        }
        // have permission to it so add it
        if (element.children) {
          children.push(
            this.getMenu(element.title, null, element.link, element.children)
          );
        } else {
          this.addChildren(element, children);
        }
      } else {
        if (this.permissionsService.haveUserPermissionTo(element.permission)) {
          if (element.children) {
            children.push(
              this.getMenu(element.title, null, element.link, element.children)
            );
          } else {
            this.addChildren(element, children);
          }
        }
      }
    });
    const menu = {
      title: title,
      icon: icon,
      link: link,
      home: true,
      children: children,
    };
    return menu;
  }

  addChildren(element: any, children: any) {
    if (element.additionalCondition && element.additionalCondition == true) {
      children.push(element);
    } else {
      children.push(element);
    }
  }

  getTaskboardMenu(
    title: string,
    icon: string,
    link: string,
    elements: any = []
  ) {
    const children = [];
    elements.forEach((element: any) => {
      if (element.taskboardPermission) {
        if (
          this.permissionsService.haveTaskboardPermissionTo(
            element.taskboardPermission
          )
        ) {
          children.push(element);
        }
      } else if (element.permission) {
        if (this.permissionsService.haveUserPermissionTo(element.permission)) {
          children.push(element);
        }
      } else {
        children.push(element);
      }
    });
    for (const board of this.permissionsService.getAvailableTaskboards()) {
      children.push({
        title: board.boardName,
        link: `/task-management/task-boards/${board.id}`,
      });
    }

    this.tempTaskBoard = children;

    if (this.cacheTaskBoardsMenu.length === 0) {
      this.cacheTaskBoardsMenu = children;
    }

    const menu = {
      title: title,
      icon: icon,
      link: link,
      home: true,
      children: children,
    };
    return menu;
  }

  menu: NbMenuItem[] = [];
  filteredMenu: NbMenuItem[] = [];
  subscription: Subscription = new Subscription();

  constructor(
    private permissionsService: PermissionsService,
    private taskBoardsService: TaskBoardsService,
    private careHomeService: CareHomesService,
    private authUserService: AuthUserService,
    public currentCareHomeService: CurrentCareHomeService,
    private usersService: UsersService,
    private menuService: NbMenuService,
    private router: Router,
    private ngZone: NgZone,
    public detectMobile: DetectMobileService,
    private selectedCareHomeState: SelectedCareHomeStateService
  ) {
    if (this.detectMobile.mobileQuery.matches) {
      const startIndex = window.location.href.indexOf("echo");
      const endIndex = window.location.href.indexOf(".");
      const toReplace = window.location.href.substring(startIndex, endIndex);
      const href = window.location.href
        .replace(toReplace, "echo")
        .replace("echo", "echo-portable");
      window.open(href, "_self");
    }

    this.getCurrentCareHomeDetails(this.authUserService.getCareHomeId());
    this.subscription.add(
      this.authUserService.userCareHomeChange$.subscribe(
        (value: {
          careHomeId: number;
          pageBefore: string;
          isOpenNewTab: boolean;
        }) => {
          this.getCurrentCareHomeDetails(
            value.careHomeId,
            value.pageBefore,
            value.isOpenNewTab
          );
        }
      )
    );
    this.subscription.add(
      this.currentCareHomeService.menuSearch$
        .pipe(debounceTime(200))
        .subscribe((textToSearch: any) => {
          const _menu = [];
          this.menu.forEach((item: any) => {
            const itemToCopy = Object.assign({}, item);
            const childrenArray = item.children.filter((child: any) => {
              return child.title
                .toLowerCase()
                .includes(textToSearch?.toLowerCase());
            });
            if (childrenArray.length > 0) {
              itemToCopy.children = childrenArray;
              _menu.push(itemToCopy);
            }
          });
          this.filteredMenu = _menu;
          if (textToSearch === "") {
            this.menuService.collapseAll();
          } else {
            this.expandMenus();
          }
        })
    );
  }

  showMenusByPrivileges(
    careHomeId: number,
    pageBefore?: string,
    isOpenNewTab = false
  ) {
    const userPermissions = this.usersService.getUserPermissions(
      this.authUserService.getOriginalUser().id,
      careHomeId
    );
    const taskboardPermissions =
      this.taskBoardsService.getTaskBoardsWithMyAccess(careHomeId);

    forkJoin([userPermissions, taskboardPermissions]).subscribe(
      (response: any) => {
        // console.log(response);
        this.permissionsService.setPermissions(response);
        const _menu = [];
        _menu.push(
          this.getMenu(
            "Key reports",
            "bar-chart-outline",
            "/dashboard",
            MenuItems.keyReportsMenu
          )
        );
        _menu.push(
          this.getMenu(
            "Employees",
            "person-outline",
            "/employess",
            MenuItems.employeesMenu
          )
        );
        _menu.push(
          this.getMenu(
            "Residents",
            "heart-outline",
            "/residents",
            MenuItems.residentsMenu
          )
        );
        _menu.push(
          this.getTaskboardMenu(
            "Weekly update",
            "list-outline",
            "/task-management",
            MenuItems.taskManagementMenu
          )
        );
        _menu.push(
          this.getMenu(
            "Administration",
            "home-outline",
            "/administration",
            MenuItems.administrationMenu
          )
        );
        this.menu = Object.assign([], _menu);
        this.filteredMenu = Object.assign([], _menu);

        this.rightMenu = Object.assign([], this.getRightMenu());

        this.generateRoutes();

        if (pageBefore) {
          this.goToPageBeforeCareHomeChange(pageBefore, isOpenNewTab);
        }
      }
    );
  }

  private generateRoutes() {
    this.routes = [];
    this.menu.forEach((menu: any) => {
      if (menu.children) {
        menu.children.forEach((element: any) => {
          if (element.children) {
            element.children.forEach((childElement: any) => {
              this.routes.push(childElement.link);
            });
          }
          this.routes.push(element.link);
        });
      }
    });
  }

  private goToPageBeforeCareHomeChange(
    pageBefore: string,
    isOpenNewTab = false
  ) {
    if (!isOpenNewTab) {
      this.router.navigateByUrl("/");
    }

    this.ngZone.run(() => {
      setTimeout(() => {
        if (isOpenNewTab) {
          window.open(`${location.origin}#${pageBefore}`, "_blank");
        } else if (this.routes.includes(pageBefore)) {
          this.router.navigateByUrl(pageBefore);
        } else if (pageBefore.includes("task-boards")) {
          const taskboardLink =
            this.getCorrespondingTaskBoardForPage(pageBefore);
          this.router.navigateByUrl(taskboardLink);
        } else if (pageBefore.includes("inbox")) {
          this.router.navigateByUrl(pageBefore);
        } else {
          this.router.navigateByUrl("dashboard/dashboard");
          pageBefore = "dashboard/dashboard";
        }
        // expand Employees menu
        if (pageBefore.includes("employess")) {
          this.expandMenu('[title="Employees"]');
        }
        // expand Weekly Update menu
        if (pageBefore.includes("task-management")) {
          this.expandMenu('[title="Weekly update"]');
        }
        // expand Dashboard menu
        if (
          pageBefore.includes("dashboard") ||
          pageBefore.includes("inbox") ||
          pageBefore.includes("manager-boards-summary-dashboard") ||
          pageBefore.includes("global-favorite-tasks-dashboard")
        ) {
          this.expandMenu('[title="Key reports"]');
        }
        // expand Administration menu
        if (pageBefore.includes("administration")) {
          this.expandMenu('[title="Administration"]');
        }
        // expand Residents menu
        if (pageBefore.includes("residents")) {
          this.expandMenu('[title="Residents"]');
        }

        this.cacheTaskBoardsMenu = this.tempTaskBoard;
      }, 1000);
    });
  }

  private expandMenus() {
    setTimeout(() => {
      this.expandMenu('[title="Employees"]');
      this.expandMenu('[title="Weekly update"]');
      this.expandMenu('[title="Key reports"]');
      this.expandMenu('[title="Administration"]');
      this.expandMenu('[title="Residents"]');
    }, 1000);
  }

  private expandMenu(selector: string) {
    const x: any = document.querySelector(selector);
    if (x) {
      x.parentElement.children[1].className =
        x.parentElement.children[1].className.replace("collapsed", "") +
        " expanded";
      x.parentElement.children[1].style = "";
    }
  }

  private getCurrentCareHomeDetails(
    careHomeId: number,
    pageBefore?: string,
    isOpenNewTab = false
  ) {
    this.careHomeService.getCareHomes().subscribe((value: any) => {
      const result = value.result.careHomesList.find((obj: any) => {
        return obj.careHomeId === careHomeId;
      });
      this.currentCareHomeService.setCurrentCareHome(result);
      this.selectedCareHomeState.setSelectedCareHome(result);
      this.showMenusByPrivileges(careHomeId, pageBefore, isOpenNewTab);
    });
  }

  private getCorrespondingTaskBoardForPage(pageBefore: string): string {
    const pageArr = pageBefore.split("/");
    const boardId = pageArr[pageArr.length - 1];

    const board = this.cacheTaskBoardsMenu.find((x) =>
      x.link.includes(boardId)
    );
    const boardTitleArr = board.title.split("-");
    const oldBoardName = boardTitleArr[boardTitleArr.length - 1]
      .trim()
      .toLowerCase();
    const targetBoard = this.tempTaskBoard.find((x) =>
      x.title.toLowerCase().includes(oldBoardName)
    );
    const link = targetBoard ? targetBoard.link : "dashboard/dashboard";

    return link;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onItemClick() {
    this.currentCareHomeService.menuSearch$.next(null);
  }
}
