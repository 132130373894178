<nb-card>
  <nb-card-header>{{title}}</nb-card-header>
  <nb-card-body>
    <form [formGroup]="form">
      <div class="form-control-group">
        <label class="label" for="input-date">Date to review the paused status</label>
        <input nbInput
                fullWidth
                formControlName="date"
                name="date"
                id="input-date"
                placeholder="DD/MM/YYYY"
                [matDatepicker]="picker"
                minlength="3"
                maxlength="50">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker startView="month"></mat-datepicker>
        <p class="error-message" *ngIf="!form.get('date').valid && form.get('date').touched && form.get('date').errors">
          Date is required
        </p>
      </div>
      <div class="form-control-group mt-3">
        <label class="label" for="input-date">Why are we pausing it?</label>
        <textarea
            nbInput
            fullWidth
            formControlName="text"
            placeholder="Pause reason"
            maxlength="125"
            autofocus>
        </textarea>
        <p class="error-message" *ngIf="!form.get('text').valid && form.get('text').touched && form.get('text').errors">
          Pause reason is required
        </p>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <button [disabled]="!isFormValid(form)" class="btn btn-success mr-2" (click)="ok()">Ok</button>
    <button class="btn btn-danger" (click)="cancel()">Cancel</button>
  </nb-card-footer>
</nb-card>
  