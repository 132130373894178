import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'dateFormat'})
export class DateFormatPipe implements PipeTransform {
  transform(value: string) {
    value = moment(value).format('DD/MM/YYYY');
    return value;
  }
}
