<div class="absolute-buttons">
  <button mat-button matStepperNext class="btn btn-info" (click)="clear()">
    Reset Shifts
  </button>
  <button mat-button matStepperNext class="btn btn-success" (click)="goToRota()">
    Show Rota Master Table
  </button>
</div>
<ng-container *ngIf="isDataLoaded; else loadingData">
  <ng-container *ngIf="!noDataAvailable; else noAvailableData">
    <ng-container *ngIf="!loadError; else loadErrorInfo">
      <ng-container *ngIf="!noDataToShow; else noDataToShowInfo">
        <header>
          <div *ngIf="noDataToMigrate; else dataToMigrateTxt">Nothing to amend, please check the schedule</div>
          <ng-template #dataToMigrateTxt>
            <div>Review calendar for the staff member and amend any <span>highlighted shifts</span></div>
          </ng-template>
        </header>
        <div class="migration-table-container thin-echo-scrollbar">
          <ngx-rota-migration-table
            *ngFor="let week of weeks"
            [employeeId]="employeeId"
            [week]="week"
            [homeId]="homeId"
            [homeName]="homeName"
          ></ngx-rota-migration-table>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #noDataToShowInfo>
    <div class="noDataToShow">
      <h2>{{noDataMsg}}</h2>
    </div>
  </ng-template>
  <ng-template #loadErrorInfo>
    <div class="noDataToShow error">
      <h2>Server Error: {{errorMsg}}</h2>
    </div>
  </ng-template>
  <ng-template #noAvailableData>
    <div class="noDataToShow">
      <h2>{{noDataAvaMsg}}{{secondsCount}} seconds</h2>
    </div>
  </ng-template>
</ng-container>
<ng-template #loadingData>
  <div class="table-spinner-container">
    <div id="nb-global-spinner" class="spinner">
      <img src="./assets/images/echo_sygnet.svg" alt=""/>
    </div>
  </div>
</ng-template>
