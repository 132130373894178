<main>
  <section id="image-placeholder" *ngIf="!croppedImage && image" (click)="!disableAddButton ? imageUpload.click() : ''">
    <div class="circle circle--outer" [ngClass]="{'agency': isAgency }">
      <div class="circle circle--inner">
        <p *ngIf="!image && !imageOnly">Please load image</p>
        <img *ngIf="image" [src]="image" alt=""/>
      </div>
      <div class="circle-label" *ngIf="isAgency">
        Agency worker
      </div>
    </div>
  </section>
  <section *ngIf="croppedImage">
    <ngx-super-croppie
      #ngxSuperCroppie
      [croppieOptions]="croppieOptions"
      [url]="url.toString()"
      [points]="points"
      [orientation]="orientation"
      [zoom]="0"
      [resultOptions]="resultOptions"
      (result)="updateCroppedImage($event)"
    ></ngx-super-croppie>
  </section>
  <input #imageUpload hidden type="file" onclick="this.value=null;" id="fileupload" [disabled]="imageOnly" (change)="imageUploadEvent($event)" accept="image/gif, image/jpeg, image/png"/>
  <button *ngIf="!imageOnly" type="button" class="btn btn-info" [disabled]="disableAddButton" (click)="imageUpload.click()">
    <!-- <i class="nb-image-outline" aria-hidden="true"></i> -->
    <nb-icon icon="image-outline" class="mr-2"></nb-icon>
    <span *ngIf="!croppedImage && !image">Add photo</span>
    <span *ngIf="croppedImage || image">Edit photo</span>
  </button>
  <button type="button" *ngIf="upload && croppedImage" class="btn btn-warning mb-2" (click)="upload($event); croppedImage = undefined">Save image</button>
  <p *ngIf="!imageOnly" [style.width.px]="185">* Only jpg, png and gif file with maximum size of 1 MB is allowed.</p>
</main>
