import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ContractsApiUrls } from './contracts.enum';
import * as moment from 'moment';

@Injectable()
export class ContractsService {
  constructor(private httpClient: HttpClient) {}

  getContracts(startDate: any, endDate: any, onlyActive: boolean) {
    const contractsUrl = onlyActive ? ContractsApiUrls.activeContracts : ContractsApiUrls.allContracts;
    return this.httpClient.get(`${environment.apiUrl}${contractsUrl}&startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}`);
  }

  getContractsXLS(startDate: any, endDate: any, alCalculateDate: any, onlyActive: boolean) {
    const contractsUrl = onlyActive ? ContractsApiUrls.activeXLSContracts : ContractsApiUrls.allContractsXLS;
    return this.httpClient.get(`${environment.apiUrl}${contractsUrl}&startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}&alCalculationDate=${moment(alCalculateDate).format('YYYY/MM/DD')}`);
  }

  addNewContract(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${ContractsApiUrls.contracts}`, data);
  }

  getContract(id: number) {
    return this.httpClient.get(`${environment.apiUrl}${ContractsApiUrls.contracts}/${id}`);
  }

  addContractDocuments(id: number, data: any) {
    return this.httpClient.post(`${environment.apiUrl}${ContractsApiUrls.contracts}/${id}/electronic-documents`, data);
  }

  getContractDocuments(id: number) {
    return this.httpClient.get(`${environment.apiUrl}${ContractsApiUrls.contracts}/${id}/electronic-documents`);
  }

  downloadDocument(id: number, documentUid: string) {
    return this.httpClient.get(`${environment.apiUrl}${ContractsApiUrls.contracts}/${id}/electronic-documents/${documentUid}`);
  }

  getContractByEventId(eventId: number) {
    return this.httpClient.get(`${environment.apiUrl}${ContractsApiUrls.contractsUnaggregated}/${eventId}`);
  }

  updateContract(id: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${ContractsApiUrls.contracts}/${id}`, data);
  }

  checkEmployeeContract(employeeId: number, params: any) {
    const queryParams = `requestedCareHomeId=${params.careHomeId}&startDateFormated=${moment(params.contractStart).format('YYYY/MM/DD')}`;
    const dateEnd = params.contractEnd ? `&endDateFormated=${moment(params.contractEnd).format('YYYY/MM/DD')}` : '';
    return this.httpClient.get(`${environment.apiUrl}/employees/${employeeId}${ContractsApiUrls.employeeContractsList}?skip-care-home?${queryParams}${dateEnd}`);
  }

  getReplacements(eventId: number) {
    return this.httpClient.get(`${environment.apiUrl}/employees/events/${eventId}?skip-care-home`);
  }

  createReplacement(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${ContractsApiUrls.contractsReplacement}`, data);
  }

  deleteReplacement(eventId: number) {
    return this.httpClient.delete(`${environment.apiUrl}${ContractsApiUrls.contractsReplacement}/${eventId}`);
  }

  deleteContract(contractId: number) {
    return this.httpClient.delete(`${environment.apiUrl}${ContractsApiUrls.contracts}/${contractId}`);
  }

  deleteContractDocument(contractId: number, documentUid: string) {
    return this.httpClient.delete(`${environment.apiUrl}${ContractsApiUrls.contracts}/${contractId}/electronic-documents/${documentUid}`);
  }

  getContractFinance(contractId: number) {
    return this.httpClient.get(`${environment.apiUrl}${ContractsApiUrls.contracts}/${contractId}/financial-data`);
  }

  updateContractFinance(contractId: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${ContractsApiUrls.contracts}/${contractId}/financial-data`, data);
  }

  getContractItems(contractId: number) {
    return this.httpClient.get(`${environment.apiUrl}${ContractsApiUrls.contracts}/${contractId}/financial-data/history`);
  }

  updateContractFinanceItem(contractId: number, id: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${ContractsApiUrls.contracts}/${contractId}/financial-data/${id}`, data);
  }

  deleteContractFinanceItem(contractId: number, id: number) {
    return this.httpClient.delete(`${environment.apiUrl}${ContractsApiUrls.contracts}/${contractId}/financial-data/${id}`);
  }
}
