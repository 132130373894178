
import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[hourChars]'
})
export class HourCharsDirective {
  @Input() hourChars: boolean;

  constructor() {}

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent> event;
    if (this.hourChars) {
      // Allow: Delete, Backspace, Tab, Escape, Enter, 109 (-), 189 (- on num key)
      if ([46, 8, 9, 27, 13, 109, 189].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode == 65 && e.ctrlKey === true) ||
        // Allow: Ctrl+C
        (e.keyCode == 67 && e.ctrlKey === true) ||
        // Allow: Ctrl+X
        (e.keyCode == 88 && e.ctrlKey === true) ||
        // Allow Shift+:
        (e.keyCode == 186 && e.shiftKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
          // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ("0123456789:.".includes(e.key)) {
        return;
        }
        e.preventDefault();
    }
  }
}
