import { UntypedFormGroup } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";

export function getValidationStatus(form: UntypedFormGroup, field: string) {
  return !form.get(field).valid && form.get(field).touched
    ? "danger"
    : "success";
}

export function isFieldInvalid(form: UntypedFormGroup, field: string) {
  return !form.get(field).valid && form.get(field).touched;
}

export function isFormValid(form: UntypedFormGroup) {
  return form.valid;
}

export function handleValidationErrorMessage(
  form: UntypedFormGroup,
  field: string,
  messages: Array<{
    field: string;
    errors: Array<{ error: string; message: string }>;
  }>,
): string {
  let message = "";
  if (
    !form.get(field).valid &&
    form.get(field).touched &&
    form.get(field).errors
  ) {
    const validationMessagePack = messages.find(
      (messageObject) => messageObject.field === field,
    );
    if (validationMessagePack) {
      const validationMessage = validationMessagePack.errors.find(
        (errorPack) =>
          errorPack.error === Object.keys(form.get(field).errors)[0],
      );
      message = validationMessage ? validationMessage.message : "";
    }
  }

  return message;
}

export function handleValidationErrorMessageForGeneratedFields(
  form: UntypedFormGroup,
  field: string,
  errorFieldName: string,
  messages: Array<{
    field: string;
    errors: Array<{ error: string; message: string }>;
  }>,
): string {
  let message = "";
  if (
    form.get(field) &&
    !form.get(field).valid &&
    form.get(field).touched &&
    form.get(field).errors
  ) {
    const validationMessagePack = messages.find(
      (messageObject) => messageObject.field === errorFieldName,
    );
    if (validationMessagePack) {
      const validationMessage = validationMessagePack.errors.find(
        (errorPack) =>
          errorPack.error === Object.keys(form.get(field).errors)[0],
      );
      message = validationMessage ? validationMessage.message : "";
    }
  }

  return message;
}

export function getErrorMessage(errorObject: HttpErrorResponse): string {
  let message = "";

  const errorsArray =
    (errorObject.error && errorObject.error.errors) ||
    errorObject.message ||
    [];
  if (Array.isArray(errorsArray)) {
    message = errorsArray[0].message || "";
  } else {
    message = errorsArray;
  }
  return message;
}

export function validateFormControlsByName(
  form: UntypedFormGroup,
  controlNames: Array<any>,
): boolean {
  return controlNames.every((control) => form.get(control).valid);
}

export function getAvatarImage(avatar: any): string {
  let image = "assets/images/person.png";
  if (avatar && avatar.file) {
    const b64encoded = btoa(
      new Uint8Array(avatar.file.data).reduce(function (data, byte) {
        return data + String.fromCharCode(byte);
      }, ""),
    );
    image = `data:image/png;base64,${b64encoded}`;
  }
  return image;
}

export function getFullName(firstName: string, surname: string): string {
  return `${firstName} ${surname || ""}`;
}

export function newlineComments(...comments: string[]) {
  return comments
    .map((comment) => `${comment}\n\r`)
    .toString()
    .replace(/,/g, "")
    .trim();
}

export function getServerLink(link: string) {
  return link.includes("localhost") ? "http://" + link : "https://" + link;
}

export function validateAtLeastOneCheckbox(
  controlNames: Array<any>,
  form: UntypedFormGroup,
): boolean {
  const results = controlNames.map((name) =>
    form.get(name).value === true ? true : false,
  );
  return results.find((name) => name === true) ? true : false;
}

export function validateAtLeastOneInput(
  controlNames: Array<any>,
  form: UntypedFormGroup,
): boolean {
  const results = controlNames.map((name) =>
    form.get(name).value ? true : false,
  );
  return results.find((name) => name === true) ? true : false;
}

export function onHoursInput(
  event: any,
  form: UntypedFormGroup,
  fieldName: string,
) {
  if (form.get(fieldName)?.value === "" || form.get(fieldName)?.value === null)
    return;

  const value = event.target.value;
  if (!value.includes(":")) {
    const o = {};
    o[fieldName] = `${value}:00`;
    form.patchValue(o);
  }
}

export function onHoursInputWithExcluding(
  event: any,
  form: UntypedFormGroup,
  fieldName: string,
  excludeValue: string = null,
) {
  const value = event.target.value;
  const isExclude = excludeValue ? value === excludeValue : false;
  if (!value.includes(":") && !isExclude) {
    const o = {};
    o[fieldName] = `${value}:00`;
    form.patchValue(o);
  }
}

export function changeHours(event: any) {
  const value = event.target.value;
  if (!value.includes(":")) {
    return `${value}:00`;
  }
  return value;
}

export function getMinutes(time: any): number {
  if (!time) {
    return 0;
  }
  const splitted = time.split(":");
  let minutes = 0;
  if (!time.includes(":") && time.length > 6) {
    return 0;
  }
  if (splitted.length === 1) {
    minutes = Number(splitted[0]) * 60;
  } else if (splitted.length === 2) {
    minutes = Number(splitted[0]) * 60 + Number(splitted[1]);
  } else {
    minutes =
      moment(time, "HH:mm").hours() * 60 + moment(time, "HH:mm").minutes();
  }
  return minutes;
}

export const round = (x: any, n: any) => parseFloat(x).toFixed(n);

export function getTime(minutes: number) {
  const negative = minutes < 0;
  let minutesRem: number = minutes % 60;
  let hoursRem = (minutes - minutesRem) / 60;
  minutesRem = Math.abs(minutesRem);
  hoursRem = Math.abs(hoursRem);
  return (
    (negative ? "-" : "") +
    hoursRem +
    ":" +
    (minutesRem === 0
      ? "00"
      : minutesRem < 10
        ? "0" + round(minutesRem, 0)
        : round(minutesRem, 0))
  );
}

export function chunkArray(array, chunkSize: number) {
  const returnArray = [];
  for (let i = 0, j = array.length; i < j; i += chunkSize) {
    returnArray.push(array.slice(i, i + chunkSize));
  }
  return returnArray;
}

export function urlify(description: string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  if (description) {
    return description.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });
  } else {
    return "";
  }
}

export function deurlify(description: string) {
  if (!description) {
    return "";
  }
  let counter = 30;
  let desc = description;
  let startPos = 0;
  do {
    counter--;
    startPos = desc.indexOf("<a href=");
    if (startPos == -1) break;
    const endPos = desc.indexOf('">', startPos);
    desc = desc.replace(desc.substring(startPos, endPos + 2), "");
  } while (startPos != -1 && counter > 0);

  desc = desc.split("</a>").join("");
  return desc;
}

export function filterValuesInTable(value: any, data: any = []) {
  return data.filter((item: any) => {
    const transformedFilter = value.trim().toLowerCase();
    const listAsFlatString = (obj): string => {
      let returnVal = "";

      Object.values(obj).forEach((val) => {
        if (typeof val !== "object") {
          returnVal = returnVal + " " + val;
        } else if (val !== null) {
          returnVal = returnVal + " " + listAsFlatString(val);
        }
      });

      return returnVal
        .trim()
        .toLowerCase()
        .replace(/ +(?= )/g, "");
    };
    return listAsFlatString(item).includes(transformedFilter);
  });
}

export function limitTo(text: string, _limitTo: number) {
  text = text || "";
  if (text.length < _limitTo) {
    return text;
  } else {
    return text.substring(0, _limitTo) + "...";
  }
}

export function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

export function correctTimeValueIfNecessary(time: string): string {
  if (
    (time.length === 1 && +time <= 9) ||
    (time.length === 2 && !time.includes(":") && +time <= 24 && +time > 0)
  ) {
    time = `${time}:00`;
  }

  return time;
}

export function getTruncateName(name: string, range: number): string {
  return `${name.substring(0, range)}...`;
}

export function isElementInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
