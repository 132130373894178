import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  CdkDragDrop,
  CdkDragStart,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { Subject, Subscription, forkJoin, fromEvent } from "rxjs";
import { TasksService } from "../../../shared/tasks/tasks.service";
import { getErrorMessage } from "../../../utilities/utils";
import { NbToastrService, NbDialogService } from "@nebular/theme";
import { DictionariesService } from "../../../shared/dictionaries/dictionaries.service";
import { TableService } from "../table/table.service";
import { QuestionDialogComponent } from "../question-dialog/question-dialog.component";
import { PermissionsService } from "../../../@core/data/permissions.service";
import { SubtasksService } from "./subtask.service";
import {
  concatMap,
  debounceTime,
  finalize,
  share,
  shareReplay,
  tap,
} from "rxjs/operators";
import { AuthUserService } from "../../../@core/data/auth-user.service";
import { AddSubtaskAdvancedViewDialogComponent } from "../add-subtask-advanced-view-dialog/add-subtask-advanced-view-dialog.component";
import { ViewportScroller } from "@angular/common";
import { RotaTableService } from "../../employess/rota/rota-table.service";

export const storageKeyTODOS = "USER_ECHO_TASKS_TODOS";

@Component({
  selector: "ngx-subtask-editor",
  templateUrl: "./subtask-editor.component.html",
  styleUrls: ["./subtask-editor.component.scss"],
})
export class SubtaskEditorComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public userTODOS: {
    todos: { id: number; isCollapsed: boolean }[];
    todosIds: number[];
  };
  @Input() advancedView: boolean = false;
  @Input() taskId: number;
  @Input() task: any;
  @Input() taskBoardId: number;
  @Input() canAdd: boolean = true;
  @Input() updateMode: boolean = false;
  @Input() addingTaskInNewTicketMode: boolean = false;
  @Input() scrollToFirstToDo: boolean = false;
  public popoverId: string;
  @Input()
  set refreshComponent(value: boolean) {
    if (value) {
      this.load();
    }
  }
  @Input()
  set forceCheckIsAllTodosR(value: boolean) {
    if (value) {
      this.checkIsAllTodosRAndNoRecurring(true);
    }
  }
  @Output() reloadParent = new EventEmitter();
  @Output() localDataChanged = new EventEmitter();
  @Output() subTasksChanged = new EventEmitter();
  @Output() dataLoaded = new EventEmitter();
  @Output() allTodosCompleted = new EventEmitter();
  @Output() continueEvent = new EventEmitter<{
    isNoRecurring: boolean;
    isAllTodosR: boolean;
  }>();
  @Output() sendRecurringTodos = new EventEmitter<
    { name: string; checked: boolean; taskName: string }[]
  >();
  @Output() saveStatusChanged = new EventEmitter();
  public isGroupManager: boolean = false;
  public newSubtaskTitle: string = "";
  public subtasksInputs = [];
  public defaultSubtaskTitle: string = "Add task name";
  public subtasks = [];
  private tmpSubtasks = [];
  public subtasksTitles: string[] = [];
  public subtasksProgress = "0%";
  public offsetTop = 0;
  public isTaskRecurring = false;
  public isChanged = false;
  private firstLoad = true;
  public isEditorLoaded = true;
  public isZoomEqualOne = false;
  public allCollapsed = false;

  public currentMousePos = { x: 0, y: 0 };

  public subtaskInfo = false;
  public subtaskIndex;
  public taskIndex;

  createInProgress: boolean = false;

  subtaskEditMode: boolean = false;
  todoEditMode: boolean = false;
  editId: string = "";

  editedId: number = null;
  @ViewChild("subtaskInfo") subtaskData: TemplateRef<any>;
  editEnabled = true;

  finishLoading = false;

  private reloadSubject = new Subject<boolean>();

  constructor(
    protected tasksService: TasksService,
    protected authUserServce: AuthUserService,
    protected toastrService: NbToastrService,
    private rotaTableService: RotaTableService,
    protected dictionariesService: DictionariesService,
    protected tableService: TableService,
    protected dialogService: NbDialogService,
    protected permissionsService: PermissionsService,
    protected subtasksService: SubtasksService,
    private scroller: ViewportScroller,
  ) {
    this.subscription.add(
      this.tableService.zoom$.subscribe((value) => {
        this.isZoomEqualOne = (value + "").includes("1.00");
      }),
    );
    this.subscription.add(
      this.subtasksService.keepAllRemaining$.subscribe(() => {
        if (this.advancedView) {
          this.subtasks.forEach((sub: any) => {
            sub.todos.forEach((todo: any) => {
              if (!todo.isNotCompleted && !todo.isCompleted) {
                const isAssignedToSomeone: boolean = !!todo.assignedUsers[0];
                let isAssignedToMe: boolean = false;
                const currentUser = this.authUserServce.getUser();

                todo.assignedUsers.forEach((user) => {
                  if (user.id === currentUser.id) {
                    isAssignedToMe = true;
                  }
                });
                const shouldIgnore: boolean =
                  isAssignedToSomeone && !isAssignedToMe;
                if (shouldIgnore) return;
                if (!todo.latestUpdate && !shouldIgnore) {
                  todo.latestUpdate = "K'd using the 'K all' button";
                }
                this.setStatus(false, false, true, sub.titleIndex, todo.index);
              }
            });
          });
        }
      }),
    );
  }

  get careHomeId() {
    return this.task.careHomeId;
  }

  ngOnInit() {
    this.userTODOS = JSON.parse(localStorage.getItem(storageKeyTODOS));
    if (!this.addingTaskInNewTicketMode) {
      this.load();
    }

    this.subscription.add(
      this.reloadSubject.pipe(debounceTime(500)).subscribe(() => {
        this.load();
      }),
    );
  }

  @HostListener("document:mousemove", ["$event"])
  onMouseMove(event: MouseEvent) {
    this.currentMousePos = { x: event.x, y: event.y };
  }

  openPopover(id: number, subtask: any, i: number, task: any) {
    this.popoverId = "todo-" + id;
    this.dialogService
      .open(this.subtaskData, {
        context: {
          id,
          popId: "todo-" + id,
          todo: this.getTODO(subtask.todos, i),
          highlight: true,
          task,
          subtask,
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: true,
      })
      .onClose.subscribe(() => {
        this.popoverId = "";
      });
  }

  setStatus(
    isDone: boolean,
    focusToNext = true,
    kForAll = false,
    subtaskIndex: number,
    todoIndex: number,
  ) {
    const data = {
      isNotCompleted: !isDone,
      isCompleted: isDone,
      subtaskIndex: subtaskIndex,
      todoIndex: todoIndex,
      focusToNext: focusToNext,
      kForAll: kForAll,
    };
    this.saveTodoStatuses(data, true);
  }

  reloadAdvanced() {
    const mySubs = Object.assign([], this.subtasks);
    if (mySubs.length > 0) {
      this.generateTodoNamesList(mySubs);
    }
    this.subtasks = [];

    for (let index = 0; index < mySubs.length; index++) {
      const element = mySubs[index];
      element.todos.forEach((todo) => {
        const advIndex = this.subtasksTitles.indexOf(todo.comments);
        todo["advIndex"] = advIndex;
      });
      this.subtasks.push(element);
    }
  }

  setTodosStorage(subs: any[]) {
    this.userTODOS = JSON.parse(localStorage.getItem(storageKeyTODOS));
    let todos;
    let todosIds;
    if (!this.userTODOS) {
      todos = [];
      todosIds = [];
    } else {
      todos = [...this.userTODOS.todos];
      todosIds = [...this.userTODOS.todosIds];
    }
    subs.forEach((sub) => {
      sub.todos.forEach((todo) => {
        if (!todosIds.includes(todo.id)) {
          todos.push({ id: todo.id, isCollapsed: todo.isCollapsed });
          todosIds.push(todo.id);
        }
      });
    });
    localStorage.setItem(storageKeyTODOS, JSON.stringify({ todos, todosIds }));
  }

  load() {
    this.subscription.add(
      this.tasksService
        .getTaskSubtasks(this.taskId)
        .pipe(
          finalize(() => {
            setTimeout(() => {
              this.finishLoading = true;
            }, 200);
          }),
        )
        .subscribe(
          (response: any) => {
            this.compactSubtasks(response.result.subTasks || []);
            const mySubs = Object.assign([], this.subtasks);
            this.tmpSubtasks = Object.assign([], mySubs);
            this.setTodosStorage(mySubs);
            if (mySubs.length > 0) {
              this.generateTodoNamesList(mySubs);
            }
            this.subtasks = [];

            for (let index = 0; index < mySubs.length; index++) {
              const element = mySubs[index];
              element.todos.forEach((todo) => {
                const advIndex = this.subtasksTitles.indexOf(todo.comments);
                todo["advIndex"] = advIndex;
              });
              setTimeout(() => {
                this.subtasks.push(element);
                if (index + 1 == mySubs.length) {
                  this.setSubtasksStats(response);
                }
              }, index * 10);
            }
          },
          (err) => {
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
          },
        ),
    );
  }

  generateTodoNamesList(subtasks: any[]) {
    let namesArray = [];
    subtasks.forEach((sub) => {
      sub.todos.forEach((todo) => {
        if (!namesArray.includes(todo.comments)) {
          namesArray.push(todo.comments);
        } else {
          namesArray = namesArray.filter(
            (todoName) => todoName != todo.comments,
          );
          namesArray.push(todo.comments);
        }
      });
    });

    this.subtasksTitles = namesArray;
  }

  getTodo(todos: any[], index: number): boolean {
    let found = false;
    todos.forEach((todo) => {
      if (todo.advIndex == index) {
        found = true;
      }
    });
    return found;
  }

  checkIfCompleted(todos: any[], index: number): boolean {
    let completed = false;
    todos.forEach((todo) => {
      if (todo.advIndex == index && todo.isCompleted) {
        completed = true;
      }
    });
    return completed;
  }

  checkIfNotCompleted(todos: any[], index: number): boolean {
    let notCompleted = false;
    todos.forEach((todo) => {
      if (todo.advIndex == index && todo.isNotCompleted) {
        notCompleted = true;
      }
    });
    return notCompleted;
  }

  getLatestUpdate(todos: any[], index: number): string {
    let data;
    todos.forEach((todo) => {
      if (todo.advIndex == index) {
        data = todo.latestUpdate;
      }
    });

    return data;
  }

  getShortHistory(todos: any[], index: number): string {
    let data;
    todos.forEach((todo) => {
      if (todo.advIndex == index) {
        data = todo.shortHistory;
      }
    });

    return data;
  }

  getTODO(todos: any[], index: number): any {
    return todos.find((todo) => todo.advIndex == index);
  }

  setSubtasksStats = (response: any) => {
    setTimeout(() => {
      this.subtasksProgress = response.result.subTasksFinishedString || "0%";
      this.subTasksChanged.emit({
        subtasks: this.subtasks,
        progress: this.subtasksProgress,
        isSubTasksChanged: this.isChanged,
      });
      setTimeout(() => {
        if (!this.scrollToFirstToDo) {
          this.goToSubtaskIfFirstLoad();
        } else {
          this.findAndFocusFirstToDo();
        }
      }, 1);
      this.dataLoaded.emit(true);
      this.checkIsAllTodosRAndNoRecurring();
    }, 1);
  };

  trackByFn(index, item) {
    return index;
  }

  goToSubtaskIfFirstLoad() {
    if (
      this.firstLoad &&
      this.tableService.getValue() &&
      this.tableService.getValue().subtaskId
    ) {
      const element = document.getElementById(
        `todo-${this.tableService.getValue().subtaskId}`,
      );
      if (element) {
        element.scrollIntoView({ block: "center" });
        const allTodos = this.getAllTodos(false);
        const subtaskFocus = allTodos.find((item: any) => {
          return item.id == this.tableService.getValue().subtaskId;
        });
        const elementToFocus = document.getElementById(subtaskFocus.name);
        if (elementToFocus) {
          elementToFocus.focus();
        }
      }
      this.firstLoad = false;
    }
  }

  findAndFocusFirstToDo() {
    if (this.subtasks.length > 0 && this.subtasks[0].todos.length > 0) {
      const taskId = this.subtasks[0].todos[0].id;
      const element = document.getElementById(`todo-${taskId}`);
      if (element) {
        element.scrollIntoView({ block: "center" });
      }
    }
  }

  compactSubtasks(subtasksData: any) {
    let index = -1;
    if (this.subtasks.length > subtasksData.length) {
      for (const subtask of this.subtasks) {
        index++;
        const subtaskRemoteItem = subtasksData.find((item: any) => {
          return item.title == subtask.title;
        });
        if (subtaskRemoteItem) {
          subtask.todos = subtaskRemoteItem.todos;
        } else {
          this.subtasks.splice(index, 1);
        }
      }
    } else {
      this.subtasks = Object.assign([], subtasksData);
    }
  }

  reloadSubtaskState(response: any) {
    const remoteSubtasks = response.result.subTasks || [];
    for (let i = 0; i < remoteSubtasks.length; i++) {
      const subtaskItem = this.subtasks.find((item: any) => {
        return item.title == remoteSubtasks[i].title;
      });
      if (subtaskItem) {
        subtaskItem.finishedString =
          remoteSubtasks[i] && remoteSubtasks[i].finishedString;
        subtaskItem.titleIndex =
          remoteSubtasks[i] && remoteSubtasks[i].titleIndex;
        // UPDATE TODOS
        const remoteTodos = remoteSubtasks[i].todos || [];
        remoteTodos.forEach((todo: any) => {
          const todoItem = subtaskItem.todos.find((item: any) => {
            return todo.id == item.id;
          });
          if (todoItem) {
            todoItem.index = todo.index;
          }
        });
      }
    }
    this.subtasksProgress = response.result.subTasksFinishedString || "0%";
    this.subtasks.sort((a, b) =>
      a.titleIndex > b.titleIndex ? 1 : b.titleIndex > a.titleIndex ? -1 : 0,
    );

    this.isChanged = true;
    this.isEditorLoaded = true;
    this.subTasksChanged.emit({
      subtasks: this.subtasks,
      progress: this.subtasksProgress,
      isSubTasksChanged: this.isChanged,
    });
  }

  reloadProgressBarsAndColors() {
    return this.subscription.add(
      this.tasksService.getTaskSubtasks(this.taskId).subscribe(
        (response: any) => {
          this.reloadSubtaskState(response);
        },
        (err) => {
          this.isEditorLoaded = true;
          this.toastrService.danger(getErrorMessage(err), "Error", {
            duration: 60000,
            destroyByClick: true,
          });
        },
      ),
    );
  }

  reload(keepAFGAndLatestUpdate = false) {
    this.reloadProgressBarsAndColors();
    this.reloadParent.emit({ keepAFGAndLatestUpdate });
  }

  isSubtaskTitleExist(title: string) {
    const subtaskRemoteItem = this.subtasks.find((item: any) => {
      return item.title == title;
    });
    return subtaskRemoteItem;
  }

  addTodoWindow() {
    let subtasksNames: string[] = [];
    this.subtasks.forEach((sub) => {
      subtasksNames.push(sub.title);
    });

    this.dialogService
      .open(AddSubtaskAdvancedViewDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          addTodos: true,
          subtasksNames: subtasksNames,
          okLabel: "Create",
          cancelLabel: "Cancel",
        },
      })
      .onClose.subscribe((res) => {
        if (res.create) {
          if (res.subtasks) {
            const requests = [];
            const titleIndex = [];
            this.subtasks.forEach((sub, index) => {
              if (res.subtasks.includes(sub.title)) {
                requests.push(
                  this.addAdvancedTodo(
                    sub.titleIndex || index,
                    res.title,
                    true,
                  ),
                );
                titleIndex.push(sub.titleIndex || index);
              }
            });
            forkJoin(requests).subscribe(
              (results) => {
                results.forEach((response, i) => {
                  const index = titleIndex[i];
                  const title = res.title;
                  const multi = true;
                  const todoObj = {
                    id: response.result[0],
                    comments: title,
                    toDoStartIndex: index,
                    index: index,
                    title: this.subtasks[index].title,
                  };
                  this.subtasks[index].todos.push(todoObj);
                  this.subTasksChanged.emit({
                    subtasks: this.subtasks,
                    progress: this.subtasksProgress,
                    isSubTasksChanged: this.isChanged,
                  });
                  this.saveStatusChanged.next(true);
                  this.toastrService.success(
                    "New Todo has been successfully added",
                    "NEW TODO",
                    {
                      duration: 2000,
                      destroyByClick: true,
                    },
                  );
                  this.reloadAdvanced();
                  this.createInProgress = false;

                  if (!multi) {
                    setTimeout(() => {
                      const newTodoId = document.getElementById(
                        `todoBox-${todoObj.id}`,
                      );
                      newTodoId.click();
                    }, 500);
                  } else {
                    this.reloadSubject.next(true);
                  }
                });
              },
              (err) => {
                this.createInProgress = false;
                this.toastrService.danger(getErrorMessage(err), "Error", {
                  duration: 60000,
                  destroyByClick: true,
                });
                this.saveStatusChanged.next(true);
                this.reloadSubject.next(true);
              },
            );
          }
        }
      });
  }

  addSubtaskWindow() {
    this.dialogService
      .open(AddSubtaskAdvancedViewDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          addTodos: false,
          subtasksNames: this.subtasksTitles,
          okLabel: "Save",
          cancelLabel: "Cancel",
        },
      })
      .onClose.subscribe((res) => {
        if (res.save) {
          this.newSubtaskTitle = res.title;
          if (this.isSubtaskTitleExist(this.newSubtaskTitle)) {
            this.toastrService.danger(
              "Subtask title exist. Can not add new one with the same title.",
              "Error",
              { duration: 60000, destroyByClick: true },
            );
            this.newSubtaskTitle = "";
          } else {
            const subtaskObject = {
              title: this.newSubtaskTitle,
              todos: [],
            };
            this.subtasksInputs.push("");
            this.subtasks.push(subtaskObject);
            const newSubIndex = this.subtasks.findIndex(
              (sub) => sub.title === res.title,
            );
            if (res.todos) {
              const requests = [];
              res.todos.forEach((todo) => {
                requests.push(
                  this.addAdvancedTodo(
                    newSubIndex,
                    { ...todo, isGridMode: true },
                    true,
                  ),
                );
              });
              forkJoin(requests).subscribe(
                (results) => {
                  results.forEach((response, i) => {
                    const index = newSubIndex;
                    const title = res.title;
                    const multi = true;
                    const todoObj = {
                      id: response.result[0],
                      comments: title,
                      toDoStartIndex: index,
                      index: index,
                      title: this.subtasks[index].title,
                    };
                    this.subtasks[index].todos.push(todoObj);
                    this.subTasksChanged.emit({
                      subtasks: this.subtasks,
                      progress: this.subtasksProgress,
                      isSubTasksChanged: this.isChanged,
                    });
                    this.saveStatusChanged.next(true);
                    this.toastrService.success(
                      "New Todo has been successfully added",
                      "NEW TODO",
                      {
                        duration: 2000,
                        destroyByClick: true,
                      },
                    );
                    this.reloadAdvanced();
                    this.createInProgress = false;

                    if (!multi) {
                      setTimeout(() => {
                        const newTodoId = document.getElementById(
                          `todoBox-${todoObj.id}`,
                        );
                        newTodoId.click();
                      }, 500);
                    } else {
                      this.reloadSubject.next(true);
                    }
                  });
                },
                (err) => {
                  this.createInProgress = false;
                  this.toastrService.danger(getErrorMessage(err), "Error", {
                    duration: 60000,
                    destroyByClick: true,
                  });
                  this.saveStatusChanged.next(true);
                  this.reloadSubject.next(true);
                },
              );
            }
            this.newSubtaskTitle = "";
            this.toastrService.success(
              "New Subtask has been successfully added",
              "NEW SUBTASK",
              {
                duration: 5000,
                destroyByClick: true,
              },
            );
          }
        }
      });
  }

  addSubtask(event: any) {
    if (this.isSubtaskTitleExist(this.newSubtaskTitle)) {
      this.toastrService.danger(
        "Subtask title exist. Can not add new one with the same title.",
        "Error",
        { duration: 60000, destroyByClick: true },
      );
      const subTaskIndex = this.subtasks.findIndex(
        (item: any) => item.title == this.newSubtaskTitle,
      );
      if (subTaskIndex > -1) {
        this.newSubtaskTitle = "";
        event.preventDefault();
        setTimeout(() => {
          document
            .getElementById("subtask-editor-add-todo-input-" + subTaskIndex)
            .focus();
        }, 100);
      }
    } else {
      const subtaskObject = {
        title: this.newSubtaskTitle,
        todos: [],
      };
      this.subtasksInputs.push("");
      this.subtasks.push(subtaskObject);
      this.newSubtaskTitle = "";
      event.preventDefault();
      setTimeout(() => {
        let newSubTitle = document.getElementById(
          `subtask-editable-title-${subtaskObject.title.split(" ").join("")}`,
        );
        newSubTitle.scrollIntoView();
        newSubTitle.click();
        setTimeout(() => {
          document
            .getElementById(
              "subtask-editor-add-todo-input-" + (this.subtasks.length - 1),
            )
            .scrollIntoView();
          document
            .getElementById(
              "subtask-editor-add-todo-input-" + (this.subtasks.length - 1),
            )
            .focus();
        }, 100);
      }, 100);
    }
  }

  addAdvancedTodo(index: number, title: string, multi = false) {
    this.createInProgress = true;
    const data = {
      taskId: Array.isArray(this.taskId) ? this.taskId : [this.taskId],
      title: this.subtasks[index].title,
      comments: title,
      taskBoardId: this.taskBoardId,
      isSubtask: true,
      index: index,
      openTime: new Date(),
      addingTaskInNewTicketMode: this.addingTaskInNewTicketMode,
      isGridMode: true,
    };
    this.toastrService.info("Work in progress...", "CREATING NEW TODO", {
      duration: 2000,
      destroyByClick: true,
    });

    this.finishLoading = false;
    return this.tasksService.addTodo(this.taskId, data).pipe(
      finalize(() => {
        this.finishLoading = true;
      }),
    );
    // .subscribe(
    //   (response: any) => {
    //     const todoObj = {
    //       id: response.result[0],
    //       comments: title,
    //       toDoStartIndex: index,
    //       index: index,
    //       title: this.subtasks[index].title,
    //     };
    //     this.subtasks[index].todos.push(todoObj);
    //     this.subTasksChanged.emit({
    //       subtasks: this.subtasks,
    //       progress: this.subtasksProgress,
    //       isSubTasksChanged: this.isChanged,
    //     });
    //     this.saveStatusChanged.next(true);
    //     this.toastrService.success('New Todo has been successfully added', "NEW TODO", {
    //       duration: 2000,
    //       destroyByClick: true,
    //     });
    //     this.reloadAdvanced()
    //     this.createInProgress = false

    //     if (!multi) {
    //       setTimeout(() => {
    //         const newTodoId = document.getElementById(`todoBox-${todoObj.id}`)
    //         newTodoId.click()
    //       }, 500)
    //     }  else {
    //       this.reloadSubject.next(true);
    //     }
    //   },
    //   (err) => {
    //     this.createInProgress = false
    //     this.toastrService.danger(getErrorMessage(err), "Error", {
    //       duration: 60000,
    //       destroyByClick: true,
    //     });
    //     this.saveStatusChanged.next(true);
    //   }
    // )
  }

  addTodo(event: any, index: number) {
    if (
      this.subtasksInputs[index] &&
      this.subtasksInputs[index].trim().length != 0
    ) {
      this.saveStatusChanged.next(false);
      if (event) {
        event.preventDefault();
      }
      const todoTitle = this.subtasksInputs[index];
      const data = {
        taskId: Array.isArray(this.taskId) ? this.taskId : [this.taskId],
        title: this.subtasks[index].title,
        comments: todoTitle,
        taskBoardId: this.taskBoardId,
        isSubtask: true,
        isCompleted: false,
        isNotCompleted: true,
        isRecurring: false,
        index: index,
        openTime: new Date(),
        addingTaskInNewTicketMode: this.addingTaskInNewTicketMode,
        isGridMode: false,
      };
      this.subtasksInputs[index] = "";
      this.finishLoading = false;
      this.subscription.add(
        this.tasksService
          .addTodo(this.taskId, data)
          .pipe(
            finalize(() => {
              this.finishLoading = true;
            }),
          )
          .subscribe(
            (response: any) => {
              const todoObj = {
                id: response.result[0],
                comments: todoTitle,
                toDoStartIndex: index,
                index: index,
                title: this.subtasks[index].title,
              };
              this.subtasks[index].todos.push(todoObj);
              this.subTasksChanged.emit({
                subtasks: this.subtasks,
                progress: this.subtasksProgress,
                isSubTasksChanged: this.isChanged,
              });
              this.saveStatusChanged.next(true);
              // this.reload(true);
            },
            (err) => {
              this.toastrService.danger(getErrorMessage(err), "Error", {
                duration: 60000,
                destroyByClick: true,
              });
              this.saveStatusChanged.next(true);
            },
          ),
      );
    } else {
      this.toastrService.warning("Todo title cannot be blank", "Info", {
        duration: 60000,
        destroyByClick: true,
      });
    }
  }

  deleteSubtask(index: number, isAdvanced = false) {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: "Are you sure?",
          message: "You want to delete this subtask?",
          okLabel: "Yes",
          cancelLabel: "No",
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          const todos = this.subtasks[index].todos.map((item: any) => {
            return item.id;
          });
          this.finishLoading = false;
          this.subscription.add(
            this.tasksService
              .deleteSubtasks(
                this.taskId,
                todos,
                this.addingTaskInNewTicketMode,
              )
              .pipe(
                finalize(() => {
                  this.finishLoading = true;
                }),
              )
              .subscribe(
                (response: any) => {
                  this.subtasks.splice(index, 1);
                  this.subtasksInputs.splice(index, 1);
                  this.toastrService.success(response.message, "Success");
                  if (isAdvanced) {
                    this.reloadAdvanced();
                  } else {
                    this.reload();
                  }
                },
                (err) => {
                  this.toastrService.danger(getErrorMessage(err), "Error", {
                    duration: 60000,
                    destroyByClick: true,
                  });
                },
              ),
          );
        }
      });
  }

  saveSubtaskTitle(data: { title: string; index: number }, isAdvanced = false) {
    const todos = this.subtasks[data.index].todos.map((item: any) => {
      return item.id;
    });
    this.subtasks[data.index].title = data.title;
    this.finishLoading = false;
    this.subscription.add(
      this.tasksService
        .updateSubtasksTitle(this.taskId, {
          title: data.title,
          ids: todos,
          addingTaskInNewTicketMode: this.addingTaskInNewTicketMode,
        })
        .pipe(
          finalize(() => {
            this.finishLoading = true;
          }),
        )
        .subscribe(
          (response: any) => {
            this.toastrService.success(response.message, "Success");
            if (isAdvanced) {
              this.reloadAdvanced();
            } else {
              this.reload();
            }
          },
          (err) => {
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
          },
        ),
    );
  }

  moveSubtask(
    data: { title: string; oldIndex: number; newIndex: number },
    isAdvanced = false,
  ) {
    this.isEditorLoaded = false;
    this.finishLoading = false;
    this.subscription.add(
      this.tasksService
        .changeSubtaskTitleOrder(this.taskId, {
          // isSubTaskTitle: true,
          // title: data.title,
          newIndex: data.newIndex,
          oldIndex: data.oldIndex,
          taskBoardId: this.taskBoardId,
        })
        .pipe(
          finalize(() => {
            this.finishLoading = true;
          }),
        )
        .subscribe(() => {
          if (isAdvanced) {
            this.reloadAdvanced();
          } else {
            this.reload();
          }
        }),
    );
  }

  saveTodoComments(
    data: {
      comments: string;
      subtaskIndex: number;
      todoIndex: number;
    },
    isAdvanced = false,
    isMulti = false,
  ) {
    isAdvanced
      ? (this.subtasks[data.subtaskIndex].todos.find(
          (todo) => todo.index == data.todoIndex,
        ).comments = data.comments)
      : (this.subtasks[data.subtaskIndex].todos[data.todoIndex].comments =
          data.comments);
    const toDo = isAdvanced
      ? this.subtasks[data.subtaskIndex].todos.find(
          (todo) => todo.index == data.todoIndex,
        )
      : this.subtasks[data.subtaskIndex].todos[data.todoIndex];
    const toDoData = {
      taskId: this.taskId,
      title: this.subtasks[data.subtaskIndex].title,
      comments: toDo.comments,
      taskBoardId: this.taskBoardId,
      isSubtask: true,
      openTime: new Date(),
      statusId: toDo.statusId,
      addingTaskInNewTicketMode: this.addingTaskInNewTicketMode,
    };
    this.finishLoading = false;
    this.subscription.add(
      this.tasksService
        .updateTask(toDo.id, toDoData)
        .pipe(
          finalize(() => {
            this.finishLoading = true;
          }),
        )
        .subscribe(
          () => {
            this.checkIsAllTodosRAndNoRecurring();
            this.reloadParent.emit({ keepAFGAndLatestUpdate: false });
            if (isAdvanced && !isMulti) {
              this.subtaskInfo = false;
              this.reloadAdvanced();
            }
          },
          (err) => {
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
          },
        ),
    );
  }

  setTodoInfinity(
    data: {
      subtaskIndex: number;
      todoIndex: number;
      isRecurring: boolean;
    },
    isAdvanced = false,
  ) {
    const toDo = isAdvanced
      ? this.subtasks[data.subtaskIndex].todos.find(
          (todo) => todo.index == data.todoIndex,
        )
      : this.subtasks[data.subtaskIndex].todos[data.todoIndex];
    const toDoData = {
      taskId: this.taskId,
      title: this.subtasks[data.subtaskIndex].title,
      comments: toDo.comments,
      taskBoardId: this.taskBoardId,
      isSubtask: true,
      openTime: new Date(),
      isCompleted: toDo.isCompleted,
      isNotCompleted: toDo.isNotCompleted,
      isRecurring: data.isRecurring,
      addingTaskInNewTicketMode: this.addingTaskInNewTicketMode,
    };
    this.finishLoading = false;
    this.subscription.add(
      this.tasksService
        .updateTask(toDo.id, toDoData)
        .pipe(
          finalize(() => {
            this.finishLoading = true;
          }),
        )
        .subscribe(
          (response: any) => {
            this.toastrService.success(response.message, "Success");
            isAdvanced
              ? (this.subtasks[data.subtaskIndex].todos.find(
                  (todo) => todo.index == data.todoIndex,
                ).isRecurring = data.isRecurring)
              : (this.subtasks[data.subtaskIndex].todos[
                  data.todoIndex
                ].isRecurring = data.isRecurring);
            this.checkTaskRecurring();
            this.checkIsAllTodosRAndNoRecurring();
            //this.checkIfDoneAndAllRecurring(this.subtasks[data.subtaskIndex])
            this.localDataChanged.emit({ isRecurring: this.isTaskRecurring });
          },
          (err) => {
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
          },
        ),
    );
  }

  saveTodoStatuses(
    data: {
      isNotCompleted: boolean;
      isCompleted: boolean;
      subtaskIndex: number;
      todoIndex: number;
      focusToNext: boolean;
      kForAll: boolean;
    },
    isAdvanced = false,
  ) {
    if (data.focusToNext && !isAdvanced) {
      this.findAndFocusNextTodoField(data.subtaskIndex, data.todoIndex);
    }
    const toDo = isAdvanced
      ? this.subtasks[data.subtaskIndex].todos.find(
          (todo) => todo.index == data.todoIndex,
        )
      : this.subtasks[data.subtaskIndex].todos[data.todoIndex];
    if (
      data.isNotCompleted !== toDo.isNotCompleted ||
      data.isCompleted !== toDo.isCompleted
    ) {
      const toDoData = {
        taskId: this.taskId,
        title: this.subtasks[data.subtaskIndex].title,
        comments: toDo.comments,
        taskBoardId: this.taskBoardId,
        isSubtask: true,
        openTime: new Date(),
        isCompleted: data.isCompleted,
        isNotCompleted: data.isNotCompleted,
        addingTaskInNewTicketMode: this.addingTaskInNewTicketMode,
        kForAll: data.kForAll,
      };
      this.finishLoading = false;
      this.subscription.add(
        this.tasksService
          .updateTask(toDo.id, toDoData)
          .pipe(
            finalize(() => {
              this.finishLoading = true;
            }),
          )
          .subscribe(
            (response: any) => {
              isAdvanced
                ? (this.subtasks[data.subtaskIndex].todos.find(
                    (todo) => todo.index == data.todoIndex,
                  ).isCompleted = data.isCompleted)
                : (this.subtasks[data.subtaskIndex].todos[
                    data.todoIndex
                  ].isCompleted = data.isCompleted);
              isAdvanced
                ? (this.subtasks[data.subtaskIndex].todos.find(
                    (todo) => todo.index == data.todoIndex,
                  ).isNotCompleted = data.isNotCompleted)
                : (this.subtasks[data.subtaskIndex].todos[
                    data.todoIndex
                  ].isNotCompleted = data.isNotCompleted);
              this.toastrService.success(response.message, "Success");
              this.calculateNewStatus();
              this.isChanged = true;
              //this.checkIfDoneAndAllRecurring(this.subtasks[data.subtaskIndex])
              this.checkIsAllTodosRAndNoRecurring();
              this.subTasksChanged.emit({
                subtasks: this.subtasks,
                progress: this.subtasksProgress,
                isSubTasksChanged: this.isChanged,
              });
              this.reloadParent.emit({ keepAFGAndLatestUpdate: false });

              isAdvanced
                ? (this.subtasks[data.subtaskIndex].todos.find(
                    (todo) => todo.index == data.todoIndex,
                  ).isDisabled = false)
                : (this.subtasks[data.subtaskIndex].todos[
                    data.todoIndex
                  ].isDisabled = false);
            },
            (err) => {
              this.toastrService.danger(getErrorMessage(err), "Error", {
                duration: 60000,
                destroyByClick: true,
              });
              this.subtasks[data.subtaskIndex].todos[
                data.todoIndex
              ].isDisabled = false;
            },
          ),
      );
    }
  }

  calculateNewStatus() {
    let total = 0;
    let finished = 0;
    let index = -1;
    for (const subTask of this.subtasks) {
      index++;
      this.subtasks[index].finished = 0;
      this.subtasks[index].total = 0;
      this.subtasks[index].finishedString = "0%";
      for (const todo of subTask.todos) {
        total++;
        this.subtasks[index].total++;
        if (todo.isCompleted) {
          finished++;
          this.subtasks[index].finished++;
        }

        this.subtasks[index].finishedString =
          Math.round(
            (this.subtasks[index].finished / this.subtasks[index].total) * 100,
          ) + "%";
      }
    }

    this.subtasksProgress = Math.round((finished / total) * 100) + "%";
  }

  checkTaskRecurring() {
    this.isTaskRecurring = false;
    for (const subTask of this.subtasks) {
      for (const todo of subTask.todos) {
        if (todo.isRecurring) {
          this.isTaskRecurring = true;
          break;
        }
      }
    }
  }

  checkIsAllTodosRAndNoRecurring(returnAlsoContinueEvent = false) {
    let allDone: boolean = true;
    let noRecurring: boolean = true;
    for (const subTask of this.subtasks) {
      for (const todo of subTask.todos) {
        if (todo.isRecurring) {
          noRecurring = false;
          break;
        }
      }
    }
    for (const subTask of this.subtasks) {
      for (const todo of subTask.todos) {
        if (!todo.isCompleted || todo.isNotCompleted) {
          allDone = false;
          break;
        }
      }
    }

    this.allTodosCompleted.next(allDone);
    if (returnAlsoContinueEvent) {
      if (!noRecurring) {
        this.sendRecurringTodos.next(this.prepareRecurringTodos());
      }
      this.continueEvent.next({
        isNoRecurring: noRecurring,
        isAllTodosR: allDone,
      });
    }
  }

  prepareRecurringTodos(): {
    name: string;
    checked: boolean;
    taskName: string;
  }[] {
    let recurringTodos: { name: string; checked: boolean; taskName: string }[] =
      [];

    for (const subTask of this.subtasks) {
      for (const [index, todo] of subTask.todos.entries()) {
        if (todo.isRecurring) {
          recurringTodos.push({
            name: todo.comments,
            checked: false,
            taskName: subTask.title,
          });
        }
      }
    }

    return recurringTodos;
  }

  checkIfDoneAndAllRecurring(subTask: any) {
    let done = true;
    let recurring = true;
    for (const todo of subTask.todos) {
      if (!todo.isCompleted || todo.isNotCompleted) {
        done = false;
        break;
      }
    }
    for (const subTask of this.subtasks) {
      for (const todo of subTask.todos) {
        if (!todo.isRecurring) {
          recurring = false;
          break;
        }
      }
    }
    if (done && recurring) {
      this.showAllDoneAndRecurringQuestion(subTask);
    }
  }

  showAllDoneAndRecurringQuestion(subTask: any) {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: "Remove Recurring Task?",
          message: "Would you like to remove the recurring tasks or keep them?",
          okLabel: "Remove It",
          cancelLabel: "Keep It",
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          let subIndex = this.subtasks.findIndex(
            (sub) => sub.title == subTask.title,
          );
          for (const todo of subTask.todos) {
            let todoIndex = subTask.todos.findIndex(
              (iTodo) => iTodo.id == todo.id,
            );
            todo.isRecurring = false;
            this.setTodoInfinity(
              {
                subtaskIndex: subIndex,
                todoIndex: todoIndex,
                isRecurring: false,
              },
              this.advancedView,
            );
          }
        }
      });
  }

  deleteTodo(
    data: { subtaskIndex: number; todoIndex: number },
    isAdvanced = false,
  ) {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: "Are you sure?",
          message: "You want to delete this todo?",
          okLabel: "Yes",
          cancelLabel: "No",
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          this.finishLoading = false;
          this.subscription.add(
            this.tasksService
              .deleteSubtasks(
                this.taskId,
                [
                  isAdvanced
                    ? this.subtasks[data.subtaskIndex].todos.find(
                        (todo) => todo.index == data.todoIndex,
                      ).id
                    : this.subtasks[data.subtaskIndex].todos[data.todoIndex].id,
                ],
                this.addingTaskInNewTicketMode,
              )
              .pipe(
                finalize(() => {
                  this.finishLoading = true;
                }),
              )
              .subscribe(
                (response: any) => {
                  this.toastrService.success(response.message, "Success");
                  if (isAdvanced) {
                    this.subtaskInfo = false;
                    let todoIndex = this.subtasks[
                      data.subtaskIndex
                    ].todos.findIndex((todo) => todo.index == data.todoIndex);
                    this.subtasks[data.subtaskIndex].todos.splice(todoIndex, 1);
                    this.reloadAdvanced();
                  } else {
                    this.reload(true);
                    this.subtasks[data.subtaskIndex].todos.splice(
                      data.todoIndex,
                      1,
                    );
                  }
                },
                (err) => {
                  this.toastrService.danger(getErrorMessage(err), "Error", {
                    duration: 60000,
                    destroyByClick: true,
                  });
                },
              ),
          );
        }
      });
  }

  saveTodoLatestUpdate(
    data: {
      latestUpdate: string;
      subtaskIndex: number;
      todoIndex: number;
    },
    isAdvanced = false,
  ) {
    const toSend = {
      latestUpdate: data.latestUpdate,
      openTime: new Date(),
      addingTaskInNewTicketMode: this.addingTaskInNewTicketMode,
    };
    this.finishLoading = false;
    this.subscription.add(
      this.tasksService
        .patchTask(
          isAdvanced
            ? this.subtasks[data.subtaskIndex].todos.find(
                (todo) => todo.index == data.todoIndex,
              ).id
            : this.subtasks[data.subtaskIndex].todos[data.todoIndex].id,
          toSend,
        )
        .pipe(
          finalize(() => {
            this.finishLoading = true;
          }),
        )
        .subscribe(() => {
          this.checkIsAllTodosRAndNoRecurring();
          this.isChanged = true;
          this.subTasksChanged.emit({
            subtasks: this.subtasks,
            progress: this.subtasksProgress,
            isSubTasksChanged: this.isChanged,
          });
        }),
    );
  }

  addTaskResponse(data: { groupResponse: string; latestUpdate: string }) {
    this.subtasksService.waitForTaskResponse = true;
    const toSend = {
      dueTo: this.task.dueTo,
      statusId: this.task.statusId,
      taskGoupingId: this.task.taskGoupingId,
      taskRagg1Id: this.task.taskRagg1Id,
      taskRagg2Id: this.task.taskRagg2Id,
      openTime: this.task.openTime,
      groupResponse: data.groupResponse,
      latestUpdate: data.latestUpdate,
    };
    this.finishLoading = false;
    this.subscription.add(
      this.tasksService
        .patchTask(this.task.taskId, toSend)
        .pipe(
          finalize(() => {
            this.finishLoading = true;
          }),
        )
        .subscribe(
          () => {
            this.reloadParent.emit({ keepAFGAndLatestUpdate: false });
            this.isChanged = true;
            this.subTasksChanged.emit({
              subtasks: this.subtasks,
              progress: this.subtasksProgress,
              isSubTasksChanged: this.isChanged,
            });
          },
          (err) => {
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
          },
        ),
    );
  }

  changeAssignedUsers(
    data: {
      subtaskIndex: number;
      todoIndex: number;
      assignedUsers: [];
    },
    isAdvanced = false,
  ) {
    const toSend = {
      userList: data.assignedUsers,
    };
    this.finishLoading = false;
    this.subscription.add(
      this.tasksService
        .updateAssignedUsers(
          this.taskId,
          isAdvanced
            ? this.subtasks[data.subtaskIndex].todos.find(
                (todo) => todo.index == data.todoIndex,
              ).id
            : this.subtasks[data.subtaskIndex].todos[data.todoIndex].id,
          toSend,
        )
        .pipe(
          finalize(() => {
            this.finishLoading = true;
          }),
        )
        .subscribe(
          () => {},
          (err) => {
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
          },
        ),
    );
  }

  onDrop(event: CdkDragDrop<string[]>) {
    //if (!this.isZoomEqualOne) return;
    if (event.container.id != event.previousContainer.id) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      this.moveTodoBySubtaskTitle(
        event.container.element.nativeElement.id,
        event.item.data,
        event,
      );
    } else {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      this.moveTodoInSubtask(event, event.container.element.nativeElement.id);
    }

    this.allCollapsed = false;

    const container = document.getElementById("edit-task-container-info-tab");
    container.style.paddingBottom = "0";

    setTimeout(() => {
      const item = document.getElementById(`todo-${event.item.data.id}`);

      if (!item) {
        console.log("not");
        return;
      }

      item.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 300);
  }

  moveTodoBySubtaskTitle(subtaskIndex: any, todo: any, event: any) {
    this.isEditorLoaded = false;
    const toDoData = {
      taskId: this.taskId,
      title: this.subtasks[subtaskIndex].title,
      comments: todo.comments,
      taskBoardId: this.taskBoardId,
      isSubtask: true,
      openTime: new Date(),
      statusId: todo.statusId,
      addingTaskInNewTicketMode: this.addingTaskInNewTicketMode,
    };
    this.finishLoading = false;
    this.subscription.add(
      this.tasksService
        .updateTask(todo.id, toDoData)
        .pipe(
          concatMap(() =>
            this.tasksService.getTaskSubtasks(this.taskId).pipe(
              tap((response) => this.reloadSubtaskState(response)),
              concatMap(() =>
                this.moveTodo(event, event.container.element.nativeElement.id),
              ),
              concatMap(() =>
                this.tasksService
                  .getTaskSubtasks(this.taskId)
                  .pipe(tap((response) => this.reloadSubtaskState(response))),
              ),
            ),
          ),
          finalize(() => {
            this.finishLoading = true;
          }),
        )
        .subscribe(
          () => {
            this.isEditorLoaded = true;
          },
          (err) => {
            this.isEditorLoaded = true;
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
          },
        ),
    );
  }

  public moveTodoInSubtask(event: any, subtaskIndex: any) {
    this.isEditorLoaded = false;
    if (!event.item.data) {
      this.isEditorLoaded = true;
      return;
    }
    this.finishLoading = false;
    this.subscription.add(
      this.moveTodo(event, subtaskIndex)
        .pipe(
          concatMap(() =>
            this.tasksService
              .getTaskSubtasks(this.taskId)
              .pipe(tap((response) => this.reloadSubtaskState(response))),
          ),
          finalize(() => {
            this.finishLoading = true;
          }),
        )
        .subscribe(
          (res) => {
            if (this.advancedView) {
              this.load();
            }
            this.isEditorLoaded = true;
          },
          (err) => {
            this.isEditorLoaded = true;
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
          },
        ),
    );
  }

  public moveTodo(event: any, subtaskIndex: any) {
    // true = move down, false = move up

    let rowBefore;
    let rowAfter;

    if (!event.item.data) {
      return;
    }
    if (this.advancedView && event.item.data.toDoStartIndex != 0) {
      let change = event.currentIndex - event.previousIndex;
      let taskNormalIndex = this.tmpSubtasks[subtaskIndex].todos.findIndex(
        (todo) => todo.advIndex == event.previousIndex,
      );

      event.previousIndex = taskNormalIndex;
      event.currentIndex = taskNormalIndex + change;

      if (change > 0) {
        rowBefore = this.subtasks[subtaskIndex].todos[event.currentIndex];
        rowAfter = this.subtasks[subtaskIndex].todos[event.currentIndex + 1];
      } else {
        rowBefore = this.subtasks[subtaskIndex].todos[event.currentIndex - 1];
        rowAfter = this.subtasks[subtaskIndex].todos[event.currentIndex];
      }
    } else {
      rowBefore = this.subtasks[subtaskIndex].todos[event.currentIndex - 1];
      rowAfter = this.subtasks[subtaskIndex].todos[event.currentIndex + 1];
    }

    return this.tasksService.changeTaskOrder(event.item.data.id, {
      isSubTask: true,
      rowBefore,
      rowAfter,
      newIndex: event.currentIndex,
      oldIndex: event.previousIndex,
      taskBoardId: this.taskBoardId,
    });
  }

  public dragStarted(
    event: CdkDragStart,
    todos: any[],
    containerIndex: number,
    todoIndex: number,
  ) {
    this.allCollapsed = true;

    setTimeout(() => {
      if (!(event.event instanceof MouseEvent)) return;
      const dropContainer = event.source._dragRef["_dropContainer"];

      // hacky workaround - cdk has huuge problems with caching sizes of containers
      // and it's not updating them when the heights change
      // entering another container forces it to recache the sizes

      dropContainer.exit();
      dropContainer.enter(
        event.source._dragRef,
        this.currentMousePos.x,
        this.currentMousePos.y,
      );
    }, 550);

    if (!(event.event instanceof MouseEvent)) return;

    const container = document.getElementById("edit-task-container-info-tab");
    container.style.paddingBottom = "700px";

    if (todos.length === 1) {
      if (containerIndex === undefined) {
        return;
      }

      const element = document.getElementById(
        `index-${containerIndex.toString()}`,
      );

      if (!element) return;

      this.animateScroll(container, element, event);

      return;
    }
    if (todoIndex === todos.length - 1) {
      todoIndex = todoIndex - 1;

      const element = document.getElementById(`todo-${todos[todoIndex].id}`);
      if (!element) return;

      this.animateScroll(container, element, event, "bottom");
      return;
    }

    todoIndex = todoIndex + 1;

    const element = document.getElementById(`todo-${todos[todoIndex].id}`);
    if (!element) return;

    this.animateScroll(container, element, event);
  }

  public animateScroll(
    container: HTMLElement,
    element: HTMLElement,
    event: CdkDragStart,
    edge: "top" | "bottom" = "top",
    duration: number = 500,
  ) {
    const startTime = performance.now();
    let animationId: number;

    const dropContainer = event.source._dragRef["_dropContainer"];

    // hacky workaround - cdk has huuge problems with caching sizes of containers
    // and it's not updating them when the heights change
    // entering another container forces it to recache the sizes

    const animateStep = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const scrollPosition = this.calculateScrollPosition(
        container,
        element,
        edge,
      );

      container.scrollTop = scrollPosition;

      if (elapsedTime < duration) {
        animationId = requestAnimationFrame(animateStep);
      } else {
        if (!(event.event instanceof MouseEvent)) return;

        dropContainer.exit();
        dropContainer.enter(
          event.source._dragRef,
          this.currentMousePos.x,
          this.currentMousePos.y,
        );
      }
    };

    animationId = requestAnimationFrame(animateStep);
  }

  public calculateScrollPosition(
    container: HTMLElement,
    element: HTMLElement,
    edge: "top" | "bottom" = "top",
  ) {
    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    const elementPosition =
      elementRect[edge] - containerRect.top + container.scrollTop;

    const mousePositionRelativeToContainer =
      this.currentMousePos.y - containerRect.top;

    return elementPosition - mousePositionRelativeToContainer;
  }

  findAndFocusNextTodoField(subtaskIndex: number, todoIndex: number) {
    const allTodos = this.getAllTodos();
    const foundIndex = allTodos.findIndex((item: any) => {
      return item.subtaskIndex == subtaskIndex && item.todoIndex == todoIndex;
    });
    let indexToFocus = foundIndex + 1;
    if (foundIndex == -1) {
      indexToFocus = allTodos.findIndex((item: any) => {
        return item.subtaskIndex >= subtaskIndex || item.todoIndex >= todoIndex;
      });
    }

    if (indexToFocus == -1 || indexToFocus >= allTodos.length) {
      indexToFocus = 0;
    }

    if (allTodos[indexToFocus]) {
      document.getElementById(allTodos[indexToFocus].name).focus();
    }
  }

  getAllTodos(onlyNotCompleted: boolean = true) {
    const allTodos = [];
    for (let i = 0; i < this.subtasks.length; i++) {
      for (let j = 0; j < this.subtasks[i].todos.length; j++) {
        const todo = Object.assign({}, this.subtasks[i].todos[j]);
        todo.name = `subtask-editable-todo-edit-latest-update-input-${i}-${j}~~~${this.getSubtaskLatestUpdate(
          todo.comments,
        )}`;
        todo.subtaskIndex = i;
        todo.todoIndex = j;
        allTodos.push(todo);
      }
    }

    let returnTodos = allTodos;
    if (onlyNotCompleted) {
      returnTodos = allTodos.filter((item: any) => {
        return !item.isCompleted;
      });
    }

    return returnTodos;
  }

  keepAllRemainingTodos() {
    this.subtasksService.keepAllRemaining$.next(true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isFirst(i: number): boolean {
    return i == 0;
  }

  isLast(i: number): boolean {
    return i == this.subtasks.length - 1;
  }

  goUp(i: number, title: string) {
    const newIndex = i - 1;
    const subTaskMoveData = {
      title: title,
      newIndex,
      oldIndex: i,
    };
    this.moveSubtask(subTaskMoveData);
  }

  goDown(i: number, title) {
    const newIndex = i + 1;
    const subTaskMoveData = {
      title: title,
      newIndex,
      oldIndex: i,
    };
    this.moveSubtask(subTaskMoveData);
  }

  saveNewTitle(title: string, index: number) {
    this.saveSubtaskTitle({ title, index }, true);
    this.subtaskEditMode = false;
    this.editId = "";
  }

  editTodoTitle(title: string, oldTitle: string) {
    let count = 0;
    this.subtasks.forEach((sub) => {
      sub.todos.forEach((todo) => {
        if (todo.comments == oldTitle) {
          count++;
          this.saveTodoComments(
            {
              comments: title,
              subtaskIndex: sub.titleIndex,
              todoIndex: todo.index,
            },
            true,
            true,
          );
        }
      });
    });
    setTimeout(() => {
      this.endEdit();
      this.reloadAdvanced();
    }, 100 * count);
  }

  endEdit() {
    this.todoEditMode = false;
    this.editId = "";
  }

  setEditMode(i: number, subtask = true) {
    if (subtask) {
      this.subtaskEditMode = true;
      this.editId = "subtask_" + i;
    } else {
      this.todoEditMode = true;
      this.editId = "todo_" + i;
    }
  }

  deleteTodos(title: string) {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: "Are you sure?",
          message: `You want to delete ${title} todos from all subtasks?`,
          okLabel: "Yes, delete all",
          cancelLabel: "No",
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          let count = 0;
          if (this.subtasks.length === 0) {
            this.subtasksTitles = [];
          }
          this.subtasks.forEach((sub) => {
            sub.todos.forEach((todo) => {
              if (todo.comments == title) {
                count++;
              }
            });
          });

          this.subtasks.forEach((sub) => {
            sub.todos.forEach((todo) => {
              if (todo.comments == title) {
                this.finishLoading = false;
                this.subscription.add(
                  this.tasksService
                    .deleteSubtasks(
                      this.taskId,
                      todo.id,
                      this.addingTaskInNewTicketMode,
                    )
                    .pipe(
                      finalize(() => {
                        this.finishLoading = true;
                      }),
                    )
                    .subscribe(() => {
                      const todoIndex = this.subtasks[
                        sub.titleIndex
                      ].todos.findIndex((todoI) => todoI.index == todo.index);
                      this.subtasks[sub.titleIndex].todos.splice(todoIndex, 1);
                      count--;
                      if (count == 0) {
                        this.reloadAdvanced();
                      }
                    }),
                );
              }
            });
          });
        }
      });
  }

  resetCollapse() {
    this.rotaTableService.setSwitcherReset();
  }

  makeRecurringTasks(index, recurring) {
    const subtask = this.subtasks[index];
    subtask.todos.forEach((e, i) => {
      this.setTodoInfinity({
        subtaskIndex: index,
        todoIndex: i,
        isRecurring: recurring,
      });
    });
  }
  makeRecurringTasksGrid(title) {
    this.subtasks.forEach((e, i) => {
      const todoIndex = e.todos.findIndex((e) => e.comments === title);
      if (todoIndex !== -1) {
        this.setTodoInfinity({
          subtaskIndex: i,
          todoIndex,
          isRecurring: !e.todos[todoIndex].isRecurring,
        });
      }
    });
  }

  isRecurringTitle(t) {
    let subtask = null;
    this.subtasks.forEach((e) => {
      e.todos.forEach((e) => {
        if (e.comments === t) {
          subtask = e;
        }
      });
    });
    if (subtask !== null) {
      return subtask.isRecurring;
    }
    return false;
  }

  isRecurring(s) {
    let result = true;
    this.subtasks[s].todos.forEach((e) => {
      if (!e.isRecurring) {
        result = false;
      }
    });
    return result;
  }

  doubleClick() {
    this.editEnabled = !this.editEnabled;
    if (!this.editEnabled) {
      this.toastrService.warning(
        "Drag & drop disabled. Double click on any task activate Drag & drop",
        "Warning",
        {
          duration: 6000,
          destroyByClick: true,
        },
      );
    } else {
      this.toastrService.warning(
        "Drag & drop enabled. Double click on any task deactivate Drag & drop",
        "Warning",
        {
          duration: 6000,
          destroyByClick: true,
        },
      );
    }
  }

  getComments(todo) {
    if (!todo?.updateHistory) {
      return "";
    }

    let result =
      "---------------------------------------<br>HISTORY<br>---------------------------------------<br>";
    todo?.updateHistory.forEach((e) => {
      result += `${e.date} ${e.author}<br>${e.comment}<br>--------------------<br>`;
    });
    return result;
  }

  public getSubtaskName(name: string) {
    return name.split("~~~")[0];
  }

  public getSubtaskLatestUpdate(latestUpdate: string | null) {
    if (!latestUpdate) {
      return "";
    }

    return latestUpdate.replace(/[\s\r\n\t]+/g, '').substring(0, 50);
  }
}
