export const CoursesApiUrls = {
  courses: "/care-homes/$chId/trainings",
  coursesXls: "/care-homes/$chId/trainings-xls",
  coursesReport: "/care-homes/$chId/trainings/courses-generate-report",
  coursesReportPdf: "/care-homes/$chId/trainings/courses-generate-pdf",
  coursesOverview: "/care-homes/$chId/trainings/overview",
  coursesSchedulesOverview: "/care-homes/$chId/schedules",
  coursesCompleted: "/care-homes/$chId/trainingsCompleted",
  coursesSummary: "/care-homes/$chId/trainings/report1",
  coursesSummary2: "/care-homes/$chId/trainings/report2",
  coursesPdfReport: "/care-homes/$chId/pdf-report-courses-todo",
  coursesXlsReport: "/care-homes/$chId/xls-traing-matrix",
};
