<div class="form-view">
  <main>
    <ng-template [ngIf]="employeeContracts.length > 0">
      <form>
        <div class="form-control-group table-container">
          <p class="mt-2 mb-2">Assign an existing contract to the uploaded document or click 'add contract' to complete the upload process</p>
          <table>
            <thead>
              <tr>
                <th *ngFor="let column of columns">{{ column }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let contract of employeeContracts; let index = index">
                <td>{{ index+1 }}</td>
                <td>{{ contract.contractNo }}</td>
                <td>{{ contract.contractRole }}</td>
                <td>{{ contract.contractType }}</td>
                <td>{{ contract.contractStart }}</td>
                <td>{{ contract.contractEnd }}</td>
                <td>{{ contract.contractHoursPerWeek }}</td>
                <td>{{ contract.contracStatus }}</td>
                <td>
                  <button class="btn btn-success" (click)="chooseContract(contract.contractId, contract.contractRole, contract.contractType)">
                    Choose
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </ng-template>
    <div class="display-flex display-flex--between mr-4">
      <div></div>
<!--      <button *ngIf="permissions.NEW_CONTRACTS" class="btn btn-success mb-2" (click)="addNewContract()">-->
<!--        Add new contract-->
<!--      </button>-->
      <button *ngIf="!documentUid && !permissions.NEW_CONTRACTS" class="btn btn-success mb-2" (click)="addNewTaskForContract()">
        Add contract task
      </button>
    </div>
    <p class="warning-info ml-3 mb-3">You must pick an option on the right. If you do not, the document will NOT upload.</p>
  </main>
  <footer>
    <button class="btn btn-danger" (click)="closeWindow()">
      <i class="nb-close" aria-hidden="true"></i> Close
    </button>
  </footer>
</div>
