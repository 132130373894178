<div *ngIf="isDataLoaded" id="table-container">
  <table #table mat-table [dataSource]="data" class="theTable" id="theTable">
    <ng-container
      *ngFor="let column of columns; trackBy: trackByFn"
      [matColumnDef]="column.fieldName"
    >
      <ng-container>
        <th
          mat-header-cell
          *matHeaderCellDef
          class="header"
          [ngClass]="{
            title: column.fieldName === 'title',
            status: column.fieldName === 'status',
            hide: column.fieldName === 'isChecked' && !isCopyVisible
          }"
        >
          <span class="bordered">{{ column.title }}</span>
        </th>
      </ng-container>
      <ng-container *ngIf="column.fieldName === 'isChecked'">
        <td
          mat-cell
          *matCellDef="let element"
          class="checkbox-width"
          [ngStyle]="{
            'background-color': element.isEmptySpace
              ? element['groupingColor']
              : 'transparent'
          }"
          [ngClass]="{
            hide: column.fieldName === 'isChecked' && !isCopyVisible
          }"
        >
          <nb-checkbox
            *ngIf="!(element.isEmptySpace || element.isSubTask)"
            (change)="onTaskSelectionChange($event, element)"
          >
          </nb-checkbox>
        </td>
      </ng-container>

      <ng-container *ngIf="column.fieldName === 'index'">
        <td
          mat-cell
          *matCellDef="let element"
          [ngStyle]="{
            'background-color': element['groupingColor']
          }"
          [attr.colspan]="element.isEmptySpace ? columns.length : 0"
          class="small-no-column"
        >
          <span *ngIf="!element.isEmptySpace">
            <span *ngIf="!element.isEmptySpace">{{
              element[column.fieldName]
            }}</span>
            <span
              *ngIf="element.totalSubtasks > 0"
              (click)="toogleSubtaskList(element.taskId)"
              style="padding-left: 0"
            >
              <nb-icon
                *ngIf="showSubtasksForTaskId != element.taskId"
                icon="plus-square-outline"
                class="subtask-expandable-icon"
              ></nb-icon>
              <nb-icon
                *ngIf="showSubtasksForTaskId == element.taskId"
                icon="minus-square-outline"
                class="subtask-expandable-icon"
              ></nb-icon>
            </span>
            <span
              *ngIf="
                element.subTaskColaboration &&
                element.subTaskColaborationTaskForMe
              "
              ><img
                src="assets/images/group_my.png"
                style="width: 20px"
                nbPopoverTrigger="hover"
                [nbPopover]="colaborationInfoPopover"
            /></span>
            <span
              *ngIf="
                element.subTaskColaboration &&
                !element.subTaskColaborationTaskForMe
              "
              ><img
                src="assets/images/group.png"
                style="width: 20px"
                nbPopoverTrigger="hover"
                [nbPopover]="colaborationInfoPopover"
            /></span>

            <ng-template #colaborationInfoPopover>
              <div class="task-tooltip" style="padding: 5px">
                <p>Assigned to:</p>
                <div [innerHTML]="element.assignedTo"></div>
              </div>
            </ng-template>
          </span>
          <div *ngIf="element.isEmptySpace" class="display-flex">
            <span>{{ element.taskGoupingName }}</span>
            <!-- <div class="display-flex display-flex--evenly" style="flex: 1;">
              <span [nbPopover]="ragg1GroupAvgHtml" nbPopoverTrigger="hover"
                [ngStyle]="{'background-color': element['ragg1GroupScoreColor'], 'visibility': !taskBoardDetails.ragg1DictionaryName ? 'hidden' : 'visible'}">{{taskBoardDetails.ragg1DictionaryName}}:
                {{element.ragg1GroupScore}}</span>
              <span [nbPopover]="ragg2GroupAvgHtml" nbPopoverTrigger="hover"
                [ngStyle]="{'background-color': element['ragg2GroupScoreColor'], 'visibility': !taskBoardDetails.ragg2DictionaryName ? 'hidden' : 'visible'}">{{taskBoardDetails.ragg2DictionaryName}}:
                {{element.ragg2GroupScore}}</span>
              <ng-template #ragg1GroupAvgHtml>
                <div class="tool-tip" [innerHTML]="element.ragg1GroupAvg"></div>
              </ng-template>
              <ng-template #ragg2GroupAvgHtml>
                <div class="tool-tip" [innerHTML]="element.ragg2GroupAvg"></div>
              </ng-template>
            </div> -->
          </div>
        </td>
      </ng-container>

      <ng-container *ngIf="column.fieldName === 'title'">
        <td
          mat-cell
          [ngStyle]="{ display: element.isEmptySpace ? 'none' : 'table-cell' }"
          *matCellDef="let element"
          (click)="edit(element)"
          [ngClass]="{
            high: element['priority'] == 'high',
            low: element['priority'] == 'low'
          }"
        >
          <span
            class="title"
            [ngClass]="{
              completed:
                element.status === 'updated' && element.isTaskCompleted,
              notCompleted:
                element.status === 'updated' &&
                (element.isTaskNotCompleted ||
                  (element.isSubTask && !element.isTaskCompleted))
            }"
          >
            <ng-container *ngIf="element['isSubTask']">
              <nb-icon
                *ngIf="element.isTaskCompleted && !element.isTaskNotCompleted"
                icon="checkmark-square-2-outline"
                class="taskboard-icon green"
              ></nb-icon>
              <nb-icon
                *ngIf="!element.isTaskCompleted && element.isTaskNotCompleted"
                icon="close-square-outline"
                class="taskboard-icon red"
              ></nb-icon>
              <nb-icon
                *ngIf="!element.isTaskCompleted && !element.isTaskNotCompleted"
                icon="square-outline"
                class="taskboard-icon red"
              ></nb-icon>
            </ng-container>
            <img
              *ngIf="element['isAfgBell']"
              src="./assets/images/danger.png"
              alt=""
            />
            <nb-icon
              *ngIf="element['isFavorite']"
              icon="star-outline"
              class="taskboard-icon"
            ></nb-icon>
            <nb-icon
              *ngIf="element['isEmailList']"
              icon="email-outline"
              class="taskboard-icon"
            ></nb-icon>
            <nb-icon
              *ngIf="element['numberOfAttachments']"
              icon="attach-2-outline"
              class="taskboard-icon"
            ></nb-icon>
            {{ element[column.fieldName] }}
          </span>
        </td>
      </ng-container>
      <ng-container *ngIf="column.fieldName === 'status'">
        <td
          mat-cell
          [ngStyle]="{ display: element.isEmptySpace ? 'none' : 'table-cell' }"
          *matCellDef="let element"
          (click)="edit(element)"
          [ngClass]="{
            grey: element['status'] == 'Paused',
            green: element['status'] == 'Done' || element['status'] == 'D&V',
            'hard-grey':
              element['status'] == 'D&R' || element['status'] == 'Closed',
            isGrey: element['isGrey'],
            isNormal: !element['isGrey'],
            isGreen: element['status'] === 'completed'
          }"
        >
          <ng-container *ngIf="element['isSubTask']">
            <span class="status-time">{{ element["updateTime"] }}</span>
          </ng-container>
          <span class="status">{{ element[column.fieldName] }}</span>
          <span
            class="recommended-status"
            [innerHTML]="element['recommendedStatus']"
          ></span>
        </td>
      </ng-container>
      <ng-container *ngIf="column.fieldName === 'avatar'">
        <td
          mat-cell
          *matCellDef="let element"
          (click)="
            showEmployee360(
              $event,
              element.employeeId,
              element.employeeFullName
            )
          "
          [ngStyle]="{ display: element.isEmptySpace ? 'none' : 'table-cell' }"
        >
          <span class="center-avatar">
            <img
              [src]="element[column.avatarId] | avatar | async"
              class="avatar"
              alt=""
            />
          </span>
        </td>
      </ng-container>
      <ng-container *ngIf="column.fieldType === 'dictionary-ragg'">
        <ng-container *ngIf="!column.canChange">
          <td
            mat-cell
            *matCellDef="let element"
            (click)="edit(element)"
            class="short-column-width"
            [ngStyle]="{
              'background-color': getRaggColorFromId(
                element[column.fieldName],
                column['dictionary']
              ),
              display: element.isEmptySpace ? 'none' : 'table-cell'
            }"
          >
            <span *ngIf="!element['isSubTask']">{{
              getRaggNameFromId(element[column.fieldName], column["dictionary"])
            }}</span>
          </td>
        </ng-container>
        <ng-container *ngIf="column.canChange">
          <td
            mat-cell
            *matCellDef="let element"
            class="short-column-width"
            [ngStyle]="{
              'background-color':
                element[column.fieldName]
                | raggColumnBackground: column['dictionary'],
              display: element.isEmptySpace ? 'none' : 'table-cell'
            }"
          >
            <span *ngIf="!element['isSubTask']" class="status-changer ragg">
              <div class="form-control-group">
                <select
                  [(ngModel)]="element[column.fieldName]"
                  (ngModelChange)="
                    onChangeRagg(
                      element[column.fieldName],
                      element['taskId'],
                      column.fieldName
                    )
                  "
                >
                  <option
                    *ngFor="let rag of column['dictionary']"
                    [ngValue]="rag.id"
                    [ngStyle]="{ 'background-color': rag.colorHash }"
                  >
                    {{ rag.wordShortName }}
                  </option>
                </select>
              </div>
            </span>
          </td>
        </ng-container>
      </ng-container>
      <ng-container
        *ngIf="
          column.fieldName !== 'status' &&
          column.fieldName !== 'title' &&
          column.fieldType !== 'ragg'
        "
      >
        <ng-container *ngIf="column.fieldType === 'textarea-view'">
          <td
            class="large-column-width"
            mat-cell
            *matCellDef="let element"
            (click)="
              column.click ? column.click($event, element) : edit(element)
            "
            [ngStyle]="{
              display: element.isEmptySpace ? 'none' : 'table-cell'
            }"
          >
            <ngx-progress-bar
              *ngIf="element.totalSubtasks > 0 && column.addSubtasksProgress"
              [progressPercent]="element.subTasksFinishedString"
            ></ngx-progress-bar>
            <span
              *ngIf="
                element.totalSubtasks > 0 && column.addSubtasksChangeProgress
              "
              class="right"
              >{{ element.subTasksFinishedChangePercent }}</span
            >
            <br
              *ngIf="
                !column.addSubtasksProgress && !column.addSubtasksChangeProgress
              "
            />
            <div
              *ngIf="!column.formatHtml"
              class="textarea-view"
              #content
              [ngClass]="{
                completed:
                  element.status === 'updated' &&
                  element.isTaskCompleted &&
                  column.fieldName == 'description',
                notCompleted:
                  element.status === 'updated' &&
                  (element.isTaskNotCompleted ||
                    (element.isSubTask && !element.isTaskCompleted)) &&
                  element.title &&
                  column.fieldName == 'description'
              }"
            >
              <pre [innerHTML]="element[column.fieldName]"></pre>
            </div>
            <div
              *ngIf="column.formatHtml"
              class="textarea-view format-html"
              #content
              [ngClass]="{
                completed:
                  element.status === 'updated' &&
                  element.isTaskCompleted &&
                  column.fieldName == 'description',
                notCompleted:
                  element.status === 'updated' &&
                  (element.isTaskNotCompleted ||
                    (element.isSubTask && !element.isTaskCompleted)) &&
                  element.title &&
                  column.fieldName == 'description'
              }"
              [innerHTML]="element[column.fieldName]"
              readonly
            ></div>
          </td>
        </ng-container>
        <ng-container *ngIf="column.fieldType !== 'textarea-view'">
          <td
            mat-cell
            *matCellDef="let element"
            (click)="
              column.click ? column.click($event, element) : edit(element)
            "
            [ngStyle]="{
              display: element.isEmptySpace ? 'none' : 'table-cell'
            }"
          >
            <span
              #content
              [class]="column.class"
              [ngClass]="{
                completed:
                  element.status === 'updated' &&
                  element.isTaskCompleted &&
                  column.fieldName == 'description',
                notCompleted:
                  element.status === 'updated' &&
                  (element.isTaskNotCompleted ||
                    (element.isSubTask && !element.isTaskCompleted)) &&
                  element.title &&
                  column.fieldName == 'description'
              }"
            >
              {{ element[column.fieldName] }}
            </span>
            <p
              *ngIf="isOverflow(content)"
              class="blue"
              (click)="column.click ? column.click($event, element) : ''"
            >
              ... see more {{ countLines(content) }}
            </p>
          </td>
        </ng-container>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="thinner"
      [ngClass]="{
        green: !row.isSubTask && row.commented,
        grey: row.isGrey || row.status == 'Paused' || row.status == 'D&V',
        'hard-grey': row.status == 'D&R' || row.status == 'Closed',
        'thicker-line': row.isSubTask && row.isDrawLineAfter,
        hidden: row.isSubTask,
        visible: showSubtasksForTaskId == row.subTaskForTaskId,
        isGrey: row['isGrey'] && !row.isSubtask,
        isNormal: !row['isGrey'] && !row.isSubtask,
        isGreen: row['status'] === 'completed'
      }"
    ></tr>
  </table>
</div>
