import { Inject, Injectable } from "@angular/core";
import { NewMultipleTaskComponent } from "../../../../../pages/task-management/tasks/new-multiple-task/new-multiple-task.component";
import { NewTaskComponent } from "../../../../../pages/task-management/tasks/new-task/new-task.component";
import { TableService } from "../../../../../pages/shared/table/table.service";
import { PermissionsService } from "../../../../../@core/data/permissions.service";
import { DOCUMENT } from "@angular/common";
import { ScreenshotService } from "../../../../../shared/screenshot.service";
import { MatDialog } from "@angular/material/dialog";
import { NewMessageDialogComponent } from "../../../../../pages/inbox/new-message-dialog/new-message-dialog.component";

@Injectable({
  providedIn: "root",
})
export class SideMenuOptionsService {
  private permissions: any = {};

  constructor(
    private tableService: TableService,
    private permissionsService: PermissionsService,
    private screenShotService: ScreenshotService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.permissions = this.permissionsService.getPermissions();
  }
  public addNewTask() {
    if (this.permissions.ADD_MULTIPLE_TASKS) {
      this.tableService.openWindow(
        NewMultipleTaskComponent,
        "Add new multiple Task",
        {
          canChangeTaskboard: true,
        },
      );
    } else {
      this.tableService.openWindow(NewTaskComponent, "Add new Task", {
        canChangeTaskboard: true,
      });
    }
  }

  public addNewInstance() {
    const link = this.document.location.href;
    window.open(link, "_blank");
  }

  public goToPortable() {
    let link;
    if (this.document.location.href.includes("beta")) {
      link = "https://echo-portable-beta.rosecaregroup.co.uk/#/";
    } else {
      link = "https://echo-portable.rosecaregroup.co.uk/#/";
    }
    window.open(link, "_blank");
  }

  public goToProduction() {
    window.open("https://echo.rosecaregroup.co.uk/#/", "_blank");
  }

  sendMessageToSupport() {
    this.screenShotService.captureScreen();
  }

  composeMessage() {
    this.dialog.open(NewMessageDialogComponent, {
      disableClose: true,
      data: {},
    });
  }
}
