<table #table mat-table matSort (matSortChange)="matSortChange.emit($event)"
  [dataSource]="dataSource">
  <ng-container matColumnDef="index">
    <th mat-header-cell *matHeaderCellDef class="header" mat-sort-header><span>No.</span></th>
    <td mat-cell *matCellDef="let element; let index = index" [ngStyle]="{'background': element ? element.indexColor : 'transparent'}"><span>{{index + 1}}</span></td>
  </ng-container>
  <ng-container *ngFor="let column of columns; trackBy: trackByFn" [matColumnDef]="column.fieldName">
    <!-- header -->
    <th mat-header-cell mat-sort-header *matHeaderCellDef
        class="header">
      <span>{{column.title}}</span>
    </th>
    <!-- header -->
    <!-- content -->
    <td
      mat-cell
      *matCellDef="let element"
      (click)="onRowClick ? onRowClick(element) : ''"
      [ngStyle]="{
        cursor: onRowClick ? 'pointer' : element[column.pointer] ? 'pointer' : ''
      }"
    >
      <span *ngIf="!column.valuePrepareFunction" (click)="column.action ? column.action($event, element) : null">{{element ? element[column.fieldName] : ''}}</span>
      <span *ngIf="column.valuePrepareFunction" (click)="column.action ? column.action($event, element) : null" [innerHTML]="element ? column.valuePrepareFunction(element) : ''"></span>
    </td>
    <!-- content -->
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="thinner"></tr>
</table>
