import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { RotaTableService } from '../rota-table.service';
import { HourModalService } from './hour-modal.service';

@Component({
  selector: 'ngx-rota-table-hour-modal',
  templateUrl: './rota-table-hour-modal.component.html',
  styleUrls: ['./rota-table-hour-modal.component.scss'],
})
export class RotaTableHourModalComponent implements OnInit {
  hourValues: {
    newFrom: string;
    newTo: string;
    newTo24Format: string;
    newFrom24Format: string;
  };
  leftButtonFrom: string;
  leftButtonTo: string;
  leftButtonValue: string = 'new';
  leftButtonHrsValue: string;
  leftButtonIsRed: boolean = false;

  rightButtonFrom: string;
  rightButtonTo: string;
  rightButtonValue: string = 'old';
  rightButtonHrsValue: string;
  rightButtonIsRed: boolean = false;

  constructor(
    private rotaTableService: RotaTableService,
    private hourModalService: HourModalService
  ) {}
  closeWindow() {
    this.rotaTableService.closeWindow();
  }
  chooseValues(option: string) {
    if (option === 'new') {
      this.hourModalService.setChangedToValue(`${this.leftButtonTo}`);
      this.hourModalService.setChangeFromValue(`${this.leftButtonFrom}`);
    } else {
      this.hourModalService.setChangedToValue(this.rightButtonTo);
      this.hourModalService.setChangeFromValue(this.rightButtonFrom);
    }

    this.closeWindow();
  }

  calculateNumericHourValue(value: string): number {
    let newValue = value.slice(0, 2);
    return parseInt(newValue, 10);
  }

  private setButtonText(dateFrom: string, dateTo: string, button: string) {
    let dateFromHours = parseInt(dateFrom.slice(0, 2), 10);
    let dateFromMinutes = parseInt(dateFrom.slice(3, 5), 10);
    let dateToHours = parseInt(dateTo.slice(0, 2), 10);
    let dateToMinutes = parseInt(dateTo.slice(3, 5), 10);

    if (dateFromHours > dateToHours) {
      dateToHours += 24;
    }

    let dateFromDate = moment().set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0);
    dateFromDate.add('hours', dateFromHours).add('minutes', dateFromMinutes);
    let dateToDate = moment().set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0);
    dateToDate.add('hours', dateToHours).add('minutes', dateToMinutes);

    const diff = dateToDate.diff(dateFromDate, 'minutes');

    const hoursToDisplay = Math.floor(diff / 60);
    const minutesToDisplay = diff % 60;
    if (button === 'left') {
      if (hoursToDisplay >= 12) this.leftButtonIsRed = true;
      this.leftButtonHrsValue = `This is ${hoursToDisplay} hours` + (minutesToDisplay !== 0 ? ` and ${minutesToDisplay} minutes` : ``);
    } else if (button === 'right') {
      if (hoursToDisplay >= 12) this.rightButtonIsRed = true;
      this.rightButtonHrsValue = `This is ${hoursToDisplay} hours` + (minutesToDisplay !== 0 ? ` and ${minutesToDisplay} minutes` : ``);
    }
  }

  ngOnInit() {
    this.hourValues = this.hourModalService.getCurrentValues();
    console.log(this.hourValues);

    if(this.hourValues.newFrom < this.hourValues.newTo) {
      this.leftButtonFrom = this.hourValues.newFrom;
      this.leftButtonTo = this.hourValues.newTo;

      this.rightButtonFrom = this.hourValues.newFrom24Format;
      this.rightButtonTo = this.hourValues.newTo24Format;

    } else if(this.hourValues.newFrom > this.hourValues.newTo || this.hourValues.newFrom === this.hourValues.newTo) {
      this.leftButtonFrom = this.hourValues.newFrom;
      this.leftButtonTo = this.hourValues.newTo24Format;

      this.rightButtonFrom = this.hourValues.newFrom24Format;
      this.rightButtonTo = this.hourValues.newTo;

    }

    this.setButtonText(this.leftButtonFrom, this.leftButtonTo, 'left');
    this.setButtonText(this.rightButtonFrom, this.rightButtonTo, 'right');
  }
}
