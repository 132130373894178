import { PagesSharedModule } from "../shared/pages-shared.module";
import { ThemeModule } from "../../@theme/theme.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { InboxRoutingModule } from "./inbox-routing.module";
import { InboxLeftComponent } from "./inbox-left/inbox-left.component";
import { InboxComponent } from "./inbox.component";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { InboxListComponent } from "./inbox-list/inbox-list.component";
import { ReadMessageComponent } from "./read-message/read-message.component";
import { NewMessageDialogComponent } from "./new-message-dialog/new-message-dialog.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatDialogModule } from "@angular/material/dialog";
import { MessageReportComponent } from "./_components/message-report/message-report.component";
import { NoteboardComponent } from "./noteboard/noteboard.component";
import { MdePopoverModule } from "@material-extended/mde";
import { MessagePreviewComponent } from "./message-preview/message-preview.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgxLinkifyjsModule } from "ngx-linkifyjs";

@NgModule({
  declarations: [
    InboxComponent,
    InboxLeftComponent,
    InboxListComponent,
    ReadMessageComponent,
    NewMessageDialogComponent,
    MessageReportComponent,
    NoteboardComponent,
    MessagePreviewComponent,
  ],
  exports: [InboxListComponent],
  imports: [
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatMenuModule,
    CommonModule,
    InboxRoutingModule,
    ThemeModule,
    FormsModule,
    MatTooltipModule,
    RouterModule,
    ReactiveFormsModule,
    MdePopoverModule,
    PagesSharedModule,
    MatExpansionModule,
    MatCheckboxModule,
    NgxLinkifyjsModule,
  ],
})
export class InboxModule {}
