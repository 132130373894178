export const errorMessages = [
  {
    field: 'login',
    errors: [
      {
        error: 'required',
        message: 'Login is required'
      },
      {
        error: 'minlength',
        message: 'Login must have 5 characters at least'
      },
      {
        error: 'maxlength',
        message: 'Login cannot be longer than 25 characters'
      }
    ]
  },
  {
    field: 'email',
    errors: [
      {
        error: 'required',
        message: 'Email is required'
      },
      {
        error: 'email',
        message: 'This is not a valid email address'
      },
      {
        error: 'maxlength',
        message: 'Email cannot be longer than 50 characters'
      }
    ]
  },
  {
    field: 'phoneNumber',
    errors: [
      {
        error: 'required',
        message: 'Phone number is required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Phone number cannot be longer than 25 character'
      },
      {
        error: 'numbers',
        message: 'Phone number must be numbers only, no spaces and no special signs'
      }
    ]
  },
  {
    field: 'accountType',
    errors: [
      {
        error: 'required',
        message: 'Account type is required'
      },
    ]
  },
  {
    field: 'firstName',
    errors: [
      {
        error: 'required',
        message: 'First name is required'
      },
      {
        error: 'minlength',
        message: 'First name must have 1 characters at least'
      },
      {
        error: 'maxlength',
        message: 'First cannot be longer than 50 characters'
      },
      {
        error: 'alpha',
        message: 'First name must be in letters only'
      }
    ]
  },
  {
    field: 'surname',
    errors: [
      {
        error: 'required',
        message: 'Last Name is required'
      },
      {
        error: 'minlength',
        message: 'Last Name must have 1 characters at least'
      },
      {
        error: 'maxlength',
        message: 'Last Name cannot be longer than 50 characters'
      },
      {
        error: 'alpha',
        message: 'First name must be in letters only'
      }
    ]
  },
  {
    field: 'password',
    errors: [
      {
        error: 'required',
        message: 'Password is required'
      },
      {
        error: 'minlength',
        message: 'Password must have 8 characters at least'
      },
      {
        error: 'password',
        message: 'Password must have at least: one upper letter, one lower letter, one number, one special char'
      },
      {
        error: 'equalToFieldValue',
        message: 'Passwords don\'t match'
      },
    ]
  },
  {
    field: 'changePassword',
    errors: [
      {
        error: 'required',
        message: 'Password is required'
      },
      {
        error: 'minlength',
        message: 'Password must have 8 characters at least'
      },
      {
        error: 'password',
        message: 'Password must have at least: one upper letter, one lower letter, one number, one special char'
      },
      {
        error: 'equalToFieldValue',
        message: 'Passwords don\'t match'
      },
    ]
  },
  {
    field: 'confirmPassword',
    errors: [
      {
        error: 'required',
        message: 'Password confirmaton is required'
      },
      {
        error: 'minlength',
        message: 'Password must have 8 characters at least'
      },
      {
        error: 'password',
        message: 'Password must have at least: one upper letter, one lower letter, one number, one special char'
      },
      {
        error: 'equalToFieldValue',
        message: 'Passwords don\'t match'
      },
    ]
  },
  {
    field: 'defaultCareHome',
    errors: [
      {
        error: 'required',
        message: 'Default Care Home is required'
      }
    ]
  },
  {
    field: 'status',
    errors: [
      {
        error: 'required',
        message: 'Status is required'
      }
    ]
  }
];
