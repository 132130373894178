import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

// Third party
import { NbPopoverDirective } from '@nebular/theme';

@Component({
  selector: 'ngx-ui-table-gps-cell',
  templateUrl: './ui-table-gps-cell.component.html',
  styleUrls: ['./ui-table-gps-cell.component.scss'],
})
export class UiTableGpsCellComponent {
  @Input() gpsData: any;
  @Input() employeeFullName: string;
  @Input() employeePositionName: string;

  @ViewChild(NbPopoverDirective, { static: false }) private _popover: NbPopoverDirective;
  public zoom = 12;

  closePopover() {
    this._popover.hide();
  }
}
