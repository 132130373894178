import { Injectable } from "@angular/core";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TaskBoardsStateService {
  public suggestedTaskTemplates = new BehaviorSubject<any[] | null>(null);

  updateGlobalList$ = new Subject<void>();
  constructor() {}
}
