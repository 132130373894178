import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, OnChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { unitOfTime } from 'moment';
import { IDateRange } from "../wages-period/models/date-range.interfaces";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

const DEBOUNCE_TIME = 700;

@Component({
  selector: 'ngx-date-filer',
  templateUrl: './date-filer.component.html',
  styleUrls: ['./date-filer.component.scss']
})
export class DateFilerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() monthMode: boolean = false;
  @Input() canSelectRange: boolean = false;
  @Input() startDate: any;
  @Input() hideWeekNames: boolean = false;
  @Input() withAutofocus = true;
  @Output() onChange: EventEmitter<IDateRange> = new EventEmitter<IDateRange>();
  public form: UntypedFormGroup;
  public startWeek: string = ' ';
  public endWeek: string = ' ';
  public dateUnit: unitOfTime.DurationConstructor = 'w';
  public dateLabel: string = 'Week';
  public startOfUnit: unitOfTime.StartOf = 'isoWeek';
  public initStart = true;

  private _sub: Subscription = new Subscription()
  private clicks = new Subject<IDateRange>();

  ngOnChanges() {
    this.startDate = {
      start: this.startDate.start,
      end: this.startDate.end
    };
    if (this.form) {
      this.form.patchValue({
        dateFrom: this.startDate.start,
        dateTo: this.startDate.end
      });
    }
  }

  ngOnInit() {
    this._sub.add(
      this.clicks.pipe(debounceTime(DEBOUNCE_TIME)).subscribe((e:IDateRange) => {
        this.onChange.next(e)
      })
    )

    this.form = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(),
      dateTo: new UntypedFormControl()
    });

    if (this.monthMode) {
      this.dateUnit = 'M';
      this.dateLabel = 'Month';
      this.startOfUnit = 'month';
    }

    this.form.get('dateFrom').valueChanges.subscribe(val => {
      this.changeWeeksNames(val, this.form.get('dateTo').value);
    });

    this.form.get('dateTo').valueChanges.subscribe(val => {
      this.changeWeeksNames(this.form.get('dateFrom').value, val);
    });

    this.updateDates(this.startDate.start, this.startDate.end);
    if (!this.canSelectRange) {
      this.form.get('dateFrom').disable();
      this.form.get('dateTo').disable();
    }
  }

  addWeekFrom() {
    const newDate = moment(this.form.get('dateFrom').value).add(1, this.dateUnit).toDate();
    this.updateDates(newDate, this.form.get('dateTo').value);
  }

  subtractWeekFrom() {
    const newDate = moment(this.form.get('dateFrom').value).subtract(1, this.dateUnit).toDate();
    this.updateDates(newDate, this.form.get('dateTo').value);
  }

  addWeekTo() {
    const newDate = moment(this.form.get('dateTo').value).add(1, this.dateUnit).toDate();
    this.updateDates(this.form.get('dateFrom').value, newDate);
  }

  subtractWeekTo() {
    const newDate = moment(this.form.get('dateTo').value).subtract(1, this.dateUnit).toDate();
    this.updateDates(this.form.get('dateFrom').value, newDate);
  }

  changeWeeksNames(start: any, end: any) {
    this.startWeek = this.dateLabel + ' ' + moment(start).startOf(this.startOfUnit).format(this.dateUnit);
    this.endWeek = this.dateLabel + ' ' + moment(end).startOf(this.startOfUnit).format(this.dateUnit);
  }

  dateFromChange(v: any) {
    let startDate = moment(v).add(5, 'hour').toDate();
    const endDate = this.form.get('dateTo').value;
    if (moment(startDate).isAfter(endDate)) {
      startDate = endDate;
    }
    this.updateDates(startDate, endDate);
  }

  dateToChange(v: any) {
    let endDate = moment(v).add(5, 'hour').toDate();
    let startDate = this.form.get('dateFrom').value;
    if (moment(startDate).toDate().getHours() == 0) {
      startDate = moment(startDate).add(5, 'hour').toDate();
    }
    if (moment(endDate).isBefore(startDate)) {
      endDate = startDate;
    }
    this.updateDates(startDate, endDate);
  }

  updateDates(startDate: any, endDate: any) {
    if (!moment(startDate).isAfter(endDate, 'day')) {
      this.initStart = false;
      this.form.patchValue({
        dateFrom: startDate,
        dateTo: endDate
      });

      const dateRange = {
        start: this.form.get('dateFrom').value,
        end: this.form.get('dateTo').value
      };

      this.clicks.next(dateRange);
    }
  }

  ngOnDestroy(): void {
    this.onChange.complete();
    this._sub.unsubscribe()
  }

}
