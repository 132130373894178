import { OnDestroy } from '@angular/core';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IconType } from '../../../icon-type.enum';
import { LoadingStatus } from '../../../loading-status.enum';
import { TableFilter } from '../../table-filter.enum';
import { FilterType, TableType } from '../../table-type.enum';
import { TableService } from '../../table.service';
import * as moment from 'moment';

@Component({
  selector: 'ngx-history-table-with-pagination',
  templateUrl: './history-table-with-pagination.component.html',
  styleUrls: ['./history-table-with-pagination.component.scss']
})
export class HistoryTableWithPaginationComponent implements OnInit, OnChanges, OnDestroy {
  public actualFilterValue: string = '';
  @Input() title: string = '';
  @Input() disabled: boolean = false
  @Input() data: any = [];
  @Input() filterBy: any = [];
  @Input() columns: any = {};
  @Input() datesRange: any = {};
  @Input() edit: (event: any) => any;
  @Input() download: any;
  @Input() load: (activeFilter?: any, datesRange?: any, actualSelectorValue?: any, searchFilter?: any, paginationData?: any, extendedFilters?: any, extendedSort?: any, extendedOrder?: any, categoryIdFilter?: any, statusIdFilter?: any) => any;
  @Input() careHomeFilter: boolean = false;
  @Input() customFilter: boolean = false;
  @Input() clearTop: boolean = false;
  @Input() descriptionTop: boolean = false;
  @Input() descriptionText: string = '';
  @Input() fullHeight: boolean = false;
  @Input() noBorder: boolean = false;
  @Input() activeFilter: any = TableFilter.ALL;
  @Input() thinner: boolean = false;
  @Input() comments: string = '';
  @Input() messageText: string = '';
  @Input() tableType: any = TableType.FULL;
  @Input() filterType: any = FilterType.SIMPLE;
  @Input() autocompleteSelectorValues: any;
  @Input() autocompleteSelectorPlaceholder: any = '';
  @Input() autocompleteSelectorLabel: any = '';
  @Input() actualSelectorValue: any;
  @Input() selectAllSwitch = false;
  @Input() actualFilterTypeValue: any;
  @Input() usePagination: boolean;
  @Input() totalRows = 0;
  @Input() paginationSizeOptions;
  @Input() isXTableScroll = false;
  @Input() sortFromBackend: boolean = false;
  @Input() filterEnterEnabled: boolean = false;
  @Input() isDataLoaded: boolean = true;
  @Input() sortConfigurations = [
    {
      name: 'Default',
      value: 'default'
    },
    {
      name: 'Sort by date added',
      value: 'date'
    }
  ];

  public sortOrder = [
    {
      name: 'Ascending',
      value: 'ASC'
    },
    {
      name: 'Descending',
      value: 'DESC'
    }
  ];

  public TableType: typeof TableType = TableType;
  public FilterType: typeof FilterType = FilterType;
  public IconType: typeof IconType = IconType;
  public onFilterChange$ = new BehaviorSubject<string>('');
  public searchChanged: Subject<string> = new Subject<string>();
  public tableFilter = TableFilter;
  public refreshSubscription: Subscription = null;
  public LoadingStatus: typeof LoadingStatus = LoadingStatus;
  public dataLoaded: LoadingStatus = LoadingStatus.NOT_LOADED;
  public careHomes: any = [];
  public filterConfigurations: any = [];
  public pickedFilterConfiguration = null;
  public pickedSortConfiguration = null;
  public pickedSortOrder = null;
  public dateSelected: any;
  public form: UntypedFormGroup;
  public monthList = [];
  public statusesFilterString = '';
  public statuses = [];
  public categoriesFilterString = '';
  public categories: any = [];
  public isShowMore = false;
  public isUsingExtendedFilters = false;
  public isDefaultSortingLoaded = false;
  public currentFilter = '';
  public extendedFilterConfig = null;
  public showMoreText = 'Show advanced';
  public defaultCareHomeId = 0;
  public paginationData = {
    pageIndex: 0,
    pageSize: 50,
    orderBy: '',
    orderDirection: ''
  };
  private subscription: Subscription = new Subscription();
  public isCareHomeFromFilter = false;

  constructor(protected tableService: TableService,
              protected router: Router) {
    this.refreshSubscription = this.tableService.refresh$.subscribe(() => {
      this.load(this.activeFilter, this.datesRange, this.actualSelectorValue, this.actualFilterValue, this.paginationData, this.filterBy, this.pickedSortConfiguration, this.pickedSortOrder, this.categoriesFilterString, this.statusesFilterString);
    });

    this.searchChanged
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.paginationData.pageIndex = 0;
        this.load(this.activeFilter, this.datesRange, this.actualSelectorValue, this.actualFilterValue, this.paginationData, this.filterBy, this.pickedSortConfiguration, this.pickedSortOrder, this.categoriesFilterString, this.statusesFilterString);
    });
  }

  ngOnInit() {
    if (!this.datesRange.start) {
      this.datesRange = {
        start: moment().startOf('month').add(5, 'hour').toDate(),
        end: moment().endOf('month').subtract(5, 'hour').toDate(),
      };
    }

    if (this.usePagination) {
      this.paginationData.pageSize = this.paginationSizeOptions[0];
    }

    this.load(this.activeFilter, this.datesRange, this.actualSelectorValue, this.actualFilterValue, this.paginationData, this.filterBy, this.pickedSortConfiguration, this.pickedSortOrder, this.categoriesFilterString, this.statusesFilterString);

  }

  updatePagination(event, isSort?) {
    if (isSort) {
      this.paginationData.orderBy = event.active;
      this.paginationData.orderDirection = event.direction;
    } else {
      this.paginationData.pageIndex = event.pageIndex;
      this.paginationData.pageSize = event.pageSize;
    }
    this.load(this.activeFilter, this.datesRange, this.actualSelectorValue, this.actualFilterValue, this.paginationData, this.filterBy, this.pickedSortConfiguration, this.pickedSortOrder, this.categoriesFilterString, this.statusesFilterString);
  }

  ngOnChanges() {
    if (!this.customFilter) {
      (async () => {
        await delay(1);
        this.onFilterChange$.next('aaa');
        this.applyFilter(this.actualFilterValue);
      })();
    }
    this.dataLoaded = LoadingStatus.LOADED;
  }

  toogleTableFilter(value: any) {
    this.activeFilter = value;
    this.actualFilterValue = value;
    this.tableService.reloadTable();
  }

  applyFilter(filterValue: string, checkIfEmpty = false) {
    this.actualFilterValue = filterValue;
    if (checkIfEmpty && this.filterEnterEnabled) {
      if (filterValue.trim().length === 0) {
        if (this.customFilter) {
          this.searchChanged.next(this.actualFilterValue);
        } else {
          this.onFilterChange$.next(this.actualFilterValue);
        }
      }
    } else {
      if (this.customFilter) {
        this.searchChanged.next(this.actualFilterValue);
      } else {
        this.onFilterChange$.next(this.actualFilterValue);
      }
    }
  }

  updateExtendedFilter(filterValue: string, fieldName: string) {
    this.actualFilterValue = '';

    const filterFromArr = this.filterBy.find((x => x.fieldName === fieldName));
    if (filterFromArr) {
      filterFromArr.search = filterValue;
    }

    this.isUsingExtendedFilters = !this.checkFiltersEmpty();
  }

  applyExtendedFilter() {
    this.searchChanged.next('aaa');
  }

  reload() {
    if (this.dataLoaded !== LoadingStatus.IN_PROGRESS) {
      this.dataLoaded = LoadingStatus.IN_PROGRESS;
      this.tableService.reloadTable();
    }
  }

  onChangeOrder(configuration: any) {
    this.searchChanged.next(configuration);
  }

  private checkFiltersEmpty() {
    for (const filter of this.filterBy) {
      if (filter.search && filter.search.length > 0) {
        return false;
      }
    }

    return true;
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.data = null;
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }
}

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
