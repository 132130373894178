<div mat-dialog-title>
    <span>{{title}}</span>
    <span class="right-menu">
        <button class="btn-icon" (click)="closeWindow()">
            <nb-icon icon="close"></nb-icon>
        </button>
    </span>
</div>
<div class="pin-info" *ngIf="message?.isPined">
    This message will be pinned for all to see. Make sure you aren't sending anything personal
</div>
<div class="message-preview-container">
    <div>
        <div class="message-wrapp">
            <h2 style="line-height: initial;"><span>Subject:</span> {{message.subject}}</h2>
            <div class="message-header mt-2 mb-1">
                <div class="sender-wrapper flex mb-3">
                    <ngx-image-viewer
                        [imageData]="avatarSender">
                    </ngx-image-viewer>
                    <div  class="sender-data ml-2">
                        <span *ngIf="!sendMessage">{{message.fromName}} ({{message.fromEmail}})</span>
                        <span *ngIf="sendMessage">{{message.toName}} ({{message.toEmail}})</span>
                        <div class="job-title">Employee</div>
                    </div>
                </div>
                <div class="message-menu flex">
                    <span>sent at: {{message.careatedAt}}</span>
                    <button class="btn-icon"
                        (click)="createReplyForward('reply')"
                        matTooltip="Reply"
                        matTooltipPosition='above'>
                        <nb-icon icon="undo"></nb-icon>
                    </button>
                    <span (click)="downloadPdf()" class="pdf-button"><nb-icon icon="download-outline"></nb-icon> PDF</span>
                    <div *ngIf="permissions.NOTICEBOARD_MANAGEMENT" class="ml-1">
                        <div *ngIf="!message.isPined" class="pin" (click)="pinMessage(true)">
                            <img src="/assets/images/pin.svg" /><span>pin to public noticeboard</span>
                        </div>
                        <ng-container *ngIf="message.isPined">
                            <div *ngIf="!showUnpin" class="pin" (click)="pinMessage(false)" (mouseenter)="showUnpin = true"  (mouseleave)="showUnpin = false">
                                <img src="/assets/images/pin_green.svg" /><span>message pinned</span>
                            </div>
                            <div *ngIf="showUnpin" class="pin" (click)="pinMessage(false)" (mouseenter)="showUnpin = true"  (mouseleave)="showUnpin = false">
                                <img src="/assets/images/pin_red.svg" /><span style="margin-left: 0.2rem;">unpin message</span>
                            </div>
                        </ng-container>
                    </div>
                    <button [matMenuTriggerFor]="menu"
                            #menuTrigger="matMenuTrigger" class="btn-icon">
                        <nb-icon icon="more-vertical"></nb-icon>
                    </button>
                </div>
            </div>
            <div class="message-text mt-1" [innerHTML]="message.message" readonly></div>
            <div *ngIf="message.attachmentsList && message.attachmentsList.length > 0" class="attachments">
                Attachments
                <div class="files flex mt-3">
                    <div (click)="downloadDocument(file.uid)" class="file-wrapper flex mr-5" *ngFor="let file of message.attachmentsList">
                        <div class="file">
                            <ngx-image-viewer
                                *ngIf='file.type=="image/jpeg" || file.type =="image/png"'
                                [imageData]="file.url">
                            </ngx-image-viewer>
                            <img *ngIf='file.type=="application/pdf"' src="../../../../assets/images/pdf.png" alt="">
                            <img *ngIf='file.type!="image/jpeg"
                                    && file.type !="image/png"
                                    && file.type !="application/pdf"' src="../../../../assets/images/document.png" alt="">
                            <span>{{file.fileName}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <mat-menu #menu="matMenu" class="more-list" xPosition="before">
        <ul>
            <li (click)="createReplyForward('forward')">
                <nb-icon icon="arrow-right"></nb-icon>
                <span>Forward</span>
            </li>
        </ul>
    </mat-menu>
</div>
