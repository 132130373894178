<div class="form-view">
  <main>
    <angular2-smart-table [settings]="settings" [source]="data"></angular2-smart-table>
    <div class="mt-1" *ngIf="!form.get('manualReview').value">
      <p>To schedule training for the above courses, type a date into 'Schedule date'. To set a date training was completed, type a date into 'Course date':</p>
    </div>
    <form [formGroup]="form">
      <div class="grid-wrapper grid-wrapper-10">
        <ng-container *ngIf="!form.get('manualReview').value">
          <div class="form-control-group box start-1 end-13" *ngIf="dateScheduled">
            <label class="label" for="input-scheduleDate">Schedule date:</label>
            <input nbInput
                   fullWidth
                   formControlName="scheduleDate"
                   name="scheduleDate"
                   id="input-scheduleDate"
                   placeholder="DD/MM/YYYY"
                   [matDatepicker]="scheduleDate">
            <mat-datepicker-toggle matSuffix [for]="scheduleDate"></mat-datepicker-toggle>
            <mat-datepicker #scheduleDate startView="month"></mat-datepicker>
            <div *ngIf="form.get('scheduleDate').value" id="clear-date" (click)="clearSchedule()">x</div>
          </div>
          <div class="form-control-group box start-1 end-13" *ngIf="!dateScheduled">
            <label class="label">Course date:</label>
            <input nbInput
                   fullWidth
                   formControlName="completedAt"
                   placeholder="DD/MM/YYYY"
                   [matDatepicker]="completedAt"
                   minlength="5"
                   maxlength="10">
            <mat-datepicker-toggle matSuffix [for]="completedAt"></mat-datepicker-toggle>
            <mat-datepicker #completedAt startView="month"></mat-datepicker>
          </div>
          <div class="to-scheduled-checkbox">
            <mat-checkbox (change)="toggleScheduled($event)">Course to be scheduled</mat-checkbox>
          </div>
          <p *ngIf="!isScheduleOrCourseDateEntered() && !form.get('manualReview').value" id="multiple-error"
             class="error-message box start-1 end-13">
            Schedule or course date is required
          </p>
        </ng-container>
      </div>
      <div id="manual-review" [style.margin-top]="form.get('manualReview').value ? '0' : '-2rem'">
        <nb-checkbox formControlName="manualReview">Manual review</nb-checkbox>
      </div>

      <ng-container *ngIf="form.get('manualReview').value">
        <p id="warning-info">Generally manual review is used when you do not expect the employee to complete the course. Try to avoid using this.</p>
        <div class="form-control-group">
          <label class="label">Expiration date of Manual Review:</label>
          <input nbInput
              fullWidth
              formControlName="expirationDate"
              placeholder="DD/MM/YYYY"
              [matDatepicker]="expirationDate"
              minlength="5"
              maxlength="10">
            <mat-datepicker-toggle matSuffix [for]="expirationDate"></mat-datepicker-toggle>
            <mat-datepicker #expirationDate startView="month"></mat-datepicker>
          <p class="error-message" *ngIf="!form.get('expirationDate').valid && form.get('expirationDate').touched && form.get('expirationDate').hasError('required')">
            Course expiration date is required
          </p>
        </div>
        <div id="course-comments" class="form-control-group">
          <label class="label" for="input-description"><span [style.color]="'red'" *ngIf="form.get('description').hasError('required')">* </span>Course comments:</label>
          <textarea nbInput
            name="description"
            formControlName="description"
            fullWidth
            placeholder="Comment - 1000 signs - if you add text here, the course will automatically go to Manual Review until the comment is removed"
            rows="2"
            class="form-control input-full-width">
          </textarea>
          <p class="error-message" *ngIf="form.get('manualReview').value && !form.get('description').valid && form.get('description').hasError('required')">
            Course comments is required
          </p>
        </div>
      </ng-container>
    </form>
  </main>
  <footer>
    <button class="btn btn-success"
            [disabled]="form.get('manualReview').value ? !isFormValid(form) : !isFormValid(form) || !isScheduleOrCourseDateEntered()"
            (click)="saveData()">
      <i class="nb-checkmark" aria-hidden="true"></i> Save
    </button>
    <button class="btn btn-danger ml-4"
            (click)="closeWindow(false)">
      <i class="nb-close" aria-hidden="true"></i> Close
    </button>
  </footer>
</div>
