import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { OverviewApiUrls } from './overview.enum';
import * as moment from 'moment';

@Injectable()
export class OverviewService {
  constructor(private httpClient: HttpClient) {}

  getData(startDate: any, endDate: any,  events: any = [], roles: any = [], search: string, fromRange: boolean = false, eventsOnly = false) {
    return this.httpClient.get(`${environment.apiUrl}${OverviewApiUrls.data}?startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}&isManager=false&eventsOnly=${eventsOnly}${events.length > 0 ? `&events=${events}` : ''}${roles.length > 0 ? `&roles=${roles}` : ''}${search && search.length > 0 ? `&search=${search}` : ''}&fromRange=${fromRange}`);
  }

  getManagerData(startDate: any, endDate: any,  events: any = [], roles: any = [], search: string, fromRange: boolean = false, eventsOnly = false) {
    return this.httpClient.get(`${environment.apiUrl}${OverviewApiUrls.data}?startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}&isManager=true&eventsOnly=${eventsOnly}${events.length > 0 ? `&events=${events}` : ''}${roles.length > 0 ? `&roles=${roles}` : ''}${search && search.length > 0 ? `&search=${search}` : ''}&fromRange=${fromRange}`);
  }

  getDataForOtherEvents(startDate: any, endDate: any, eventsOnly = false) {
    return this.httpClient.get(`${environment.apiUrl}${OverviewApiUrls.dataOtherEvents}?startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}&eventsOnly=${eventsOnly}&includeRequiredAcceptation=true`);
  }

  getDataForOtherEventsForEmployee(employeeId: number, startDate: any, endDate: any, eventsOnly = false) {
    return this.httpClient.get(`${environment.apiUrl}/employees/${employeeId}/overviewOtherEvents?startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}&eventsOnly=${eventsOnly}&includeRequiredAcceptation=true`);
  }

  getDataForOtherEventsForEmployeeWithoutEmployee(startDate: any, endDate: any, eventsOnly = false) {
    return this.httpClient.get(`${environment.apiUrl}/employees/overviewOtherEvents?startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}&eventsOnly=${eventsOnly}&includeRequiredAcceptation=true`);
  }

  getDataForOtherEventsForEmployeeWithSorceEmployee(employeeId : number, startDate: any, endDate: any, eventsOnly = false) {
    return this.httpClient.get(`${environment.apiUrl}/employees/overviewOtherEvents?employeeIdForCareHome=${employeeId}&startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}&eventsOnly=${eventsOnly}&includeRequiredAcceptation=true`);
  }


  getDataOfEmployeeSchedule(employeeId: number, startDate: any, endDate: any) {
    return this.httpClient.get(`${environment.apiUrl}/employees/${employeeId}/schedule?startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}`);
  }

  getDataOfEmployeeScheduleWithoutEmployee(startDate: any, endDate: any) {
    return this.httpClient.get(`${environment.apiUrl}/employees/schedule?startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}`);
  }

  downloadXlsReport(startDate: any, endDate: any,  events: any = [], roles: any = [], search: string, isManagerOverview: boolean, fromRange: boolean = false, eventsOnly = false) {
    return this.httpClient.get(`${environment.apiUrl}${OverviewApiUrls.dataXls}?startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}&isManager=${isManagerOverview}&eventsOnly=${eventsOnly}${events.length > 0 ? `&events=${events}` : ''}${roles.length > 0 ? `&roles=${roles}` : ''}${search && search.length > 0 ? `&search=${search}` : ''}&fromRange=${fromRange}`);
  }
}
