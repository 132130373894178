import {
  Component,
  Input,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList,
  HostBinding,
} from '@angular/core';
import { convertToBoolProperty, NbBooleanInput } from './tabset-helpers';
import { TabsetService } from './tabset.service';

@Component({
  selector: 'ngx-tab',
  template: `
    <ng-container *ngIf="init">
      <ng-content></ng-content>
    </ng-container>
  `,
})
export class TabComponent {
  @Input() tabTitle: string;
  @Input() tabCanClose: boolean = false;

  /**
   * Item is disabled and cannot be opened.
   * @type {boolean}
   */
  @Input('disabled')
  @HostBinding('class.disabled')
  get disabled(): boolean {
    return this.disabledValue;
  }
  set disabled(val: boolean) {
    this.disabledValue = convertToBoolProperty(val);
  }
  static ngAcceptInputType_disabled: NbBooleanInput;

  @HostBinding('class.content-active')
  activeValue: boolean = false;

  responsiveValue: boolean = false;
  disabledValue = false;

  /**
   * Specifies active tab
   * @returns {boolean}
   */
  @Input()
  get active() {
    return this.activeValue;
  }
  set active(val: boolean) {
    this.activeValue = convertToBoolProperty(val);
    if (this.activeValue) {
      this.init = true;
    }
  }
  static ngAcceptInputType_active: NbBooleanInput;

  /**
   * Lazy load content before tab selection
   * TODO: rename, as lazy is by default, and this is more `instant load`
   * @param {boolean} val
   */
  @Input()
  set lazyLoad(val: boolean) {
    this.init = convertToBoolProperty(val);
  }
  static ngAcceptInputType_lazyLoad: NbBooleanInput;

  init: boolean = false;
}

@Component({
  selector: 'ngx-tabset',
  styleUrls: ['./tabset.component.scss'],
  template: `
    <ul class="tabset">
      <li *ngFor="let tab of tabs; let index=index"
          (click)="selectTab(tab)"
          (keyup.space)="selectTab(tab)"
          (keyup.enter)="selectTab(tab)"
          [class.responsive]="tab.responsive"
          [class.active]="tab.active"
          [class.disabled]="tab.disabled"
          [attr.tabindex]="tab.disabled ? -1 : 0"
          class="tab">
        <a href (click)="$event.preventDefault()" href="" tabindex="-1" class="tab-link">
          <nb-icon *ngIf="tab.tabIcon" [config]="tab.tabIcon"></nb-icon>
          <span *ngIf="tab.tabTitle" class="tab-text">{{ tab.tabTitle }}</span>
          <nb-icon *ngIf="tab.tabCanClose" icon="close-square-outline" class="tab-close" (click)="close(index); $event.preventDefault();">X</nb-icon>
        </a>
      </li>
    </ul>
    <ng-content select="ngx-tab"></ng-content>
  `,
})
export class TabsetComponent {

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  @HostBinding('class.full-width')
  fullWidthValue: boolean = false;

  /**
   * Take full width of a parent
   * @param {boolean} val
   */
  @Input()
  set fullWidth(val: boolean) {
    this.fullWidthValue = convertToBoolProperty(val);
  }
  static ngAcceptInputType_fullWidth: NbBooleanInput;

  /**
   * Emits when tab is selected
   * @type EventEmitter<any>
   */
  @Output() changeTab = new EventEmitter<any>();
  @Output() closeTab = new EventEmitter<number>();

  constructor(private tabsetService: TabsetService) {
    this.tabsetService.selectTab$.subscribe((index: number) => {
      setTimeout(() => {
        this.selectTab(this.tabs.toArray()[index]);
      }, 10);
    });
    this.tabsetService.closeTab$.subscribe((index: number) => {
      setTimeout(() => {
        this.close(index);
      }, 10);
    });
  }

  selectTab(selectedTab: TabComponent) {
    // if (!selectedTab.disabled) {
      this.tabs.forEach(tab => tab.active = tab === selectedTab);
      this.changeTab.emit(selectedTab);
    // }
  }

  close(index: number) {
    this.tabs.toArray().splice(index, 1);
    this.closeTab.emit(index);
    this.selectTab(this.tabs.toArray()[index - 1]);
  }
}
