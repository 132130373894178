<div class="form-view-grid">
  <ngx-tabset>
    <ngx-tab tabTitle="Task Information" [active]="activeTab === TaskTab.INFO">
      <main>
        <form [formGroup]="form" autocomplete="off" style="margin-right: 0.5rem;">
          <div class="grid-wrapper mt-2">
            <ng-container *ngIf="!isRoomEnquiries">
              <div class="form-control-group height-auto box start-1 end-11 mt-1 mb-2">
                <ngx-multiple-autocomplete-selector-with-checkbox
                  *ngIf="!canChangeTaskboard"
                  [values]="taskboards"
                  placeholder="select taskboards"
                  bindLabel="boardName"
                  label="Taskboards:"
                  formControlName="taskboards"
                  [disabled]="true">
                </ngx-multiple-autocomplete-selector-with-checkbox>
                <ngx-multiple-autocomplete-selector-with-checkbox
                  *ngIf="canChangeTaskboard"
                  [values]="taskboards"
                  placeholder="select taskboards"
                  bindLabel="boardName"
                  label="Taskboards:"
                  valuesLimit="1"
                  formControlName="taskboards">
                </ngx-multiple-autocomplete-selector-with-checkbox>
                <p class="error-message">
                  {{ utils.handleValidationErrorMessage(form, 'taskboards', errorMessages) }}
                </p>
              </div>
            </ng-container>
            <ng-container *ngIf="isRoomEnquiries">
              <div class="form-control-group box start-1 end-11 mt-1rem mb-2">
                <label class="label" for="input-title">Title:</label>
                <input nbInput
                      fullWidth
                      formControlName="title"
                      name="title"
                      id="input-title"
                      maxlength="50"
                      placeholder="Title">
                <p class="error-message">
                  {{ utils.handleValidationErrorMessage(form, 'title', errorMessages) }}
                </p>
              </div>
            </ng-container>
            <div class="form-control-group box start-12 end-17 mt-1rem">
              <label class="label" for="input-status">Status:</label>
              <select formControlName="statusId">
                <option *ngFor="let status of statuses" [ngValue]="status.id">{{status.wordFullName}}</option>
              </select>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'statusId', errorMessages) }}
              </p>
            </div>
          </div>

          <div class="grid-wrapper">
            <div class="form-control-group box start-1 end-11">
              <div id="choose-template" (click)="chooseTemplate()">Choose task template</div>
            </div>
            <div class="form-control-group box start-12 end-17">
              <label class="label" for="input-due-to">Deadline:</label>
              <input nbInput
                      fullWidth
                      formControlName="dueTo"
                      name="dueTo"
                      id="input-due-to"
                      placeholder="DD/MM/YYYY"
                      [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker startView="month"></mat-datepicker>
            </div>
          </div>

          <div class="grid-wrapper lines-2 mt-2">
            <ng-container *ngIf="!isRoomEnquiries">
              <div class="form-control-group box start-1 end-11 row-1 task_category">
                <nb-icon
                  *ngIf="checkedCategories.length > 0"
                  icon="info-outline"
                  nbPopoverTrigger="hover"
                  [nbPopover]="categoryInfoPopover"
                ></nb-icon>
                <ng-template #categoryInfoPopover>
                  <div class="task-tooltip">
                    <div class="category-info-title">Following users will be informed about changes in this task:</div>
                    <ng-container>
                      <div class="category-info-subtitle">
                        <ul>
                          <li *ngFor="let name of getCategoriesNames()"> {{name}}</li>
                        </ul>
                      </div>
                    </ng-container>
                  </div>
                </ng-template>
                <ngx-multiple-autocomplete-selector-with-checkbox
                  [values]="categories"
                  bindLabel="wordFullName"
                  placeholder="select max 3 categories"
                  class="categories-selector"
                  label="Category:"
                  valuesLimit="3"
                  formControlName="categoryId">
                </ngx-multiple-autocomplete-selector-with-checkbox>
                <p class="error-message">
                  {{ utils.handleValidationErrorMessage(form, 'categoryId', errorMessages) }}
                </p>
              </div>
            </ng-container>
            <div class="form-control-group box start-12 end-17 row-1">
              <label class="label" for="input-priority-id">Priority:</label>
              <select formControlName="priorityId">
                <option *ngFor="let priority of priorities" [ngValue]="priority.id">{{priority.wordFullName}}</option>
              </select>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'priorityId', errorMessages) }}
              </p>
            </div>
            <div class="form-control-group box start-1 end-11 row-2">
              <label class="label" for="input-title">Title:</label>
                <input nbInput
                      fullWidth
                      formControlName="title"
                      name="title"
                      id="input-title"
                      maxlength="50"
                      placeholder="Title">
                <p class="error-message">
                  {{ utils.handleValidationErrorMessage(form, 'title', errorMessages) }}
                </p>
            </div>
            <div class="form-control-group box start-12 end-17 row-2">
              <ngx-multiple-autocomplete-selector-with-checkbox-and-add
                [values]="tags"
                bindLabel="wordFullName"
                placeholder="select tags"
                label="Linking tags:"
                formControlName="tags"
                noSelectAll="true"
                (newValueAdded)="newTagAdded($event)">
              </ngx-multiple-autocomplete-selector-with-checkbox-and-add>
            </div>
          </div>

          <div class="grid-wrapper mt-3 lines-2">
            <div class="form-control-group box start-1 end-11">
              <label class="label" for="comments">Description:</label>
              <textarea nbInput
                        fullWidth
                        formControlName="comments"
                        name="comments"
                        style="height: 114px;"
                        id="comments"
                        placeholder="Add notes about this task"
                        maxlength="2000"
                        autofocus>
              </textarea>
            </div>

            <div *ngIf="taskboardDetails.ragg2DictionaryName" class="form-control-group box start-12 end-17 row-1">
              <label class="label">{{taskboardDetails.ragg2DictionaryName}}:</label>
              <select formControlName="taskRagg2Id" [ngStyle]="{'background-color': raggsColors.ragg2Color}">
                <option *ngFor="let rag of raggs2" [ngValue]="rag.id" [ngStyle]="{'background-color': rag.colorHash}">{{rag.wordFullName}}</option>
              </select>
            </div>
            <div *ngIf="taskboardDetails.ragg1DictionaryName" class="form-control-group box start-12 end-17 row-2">
              <label class="label">{{taskboardDetails.ragg1DictionaryName}}:</label>
              <select formControlName="taskRagg1Id" [ngStyle]="{'background-color': raggsColors.ragg1Color}">
                <option *ngFor="let rag of raggs1" [ngValue]="rag.id" [ngStyle]="{'background-color': rag.colorHash}">{{rag.wordFullName}}</option>
              </select>
            </div>
          </div>

          <div class="grid-wrapper">
            <div *ngIf="taskboardDetails.groupingDictionaryName" class="form-control-group box start-1 end-11">
              <label class="label">{{taskboardDetails.groupingDictionaryName}}:</label>
              <select formControlName="taskGoupingId" [ngStyle]="{'background-color': raggsColors.groupingColor}">
                <option *ngFor="let group of grouping" [ngValue]="group.id" [ngStyle]="{'background-color': group.colorHash}">{{group.wordFullName}}</option>
              </select>
            </div>
          </div>

          <ngx-subtask-editor-form formControlName="subTasksList" [isRecurringVisible]="true" [addingTaskInNewTicketMode]="true"></ngx-subtask-editor-form>
        </form>
      </main>
    </ngx-tab>
    <ngx-tab tabTitle="Files" [active]="activeTab === TaskTab.FILES">
      <div class="mt-2">
        <ngx-file-upload
          [files]="files"
          [isDownloadEnabled]="false"
          (onUpload)="uploadFile($event)"
          (onDelete)="deleteFile($event)">
        </ngx-file-upload>
      </div>
    </ngx-tab>
  </ngx-tabset>
  <footer class="space-between">
    <div class="flex">
      <button class="btn btn-success"
              [disabled]="!utils.isFormValid(form) || !isAnySubtaskAdded() || !canSave"
              (click)="save()">
        <i class="nb-checkmark" aria-hidden="true"></i> Save
      </button>
      <button class="btn btn-danger ml-4"
              (click)="closeWindow()">
        <i class="nb-close" aria-hidden="true"></i> Close
      </button>
      <div class="flex middle ml-2">
        <nb-checkbox (change)="changeEmailList($event)" [checked]="this.form.get('isEmailList').value" class="task-checkbox">email with WU</nb-checkbox>
        <nb-checkbox (change)="changeEmailOnSave($event)" [checked]="this.form.get('isEmailOnSave').value" class="task-checkbox longer">email this task on save</nb-checkbox>
      </div>
    </div>
    <div class="flex middle">
      <button id="watchers" class="btn btn-info" [disabled]="!utils.isFormValid(form) || !isAnySubtaskAdded()" (click)="addWatchers()">Add Watchers</button>
      <nb-checkbox (change)="changeFavoriteState($event)" [checked]="this.form.get('isFavorite').value" class="task-checkbox">on my watchlist</nb-checkbox>
    </div>
  </footer>
</div>
