import { UsersService } from "./../../../shared/users/users.service";
import { FormErrorStateMatcher } from "./../../../utilities/form-error-state.matcher";
import { DictionariesService } from "./../../../shared/dictionaries/dictionaries.service";
import { InboxEventService } from "../_services/inbox-event.service";
import { AuthUserService } from "./../../../@core/data/auth-user.service";
import { InboxService } from "./../../../shared/inbox/inbox.service";
import { atLeastOne } from "../../../utilities/validators";
import { Subscription, forkJoin } from "rxjs";
import {
  handleValidationErrorMessage,
  getValidationStatus,
  isFormValid,
  isFieldInvalid,
  getErrorMessage,
} from "../../../utilities/utils";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormControl,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import {
  ChooseTemplateDialogComponent,
  TemplateType,
} from "../../shared/choose-template/choose-template.component";
import {
  QuestionDecisionTypeEnum,
  QuestionDialogComponent,
} from "../../shared/question-dialog/question-dialog.component";
import { MessagesService } from "../../../shared/messages/messages.service";
import { CareHomesService } from "./../../../shared/care-homes/care-homes.service";

const MAX_SIZE = 10 * 1024 * 1024; // 10MB
const fileName = "echo-screenshot.png";
@Component({
  selector: "ngx-new-message-dialog",
  templateUrl: "./new-message-dialog.component.html",
  styleUrls: ["./new-message-dialog.component.scss"],
})
export class NewMessageDialogComponent implements OnInit {
  @ViewChild("FileInput", { static: false }) FileInput: ElementRef;
  public form: UntypedFormGroup;
  public careHomeId = 0;
  public reportType = "Home";
  private subscription: Subscription = new Subscription();
  public userId = 0;
  public matcher = new FormErrorStateMatcher();
  public utils = {
    getValidationStatus,
    handleValidationErrorMessage,
    isFormValid,
    isFieldInvalid,
  };
  public recipients = [];
  public e360recipients = [];
  public groupes = [];
  public files = [];
  public careHomes = [];
  public messageType = "carehome";
  public isPined = false;
  public showUnpin = false;
  public canSave = true;
  public label360: "Add to employees 360" | "Hide 360 list" =
    "Add to employees 360";
  public sendScreenshoot = false;
  public sendToValues = [];
  show360List: boolean = false;
  showAdvanced = false;

  public defaultRecipientsIds = [];
  public defaultRecipients = [];

  constructor(
    private inboxService: InboxService,
    private authUserService: AuthUserService,
    private toastService: NbToastrService,
    private eventService: InboxEventService,
    private dictionariesService: DictionariesService,
    private usersService: UsersService,
    public careHomesService: CareHomesService,
    public dialogRef: MatDialogRef<NewMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private messagesService: MessagesService
  ) {}

  ngOnInit() {
    this.createForm();
    this.userId = this.authUserService.getUser().id;
    this.careHomeId = this.authUserService.getCareHomeId();
    this.dialogRef.updateSize("900px");
    this.loadSendToValues(this.messageType, [], true);
    this.getCareHomes();
    if (this.data.type !== 'reply' && this.data.type !== 'forward') {
      this.chooseTemplate();
    }
    if (this.data.type === 'reply') {
      this.getMessagesAddresBook(this.data.type, [this.authUserService.getCareHomeId(), this.data.fromCareHomeId]);
    }
  }

  getCareHomes() {
    this.subscription.add(
      this.careHomesService.getCareHomes().subscribe(
        (response: any) => {
          const _careHomes = response.result.careHomesList;
          const _careHomeList = [];
          _careHomes.forEach((element) => {
            _careHomeList.push({
              id: element.careHomeId,
              fullName: element.careHomeFullName,
            });
          });
          this.careHomes = _careHomeList;
          const careHome = this.getMineCareHome();
          this.form.patchValue({
            careHomeList: [careHome],
          });
        },
        (err: any) => {
          this.toastrService.danger(getErrorMessage(err), "Error", {
            duration: 60000,
            destroyByClick: true,
          });
        }
      )
    );
  }

  getMineCareHome() {
    const careHome = this.careHomes.find((element: any) => {
      return element.id == this.authUserService.getCareHomeId();
    });
    return careHome;
  }

  getMessagesAddresBook(messageType, careHomeIds: number[], toUpdateValues?) {
    const careHomeIdsFinal = [...careHomeIds];
    this.subscription.add(
      this.usersService
        .getMessagesAddresBookForHomes(
          this.userId,
          careHomeIdsFinal,
          messageType
        )
        .subscribe((response: any) => {
          this.recipients = response.result.list;
          if (toUpdateValues) this.updateValues();
        })
    );
  }

  getProfessionalRoles(messageType) {
    this.subscription.add(
      this.dictionariesService
        .getProfessionalRolesActiveOnlyForMessages(
          this.authUserService.getCareHomeId(),
          true,
          messageType
        )
        .subscribe((response: any) => {
          this.groupes = response.result;
        })
    );
  }

  private loadSendToValues(
    messageType: string,
    careHomeIds: number[],
    toUpdateValues?: boolean
  ) {
    const careHomeIdsFinal = [...careHomeIds];
    forkJoin([
      this.usersService.getMessagesAddresBookForHomes(
        this.userId,
        this.data.type === 'reply' ? [...careHomeIdsFinal, this.data.fromCareHomeId] : [careHomeIdsFinal],
        messageType
      ),
      this.dictionariesService.getProfessionalRolesActiveOnlyForMessages(
        this.authUserService.getCareHomeId(),
        true,
        messageType
      ),
    ]).subscribe(([recipients, groupes]) => {
      this.recipients = recipients.result.list;
      this.groupes = groupes.result;
      this.sendToValues = [
        ...this.groupes.map((e) => ({
          id: `group_${e.id}`,
          name: e.roleName,
          secondName: null,
          color: e.color,
        })),
        ...this.recipients.map((e) => ({
          id: `user_${e.id}`,
          name: e.fullName,
          secondName: e.defaultCareHome,
          color: "#000",
        })),
      ];

      if (toUpdateValues) this.updateValues();
    });
  }

  createForm() {
    this.form = new UntypedFormGroup(
      {
        recipients: new UntypedFormControl(null),
        e360recipients: new UntypedFormControl(null),
        sendTo: new UntypedFormControl(null, [Validators.required]),
        groupes: new UntypedFormControl(null),
        topic: new UntypedFormControl(null, [Validators.required]),
        message: new UntypedFormControl(null, [Validators.required]),
        careHomeList: new UntypedFormControl(null),
      },
      atLeastOne(Validators.required, ["sendTo", "groupes"])
    );

    this.form.controls["sendTo"].valueChanges.subscribe((value) => {
      this.e360recipients = value.filter((e) => e?.id?.includes('user'));
    });

    this.form.controls["careHomeList"].valueChanges.subscribe((value) => {
      this.onCareHomeChange(value);
    });
  }

  updateValues() {
    const message =
      "\nFrom: " +
      this.data.fromName +
      " <" +
      this.data.fromEmail +
      ">" +
      "\nDate: " +
      this.data.date +
      "\nTo: " +
      this.data.toName +
      " <" +
      this.data.toEmail +
      ">" +
      "\nSubject: " +
      this.data.subject +
      "\n\n" +
      this.data.message;
    if (
      (this.data.sender || this.data.newMessageUserId) &&
      this.data.type === "reply"
    ) {
      const senderId = this.data.sender || this.data.newMessageUserId;
      const newRecipient = this.recipients.find((rec) => rec.id === senderId);
      let sendToValue = null;
      if (this.sendToValues.length) {
        sendToValue = this.sendToValues.find((e) => e?.id === `user_${newRecipient?.id}`);
      }
      this.form.patchValue({
        recipients: [newRecipient],
        sendTo: sendToValue ? [sendToValue] : [],
        topic: "RE: " + this.data.subject,
        message: "\n\n\n ---------- Replied  message ---------" + message,
      });
    }
    if (this.data.type === "forward") {
      this.form.patchValue({
        topic: "FWD: " + this.data.subject,
        message: "\n\n\n ---------- Forwarded message ---------" + message,
      });
      this.data.attachmentsList.forEach((file) => {
        this.files.push(file);
      });
    }
  }
  getRecipients() {
    return this.form.get("recipients").value;
  }
  getGroupes() {
    return this.form.get("groupes").value;
  }
  get360Recipients() {
    return this.form.get("e360recipients").value;
  }
  getSendTo() {
    return this.form.get("sendTo").value;
  }

  getCareHomeList() {
    return this.form.get("careHomeList").value;
  }

  toogleSendTo(type) {
    this.messageType = type;
    this.loadSendToValues(type, []);
  }

  showPinnedWarningMessage() {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: "Warning",
          message:
            "This message will be visible to all, published on Staff Noticeboard",
          okLabel: "Ok, Understand.",
          cancelLabel: "Cancel",
        },
      })
      .onClose.subscribe((response: any) => {
        if (response) {
          this.sendMessage();
        }
      });
  }

  sendMessage() {
    this.canSave = false;
    const recipientsId = [];
    const e360Id = [];
    const grupesId = [];
    const sendTo = [];
    const toCareHomeIdList = [];
    if (this.getRecipients()) {
      this.getRecipients().forEach((rec) => {
        recipientsId.push(rec.id.toString());
      });
    }
    if (this.getGroupes()) {
      this.getGroupes().forEach((role) => {
        grupesId.push(role.id.toString());
      });
    }
    if (this.get360Recipients()) {
      this.get360Recipients().forEach((rec) => {
        e360Id.push(rec.id.replace('user_', '').toString());
      });
    }

    if (this.getSendTo()) {
      this.getSendTo().forEach((e) => {
        sendTo.push(e.id.toString());
      });
    }

    if (this.getCareHomeList()) {
      this.getCareHomeList().forEach((e) => {
        toCareHomeIdList.push(e.id.toString());
      });
    }

    const fd = new FormData();
    fd.append("subject", this.form.get("topic").value);
    fd.append("message", this.form.get("message").value);
    fd.append("fromId", this.userId.toString());
    fd.append(
      "toCareHomeId",
      this.messageType === "echo"
        ? "-1"
        : this.authUserService.getCareHomeId().toString()
    );
    fd.append(
      "scheduleRelated",
      this.data.scheduleRelated ? this.data.scheduleRelated.toString() : ""
    );
    fd.append(
      "replayToUid",
      this.data.replyToUid ? this.data.replyToUid.toString() : ""
    );
    // fd.append('toIdList', '[' + recipientsId.toString() + ']');
    // fd.append('toGroupIdList', '[' + grupesId.toString() + ']');
    fd.append("toCareHomeIdList", "[" + toCareHomeIdList.toString() + "]");
    fd.append("sendToList", "[" + sendTo.toString() + "]");
    fd.append("e360IdList", "[" + e360Id.toString() + "]");
    fd.append("isPined", this.isPined ? "true" : "false");
    const filesUid = [];
    this.files.forEach((file: any) => {
      file.size ? fd.append("files", file) : filesUid.push(file.uid);
    });
    if (filesUid.length > 0) {
      fd.append("filesUid", "[" + filesUid.toString() + "]");
    }
    this.inboxService.sendEasyMessage(this.userId, fd).subscribe(
      (response: any) => {
        this.canSave = true;
        this.eventService.updateInbox();
        this.toastService.success(response.message);
        this.dialogRef.close();
      },
      (err: any) => {
        this.canSave = true;
        this.toastrService.danger(getErrorMessage(err), "Error", {
          duration: 60000,
          destroyByClick: true,
        });
      }
    );
  }

  fileSelected(event) {
    for (const file of event) {
      if (file.type !== "application/pdf") {
        this.toastService.danger(
          "PDF is the preferred document format. Non-PDF attachments can be difficult to open on mobile devices.",
          "Error",
          { duration: 60000, destroyByClick: true }
        );
      }
      if (file.size <= MAX_SIZE) {
        this.files.push(file);
      } else {
        this.toastService.danger(
          `Maximum file size exceed. Max file size is 10 MB.`,
          "Error",
          { duration: 60000, destroyByClick: true }
        );
      }
    }
  }

  deleteFile(index) {
    this.files.splice(index, 1);
  }

  clickInput() {
    this.FileInput.nativeElement.click();
  }

  close() {
    this.dialogRef.close();
  }

  private checkMessageChanged(): boolean {
    if (
      this.form.get("recipients").value !== null ||
      this.form.get("groupes").value !== null ||
      this.form.get("message").value !== null ||
      this.form.get("topic").value !== null
    ) {
      return true;
    }
    return false;
  }

  chooseTemplate() {
    this.subscription.add(
      this.dialogService
        .open(ChooseTemplateDialogComponent, {
          closeOnBackdropClick: false,
          context: {
            type: TemplateType.MESSAGE,
          },
        })
        .onClose.subscribe((templateId: any) => {
          if (templateId) {
            const changed = this.checkMessageChanged();
            if (changed) {
              this.subscription.add(
                this.dialogService
                  .open(QuestionDialogComponent, {
                    closeOnBackdropClick: false,
                    context: {
                      title: "Confirmation",
                      message: `We have detected information already within the message.<br>Should we ADD the template to what you’ve already got, wherever possible,<br>OR shall we OVERWRITE what’s already there`,
                      okLabel: "Add",
                      cancelLabel: "Cancel",
                      hideSecondOkButton: false,
                      secondOkLabel: "Overwrite",
                    },
                  })
                  .onClose.subscribe((decision: any) => {
                    if (decision && decision.type && decision.isConfirm) {
                      if (decision.type === QuestionDecisionTypeEnum.FIRST) {
                        this.applyTemplate(templateId, false);
                      } else {
                        this.applyTemplate(templateId, true);
                      }
                    }
                  })
              );
            } else {
              this.applyTemplate(templateId, true);
            }
          }
        })
    );
  }

  prepareData() {
    const data = {
      subject: this.form.get("topic").value,
      message: this.form.get("message").value,
      categoryId: this.form.get("categoryId").value,
      toIdList: this.form.get("toIdList").value,
      toGroupIdList: this.form.get("toGroupIdList").value,
    };
    return data;
  }

  applyTemplate(detailsId: any, overwirte = false) {
    this.messagesService
      .getMessageTemplateDetails(detailsId)
      .subscribe((response: any) => {
        const templateDetails = response.result;
        // const originData = this.prepareData();
        if (overwirte) {
          const recipients = this.fillArray(
            templateDetails.toIdList,
            this.recipients
          );
          const groupes = this.fillArray(
            templateDetails.toGroupIdList,
            this.groupes
          );
          this.form.patchValue({
            recipients: recipients,
            groupes: groupes,
            topic: templateDetails.subject,
            message: templateDetails.message,
          });

          console.log(groupes);

          const sendToList = [
            ...recipients.map((item) => {
              return {
                id: `user_${item.id}`,
                name: item.fullName,
              };
            }),
            ...groupes.map((item) => {
              return {
                id: `group_${item.id}`,
                name: item.roleName,
              };
            }),
          ];

          this.setDefaultRecipients(sendToList);

          this.messageType = templateDetails.isAllRCG ? "echo" : "carehome";

          this.toastrService.info(
            "Template has been aplied as an overwrite",
            "Info",
            { duration: 60000, destroyByClick: true }
          );
        } else {
          //TODO
          const recipients = this.fillArray(
            templateDetails.toIdList,
            this.recipients
          );
          const groupes = this.fillArray(
            templateDetails.toGroupIdList,
            this.groupes
          );
          this.form.patchValue({
            recipients: recipients,
            groupes: groupes,
            topic: templateDetails.subject,
            message: templateDetails.message,
          });
          this.files = templateDetails.files;
          this.messageType = templateDetails.isAllRCG ? "echo" : "carehome";

          this.toastrService.info("Template has been aplied", "Info", {
            duration: 60000,
            destroyByClick: true,
          });
        }
      });
  }

  setDefaultRecipients(sendToList) {
    if (sendToList) {
      this.defaultRecipients = sendToList;
      console.log(this.defaultRecipients);
    }
  }

  fillArray(ids: [], sourceArray: any = []) {
    const array = [];
    ids.forEach((itemId: number) => {
      const item = sourceArray.find((sourceItem: any) => {
        return itemId == sourceItem.id;
      });
      if (item) {
        array.push(item);
      }
    });
    return array;
  }

  change360List() {
    this.show360List = !this.show360List;
    if (!this.show360List) {
      this.label360 = "Add to employees 360";
    } else {
      this.label360 = "Hide 360 list";
    }
  }

  onCareHomeChange(data: any) {
    const ids = this.getHomeIds(data);
    this.loadSendToValues(this.messageType, ids);
  }

  private getHomeIds(homes: any) {
    const idArr = [];

    for (const taskboard of homes) {
      idArr.push(taskboard.id);
    }
    return idArr;
  }

  changeAttachFile($event) {
    this.sendScreenshoot = $event.checked;
    if (this.sendScreenshoot) {
      this.files.unshift(this.dataURLtoFile(this.data.screenshot, fileName));
    } else {
      if (this.files[0]?.name === fileName) {
        this.files.shift();
      }
    }
  }

  private dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  isScreenshot(indexOfFile: number) {
    return this.files[indexOfFile].name === fileName;
  }

  openInNewTab(indexOfFile: number) {
    const fileURL = URL.createObjectURL(this.files[indexOfFile]);
    window.open(fileURL, "_blank");
  }
}
