<table
  #table
  mat-table
  matSort
  (matSortChange)="matSortChange.emit($event)"
  [dataSource]="dataSource"
  class="only-table"
>
  <ng-container matColumnDef="index">
    <th
      mat-header-cell
      *matHeaderCellDef
      class="header num"
      mat-sort-header
      [disabled]="disableSorting"
    >
      <span>No.</span>
    </th>
    <td
      mat-cell
      *matCellDef="let element; let index = index"
      [ngStyle]="{ background: specifyRowColors ? idMaps[element.eventSeriesId] : (element ? element.indexColor : 'transparent') }"
      [ngClass]="{
        'is-booking': element.isBooking
      }"
    >
      <span class="num">
        {{ index + 1 }}
        <nb-icon
          *ngIf="element['attachmentCount'] > 0"
          icon="attach-2-outline"
          class="taskboard-icon"
        ></nb-icon>
      </span>
    </td>
    <td mat-footer-cell *matFooterCellDef class="header summary">
      <span>Total</span>
    </td>
  </ng-container>
  <ng-container
    *ngFor="let column of columns; trackBy: trackByFn; index as i"
    [matColumnDef]="column.fieldName"
  >
    <!-- header -->
    <ng-container *ngIf="column.tooltip">
      <ng-container *ngIf="column.staticWidth">
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="header"
          [nbTooltip]="column.tooltip || column.title"
          [ngStyle]="{
            'min-width': column.staticWidth,
            'max-width': column.staticWidth
          }"
          [disabled]="column.disableSort ? column.disableSort : disableSorting"
        >
          <span>{{
            column.charsLimit
              ? (column.title | charsLimit: column.charsLimit)
              : column.title
          }}</span>
        </th>
      </ng-container>
      <ng-container *ngIf="!column.staticWidth">
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="header"
          [nbTooltip]="column.tooltip"
          [disabled]="column.disableSort ? column.disableSort : disableSorting"
        >
          <span>{{ column.title }}</span>
        </th>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!column.tooltip">
      <ng-container *ngIf="column.staticWidth">
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="header"
          [ngStyle]="{
            'min-width': column.staticWidth,
            'max-width': column.staticWidth
          }"
          [disabled]="disableSorting"
        >
          <span>{{
            column.charsLimit
              ? (column.title | charsLimit: column.charsLimit)
              : column.title
          }}</span>
        </th>
      </ng-container>
      <ng-container *ngIf="!column.staticWidth">
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="header"
          [disabled]="column.disableSort ? column.disableSort : disableSorting"
        >
          <span>{{ column.title }}</span>
        </th>
      </ng-container>
    </ng-container>
    <!-- header -->
    <!-- content -->
    <td
      mat-cell
      *matCellDef="let element"
      (click)="onRowClick ? onRowClick(element) : ''"
      [ngStyle]="{
        background: specifyRowColors ? idMaps[element.eventSeriesId] : element[column.backgroundFieldName],
        cursor: onRowClick ? 'pointer' : element[column.pointer] ? 'pointer' : ''
      }"
      [ngClass]="{
        'is-booking': element.isBooking
      }"
      [colSpan]="element.isEmployeeRotaSummaryRow && !element.noColSpan && i === 7 ? '2' : ''"
    >
      <ng-container *ngIf="column.fieldType && column.fieldType === 'download'">
        <span
          style="
            text-align: center;
            cursor: pointer;
            padding: 0;
            padding-top: 0.1rem;
          "
          ><nb-icon
            (click)="column.action && column.action(element)"
            icon="download-outline"
          ></nb-icon
        ></span>
      </ng-container>
      <ng-container
        *ngIf="column.fieldType && column.fieldType === 'textarea-view'"
      >
        <textarea
          autosize
          [maxRows]="7"
          class="textarea-view"
          #content
          [innerHTML]="element[column.fieldName]"
          readonly
        >
        </textarea>
      </ng-container>
      <ng-container
        *ngIf="column.fieldType && column.fieldType === 'textarea-editable' && !(element.isEmployeeRotaSummaryRow && column.hideColumnInSummary)"
      >
        <textarea
          *ngIf="!element.isTextareaDisabled"
          autosize
          [maxRows]="column.textareaMaxRows || 3"
          class="textarea-editable"
          (blur)="onTextFieldChange($event); textAreaChange(element, $event.target.value, i)"
          #content
          [innerHTML]="element[column.fieldName]"
        >
        </textarea>
        <span *ngIf="element.isTextareaDisabled">{{ element ? element[column.fieldName] : "" }}</span>
      </ng-container>
      <ng-container
        *ngIf="!column.fieldType || column.fieldType !== 'textarea-view'"
      >
        <ng-container *ngIf="column.fieldName != 'avatar'">
          <ng-container *ngIf="!column.tooltipFieldName && !(column.fieldType === 'textarea-editable' && column.hideSpanAfterHtml)">
            <span
              *ngIf="!column.valuePrepareFunction && !(element.isEmployeeRotaSummaryRow && column.hideColumnInSummary) && !column.canBeEditable && !column.dontDisplayAsCommonText"
              (click)="column.action ? column.action($event, element) : null"
              [ngStyle]="{ cursor: column.action ? 'pointer' : element[column.pointer] ? 'pointer' : '' }"
              [ngClass]="{ 'only-table__rota-summary': element.isEmployeeRotaSummaryRow && column.fieldName === 'confirmed' }"
            >
              <ng-container *ngIf="!column.isAgencyLocationInfo && !column.isAgencyPhotoInfo">
                {{ element ? element[column.fieldName] : "" }}
              </ng-container>

              <ng-container *ngIf="column.isAgencyLocationInfo">
                <div class="display-flex display-flex--justify-center">
                  <ng-container *ngIf="element.gpsPosition?.length > 0; else noShiftInfo">
                    <ngx-ui-table-gps-cell
                      *ngFor="let position of element[column.fieldName]"
                      [employeePositionName]="element.agencyEmployeeRoleName"
                      [employeeFullName]="element.agencyEmployeeFullName"
                      [gpsData]="position"
                    ></ngx-ui-table-gps-cell>
                  </ng-container>
                </div>
              </ng-container>

              <ng-container *ngIf="column.isAgencyPhotoInfo">
                <div class="display-flex display-flex--justify-center">
                  <ng-container *ngIf="element.confirmedPhoto?.length > 0; else noShiftInfo">
                    <ngx-ui-table-photo-cell
                      *ngFor="let position of element[column.fieldName]"
                      [employeePositionName]="element.agencyEmployeeRoleName"
                      [employeeFullName]="element.agencyEmployeeFullName"
                      [photoData]="position"
                    ></ngx-ui-table-photo-cell>
                  </ng-container>
                </div>
              </ng-container>

              <ng-template #noShiftInfo>
                <i class="nb-close only-table__no-info-icon" aria-hidden="true"></i>
              </ng-template>

            </span>
            <span
              class="value-prepare"
              *ngIf="column.valuePrepareFunction && !(element.isEmployeeRotaSummaryRow && column.hideColumnInSummary) && !column.tooltipFunction"
              (click)="column.action ? column.action($event, element) : null"
              [ngStyle]="{ cursor: column.action ? 'pointer' : '' }"
              [innerHTML]="element ? column.valuePrepareFunction(element) : ''"
            ></span>

            <span
              *ngIf="column.valuePrepareFunction && !(element.isEmployeeRotaSummaryRow && column.hideColumnInSummary) && column.tooltipFunction"
              (click)="column.action ? column.action($event, element) : null"
              [ngStyle]="{ cursor: column.action ? 'pointer' : '' }"
              [innerHTML]="element ? column.valuePrepareFunction(element) : ''"
              [nbTooltip]="column.tooltipFunction(element)"
            ></span>

            <span *ngIf="column.dontDisplayAsCommonText && column.fieldName === 'agencyName' && !(element.isEmployeeRotaSummaryRow && column.hideColumnInSummary)">
              <ngx-ui-agencies-list [agencies]="agencies" [agencyId]="element.agencyId" (onAgencyChanged)="agencyChanged($event, element, i)"></ngx-ui-agencies-list>
            </span>

            <ng-container *ngIf="column.canBeEditable">
              <ng-container *ngIf="!element.isEditMode">
                <span [ngClass]="{'only-table__hours--warning': column.fieldName === 'confirmed' && element.isConfirmedDifferentThenScheduled}">
                  {{ element ? element[column.fieldName] : "" }}
                </span>
              </ng-container>
              <ng-container *ngIf="element.isEditMode">
                <ngx-ui-table-editable-hours-cell *ngIf="column.fieldName === 'scheduled'" [cellForm]="element.scheduledForm"></ngx-ui-table-editable-hours-cell>
                <ngx-ui-table-editable-hours-cell *ngIf="column.fieldName === 'confirmed'" [cellForm]="element.confirmedForm"></ngx-ui-table-editable-hours-cell>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="column.tooltipFieldName">
            <span
              *ngIf="!column.valuePrepareFunction"
              (click)="column.action ? column.action($event, element) : null"
              [ngStyle]="{ cursor: column.action ? 'pointer' : '' }"
              [nbPopover]="templateRef"
              nbPopoverTrigger="hint"
              >{{ element ? element[column.fieldName] : "" }}</span
            >
            <span
              *ngIf="
                column.valuePrepareFunction && element[column.tooltipFieldName]
              "
              (click)="column.action ? column.action($event, element) : null"
              [ngStyle]="{ cursor: column.action ? 'pointer' : '' }"
              [innerHTML]="element ? column.valuePrepareFunction(element) : ''"
              [nbPopover]="templateRef"
              nbPopoverTrigger="hint"
            ></span>
            <span
              *ngIf="
                column.valuePrepareFunction && !element[column.tooltipFieldName]
              "
              (click)="column.action ? column.action($event, element) : null"
              [ngStyle]="{ cursor: column.action ? 'pointer' : '' }"
              [innerHTML]="element ? column.valuePrepareFunction(element) : ''"
            ></span>
            <ng-template #templateRef>
              <div
                class="popover-container"
                [innerHTML]="element[column.tooltipFieldName]"
              ></div>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="column.fieldName == 'avatar'">
          <span
            *ngIf="column.resource === AvatarResource.EMPLOYEE && !element.isEmployeeRotaSummaryRow"
            [ngClass]="{ 'center-avatar': thinner }"
            style="cursor: pointer"
            (click)="
              showEmployee360(
                $event,
                element[column.id],
                element[column.firstNameColumn],
                element[column.surnameColumn],
                element[column.fullNameColumn]
              )
            "
          >
            <img
              [src]="element[column.avatarId] | avatar | async"
              class="avatar"
              [ngClass]="{ 'thinner-avatar': thinner }"
              alt=""
            />
          </span>
          <span
            *ngIf="column.resource !== AvatarResource.EMPLOYEE"
            [ngClass]="{ 'center-avatar': thinner }"
          >
            <img
              [src]="element[column.avatarId] | avatar | async"
              class="avatar"
              [ngClass]="{ 'thinner-avatar': thinner }"
              alt=""
            />
          </span>
        </ng-container>
      </ng-container>
    </td>
    <!-- content -->
    <td mat-footer-cell *matFooterCellDef class="header summary">
      <span *ngIf="column.total">{{
        getSum(column.fieldName, column.fieldType)
      }}</span
      ><span *ngIf="!column.total"></span>
    </td>
  </ng-container>
  <!-- <tr mat-header-row *matHeaderRowDef="doubleHeaderColumns; sticky: true"></tr> -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    class="thinner NEW ROW"
    [ngClass]="{
      'row-disabled': row.isLTSickOrMaternity,
      'row-not-started-yet': row.isNotStartedYet,
      'thicker-line': row.isThickerLineNeeded,
      'graded-row': row.isEmployeeRotaSummaryRow && !row.isSummaryBlackRow,
      'black-row': row.isEmployeeRotaSummaryRow && row.isSummaryBlackRow
    }"
  ></tr>
  <ng-container *ngIf="totalRow  && customTotal.value == ''">
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
  </ng-container>
  <ng-container matColumnDef="empty">
    <td mat-footer-cell *matFooterCellDef class="header summary"><span></span></td>
  </ng-container>
  <ng-container matColumnDef="total">
    <td mat-footer-cell *matFooterCellDef class="header summary footer-total">
      <span class="justify-content-end">{{customTotal.name}}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="totalImpact">
    <td mat-footer-cell *matFooterCellDef class="header summary footer-total">
      <span class="justify-content-start">{{customTotal.value}}</span>
    </td>
  </ng-container>
  <ng-container *ngIf="totalRow && customTotal.value != ''">
    <tr mat-footer-row *matFooterRowDef="displayedColumnsFinal"></tr>
  </ng-container>

  <!--Awards specific double header-->
  <ng-container *ngIf="doubleHeaderColumns === 'awards'">
    <!-- <ng-container matColumnDef="awards-no">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>
    <ng-container matColumnDef="awards-name">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>
    <ng-container matColumnDef="awards-new_contract">
      <th mat-header-cell *matHeaderCellDef class=>Last month: User:</th>
    </ng-container>
    <ng-container matColumnDef="awards-ote">
      <th mat-header-cell *matHeaderCellDef class=>Recommended: User:</th>
    </ng-container>
    <ng-container matColumnDef="awards-SMA">
      <th mat-header-cell *matHeaderCellDef class=>Recommended: User:</th>
    </ng-container>
    <ng-container matColumnDef="awards-care">
      <th mat-header-cell *matHeaderCellDef class=>Last month: User:</th>
    </ng-container>
    <ng-container matColumnDef="awards-explanation">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container> -->
    <ng-container matColumnDef="awards-no">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>
    <ng-container matColumnDef="awards-avatar">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>
    <ng-container matColumnDef="awards-name">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>
    <ng-container matColumnDef="awards-new_contract">
      <th mat-header-cell *matHeaderCellDef class="awards-header-cell">
        <span class="cell">last month</span>
        <span class="cell">user</span>
      </th>
    </ng-container>
    <ng-container matColumnDef="awards-ote">
      <th mat-header-cell *matHeaderCellDef class="awards-header-cell">
        <span class="cell">recommended</span>
        <span class="cell">user</span>
      </th>
    </ng-container>
    <ng-container matColumnDef="awards-food">
      <th mat-header-cell *matHeaderCellDef class="awards-header-cell">
        <span class="cell">recommended</span>
        <span class="cell">user</span>
      </th>
    </ng-container>
    <ng-container matColumnDef="awards-cleaning">
      <th mat-header-cell *matHeaderCellDef class="awards-header-cell">
        <span class="cell">recommended</span>
        <span class="cell">user</span>
      </th>
    </ng-container>
    <ng-container matColumnDef="awards-care">
      <th mat-header-cell *matHeaderCellDef class="awards-header-cell">
        <span class="cell">last month</span>
        <span class="cell">user</span>
      </th>
    </ng-container>
    <ng-container matColumnDef="awards-explanation">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>
    <tr
      class="awards-double-header-row"
      mat-header-row
      *matHeaderRowDef="[
        'awards-no',
        'awards-avatar',
        'awards-name',
        'awards-new_contract',
        'awards-ote',
        'awards-food',
        'awards-cleaning',
        'awards-care',
        'awards-explanation'
      ]"
    ></tr>
  </ng-container>
</table>
