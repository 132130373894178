export const eventsErrors = [
  {
    field: 'eventType',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'sicknessStart',
    errors: [
      {
        error: 'invalidDate',
        message: 'Invalid date'
      },
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'sicknessEnd',
    errors: [
      {
        error: 'invalidDate',
        message: 'Invalid date'
      },
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'leavingStart',
    errors: [
      {
        error: 'invalidDate',
        message: 'Invalid date'
      },
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'leavingEnd',
    errors: [
      {
        error: 'invalidDate',
        message: 'Invalid date'
      },
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'firstDayAvailable',
    errors: [
      {
        error: 'dateGreaterOrEqualThan',
        message: 'Must be greater than end date'
      },
      {
        error: 'dateFDAEmptyOrNextAfterEnd',
        message: 'Must be end+1 day or empty'
      }
    ]
  },
  {
    field: 'description',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Must be at least 3 characters long'
      },
      {
        error: 'maxlength',
        message: 'Description cannot be longer than 5000 characters'
      }
    ]
  },
  {
    field: 'cancellationDate',
    errors: [
      {
        error: 'invalidDate',
        message: 'Invalid date'
      },
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'cancellationDescription',
    errors: [
      {
        error: 'minlength',
        message: 'Minimum 3 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 5000 characters'
      },
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
];
