<div class="hour-modal-container">
  <div class="hour-modal-button-wrapper">
    <button
      (click)="chooseValues(leftButtonValue); $event.stopPropagation()"
      class="btn btn-info"
      [ngClass]="{ 'btn-danger': leftButtonIsRed }"
    >
      {{ leftButtonFrom }} - {{ leftButtonTo }}
    </button>
    <span
      class="hour-modal-hrs"
      [ngClass]="{ 'danger-text': leftButtonIsRed }"
      >{{ leftButtonHrsValue }}</span
    >
  </div>
  <div class="hour-modal-button-wrapper">
    <button
      (click)="chooseValues(rightButtonValue); $event.stopPropagation()"
      class="btn btn-info"
      [ngClass]="{ 'btn-danger': rightButtonIsRed }"
    >
      {{ rightButtonFrom }} - {{ rightButtonTo }}
    </button>
    <span
      class="hour-modal-hrs"
      [ngClass]="{ 'danger-text': rightButtonIsRed }"
      >{{ rightButtonHrsValue }}</span
    >
  </div>
</div>
<span class="hour-value-text"
  >24-hour notation. For example: 2PM = 14:00, 5AM = 05:00</span
>
