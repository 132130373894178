import { OnInit, OnDestroy, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { TableService } from '../../../shared/table/table.service';
import { LoadingStatus } from '../../../shared/loading-status.enum';

@Component({
  selector: 'ngx-tasks-details',
  templateUrl: './tasks-details.component.html',
  styleUrls: ['./tasks-details.component.scss']
})
export class TasksDetailsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public LoadingStatus: typeof LoadingStatus = LoadingStatus;
  public loaded = LoadingStatus.NOT_LOADED;
  public data = [];
  public dataStream: any = {};
  public careHomeId: any;
  public viewParams: any;
  public date: any;
  public fieldName = '';

  public columns = [
    {
      fieldName: 'title',
      title: 'Title',
      class: 'text-left'
    },
    {
      fieldName: 'description',
      title: 'Description',
    },
    {
      fieldName: 'latestUpdate',
      title: 'Latest update',
    },
    {
      fieldName: 'shortHistory',
      title: 'Submitted update',
    },
    {
      fieldName: 'status',
      title: 'State',
    }
  ];

  constructor(private tableService: TableService) {}

  ngOnInit() {
    this.viewParams = this.tableService.getValue().parameters || this.tableService.getValue();
    this.careHomeId = this.viewParams.careHomeId;
    this.date = this.viewParams.date;
    this.fieldName = this.viewParams.fieldName;
    this.dataStream = this.viewParams.dataStream;
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
