import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { FileUploadComponent } from '../file-upload.component';
import { AuthUserService } from '../../../../@core/data/auth-user.service';
import { UntypedFormGroup } from '@angular/forms';
import { TableService } from '../../table/table.service';
import { EditEmployeeComponent } from '../../../employess/edit-employee/edit-employee.component';
import { EmployeeTab } from '../../../employess/employee-tab.enum';
import { NewDocumentsComponent } from '../../../employess/contracts/new-documents/new-documents.component';
import { QuestionDialogComponent } from "../../question-dialog/question-dialog.component";

const MAX_SIZE = 10 * 1024 * 1024; // 10MB

@Component({
  selector: 'ngx-employee-file-upload',
  templateUrl: './employee-file-upload.component.html',
  styleUrls: ['./employee-file-upload.component.scss']
})
export class EmployeeFileUploadComponent extends FileUploadComponent {
  @Input() categoriesWithFiles = [];
  @Input() securityLevelId: any;
  @Input() activeOnly: boolean = true;
  @Input('employeeDateFirstJoined') employeeDateFirstJoined: any;
  @ViewChild('individualFileInput', { static: false }) individualFileInput: ElementRef;
  private categoryId: number = 0;
  public form: UntypedFormGroup;
  public employeeId = 0;

  constructor(public authUserService: AuthUserService,
              public toastrService: NbToastrService,
              public tableService: TableService,
              public dialogService: NbDialogService) {
    super(authUserService, toastrService, dialogService);

    this.employeeId = this.tableService.getValue().elementId ? this.tableService.getValue().elementId : this.tableService.getValue().employeeId;

  }

  saveDesc(event: any, file: any) {
    this.descriptionUpdate.emit({
      text: event.target.value,
      uid: file.uid
    });
  }

  setCategory(category: any) {
    this.categoryId = category.id;
  }

  uploadFileWithCategory(event: any, fileCategory: any) {
    this.categoryId = fileCategory.id;
    for (const file of event) {
      if (file.size <= MAX_SIZE) {
        file['categoryId'] = this.categoryId;
        file['category'] = fileCategory.name;
        this.onUpload.next(file);
      } else {
        this.toastrService.danger(`Maximum file size exceed. Max file size is 10 MB.`, 'Error');
      }
    }
  }

  addNewDoc(category: any) {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'New contract document',
          message: '',
          okLabel: 'This is a new contract or you are replacing an existing contract',
          secondOkLabel: 'I am assigning a document to an existing contract',
          hideSecondOkButton: false
        },
      })
      .onClose.subscribe((decision: any) => {
      if (decision.isConfirm) {
        this.setCategory(category);
        this.tableService.openWindowWithBackStack(
          NewDocumentsComponent,
          decision.type == 'first' ? 'New Contract' : 'Assign document to an existing contract',
          {
            component: EditEmployeeComponent,
            title: 'Employee Profile Information',
            elementId: this.employeeId,
            activeTab: EmployeeTab.DOCUMENTS,
          },
          {
            categoryToAssign: category,
            employeeId: this.employeeId,
            securityLevelId: this.securityLevelId,
            notRota: true,
            decisionType: decision.type,
            activeOnly: this.activeOnly,
          }
        );
      }
    })
  }
}
