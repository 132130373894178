import { Component, OnInit, Input, OnDestroy, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { NbPopoverDirective } from '@nebular/theme';
import { debounceTime } from 'rxjs/operators';
import { EmployeeTab } from '../../../../employess/employee-tab.enum';
import { AvatarResource } from '../../../../../utilities/avatar-resource.enum';
import { TableService } from '../../table.service';
import { EditEmployeeComponent } from '../../../../employess/edit-employee/edit-employee.component';
import { getFullName, getMinutes, getTime } from '../../../../../utilities/utils';
import { TableFieldType } from '../only-table.component';
import { AwardsSummaryModel } from '../../../../../shared/employess/awards/models/awards-summary.interfaces';

const DEBOUNCE_TIME = 500;
@Component({
  selector: 'ngx-awards-only-table',
  templateUrl: './awards-only-table.component.html',
  styleUrls: ['../only-table.component.scss'],
})
export class AwardsOnlyTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('table', { static: false }) table: MatTable<any>;

  @Input() data: any = [];
  @Input() columns: any = {};
  @Input() onRowClick: (event: any) => any;
  @Input() employeeDetailsTab: EmployeeTab = EmployeeTab.INFO;
  @Input() filter: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Input() thinner: boolean = false;
  @Input() totalRow: boolean = false;
  @Input() showIndexColumn: boolean = true;
  @Input() sortFromBackend: boolean = false;
  @Input() disableSorting: boolean = false;
  @Input() doubleHeader: boolean = false;
  @Input() doubleHeaderColumns: string;
  @Input() tableDataSummary: AwardsSummaryModel[];
  @Output() textfieldChange = new EventEmitter();
  @Output() matSortChange = new EventEmitter();
  @ViewChild(NbPopoverDirective, { static: false }) popover;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public AvatarResource: typeof AvatarResource = AvatarResource;
  public displayedColumns: string[] = [];
  public dataSource: MatTableDataSource<any>;
  public id: number = 0;
  public tempTextfieldValue: string;
  public isTextFieldToBeRestored: boolean = false;

  constructor(protected tableService: TableService) {}

  ngOnInit() {
    this.filter.pipe(debounceTime(DEBOUNCE_TIME)).subscribe((value: string) => {
      this.dataSource.filter = value.trim ? value.trim().toLowerCase() : '';
    });
    this.dataSource.sort = this.sort;
    if (this.sortFromBackend) {
      // this.dataSource.sortData = (data, sort) => {};
    }
  }

  ngOnChanges() {
    const columns = [];
    if (this.showIndexColumn) {
      columns.push('index');
    }

    for (const column of this.columns) {
      columns.push(column.fieldName);
    }
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.filterPredicate = (order: any, filter: string) => {
      const transformedFilter = filter.trim().toLowerCase();

      const listAsFlatString = (obj): string => {
        let returnVal = '';

        Object.values(obj).forEach((val) => {
          if (typeof val !== 'object') {
            returnVal = returnVal + ' ' + val;
          } else if (val !== null) {
            returnVal = returnVal + ' ' + listAsFlatString(val);
          }
        });

        return returnVal
          .trim()
          .toLowerCase()
          .replace(/ +(?= )/g, '');
      };

      return listAsFlatString(order).includes(transformedFilter);
    };

    this.displayedColumns = columns;

    this.dataSource.sort = this.sort;
    if (this.sortFromBackend) {
      // this.dataSource.sortData = (data, sort) => {};
    }
  }

  showEmployee360(event: any, employeeId: number, firstName: string, surname: string, fullName: string) {
    event.preventDefault();
    event.stopPropagation();
    this.tableService.openWindowWithoutCareHomeVerification(EditEmployeeComponent, `Employee Profile Information - ${fullName ? fullName : getFullName(firstName, surname)}`, {
      elementId: employeeId,
      activeTab: this.employeeDetailsTab,
    });
  }

  getSum(fieldName: any, fieldType: TableFieldType) {
    if (fieldType === TableFieldType.HOURS) {
      let minutesSum = 0;
      this.dataSource.filteredData.map((element: any) => {
        minutesSum += getMinutes(this.getTimeFromString(element[fieldName]));
      });
      return getTime(minutesSum);
    } else {
      return 0;
    }
  }

  trackByFn(index, item) {
    return index;
  }

  changeId(id: number) {
    this.id = id;
  }
  onTextFieldChange(event) {
    this.textfieldChange.emit(event);
    this.isTextFieldToBeRestored = false;
  }
  saveTextFieldContent(event) {
    this.tempTextfieldValue = event.target.value;
    this.isTextFieldToBeRestored = true;
  }
  restoreTextFieldContent(event) {
    if (!this.isTextFieldToBeRestored) {
      return;
    }
    event.target.value = this.tempTextfieldValue;
    this.isTextFieldToBeRestored = true;
  }

  public getSummaryDataInfo = (columnFieldName: string): string =>
    this.tableDataSummary[0][columnFieldName] !== null && this.tableDataSummary[0][columnFieldName] !== undefined
      ? `${this.tableDataSummary[0][columnFieldName]}-YES / ${this.tableDataSummary[1][columnFieldName]}-NO`
      : '';

  public getAteAwardInfo = (columnTitle: string): string[] => columnTitle.split('::');

  ngOnDestroy(): void {
    if (this.filter) {
      this.filter.unsubscribe();
    }
  }

  private getTimeFromString(element: string) {
    const time = element.split('(');

    if (time.length > 0) {
      const timePart = time[0].trim();
      return timePart;
    }

    return '';
  }
}
