export const errorMessages = [
    {
      field: 'name',
      errors: [
        {
          error: 'required',
          message: 'Name is required'
        },
      ]
    },
    {
      field: 'description',
      errors: [
        {
          error: 'required',
          message: 'Description is required'
        },
      ]
    },
    {
        field: 'typeId',
        errors: [
          {
            error: 'required',
            message: 'Role is required'
          },
        ]
      },
      {
        field: 'groupId',
        errors: [
          {
            error: 'required',
            message: 'Group is required'
          },
        ]
      },

      {
        field: 'copyFrom',
        errors: [
          {
            error: 'required',
            message: 'Copy from type is required'
          },
        ]
      },
      {
        field: 'copyTo',
        errors: [
          {
            error: 'required',
            message: 'Copy to type is required'
          },
        ]
      },
      {
        field: 'copyStuff',
        errors: [
          {
            error: 'required',
            message: 'Stuff to copy is required'
          },
        ]
      },
      {
        field: 'groupName',
        errors: [
          {
            error: 'required',
            message: 'Group name copy is required'
          },
        ]
      },
      {
        field: 'colorHash',
        errors: [
          {
            error: 'required',
            message: 'Color is required'
          },
        ]
      },
  ];
  