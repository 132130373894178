import { NgModule } from '@angular/core';
import { ThemeModule } from '../../../@theme/theme.module';
import { PagesSharedModule } from '../../shared/pages-shared.module';
import { OverviewComponent } from './overview.component';
import { OverviewCellComponent } from './overview-cell/overview-cell.component';
import { OverviewHeaderComponent } from './overview-header/overview-header.component';
import { OverviewRowComponent } from './overview-row/overview-row.component';
import { OverviewCalendarComponent } from './overview-calendar/overview-calendar.component';

@NgModule({
  imports: [
    ThemeModule,
    PagesSharedModule
  ],
  exports: [
    OverviewCalendarComponent,
    OverviewHeaderComponent,
    OverviewRowComponent,
    OverviewCellComponent
  ],
  declarations: [
    OverviewComponent,
    OverviewCalendarComponent,
    OverviewHeaderComponent,
    OverviewRowComponent,
    OverviewCellComponent
  ]
})
export class OverviewModule {}
