<nb-accordion [multi]="true">
  <nb-accordion-item *ngFor="let source of dataSources" expanded>
    <nb-accordion-item-header>
      <div class="dividing-header">Holiday Year: {{ source.hy }}</div>
    </nb-accordion-item-header>
    <nb-accordion-item-body>
      <table
        [nbTreeGrid]="source.dataSource"
        [nbSort]="source.dataSource"
        (sort)="updateSort($event)"
        class="events-table"
      >
        <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns"></tr>
        <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns"></tr>

        <ng-container [nbTreeGridColumnDef]="customColumn">
          <th
            nbTreeGridHeaderCell
            [nbSortHeader]="getSortDirection(customColumn)"
            *nbTreeGridHeaderCellDef
          >
            {{ columnMap.get(customColumn) }}
          </th>
          <ng-container *nbTreeGridCellDef="let row">
            <td nbTreeGridCell [ngClass]="{ subrow: row.data.isChildren }">
              <nb-fs-icon
                [children]="row.children"
                [expanded]="row.expanded"
              ></nb-fs-icon>
            </td>
          </ng-container>
        </ng-container>

        <ng-container
          *ngFor="let column of defaultColumns; let index = index"
          [nbTreeGridColumnDef]="column"
          [showOn]="getShowOn(index)"
        >
          <th
            nbTreeGridHeaderCell
            [nbSortHeader]="getSortDirection(column)"
            *nbTreeGridHeaderCellDef
          >
            {{ columnMap.get(column) }}
          </th>
          <ng-container *nbTreeGridCellDef="let row">
            <td
              nbTreeGridCell
              [ngClass]="{ subrow: row.data.isChildren }"
              (click)="
                row.data.isChildren && onRowClick ? onRowClick(row.data) : ''
              "
            >
              <ng-container *ngIf="row.data.isChildren">
                <ng-container [ngSwitch]="column">
                  <ng-container *ngSwitchCase="'contractRole'">
                    {{ row.data[column] || "-" }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'eventGivenHrs'">
                    {{ row.data[column] || "-" }}
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!row.data.isChildren">
                <ng-container [ngSwitch]="column">
                  <ng-container *ngSwitchCase="'eventDescription'">
                    <nb-icon
                      [nbTooltip]="row.data[column]"
                      class="description-icon"
                      icon="message-circle-outline"
                    ></nb-icon>
                  </ng-container>
                  <ng-container *ngSwitchCase="'eventGivenHrs'">
                    {{ row.data["eventSeriesHrsSum"] || "-" }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'contractRole'">
                    {{ getRolesText(row?.children) }}
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    {{ row.data[column] || "-" }}
                  </ng-container>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>
      </table>
    </nb-accordion-item-body>
  </nb-accordion-item>
</nb-accordion>
