import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FinanceTaskService {
  public saveData$ = new Subject<boolean>();
  public financeData$ = new Subject<{}>();
  public isValid$ = new Subject<boolean>();

  public reset() {
    this.saveData$ = new Subject<boolean>();
    this.financeData$ = new Subject<{}>();
    this.isValid$ = new Subject<boolean>();
  }
}
