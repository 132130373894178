import { Component, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-slider-switcher',
  styleUrls: ['./slider-switcher.component.scss'],
  templateUrl: './slider-switcher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderSwitcherComponent implements OnDestroy, OnInit {
  @Input() firstValue: any;
  @Input() secondValue: any;

  @Input() firstValueLabel: string;
  @Input() secondValueLabel: string;

  @Input() isDisabled: boolean = false;
  @Input() changeDefaultValue: boolean = false;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();

  ngOnInit() {
    if (this.changeDefaultValue) {
      this.changeValue();
    }
  }

  isFirstValue() {
    return this.value === this.firstValue;
  }

  isSecondValue() {
    return this.value === this.secondValue;
  }

  changeValue() {
    this.value = this.isFirstValue()
      ? this.secondValue
      : this.firstValue;

    this.valueChange.emit(this.value);
  }

  ngOnDestroy() {
    this.valueChange.complete();
  }
}
