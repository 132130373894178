import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule } from '@nebular/auth';
import { AuthService } from './auth/auth.service';
import { AuthUserService } from './data/auth-user.service';
import { PermissionsService } from './data/permissions.service';
import { CurrentCareHomeService } from './data/current-care-home.service';

export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({}).providers,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
  providers: [
    AuthService,
    AuthUserService,
    CurrentCareHomeService,
    PermissionsService
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
  }

  static forRoot() {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
