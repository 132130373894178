import { Injectable } from "@angular/core";
import { finalize, from, Observable, of, Subject, Subscription } from "rxjs";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import {
  CareHomesService,
  IUpdateCareHomeBody,
} from "../../../../../shared/care-homes/care-homes.service";
import { AuthUserService } from "../../../../../@core/data/auth-user.service";
import { catchError, concatMap, filter } from "rxjs/operators";
import { IDefaultValue } from "../../rota-table-week/interfaces/rota-location.interfaces";
import { Moment } from "moment";
import { QuestionDialogComponent } from "../../../../shared/question-dialog/question-dialog.component";
import { getErrorMessage } from "../../../../../utilities/utils";
import { ScheduleDayStatus } from "../../schedule-day-status.enum";

export const invalidStatusIds = [
  ScheduleDayStatus.ABSENT,
  ScheduleDayStatus.AL,
  ScheduleDayStatus.SICK,
  ScheduleDayStatus.TRAINING,
  ScheduleDayStatus.TRAINING_SICK,
  ScheduleDayStatus.TRAINING_AL,
  ScheduleDayStatus.NO_CONTRACT_END,
  ScheduleDayStatus.NO_CONTRACT_START,
];

@Injectable({
  providedIn: "root",
})
export class RotaSummaryService {
  public allowOverwriteAl = true;

  private subscription = new Subscription();

  public buttonsOpened = new Subject<string>();

  public reloadSummaryTable$ = new Subject<void>();
  public reloadTable$ = new Subject<void>();

  public updateHoursSummary(body: IUpdateHoursBody) {
    this.subscription.add(
      this.careHomesService
        .updateSchedulesEnhanced(this.authUserService.getCareHomeId(), body)
        .subscribe({
          next: () => {
            this.reloadSummaryTable$.next();
          },
          error: (err) => {
            if (!this.checkFor101(err)) {
              this.toastrService.danger(getErrorMessage(err), "Error", {
                duration: 60000,
                destroyByClick: true,
              });

              this.reloadSummaryTable$.next();
              return;
            }
            this.openDecisionDialog(
              err,
              body,
              this.careHomesService.updateSchedulesEnhanced.bind(
                this.careHomesService,
              ),
            );
          },
        }),
    );
  }

  public updateConfirmedSummary(body: IConfirmHoursBody) {
    this.subscription.add(
      this.careHomesService
        .updateConfirmedEnhanced(this.authUserService.getCareHomeId(), body)
        .subscribe({
          next: () => {
            this.reloadSummaryTable$.next();
          },
          error: (err) => {
            if (!this.checkFor101(err)) {
              this.toastrService.danger(getErrorMessage(err), "Error", {
                duration: 60000,
                destroyByClick: true,
              });

              this.reloadSummaryTable$.next();
              return;
            }

            this.openDecisionDialog(
              err,
              body,
              this.careHomesService.updateConfirmedEnhanced.bind(
                this.careHomesService,
              ),
            );
          },
        }),
    );
  }

  public deleteShift(shift, dayDate: string) {
    this.subscription.add(
      this.careHomesService
        .deleteScheduleEnhanced(
          this.authUserService.getCareHomeId(),
          shift.rotaUid,
          dayDate,
        )
        .subscribe({
          next: () => {
            this.reloadSummaryTable$.next();
          },
          error: (err) => {
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
          },
        }),
    );
  }

  public updateHoursRotaMaster(body: IUpdateHoursBody, reload: boolean = true) {
    this.subscription.add(
      this.careHomesService
        .updateSchedulesEnhanced(this.authUserService.getCareHomeId(), body)
        .subscribe({
          next: () => {
            if (reload) this.reloadTable$.next();
          },
          error: (err) => {
            if (!this.checkFor101(err)) {
              this.toastrService.danger(getErrorMessage(err), "Error", {
                duration: 60000,
                destroyByClick: true,
              });
              return;
            }
            this.openDecisionDialog(
              err,
              body,
              this.careHomesService.updateSchedulesEnhanced.bind(
                this.careHomesService,
              ),
            );
          },
        }),
    );
  }

  public updateConfirmedHoursRotaMaster(
    body: IConfirmHoursBody,
    reload: boolean = true,
  ) {
    this.subscription.add(
      this.careHomesService
        .updateConfirmedEnhanced(this.authUserService.getCareHomeId(), body)
        .subscribe({
          next: () => {
            if (reload) this.reloadTable$.next();
          },
          error: (err) => {
            if (!this.checkFor101(err)) {
              this.toastrService.danger(getErrorMessage(err), "Error", {
                duration: 60000,
                destroyByClick: true,
              });
              return;
            }

            this.openDecisionDialog(
              err,
              body,
              this.careHomesService.updateConfirmedEnhanced.bind(
                this.careHomesService,
              ),
            );
          },
        }),
    );
  }

  public changeDayOrEmployee(
    body: IUpdateHoursBody,
    rotaUid: string,
    dayDate: string,
    reload: boolean = true,
  ) {
    this.subscription.add(
      this.careHomesService
        .updateSchedulesEnhanced(this.authUserService.getCareHomeId(), body)
        .pipe(
          catchError((err) => {
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
            return null;
          }),
          concatMap((val) => {
            if (!val) return of(null);

            return this.careHomesService.deleteScheduleEnhanced(
              this.authUserService.getCareHomeId(),
              rotaUid,
              dayDate,
            );
          }),
        )
        .subscribe({
          next: () => {
            if (reload) this.reloadTable$.next();
          },
        }),
    );
  }

  public confirmCell(body: IConfirmHoursBody) {
    return this.careHomesService.updateConfirmedEnhanced(
      this.authUserService.getCareHomeId(),
      body,
    );
  }

  public deleteCell(rotaUid: string, dayDate: string) {
    return this.careHomesService.deleteScheduleEnhanced(
      this.authUserService.getCareHomeId(),
      rotaUid,
      dayDate,
    );
  }

  public checkFor101(err) {
    if (!err.error.errors || err.error.errors.length === 0) return false;
    return !!err.error.errors.find((err) => err.extendandErrorCodeId === 101);
  }

  public openDecisionDialog(
    err,
    body: IUpdateHoursBody | IConfirmHoursBody,
    method: (careHomeId: number, body: any) => Observable<any>,
  ) {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: "Overwrite schedule",
          message: getErrorMessage(err),
          okLabel: "Overwrite",
          cancelLabel: "Cancel",
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (!decision) return;

        body.forceOverwrite = true;

        method(this.authUserService.getCareHomeId(), body).subscribe({
          next: (result) => {
            this.toastrService.success(result.message, {
              duration: 60000,
              destroyByClick: true,
            });
            this.reloadTable$.next();
            this.reloadSummaryTable$.next();
          },
          error: (err) => {
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
            this.reloadTable$.next();
            this.reloadSummaryTable$.next();
          },
        });
      });
  }

  public updateMultiSiteCareHomeId(
    roleId: number,
    employeeId: number,
    weekStartDate: Moment,
    ids: IDefaultValue[],
    reload = true,
  ) {
    from(ids)
      .pipe(
        concatMap((id, index) => {
          if (!id.isEdited) return of(null);

          const body: IUpdateCareHomeBody = {
            careHomeId: id.id,
            rotaUid: id.rotaUid,
            dayName: id.dayName,
            rotaDate: weekStartDate.add(index).toISOString().slice(0, 10),
            employeeId: employeeId,
            roleId: roleId,
          };

          return this.careHomesService
            .updateMultisiteCareHomeEnhanced(
              this.authUserService.getCareHomeId(),
              body,
            )
            .pipe(
              catchError((err) => {
                this.toastrService.danger(getErrorMessage(err), "Error", {
                  duration: 60000,
                  destroyByClick: true,
                });
                return of(null);
              }),
            );
        }),
        filter((result) => result !== null),
      )
      .subscribe((result: any) => {
        if (reload) this.reloadTable$.next();

        this.toastrService.success(result.message, {
          duration: 60000,
          destroyByClick: true,
        });
      });
  }

  constructor(
    private toastrService: NbToastrService,
    private careHomesService: CareHomesService,
    private authUserService: AuthUserService,
    private dialogService: NbDialogService,
  ) {}
}

export interface IUpdateHoursBody {
  roleId: number;
  employeeId: number;
  rotaDate: string;
  scheduleFrom: string;
  scheduleTo: string;
  careHomeId: number;
  rotaUid?: string;
  isExtrasToBeTaken?: boolean;
  isExtrasPublished?: boolean;
  isScheduleMode?: boolean;
  forceOverwrite?: boolean;
  isOverwriteAl?: boolean;
}

export interface IConfirmHoursBody {
  confirmedFrom: string;
  confirmedTo: string;
  contractId: number;
  dayName: string;
  employeeId: number;
  isConfirm: boolean;
  roleId: number;
  rotaDate: string;
  weekStartAt: string;
  careHomeId: number;
  rotaUid?: string;
  forceOverwrite?: boolean;
  isOverwriteAl?: boolean;
}
