export const errorMessages = [
  {
    field: 'replacementId',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'replacementFrom',
    errors: [
      {
        error: 'invalidDate',
        message: 'Invalid date'
      },
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'replacementTo',
    errors: [
      {
        error: 'invalidDate',
        message: 'Invalid date'
      },
      {
        error: 'dateSmallerThan',
        message: 'Maximum 7 days forward'
      },
      {
        error: 'dateGreaterOrEqualThan',
        message: 'Cannot be earlier than start date'
      },
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'dailyHrs',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'dailyHrs',
        message: 'Must be written in h:mm format'
      }
    ]
  },
  {
    field: 'comments',
    errors: [
      {
        error: 'maxlength',
        message: 'Maximum 5000 characters'
      }
    ]
  }
];
