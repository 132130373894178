<div>
  <p class="label">{{label}}</p>
  <ngx-switcher
    *ngIf="!disabled && !noSelectAll"
    [firstValue]="false"
    [secondValue]="true"
    [firstValueLabel]="'Select all'"
    [secondValueLabel]="''"
    [value]="selectAll"
    (valueChange)="toggleSelectAll($event)"
  ></ngx-switcher>
  <ngx-switcher
    *ngIf="!disabled && values1.length > 0"
    [firstValue]="false"
    [secondValue]="true"
    [firstValueLabel]="values1Label"
    [secondValueLabel]="''"
    [value]="values1Selected"
    (valueChange)="toggleSelectValues($event, values1, 'values1Selected')"
  ></ngx-switcher>
  <ngx-switcher
    *ngIf="!disabled && values2.length > 0"
    [firstValue]="false"
    [secondValue]="true"
    [firstValueLabel]="values2Label"
    [secondValueLabel]="''"
    [value]="values2Selected"
    (valueChange)="toggleSelectValues($event, values2, 'values2Selected')"
  ></ngx-switcher>
  <ngx-switcher
    *ngIf="!disabled && values3.length > 0"
    [firstValue]="false"
    [secondValue]="true"
    [firstValueLabel]="values3Label"
    [secondValueLabel]="''"
    [value]="values3Selected"
    (valueChange)="toggleSelectValues($event, values3, 'values3Selected')"
  ></ngx-switcher>
</div>
<mat-form-field class="users-chips">
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let value of selectedValues"
      (removed)="remove(value)">
      {{value.fullName}}
      <i *ngIf="!disabled" class="nb-close" aria-hidden="true" matChipRemove></i>
    </mat-chip>
    <input
      [placeholder]="placeholder"
      #textInput
      [formControl]="inputControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [disabled]="disabled">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of filteredValues | async" [value]="item">
      {{item.fullName}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>