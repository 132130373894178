import { Component, forwardRef, OnChanges, Input, ViewChild, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { CroppieOptions, ResultOptions } from 'croppie';
import { NbToastrService } from '@nebular/theme';
import { NgxSuperCroppieComponent } from "ngx-super-croppie";

const MAX_FILE_SIZE = 1048576 * 10; // 1 MB
@Component({
  selector: 'ngx-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageEditorComponent),
      multi: true
    }
  ]
})
export class ImageEditorComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input()
  public imgCropToHeight = '160';
  @Input()
  public imgCropToWidth = '160';
  @Input()
  public imageData: any;
  @Input()
  public disableAddButton = false;
  @Input()
  upload: (event: any) => any;
  @Input()
  imageOnly: boolean;

  @Input() isAgency = false;

  public image: any;

  public resultOptions: ResultOptions = {
    type: 'blob',
    size: 'viewport',
    format: 'png',
    quality: 1,
    circle: true,
  };

  @ViewChild('ngxSuperCroppie')
  ngxSuperCroppie: NgxSuperCroppieComponent;

  public url: string | ArrayBuffer;
  public points: number[] = [0, 0, 0, 0];
  public orientation = 1;
  public zoom = 0;
  public croppedImage: string | HTMLElement | Blob | HTMLCanvasElement;

  constructor(private toastrService: NbToastrService) {}

  ngOnInit(): void {
    this.url = null;
  }

  ngOnChanges(changes: any) {
    this.loadImage();
    if (this.croppedImage) {
      return;
    }

    if (!changes.imageUrl) {
      return;
    }
    if (!changes.imageUrl.previousValue && changes.imageUrl.currentValue) {
      this.croppedImage = changes.imageUrl.currentValue;
      this.propagateChange(this.croppedImage);
    }
  }

  private loadImage() {
    if (this.imageData && this.imageData.size) {
      const reader = new FileReader();
      reader.readAsDataURL(this.imageData);
      reader.onloadend = () => {
        this.image = reader.result;
      };
    }
  }

  public updateCroppedImage(
    crop: string | HTMLElement | Blob | HTMLCanvasElement,
  ): void {
    this.croppedImage = crop;
    this.imageData = crop
    this.setZoom()
    this.propagateChange(this.croppedImage);
  }

  /* Options for croppie, you can learn more about this here -> https://foliotek.github.io/Croppie/ */
  public get croppieOptions(): CroppieOptions {
    const opts: CroppieOptions = {};
    opts.viewport = {
      width: parseInt(this.imgCropToWidth, 10),
      height: parseInt(this.imgCropToHeight, 10),
      type: 'circle'
    };
    opts.boundary = {
      width: 160,
      height: 160
    };
    opts.enforceBoundary = true;
    opts.showZoomer = true;

    return opts;
  }

  /* Event to be activated when you select an image */
  imageUploadEvent(evt: any) {
    if (!evt.target) {
      return;
    }
    if (!evt.target.files) {
      return;
    }

    if (evt.target.files.length !== 1) {
      return;
    }

    const file = evt.target.files[0];
    if (
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png' &&
      file.type !== 'image/gif' &&
      file.type !== 'image/jpg'
    ) {
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      this.toastrService.danger('File is too big. Maxismusm size is: ' + this.formatSizeUnits(MAX_FILE_SIZE) + ' your file is: ' + this.formatSizeUnits(file.size) , 'Error', { duration: 60000, destroyByClick: true });
    } else {
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.onloadend = () => {
        this.url = fr.result;
        this.croppedImage = fr.result.toString()
      };

    }

  }

  public get(): any {
    const data = this.ngxSuperCroppie.url
  }

  public destroy(): void {
    this.ngxSuperCroppie.destroy();
  }

  public rotate(): void {
    this.ngxSuperCroppie.rotate(90);
  }

  public setZoom(): void {
    this.ngxSuperCroppie.setZoom(0);
  }

  /* Takes the value  */
  writeValue(value: any) {
    if (value !== undefined) {
      this.croppedImage = value;
      this.propagateChange(this.croppedImage);
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

   formatSizeUnits(bytes: number): string {
    if      (bytes >= 1073741824) { return (bytes / 1073741824).toFixed(2) + " GB"; }
    else if (bytes >= 1048576)    { return (bytes / 1048576).toFixed(2) + " MB"; }
    else if (bytes >= 1024)       { return (bytes / 1024).toFixed(2) + " KB"; }
    else if (bytes > 1)           { return bytes + " bytes"; }
    else if (bytes == 1)          { return bytes + " byte"; }
    else                          { return "0 bytes"; }
  }

  registerOnTouched() {}
}

