import {Component, Input} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'ngx-ui-table-editable-hours-cell',
  templateUrl: './ui-table-editable-hours-cell.component.html',
  styleUrls: ['./ui-table-editable-hours-cell.component.scss'],
})
export class UiTableEditableHoursCellComponent {
  @Input() cellForm: UntypedFormGroup;

  get hourFrom(): AbstractControl {
    return this.cellForm.get('from');
  }

  get hourTo(): AbstractControl {
    return this.cellForm.get('to');
  }

  public updateFromIfNecessary(value: string) {
    if (this.hourFrom.value !== value) {
      this.cellForm.patchValue({ from: value })
    }
  }

  public updateToIfNecessary(value: string) {
    if (this.hourTo.value !== value) {
      this.cellForm.patchValue({ to: value })
    }
  }
}
