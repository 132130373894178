<div class="form-view">
  <ngx-flash-loader *ngIf="isDataLoading"></ngx-flash-loader>
  <ng-container *ngIf="dataLoaded">
    <div
      *ngIf="isMissingRequiredDocuments || !utils.isFormValid(form)"
      class="employee-status"
      (click)="openErrorTab(utils.isFormValid(form))"
    >
      <span *ngIf="isMissingRequiredDocuments && utils.isFormValid(form)"
        >This person cannot be activated yet. They are missing mandatory
        documents. See the Documents tab.</span
      >
      <span *ngIf="!utils.isFormValid(form)"
        >Can't save, missing required information, click to see details</span>
    </div>
  </ng-container>
  <main>
    <form [formGroup]="form" autocomplete="off" class="thin-echo-scrollbar edit-employee">
      <div class="edit-employee__avatar">
        <div>
          <ngx-image-editor
          formControlName="avatar"
          [isAgency]="isAgency"
          [imageData]="profileAvatar"
          [disableAddButton]="!permissions.MENU_EMPLOYEES"
        >
        </ngx-image-editor>

        <div class="form-control-group">
          <label class="label" for="input-status">Status:</label>
          <select
            nbInput
            fullWidth
            formControlName="statusId"
            name="input-status"
            id="input-status"
          >
            <option
              *ngFor="let status of statusesToChange"
              [ngValue]="status.id"
            >
              {{ status.wordShortName }}
            </option>
          </select>
          <p class="error-message">
            {{
              utils.handleValidationErrorMessage(
                form,
                "statusId",
                errorMessages
              )
            }}
          </p>
        </div>
        </div>

        <div class="edit-employee__buttons">
          <button
            class="btn btn-success"
            (click)="saveEmployee()"
            [disabled]="!utils.isFormValid(form) || !permissions.MENU_EMPLOYEES"
          >
            <i class="nb-checkmark mr-2" aria-hidden="true"></i> Save
          </button>
          <button class="btn btn-cancel" (click)="closeWindow()">
            <i class="nb-close mr-2" aria-hidden="true"></i> Close
          </button>
          <div class="show-options">
            <button class="btn btn-info" (click)="showOptionsTooltip = !showOptionsTooltip">
              <nb-icon icon="more-vertical-outline" class="mr-2"></nb-icon> Options
            </button>
            <div *ngIf="showOptionsTooltip" class="popup-for-options">
              <ul class="options-tooltip">
                <li>
                  <ngx-switcher
                    [firstValue]="false"
                    [secondValue]="true"
                    [firstValueLabel]="''"
                    [secondValueLabel]="'Agency worker'"
                    [value]="isAgency"
                    (valueChange)="toggleAgency($event)"
                  >
                  </ngx-switcher>
                </li>
                <li title="download NSPF01" (click)="downloadNspf01()">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="-97 220.9 400 400" style="enable-background:new -97 220.9 400 400;" xml:space="preserve">
               <style type="text/css">
                 .st0{fill:#000000;}
               </style>
               <title>Artboard 170</title>
               <path class="st0" d="M13.5,551.1H-2V290.7h117.8v80.6h80.6v66.5h12.4v-76.4l-85.7-83.1H-14.4v285.2h27.9V551.1z M128.2,300.5
                 l60.2,58.4h-60.2V300.5z"/>
               <g>
                 <path class="st0" d="M99.8,496.9c0,10.1-3.2,17.9-9.5,23.3c-6.3,5.4-15.4,8.1-27,8.1h-8.6v33.5h-20v-94.3h30.1
                   c11.4,0,20.1,2.5,26.1,7.4C96.8,479.8,99.8,487.1,99.8,496.9z M54.6,511.8h6.6c6.1,0,10.7-1.2,13.8-3.6c3.1-2.4,4.6-6,4.6-10.6
                   c0-4.7-1.3-8.1-3.8-10.4c-2.6-2.2-6.6-3.4-12-3.4h-9.1V511.8z"/>
                 <path class="st0" d="M195.8,513.7c0,15.5-4.4,27.4-13.2,35.7c-8.8,8.3-21.6,12.4-38.3,12.4h-26.7v-94.3h29.6
                   c15.4,0,27.3,4.1,35.8,12.2C191.5,487.8,195.8,499.1,195.8,513.7z M175,514.2c0-20.2-8.9-30.4-26.8-30.4h-10.6v61.4h8.6
                   C165.4,545.2,175,534.9,175,514.2z"/>
                 <path class="st0" d="M235,561.7h-19.7v-94.3h54v16.4H235v24.3h32v16.3h-32V561.7z"/>
               </g>
               </svg>
                  PDF</li>
                <li (click)="openTestShiftMigration()" class="with-icon">
                  <i class="nb-checkmark" aria-hidden="true"></i> Shift migration
                </li>
                <li class="red"
                (click)="permissions.MENU_EMPLOYEES && deleteEmployee()"><i class="nb-trash" aria-hidden="true"></i> Delete employee</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="edit-employee__tabs">
      <nb-tabset #tabset id="employee-tabset" (changeTab)="onTabChange($event)" class="echo-tabset">
        <nb-tab id="general-tab" tabTitle="General">
          <div class="flex-container-horizontal margin-bottom">
            <section class="form-fields">
              <div class="flex align-center justify-content-between edit-employee__basic-info-label">
                <h2>Account information</h2>
                <div class="flex align-center flex--column-gap-standard">
                  <div
                    *ngIf="statusIdControl?.value === employeeStatusType.IN_TRAINING"
                    class="mb-2 cursor--pointer mr-2 flex flex--column-gap-2"
                    (click)="openTrainingEditComponentWithSingleBackstack(false, false, true)"
                  >
                    <div class="flex justify-content-between flex--column-gap-4">
                      <span>Training budget: </span>
                      <span
                        class="font-weight-bold"
                        [ngClass]="isTrainingBudgetNegative() ? 'text-color-danger' : 'text-color-green'"
                      >
                        {{employee?.trainingBudget || 0}}
                      </span>
                    </div>
                    <div class="flex justify-content-between flex--column-gap-4">
                      <span>Confirmed or scheduled: </span>
                      <span
                        class="font-weight-bold"
                        [ngClass]="isUsedTrainingBudget() ? 'text-color-danger' : 'text-color-green'"
                      >
                        {{employee?.usedTrainingBudget || 0}}
                      </span>
                    </div>
                    <div class="flex justify-content-between flex--column-gap-4">
                      <span>Unused: </span>
                      <span
                        class="font-weight-bold"
                        [ngClass]="isRemainingTrainingBudget() ? 'text-color-danger' : 'text-color-green'"
                      >
                        {{employee?.remainingTrainingBudget || 0}}
                      </span>
                    </div>
                  </div>
                  <!-- <mat-checkbox
                    *ngIf="statusIdControl?.value !== employeeStatusType.IN_TRAINING"
                    color="primary"
                    formControlName="isAgency"
                    class="edit-employee__is-agency"
                    labelPosition="before"
                  >
                    Agency worker
                  </mat-checkbox> -->
                </div>
              </div>
              <div class="grid-wrapper">
                <div class="form-control-group id box start-1 end-3">
                  <label class="label" for="input-employee-id">ID</label>
                  <input
                    nbInput
                    fullWidth
                    formControlName="id"
                    name="employeeId"
                    id="input-employee-id"
                    placeholder="ID"
                    maxlength="50"
                    autofocus
                  />
                  <p class="error-message">
                    {{
                      utils.handleValidationErrorMessage(form, "id", errorMessages)
                    }}
                  </p>
                </div>
                <div class="form-control-group box start-3 end-5">
                  <label class="label" for="input-employee-id"
                    >Security level</label
                  >
                  <select
                    nbInput
                    fullWidth
                    formControlName="levelId"
                    name="level-id"
                  >
                    <option
                      *ngFor="let level of securityLevels"
                      [ngValue]="level.id"
                    >
                      {{ level.wordShortName }}
                    </option>
                  </select>
                  <p class="error-message">
                    {{
                      utils.handleValidationErrorMessage(
                        form,
                        "levelId",
                        errorMessages
                      )
                    }}
                  </p>
                </div>
                <!-- <div class="form-control-group box start-5 end-9">
                  <label class="label" for="input-status">Agency worker</label>
                  <select
                    nbInput
                    fullWidth
                    formControlName="isAgency"
                    name="input-agency"
                    id="input-agency"
                  >
                    <option
                      *ngFor="let status of checkboxStatuses"
                      [ngValue]="status.value"
                    >
                      {{ status.label }}
                    </option>
                  </select>
                  <p class="error-message">
                    {{
                      utils.handleValidationErrorMessage(
                        form,
                        "statusId",
                        errorMessages
                      )
                    }}
                  </p>
                </div> -->
                <div class="form-control-group box start-5 end-9">
                  <label class="label" for="input-date-first-joined"
                    >Date First Joined <span class="required">*</span></label
                  >
                  <input
                    nbInput
                    fullWidth
                    formControlName="dateFirstJoined"
                    name="dateFirstJoined"
                    id="input-date-first-joined"
                    [placeholder]="DFJplaceholder"
                    [matDatepicker]="dateFirstJoinedDatepicker"
                    minlength="3"
                    maxlength="50"
                    autofocus
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dateFirstJoinedDatepicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker
                    #dateFirstJoinedDatepicker
                    startView="month"
                  ></mat-datepicker>
                  <p class="error-message">
                    {{
                      utils.handleValidationErrorMessage(
                        form,
                        "dateFirstJoined",
                        errorMessages
                      )
                    }}
                  </p>
                </div>
                <div class="form-control-group box start-9 end-13">
                  <div>
                    <label class="label">Connected user account</label>
                    <div class="flex">
                      <input
                        nbInput
                        fullWidth
                        formControlName="employeeUserLogin"
                        placeholder="none"
                        maxlength="50"
                        autofocus
                      />
                      <button
                        *ngIf="employeeUserId"
                        class="btn btn-info"
                        (click)="editUserAccount()"
                      >
                        <nb-icon icon="edit-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-control-group box start-13 end-17">
                  <ng-container *ngIf="dataLoaded">
                    <button
                      *ngIf="employeeUserId && !employee.isPortableOnly"
                      class="btn btn-info mt-3 button-text btn-full-width"
                      (click)="resetPassword(false)"
                    >
                    <nb-icon icon="paper-plane-outline" class="mr-1"></nb-icon>Reset password & invite
                    </button>
                    <button
                      *ngIf="employeeUserId && employee.isPortableOnly"
                      class="btn btn-warning mt-3 button-text btn-full-width"
                      (click)="resetPassword(true)"
                    >
                    <nb-icon icon="paper-plane-outline" class="mr-1"></nb-icon>Reset password & invite
                    </button>
                    <button
                      *ngIf="!employeeUserId"
                      class="blinking-button btn btn-success mt-3 btn-full-width"
                      (click)="createUserAccount()"
                      [nbPopover]="'please remember to create user account'"
                      nbPopoverTrigger="hover"
                    >
                      <nb-icon icon="plus-circle-outline" class="mr-1"></nb-icon>
                      Create user account
                    </button>
                  </ng-container>
                </div>
              </div>
              <div class="grid-wrapper">
                <div class="form-control-group box start-1 end-5">
                  <label class="label" for="input-title">Title <span class="required">*</span></label>
                  <select
                    nbInput
                    formControlName="title"
                    name="title"
                    id="input-title"
                  >
                    <option *ngFor="let title of titles" [ngValue]="title.id">
                      {{ title.wordFullName }}
                    </option>
                  </select>
                  <p class="error-message">
                    {{
                      utils.handleValidationErrorMessage(
                        form,
                        "title",
                        errorMessages
                      )
                    }}
                  </p>
                </div>
                <div class="form-control-group box start-5 end-9">
                  <label class="label" for="input-first-name">First Name <span class="required">*</span></label>
                  <input
                    nbInput
                    fullWidth
                    formControlName="firstName"
                    name="firstName"
                    id="input-first-name"
                    placeholder="First Name"
                    minlength="3"
                    maxlength="50"
                    autofocus
                  />
                  <p class="error-message">
                    {{
                      utils.handleValidationErrorMessage(
                        form,
                        "firstName",
                        errorMessages
                      )
                    }}
                  </p>
                </div>
                <div class="form-control-group box start-9 end-13">
                  <label class="label" for="input-surname">Surname <span class="required">*</span></label>
                  <input
                    nbInput
                    fullWidth
                    formControlName="surname"
                    name="surname"
                    id="input-surname"
                    placeholder="Last Name"
                    minlength="3"
                    maxlength="50"
                    autofocus
                  />
                  <p class="error-message">
                    {{
                      utils.handleValidationErrorMessage(
                        form,
                        "surname",
                        errorMessages
                      )
                    }}
                  </p>
                </div>
                <div class="form-control-group box start-13 end-17">
                  <label class="label" for="input-date-of-birth"
                    >Date of Birth <span class="required">*</span></label
                  >
                  <input
                    nbInput
                    fullWidth
                    formControlName="dateOfBirth"
                    name="dateOfBirth"
                    id="input-date-of-birth"
                    placeholder="DD/MM/YYYY"
                    [matDatepicker]="dateOfBirthDatepicker"
                    [min]="minDate"
                    [max]="maxDate"
                    minlength="3"
                    maxlength="50"
                    autofocus
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dateOfBirthDatepicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker
                    #dateOfBirthDatepicker
                    startView="month"
                  ></mat-datepicker>
                  <p class="warning-message">
                    {{
                      utils.handleAdultWarningMessage(form, "dateOfBirth")
                        ? "Employee is underage"
                        : null
                    }}
                  </p>
                  <p class="error-message">
                    {{
                      utils.handleValidationErrorMessage(
                        form,
                        "dateOfBirth",
                        errorMessages
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="form-view-grid">
                <h2>General notes</h2>
                <div class="grid-wrapper">
                  <div
                    class="form-control-group form-control-group--textarea box start-1 end-17"
                  >
                    <textarea
                      nbInput
                      fullWidth
                      formControlName="description"
                      name="description"
                      id="description"
                      placeholder="Add notes about this employee"
                      maxlength="5000"
                      autofocus
                    ></textarea>
                    <p class="error-message">
                      {{
                        utils.handleValidationErrorMessage(
                          form,
                          "description",
                          errorMessages
                        )
                      }}
                    </p>
                  </div>
                  <!-- <div class="form-control-group box start-9 end-13">
                    <div>
                      <label class="label">Connected user account</label>
                      <div class="flex">
                        <input
                          nbInput
                          fullWidth
                          formControlName="employeeUserLogin"
                          placeholder="none"
                          maxlength="50"
                          class="mr-1"
                          autofocus
                        />
                        <button
                          *ngIf="employeeUserId"
                          class="btn btn-info"
                          (click)="editUserAccount()"
                        >
                          <nb-icon icon="edit-outline"></nb-icon>
                        </button>
                      </div>
                    </div>
                    <ng-container *ngIf="dataLoaded">
                      <button
                        *ngIf="employeeUserId && !employee.isPortableOnly"
                        class="btn btn-info mt-3 button-text"
                        (click)="resetPassword(false)"
                      >
                        Reset password & send invitation desktop
                      </button>
                      <button
                        *ngIf="employeeUserId && employee.isPortableOnly"
                        class="btn btn-warning mt-3 button-text"
                        (click)="resetPassword(true)"
                      >
                        Reset password & send invitation portable
                      </button>
                      <button
                        *ngIf="!employeeUserId"
                        class="blinking-button btn btn-success mt-3"
                        (click)="createUserAccount()"
                        [nbPopover]="'please remember to create user account'"
                        nbPopoverTrigger="hover"
                      >
                        <nb-icon icon="plus-circle-outline" class="mr-2"></nb-icon>
                        Create user account
                      </button>
                    </ng-container>
                  </div> -->
                </div>
              </div>
              <div class="form-view-grid" style="margin-top: 40px">
                <h2>Contracts</h2>
                  <div class="grid-wrapper">
                    <div class="form-control-group box start-1 end-17">
                      <div class="contract-filters">
                        <div class="form-control-group filter">
                          <label class="label" for="input-date">&nbsp;</label>
                          <input
                            nbInput
                            fullWidth
                            placeholder="Filter..."
                            name="filter"
                            (keyup)="applyFilter($event.target.value)"
                          />
                        </div>
                        <div class="display-flex display-flex--between contracts-buttons">
                          <button class="btn btn-info" (click)="refresh('general')">
                            <i class="nb-loop" aria-hidden="true"></i>
                          </button>
                          <button
                            class="btn btn-success"
                            type="button"
                            [disabled]="careHomeId === 0 || !permissions.NEW_CONTRACTS"
                            (click)="addContract()"
                          >
                            <i class="nb-checkmark" aria-hidden="true"></i> Add Contract
                          </button>
                        </div>
                        <div id="contracts-filter">
                          <ngx-switcher
                            [firstValue]="TableFilter.ALL"
                            [secondValue]="TableFilter.ACTIVE_ONLY"
                            [firstValueLabel]="'All'"
                            [secondValueLabel]="'Active Only'"
                            [value]="activeContractsTableFilter"
                            (valueChange)="toogleContractsTableFilter($event)"
                          ></ngx-switcher>
                        </div>
                      </div>
                      <div class="contracts-general-container thin-echo-scrollbar">
                        <ngx-only-table
                          [data]="contracts"
                          [thinner]="true"
                          [onRowClick]="editContract"
                          [columns]="contractColumns"
                          [filter]="onFilterChange$"
                        >
                      </ngx-only-table>
                      </div>
                    </div>
                  </div>
              </div>
            </section>
          </div>
        </nb-tab>
        <nb-tab #informationTab id="information-tab" tabTitle="Information">
          <section>
            <h2>Additional information</h2>
            <div class="flex-container-horizontal">
              <div
                class="form-control-group form-control-group--national-insurance-number"
              >
                <label class="label" for="input-national-insurance-number"
                  >National Insurance Number <span class="required">*</span></label
                >
                <input
                  nbInput
                  fullWidth
                  formControlName="nationalInsuranceNumber"
                  name="nationalInsuranceNumber"
                  id="input-national-insurance-number"
                  placeholder="National Insurance Number"
                  minlength="3"
                  maxlength="9"
                  autofocus
                />
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "nationalInsuranceNumber",
                      errorMessages
                    )
                  }}
                </p>
              </div>
              <div class="form-control-group">
                <label class="label" for="input-passport"
                  >Passport Number</label
                >
                <input
                  nbInput
                  fullWidth
                  formControlName="passportNumber"
                  name="passportNumber"
                  id="input-passport-number"
                  placeholder="Passport Number"
                  minlength="3"
                  maxlength="50"
                  autofocus
                />
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "passportNumber",
                      errorMessages
                    )
                  }}
                </p>
              </div>
              <div class="form-control-group">
                <label class="label" for="input-gender">Gender <span class="required">*</span></label>
                <select
                  nbInput
                  formControlName="gender"
                  name="gender"
                  id="input-gender"
                >
                  <option *ngFor="let gender of genders" [ngValue]="gender.id">
                    {{ gender.wordFullName }}
                  </option>
                </select>
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "gender",
                      errorMessages
                    )
                  }}
                </p>
              </div>
              <div class="form-control-group">
                <label class="label" for="input-marital-status"
                  >Marital Status <span class="required">*</span></label
                >
                <select
                  nbInput
                  formControlName="maritalStatus"
                  name="maritalStatus"
                  id="input-marital-status"
                >
                  <option
                    *ngFor="let maritalStatus of maritalStatuses"
                    [ngValue]="maritalStatus.id"
                  >
                    {{ maritalStatus.wordFullName }}
                  </option>
                </select>
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "maritalStatus",
                      errorMessages
                    )
                  }}
                </p>
              </div>
            </div>
            <div class="flex-container-horizontal">
              <div class="form-control-group">
                <label class="label label--with-icons" for="input-phone-number"
                  ><span>Telephone Number (mobile) <span class="required">*</span></span>
                  <div class="switch-icons">
                    <nb-icon class="switch-icon switch-icon--visible" (click)="togglehideMobileTelephoneNumber(true)" *ngIf="form.get('hideMobileTelephoneNumber').value === false" icon="eye-outline"></nb-icon>
                    <nb-icon class="switch-icon" (click)="togglehideMobileTelephoneNumber(false)" *ngIf="form.get('hideMobileTelephoneNumber').value === true" icon="eye-off-outline"></nb-icon>
                  </div>
                  </label>
                <input
                  nbInput
                  fullWidth
                  formControlName="phoneNumber"
                  name="phoneNumber"
                  id="input-phone-number"
                  placeholder="Telephone Number (mobile)"
                  minlength="3"
                  maxlength="25"
                  autofocus
                  autocomplete="none"
                />
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "phoneNumber",
                      errorMessages
                    )
                  }}
                </p>
              </div>
              <div class="form-control-group">
                <!-- <label class="label" for="input-second-phone-number"
                  >Telephone Number (landline) <span class="required">*</span></label
                > -->
                <label class="label label--with-icons" for="input-second-phone-number"
                ><span>Telephone Number (landline) <span class="required">*</span></span>
                  <div class="switch-icons">
                    <nb-icon class="switch-icon switch-icon--visible" (click)="togglehideTelephoneNumber(true)" *ngIf="form.get('hideTelephoneNumber').value === false" icon="eye-outline"></nb-icon>
                    <nb-icon class="switch-icon" (click)="togglehideTelephoneNumber(false)" *ngIf="form.get('hideTelephoneNumber').value === true" icon="eye-off-outline"></nb-icon>
                  </div>
                </label>
                <input
                  nbInput
                  fullWidth
                  formControlName="secondPhoneNumber"
                  name="secondPhoneNumber"
                  id="input-second-phone-number"
                  placeholder="Telephone Number (landline)"
                  minlength="3"
                  maxlength="25"
                  autofocus
                  autocomplete="none"
                />
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "secondPhoneNumber",
                      errorMessages
                    )
                  }}
                </p>
              </div>
              <div class="form-control-group">
                <!-- <label class="label" for="input-email">Email address <span class="required">*</span></label> -->
                <label class="label label--with-icons" for="input-email"
                ><span>Email address <span class="required">*</span></span>
                  <div class="switch-icons">
                    <nb-icon class="switch-icon switch-icon--visible" (click)="togglehideEmail(true)" *ngIf="form.get('hideEmail').value === false" icon="eye-outline"></nb-icon>
                    <nb-icon class="switch-icon" (click)="togglehideEmail(false)" *ngIf="form.get('hideEmail').value === true" icon="eye-off-outline"></nb-icon>
                  </div>
                </label>
                <input
                  nbInput
                  fullWidth
                  formControlName="email"
                  name="email"
                  id="input-email"
                  placeholder="Email address"
                  minlength="3"
                  maxlength="50"
                  autofocus
                  autocomplete="none"
                />
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "email",
                      errorMessages
                    )
                  }}
                </p>
              </div>
              <div class="form-control-group">
                <label class="label" for="input-receiveEmail"
                  >Receive emails from Echo email</label
                >
                <input
                  nbInput
                  fullWidth
                  formControlName="receiveEmail"
                  name="receiveEmail"
                  id="input-receiveEmail"
                  placeholder="Email address"
                  minlength="3"
                  maxlength="50"
                  autofocus
                  autocomplete="none"
                />
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "receiveEmail",
                      errorMessages
                    )
                  }}
                </p>
              </div>
            </div>
            <div class="flex-container-horizontal">
              <div class="form-control-group">
                <label class="label" for="input-statement"
                  >Employee Statement <span class="required">*</span></label
                >
                <select
                  nbInput
                  formControlName="employeeStatementId"
                  name="employeeStatementId"
                  id="input-statement"
                >
                  <option
                    *ngFor="let statement of employeeStatements"
                    [ngValue]="statement.id"
                  >
                    {{ statement.wordFullName }}
                  </option>
                </select>
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "employeeStatementId",
                      errorMessages
                    )
                  }}
                </p>
              </div>
            </div>
            <!-- <div class="flex-container-horizontal">
              <div class="form-control-group hide-switcher">
                <label>Hide mobile telephone number</label>
                <ngx-switcher
                  [firstValue]="false"
                  [secondValue]="true"
                  [firstValueLabel]="'No'"
                  [secondValueLabel]="'Yes'"
                  [value]="hideMobileTelephoneNumber"
                  (valueChange)="togglehideMobileTelephoneNumber($event)"
                  [isDisabled]="!permissions.MENU_EMPLOYEES"
                >
                </ngx-switcher>
              </div>
              <div class="form-control-group hide-switcher">
                <label>Hide telephone number</label>
                <ngx-switcher
                  [firstValue]="false"
                  [secondValue]="true"
                  [firstValueLabel]="'No'"
                  [secondValueLabel]="'Yes'"
                  [value]="hideTelephoneNumber"
                  (valueChange)="togglehideTelephoneNumber($event)"
                  [isDisabled]="!permissions.MENU_EMPLOYEES"
                >
                </ngx-switcher>
              </div>
              <div class="form-control-group hide-switcher">
                <label>Hide email address</label>
                <ngx-switcher
                  [firstValue]="false"
                  [secondValue]="true"
                  [firstValueLabel]="'No'"
                  [secondValueLabel]="'Yes'"
                  [value]="hideEmail"
                  (valueChange)="togglehideEmail($event)"
                  [isDisabled]="!permissions.MENU_EMPLOYEES"
                >
                </ngx-switcher>
              </div>
              <div class="form-control-group"></div>
            </div> -->
            <!-- <div class="flex-container-horizontal">
              <div class="form-control-group form-control-group--student-loan">
                <div class="form-control-group">
                  <label>Student loan to be repaid</label>
                  <ngx-switcher
                    [firstValue]="false"
                    [secondValue]="true"
                    [firstValueLabel]="'No'"
                    [secondValueLabel]="'Yes'"
                    [value]="studentLoan"
                    [isDisabled]="!permissions.MENU_EMPLOYEES"
                    (valueChange)="toggleStudentLoan($event)"
                  ></ngx-switcher>
                </div>
                <div class="form-control-group" *ngIf="studentLoan">
                  <label class="label" for="input-student-loan-type"
                    >Student Loan Types <span class="required">*</span></label
                  >
                  <select
                    nbInput
                    formControlName="studentLoanTypeId"
                    name="studentLoanTypeId"
                    id="input-student-loan-type"
                  >
                    <option
                      *ngFor="let type of studentLoanTypes"
                      [ngValue]="type.id"
                    >
                      {{ type.wordFullName }}
                    </option>
                  </select>
                  <p class="error-message">
                    {{
                      utils.handleValidationErrorMessage(
                        form,
                        "studentLoanTypeId",
                        errorMessages
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="form-control-group">
                <label class="label" for="input-p45">P45 <span class="required">*</span></label>
                <select
                  nbInput
                  formControlName="p45Id"
                  name="p45Id"
                  id="input-p45"
                >
                  <option *ngFor="let p45 of p45Values" [ngValue]="p45.id">
                    {{ p45.wordFullName }}
                  </option>
                </select>
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "p45Id",
                      errorMessages
                    )
                  }}
                </p>
              </div>
              <div class="form-control-group">
                <label class="label" for="input-statement"
                  >Employee Statement <span class="required">*</span></label
                >
                <select
                  nbInput
                  formControlName="employeeStatementId"
                  name="employeeStatementId"
                  id="input-statement"
                >
                  <option
                    *ngFor="let statement of employeeStatements"
                    [ngValue]="statement.id"
                  >
                    {{ statement.wordFullName }}
                  </option>
                </select>
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "employeeStatementId",
                      errorMessages
                    )
                  }}
                </p>
              </div>
            </div> -->
            <h2>Emergency Contacts</h2>
            <div class="flex-container-horizontal">
              <div class="form-control-group">
                <label class="label" for="emergencyNameFirst"
                >First contact name  <span class="required">*</span></label
                >
                <input
                  nbInput
                  fullWidth
                  formControlName="emergencyNameFirst"
                  name="emergencyNameFirst"
                  id="emergencyNameFirst"
                  placeholder="Name and surname"
                  minlength="3"
                  maxlength="25"
                  autofocus
                  autocomplete="none"
                />
                <p class="error-message">
                  {{
                  utils.handleValidationErrorMessage(
                    form,
                    "emergencyNameFirst",
                    errorMessages
                  )
                  }}
                </p>
              </div>
              <div class="form-control-group form-control-group--wide">
                <label class="label" for="emergencyRelationFirst"
                >Relation <span class="required">*</span></label
                >
                <input
                  nbInput
                  fullWidth
                  formControlName="emergencyRelationFirst"
                  name="emergencyRelationFirst"
                  id="emergencyRelationFirst"
                  placeholder="How this person is related"
                  minlength="3"
                  maxlength="25"
                  autofocus
                />
                <p class="error-message">
                  {{
                  utils.handleValidationErrorMessage(
                    form,
                    "emergencyRelationFirst",
                    errorMessages
                  )
                  }}
                </p>
              </div>
              <div class="form-control-group">
                <label class="label" for="emergencyPhoneFirst"
                >Emergency phone number <span class="required">*</span></label
                >
                <input
                  nbInput
                  fullWidth
                  formControlName="emergencyPhoneFirst"
                  name="emergencyPhoneFirst"
                  id="emergencyPhoneFirst"
                  placeholder="Emergency phone number"
                  minlength="6"
                  maxlength="20"
                  autofocus
                />
                <p class="error-message">
                  {{
                  utils.handleValidationErrorMessage(
                    form,
                    "emergencyPhoneFirst",
                    errorMessages
                  )
                  }}
                </p>
              </div>
            </div>
            <div class="flex-container-horizontal">
              <div class="form-control-group">
                <label class="label" for="emergencyNameSecond"
                >Second contact name </label
                >
                <input
                  nbInput
                  fullWidth
                  formControlName="emergencyNameSecond"
                  name="emergencyNameSecond"
                  id="emergencyNameSecond"
                  placeholder="Name and surname"
                  minlength="3"
                  maxlength="25"
                  autofocus
                  autocomplete="none"
                />
                <p class="error-message">
                  {{
                  utils.handleValidationErrorMessage(
                    form,
                    "emergencyNameSecond",
                    errorMessages
                  )
                  }}
                </p>
              </div>
              <div class="form-control-group form-control-group--wide">
                <label class="label" for="emergencyRelationSecond"
                >Relation</label
                >
                <input
                  nbInput
                  fullWidth
                  formControlName="emergencyRelationSecond"
                  name="emergencyRelationSecond"
                  id="emergencyRelationSecond"
                  placeholder="How this person is related"
                  minlength="3"
                  maxlength="25"
                  autofocus
                />
                <p class="error-message">
                  {{
                  utils.handleValidationErrorMessage(
                    form,
                    "emergencyRelationSecond",
                    errorMessages
                  )
                  }}
                </p>
              </div>
              <div class="form-control-group">
                <label class="label" for="emergencyPhoneSecond"
                >Emergency phone number</label
                >
                <input
                  nbInput
                  fullWidth
                  formControlName="emergencyPhoneSecond"
                  name="emergencyPhoneSecond"
                  id="emergencyPhoneSecond"
                  placeholder="Emergency phone number"
                  minlength="6"
                  maxlength="20"
                  autofocus
                />
                <p class="error-message">
                  {{
                  utils.handleValidationErrorMessage(
                    form,
                    "emergencyPhoneSecond",
                    errorMessages
                  )
                  }}
                </p>
              </div>
            </div>
            <h2>Address information</h2>
            <div class="flex-container-horizontal">
              <div class="form-control-group">
                <label class="label" for="input-house-number"
                  >House Number <span class="required">*</span></label
                >
                <input
                  nbInput
                  fullWidth
                  formControlName="houseNumber"
                  name="houseNumber"
                  id="input-house-number"
                  placeholder="House Number"
                  minlength="1"
                  maxlength="20"
                  autofocus
                  autocomplete="none"
                />
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "houseNumber",
                      errorMessages
                    )
                  }}
                </p>
              </div>
              <div class="form-control-group">
                <label class="label" for="input-street">Street <span class="required">*</span></label>
                <input
                  nbInput
                  fullWidth
                  formControlName="street"
                  name="street"
                  id="input-street"
                  placeholder="Street"
                  minlength="1"
                  maxlength="50"
                  autofocus
                  autocomplete="none"
                />
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "street",
                      errorMessages
                    )
                  }}
                </p>
              </div>
              <div class="form-control-group">
                <label class="label" for="input-city">City <span class="required">*</span></label>
                <input
                  nbInput
                  fullWidth
                  formControlName="city"
                  name="city"
                  id="input-city"
                  placeholder="City"
                  minlength="3"
                  maxlength="50"
                  autofocus
                  autocomplete="none"
                />
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "city",
                      errorMessages
                    )
                  }}
                </p>
              </div>
              <!-- <div class="form-control-group form-control-group--small">
                <label class="label" for="input-state">Country <span class="required">*</span></label>
                <input
                  nbInput
                  fullWidth
                  formControlName="state"
                  name="state"
                  id="input-state"
                  placeholder="State"
                  minlength="3"
                  maxlength="50"
                  autofocus
                  autocomplete="none"
                />
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "state",
                      errorMessages
                    )
                  }}
                </p>
              </div> -->
              <div class="form-control-group">
                <label class="label" for="input-zip-code">Postal Code <span class="required">*</span></label>
                <input
                  nbInput
                  fullWidth
                  formControlName="zipCode"
                  name="zipCode"
                  id="input-zip-code"
                  placeholder="Postal Code"
                  minlength="3"
                  maxlength="10"
                  autofocus
                  autocomplete="none"
                />
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "zipCode",
                      errorMessages
                    )
                  }}
                </p>
              </div>
            </div>
            <h2>Payroll information</h2>
            <div class="flex-container-horizontal">
              <div class="form-control-group form-control-group--with-select">
                <label class="label" for="student-loan">Student loan to be repaid</label>
                <select
                  nbInput
                  fullWidth
                  formControlName="studentLoan"
                  [disabled]="!permissions.MENU_EMPLOYEES"
                  (change)="toggleStudentLoan($event)"
                  name="student-loan"
                  id="student-loan"
                >
                  <option
                    *ngFor="let status of checkboxStatuses"
                    [ngValue]="status.value"
                  >
                    {{ status.label }}
                  </option>
                </select>
                <p class="error-message">
                </p>
              </div>
              <div class="form-control-group">
                <label class="label" for="input-student-loan-type"
                  >Student Loan Types <span class="required">*</span></label
                >
                <select
                  nbInput
                  formControlName="studentLoanTypeId"
                  [ngClass]="{'disabled': !studentLoan }"
                  name="studentLoanTypeId"
                  id="input-student-loan-type"
                >
                  <ng-container *ngIf="studentLoan">
                    <option
                    *ngFor="let type of studentLoanTypes"
                    [ngValue]="type.id"
                  >
                    {{ type.wordFullName }}
                  </option>
                  </ng-container>
                </select>
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "studentLoanTypeId",
                      errorMessages
                    )
                  }}
                </p>
              </div>
              <div class="form-control-group form-control-group--50">
                <label class="label" for="input-p45">P45 <span class="required">*</span></label>
                <select
                  nbInput
                  formControlName="p45Id"
                  name="p45Id"
                  id="input-p45"
                >
                  <option *ngFor="let p45 of p45Values" [ngValue]="p45.id">
                    {{ p45.wordFullName }}
                  </option>
                </select>
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "p45Id",
                      errorMessages
                    )
                  }}
                </p>
              </div>
            </div>
            <div class="flex-container-horizontal">
              <div class="form-control-group form-control-group--wide">
                <label class="label" for="input-bank-account-number"
                  >Bank Account Number <span class="required">*</span></label
                >
                <input
                  nbInput
                  fullWidth
                  formControlName="bankAccountNumber"
                  name="bankAccountNumber"
                  id="input-bank-account-number"
                  placeholder="Bank Account Number"
                  minlength="3"
                  maxlength="25"
                  autofocus
                  autocomplete="none"
                />
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "bankAccountNumber",
                      errorMessages
                    )
                  }}
                </p>
              </div>
              <div class="form-control-group">
                <label class="label" for="input-bank-sort-code"
                  >Bank Sort Code <span class="required">*</span></label
                >
                <input
                  nbInput
                  fullWidth
                  formControlName="bankSortCode"
                  name="bankSortCode"
                  id="input-bank-sort-code"
                  placeholder="Bank Sort Code"
                  minlength="3"
                  maxlength="25"
                  autofocus
                />
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "bankSortCode",
                      errorMessages
                    )
                  }}
                </p>
              </div>
              <div class="form-control-group">
                <label class="label" for="input-building-society-routing-number"
                  >Building Society Routing Number</label
                >
                <input
                  nbInput
                  fullWidth
                  formControlName="buildingSocietyRoutingNumber"
                  name="buildingSocietyRoutingNumber"
                  id="input-building-society-routing-number"
                  placeholder="Building Society Routing Number"
                  minlength="3"
                  maxlength="50"
                  autofocus
                />
                <p class="error-message">
                  {{
                    utils.handleValidationErrorMessage(
                      form,
                      "buildingSocietyRoutingNumber",
                      errorMessages
                    )
                  }}
                </p>
              </div>
            </div>
          </section>
        </nb-tab>
        <!-- <nb-tab
          tabTitle="Contracts"
          [active]="activeTab === EmployeeTab.CONTRACTS"
        >
          <div id="contracts-filter">
            <ngx-switcher
              [firstValue]="TableFilter.ALL"
              [secondValue]="TableFilter.ACTIVE_ONLY"
              [firstValueLabel]="'All'"
              [secondValueLabel]="'Active Only'"
              [value]="activeContractsTableFilter"
              (valueChange)="toogleContractsTableFilter($event)"
            ></ngx-switcher>
          </div>
          <div class="form-control-group filter">
            <label class="label" for="input-date">&nbsp;</label>
            <input
              nbInput
              fullWidth
              placeholder="Filter..."
              name="filter"
              (keyup)="applyFilter($event.target.value)"
            />
          </div>
          <ngx-only-table
            [data]="contracts"
            [thinner]="true"
            [onRowClick]="editContract"
            [columns]="contractColumns"
            [filter]="onFilterChange$"
          >
          </ngx-only-table>

          <div class="display-flex display-flex--between margin-top">
            <button class="btn btn-info" (click)="refresh('contracts')">
              <i class="nb-loop" aria-hidden="true"></i>
            </button>
            <button
              class="btn btn-success"
              type="button"
              [disabled]="careHomeId === 0 || !permissions.NEW_CONTRACTS"
              (click)="addContract()"
            >
              <i class="nb-checkmark" aria-hidden="true"></i> Add Contract
            </button>
          </div>
        </nb-tab> -->
        <nb-tab tabTitle="Events" [active]="activeTab === EmployeeTab.EVENTS">
          <div id="contracts-filter">
            <div>
              <nb-checkbox formControlName="isSicknessEventOnly"
                >Sickness only</nb-checkbox
              >
              <nb-checkbox formControlName="isAlEventOnly"
                >Annual leave only</nb-checkbox
              >
            </div>
            <ngx-switcher
              class="switcher-container"
              [firstValue]="TableFilter.ALL"
              [secondValue]="TableFilter.ACTIVE_ONLY"
              [firstValueLabel]="'All'"
              [secondValueLabel]="'Active Only'"
              [value]="activeEventsTableFilter"
              (valueChange)="toogleEventsTableFilter($event)"
            ></ngx-switcher>
          </div>
          <div class="form-control-group filter">
            <label class="label" for="input-date">&nbsp;</label>
            <input
              nbInput
              fullWidth
              placeholder="Filter..."
              name="filter"
              (keyup)="applyFilter($event.target.value)"
            />
          </div>
          <ngx-events-table [onRowClick]="editEvent" [data]="events" [filter]="onFilterChange$"></ngx-events-table>
          <div class="display-flex display-flex--between margin-top">
            <button class="btn btn-info" (click)="refresh('events')">
              <i class="nb-loop" aria-hidden="true"></i>
            </button>
            <div class="display-flex">
              <button
                class="btn btn-success"
                type="button"
                [disabled]="
                  careHomeId === 0 ||
                  !permissions.MENU_SICKNESS
                "
                (click)="addSickness()"
              >
                <i class="nb-checkmark" aria-hidden="true"></i> Add Sickness
              </button>
              <button
                class="btn btn-success ml-4"
                type="button"
                [disabled]="
                  careHomeId === 0 ||
                  !permissions.MENU_ANNUAL_LEAVES
                "
                (click)="addAnnualLeaving()"
              >
                <i class="nb-checkmark" aria-hidden="true"></i> Add Annual Leave
              </button>
            </div>
          </div>
          <div class="margin-top explain-al-text" [innerHTML]="eventsText">
          </div>
        </nb-tab>
        <nb-tab tabTitle="Courses" [active]="activeTab === EmployeeTab.COURSES">
          <div class="form-control-group filter">
            <label class="label" for="input-date">&nbsp;</label>
            <input
              nbInput
              fullWidth
              placeholder="Filter..."
              name="filter"
              (keyup)="applyFilter($event.target.value)"
            />
          </div>
          <ngx-only-table
            [data]="courses"
            [thinner]="true"
            [columns]="coursesColumns"
            [filter]="onFilterChange$"
          >
          </ngx-only-table>
          <div class="display-flex display-flex--between margin-top">
            <button class="btn btn-info" (click)="refresh('courses')">
              <i class="nb-loop" aria-hidden="true"></i>
            </button>
          </div>
        </nb-tab>
        <nb-tab tabTitle="ROTA" [active]="activeTab === EmployeeTab.SCHEDULE">
          <div class="form-control-group filter">
            <label class="label" for="input-date">&nbsp;</label>
            <input
              nbInput
              fullWidth
              placeholder="Filter..."
              name="filter"
              (keyup)="applyFilter($event.target.value, 'schedule')"
            />
          </div>
          <ngx-only-table
            [data]="schedule"
            [sortFromBackend]="true"
            (matSortChange)="updatePagination($event, 'schedule', true)"
            [thinner]="true"
            [columns]="scheduleColumns"
            [filter]="onFilterChange$"
          >
          </ngx-only-table>
          <div
            class="display-flex display-flex--between margin-top align-center"
          >
            <button class="btn btn-info" (click)="refresh('schedule')">
              <i class="nb-loop" aria-hidden="true"></i>
            </button>
            <mat-paginator
              class="pagination"
              [length]="totalRows"
              (page)="updatePagination($event, 'schedule')"
              [pageSizeOptions]="[50, 100, 150, 200]"
            >
            </mat-paginator>
          </div>
        </nb-tab>
        <nb-tab
          tabTitle="Payroll"
          [active]="activeTab === EmployeeTab.PAYROLL"
        >
          <div class="form-control-group filter">
            <label class="label" for="input-date">&nbsp;</label>
            <input
              nbInput
              fullWidth
              placeholder="Filter..."
              name="filter"
              (keyup)="applyFilter($event.target.value, 'payroll')"
            />
          </div>
          <ngx-only-table
            [data]="payroll"
            [thinner]="true"
            [columns]="payrollColumns"
            [onRowClick]="editPayroll"
            [filter]="onFilterChange$"
          >
          </ngx-only-table>
          <div class="display-flex display-flex--between margin-top">
            <button
              class="btn btn-info"
              (click)="refresh('payroll adjustments')"
            >
              <i class="nb-loop" aria-hidden="true"></i>
            </button>
          </div>
        </nb-tab>
        <nb-tab
          *ngIf="false"
          tabTitle="Finance"
          [active]="activeTab === EmployeeTab.FINANCE"
        >
          <div class="form-control-group filter">
            <label class="label" for="input-date">&nbsp;</label>
            <input
              nbInput
              fullWidth
              fullWidth
              placeholder="Filter..."
              name="filter"
              (keyup)="applyFilter($event.target.value, 'finance')"
            />
          </div>
          <ngx-only-table
            [data]="finance"
            [thinner]="true"
            [columns]="financeColumns"
            [filter]="onFilterChange$"
          >
          </ngx-only-table>
          <div class="display-flex display-flex--between margin-top">
            <button class="btn btn-info" (click)="refresh('finance')">
              <i class="nb-loop" aria-hidden="true"></i>
            </button>
          </div>
        </nb-tab>
        <nb-tab
          #documentsTab
          tabTitle="Documents"
          [active]="activeTab === EmployeeTab.DOCUMENTS"
          *ngIf="!noAccessToDocuments"
          [disabled]="!permissions.EMPLOYEE_DOCUMENTS"
        >
          <ngx-employee-file-upload
            [categoriesWithFiles]="categoriesWithFiles"
            [activeOnly]="activeContractsTableFilter == TableFilter.ACTIVE_ONLY"
            [employeeDateFirstJoined]="employee ? employee.dateFirstJoined : ''"
            (onUpload)="uploadDocument($event)"
            (onDelete)="deleteDocument($event)"
            (onDownload)="downloadDocument($event)"
            (descriptionUpdate)="uploadDocumentDescription($event)"
          >
          </ngx-employee-file-upload>
        </nb-tab>
        <nb-tab
          tabTitle="Messages"
          *ngIf="!noAccessToDocuments"
        >
          <div [style.minHeight.px]="minHeightTab">
          <ngx-inbox-list
            *ngIf="employeeId != 0 && activeTab == EmployeeTab.MESSAGES"
            [isMessagesReport]="true"
            [multiselectorVisible]="false"
            [userId]="employeeUserId"
            [noLoadOnStart]="false"
            [showPinned]="true"
            [is360]="true"
            [heightPx]="minHeightTab"
          ></ngx-inbox-list>
          </div>
        </nb-tab>
        <nb-tab
          *ngIf="showLeavingTab"
          tabTitle="Leaving"
          badgeText="IMPORTANT"
          badgePosition="top right"
          badgeStatus="danger"
          [active]="activeTab === EmployeeTab.LEAVING"
        >
          <ngx-employee-left
            [disableForm]="leavingCardDisableForm"
            [cardMode]="true"
            (cancelEvent)="cancelLeavingEvent($event)"
          >
          </ngx-employee-left>
        </nb-tab>
        <nb-tab
          *ngIf="employeeActivations.length > 0"
          tabTitle="Activation"
          [active]="activeTab === EmployeeTab.ACTIVATION"
        >
          <nb-accordion multi>
            <nb-accordion-item
              *ngFor="let activation of employeeActivations"
              class="mb-1"
            >
              <nb-accordion-item-header class="accordeon-header">
                <p>
                  Activated by: {{ activation.activateBy }} on
                  {{ activation.activationDate }}. From status:
                  <b class="text-color-green">{{ activation.fromStatus }}</b>
                  to: <b class="text-color-green">{{ activation.toStatus }}</b>
                </p>
              </nb-accordion-item-header>
              <nb-accordion-item-body>
                <ngx-only-table
                  [data]="activation.checklist"
                  [thinner]="true"
                  [columns]="activationColumns"
                >
                </ngx-only-table>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>
        </nb-tab>
        <nb-tab *ngIf="isUserHaveMultiSiteAdminPermission" tabTitle="Multi site" [active]="activeTab === EmployeeTab.MULTI_SITE_ROLES">
          <div class="edit migration-tool-container">
            <span class="migration-care-title">Pay center: </span>
            <span [class.margin-z-auto]="!permissions.EMPLOYEE_MIGRATION">{{ careHomeName }}</span>
            <i class="nb-edit"
               aria-hidden="true"
               (click)="migrateUser()"
               *ngIf="careHomeId !== 0 && permissions.EMPLOYEE_MIGRATION"
               [nbPopover]="'Migrate to another Care Home'"
               nbPopoverTrigger="hover"
            ></i>
          </div>
          <ngx-roles-multi-site *ngIf="isMultiSiteRolesTab && employeeUserId"
                                [employeeUserId]="employeeUserId"
                                [rolesMultiSiteForm]="rolesMultiSiteForm"
                                (onUserProfileRoles)="setUserProfileRoles($event)"
          ></ngx-roles-multi-site>
          <div class="edit-employee__error" *ngIf="!employeeUserId">
            <span>This user have no account</span>
          </div>
        </nb-tab>
        <nb-tab tabTitle="History" [active]="activeTab === EmployeeTab.HISTORY">
          <div class="form-control-group filter">
            <label class="label" for="input-date">&nbsp;</label>
            <input
              nbInput
              fullWidth
              placeholder="Filter..."
              name="filter"
              (keyup)="applyFilter($event.target.value)"
            />
          </div>
          <ngx-only-table
            [data]="histories"
            [thinner]="true"
            [columns]="historyColumns"
            [filter]="onFilterChange$"
          >
          </ngx-only-table>
        </nb-tab>
      </nb-tabset>
    </div>
    </form>
  </main>
  <!-- <footer class="space-between">
    <div class="flex">
      <button
        class="btn btn-success"
        (click)="saveEmployee()"
        [disabled]="!utils.isFormValid(form) || !permissions.MENU_EMPLOYEES"
      >
        <i class="nb-checkmark" aria-hidden="true"></i> Save
      </button>
      <button class="btn btn-danger ml-4" (click)="closeWindow()">
        <i class="nb-close" aria-hidden="true"></i> Close
      </button>
      <button class="btn btn-warning ml-4 w-auto" (click)="openTestShiftMigration()" *ngIf="this.testButtonActive">
        <i class="nb-checkmark" aria-hidden="true"></i>SHIFT MIGRATION
      </button>

    </div>
    <div class="flex">
      <button
        id="nspf01"
        title="download NSPF01"
        class="btn btn-info mr-4 report-icon"
        (click)="downloadNspf01()"
      >
        <img src="assets/images/nb-pdf.svg" />
      </button>
      <button
        id="delete-employee"
        class="btn btn-danger"
        [disabled]="!permissions.MENU_EMPLOYEES"
        (click)="deleteEmployee()"
      >
        <i class="nb-trash" aria-hidden="true"></i> Delete employee
      </button>
    </div>
  </footer> -->
</div>
