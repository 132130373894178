import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { AuthUserService } from '../../../@core/data/auth-user.service';
import { PermissionsService } from '../../../@core/data/permissions.service';
import { InboxService } from '../../../shared/inbox/inbox.service';
import { chunkArray, getErrorMessage, limitTo } from '../../../utilities/utils';
import { QuestionDialogComponent } from '../../shared/question-dialog/question-dialog.component';
import { TableService } from '../../shared/table/table.service';
import { MessagePreviewComponent } from '../message-preview/message-preview.component';
import { NoteColor } from './note-color.enum';

@Component({
  selector: 'ngx-noteboard',
  templateUrl: './noteboard.component.html',
  styleUrls: ['./noteboard.component.scss']
})
export class NoteboardComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public NoteColor: typeof NoteColor = NoteColor;
  public dataToShow = [];
  public permissions = [];

  ngOnInit() {
    this.load();
  }

  constructor(private dialogService: NbDialogService,
              private toastrService: NbToastrService,
              private inboxService: InboxService,
              private authUserService: AuthUserService,
              private permissionsService: PermissionsService,
              private dialog: MatDialog,
              private tableService: TableService) {
    this.permissions = this.permissionsService.getPermissions();
    this.subscription.add(this.tableService.refresh$.subscribe(() => {
      this.load();
    }));
  }

  load() {
    this.subscription.add(this.inboxService.getNoteboard(this.authUserService.getUser().id, this.authUserService.getCareHomeId())
    .subscribe((response: any) => {
      this.convertTable(response.result.noticeBoardList);
    }, (err: any) => {
      this.toastrService.danger(err);
    }))
  }

  convertTable(data: any = []) {
    this.dataToShow = [];
    data.forEach((category: any) => {
      this.dataToShow.push(category);
      category.list = chunkArray(category.messageList, 4);
    });
    console.log(this.dataToShow);
  }

  public readonly getTriangleColor = (color: any) => {
    let trinagleColor = '#e0e0e0';
    switch (color) {
      case NoteColor.GREEN: {
        trinagleColor = '#297e4b';
        break;
      }
      case NoteColor.BLUE: {
        trinagleColor = '#2a7cce';
        break;
      }
      case NoteColor.VIOLET: {
        trinagleColor = '#3f33ba';
        break;
      }
      case NoteColor.RED: {
        trinagleColor = '#bf2942';
        break;
      }
      case NoteColor.BLACK: {
        trinagleColor = '#2a2a2a';
        break;
      }
      case NoteColor.ORANGE: {
        trinagleColor = '#d18505';
        break;
      }
      case NoteColor.PINK: {
        trinagleColor = '#cf4d9b';
        break;
      }
    }
    return trinagleColor;
  }

  unpin(note: any) {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Unpin message',
          message: `Are you sure you want to unpin message from noticeboard?`,
          okLabel: 'Yes',
          cancelLabel: 'Cancel'
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          const data = {
            isPined: false,
            color: note.color + ''
          };
          this.subscription.add(this.inboxService.updateNote(this.authUserService.getUser().id, note.uid, data).subscribe((response: any) => {
            this.toastrService.success(response.message, 'Success');
            this.load();
          },
          (err) => {
            this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
          }));
        }
      });
  }

  public changeNoteColor(note: any, color: NoteColor) {
    const data = {
      isPined: true,
      color: color + ''
    };
    this.subscription.add(this.inboxService.updateNote(this.authUserService.getUser().id, note.uid, data).subscribe((response: any) => {
      this.toastrService.success(response.message, 'Success');
      this.load();
    },
    (err) => {
      this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
      note.color = color;
    }));
  }

  showMessage(note: any) {
    this.dialog.open(MessagePreviewComponent, {
      disableClose: true,
      data: {
        title: `${limitTo(note.title, 120)}`,
        elementId: note.messageUid,
        fromId: note.fromId,
        toId: note.toId,
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
