<div class="flex-container" [style.align-items]="'unset'" [style.width]="isMultiple ? 'calc(100% - 85px)' : 'calc(100% - 40px)'">
  <div *ngIf="editMode" [style.width.px]="680" [style.margin-right]="'auto'"  (click)="toggleAccordion.emit()">
    <textarea
      [disabled]="disabled"
      id="subtask-editable-title-edit-textarea"
      autosize [maxRows]="10"
      class="textarea-view highlight thin-echo-scrollbar"
      [ngStyle]="{'min-width': 'unset', 'max-width': 'unset'}"
      [(ngModel)]="title"
      (focusout)="save()"
      [innerHTML]="title"
      (keydown.space)="$event.stopPropagation()"
      (keydown.enter)="$event.stopPropagation()">
    </textarea>
  </div>
  <div
    *ngIf="!editMode"
    (click)="toggleAccordion.emit()"
    class="horizontal-task-title thin-echo-scrollbar"
    [id]="'subtask-editable-title-' + title.split(' ').join('')"
    [nbPopover]="subtaskColumnTitlePopover" nbPopoverTrigger="hover"
    [ngStyle]="{'min-width': 'unset', 'max-width': isMultiple ? '1400px' : '680px', 'white-space': 'unset', 'min-height': '20px'}"
  >{{title}}</div>
  <ng-template #subtaskColumnTitlePopover>
    <div (click)="toggleAccordion.emit()" class="subtask-title-popover">{{title}}</div>
  </ng-template>
  <ngx-progress-bar (click)="toggleAccordion.emit()" *ngIf="!isMultiple" [style.width.px]="300" [progressPercent]="subtaskPercent"></ngx-progress-bar>
  <div class="flex-center" [style.margin-bottom.rem]="0.4">
    <span *ngIf="!isFirst" (click)="!disabled && goUp()" class="icon" [ngClass]="{'disabled': disabled}"><nb-icon icon="arrow-ios-upward-outline"></nb-icon></span>
    <span *ngIf="!isLast" (click)="!disabled && goDown()" class="icon" [ngClass]="{'disabled': disabled}"><nb-icon icon="arrow-ios-downward-outline"></nb-icon></span>
    <span *ngIf="!editDisabled && !editMode" (click)="!disabled && edit()" class="icon" [ngClass]="{'disabled': disabled}"><i class="nb-edit" aria-hidden="true"></i></span>
    <!-- <span class="icon recurring-icon" [ngClass]="{'disabled': disabled}" (click)="makeRecurringTasks()"><nb-icon icon="flip-2-outline"></nb-icon></span> -->
    <span (click)="!disabled && deleteItem()" class="icon red" [ngClass]="{'disabled': isDeletedReadonly || disabled}"><i class="nb-trash" aria-hidden="true"></i></span>
  </div>
</div>
