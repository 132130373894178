import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { SignInOutReminderService } from "../../../shared/sign-in-out-reminder.service";
import { environment } from "../../../../environments/environment";
@Component({
  selector: "sign-in-out-reminder",
  templateUrl: "./sign-in-out-reminder.component.html",
  styleUrls: ["./sign-in-out-reminder.component.scss"],
  animations: [
    trigger("moveLeftAnimation", [
      state("void", style({ transform: "translateX(110%)" })),
      state("visible", style({ transform: "translateX(0)" })),
      transition("void => visible", [
        animate("600ms ease-out", style({ transform: "translateX(0)" })),
      ]),
      transition("visible => void", [
        animate("300ms ease-out", style({ transform: "translateX(110%)" })),
      ]),
    ]),
  ],
})
export class SignInOutReminderComponent implements OnInit {
  @Input() type: "sign-in" | "sign-out";
  @Input() shiftDate?: string | null = null;

  protected animationState: "void" | "visible" = "void";

  constructor(private signInService: SignInOutReminderService) {}

  close() {
    this.animationState = "void";
    setTimeout(() => {
      this.signInService.closeSignInOutReminder();
    }, 300);
  }

  navigateToSignIn() {
    this.signInService.navigateToSignIn(this.date);
    this.close();
  }

  ngOnInit(): void {
    this.animationState = "visible";
  }

  public get date(): string {
    return this.shiftDate === null
      ? new Date().toLocaleDateString("en-GB")
      : this.shiftDate;
  }
}
