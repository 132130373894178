import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CareHomesService } from '../../../../shared/care-homes/care-homes.service';
import { DictionariesService } from '../../../../shared/dictionaries/dictionaries.service';
import { RotaTableService } from '../../rota/rota-table.service';
import { Subscription } from 'rxjs';
import { EmployessService } from '../../../../shared/employess/employess.service';
import { TableService } from '../../../shared/table/table.service';
import { Router } from "@angular/router";
import { RotaMigrationService } from "../rota-migration.service";
import { getErrorMessage } from "../../../../utilities/utils";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import {
  IScheduleInfoList,
  IShiftMigrationApiResponse,
  IShiftMigrationData
} from "../../../shared/interfaces/shifts.interfaces";
import { QuestionDialogComponent } from '../../../shared/question-dialog/question-dialog.component';

@Component({
  selector: 'ngx-rota-migration',
  templateUrl: './rota-migration.component.html',
  styleUrls: ['./rota-migration.component.scss']
})
export class RotaMigrationComponent implements OnInit, OnDestroy {

  @Input() employeeId: any;
  @Output() closeWindow = new EventEmitter();
  public weeks: IScheduleInfoList[] = [];
  public homeId: number;
  public homeName: string;
  public isDataLoaded = false;
  public loadError = false;
  public errorMsg = '';
  public noDataToShow = false;
  public noDataMsg = 'There are no shifts to migrate';
  public noDataToMigrate: boolean = true;
  public noDataAvailable: boolean = false;
  public noDataAvaMsg = 'Nothing to amend. Window will close in ';

  private subscription: Subscription = new Subscription();
  secondsCount: number = 5;

  constructor(
    private careHomesService: CareHomesService,
    private employeeService: EmployessService,
    private dictionariesService: DictionariesService,
    private rotaTableService: RotaTableService,
    private rotaMigrationService: RotaMigrationService,
    private toastrService: NbToastrService,
    private tableService: TableService,
    private dialogService: NbDialogService,
    private router: Router,
  ) { }

  ngOnDestroy(): void {
    this.rotaTableService.resetService();
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.load();
  }

  load() {
    this.rotaMigrationService.clearChanges();
    this.isDataLoaded = false;
    this.loadError = false;
    this.employeeService.getMigrationShiftsData(this.employeeId).subscribe((response: IShiftMigrationApiResponse) => {
      if (Object.keys(response.result).length == 0) {
        this.isDataLoaded = true;
        this.noDataAvailable = true;
        this.countDownAndClose();
        return;
      }
      this.checkIfAnythingToMigrate(response.result);
      this.weeks = response.result.scheduleInfoList;
      this.homeId = response.result.careHomeId;
      this.homeName = response.result.careHomeName;
      this.isDataLoaded = true;
      this.noDataAvailable = false;
      this.noDataToShow = Object.keys(response.result).length == 0 || this.weeks.length == 0;
    }, (err) => {
      this.toastrService.danger(getErrorMessage(err), "Error", {
        duration: 60000,
        destroyByClick: true,
      });
      this.isDataLoaded = true;
      this.loadError = true;
      this.errorMsg = getErrorMessage(err);
    });
  }



  goToRota() {
    const urlPath = this.router.serializeUrl(this.router.createUrlTree(['/employess/rota']));
    window.open('#' + urlPath, '_blank');
  }

  clear() {
    this.subscription.add(
      this.dialogService
        .open(QuestionDialogComponent, {
          context: {
            title: "Reset shifts",
            message: `Are you sure to reset shifts? You'll loose all changes`,
            okLabel: "Yes",
            cancelLabel: "No",
          },
        })
        .onClose.subscribe((decision: boolean) => {
          if (decision) {
            this.load();
          }
        })
    );
  }

  countDownAndClose() {
    setInterval(() => {
      if (this.secondsCount == 1) {
        clearInterval();
        this.closeWindow.emit();
      }
      this.secondsCount--;
    }, 1000);
  }

  private checkIfAnythingToMigrate(migrationData: IShiftMigrationData) {
    const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    migrationData.scheduleInfoList.forEach(week => {
      week.scheduleWeekInfoDepList.forEach(contract => {
        weekdays.forEach(day => {
          if (contract[day].requireMigration) {
            this.noDataToMigrate = false;
          }
        });
      });
    });
  }
}


