<div class="form-view">
  <main>
    <div class="table-container">
      <ngx-taskboard-only-table-with-stats
          class="taskboard-container"
          id="taskboard-container"
          [dataStream]="dataStream"
          [backstackViewParams]="viewParams">
        </ngx-taskboard-only-table-with-stats>
    </div>
  </main>
  <footer class="space-between">
    <button (click)="closeWindow()" class="btn btn-danger">
      <i class="nb-close" aria-hidden="true"></i> Close
    </button>
  </footer>
</div>