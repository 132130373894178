import { Component, OnDestroy, Inject } from '@angular/core';
import { AuthService } from '../../../@core/auth/auth.service';
import { NbToastrService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ngx-request-pass',
  templateUrl: './request-pass.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class RequestPassComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();
  error: string = '';
  user: any = {};
  submitted: boolean = false;

  constructor(protected service: AuthService,
              protected toastrService: NbToastrService,
              @Inject(DOCUMENT) private document: Document) {}

  requestPass(): void {
    this.error = '';
    this.submitted = true;

    const data = {
      email: this.user.email,
      originUrl: this.document.location.origin
    };

    this.service.requestPassword(data)
      .subscribe(() => {
        this.submitted = false;
        this.toastrService.success('Please check your email', 'Link to reset password send sucessfully');
      },
      (err) => {
        this.submitted = false;
        this.error = err.error.errors[0].message;
        // this.toastrService.danger(this.error, 'Error', { duration: 60000, destroyByClick: true });
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
