<div class="table-fill">
  <div class="filters">
    <div class="form-control-group filter">
      <div>
        <input
          nbInput
          fullWidth
          placeholder="Filter..."
          name="filter"
          [(ngModel)]="currentFilter"
          class="mr-10"
          [disabled]="disabled"
          (keyup.enter)="applyFilter($event.target.value)"
          (keyup)="applyFilter($event.target.value, true)"
        />
      </div>
    </div>
  </div>
  <div *ngIf="!isDataLoaded" class="table-container">
    <div class="table-spinner-container">
      <div id="nb-global-spinner" class="spinner">
        <img src="./assets/images/echo_sygnet.svg" alt=""/>
      </div>
    </div>
  </div>
  <div
    class="table-container container-extended-filters"
    [style.display]="isDataLoaded ? 'block' : 'none'"
  >
    <ngx-task-history-only-table
      (matSortChange)="usePagination ? updatePagination($event, true) : null"
      [data]="data"
      [columns]="columns"
      [sortFromBackend]="sortFromBackend"
      [sortFromBackend]="true"
      [filter]="onFilterChange$"
    >
    </ngx-task-history-only-table>
  </div>
  <div class="button-container">
    <div class="flex"></div>
    <div class="flex middle">
      <mat-paginator
        (page)="updatePagination($event)"
        class="pagination"
        [length]="totalRows"
        [pageSizeOptions]="paginationSizeOptions"
      >
      </mat-paginator>
      <button class="btn btn-info" (click)="reload()">
        <i class="nb-loop" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</div>
