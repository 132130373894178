import { Component, OnInit, ViewChild, OnDestroy, Input, OnChanges, ElementRef, EventEmitter, Output } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { BehaviorSubject, Observable, Subscription, finalize } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { LoadingStatus } from '../loading-status.enum';
import { FastEditorComponent } from '../../task-management/tasks/fast-editor/fast-editor.component';
import { TaskTab } from '../../task-management/tasks/task-tab.enum';
import { TableService } from '../table/table.service';
import { TasksService } from '../../../shared/tasks/tasks.service';
import { EditTaskComponent } from '../../task-management/tasks/edit-task/edit-task.component';
import { FormMode } from '../../../utilities/form-mode.enum';
import { getErrorMessage, isFunction } from '../../../utilities/utils';
import { DictionariesService } from '../../../shared/dictionaries/dictionaries.service';
import { AdminAnalyzerDetailsComponent } from '../../dashboard/admin-analyzer/admin-analyzer-details/admin-analyzer-details.component';
import { TaskPausingService } from '../table/task-pausing.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AuthUserService } from '../../../@core/data/auth-user.service';
import { UsersService } from '../../../shared/users/users.service';

@Component({
  selector: 'ngx-taskboard-only-table-with-stats',
  templateUrl: './taskboard-only-table-with-stats.component.html',
  styleUrls: ['../taskboard-table/taskboard-table.component.scss']
})
export class TaskboardOnlyTableWithStatsComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('table', { static: false }) table: MatTable<any>;
  @ViewChild('statusSelect', { static: false }) statusSelect: NgSelectComponent;
  @Input() dataStream: any;
  @Input() add: (taskboardPermissions: any) => any;
  // When exist then change columns, but if column name matches to names from columns this columns actions are unchanged
  @Input() columnsSettings;
  @Input() dataValues: any;
  @Input() openInBackstack: boolean = false;
  @Input() backstackViewParams: any;
  @Input() showReload = true;
  @Input() showTableScroll = true;
  @Input() scrollableContainerMaintainer = false;
  @Input() additionalColumns = [];
  @Output() loadEmployeeId = new EventEmitter<number>();
  private offsetTop: number = 0;
  private subscription: Subscription = new Subscription();
  public dataLoaded: LoadingStatus = LoadingStatus.NOT_LOADED;
  public LoadingStatus: typeof LoadingStatus = LoadingStatus;
  public taskboardPermissions: any = {};
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public data: any;
  public displayedColumns: string[] = [];
  private defaultColumns = [
    {
      fieldName: 'index',
      title: 'No.'
    },
    {
      fieldName: 'info',
      title: 'Info'
    },
    {
      fieldName: 'title',
      title: 'Title',
      class: 'text-left'
    },
    {
      fieldName: 'description',
      title: 'Description',
      fieldType: 'textarea-view',
      addSubtasksProgress: true
    },
    {
      fieldName: 'latestUpdate',
      title: 'Latest update',
      fieldType: 'textarea-view',
      formatHtml: true,
      click: (event: any, task: any) => {
        if (task.canEditLatestUpdate) {
          event.preventDefault();
          event.stopPropagation();
          this.getTopOffset();
          let taskNo = task.taskNo;
          let taskId = task.taskId;
          let subtaskId = 0;
          if (task.isSubTask) {
            taskNo = task.subTaskForTaskNo;
            taskId = task.subTaskForTaskId;
            subtaskId = task.taskId;
          }
          const taskCopy = Object.assign({}, task);
          taskCopy.taskId = taskId;

          if (this.backstackViewParams && this.backstackViewParams.isTaskOnlyDetails) {
          } else if (this.openInBackstack) {
            this.tableService.openWindowWithBackStack(FastEditorComponent, `Edit Latest update of ${taskNo}`,
            {
              component: AdminAnalyzerDetailsComponent,
              title: `${this.backstackViewParams.careHomeName} - staff hours details`,
              elementId: 1,
              parameters: this.backstackViewParams
            },
            {
              task: taskCopy,
              elementId: taskId,
              taskPermissions: this.taskboardPermissions,
              subtaskId: subtaskId
            });
          } else if (task.isSubTask) {
            this.tableService.openWindow(FastEditorComponent, `Edit Latest update of ${taskNo}`, {
              task: taskCopy,
              elementId: taskId,
              taskPermissions: this.taskboardPermissions,
              subtaskId: subtaskId
            });
          } else {
            this.edit(task, TaskTab.INFO, true);
          }
        } else {
          this.toastrService.warning('You cannot edit this field.', 'Warning', { duration: 60000, destroyByClick: true });
        }
      }
    },
    {
      fieldName: 'assignedTo',
      title: 'Assigned to',
      fieldType: 'textarea-view',
      formatHtml: true,
      click: (event: any, task: any) => {
      }
    },
    {
      fieldName: 'status',
      title: 'State',
    }
  ];
  public columns: any = [];
  public onFilterChange$ = new BehaviorSubject<string>('');
  public taskboardSummary: any = {};
  public taskBoardName: string = '';
  public taskboardId: number = -1;
  public statuses = [];
  public showSubtasksForTaskId;

  public employees = [];
  public employeeId: number = null;
  public employeeLoader = false;
  public showEmployeeInfo = false;

  constructor(private tableService: TableService,
              private toastrService: NbToastrService,
              private tasksService: TasksService,
              private authUserService: AuthUserService,
              private usersService: UsersService,
              private dictionariesService: DictionariesService,
              private taskPausingService: TaskPausingService) {
    this.subscription.add(this.tableService.refresh$
      .subscribe(() => { this.load(); }
    ));
  }

  ngOnChanges() {
    this.load();
  }

  ngOnInit() {
    if (this.columnsSettings) {
      for (const column of this.columnsSettings) {
        const columnFromDefault = this.defaultColumns.find((columnElement: any) => {
          return column.fieldName === columnElement.fieldName;
        });
        this.columns.push(columnFromDefault ? columnFromDefault : column);
      }
    } else {
      this.columns = this.defaultColumns;
    }
    if (this.additionalColumns && this.additionalColumns.length > 0) {
      for (const column of this.additionalColumns) {
        this.columns.push(column);
      }
    }
    const columns = [];
    for (const column of this.columns) {
      columns.push(column.fieldName);
    }
    this.displayedColumns = columns;
    this.load();

    this.employeeLoader = true;
    this.subscription.add(this.usersService.getUsersForTasksCollaborationWithAssignedTasks(this.authUserService.getCareHomeId()).pipe(finalize(() => {
      this.employeeLoader = false;
    })).subscribe((res: any) => {
      this.employees = res?.result?.list || [];
    }));
  }

  getFullName(firstName: string, surname: string): string {
    return `${firstName} ${surname || ''}`;
  }

  getEmployeeName(employeeId) {
    return this.employees.find((e) => e.id === employeeId)?.login;
  }

  loadOnlyMine() {
    this.employeeId = this.authUserService.getUser().id;
    this.loadEmployeeId.next(this.employeeId);
    this.load();
  }

  load() {
    if (this.dataValues) {
      this.dataLoaded = LoadingStatus.LOADED;
      this.data = this.dataValues;
      this.data = new MatTableDataSource(this.dataValues);
      this.data.sort = this.sort;
    } else if (this.dataLoaded !== LoadingStatus.IN_PROGRESS) {
      this.dataLoaded = LoadingStatus.IN_PROGRESS;
      this.subscription.add(this.dataStream().subscribe((response: any) => {
        this.dataLoaded = LoadingStatus.LOADED;
        let result = {
          tasksList: this.concatTasksList(response.result.tasksListGeneral || []),
          statistics: this.concatStats(response.result.tasksListGeneral || []
          ),
          taskboardPermissions: {
          canIChange: true,
          canIClose: true
          }
          }
        this.data = result.tasksList;

        //console.log(result);
        this.taskboardSummary = result.statistics || {};
        this.taskboardPermissions = result.taskboardPermissions || {};
        this.loadTaskStatuses();
        this.data = new MatTableDataSource(this.data);
        this.data.sort = this.sort;
        this.subscription.add(this.onFilterChange$
          .subscribe((value: string) => {
          this.data.filter =  (value.trim) ? value.trim().toLowerCase() : '';
        }));
        setTimeout(() => {
          if (this.scrollableContainerMaintainer) {
            document.getElementsByClassName('scrollable-container')[0] && document.getElementsByClassName('scrollable-container')[0].scrollTo(0, this.tableService.offsetTop);
          } else {
            document.getElementById('table-container') && document.getElementById('table-container').scrollTo(0, this.offsetTop);
          }
        }, 1);
        this.showEmployeeInfo = true;
      },
      
      (err) => {
        this.showEmployeeInfo = true;
        this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
      }));
    }
  }

  concatTasksList(taskListGeneral = []) {
    let returnArray = [];
    for (const taskArray of taskListGeneral) {
      returnArray = returnArray.concat(taskArray.tasksList);
    }

    return returnArray;
  }

  concatStats(taskListGeneral = []) {
    const stats = {
      activeTasksCount: 0,
      commentedTasksCount: 0,
      dayCareResidentsCount: 0,
      doneTasksCount: 0,
      inHouseResidentsCount: 0,
      pausedTasksCount: 0,
      registeredResidentsCount: 0,
      uncommentedTasksCount: 0,
      vacantRoomsCount: 0,
    };
    for (const taskArray of taskListGeneral) {
      stats.activeTasksCount += taskArray.statistics.activeTasksCount;
      stats.commentedTasksCount += taskArray.statistics.commentedTasksCount;
      stats.dayCareResidentsCount += taskArray.statistics.dayCareResidentsCount;
      stats.doneTasksCount += taskArray.statistics.doneTasksCount;
      stats.inHouseResidentsCount += taskArray.statistics.inHouseResidentsCount;
      stats.pausedTasksCount += taskArray.statistics.pausedTasksCount;
      stats.registeredResidentsCount +=
        taskArray.statistics.registeredResidentsCount;
      stats.uncommentedTasksCount += taskArray.statistics.uncommentedTasksCount;
      stats.vacantRoomsCount += taskArray.statistics.vacantRoomsCount;
    }
    return stats;
  }

  loadTaskStatuses() {
    this.subscription.add(this.dictionariesService.getTaskStatuses()
      .subscribe((response: any) => {
        this.statuses = response.result.wordsFromDictionary;
    }));
  }

  applyFilter(filterValue: string) {
    this.onFilterChange$.next(filterValue);
  }

  edit = (row: any, tab: TaskTab = TaskTab.INFO, scrollToUpdate = false) => {
    let taskToEdit = row.taskId;
    const taskHeader = row.taskBoardName || ''  + ' "' + row.title + '"';
    let subtaskId = 0;
    if (row.isSubTask) {
      taskToEdit = row.subTaskForTaskId;
      subtaskId = row.taskId;
    }
    this.getTopOffset();
    if (this.backstackViewParams && this.backstackViewParams.isTaskOnlyDetails) {
    } else if (this.openInBackstack) {
      this.tableService.openWindowWithBackStack(EditTaskComponent, `Edit Task - ${taskHeader}`,
      {
        component: AdminAnalyzerDetailsComponent,
        title: `${this.backstackViewParams.careHomeName} - staff hours details`,
        elementId: 1,
        parameters: this.backstackViewParams
      },
      {
        mode: FormMode.EDIT,
        elementId: taskToEdit,
        activeTab: tab,
        subtaskId: subtaskId,
        scrollToUpdate: scrollToUpdate,
      }, 'edit-task-window');
    } else {
      this.tableService.openWindow(EditTaskComponent, `Edit Task - ${taskHeader}`,
      {
        mode: FormMode.EDIT,
        elementId: taskToEdit,
        activeTab: tab,
        subtaskId: subtaskId,
        scrollToUpdate: scrollToUpdate,
        disableTaskCopy: true
      }, 'edit-task-window');
    }
  }

  toogleSubtaskList(taskId: number) {
    if (this.showSubtasksForTaskId != taskId) {
      this.showSubtasksForTaskId = taskId;
    } else {
      this.showSubtasksForTaskId = null;
    }
  }

  onChangeStatus(newStatus: any, task: any) {
    if (newStatus === 'Paused') {
      this.taskPausingService.showPausingPopup(task, () => {
        this.updateTaskStatus(newStatus, task);
      });
    } else {
      this.updateTaskStatus(newStatus, task);
    }
  }

  updateTaskStatus(newStatus: any, task: any) {
    this.subscription.add(this.tasksService.updateTaskStatus(task.taskId, { status: newStatus })
      .subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        this.load();
      },
      (err) => {
        this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
    }));
  }

  getTopOffset() {
    if (this.scrollableContainerMaintainer) {
      this.tableService.offsetTop = document.getElementsByClassName('scrollable-container')[0].scrollTop;
    } else {
      this.offsetTop = document.getElementById('table-container').scrollTop;
    }

  }

  isOverflow(element: any) {
    return (element.offsetHeight < element.scrollHeight
         || element.offsetWidth < element.scrollWidth);
  }

  countLines(element: any) {
    return Math.floor(100 - (element.offsetHeight * 100) / element.scrollHeight) + '% info';
  }

  openStatusSelect(): void {
    this.statusSelect.open();
  }

  trackByFn(index, item) {
    return index;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
