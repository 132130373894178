import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, UntypedFormBuilder, Validators } from "@angular/forms";
import { NbDialogRef, NbWindowRef } from "@nebular/theme";
import { ProfessionalRolesModel } from "../../../../../shared/models/dictionaries/professional-roles/professional-roles-model";
import { CareHomesService } from "../../../../../shared/care-homes/care-homes.service";
import { Subscription } from "rxjs";
import { CellErrorType } from "../../cell-error-type.enum";
import { RotaTableHourModalComponent } from "../../rota-table-hour-modal/rota-table-hour-modal.component";
import { HourModalService } from "../../rota-table-hour-modal/hour-modal.service";
import { RotaTableService } from "../../rota-table.service";

@Component({
  selector: "add-edit-shift",
  templateUrl: "./add-edit-shift.component.html",
  styleUrls: ["./add-edit-shift.component.scss"],
})
export class AddEditShiftComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("fromControl", { read: ElementRef }) fromControl: ElementRef;

  private _selectedRole: number | null = null;
  private _canHaveExtras: boolean = false;
  private subscription: Subscription = new Subscription();
  private _shiftType: "AM" | "PM" | "LONG" | "NIGHT" = null;

  @Input() title: string;
  @Input() from: string = null;
  @Input() to: string = null;

  @Input() dayData: any;
  @Input() shiftType: string = null;
  @Input() roles: ProfessionalRolesModel[] = [];
  @Input() careHomeId: number;
  @Input() set canHaveExtras(value: boolean) {
    if (!value) {
      this.form.controls.isExtrasToBeTaken.setValue(false);
      this.form.controls.isExtrasToBeTaken.disable();
      return;
    }

    this.form.controls.isExtrasToBeTaken.enable();
    this._canHaveExtras = value;
  }
  get canHaveExtras() {
    return this._canHaveExtras;
  }

  public roleEnabled = false;
  @Input() set selectedRole(value: number | null) {
    this._selectedRole = value;
    if (value) {
      this.form.controls.role.enable();
      this.form.controls.role.setValue(value);
      this.roleEnabled = true;
    }
  }
  @Input() set employeeId(value: number | null) {
    if (value) {
      this.form.controls.employeeId.setValue(value);
    }
  }

  public paramTemplate: {
    AM: { from: string; to: string };
    PM: { from: string; to: string };
    LONG: { from: string; to: string };
    NIGHT: { from: string; to: string };
  } = {
    AM: { from: "6:00", to: "14:00" },
    PM: { from: "14:00", to: "22:00" },
    LONG: { from: "6:00", to: "22:00" },
    NIGHT: { from: "22:00", to: "6:00" },
  };

  public templateHRS = [];

  public form = this.fb.group({
    role: [{ value: null, disabled: true }, Validators.required],
    from: [null, Validators.required],
    to: [null, Validators.required],
    employeeId: [null],
    isExtrasToBeTaken: [{ value: false, disabled: true }],
    extrasPublished: [true],
  });

  get fromValue() {
    return this.form.controls.from.value;
  }

  get toValue() {
    return this.form.controls.to.value;
  }

  isOpen = false;
  menuPosition = "from";

  isBackspaceActive = false;

  constructor(
    protected ref: NbWindowRef<AddEditShiftComponent>,
    private fb: UntypedFormBuilder,
    private careHomesService: CareHomesService,
    private hourModalService: HourModalService,
    private rotaTableService: RotaTableService,
  ) {}

  ngOnInit(): void {
    this.prepareTemplate();
    this.loadValuesIfExist();

    this.subscription.add(
      this.form.controls.isExtrasToBeTaken.valueChanges.subscribe((value) => {
        if (value) {
          this.form.controls.employeeId.setValue(null);
          this.form.controls.employeeId.disable();
        } else {
          this.form.controls.employeeId.enable();
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.fromControl.nativeElement.focus();
  }

  loadValuesIfExist() {
    if (this.from) {
      this.form.controls.from.setValue(this.from);
    }
    if (this.to) {
      this.form.controls.to.setValue(this.to);
    }

    if (this.shiftType) {
      this.form.controls.from.setValue(
        this.paramTemplate[this.shiftType.toUpperCase()].from,
      );
      this.form.controls.to.setValue(
        this.paramTemplate[this.shiftType.toUpperCase()].to,
      );
    }
  }

  // updateAvailableEmployees() {
  //   this.subscription.add(
  //     this.careHomesService
  //       .getRotaEnhanced(
  //         this.careHomeId,
  //         this.form.controls.role.value,
  //         this.dayData.dayDate,
  //       )
  //       .subscribe((result: any) => {
  //         this.dayData = result.result;
  //       }),
  //   );
  // }

  private prepareTemplate() {
    const am = this.dayData.amShift.split(" - ");
    const pm = this.dayData.pmShift.split(" - ");
    this.paramTemplate = {
      AM: { from: am[0], to: am[1] },
      PM: { from: pm[0], to: pm[1] },
      LONG: { from: am[0], to: pm[1] },
      NIGHT: { from: pm[1], to: am[0] },
    };
    this.templateHRS = ["AM", "PM", "LONG", "NIGHT", am[0], pm[0], pm[1]];
  }

  close() {
    this.ref.close(null);
    this.rotaTableService.closeWindow();
  }

  save() {
    this.ref.close(this.form.getRawValue());
  }

  makeListVisible(type) {
    this.isOpen = true;
    this.menuPosition = type;
  }

  @HostListener("click", ["$event"])
  onClick(event: MouseEvent) {
    if ((event.target as Element).classList.contains("menu-item")) return;
    if ((event.target as Element).tagName.toLowerCase() === "input") return;


    if (this.isOpen) {
      this.makeListNotVisible();
    }
  }

  makeListNotVisible() {
    this.isOpen = false;
  }

  public autofillValue(type: string): void {
    let val =
      type === "from"
        ? this.fromValue.replace(".", ":")
        : this.toValue.replace(".", ":");

    if (val === "-") return;

    let value = val;
    if (val.length === 1) {
      value = `${val}:00`;
    }

    if (
      val.length === 2 &&
      !this.isBackspaceActive &&
      parseInt(val, 10) <= 24
    ) {
      value = val.includes(":") ? `${val}00` : `${val}:00`;
    }
    if (val.length === 3 && !val.includes(":")) {
      const tempVal = val.slice(0, 2) + ":" + val.slice(2).padEnd(2, "0");
      const test = tempVal.split(":").map((_) => parseInt(_));
      if (test[0] < 24 && test[1] < 60) {
        value = tempVal;
      } else {
        value = "";
      }
    }
    if (val.length === 4 && !val.includes(":")) {
      if (val.slice(0, 2) < "23" && val.slice(2) < "60") {
        value = val.slice(0, 2) + ":" + val.slice(2);
      } else {
        value = "";
      }
    }
    this.isBackspaceActive = false;
    if (type === "from") {
      this.form.controls.from.setValue(value);
    } else {
      this.form.controls.to.setValue(value);
    }
  }

  fillFromMenu(item: "AM" | "PM" | "LONG" | "NIGHT" | string) {
    this.isOpen = false;

    switch (item) {
      case "AM": {
        this.form.controls.from.setValue(this.paramTemplate.AM.from);
        this.form.controls.to.setValue(this.paramTemplate.AM.to);
        break;
      }
      case "PM": {
        this.form.controls.from.setValue(this.paramTemplate.PM.from);
        this.form.controls.to.setValue(this.paramTemplate.PM.to);
        break;
      }
      case "LONG": {
        this.form.controls.from.setValue(this.paramTemplate.AM.from);
        this.form.controls.to.setValue(this.paramTemplate.PM.to);
        break;
      }
      case "NIGHT": {
        this.form.controls.from.setValue(this.paramTemplate.PM.to);
        this.form.controls.to.setValue(this.paramTemplate.AM.from);
        break;
      }
      default: {
        this.form.controls[this.menuPosition].setValue(item);
      }
    }
  }

  checkHours(event, showHours = true) {
    if (["AM", "PM", "LONG", "NIGHT"].includes(event.emitterVal)) {
      return;
    }
    if (this.rotaTableService.isWindowOpen) {
      this.rotaTableService.closeWindow();
    }

    const from = this.form.get("from").value?.toString();
    const to = this.form.get("to").value?.toString();

    // Check if entered input was in a 12-hour format and load helping modal.
    if (from && to) {
      const { newFrom, newTo, newTo24Format, newFrom24Format } =
        this.formatInsertedDate(from, to);

      let target = event.target;
      if (!!event.emitterEvent && !!event.emitterVal) {
        target = event.emitterEvent;
        target.value = event.emitterVal.trim();
      }

      if (!target || !target.value) return;

      if (
        +newTo < 1300 &&
        +newFrom < 1300 &&
        (+newTo - +newFrom) / 100 < 12 &&
        ((target.value === this.form.get("to").value &&
          target.id === "toControl") ||
          (target.value === this.form.get("from").value &&
            target.id === "fromControl"))
      ) {
        this.hourModalService.setCurrentValues(
          newFrom,
          newTo,
          newTo24Format,
          (arg) => this.patchToValue(arg),
          target,
          newFrom24Format,
          (newFromValue) => this.patchFromValue(newFromValue),
        );
        if (showHours) {
          this.rotaTableService.openWindow(
            RotaTableHourModalComponent,
            "What did you mean?",
            {
              elementId: this.form,
            },
            "hour-modal-window",
            true,
          );
        }
        return;
      }
    }
  }

  patchToValue(newVal) {
    if (["AM", "PM", "LONG", "NIGHT"].includes(newVal)) {
      return;
    }
    switch (newVal) {
      case ":":
        this.form.controls["to"].patchValue("00:00", { emitSelf: true });
        break;
      default:
        this.form.controls["to"].patchValue(newVal, { emitSelf: true });
        break;
    }
  }

  patchFromValue(newVal: string) {
    if (["AM", "PM", "LONG", "NIGHT"].includes(newVal)) {
      return;
    }
    switch (newVal) {
      case ":":
        this.form.controls["from"].patchValue("00:00", { emitSelf: true });
        break;
      default:
        this.form.controls["from"].patchValue(newVal, { emitSelf: true });
        break;
    }
  }

  formatInsertedDate(
    from: string,
    to: string,
  ): {
    newFrom: string;
    newTo: string;
    newTo24Format: string;
    newFrom24Format: string;
  } {
    // check if hh:mm
    let formattedFrom: string = from;
    let formattedTo: string = to;
    const h_mm = new RegExp("^[0-9]:[0-5][0-9]$");
    const h_m = new RegExp("^[0-9]:[0-9]$");
    const hh_m = new RegExp("^[0-2][0-9]:[0-9]$");
    const h = new RegExp("^[0-9]");
    const hh = new RegExp("^[0-2][0-9]");
    if (from.length !== 5) {
      // regex formats

      h_mm.test(to) ? (formattedTo = `0${to}`) : null;
      hh_m.test(to) ? (formattedTo = `${to}0`) : null;
      h.test(to) ? (formattedTo = `0${to}:00`) : null;
      hh.test(to) ? (formattedTo = `${to}:00`) : null;

      h_mm.test(from) ? (formattedFrom = `0${from}`) : null;
      hh_m.test(from) ? (formattedFrom = `${from}0`) : null;
      h.test(from) ? (formattedFrom = `0${from}:00`) : null;
      hh.test(from) ? (formattedFrom = `${from}:00`) : null;

      formattedFrom = formattedFrom.slice(0, 5);
    }
    if (to.length !== 5) {
      // regex formats

      h_mm.test(to) ? (formattedTo = `0${to}`) : null;
      hh_m.test(to) ? (formattedTo = `${to}0`) : null;

      h.test(to) ? (formattedTo = `0${to}:00`) : null;
      hh.test(to) ? (formattedTo = `${to}:00`) : null;

      formattedTo = formattedTo.slice(0, 5);
    }

    const newFrom = formattedFrom.split(":")[0] + formattedFrom.split(":")[1];
    const newTo = formattedTo.split(":")[0] + formattedTo.split(":")[1];
    // console.log("new to", formattedTo);
    const newTo24Format = `${
      +formattedTo.split(":")[0] + 12 < 24
        ? +formattedTo.split(":")[0] + 12
        : "00"
    }:${formattedTo.split(":")[1]}`;
    // console.log("new to24", formattedTo);
    const newFrom24Format = `${
      +formattedFrom.split(":")[0] + 12 < 24
        ? +formattedFrom.split(":")[0] + 12
        : "00"
    }:${formattedFrom.split(":")[1]}`;
    return { newFrom, newTo, newTo24Format, newFrom24Format };
  }
}
