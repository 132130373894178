import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Subscription, forkJoin } from "rxjs";
import { AuthUserService } from "../../../@core/data/auth-user.service";
import { PermissionsService } from "../../../@core/data/permissions.service";
import { DictionariesService } from "../../../shared/dictionaries/dictionaries.service";
import { InboxService } from "../../../shared/inbox/inbox.service";
import { MessagesService } from "../../../shared/messages/messages.service";
import { UsersService } from "../../../shared/users/users.service";
import { FormErrorStateMatcher } from "../../../utilities/form-error-state.matcher";
import {
  getValidationStatus,
  handleValidationErrorMessage,
  isFormValid,
  isFieldInvalid,
  getErrorMessage,
} from "../../../utilities/utils";
import { newMessageErrors } from "./message-errors";
import { NbToastrService, NbDialogService } from "@nebular/theme";
import { InboxEventService } from "../../../pages/inbox/_services/inbox-event.service";
import {
  ChooseTemplateDialogComponent,
  TemplateType,
} from "../../../pages/shared/choose-template/choose-template.component";
import {
  QuestionDialogComponent,
  QuestionDecisionTypeEnum,
} from "../../../pages/shared/question-dialog/question-dialog.component";
import { CareHomesService } from "../../../shared/care-homes/care-homes.service";
import { atLeastOne } from "../../../utilities/validators";
import { DatePipe } from "@angular/common";
import { environment } from "../../../../environments/environment";

const MAX_SIZE = 10 * 1024 * 1024; // 10MB
const fileName = "echo-screenshot.png";

@Component({
  selector: "message-to-support",
  templateUrl: "./message-to-support.component.html",
  styleUrls: ["./message-to-support.component.scss"],
})
export class MessageToSupportComponent implements OnInit {
  @ViewChild("FileInput", { static: false }) FileInput: ElementRef;
  public form: UntypedFormGroup;
  public careHomeId = 0;
  public reportType = "Home";
  private subscription: Subscription = new Subscription();
  public userId = 0;
  public matcher = new FormErrorStateMatcher();
  public utils = {
    getValidationStatus,
    handleValidationErrorMessage,
    isFormValid,
    isFieldInvalid,
  };
  public files = [];
  public messageType = "carehome";
  public isPined = false;
  public showUnpin = false;
  public canSave = true;
  public disableExternal = false;
  public attachScreenshot = false;
  private sendScreenshoot = false;

  public recipients = [];
  public e360recipients = [];
  public groupes = [];
  public sendToValues = [];

  public defaultRecipientsIds = [];
  public defaultRecipients = [];

  constructor(
    private inboxService: InboxService,
    private authUserService: AuthUserService,
    private toastService: NbToastrService,
    private eventService: InboxEventService,
    private dictionariesService: DictionariesService,
    private usersService: UsersService,
    public careHomesService: CareHomesService,
    public dialogRef: MatDialogRef<MessageToSupportComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private messagesService: MessagesService
  ) {}

  ngOnInit() {
    this.createForm();
    this.userId = this.authUserService.getUser().id;
    this.careHomeId = this.authUserService.getCareHomeId();
    this.dialogRef.updateSize("900px");
    this.loadSendToValues(this.messageType, [], true);
  }

  private loadSendToValues(
    messageType: string,
    careHomeIds: number[],
    toUpdateValues?: boolean
  ) {
    const careHomeIdsFinal = [...careHomeIds];
    forkJoin([
      this.usersService.getMessagesAddresBookForHomes(
        this.userId,
        careHomeIdsFinal,
        messageType
      ),
      this.dictionariesService.getProfessionalRolesActiveOnlyForMessages(
        this.authUserService.getCareHomeId(),
        true,
        messageType
      ),
    ]).subscribe(([recipients, groupes]) => {
      this.recipients = recipients.result.list;
      this.groupes = groupes.result;
      this.sendToValues = [
        ...this.groupes.map((e) => ({
          id: `group_${e.id}`,
          name: e.roleName,
          secondName: null,
          color: e.color,
          selected: this.defaultRecipientsIds.includes(`group_${e.id}`),
        })),
        ...this.recipients.map((e) => ({
          id: `user_${e.id}`,
          name: e.fullName,
          secondName: e.defaultCareHome,
          color: "#000",
          selected: this.defaultRecipientsIds.includes(`user_${e.id}`),
        })),
      ];
      this.defaultRecipients = this.sendToValues.filter(
        (e) => e.selected === true
      );
    });
  }

  createForm() {
    (this.form = new UntypedFormGroup({
      recipients: new UntypedFormControl(null),
      e360recipients: new UntypedFormControl(null),
      sendTo: new UntypedFormControl(null, [Validators.required]),
      groupes: new UntypedFormControl(null),
      topic: new UntypedFormControl(null, [Validators.required]),
      message: new UntypedFormControl(null, [Validators.required]),
    })),
      atLeastOne(Validators.required, ["sendTo", "groupes"]);

    this.form.controls["recipients"].valueChanges.subscribe((value) => {
      this.e360recipients = value;
    });

    this.messagesService
      .getMessageTemplateByType(
        this.authUserService.getCareHomeId(),
        this.data.supportArea
      )
      .subscribe((res: any) => {
        if (res.result.list.length) {
          this.form.get("topic").setValue(res.result.list[0].title || "");
          this.form.get("message").setValue(res.result.list[0].body || "");
          this.attachScreenshot = res.result.list[0].attachScreenshot;
          this.setScreenshot();

          this.setDefaultRecipientsIds(res.result.list[0].sendToList);
        }
      });
  }

  setDefaultRecipientsIds(sendToList) {
    if (sendToList) {
      this.defaultRecipientsIds = sendToList;
    }
  }

  getRecipients() {
    return this.form.get("recipients").value;
  }
  get360Recipients() {
    return this.form.get("e360recipients").value;
  }
  getGroupes() {
    return this.form.get("groupes").value;
  }
  getSendTo() {
    return this.form.get("sendTo").value;
  }

  toggleSendTo(type) {
    this.messageType = type;
    this.loadSendToValues(type, []);
  }

  sendMessage() {
    const formValue = this.form.getRawValue();

    const recipientsId = [];
    const e360Id = [];
    const groupesId = [];
    const sendTo = [];

    if (this.getRecipients()) {
      this.getRecipients().forEach((rec) => {
        recipientsId.push(rec.id.toString());
      });
    }

    if (this.getGroupes()) {
      this.getGroupes().forEach((role) => {
        groupesId.push(role.id.toString());
      });
    }

    if (this.get360Recipients()) {
      this.get360Recipients().forEach((rec) => {
        e360Id.push(rec.id.toString());
      });
    }

    if (this.getSendTo()) {
      this.getSendTo().forEach((e) => {
        sendTo.push(e.id.toString());
      });
    }

    if (!this.disableExternal) {
      window.location.href = `mailto:piotr@apps-garden.com?subject=${formValue.topic}&body=${formValue.message}`;
    }
    const fd = new FormData();
    fd.append("topic", this.form.get("topic").value);
    fd.append("message", this.form.get("message").value);
    fd.append("supportArea", this.data.supportArea);

    fd.append("sendToList", "[" + sendTo.toString() + "]");
    fd.append("e360IdList", "[" + e360Id.toString() + "]");

    const filesUid = [];
    this.files.forEach((file: any) => {
      file.size ? fd.append("files", file) : filesUid.push(file.uid);
    });
    if (filesUid.length > 0) {
      fd.append("filesUid", "[" + filesUid.toString() + "]");
    }

    this.messagesService.sendSupportMessage(fd).subscribe(
      () => {
        this.close();
      },
      (err) => {
        this.toastrService.danger(getErrorMessage(err), "Error", {
          duration: 60000,
          destroyByClick: true,
        });
        this.close();
      }
    );
  }

  fileSelected(event) {
    for (const file of event) {
      if (file.type !== "application/pdf") {
        this.toastService.danger(
          "PDF is the preferred document format. Non-PDF attachments can be difficult to open on mobile devices.",
          "Error",
          { duration: 60000, destroyByClick: true }
        );
      }
      if (file.size <= MAX_SIZE) {
        this.files.push(file);
      } else {
        this.toastService.danger(
          `Maximum file size exceed. Max file size is 10 MB.`,
          "Error",
          { duration: 60000, destroyByClick: true }
        );
      }
    }
  }

  deleteFile(index) {
    if (this.isScreenshot(index)) {
      this.sendScreenshoot = false;
      this.attachScreenshot = false;
    }
    this.files.splice(index, 1);
  }

  clickInput() {
    this.FileInput.nativeElement.click();
  }

  close() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }

  prepareData() {
    const data = {
      subject: this.form.get("topic").value,
      message: this.form.get("message").value,
      categoryId: this.form.get("categoryId").value,
      toIdList: this.form.get("toIdList").value,
      toGroupIdList: this.form.get("toGroupIdList").value,
    };
    return data;
  }

  fillArray(ids: [], sourceArray: any = []) {
    const array = [];
    ids.forEach((itemId: number) => {
      const item = sourceArray.find((sourceItem: any) => {
        return itemId == sourceItem.id;
      });
      if (item) {
        array.push(item);
      }
    });
    return array;
  }

  changeAttachFile($event) {
    this.attachScreenshot = $event.checked;
    this.setScreenshot();
  }

  setScreenshot() {
    if (this.attachScreenshot) {
      this.disableExternal = true;
      this.sendScreenshoot = this.attachScreenshot;
      this.files.unshift(this.dataURLtoFile(this.data.image, fileName));
    } else {
      this.disableExternal = false;
      if (this.files[0]?.name === fileName) {
        this.files.shift();
      }
    }
  }

  isScreenshot(indexOfFile: number) {
    if (!this.files[indexOfFile]) return false;
    return this.files[indexOfFile].name === fileName;
  }

  private dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  openInNewTab(indexOfFile: number) {
    const fileURL = URL.createObjectURL(this.files[indexOfFile]);
    window.open(fileURL, "_blank");
  }
}
