import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import {
  isFormValid,
  handleValidationErrorMessage,
  getFullName,
  getErrorMessage,
  onHoursInput,
} from "../../../../utilities/utils";
import { Observable, Subscription } from "rxjs";
import { TableService } from "../../../shared/table/table.service";
import { EmployessService } from "../../../../shared/employess/employess.service";
import { NbToastrService, NbDialogService } from "@nebular/theme";
import { PayrollService } from "../../../../shared/payroll/payroll.service";
import { DictionariesService } from "../../../../shared/dictionaries/dictionaries.service";
import { AuthUserService } from "../../../../@core/data/auth-user.service";
import { FormMode } from "../../../../utilities/form-mode.enum";
import { payrollErrorMessages } from "../payroll-errors";
import {
  invalidDate,
  hours250AndNegative,
} from "../../../../utilities/validators";
import { QuestionDialogComponent } from "../../../shared/question-dialog/question-dialog.component";
import { PermissionsService } from "../../../../@core/data/permissions.service";
import * as moment from "moment";
import { CareHomesService } from "../../../../shared/care-homes/care-homes.service";
import { DOCUMENT } from "@angular/common";
import { TasksEmailDialogComponent } from "../../../shared/tasks-email-dialog/tasks-email-dialog.component";

@Component({
  selector: "ngx-new-payroll",
  templateUrl: "./new-payroll.component.html",
  styleUrls: ["./new-payroll.component.scss"],
})
export class NewPayrollComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public utils = { handleValidationErrorMessage, isFormValid, onHoursInput };
  public errorMessages = payrollErrorMessages;
  public form: UntypedFormGroup;
  public employees: any = [];
  public roles: any = [];
  public types: any = [];
  public monthList: any = [];
  public defaultMonth: string = "";
  public id: number;
  public isAdjustmentAdvancePicked = false;
  public showFormerEmployees = false;
  public status = "";
  public acceptanceMessage = "";
  public permissions = <any>{};
  public noReload = false;
  public readOnly = false;
  public lockedEmailSave = false;
  public responseReadOnly = false;
  public isAdvance = false;
  public isAdvanceControlDisabled = false;
  public canSave = true;
  public isALFromBackend = false;

  constructor(
    private tableService: TableService,
    private dialogService: NbDialogService,
    private employeesService: EmployessService,
    private toastrService: NbToastrService,
    private dictionariesService: DictionariesService,
    private payrollService: PayrollService,
    private authUserService: AuthUserService,
    private careHomesService: CareHomesService,
    private permissionsService: PermissionsService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.permissions = this.permissionsService.getPermissions();
  }

  ngOnInit() {
    this.createForm();

    this.subscription.add(
      this.dictionariesService
        .getProfessionalRolesForPayrollAdjustment()
        .subscribe((response: any) => {
          this.roles = response.result;
        }),
    );

    this.subscription.add(
      this.dictionariesService.getPayrollTypes().subscribe((response: any) => {
        this.types = response.result.wordsFromDictionary;
      }),
    );

    if (this.isEditMode()) {
      this.loadEmployees(true);
      this.subscription.add(
        this.payrollService
          .getPayroll(this.tableService.getValue().elementId)
          .subscribe((response: any) => this.updateValues(response.result)),
      );
    } else {
      this.subscription.add(
        this.careHomesService
          .getCareHome(this.authUserService.getCareHomeId())
          .subscribe((response: any) => {
            const payrollDay = moment(response.result.wagesDate).format("DD");
            this.prepareMonthList(moment(), payrollDay);
          }),
      );
      this.loadEmployees();
    }
    if (this.tableService.getValue()) {
      this.patchValues();
    }
  }

  loadEmployees(withFormer: boolean = false) {
    const method = withFormer
      ? this.employeesService.getEmployess()
      : this.employeesService.getActiveEmployess();
    this.subscription.add(
      method.subscribe((response: any) => {
        response.result.employeesLines.map(
          (i) =>
            (i.fullName = getFullName(i.employeeFirstName, i.employeeSurname)),
        );
        return (this.employees = response.result.employeesLines);
      }),
    );
  }

  createForm() {
    this.form = new UntypedFormGroup({
      employeeId: new UntypedFormControl(null, [Validators.required]),
      typeId: new UntypedFormControl(null, [Validators.required]),
      roleId: new UntypedFormControl(null, [Validators.required]),
      timeAmount: new UntypedFormControl(null, [
        Validators.required,
        hours250AndNegative,
      ]),
      value: new UntypedFormControl(null),
      comments: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(1000),
      ]),
      payrollMonth: new UntypedFormControl(this.defaultMonth, [
        Validators.required,
      ]),
      eventDate: new UntypedFormControl(null, [
        Validators.required,
        invalidDate,
      ]),
      isEmailOnSave: new UntypedFormControl(false),
      isAdvance: new UntypedFormControl(false),
    });

    this.form.get("typeId").valueChanges.subscribe(() => {
      if (this.permissions.PAYROLL_ADJUSTEMENTS_ADD_AND_ACCEPTANCE) {
        this.onAdjustmentPick();
      }
    });

    if (!this.permissions.PAYROLL_ADJUSTEMENTS_ADD_AND_ACCEPTANCE) {
      this.form.disable();
      this.isAdvanceControlDisabled = true;
    }
  }

  patchValues() {
    const values = this.tableService.getValue();
    this.noReload = values.noReload;
    this.form.get("employeeId").patchValue(values.employeeId);
    this.form.get("roleId").patchValue(values.employeeRoleId);
  }

  toggleShowFormerEmployees(showFormer: boolean) {
    this.showFormerEmployees = showFormer;
    this.loadEmployees(showFormer);
  }

  toggleIsAdvance(isAdvance: boolean) {
    this.isAdvance = isAdvance;
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  deletePayroll() {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: "Delete confirmation",
          message: "Are you sure you want to delete? It cannot be undone.",
          deleteInput: true,
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          this.subscription.add(
            this.payrollService.deletePayroll(this.id).subscribe(
              (response: any) => {
                this.toastrService.success(response.message, "Success");
                this.tableService.closeWindow(!this.noReload);
              },
              (err) => {
                this.toastrService.danger(getErrorMessage(err), "Error", {
                  duration: 60000,
                  destroyByClick: true,
                });
              },
            ),
          );
        }
      });
  }

  prepareData() {
    const data = {
      careHomeId: this.authUserService.getCareHomeId(),
      employeeId: this.form.get("employeeId").value,
      typeId: this.form.get("typeId").value,
      roleId: this.form.get("roleId").value,
      timeAmount: this.form.get("timeAmount").value,
      value: this.form.get("value").value,
      comments: this.form.get("comments").value,
      payrollMonth: this.form.get("payrollMonth").value,
      eventDate: this.form.get("eventDate").value,
      isAdvance:
        this.form.get("isAdvance")?.value?.toString() === "true" ? true : false,
    };
    return data;
  }

  save() {
    if (this.isDateInTheFuture()) {
      this.saveIfDateIsInTheFuture();
      return;
    }

    this.saveData();
  }

  public saveData() {
    this.saveWithObservable(true).subscribe((response: any) => {
      if (this.form.get("isEmailOnSave").value) {
        this.openEmailWindow(response);
      } else {
        this.tableService.closeWindow(!this.noReload);
      }
    });
  }

  // TODO
  saveWithObservable(closeWindow = true) {
    this.canSave = false;
    const data = this.prepareData();
    const dataStream = this.isEditMode()
      ? this.payrollService.updatePayroll(this.id, data)
      : this.payrollService.addPayroll(data);
    // this.isCloseWindow = closeWindow;
    // const data = this.prepareData();
    // let dataStream = this.tasksService.addMultipleTask(data);
    // let isUpdate = false;
    // if (this.taskId > 0) {
    //   dataStream = this.tasksService.updateTask(this.taskId, data);
    //   isUpdate = true;
    // } else if (Array.isArray(this.taskId)) {
    //   dataStream = this.tasksService.updateMultipleTasks(this.taskId, data);
    // }
    return Observable.create((observer) => {
      this.subscription.add(
        dataStream.subscribe(
          (response: any) => {
            this.toastrService.success(response.message, "Success");
            observer.next(response);
            observer.complete();
          },
          (err) => {
            this.canSave = true;
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
            observer.complete();
          },
        ),
      );
    });
  }

  openEmailWindow(postResponse: any) {
    this.dialogService
      .open(TasksEmailDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: "Please enter subject and emails",
          needLoadTable: false,
          showClearEmails: false,
          careHomeId: this.authUserService.getCareHomeId(),
        },
      })
      .onClose.subscribe((inputData: any) => {
        if (inputData && inputData.isConfirm) {
          const adjustmentObject = {
            adjId: postResponse.id,
          };
          const data = {
            frontURL: this.document.location.origin,
            adjustmentListId: [adjustmentObject],
            emailToListId: inputData.emailTo,
            emailBody: inputData.emailBody,
            emailSubject: inputData.subject,
          };

          this.subscription.add(
            this.payrollService.sendEmails(data).subscribe(
              (response: any) => {
                this.toastrService.success(response.message, "Success");
                this.tableService.closeWindow(!this.noReload);
              },
              (err) => {
                this.toastrService.danger(getErrorMessage(err), "Error", {
                  duration: 60000,
                  destroyByClick: true,
                });
              },
            ),
          );
        } else {
          this.tableService.closeWindow(true);
        }
      });
  }

  updateValues(data: any) {
    this.readOnly = data.readOnly;
    this.responseReadOnly = data.readOnly;
    this.prepareMonthList(data.payrollMonth, data.careHomePayrollDay);
    this.id = data.payrollId;
    this.status = data.acceptanceStatus;
    this.acceptanceMessage = data.acceptanceMessage;
    this.form.patchValue({
      employeeId: data.employeeId,
      typeId: data.typeId,
      roleId: data.roleId,
      timeAmount: data.timeAmount,
      value: data.value,
      comments: data.comments,
      payrollMonth: this.defaultMonth,
      eventDate: data.eventDate,
      isAdvance: data.isAdvance,
    });
    if (this.readOnly) {
      this.form.disable();
      this.isAdvanceControlDisabled = true;
    }

    const typeId = this.types.find((e) => e.wordFullName === "Assigned AL")?.id;
    if (typeId) {
      this.isALFromBackend = typeId === data.typeId;
    }
    // this.toggleIsAdvance(data.isAdvance);
  }

  onAdjustmentPick() {
    const adjustmentId = this.form.get("typeId").value;
    const formTimeAmount = this.form.get("timeAmount");

    for (let i = 0; i < this.types.length; i++) {
      const element = this.types[i];

      if (element.wordFullName === "Advance" && element.id === adjustmentId) {
        formTimeAmount.setValue("1:00");
        formTimeAmount.disable();
        this.isAdjustmentAdvancePicked = true;
        break;
      } else {
        formTimeAmount.enable();
        this.isAdjustmentAdvancePicked = false;
      }
    }
  }

  acceptPayroll() {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: "Accept confirmation",
          message: "Are you sure you want to accept this payroll?",
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          this.subscription.add(
            this.payrollService
              .updatePayrollStatus(this.id, { status: "Accepted" })
              .subscribe(
                (response: any) => {
                  this.toastrService.success(response.message, "Success");
                  this.status = "Accepted";
                },
                (err) => {
                  this.toastrService.danger(getErrorMessage(err), "Error", {
                    duration: 60000,
                    destroyByClick: true,
                  });
                },
              ),
          );
        }
      });
  }

  changeEmailOnSave(event: any) {
    this.form.patchValue({
      isEmailOnSave: event.target.checked ? true : false,
    });
    if (this.responseReadOnly && this.readOnly) {
      this.lockedEmailSave = event.target.checked;
    }
  }

  isEditMode() {
    return (
      this.tableService.getValue() &&
      this.tableService.getValue().mode === FormMode.EDIT
    );
  }

  trackByFn(index, item) {
    return index;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private prepareMonthList(date: any, payrollDay: any) {
    this.monthList = [];
    let addMonthsStart = 3;
    const payrollDate =
      moment(date, "YYYY/MM/DD").format("YYYY/MM") + "/" + payrollDay;
    const payrollMonth = moment(date, "YYYY/MM/DD").format("DD");
    const payrollDayNumber = parseInt(payrollDay, 10);
    const dayNumber = parseInt(payrollMonth, 10);

    const isDateAfterPayroll = dayNumber >= payrollDayNumber;

    if (isDateAfterPayroll) {
      addMonthsStart = 2;
    }

    const startDate = moment(payrollDate, "YYYY/MM/DD").subtract(
      addMonthsStart,
      "months",
    );
    const endDate = moment(payrollDate, "YYYY/MM/DD")
      .subtract(addMonthsStart - 1, "months")
      .subtract(1, "days");
    for (let i = 0; i < 7; i++) {
      const startMonthToInsert = startDate.add(1, "months");
      const endMonthToInsert = endDate.add(1, "months");
      const dateSend = moment(endMonthToInsert);
      const monthFormatted = `${startMonthToInsert.format(
        "YYYY/MM/DD",
      )} - ${endMonthToInsert.format("YYYY/MM/DD")}`;
      this.monthList.push({
        dateShow: `${monthFormatted} ${
          i === 0 ? " (previous wages)" : i === 1 ? " (active wages)" : ""
        }`,
        dateSend: dateSend.format("YYYY/MM/DD"),
      });
    }

    this.defaultMonth = this.monthList[1].dateSend;
  }

  get remainingCounter(): string {
    return `${
      this.form?.get("comments")?.value
        ? this.form?.get("comments")?.value?.length
        : 0
    } / 1000`;
  }

  get filterTypes() {
    return this.isALFromBackend
      ? this.types
      : this.types.filter((e) => e.wordFullName !== "Assigned AL");
  }


  public isDateInTheFuture(): boolean {
    return moment(this.form.get("eventDate")?.value).isAfter(moment(), "day");
  }

  public saveIfDateIsInTheFuture(): void {
    this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: "Confirmation",
          message:
            "Selected Event date is in the future. Are you sure you want to proceed?",
        },
      })
      .onClose.subscribe((decision: boolean) => {
        if (decision) {
          this.saveData();
        }
      });
  }
}
