import { Component, OnDestroy } from '@angular/core';
import { VersionService } from '../../../shared/version.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();
  public version: string;

  constructor(private versionService: VersionService) {
    this.subscription.add(this.versionService.version$.subscribe((apiVersion: any) => {
      this.version = `${apiVersion.realeseDate} f:${environment.apiVersion}, b:${apiVersion.apiActualVersion}`;
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
