<main
  *ngIf="isHeaderCell"
  [ngClass]="{
    weekend: date.format('dd') === 'Su' || date.format('dd') === 'Sa',
    today: isToday(date)
  }"
  class="border-top"
>
  <div class="name">{{ date.format("dd") }}</div>
  <div class="number">{{ date.format("D") }}</div>
</main>
<div
  class="popup-fallback-bg"
  (click)="closeCellDropdown()"
  *ngIf="isEmpty && isCellDropdownVisible && !hideAlButton"
></div>
<main
  *ngIf="!isHeaderCell"
  [ngClass]="{ today: isToday(date), 'relative-pos': isEmpty }"
  (click)="openCellOptions($event)"
>
  <nav
    class="employee-popup-menu employee-popup-menu--right"
    [ngClass]="{leftMenu: isLastFive(date)}"
    *ngIf="isEmpty && isCellDropdownVisible && !hideAlButton"
  >
    <div
      *ngIf="permissions.MENU_SICKNESS && !hideAlButton"
      (click)="addSickness()"
      class="cell-option"
    >
      Add Sickness
    </div>
    <div *ngIf="!permissions.MENU_SICKNESS && !hideAlButton" class="disabled cell-option">
      Add Sickness
    </div>
    <div
      *ngIf="permissions.MENU_ANNUAL_LEAVES && !hideAlButton"
      (click)="addAnnualLeaving()"
      class="cell-option"
    >
      Add Annual Leaving
    </div>
    <div *ngIf="!permissions.MENU_ANNUAL_LEAVES && !hideAlButton" class="disabled cell-option">
      Add Annual Leaving
    </div>
  </nav>

  <div
    *ngIf="event"
    class="bar"
    [ngClass]="{
      first: isStartCell,
      last: isEndCell,
      'first-and-last': isStartCell && isEndCell,
      sickness:
        event &&
        event.eventType &&
        event.eventType.wordFullName == EventType.SICKNESS,
      ro:
        event &&
        event.eventType &&
        event.eventType.wordFullName == EventType.REQUEST_OFF,
      al:
        event &&
        event.eventType &&
        (event.eventType.wordFullName == EventType.ANNUAL_LEAVE ||
          event.eventType.wordFullName == EventType.MATERNITY_LEAVE),
      'to-accept': event && event.requiresAcceptance
    }"
    [nbPopover]="event ? popover : null"
    nbPopoverPlacement="bottom"
  >
    <span *ngIf="isStartCell || isEndCell">
      <span>{{ event.eventType.wordShortName }}</span>
    </span>
  </div>
  <div
    *ngIf="!event"
    class="bar"
    [ngClass]="{
      first: isStartCell,
      last: isEndCell,
      'first-and-last': isStartCell && isEndCell,
      sickness:
        event &&
        event.eventType &&
        event.eventType.wordFullName == EventType.SICKNESS,
      ro:
        event &&
        event.eventType &&
        event.eventType.wordFullName == EventType.REQUEST_OFF,
      al:
        event &&
        event.eventType &&
        (event.eventType.wordFullName == EventType.ANNUAL_LEAVE ||
          event.eventType.wordFullName == EventType.MATERNITY_LEAVE),
      'to-accept': event && event.requiresAcceptance
    }"
  >
    <span *ngIf="isStartCell || isEndCell">
      <span>{{ event.eventType.wordShortName }}</span>
    </span>
  </div>
</main>

<ng-template #popover>
  <div
    class="event-popover"
    [ngClass]="{
      sickness:
        event &&
        event.eventType &&
        event.eventType.wordFullName == EventType.SICKNESS,
      al:
        event &&
        event.eventType &&
        (event.eventType.wordFullName == EventType.ANNUAL_LEAVE ||
          event.eventType.wordFullName == EventType.MATERNITY_LEAVE ||
          event.eventType.wordFullName == EventType.REQUEST_OFF),
      'to-accept': event && event.requiresAcceptance
    }"
  >
    <header>
      <section>
        <div>Type</div>
        <p>{{ event.eventType.wordFullName }}</p>
      </section>
      <section>
        <div>Start</div>
        <p>{{ getDate(event.startDate) }}</p>
      </section>
      <section>
        <div>End</div>
        <p>{{ getDate(event.endDate) }}</p>
      </section>
      <section>
        <div>Hours requested</div>
        <p>{{ event.givenHrs }}</p>
      </section>
      <section>
        <div>Status</div>
        <p>{{ event.statusId }}</p>
      </section>
    </header>
    <content>
      <section>
        <div>Description</div>
        <p>{{ event.description }}</p>
      </section>
      <div class="flex" style="justify-content: space-between">
        <button
          class="btn btn-info see-more"
          [disabled]="!permissions.MENU_ANNUAL_LEAVES"
          (click)="showEvent()"
        >
          See more
        </button>
        <div *ngIf="event.requiresAcceptance" class="flex">
          <button
            class="btn btn-success"
            [disabled]="!permissions.MENU_ANNUAL_LEAVES"
            (click)="acceptEvent()"
          >
            Accept
          </button>
          <button
            class="btn btn-danger ml-2"
            [disabled]="!permissions.MENU_ANNUAL_LEAVES"
            (click)="rejectEvent()"
          >
            Reject
          </button>
        </div>
      </div>
    </content>
  </div>
</ng-template>
