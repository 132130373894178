import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { DictionariesApiUrls } from "./dictionaries.enum";
import { Cacheable } from "ngx-cacheable";
import { Observable } from "rxjs";
import {
  DictionariesAgenciesModel,
  DictionariesAgencyPostModel,
} from "../models/dictionaries/agencies/dictionaries-agencies-model";
import { IHttpSuccessResponse } from "../models/http-success-reposonse.interfaces";
import { map } from "rxjs/operators";
import { DictionariesAgenciesCommand } from "../models/dictionaries/agencies/dictionaries-agencies-command";
import { DictionariesStuffsModel } from "../models/dictionaries/stuff/dictionaries-stuff.model";

@Injectable()
export class DictionariesService {
  constructor(private httpClient: HttpClient) {}

  @Cacheable()
  getContractTypes() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.contractTypes}`,
    );
  }

  // @Cacheable() Do not cach it please..
  getProfessionalRoles(
    isContracted?: boolean,
    teamUid?: string,
  ): Observable<any> {
    let params = new HttpParams();
    if (isContracted) params = params.append("isContracted", isContracted);
    if (teamUid) params = params.append("teamUid", teamUid);

    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.professionalRoles}`,
      { params },
    );
  }

  // @Cacheable() Do not cach it please..
  getProfessionalRolesForCare(careHomeId: any): Observable<any> {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.professionalRoles}?careHomeId=${careHomeId}`,
    );
  }

  getProfessionalRolesForPayrollAdjustment() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.professionalRoles}?isPayrollAdjustment=true`,
    );
  }

  // @Cacheable()
  getProfessionalRolesActiveOnlyForMessages(
    careHomeId: any,
    activeOnlyForMessages?: any,
    type?: string,
  ): Observable<any> {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.professionalRoles}?careHomeId=${careHomeId}&activeOnlyForMessages=${activeOnlyForMessages}&type=${type}`,
    );
  }

  @Cacheable()
  getGenders() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.genders}`,
    );
  }

  @Cacheable()
  getTitles() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.titles}`,
    );
  }

  @Cacheable()
  getMaritalStatuses() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.maritalStatus}`,
    );
  }

  @Cacheable()
  getStatuses() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.statuses}`,
    );
  }

  @Cacheable()
  getUserStatuses() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.userStatuses}`,
    );
  }

  @Cacheable()
  getEmployeeStatuses() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.employeeStatuses}`,
    );
  }

  @Cacheable()
  getUserPermissions() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.userPermissions}`,
    );
  }

  @Cacheable()
  getPermissions() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.permissions}`,
    );
  }

  @Cacheable()
  getEventTypes() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.eventTypes}`,
    );
  }

  @Cacheable()
  getCourseStatuses() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.courseStatuses}`,
    );
  }

  @Cacheable()
  getBreads() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.breads}`,
    );
  }

  @Cacheable()
  getMealSizes() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.mealSizes}`,
    );
  }

  getTaskCategories() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.taskCategories}`,
    );
  }

  getPinnedTasks() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.pinnedTasks}`,
    );
  }

  @Cacheable()
  getTaskPriorities() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.taskPriorities}`,
    );
  }

  @Cacheable()
  getTaskStatuses() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.taskStatuses}`,
    );
  }

  getUncachedTaskStatuses() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.taskStatuses}`,
    );
  }

  @Cacheable()
  getTaskStatusesAll() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.taskStatusesAll}`,
    );
  }

  @Cacheable()
  getPayrollTypes() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.payrollTypes}`,
    );
  }

  @Cacheable()
  getResidencyStatuses() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.residencyStatuses}`,
    );
  }

  @Cacheable()
  getP45Values() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.p45Values}`,
    );
  }

  @Cacheable()
  getStudentLoanTypes() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.studentLoanTypes}`,
    );
  }

  @Cacheable()
  getEmployeeStatements() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.employeeStatements}`,
    );
  }

  @Cacheable()
  getPayrollStatuses() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.payrollStatuses}`,
    );
  }

  @Cacheable()
  getMedicalUrgencies() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.medicalUrgencies}`,
    );
  }

  @Cacheable()
  getTrainingCategories() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.trainingCategories}`,
    );
  }

  @Cacheable()
  getTrainingCategoriesFilters() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.trainingCategoriesFilters}`,
    );
  }

  getTypeOfFinancing() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.typeOfFinancing}`,
    );
  }

  getSecurityLevels() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.securityLevels}`,
    );
  }

  getUserProfiles() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.userProfiles}`,
    );
  }

  getMessageGroups() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.messageGroups}`,
    );
  }

  @Cacheable()
  getCacheableTaskTags() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.taskTags}`,
    );
  }

  getTaskTags() {
    return this.httpClient.get(
      `${environment.apiUrl}${DictionariesApiUrls.taskTags}`,
    );
  }

  getDictionaryByName(name: string, takeAll = false) {
    return this.httpClient.get(
      `${environment.apiUrl}/dictionaries?dictionaryFullName=${name}&takeAll=${takeAll}`,
    );
  }

  getDictionaryByShortName(name: string) {
    return this.httpClient.get(
      `${environment.apiUrl}/dictionaries?dictionaryShortName=${name}`,
    );
  }

  updateDictionaryWord(dictionaryId: number, wordId: number, data: any) {
    return this.httpClient.put(
      `${environment.apiUrl}/dictionaries/${dictionaryId}/words/${wordId}`,
      data,
    );
  }

  addDictionaryWord(dictionaryId: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}/dictionaries/${dictionaryId}/words`,
      data,
    );
  }

  deleteTaskTag(dictionaryId: number) {
    return this.httpClient.delete(
      `${environment.apiUrl}/dictionaries/task-tags/${dictionaryId}`,
    );
  }

  public getAgenciesDictionary(
    careHomeId: number,
  ): Observable<DictionariesAgenciesModel> {
    return this.httpClient
      .get<IHttpSuccessResponse<DictionariesAgenciesModel>>(
        `${environment.apiUrl}${DictionariesApiUrls.agency}?careHomeId=${careHomeId}`,
      )
      .pipe(map((item) => item.result));
  }

  public updateAgenciesDictionary(
    body: DictionariesAgenciesCommand,
  ): Observable<DictionariesAgencyPostModel> {
    return this.httpClient
      .post<IHttpSuccessResponse<DictionariesAgencyPostModel>>(
        `${environment.apiUrl}${DictionariesApiUrls.agency}`,
        body,
      )
      .pipe(map((item) => item.result));
  }

  public deleteAgencyFromDictionary(
    agencyId: number,
  ): Observable<IHttpSuccessResponse<any>> {
    return this.httpClient.delete<IHttpSuccessResponse<any>>(
      `${environment.apiUrl}${DictionariesApiUrls.agency}/${agencyId}`,
    );
  }

  getStuffForCare(
    careHomeId: number,
  ): Observable<IHttpSuccessResponse<DictionariesStuffsModel>> {
    return this.httpClient.get<IHttpSuccessResponse<DictionariesStuffsModel>>(
      `${environment.apiUrl}${DictionariesApiUrls.stuff}?careHomeId=${careHomeId}`,
    );
  }

  getStuffForCareAndRole(
    careHomeId: number,
    typeId: number,
  ): Observable<IHttpSuccessResponse<DictionariesStuffsModel>> {
    return this.httpClient.get<IHttpSuccessResponse<DictionariesStuffsModel>>(
      `${environment.apiUrl}${DictionariesApiUrls.stuff}?careHomeId=${careHomeId}&typeId=${typeId}`,
    );
  }

  getStuffForCareRoleAndConfirmationUid(
    careHomeId: number,
    typeId: number,
    confirmationUid: string,
  ): Observable<IHttpSuccessResponse<DictionariesStuffsModel>> {
    return this.httpClient.get<IHttpSuccessResponse<DictionariesStuffsModel>>(
      `${environment.apiUrl}${DictionariesApiUrls.stuff}?careHomeId=${careHomeId}&typeId=${typeId}&confirmationUid=${confirmationUid}`,
    );
  }

  addStuff(stuff: {
    careHomeId: number;
    name: string;
    // description: string;
    typeId: number[];
    groupId: number;
    isObligatory: boolean;
  }) {
    const payload = {
      ...stuff,
      isObligatory: new Array(stuff.typeId.length).fill(stuff.isObligatory),
    };
    return this.httpClient.post(
      `${environment.apiUrl}${DictionariesApiUrls.stuff}`,
      payload,
    );
  }

  deleteStuff(stuffId: number) {
    return this.httpClient.delete(
      `${environment.apiUrl}${DictionariesApiUrls.stuff}/${stuffId}`,
    );
  }

  updateStuff(
    stuffId: number,
    payload: Partial<{
      careHomeId: number;
      name: string;
      description: string;
      typeId: number[];
      groupId: number;
      isObligatory: boolean[];
    }>,
  ) {
    return this.httpClient.patch(
      `${environment.apiUrl}${DictionariesApiUrls.stuff}/${stuffId}`,
      payload,
    );
  }

  getStuffGroups(): Observable<DictionariesAgenciesModel> {
    return this.httpClient
      .get<IHttpSuccessResponse<DictionariesAgenciesModel>>(
        `${environment.apiUrl}${DictionariesApiUrls.stuffGroups}`,
      )
      .pipe(map((item) => item.result));
  }

  addStuffGroup(
    payload,
    dictionaryId: number,
  ): Observable<{ message: string; result: number }> {
    return this.httpClient.post<{ message: string; result: number }>(
      `${environment.apiUrl}/dictionaries/${dictionaryId}/words`,
      payload,
    );
  }

  updateStuffGroup(payload, dictionaryId: number, id: number) {
    return this.httpClient.put(
      `${environment.apiUrl}/dictionaries/${dictionaryId}/words/${id}`,
      payload,
    );
  }

  deleteStuffGroup(dictionaryId, id) {
    return this.httpClient.delete(
      `${environment.apiUrl}/dictionaries/${dictionaryId}/words/${id}`,
    );
  }
}
