import { Component, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { OnlyTableComponent } from '../only-table.component';
import { TableService } from '../../table.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'ngx-horizontal-sticky-only-table',
  templateUrl: './horizontal-sticky-only-table.component.html',
  styleUrls: ['../only-table.component.scss']
})
export class HorizontalStickyOnlyTableComponent extends OnlyTableComponent implements OnChanges {
  @Input() highHeader = true;
  @Input() onHeaderClick: (event: any) => any;
  @Input() onTextareaFocusOut: (event: any) => any;
  @Input() actions: [];
  @ViewChild('hiddenButton', { static: true }) hiddenButton: ElementRef<HTMLElement>;

  private element: any;
  public firstColumn: any;
  public cutedColumns = [];
  constructor(tableService: TableService) {
    super(tableService);
    this.showIndexColumn = false;
  }

  ngOnChanges() {
    const columns = [];
    this.firstColumn = this.columns[0];
    for (const column of this.columns) {
      columns.push(column.fieldName);
    }
    this.cutedColumns = this.columns;
    this.cutedColumns.shift();

    this.dataSource = new MatTableDataSource(this.data);
    this.displayedColumns = columns;
    this.dataSource.sort = this.sort;
  }

  hidePopoverAndExecuteAction(click: any) {
    const hiddenButton: HTMLElement = this.hiddenButton.nativeElement;
    hiddenButton.click();
    click(this.element);
  }

  setElementToPass(element: any) {
    this.element = element;
  }
}
