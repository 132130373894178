export const errorMessages = [
  {
    field: 'name',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Minimum 3 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'urlEmar',
    errors: [
      {
        error: 'pattern',
        message: 'Provide valid url (https://...)'
      }
    ]
  },
  {
    field: 'urlEmarName',
    errors: [
      {
        error: 'minlength',
        message: 'Minimum 3 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 10 characters'
      }
    ]
  },
  {
    field: 'urlCarePlans',
    errors: [
      {
        error: 'pattern',
        message: 'Provide valid url (https://...)'
      }
    ]
  },
  {
    field: 'urlCarePlansName',
    errors: [
      {
        error: 'minlength',
        message: 'Minimum 3 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 10 characters'
      }
    ]
  },
  {
    field: 'nameShort',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Minimum 2 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'houseNumber',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Minimum 1 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 10 characters'
      }
    ]
  },
  {
    field: 'street',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Minimum 3 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'city',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Minimum 3 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'state',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Minimum 2 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'email',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'email',
        message: 'Must be a valid email'
      },
      {
        error: 'minlength',
        message: 'Minimum 3 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 50 characters'
      }
    ]
  },
  {
    field: 'phoneNumber',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'phoneNumber',
        message: 'Must contain numbers and dashes only'
      },
      {
        error: 'minlength',
        message: 'Minimum 3 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 25 characters'
      }
    ]
  },
  {
    field: 'zipCode',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Minimum 3 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 25 characters'
      }
    ]
  },
  {
    field: 'country',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Minimum 2 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 25 characters'
      }
    ]
  },
  {
    field: 'holidayYearStart',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'invalidDate',
        message: 'Invalid date format'
      }
    ]
  },
  {
    field: 'deputyManager',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'careHomeId',
    errors: [
      {
        error: 'required',
        message: 'Care Home is required'
      }
    ]
  },
  {
    field: 'changeDate',
    errors: [
      {
        error: 'required',
        message: 'Migration start date is required'
      }
    ]
  },
  {
    field: 'careHomeManager',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'weekStart',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'transportCompany',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Minimum 3 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 3 characters'
      }
    ]
  },
  {
    field: 'transportCompany2',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Minimum 3 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 3 characters'
      }
    ]
  },
  {
    field: 'transportCompanyPhone',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'phoneNumber',
        message: 'Must be a valid phone number'
      }
    ]
  },
  {
    field: 'transportCompany2Phone',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'phoneNumber',
        message: 'Must be a valid phone number'
      }
    ]
  },
  {
    field: 'escalationName',
    errors: [
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
  {
    field: 'escalationPhone',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'phoneNumber',
        message: 'Must be a valid phone number'
      }
    ]
  },
  {
    field: 'rotaFreezeDate',
    errors: [
      {
        error: 'invalidDate',
        message: 'Invalid date format'
      }
    ]
  },
  {
    field: 'wagesDate',
    errors: [
      {
        error: 'invalidDate',
        message: 'Invalid date format'
      }
    ]
  },
  {
    field: 'payrollBuffer',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
    ]
  },
  {
    field: 'stpb',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Minimum 1 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 10 characters'
      }
    ]
  },
  {
    field: 'stnb',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Minimum 1 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 10 characters'
      }
    ]
  },
  {
    field: 'etpb',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Minimum 1 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 10 characters'
      }
    ]
  },
  {
    field: 'etnb',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Minimum 1 characters'
      },
      {
        error: 'maxlength',
        message: 'Maximum 10 characters'
      }
    ]
  },
  {
    field: 'maxShiftAmount',
    errors: [
      {
        error: 'required',
        message: 'Required'
      },
      {
        error: 'minlength',
        message: 'Minimum 1 characters'
      },
      {
        error: 'pattern',
        message: 'Numbers and :'
      }
    ]
  },
  {
    field: 'workingDays',
    errors: [
      {
        error: 'min',
        message: 'Min of 1 working day per week'
      },
      {
        error: 'max',
        message: 'Max of 7 working days per week'
      },
      {
        error: 'required',
        message: 'Required'
      }
    ]
  },
];
