import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabsetService {
  public selectTab$ = new Subject();
  public closeTab$ = new Subject();
}
