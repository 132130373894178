import { OnInit, OnDestroy, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { TableService } from '../../../shared/table/table.service';
import { LoadingStatus } from '../../../shared/loading-status.enum';
import { getDateHeaders, transformDataViewer, getStaffDateHeadersWithTransform } from '../admin-analyzer-utils';
import { TaskTab } from '../../../task-management/tasks/task-tab.enum';
import { EditTaskComponent } from '../../../task-management/tasks/edit-task/edit-task.component';
import { FormMode } from '../../../../utilities/form-mode.enum';
import { AnalyzerService } from '../../../../shared/dashboard/analyzer.service';
import { getServerLink, getErrorMessage } from '../../../../utilities/utils';
import { NbToastrService } from '@nebular/theme';
import { AuthUserService } from '../../../../@core/data/auth-user.service';

const headerFieldName = 'header_';
@Component({
  selector: 'ngx-admin-analyzer-details',
  templateUrl: './admin-analyzer-details.component.html',
  styleUrls: ['./admin-analyzer-details.component.scss']
})
export class AdminAnalyzerDetailsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public LoadingStatus: typeof LoadingStatus = LoadingStatus;
  public loaded = LoadingStatus.NOT_LOADED;
  public data = [];
  public dataStream: any;
  public careHomeName = '';
  public actualWeek = 5;
  public isStaffHours = false;
  public isBoards = false;
  public staffTasksData = [];
  public viewParams: any;
  public isXLSEnabled = false;
  public isOnlyRefreshAvailable = true;

  public columns = [
    {
      fieldName: 'taskBoardName',
      title: 'Board',
    },
    {
      fieldName: 'title',
      title: 'Title',
      class: 'text-left'
    },
    {
      fieldName: 'description',
      title: 'Description',
    },
    {
      fieldName: 'latestUpdate',
      title: 'Latest update',
    },
    {
      fieldName: 'shortHistory',
      title: 'Submitted update',
    },
    {
      fieldName: 'status',
      title: 'State',
    }
  ];

  constructor(private tableService: TableService,
              private analyzerService: AnalyzerService,
              private toastrService: NbToastrService,
              private authUserService: AuthUserService) {}

  ngOnInit() {
    this.viewParams = this.tableService.getValue().parameters || this.tableService.getValue();
    this.careHomeName = this.viewParams.careHomeName;
    this.isStaffHours = this.viewParams.isStaffHours;
    this.isBoards = this.viewParams.isBoards;
    this.isXLSEnabled = this.viewParams.isXLSEnabled;
    this.dataStream = this.viewParams.dataStream(this.actualWeek);
    if (this.authUserService.getWeeksForOccupancy() !== null) {
      this.actualWeek = parseInt(this.authUserService.getWeeksForOccupancy(), 10);
    }
    this.load();
    if (this.viewParams.backstackEnabled) {
      this.scrollToTaskBoardContainer();
    }
    this.viewParams.backstackEnabled = true;
  }

  load() {
    this.authUserService.setWeeksForOccupancy(this.actualWeek);
    this.subscription.add(this.viewParams.dataStream(this.actualWeek).subscribe((response: any) => {
      this.staffTasksData = response.result.taskList;
      this.transformTable(this.viewParams.categories, response.result.weekList);
    }));
  }

  transformTable(categories: any, dataSource: any) {
    if (this.isBoards) {
      this.transformBoardsDetails(dataSource);

      if (categories) {
        this.assignCategories(categories);
        this.isOnlyRefreshAvailable = true;
      }
    } else {
      this.columns = Object.assign([], getDateHeaders(headerFieldName, dataSource));
      this.data = Object.assign([], transformDataViewer(headerFieldName, categories, dataSource));
      if (this.isStaffHours) {
        this.columns = Object.assign([], getStaffDateHeadersWithTransform(headerFieldName, dataSource));
      }
    }
    this.loaded = this.LoadingStatus.LOADED;
  }

  transformBoardsDetails(dataSource: any) {
    this.data = [];
    this.data = dataSource;
  }

  assignCategories(categories: any) {
    this.columns = categories;
  }

  subtractWeek() {
    if (this.actualWeek > 1) {
      this.actualWeek--;
    }
    this.load();
  }

  addWeek() {
    if (this.actualWeek < 52) {
      this.actualWeek++;
    }
    this.load();
  }

  edit = (row: any) => {
    this.tableService.openWindowWithBackStack(EditTaskComponent, `Edit Task - ${row.taskBoardName} "${row.title}"`,
    {
      component: AdminAnalyzerDetailsComponent,
      title: `${this.careHomeName} - staff hours details`,
      elementId: 1,
      parameters: this.viewParams
    },
    {
      mode: FormMode.EDIT,
      elementId: row.taskId,
      activeTab: TaskTab.INFO,
      disableTaskCopy: true
    }, 'edit-task-window');
  }

  downloadXls() {
    this.subscription.add(this.analyzerService.downloadDetailsReport(this.viewParams.date, this.actualWeek, this.viewParams.careHomeId)
      .subscribe((response: any) => {
        window.open(getServerLink(response.link), '_blank');
    },
    (err) => {
      this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
    }));
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private scrollToTaskBoardContainer() {
    setTimeout(() => {
      document.getElementById('taskboard-container').scrollIntoView();
    }, 100);
  }
}
