<div class="form-view">
  <main>
    <form [formGroup]="form" autocomplete="off">
      <div class="form-control-group">
        <label class="label">Reason for leaving:</label>
        <input nbInput
            fullWidth
            formControlName="reasonForLeaving"
            placeholder="reason for leaving"
            minlength="1"
            maxlength="50"
            autofocus>
        <p class="error-message">
          {{ utils.handleValidationErrorMessage(form, 'reasonForLeaving', errorMessages) }}
        </p>
      </div>
      <div class="flex-container-horizontal">
        <div class="flex">
          <div class="form-control-group">
            <label class="label">Date Left/passed away:</label>
            <input nbInput
                  fullWidth
                  formControlName="dateLeft"
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="dateLeft"
                  [max]="leftMaxDate"
                  minlength="3"
                  maxlength="50">
                <mat-datepicker-toggle matSuffix [for]="dateLeft"></mat-datepicker-toggle>
                <mat-datepicker #dateLeft startView="month"></mat-datepicker>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'dateLeft', errorMessages) }}
            </p>
          </div>
        </div>
        <div class="flex">
          <div class="form-control-group">
            <label class="label">Date room cleared:</label>
            <input nbInput
                  fullWidth
                  formControlName="dateRoomCleared"
                  placeholder="DD/MM/YYYY"
                  [matDatepicker]="dateRoomCleared"
                  minlength="3"
                  maxlength="50">
                <mat-datepicker-toggle matSuffix [for]="dateRoomCleared"></mat-datepicker-toggle>
                <mat-datepicker #dateRoomCleared startView="month"></mat-datepicker>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'dateRoomCleared', errorMessages) }}
            </p>
          </div>
          <div>
            <nb-checkbox formControlName="dateRoomClearedToCome"></nb-checkbox>
          </div>
        </div>
      </div>

      <div class="flex-container-horizontal">
        <div class="flex">
          <div class="form-control-group">
            <label class="label">Sundries still to charge for: <span nbTooltip="That should be deducted by BACS"><i class="nb-info" aria-hidden="true"></i></span></label>
            <input nbInput
                  fullWidth
                  formControlName="sundriesToCharge"
                  placeholder="Sundries still to charge for"
                  minlength="1"
                  maxlength="25"
                  autofocus>
            <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'sundriesToCharge', errorMessages) }}
            </p>
          </div>
          <div>
            <nb-checkbox formControlName="sundriesToChargeToCome"></nb-checkbox>
          </div>
        </div>
        <div class="flex">
          <div class="form-control-group">
            <label class="label">Resident cash held to reimburse via BACS:</label>
            <input nbInput
                    fullWidth
                    formControlName="residentCashToReimburse"
                    placeholder="Resident cash held to reimburse via BACS"
                    autofocus>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'residentCashToReimburse', errorMessages) }}
            </p>
          </div>
          <div>
            <nb-checkbox formControlName="residentCashToReimburseToCome"></nb-checkbox>
          </div>
        </div>
      </div>
      
      <div class="flex-container-horizontal mt-4">
        <div class="flex-container-2">
          <div class="flex-container">
            <div class="flex">
              <div class="form-control-group">
                <label class="label" for="input-positionId">NOK to receive a refund:</label>
                <select formControlName="nokToReceiveRefund">
                  <option *ngFor="let nok of noks" [ngValue]="nok.id">{{nok.name}}</option>
                </select>
                <p class="error-message">
                  {{ utils.handleValidationErrorMessage(form, 'nokToReceiveRefund', errorMessages) }}
                </p>
              </div>
              <div>
                <nb-checkbox formControlName="contactPersonToCome"></nb-checkbox>
              </div>
            </div>
          </div>
          <div class="flex-container-horizontal-2">
            <div class="form-control-group">
              <label class="label">First Name:</label>
              <input nbInput
                      fullWidth
                      formControlName="firstName"
                      placeholder="First Name"
                      minlength="1"
                      maxlength="50"
                      autofocus>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'firstName', errorMessages) }}
              </p>
            </div>
            <div class="form-control-group">
              <label class="label">Surname:</label>
              <input nbInput
                      fullWidth
                      formControlName="surname"
                      placeholder="Surname"
                      minlength="1"
                      maxlength="50"
                      autofocus>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'surname', errorMessages) }}
              </p>
            </div>
          </div>
          <div class="flex-container-horizontal">
            <div class="form-control-group">
              <label class="label">Phone number:</label>
              <input nbInput
                      fullWidth
                      formControlName="phoneNumber"
                      placeholder="Phone number"
                      minlength="1"
                      maxlength="50"
                      autofocus>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'phoneNumber', errorMessages) }}
              </p>
            </div>
            <div class="form-control-group">
              <label class="label">Email:</label>
              <input nbInput
                      fullWidth
                      formControlName="email"
                      placeholder="Email"
                      minlength="1"
                      maxlength="50"
                      autofocus>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'email', errorMessages) }}
              </p>
            </div>
          </div>
        </div>
        
        <div class="flex-container-2">
          <div class="flex-container-horizontal-2">
            <div class="form-control-group">
              <label class="label">House No.:</label>
              <input nbInput
                      fullWidth
                      formControlName="houseNumber"
                      placeholder="House No."
                      minlength="1"
                      maxlength="20"
                      autofocus>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'houseNumber', errorMessages) }}
              </p>
            </div>
            <div class="form-control-group">
              <label class="label">Street:</label>
              <input nbInput
                      fullWidth
                      formControlName="street"
                      placeholder="Street"
                      minlength="1"
                      maxlength="50"
                      autofocus>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'street', errorMessages) }}
              </p>
            </div>
          </div>
          <div class="flex-container-horizontal">
            <div class="form-control-group">
              <label class="label">City:</label>
              <input nbInput
                    fullWidth
                    formControlName="city"
                    placeholder="City"
                    minlength="1"
                    maxlength="50"
                    autofocus>
              <p class="error-message">
                  {{ utils.handleValidationErrorMessage(form, 'city', errorMessages) }}
              </p>
            </div>
            <div class="form-control-group">
              <label class="label">County:</label>
              <input nbInput
                      fullWidth
                      formControlName="county"
                      placeholder="County"
                      autofocus>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'county', errorMessages) }}
              </p>
            </div>
          </div>
          <div class="flex-container-horizontal">
            <div class="form-control-group">
              <label class="label">Postal Code:</label>
              <input nbInput
                      fullWidth
                      formControlName="zipCode"
                      placeholder="Postal Code"
                      minlength="1"
                      maxlength="25"
                      autofocus>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'zipCode', errorMessages) }}
              </p>
            </div>
            <div class="form-control-group">
              <label class="label">Country Code:</label>
              <input nbInput
                      fullWidth
                      formControlName="country"
                      placeholder="Country Code"
                      minlength="1"
                      maxlength="2"
                      autofocus>
              <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'country', errorMessages) }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-container-horizontal">
        <div class="flex">
          <div class="form-control-group">
            <label class="label">Sort code:</label>
            <input nbInput
                  fullWidth
                  formControlName="sortCode"
                  placeholder="Sort code"
                  minlength="1"
                  maxlength="25"
                  autofocus>
            <p class="error-message">
                {{ utils.handleValidationErrorMessage(form, 'sortCode', errorMessages) }}
            </p>
          </div>
          <div>
            <nb-checkbox formControlName="sortCodeToCome"></nb-checkbox>
          </div>
        </div>
        <div class="flex">
          <div class="form-control-group">
            <label class="label">Account Number:</label>
            <input nbInput
                    fullWidth
                    formControlName="accountNumber"
                    placeholder="Account Number"
                    autofocus>
            <p class="error-message">
              {{ utils.handleValidationErrorMessage(form, 'accountNumber', errorMessages) }}
            </p>
          </div>
          <div>
            <nb-checkbox formControlName="accountNumberToCome"></nb-checkbox>
          </div>
        </div>
      </div>

      <div class="flex-container">
        <div class="form-control-group">
          <label class="label" for="input-description">Description:</label>
          <textarea nbInput
                    fullWidth
                    formControlName="comments"
                    maxlength="255"
                    placeholder="Description">
          </textarea>
          <p class="error-message">
            {{ utils.handleValidationErrorMessage(form, 'comments', errorMessages) }}
          </p>
        </div>
      </div>
    </form>
  </main>
  <footer *ngIf="!financeTaskMode">
    <button class="btn btn-success"
            [disabled]="!utils.isFormValid(form)"
            (click)="save()">
      <i class="nb-checkmark" aria-hidden="true"></i> Save
    </button>
    <button class="btn btn-danger ml-4"
            (click)="closeWindow()">
      <i class="nb-close" aria-hidden="true"></i> Close
    </button>
  </footer>
</div>