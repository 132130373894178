import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  ViewChild,
} from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { NbIconModule } from "@nebular/theme";
import { DOCUMENT, NgIf } from "@angular/common";
import { PermissionsService } from "../../../../@core/data/permissions.service";
import { SideMenuOptionsService } from "./service/side-menu-options.service";
import { ApplicationPermissions } from "../../../../@core/data/application-permissions.enum";
import { RouterLink } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import { ConnectionPositionPair, OverlayModule } from "@angular/cdk/overlay";

@Component({
  selector: "side-menu-options-accordion",
  templateUrl: "./side-menu-options-accordion.component.html",
  styleUrls: ["./side-menu-options-accordion.component.scss"],
  standalone: true,
  imports: [MatExpansionModule, NbIconModule, NgIf, RouterLink, OverlayModule],
})
export class SideMenuOptionsAccordionComponent {
  private _isCompact: boolean = false;
  public isBeta = false;
  public isOpen = false;

  public positions = [
    new ConnectionPositionPair(
      { originX: "end", originY: "center" },
      { overlayX: "start", overlayY: "center" },
    ),
  ];

  public open() {
    this.isOpen = true;
  }

  public close() {
    this.isOpen = false;
  }

  public shouldCloseFromSource(event: MouseEvent) {
    if (!((event.relatedTarget as Element)?.id === "actions-card"))
      this.close();
  }
  public shouldCloseFromOverlay(event: MouseEvent) {
    if (!((event.relatedTarget as Element)?.id === "actions-source"))
      this.close();
  }

  constructor(
    private sideMenuOptionsService: SideMenuOptionsService,
    public permissionsService: PermissionsService,
  ) {
    this.isBeta = environment.beta;
  }

  @Input() set isCompacted(value: boolean) {
    this._isCompact = value;
    if (this._isCompact) {
      this.expanded = false;
    }
  }
  get isCompacted(): boolean {
    return this._isCompact;
  }

  addNewTask() {
    this.sideMenuOptionsService.addNewTask();
    this.close();
  }

  addNewInstance() {
    this.sideMenuOptionsService.addNewInstance();
    this.close();
  }

  goToPortable() {
    this.sideMenuOptionsService.goToPortable();
    this.close();
  }

  goToProuction() {
    this.sideMenuOptionsService.goToProduction();
    this.close();
  }

  sendMessageToSupport() {
    this.sideMenuOptionsService.sendMessageToSupport();
    this.close();
  }

  composeMessage() {
    this.sideMenuOptionsService.composeMessage();
    this.close();
  }

  public expanded: boolean = false;
  protected readonly ApplicationPermissions = ApplicationPermissions;
}
