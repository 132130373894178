// Angular
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Third party
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';

// Application services
import { EmployessService } from '../../../../shared/employess/employess.service';

// Application models
import { ITableColumn } from '../../../shared/table/interfaces/table.interfaces';
import { EmployeeTrainingBudgetModel } from '../../../../shared/employess/models/employee-training-budget-model';
import { getErrorMessage } from '../../../../utilities/utils';
import { EmployeeTrainingBudgetCommand } from '../../../../shared/employess/models/employee-training-budget-command';

@Injectable({
  providedIn: 'root',
})
export class EmployeeTrainingBudgetService {
  private _isDataLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _trainingBudget$: BehaviorSubject<EmployeeTrainingBudgetModel> = new BehaviorSubject<EmployeeTrainingBudgetModel>(null);

  constructor(private _employeeApiService: EmployessService, private _toastrService: NbToastrService, private _employeeTrainingBudgetService: EmployeeTrainingBudgetService) {}

  public get isDataLoading$(): Observable<boolean> {
    return this._isDataLoading$.asObservable();
  }

  public setIsDataLoading(value: boolean): void {
    this._isDataLoading$.next(value);
  }

  public get trainingBudget$(): Observable<EmployeeTrainingBudgetModel> {
    return this._trainingBudget$.asObservable();
  }

  public setTrainingBudget(value: EmployeeTrainingBudgetModel): void {
    this._trainingBudget$.next(value);
  }

  public loadData(employeeId: number): void {
    this.setIsDataLoading(true);
    this._employeeApiService
      .getEmployeeTrainingBudget(employeeId)
      .pipe(
        tap((response) => this.setTrainingBudget(response)),
        catchError((err: HttpErrorResponse) => {
          this._toastrService.danger(getErrorMessage(err), 'Error', {
            duration: 6000,
            destroyByClick: true,
          });
          throw err;
        }),
        finalize(() => this.setIsDataLoading(false))
      )
      .subscribe();
  }

  updateTrainingBudget(employeeId: number, trainingBudget: string, description: string): void {
    this.setIsDataLoading(true);

    const body: EmployeeTrainingBudgetCommand = {
      trainingBudget,
      description
    }

    this._employeeApiService.updateEmployeeTrainingBudget(employeeId, body)
      .pipe(
        tap(() => {
          this._toastrService.success('Training budget has been updated successfully', 'Success', {
            duration: 6000,
            destroyByClick: true,
          });
        }),
        catchError((err: HttpErrorResponse) => {
          this._toastrService.danger(getErrorMessage(err), 'Error', {
            duration: 6000,
            destroyByClick: true,
          });
          throw err;
        }),
        finalize(() => this.setIsDataLoading(false))
      ).subscribe();
  }

  public createTableColumns = (): ITableColumn[] => [
    {
      fieldName: 'roleName',
      title: 'Role name',
      sort: true,
    },
    {
      fieldName: 'trainingBudgetHrs',
      title: 'Training budget',
      sort: true,
    },
  ];
}
