import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
} from "@angular/forms";
import {
  getValidationStatus,
  handleValidationErrorMessage,
  isFormValid,
  getErrorMessage,
  validateFormControlsByName,
  getFullName,
  validateAtLeastOneCheckbox,
} from "../../../../utilities/utils";
import { TableService } from "../../../shared/table/table.service";
import { SicknessService } from "../../../../shared/employess/sickness/sickness.service";
import { EmployessService } from "../../../../shared/employess/employess.service";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { invalidDate } from "../../../../utilities/validators";
import { DictionariesService } from "../../../../shared/dictionaries/dictionaries.service";
import { AuthUserService } from "../../../../@core/data/auth-user.service";
import { Subscription } from "rxjs";
import * as moment from "moment";
import { eventsErrors } from "../../events-errors";
import { DayShiftsTableService } from "../../day-shifts/day-shifts-table.service";
import { QuestionDialogComponent } from "../../../shared/question-dialog/question-dialog.component";
import { LoadingStatus } from "../../../shared/loading-status.enum";
import { OverviewService } from "../../../../shared/employess/overview/overview.service";
import {
  EmployeeScheduleCalendar,
  EmployeeScheduleSummary
} from "../../overview/overview-calendar/overview-calendar.component";
import { IPreviousReq } from "../../annual-leaving/new-annual-leaving/new-annual-leaving.component";

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
@Component({
  selector: "ngx-new-sickness",
  templateUrl: "./new-sickness.component.html",
  styleUrls: [
    "../../annual-leaving/new-annual-leaving/new-annual-leaving.component.scss",
  ],
})
export class NewSicknessComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public errorMessages = eventsErrors;
  public employees: any = [];
  public eventTypes: any = [];
  public employeeContracts: any = [];
  public contract: any = {};
  public context: any = {};
  public form: UntypedFormGroup;
  public personAvailableText: string = "";
  public forceChangeToLTS = false;
  public verificationLTSDone = false;
  public controlNames = ["employeeId", "sicknessStart", "sicknessEnd"];
  public contractControlNames = [];
  public utils = {
    getValidationStatus,
    handleValidationErrorMessage,
    isFormValid,
    validateFormControlsByName,
    getFullName,
    validateAtLeastOneCheckbox,
  };
  public showContractTable = false;
  public columns = [
    "Contract ID",
    "Role",
    "Contract Type",
    "Contract Start",
    "Contract End",
    "Gross Hrs",
    "Given (req.)",
  ];
  public source = "";
  public needAcceptance = false;
  public careHomeId = this.authUserService.getCareHomeId();
  public canSave = true;

  schedule: EmployeeScheduleCalendar[] = [];
  scheduleSummary: EmployeeScheduleSummary[] = [];

  public calendarData = {
    startDate: moment(),
    endDate: moment(),
    months: []
  };
  public dateRange = {
    from: moment().subtract(3, 'days'),
    to: moment().add(3, 'days')
  };
  public calendarMessageLeave = '';
  public calendarMessageShifts = '';
  public LoadingStatus: typeof LoadingStatus = LoadingStatus;
  public dataLoaded: LoadingStatus = LoadingStatus.NOT_LOADED;
  public tableIdentifier?: string;

  private employeeId = 0;
  private previousReqRange: IPreviousReq = {from: undefined, to: undefined};
  private previousReqRangeContracts: IPreviousReq = {from: undefined, to: undefined};

  constructor(
    private tableService: TableService,
    private authUserService: AuthUserService,
    private sicknessService: SicknessService,
    private employeeService: EmployessService,
    private dictionariesService: DictionariesService,
    private toastrService: NbToastrService,
    private formBuilder: UntypedFormBuilder,
    private dayShiftsTableService: DayShiftsTableService,
    private dialogService: NbDialogService,
    private overviewService: OverviewService
  ) {
    this.employeeId = (this.tableService && this.tableService.getValue()) ? this.tableService.getValue().employeeId : 0;
    this.tableIdentifier = this.tableService.getValue().tableIdentifier;
  }

  ngOnInit() {
    this.createForm();
    this.subscription.add(
      this.dictionariesService.getEventTypes().subscribe((response: any) => {
        this.eventTypes = response.result.wordsFromDictionary.filter(
          (word) => !word.wordFullName.includes("leave")
        );
        this.form
          .get("eventType")
          .patchValue(
            this.eventTypes.find((word) => word.wordFullName === "Sickness").id
          );
      })
    );
    this.subscription.add(
      this.employeeService
        .getEmployeesByCareHome(this.careHomeId, this.employeeId)
        .subscribe((response: any) => {
          response.result.employeesLines.map(
            (i) =>
              (i.fullName = getFullName(i.employeeFirstName, i.employeeSurname))
          );
          return (this.employees = response.result.employeesLines);
        })
    );
    if (this.tableService.getValue()) {
      this.patchValues();
      this.careHomeId = this.tableService.getValue().careHomeId;
    }
  }

  private getCalendarData() {
    this.calendarMessageLeave = '';
    this.calendarMessageShifts = '';
    this.previousReqRange['from'] = this.dateRange.from;
    this.previousReqRange['to'] = this.dateRange.to;

    this.dataLoaded = LoadingStatus.NOT_LOADED;
    let loadCount = 2
    this.subscription.add(this.overviewService
      .getDataForOtherEventsForEmployeeWithSorceEmployee(this.employeeId, this.dateRange.from, this.dateRange.to, true)
      .subscribe((response: any) => {
          if (!(response.result.calendar)) {
            this.calendarMessageLeave = 'There are no other Annual Leave nor Sickness in this period.';
          } else {
            this.calendarData.months = this.transformCallendarData(response.result.list);
          }
          loadCount--
          if (loadCount == 0) {
            this.dataLoaded = LoadingStatus.LOADED;
          }
        }
      ));
    this.subscription.add(this.overviewService
      .getDataOfEmployeeSchedule(this.employeeId, this.dateRange.from, this.dateRange.to)
      .subscribe((responseSchedule: any) => {
          if (responseSchedule.result.list.length > 0) {
            this.schedule = responseSchedule.result.list;
            this.scheduleSummary = responseSchedule.result.summaryByRoleList;
            if (this.calendarData.months.length == 0) {
              this.calendarData.months = this.transformScheduleData(responseSchedule.result.list);
            }
          } else {
            this.calendarMessageShifts = 'There are no shifts planned in this period.';
          }
          loadCount--
          if (loadCount == 0) {
            this.dataLoaded = LoadingStatus.LOADED;
          }
        }
      ));
  }

  private transformScheduleData(scheduleList: any[]) {
    const monthsData: {month: string, date: number}[] = [];
    const monthsArray = [];
    const monthsArrayTimestamps = [];
    scheduleList.forEach(schedule => {
      if (!monthsArray.includes(moment(schedule.date).month())) {
        monthsArray.push(moment(schedule.date).month());
        monthsData.push({month: moment(schedule.date).format('MMMM'), date: moment(schedule.date).valueOf()});
        monthsArrayTimestamps.push(moment(schedule.date).valueOf());
      }
    });
    return monthsData;
  }

  private transformCallendarData(callendarMonths: any[]) {
    const firstMonth = callendarMonths[0];
    let fmContracts = firstMonth.contracts;
    if (callendarMonths.length > 1) {
      fmContracts = fmContracts.concat(callendarMonths[1].contracts);
      fmContracts = fmContracts.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
    }

    firstMonth.contracts = fmContracts;

    return [firstMonth];
  }

  createForm() {
    this.form = new UntypedFormGroup({
      employeeId: new UntypedFormControl(null, [Validators.required]),
      eventType: new UntypedFormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      sicknessStart: new UntypedFormControl(null, [Validators.required, invalidDate]),
      sicknessEnd: new UntypedFormControl(null, [Validators.required, invalidDate]),
      firstDayAvailable: new UntypedFormControl(null),
      description: new UntypedFormControl(null, [Validators.required]),
    });

    this.form.get("employeeId").valueChanges.subscribe((value: any) => {
      if (this.utils.validateFormControlsByName(this.form, this.controlNames)) {
        this.toggleContractTable();
      }
    });

    this.form.get("sicknessStart").valueChanges.subscribe((value: any) => {
      if (moment(this.form.get("sicknessStart").value).isValid()) {
        if (
          this.utils.validateFormControlsByName(this.form, this.controlNames)
        ) {
          this.toggleContractTable();
        }
      }
    });

    this.form.get("sicknessEnd").valueChanges.subscribe((value: any) => {
      if (moment(this.form.get("sicknessEnd").value).isValid()) {
        this.form.patchValue({
          firstDayAvailable: moment(value, "DD/MM/YYYY").add(1, "d"),
        });
        this.toggleContractTable();
      }
    });
    this.form.get("firstDayAvailable").valueChanges.subscribe((value: any) => {
      if (
        moment(this.form.get("sicknessEnd").value).isSameOrAfter(
          moment(value),
          "date"
        )
      ) {
        this.form
          .get("firstDayAvailable")
          .setErrors({ dateGreaterOrEqualThan: true });
      } else if (!this.isFDAEmptyOrNextAfterEnd()) {
        this.form
          .get("firstDayAvailable")
          .setErrors({ dateFDAEmptyOrNextAfterEnd: true });
      } else {
        this.form.get("firstDayAvailable").setErrors(null);
      }
    });
  }

  checkIfPreviousIsSame(previous: IPreviousReq): boolean {
    return moment(previous.from).isSame(moment(this.dateRange.from, 'day'))
      && moment(previous.to).isSame(moment(this.dateRange.to, 'day'));
  }

  onStartDateInput(event: any) {
    const date = event.value;
    this.dateRange.from = moment(date).subtract(2, 'days');
    if (this.form.get("sicknessEnd").value === null) {
      this.form.get("sicknessEnd").setValue(date);
      this.dateRange.to = moment(date).add(7, 'days');
    } else {
      this.dateRange.to = moment(this.form.get("sicknessEnd").value).add(2, 'days');;
    }
    if (this.checkIfPreviousIsSame(this.previousReqRange)) {return; }
    this.getCalendarData();
  }

  onEndDateInput(event: any) {
    this.form.get("sicknessEnd").setValue(event.value);
    this.dateRange.to = moment(event.value).add(2, 'days');
    if (this.form.get("sicknessStart").value) {
      if (this.checkIfPreviousIsSame(this.previousReqRange)) {return; }

      this.getCalendarData();
    }
  }

  onStartInput(value: any) {
    if (value && value.toString().length > 7) {
      this.onStartDateInput({ value: this.form.get("sicknessStart").value });
    }
  }

  isFDAEmptyOrNextAfterEnd() {
    let isFDAEmptyOrNextAfterEnd = false;
    if (
      this.form.get("firstDayAvailable").value === null ||
      moment(this.form.get("sicknessEnd").value)
        .add(1, "day")
        .isSame(this.form.get("firstDayAvailable").value)
    ) {
      isFDAEmptyOrNextAfterEnd = true;
    }
    return isFDAEmptyOrNextAfterEnd;
  }

  clearFirstDayAvailable() {
    this.form.patchValue({ firstDayAvailable: null });
  }

  patchValues() {
    const values = this.tableService.getValue();
    if (values && values.needAcceptance) {
      this.needAcceptance = values.needAcceptance;
    }
    this.source = values.source;
    this.form.get("employeeId").patchValue(values.employeeId);

    this.forceChangeToLTS = values.forceChangeToLTS;
    // console.log("VALUES", values, this.source);
    if (values && values.sicknessStart) {
      this.form.get("sicknessStart").setValue(values.sicknessStart);
    }
    if (values && values.from && values.to) {
      this.form
        .get("sicknessStart")
        .setValue(moment(values.from, "YYYY/MM/DD"));
      this.form.get("sicknessEnd").setValue(moment(values.to, "YYYY/MM/DD"));
    }
  }

  updateValues() {
    this.employeeContracts.forEach((contract) =>
      this.form.patchValue({
        [`contract-${contract.contractId}`]: this.form.get(
          `contract-${contract.contractId}`
        ).value,
      })
    );
  }

  private checkIsLtSick(latestSickDays: number) {
    let totalSickDays = 0;
    const sicknessStart = moment(this.form.get("sicknessStart").value);
    const sicknessEnd = moment(this.form.get("sicknessEnd").value);

    const currentSicknessDiff = sicknessEnd.diff(sicknessStart, "days") + 1; // (include day start)

    if (latestSickDays !== null && latestSickDays !== undefined) {
      totalSickDays += latestSickDays;
    }

    totalSickDays += currentSicknessDiff;

    if (totalSickDays > 14) {
      this.subscription.add(
        this.dialogService
          .open(QuestionDialogComponent, {
            closeOnBackdropClick: false,
            context: {
              title: "Long term sickness",
              message: `If you think this person will likely not be back even after this sickness ends, shall we change their status to Long Term Sick?<br>
            (this will grey them out in the training matrix and show they can't be actively managed)`,
              okLabel: "Yes",
              cancelLabel: "No",
            },
          })
          .onClose.subscribe((decision: boolean) => {
            this.forceChangeToLTS = decision;
          })
      );
    } else {
      this.forceChangeToLTS = false;
    }
  }

  toggleContractTable() {
    this.subscription.add(
      this.employeeService
        .getContractsByEmployeeIdAndDate(
          this.form.get("employeeId").value,
          this.form.get("sicknessStart").value,
          this.form.get("sicknessEnd").value
        )
        .subscribe((response: any) => {
          // delete replacements contracts
          this.checkIsLtSick(response.result.sickDaysInLast3Weeks);
          if (this.tableService.getValue().forceChangeToLTS) {
            this.forceChangeToLTS = true;
          }
          this.employeeContracts = response.result.contractsLines.filter(
            (contract) => contract.contractType !== "Replacement"
          );
          if (
            this.employeeContracts &&
            this.employeeContracts[0] &&
            this.employeeContracts[0].employeeStatus === "Inactive"
          ) {
            this.toastrService.danger(
              "Employee is inactive, you cannot add AL or sickness",
              "Error",
              { duration: 60000, destroyByClick: true }
            );
          } else {
            this.showContractTable = true;
            this.form.get("firstDayAvailable").markAsTouched();
            this.form.get("description").markAsTouched();

            // used for validation only start
            this.employeeContracts.forEach((contract) => {
              this.form.removeControl(`contract-${contract.contractId}`);
            });
            this.employeeContracts.forEach((contract) => {
              this.form.addControl(
                `contract-${contract.contractId}`,
                this.formBuilder.control(true)
              );
            });
            this.contractControlNames = [];
            Object.keys(this.form.controls).forEach((key) => {
              if (key.includes("contract-")) {
                this.contractControlNames.push(key);
              }
            });
            // used for validation only end
            this.updateValues();
          }
        })
    );

    this.subscription.add(
      this.employeeService
        .getEmployeeNextSchedule(
          this.form.get("employeeId").value,
          this.form.get("sicknessStart").value
        )
        .subscribe((response: any) => {
          this.personAvailableText = response.result.nextShedule;
        })
    );
  }

  saveData() {
    this.canSave = false;
    let eventList = [];
    const eventId = this.form.get("eventType").value;
    const eventTypeName = this.eventTypes.find(
      (x) => x.id === eventId
    ).wordFullName;
    this.employeeContracts.forEach((contract) => {
      eventList.push({
        startDate: new Date(this.form.get("sicknessStart").value),
        endDate: this.form.get("sicknessEnd").value,
        firstDayAvailable: this.form.get("firstDayAvailable").value,
        contractId: contract.contractId,
        typeId: eventId,
        usedHrs: null,
        leftHrs: null,
        givenHrs: contract.contractHoursPerWeek,
        description: this.form.get("description").value,
        addedById: this.authUserService.getOriginalUser().id,
        acceptedById: this.authUserService.getOriginalUser().id,
        acceptanceDate: this.needAcceptance ? null : new Date().toISOString(),
        given: true,
        requiresAcceptance: this.needAcceptance,
      });
      eventList = eventList.filter((item) => item.given !== false);
    });

    const data = {
      employeeId: this.form.get("employeeId").value,
      description: this.form.get("description").value,
      eventTypeId: eventId,
      eventStartDate: this.form.get("sicknessStart").value,
      eventEndDate: this.form.get("sicknessEnd").value,
      firstDayAvailable: this.form.get("firstDayAvailable").value,
      eventType: eventTypeName,
      forceLTSick: this.forceChangeToLTS,
      verificationLTSDone: this.verificationLTSDone,
      eventList: eventList,
    };

    this.subscription.add(
      this.sicknessService
        .addNewSickness(this.form.get("employeeId").value, data)
        .subscribe(
          (response: any) => {
            if (this.source === "shift-confirmation") {
              this.dayShiftsTableService.rememberScrollPosition$.next(true);
            }
            this.toastrService.success(response.message, "Success");
            this.tableService.closeWindow(true, 0, this.tableIdentifier);
          },
          (err) => {
            this.canSave = true;
            this.toastrService.danger(getErrorMessage(err), "Error", {
              duration: 60000,
              destroyByClick: true,
            });
            const errorsArray =
              (err.error && err.error.errors) || err.message || [];
            console.log(" = err.message = ", errorsArray[0].message);
            if (
              errorsArray[0].message ==
              "This employee status should be switched to LT-Sickenss."
            ) {
              this.subscription.add(
                this.dialogService
                  .open(QuestionDialogComponent, {
                    closeOnBackdropClick: false,
                    context: {
                      title: "Long term sickness",
                      message: `This person has not worked for 14 days, if you think this person will likely not be back after this sickness ends,
                          shall we change their status to Long Term Sick? <br>
                          (this will grey them out in the training matrix and show they can't be actively managed).`,
                      okLabel: "Yes",
                      cancelLabel: "No",
                    },
                  })
                  .onClose.subscribe((decision: boolean) => {
                    if (decision) {
                      console.log("verificationLTSDone = true");
                      this.verificationLTSDone = true;
                      this.forceChangeToLTS = false;
                      this.saveData();
                    }
                  })
              );
            }
          }
        )
    );
  }

  setPersonAvailableText() {
    if (this.employeeContracts.length > 0) {
      const latestShift = this.employeeContracts[0];
      const shiftStartDate = moment(latestShift.contractStart);
      this.personAvailableText =
        "This person is due to work in " +
        this.dateDiffString(shiftStartDate) +
        " on " +
        shiftStartDate.day() +
        " " +
        shiftStartDate.format("MMMM") +
        " from " +
        +" am to 20.00 as a " +
        latestShift.contractRole;
    } else {
      this.personAvailableText = "";
    }
  }

  dateDiffString(date: any): string {
    const daysBetweenShifts = moment().diff(date);

    if (daysBetweenShifts > 1) {
      return daysBetweenShifts + " days";
    } else {
      return daysBetweenShifts + " day";
    }
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
