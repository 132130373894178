import { Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Subscription, BehaviorSubject, Observable, Subject, fromEvent, of } from 'rxjs';
import { taskErrorMessages } from '../task-errors';
import { handleValidationErrorMessage, isFormValid, getErrorMessage, getServerLink, urlify, deurlify } from '../../../../utilities/utils';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { DictionariesService } from '../../../../shared/dictionaries/dictionaries.service';
import { TableService } from '../../../shared/table/table.service';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { TasksService } from '../../../../shared/tasks/tasks.service';
import { TaskTab } from '../task-tab.enum';
import { FinanceTaskService } from './finance-task.service';
import { CopyTaskComponent, CopyMode } from '../copy-task/copy-task.component';
import { AddWatchersDialogComponent } from '../../../shared/add-watchers-dialog/add-watchers-dialog.component';
import { DOCUMENT } from '@angular/common';
import { ChooseContractComponent } from '../../../employess/contracts/choose-contract/choose-contract.component';
import { QuestionDialogComponent, QuestionDecisionTypeEnum } from '../../../shared/question-dialog/question-dialog.component';
import { CareHomesService } from '../../../../shared/care-homes/care-homes.service';
import { AuthUserService } from '../../../../@core/data/auth-user.service';
import { TasksEmailDialogComponent } from '../../../shared/tasks-email-dialog/tasks-email-dialog.component';
import { debounceTime, delay, distinctUntilChanged, take } from 'rxjs/operators';
import { PermissionsService } from '../../../../@core/data/permissions.service';
import { SubtasksService } from '../../../shared/subtask-editor/subtask.service';
import { LogsService } from '../../../../shared/logs/logs.service';
import { UsersService } from '../../../../shared/users/users.service';
import { QuestionDialogTaskPauseDialogComponent } from '../../../shared/question-dialog/question-dialog-task-pause/question-dialog-task-pause.component';
import {
  ITasksDeadlineEmailsInfoLine,
  ITasksDeadlineEmailsInfoResponse
} from "../../../shared/interfaces/tasks.interfaces";
import {
  TasksEmailListDialogComponent
} from "../../../shared/tasks-email-list-dialog/tasks-email-list-dialog.component";
import { storageKeyTODOS, SubtaskEditorComponent } from "../../../shared/subtask-editor/subtask-editor.component";
import { TaskBoardsStateService } from '../../../../shared/tasks/task-boards/task-boards-state.service';

export enum ESpecialTaskTypes {
  favorite = -1,
  residents = 1,
  menuChoices = 2,
  roomEnquiries = 3,
  communityTracker = 4,
  finance = 5,
  annualTasks = 6,
}

export const storageKeyTasksViewMode = 'USER_ECHO_TASKS_VIEW_MODE'
export const CLASSES_TO_SET_FOCUS = ['title', 'nb-minimize', 'nb-loop']

@Component({
  selector: 'ngx-edit-task',
  templateUrl: './edit-task.component.html',
  styleUrls: ['../new-task/new-task.component.scss']
})
export class EditTaskComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public errorMessages = taskErrorMessages;
  public utils = { handleValidationErrorMessage, isFormValid };
  public TaskTab: typeof TaskTab = TaskTab;
  public activeTab = TaskTab.INFO;
  public selectedTab = 0;
  public taskDetails: any = {};
  public startTaskDetails: any = {};
  public form: UntypedFormGroup;
  public formHistory: UntypedFormGroup;
  public statuses: any = [];
  public statusesWithoutEndStatuses = [];
  public cachedStatuses: any = [];
  public categories: any = [];
  public priorities: any = [];
  public grouping: any = [];
  public raggs1: any = [];
  public raggs2: any = [];
  public pinned: any = [];
  public id: number;
  public titleToUpdate: string;
  public taskBoardId: number;
  public statusId: number;
  public hasChanges = false;
  public isRecurring = false;
  public isRedoTaskMode = false;
  public statusAutoChange = false;
  public taskboardPermissions: any = {};
  public tabLabels = ['Task Information', 'Financial', 'Task history', 'Files', 'Linked tasks'];
  public disableTaskCopy: boolean = false;
  public isFinanceTask = false;
  public financeTabIsValid = false;
  public refreshSubTasks = false;
  public forceCheckIsAllTodosR = false;
  public historyTableLoaded = false;
  public filesTableLoaded = false;
  public linkedTasksTableLoaded = false;
  public isTakeAll = true;
  public isExcludeClosed = true;
  public taskPermissions: any = {};
  public isHideStatus = false;
  public subTaskPercentage = '0%';
  public subTasks = [];
  public isSubTasksChanged = false;
  private isAllTodosRAndNoRecurring = false;
  private subTaskChenged = false;
  public onHistoryTableFilterChange$ = new BehaviorSubject<string>('');
  public isFileSendEnabled: boolean = false;
  public isSaveEnabled = true;
  public deadlineEmails: ITasksDeadlineEmailsInfoLine[] = [];
  public deadlineEmailsCollected: boolean = false;
  public paginationData = {
    pageIndex: 0,
    pageSize: 10,
    orderBy: '',
    orderDirection: ''
  };
  public historyTotalRows = 0;
  public isTaskClosed: boolean = false;
  public historyTabIsActive = false;
  public historyData: any = [];
  public historyColumnsOld = [
    {
      fieldName: 'modifiedAt',
      title: 'Modified At',
      tooltip: 'Click on cell, to see details',
      tooltipFieldName: 'modifiedBy',
    },
    {
      fieldName: 'fieldEdited',
      title: 'Field edited'
    },
    {
      fieldName: 'oldVersion',
      title: 'Old version',
      fieldType: 'textarea-view'
    },
    {
      fieldName: 'newVersion',
      title: 'New version',
      fieldType: 'textarea-view'
    }
  ];
  public historyColumns = [
    {
      fieldName: 'index',
      title: 'No.'
    },
    {
      fieldName: 'title',
      title: 'Title',
      class: 'text-left'
    },
    {
      fieldName: 'description',
      title: 'Description',
      fieldType: 'textarea-view',
      addSubtasksProgress: true,
      formatHtml: true,
    },
    {
      fieldName: 'latestUpdate',
      title: 'Latest update',
      fieldType: 'textarea-view',
      formatHtml: true,
    },
    {
      fieldName: 'status',
      title: 'State',
    }
  ];
  public tagsColumns = [
    {
      fieldName: 'index',
      title: 'No.'
    },
    {
      fieldName: 'taskBoardName',
      title: 'Board',
      type: 'html',
      valuePrepareFunction: (row: any) => {
        return `<div>${row.taskBoardName || ''}</div>`;
      }
    },
    {
      fieldName: 'title',
      title: 'Title',
      class: 'text-left',
      type: 'html',
      addSubtasksProgress: true,
      valuePrepareFunction: (row: any) => {
        return `<div>${row.title || ''}</div>`;
      }
    },
    {
      fieldName: 'comments',
      title: 'Description',
      fieldType: 'textarea-view',
      addSubtasksProgress: true,
      type: 'html',
      isInWindowSize: true,
      valuePrepareFunction: (row: any) => {
        return `<textarea>${row.comments || ''}</textarea>`;
      }
    },
    {
      fieldName: 'latestUpdate',
      title: 'Latest update',
      type: 'html',
      isInWindowSize: true,
      valuePrepareFunction: (row: any) => {
        return `<div>${row.latestUpdate || ''}</div>`;
      },
    },
    {
      fieldName: 'shortHistory',
      title: 'Submitted update',
      isInWindowSize: true,
      addSubtasksChangeProgress: true,
      type: 'html',
      valuePrepareFunction: (row: any) => {
        return `<div>${row.shortHistory || ''}</div>`;
      }
    },
    {
      fieldName: 'status',
      title: 'State',
    }
  ];
  public files: any = [];
  public taskboardDetails: any = {};
  public raggsColors: any = {};
  private tagsDictionaryId: number;
  public tags: any = [];
  public selectedTags: any = [];
  public taskSelectorVisible = true;
  public linkedTasksData = [];
  public isGroupManager = false;
  // public scrollToFirstToDo = false;
  public scrollToUpdate = false;
  public descriptionWithActiveLinks = '';
  public isTaskLoaded = false;
  public isNewHistoryTable: boolean = false;
  public taskHeader = '';
  public taskTitle = '';
  public taskBoardName = '';
  public taskDebugInfgo = '';
  public taskNo = '';
  public taskProgress: any;
  public taskProgressBackground = '#fff';
  public temporarySubtasks = [];
  public isTaskReadyToEndStatus = false;
  public descriptionMaxLength = 1000;
  public checkedCategories: any[] = [];

  public showAdvancedFeatures: boolean = false
  public showGridView: boolean = false

  private hasRecurringTasks: boolean = false;
  public recurringTasks: { name: string; checked: boolean; taskName: string}[] = [];

  private toggleHistory$ = new Subject<boolean>();

  public lastScrollTop = 0;

  showOptionsTooltip = false;

  private confirmDone = false;
  toggleAdvFeatures() {
    this.showAdvancedFeatures = !this.showAdvancedFeatures
  }

  toggleListGridView() {
    this.showGridView = !this.showGridView
    this.setTaskMode(this.showGridView)
  }

  setTaskMode(isGridShown: boolean) {
    let tmpTasks = {tasks: []}
    if (!this.userTaskMode) {
      tmpTasks.tasks.push({id: this.id, isGridMode: isGridShown})
    } else {
      tmpTasks.tasks = [...this.userTaskMode.tasks]
      let existingTask = tmpTasks.tasks.findIndex(task => task.id == this.id)
      if (existingTask == -1) {
        tmpTasks.tasks.push({id: this.id, isGridMode: isGridShown})
      } else {
        tmpTasks.tasks[existingTask].isGridMode = isGridShown
      }
    }

    localStorage.setItem(storageKeyTasksViewMode, JSON.stringify(tmpTasks))
  }

  toogleIsNewHistoryTable(event: any) {
    this.toggleHistory$.next(event);
  }

  public userTaskMode: { tasks: {id: number, isGridMode: boolean}[]}

  @ViewChild('subtaskEditor') editor: SubtaskEditorComponent;
  @ViewChild('formBlock') formBlock: ElementRef;
  delayFocusSubject: Subject<void> = new Subject();

  private clickSubscription = new Subscription();

  constructor(private dictionariesService: DictionariesService,
              private tableService: TableService,
              private tasksService: TasksService,
              private toastrService: NbToastrService,
              private financeTaskService: FinanceTaskService,
              private authUserService: AuthUserService,
              private careHomesService: CareHomesService,
              @Inject(DOCUMENT) private document: Document,
              private dialogService: NbDialogService,
              private permissionsService: PermissionsService,
              private subtasksService: SubtasksService,
              private logsService: LogsService,
              private taskBoardsStateService: TaskBoardsStateService,
              private cdr: ChangeDetectorRef,
              private usersService: UsersService) {
    this.subscription.add(this.financeTaskService.isValid$
      .subscribe((isValid: any) => {
        this.financeTabIsValid = isValid;
      }));
    this.isGroupManager = this.permissionsService.getIsGroupManager();
  }

  ngOnInit() {
    this.subtasksService.waitForTaskResponse = false;
    this.id = this.tableService.getValue().elementId;
    this.titleToUpdate = this.tableService.getValue().subtitle;
    // this.scrollToFirstToDo = this.tableService.getValue().scrollToFirstToDo;
    this.scrollToUpdate = this.tableService.getValue().scrollToUpdate;
    this.createForm();
    this.subscription.add(this.dictionariesService.getPinnedTasks()
      .subscribe((response: any) => {
        this.pinned = response.result.wordsFromDictionary;
      }))
    this.subscription.add(this.dictionariesService.getTaskCategories()
      .subscribe((response: any) => {
        this.categories = response.result.wordsFromDictionary;
      }));

    this.subscription.add(this.dictionariesService.getTaskPriorities()
      .subscribe((response: any) => {
        this.priorities = response.result.wordsFromDictionary;
      }));

    this.subscription.add(this.dictionariesService.getTaskStatuses()
      .subscribe((response: any) => {
        this.statuses = response.result.wordsFromDictionary.map((item: any) => {
          return {
            id: item.id,
            wordFullName: item.wordFullName,
            disabled: item.wordFullName == 'Closed'
          };
        });
        this.cachedStatuses = this.statuses;
        this.loadStatusesAndDisableEndStatuses(response.result.wordsFromDictionary);
      }));

    this.loadTags(null);

    this.getTaskData()

    this.subscription.add(this.usersService.getUsersForTasksCollaboration(this.id, this.authUserService.getCareHomeId())
      .subscribe((data: any) => {
        const d = data.result.list
        .map(i => {
          return { id: i.id, fullName: i.login };
        });
        this.subtasksService.setUsers(d);
      })
    );

    this.subscription.add(this.delayFocusSubject.pipe(delay(100)).subscribe(() => {
      this.setFocus();
    }));

    this.clickSubscription.add(
      fromEvent(document, "click").subscribe((event) => {
        if ((event.target as HTMLElement)?.tagName?.toUpperCase() === 'NB-CARD-BODY') {
          this.setFocus();
        } else {
          (event.target as HTMLElement).classList.forEach((e) => {
            if (CLASSES_TO_SET_FOCUS.includes(e)) {
              this.delayFocusSubject.next(null);
            }
          })
        }
      })
    );

    this.subscription.add(
      this.form.get('taskGoupingId').valueChanges.pipe(delay(100), distinctUntilChanged()).subscribe((value) => {
        this.delayFocusSubject.next(null);
        this.form.controls.taskGoupingId.setValue(value)
      })
    )

    this.subscription.add(
      this.toggleHistory$.pipe(debounceTime(150)).subscribe((val: boolean) => {
        this.isNewHistoryTable = val;
      })
    );
  }

  getTaskData(reloadEditor: boolean = false) {
    const t0 = performance.now();
    this.subscription.add(this.tasksService.getTask(this.id)
      .subscribe((response: any) => {
          this.taskHeader = response.result.taskBoardName + ' "' + response.result.title + '"';
          this.taskHeader = this.taskHeader.length > 60 ? this.taskHeader.substring(0, 60) + '...' : this.taskHeader;
          this.taskTitle = response.result.title;
          //this.isPined = response.result.isPinned
          this.taskBoardName = response.result.taskBoardName;
          this.taskDebugInfgo = response.result.taskDebugInfgo;
          this.taskNo = response.result.taskNo;
          this.tableService.changeWindowName(this.taskHeader);
          this.isFileSendEnabled = response.result.permissions.canIWrite;
          this.isTaskClosed = response.result.status === 'Closed';
          this.taskPermissions = response.result.permissions;
          this.showGridView = response.result.isGridMode;
          if (response.result.finaceTaskInfo.financeTaskId) {
            this.isFinanceTask = true;
          }
          this.isRecurring = response.result.isRecurring;
          this.isRedoTaskMode = response.result.reDoTestMode;
          this.updateValues(response.result);
          this.updateCategoriesOnLoad(response.result.categoryId);
          this.loadGrouppingAndRaggs(response.result);
          if (reloadEditor) {
            this.editor.load()
            setTimeout(() => {
              this.isTaskLoaded = true;
              this.scrollTasksToPosition();
            }, 500);
          } else {
            this.isTaskLoaded = true;
            this.scrollTasksToPosition();
          }


          setTimeout(() => {
            this.form.get('statusId').enable({ onlySelf: true, emitEvent: false });
          }, 2400);
          this.activeTab = this.tableService.getValue().activeTab || TaskTab.INFO;
          this.disableTaskCopy = this.tableService.getValue().disableTaskCopy;
          this.isHideStatus = this.tableService.getValue().isHideStatus ? this.tableService.getValue().isHideStatus : false;
          // if is resident taskboard then disable title edit
          if (response.result.residentId) {
            this.form.get('title').disable();
          }
          this.disableByPermissions();
          const t1 = performance.now();
          this.subscription.add(
            this.logsService.sendOpenTime({
              time: (t1 - t0),
              description: `edit-task opening for task ${this.id}`
            }).subscribe(() => {})
          );
          document.getElementById('edit-task-container-info-tab').click();

          this.setFocus();
        }
      ));
  }

  private scrollTasksToPosition() {
    // setTimeout(() => {
    //   document.getElementById('edit-task-container-info-tab').scrollTop = this.lastScrollTop;
    // }, 3000);
  }

  subtasksLoaded(event) {
    if (this.scrollToUpdate) {
      this.scrollToUpdateTextarea();
    }
  }

  scrollToUpdateTextarea() {
    if (this.titleToUpdate) {
      this.editor.subtasks.forEach((sub) => {
        if (sub.title == this.titleToUpdate) {
          sub.isExpanded = true
        }
      })
    } else {
      let fieldToCheck = 'afg-latest-update';
      let fieldToFocus = 'afg-latest-update-field';
      if (this.isGroupManager) {
        fieldToCheck = 'group-response';
        fieldToFocus = 'group-response-field';
      }
      let element = document.getElementById(fieldToCheck);
      if (element) {
        element.scrollIntoView({ block: 'center' });
        let elementToFocus = document.getElementById(fieldToFocus);
        if (elementToFocus) {
          elementToFocus.focus();
          elementToFocus.click()
          elementToFocus = undefined;
        }
        element = undefined;
      }
    }
  }

  addTaskTab = (row: any) => {
    const link = this.document.location.origin + '/#/task-management/task-boards/' + this.taskBoardId + '?taskId=' + row.taskId;
    window.open(link, '_blank');
  }

  private loadFiles() {
    this.subscription.add(this.tasksService.getTaskFiles(this.id)
      .subscribe((response: any) => {
        this.files = response.result.list;
        this.filesTableLoaded = true;
        document.getElementById('edit-task-container-files-tab').click();
      }));
  }

  private loadLinkedTasks() {
    const myObservable = new Observable((observer) => {
      this.tasksService.getTaskLinkedTasks(this.id, this.form.get('isShowPaused').value, this.form.get('isShowClosed').value).subscribe((response: any) => {
        observer.next(response);
      });
    });
    this.subscription.add(myObservable.subscribe((response: any) => {
      this.linkedTasksData = response.result.list;
      this.linkedTasksTableLoaded = true;
      document.getElementById('edit-task-container-linked-tab').click();
    }));
  }

  loadHistoryOld = (activeFilter?: any, datesRange?: any, actualSelectorValue?: any, searchFilter?: any, paginationData?: any, extendedFilters?: any, extendedSort?: any, extendedOrder?: any, categoryIdFilter?: any, statusIdFilter?: any) => {
    this.historyTableLoaded = false;
    this.paginationData = paginationData;
    const from = this.paginationData.pageSize * (this.paginationData.pageIndex + 1) - this.paginationData.pageSize + 1;
    const to = this.paginationData.pageSize * (this.paginationData.pageIndex + 1);
    const myObservable = new Observable((observer) => {
      this.tasksService.getTaskHistory(this.id, from, to, paginationData.orderBy, paginationData.orderDirection).subscribe((response: any) => {
        observer.next(response);
      });
    });
    this.subscription.add(myObservable.pipe(take(1)).subscribe((response: any) => {
      window.document.getElementById('edit-task-container-history-tab').click();
      this.historyData = response.result.taskHistoryList;
      this.historyTableLoaded = true;
      this.historyTotalRows = response.result.totalRows;
    }));
  }

  loadHistory = (activeFilter?: any, datesRange?: any, actualSelectorValue?: any, searchFilter?: any, paginationData?: any, extendedFilters?: any, extendedSort?: any, extendedOrder?: any, categoryIdFilter?: any, statusIdFilter?: any) => {
    this.historyTableLoaded = false;
    this.paginationData = paginationData;
    const from = this.paginationData.pageSize * (this.paginationData.pageIndex + 1) - this.paginationData.pageSize + 1;
    const to = this.paginationData.pageSize * (this.paginationData.pageIndex + 1);
    const myObservable = new Observable((observer) => {
      this.tasksService.getTaskHistory(this.id, from, to, paginationData.orderBy, paginationData.orderDirection, 'new').subscribe((response: any) => {
        observer.next(response);
      });
    });
    this.subscription.add(myObservable.pipe(take(1)).subscribe((response: any) => {
      window.document.getElementById('edit-task-container-history-tab').click();
      this.historyData = response.result.taskHistoryList;
      this.historyTableLoaded = true;
      this.historyTotalRows = response.result.totalRows;
    }));
  }

  loadGrouppingAndRaggs(taskboardDetails: any) {
    this.taskboardDetails = taskboardDetails;
    if (taskboardDetails.ragg1DictionaryId) {
      this.subscription.add(
        this.dictionariesService.getDictionaryByName(taskboardDetails.ragg1DictionaryName)
        .subscribe((response: any) => {
          this.raggs1 = response.result.wordsFromDictionary;
          this.setRaggColor(this.raggs1, this.form.get('taskRagg1Id').value, 'ragg1Color');
        })
      );
    }

    if (taskboardDetails.ragg2DictionaryId) {
      this.subscription.add(
        this.dictionariesService.getDictionaryByName(taskboardDetails.ragg2DictionaryName)
        .subscribe((response: any) => {
          this.raggs2 = response.result.wordsFromDictionary;
          this.setRaggColor(this.raggs2, this.form.get('taskRagg2Id').value, 'ragg2Color');
        })
      );
    }

    if (taskboardDetails.groupingDictionaryId) {
      this.subscription.add(
        this.dictionariesService.getDictionaryByName(taskboardDetails.groupingDictionaryName)
        .subscribe((response: any) => {
          this.grouping = response.result.wordsFromDictionary;
          this.setRaggColor(this.grouping, this.form.get('taskGoupingId').value, 'groupingColor');
        })
      );
    }
  }

  loadTags(callback: any = null) {
    const stream = callback ? this.dictionariesService.getTaskTags() : this.dictionariesService.getCacheableTaskTags();
    this.subscription.add(stream
      .subscribe((response: any) => {
        this.tags = response.result.wordsFromDictionary;
        this.tagsDictionaryId = response.result.dictionaryId;
        if (callback) { callback(); }
      }));
  }

  newTagAdded(value: string) {
    this.taskSelectorVisible = false;
    const data = {
      description: value,
      name: value
    };
    this.subscription.add(this.dictionariesService.addDictionaryWord(this.tagsDictionaryId, data)
      .subscribe((response: any) => {
        this.loadTags(() => {
          setTimeout(() => {
            const addedWordId = response.result;
            const actualTagsValue = this.selectedTags || [];
            const addedValue = this.tags.find((item: any) => {
              return item.id == addedWordId;
            });
            actualTagsValue.push(addedValue);
            this.selectedTags = actualTagsValue;
            this.form.updateValueAndValidity();
            this.taskSelectorVisible = true;
          }, 1);
        });
      },
        (err: any) => {
          this.taskSelectorVisible = true;
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }));
  }

  disableByPermissions() {
    if (!this.taskboardPermissions.canIEdit) {
      this.form.get('comments').disable();
    }
    if (!this.taskboardPermissions.canIChange && !this.taskboardPermissions.canIClose) {
      this.form.get('statusId').disable();
    }
    if (this.taskboardPermissions.canIChange || this.taskboardPermissions.canIClose) {
      const oldValue = this.form.get('statusId').value;
      this.subscription.add(
        this.form.get('statusId').valueChanges.subscribe((value: any) => {
          if (this.taskboardPermissions.canIChange && !this.taskboardPermissions.canIClose) {
            if (value === this.getStatusIdByText('Done(remove)')) {
              this.form.patchValue({ statusId: oldValue });
              this.toastrService.warning('You have no permission for this action', 'No permission', { duration: 60000, destroyByClick: true });
            }
          }
        })
      );
    }
  }

  getStatusIdByText(statusText: string) {
    const word = this.cachedStatuses.find((element: any) => {
      return element.wordFullName == statusText;
    });
    return word.id;
  }

  createForm() {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl(null, [Validators.required]),
      categoryId: new UntypedFormControl(null),
      statusId: new UntypedFormControl({value: null, disabled: true}, [Validators.required]),
      priorityId: new UntypedFormControl(null, [Validators.required]),
      dueTo: new UntypedFormControl(null),
      comments: new UntypedFormControl(null),
      isFavorite: new FormControl<boolean>(false),
      taskGoupingId: new UntypedFormControl(null),
      taskRagg1Id: new UntypedFormControl(null),
      taskRagg2Id: new UntypedFormControl(null),
      isEmailList: new FormControl<boolean>(false),
      isEmailOnSave: new FormControl<boolean>(false),
      isShowPaused: new FormControl<boolean>(false),
      isShowClosed: new FormControl<boolean>(false),
      pinnedAreaId: new FormControl<number>(null)
    });

    this.subscription.add(
      this.form.get('categoryId').valueChanges.subscribe((value: any) => {
        this.checkedCategories = value;
      })
    );

    this.form.get('categoryId').markAsTouched();

    this.formHistory = new UntypedFormGroup({
      titleDisabled: new UntypedFormControl({ value: null, disabled: true }),
      commentsDisabled: new UntypedFormControl({ value: null, disabled: true }),
    });

    this.subscription.add(
      this.form.get('taskRagg1Id').valueChanges.subscribe((value: any) => {
        this.setRaggColor(this.raggs1, value, 'ragg1Color');
      })
    );

    this.subscription.add(
      this.form.get('taskRagg2Id').valueChanges.subscribe((value: any) => {
        this.setRaggColor(this.raggs2, value, 'ragg2Color');
      })
    );

    this.subscription.add(
      this.form.get('taskGoupingId').valueChanges.subscribe((value: any) => {
        this.setRaggColor(this.grouping, value, 'groupingColor');
      })
    );

    this.subscription.add(
      this.form.get('statusId').valueChanges.subscribe((value: string) => {
        this.updateTaskStatus(this.temporarySubtasks);
      })
    );

    this.subscription.add(
      this.form.get('statusId').valueChanges.pipe(distinctUntilChanged()).subscribe((value: string) => {
        if (this.isRecurring && !this.statusAutoChange && (value === this.getStatusWord('Paused').id || value === this.getStatusWord('Done(remove)').id)) {
          this.showChangeStatusPopup();
        } else {
          this.statusAutoChange = false;
        }
        const paused = this.getStatusWord('Paused').id;
        if (this.taskDetails.status != 'Paused' && value === paused) {
          this.showPausingPopup();
        }
      })
    );
  }

  getStatusWord(wordName: string) {
    const word = this.cachedStatuses.find((element: any) => element.wordFullName === wordName);
    return word;
  }

  getWord(arrray: any, wordId: number) {
    const word = arrray.find((element: any) => element.id == wordId);
    return word;
  }

  setRaggColor(array: any, value: number, colorFieldName: string) {
    const word = this.getWord(array, value);
    this.raggsColors[colorFieldName] = word ? word.colorHash : '';
  }

  prepareData(forceUpdate = false) {
    const data = {
      openTime: this.taskDetails.openTime,
      title: this.form.get('title').value,
      employeeId: this.taskDetails.employeeId,
      tags: this.selectedTags,
      residentId: this.taskDetails.residentId,
      categoryId: this.form.get('categoryId').value,
      statusId: this.form.get('statusId').value,
      priorityId: this.form.get('priorityId').value,
      dueTo: this.form.get('dueTo').value,
      comments: urlify(this.form.get('comments').value),
      isFavorite: this.form.get('isFavorite').value,
      taskGoupingId: this.form.get('taskGoupingId').value,
      taskRagg1Id: this.form.get('taskRagg1Id').value,
      taskRagg2Id: this.form.get('taskRagg2Id').value,
      isEmailList: this.form.get('isEmailList').value,
      originUrl: this.document.location.origin,
      forceUpdate: forceUpdate,
      pinnedAreaId: this.form.get('pinnedAreaId').value
    };
    return data;
  }

  assignTodosStatus(isAllTodosRAndNoRecurring: boolean) {
    this.isAllTodosRAndNoRecurring = isAllTodosRAndNoRecurring;
  }

  presave() {
    this.forceCheckIsAllTodosR = true;
  }

  presaveContinue(data: {isNoRecurring: boolean, isAllTodosR: boolean}) {
    console.log("this.taskTitle: " + this.form.get('title').value);
    if (this.form.get('title').value
      && ((this.form.get('title').value||'').includes("[K]") || (this.form.get('title').value||'').includes("[k]"))) {
      this.save();
      return;
      }
    this.forceCheckIsAllTodosR = false;
    const doneAndRemove = this.getStatusIdByText('Done(remove)');
    const statusIsDoneAndRemove = this.form.get('statusId').value == doneAndRemove;
    if (data.isNoRecurring && data.isAllTodosR) {
      if (!statusIsDoneAndRemove) {
        this.subscription.add(this.dialogService
          .open(QuestionDialogComponent, {
            closeOnBackdropClick: false,
            context: {
              title: 'Confirm task done',
              message: 'If this task is fully done, do you want to change status to Done & Remove?',
              okLabel: 'Done & Remove',
              cancelLabel: 'Keep'
            },
          })
          .onClose.subscribe((decision: any) => {
            if (decision) {
              this.form.patchValue({
                statusId: doneAndRemove
              });
              this.save();
            } else {
              this.save();
            }
        }));
      } else {
        this.save();
      }
    } else {
      if (this.hasRecurringTasks && data.isAllTodosR) {
        this.subscription.add(this.dialogService
          .open(QuestionDialogComponent, {
            closeOnBackdropClick: false,
            context: {
              title: 'Confirm task done',
              message: 'The todos below are recurring todos. If this task is fully complete and you want to set it to D&R, tick the todos below.',
              okLabel: 'Done & Remove',
              cancelLabel: 'Keep',
              hideSecondOkButton: true,
              hasRecurringTasks: true,
              recurringTasks: this.recurringTasks
            },
          })
          .onClose.subscribe((decision: any) => {
            this.confirmDone = true;
            if (decision) {
              this.form.patchValue({
                statusId: doneAndRemove
              });
              this.save();
            } else {
              this.save();
            }
        }));
      } else {
        this.save();
      }
    }
  }

  save(forceUpdate = false, saveAsNewTask = false) {
    setTimeout(() => {
      if (forceUpdate || !this.subtasksService.waitForTaskResponse) {
        if (this.selectedTab == TaskTab.FINANCE) {
          this.financeTaskService.saveData$.next(true);
        } else {
          const data = this.prepareData(forceUpdate);
          const isTaskChanged = this.checkTaskChanged();
          this.subscription.add(this.tasksService.updateTask(this.id, {...data, isGridMode: this.showGridView, saveAsNewTask })
            .subscribe((response: any) => {
              // const localData = this.prepareDataToLocalUpdate(data);
              if (response.taskModifiedByOther) {
                this.showConfirmModifiedByOther(response.message);
              } else {
                this.toastrService.success(response.message, 'Success');
                if (this.form.get('isEmailOnSave').value) {
                  this.openEmailWindow();
                } else {
                  this.tableService.closeWindow(true);
                }
              }
              if (isTaskChanged || this.subTaskChenged) {
                this.taskBoardsStateService.updateGlobalList$.next();
              }
            },
              (err) => {
                this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
              }
            ));
        }
      }
    }, 1000);
  }

  setDeadlineAndText(dateAndText: { date: string, text: string}) {
    const textToAppend = dateAndText.text;
    const comments = urlify(this.form.get('comments').value);
    this.descriptionWithActiveLinks = textToAppend + '\n' + comments;
    const desc = textToAppend + '\n' + this.form.get('comments').value;
    this.descriptionMaxLength = desc.length > 1000 ? desc.length : 1000;
    this.form.patchValue({
      comments: desc,
      dueTo: dateAndText.date
    });
  }

  showPausingPopup() {
    this.subscription.add(this.dialogService
      .open(QuestionDialogTaskPauseDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Pausing confirmation'
        },
      })
      .onClose.subscribe((decisionResponse: any) => {
        if (decisionResponse) {
          this.setDeadlineAndText(decisionResponse);
        }
      }));
  }

  showChangeStatusPopup() {
    const newStatus = this.statuses.find((element: any) => element.id === this.form.get('statusId').value).wordFullName;
    let message = 'There are tasks in here that will refresh after each WU submission. Are you sure you want to change the status and remove this whole task?';

    if (newStatus === 'Paused') {
      message = 'There are tasks in here that will refresh after each WU submission. Are you sure you want to change the status and pause this whole task?';
    }

    if (this.confirmDone) {
      this.confirmDone = false;
      return;
    }
    this.subscription.add(this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Modify confirmation',
          message: message,
          okLabel: 'Yes',
          cancelLabel: 'No',
          hideSecondOkButton: true
        },
      })
      .onClose.subscribe((decision: any) => {
        if (!decision) {
          this.form.get('statusId').setValue(this.statusId);
        }
      }));
  }

  changeSaveStatus(saveEnabled: boolean) {
    this.isSaveEnabled = saveEnabled;
  }

  refreshSubtasksData(data: any) {
    this.subTaskChenged = true;
    // this.subTasks = data.subtasks;
    this.subTaskPercentage = data.progress;
    this.isSubTasksChanged = data.isSubTasksChanged;
    this.updateTaskStatus(data && data.subtasks);
  }

  showConfirmModifiedByOther(message: string) {
    this.subscription.add(this.dialogService
      .open(QuestionDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Modify confirmation',
          message: message,
          okLabel: 'Overwrite their changes',
          secondOkLabel: 'Keep their changes',
          cancelLabel: 'Cancel',
          hideSecondOkButton: false
        },
      })
      .onClose.subscribe((decision: any) => {
        if (decision.isConfirm && decision.type === QuestionDecisionTypeEnum.FIRST) {
          this.save(true);
        } else if (decision.isConfirm && decision.type === QuestionDecisionTypeEnum.SECOND) {
          this.ngOnInit();
        }
      }));
  }

  openEmailLogs() {
    this.tableService.openWindowWithBackStack(
      TasksEmailListDialogComponent,
      `Task ${this.taskBoardName} "${this.taskTitle}" - Email list`,
      {
        component: EditTaskComponent,
        title: this.taskHeader,
        elementId: this.id
      },
      {
        taskId: this.id
      }
    );
  }

  openEmailWindow() {
    this.dialogService
      .open(TasksEmailDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          title: 'Please enter subject and emails',
          needLoadTable: false,
          showClearEmails: false,
          careHomeId: this.authUserService.getCareHomeId(),
          taskboards: [{ id: this.taskBoardId }]
        }
      })
      .onClose.subscribe((inputData: any) => {
        if (inputData && inputData.isConfirm) {
          const data = {
            frontURL: this.document.location.origin,
            emailToIds: inputData.emailTo,
            emailTo: inputData.emailList,
            emailBody: inputData.emailBody,
            emailSubject: inputData.subject,
            taskList: [this.id],
            clearEmailListAfterSend: inputData.clearEmailListAfterSend
          };

          this.subscription.add(this.careHomesService.sendTasksInEmailTasks(this.authUserService.getCareHomeId(), data).subscribe((response: any) => {
            this.toastrService.success(response.message, 'Success');
            const isTaskChanged = this.checkTaskChanged();
            this.tableService.closeWindow(isTaskChanged || this.isSubTasksChanged);
          },
            (err) => {
              this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
            }));
        }
      }
      );
  }

  refreshLocalData(data: any) {
    this.isRecurring = data.isRecurring;
  }

  onTabChange(event) {
    this.selectedTab = this.tabLabels.indexOf(event.tabTitle);
    if (this.selectedTab == TaskTab.FINANCE) {
      this.financeTaskService.financeData$.next(this.taskDetails.finaceTaskInfo);
    } else if (this.selectedTab == TaskTab.HISTORY && !this.historyTableLoaded) {
      // reload history
      this.historyTabIsActive = true;
    } else if (this.selectedTab == TaskTab.FILES && !this.filesTableLoaded) {
      this.loadFiles();
    } else if (this.selectedTab == TaskTab.LINKED_TASKS && !this.linkedTasksTableLoaded) {
      this.loadLinkedTasks();
    }

    if (this.selectedTab === TaskTab.INFO) {
      this.delayFocusSubject.next();
    }
  }

  copyTask() {
    this.tableService.closeWindow(false);
    this.tableService.openWindow(CopyTaskComponent, 'Copy task', { taskList: [this.id], mode: CopyMode.COPY });
  }

  moveTask() {
    this.tableService.closeWindow(false);
    this.tableService.openWindow(CopyTaskComponent, 'Move task', { taskList: [this.id], mode: CopyMode.MOVE });
  }

  updateValues(task: any) {
    this.statusAutoChange = true;
    this.taskDetails = task;
    this.startTaskDetails = Object.assign({}, task);
    this.id = task.taskId;
    this.statusId = task.statusId;
    this.taskBoardId = task.taskBoardId;
    this.taskboardPermissions = task.permissions;
    this.selectedTags = task.tags;
    this.descriptionWithActiveLinks = task.comments;
    const desc = deurlify(task.comments);
    this.descriptionMaxLength = desc.length > 1000 ? desc.length : 1000;
    this.form.patchValue({
      title: task.title,
      categoryId: task.categoryId,
      statusId: task.statusId,
      priorityId: task.priorityId,
      dueTo: task.dueTo,
      comments: desc,
      taskGoupingId: task.taskGoupingId,
      taskRagg1Id: task.taskRagg1Id,
      taskRagg2Id: task.taskRagg2Id,
      isEmailList: task.isEmailList,
      pinnedAreaId: task.pinnedAreaId ? task.pinnedAreaId : null
    });

    this.checkedCategories = task.categoryId;

    if (this.isTaskClosed) {
      this.form.disable();
      this.form.get('statusId').enable();
      this.subscription.add(this.dictionariesService.getTaskStatusesAll()
        .subscribe((response: any) => {
          this.statuses = response.result.wordsFromDictionary;
        }));
    } else {
      this.statuses = this.cachedStatuses;
    }

    this.formHistory.patchValue({
      titleDisabled: task.title,
      commentsDisabled: task.comments
    });
    this.changeFavoriteState({
      target: {
        checked: task.isFavorite
      }
    });

    this.taskPermissions.canIChangeStateRagg1 ? this.form.get('taskRagg1Id').enable() : this.form.get('taskRagg1Id').disable();
    this.taskPermissions.canIChangeStateRagg2 ? this.form.get('taskRagg2Id').enable() : this.form.get('taskRagg2Id').disable();
  }

  onClickPre(event) {
    if (event.target.localName != 'a') {
      document.getElementById('description-pre').style.display = 'none';
      document.getElementById('comments').style.display = 'block';
      document.getElementById('comments').focus();
    }
  }

  onFocusLostOfDesc() {
    this.descriptionWithActiveLinks = urlify(this.form.get('comments').value);
    document.getElementById('description-pre').style.display = 'block';
    document.getElementById('comments').style.display = 'none';
  }

  applyFilter(filterValue: string) {
    this.onHistoryTableFilterChange$.next(filterValue);
  }

  refreshForSubtasks(event: any) {
    this.subscription.add(this.tasksService.getTask(this.id)
      .subscribe((response: any) => {
        this.taskDetails.openTime = response.result.openTime;
        this.isRecurring = response.result.isRecurring;
        this.subtasksService.waitForTaskResponse = false;
      }));
  }

  toggleTakeAll(event: any) {
    this.form.get('isShowPaused').setValue(event.target.checked);
    this.loadLinkedTasks();
  }

  toggleTakeClosed(event: any) {
    this.form.get('isShowClosed').setValue(event.target.checked);
    this.loadLinkedTasks();
  }

  changeFavoriteState(event: any) {
    this.form.patchValue({
      isFavorite: event.target.checked ? true : false
    });
  }

  changeEmailList(event: any) {
    this.form.patchValue({
      isEmailList: event.target.checked ? true : false
    });
  }

  changeEmailOnSave(event: any) {
    this.form.patchValue({
      isEmailOnSave: event.target.checked ? true : false
    });
  }

  addWatchers() {
    this.dialogService
      .open(AddWatchersDialogComponent, {
        closeOnBackdropClick: false,
        context: {
          taskboardId: this.taskBoardId,
          taskId: this.id
        },
      })
      .onClose.subscribe((selectedWatchers: any) => {
        if (selectedWatchers) {
          this.subscription.add(this.tasksService.updateTaskWatchers(this.id, selectedWatchers)
            .subscribe((response: any) => {
              this.toastrService.success(response.message, 'Success');
            },
            (err: any) => {
              this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
            }));
        }
      }
    );
  }

  calculateHowManySubtaskToUpdate(subtasks: any = []) {
    let toUpdate = 0;
    for (const subTask of subtasks) {
      for (const todo of subTask.todos) {
        if (!todo.isCompleted && !todo.isNotCompleted && !todo.latestUpdate) {
          toUpdate++;
        }
      }
    }
    return toUpdate;
  }

  updateTaskStatus(subtasks: any = []) {
    this.isTaskReadyToEndStatus = false;
    this.temporarySubtasks = subtasks;
    const noOfSubtasksToUpdate = this.calculateHowManySubtaskToUpdate(subtasks);
    this.taskProgress = 'There are ' + noOfSubtasksToUpdate + ' todos to be updated';
    this.taskProgressBackground = '#6B6B6B';
    if (this.form.get('statusId').value == this.getStatusWord('Paused').id) {
      this.taskProgress = 'This task is paused';
      this.taskProgressBackground = '#5DE191';
      this.isTaskReadyToEndStatus = true;
    } else if (noOfSubtasksToUpdate < 1 ) {
      this.isTaskReadyToEndStatus = true;
      this.taskProgress = 'Task ready to submit for your report.';
      this.taskProgressBackground = '#5DE191';
    }
    this.isHideStatus = true;
    if (this.isTaskReadyToEndStatus && this.IsAllTodosCompleted(subtasks)) {
      this.statuses = Object.assign([], this.cachedStatuses);
    } else {
      this.statuses = Object.assign([], this.statusesWithoutEndStatuses);
    }
    this.isHideStatus = false;
  }

  IsAllTodosCompleted(subtasks: any = []) {
    let isCompleted = true;
    subtasks.forEach((subtask: any) => {
      for (let i = 0; i < subtask.todos.length; i++) {
        const todo = subtask.todos[i];
        if (!todo.isCompleted) {
          isCompleted = false;
          break;
        }
      }
    });
    return isCompleted;
  }

  loadStatusesAndDisableEndStatuses(allStatuses = []) {
    this.statusesWithoutEndStatuses = Object.assign([], allStatuses);
    this.statusesWithoutEndStatuses = allStatuses.map((item: any) => {
      return {
        id: item.id,
        wordFullName: item.wordFullName + (item.wordFullName == 'Done(remove)' || item.wordFullName == 'Done and verified' || item.wordFullName == 'Done' ? ' - Selectable once task is 100%' : ''),
        disabled: item.wordFullName == 'Done(remove)' || item.wordFullName == 'Done and verified' || item.wordFullName == 'Done' || item.wordFullName == 'Closed'
      };
    });
  }

  uploadDocumentDescription(event: any) {
    this.subscription.add(this.tasksService.addDocumentDescription(this.id, event.uid, event).subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
      },
      (err) => {
        this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
      }
    ));
  }

  uploadFile(file: any) {
    const fd = new FormData();
    fd.append('file', file);
    this.subscription.add(this.tasksService.addTaskFile(this.id, fd)
      .subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        this.loadFiles();
      },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }
      ));
  }

  deleteFile(fileUId: string) {
    this.subscription.add(this.tasksService.deleteTaskFile(this.id, fileUId)
      .subscribe((response: any) => {
        this.toastrService.success(response.message, 'Success');
        this.loadFiles();
      },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }
      ));
  }

  downloadFile(fileUId: string) {
    this.subscription.add(this.tasksService.downloadTaskFile(this.id, fileUId)
      .subscribe((response: any) => {
        window.open(getServerLink(response.link), '_blank');
      },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }
      ));
  }

  getTaskFiles() {
    this.subscription.add(this.tasksService.getTaskFiles(this.id)
      .subscribe((response: any) => {
      },
        (err) => {
          this.toastrService.danger(getErrorMessage(err), 'Error', { duration: 60000, destroyByClick: true });
        }
      ));
  }

  addTab = (task: any) => {
    if (task.taskId == this.id) {
      this.activeTab = TaskTab.INFO;
    } else {
      const link = this.getProperLinkForTask(task);
      window.open(link, '_blank');
    }
  }

  getProperLinkForTask(task: any) {
    const customLink = this.getSpecialTaskboardLink(task);
    let taskId = task.taskId;
    if (task.isSubTask) {
      taskId = task.subTaskForTaskId;
    }
    if (customLink && customLink.length > 0) {
      return (`${location.origin}#/${customLink}?taskId=${taskId}&careHomeId=${task.careHomeId}`);
    } else {
      return (`${location.origin}#/task-management/task-boards/${task.taskBoardId}?taskId=${taskId}&careHomeId=${task.careHomeId}`);
    }
  }

  getSpecialTaskboardLink(task: any) {
    if (task.specialTaskboardId) {
      switch (task.specialTaskboardId) {
        case ESpecialTaskTypes.residents: return 'task-management/custom-task-boards/resident-taskboard';
        case ESpecialTaskTypes.menuChoices: return 'task-management/custom-task-boards/menu-changes';
        case ESpecialTaskTypes.roomEnquiries: return 'task-management/custom-task-boards/room-enquiries';
        case ESpecialTaskTypes.finance: return 'task-management/custom-task-boards/finance-taskboard';
        case ESpecialTaskTypes.annualTasks: return 'task-management/custom-task-boards/auto-tasks-taskboard';
      }
    }
    return null;
  }

  readonly checkFileIsContractDocument = (fileData: any) => {
    return fileData.isContractDocument;
  }

  connectWithContract = (fileData: any) => {
    this.tableService.openWindowWithBackStack(ChooseContractComponent, 'Choose contract',
      { component: EditTaskComponent, title: `Edit task - ${this.taskHeader}`, elementId: this.id },
      { documentUid: fileData.uid, documentCategoryId: fileData.documentCategoryId, employeeId: fileData.employeeId }
    );
  }

  readonly getContractLabel = (fileData: any) => {
    return fileData.contractUploadedAt != 'n/a' ? 'Contract uploaded' : 'Add contract';
  }

  readonly isContractUploaded = (fileData: any) => {
    return fileData.contractUploadedAt != 'n/a';
  }

  closeWindow() {
    const isTaskChanged = this.checkTaskChanged();

    if (isTaskChanged) {
      this.subscription.add(this.dialogService
        .open(QuestionDialogComponent, {
          closeOnBackdropClick: false,
          context: {
            title: 'Task changed',
            message: 'Are you sure, you want close task without saving?',
            okLabel: 'Yes',
            cancelLabel: 'No',
            hideSecondOkButton: true
          },
        })
        .onClose.subscribe((decision: any) => {
          if (decision) {
            this.doActionsAfterClose();
          }
        }));
    } else {
      this.doActionsAfterClose();
    }
  }

  doActionsAfterClose() {
    this.taskDetails['subTaskPercentage'] = this.subTaskPercentage;
    this.tableService.updateLocalObjectAndCloseWindow(this.taskDetails, true);
  }

  private checkTaskChanged(): boolean {
    if (this.form.get('title').value !== this.startTaskDetails.title
      || this.form.get('statusId').value !== this.startTaskDetails.statusId
      || this.form.get('priorityId').value !== this.startTaskDetails.priorityId
      || this.form.get('dueTo').value !== this.startTaskDetails.dueTo
      || urlify(this.form.get('comments').value) !== this.startTaskDetails.comments
      || this.form.get('isFavorite').value !== this.startTaskDetails.isFavorite
      || this.form.get('taskGoupingId').value !== this.startTaskDetails.taskGoupingId
      || this.form.get('taskRagg1Id').value !== this.startTaskDetails.taskRagg1Id
      || this.form.get('taskRagg2Id').value !== this.startTaskDetails.taskRagg2Id
      || this.form.get('isEmailList').value !== this.startTaskDetails.isEmailList) {
      return true;
    }

    if (this.form.get('categoryId').value && this.startTaskDetails.categoryId.length === this.form.get('categoryId').value.length) {
      for (const category of this.startTaskDetails.categoryId) {
        if (this.form.get('categoryId').value) {
          const foundCategory = this.form.get('categoryId').value.find((element) => element.id === category.id);
          if (!foundCategory) {
            return true;
          }
        } else {
          return true;
        }
      }
    } else {
      return true;
    }

    if (this.selectedTags.length === this.startTaskDetails.tags.length) {
      for (const tag of this.startTaskDetails.tags) {
        const foundTag = this.selectedTags.find((element) => element.id === tag.id);
        if (!foundTag) {
          return true;
        }
      }
    } else {
      return true;
    }

    return false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.financeTaskService.reset();
    this.clickSubscription?.unsubscribe();
  }

  private updateCategoriesOnLoad(categoryId) {
    categoryId.forEach(categoryId => {
      this.categories.forEach(category => {
        if (category.id === categoryId.id) {
          categoryId.customListOfInfo = category.customListOfInfo;
        }
      });
    });
  }

  getCategoriesNames(): string[] {
    const namesToReturn = [];

    const setNames = (category) => {
      category.customListOfInfo.forEach(item => {
        if (!namesToReturn.includes(item.name)) {
          namesToReturn.push(item.name);
        }
      });
    };

    this.checkedCategories.forEach(category => {
      if (!category.customListOfInfo) {
        this.categories.forEach(item => {
          if (item.id == category.id) {
            category.customListOfInfo = item.customListOfInfo;
          }
        });
        setNames(category);
      } else {
        setNames(category);
      }
    });

    return namesToReturn;
  }

  getDataToSend() {
    this.subscription.add(
      this.tasksService.getDeadlineEmails(this.id)
      .subscribe((response: ITasksDeadlineEmailsInfoResponse) => {
        this.deadlineEmails = response.taskDeadlineEmailsInfo;
        this.deadlineEmailsCollected = true;
      })
    );
  }

  getRecurringTodos($event: { name: string; checked: boolean; taskName: string}[]) {
    this.hasRecurringTasks = $event.length > 0;
    this.recurringTasks = $event;
  }

  toggleExpandCollapse($event: boolean | "default") {

    if ($event === "default") {
      this.lastScrollTop = document.getElementById('edit-task-container-info-tab').scrollTop;
      this.getTaskData(true)

    } else {
      this.editor.subtasks.forEach(subTask => {
        if ($event) {
          subTask.isExpanded = $event;
          subTask.todos.forEach(todo => {
            todo.isCollapsed = !$event;
          })
        } else {
          subTask.todos.forEach(todo => {
            todo.isCollapsed = !$event;
          })
          subTask.isExpanded = $event;
        }
      })
    }

  }

  private setFocus() {
    this.formBlock.nativeElement.focus();
    this.formBlock.nativeElement.click();
  }

  saveAsNewTask() {
    this.save(true, true);
  }
}
