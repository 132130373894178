
import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[alFilter]'
})
export class AlFilterDirective {
  @Input() alFilter: boolean;

  constructor() {}

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent> event;
    if (this.alFilter) {
      // Allow: Delete, Backspace, Tab, Escape, Enter, 65 - a, 83 -s, 186 - :
            if ([46, 8, 9, 27, 13, 65, 83, 186].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode == 65 && e.ctrlKey === true) ||
        // Allow: Ctrl+C
        (e.keyCode == 67 && e.ctrlKey === true) ||
        // Allow: Ctrl+X
        (e.keyCode == 88 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
          // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    }
  }
}
