import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HourModalService {
  private newFrom: string;
  private newTo: string;
  private newTo24Format: string;
  private newFrom24Format: string;
  private setToControl: Function;
  private setFromControl: Function;
  private target: HTMLInputElement;

  setCurrentValues(
    newFrom: string,
    newTo: string,
    newTo24Format: string,
    setToControl: any,
    target: HTMLInputElement,
    newFrom24Format?: string,
    setFromControl?: any,
  ): void {
    this.newFrom = newFrom;
    this.newTo = newTo;
    this.newTo24Format = newTo24Format;
    this.newFrom24Format = newFrom24Format;
    this.setToControl = setToControl;
    this.target = target;
    this.setFromControl = setFromControl;
  }

  setChangedToValue(changedTo: string): void {
    this.setToControl(changedTo);

    this.target.value = changedTo;
  }

  public setChangeFromValue(changeFrom: string): void {
    this.setFromControl(changeFrom);
  }

  getCurrentValues(): {
    newFrom: string;
    newTo: string;
    newTo24Format: string;
    newFrom24Format: string;
  } {
    const currentValues = {
      newFrom: this.newFrom.length > 4 ? this.newFrom : this.returnFormatterDate(this.newFrom),
      newTo: this.newTo.length > 4 ? this.newTo : this.returnFormatterDate(this.newTo),
      newTo24Format: this.newTo24Format.length > 4 ? this.newTo24Format : this.returnFormatterDate(this.newTo24Format),
      newFrom24Format: this.newFrom24Format.length > 4 ? this.newFrom24Format : this.returnFormatterDate(this.newFrom24Format),
    };
    return { ...currentValues };
  }

  private returnFormatterDate(value: string) {
    return value.slice(0, 2) + ':' + value.slice(2, 4);
  }

  constructor() {}
}
