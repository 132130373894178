export enum ScheduleDayStatus {
  CONFIRMED = 1,
  CONFIRMED_OTHERS = 2,
  ABSENT = 3,
  AL = 4,
  SICK = 5,
  TRAINING = 13,
  TRAINING_SICK = 14,
  TRAINING_AL = 15,
  EXTRAS_TAKEN = 16,
  NO_CONTRACT_START = 6,
  NO_CONTRACT_END = 7,
  NOT_CONFIRMED = 8,
  UNSET = 404,
  SET_IN_CONFIRM_MODE = 205,
  BLANK = 500
}
