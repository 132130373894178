import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AnnualLeavingApiUrls } from './annual-leaving.enum';
import * as moment from 'moment';

@Injectable()
export class AnnualLeavingService {
  constructor(private httpClient: HttpClient) {}

  getAnnualLeavingById(eventId: number) {
    return this.httpClient.get(`${environment.apiUrl}${AnnualLeavingApiUrls.annualLeavingUnaggregated}/${eventId}`);
  }

  getAnnualLeavings(startDate: any, endDate: any, onlyActive: boolean) {
    return this.httpClient.get(`${environment.apiUrl}${AnnualLeavingApiUrls.annualLeavingList}?activeOnly=${onlyActive}&type=AL&startDateFormated=${moment(startDate).format('YYYY/MM/DD')}&endDateFormated=${moment(endDate).format('YYYY/MM/DD')}`);
  }

  addNewLeaving(employeeId: number, data: any, eventType: string) {
    return this.httpClient.post(`${environment.apiUrl}/employees/${employeeId}/events?eventType=${eventType}`, data);
  }

  updateLeaving(eventId: number, data: any) {
    return this.httpClient.put(`${environment.apiUrl}${AnnualLeavingApiUrls.annualLeavingUnaggregated}/${eventId}`, data);
  }

  acceptEvent(eventId: number, data: any) {
    return this.httpClient.patch(`${environment.apiUrl}${AnnualLeavingApiUrls.annualLeavingUnaggregated}/${eventId}`, data);
  }

  deleteLeaving(eventId: number) {
    return this.httpClient.delete(`${environment.apiUrl}${AnnualLeavingApiUrls.annualLeavingUnaggregated}/${eventId}`);
  }

  getReplacements(eventId: number) {
    return this.httpClient.get(`${environment.apiUrl}/employees/events/${eventId}?skip-care-home`);
  }

  createReplacement(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${AnnualLeavingApiUrls.annualLeavingReplacement}`, data);
  }

  deleteReplacement(eventId: number) {
    return this.httpClient.delete(`${environment.apiUrl}${AnnualLeavingApiUrls.annualLeavingReplacement}/${eventId}`);
  }

}
