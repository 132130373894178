import { Injectable, TemplateRef } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { NbWindowRef, NbWindowService, NbComponentType } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class CoursesOverviewService {
  public offset = {
    top: 0,
    left: 0
  };
  data: any = [];
  choosenCourse: any;
  public multipleMode$ = new BehaviorSubject<boolean>(true);

  private closeChangeWindowSource = new Subject<boolean>();
  public closeChangeWindow$ = this.closeChangeWindowSource.asObservable();
  private windowRef: NbWindowRef;
  public courseStatuses: any;

  constructor(private windowService: NbWindowService) {}

  resetService() {
    this.data = [];
    this.courseStatuses = null;
    this.choosenCourse = null;
  }

  selectByCourse(courseId: number, selectedStatuses: any = []) {
    this.changeToMultipleMode(true);
    this.data.forEach((employee: any) => {
      employee.courses.forEach((course: any) => {
        const isCourseInFilteredStatuses = selectedStatuses.find((status: any) => {
          return status.wordFullName === course.status;
        });
        if (course.id === courseId && isCourseInFilteredStatuses) {
          course.selected = true;
        }
      });
    });
  }

  toogleSelected(entryId: number) {
    this.data.forEach((employee: any) => {
      employee.courses.forEach((course: any) => {
        if (course.entryId == entryId) {
          course.selected = !course.selected;
        }
      });
    });
  }

  getSelectedCourses() {
    const list = [];

    this.data.forEach((employee: any) => {
      employee.courses.forEach((course: any) => {
        if (course.selected) {
          list.push({
            course: course,
            employee: employee
          });
        }
      });
    });

    return list;
  }

  removeSelectedByCourse(courseId: number) {
    this.data.forEach((employee: any) => {
      employee.courses.forEach((course: any) => {
        if (course.id === courseId) {
          course.selected = false;
        }
      });
    });
  }

  changeToMultipleMode(multipleMode: boolean) {
    this.multipleMode$.next(multipleMode);
    if (!multipleMode) {
      this.resetAllSelectedCourses();
    }
  }

  resetAllSelectedCourses() {
    this.data.forEach((employee: any) => {
      employee.courses.forEach((course: any) => {
        course.selected = false;
      });
    });
  }

  getChoosenCourseData() {
    const foundEmployee = this.data.find((employee: any) => {
      return employee.id === this.choosenCourse.employeeId;
    });

    return {
      course: this.choosenCourse,
      employee: foundEmployee
    };
  }

  saveOffset() {
    this.offset = {
      top: document.getElementById('main-content').scrollTop,
      left: document.getElementById('scrollable-content').scrollLeft
    };
  }

  openWindow(windowContent: TemplateRef<any> | NbComponentType, title: string, choosenCourse: any = null) {
    this.choosenCourse = choosenCourse;
    const config = {
      closeOnBackdropClick: false,
      closeOnEsc: false,
      title: title
    };
    this.windowRef = this.windowService.open(windowContent, config);
  }

  closeWindow(needReload: boolean) {
    this.choosenCourse = undefined;
    this.windowRef.close();
    this.closeChangeWindowSource.next(needReload);
  }

}
