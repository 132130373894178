import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class InboxEventService {
  public refreshLeftMenu$ = new Subject<any>();
  public refreshList$ = new Subject<any>();
  public actualMailbox$ = new Subject<any>();

  updateLeftMenu(categoryId?: string) {
    this.refreshLeftMenu$.next(categoryId);
  }

  updateInbox() {
    this.refreshList$.next(true);
  }

}
