import { Injectable } from '@angular/core';
import { ICareHome } from '../interfaces/care-home.interfaces';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CareHomesInfoService {
  private _careHomes: BehaviorSubject<ICareHome[]>;

  constructor() {
    this._careHomes = new BehaviorSubject<ICareHome[]>(null);
  }

  setCareHomes(value: ICareHome[]): void {
    this._careHomes.next(value);
  }

  get careHomes$(): Observable<ICareHome[]> {
    return this._careHomes.asObservable();
  }

  get careHomesSnapshot(): ICareHome[] {
    return this._careHomes.getValue();
  }
}
