import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { getErrorMessage, getServerLink } from "../../../utilities/utils";
import { ProfilesService } from "../../../shared/profiles/profiles.service";
import { NbToastrService } from "@nebular/theme";
import { focusMainElement } from "../../../utilities/focus-main-element";
import { TableService } from "../table/table.service";

@Component({
  selector: 'ngx-tasks-email-list-dialog',
  templateUrl: './tasks-email-list-dialog.component.html',
  styleUrls: ['./tasks-email-list-dialog.component.scss']
})
export class TasksEmailListDialogComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  public taskId: number;
  public emailLogList = [];
  public totalRows = 0;
  public isDataLoaded = false;
  public loadError = false;
  public errorMsg = '';
  public noDataMsg = 'No emails to display';
  public columns = [
    {
      fieldName: "date",
      title: "Date sent",
    },
    {
      fieldName: "email",
      title: "Sent to",
    },
    {
      fieldName: "typeName",
      title: "Email type",
    },
    {
      fieldName: "taskName",
      title: "Message / Task title",
    },
    {
      fieldName: "careHomeName",
      title: "Care Home",
    },
    {
      fieldName: "download",
      title: "Download",
      fieldType: "download",
      action: (element: any) => {
        this.subscription.add(
          this.profileService.getEmailMessage(element.emailUid).subscribe(
            (response: any) => {
              window.open(getServerLink(response.link), "_blank");
            },
            (err) => {
              this.toastrService.danger(getErrorMessage(err), "Error", {
                duration: 60000,
                destroyByClick: true,
              });
            }
          )
        );
      },
    },
  ];
  public paginationData = {
    pageIndex: 0,
    pageSize: 25,
    orderBy: "",
    orderDirection: "",
  };

  constructor(
    private tableService: TableService,
    private profileService: ProfilesService,
    private toastrService: NbToastrService,
  ) { }

  ngOnInit() {
    this.taskId = this.tableService.getValue().taskId;
    this.getEmails();
  }

  getEmails() {
    this.isDataLoaded = false;
    this.loadError = false;
    this.subscription.add(
      this.profileService
        .getEmailLogForTask(this.taskId)
        .subscribe((response: any) => {
          this.emailLogList = response.result.list;
          this.totalRows = response.result.totalRows;
          this.isDataLoaded = true;
          setTimeout(() => {
            focusMainElement("table-container-to-focus", "id");
          }, 100);
        }, (err) => {
          this.toastrService.danger(getErrorMessage(err), "Error", {
            duration: 60000,
            destroyByClick: true,
          });
          this.isDataLoaded = true;
          this.loadError = true;
          this.errorMsg = getErrorMessage(err);
        })
    );
  }

  updatePagination(event, isSort?) {
    if (isSort) {
      this.paginationData.orderBy = event.active;
      this.paginationData.orderDirection = event.direction;
    } else {
      this.paginationData.pageIndex = event.pageIndex;
      this.paginationData.pageSize = event.pageSize;
    }
    this.getEmails();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  closeWindow() {
    this.tableService.closeWindow(false);
  }

}
