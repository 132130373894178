<ng-container *ngFor="let item of menuItems; let index = index">
  <section
    class="accordion-section"
    *ngIf="item.children && item.children.length > 0"
  >
    <mat-accordion>
      <mat-expansion-panel
        class="menu-panel app-menu-expansion-panel"
        [expanded]="openedIndex === index"
        [disabled]="isCompacted"
      >
        <mat-expansion-panel-header
          class="menu-header"
          [ngClass]="{
            compacted: isCompacted,
            selected: openedIndex === index
          }"
          (click)="
            openIfExpanded(index, false);
            openCompactedSideMenu(item);
            menuClick.emit()
          "
        >
          <div class="menu-position" [id]="item.title">
            <nb-icon [icon]="item.icon"></nb-icon>
            <p>{{ item.title }}</p>
          </div>
        </mat-expansion-panel-header>
        <div class="panel-body" [ngClass]="{opened: openedIndex === index}">
          <span
            *ngFor="let child of item.children; let sub_index = index"
            [ngClass]="{ hidden: openedIndex !== index }"
          >
            <ng-container
              *ngIf="!child.children || child.children.length === 0"
            >
              <mat-list-item
                [id]="child.id"
                [routerLink]="[child.link]"
                [routerLinkActive]="'activated'"
                [ngClass]="{
                  selected: child.link === openedUrl,
                  'no-icon': !child.icon
                }"
                class="child"
              >
                <div class="menu-position">
                  <nb-icon *ngIf="child.icon" [icon]="child.icon"></nb-icon>
                  <p>{{ child.title }}</p>
                </div>
              </mat-list-item>
            </ng-container>
            <ng-container *ngIf="child.children && child.children.length > 0">
              <mat-accordion>
                <mat-expansion-panel
                  class="menu-panel"
                  [expanded]="openedSubmenuIndex === sub_index"
                >
                  <mat-expansion-panel-header
                    class="menu-header with-children sub-opened"
                    (click)="openIfExpanded(sub_index, true)"
                    [id]="child.title"
                  >
                    <div class="menu-position">
                      <nb-icon *ngIf="child.icon" [icon]="child.icon"></nb-icon>
                      <p>{{ child.title }}</p>
                    </div>
                  </mat-expansion-panel-header>
                  <span *ngFor="let ch of child.children">
                    <mat-list-item
                      [id]="ch.title"
                      [routerLink]="[ch.link]"
                      [routerLinkActive]="'activated'"
                      [ngClass]="{
                        selected: ch.link === openedUrl,
                        'no-icon': !ch.icon
                      }"
                      class="child sub-menu"
                    >
                      <div class="menu-position">
                        <nb-icon *ngIf="ch.icon" [icon]="ch.icon"></nb-icon>
                        <p>{{ ch.title }}</p>
                      </div>
                    </mat-list-item>
                  </span>
                </mat-expansion-panel>
              </mat-accordion>
            </ng-container>
          </span>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</ng-container>
